import {Injectable} from '@angular/core';
import {AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {FirestoreService} from '@eva-services/firestore/firestore.service';
import {AuthService} from '@eva-core/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Process} from '@eva-model/process/process';
import {defaultUserPreferences, UserPreferences} from '@eva-model/UserPreferences';
import {User} from '@eva-model/User';
import {EvaGlobalService} from '@eva-core/eva-global.service';
import {DataStorageService} from '@eva-core/storage/data-storage.service';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import firebase from 'firebase/compat/app';
import {take} from 'rxjs/operators';
import {AngularFirePerformance, trace} from '@angular/fire/compat/performance';
import {DynamicDatabaseService} from "@eva-services/dynamicdatabase/dynamic-database.service";
import { ChatService } from '@eva-services/chat/chat.service';
import { LastStateType } from '@eva-model/userLastState';
import { ILaunchPad } from '@eva-model/menu/menu';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  processCollection: AngularFirestoreCollection<Process>;
  // user: User; // the user object for use in the application.

  private userPreferences: BehaviorSubject<UserPreferences> = new BehaviorSubject(null);
  public userPreferences$: Observable<UserPreferences>;

  currentLaunchPadItems: ILaunchPad = null;

  firebaseFunctionsEndpoint = environment.firebaseFunction.endpoint;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  getEntropyUrl = this.firebaseFunctionsEndpoint + '/getEntropy';
  verifyUserUrl = this.firebaseFunctionsEndpoint + '/verifyUser';

  constructor(
    private _fireStoreService: FirestoreService,
    private _dynamicDatabaseService: DynamicDatabaseService,
    private _evaGlobalService: EvaGlobalService,
    private _dataStorageService: DataStorageService,
    private _authService: AuthService,
    private _http: HttpClient,
    private chatService: ChatService,
    private perf: AngularFirePerformance) {
      this.userPreferences$ = this.userPreferences.asObservable();
    }

  //#region User Process(es) related functionalities
  /**
   * This function fetches the user processes by group
   *
   * @param groupPublicKey Public key of the group
   */
  public async fetchUserProcessesByGroup(groupPublicKey: string): Promise<Observable<any>> {
    const userId = await this._authService.getUserId();
    return this._fireStoreService.colWithIds$(
      'users/' + userId + '/Processes',
      ref => ref.where('groupPublicKey', '==', groupPublicKey)).pipe(
        trace('user-fetchUserProcessesByGroup')
      );
  }
  //#endregion

  //#region User preferences related methods
  public async getDefaultUserPreferenceObject(): Promise<UserPreferences> {
    try {
      const user = await this._authService.user.pipe(
        trace('user-getDefaultUserPreferenceObject'),
        take(1)
      ).toPromise();
      if (user) {
        const userPreferences = defaultUserPreferences;
        userPreferences.name =  (user.preferredName) ? user.preferredName : '';
        userPreferences.image =  (user.photoURL) ? user.photoURL : '';
        return JSON.parse(JSON.stringify(userPreferences));
      } else {
        return Promise.reject('No user found');
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  // Utilizes when a user gets created  :: this._authService.getUserId()
  public async initializeUserPreferences(user?: User): Promise<void> {
    // // need the user ID to create
    // if (!user.uid) {
    //   console.log("No User ID found");
    //   return Promise.reject('No User Id found');
    // }

    const userId = await this._authService.getUserId();
    // get the path to the user preference collection
    const userPreferencePath = `users/${userId}/UserPreferences/Preferences`;
    try {
      const userPreferences = await this._fireStoreService.doc$(userPreferencePath).pipe(
        trace('user-initializeUserPreferences'),
        take(1)
      ).toPromise();
      // this will happen if a doc is found.
      if (userPreferences) {
        return Promise.reject('User preferences already exists for the user.');
      } else {
        // create the user object.
        const newUserPreferences =  await this.getDefaultUserPreferenceObject();
        this.userPreferences.next(newUserPreferences);
        return this._fireStoreService.set(userPreferencePath, newUserPreferences);

      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
    // // get path to user.prefref by uid
    // const userPrefClctn = this._fireStoreService.col<any>('users/' + user.uid + '/UserPreferences');


    // return this._fireStoreService.add(userPrefClctn, this.getDefaultUserPreferenceObject(user));
  }

  public async getUserPreferences(user?: User, forceUpdate?: boolean): Promise<UserPreferences> {
    // check if this is a proper user object.
    // if (!user.uid) {
    //   console.log("No User Id found");
    //   return Promise.reject('No User Id Found');
    // }

    const loadedUserPreferences = this.userPreferences.getValue();
    // if this currently exists, return the preferences, otherwise get them and return them.
    if (loadedUserPreferences && !forceUpdate) {
      return loadedUserPreferences;
    }
    const userId = await this._authService.getUserId();
    const preferencePath = `users/${userId}/UserPreferences`;
    // get the path to the user preference collection
    const userPreferencePath = `${preferencePath}/Preferences`;

    try {
      const userPreferences = await this._fireStoreService.doc$<UserPreferences>(userPreferencePath).pipe(
        trace('user-getUserPreferences'),
        take(1)
      ).toPromise();
      if (userPreferences) {
        this.userPreferences.next(userPreferences);
        return userPreferences;
      } else {
        // this portion was added to clean up the original design of the preference objects in the EVA system.
        const multipleUserPreferences = await this._fireStoreService.colWithIds$<UserPreferences>(preferencePath).pipe(
          trace('user-getUserPreferences-multipleUserPreferences'),
          take(1)
          ).toPromise();
        if (multipleUserPreferences && multipleUserPreferences.length > 0) {
          // get the preference that was being used and create a new object.
          const currentUsedPref: UserPreferences = JSON.parse(JSON.stringify(multipleUserPreferences[0]));
          this.userPreferences.next(currentUsedPref);
          // delete all references in the preferences.
          await this._fireStoreService.deleteCollection(preferencePath, 300).pipe(
            trace('user-getUserPreferences-deleteCollection'),
            take(1)
            ).toPromise();
          // create a new document at the correct path.
          await this._fireStoreService.set(userPreferencePath, currentUsedPref);
          // return the preferences
          return currentUsedPref;
        } else {
          // create new preferences for the user.
          const newUserPreferences = await this.getDefaultUserPreferenceObject();
          this.userPreferences.next(newUserPreferences);
          await this._fireStoreService.set(userPreferencePath, newUserPreferences);
          return newUserPreferences;
        }
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
    // // added a limit to one user preference to return (there should only be one).
    // const userPrefClctn = this._fireStoreService.col<any>('users/' + user.uid + '/UserPreferences', ref => ref.limit(1));
    // return this._fireStoreService.col$<UserPreferences>(userPrefClctn);
  }

  public async getUserLaunchPadItems(forceUpdate?: boolean): Promise<ILaunchPad> {
    // if this currently exists, return the launchPad items, otherwise get them and return them.
    if (this.currentLaunchPadItems && !forceUpdate) {
      return this.currentLaunchPadItems;
    }

    const userId = await this._authService.getUserId();
    const preferencePath = `users/${userId}/UserPreferences`;
    // get the path to the user LaunchPad collection
    const userLaunchPadItemsPath = `${preferencePath}/LaunchPad`;

    try {
      const userLaunchPadItems = await this._fireStoreService.doc$<ILaunchPad>(userLaunchPadItemsPath).pipe(
        trace('user-getUserLaunchPadItems'),
        take(1)
      ).toPromise();
      if (userLaunchPadItems) {
        this.currentLaunchPadItems = userLaunchPadItems;
        return userLaunchPadItems;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  public async updateUserLaunchPadItems(launchPadItems: ILaunchPad): Promise<void> {
    const userId = await this._authService.getUserId();
    // get the path to the user LaunchPad collection
    const userPreferencePath = `users/${userId}/UserPreferences/LaunchPad`;
    // update the user LaunchPad items.
    try {
      this.currentLaunchPadItems = launchPadItems;
      return await this._fireStoreService.upsert(userPreferencePath, launchPadItems);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function initializes the signup process and introduces what EVA can do for the user.
   *
   * @param user User object
   */
  public initializeSignupEVAConversation(user: User): Promise<void> {
    if (!user.uid) {
      // console.log("No User ID found");
      return;
    }

    // eslint-disable-next-line max-len
    const initialMessaging = 'Hi! We’re EVA, we’re currently learning but there’s a few processes currently in pilot on our platform. We can help you get to them! Here’s a few things you can ask us: <br /><br />' +
    '"What can you do?" for a list of Intelligent Process Automation. (Mastercard, Loan Servicing, Address Change) <br /><br />' +
    // eslint-disable-next-line max-len
    'If you’re really stuck, please refer to the training material provided to you in your invite email. The “About” page is currently in construction and will be released shortly. ';

    // “What’s the best Backstreet Boys song?” for my honest opinion on my favorite band
    const evaFirstMessage = {
      query: '{ Signup complete }',
      response: {
        fulfillmentText: initialMessaging,
        queryText: '{ Signup complete }'
      },
      type: 'DialogFlowResponse' as LastStateType
    };

    this.chatService.createChatEntitiesFromDialogFlow(evaFirstMessage);

    const lastStateRef = this._fireStoreService.doc<any>('users/' + user.uid + '/State/LastState');
    return this._fireStoreService.upsert(lastStateRef, evaFirstMessage);
  }

  /**
   * This will update the user preferences with a new user preference object.
   *
   * @param userPreferences the new user preferences to include.
   */
  public async updateUserPreferences(userPreferences: UserPreferences): Promise<void> {

    // // need the user ID to create
    // if (!this.user || !this.user.uid) {
    //   console.log("No User ID found");
    //   return Promise.reject('No User Id found');
    // }

    const userId = await this._authService.getUserId();
    // get the path to the user preference collection
    const userPreferencePath = `users/${userId}/UserPreferences/Preferences`;
    // update the user Preferences.
    try {
      await this._fireStoreService.upsert(userPreferencePath, userPreferences);
      this.userPreferences.next(userPreferences);
      return this._dynamicDatabaseService.saveNotificationPreferences(userPreferences);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }

    // return this._fireStoreService.colWithIds$(userPrefClctn)
    // .pipe(take(1))
    // .toPromise()
    // .then((prefData) => {
    //   if (!( prefData && Array.isArray(prefData) && prefData.length > 0 )) {
    //     throw new Error("User preference doesn't exist");
    //   }

    //   const userPrefId = prefData[0].id;
    //   const userPrefPath = 'users/' + user.uid + '/UserPreferences/' + userPrefId;
    //   return this._fireStoreService.upsert(userPrefPath, userPreferences);
    // })
    // .catch( err => {
    //   console.log(err);
    //   return null;
    // });
  }

  //#endregion

  //#region Crypto Encryption Signing related methods
  /**
   * This function gets the entropy for encryption
   */
  getEntropy(): void {

    const httpOptions = {
      headers: this.headers
    };

    // console.log( this._evaGlobalService.userId );
    const bodyData = { 'uid': this._evaGlobalService.userId };

    this._http.post<any>(this.getEntropyUrl, bodyData, httpOptions).pipe(
      trace('user-getEntropy')
    ).toPromise()
    .then(data => {
      console.log(data);
    })
    .catch(err => {
      console.log(err);
    });
  }

  /**
   * This function verifies the user mnemonic to validate user
   *
   * @param mnemonic User mnemonic
   * @param mnemonicTypeToVerify Type of the mnemonic
   */
  verifyMnemonic(mnemonic: string, mnemonicTypeToVerify: string): Observable<any> {
    const httpOptions = {
      headers: this.headers
    };

    const bodyData = {
      'uid': this._evaGlobalService.userId,
      'mnemonic': mnemonic,
      'type': mnemonicTypeToVerify   // mnemonicType enum
    };

    return this._http.post<any>(this.verifyUserUrl, bodyData, httpOptions).pipe(
      trace('user-verifyMnemonic')
    );
  }
  //#endregion

  //#region User devices related methods
  /**
   * This function returns user device from id
   * @param deviceId Id of the user device
   */
  getUserDevice(deviceId: string): Observable<any> {
    const userDeviceCollection = 'users/' + this._evaGlobalService.userId + '/Devices';

    return this._fireStoreService.colWithIds$(
      userDeviceCollection,
        ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', deviceId)).pipe(
          trace('user-getUserDevice')
        );
  }
  //#endregion

}


