<div *ngIf="isLoading; else searchBlock">
  Loading...
</div>
<ng-template #searchBlock>
  <ais-instantsearch *ngIf="!isLoading" [config]="searchConfig" (change)="onSearchChange($event)">
    <ais-configure [searchParameters]="searchParams"></ais-configure>
    <mat-form-field class="search-query-control">
      <mat-icon matPrefix class="search-query-icon">search</mat-icon>
      <input matInput type="text" autocomplete="off" placeholder="Document Search" [(ngModel)]="searchParams.query" cdkFocusInitial>
      <button mat-button *ngIf="searchParams.query" matSuffix mat-icon-button aria-label="Clear" (click)="searchParams.query=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="document-results" *ngIf="searchParams.query && searchParams.query !== ''">
      <ais-hits>
        <ng-template let-hits="hits">
          <ol class="document-results-list">
            <li *ngFor="let result of hits" (click)="selectDocumentResult(result)">
              <h4 class="hit-name">
                <ais-highlight attribute="title" [hit]="result"></ais-highlight>
              </h4>
              <p class="hit-description">
                <ais-highlight attribute="text" [hit]="result"></ais-highlight>
              </p>
              <h6 class="excerpt-document-name">{{result.docName}}</h6>
            </li>
          </ol>
        </ng-template>
      </ais-hits>
      <ais-pagination></ais-pagination>
    </div>
  </ais-instantsearch>
</ng-template>