<h4 mat-dialog-title>Global Find and Replace</h4>
<mat-dialog-content>
    <div class="app-loading" *ngIf="currentlyLoading">
        <mat-spinner diameter=100></mat-spinner>
    </div>
    <div *ngIf="!currentlyLoading">
        <!-- Searching -->
        <div>
            <mat-card>
                <form fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="searchForm" autocomplete="off" (ngSubmit)="doSearch()">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="find" placeholder="Find" cdkFocusInitial>
                        <mat-hint>Not case-sensitive</mat-hint>
                    </mat-form-field>
                    <button type="submit" mat-raised-button color="primary">Search</button>
                </form>
                <mat-card-subtitle style="margin-top:1em;">
                    <ng-container *ngIf="!indexQuery">Type a term to search across all EVA Knowledge documents</ng-container>
                    <ng-container *ngIf="totalHits && indexQuery">Found {{ indexQuery }} in {{ totalHits }} documents (Page {{ page+1 }} of {{ totalPages }}) | <a [routerLink]="[]" (click)="selectAll()">Select page</a></ng-container>
                    <ng-container *ngIf="!totalHits && indexQuery">No results found for {{ indexQuery }}</ng-container>
                </mat-card-subtitle>
            </mat-card>
            <ng-container>
                <app-generic-search
                    cardColumns="1"
                    [indexName]="algoliaIndex"
                    [tokenType]="algoliaTokenType"
                    searchResultTitleProperty="docName"
                    [searchResultsDistinct]="false"
                    [propertyNameMapping]="propertyNameMapping"
                    [hideViewToggle]="true"
                    [customSearchParams]="{advancedSyntax: true, advancedSyntaxFeatures: ['exactPhrase'], typoTolerance: false, hitsPerPage: 100}"
                    [customSearchQueryObservable]="findQuery$"
                    (searchResultsChange)="resultsChanged($event)">
                    <ng-template #cardTemplate let-hit>
                        <mat-checkbox matRipple class="extra-padding" [(ngModel)]="selectedDocs[hit.docId]" (change)="calcSelected()">
                            {{ hit.docName }}
                        </mat-checkbox>
                    </ng-template>
                </app-generic-search>
            </ng-container>
        </div>
    </div>
    <br/>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" mat-dialog-close (click)="startEditingDocuments()" [disabled]="!selectedHits">Edit Documents ({{ selectedHits }} selected)</button>
</mat-dialog-actions>
