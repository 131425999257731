import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidation {

  static MatchPassword(input1: string, input2: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // const forbidden = nameRe.test(control.value);
      const password = control.get(input1).value; // to get value in input tag
      const confirmPassword = control.get(input2).value;
      if (password !== confirmPassword) {
        control.get(input2).setErrors({ MatchPassword: true });
      } else {
        return null;
      }
    };
  }
}
