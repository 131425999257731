
    <div *ngIf="_isAdministrator">
        <button mat-button color="primary" class="add-document-button" (click)="addDocument()">New Document</button>
    </div>
    <!-- <mat-form-field class="filter-field">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field> -->
    <div class="document-table">
        <!-- [paginator] -->
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-width">

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="marginright10"></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="_isAdministrator">
              <span class="mobile-label">Actions: </span>
              <button class="rightMargin30" (click)='editDocument(element)' matTooltip="Edit Document"><i class="far fa-edit"></i></button>
            </div>
          </td>
        </ng-container>
        <!-- Document Name Column -->
        <ng-container matColumnDef="documentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="document-header"> Document</th>
          <td mat-cell *matCellDef="let element"><a target="_blank" href="{{element.url}}">{{element.documentName}}</a></td>
        </ng-container>

        <!-- Document Url Column -->
        <!-- <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Url </th>
          <td mat-cell *matCellDef="let element"> <a target="_blank" href="{{element.url}}">{{element.url}}</a>  </td>
        </ng-container> -->

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>

        <!-- Date Uploaded Column -->
        <ng-container matColumnDef="dateUploaded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Uploaded </th>
          <td mat-cell *matCellDef="let element"> {{element.dateUploaded | date:shortDate }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
