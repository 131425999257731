import { Injectable } from '@angular/core';
import { UserDirectoryDocument } from '@eva-model/user/userDirectory';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import firebase from 'firebase/compat/app';
import { NotificationDocument } from '@eva-model/notifications/notifications';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

/**
 * This service is used to create notifications to users in the EVA application.
 */
@Injectable({
  providedIn: 'root'
})
export class CreateNotificationsService {

  constructor(
    private _firestoreService: FirestoreService,
    private perf: AngularFirePerformance
  ) { }

  /**
   * This creates a notification to an eva user based on an email address.
   *
   * @param publicKey the public key to send a message to.
   * @param message message to include to the user.
   * @param url an optional url to include. This is only for the paths past the root EVA urls.
   * @return {Promise<firebase.firestore.DocumentReference>} a promise of the document reference.
   */
  async createNotificationWithPublicKey(publicKey: string, message: string, url?: string): Promise<firebase.firestore.DocumentReference> {
    // get the users email address.
    try {
      const userDoc: UserDirectoryDocument[] = await this._firestoreService.colWithIds$<UserDirectoryDocument[]>(`UserDirectory`, ref => {
        ref.where('publicKey', '==', publicKey).limit(1);
      }).pipe(
        trace('create-notification-createNotificationWithPublicKey')
      ).toPromise();

      // make sure this exists.
      if (userDoc && userDoc.length === 1) {
        const notificationRef = this._firestoreService.col<Notification>('users/' + userDoc[0].uid + '/Notifications');
        const notificationDoc: NotificationDocument = { message: message, dateUpdated: Date.now() };
        if (url) {
          notificationDoc.url = url;
        }
        return this._firestoreService.add(notificationRef, notificationDoc);
      } else {
        return Promise.reject('No document found.');
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This creates a notification to an eva user based on an email address.
   *
   * @param email email address of the EVA user.
   * @param message message to include to the user.
   * @param url an optional url to include. This is only for the paths past the root EVA urls.
   * @return {Promise<firebase.firestore.DocumentReference>} a promise of the document reference.
   */
  async createNotificationWithEmail(email: string, message: string, url?: string): Promise<firebase.firestore.DocumentReference> {
    // get the users email address.
    try {
      const userDoc: UserDirectoryDocument = await this._firestoreService.doc$<UserDirectoryDocument>(`UserDirectory/${email}`).toPromise();
      // make sure this exists.
      if (userDoc) {
        const notificationRef = this._firestoreService.col<Notification>('users/' + userDoc.uid + '/Notifications');
        const notificationDoc: NotificationDocument = { message: message, dateUpdated: Date.now() };
        if (url) {
          notificationDoc.url = url;
        }
        return this._firestoreService.add(notificationRef, notificationDoc);
      } else {
        return Promise.reject('No document found.');
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function creates a notificaiton in the database and places it under the user path.
   *
   * @param uid the user ID to receive the notification
   * @param message message to include for the user.
   * @param url an optional url to include. This is only for the paths past the root EVA urls.
   * @return {Promise<firebase.firestore.DocumentReference>} the promise of a document creation.
   */
  createNotificationByUserId(uid: string, message: string, url?: string): Promise<firebase.firestore.DocumentReference> {
    const notificationDoc: NotificationDocument = { message: message, dateUpdated: Date.now() };
    if (url) {
      notificationDoc.url = url;
    }

    const notificationRef = this._firestoreService.col<Notification>('users/' + uid + '/Notifications');
    return this._firestoreService.add(notificationRef, notificationDoc);
  }
}
