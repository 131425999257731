<app-page-header [title]="componentTitle" subTitle="Process Logs of what is happening in the EVA system"></app-page-header>

<div>
  <form [formGroup]="prcsIssueReportFormGrp">
    <div class="form-container">
      <div class="date-filter-container">
        <mat-form-field class="fullWidth">
          <input matInput class="fullWidth" type="datetime-local" placeholder="From (Date time)"
            (change)="onFromDateTimeChange($event)" formControlName="fromDateTime" value="">
        </mat-form-field>
      </div>

      <div class="date-filter-container">
        <mat-form-field class="fullWidth">
          <input matInput class="fullWidth" type="datetime-local" placeholder="To (Date time)"
            (change)="onToDateTimeChange($event)" formControlName="toDateTime" value="">
        </mat-form-field>
      </div>

      <div>
        <button mat-raised-button *ngIf="!isWaiting" class="get-process-report-button" color="primary" (click)="getProcessReport()"
        [disabled]="!this.toDateTime || !this.fromDateTime">
          <i class="material-icons">bug_report</i>
          Process Issues Report
        </button>
      </div>

    </div>
  </form>

  <div class="app-loading" *ngIf="isWaiting">
    <mat-spinner></mat-spinner>
    <ng-content></ng-content>
    <h6>{{ onWaitMessage }}</h6>
  </div>

  <ag-grid-angular *ngIf="!isWaiting" #prcsReportGrid class="ag-theme-dark ag-grid-container"
    [gridOptions]="prcsReportGridOptions" [rowData]="prcsReportData" rowSelection="multiple"
    (gridReady)="prcsReportGridReady()" (modelUpdated)="prcsReportGridModelUpdated()"
    (rowGroupOpened)="prcsReportGridRowGroupOpened()" (RowDataChanged)="prcsReportGridRowDataChanged()"
    (firstDataRendered)="prcsReportGridFirstDataRendered()">
  </ag-grid-angular>

  <div *ngIf="isWaiting">
    <br /><br /><br /><br /><br /><br />
  </div>

  <!-- <br/>&nbsp;&nbsp;
            <button mat-raised-button color="primary" (click)="actionOnSelectedRowsConfirm()">
              <i class="material-icons">delete_forever</i>
              Process action 
            </button>
            <br/> -->

  <!-- <p-growl [(value)]="growl_msgs" life = "7000"></p-growl> -->
</div>