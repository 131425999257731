import { AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { finalize, tap, map, catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActiveUploadItem } from '@eva-services/storage/storage.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  currentlyUploading$: Observable<boolean>;
  activeUpload$: Observable<ActiveUploadItem[]>;

  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.currentlyUploading$ = this.data.currentlyUploading.pipe(
      tap(data => {
        // console.log('currentlyUploading', data);
        if (!data) {
          // console.log('closing dialog...');
          setTimeout(() => {
            this.dialogRef.close();
          }, 3000);
        }
      })
    );
    this.activeUpload$ = this.data.activeUploads.pipe(
      tap(data => console.log('activeUpload', data))
    );
  }

  isActive(snapshot: UploadTaskSnapshot): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
