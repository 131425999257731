import { FirebaseStorageImageUploadService } from '@eva-services/about/firebase-storage-image-upload.service';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { TeamMemberData, TeamMembers, TeamMemberDeleteResponse } from '@eva-model/releaseNotes';
import { Observable } from 'rxjs';
import { StorageService } from '@eva-services/storage/storage.service';

@Component({
  selector: 'app-add-edit-team-members',
  templateUrl: './add-edit-team-members.component.html',
  styleUrls: ['./add-edit-team-members.component.scss']
})
export class AddEditTeamMembersComponent implements OnInit {

  teamMembersForm: UntypedFormGroup;
  hideInfo: boolean;
  movingMember: number;
  fileDragged: boolean;
  fileList: any = [];
  submitting: boolean;

  constructor(
    public dialogRef: MatDialogRef <AddEditTeamMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _fb: UntypedFormBuilder,
    private storageService: StorageService,
    private imageUploadService: FirebaseStorageImageUploadService
  ) { }

  ngOnInit() {
    // setup the team members form group
    this.teamMembersForm = this._fb.group({
      teamMembers: this._fb.array([]),
    });
    this.hideInfo = false;
    this.fileDragged = false;
    this.submitting = false;
    // make sure information was provided and then setup the form
    if (this.dialogData.extra.data) {
      this.setupTeamMembers(this.dialogData.extra.data);
    }
  }

  /**
   * This sets up the team members into a team member dialog
   *
   * @param teamMembers array of team members being added to the dialog
   */
  setupTeamMembers(teamMembers: TeamMemberData[]) {
    // get the links information
    const teamMemberControl = <UntypedFormArray>this.teamMembersForm.controls.teamMembers;
    // loop each link and add the information to the control.

    teamMembers.forEach(teamMember => {
      this.fileList.push(null);
      teamMemberControl.push(
        this._fb.group({
          name: new UntypedFormControl(teamMember.name, Validators.required),
          position: new UntypedFormControl(teamMember.position, Validators.required),
          description: new UntypedFormControl(teamMember.description, Validators.required),
          contactMethods: new UntypedFormControl(teamMember.contactMethods, Validators.required),
          imageUrl: new UntypedFormControl(teamMember.imageUrl, Validators.required)
        })
      );
    });
  }

  /**
   * Cancel the dialog and return to the main screen.
   */
  onCancelClick(): void {
    // close the dialog
    this.dialogRef.close();
  }

  /**
   * This function adds new team member.
   */
  addTeamMember(): void {
    // get the control
    const control = <UntypedFormArray>this.teamMembersForm.controls.teamMembers;
    // add in the release notes:
    control.insert(0,
      this._fb.group({
            name: new UntypedFormControl('', Validators.required),
            position: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl('', Validators.required),
            contactMethods: new UntypedFormControl('', Validators.required),
            imageUrl: new UntypedFormControl('', Validators.required)
          })
    );
    this.fileList.splice(0, 0, null);
  }

  /**
   * This function removes the team member.
   *
   * @param index Index of the team member being removed
   */
  deleteTeamMember(index: number): void {
    const control = <UntypedFormArray>this.teamMembersForm.controls.teamMembers;
    control.removeAt(index);
    this.fileList.splice(index, 1);
  }

  /**
   * This function submits the changes to firebase.
   */
  async finalizeTeamMembers(): Promise<void> {
    this.submitting = true;
    // check that the form is valid.
    if (this.teamMembersForm.valid) {
      const returnTeamMembers: TeamMembers = {
        teamMembers: []
      };
      const allChangedTeamMembers: TeamMembers = this.teamMembersForm.value;
      let index = 0;

      if (allChangedTeamMembers.teamMembers.length === 0) {
        this.dialogRef.close(returnTeamMembers);
      }

      const responseArray = await this.deleteRemovedTeamMembersImage(allChangedTeamMembers.teamMembers);
      // add the deleted team members back if their image delete fails
      responseArray.forEach(response => {
        allChangedTeamMembers.teamMembers.push(response.teamMemberData);
      });

      for (const tm of allChangedTeamMembers.teamMembers) {

        let url: string = tm.imageUrl;

        // if the team member does not have an image url already
        if (!url || url === ' ') {
          // wait for the url from firebase storage, if new image not added for upload then returns null
          url = await this.storageService.uploadFileAndGetURL(
            `/images/about/developers${this.fileList[index].name}`, this.fileList[index]
          );
        }

        // add the team member to the array with updated image url
        returnTeamMembers.teamMembers.push({
          name: tm.name,
          position: tm.position,
          description: tm.description,
          contactMethods: tm.contactMethods,
          imageUrl: url
        });
        // if all the team members have been updated, update firestore
        if (returnTeamMembers.teamMembers.length === allChangedTeamMembers.teamMembers.length) {
          this.dialogRef.close(returnTeamMembers);
        }

        index++;
      }
      this.submitting = false;
      return;
    } else {
      this.submitting = false;
      return;
    }
  }

  /**
   * This function deletes the image for removed team members from firebase storage
   * @param teamMembers Updated list of team members being uploaded to firestore
   */
  async deleteRemovedTeamMembersImage(teamMembers: TeamMemberData[]): Promise<TeamMemberDeleteResponse[]> {
    let storedTeamMembers: TeamMemberData[];
    // get old list of team members before update and find removed team members

    storedTeamMembers = this.dialogData.extra.data;
    const deletedTeamMembers: TeamMemberData[] = storedTeamMembers.filter(storedMember => {
      for (const member of teamMembers) {
        // imageUrl is unique
        if (member.imageUrl === storedMember.imageUrl) {
          return false;
        }
      }
      return true;
    });

    const responseArray: TeamMemberDeleteResponse[] = [];
    // delete images for all removed team members
    deletedTeamMembers.forEach(async member => {
      const memberResponse: TeamMemberDeleteResponse = {
        teamMemberData: null,
        deleteStorageResponse: null
      };
      memberResponse.deleteStorageResponse = await this.imageUploadService.deleteImage(member.imageUrl);
      memberResponse.teamMemberData = member;
      if (!memberResponse.deleteStorageResponse.successful) {
        responseArray.push(memberResponse);
      }
    });

    return responseArray;
  }

  /**
   * This function acts as a switch for accordion
   */
  hideMemberInfo(): void {
    this.hideInfo = !this.hideInfo;
  }

  /**
   * This function re-orders the team members on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the team member being switched with the team member being dragged
   */
  reorderMembers(event: any, index: number): void {
    const control = <UntypedFormArray>this.teamMembersForm.controls.teamMembers;
    const item = control.at(this.movingMember);

    control.removeAt(this.movingMember);
    control.insert(index, item);
    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [ this.fileList[this.movingMember], this.fileList[index] ] = [ this.fileList[index], this.fileList[this.movingMember] ];
  }

  /**
   * This function prevents default action on dragOver event
   * @param event HTML dragOver event
   */
  allowdrop(event: any): void {
    event.preventDefault();
  }

  /**
   * This function stores the index for team member being dragged
   * @param event HTML dragStart event
   * @param index Index of the team member being dragged
   */
  onDrag(event: any, index: number): void {
    this.movingMember = index;
  }

  /**
   * This function stores the file being dropped in the dropzone
   * @param event HTML Input change event
   * @param index Index of the team Member being updated
   */
  onFileChange(event: any, index: number): void {
    const files = event.dataTransfer.files;
    const control = <UntypedFormArray>this.teamMembersForm.controls.teamMembers;
    if (files.length > 0) {
      if (files[0].type.split('/')[0] !== 'image') {
        alert('File type must be Image');
        return;
      }
      this.fileList[index] = files[0];
      const item = control.at(index);
      item.patchValue({imageUrl: ' '});   // this assigns value to the imageUrl field on file drop for validation
      item.updateValueAndValidity();      // this validates the form programmatically
      control.removeAt(index);
      control.insert(index, item);
    }
  }

  /**
   * This function is called when file is dropped in the dropzone
   * @param event HTML drop event
   * @param index Index of the team member being updated
   */
  fileDrop(event: any, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.onFileDragLeave(event);
    this.onFileChange(event, index);
  }

  /**
   * This function is called when file is dragged over the dropzone
   *
   * @param event HTML dragOver event
   */
  onFileDragOver(event: any): void {
    this.fileDragged = true;
  }

  /**
   * This function is called when dragged file leaves the dropzone
   * @param event HTML dragLeave event
   */
  onFileDragLeave(event: any): void {
    this.fileDragged = false;
  }

}
