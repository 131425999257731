import { WorkFlowInteractionCondition } from "./workflowInteractionCondition";

export class WorkFlowInteractionConditionSet {

    constructor(
        public name: string,                                        // name of the condition set
        public conditions: WorkFlowInteractionCondition[],          // list of conditions in the set
        public connectionOperator?: string,                         // connection operator
        public order?: number,                                      // order of the condition set
        public hovered?: boolean                                    // whether condition set is hovered or not
    ) { }

}
