<div class="administration-overview">
  <app-page-header [title]="componentTitle" subTitle="This section allows the user to administrate the EVA Environment."></app-page-header>

  <div class="admin-overview-container">
    <div class="width-30">
      <img src="../../../assets/images/Unique-Protection.png" alt="Workflow-Card-Image" class="image-width">
    </div>
    <div class="width-55">
        <button mat-button appHoldable [disabled]="versionUpdateDisabled" color="danger"
          (holdTime)="updateVersion($event)" matTooltip="Hold to execute" matTooltipClass="adminTooltip">Update EVA
          Version</button><br />
        <button mat-button appHoldable color="danger" (holdTime)="updateAllRecords($event)"
          matTooltip="Hold to execute" matTooltipClass="adminTooltip">Update All Records</button>
    </div>
    <div class="width-15"></div>
  </div>
</div>