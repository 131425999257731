import { Component, OnInit, Inject } from '@angular/core';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AboutPageFAQs } from '@eva-model/releaseNotes';
@Component({
  selector: 'app-add-edit-faqs',
  templateUrl: './add-edit-faqs.component.html',
  styleUrls: ['./add-edit-faqs.component.scss']
})
export class AddEditFAQsComponent implements OnInit {

  FAQForm: UntypedFormGroup;
  addUpdate = false;
  id: string; // id

  constructor(
    public dialogRef: MatDialogRef <AddEditFAQsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    // setup the form group.
    this.FAQForm = this._fb.group(
      {
        'title': ['', [
          Validators.required
        ]],
        'description': ['', [
          Validators.required
        ]]
      });

    // if there was data passed, setup the information for an edit.
    if (this.dialogData.extra.data) {
      const inputData = this.dialogData.extra.data;
      this.id = inputData.id;
      this.FAQForm.setValue({
        title: inputData.title,
        description: inputData.description
      });
    }
  }
  /**
   * Close the dialog and return no data.
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Ensure the form is valid and then return the add or edit information.
   */
  addEditFAQs() {
    if (this.FAQForm.valid) { // filled in form to send to user outside system
      this.addUpdate = true;
      const documentData: AboutPageFAQs = {
        title: this.FAQForm.get('title').value,
        description: this.FAQForm.get('description').value,
        id: this.id
      };

      this.addUpdate = false;
      this.dialogRef.close(documentData);
    } else {
      this.dialogRef.close(null);
    }
  }

}
