import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { BehaviorSubject, iif, interval, Observable, Subscription } from 'rxjs';

const TOKEN_ENDPOINT = `${environment.firebaseFunction.endpoint}/generateAlgoliaKey`;
const TOKEN_OPTIONS = {headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

@Injectable({
  providedIn: 'root'
})
export class SearchAuthService {
  private storedTokens: BehaviorSubject<{[type in AlgoliaTokenType]?: AlgoliaSearchToken}> = new BehaviorSubject({});
  // memory subject that keeps tokens sorted by tokenType
  private tokenSubjects: {[type in AlgoliaTokenType]?: BehaviorSubject<AlgoliaSearchToken>} = {};

  constructor(private http: HttpClient) {}

  /**
   * Returns an observable with token information for the request type of token
   * If the token exists in the store, returns that token if valid.
   * If the token is not valid, it will fetch token, add to store and then return that token.
   * This function also sets up a timer to fetch a new token when the existing token expires.
   *
   * @param tokenType The token type to get
   */
  public getSearchToken(tokenType: AlgoliaTokenType): Observable<AlgoliaSearchToken> {
    const fetchNewToken$ = this.fetchToken(tokenType).pipe(
      tap((tokenData) => {
        this.storeToken(tokenData);
      }),
      switchMap(() => {
        return this.tokenSubjects[tokenType]?.asObservable();
      })
    );

    return iif(() => this.isTokenValid(tokenType), this.tokenSubjects[tokenType]?.asObservable(), fetchNewToken$).pipe(take(1));
  }

  // clear and halt everything
  public clearTokens(): void {
    this.storedTokens.next({});
    this.tokenSubjects = {};
  }

  // private api request
  private fetchToken(tokenType: AlgoliaTokenType): Observable<AlgoliaSearchToken> {
    return this.http.post<AlgoliaSearchToken>(TOKEN_ENDPOINT, { tokenType }, TOKEN_OPTIONS).pipe(
      take(1)
    );
  }

  // store a new token from an api request
  private storeToken(tokenData: AlgoliaSearchToken): void {
    const current = this.storedTokens.getValue();   // current storedTokens subject value
    const next = Object.assign({}, current);        // upcoming storedTokens value to be 'next' into subject
    next[tokenData.tokenType] = tokenData;
    // update token store
    this.storedTokens.next(next);
    // update individual token type subjects
    if (!this.tokenSubjects[tokenData.tokenType]) {
      // create individual token subject
      this.tokenSubjects[tokenData.tokenType] = new BehaviorSubject(tokenData);
    } else {
      // update individual token subject
      this.tokenSubjects[tokenData.tokenType].next(tokenData);
    }
  }

  // Checks if the token (a) exists in cache and (b) whether the expiry time is not in the past
  private isTokenValid(tokenType: AlgoliaTokenType): boolean {
    const tokenStore = this.storedTokens.getValue();
    // check for existince of token in store
    if (!tokenStore[tokenType]) {
      return false;
    }
    // token exists, check is valid
    if (tokenStore[tokenType].validUntil > Date.now()) {
      return true;
    }
    // token did not pass checks
    return false;
  }
}
