import { Injectable } from '@angular/core';
import { FormScreen  } from "@eva-model/interaction/dynamicForm";
import { InteractionFlatModel } from '@eva-model/interactionFlatModel';
import { IfThenLogicOptions, elementTypes, MathEquationOperators, LogicOption,
  IfThenLogicAction, Relation, SubRelation } from '@eva-model/interactionElementRelationDialogModel';
import { DynamicFormControlModel, DynamicFormService, DynamicInputModel,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from '@ng-dynamic-forms/core';
import { FRM_CNTRL_INPUT, FRM_CNTRL_DATETIME, FRM_CNTRL_ADDITIONAL, FRM_CNTRL_CUSTOM, FrmCntrl } from '@eva-model/formControls';
import * as _ from 'lodash';
import {
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX as Checkbox,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP as RadioGroup,
  DYNAMIC_FORM_CONTROL_TYPE_SELECT as Select,
  DYNAMIC_FORM_CONTROL_TYPE_SLIDER as Slider,
  DYNAMIC_FORM_CONTROL_TYPE_SWITCH as Switch,
  DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA as Textarea,

  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT as Text,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE as InputDate,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL as InputDateTimeLocal,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL as Email,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH as Month,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER as InputNumber,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD as Password,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH as Search,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL as Tel,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME as Time,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL as URL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK as Week
} from '@ng-dynamic-forms/core';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { UntypedFormGroup } from '@angular/forms';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import { UserFriendlyConditionPipe } from '@eva-ui/form-builder/interaction-element-relation/pipes/user-friendly-condition.pipe';
import { Subscription } from 'rxjs';
import { Process } from '@eva-model/process/process';
import { WorkFlowCondition } from '@eva-model/workflow';
const Chips = 'CHIPS';
const CheckboxGroup = 'CHECKBOX_GROUP';

@Injectable({
  providedIn: 'root'
})
export class ConditionalObjectCheckerService {

  formControlElementTypes: FrmCntrl[];

  constructor(
    private formBuilderService: FormBuilderService,
    private userFriendlyConditionPipe: UserFriendlyConditionPipe,
    private ngDynFormService: DynamicFormService) {
    this.formControlElementTypes = [
      ...FRM_CNTRL_INPUT,
      ...FRM_CNTRL_DATETIME,
      ...FRM_CNTRL_ADDITIONAL,
      ...FRM_CNTRL_CUSTOM];
  }

/**
 * this function is to check element relations and look for a matching relationship to enforce.
 * it exists because the ng-dynamic-forms library does not check objects when enforcing conditions
 * this function is called in the form visualizer component in flatInteractionValueChange and in
 * ngAfterViewInit if returning from a process edit
 *
 * @param formScreens a list of form screen in the interaction
 * @param flatInteraction an array of all elements in the interaction
 * @param formScreen the screen containing the elements
 * @param formGroup form group containing all the elements
 */
  checkElementRelations(
    flatInteraction: InteractionFlatModel,
    formScreen: FormScreen,
    formScreens: FormScreen[],
    formGroups: UntypedFormGroup[]): { valid: boolean, invalidMessage: string } {

    const relationsArray: {element: any, relation: Relation[]}[] = [];
    let isRelationValid: boolean;
    // let actionToTake: string;

    const allFormElements: DynamicInputModel[] = [].concat.apply([], formScreens.map(screen => screen.FormElements));

    // list of all conditions in each form element. Will need to check this array for id of changed element if element is multi-select
    allFormElements.forEach(element => {
      if (element['additional']
        && element['additional'].relation
        && element['additional'].relation.length > 0) {
        (<Relation[]>element['additional'].relation).forEach(relation => {
          const existingRelation = relationsArray.find(combinedRelation => combinedRelation.element.id === element.id);
          if (existingRelation) {
            if (relation.subRelation[0].ifConnective) {
              existingRelation.relation[existingRelation.relation.length - 1].subRelation.push(
                JSON.parse(JSON.stringify(relation.subRelation[0])));
              existingRelation.relation[existingRelation.relation.length - 1].id.push(relation.id[0]);
            } else {
              existingRelation.relation.push(JSON.parse(JSON.stringify(relation)));
            }
          } else {
            relationsArray.push({element: element, relation: [JSON.parse(JSON.stringify(relation))]});
          }
        });
      }
    });

    // let disableOrEnableThisID;
    const isConditionValid: {
      connective?: "AND" | "OR",
      valid: boolean,
      ifConnective: boolean,
      thenAction: IfThenLogicAction,
      id: string,
      ifNotValid: boolean,
      subRelation: SubRelation
    }[] = [];
    let currentRelationConnective: "AND" | "OR";
    let currentIfRelationConnective: "AND" | "OR";
    let relationArrayIndex = 0;
    for (const combinedRelation of relationsArray) {
      // for every relation on page
      let relationIndex = 0;
      for (const relation of combinedRelation.relation) {
        if (relationArrayIndex > 0 || relationIndex > 0) {
          if (combinedRelation.relation[relationIndex].connective) {
            currentRelationConnective = combinedRelation.relation[relationIndex].connective;
          }
        }
        let subRelationIndex = 0;
        let enableOpposite = false;
        for (const subRelation of relation.subRelation) {
          if (subRelationIndex < (relation.subRelation.length - 1)) {
            if (relation.subRelation[subRelationIndex + 1].ifConnective) {
              currentIfRelationConnective = relation.subRelation[subRelationIndex + 1].ifConnective;
            }
          }
          enableOpposite = relation.subRelation[0].then.enableOpposite;
          // check all values within the condition, check against flat interaction values for ease, if any fail condition is not met
          for (const flatElement of flatInteraction.elements) {
            if (relation.id[subRelationIndex] === flatElement.originalId) {
              let previousRelation;
              for (let index = isConditionValid.length - 1; index >= 0; index--) {
                if (isConditionValid[index].ifConnective) {
                  previousRelation = isConditionValid[index].subRelation;
                } else {
                  index = -1;
                }
              }
              const validState = this.checkIfConditionIsMet(subRelation, flatElement, formScreen,
                relationsArray[relationArrayIndex].element,
                formGroups, currentIfRelationConnective, isConditionValid[isConditionValid.length - 1]?.valid,
                isConditionValid[isConditionValid.length - 1]?.ifConnective
                ? isConditionValid[isConditionValid.length - 1]?.connective
                : undefined, enableOpposite, isConditionValid[isConditionValid.length - 1]?.ifConnective
                ? previousRelation
                : undefined, flatInteraction.elements, relation, currentRelationConnective,
                isConditionValid[isConditionValid.length - 1]?.ifNotValid, subRelationIndex);
              isConditionValid.push({
                connective: currentIfRelationConnective ? currentIfRelationConnective : currentRelationConnective,
                valid: validState.ifValid || currentIfRelationConnective === 'AND' ? validState.thenValid : true,
                ifConnective: currentIfRelationConnective ? true : false,
                thenAction: subRelation.then.action,
                id: relation.id[subRelationIndex],
                ifNotValid: !validState.ifValid,
                subRelation
              });
            }
          }
          subRelationIndex++;
          currentIfRelationConnective = undefined;
          enableOpposite = false;
        }
        relationIndex++;
        currentRelationConnective = undefined;
      }
      relationArrayIndex++;
    }
    let invalidMessage: string;
    for (let index = 0; index < isConditionValid.length; index++) {
      const condition = isConditionValid[index];
      if (condition.thenAction === IfThenLogicAction.MustBe) {
        if (typeof isRelationValid === 'undefined') {
          isRelationValid = !condition.ifNotValid ? condition.valid : true;
        }
        if (condition.connective) {
          if (condition.ifConnective) {
            let previousCondition = condition;
            let nextConditionIndex = index + 1;
            while (nextConditionIndex < isConditionValid.length) {
              const nextCondition = isConditionValid[nextConditionIndex];
              index++;
              let ifValid = !previousCondition.ifNotValid;
              const thenValid = previousCondition.valid && nextCondition.valid;
              if (previousCondition.connective === 'AND') {
                ifValid = ifValid && !nextCondition.ifNotValid;
              } else if (previousCondition.connective === 'OR') {
                ifValid = ifValid || !nextCondition.ifNotValid;
              }
              isRelationValid = isRelationValid && (ifValid
                ? thenValid
                : true);
              if (!nextCondition.ifConnective) {
                break;
              }
              previousCondition = nextCondition;
              nextConditionIndex++;
            }
          } else {
            // need to switch the logic between AND and OR - AND should perform ||, OR should perform &&
            // this needs communication with existing processes to make sure no processes will be affected with this change
            if (condition.connective === 'AND') {
              isRelationValid = isRelationValid && (!condition.ifNotValid ? condition.valid : true);
            } else if (condition.connective === 'OR') {
              isRelationValid = isRelationValid || (!condition.ifNotValid ? condition.valid : false);
            }
          }
        } else {
          // independent condition
          isRelationValid = isRelationValid && (!condition.ifNotValid ? condition.valid : true);
        }
        if (!isRelationValid) {
          const relationControls = [];
          for (const screen of formScreens) {
            screen.FormElements.forEach(formElement => {
              this.getRelationControls(formScreens, formElement, relationControls);
            });
          }

          invalidMessage = this.userFriendlyConditionPipe.transform(relationControls, formScreens);
        }
      }
      // if (condition.ifConnective && condition.connective === 'AND' && (!condition.valid || condition.ifNotValid)) {
      //   isConditionValid[index + 1].valid = condition.valid;
      // }
      // if (condition.thenAction === IfThenLogicAction.MustBe
      //   || (index > 0 && isConditionValid[index - 1].ifConnective
      //     ? isConditionValid[index - 1].thenAction === IfThenLogicAction.MustBe
      //     : false)) {
      //   if (!condition.ifConnective && condition.connective === 'AND') {
      //     if (typeof isRelationValid === 'undefined') {
      //       isRelationValid = condition.valid;
      //     } else {
      //       isRelationValid = (!condition.ifNotValid ? condition.valid : true) && isRelationValid;
      //     }
      //   } else if (!condition.ifConnective && condition.connective === 'OR') {
      //     if (typeof isRelationValid === 'undefined') {
      //       isRelationValid = condition.valid;
      //     } else {
      //       isRelationValid = (!condition.ifNotValid ? condition.valid : true) || isRelationValid;
      //     }
      //   } else {
      //     if (condition.ifConnective && condition.connective === 'OR') {
      //       isRelationValid = condition.valid;
      //     } else if (condition.ifConnective && condition.connective === 'AND') {
      //       isRelationValid = true;
      //     }
      //     if (!condition.ifNotValid) {
      //       isRelationValid = condition.valid;
      //     }
      //     if (index > 0
      //       && isConditionValid[index - 1].ifConnective
      //       && isConditionValid[index - 1].connective !== 'AND'
      //       && !isConditionValid[index - 1].ifNotValid) {
      //         isRelationValid = isConditionValid[index - 1].valid;
      //       }
      //     if (index > 0 && isConditionValid[index - 1].ifConnective) {
      //       if (isConditionValid[index - 1].connective === 'AND') {
      //         isRelationValid = (!condition.ifNotValid ? condition.valid : true) || isRelationValid;
      //       } else if (isConditionValid[index - 1].connective === 'OR') {
      //         isRelationValid = (!condition.ifNotValid ? condition.valid : false) && isRelationValid;
      //       } else {

      //       }
      //     }
      //   }
      //   if (!condition.valid) {
      //     const relationControls = [];
      //     for (const screen of formScreens) {
      //       screen.FormElements.forEach(formElement => {
      //         this.getRelationControls(formScreens, formElement, relationControls);
      //       });
      //     }

      //     invalidMessage = this.userFriendlyConditionPipe.transform(relationControls, formScreens);
      //   }
      // }
    }
    return { valid: isRelationValid ?? true, invalidMessage: invalidMessage };
  }

  getRelationControls(formScreens: any[], formElement: EvaDynamicFormControlModel, relationControls: any[],
    valueChanges?: Subscription, emitElementRelationChange?: Function, disableDynamicFormElements?: Function) {
    let screenIndex = -1;
    for (const screen of formScreens) {
      screenIndex++;
      const elements: EvaDynamicFormControlModel[] = formScreens[screenIndex].FormElements;

      formElement['additional'].relation.forEach( relationObj => {
        const relationConditionElementModel: EvaDynamicFormControlModel[] = [];
        elements.forEach(element => {
          relationObj.id.forEach(id => {
            if (element.originalId === id) {
              relationConditionElementModel.push(element);
            }
          });
        });
        if (relationConditionElementModel.length === 0) {
          return;
        }
        const relationElementModel: {
          relation: Relation[],
          originalId: string[]
        } = { relation: [], originalId: relationObj.id };

        if ( relationElementModel ) {
          relationElementModel.relation = [JSON.parse(JSON.stringify(relationObj))];
          relationElementModel.relation[0].subRelation.forEach((item, subRelationIndex) => {
            item.if.model = this.formBuilderService.createDynamicFormControlModel(relationConditionElementModel[subRelationIndex]);
            item.then.model = this.formBuilderService.createDynamicFormControlModel(formElement);

            const newFormGroup = [];
            newFormGroup.push(item.if.model);
            newFormGroup.push(item.then.model);
            item.formGroup = this.ngDynFormService.createFormGroup(newFormGroup);

            elements.forEach(element => {
              if (item.if.model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                if (element.originalId === item.if.model.originalId) {
                  item.if.model.group.forEach(groupControl => {
                    const groupElement = element.group.find(elementGroupControl =>
                      elementGroupControl.label === groupControl.label);
                    if (groupElement && item.if.value) {
                      item.if.value[groupControl.id] = item.if.value[groupElement.label];
                      delete item.if.value[groupElement.label];
                    }
                  });
                }
              }
              if (item.then.model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                if (element.originalId === item.then.model.originalId) {
                  item.then.model.group.forEach(groupControl => {
                    const groupElement = element.group.find(elementGroupControl =>
                      elementGroupControl.label === groupControl.label);
                    if (groupElement && item.then.value) {
                      item.then.value[groupControl.id] = item.then.value[groupElement.label];
                      delete item.then.value[groupElement.label];
                    }
                  });
                }
              }
            });

            if (item.if.value) {
              item.formGroup.controls[item.if.model.id].setValue(item.if.value);
            }
            if (item.then.value) {
              item.formGroup.controls[item.then.model.id].setValue(item.then.value);
            }

            if (valueChanges) {
              valueChanges.add(item.formGroup.valueChanges.subscribe(value => {
                item.if.value = value[item.if.model.id];
                item.then.value = value[item.then.model.id];
                relationControls = [...relationControls];
                emitElementRelationChange();
              }));
            }
            if (disableDynamicFormElements) {
              setTimeout(() => {
                disableDynamicFormElements(item);
              }, 100);
            }
          });
          relationElementModel.relation[0].ifControl = relationConditionElementModel;
          relationElementModel.relation[0].thenControl = [formElement];
          relationControls.push(relationElementModel);
        }
      });
    }
  }

  /**
   * This function returns the logic options being for the input type of the element
   *
   * @param element Element being checked
   * @param preExistingRelationship existing relationship on the element object
   * @param rowIndex Index of the row in the dialog view
   */
  getLogicOptions(relationElement: DynamicFormControlModel, preExistingRelationship?: Object, rowIndex?: number,
    type?: 'if' | 'then', control?: any): LogicOption[] {
    // logic options available to all controls
    const logicOptions = [
      new LogicOption(IfThenLogicOptions.IsEqualTo),
      new LogicOption(IfThenLogicOptions.IsNotEqualTo),
      // new LogicOption(IfThenLogicOptions.XasDefault),
      new LogicOption(IfThenLogicOptions.IsEmpty),
      new LogicOption(IfThenLogicOptions.IsNotEmpty)
    ];

    // logic options available to select controls
    switch (relationElement['inputType']) {
      case InputNumber:
        logicOptions.push(new LogicOption(IfThenLogicOptions.StartsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.EndsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.GreaterThan));
        logicOptions.push(new LogicOption(IfThenLogicOptions.LessThan));
        logicOptions.push(new LogicOption(IfThenLogicOptions.GreaterThanOrEqualTo));
        logicOptions.push(new LogicOption(IfThenLogicOptions.LessThanOrEqualTo));
        logicOptions.push(new LogicOption(IfThenLogicOptions.IsBetween));
        logicOptions.push(new LogicOption(IfThenLogicOptions.IsNotBetween));
        if (control.inputType === InputNumber || control.inputType === Text) {
         logicOptions.push(new LogicOption(IfThenLogicOptions.MathEquation));
        }
        break;
      case InputDate:
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsToday));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsAfterToday));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsBeforeToday));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrAfterToday));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrBeforeToday));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsBeforeX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrBeforeX));
        break;
      case InputDateTimeLocal:
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsNow));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsAfterNow));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsBeforeNow));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrAfterNow));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrBeforeNow));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsBeforeX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.DateIsEqualToOrBeforeX));
        break;
      case Week:
        logicOptions.push(new LogicOption(IfThenLogicOptions.IsCurrentWeek));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsAfterCurrentWeek));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsBeforeCurrentWeek));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsBeforeX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsEqualToOrAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.WeekIsEqualToOrBeforeX));
        break;
      case Month:
        logicOptions.push(new LogicOption(IfThenLogicOptions.IsCurrentMonth));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsAfterCurrentMonth));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsBeforeCurrentMonth));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsBeforeX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsEqualToOrAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.MonthIsEqualToOrBeforeX));
        break;
      case Text:
      case Email:
      case Tel:
      case Password:
      case Search:
      case URL:
        logicOptions.push(new LogicOption(IfThenLogicOptions.StartsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.EndsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TextDoesNotContain));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TextContains));
        if ((control.inputType === InputNumber || control.inputType === Text) && relationElement['inputType'] === Text) {
          logicOptions.push(new LogicOption(IfThenLogicOptions.MathEquation));
         }
        break;
      case Time:
        logicOptions.push(new LogicOption(IfThenLogicOptions.IsCurrentTime));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsAfterCurrentTime));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsBeforeCurrentTime));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsBeforeX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsEqualToOrAfterX));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TimeIsEqualToOrBeforeX));
        break;
      default:
        break;
    }

    switch (relationElement['type']) {
      case Textarea:
        logicOptions.push(new LogicOption(IfThenLogicOptions.StartsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.EndsWith));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TextDoesNotContain));
        logicOptions.push(new LogicOption(IfThenLogicOptions.TextContains));
        break;
      default:
        break;
    }

    return logicOptions;
  }

  getLogicActions(): LogicOption[] {
    return Object.keys(IfThenLogicAction).map(key => new LogicOption(IfThenLogicAction[key]));
  }

  getMathEquationOperators(): LogicOption[] {
    return Object.keys(MathEquationOperators).map(key => new LogicOption(MathEquationOperators[key]));
  }

  /**
   * This function checks if the relation condition is met by comparing user input with relation value
   *
   * @param relation Relation object containing the relation value
   * @param flatElement Form Element containing user input
   * @param screen Form screen containing the flat element
   * @param element form element containing the relation object
   * @param formGroups form groups containing all the elements
   */
  checkIfConditionIsMet(subRelation: SubRelation, flatElement: any, screen: any, element: EvaDynamicFormControlModel,
    formGroups: UntypedFormGroup[], currentIfRelationConnective: 'AND' | 'OR', isPreviousConditionValid: boolean,
    previousIfConnective: 'AND' | 'OR', enableOpposite: boolean, previousRelation: SubRelation, flatInteractionElements: any[],
    relation: Relation, currentRelationConnective: 'AND' | 'OR', isPreviousIfConditionNotValid: boolean, subRelationIndex: number)
      : { ifValid: boolean, thenValid: boolean } {
    let logicOption: IfThenLogicOptions;
    const validState = {
      ifValid: false,
      thenValid: false
    };
    // if logic option exists, use that option otherwise use "is equal to" for existing conditions
    if (subRelation.if.option) {
      logicOption = subRelation.if.option;
    } else {
      logicOption = IfThenLogicOptions.IsEqualTo;
    }

    const isCurrentConditionValid = this.checkCondition(logicOption, subRelation.if.value, flatElement, subRelation, element,
      screen, relation, 'if', subRelationIndex);
    validState.ifValid = isCurrentConditionValid;
    let logicAction = subRelation.then.action;
    let value = subRelation.then.value;
    let currentRelation = subRelation;
    const currentElement = flatInteractionElements.find(flatInteractionElement =>
      flatInteractionElement.originalId === element.originalId);
    logicOption = subRelation.then.option;

    if (isCurrentConditionValid && currentIfRelationConnective !== 'AND'
      && (previousIfConnective && previousIfConnective === 'AND'
        ? isPreviousConditionValid
        : currentRelationConnective === 'AND'
          ? isPreviousConditionValid && !isPreviousIfConditionNotValid
          : true)) {
      let formGroupControl;
      formGroups.forEach(formGroup => {
        if (formGroup.controls[element.id]) {
            formGroupControl = formGroup.controls[element.id];
        }
      });

      if (previousIfConnective && previousRelation) {
        logicAction = previousRelation.then.action;
        value = previousRelation.then.value;
        currentRelation = previousRelation;
        logicOption = previousRelation.then.option;
      }
      // this is required for initial load - setTimeout makes sure to enable/disable/set default value of elements after UI loads
      setTimeout(() => {
        this.performThenAction(logicAction, element, formGroupControl, validState, value, currentRelation, currentElement,
          screen, logicOption, flatInteractionElements, relation, subRelationIndex);
      });
      this.performThenAction(logicAction, element, formGroupControl, validState, value, currentRelation, currentElement,
        screen, logicOption, flatInteractionElements, relation, subRelationIndex);
    } else {
      if ((currentIfRelationConnective && currentIfRelationConnective === 'OR'
        || (!currentIfRelationConnective && previousIfConnective === 'AND')
        || (!currentIfRelationConnective && !previousIfConnective)) && enableOpposite) {
        if (previousIfConnective && previousRelation) {
          logicAction = previousRelation.then.action;
        }
        let formGroupControl;
        formGroups.forEach(formGroup => {
          if (formGroup.controls[element.id]) {
              formGroupControl = formGroup.controls[element.id];
          }
        });
        const formElement = screen.FormElements.find(formScreenElement => formScreenElement.originalId === element.originalId);
        if (formGroupControl) {
          switch (logicAction) {
            case IfThenLogicAction.Enable:
              if (formGroupControl.enabled) {
                formGroupControl.disable();
                if (formElement) {
                  formElement.disabled = true;
                }
              }
              break;
            case IfThenLogicAction.Disable:
              if (formGroupControl.disabled) {
                formGroupControl.enable();
                if (formElement) {
                  formElement.disabled = false;
                }
              }
              break;
            case IfThenLogicAction.DefaultValue:
              if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                const newValue = formGroupControl.value;
                Object.keys(newValue).forEach(key => {
                  newValue[key] = false;
                });
                formGroupControl.setValue(newValue, { emitEvent: false });
              } else if (element.type === Checkbox) {
                formGroupControl.setValue(!value, { emitEvent: false });
              } else if (element.type === Switch) {
                formGroupControl.setValue(!value, { emitEvent: false });
              } else {
                formGroupControl.setValue(undefined, { emitEvent: false });
              }
              break;
            case IfThenLogicAction.MathEquation:
              formGroupControl.setValue(0);
              break;
            case IfThenLogicAction.MustBe:
              validState.thenValid = this.checkCondition(logicOption, value, currentElement, currentRelation, element, screen,
              relation, 'then', subRelationIndex);
              break;
            default: break;
          }
        }
      } else {
        if (currentIfRelationConnective && currentIfRelationConnective === "AND" && validState.ifValid) {
          if (previousIfConnective === 'AND') {
            validState.thenValid = isPreviousConditionValid && true;
          } else if (previousIfConnective === 'OR') {
            validState.thenValid = isPreviousConditionValid || true;
          } else {
            validState.thenValid = true;
          }
        }
      }
    }
    return validState;
  }

  performThenAction(logicAction: IfThenLogicAction, element: any, formGroupControl: any,
    validState: {ifValid: boolean, thenValid: boolean}, value: any, currentRelation: any, currentElement: any, screen: any,
    logicOption: IfThenLogicOptions, flatInteractionElements: any, relation: any, subRelationIndex: number) {
    const formElement = screen.FormElements.find(formScreenElement => formScreenElement.originalId === element.originalId);
    switch (logicAction) {
      case IfThenLogicAction.Enable:
        if (formGroupControl) {
          if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            formGroupControl.enable({ emitEvent: false });
          } else {
            formGroupControl.enable();
          }
          if (formElement) {
              formElement.disabled = false;
          }
        }
        validState.thenValid = true;
        break;
      case IfThenLogicAction.Disable:
        if (formGroupControl) {
          if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            formGroupControl.disable({ emitEvent: false });
          } else {
            formGroupControl.disable();
          }
          if (formElement) {
            formElement.disabled = true;
          }
        }
        validState.thenValid = true;
        break;
      case IfThenLogicAction.DefaultValue:
        validState.thenValid = this.relationXasDefault(value, currentRelation, currentElement, element, screen, formGroupControl);
        break;
      case IfThenLogicAction.MustBe:
        validState.thenValid = this.checkCondition(logicOption, value, currentElement, currentRelation, element, screen,
        relation, 'then', subRelationIndex);
        break;
      case IfThenLogicAction.MathEquation:
        validState.thenValid = this.relationMathEquation(currentRelation, currentElement, element, screen,
        currentElement.inputType ? currentElement.inputType : currentElement.type, flatInteractionElements);
        break;
      default:
        validState.thenValid = false;
        break;
    }
  }

  checkIfInteractionConditionIsMet(process: Process, processCondition: WorkFlowCondition[], conditionId: string) {
    let isValid = false;

    if ( processCondition && Array.isArray(processCondition) && processCondition.length > 0 ) {
      const interactionConditionIndex = processCondition.map(condition => condition.id).indexOf(conditionId);
      const interactionCondition = processCondition[interactionConditionIndex];
      if (interactionCondition.condition && Array.isArray(interactionCondition.condition) && interactionCondition.condition.length > 0 ) {
        let conditionSetIndex = 0;
        for (const conditionSet of interactionCondition.condition) {
          let setConditionIndex = 0;
          let isConditionSetValid = false;
          for (const setCondition of conditionSet.conditions) {
            let isConditionValid = false;
            const { elementValue, screenIndex, interactionId, elementId }
              = this.getInteractionElementValue(process, setCondition.elementOriginalId);
            let operator;
            if (setCondition.operator === '===') {
              operator = IfThenLogicOptions.IsEqualTo;
            } else if (setCondition.operator === '!==') {
              operator = IfThenLogicOptions.IsNotEqualTo;
            } else {
              operator = setCondition.operator;
            }
            const conditionValue = setCondition.value;
            const secondValue = setCondition.secondValue;
            const currentInteraction = process.workflows[0].interactions.find(interaction => interaction.interactionId === interactionId);
            const formScreen = currentInteraction.interaction['FormScreens'][screenIndex];
            const element = formScreen['FormElements'].find(formElement => formElement.id === elementId);
            // if (typeof(setCondition.value) === 'string') {
            isConditionValid = this.checkCondition(operator, conditionValue, { ...element, value: elementValue }, { if: { secondValue } },
              undefined, formScreen, { id: [elementId], subRelation: [{ if: { value: conditionValue } }] }, 'if', 0);
            // } else if (Array.isArray(setCondition.value)) {
            //   let convertedValue = '[';
            //   setCondition.value.forEach((stringValue, index) => {
            //     if (typeof(stringValue) === 'string') {
            //       convertedValue += "`" + stringValue + "`";
            //       if (index < setCondition.value.length - 1) {
            //         convertedValue += ',';
            //       }
            //     }
            //   });
            //   convertedValue += ']';
            //   if (this.getInteractionElementValue(process, setCondition.elementOriginalId)) {
            //     condition += "(" + convertedValue + ".every(userValue => "
            //       + this.getInteractionElementValue(process, setCondition.elementOriginalId) + ".includes(userValue))" + ")";
            //   } else {
            //     if (setCondition.value) {
            //       condition +=  "(" + (setCondition.value.length === 0) + ")";
            //     }
            //   }
            // } else {
            //   if (setCondition.value && typeof(setCondition.value) === 'object') {
            //     const elementValue = this.getInteractionElementValue(process, setCondition.elementOriginalId);
            //     condition += "(" + (Object.keys(elementValue).every(checkboxLabel => elementValue[checkboxLabel]
            //       === setCondition.value[checkboxLabel])) + ")";
            //   } else {
            //     condition += "(" + this.getInteractionElementValue(process, setCondition.elementOriginalId) +
            //       setCondition.operator + setCondition.value  + ")";
            //   }
            // }
            // if ( !this.isValidConnectionOperator(setCondition.connectionOperator) ) {
            //   setCondition.connectionOperator = 'AND';
            // }
            // if (setConditionIndex !== conditionSet.conditions.length - 1) {
            //   condition += " " + this.getConditionConnectionOperator(setCondition.connectionOperator) + " ";
            // }
            if (setConditionIndex > 0) {
              if (conditionSet.conditions[setConditionIndex - 1].connectionOperator === 'AND') {
                isConditionSetValid = isConditionSetValid && isConditionValid;
              } else if (conditionSet.conditions[setConditionIndex - 1].connectionOperator === 'OR') {
                isConditionSetValid = isConditionSetValid || isConditionValid;
              }
            } else {
              isConditionSetValid = isConditionValid;
            }
            setConditionIndex++;
          }

          // if ( condition ) conditionString += condition;
          // if ( !this.isValidConnectionOperator(conditionSet.connectionOperator) ) {
          //   conditionSet.connectionOperator = 'AND';
          // }
          // if (conditionSetIndex !== interactionCondition.condition.length - 1) {
          //   conditionString += " " + this.getConditionConnectionOperator(conditionSet.connectionOperator) + " ";
          // }
          if (conditionSetIndex > 0) {
            if (interactionCondition.condition[conditionSetIndex - 1].connectionOperator === 'AND') {
              isValid = isValid && isConditionSetValid;
            } else if (interactionCondition.condition[conditionSetIndex - 1].connectionOperator === 'OR') {
              isValid = isValid || isConditionSetValid;
            }
          } else {
            isValid = isConditionSetValid;
          }
          conditionSetIndex++;
        }
      } else if (interactionCondition.condition === 'true') {
        return true;
      }
    }

    return isValid;
  }

  /**
   * This function returns the interaction element value
   *
   * @param process Process to evaluate
   * @param elementId Id of the element
   */
  private getInteractionElementValue( process: Process, elementId: string ) {
    const response = {
      elementValue: null,
      screenIndex: null,
      interactionId: null,
      elementId: null
    };
    if ( process && process.executingInteractionId && process.interactionsValues &&
      Array.isArray(process.interactionsValues) && process.interactionsValues.length > 0 ) {

      const interactionValueIndex = process.interactionsValues.map(interactionValue => interactionValue.interactionValues.originalId)
      .indexOf(process.executingInteractionId);
      if (interactionValueIndex !== -1 ) {
        const interactionValue = process.interactionsValues[interactionValueIndex];
        if ( interactionValue.interactionValues && interactionValue.interactionValues.elements &&
            Array.isArray(interactionValue.interactionValues.elements) && interactionValue.interactionValues.elements.length > 0 ) {

            const interactionValueElementIndex = interactionValue.interactionValues.elements
            .map( interactionValueElement => interactionValueElement.originalId ).indexOf(elementId);
            if ( interactionValueElementIndex !== -1 ) {
              response.elementValue = interactionValue.interactionValues.elements[interactionValueElementIndex].value;
              response.screenIndex = interactionValue.interactionValues.elements[interactionValueElementIndex].scrnIndex;
              response.elementId = interactionValue.interactionValues.elements[interactionValueElementIndex].originalId;
              response.interactionId = interactionValue.interactionValues.originalId;
              // if (typeof(value) === 'string') value = "`" + value + "`";
              // if (Array.isArray(value)) {
              //   let convertedValue = null;
              //   if (value && value.length > 0 && typeof(value[0]) === 'string') {
              //     convertedValue = '[';
              //   } else if (value && value.length > 0 && typeof(value[0]) === 'object') {
              //     convertedValue = {};
              //   } else {
              //     convertedValue = '';
              //   }
              //   value.forEach((stringValue, index) => {
              //     if (typeof(stringValue) === 'string') {
              //       convertedValue += "`" + stringValue + "`";
              //       if (index < value.length - 1) {
              //         convertedValue += ',';
              //       }
              //     } else if (typeof(stringValue) === 'object') {
              //       convertedValue[stringValue.label] = stringValue.value;
              //     }
              //   });
              //   if (value && value.length > 0 && typeof(value[0]) === 'string') {
              //     convertedValue += ']';
              //   }
              //   value = convertedValue;
              // }
            }
        }
      }
    }

    return response;
  }

  checkCondition(logicOption: IfThenLogicOptions, value: any, flatElement: any, subRelation: SubRelation | any, element: any,
    formScreen: any, relation: Relation | any, conditionType: 'if' | 'then', subRelationIndex: number): boolean {
    const elementValue = flatElement.value; // user input
    const type = flatElement.inputType ? flatElement.inputType : flatElement.type;

    const today: Date = new Date();
    const weekDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    weekDate.setUTCDate(weekDate.getUTCDate() + 4 - (weekDate.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(weekDate.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNumber = today.getFullYear().toString() + '-W' +
      Math.ceil(( ( (weekDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    const timeNumber = today.getHours() + ':' + today.getMinutes(); // time in HH:MM format

    switch (logicOption) {
      case IfThenLogicOptions.IsEqualTo:                  return this.relationIsEqualTo(value, flatElement, type, formScreen, relation,
                                                            conditionType, subRelationIndex);
      case IfThenLogicOptions.IsNotEqualTo:               return !this.relationIsEqualTo(value, flatElement, type, formScreen, relation,
                                                            conditionType, subRelationIndex);
      case IfThenLogicOptions.StartsWith:                 return this.relationStartsWith(elementValue, value, type);
      case IfThenLogicOptions.EndsWith:                   return this.relationEndsWith(elementValue, value, type);
      case IfThenLogicOptions.IsEmpty:                    return this.relationIsEmpty(elementValue, type);
      case IfThenLogicOptions.IsNotEmpty:                 return !this.relationIsEmpty(elementValue, type);
      case IfThenLogicOptions.GreaterThan:                return this.relationGreaterThan(elementValue, value, type);
      case IfThenLogicOptions.LessThan:                   return this.relationLessThan(elementValue, value, type);
      case IfThenLogicOptions.GreaterThanOrEqualTo:       return this.relationGreaterThanOrEqualTo(elementValue, value, type);
      case IfThenLogicOptions.LessThanOrEqualTo:          return this.relationLessThanOrEqualTo(elementValue, value, type);
      case IfThenLogicOptions.IsBetween:                  return this.relationIsBetween(elementValue, value, subRelation, type,
                                                            conditionType);
      case IfThenLogicOptions.IsNotBetween:               return !this.relationIsBetween(elementValue, value, subRelation, type,
                                                            conditionType);
      case IfThenLogicOptions.MathEquation:               return true;
      case IfThenLogicOptions.DateIsNow:
      case IfThenLogicOptions.DateIsToday:                return this.relationIsEqualTo(today, flatElement, type);
      case IfThenLogicOptions.DateIsAfterNow:
      case IfThenLogicOptions.DateIsAfterToday:           return this.relationDateIsAfterX(elementValue, today, type);
      case IfThenLogicOptions.DateIsBeforeNow:
      case IfThenLogicOptions.DateIsBeforeToday:          return this.relationDateIsBeforeX(elementValue, today, type);
      case IfThenLogicOptions.DateIsEqualToOrAfterNow:
      case IfThenLogicOptions.DateIsEqualToOrAfterToday:  return this.relationDateIsEqualToOrAfterX(elementValue, today, type);
      case IfThenLogicOptions.DateIsEqualToOrBeforeNow:
      case IfThenLogicOptions.DateIsEqualToOrBeforeToday: return this.relationDateIsEqualToOrBeforeX(elementValue, today, type);
      case IfThenLogicOptions.DateIsAfterX:               return this.relationDateIsAfterX(elementValue, value, type);
      case IfThenLogicOptions.DateIsBeforeX:              return this.relationDateIsBeforeX(elementValue, value, type);
      case IfThenLogicOptions.DateIsEqualToOrAfterX:      return this.relationDateIsEqualToOrAfterX(elementValue, value, type);
      case IfThenLogicOptions.DateIsEqualToOrBeforeX:     return this.relationDateIsEqualToOrBeforeX(elementValue, value, type);
      case IfThenLogicOptions.IsCurrentMonth:             return this.relationIsEqualTo(today, flatElement, type);
      case IfThenLogicOptions.MonthIsAfterCurrentMonth:   return this.relationMonthIsAfterX(elementValue, today, type);
      case IfThenLogicOptions.MonthIsBeforeCurrentMonth:  return this.relationMonthIsBeforeX(elementValue, today, type);
      case IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth:  return this.relationMonthIsEqualToOrAfterX(elementValue, today, type);
      case IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth: return this.relationMonthIsEqualToOrBeforeX(elementValue, today, type);
      case IfThenLogicOptions.MonthIsAfterX:              return this.relationMonthIsAfterX(elementValue, value, type);
      case IfThenLogicOptions.MonthIsBeforeX:             return this.relationMonthIsBeforeX(elementValue, value, type);
      case IfThenLogicOptions.MonthIsEqualToOrAfterX:     return this.relationMonthIsEqualToOrAfterX(elementValue, value, type);
      case IfThenLogicOptions.MonthIsEqualToOrBeforeX:    return this.relationMonthIsEqualToOrBeforeX(elementValue, value, type);
      case IfThenLogicOptions.IsCurrentWeek:              return this.relationIsEqualTo(weekNumber, flatElement, type);
      case IfThenLogicOptions.WeekIsAfterCurrentWeek:     return this.relationWeekIsAfterX(elementValue, weekNumber, type);
      case IfThenLogicOptions.WeekIsBeforeCurrentWeek:    return this.relationWeekIsBeforeX(elementValue, weekNumber, type);
      case IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek:  return this.relationWeekIsEqualToOrAfterX(elementValue, weekNumber, type);
      case IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek: return this.relationWeekIsEqualToOrBeforeX(elementValue, weekNumber, type);
      case IfThenLogicOptions.WeekIsAfterX:               return this.relationWeekIsAfterX(elementValue, value, type);
      case IfThenLogicOptions.WeekIsBeforeX:              return this.relationWeekIsBeforeX(elementValue, value, type);
      case IfThenLogicOptions.WeekIsEqualToOrAfterX:      return this.relationWeekIsEqualToOrAfterX(elementValue, value, type);
      case IfThenLogicOptions.WeekIsEqualToOrBeforeX:     return this.relationWeekIsEqualToOrBeforeX(elementValue, value, type);
      case IfThenLogicOptions.IsCurrentTime:              return this.relationIsEqualTo(timeNumber, flatElement, type);
      case IfThenLogicOptions.TimeIsAfterCurrentTime:     return this.relationTimeIsAfterX(elementValue, timeNumber, type);
      case IfThenLogicOptions.TimeIsBeforeCurrentTime:    return this.relationTimeIsBeforeX(elementValue, timeNumber, type);
      case IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime:  return this.relationTimeIsEqualToOrAfterX(elementValue, timeNumber, type);
      case IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime: return this.relationTimeIsEqualToOrBeforeX(elementValue, timeNumber, type);
      case IfThenLogicOptions.TimeIsAfterX:               return this.relationTimeIsAfterX(elementValue, value, type);
      case IfThenLogicOptions.TimeIsBeforeX:              return this.relationTimeIsBeforeX(elementValue, value, type);
      case IfThenLogicOptions.TimeIsEqualToOrAfterX:      return this.relationTimeIsEqualToOrAfterX(elementValue, value, type);
      case IfThenLogicOptions.TimeIsEqualToOrBeforeX:     return this.relationTimeIsEqualToOrBeforeX(elementValue, value, type);
      case IfThenLogicOptions.TextDoesNotContain:         return !this.relationTextContains(elementValue, value, type);
      case IfThenLogicOptions.TextContains:               return this.relationTextContains(elementValue, value, type);
      default:                                            break;
    }
    return false;
  }

  /**
   * This function checks whether user input is equal to relation value
   *
   * @param value relation value
   * @param flatElement form element with user input
   * @param type type of the form element (ng dynamic form control type)
   * @param relation Relation object containing the condition
   * @param formScreen Form screen containing the flat element
   */
  relationIsEqualTo(value: any, flatElement: any, type: string, formScreen?: any, relation?: Relation,
    conditionType?: 'if' | 'then', subRelationIndex?: number): boolean {
    const elementType = this.getRelationElementType(type, value);

    const userSelectedDate = new Date(String(flatElement.value).replace(/-/g, ' '));
    const userSelectedDateTime = new Date(String(flatElement.value));
    const relationDate = new Date(String(value).replace(/-/g, ' '));
    const relationDateTime = new Date(String(value));

    switch (elementType) {
      case elementTypes.string:
      case elementTypes.number:
        return value === flatElement.value;

      case elementTypes.boolean:
        if (typeof value === 'boolean' && typeof flatElement.value !== 'boolean') {
          return value === Boolean(flatElement.value);
        } else {
          return value === flatElement.value;
        }

      case elementTypes.tel:
        const relationValueNumbers = String(value).match(/\d+/g)
          ? String(value).match(/\d+/g).join('').substring(0, 10)
          : '';
        const elementValueNumbers = String(flatElement.value).match(/\d+/g)
          ? String(flatElement.value).match(/\d+/g).join('').substring(0, 10)
          : '';
        return elementValueNumbers === relationValueNumbers;

      case elementTypes.date:
        return this.relationDateIsEqualTo(relationDate, userSelectedDate, elementType);

      case elementTypes.dateTime:
        return this.relationDateTimeIsEqualTo(userSelectedDateTime, relationDateTime, elementType);

      case elementTypes.month:
        return this.relationMonthIsEqualTo(userSelectedDate, relationDate, elementType);

      case elementTypes.week:
        return this.relationWeekIsEqualTo(value, flatElement.value, elementType);

      case elementTypes.time:
        return this.relationTimeIsEqualTo(value, flatElement.value, elementType);

      case elementTypes.multiSelect:
      case elementTypes.chips:
        return this.relationChipsIsEqualTo(value, flatElement.value, elementType);

      case elementTypes.checkboxGroup:
        return this.relationCheckboxGroupIsEqualTo(relation, formScreen, elementType, flatElement, conditionType, subRelationIndex);
      default:
        break;
    }
    return false;
  }

  /**
   * This function checks whether user input starts with relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationStartsWith(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.string || elementType === elementTypes.number) {
      return String(elementValue).startsWith(value);
    } else if (elementType === elementTypes.tel) {
      const elementValueNumbers = String(elementValue).match(/\d+/g) ? String(elementValue).match(/\d+/g).join('') : '';
      const valueNumbers = String(value).match(/\d+/g) ? String(value).match(/\d+/g).join('') : '';
      return elementValueNumbers.startsWith(valueNumbers);
    } else if (elementType === elementTypes.chips) {
      return elementValue[0] === value[0];
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input ends with relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationEndsWith(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.string || elementType === elementTypes.number) {
      return String(elementValue).endsWith(value);
    } else if (elementType === elementTypes.tel) {
      const elementValueNumbers = String(elementValue).match(/\d+/g) ? String(elementValue).match(/\d+/g).join('') : '';
      const valueNumbers = String(value).match(/\d+/g) ? String(value).match(/\d+/g).join('') : '';
      return elementValueNumbers.endsWith(valueNumbers);
    } else if (elementType === elementTypes.chips) {
      return elementValue[elementValue.length - 1] === value[value.length - 1];
    } else {
      return false;
    }
  }

/**
 * This function assigns a value to another form element based on value in relation
 *
 * @param value relation value
 * @param relation relation object
 * @param flatElement user input value
 * @param element element relation is set on
 * @param formScreen screen containing the element
 */
  relationXasDefault(value: any, relation: SubRelation, flatElement: any, element: any, formScreen: any, formGroupControl: any): boolean {
    const elementType = this.getRelationElementType(element.type === 'INPUT' ? element.inputType : element.type);
    const flatElementType = this.getRelationElementType(flatElement.type === 'INPUT' ? flatElement.inputType : flatElement.type, value);
    let flag = false;

    // if element type is multi-select or chips, check their equality
    if (flatElementType === elementTypes.multiSelect || flatElementType === elementTypes.chips) {
      if (this.relationChipsIsEqualTo(value, flatElement.value, flatElementType)) {
        flag = true;
      } else {
        flag = false;
      }
    } else if (flatElementType === elementTypes.checkboxGroup) {
      // else if element type is checkbox group
      const checkboxGroupElement = formScreen.FormElements.find((formElement: any) => formElement.id === flatElement.id);
      const values = [];
      checkboxGroupElement.group.forEach(group => {
        const groupElementValue = {
          label: group.label,
          value: group.value
        };
        values.push(groupElementValue);
      });
      if (_.isEqual(relation.then.value, values)) {
        flag = true;
      } else {
        flag = false;
      }
    } else if (flatElementType === elementTypes.tel) {
      // else if element type is telephone number
      const elementValueNumbers = String(flatElement.value).match(/\d+/g)
        ? String(flatElement.value).match(/\d+/g).join('').substring(0, 10)
        : '';
      const valueNumbers = String(value).match(/\d+/g)
        ? String(value).match(/\d+/g).join('').substring(0, 10)
        : '';
      if (elementValueNumbers === valueNumbers) {
        flag = true;
      } else {
        flag = false;
      }
    }

    // if condition type is boolean but element type is not boolean, make it boolean.
    // This is needed for initial onload check for condition value
    if (typeof value === 'boolean' && typeof flatElement.value !== 'boolean') {
      flatElement.value = Boolean(flatElement.value);
    }

    // if values are same, assign the "X as default" value to the element the condition was set on
    if (elementType === elementTypes.checkboxGroup) {
      const relationValue = relation.then.value;
      const objectKeys = Object.keys(relation.then.value);
      element.group.forEach((group: any, index: number) => group.value = relationValue[objectKeys[index]]);
    } else if (elementType === elementTypes.tel) {
      // element.value = relation.then.value.slice(0, -1);
      formGroupControl.setValue(relation.then.value.slice(0, -1), { emitEvent: false });
    } else if (flatElementType === elementTypes.chips) {
      // element.value = relation.then.value;
      formGroupControl.setValue(relation.then.value?.toString(), { emitEvent: false });
    } else {
      // element.value = relation.then.value;
      formGroupControl.setValue(relation.then.value, { emitEvent: false });
    }
    return true;
  }

  /**
   * This function calculates a math equation with basic arithmetic operations: +,-,*,/
   *
   * @param value relation value
   * @param flatElement user input value
   * @param element element relation is set on
   * @param formScreen screen containing the element
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMathEquation(relation: SubRelation, flatElement: any, element: any, formScreen: any, type: string,
    flatInteractionElements: any[]): boolean {
    const elementType = this.getRelationElementType(type);
    let total = 0;

    relation.then?.operations?.forEach((operation, operationIndex) => {
      const formElement = flatInteractionElements.find((formControl: EvaDynamicFormControlModel) =>
        formControl.originalId === operation.value);
      if (formElement && (elementType === elementTypes.number || elementType === elementTypes.string)) {
        switch (operation.operator) {
          case MathEquationOperators.Add:
            total += Number(formElement.value);
            break;
          case MathEquationOperators.Subtract:
            total -= Number(formElement.value);
            break;
          case MathEquationOperators.MultipliedBy:
            total *= Number(formElement.value);
            break;
          case MathEquationOperators.DividedBy:
            if (Number(formElement.value) !== 0) {
              total = total / formElement.value;
            }
            break;
          default:
            total = Number(formElement.value);
            break;
        }
      }
    });

    element.value = total;
    return true;
  }

  /**
   * This function checks if user input is empty
   *
   * @param elementValue User input
   * @param type type of the form element (ng dynamic form control type)
   */
  relationIsEmpty(elementValue: any, type: string): boolean {
    const elementType = this.getRelationElementType(type, elementValue);

    if (!elementValue) {
      return true;
    }

    if (elementType === elementTypes.string || elementType === elementTypes.number) {
      return String(elementValue).length === 0;
    } else if (elementType === elementTypes.tel) {
      const elementValueNumbers = String(elementValue).match(/\d+/g) ? String(elementValue).match(/\d+/g).join('') : '';
      return elementValueNumbers.length === 0;
    } else if (elementType === elementTypes.chips || elementType === elementTypes.multiSelect) {
      return elementValue.length === 0;
    } else if (elementType === elementTypes.checkboxGroup) {
      if (Array.isArray(elementValue)) {
        const modifiedValue = {};
        elementValue.forEach(value => {
          modifiedValue[value.label] = value.value;
        });
        elementValue = modifiedValue;
      }
      return Object.keys(elementValue).every(key => !elementValue[key]);
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input is greater than relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationGreaterThan(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.number) {
      return elementValue > value;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input is greater than or equal to relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationGreaterThanOrEqualTo(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.number) {
      return elementValue >= value;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input is less than relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationLessThan(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.number) {
      return elementValue < value;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input is less than or equal to relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationLessThanOrEqualTo(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.number) {
      return elementValue <= value;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input is between relation value and max limit
   *
   * @param elementValue User input
   * @param value relation value
   * @param relation Relation object containing max limit
   * @param type type of the form element (ng dynamic form control type)
   */
  relationIsBetween(elementValue: any, value: any, relation: SubRelation, type: string, conditionType: 'if' | 'then'): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.number) {
      const maxLimit = Number(relation[conditionType].secondValue);
      return elementValue > value && elementValue < maxLimit;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date is equal to relation date
   *
   * @param date User input date
   * @param secondDate relation value or today date
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateIsEqualTo(date: Date, secondDate: Date, type: string): boolean {
    if (type === elementTypes.date || this.getRelationElementType(type) === elementTypes.date) {
      return date.getFullYear() === secondDate.getFullYear()
        && date.getMonth() === secondDate.getMonth()
        && date.getDate() === secondDate.getDate();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date time is equal to relation date time
   *
   * @param dateTime User input date time
   * @param secondDateTime relation value or today date time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateTimeIsEqualTo(dateTime: Date, secondDateTime: Date, type: string): boolean {
    if (type === elementTypes.dateTime) {
      return dateTime.getFullYear() === secondDateTime.getFullYear()
        && dateTime.getMonth() === secondDateTime.getMonth()
        && dateTime.getDate() === secondDateTime.getDate()
        && dateTime.getHours() === secondDateTime.getHours()
        && dateTime.getMinutes() === secondDateTime.getMinutes();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input month is equal to relation month
   *
   * @param month User input month
   * @param secondMonth relation value month
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMonthIsEqualTo(month: Date, secondMonth: Date, type: string): boolean {
    if (type === elementTypes.month) {
      return month.getMonth() === secondMonth.getMonth()
        && month.getFullYear() === secondMonth.getFullYear();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input week is equal to relation week
   *
   * @param week User input week
   * @param secondWeek relation value week
   * @param type type of the form element (ng dynamic form control type)
   */
  relationWeekIsEqualTo(week: string, secondWeek: string, type: string): boolean {
    if (type === elementTypes.week) {
      return week === secondWeek;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input time is equal to relation time
   *
   * @param time User input time
   * @param secondTime relation value time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTimeIsEqualTo(time: string, secondTime: string, type: string): boolean {
    if (type === elementTypes.time) {
      const userSelectedTime = String(time).match(/\d+/g) ? time.match(/\d+/g).map(Number) : [];
      const relationTime = String(secondTime).match(/\d+/g) ? String(secondTime).match(/\d+/g).map(Number) : [];

      if (userSelectedTime.length === 2 && relationTime.length === 2) {
        return userSelectedTime[0] === relationTime[0] && userSelectedTime[1] === relationTime[1];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input chips are equal to relation chips
   *
   * @param chips User input chips
   * @param secondChips relation value chips
   * @param type type of the form element (ng dynamic form control type)
   */
  relationChipsIsEqualTo(chips: string[], secondChips: string[], type: string): boolean {
    if (!secondChips) {
      return false;
    }
    if (type === elementTypes.chips || type === elementTypes.multiSelect) {
      return _.isEqual(chips.sort(), secondChips.sort());
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input checkbox group values are equal to relation checkbox group values
   *
   * @param relation Relation object containing the condition value
   * @param formScreen Form screen containing the flat element
   * @param type type of the form element from elementTypes
   */
  relationCheckboxGroupIsEqualTo(relation: Relation, formScreen: any, type: string, flatElement: any,
    conditionType: 'if' | 'then', subRelationIndex: number): boolean {
    if (type === elementTypes.checkboxGroup) {
      // get the check box form element from the form screen
      let formElement;
      formScreen.FormElements.forEach((element: any) => {
        if ((flatElement.originalId ?? flatElement.id) === (element.originalId ?? element.id)) {
          formElement = element;
        }
      });
      if (!formElement) {
        return false;
      }
      const comparableCheckboxGroupValue = {};
      // grab the values from every checkbox field from checkbox group
      formElement['group'].forEach(group => {
        const label = group.label;
        let currentItem;
        if (Array.isArray(flatElement.value)) {
          currentItem = flatElement.value.find(item => item.label === label);
        }
        let value = group.value;
        if (currentItem) {
          value = currentItem.value;
        }
        comparableCheckboxGroupValue[label] = value;
      });

      let isSame = true;
      // get the correct object based on old or new object model
      const relationValue = relation.subRelation[subRelationIndex ?? 0][conditionType].value;
      if (relationValue) {
        // compare the values for the relation and user input
        Object.keys(relationValue).forEach(key => {
          if (relationValue[key] !== comparableCheckboxGroupValue[key]) {
            isSame = false;
          }
        });
        return isSame;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date is after the relation date
   *
   * @param elementValue User input as date
   * @param value relation value as date
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateIsAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.date) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));
      userSelectedDate.setHours(0, 0, 0, 0);
      relationDate.setHours(0, 0, 0, 0);

      return userSelectedDate > relationDate;
    } else if (elementType === elementTypes.dateTime) {
      const userSelectedDate = new Date(String(elementValue));
      const relationDate = new Date(String(value));
      userSelectedDate.setSeconds(0, 0);
      relationDate.setSeconds(0, 0);

      return userSelectedDate > relationDate;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input month is after the relation month
   *
   * @param elementValue User input as month
   * @param value relation value as month
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMonthIsAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.month) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));

      return (userSelectedDate.getFullYear() === relationDate.getFullYear() &&
      userSelectedDate.getMonth() > relationDate.getMonth()) || userSelectedDate.getFullYear() > relationDate.getFullYear();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input week is after the relation week
   *
   * @param elementValue User input as week
   * @param value relation value as week
   * @param type type of the form element (ng dynamic form control type)
   */
  relationWeekIsAfterX(elementValue: string, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.week) {
      const userSelectedWeek = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationWeek = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedWeek.length === 2 && relationWeek.length === 2) {
        return (userSelectedWeek[0] === relationWeek[0] && userSelectedWeek[1] > relationWeek[1]) ||
          userSelectedWeek[0] > relationWeek[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input time is after the relation time
   *
   * @param elementValue User input as time
   * @param value relation value as time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTimeIsAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.time) {
      const userSelectedTime = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationTime = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedTime.length === 2 && relationTime.length === 2) {
        return (userSelectedTime[0] === relationTime[0] && userSelectedTime[1] > relationTime[1]) ||
          userSelectedTime[0] > relationTime[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date is equal to or after the relation date
   *
   * @param elementValue User input as date
   * @param value relation value as date
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateIsEqualToOrAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.date) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));
      userSelectedDate.setHours(0, 0, 0, 0);
      relationDate.setHours(0, 0, 0, 0);

      return this.relationDateIsEqualTo(userSelectedDate, relationDate, elementType) ||  userSelectedDate > relationDate;
    } else if (elementType === elementTypes.dateTime) {
      const userSelectedDate = new Date(String(elementValue));
      const relationDate = new Date(String(value));
      userSelectedDate.setSeconds(0, 0);
      relationDate.setSeconds(0, 0);

      return this.relationDateTimeIsEqualTo(userSelectedDate, relationDate, elementType) || userSelectedDate > relationDate;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input month is equal to or after the relation month
   *
   * @param elementValue User input as month
   * @param value relation value as month
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMonthIsEqualToOrAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.month) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));

      return this.relationMonthIsEqualTo(userSelectedDate, relationDate, elementType) ||
        (userSelectedDate.getFullYear() === relationDate.getFullYear() &&
        userSelectedDate.getMonth() > relationDate.getMonth()) || userSelectedDate.getFullYear() > relationDate.getFullYear();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input week is equal to or after the relation week
   *
   * @param elementValue User input as week
   * @param value relation value as week
   * @param type type of the form element (ng dynamic form control type)
   */
  relationWeekIsEqualToOrAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.week) {
      const userSelectedWeek = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationWeek = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedWeek.length === 2 && relationWeek.length === 2) {
        return this.relationWeekIsEqualTo(elementValue, value, elementType) ||
          (userSelectedWeek[0] === relationWeek[0] && userSelectedWeek[1] > relationWeek[1]) ||
          userSelectedWeek[0] > relationWeek[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input time is equal to or after the relation time
   *
   * @param elementValue User input as time
   * @param value relation value as time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTimeIsEqualToOrAfterX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.time) {
      const userSelectedTime = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationTime = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedTime.length === 2 && relationTime.length === 2) {
        return this.relationTimeIsEqualTo(elementValue, value, elementType) ||
          (userSelectedTime[0] === relationTime[0] && userSelectedTime[1] > relationTime[1]) ||
          userSelectedTime[0] > relationTime[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date is before the relation date
   *
   * @param elementValue User input as date
   * @param value relation value as date
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateIsBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.date) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));
      userSelectedDate.setHours(0, 0, 0, 0);
      relationDate.setHours(0, 0, 0, 0);

      return userSelectedDate < relationDate;
    } else if (elementType === elementTypes.dateTime) {
      const userSelectedDate = new Date(String(elementValue));
      const relationDate = new Date(String(value));
      userSelectedDate.setSeconds(0, 0);
      relationDate.setSeconds(0, 0);

      return userSelectedDate < relationDate;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input month is before the relation month
   *
   * @param elementValue User input as month
   * @param value relation value as month
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMonthIsBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.month) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));

      return (userSelectedDate.getFullYear() === relationDate.getFullYear() &&
      userSelectedDate.getMonth() < relationDate.getMonth()) || userSelectedDate.getFullYear() < relationDate.getFullYear();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input week is before the relation week
   *
   * @param elementValue User input as week
   * @param value relation value as week
   * @param type type of the form element (ng dynamic form control type)
   */
  relationWeekIsBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.week) {
      const userSelectedWeek = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationWeek = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedWeek.length === 2 && relationWeek.length === 2) {
        return (userSelectedWeek[0] === relationWeek[0] && userSelectedWeek[1] < relationWeek[1]) ||
          userSelectedWeek[0] < relationWeek[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input time is before the relation time
   *
   * @param elementValue User input as time
   * @param value relation value as time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTimeIsBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.time) {
      const userSelectedTime = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationTime = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedTime.length === 2 && relationTime.length === 2) {
        return (userSelectedTime[0] === relationTime[0] && userSelectedTime[1] < relationTime[1]) ||
          userSelectedTime[0] < relationTime[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input date is equal to or before the relation date
   *
   * @param elementValue User input as date
   * @param value relation value as date
   * @param type type of the form element (ng dynamic form control type)
   */
  relationDateIsEqualToOrBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.date) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));
      userSelectedDate.setHours(0, 0, 0, 0);
      relationDate.setHours(0, 0, 0, 0);

      return this.relationDateIsEqualTo(userSelectedDate, relationDate, elementType) ||  userSelectedDate < relationDate;
    } else if (elementType === elementTypes.dateTime) {
      const userSelectedDate = new Date(String(elementValue));
      const relationDate = new Date(String(value));
      userSelectedDate.setSeconds(0, 0);
      relationDate.setSeconds(0, 0);

      return this.relationDateTimeIsEqualTo(userSelectedDate, relationDate, elementType) || userSelectedDate < relationDate;
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input month is equal to or before the relation month
   *
   * @param elementValue User input as month
   * @param value relation value as month
   * @param type type of the form element (ng dynamic form control type)
   */
  relationMonthIsEqualToOrBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.month) {
      const userSelectedDate = new Date(String(elementValue).replace(/-/g, ' '));
      const relationDate = new Date(String(value).replace(/-/g, ' '));

      return this.relationMonthIsEqualTo(userSelectedDate, relationDate, elementType) ||
        (userSelectedDate.getFullYear() === relationDate.getFullYear() &&
        userSelectedDate.getMonth() < relationDate.getMonth()) || userSelectedDate.getFullYear() < relationDate.getFullYear();
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input week is equal to or before the relation week
   *
   * @param elementValue User input as week
   * @param value relation value as week
   * @param type type of the form element (ng dynamic form control type)
   */
  relationWeekIsEqualToOrBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.week) {
      const userSelectedWeek = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationWeek = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedWeek.length === 2 && relationWeek.length === 2) {
        return this.relationWeekIsEqualTo(elementValue, value, elementType) ||
          (userSelectedWeek[0] === relationWeek[0] && userSelectedWeek[1] < relationWeek[1]) ||
          userSelectedWeek[0] < relationWeek[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input time is equal to or before the relation time
   *
   * @param elementValue User input as time
   * @param value relation value as time
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTimeIsEqualToOrBeforeX(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type);

    if (elementType === elementTypes.time) {
      const userSelectedTime = String(elementValue).match(/\d+/g) ? elementValue.match(/\d+/g).map(Number) : [];
      const relationTime = String(value).match(/\d+/g) ? String(value).match(/\d+/g).map(Number) : [];

      if (userSelectedTime.length === 2 && relationTime.length === 2) {
        return this.relationTimeIsEqualTo(elementValue, value, elementType) ||
          (userSelectedTime[0] === relationTime[0] && userSelectedTime[1] < relationTime[1]) ||
          userSelectedTime[0] < relationTime[0];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function checks whether user input contains relation value
   *
   * @param elementValue User input
   * @param value relation value
   * @param type type of the form element (ng dynamic form control type)
   */
  relationTextContains(elementValue: any, value: any, type: string): boolean {
    const elementType = this.getRelationElementType(type, value);

    if (!elementValue) {
      return false;
    }

    if (elementType === elementTypes.string) {
      return elementValue.includes(value);
    } else if (elementType === elementTypes.tel) {
      const elementValueNumbers = String(elementValue).match(/\d+/g) ? String(elementValue).match(/\d+/g).join('') : '';
      const valueNumbers = String(value).match(/\d+/g) ? String(value).match(/\d+/g).join('') : '';
      return elementValueNumbers.includes(valueNumbers);
    } else if (elementType === elementTypes.chips) {
      let flag = true;
      value.forEach((chip: String) => {
        if (elementValue && elementValue.find((valueChip: string) => valueChip === chip)) {
          flag  = true;
        } else {
          flag = false;
        }
      });
      return flag;
    } else {
      return false;
    }
  }

  /**
   * This function returns the type of input based on input ng dynamic form type
   *
   * @param type ng dynamic form type
   * @param value value of the form element
   */
  getRelationElementType(type: string, value?: any): string {
    let elementType = '';
    switch (type) {
      case RadioGroup:
      case Textarea:
      case Email:
      case Password:
      case Search:
      case URL:                   elementType = elementTypes.string; break;
      case Time:                  elementType = elementTypes.time; break;
      case Tel:                   elementType = elementTypes.tel; break;
      case Text:                  elementType = _.isArray(value) ? elementTypes.chips : elementTypes.string; break;
      case Select:                elementType = _.isArray(value) ? elementTypes.multiSelect : elementTypes.string; break;
      case Checkbox:
      case Switch:                elementType = elementTypes.boolean; break;
      case Slider:
      case InputNumber:           elementType = elementTypes.number; break;
      case InputDate:             elementType = elementTypes.date; break;
      case InputDateTimeLocal:    elementType = elementTypes.dateTime; break;
      case Month:                 elementType = elementTypes.month; break;
      case Week:                  elementType = elementTypes.week; break;
      case Chips:                 elementType = elementTypes.chips; break;
      case CheckboxGroup:         elementType = elementTypes.checkboxGroup; break;
      default: break;
    }

    return elementType;
  }

}
