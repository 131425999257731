import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Observable } from 'rxjs';
import { TeamMemberData, TeamMembers } from '@eva-model/releaseNotes';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class TeamMembersService {

  _teamMembersPath = 'About/TeamMembers/';

  constructor(
    private firestoreService: FirestoreService,
    private perf: AngularFirePerformance
  ) { }

  /**
   * This gets the team members and returns the document.
   */
  getTeamMembers(): Observable<TeamMembers> {
    return this.firestoreService.doc$<TeamMembers>(this._teamMembersPath).pipe(
      trace('team-members-getTeamMembers')
    );
  }

  /**
   * This stores the team members into a new object.
   * @param teamMembers
   */
  storeTeamMembers(teamMembers: TeamMembers) {
    return this.firestoreService.set(this._teamMembersPath, teamMembers);
  }
}
