import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Pair } from '@eva-model/idMap';

@Component({
  selector: 'app-id-map-entity-group-map',
  templateUrl: './id-map-entity-group-map.component.html',
  styleUrls: ['./id-map-entity-group-map.component.scss']
})
export class IdMapEntityGroupMapComponent {

  pairs: Pair[] = [];                               // Array containing pairs of groups mapped by user
  newGroups: any[] = [];                            // List of group destinations from imported workflow
  selectUserGroupFormGroup: UntypedFormGroup;              // Form group for user groups from current environment
  userGroups: any[] = [];                           // List of user groups from current environment
  allGroupsSelected = false;                        // Whether "select all" button was selected or not
  allSameGroupsSelected = false;                    // Whether "select all same" button was selected or not
  selectedGroup = null;                             // user group selected by user from current environment

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _formBuilder: UntypedFormBuilder) {
    this.newGroups = dialogData.newGroups;
    this.selectUserGroupFormGroup = this._formBuilder.group({
      selectGroupCtrl: ['', Validators.required]
    });
    this.userGroups = dialogData.userGroups;
  }

  /**
   * This function returns the name of the group from group public key
   *
   * @param groupPk Public key of the group
   */
  getGroupNameFromGroupPublicKey(groupPk: string): string {
    const group = this.userGroups.find(grp => grp.groupPublicKey === groupPk);
    if (group) {
      return group.groupName;
    }
    return '';
  }

  /**
   * This function changes selected old group based on user selection
   */
  onGroupSelect(): void {
    const selectedUserGroup = this.selectUserGroupFormGroup.value.selectGroupCtrl;
    if (selectedUserGroup) {
      // if user group is selected
      if (this.allGroupsSelected) {
        // if all groups are selected, select all the groups
        this.pairs.forEach(pair => {
          pair.old = {
            groupName: this.getGroupNameFromGroupPublicKey(selectedUserGroup),
            groupPublicKey: selectedUserGroup
          };
        });
        this.allGroupsSelected = false;
        this.allSameGroupsSelected = false;
      } else if (this.allSameGroupsSelected) {
        // if all same groups selected, select all the same groups
        this.pairs.forEach(pair => {
          if (pair.new.group.publicKey === this.selectedGroup.group.publicKey) {
            pair.old = {
              groupName: this.getGroupNameFromGroupPublicKey(selectedUserGroup),
              groupPublicKey: selectedUserGroup
            };
          }
        });
        this.allSameGroupsSelected = false;
      } else {
        // else if user group is deselected, remove the pairing for that group
        this.pairs[0].old = {
          groupName: this.getGroupNameFromGroupPublicKey(selectedUserGroup),
          groupPublicKey: selectedUserGroup
        };
      }
    }
  }

  /**
   * This function selects the new group destination based on user selection
   *
   * @param group Group selected by user from previous environment
   */
  selectGroup(group: any): void {
    group.selected = !group.selected;
    if (group.selected) {
      // if group is selected, add it to the paired groups array
      this.selectedGroup = group;
      this.pairs.unshift({
        old: null,
        new: group
      });
    } else {
      // else remove it from the paired group array
      const pairIndex = this.pairs.findIndex(pair => pair.new.group.publicKey === group.group.publicKey);
      if (pairIndex !== -1) {
        this.pairs.splice(pairIndex, 1);
      }
      this.selectedGroup = null;
    }
  }

  /**
   * This function checks if the group pairs are all paired or not
   */
  isValid(): boolean {
    return this.pairs.filter(pair => !pair.old).length === 0
      && this.pairs.length === this.newGroups.length;
  }

  /**
   * This function checks if the new group is selected or not
   */
  isNewSelected(): boolean {
    if (this.pairs[0] && this.pairs[0].old) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * This function selects all the user groups
   */
  selectAll(): void {
    if (this.allGroupsSelected) {
      // if all groups are selected, deselect all the groups
      this.allGroupsSelected = false;
      this.newGroups.forEach(group => {
        group.selected = false;
        const pairIndex = this.pairs.findIndex(pair => pair.new.group.publicKey === group.group.publicKey);
        if (pairIndex !== -1) {
          this.pairs.splice(pairIndex, 1);
        }
      });
      return;
    }
    // if not all groups selected, select all the groups
    this.newGroups.forEach(group => {
      group.selected = true;
      this.pairs.unshift({
        old: null,
        new: group
      });
    });
    this.allGroupsSelected = true;
  }

  /**
   * This function selects all the same user groups
   */
  selectAllSame(): void {
    if (this.selectedGroup) {
      // if there is a selected group, select all the similar groups
      if (this.allSameGroupsSelected) {
        // if all same groups selected, deselect all of them
        this.allSameGroupsSelected = false;
        this.newGroups.forEach(group => {
          if (group.group.publicKey === this.selectedGroup.group.publicKey) {
            group.selected = false;
            const pairIndex = this.pairs.findIndex(pair => pair.new.group.publicKey === group.group.publicKey);
            if (pairIndex !== -1) {
              this.pairs.splice(pairIndex, 1);
            }
          }
        });
        this.selectedGroup = null;
        return;
      }
      // if not all the same groups selected, select all of them
      this.newGroups.forEach(group => {
        if (group.group.publicKey === this.selectedGroup.group.publicKey) {
          if (group.selected) {
            return;
          }
          group.selected = true;
          this.pairs.unshift({
            old: null,
            new: group
          });
        }
      });
      this.allSameGroupsSelected = true;
    }
  }

  /**
   * This function resets the dialog box to initial state
   *
   * @param stepperCtrl MatHorizontalStepper
   * @param userGroupForm Form group
   */
  onReset(stepperCtrl: MatStepper, userGroupForm: UntypedFormGroup): void {
    this.newGroups.forEach(interaction => {
      interaction.selected = false;
    });
    this.pairs = [];
    this.pairs.length = 0;
    this.allGroupsSelected = false;
    this.allSameGroupsSelected = false;
    this.selectedGroup = null;

    if (stepperCtrl) stepperCtrl.reset();
    if (userGroupForm) userGroupForm.reset();
  }

  /**
   * This function returns the matched group name
   *
   * @param group Group for which name is being returned
   */
  getMatchedGroupName(group: any): string {
    const index = this.pairs.findIndex(pair => pair.new.group.publicKey === group.group.publicKey);
    if (index !== -1) {
      return this.pairs[index].old ? this.pairs[index].old.groupName : '';
    } else {
      return '';
    }
  }

  /**
   * This function checks if all the groups are selected or not
   */
  areAllGroupsSelected(): boolean {
    return this.newGroups.filter(group => group.selected).length === this.newGroups.length;
  }
}
