<h2 mat-dialog-title>Edit Launchpad</h2>
<mat-dialog-content>
    <div *ngFor="let group of (menu$ | async)">
        <ng-container *ngIf="group.items.length > 0">
            <h1>{{group.title}}</h1>
            <div cdkDropList class="launch-pad-list" (cdkDropListDropped)="dropMenuItem($event, group.items, group.id)">
                <div class="launch-pad-item" *ngFor="let item of group.items; let i = index" cdkDrag [cdkDragData]="item">
                    <div class="launch-pad-placeholder" *cdkDragPlaceholder></div>
                    <div>{{item.title}}</div>
                    <div class="spacer-container"></div>
                    <button class="button-pin-item" [ngClass]="{'pinned-item': item.pinned && group.id !== 'pinned'}" mat-icon-button [color]="item.pinned ? 'primary' : 'basic'" [matTooltip]="item.pinned ? 'Unpin' : 'Pin for Quick Access'" (click)="item.pinned ? unpinMenuItem(item.id, item) : pinMenuItem(item.id, item)" aria-label="Pin and Unpin">
                        <mat-icon>push_pin</mat-icon>
                    </button>
                    <button *ngIf="group.id === userItemsGroupId" mat-icon-button matTooltip="Remove" (click)="removeUserItem(item, i)" aria-label="Remove Item">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-icon aria-hidden="false" aria-label="Reorder item" class="drag-handle">drag_handle</mat-icon>
                </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>Done</button>
</mat-dialog-actions>
