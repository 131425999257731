import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';

@Component({
  selector: 'app-knowledge-revisions-view',
  templateUrl: './knowledge-revisions-view.component.html',
  styleUrls: ['./knowledge-revisions-view.component.scss']
})
export class KnowledgeRevisionsViewComponent implements OnInit {

  @Input() id: string;
  @Input() groupPublicKey: string;
  @Input() currentVersion: number;
  @Output() versionSelected: EventEmitter<number> = new EventEmitter()
  
  revisions: {version: number; active: boolean}[] = null;
  loading: boolean = true;
  error: boolean = false;

  constructor(private knowledgeService: KnowledgeService) { }

  async ngOnInit() {
    try {
      const response = await this.knowledgeService.getDocumentRevisions(this.id, this.groupPublicKey, true);
      this.revisions = response.publishedVersions.map((version) => {
        return {
          version: version,
          active: this.currentVersion === version
        }
      });
      this.loading = false;
    } catch (err) {
      this.error = true;
      this.loading = false;
      console.error(err);
    }
  }

  /**
   * Close menu and emit version clicked
   */
  selectVersion(event: MatRadioChange) {
    this.versionSelected.emit(event.value);
  }
}
