import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getAllGroupTypes(): string[] {
    return [
      environment.blockConfig.types.groups.types.dynamicData,
      environment.blockConfig.types.groups.types.flowThrough,
      environment.blockConfig.types.groups.types.invitation,
      environment.blockConfig.types.groups.types.knowledge,
      environment.blockConfig.types.groups.types.normal
    ];
  }

  getAllInvitationGroupTypes(): string[] {
    return [
      environment.blockConfig.types.groups.types.dynamicData,
      environment.blockConfig.types.groups.types.invitation,
      environment.blockConfig.types.groups.types.knowledge
    ];
  }

  getDirectAddGroupTypes(): string[] {
    return [
      environment.blockConfig.types.groups.types.flowThrough,
      environment.blockConfig.types.groups.types.normal
    ];
  }
}
