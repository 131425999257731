<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-title>
      Current Members
    </mat-card-title>
    <app-spinner name="getting-preferences" show="true" *ngIf="!group">
      <h6>Getting member information...</h6>
    </app-spinner>
    <mat-list *ngIf="group && group.groupSigningMembership.length > 0">
      <mat-list-item *ngFor="let groupUser of groupUsers; trackBy: trackByPublicKey">
          <app-user-info-display matLine [publicKey]="groupUser.publicKey"></app-user-info-display>
          <button class="move-right" mat-button type="button" color="primary" (click)="removeUserFromGroup(groupUser)" *ngIf="dialogData.extra.group.groupType !== changeGroupType" [disabled]="groupUser.isRemoving">
            <mat-spinner diameter="24" *ngIf="groupUser.isRemoving"></mat-spinner>
            <span *ngIf="!groupUser.isRemoving">Remove</span>
          </button>
      </mat-list-item>
    </mat-list>
    <mat-list *ngIf="group && group.groupSigningMembership.length === 0">
      <mat-list-item>
        <div matLine>
          No Members in this Group
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>

  <mat-card>
    <mat-card-title>
      Add New Member
    </mat-card-title>
    <mat-card>
      <mat-card-content>
        <form>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Search for Users..." (keyup)="findUser($event)">
            <mat-spinner matSuffix diameter="24" *ngIf="queryLoading | async"></mat-spinner>
          </mat-form-field>
        </form>
        <mat-list *ngIf="showUserSearchResults | async">
          <mat-list-item *ngFor="let user of users; let i = index; trackBy: trackByUid">
            <div matLine>
              <span>{{user?.preferredName}}</span>
              <button class="move-right" *ngIf="!checkIfUserIsMember(user.publicKey)" mat-raised-button type="button" color="primary" (click)="addToSelectedList(user, i)">
                <mat-icon>add</mat-icon>
              </button>
              <button class="move-right" *ngIf="checkIfUserIsMember(user.publicKey)" mat-raised-button type="button" color="primary" [disabled]="true">
                  <mat-icon>check</mat-icon>
                </button>
            </div>
          </mat-list-item>
        </mat-list>

        <h4>Selected Users to Add</h4>
        <mat-list>
          <mat-list-item *ngFor="let user of selectedUsers; let i = index">
            <div matLine>
              <span>{{user?.preferredName}}</span>
              <button class="move-right" mat-raised-button type="button" color="primary" (click)="removeFromSelectedList(user, i)">
                <mat-icon>remove</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>

      <div *ngIf="dialogData.extra.group.groupType !== normalGroupType && dialogData.extra.group.groupType !== flowThroughGroupType">
        <form [formGroup]="createInvitationForm" (ngSubmit)="createInvitation()">
          <mat-form-field class="full-width-input">
            <input matInput type="text" formControlName="invitationName" placeholder="Invitation name" required>
            <mat-error *ngIf="createInvitationForm.get('invitationName').hasError('required')">
              Invitation name required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" formControlName="invitationDescription" placeholder="Invitation description" required>
            <mat-error *ngIf="createInvitationForm.get('invitationDescription').hasError('required')">
              Invitation description required
            </mat-error>
          </mat-form-field>
        </form>

        <button mat-raised-button type="button" color="primary" (click)="createInvitation()" [disabled]="selectedUsers.length === 0 || !createInvitationForm.valid">Send invitation to selected users</button>
        <mat-error *ngIf="selectedUsers.length === 0 || !createInvitationForm.valid">
          No selected users found or the invitation information has not been filled out.
        </mat-error>

      </div>

      <div *ngIf="dialogData.extra.group.groupType === normalGroupType || dialogData.extra.group.groupType === flowThroughGroupType">
        <button class="margin-left-auto" mat-raised-button type="button" color="primary" (click)="addMembersToGroup()" [disabled]="selectedUsers.length === 0 || (sending | async)">
          <mat-spinner diameter="24" *ngIf="sending | async"></mat-spinner>
          <span *ngIf="(sending | async) === null || (sending | async) === undefined || (sending | async) === false">Add Users to Group</span>
        </button>
      </div>
    </mat-card>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can't find the right user? Create invite:
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="createUserForm" (ngSubmit)="createInvitationNewUser()">
        <mat-form-field class="full-width-input">
          <input matInput type="text" formControlName="userName" placeholder="User's name" required>
          <mat-error *ngIf="createUserForm.get('userName').hasError('required')">
            Name required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="text" formControlName="userEmail" placeholder="User's email" required>
          <mat-error *ngIf="createUserForm.get('userEmail').hasError('email')">
            Email required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="text" formControlName="message" placeholder="Message to user" required>
          <mat-error *ngIf="createUserForm.get('message').hasError('required')">
            Messages required
          </mat-error>
        </mat-form-field>
        <button class="margin-left-auto" mat-raised-button color="primary" type="submit" [disabled]="!createUserForm.valid || !createSending">Send Invite</button>
      </form>
    </mat-expansion-panel>
  </mat-card>
</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button color="accent" (click)="openGroupPublicKeySnackBar()">Group Key</button>
    <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
  </div>
</div>