import { Injectable } from '@angular/core';

import { Process } from '@eva-model/process/process';
import { WorkFlowCondition } from '@eva-model/workflow';
import { workflowInteractionDest } from '@eva-ui/shared/constants';
import { Log, LogLevel, EntityType } from '@eva-model/log';
import { LogService } from '@eva-core/log/log.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessInteractionConditionService {

  constructor(
    private _logSrv: LogService,
    private _evaGlobalSrv: EvaGlobalService,
    private conditionObjectCheckerService: ConditionalObjectCheckerService
  ) {

  }

  /**
   * This function evaluates the process condition
   *
   * @param process Process to evaluate
   */
  processConditionEvaluator(process: Process): any {
    if ( ! this.validateProcessToEvaluate(process) ) return null;
    const processCondition = this.getProcessCondition(process);
    const processDestination = this.getProcessDestination(process, processCondition);

    return processDestination;
  }

  /**
   * this function validates the Process and ensures that it has an ud, a groupPublicKey, is not complete, it's status is
   * in progress and that the workflows executing and the interactions executing in it are all valid.
   *
   * @param processObject the process object to eveluate,
   */
  private validateProcessToEvaluate(processObject: Process): boolean {
    let isProcessValid = false;
    // check if the required items exist, if they do, this is valid.
    if ( processObject && processObject.id && processObject.groupPublicKey &&
      !processObject.isDone && processObject.status === "InProgress" &&
      processObject.executingWorkflowId && processObject.executingInteractionId &&
      processObject.workflows && Array.isArray(processObject.workflows) && processObject.workflows.length > 0 ) {

        const workflowIndex = processObject.workflows.map(workflow => workflow.id).indexOf(processObject.executingWorkflowId);
        if ( workflowIndex !== -1 ) {
          const interactionIndex = processObject.workflows[workflowIndex].interactions.map(interaction => interaction.interactionId)
          .indexOf(processObject.executingInteractionId);
          if ( interactionIndex !== -1 ) {
            isProcessValid = true;
          }
        }
    }
    return isProcessValid;
  }

  /**
   * This function returns the process destination
   *
   * @param process Process being evaluated
   * @param processCondition Process Condition with destinations
   * @param conditionId Id of the condition
   */
  private getProcessDestination(process: Process, processCondition: WorkFlowCondition[], conditionId?: string): any {
    let processDestination: any = null;
    if ( !conditionId ) {
      if ( processCondition && Array.isArray(processCondition) && processCondition.length > 0 ) {
        //#region :: Now that there is no condition id then get the first condition based on order!
        const conditionIndex = processCondition.map(condition => condition.order).indexOf(0);
        if ( conditionIndex !== -1 ) conditionId = processCondition[conditionIndex].id;
        // #endRegion
      } else {
        return processDestination;
      }
    }

    // const conditionString = this.getProcessConditionString(process, processCondition, conditionId);

    //#region IE issue in 'address change' workflow and undefined condition strings
    const conditionEval = this.conditionObjectCheckerService.checkIfInteractionConditionIsMet(process, processCondition, conditionId);
    // if ( conditionString && (typeof conditionString !== 'undefined' ) ) {
    //   conditionEval = this.looseConditionEval(conditionString, process.id);
    // }
    //#endregion

    processDestination = this.getDestination(process, processCondition, conditionEval, conditionId);

    if ( processDestination && processDestination.type === workflowInteractionDest.condition) {
      if ( processDestination.id ) {
        return this.getProcessDestination(process, processCondition, processDestination.id);
      }
    }

    return processDestination;
  }

  // //#region
  // /**
  //  * This function logs interaction condition string in a process
  //  *
  //  * @param process Process being evaluated
  //  * @param conditionString
  //  */
  // private logProcessConditionString(process: Process, conditionString: string): void {
  //   // Note: at this point the process has been already validated

  //   // combine the interaction condition string in the process and interaction id of processing interaction
  //   const msg = `${conditionString} :: (executing interaction id: ${process.executingInteractionId}`;
  //   // log as info with process-condition type
  //   const log = new Log(LogLevel.Info, EntityType.processCondition, process.id, msg, this._evaGlobalSrv.userId);
  //   this._logSrv.error(log).then(() => { }).catch((e) => console.log(e));
  // }
  // //#endregion

  /**
   * This function returns the interaction element value
   *
   * @param process Process to evaluate
   * @param elementId Id of the element
   */
  // private getInteractionElementValue( process: Process, elementId: string ): any {
  //   let value: any = null;
  //   if ( process && process.executingInteractionId && process.interactionsValues &&
  //     Array.isArray(process.interactionsValues) && process.interactionsValues.length > 0 ) {

  //     const interactionValueIndex = process.interactionsValues.map(interactionValue => interactionValue.interactionValues.originalId)
  //     .indexOf(process.executingInteractionId);
  //     if (interactionValueIndex !== -1 ) {
  //       const interactionValue = process.interactionsValues[interactionValueIndex];
  //       if ( interactionValue.interactionValues && interactionValue.interactionValues.elements &&
  //           Array.isArray(interactionValue.interactionValues.elements) && interactionValue.interactionValues.elements.length > 0 ) {

  //           const interactionValueElementIndex = interactionValue.interactionValues.elements
  //           .map( interactionValueElement => interactionValueElement.originalId ).indexOf(elementId);
  //           if ( interactionValueElementIndex !== -1 ) {
  //             value = interactionValue.interactionValues.elements[interactionValueElementIndex].value;
  //             if (typeof(value) === 'string') value = "`" + value + "`";
  //             if (Array.isArray(value)) {
  //               let convertedValue = null;
  //               if (value && value.length > 0 && typeof(value[0]) === 'string') {
  //                 convertedValue = '[';
  //               } else if (value && value.length > 0 && typeof(value[0]) === 'object') {
  //                 convertedValue = {};
  //               } else {
  //                 convertedValue = '';
  //               }
  //               value.forEach((stringValue, index) => {
  //                 if (typeof(stringValue) === 'string') {
  //                   convertedValue += "`" + stringValue + "`";
  //                   if (index < value.length - 1) {
  //                     convertedValue += ',';
  //                   }
  //                 } else if (typeof(stringValue) === 'object') {
  //                   convertedValue[stringValue.label] = stringValue.value;
  //                 }
  //               });
  //               if (value && value.length > 0 && typeof(value[0]) === 'string') {
  //                 convertedValue += ']';
  //               }
  //               value = convertedValue;
  //             }
  //           }
  //       }
  //     }
  //   }

  //   return value;
  // }

  /**
   * This function validates the connection operator
   *
   * @param operator Operator being validated
   */
  // private isValidConnectionOperator(operator: string): boolean {
  //   return ( operator === 'AND' || operator === 'OR' );
  // }

  /**
   * This function returns the condition connection operator from connection operator
   *
   * @param operator Operator being validated
   */
  // private getConditionConnectionOperator(operator: string): string {
  //   let conditionConnectionOperator = '';

  //   switch ( operator ) {
  //     case 'AND':
  //       conditionConnectionOperator = '&&';
  //       break;
  //     case 'OR':
  //       conditionConnectionOperator = '||';
  //       break;
  //     default:
  //       break;
  //   }

  //   return conditionConnectionOperator;
  // }

  /**
   * This function returns the process conditions
   *
   * @param processObject Process being evaluated
   */
  private getProcessCondition(processObject: Process): WorkFlowCondition[] {
    // be sure to call this method after validating the process
    let processCondition: WorkFlowCondition[] = null;
    const workflowIndex = processObject.workflows.map(workflow => workflow.id).indexOf(processObject.executingWorkflowId);
    if ( workflowIndex !== -1 ) {
      const interactionIndex = processObject.workflows[workflowIndex].interactions
      .map(interaction => interaction.interactionId).indexOf(processObject.executingInteractionId);
      if ( interactionIndex !== -1 ) {
        processCondition = processObject.workflows[workflowIndex].interactions[interactionIndex].conditions;
      }
    }

    return processCondition;
  }

  /**
   * This function returns the process condition string
   *
   * @param process Process being evaluated
   * @param processCondition Process conditions
   * @param conditionId Id of the condition
   */
  // private getProcessConditionString(process: Process, processCondition: WorkFlowCondition[], conditionId: string): string {
  //   let conditionString = "";
  //   if ( processCondition && Array.isArray(processCondition) && processCondition.length > 0 ) {

  //     const interactionConditionIndex = processCondition.map(condition => condition.id).indexOf(conditionId);
  //     const interactionCondition = processCondition[interactionConditionIndex];
  //     if (interactionCondition.condition && Array.isArray(interactionCondition.condition) && interactionCondition.condition.length > 0 ) {

  //       let conditionSetIndex = 0;
  //       for (const conditionSet of interactionCondition.condition) {
  //         let condition = "";
  //         let setConditionIndex = 0;
  //         for (const setCondition of conditionSet.conditions) {
  //           if (typeof(setCondition.value) === 'string') {
  //             condition += "(" + this.getInteractionElementValue(process, setCondition.elementOriginalId) +
  //             setCondition.operator + ("`" + setCondition.value + "`")  + ")";
  //           } else if (Array.isArray(setCondition.value)) {
  //             let convertedValue = '[';
  //             setCondition.value.forEach((stringValue, index) => {
  //               if (typeof(stringValue) === 'string') {
  //                 convertedValue += "`" + stringValue + "`";
  //                 if (index < setCondition.value.length - 1) {
  //                   convertedValue += ',';
  //                 }
  //               }
  //             });
  //             convertedValue += ']';
  //             if (this.getInteractionElementValue(process, setCondition.elementOriginalId)) {
  //               condition += "(" + convertedValue + ".every(userValue => "
  //                 + this.getInteractionElementValue(process, setCondition.elementOriginalId) + ".includes(userValue))" + ")";
  //             } else {
  //               if (setCondition.value) {
  //                 condition +=  "(" + (setCondition.value.length === 0) + ")";
  //               }
  //             }
  //           } else {
  //             if (setCondition.value && typeof(setCondition.value) === 'object') {
  //               const elementValue = this.getInteractionElementValue(process, setCondition.elementOriginalId);
  //               condition += "(" + (Object.keys(elementValue).every(checkboxLabel => elementValue[checkboxLabel]
  //                 === setCondition.value[checkboxLabel])) + ")";
  //             } else {
  //               condition += "(" + this.getInteractionElementValue(process, setCondition.elementOriginalId) +
  //                 setCondition.operator + setCondition.value  + ")";
  //             }
  //           }
  //           if ( !this.isValidConnectionOperator(setCondition.connectionOperator) ) {
  //             setCondition.connectionOperator = 'AND';
  //           }
  //           if (setConditionIndex !== conditionSet.conditions.length - 1) {
  //             condition += " " + this.getConditionConnectionOperator(setCondition.connectionOperator) + " ";
  //           }
  //           setConditionIndex++;
  //         }

  //         if ( condition ) conditionString += condition;
  //         if ( !this.isValidConnectionOperator(conditionSet.connectionOperator) ) {
  //           conditionSet.connectionOperator = 'AND';
  //         }
  //         if (conditionSetIndex !== interactionCondition.condition.length - 1) {
  //           conditionString += " " + this.getConditionConnectionOperator(conditionSet.connectionOperator) + " ";
  //         }
  //         conditionSetIndex++;
  //       }

  //     }
  //   }

  //   return conditionString;
  // }

  /**
   * This function returns the destination of the process condition with conditionId
   *
   * @param process Process being evaluated
   * @param processCondition process conditions array
   * @param conditionEval
   * @param conditionId Id of the condition for which destination is being returned
   */
  private getDestination(process: Process, processCondition: WorkFlowCondition[], conditionEval: any, conditionId: string): any {
    let destination: any = null;

    const interactionConditionIndex = processCondition.map(condition => condition.id).indexOf(conditionId);
    const interactionCondition = processCondition[interactionConditionIndex];
    const destinationCollection =
      ( typeof(conditionEval) !== typeof(true) )
      ? interactionCondition.trueDestination
      : conditionEval ? interactionCondition.trueDestination : interactionCondition.falseDestination;

    if ( destinationCollection && Array.isArray(destinationCollection) && destinationCollection.length > 0 ) {

      const interactionValueIndex = process.interactionsValues.map( interactionValue => interactionValue.interactionValues.originalId )
      .indexOf(process.executingInteractionId);
      for (const dest of destinationCollection ) {
        destination = JSON.parse(JSON.stringify(dest));
        if ( destination.type !== "group" ) break;

        //#region only for Group destinations
        let isGroupDestinationAlreadyPicked = false;
        if ( interactionValueIndex !== -1 &&
          process.interactionsValues[interactionValueIndex].conditionDestinations &&
          Array.isArray(process.interactionsValues[interactionValueIndex].conditionDestinations) &&
          process.interactionsValues[interactionValueIndex].conditionDestinations.length > 0) {

            const pickedDestinations = process.interactionsValues[interactionValueIndex].conditionDestinations;
            for ( const pickedDest of pickedDestinations ) {
              if ( pickedDest.type === "group" && pickedDest.publicKey === destination.publicKey ) {
                isGroupDestinationAlreadyPicked = true;
                break;
              }
            }

        }

        if ( !isGroupDestinationAlreadyPicked ) break;
        else destination = null;
        //#endregion
      }
    }

    return destination;
  }

  /**
   * This function returns function from condition string
   *
   * @param conditionString
   * @param processId Id of the process
   */
  // private looseConditionEval(conditionString: string, processId: string): Function {
  //   try {
  //     if ( conditionString ) {
  //       return Function('"use strict";return (Boolean(' + conditionString + '))')();
  //     } else {
  //       return  null;
  //     }
  //   } catch (err) {

  //     //#region Log the process interaction condition evaluation error
  //     // Check to see if the 'err' is an object
  //     const errMsg = (err && typeof err === 'object') ? JSON.stringify(err) : ((err && typeof err === 'string') ? err : '');
  //     const msg =
  //       `Process ${processId} failed to evaluate condition string :: ERROR :: ${errMsg}`;
  //     const log = new Log(LogLevel.Error, EntityType.process, processId, msg, this._evaGlobalSrv.userId);
  //     this._logSrv.error(log).then( () => {} ).catch( (err) => console.log(err));
  //     //#endregion

  //   }
  // }

}
