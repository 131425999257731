import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { formControlValidationPattern } from '@eva-services/form-builder/form-control-properties';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { KeysService } from '@eva-core/encryption/keys.service';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { map, take } from 'rxjs/operators';
import { FirestoreService } from '@eva-services/firestore/firestore.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {

  msgs: any[];

  grpPk: string;
  memberPks: string[];

  frmGrp: UntypedFormGroup;

  isWaiting: boolean;
  isWaitingForDelete: boolean;
  isWaitingForAddMembers: boolean;

  groups$: Observable<any[]>;
  group: any;

  subs = new Subscription();
  componentTitle: string;

  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _arrayUtils: ArrayUtilsService,
    private _invitationService: InvitationService,
    private _firestoreService: FirestoreService,
    private _groupProvider: GroupProviderService,
    private _evaGlobalService: EvaGlobalService,
    private _keysService: KeysService,
    private dialog: MatDialog,
    private _generalDialogService: GeneralDialogService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    }); }

  ngOnInit() {
    this.grpPk = this._route.snapshot.paramMap.get('pk');

    if (this._evaGlobalService.userGroupsPublicKeys &&
      Array.isArray(this._evaGlobalService.userGroupsPublicKeys) &&
      this._evaGlobalService.userGroupsPublicKeys.length > 0) {

      this.checkIfUserIsGroupMember();

    } else {

      this.prepareThenCheckIfUserIsGroupMember();

    }

  }

  checkIfUserIsGroupMember() {
    if (this._evaGlobalService.userGroupsPublicKeys &&
      Array.isArray(this._evaGlobalService.userGroupsPublicKeys) &&
      this._evaGlobalService.userGroupsPublicKeys.length > 0 &&
      this._evaGlobalService.userGroupsPublicKeys.indexOf(this.grpPk) !== -1) {

      this.getGroup(this.grpPk);
      this.createForm();

    } else {
      this.showMessage('error', `Group access denied`);
      this.group = null;
    }
  }

  prepareThenCheckIfUserIsGroupMember() {
    if (this._evaGlobalService.userPublicKey) {
      this.subs.add(
        this._evaGlobalService.userGroups$
          .subscribe(
            () => {
              setTimeout(() => {
                this.checkIfUserIsGroupMember();
              }, 500);
            }
          )
      );
    } else {
      this._keysService.getUserPublicKey()
        .then(currentUserPublicKey => {
          this._evaGlobalService.userPublicKey = currentUserPublicKey.toString();
          this.prepareThenCheckIfUserIsGroupMember();
        });
    }
  }

  getGroup(pk: string) {
    this.groups$ = this._firestoreService.col(
      'GroupSigningKeys',
      ref => ref.where(`groupPublicKey`, '==', pk)
    ).valueChanges();

    this.subs.add(
      this.groups$
        .subscribe(
          (grps) => {
            if (grps && Array.isArray(grps) && grps.length > 0) {
              this.group = grps[0];
              if (!(this.group.groupPublicKey && this.group.groupName && this.group.groupType)) {
                this.group = null;
                this.showMessage('error', `Group doesn't have expected elements, please try again!`);
              } else {
                this.componentTitle = this.group.groupName + ' Group';
              }
            } else {
              this.group = null;
              this.showMessage('error', `Group not found, please try again!`);
            }
          },
          (err) => {
            this.showMessage('error', `Group not found, please try again!`);
            console.log(err);
          }
        )
    );
  }

  showMessage(severity: string, summary: string, detail?: string) {
    this.msgs = [];
    this.msgs.length = 0;
    this.msgs.push({
      severity: severity,   // success, info, warn, error
      summary: summary,
      detail: (detail) ? detail : ''
    });
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  createForm() {
    this.frmGrp = this._fb.group({
      memberEmailsCsv: [''],
    });
  }

  //#region :: Delete members related functions
  deleteAllConfirm() {

    const dialogData = new GeneralDialogModel(
      'Group Members Removal dialog', `Are you sure to remove all group members? <br> "${this.group.groupName}"`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.deleteAllConfirmed, { that: this });
  }

  deleteAllConfirmed(data) {   // In transition method to call deleteAll
    data.that.deleteAll();
  }

  deleteAll() {

    this.memberPks = [];
    this.memberPks.length = 0;

    this.memberPks = this.group.groupSigningMembership.slice();
    if (this._evaGlobalService.processFlowThroughGroupConfig &&
      this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys &&
      Array.isArray(this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys) &&
      this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys.length > 0) {
      const elementsToDrop = this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys.slice();
      this.memberPks = this.memberPks.filter(el => elementsToDrop.indexOf(el) === -1);
    }

    if (this.memberPks.length <= 0) {
      this.showMessage('info', `No group member to delete`);
      return;
    }

    this.isWaiting = true;
    this.isWaitingForDelete = true;

    this._groupProvider.removeGroupMember(this.memberPks, this.group.groupPublicKey, this.group.groupName, this.group.groupType)
      .then(() => {
        this.isWaiting = false;
        this.isWaitingForDelete = false;
      })
      .catch(err => {
        this.isWaiting = false;
        this.isWaitingForDelete = false;
        console.log(err);
      });
  }
  //#endregion

  //#region :: Add members related functions
  addMembersConfirm() {
    const dialogData = new GeneralDialogModel(
      'Group Members Addition dialog', `Are you sure to add all the group members? <br> "${this.group.groupName}"`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.addMembersConfirmed, { that: this });
  }

  addMembersConfirmed(data) {   // In transition method to call addMembers
    data.that.addMembers();
  }

  addMembers() {

    this.memberPks = [];
    this.memberPks.length = 0;

    // const tasks$ = [];

    let memberEmailsCsv = this.frmGrp.get('memberEmailsCsv').value;
    if (!(memberEmailsCsv && memberEmailsCsv.trim().length > 0)) return;


    memberEmailsCsv = memberEmailsCsv.trim();
    let memberEmails = memberEmailsCsv.split(',');

    if (memberEmails && Array.isArray(memberEmails) && memberEmails.length > 0) {
      memberEmails = memberEmails.filter(email => email && email.trim().length > 0).map(email => email.trim());
      memberEmails = this._arrayUtils.uniqueArray(memberEmails);   // Make a unique array
    }

    this.isWaiting = true;
    this.isWaitingForAddMembers = true;

    this._firestoreService.col$('UserDirectory')
      .pipe(
        take(1),
        map((users: any[]) => {

          if (users && Array.isArray(users) && users.length > 0) {
            const usersSelected = users.filter((el) => {
              return memberEmails.indexOf(el.emailAddress) !== -1;
            });

            if (usersSelected && Array.isArray(usersSelected) && usersSelected.length > 0) {
              this.memberPks = usersSelected.map(el => el.publicKey);
            }
          }

          return this.memberPks;
        })
      )
      .subscribe(
        () => {

          if (!(this.memberPks && Array.isArray(this.memberPks) && this.memberPks.length > 0)) {
            this.isWaiting = false;
            this.isWaitingForAddMembers = false;
            this.showMessage('warn', `No eligible member to add`);
            return;
          }

          this._groupProvider.addGroupMember(this.memberPks, this.group.groupPublicKey, this.group.groupName, this.group.groupType)
            .then(() => {
              this.isWaiting = false;
              this.isWaitingForAddMembers = false;

              const patchObj = {
                memberEmailsCsv: ''
              };
              this.frmGrp.patchValue(patchObj);
            })
            .catch(err => {
              this.isWaiting = false;
              this.isWaitingForAddMembers = false;
              console.log(err);
            });
        },
        (err) => {
          this.isWaiting = false;
          this.isWaitingForAddMembers = false;
          console.log(err);
        }
      );

  }

  validateEmail(email) {
    // eslint-disable-next-line max-len
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = new RegExp(formControlValidationPattern.email);
    return re.test(String(email).toLowerCase());
  }

  isAnyMember() {
    const memberEmailsCsv: string = this.frmGrp.get('memberEmailsCsv').value;
    return (memberEmailsCsv && memberEmailsCsv.length > 0);
  }

  //#region Dialog related functions
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback, callbackData: any, callbackForFalse = null) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, { data: dialogModel });

    this.subs.add(
      this._generalDialogService.generalDialogChanged$
        .subscribe(
          changeObj => {
            if (changeObj) {
              if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
                callbackData['generalDialogOnChange'] = changeObj;
              }
            }
          },
          err => { console.log(err); }
        )
    );

    this.subs.add(
      dialogRef.afterClosed()
        .subscribe(
          (result) => {
            if (result === true) {
              if (callback) {
                callbackData.result = true;
                callback(callbackData);
              }
            } else {
              if (callbackForFalse) {
                callbackData.result = false;
                callbackForFalse(callbackData);
              }
            }
          },
          err => { console.log(err); }
        )
    );
  }
  //#endregion

}
