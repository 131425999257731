import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit,
  ComponentRef, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { SaltChatComponent } from '@eva-ui/salt-chat/saltchat.component';
import { Subscription, BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChatInteractionService } from '@eva-services/chat/process/chat-interaction.service';
import { EvaChatWorkflowsService } from '@eva-services/eva-chat-workflow/eva-chat-workflows-service.service';
import { MatDialog } from '@angular/material/dialog';
import { EvaChatWorkflowsComponent } from '@eva-ui/eva-chat-workflows/eva-chat-workflows.component';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { take} from 'rxjs/operators';
import { ConfirmWorkflowDialogComponent } from '@eva-ui/salt-chat/confirm-workflow-dialog/confirm-workflow-dialog.component';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { WindowScrollingService } from '@eva-services/window-scrolling/window-scrolling.service';
import { ScrollPosition } from '@eva-model/util/scrollPosition';
import { ChatService } from '@eva-services/chat/chat.service';
import { ProcessService } from '@eva-services/process/process.service';
import { ActivatedRoute, UrlSegment, Params, Router, NavigationEnd } from '@angular/router';
import { UrlShortenerService } from '@eva-services/url-shortener/url-shortener.service';
import { UrlDataType, UrlShortlinkData } from '@eva-model/url-shortener/urlShortenerBase';
import { KnowledgeUrlData } from '@eva-model/url-shortener/knowledge';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { KnowledgeTableOfContentsComponent } from '@eva-ui/admin-overview/knowledge/knowledge-table-of-contents/knowledge-table-of-contents.component';
import { LastStateService } from '@eva-services/last-state/last-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  componentSubs: Subscription = new Subscription();
  scrollingIndicatorSub: Subscription;

  panelWidth$: BehaviorSubject<{left: string, right: string}> = new BehaviorSubject({left: '0%', right: '100%'});
  isChatMinimized$: Observable<boolean>;
  unseenMessages$: Observable<number>;

  // Observables for creating components and injecting them into the view
  interactionStartAnnounce$ = this.chatInteractionService.announceStartingInteraction$;
  interactionFinishAnnounce$ = this.chatInteractionService.announceFinishedInteraction$;
  processStartAnnounce$ = this.chatProcessService.announceStartingProcess$;
  processFinishAnnounce$ = this.processService.processDone$;
  knowledgeCloseAnnounce$ = this.chatKnowledgeService.announceHideKnowledge$;

  isScrollable: boolean; // indicating if div is scrollable
  scrollAtBottom: boolean; // indicating if scrollable div is at max scrolling position
  leftComponentTitle: string;

  activeLeftComponent: ComponentRef<any>;
  showSavingIcon: boolean;
  savingMessage: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private chatInteractionService: ChatInteractionService,
    private evaChatWorkflowsService: EvaChatWorkflowsService,
    private chatProcessService: ChatProcessService,
    private urlShortenerService: UrlShortenerService,
    private chatKnowledgeService: ChatKnowledgeService,
    public chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private _scrollingService: WindowScrollingService,
    private processService: ProcessService,
    private menuNavigationService: MenuNavigationService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private multiViewService: MultiViewService,
    private lastStateService: LastStateService) {
      this.router.events.subscribe((event) => {
        // if navigation has settled and we are at the home screen, make sure chat comes up.
        if (event instanceof NavigationEnd && event.url === '/') {
          this.chatService.setChatMinimizedState(false);
        }
      });

      // Watches any URLs coming through and announces for knowledge
      this.componentSubs.add(
        this.urlShortenerService.listenForUrlByType(UrlDataType.KNOWLEDGE).subscribe(urlData => {
          const knowledgeUrlData = urlData as UrlShortlinkData<KnowledgeUrlData>; // set our data type so we know what we are looking at

          this.chatKnowledgeService.announceKnowledgeShow({
            docId: knowledgeUrlData.data.docId,
            docGroup: knowledgeUrlData.data.groupPublicKey,
            docVersion: knowledgeUrlData.data.version ? knowledgeUrlData.data.version : null,
            published: knowledgeUrlData.data.published ? knowledgeUrlData.data.published : false,
            sectionId: knowledgeUrlData.queryParams && knowledgeUrlData.queryParams.section ? knowledgeUrlData.queryParams.section : null,
            promptForFeedback: false
          });
        })
      );
      // Watch for launching a workflow from a query param
      this.componentSubs.add(
        this.activatedRoute.queryParams
        .pipe(
          take(1)
        )
        .subscribe(params => {
          // Query Params - Launch Workflow by ID
          const launchWorkflowId = params['wfid'];
          if (launchWorkflowId) { // if exists, check if valid
            this.launchWorkflowDialog(launchWorkflowId);
          }

          // Query Params - Launch Knowledge Doc
          // check if valid data is supplied
          if (params['type'] &&
            params['type'] === 'knowledge' &&
            params['id'] &&
            params['gpk']) {
              // we have enough information to retrieve a document.
              this.chatKnowledgeService.handleKnowledgeFromQueryParams(params);

              // clean the query params out of the url
              this.cleanQueryParamsFromUrl();
          }
        })
      );
      this.componentSubs.add(
        this.lastStateService.saveIndicator$.subscribe(data => {
          this.showSavingIcon = data.show;
          this.savingMessage = data.message;
        })
      );
    }

  // Access to our template view containers
  @ViewChild('leftPane', { read: ViewContainerRef, static: true }) leftPane: ViewContainerRef;
  @ViewChild('rightPane', { read: ViewContainerRef, static: true }) rightPane: ViewContainerRef;
  @ViewChild('rightPaneContainer', { read: ElementRef, static: true }) rightPaneContainer: ElementRef;

  ngOnInit() {
    // Initialize right pane as chat by default
    this.createRightPaneComponent<SaltChatComponent>(SaltChatComponent);

    if (this.router.url === '/') {
      this.adjustSideWidths('0%', '100%');
    } else {
      this.adjustSideWidths('60%', '40%');
    }

    // bind state of left pane if it is being shown or not
    this.isChatMinimized$  = this.chatService.isChatMinimized$;
    this.unseenMessages$ = this.chatService.unseenChatEntities$;

    // listen for finished interaction and clean up the view
    this.componentSubs.add(
      this.interactionFinishAnnounce$.subscribe(() => {
        this.destroyLeftComponentAndResetView();
      })
    );

    // listen for finished process and clean up the view
    this.componentSubs.add(
      this.processFinishAnnounce$.subscribe(() => {
        this.destroyLeftComponentAndResetView();
        this.destroyScrollIndicatorWatch();
      })
    );

    // listen for finished process and clean up the view
    this.componentSubs.add(
      this.knowledgeCloseAnnounce$.subscribe(() => {
        this.destroyLeftComponentAndResetView();
      })
    );

    /**
     * INTENT TO LOAD A WORKFLOW
     */
    this.componentSubs.add(
      this.evaChatWorkflowsService.announceShowAvailableWorkflows$.subscribe((toggle) => {

        const dialogName = 'eva-chat-workflows';
        const dialogIsOpen = this.dialog.openDialogs.find( (dialog) => dialog.id === dialogName );

        // if a dialog is open, don't open it again.
        if ( dialogIsOpen ) {
          return;
        }

        // create and show dialog
        this.dialog.open( EvaChatWorkflowsComponent, {
          height: '100vh',
          panelClass: 'workflow-picker',
          position: {
            right: '0',
            top: '0'
          },
          id: dialogName,
          closeOnNavigation: true,
          hasBackdrop: true,
          disableClose: false,
          maxWidth: 400,
          minWidth: 400
        });
      })
    );

    this.componentSubs.add(
      this.isChatMinimized$.subscribe(value => {
        if (value) {
          setTimeout(() => {
            this.rightPaneContainer.nativeElement.style.width = '0';
          }, 500);
        } else {
          this.rightPaneContainer.nativeElement.style.width = '100%';
        }
      })
    );

    /**
     * INTENT TO LOAD THE CURRENT KNOWLEDGE DOCUMENT TABLE OF CONTENTS
     */
    this.componentSubs.add(
      this.chatKnowledgeService.announceShowTableOfContents$.subscribe((state: boolean) => {
        const dialogId = 'eva-knowledge-toc';
        // get reference to dialog if it exists
        const dialogRef = this.dialog.openDialogs.find((dialog) => dialog.id === dialogId);

        // hide the current dialog if being shown and intention is to hide dialog
        if (dialogRef && !state) {
          dialogRef.close();
          return; // done.
        }

        // do nothing if dialog ref found and state intention is to not close
        if (dialogRef) {
          return;
        }

        // create and show dialog
        this.dialog.open( KnowledgeTableOfContentsComponent, {
          height: '100%',
          panelClass: 'document-table-of-contents-dialog',
          position: {
            right: '0',
            top: '80px'
          },
          id: dialogId,
          closeOnNavigation: true,
          hasBackdrop: false,
          disableClose: false,
          maxWidth: 400,
          minWidth: 400
        });
      })
    );

    // Check URL for shortlink
    this.checkUrlForShortlink();
    this.updateLeftComponentTitle({ url: this.router.url });

    this.componentSubs.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.updateLeftComponentTitle(event);
          if (event.url === '/') {
            this.adjustSideWidths('0%', '100%');
          } else {
            this.adjustSideWidths('60%', '40%');
          }
        }
      })
    );
  }

  updateLeftComponentTitle(event: NavigationEnd | { url: string }): void {
    this.router.config.forEach(configPath => {
      if (configPath.children) {
        configPath.children.forEach(child => {
          const existingPaths = child.path.split('/');
          const currentPath = event.url.substring(1).split('/');
          const dynamicIdIndex = existingPaths.findIndex(path => path.includes(':'));
          let flag = false;
          if (existingPaths.every((path, index) => path.includes(':')
            ? true
            : (currentPath.includes(path) && currentPath.slice(0, dynamicIdIndex)[index]?.includes(path)))) {
            flag = true;
          }
          if ((child.path === event.url.substring(1) && child.data) || flag) {
            this.leftComponentTitle = child.data?.componentTitle;
          }
        });
      } else {
        if (event.url.substring(1).includes(configPath.path) && configPath.data) {
          this.leftComponentTitle = configPath.data?.componentTitle;
        }
      }
    });
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();

    // remove the scroll event listener
    this.destroyScrollIndicatorWatch();
  }

  /**
   * Creates and renders a component inside the right pane. You must pass the component token as a param
   * and also define the type as the component token also.
   *
   * @param componentToken component you'd like to render, eg. `SaltChatComponent`
   */
  private createRightPaneComponent<T>(componentToken: any): ComponentRef<T> {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(componentToken);
    componentFactory.create(this.rightPane.injector);
    const compRef = this.rightPane.createComponent<T>(componentFactory);

    return compRef;
  }

  /**
   * Creates and renders a component inside the left pane. You must pass the component token as a param
   * and also define the type as the component token also.
   *
   * @param componentToken component you'd like to render, eg. `SaltChatComponent`
   */
  private createLeftPaneComponent<T>(componentToken: any): ComponentRef<T> {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(componentToken);
    componentFactory.create(this.leftPane.injector);
    const compRef = this.leftPane.createComponent<T>(componentFactory);

    return compRef;
  }

  /**
   * adjust the widths of the panes, passing in the width of the left and then the right respectively.
   *
   * @param leftWidth percentage or px you'd like to set the left pane
   * @param rightWidth percentage or px you'd like to set the right pane
   */
  adjustSideWidths = (leftWidth: string, rightWidth: string) => {

    // animate the left pane component before adjusting the widths
    const animate = true;

    if (animate && this.activeLeftComponent && leftWidth !== '0%') {
      // 1000ms transition
      this.renderer.addClass(this.activeLeftComponent.location.nativeElement.parentElement, 'left-pane-animate-state');

      // after 1000ms, remove class and set column widths
      setTimeout(() => {
        this.renderer.removeClass(this.activeLeftComponent.location.nativeElement.parentElement, 'left-pane-animate-state');
        this.chatService.setLeftPanelActiveState(true);
        this.panelWidth$.next( {left: leftWidth, right: rightWidth} );
      }, 1200);
    } else {
      // Toggle another observable true or false if left pane active.
      if (leftWidth === '0%') {
        this.chatService.setLeftPanelActiveState(false);
        this.panelWidth$.next( {left: leftWidth, right: rightWidth} );
      } else {
        this.chatService.setLeftPanelActiveState(true);
        this.panelWidth$.next( {left: leftWidth, right: rightWidth} );
      }
    }

  }

  /**
   * If the app is initialized with a `wfId` query param, shows a confirmation before the user launches into a workflow
   */
  launchWorkflowDialog(wfId: string): void {
    this.dialog.open(ConfirmWorkflowDialogComponent, {
      width: '2500px',
      data: {launchWorkflowId: wfId}
    });
  }

  /**
   * Destroys the active component on the left and then modifies the flexbox widths.
   */
  destroyLeftComponentAndResetView = (): void => {
    if (this.activeLeftComponent) {
      this.activeLeftComponent.destroy(); // destroy component
      this.activeLeftComponent = undefined;
      this.leftComponentTitle = '';

      if (this.router.url === '/') {
        this.adjustSideWidths('0%', '100%'); // reset view panel width
      }
      this.resetScrollIndicator();
    }
  }

  /**
   * Resets the scroll variables to false to hide the scrolling arrow if it is showing.
   */
  private resetScrollIndicator() {
    this.scrollAtBottom = false;
    this.isScrollable = false;
  }

  /**
   * Initializes the scrolling indicator on the left-pane so if the left pane is scrollable,
   * we can choose when to watch it (eg. Processes)
   */
  initScrollIndicatorWatch = () => {
    // initialize the scrolling service to provide a scrolling indicator
    // subscribe to the scrolling service
    this.scrollingIndicatorSub = this._scrollingService.initialize('left-pane').subscribe((scrollObj: ScrollPosition) => {
      if (scrollObj) {
        this.isScrollable = scrollObj.isScrollable;
        this.scrollAtBottom = scrollObj.isAtBottom;
      }
    });
  }

  /**
   * Destroys the scroll indicator subscription.
   */
  private destroyScrollIndicatorWatch() {
    if (this.scrollingIndicatorSub) {
      this.scrollingIndicatorSub.unsubscribe();
    }

    this.isScrollable = false;
    this.scrollAtBottom = false;
  }

  /**
   * If an action is taken through query params in the url,
   * remove the query params after the action has happened.
   */
  private cleanQueryParamsFromUrl() {
    const newURL = location.href.split("?")[0];
    window.history.pushState('object', document.title, newURL);
  }

  maximizeChat() {
    this.chatService.minimizedByUser = false;
    this.chatService.setChatMinimizedState(false);
  }

  async closeLeftRouterComponent(): Promise<void> {
    const openedTabsClose: BehaviorSubject<boolean[]> = new BehaviorSubject([]);
    if (this.activeLeftComponent) {
      this.destroyLeftComponentAndResetView();
    } else {
      if (this.multiViewService.tabs[this.router.url] && this.multiViewService.tabs[this.router.url].length > 1) {
        for (const tab of this.multiViewService.tabs[this.router.url]) {
          const canDeactivateFunction = tab.componentRef?.instance?.canDeactivate;
          const isSaving = await this.lastStateService.saveIndicator$.pipe(take(1)).toPromise();
          if (canDeactivateFunction && isSaving.show) {
            canDeactivateFunction().pipe(take(1)).subscribe(value => {
              const values = openedTabsClose.getValue();
              values.push(value);
              if (values.length === this.multiViewService.tabs[this.router.url].length) {
                openedTabsClose.next(values);
              }
            });
          } else {
            const values = openedTabsClose.getValue();
            values.push(true);
          }
        }
      } else {
        openedTabsClose.next([true]);
      }
      this.componentSubs.add(
        openedTabsClose.subscribe(value => {
          if (value.length > 0 && value.filter(keep => !keep).length === 0) {
            this.router.navigate(['/']);
            this.menuNavigationService.updateCurrentMenu('main');
            this.adjustSideWidths('0%', '100%');
          }
        })
      );
    }
  }

  /**
   * Checks the current url for a link like:
   *
   * https://atbeva.com/i/{urlId}
   *
   * If it finds something like that, get the url data and handle this type of url
   */
  private async checkUrlForShortlink() {
    // get anything in the url after the EVA base url
    const url: UrlSegment[] = await this.activatedRoute.url.pipe(take(1)).toPromise();

    // check if there are params
    if (url.length < 2) {
      // no url data so just exit out
      return;
    }

    // the params after the i is shortlink data
    if (url[0].path === 'i') {
      const routeParams: Params = await this.activatedRoute.params.pipe(take(1)).toPromise();
      const queryParams: Params = await this.activatedRoute.queryParams.pipe(take(1)).toPromise();

      // we have everything to do a notification there was a shortlink
      if (routeParams.urlId) {
        this.urlShortenerService.getUrlDataAndNotify(routeParams.urlId, queryParams);
      }
    }
  }
}
