import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer implements UrlSerializer  {
  /** Converts a `UrlTree` into a url */
  serialize(tree: UrlTree): string {
    return super.serialize(tree).replace(/\((((?!(\/\/)).)*)\)/g, '$1');
  }
}
