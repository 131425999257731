<div class="login-page">
  <div class="login-page-container">
    <mat-toolbar color="primary">
      <div class="image-spacer" (click)="goToHome()">
        <img class="image-logo"
          src="/assets/images/atb-eva-logo-300.png" alt="ATB Logo">
      </div>
    </mat-toolbar>

    <!-- <div class="top-space"></div> -->
    <div class="login-container">
      <ng-container *ngIf="(afAuth.user | async) || {} as user">
        <mat-card *ngIf="!user.uid && !emailVerified" class="top-space">
          <mat-card-title>{{formLabel}}</mat-card-title>
          <mat-card-content>

            <mat-card-actions class="googleBtn">
              <button mat-raised-button (click)="loginGoogle()">{{googleLabel}}</button>
            </mat-card-actions>

            <div class="emailDivider">
              <h4><span>OR BY EMAIL</span></h4>
            </div>

            <!-- <form [formGroup]="initForm" autocomplete="off" (ngSubmit)="signIn()" *ngIf="init === 1"> -->
            <form [formGroup]="initForm" autocomplete="off" (ngSubmit)="signIn()" *ngIf="init !== 4">
              <!-- <h5>Sign Up with Email</h5> -->
              <mat-form-field class="full-width-input">
                <input matInput type="email" placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="initForm.get('email').hasError('required')">
                  Email required
                </mat-error>
              </mat-form-field>
              <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!initForm.valid">Next ></button>
              </mat-card-actions>
            </form>

            <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="loginEmail()" *ngIf="init === 2">
              <h3>Welcome Back! Login with email:</h3>
              <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Password" formControlName="password" required>
                <mat-error *ngIf="loginForm.get('password').hasError('required')">
                  Passwords required
                </mat-error>
              </mat-form-field>
              <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Login with
                  Email</button>
              </mat-card-actions>
              <button mat-button color="accent" type="button" (click)="resetPassword()"
                [disabled]="!loginForm.valid">Forgot
                your password?</button>
            </form>

            <!-- <form [formGroup]="signupForm" autocomplete="off" (ngSubmit)="signUpEmail()" *ngIf="init === 4">
              <div class="signupForm">
                <mat-form-field class="full-width-input">
                  <input matInput type="email" placeholder="Email" formControlName="email" required>
                  <mat-error *ngIf="signupForm.get('email').hasError('required')">
                    Email required
                  </mat-error>
                  <mat-error *ngIf="signupForm.get('email').hasError('googleDomainFound')">
                    Please sign up with your Google account above
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                  <input matInput type="password" placeholder="Password" formControlName="password" required>
                  <mat-error *ngIf="signupForm.get('password').hasError('required')">
                    Passwords required
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                  <input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword"
                    [errorStateMatcher]="matcher" required>
                  <mat-error *ngIf="signupForm.get('confirmPassword').hasError('MatchPassword')">
                    Passwords do not match
                  </mat-error>
                </mat-form-field>
                <mat-card-actions>
                  <button mat-raised-button class="signupBtn" type="submit" [disabled]="!signupForm.valid">Sign
                    up</button>
                </mat-card-actions>
              </div>
            </form> -->
          </mat-card-content>
        </mat-card>

        <!-- Verification Card -->
        <mat-card *ngIf="user.uid && !emailVerified" class="verificationCard">
          <div class="verifyEmailImageSection" fxFlex="30%">
            <img class="signupProcessImage" src="../../../assets/images/encryption.png">
          </div>
          <div class="verifyEmailContentSection" fxFlex="70%">
            <mat-card-title>Pending Email Verification!</mat-card-title>
            <mat-card-content>
              <div class="greyImportantNote">
                <div fxFlex="15%">
                  <img src="../../../assets/images/warning.svg" alt="Important Note">
                </div>
                <div class="verifyNote" fxFlex="85%">
                  <p>
                    <b>Important!</b> You must verify your email to continue.
                  </p>
                </div>
              </div>
              <div class="verifyButtonSection">
                <!-- this button seems to never show, so commented out for now -->
                <!-- <button mat-raised-button type="button" color="primary" matStepperNext *ngIf="verifiedUser">Next</button> -->
                <div>
                  <button mat-raised-button type="button" color="primary" (click)="checkVerification()">I've Verified My
                    Email</button>
                </div>
                <div>
                  <button mat-stroked-button type="button" color="primary" *ngIf="!verifiedUser"
                    (click)="resendVerification()">Resend Verification Email</button>
                </div>
              </div>
            </mat-card-content>
          </div>

        </mat-card>

        <!-- Sign up process horizontal stepper -->
        <div class="signupHeader" *ngIf="user.uid && !user.acceptedTermsAndCond && emailVerified">
          <h1>Sign Up for EVA</h1>
        </div>

        <div>
          <mat-horizontal-stepper linear #step #stepper="matHorizontalStepper"
            *ngIf="user.uid && !user.acceptedTermsAndCond && emailVerified" [linear]="isLinear">
            <!-- First Step "Security Keys" -->
            <mat-step label="Security keys">
              <mat-card *ngIf="loading">
                <app-spinner name="getting-keys" show="true">
                  <h6>Plumbing the internet for information...</h6>
                </app-spinner>
              </mat-card>
              <mat-card *ngIf="!loading">
                <mat-card-content>
                  <div class="signupProcessImageSection" fxFlex="30%">
                    <img class="signupProcessImage securityContentImage" src="../../../assets/images/protection.png">
                  </div>
                  <div class="signupProcessContentSection" fxFlex="70%">
                    <mat-card-title class="signupCardTitle">Security keys</mat-card-title>
                    <div class="securityCardContent">
                      <p>When using EVA, you are protected by both <b>signing</b> and <b>encryption keys</b>.</p>
                      <p>The signing key protects your identity, the encryption key protects your data.</p>
                      <p>You can’t access any individual piece of data without both of these security keys.</p>
                      <br />
                      <p>It is the safest way to protect your data.</p>
                      <br />
                      <p>Access is enabled by <b>mnemonics</b>, a series of 12 words that function like your password.</p>
                      <br />
                      <p>To get started, set up your signing key by <b>generating your signing mnemonic</b> below.</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <button class="singlenextBtn" mat-button type="button" (click)="setPrivateKey(step)"
                    *ngIf="signingMnemonic.length === 0 && encryptionMnemonic.length === 0">Generate signing
                    mnemonic</button>
                  <button class="singlenextBtn" mat-button type="button" color="primary"
                    *ngIf="signingMnemonic.length !== 0 && encryptionMnemonic.length !== 0" matStepperNext>Next</button>
                </div>
              </div>
            </mat-step>

            <!-- Second Step "Signing Mnemonic" -->
            <mat-step label="Signing Mnemonic">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/Safe_illustration.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Signing Mnemonic</mat-card-title>
                  <mat-card-content>
                    <ng-container *ngIf="signingMnemonicForm; else noSigningMnemonicGenerated">
                      <div class="mnemonicDiv">
                        <p>{{ signingMnemonic }}</p>
                      </div>
                      <button mat-button type="button" color="accent" ngxClipboard [cbContent]="signingMnemonic"
                        (cbOnSuccess)="openSnackBarCopy()">Copy
                        To Clipboard</button>
                      <button mat-button type="button" color="accent"
                        (click)="saveToFile(signingMnemonic, 'signing-mnemonic')">Save
                        to Device</button>

                      <div class="greyImportantNote">
                        <div class="iconDiv" fxFlex=15%>
                          <img src="../../../assets/images/warning.svg" alt="Important Note">
                        </div>
                        <div fxFlex="85%">
                          <p>
                            <b>Important!</b> You must save this mnemonic somewhere safe, with the words in the correct
                            order.
                            Write it down, copy it into a document, or save the mnemonic file on your computer.
                          </p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noSigningMnemonicGenerated>
                      <p>You must generate signing and encryption mnemonics, go back to the <a
                          (click)="stepper.selectedIndex = 0;">first step</a> and generate them.</p>
                    </ng-template>
                  </mat-card-content>
                </div>
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <button mat-button class="backBtn" matStepperPrevious>Back</button>
                  <button class="nextBtn" mat-button type="button" matStepperNext>Next</button>
                </div>
              </div>
            </mat-step>

            <!-- Third Step "Encryption Key" -->
            <mat-step label="Encryption key" editable="false">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage encryptionContentImage" src="../../../assets/images/encryption.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title class="signupCardTitle">Encryption key</mat-card-title>
                  <div class="encryptionCardContent">
                    <p>Now that you’ve captured your signing key, we’ll do the same thing with your encryption key.</p>
                    <p>Your encryption key protects your data, and requires that you save your encryption mnemonic in
                      addition to your signing mnemonic.</p>
                  </div>
                </div>
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <button class="backBtn" mat-button type="button" matStepperPrevious>Back</button>
                  <button class="nextBtn" mat-button type="button" matStepperNext>Next</button>
                </div>
              </div>
            </mat-step>

            <!-- Fourth Step "Encryption Mnemonic" -->
            <mat-step label="Encryption mnemonic">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/Safe_illustration.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Encryption Mnemonic</mat-card-title>
                  <mat-card-content>
                    <ng-container *ngIf="encryptionMnemonicForm; else noEncryptionMnemonicGenerated">
                      <div class="mnemonicDiv">
                        <p>{{ encryptionMnemonic }}</p>
                      </div>
                      <button mat-button type="button" color="accent" ngxClipboard [cbContent]="encryptionMnemonic"
                        (cbOnSuccess)="openSnackBarCopy()">Copy To Clipboard</button>
                      <button mat-button type="button" color="accent"
                        (click)="saveToFile(encryptionMnemonic, 'encryption-mnemonic')">Save
                        to Device</button>

                      <div class="greyImportantNote">
                        <div class="iconDiv" fxFlex="15%">
                          <img src="../../../assets/images/warning.svg" alt="Important Note">
                        </div>
                        <div fxFlex="85%">
                          <p>
                            <b>Important!</b> You must save this mnemonic somewhere safe, with the words in the correct
                            order.
                            Write it down, copy it into a document, or save the mnemonic file on your computer.
                          </p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noEncryptionMnemonicGenerated>
                      <p>You must generate signing and encryption mnemonics, go back to the <a
                          (click)="stepper.selectedIndex = 0;">first step</a> and generate them.</p>
                    </ng-template>
                  </mat-card-content>
                </div>
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <button class="backBtn" mat-button type="button" matStepperPrevious>Back</button>
                  <button class="nextBtn" mat-button type="button" matStepperNext>Next</button><!-- nextBtn-->
                </div>
              </div>
            </mat-step>

            <!-- Fifth Step "Confirm Mnemonics" -->
            <mat-step label="Confirm mnemonics">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/illy-atb-system.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Confirm mnemonics</mat-card-title>
                  <mat-card-content>
                    <p>
                      Enter the missing words from your signing and encryption mnemonics below. We just want to make
                      sure that you’ve recorded them.
                    </p>

                    <!-- Check if the mnemonics forms are initialized -->
                    <ng-container *ngIf="signingMnemonicForm && encryptionMnemonicForm; else noMnemonicForms">

                      <h3>Signing mnemonic</h3>
                      <div class="mnemonicDiv"
                        [ngClass]="{'valid': signingMnemonicForm.valid, 'invalid': signingMnemonicForm.invalid}">
                        <form [formGroup]="signingMnemonicForm">
                          <input type="text" class="confirmMnemonics" [formControlName]="control"
                            *ngFor="let control of splitSigningMnemonic">
                        </form>
                        <!-- <p>{{ signingMnemonic }}</p> -->
                        <div class="mnemonicHelpText"
                          [ngClass]="{'valid': signingMnemonicForm.valid, 'invalid': signingMnemonicForm.invalid}">
                          <ng-container *ngIf="signingMnemonicForm.invalid">
                            Please complete the signing mnemonic to continue
                          </ng-container>
                          <ng-container *ngIf="signingMnemonicForm.valid">
                            Looks good!
                          </ng-container>
                        </div>
                      </div>

                      <h3>Encryption mnemonic</h3>
                      <div class="mnemonicDiv"
                        [ngClass]="{'valid': encryptionMnemonicForm.valid, 'invalid': encryptionMnemonicForm.invalid}">
                        <form [formGroup]="encryptionMnemonicForm">
                          <input type="text" class="confirmMnemonics" [formControlName]="control"
                            *ngFor="let control of splitEncryptionMnemonic">
                        </form>
                        <!-- <p>{{ encryptionMnemonic }}</p> -->
                        <div class="mnemonicHelpText"
                          [ngClass]="{'valid': encryptionMnemonicForm.valid, 'invalid': encryptionMnemonicForm.invalid}">
                          <ng-container *ngIf="encryptionMnemonicForm.invalid">
                            Please complete the encryption mnemonic to continue
                          </ng-container>
                          <ng-container *ngIf="encryptionMnemonicForm.valid">
                            Looks good!
                          </ng-container>
                        </div>
                      </div>

                    </ng-container>
                    <ng-template #noMnemonicForms>
                      <p>You must generate signing and encryption mnemonics, go back to the <a
                          (click)="stepper.selectedIndex = 0;">first step</a> and generate them.</p>
                    </ng-template>

                  </mat-card-content>
                </div>
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <button mat-button class="backBtn" matStepperPrevious>Back</button>
                  <button class="nextBtn" mat-button matStepperNext
                    [disabled]="(!signingMnemonicForm || !encryptionMnemonicForm) || (signingMnemonicForm.invalid || encryptionMnemonicForm.invalid)">Next</button>
                </div>
              </div>
            </mat-step>

            <!-- Sixth Step "Terms & Conditions" -->
            <mat-step [stepControl]="tacForm" label="Terms & Conditions" editable="false">
              <mat-card *ngIf="loading">
                <app-spinner name="getting-keys" show="true">
                  <h6>Processing your acceptance...</h6>
                </app-spinner>
              </mat-card>
              <mat-card *ngIf="!loading">
                <mat-card-content>
                  <div class="signupProcessImageSection" fxFlex="30%">
                    <img class="signupProcessImage tacContentImage" src="../../../assets/images/List_illustration.png"
                      alt="Terms & Conditions" />
                  </div>
                  <div class="tacContent" fxFlex="70%">
                    <h4>TERMS AND CONDITIONS</h4>
                    <p>Please read these terms of use (“Terms of Use”) carefully, as they set forth the legally binding
                      terms and conditions for your use of EVA and the services made available through EVA (the
                      “Services”) offered by ATB Financial from time to
                      time (collectively “EVA”). By using the website access to EVA (the “Site”) or EVA in any manner,
                      including but not limited to visiting or browsing the Site, you agree to be bound by these Terms of
                      Use. These Terms of Use apply to all
                      users of the Site or EVA, including users who are also contributors of content, information, and
                      other materials to EVA or the Site. These Terms of Use outline the terms and conditions pursuant to
                      which ATB Financial will provide Services
                      to you pursuant to applicable schedules (each a “Schedule”). Supplemental terms and conditions
                      applicable to a specific Service may be set out in the applicable Schedule. Each Schedule is deemed
                      to incorporate all of the terms and conditions
                      of these Terms of Use. In the event of any inconsistencies between the Terms of Use and any
                      Schedule, these Terms of Use prevail unless the Schedule specifically says otherwise.
                    </p>
                    <h5>ACCEPTANCE OF TERMS</h5>
                    <p>EVA is offered subject to acceptance without modification of all of these Terms of Use, including
                      the ATB Financial Privacy Statement hosted at atb.com, and any other document incorporated by
                      reference. Policies published on the Site or
                      through EVA from time to time or additional terms are incorporated by reference. Such policies and
                      terms may be updated from time to time by service providers, or by ATB Financial in accordance with
                      these Terms of Use. ATB Financial
                      may, in its sole discretion, refuse to offer EVA to any person or entity and change its eligibility
                      criteria at any time. By accessing and using EVA and/or any of the Services, you are deemed to
                      agree to these Terms of Use without the
                      requirement for a signature on this Agreement or any other agreement.</p>
                    <h5>MODIFICATION OF TERMS OF USE</h5>
                    <p>ATB Financial reserves the right, at its sole discretion, to modify or replace any of these Terms
                      of Use, or change, suspend or discontinue any portion of EVA (including without limitation, the
                      availability of any feature, database, or
                      content) at any time by posting a notice on the Site or by sending you an email. ATB Financial may
                      also impose limits on certain features and services or restrict your access to parts or all of EVA
                      without notice or liability. It is
                      your responsibility to check the Terms of Use periodically for changes. Your continued use of EVA
                      following the posting of any changes to the Terms of Use constitutes acceptance of those changes.</p>
                    <h5>ACCEPTABLE USE</h5>
                    <p>As a condition of use, you promise not to use EVA for any purpose that is prohibited by the Terms
                      of Use or prohibited by law. You are responsible for all of your activity in connection with EVA.
                      Additionally, you shall not: (i) take any
                      action that imposes or may impose (as determined by ATB Financial in its sole discretion) an
                      unreasonable or disproportionately large load on the EVA (or its third party providers’)
                      infrastructure; (ii) interfere or attempt to interfere
                      with the proper working of EVA or any activities conducted on EVA; (iii) bypass any measures EVA
                      may use to prevent or restrict access to EVA (or other accounts, computer systems or networks
                      connected to EVA ); or (iv) use manual or
                      automated software, devices, or other processes to “crawl” or “spider” any page of the Site, other
                      than as allowed by ATB Financial through robots.txt files or otherwise from time to time. You shall
                      not (directly or indirectly): (i)
                      decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any passwords,
                      public or private keys, source code or underlying ideas or algorithms of any part of EVA; (ii)
                      modify, translate, or otherwise create derivative
                      works of any part of EVA; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the
                      rights that you receive hereunder. You shall abide by all applicable local, provincial, federal and
                      international laws and regulations.
                    </p>
                    <h5>REGISTRATION</h5>
                    <p>You may browse the Site and view Content without registering, but as a condition to using certain
                      aspects of EVA, you may be required to register for an EVA account with ATB Financial on EVA and
                      select a password and screen name (“User
                      ID”). You shall provide accurate, complete, and updated registration information. Failure to do so
                      shall constitute a breach of the Terms of Use, which may result in immediate termination of your
                      EVA account. Your registration information
                      will be retained in EVA even if you cease to use EVA, and may be available to other EVA users who
                      have the necessary access rights. For purposes of the Terms of Use, the term “Content” includes,
                      without limitation, any information, financial
                      information, data, text, photographs, software, graphics, and interactive features generated,
                      provided, or otherwise made accessible on EVA by ATB Financial or its partners on or through EVA.
                      You shall not (i) select or use as a User
                      ID or domain a name of another person with the intent to impersonate that person; (ii) use as a
                      User ID or domain a name subject to any rights of a person other than you without appropriate
                      authorization; (iii) create, access, influence,
                      modify or otherwise use multiple User IDs; or (iv) use as a User ID or domain a name that is
                      otherwise offensive, vulgar or obscene. ATB Financial reserves the right to refuse registration of,
                      or cancel a User ID and domain in its sole
                      discretion. You are solely responsible for activity that occurs on your account and shall be
                      responsible for maintaining the confidentiality of your EVA password. You shall never use another
                      user’s account without such other user’s express
                      permission. You will immediately notify ATB Financial in writing of any unauthorized use of your
                      account, or other account related security breach of which you are aware.
                    </p>
                    <h5>THIRD PARTY SITE</h5>
                    <p>EVA may permit you to link to other websites or resources on the Internet, and other websites or
                      resources may contain links to the Site. When you access third party websites, you do so at your
                      own risk. These other websites are not under
                      ATB Financial's control, and you acknowledge that ATB Financial is not responsible or liable for
                      the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or
                      resources. The inclusion of any such
                      link does not imply endorsement by ATB Financial or any association with its operators. You further
                      acknowledge and agree that ATB Financial shall not be responsible or liable, directly or
                      indirectly, for any damage or loss caused or
                      alleged to be caused by or in connection with the use of or reliance on any such Content, goods or
                      services available on or through any such website or resource.</p>
                    <h5>CONTENT AND LICENSE</h5>
                    <p>You agree that EVA contains Content specifically provided by ATB Financial or its partners and
                      that such Content may be protected by copyrights, trademarks, patents, trade secrets or other
                      proprietary rights and laws in Canada and elsewhere.
                      You shall abide by and maintain all copyright notices, information, and restrictions contained in
                      any Content accessed through EVA.</p>
                    <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
                    <p>EVA may provide you with the ability to upload Content to the Site. You represent and warrant that
                      you have all necessary rights and licenses to submit and upload such Content to the Site, and to
                      make it available in connection with EVA.
                      By submitting Content to the Site, you (i) grant ATB Financial full unlimited unrestrained
                      ownership and rights in such Content, together with any copyright, patent, trade secret, industrial
                      design, trade-mark or other intellectual property
                      right recognized anywhere in the world and included as part of any Content that is made, prepared,
                      developed, generated, produced or acquired under or in relation to this Agreement; (ii) irrevocably
                      waive in whole all moral rights (as
                      defined in the Copyright Act (Canada)) to the Content; (iii) shall ensure that your
                      representatives, agents and subcontractors shall irrevocably waive in whole all moral rights to the
                      Content; and (iv) declare that these waivers shall
                      operate in favour of ATB Financial and ATB Financial’s successors, assigns and licensees. ATB
                      Financial grants to you a revocable license relating to the Content until such time as your EVA
                      membership is terminated, in order to continue
                      to use such Content in respect of EVA. ATB Financial does not endorse any Content. ATB Financial
                      cannot guarantee the authenticity of any data that users may provide. You acknowledge that all
                      Content accessed by you using EVA is at your
                      own risk and you will be solely responsible for any damage or loss to any party resulting
                      therefrom. ATB Financial does not guarantee that any Content will be made available on the Site or
                      through EVA. ATB Financial has no obligation
                      to monitor the Site, Service or Content. However, ATB Financial reserves the right to remove, edit
                      or modify any Content in its sole discretion, from the Site or EVA at any time, without notice to
                      you and for any reason (including, but
                      not limited to, upon receipt of claims or allegations from third parties or authorities relating to
                      such Content or if ATB Financial is concerned that you may have violated the Terms of Use). Your
                      Content will be retained in EVA even
                      if you cease to use EVA, and may be available to other EVA users who have the necessary access
                      rights
                    </p>
                    <h5>TERMINATION</h5>
                    <p>ATB Financial may terminate EVA, or your access to all or any part of EVA at any time, with or
                      without cause, with or without notice, effective immediately, which may result in the forfeiture
                      and destruction of all information associated
                      with your participation in or use of EVA. If you wish to terminate your account, you may do so by
                      following the instructions on the Site. All provisions of the Terms of Use which by their nature
                      should survive termination shall survive
                      termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity
                      and limitations of liability.</p>
                    <h5>WARRANTY DISCLAIMER</h5>
                    <p>ATB Financial has no special relationship (including any agency relationship) with or fiduciary
                      duty to you as a result of your use of EVA. You acknowledge that ATB Financial has no control over,
                      and no duty to take any action regarding:
                      which users gain access to the Site; what Content you access via the Site; what effects the Content
                      may have on you; how you may interpret or use the Content; or what actions you may take as a result
                      of having been exposed to the Content.
                      You release ATB Financial from all liability for you having acquired or not acquired Content
                      through the Site. ATB Financial makes no representations concerning any Content contained in or
                      accessed through the Site, and ATB Financial
                      will not be responsible or liable for the accuracy, copyright compliance, legality or decency of
                      material contained in or accessed through the Site or EVA. EVA is provided “as is” and “as
                      available” and is without warranty or condition
                      of any kind, express or implied, including, but not limited to, the implied warranties or
                      conditions of title, non-infringement, merchantability and fitness for a particular purpose, and
                      any warranties or conditions implied by any course
                      of performance or usage of trade, all of which are expressly disclaimed. ATB Financial, and its
                      directors, employees, agents, suppliers, partners and content providers do not warrant that: (a)
                      EVA will be secure or available at any particular
                      time or location; (b) any defects or errors will be corrected; (c) any content or software
                      available at or through EVA is free of viruses or other harmful components; or (d) the results of
                      using EVA will meet your requirements. Your
                      use of EVA is solely at your own risk. ATB Financial will not be liable for the privacy of email
                      addresses, registration and identification information, disk space, communications, confidential or
                      trade-secret information, or any other
                      Content stored on ATB Financial’s equipment, transmitted over networks accessed by the Site, or
                      otherwise connected with your use of EVA.
                    </p>
                    <h5>INDEMNIFICATION</h5>
                    <p>You shall defend, indemnify, and hold harmless ATB Financial, its affiliates and service providers
                      and each of its affiliates and its service providers respective employees, contractors, directors,
                      suppliers and representatives from all
                      liabilities, claims, and expenses, including reasonable attorneys' fees, that arise from or relate
                      to your use or misuse of, or access to, the Site, Service, Content or otherwise, violation of the
                      Terms of Use, or infringement by you,
                      or any third party using your account, of any intellectual property or other right of any person or
                      entity. ATB Financial reserves the right to assume the exclusive defense and control of any matter
                      otherwise subject to indemnification
                      by you, in which event you will assist and cooperate with ATB Financial in asserting any available
                      defenses.</p>
                    <h5>LIMITATION OF LIABILITY</h5>
                    <p>In no event shall ATB Financial, nor its directors, employees, agents, partners, suppliers or
                      content providers, be liable under contract, tort, strict liability, negligence or any other legal
                      or equitable theory with respect to EVA (i)
                      for any lost profits, data loss, cost of procurement of substitute goods or services, or special,
                      indirect, incidental, punitive, or consequential damages of any kind whatsoever, substitute goods
                      or services (however arising), (ii) for
                      any bugs, viruses, trojan horses, or the like (regardless of the source of origination), or (iii)
                      for any direct damages.</p>
                    <h5>INTERNATIONAL</h5>
                    <p>Accessing EVA is prohibited from territories where such Content is illegal. If you access EVA from
                      other locations, you do so at your own initiative and are responsible for compliance with local
                      laws.</p>
                    <h5>ELECTRONIC DELIVERY/NOTICE POLICY AND YOUR CONSENT</h5>
                    <p>ATB Financial will collect, use and disclose personal information (as defined in the applicable
                      personal protection legislation) in accordance with ATB Financial’s Privacy Policy, a copy of which
                      can be obtained at www.atb.com or at any
                      ATB Financial branch. ATB Financial may contract with third parties or agents and service providers
                      to collect, use, store, transfer or process personal information on ATB Financial’s behalf in
                      connection with any Services described
                      in this Agreement. For certain Services, you may be linked or directed to websites of, or
                      applications provided by, third party service providers. The Customer acknowledges that the use of
                      such third party service provider websites or
                      applications is subject to the terms and conditions of use and the privacy policy of such websites.
                      ATB Financial makes no warranties or conditions (express or implied) concerning the websites of
                      service providers or any product or Services
                      offered by such service provider and is not responsible for such websites or applications or any
                      acts or omissions of any service provider in providing the Services from such websites. ATB
                      Financial may, in its sole and unfettered discretion,
                      delegate any duties or rights under this Agreement to agents (including, without limitation, third
                      party sub-contractors), and ATB Financial may change such agents and the manner in which their
                      duties are assigned. The You acknowledge
                      that some of these third parties or agents may be located outside of Alberta or Canada, and your
                      information (including personal information) may be transferred or processed outside of Alberta or
                      Canada for these purposes. Personal information
                      held with a third party or agent in another province or country is subject to the laws of that
                      jurisdiction and may be collected, used or disclosed without Customer knowledge or consent where
                      required or permitted by law. </p>
                    <h5>GOVERNING LAW</h5>
                    <p>These Terms of Service (and any further rules, policies or guidelines incorporated by reference
                      herein) shall be governed by and construed in accordance with the laws of the Province of Alberta,
                      without giving effect to any principles
                      of conflicts of law. You agree that EVA (and all Services) is deemed a passive website that does
                      not give rise to personal jurisdiction over EVA or its respective parents, subsidiaries,
                      affiliates, successors, assigns, employees, agents,
                      directors, officers or shareholders, either specific or general, in any jurisdiction other than the
                      Province of Alberta, Canada. You agree that any action at law or in equity arising out of or
                      relating to these terms, or your use or
                      non-use of EVA, shall be filed only in the provincial or federal courts located in Edmonton,
                      Alberta and you hereby consent and submit to the personal jurisdiction of such courts for the
                      purposes of litigating any such action. You hereby
                      irrevocably waive any right you may have to trial by jury in any such dispute, action or
                      proceeding.
                    </p>
                    <h5>INTEGRATION AND SEVERABILITY</h5>
                    <p>The Terms of Use are the entire agreement between you and ATB Financial with respect to EVA and
                      use of the Site, and supersede all prior or contemporaneous communications and proposals (whether
                      oral, written or electronic) between you
                      and ATB Financial with respect to the Site. If any provision of the Terms of Use is found to be
                      unenforceable or invalid, that provision will be limited or eliminated to the minimum extent
                      necessary so that the Terms of Use will otherwise
                      remain in full force and effect and enforceable. The failure of either party to exercise in any
                      respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
                    </p>
                    <h5>MISCELLANEOUS</h5>
                    <p>The Terms of Use are personal to you, and are not assignable or transferable by you. ATB Financial
                      may assign, transfer or delegate any of its rights and obligations hereunder without your consent.
                      No agency, partnership, joint venture,
                      or employment relationship is created as a result of the Terms of Use and neither party has any
                      authority of any kind to bind the other in any respect. In any action or proceeding to enforce
                      rights under the Terms of Use, the prevailing
                      party will be entitled to recover costs and attorneys' fees. All notices to ATB Financial under
                      these Terms of Use must be in writing and will be deemed to have been duly given when received, if
                      personally delivered or sent by certified
                      or registered mail, return receipt requested; when receipt is electronically confirmed, if
                      transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by
                      recognized overnight delivery service. ATB Financial
                      may add, discontinue or modify any part or feature of the Services or ATB Online Business at any
                      time in its sole discretion. ATB Financial may, but is not obligated unless required by applicable
                      law, notify you of such change.Other
                      than as required by law, ATB Financial may change this Agreement (or any other Schedule) by giving
                      you notice of the change; and notice may be given before or after the change takes effect. If
                      notice is given before the change takes
                      effect, you agree and consent to the change upon the use of EVA or of any Services after the date
                      of the change. If notice is given after the change takes effect, use after the date of the notice
                      constitutes a consent and agreement to
                      such change.</p>
                    <h4>SCHEDULE</h4>
                    <h5>REPUTATION CHANGE MANAGEMENT</h5>
                    <p>This schedule (“Schedule”) supplements and forms part of the EVA Terms of Use, and incorporates by
                      reference each of the terms contained in the Terms of Use. Any terms in this Schedule which are
                      capitalized but not defined are given the
                      meaning given to them in the Terms of Use. Reputation change management (“RCM”) is a service
                      offered by ATB on EVA. A description of, and guide to using, RCM can be found on the Site and may
                      be modified by ATB from time to time on its
                      own discretion. By opting in to a change process (a “Change”) through RCM you agree to the terms
                      and conditions of this Schedule and the Terms of Use.
                    </p>
                    <h5>RCM POINTS</h5>
                    <p>ATB will hold and handle on your behalf any points (“RCM Points”) provided to or earned by you
                      through the Site in an account (an “RCM Account”). ATB will maintain records in respect of such RCM
                      Account, including without limitation a
                      record of all deposits and withdrawals. You may access records for your RCM Account on the Site.
                      ATB Financial does not that the RCM Points will have any inherent value, and shall not be liable to
                      you if your RCM Points cease to exist
                      or are removed from your RCM Account. </p>
                    <h5>FEES AND PAYMENTS</h5>
                    <p>Using RCM is free. However, there may be fees charged for certain actions or services. Please see
                      the Fees section on the Site for details on applicable fees. Fees may change from time to time, so
                      please check the Site regularly. ATB Financial
                      may choose to change the fees temporarily or permanently from time to time,, and such changes are
                      effective when ATB Financial posts the new fees on the Site.</p>
                    <h5>INTELLECTUAL PROPERTY</h5>
                    <p>By participating in RCM, you (i) grant ATB Financial full unlimited unrestrained ownership and
                      rights in any Content generated in respect of RCM, together with any copyright, patent, trade
                      secret, industrial design, trademark or other
                      intellectual property right recognized anywhere in the world and included as part of any Content
                      that is made, prepared, developed, generated, produced or acquired under or in relation to RCM;
                      (ii) irrevocably waive in whole all moral
                      rights (as defined in the Copyright Act (Canada)) to the Content; (iii) shall ensure that your
                      representatives, agents and subcontractors shall irrevocably waive in whole all moral rights to the
                      Content; and (iv) declare that these waivers
                      shall operate in favour of ATB Financial and ATB Financial’s successors, assigns and licensees. ATB
                      Financial grants to you a revocable license relating to the Content until such time as your EVA
                      membership is terminated, in order to
                      continue to use such Content in respect of EVA.</p>
                  </div>
                  <!-- <mat-checkbox class="example-margin" formControlName="tacChecked">Accept Terms and Conditions</mat-checkbox> -->
                </mat-card-content>
                <!-- </mat-expansion-panel> -->
                <!-- <mat-card-actions>
                          <button mat-button type="button" (click)="cancelTAC()">Cancel</button>
                          <div fxFlex></div>
                          <button mat-raised-button color="primary" type="submit" [disabled]="!tacForm.get('tacChecked').value" matStepperNext>Next</button>
                        </mat-card-actions> -->
                <!-- </form> -->
                <!-- </mat-card-content> -->
              </mat-card>
              <div class="step-footer">
                <div class="step-footer-inside">
                  <div class="disabled-text"
                    *ngIf="(!signingMnemonicForm || !encryptionMnemonicForm) || (signingMnemonicForm.invalid || encryptionMnemonicForm.invalid)">
                    You must generate your mnemonics and validate them before accepting the terms and conditions.
                  </div>
                  <button mat-button class="backBtn" matStepperPrevious>Back</button>
                  <button mat-button class="nextBtn" (click)="mnemonicRecorded()"
                    [disabled]="(!signingMnemonicForm || !encryptionMnemonicForm) || (signingMnemonicForm.invalid || encryptionMnemonicForm.invalid)">I
                    accept</button>
                </div>
              </div>
            </mat-step>

          </mat-horizontal-stepper>
        </div>
      </ng-container>
    </div> <!-- End Login Container -->
  </div>
</div>