import { Component } from '@angular/core';

@Component({
  selector: 'app-knowledge-upload-task',
  templateUrl: './knowledge-upload-task.component.html',
  styleUrls: ['./knowledge-upload-task.component.scss']
})
export class KnowledgeUploadTaskComponent {

  constructor() { }

}
