/**
 * This represents the flat interaction object model
 */
export class InteractionFlatModel {

    constructor(
        public name: string,                                // name of the interaction
        public id: string,                                  // GUID of the interaction
        public originalId: string,                          // original ID of the interaction
        public elements: InteractionFlatModelElement[],     // flat form elements array
        public isInteractionValid?: boolean                 // is interaction valid
    ) {}
}

/**
 * This represents the flat interaction form element model
 */
export interface InteractionFlatModelElement {
    disabled: boolean;                                      // whether form element is disabled or not
    id: string;                                             // GUID of the form element
    inputType: string;                                      // input type of the form element
    originalId: string;                                     // original ID of the form element
    prefix: string;                                         // prefix of the value
    scrnIndex: number;                                      // Index of the form screen form element belongs to
    suffix: string;                                         // suffix of the value
    type: string;                                           // type of form element
    valid: boolean;                                         // whether form element is valid or not
    value: any;                                             // value of the form element
    label: string;                                          // label of form element
}
