import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { Pair } from '@eva-model/idMap';

@Component({
  selector: 'app-id-map-interaction-element-dialog',
  templateUrl: './id-map-interaction-element-dialog.component.html',
  styleUrls: ['./id-map-interaction-element-dialog.component.scss']
})
export class IdMapInteractionElementDialogComponent implements OnInit {

  oldFormScreens: any;                                        // List of form screens for imported interaction
  newFormScreens: any;                                        // List of form screens for mapped interaction from current environment
  existingMapping: any;                                       // Existing mappings if interactions have been already mapped
  oldDynFrm: any = {};                                        // Imported Interaction object
  newDynFrm: any = {};                                        // Mapped Interaction object from current environment
  oldSpecialControls: any[] = [];                             // List of special controls from imported interaction
  newSpecialControls: any[] = [];                             // List of special controls from mapped interaction
  selectedOldScreen = 0;                                      // Selected imported interaction screen in the form visualizer
  selectedNewScreen = 0;                                      // Selected mapped interaction screen in the form visualizer
  pairs: Pair[] = [];                                         // Array containing pairs of form elements mapped by user
  isOldSpecialControlSelected = false;                        // Whether the form element selected in imported interaction is
                                                              // special control or not
  isNewSpecialControlSelected = false;                        // Whether the form element selected in mapped interaction is
                                                              // special control or not

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    public formBuilderService: FormBuilderService) {
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.oldInteraction.FormScreens &&
      Array.isArray(dialogData.dynFormMdl.oldInteraction.FormScreens)) {
      this.oldFormScreens = dialogData.dynFormMdl.oldInteraction.FormScreens;
    }
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.newInteraction.FormScreens &&
      Array.isArray(dialogData.dynFormMdl.newInteraction.FormScreens)) {
      this.newFormScreens = dialogData.dynFormMdl.newInteraction.FormScreens;
    }
  }

  ngOnInit() {
    this.oldDynFrm = this.dialogData.dynFormMdl.oldInteraction;
    if (!this.oldDynFrm) return;
    this.oldFormScreens = this.oldDynFrm.FormScreens;
    this.oldSpecialControls = this.oldDynFrm.specialControls;

    this.newDynFrm = this.dialogData.dynFormMdl.newInteraction;
    if (!this.newDynFrm) return;
    this.newFormScreens = this.newDynFrm.FormScreens;
    this.newSpecialControls = this.newDynFrm.specialControls;

    this.existingMapping = this.dialogData.dynFormMdl.existingMapping;
    if (this.existingMapping) {
      const valuePairs = Object.entries(this.existingMapping);

      this.newFormScreens.forEach((formScreen, screenIndex) => {
        if (formScreen['FormElements']) {
          formScreen['FormElements'].forEach(formElement => {
            if (this.existingMapping[formElement.id]) {
              formElement.selected = true;
              formElement.formScreenIndex = screenIndex;
            }
          });
        }
      });

      this.oldFormScreens.forEach((formScreen, screenIndex) => {
        if (formScreen['FormElements']) {
          formScreen['FormElements'].forEach(formElement => {
            valuePairs.forEach(pair => {
              if (pair[1] === formElement.id) {
                formElement.selected = true;
                formElement.formScreenIndex = screenIndex;
                let newFormElement = null;
                this.newFormScreens.forEach(screen => {
                  if (!newFormElement) {
                    newFormElement = screen['FormElements'].find(element => element.id === pair[0]);
                  }
                });
                if (!newFormElement) {
                  formElement.selected = false;
                  return;
                }
                this.pairs.unshift({
                  old: formElement,
                  new: newFormElement
                });
              }
            });
          });
        }
      });

      this.newFormScreens.forEach(formScreen => {
        if (formScreen['FormElements']) {
          formScreen['FormElements'].forEach(formElement => {
            if (!this.pairs.find(pair => pair.new && pair.new.id === formElement.id)) {
              formElement.selected = false;
            }
          });
        }
      });
    }
  }

  /**
   * This function gets the screen index when user changes the tab
   *
   * @param event Mat tab selected tab change
   */
  onOldSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedOldScreen = event.index;
  }

  /**
   * This function gets the screen index when user changes the tab
   *
   * @param event Mat tab selected tab change
   */
  onNewSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedNewScreen = event.index;
  }

  /**
   * This function selects the form element from old interaction
   *
   * @param formScreen Form screen
   * @param elementIndex Index of the element in the screen
   */
  selectOldElement(formScreen: any, elementIndex: number): void {
    formScreen.FormElements[elementIndex].selected = !formScreen.FormElements[elementIndex].selected;
    if (formScreen.FormElements[elementIndex].selected) {
      // if form element is selected, add it to the paired elements array
      if (this.isOldElementSpecialControl(formScreen, elementIndex)) {
        // if selected form element is a special control, select all special controls
        this.selectOldSpecialControl(formScreen, elementIndex);
      } else {
        formScreen.FormElements[elementIndex].formScreenIndex = this.selectedOldScreen;
        if (this.pairs[0] && !this.pairs[0].old) {
          this.pairs[0].old = formScreen.FormElements[elementIndex];
        } else {
          this.pairs.unshift({
            old: formScreen.FormElements[elementIndex],
            new: null
          });
        }
        this.isOldSpecialControlSelected = false;
      }
    } else {
      // else if form element is deselected, remove it from the paired array
      if (this.isOldElementSpecialControl(formScreen, elementIndex)) {
        // if deselected form element is a special control, deselect all special controls
        this.deselectOldSpecialControl(formScreen, elementIndex);
      } else {
        const index = this.pairs.findIndex(pair => pair.old.id === formScreen.FormElements[elementIndex].id);
        if (index !== -1) {
          if (this.pairs[index].new) {
            this.newFormScreens[this.pairs[index].new.formScreenIndex].FormElements.forEach(element => {
              if (element.id === this.pairs[index].new.id) {
                element.selected = false;
              }
            });
          }
          if (this.pairs[index].old) {
            this.oldFormScreens[this.pairs[index].old.formScreenIndex].FormElements.forEach(element => {
              if (element.id === this.pairs[index].old.id) {
                element.selected = false;
              }
            });
          }
          this.pairs.splice(index, 1);
        }
      }
    }
  }

  /**
   * This function checks if special control exists in selected form screen of new interaction
   *
   * @param oldSpecialControl special control from old interaction
   */
  newSpecialControlExists(oldSpecialControl: any): boolean {
    let flag = false;
    if (oldSpecialControl) {
      this.newSpecialControls.forEach(specialControl => {
        if (oldSpecialControl.controls.length === specialControl.controls.length) {
          specialControl.controls.forEach(control => {
            this.newFormScreens[this.selectedNewScreen].FormElements.forEach(element => {
              if (element.id === control.id) {
                flag = true;
              }
            });
          });
        }
      });
    }
    return flag;
  }

  /**
   * This function selects the form element from new interaction
   *
   * @param formScreen Form screen
   * @param elementIndex Index of the element in the screen
   */
  selectNewElement(formScreen: any, elementIndex: number): void {
    formScreen.FormElements[elementIndex].selected = !formScreen.FormElements[elementIndex].selected;
    if (formScreen.FormElements[elementIndex].selected) {
      // if form element is selected, add it to the paired elements array
      if (this.isNewElementSpecialControl(formScreen, elementIndex)) {
        // if selected form element is a special control, select all special controls
        this.selectNewSpecialControl(formScreen, elementIndex);
      } else {
        if (this.pairs[0] && !this.pairs[0].new) {
          this.pairs[0].new = formScreen.FormElements[elementIndex];
        } else {
          this.pairs.unshift({
            old: null,
            new: formScreen.FormElements[elementIndex]
          });
        }
        this.pairs[0].new.formScreenIndex = this.selectedNewScreen;
      }
    } else {
      // else if form element is deselected, remove it from the paired array
      if (this.isNewElementSpecialControl(formScreen, elementIndex)) {
        // if deselected form element is a special control, deselect all special controls
        this.deselectNewSpecialControl(formScreen, elementIndex);
      } else {
        const index = this.pairs.findIndex(pair => pair.new.id === formScreen.FormElements[elementIndex].id);
        if (index !== -1) {
          if (this.pairs[index].new) {
            this.newFormScreens[this.pairs[index].new.formScreenIndex].FormElements.forEach(element => {
              if (element.id === this.pairs[index].new.id) {
                element.selected = false;
              }
            });
          }
          if (this.pairs[index].old) {
            this.oldFormScreens[this.pairs[index].old.formScreenIndex].FormElements.forEach(element => {
              if (element.id === this.pairs[index].old.id) {
                element.selected = false;
              }
            });
          }
          this.pairs.splice(index, 1);
        }
      }
    }
  }

  /**
   * This function validates if all the selected elements are paired
   */
  isValid(): boolean {
    return this.pairs.filter(pair => !pair.new || !pair.old).length === 0;
  }

  /**
   * This function checks if new element selected is of same type as old selected element
   *
   * @param newFormElement formElement being compared
   */
  isSelectedSameNewType(newFormElement: any): boolean {
    return this.pairs[0]
      && this.pairs[0].new
      && this.pairs[0].new.type === newFormElement.type
      && this.pairs[0].new.inputType === newFormElement.inputType
      ? true : false;
  }

  /**
   * This function checks if old element selected is of same type as new selected element
   *
   * @param oldFormElement formElement being compared
   */
  isSelectedSameOldType(oldFormElement: any): boolean {
    return this.pairs[0]
      && this.pairs[0].old
      && this.pairs[0].old.type === oldFormElement.type
      && this.pairs[0].old.inputType === oldFormElement.inputType
      ? true : false;
  }

  /**
   * This function resets the dialog box to initial state
   */
  onReset(): void {
    this.oldFormScreens.forEach(formScreen => {
      formScreen.marked = false;
      formScreen.FormElements.forEach(element => {
        element.selected = false;
      });
    });

    this.newFormScreens.forEach(formScreen => {
      formScreen.marked = false;
      formScreen.FormElements.forEach(element => {
        element.selected = false;
      });
    });

    this.pairs = [];
    this.pairs.length = 0;
    this.isOldSpecialControlSelected = false;
    this.isNewSpecialControlSelected = false;
  }

  /**
   * This function selects all the old elements in the selected form screen
   *
   * @param formScreen Form Screen containing the form elements being selected
   */
  selectAllOld(formScreen: any): void {
    formScreen.marked = !formScreen.marked;
    if (formScreen.marked) {
      // if form screen is selected, add all the elements to the paired array
      formScreen.FormElements.forEach((element: any, index: number) => {
        element.selected = true;
        element.formScreenIndex = this.selectedOldScreen;
        const matchingPair = this.pairs[formScreen.FormElements.length - 1 - index];
        if (matchingPair && !matchingPair.old) {
          if (matchingPair.new && matchingPair.new.type === element.type && matchingPair.new.inputType === element.inputType) {
            matchingPair.old = element;
          } else {
            matchingPair.new.selected = false;
            element.selected = false;
            this.pairs.splice(formScreen.FormElements.length - 1 - index, 1);
          }
        } else {
          this.pairs.unshift({
            old: element,
            new: null
          });
        }
      });
    } else {
      // else if form screen is deselected, remove all elements from the paired array
      formScreen.FormElements.forEach((element: any) => {
        element.selected = false;
        const index = this.pairs.findIndex(pair => pair.old.id === element.id);
        if (index !== -1) {
          this.pairs.splice(index, 1);
        }
      });
    }
  }

  /**
   * This function selects all the new elements in the selected form screen
   *
   * @param formScreen Form Screen containing the form elements being selected
   */
  selectAllNew(formScreen: any): void {
    formScreen.marked = !formScreen.marked;
    if (formScreen.marked) {
      // if form screen is selected, add all the elements to the paired array
      formScreen.FormElements.forEach((element: any, index: number) => {
        element.selected = true;
        element.formScreenIndex = this.selectedNewScreen;
        const matchingPair = this.pairs[formScreen.FormElements.length - 1 - index];
        if (matchingPair && !matchingPair.new) {
          if (matchingPair.old && matchingPair.old.type === element.type && matchingPair.old.inputType === element.inputType) {
            matchingPair.new = element;
          } else {
            matchingPair.old.selected = false;
            element.selected = false;
            this.pairs.splice(formScreen.FormElements.length - 1 - index, 1);
          }
        } else {
          this.pairs.unshift({
            old: null,
            new: element
          });
        }
      });
    } else {
      // else if form screen is deselected, remove all elements from the paired array
      formScreen.FormElements.forEach((element: any) => {
        element.selected = false;
        const index = this.pairs.findIndex(pair => pair.new && pair.new.id === element.id);
        if (index !== -1) {
          this.pairs[index].new = null;
        }
      });
    }
  }

  /**
   * This function checks if all the old elements are selected in the selected form element
   */
  areAllOldSelected(): boolean {
    return this.oldFormScreens[this.selectedOldScreen].marked;
  }

  /**
   * This function checks if all the new elements are selected in the selected form element
   */
  areAllNewSelected(): boolean {
    return this.newFormScreens[this.selectedNewScreen].marked;
  }

  /**
   * This function checks if any old element is selected in the selected form screen
   */
  isAnyOldSelected(): boolean {
    for (let i = 0; i < this.oldFormScreens[this.selectedOldScreen].FormElements.length; i++) {
      if (this.oldFormScreens[this.selectedOldScreen].FormElements[i].selected) {
        return true;
      }
    }
    return false;
  }

  /**
   * This function checks if any new element is selected in the selected form screen
   */
  isAnyNewSelected(): boolean {
    for (let i = 0; i < this.newFormScreens[this.selectedNewScreen].FormElements.length; i++) {
      if (this.newFormScreens[this.selectedNewScreen].FormElements[i].selected) {
        return true;
      }
    }
    return false;
  }

  /**
   * This function selects the special controls from old interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  selectOldSpecialControl(formScreen: any, elementIndex: number): void {
    let flag = false;
    let index = 0;
    this.oldSpecialControls.forEach(specialControl => specialControl.controls.forEach((control: any) => {
      if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
        return;
      }
      formScreen.FormElements.forEach((element: any) => {
        if (element.id === control.id) {
          element.selected = true;
          element.formScreenIndex = this.selectedOldScreen;
          if (this.pairs[specialControl.controls.length - 1 - index] && !flag
            && this.pairs[specialControl.controls.length - 1 - index].new && !this.pairs[specialControl.controls.length - 1 - index].old) {
            this.pairs[specialControl.controls.length - 1 - index].old = element;
          } else {
            flag = true;
            this.pairs.unshift({
              old: element,
              new: null
            });
          }
          index++;
        }
      });
    }));
    this.isOldSpecialControlSelected = true;
  }

  /**
   * This function deselects the special controls from old interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  deselectOldSpecialControl(formScreen: any, elementIndex: number): void {
    let newElementId = null;
    this.oldSpecialControls.forEach(specialControl => specialControl.controls.forEach(control => {
      if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
        return;
      }
      formScreen.FormElements.forEach(element => {
        if (element.id === control.id) {
          element.selected = false;
          const index = this.pairs.findIndex(pair => pair.old.id === element.id);
          if (index !== -1) {
            newElementId = this.pairs[index].new && this.pairs[index].new.id;
            this.pairs.splice(index, 1);
          }
        }
      });
    }));
    this.isOldSpecialControlSelected = false;
    this.isNewSpecialControlSelected = false;
    this.newSpecialControls.forEach(specialControl => {
      specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === newElementId)) {
          return;
        }
        this.newFormScreens[this.selectedNewScreen].FormElements.forEach(element => {
          if (element.id === control.id) {
            element.selected = false;
          }
        });
      });
    });
  }

  /**
   * This function checks if the selected element is a special control in the old interaction in selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  isOldElementSpecialControl(formScreen: any, elementIndex: number): boolean {
    let flag = false;
    this.oldSpecialControls.forEach(specialControl => specialControl.controls.forEach(control => {
      if (control.id === formScreen.FormElements[elementIndex].id) {
        flag = true;
      }
    }));
    return flag;
  }

  /**
   * This function selects the special controls from new interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  selectNewSpecialControl(formScreen: any, elementIndex: number): void {
    let flag = false;
    let index = 0;
    this.newSpecialControls.forEach(specialControl => specialControl.controls.forEach((control: any) => {
      if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
        return;
      }
      formScreen.FormElements.forEach((element: any) => {
        if (element.id === control.id) {
          element.selected = true;
          element.formScreenIndex = this.selectedNewScreen;
          if (this.pairs[specialControl.controls.length - 1 - index] && !flag
            && this.pairs[specialControl.controls.length - 1 - index].old && !this.pairs[specialControl.controls.length - 1 - index].new) {
            this.pairs[specialControl.controls.length - 1 - index].new = element;
          } else {
            flag = true;
            this.pairs.unshift({
              old: null,
              new: element
            });
          }
          index++;
        }
      });
    }));
    this.isNewSpecialControlSelected = true;
  }

  /**
   * This function deselects the special controls from new interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  deselectNewSpecialControl(formScreen: any, elementIndex: number): void {
    let oldElementId = null;
    this.newSpecialControls.forEach(specialControl => specialControl.controls.forEach(control => {
      if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
        return;
      }
      formScreen.FormElements.forEach(element => {
        if (element.id === control.id) {
          element.selected = false;
          const index = this.pairs.findIndex(pair => pair.new.id === element.id);
          if (index !== -1) {
            oldElementId = this.pairs[index].old && this.pairs[index].old.id;
            this.pairs.splice(index, 1);
          }
        }
      });
    }));
    this.isNewSpecialControlSelected = false;
    this.isOldSpecialControlSelected = false;
    this.oldSpecialControls.forEach(specialControl => {
      specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === oldElementId)) {
          return;
        }
        this.oldFormScreens[this.selectedOldScreen].FormElements.forEach(element => {
          if (element.id === control.id) {
            element.selected = false;
          }
        });
      });
    });
  }

  /**
   * This function checks if the selected element is a special control in the new interaction in selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  isNewElementSpecialControl(formScreen: any, elementIndex: number): boolean {
    let flag = false;
    this.newSpecialControls.forEach(specialControl => specialControl.controls.forEach((control: any) => {
      if (control.id === formScreen.FormElements[elementIndex].id) {
        flag = true;
      }
    }));
    return flag;
  }

  /**
   * This function disables the Mat Tab in old section based on user selection
   *
   * @param oldTab current tab
   */
  disableOldMatTab(oldTab: MatTab): boolean {
    return (
      // disable old mat tab
      // if old select all button is clicked and not all elements are paired
      (this.areAllOldSelected() && !this.isValid())
      // or if old element is selected but not paired with new element
      || (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new)
      // and it's not the current selected tab
    ) && !oldTab.isActive;
  }

  /**
   * This function disables the Mat Tab in new section based on user selection
   *
   * @param newTab current tab
   */
  disableNewMatTab(newTab: MatTab): boolean {
    return (
      // disable new mat tab
      // if new select all button is clicked and not all elements are paired
      (this.areAllNewSelected() && !this.isValid())
      // or if new element is selected but not paired with old element
      || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
      // and it's not the current selected tab
    ) && !newTab.isActive;
  }

  /**
   * This function disables the select all button in old section
   */
  disableOldSelectAllButton(screenIndex: number): boolean {
    // disable the old select all button
    // if selected old form screen index is not equal to selected new form screen index
    return this.selectedOldScreen !== this.selectedNewScreen
    // or if selected old form screen's form elements length is not equal to selected new form screen's form elements length
    || this.oldFormScreens[this.selectedOldScreen].FormElements.length !== this.newFormScreens[this.selectedNewScreen].FormElements.length
    // or if current screen index is not equal to selected old screen
    || screenIndex !== this.selectedOldScreen
    // or if any pair exists and old select all and new select all buttons are not clicked
    || (this.pairs.length > 0 && !this.areAllOldSelected() && !this.areAllNewSelected());
  }

  /**
   * This function disables the select all button in new section
   *
   * @param screenIndex Index of the form screen in current interaction selected
   */
  disableNewSelectAllButton(screenIndex: number): boolean {
    // disable the new select all button
    // if selected old form screen index is not equal to selected new form screen index
    return this.selectedOldScreen !== this.selectedNewScreen
    // or if selected old form screen's form elements length is not equal to selected new form screen's form elements length
    || this.oldFormScreens[this.selectedOldScreen].FormElements.length !== this.newFormScreens[this.selectedNewScreen].FormElements.length
    // or if current screen index is not equal to selected new screen
    || screenIndex !== this.selectedNewScreen
    // or if any pair exists and new select all and old select all buttons are not clicked
    || (this.pairs.length > 0 && !this.areAllNewSelected() && !this.areAllOldSelected());
  }

  /**
   * This function disables the form element in old section
   *
   * @param formElement current form element
   * @param elementIndex index of the form element
   * @param screen form screen object
   */
  disableOldFormElement(formElement: any, elementIndex: number, screen: any): boolean {
    const oldSpecialControl =  this.oldSpecialControls.find(specialControl =>
      specialControl.controls.find(control => control.id === formElement.id));
    const newSpecialControl = this.newSpecialControls.find(specialControl =>
      specialControl.controls.find(control =>
        control.id === (this.pairs[0] && this.pairs[0].new ? this.pairs[0].new.id : '')));
    return (
      // disable old form element
      // if selected new form element is not of same type
      // and new select all button is not clicked
      // and any new form element is selected
      // and pair exists
      // but either old element or new element doesn't exist in pair
      // and current old element is not a special control element
      // and current old form element is not selected
      !this.isSelectedSameNewType(formElement)
      && !this.areAllNewSelected()
      && this.isAnyNewSelected()
      && this.pairs[0]
      && (!this.pairs[0].old || !this.pairs[0].new)
      && !this.isOldElementSpecialControl(screen, elementIndex)
      && !formElement.selected
    )
    // or if new select all button is clicked and old select all button is not clicked
    || (this.areAllNewSelected() && !this.areAllOldSelected())
    // or if current old form element is not a special control and new special control form element is selected
    // and old form element doesn't exist in the pair and old special control is not selected
    || (!this.isOldElementSpecialControl(screen, elementIndex) && this.isNewSpecialControlSelected && !this.pairs[0].old
      && !this.isOldSpecialControlSelected)
    || (this.isOldElementSpecialControl(screen, elementIndex) && this.isNewSpecialControlSelected
      && this.pairs[0].new && !this.pairs[0].old
      && (newSpecialControl && newSpecialControl.controls ? newSpecialControl.controls.length : 0)
      !== (oldSpecialControl && oldSpecialControl.controls ? oldSpecialControl.controls.length : 0))
    // or if any old element is selected and pair exists but new form element doesn't exist in pair
    || ((this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new))
      && (this.pairs[0] && this.pairs[0].old && formElement.id !== this.pairs[0].old.id) && !this.areAllOldSelected())
    // or if any new element is selected and pair exists but old form element doesn't exist in pair and current form element is selected
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old) && formElement.selected)
    // or if any new element is selected and pair exists but old form element doesn't exist in pair
    // and current form element is a special control element and new special control element is selected
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old) && this.isOldElementSpecialControl(screen, elementIndex)
      && !this.isNewSpecialControlSelected);
  }

  /**
   * This function disables the form element in new section
   *
   * @param formElement current form element
   * @param elementIndex index of the form element
   * @param screen form screen object
   */
  disableNewFormElement(formElement: any, elementIndex: number, screen: any): boolean {
    const newSpecialControl =  this.newSpecialControls.find(specialControl =>
      specialControl.controls.find(control => control.id === formElement.id));
    const oldSpecialControl = this.oldSpecialControls.find(specialControl =>
      specialControl.controls.find(control =>
        control.id === (this.pairs[0] && this.pairs[0].old ? this.pairs[0].old.id : '')));
    return (
      // disable new form element
      // if selected old form element is not of same type
      // and old select all button is not clicked
      // and any old form element is selected
      // and pair exists
      // but either old element or new element doesn't exist in pair
      // and current new element is not a special control element
      // and current nwe form element is not selected
      !this.isSelectedSameOldType(formElement)
      && !this.areAllOldSelected()
      && this.isAnyOldSelected()
      && this.pairs[0]
      && (!this.pairs[0].old || !this.pairs[0].new)
      && !this.isNewElementSpecialControl(screen, elementIndex)
      && !formElement.selected
    )
    // or if old select all button is clicked and new select all button is not clicked
    || (this.areAllOldSelected() && !this.areAllNewSelected())
    // or if current new form element is not a special control and old special control form element is selected
    // and new form element doesn't exist in the pair and new special control is not selected
    || (!this.isNewElementSpecialControl(screen, elementIndex) && this.isOldSpecialControlSelected && !this.pairs[0].new
      && !this.isNewSpecialControlSelected)
    || (this.isNewElementSpecialControl(screen, elementIndex) && this.isOldSpecialControlSelected
      && this.pairs[0].old && !this.pairs[0].new
      && (newSpecialControl && newSpecialControl.controls ? newSpecialControl.controls.length : 0)
      !== (oldSpecialControl && oldSpecialControl.controls ? oldSpecialControl.controls.length : 0))
    // or if any new element is selected and pair exists but old form element doesn't exist in pair
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old))
      && (this.pairs[0] && this.pairs[0].new && formElement.id !== this.pairs[0].new.id)
    // or if any old element is selected and pair exists but new form element doesn't exist in pair and current form element is selected
    || (this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new) && formElement.selected)
    // or if any old element is selected and pair exists but new form element doesn't exist in pair
    // and current form element is a special control element and old special control element is selected
    || (this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new) && this.isNewElementSpecialControl(screen, elementIndex)
      && !this.isOldSpecialControlSelected);
  }

  /**
   * This function returns the string equivalent of the current environment based on window url
   */
  getCurrentEnvironment(): string {
    const url = window.location.href;
    let environmentName = '';

    // set the environment based on url
    if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
      environmentName = 'Development';
    } else if (url.includes('staging.atbeva.com')) {
      environmentName = 'Staging';
    } else if (url.includes('test.atbeva.com')) {
      environmentName = 'Test';
    } else if (url.includes('atbeva.com')) {
      environmentName = 'Production';
    }

    return environmentName;
  }
}
