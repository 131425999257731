
<ng-container *ngIf="(userData$ | async) as userData">
  <div *ngIf="userData.length > 0"
        matTooltip = '{{ this.toolTipContent }}'
        matTooltipClass = 'userInfoTooltip'
        [matTooltipHideDelay]="400"
        aria-label="User info">
        <strong>{{ user.preferredName }}</strong> ({{ user.emailAddress }}) <mat-icon>info</mat-icon>
  </div>
  <div *ngIf="userData.length === 0">
    User Unknown
  </div>
</ng-container>