<h2 mat-dialog-title>
    <div class="flex">
        <div>Your Launchpad</div>
        <div class="spacer"></div>
        <div><button mat-button (click)="editMenu()" color="primary">EDIT</button></div>
    </div>
</h2>
<mat-dialog-content>
    <div *ngFor="let group of (menu$ | async)">
        <ng-container *ngIf="group.items.length > 0">
            <h1>{{group.title}}</h1>
            <mat-card *ngFor="let item of group.items" (click)="launchPadService.doAction(item)" mat-dialog-close>
                <div class="flex">
                    <div *ngIf="item.icon" class="launch-pad-icon">
                        <svg [ngClass]="item.icon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="item.icon.viewBox">
                            <path *ngFor="let path of item.icon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
                        </svg>
                    </div>
                    <div class="launch-pad-label">
                        {{item.title}} <!-- <span *ngIf="item.badgeCount && item.badgeCount > 0" class="nav-link-badge">{{ item.badgeCount }}</span> -->
                    </div>
                </div>
            </mat-card>
        </ng-container>
    </div>
</mat-dialog-content>
