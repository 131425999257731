<h1 mat-dialog-title>Tips</h1>
<mat-divider></mat-divider>
<div mat-dialog-content class="padding-top">
    1. If the <b>If</b> side is valid, only then the <b>Then</b> side will be evaluated.<br/>
    For e.g., If the condition is <b>If Age is less than 18 Then disable password</b>, in this case, if the <b>Age</b> is less than 18 only then the password field will be disabled.
    <br/><br/>
    2. If the <b>Then Action</b> is <b>Must Be</b>, then both <b>If</b> and <b>Then</b> conditions must be valid, otherwise user will not be allowed to go to the next interaction as the <b>Next</b> button on the interaction will be disabled.
    <br/><br/>
    3. Legacy conditions have been translated to <b>If <i>Element</i> is equal to <i>Value</i> Then disable <i>Another Element</i></b>.
    <br/><br/>
    4. On <b>If</b> side, <b>AND</b> connector needs both <b>If</b> conditions to be valid for <b>Then</b> condition to evaluate. <b>OR</b> connector needs any one of the <b>If</b> conditions to be valid for <b>Then</b> condition to evaluate.
    <br/><br/>
    5. Conditions are evaluated in order from top to bottom, so last condition will have the highest priority.
    <br/><br/>
    6. There are 2 ways the conditions can be combined:<br/>
    <div style="margin-left: 40px;">
        i. Using the <b>AND</b>/<b>OR</b> connectors on <b>If</b> side. By doing this, the condition will look like:<br/>
        <b>If Age is less than 18 and Name is not empty Then disable password</b>.<br/><br/>
        ii. Using the <b>AND</b>/<b>OR</b> connectors between 2 conditions. By doing this, multiple set of conditions can be combined to perform the same or different <b>Then</b> action, the condition will look like:<br/>
        <b>If Age is less than 18 and Name is not empty Then disable password or<br/>
        If Name is John Doe and Age is 25 Then disable password</b>.<br/>
        When using the <b>AND</b> connector in this case, both <b>If</b> and <b>Then</b> conditions need to be valid for first condition, in order for the second condition to evaluate.
        <br/><br/>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <div class="button-container">
      <button mat-stroked-button (click)="onNoClick()">Close</button>
    </div>
</div>