<div class="chat-full-messages">
  <div *ngFor="let chatEntity of chatService.chatEntities$ | async; let i = index;" class="chat-message-container" [ngClass]="{'chat-message-user': chatEntity.author === chatAuthor.user, 'chat-message-eva': chatEntity.author === chatAuthor.eva}" [@inOutAnimation]>
    <div class="chat-message-inside">
      <app-chat-loading *ngIf="chatEntity.type === chatType.loading"></app-chat-loading>
      <app-chat-message *ngIf="chatEntity.type === chatType.text" [chatEntity]="chatEntity"></app-chat-message>
      <app-chat-interaction-message *ngIf="chatEntity.type === chatType.interactionResponse" [metadata]="chatEntity.metadata" [chatEntity]="chatEntity" [chatId]="chatEntity.id" (contentChange)="scrollToBottom()"></app-chat-interaction-message>
      <app-chat-ml *ngIf="chatEntity.type === chatType.knowledgeResponse" [dialogflowResponse]="chatEntity.componentData.lastState" [data]="chatEntity.componentData.data"></app-chat-ml>
      <app-chat-ml-training *ngIf="chatEntity.type === chatType.knowledgeResponseTraining" [lastState]="chatEntity.componentData" [data]="chatEntity.componentData.data" [chatId]="chatEntity.id"></app-chat-ml-training>
      <app-chat-redirect *ngIf="chatEntity.type === chatType.routeRedirect" [chatEntity]="chatEntity" [data]="chatEntity.componentData"></app-chat-redirect>
    </div>
  </div>
</div>
<div id="chatBottomAnchor" #chatBottomAnchor class="bottom-anchor"></div>