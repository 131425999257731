import { Component, Input } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'eva-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input() notifyObj: any;

  constructor(
    public evaGlobalService: EvaGlobalService
  ) { }

}
