import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, catchError, take, filter, pluck, tap } from 'rxjs/operators';

import { Guid } from '@eva-core/GUID/guid';
import { environment } from '@environments/environment';
import { Process, ProcessInteractionValues, ProcessAnnouncemenMessage, ProcessSummaryMessage } from '@eva-model/process/process';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { WorkflowService } from '@eva-services/workflow/workflow.service';
import { UserService } from '@eva-services/user/user.service';
import { ProcessEdit } from '@eva-model/process/processEdit';
import { WorkFlow } from '@eva-model/workflow';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  private getProcessesByGroupUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getProcessesByGroup';
  private getProcessUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getProcess';

  //#region "Process Done" subject as observable to emit and subscribe to
  private processDoneSource = new Subject<ProcessAnnouncemenMessage>();
  public processDone$ = this.processDoneSource.asObservable();
  //#endregion

  private processCancelSource = new Subject<boolean>();
  public processCancel$ = this.processCancelSource.asObservable();

  //#region "Process Summary Done" subject as observable to emit and subscribe to
  private processSummaryDoneSource = new Subject<ProcessSummaryMessage>();
  public processSummaryDone$ = this.processSummaryDoneSource.asObservable();
  //#endregion

  //#region "Process Edit Summary Done" subject as observable to emit and subscribe to
  private processEditSummaryDoneSource = new Subject<ProcessEdit>();
  public processEditSummaryDone$ = this.processEditSummaryDoneSource.asObservable();
  //#endregion

  constructor(
    private http: HttpClient,
    private workflowService: WorkflowService,
    private evaGlobalService: EvaGlobalService,
    private userService: UserService,
    private chatProcessService: ChatProcessService,
    private perf: AngularFirePerformance,
    private multiViewService: MultiViewService) {
    // if a process is done, emit to the ChatProcessService so we can alter the view

    // commented out because we are listening to lastState changes for this announcement instead.
    // this.processDone$.subscribe(message => this.chatProcessService.announceProcessFinish(message.processId));
  }


  //#region Announce methods through rxjs subjects to enable communication between process related components

  /**
   * This announces a process is done to the process Done source behavior subject.
   *
   * @param data the announcement for the process message
   */
  announceProcessDone(message: ProcessAnnouncemenMessage) {
    this.processDoneSource.next(message);
  }

  announceProcessCancel() {
    this.processCancelSource.next(true);
  }

  /**
   * This announces a message to the process summary done behavior subject.
   * @param message the Process Summary Done message
   */
  announceProcessSummaryDone(message: ProcessSummaryMessage) {
    this.processSummaryDoneSource.next(message);
  }

  /**
   * @param data a process object to be submitted or edited
   *
   * method to announce a process is being edited or submitted
   */
  announceProcessEditSummaryDone(data: ProcessEdit): void {
    this.processEditSummaryDoneSource.next(data);
  }
  //#endregion


  /**
   * This gets all processes by group public key from ATB interactions and returns them to EVA.
   *
   * @param groupPublicKey this is the group public key to get all processes
   * @param isDone whether the process is done or in procgress.
   */
  fetchProcessesByGroup(groupPublicKey: string, isDone?: boolean): Observable<Process[]> {
    groupPublicKey = groupPublicKey.trim();

    // Add safe, URL encoded search parameter if there is a search term
    let paramsOption = new HttpParams().set('groupPublicKey', groupPublicKey);
    if ( isDone !== undefined ) {
      paramsOption = paramsOption.set('isDone', isDone.toString());
    }

    const options = {
      params: paramsOption
    };

    return this.http.get<Process[]>(this.getProcessesByGroupUrl, options).pipe(
      trace('process-fetchProcessesByGroup')
    );
  }

  /**
   * This fetches an individual process from ATB interactions.
   * @param processId the process id to fetch.
   */
  fetchProcess(processId: string): Observable<Process> {
    processId = processId.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('processId', processId);

    const options = {
      params: paramsOption
    };

    return this.http.get<Process>(this.getProcessUrl, options).pipe(
      trace('process-fetchProcess')
    );
  }

  /**
   * This exists to take the data from an existing document record and convert / map it to a process object
   * for use in EVA.
   *
   * @param processObj Should be the shape of a process document object (same as a Process Shape)
   */
  processObjectMapper(processObj: Process): Process {
    if (!processObj ) return null;

    // this creates the process instance
    const process = new Process(
      processObj.id ? processObj.id : Guid.newGuid().toString(),
      (processObj.name) ? processObj.name : '',
      processObj.submitterPublicKey,
      processObj.acknowledgerPublicKey,
      processObj.destinationPublicKey,
      processObj.groupPublicKey, [], [],
      processObj.executingWorkflowId,
      processObj.executingInteractionId, [],
      processObj.status,
      processObj.isDone,
      processObj.lastUpdatePublicKey,
      processObj.createTimestamp,
      processObj.lastUpdateTimestamp,
      (processObj.notes) ? processObj.notes : '',
      (processObj.storageId) ? processObj.storageId : ''
    );

    // transfers all array items to the new process instance
    if (processObj.descriptions && Array.isArray(processObj.descriptions) && processObj.descriptions.length > 0) {
      processObj.descriptions.forEach( desc => { process.descriptions.push(desc); });
    }

    if (processObj.workflows && Array.isArray(processObj.workflows) && processObj.workflows.length > 0) {
      processObj.workflows.forEach( workflow => {
        process.workflows.push(JSON.parse(JSON.stringify(workflow)));
      });
    }

    // check if interaction values exists, if they do map and put these values into the process instance.
    if (processObj.interactionsValues && Array.isArray(processObj.interactionsValues) && processObj.interactionsValues.length > 0) {
      processObj.interactionsValues.forEach( intrctValue => {
        const prcsIntrctValue = new ProcessInteractionValues(
          intrctValue.workflowId,
          intrctValue.interactionId,
          intrctValue.interactionValues = JSON.parse(JSON.stringify(intrctValue.interactionValues)),
          intrctValue.conditionDestinations,
          intrctValue.order = intrctValue.order);

        process.interactionsValues.push(prcsIntrctValue);
      });
    }

    return process;
  }

  /**
   * This initializes and ematy process instance with empty values.
   */
  initializeNewProcess(): Process {
    const dt = Date.now();

    const process = new Process(
      Guid.newGuid().toString(), '',
      this.evaGlobalService.userPublicKey, null, null,
      '', [], [], '', '', [], 'Created', false, '', dt, null, '', '' );

    return process;
  }

  /**
   * This initializes a process based on the current active version  of a workflow if one is not provided. Otherwise it
   * initializes the process by the workflowId and the specific version.
   *
   * @param workflowId the workflow Id.
   * @param processName the name of the process.
   * @param workflowVersion the workflow version number to initialize
   */
  async initializeProcess(workflowId: string, processName: string, workflowVersion?: number)
    : Promise<{processId: string, tabIndex: number, process: Process}> {
    // get the current date.
    const dt = Date.now();
    // update this to correct tabIndex from multiViewService
    let tabIndex = 1;
    if (this.multiViewService.tabs[Routes.Process]) {
      tabIndex = this.multiViewService.tabs[Routes.Process].length;
    }

    // create an empty process
    const process = new Process(
      Guid.newGuid().toString(), processName,
      this.evaGlobalService.userPublicKey, null, null,
      '', [], [], '', '', [], 'Created', false, '', dt , null, '', '' );

    try {
      let workflowForProcess: WorkFlow = null;
      workflowForProcess = await this.chatProcessService.getWorkflowById$(workflowId).pipe(
        take(1),
        trace('process-initializeProcess')
        ).toPromise();

      if ( workflowForProcess ) {
        process.workflows.push(workflowForProcess);
        process.groupPublicKey = workflowForProcess.groupPublicKey;
      }
      // store the process to the user object.
      // await this.userService.userProcessUpsert(process, false);
      return {processId: process.id, tabIndex: tabIndex, process: process};
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
}
