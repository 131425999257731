<!-- Revision Verions -->
<app-spinner *ngIf="loading && !error" name="getting-document-revisions" show="true"></app-spinner>
<mat-nav-list *ngIf="!loading && !error">
  <h3 mat-subheader>Select a Revision ({{revisions.length}})</h3>
  <ng-container *ngIf="revisions; else noRevisions">
    <mat-radio-group [(ngModel)]="currentVersion" class="knowledge-revisions-radio-group" (change)="selectVersion($event)">
      <mat-radio-button *ngFor="let revision of revisions" [value]="revision.version">
        {{ revision.version | date:'MMM d, y, h:mm a' }}
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <ng-template #noRevisions>
    <mat-list-item>
      <h4 mat-line>No revisions found for this document</h4>
    </mat-list-item>
  </ng-template>
</mat-nav-list>
<!-- Just incase we dont have version information for some reason -->
<ng-container *ngIf="error && !loading">
  <mat-list>
    <mat-list-item>
      Failed to get document revisions. Please try again.
    </mat-list-item>
  </mat-list>
</ng-container>
