export class WorkFlowInteractionCondition {

    constructor(
        public elementOriginalId: string,               // id of the element
        public operator: string,                        // operator selected
        public value: any,                              // value of the condition
        public scrnIdx: number,                         // index of the screen
        public elmntIdx: number,                        // index of the element
        public connectionOperator?: string,             // connection operator selected - and / or
        public hovered?: boolean,                       // whether condition is hovered or not
        public secondValue?: any                        // second value of the condition
    ) { }

}
