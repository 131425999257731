import { WorkFlow } from "@eva-model/workflow";
import { InteractionMapped } from "@eva-model/interaction/interactionMapped";
import { Process } from "@eva-model/process/process";

export enum InteractionAnnouncementStatus {
    CANCELLED = 'cancelled',
    COMPLETED = 'completed'
}

/**
 * workflows stored under their key with some state data
 */
export interface WorkflowStore {
    [workflowId: string]: WorkflowStoreObject;
}

/**
 * processes stored under their key, this only happens if the process was created by the auth user
 */
export interface ProcessStore {
    [processId: string]: Process;
}

/**
 * interactions stored under their key with some state data
 */
export interface InteractionStore {
    [interactionId: string]: InteractionStoreObject;
}

export interface RetrieveWorkflow {
    workflowId: string;
    version: number | null; // required, must be number OR null
}

export interface RetrieveInteraction {
    interactionId: string;
}

export interface AnnounceInteractionStart {
    started: number;
    interactionId: string;
    createNewTab: boolean;
}

export interface AnnounceInteractionFinish {
    finished: number;
    interactionId: string;
}

/**
 * data we have access to when intializing process component
 *
 * @see {ProcessComponent} component we inject data into
 */
export interface AnnounceProcessStart {
    processId: string;
    chatId: string | null;
}

export interface AnnounceProcessFinish {
    finished: number; // timestamp
    processId: string;
}

export interface CreateProcessFromWorkflow {
    workflowId: string;
    workflowName: string;
}

/**
 * Metadata for all storage objects. These are properties all storage objects have in common
 */
interface ChatStoreObjectMetadata {
    expires: number; // timestamp when cache version expires and should be retrieved again from database
    loading: boolean;
    error: boolean;
}

/**
 * Interaction specific storage object
 */
export interface InteractionStoreObject extends ChatStoreObjectMetadata {
    interaction: InteractionMapped; // mapped interaction
}

/**
 * Workflow specific storage object
 */
export interface WorkflowStoreObject extends ChatStoreObjectMetadata {
    workflow: WorkFlow;
    workflowVersion: number;
}

