import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowInteraction } from '@eva-model/workflow';

@Component({
  selector: 'eva-workflow-interaction-placeholder',
  templateUrl: './workflow-interaction-placeholder.component.html',
  styleUrls: ['./workflow-interaction-placeholder.component.scss']
})
export class WorkflowInteractionPlaceholderComponent implements OnChanges {

  @Input()
  set interactionItem(interactionItem: string) {
    this.interactionWorkflow = JSON.parse(interactionItem);
    this.interactionOriginalId = this.interactionWorkflow.interactionId;
  }

  @Input() interactionIndex: number;
  @Input() interactionCnt: number;
  @Input() isWaiting: boolean;
  @Input() onWaitMessage: string;

  @Output() removeIneractionConfirm = new EventEmitter<object>();
  @Output() moveUpInteraction = new EventEmitter<object>();
  @Output() moveDownInteraction = new EventEmitter<object>();
  @Output() showInteraction = new EventEmitter<object>();
  @Output() settingInteractionCondition = new EventEmitter<object>();

  interactionWorkflow: WorkflowInteraction;
  interactionOriginalId: string;

  tooltipShowDelay = 700;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {

      switch (propName) {
        case 'interactionItem':
          if (!changes.interactionItem.firstChange) {
            // this.formInitializer();
          }

          break;
      }
    }
  }

  /**
   * This function emits interaction removal button click event
   */
  onRemoveInteraction(): void {
    this.removeIneractionConfirm.emit({
      wrkflwInteractionName: this.interactionWorkflow.interactionName,
      wrkflwInteractionIndex: this.interactionIndex,
      wrkflwInteractionId: this.interactionOriginalId
    });
  }

  /**
   * This function emits interaction move up by one button click event
   */
  onMoveUpInteraction(): void {
    this.moveUpInteraction.emit({ wrkflwInteractionIndex: this.interactionIndex, wrkflwInteractionId: this.interactionOriginalId });
  }

  /**
   * This function emits interaction move down by one button click event
   */
  onMoveDownInteraction(): void {
    this.moveDownInteraction.emit({ wrkflwInteractionIndex: this.interactionIndex, wrkflwInteractionId: this.interactionOriginalId });
  }

  /**
   * This function emits setting interaction condition button click event
   */
  onSettingInteractionCondition(): void {
    this.settingInteractionCondition.emit({
      wrkflwInteractionName: this.interactionWorkflow.interactionName,
      wrkflwInteractionIndex: this.interactionIndex,
      wrkflwInteractionId: this.interactionOriginalId,
      wrkflwInteractionVersion: this.interactionWorkflow.interactionVersion,
      wrkflwInteractionGroupPublicKey: this.interactionWorkflow.interactionGroupPublicKey
    });
  }

  /**
   * This function emits interaction preview button click event
   */
  onShowInteraction(): void {
    this.showInteraction.emit({
      wrkflwInteractionName: this.interactionWorkflow.interactionName,
      wrkflwInteractionIndex: this.interactionIndex,
      wrkflwInteractionId: this.interactionOriginalId,
      wrkflwInteractionVersion: this.interactionWorkflow.interactionVersion,
      wrkflwInteractionGroupPublicKey: this.interactionWorkflow.interactionGroupPublicKey
    });
  }

}
