import { Component, Input, ChangeDetectorRef, HostListener, ChangeDetectionStrategy, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { Node } from '@eva-model/node';
import { ForceDirectedGraph } from '@eva-model/forceGraph';
import { ForceGraphService } from '@eva-services/force-graph/force-graph.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eva-force-directed-graph',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})

export class GraphComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() nodes: any;
  @Input() links: any;
  graph: ForceDirectedGraph;
  public _options: { width: number, height: number } = { width: 800, height: 600 };

  private graphTickerSub: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.graph.initSimulation(this.options);
  }


  constructor(private forceGraphService: ForceGraphService, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    /* Receiving an initialized simulated graph from our custom d3 force graph service */
    this.instantiateGraph();
  }

  private instantiateGraph() {
    this.graph = this.forceGraphService.getForceDirectedGraph(this.nodes, this.links, this.options);
    /** Binding change detection check on each tick
     * This along with an onPush change detection strategy should enforce checking only when relevant!
     * This improves scripting computation duration in a couple of tests I've made, consistently.
     * Also, it makes sense to avoid unnecessary checks when we are dealing only with simulations data binding.
     */
    this.graphTickerSub = this.graph.ticker.subscribe(() => {
      this.ref.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.graph.initSimulation(this.options);
  }

  ngOnDestroy() {
    if (this.graphTickerSub) {
      this.graphTickerSub.unsubscribe();
    }
  }

  get options() {
    return this._options = {
      width: 360,
      height: 360
    };
  }
}
