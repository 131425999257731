import { Injectable } from '@angular/core';
import { SigningService } from '@eva-core/signing.service';
import { KeysService } from '@eva-core/encryption/keys.service';
import * as Elliptic from 'elliptic';
import { environment } from '@environments/environment';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '@eva-core/auth.service';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { SaveChatService } from '@eva-services/chat/save-chat.service';
import { take } from 'rxjs/operators';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { LastStateType } from '@eva-model/userLastState';
import { ChatService } from '@eva-services/chat/chat.service';
import { ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class InteractionSubmissionService {

  private _EC: any; // elliptical curve items.
  private _ec: any;

  interactionId: string; // this is used for database storage.
  interactionCollection: AngularFirestoreCollection<any>; // any type for file storage.
  interactionAFSDocument: AngularFirestoreDocument<any>;
  private interactionSubmitSource: Subject<{changeKey: string, interactionId: string}> = new Subject();
  public announceInteractionSubmit$: Observable<{changeKey: string, interactionId: string}> = this.interactionSubmitSource.asObservable();

  constructor(private _signingService: SigningService,
    private _keysService: KeysService,
    private firestoreService: FirestoreService,
    public groupProviderService: GroupProviderService,
    private _router: Router,
    private _interactionsService: DynamicInteractionsService,
    public authService: AuthService,
    public saveChat: SaveChatService,
    private chatService: ChatService,
    private lastStateService: LastStateService
  ) {
    this._EC = Elliptic.ec;
    this._ec = new this._EC(environment.blockConfig.SIGNING_CURVE);
  }

  /**
   * This functions handles the submission of interaction data into the system for execution.
   * @param interaction
   */
  submit(interaction, originalInteraction, dbId, redirectBool) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}
