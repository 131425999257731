import { Component, OnInit, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GetChatService } from '@eva-services/chat/get-chat.service';

import { ScrollableDirective } from '@eva-ui/salt-chat/scrollable.directive';

@Component({
  selector: 'app-show-history',
  templateUrl: './show-history.component.html',
  styleUrls: ['./show-history.component.scss']
})
export class ShowHistoryComponent implements OnInit, AfterViewInit {
  @ViewChild(ScrollableDirective) appScrollable = null;

  constructor(public dialogRef: MatDialogRef<ShowHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public getChat: GetChatService,
  ) { }

  ngOnInit() {
    this.getChat.init(`users/${this.data}/Chats`, 'createdAt', { limit: 4, reverse: true, prepend: true });
  }

  ngAfterViewInit() {
    this.getChat.data.subscribe(result => {
      if (result) {
        this.appScrollable.scrollToBottom();
      }
    });
  }

  scrollHandler(e) {
    if (e === 'top') {
      // console.log('other top');
      this.getChat.more('backward');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
