import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'app-action-template-cell',
    // template: `<ng-container [ngTemplateOutlet]="actionTemplate" [ngTemplateOutletContext]="{hit: params.data}"></ng-container>` })
    template: `<div *ngIf="componentTitle === 'Interaction Builder'">
                    <button matTooltip='Edit' matTooltipShowDelay='500' mat-icon-button (click)="startEdit(params.data)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>

                    <button matTooltip='Preview' matTooltipShowDelay='500' mat-icon-button (click)="openPreview(params.data)">
                        <mat-icon aria-label="Copy">file_copy</mat-icon>
                    </button>

                    <app-interaction-import-export
                        buttonType="Download"
                        [submitFormToBlock]="submitFormToBlock"
                        [interactionSubmittedToBlockSubscription]="interactionSubmittedToBlockSubscription"
                        [openFormViewerDialog]="openFormViewerDialog"
                        [interactionBuilderComponent]="params.context.componentParent"
                        [dynamicForm]="params.data"
                        [(isWaiting)]="params.context.componentParent.isWaiting"
                        [(waitMessage)]="params.context.componentParent.waitMessage">
                    </app-interaction-import-export>
                </div>
                <div *ngIf="componentTitle === 'Workflow Builder'">
                    <button mat-icon-button (click)="startEdit(params.data)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="onActiveVersion(params.data)"
                        [disabled]="params.data['version'] === params.data['activeVersion']">
                        <mat-icon aria-label="Activate"
                            [class.enabledAction]="params.data['version'] !== params.data['activeVersion']"
                            [class.disabledAction]="params.data['version'] === params.data['activeVersion']">flash_on</mat-icon>
                    </button>
                    <app-workflow-import-export
                        buttonType="Download"
                        [(isWaitingForGroupInteractions)]="params.context.componentParent.isWaitingForGroupInteractions"
                        [submitWorkFlowToBlock]="submitWorkFlowToBlock"
                        [workflowBuilderComponent]="params.context.componentParent"
                        [workflowSubmittedToBlockSubscription]="workflowSubmittedToBlockSubscription"
                        [openGeneralDialog]="openGeneralDialog"
                        [getInteractionsByUserGroups]="getInteractionsByUserGroups"
                        [generalDialogChangeSubscription]="generalDialogChangeSubscription"
                        [workflow]="params.data"
                        [(isWaiting)]="params.context.componentParent.isWaiting"
                        [(waitMessage)]="params.context.componentParent.waitMessage"
                        [(waitForGroupInteractionsMessage)]="params.context.componentParent.waitForGroupInteractionsMessage"
                    ></app-workflow-import-export>
                </div>
                <div *ngIf="componentTitle === 'Knowledge'">
                    <button color="primary" mat-icon-button matTooltip="View Document" (click)="logThis(params); viewKnowledgeDocument(params.data.id, params.data.groupPublicKey, params.data.publishedVersions.length > 0)">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <button color="primary" mat-icon-button matTooltip="Edit Document" (click)="editKnowledgeDocument(params.data.id, params.data.name, params.data.groupPublicKey, params.data.publishedVersions.length > 0)"
                        *ngIf="isValidEditor(params.data.groupPublicKey)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <!-- <button color="primary" mat-icon-button [matTooltip]="params.data.isDeleted ? 'Restore Document' : 'Archive Document'"
                        (click)="deleteKnowledgeDocument(params.data)" *ngIf="isValidEditor(params.data)">
                        <mat-icon>{{params.data.isDeleted ? 'undo' : 'archive'}}</mat-icon>
                    </button> -->
                    <button color="primary" mat-icon-button matTooltip="Copy Link to Document" (click)="copyLinkToKnowledgeDocument(params.data)">
                        <mat-icon>link</mat-icon>
                    </button>
                </div>
                <div *ngIf="componentTitle === 'Process Search'">
                    <button matTooltip="Launch Process" mat-icon-button (click)="workflowSelected(params.data)">
                        <mat-icon>play_circle_filled</mat-icon>
                    </button>
                    <button matTooltip="Pin to Launchpad" mat-icon-button
                        (click)="pinToLaunchPad($event, params.data)">
                        <mat-icon>push_pin</mat-icon>
                    </button>
                </div>`
})

export class ActionTemplateRendererComponent implements ICellRendererAngularComp {
    params: any;
    // Interaction Builder
    submitFormToBlock: any;
    interactionSubmittedToBlockSubscription: any;
    openFormViewerDialog: any;
    startEdit: any;
    openPreview: any;
    actionTemplate: any;
    componentTitle: string;
    // Workflow Builder
    onActiveVersion: any;
    submitWorkFlowToBlock: any;
    workflowSubmittedToBlockSubscription: any;
    openGeneralDialog: any;
    getInteractionsByUserGroups: any;
    generalDialogChangeSubscription: any;
    // Knowledge Search
    deleteKnowledgeDocument: any;
    isValidEditor: any;
    editKnowledgeDocument: any;
    viewKnowledgeDocument: any;
    logThis: any;
    copyLinkToKnowledgeDocument: any;
    // Process Search
    pinToLaunchPad: any;
    workflowSelected: any;

    agInit(params: any): void {
        this.params = params;
        this.componentTitle = this.params.context.componentParent.componentTitle;
        // Interaction Builder
        this.submitFormToBlock = this.params.context.componentParent.submitFormToBlock;
        this.interactionSubmittedToBlockSubscription = this.params.context.componentParent.interactionSubmittedToBlockSubscription;
        this.openFormViewerDialog = this.params.context.componentParent.openFormViewerDialog;
        this.startEdit = this.params.context.componentParent.startEdit;
        this.openPreview = this.params.context.componentParent.openPreview;
        this.actionTemplate = this.params.context.componentParent.actionTemplate;

        // Workflow Builder
        this.onActiveVersion = this.params.context.componentParent.onActiveVersion;
        this.submitWorkFlowToBlock = this.params.context.componentParent.submitWorkFlowToBlock;
        this.workflowSubmittedToBlockSubscription = this.params.context.componentParent.workflowSubmittedToBlockSubscription;
        this.openGeneralDialog = this.params.context.componentParent.openGeneralDialog;
        this.getInteractionsByUserGroups = this.params.context.componentParent.getInteractionsByUserGroups;
        this.generalDialogChangeSubscription = this.params.context.componentParent.generalDialogChangeSubscription;

        // Knowledge Search
        this.deleteKnowledgeDocument = this.params.context.componentParent.deleteKnowledgeDocument;
        this.isValidEditor = this.params.context.componentParent.isValidEditor;
        this.editKnowledgeDocument = this.params.context.componentParent.editKnowledgeDocument;
        this.viewKnowledgeDocument = this.params.context.componentParent.viewKnowledgeDocument;
        this.logThis = this.params.context.componentParent.logThis;
        this.copyLinkToKnowledgeDocument = this.params.context.componentParent.getDocumentLink;

        // Process Search
        this.pinToLaunchPad = this.params.context.componentParent.pinToLaunchPad;
        this.workflowSelected = this.params.context.componentParent.workflowSelected;
    }
    refresh(): boolean {
        return false;
    }
}
