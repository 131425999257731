import { Injectable } from '@angular/core';
import { Guid } from '@eva-core/GUID/guid';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from "rxjs/operators";
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { IDMappingResponse, IdMappingType } from '@eva-model/id-mapping/id-mapping';

@Injectable({
  providedIn: 'root'
})
export class IdMapperService {

  private idMapperUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'setMappingId';
  private idMapperGetUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getMappingId';
  private interactionPairs = [];
  private workflowPairs = [];
  private elementPairs = [];

  constructor(
    private http: HttpClient,
    private perf: AngularFirePerformance
  ) { }

  /**
   * This function compares the oldInteractionId with the id map collection, whether the mapping already exists otherwise
   * creates new mapping with either new id or passed existingId
   *
   * @param oldInteractionId Interaction Id of the existing interaction in current environment
   * @param interactionId Interaction Id of the imported Interaction from previous environment
   * @param selectedInteractionId Interaction Id of the selected interaction in current environment
   * @param existingId interaction Id of the mapped interaction from current environment
   */
  async mapInteractionId(oldInteractionId: string, interactionId: string, selectedInteractionId: string,
    existingId?: string, useNewId?: boolean): Promise<string> {
    let newId: string = null;

    if (this.areIdsValid(oldInteractionId, interactionId, selectedInteractionId)) {
      // check if document with id mapping already exists
      let updatedId = false;
      try {
        const docId = interactionId + '_' + selectedInteractionId;
        const idMappingRequest = {
          type: IdMappingType.INTERACTION,
          docId: docId
        };
        const documentData = await this.http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
          trace('id-mapper-mapInteractionId'),
          take(1)
        ).toPromise();
        if (typeof documentData.idMapping !== 'undefined' && documentData.idMapping[oldInteractionId] && !existingId && !useNewId) {
          updatedId = true;
          newId = documentData.idMapping[oldInteractionId];
        }
      } catch (err) {
        console.log(err);
        // continues to next step
      }
      // set new values if the mapping didn't exist.
      if (!updatedId) {
        // else get new id
        newId = Guid.newGuid().toString();
        const existingInteractionIdPair = this.interactionPairs.find(pair => pair.oldId === oldInteractionId);
        if (existingInteractionIdPair) {
          newId = existingInteractionIdPair.newId;
        }
        if (existingId) {
          if (Guid.isValid(existingId)) {
            newId = existingId;
          } else {
            console.error("Invalid Id: " + existingId + ". Assigning new GUID.");
          }
        }
        // store the mapping in the array to store to firestore later if mapping already doesn't exists
        this.interactionPairs.push({
          oldId: oldInteractionId,
          newId: newId,
          docId: interactionId + '_' + selectedInteractionId
        });
      }
    }
    return newId;
  }

  /**
   * This function sends the interaction Ids to the database in one request
   *
   * @param selectedPairs Extra pairs of the interaction ids to upload
   */
  async sendInteractionIds(selectedPairs: any[] = []): Promise<boolean> {
    if (this.interactionPairs.length === 0 && selectedPairs.length === 0) {
      return true;
    }
    let response = null;
    try {
      response = await this.http.post(this.idMapperUrl, {
        type: IdMappingType.INTERACTION,
        pairs: this.interactionPairs.concat(selectedPairs)
      }).pipe(
        trace('id-mapper-sendInteractionIds')
      ).toPromise();
    } catch (err) {
      console.log(err);
    }
    this.interactionPairs = [];
    this.interactionPairs.length = 0;
    return response && response.success;
  }

  /**
   * This function compares the oldWorkflowId with the id map collection, whether the mapping already exists otherwise
   * creates new mapping with either new id or passed existingId
   *
   * @param oldWorkflowId Workflow Id of the existing Workflow in current environment
   * @param workflowId Workflow Id of the imported Workflow from previous environment
   * @param selectedWorkflowId Workflow Id of the selected Workflow in current environment
   * @param existingId Workflow Id of the mapped Workflow from current environment
   */
  async mapWorkflowId(oldWorkflowId: string, workflowId: string, selectedWorkflowId: string, existingId?: string,
    useNewId?: boolean): Promise<string> {
    let newId: string = null;

    if (this.areIdsValid(oldWorkflowId, workflowId, selectedWorkflowId)) {
      // check if document with id mapping already exists
      let updatedId = false;
      try {
        const docId = workflowId + '_' + selectedWorkflowId;
        const idMappingRequest = {
          type: IdMappingType.WORKFLOW,
          docId: docId
        };
        const documentData = await this.http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
          trace('id-mapper-mapWorkflowId'),
          take(1)
        ).toPromise();
        if (typeof documentData.idMapping !== 'undefined' && documentData.idMapping[oldWorkflowId] && !existingId && !useNewId) {
          updatedId = true;
          newId = documentData.idMapping[oldWorkflowId];
        }
      } catch (err) {
        console.log(err);
        // continues to next step
      }

      // if id already exists, use that id
      if (!updatedId) {
        // else get new id
        newId = Guid.newGuid().toString();
        const existingWorkflowIdPair = this.workflowPairs.find(pair => pair.oldId === oldWorkflowId);
        if (existingWorkflowIdPair) {
          newId = existingWorkflowIdPair.newId;
        }
        if (existingId) {
          if (Guid.isValid(existingId)) {
            newId = existingId;
          } else {
            console.error("Invalid Id: " + existingId + ". Assigning new GUID.");
          }
        }
        // store the mapping in the array to store to firestore later if mapping already doesn't exists
        this.workflowPairs.push({
          oldId: oldWorkflowId,
          newId: newId,
          docId: workflowId + '_' + selectedWorkflowId
        });
      }
    }
    return newId;
  }

  /**
   * This function sends the workflow Ids to the database in one request
   *
   * @param selectedPairs Extra pairs of the workflow ids to upload
   */
  async sendWorkflowIds(selectedPairs: any[] = []): Promise<boolean> {
    if (this.workflowPairs.length === 0 && selectedPairs.length === 0) {
      return true;
    }
    let response = null;
    try {
      response = await this.http.post(this.idMapperUrl, {
        type: IdMappingType.WORKFLOW,
        pairs: this.workflowPairs.concat(selectedPairs)
      }).pipe(
        trace('id-mapper-sendWorkflowIds')
      ).toPromise();
    } catch (err) {
      console.log(err);
    }
    this.workflowPairs = [];
    this.workflowPairs.length = 0;
    return response && response.success;
  }

  /**
   * This function compares the oldElementId with the id map collection, whether the mapping already exists otherwise
   * creates new mapping with either new id or passed existingId
   *
   * @param oldElementId Element Id of the existing Element in current environment
   * @param elementId Element Id of the imported Element from previous environment
   * @param selectedElementId Element Id of the selected Element in current environment
   * @param existingId Element Id of the mapped Element from current environment
   */
  async mapElementId(oldElementId: string, elementId: string, selectedElementId: string, existingId?: string,
    useNewId?: boolean): Promise<string> {
    let newId: string = null;

    if (this.areIdsValid(oldElementId, elementId, selectedElementId)) {
      // check if document with id mapping already exists
      let updatedId = false;
      try {
        const docId = elementId + '_' + selectedElementId;
        const idMappingRequest = {
          type: IdMappingType.ELEMENT,
          docId: docId
        };
        const documentData = await this.http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
          trace('id-mapper-mapElementId'),
          take(1)
        ).toPromise();
        if (typeof documentData.idMapping !== 'undefined' && documentData.idMapping[oldElementId] && !existingId && !useNewId) {
          updatedId = true;
          newId = documentData.idMapping[oldElementId];
        }
      } catch (err) {
        console.log(err);
        // continues to next step
      }

      // if id already exists, use that id
      if (!updatedId) {
        // else get new id
        newId = Guid.newGuid().toString();
        const existingElementIdPair = this.elementPairs.find(pair => pair.oldId === oldElementId);
        if (existingElementIdPair) {
          newId = existingElementIdPair.newId;
        }
        if (existingId) {
          if (Guid.isValid(existingId)) {
            newId = existingId;
          } else {
            console.error("Invalid GUID: " + existingId + ". Assigning new GUID.");
          }
        }
        // store the mapping in the array to store to firestore later if mapping already doesn't exists
        this.elementPairs.push({
          oldId: oldElementId,
          newId: newId,
          docId: elementId + '_' + selectedElementId
        });
      }
    }
    return newId;
  }

  /**
   * This function sends the element Ids to the database in one request
   *
   * @param selectedPairs Extra pairs of the element ids to upload
   */
  async sendElementIds(selectedPairs: any[] = []): Promise<boolean> {
    if (this.elementPairs.length === 0 && selectedPairs.length === 0) {
      return true;
    }
    let response = null;
    try {
      response = await this.http.post(this.idMapperUrl, {
        type: IdMappingType.ELEMENT,
        pairs: this.elementPairs.concat(selectedPairs)
      }).pipe(
        trace('id-mapper-sendElementIds')
      ).toPromise();
    } catch (err) {
      console.log(err);
    }
    this.elementPairs = [];
    this.elementPairs.length = 0;
    return response && response.success;
  }

  /**
   * This function validates the ids to be of valid GUID type.
   *
   * @param ids List of ids being validated
   */
  areIdsValid(...ids: string[]): boolean {
    let isValid = false;

    for (const id of ids) {
      if (Guid.isValid(id)) {
        isValid = true;
      } else {
        return false;
      }
    }

    return isValid;
  }
}
