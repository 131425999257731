import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {combineLatest, from, of, Subscription} from 'rxjs';
import {FirestoreService} from '@eva-services/firestore/firestore.service';
import {ArrayUtilsService} from '@eva-services/utils/array-utils.service';

import {UtilsService} from '@eva-services/utils/utils.service';
import {AgGridAngular} from 'ag-grid-angular';
import {GridOptions} from 'ag-grid-community';

import {EntityType, Log} from '@eva-model/log';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {GeneralDialogModel} from '@eva-model/generalDialogModel';
import {flatMap, map, take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {GeneralDialogComponent} from '@eva-ui/general-dialog/general-dialog.component';
import {GeneralDialogService} from '@eva-services/general-dialog/general-dialog.service';
import {MessageService} from "primeng/api";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'eva-logs',
  templateUrl: './eva-logs.component.html',
  styleUrls: ['./eva-logs.component.scss'],
  providers: [MessageService]
})
export class EvaLogsComponent implements OnInit, OnDestroy {

  public logData: any[];

  public evaLogsGridColumnDefs = [
    {headerName: '', field: 'entityType', hide: true, rowGroup: true },
    {headerName: 'Entity Id', field: 'entityId', width: 300, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Log Level', field: 'logLevel', width: 110, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Message', field: 'message', width: 480, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'User Id', field: 'uid', width: 300, resizable: true, sortable: true, filter: 'agTextColumnFilter' }
  ];

  public evaLogsAutoGroupColumnDef = {
    headerName: 'Created at',
    field: 'createdAt',
    width: 250,
    sortable: true,
    resizable: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
        checkbox: true
    }
  };

  adminSubs = new Subscription();

  @ViewChild('evaLogsGrid') evaLogsGrid: AgGridAngular;

  public evaLogsGridOptions: GridOptions;
  componentTitle: string;

  constructor(
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService,
    private _firestoreSrv: FirestoreService,
    private _arrayUtilsSrv: ArrayUtilsService,
    private _utils: UtilsService,
    private _afs: AngularFirestore,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.evaLogsGridOptions = <GridOptions>{};
    this.evaLogsGridOptions.columnDefs = this.evaLogsGridColumnDefs;
    this.evaLogsGridOptions.autoGroupColumnDef = this.evaLogsAutoGroupColumnDef;

    this.evaLogsGridOptions.animateRows = true;
    this.evaLogsGridOptions.pagination = true;
    this.evaLogsGridOptions.paginationPageSize = 17;

    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  ngOnInit() {
    this.evaLogs();
  }

  ngOnDestroy(): void {
    if ( this.adminSubs ) {
      this.adminSubs.unsubscribe();
    }
  }

  //#region EVA Logs
  /**
   * This function retrieves all the logs and sorts them in descending order based on time.
   */
  private evaLogs(): void {
    this.adminSubs.add(
      this._firestoreSrv.col$('logs')
      .subscribe(
        (logs) => {
          let logDataArray = [];

          logs.forEach( (logItem: any) => {
            const log = {
              createdAt: this._utils.convertTimestampToDate(logItem.createdAt.seconds * 1000),
              entityType: logItem.entityType,
              entityId: logItem.entityId,
              logLevel: logItem.logLevel,
              message: logItem.error,
              id: logItem.id,
              uid: logItem.uid ? logItem.uid : ''
            };
            logDataArray.push(log);
          });

          logDataArray = this._arrayUtilsSrv.uniqueArray(logDataArray);
          this.logData = this._arrayUtilsSrv.arrayObjectCustomSort(logDataArray, 'createdAt', 'DESC');
        },
        (err) => {
          console.log(err);
        }
      ));
  }
  // #endRegion

  //#region Delete Selected Logs
  /**
   * This function prompts user to confirm the delete log action
   */
  deleteSelectedRowsConfirm(): void {
    const selectedRows = this.getSelectedRows();
    if ( !(selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) ) {
      this.messageService.clear();
      this.messageService.add({
        life: 7000,
        severity: 'warn',
        summary: "No log record selected to get deleted",
        detail: `To delete a log please select it first`
      });

      return;
    }

    const dialogData = new GeneralDialogModel(
      'EVA Log(s) Deleting Dialog', `Do you want to delete the selected EVA log${selectedRows.length > 1 ? 's' : ''} ? `, 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.deleteSelectedRowsConfirmed, { that: this });
  }

  /**
   * This is a callback function for deleteSelectedRowsConfirm function
   *
   * @param data { that: this }
   */
  deleteSelectedRowsConfirmed(data: {that: EvaLogsComponent}): void {
    data.that.tryDeleteSelectedRows();
  }

  /**
   * This function gets the selected rows for deletion and deletes them
   */
  public tryDeleteSelectedRows(): void {
    const selectedRows = this.getSelectedRows();

    if ( ! (selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) ) {
      this.messageService.clear();
      this.messageService.add({
        life: 7000,
        severity: 'info',
        summary: "No log record selected to get deleted",
        detail: ``
      });

      return;
    }

    this.deleteSelectedRows(selectedRows);
  }

  /**
   * This function deletes the selected rows from db
   *
   * @param selectedRows List of selected rows
   */
  private deleteSelectedRows(selectedRows: any[]): void {
    if ( ! (selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) ) return;

    const tasks$ = [];
    selectedRows.forEach( (log: Log) => {

      tasks$.push( this._afs.collection('logs/', ref => ref.where('id', '==', log.id)).snapshotChanges() );

    });

    combineLatest(tasks$)
    .pipe(
      take(1),
      map( (docs: any[]) => {
        let docIds = [];

        if ( Array.isArray(docs) && docs.length > 0 ) {
          const docs0 = docs.map( data => data[0] );
          if ( Array.isArray( docs0 ) && docs0.length > 0 ) {
            docIds = docs0.map(element => element.payload.doc.id);
          }
        }

        return docIds;
      }),
      flatMap( docIds => {
        const deleteTasks$ = [];
        if (Array.isArray(docIds) && docIds.length > 0) {
          docIds.forEach( id => {
            deleteTasks$.push(from(this._afs.doc(`logs/${id}`).delete()));
          });
        }

        return combineLatest( (deleteTasks$.length > 0) ? deleteTasks$ : of( null )).pipe(take(1));
      })
    )
    .subscribe(
      () => {
        // In case necessary to take any action
        this.evaLogsExpandProcessGroup();
      },
      (err) => {
        console.log(err);
        // log errors
      },
      () => {
        // Final
        console.log( 'final' );
      }
    );

  }
  // #endRegion

  /**
   * This function gets rows selected by user from the grid
   */
  private getSelectedRows(): any[] {
    const selectedNodes = this.evaLogsGrid.api.getSelectedNodes();
    return selectedNodes.map(node => node.data);
  }

  //#region Agnostic grid events
  public evaLogsGridReady(): void {
    // On Grid Ready
  }

  public evaLogsGridModelUpdated(): void {
    // On Grid Model Updated
  }

  public evaLogsGridRowGroupOpened(): void {
    // On Grid row group Expand/Collapse
  }

  public evaLogsGridRowDataChanged(): void {
    // On Grid row data changed
  }

  /**
   * This function fires when grid data is rendered for the first time
   */
  public evaLogsGridFirstDataRendered(): void {
    this.evaLogsExpandProcessGroup();
  }

  /**
   * This function expands the process logs in the grid
   */
  private evaLogsExpandProcessGroup(): void {
    this.evaLogsGridOptions.api.forEachNode((node) => {
      if (node.key === EntityType.process) {
        node.setExpanded(true);
      }
    });
  }
  // #endRegion

  //#region Dialog related functions
  /**
   * This function opens a general dialog
   *
   * @param dialogModel Data for the GeneralDialogComponent
   * @param callback  Callback function to be called if dialog results true
   * @param callbackData Data to be passed to the callback function
   * @param callbackForFalse Callback function to be called if dialog results false
   */
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback: Function, callbackData: any,
    callbackForFalse: Function = null): void {
    const dialogReference = this.dialog.open(GeneralDialogComponent, { data: dialogModel });

    this.generalDialogService.generalDialogChanged$
    .pipe(
      take(1)
    )
    .subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogReference.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callbackData.result = true;
          callback(callbackData);
        }
      } else {
        if (callbackForFalse) {
          callbackData.result = false;
          callbackForFalse(callbackData);
        }
      }
    });
  }
  // #endRegion


}
