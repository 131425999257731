import { environment } from '@environments/environment';
import { GroupTransactionBase } from '@eva-model/blockchain/transaction';
import * as environmentService from '@eva-services/environment/environment.service';
const eS = new environmentService.EnvironmentService;
const invitationGroupTypes = eS.getAllInvitationGroupTypes();
const normalGroup = environment.blockConfig.types.groups.types.normal;

/**
 * Updates to this class must be updated in all EVA firebase projects the firebase functions project.
 */
export class GroupTransactionStructure {
    private _addPublicKeys: string[] = []; // an array of public keys to add to group membership
    private _removePublicKeys: string[] = []; // an array of public keys to remove from the group membership
    private _addParentGroupPublicKey: string; // the public key of the parent group to add to the group of this transaction
    private _removeParentGroupPublicKey: string; // the public key of the parent group to remove from the group of this transaction
    private _groupPublicKey: string; // the public key of the group.
    private _groupName: string; // the name of the group.
    private _groupType: string; // the type of group/
    private _groupSubType: string; // group subtype
    private _groupDescription: string; // group description
    private _organizationGroup: boolean; // whether this group is an organizational group.
    private _groupTransactionBase: GroupTransactionBase; // the object containing group membership
    private _isGroupTransaction = false; // set to let the applications know if it a proper group.
    private _isParentGroupTransaction = false; // set to determine on the applications if this is a parent group transaction.

    private _message: any; // this will be the invitation and acceptance data if it it used.
    private _isValidStructure = false;

    constructor(groupTransactionBase?: GroupTransactionBase) {
        // check if there is data entered.
        if (groupTransactionBase) {
            this.setFromGroupTransactionBase(groupTransactionBase); // setup the transaction or an empty group
        }
    }

    /**
     * This sets the transaction and determines what type of transaction it is based on the type of object passed in.
     */
    setFromGroupTransactionBase(groupTransactionBase: GroupTransactionBase): void {
        this._isGroupTransaction = false;
        this._isValidStructure = false;
        this._addPublicKeys = [];
        this._removePublicKeys = [];
        this._groupTransactionBase = groupTransactionBase;
        if (groupTransactionBase.publicKey && groupTransactionBase.groupName && groupTransactionBase.groupType) {
            this._groupPublicKey = this._groupTransactionBase.publicKey;
            // check if there is an add or update
            this.performBasicChecks();
            if (!this._isGroupTransaction) { // if this isn't a valid group transaction, see if it's a parent transcation.
                this.performParentGroupChecks();
            }
        } // not needed to set failed as the construtor already does this.
    }

    /**
     * This sets the values in the group object based on new values being added.
     * @param groupPublicKey the public key of the group
     * @param groupName the group name (can be optional on a add or remove transaction)
     * @param groupType the type of group that is being used
     * @param organizationGroup is this an organizational group?
     * @param addPublicKeys public keys to add to the group
     * @param removePublicKeys public keys to remove from the group
     * @param groupSubType the group sub type. Not currently in use.
     * @param message additional information that is incoming. In invitation groups, this is the
     * crypto proof that the user should be accepted.
     */
    setFromValues(groupPublicKey: string, groupName: string, groupType: string, organizationGroup: boolean,
    addPublicKeys?: string[], removePublicKeys?: string[], groupSubType?: string, message?: any,
    addParentGroupPublicKey?: string, removeParentGroupPublicKey?: string, groupDescription?: string): void {

        this._addPublicKeys = (addPublicKeys) ? addPublicKeys : [];
        this._removePublicKeys = (removePublicKeys) ? removePublicKeys : [];
        this._groupPublicKey = groupPublicKey;
        this._groupName = (groupName) ? groupName : '';
        this._groupType = (groupType) ? groupType : normalGroup;
        this._organizationGroup = organizationGroup;
        this._groupSubType = (groupSubType) ? groupSubType : '';
        this._message = (message) ? message : null;
        this._addParentGroupPublicKey = (addParentGroupPublicKey) ? addParentGroupPublicKey : null;
        this._removeParentGroupPublicKey = (removeParentGroupPublicKey) ? removeParentGroupPublicKey : null;
        this._groupDescription = (groupDescription) ? groupDescription : null;

        // once set, this ensures that the transaction gets sent.
        if (this.performArrayChecks(this.addPublicKeys) || this.performArrayChecks(this.removePublicKeys)) {
            this._isGroupTransaction = true;

            this.performAdditionalChecks();
        } else { // not a normal transaction for membership
            this._isGroupTransaction = false;
            this.performParentGroupChecks();
        }
    }


    /**
     * This validates that the parent group transaction has a parent or child group to add.
     */
    performParentGroupChecks(): void {
        if (typeof this._addParentGroupPublicKey === 'string' || typeof this._removeParentGroupPublicKey === 'string') {
            this._isParentGroupTransaction = true;
            this._isGroupTransaction = true;
        } else {
            this._isParentGroupTransaction = false;
        }
    }

    /**
     * This function performs the basic checks to ensure that information is included.
     */
    performBasicChecks(): void {
        let addOrUpdateTransaction = false;
        if (Array.isArray(this._groupTransactionBase.addPublicKeys) || Array.isArray(this._groupTransactionBase.removePublicKeys)) {
            if (this.performArrayChecks(this._groupTransactionBase.addPublicKeys)) {
                this._addPublicKeys = this._groupTransactionBase.addPublicKeys;
                addOrUpdateTransaction = true;
            }
            if (this.performArrayChecks(this._groupTransactionBase.removePublicKeys)) {
                this._removePublicKeys = this._groupTransactionBase.removePublicKeys;
                addOrUpdateTransaction = true;
            }
            if (this._groupTransactionBase.groupName) {
                this._groupName = this._groupTransactionBase.groupName;
            }
            if (this._groupTransactionBase.groupType) {
                this._groupType = this._groupTransactionBase.groupType;
            }
            if (this._groupTransactionBase.organizationGroup) {
                this._organizationGroup = true;
            }
            if (this._groupTransactionBase.message) {
                this._message = this._groupTransactionBase.message;
            }
            if (this._groupTransactionBase.groupDescription) {
                this._groupDescription = this._groupTransactionBase.groupDescription;
            }

            if (addOrUpdateTransaction) {
                this._isGroupTransaction = true;
                this.performAdditionalChecks();
            }
        } else {
            this._isGroupTransaction = false;
        }
    }

    /**
     * This function performs additional group structure checks to ensure the group structure
     * is at the very least somewhat valid.
     */
    performAdditionalChecks(): void {
        if (invitationGroupTypes.indexOf(this._groupType) > -1) {
            this._isValidStructure = true;
        } else if (this._message) {
            this._isValidStructure = true;
        } // otherwise not valid already set in the component.
    }

    /**
     * This determines if an object is an array.
     * @param objectToCheck potentially an array object
     */
    performArrayChecks(objectToCheck: any): boolean {
        if (objectToCheck && Array.isArray(objectToCheck) && objectToCheck.length > 0) {
            return true;
        } else {
            return false;
        }
    }

  // #region Getter/Setter

    set groupPublicKey(groupPublicKey) { this._groupPublicKey = groupPublicKey; }
    get groupPublicKey() { return this._groupPublicKey; }
    get isGroupTransaction() { return this._isGroupTransaction; }
    // set to determine on the applications if this is a parent group transaction.
    get isParentGroupTransaction() { return this._isParentGroupTransaction; }
    get addPublicKeys() { return this._addPublicKeys; }
    set addPublicKeys(addPublicKeys) { this._addPublicKeys = addPublicKeys; }
    get addParentGroupPublicKey() { return this._addParentGroupPublicKey; }
    set addParentGroupPublicKey(addParentGroupPublicKey) { this._addParentGroupPublicKey = addParentGroupPublicKey; }
    get removeParentGroupPublicKey() { return this._removeParentGroupPublicKey; }
    set removeParentGroupPublicKey(removeParentGroupPublicKey) { this._removeParentGroupPublicKey = removeParentGroupPublicKey; }
    get removePublicKeys() { return this._removePublicKeys; }
    set removePublicKeys(removePublicKeys) { this._removePublicKeys = removePublicKeys; }
    get groupName() { return this._groupName; }
    set groupName(groupName) { this._groupName = groupName; }
    get groupType() { return this._groupType; }
    set groupType(groupType) { this._groupType = groupType; }
    get organizationGroup() { return this._organizationGroup; }
    set organizationGroup(organizationGroup) { this._organizationGroup = organizationGroup; }
    get groupSubType() { return this._groupSubType; }
    set groupSubType(groupSubType) { this._groupSubType = groupSubType; }
    get groupTransactionBase(): GroupTransactionBase {
        const groupTransactionBase: GroupTransactionBase = {
            groupType: this._groupType,
            groupName: this._groupName,
            publicKey: this._groupPublicKey,
            organizationGroup: this._organizationGroup
        };
        if (this.performArrayChecks(this._addPublicKeys)) {
            groupTransactionBase.addPublicKeys = this._addPublicKeys;
        }
        if (this._addParentGroupPublicKey) { // add in the group descrption if it is available.
            groupTransactionBase.addParentGroupPublicKey = this._addParentGroupPublicKey;
        }
        if (this._groupDescription) { // add in the group descrption if it is available.
            groupTransactionBase.groupDescription = this._groupDescription;
        }
        if (this._removeParentGroupPublicKey) { // add in the group descrption if it is available.
            groupTransactionBase.removeParentGroupPublicKey = this._removeParentGroupPublicKey;
        }
        if (this.performArrayChecks(this._removePublicKeys)) {
            groupTransactionBase.removePublicKeys = this._removePublicKeys;
        }
        if (this._groupSubType !== '') {
            groupTransactionBase.groupSubType = this.groupSubType;
        }
        if (this._message) {
            groupTransactionBase.message = JSON.stringify(this._message);
        }

        return groupTransactionBase;
    }
    get isValidStructure() { return this._isValidStructure; }

  //#endregion
}
