import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@eva-core/auth.service';
import * as moment from 'moment';
import {ColumnApi, GridApi, ColDef, ColGroupDef} from 'ag-grid-community';
import {EvaGlobalService} from '@eva-core/eva-global.service';
import {User} from '@eva-model/User';
import {environment} from '@environments/environment';
import { InstantSearchService} from '@eva-services/search/instant-search.service';
// import { NgAisInstantSearch, SearchParameters, SearchRequest, InstantSearchConfig,
//   SearchClient } from 'angular-instantsearch/instantsearch/instantsearch';
import {Subscription} from 'rxjs';
import {TutorialService} from '@eva-services/tutorial/tutorial.service';
import {CustomTooltipComponent} from '@eva-ui/process/process-dashboard/custom-dashboard-tooltip/custom-dashboard-tooltip.component';
import {UserService} from '@eva-services/user/user.service';
import {UserPreferences} from '@eva-model/UserPreferences';
import {LoggingService} from '@eva-core/logging.service';

import { take } from 'rxjs/operators';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { ActivatedRoute } from '@angular/router';
import { ValueModifier, PropertyMapping } from '@eva-model/search';
import { SearchUtilsService } from '@eva-services/utils/search-utils.service';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { NgAisInstantSearch } from 'angular-instantsearch/instantsearch/instantsearch';

@Component({
  selector: 'app-process-dashboard',
  templateUrl: './process-dashboard.component.html',
  styleUrls: ['./process-dashboard.component.scss']
})
export class ProcessDashboardComponent implements OnInit, OnDestroy {

  public administratorMessage = '';                         // stores the message set by the administrator
  public isAdministrator = false;                           // boolean to determine whether or not to show admin message input
  public overlayNoRowsTemplate: string;                     // stores a custom error message tos hwo if no rows are found
  private componentSubs: Subscription = new Subscription();  // the subscription for the tutorials.
  private tutorialSub: Subscription;
  public emailToggle = false;                               // whether or not the user will receive emails when a process is completed
  public userEmail: string;

  private adminMessageUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getProcessDashboardAdminMessage';

  processDashboardAgGridIsSortable = false;                 // Ag Grid is sortable

  // for algolia search
  public agGrid: any; // default ag grid reference
  public frameworkComponents; // currently only stores tooltip component
  private gridApi: GridApi; // the api of the ag-grid
  private gridColumnApi: ColumnApi; // stores the ag-grids column api
  public rowData: Object; // stores the data to be loaded by ag-grid
  public paginationPageSize: string; // number of records to be loaded per page
  public paginationNumberFormatter: any; // shows users the number of records being displayed
  searchConfig = { indexName: '', searchClient: {}, searchParameters: { filters: '' } }; // the search configuration
  searchClient: SearchClient; // the algolia search client
  indexName = 'processDashboard' + environment.algolia.indexEnding; // the Algolia index to query.
  algoloaTokenType: AlgoliaTokenType = AlgoliaTokenType.DASHBOARD;
  searchAccess = false; // is search access ready.
  currentlyLoading = true; // to determine if algolia api key is loaded or not
  searchToken: AlgoliaSearchToken; // the algolia search token used for this component.
  processPropertyNameMapping: PropertyMapping; // the property mapping for process config
  public defaultColDef: ColDef;                             // the default column definitions
  public rowHeight: number;                                 // the height of individual rows
  public columnDefs: (ColDef | ColGroupDef)[];              // stores definitions for each column



  disableSubmitButton = true;                               // to disable submit button on submit
  showAdminMessage = false;                                 // to determine whether to show admin message or not
  adminLoading = false;                                     // to determine whether to show loading spinner or not
  clickCount = 0;                                           // count of programmatic clicks for tutorial
  searchInterval = null;                                    // interval for the text being added to search field for tutorial
  filterInterval = null;                                    // interval for the text being added to filter field for tutorial
  introJsObj = null;                                        // introJS tutorial object
  secondTabSelected = false;                                // to determine if second tab is selected in filter for tutorial
  timer = 0;                                                // timer for auto-refresh for algolia
  algoliaRefreshInterval = null;                            // interval for algolia auto-refresh
  toggleRefresh = false;                                    // toggle for auto-refresh table data
  user: User;                                               // current user object
  userPreferences: UserPreferences;                         // current user preferences
  refreshLoading = false;                                   // to determine whether to show loading spinner or not
  filterModel = {};                                         // filter object from ag-grid
  @ViewChild('adminMessageInput') adminMessageTextArea: ElementRef;
  instantSearchElement: any;
  @ViewChild('algoliaSortMenu', { read: ElementRef }) algoliaSortMenu: ElementRef;
  refreshEmailLoading: boolean;
  componentTitle: string;

  state = {
    searchText: ''
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public evaGlobalService: EvaGlobalService,
    public instantSearch: InstantSearchService,
    public tutorialService: TutorialService,
    private userService: UserService,
    private loggingService: LoggingService,
    private activatedRoute: ActivatedRoute,
    private searchUtils: SearchUtilsService) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  async ngOnInit() {
    this.setupSearch();
    this.http
      .get(this.adminMessageUrl, {})
      .subscribe(async data => {
        this.administratorMessage = data['message']['adminMessage'];
      });
    // (<SearchParameters>this.searchConfig.searchParameters).filters = 'isATBGlobal:true';

    try {
      await this.isUserAdmin(); // determine if the user is an administrator (used in the component html)

      this.userPreferences = await this.userService.getUserPreferences(this.user, true);
      this.toggleRefresh = this.userPreferences.autoRefreshProcessDashboard ? this.userPreferences.autoRefreshProcessDashboard : false;
      this.emailToggle = this.userPreferences.toggleEmailsProcessDashboard ? this.userPreferences.toggleEmailsProcessDashboard : false;
      this.userEmail = await this.authService.getUserEmail();

      if (this.toggleRefresh) {
        // this is to add the event listener to re-fetch algolia data
        this.resetTimer();
        document.addEventListener('mousemove', this.resetTimer, false);
        document.addEventListener('keydown', this.resetTimer, false);
      } else {
        clearInterval(this.algoliaRefreshInterval);
        document.removeEventListener('mousemove', this.resetTimer, false);
        document.removeEventListener('keydown', this.resetTimer, false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Checks in firebase if a user is an administrator. If they are, isAdministrator is set to true and access to the admin message
   * input is allowed
   */
  async isUserAdmin(): Promise<void> {
    try {
      this.isAdministrator = await this.authService.isUserAdmin();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * This functions gets called when ag-grid filter is changed
   *
   * @param event Filter change event
   */
  onFilterChange(event: any): void {
    this.filterModel = (<GridApi>event.api).getFilterModel();
    // get filter model from ag-grid for current filter
    const filterModel = (<GridApi>event.api).getFilterModel();
    // for every property bring filtered
    for (const key in filterModel) {
      // if operator property exists then filter based on multiple conditions
      if (filterModel[key].operator) {
        this.searchForMultipleConditions(key, filterModel);
      } else {
        this.searchForSingleCondition(key, filterModel);
      }
    }
  }

  onReferenceChange(angularSearchRef: any) {
    this.instantSearchElement = angularSearchRef;
  }

  onAgGridReferenceChange(agGridRef: any) {
    this.agGrid = agGridRef;
  }

  onFilterModelChange(filterModel: any) {
    // console.log(angularSearchRef);
    this.filterModel = filterModel;
  }

  /**
   * This function searches algolia for multiple filter conditions
   *
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  searchForMultipleConditions(key: string, filterModel: any): void {
    const condition1 = filterModel[key].condition1;
    const condition2 = filterModel[key].condition2;
    const operator = filterModel[key].operator;
    let filter = '';

    switch (condition1.filterType) {
      case 'date':
        filter += this.getDateFilterString(condition1.type, key, condition1) + ` ${operator} `;
        break;
      case 'text':
        filter += this.getTextFilterString(condition1.type, key, condition1) + ` ${operator} `;
        break;
      default: break;
    }
    switch (condition2.filterType) {
      case 'date':
        filter += this.getDateFilterString(condition2.type, key, condition2);
        break;
      case 'text':
        filter += this.getTextFilterString(condition2.type, key, condition2);
        break;
      default: break;
    }
    // search algolia with combined filter string
    this.filteredSearch(filter);
  }

  /**
   * This function searches algolia for single filter condition
   *
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  searchForSingleCondition(key: string, filterModel: any): void {
    switch (filterModel[key].filterType) {
      case 'date':
        switch (filterModel[key].type) {
          case 'equals':
            this.filteredSearch(`${key}\:${moment(filterModel[key].dateFrom).toDate().getTime()} `
              + `TO ${moment(filterModel[key].dateFrom).toDate().getTime() + 86400000}`);
            break;
          case 'notEqual':
            this.filteredSearch(`NOT ${key}\:${moment(filterModel[key].dateFrom).toDate().getTime()} `
              + `TO ${moment(filterModel[key].dateFrom).toDate().getTime() + 86400000}`);
            break;
          default: break;
        }
        break;
      case 'text':
        switch (filterModel[key].type) {
          case 'equals':
            this.filteredSearch(`${key}\:'${filterModel[key].filter}'`);
            break;
          case 'notEqual':
            this.filteredSearch(`NOT ${key}\:'${filterModel[key].filter}'`);
            break;
          case 'contains':
            this.querySearch(`${filterModel[key].filter}`);
            break;
          default: break;
        }
        break;
      default: break;
    }
  }

  /**
   * This function returns the filter string for algolia for text element from ag-grid filter data
   *
   * @param condition Condition type
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  getTextFilterString(condition: string, key: string, filterModel: any): string {
    switch (condition) {
      case 'equals':
        return `${key}\:'${filterModel.filter}'`;
      case 'notEqual':
        return `NOT ${key}\:'${filterModel.filter}'`;
      case 'contains':
        break;
      case 'notContains':
        break;
      case 'startsWith':
        break;
      case 'endsWith':
        break;
      default: return '';
    }
  }

  /**
   * This function returns the filter string for algolia for date element from ag-grid filter data
   *
   * @param condition Condition type
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  getDateFilterString(condition: string, key: string, filterModel: any): string {
    switch (condition) {
      case 'equals':
        return `${key}\:${moment(filterModel.dateFrom).toDate().getTime()}`;
      case 'notEqual':
        return `NOT ${key}\:${moment(filterModel.dateFrom).toDate().getTime()}`;
      case 'greaterThan':
        return '';
      case 'lessThan':
        break;
      case 'inRange':
        break;
      default: return '';
    }
  }

  /**
   * This function searches algolia with filters passed
   *
   * @param filter Filter for algolia search data
   */
  filteredSearch = (filter: string): void => {
    (<NgAisInstantSearch>this.instantSearchElement).config.searchClient.search(
      [({
        indexName: this.searchConfig.indexName,
        params: {
          filters: (this.searchConfig.searchParameters ? this.searchConfig.searchParameters.filters : '')
            + ' AND ' + filter
        }
      })]
    ).then(data => {
      this.gridApi.updateRowData((<any>data.results[0].hits));
    });
  }

  /**
   * This function searches algolia with query passed
   *
   * @param filter Query for algolia search data
   */
  querySearch(query: string): void {
    (<NgAisInstantSearch>this.instantSearchElement).config.searchClient.search(
      [({
        indexName: this.searchConfig.indexName,
        params: {
          query: query
        }
      })]
    ).then(data => {
      this.gridApi.updateRowData((<any>data.results[0].hits));
    });
  }

  /**
   * Sets the gridApis DOM layout to automatically detect the height based on the number of rows
   */
  setAutoHeight() {
    this.gridApi.setDomLayout('autoHeight');
  }

  /**
   * This function gets called when ag-grid column is resized
   */
  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  /**
   * This is called when the first data in the grid is rendered
   * @param params this is an object that contains the Api, the columnApi and the grids type
   */
  async onFirstDataRendered(params): Promise<void> {
    params.api.sizeColumnsToFit();
    try {
      params.api.resetRowHeights();
      // check if user has done process dashboard intro
      this.tutorialService.showTutorial('processDashboard');
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    clearInterval(this.algoliaRefreshInterval);
    document.removeEventListener('mousemove', this.resetTimer, false);
    document.removeEventListener('keydown', this.resetTimer, false);
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }

    if (this.tutorialSub) {
      this.componentSubs.unsubscribe();
    }
  }

  /**
   * Called when the grid is ready. Does not load data if the user does not have search access
   * @param params this is an object that contains the Api, the columnApi and the grids type
   */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    this.onColumnResized();

    if (!this.searchAccess) {
      this.rowData = [];
      this.gridApi.updateRowData(this.rowData);
    }
  }

  /**
   * Triggers on a key press, after searchChanged to update the row data
   * @param results the returned search results
   */
  onSearchChange({ results, state }: { results: any, state: any }): void {
    if (results && results.hits) {
      this.rowData = results.hits;
      if (this.gridApi) {
        for (const row of results.hits) {
          row['createdAt'] = moment(row['createdAt']).toDate();
          row['updatedAt'] = moment(row['updatedAt']).toDate();
        }
        this.gridApi.updateRowData(results.hits);
      } else {
        (<NgAisInstantSearch>this.instantSearchElement).refresh();
      }
    }
  }

  /**
   * Called when an administrator submits a new message for users on the process dashboard to see
   * this sends the message to firebase under the document titled AdministratorMessage in the ProcessDashboard Collection
   *
   * @param message new admin message
   * @param clear boolean to clear the admin message
   */
  submitNewMessage(message: HTMLTextAreaElement, clear: boolean = false): void {

    if (clear === true) message.value = ' '; // if user wants message cleared

    this.disableSubmitButton = true;
    this.adminLoading = true;
    this.http
      .post(environment.endPoints.DYNAMIC_INTERACTIONS.url + 'updateProcessDashboardAdminMessage', { message: message.value })
      .subscribe(_ => {
        this.administratorMessage = message.value;
        this.disableSubmitButton = false;
        this.adminLoading = false;
      });
  }

  /**
   * When the admin message is being changed, the submit button will disable temporarily.
   */
  onAdminMessageChange(): void {
    if (this.adminMessageTextArea.nativeElement.value.length === 0) {
      this.disableSubmitButton = true;
    } else {
      this.disableSubmitButton = false;
    }
  }

  /**
   * This function shows the tutorial animations based on current tutorial step
   *
   * @param _ HTML Element assigned for current introJS step
   */
  tutorialDomAnimations = (_: HTMLElement): void => {
    // this is to check if the introJS object exists on change event since change runs on load as well
    if (!this.introJsObj) {
      return;
    }
    switch (this.introJsObj._currentStep) {
      case 0: this.firstTutorialStep();
        break;
      case 1: this.secondTutorialStep();
        break;
      case 2: this.thirdTutorialStep();
        break;
      default: break;
    }
  }

  /**
   * This function runs on the first step of introJS
   */
  firstTutorialStep(): void {
    clearInterval(this.searchInterval);
    // clear the search field data
    (<HTMLInputElement>document.getElementsByClassName('ais-SearchBox-input')[0]).value = '';
  }

  /**
   * This function runs on the second step of introJS
   */
  secondTutorialStep(): void {
    // start adding search field data after 1 second
    setTimeout(() => {
      let count = 0;
      const searchRequest = 'Address Change Betty White';
      // After every 0.1 second, type in next character of search request
      this.searchInterval = setInterval(() => {
        if (count === searchRequest.length) {
          clearInterval(this.searchInterval);
          return;
        }
        // update the search field data
        (<HTMLInputElement>document.getElementsByClassName('ais-SearchBox-input')[0]).value += searchRequest[count];
        count++;
      }, 100);
    }, 1000);
  }

  /**
   * This function runs on the third step of introJS
   */
  thirdTutorialStep(): void {
    clearInterval(this.searchInterval);
    // clear the search field data
    (<HTMLInputElement>document.getElementsByClassName('ais-SearchBox-input')[0]).value = '';
    // disable the introJS tooltip buttons to prevent change of steps until the animation is done
    (<HTMLElement>document.getElementsByClassName('introjs-tooltipbuttons')[0]).style.opacity = '0.4';
    (<HTMLElement>document.getElementsByClassName('introjs-tooltip')[0]).style.pointerEvents = 'none';

    // swap columns after 1 second
    setTimeout(() => {
      this.gridColumnApi.moveColumnByIndex(1, 2);
      // swap columns after 1 second
      setTimeout(() => {
        this.gridColumnApi.moveColumnByIndex(2, 3);
      }, 1000);
      // swap columns after 1 second
      setTimeout(() => {
        this.gridColumnApi.moveColumnByIndex(3, 1);
        // Enable the introJS tooltip buttons
        (<HTMLElement>document.getElementsByClassName('introjs-tooltipbuttons')[0]).style.opacity = '1';
        (<HTMLElement>document.getElementsByClassName('introjs-tooltip')[0]).style.pointerEvents = 'auto';
      }, 2000);
    }, 1000);
  }

  /**
   * This function resets the timer for fetching algolia data and gets called onmousemove event
   */
  resetTimer = (): void => {
    this.timer = 0;
    clearInterval(this.algoliaRefreshInterval);
    // set the interval for 1 minute for every 10 seconds to refresh algolia data
    this.algoliaRefreshInterval = setInterval(() => {
      if (this.timer >= 6) {
        clearInterval(this.algoliaRefreshInterval);
      }
      this.timer++;
      // re-fetch data from algolia
      try {
        (<NgAisInstantSearch>this.instantSearchElement).refresh();
      } catch (error) {
        console.log(error);
        this.loggingService.logMessage('Please refresh the page to update table data.', false, 'info');
      }
      setTimeout(() => {
        this.agGrid?.api.setFilterModel(this.filterModel);
      }, 500);
      console.log('Refreshed Process Dashboard...');
    }, 10000);
  }

  /**
   * This functions toggles auto-refresh for table data in user preferences
   */
  async toggleAutoRefresh(): Promise<void> {
    this.refreshLoading = true;
    // set user preferences for auto-refresh
    this.userPreferences.autoRefreshProcessDashboard = !this.toggleRefresh;
    try {
      await this.userService.updateUserPreferences(this.userPreferences);
      this.toggleRefresh = !this.toggleRefresh;
      this.refreshLoading = false;
      if (this.toggleRefresh) {
        // this is to add the event listener to re-fetch algolia data
        this.resetTimer();
        document.addEventListener('mousemove', this.resetTimer, false);
        document.addEventListener('keydown', this.resetTimer, false);
      } else {
        clearInterval(this.algoliaRefreshInterval);
        document.removeEventListener('mousemove', this.resetTimer, false);
        document.removeEventListener('keydown', this.resetTimer, false);
      }
    } catch (error) {
      this.refreshLoading = false;
      console.log(error);
    }
  }

  /**
   * This function updates whether or not the user wants to receive emails from completed processes
   */
  async toggleSendEmails() {
    try {
      this.refreshEmailLoading = true;
      const userPreferences = await this.userService.getUserPreferences();
      if (this.emailToggle) {
        this.emailToggle = false;
        userPreferences.toggleEmailsProcessDashboard = false;
      } else {
        this.emailToggle = true;
        userPreferences.toggleEmailsProcessDashboard = true;
      }
      await this.userService.updateUserPreferences(userPreferences);
      this.refreshEmailLoading = false;
    } catch (err) {
      this.refreshEmailLoading = false;
      console.log(err);
    }
  }

  //#region Token Functions

  /**
   * This gets the search token on processes
   */
  private setupSearch(): void {
    this.setProcessPropertyNameMapping(); // set the default property names.
    this.setDefaultColumnDefinitions(); // set the default column definitions.

    this.rowHeight = 275; // default row height.
    this.frameworkComponents = { CustomTooltipComponent: CustomTooltipComponent };

    this.setColumnDefinitions(); // set the search column definitions.
    this.paginationPageSize = '20'; // default pagination size
    this.paginationNumberFormatter = (params) => {
      return "[" + params.value.toLocaleString() + "]";
    };

    this.overlayNoRowsTemplate = "No Results";
  }

//#region Setup Search Grid Items.

  /**
   * This sets the column definitions for search.
   */
  private setColumnDefinitions(): void {
    this.columnDefs = [
      (this.searchUtils.getColumnDefinition( 'EVA Request ID #', 'processId')),
      (this.searchUtils.getColumnDefinition('Process Name', 'processName')),
      (this.searchUtils.getColumnDefinitionWithStyleAndAutoHeightEnabled('Process Description', 'processDescription')),
      (this.searchUtils.getColumnDefinition('Requester Name', 'requesterName')),
      (this.searchUtils.getColumnDefinition('Requester Email', 'requesterEmail')),
      (this.searchUtils.getColumnDefinitionForDateTime('Submitted At', 'createdAt')),
      (this.searchUtils.getColumnDefinitionForDateTime('Updated At', 'updatedAt')),
      {headerName: 'Status', field: 'status', sortable: this.processDashboardAgGridIsSortable, filter: 'agTextColumnFilter', filterParams: {
        filterOptions: ["contains", "equals", "notEqual"]}, width: 200, cellStyle: { 'white-space': 'normal !important' },
        autoHeight: true, tooltipComponent: "CustomTooltipComponent",
        tooltipValueGetter: (params) => {
          if (params && params.data && params.data.exceptionMessage.length) {
            let exceptionMessage: string = params.data.exceptionMessage;
            // check if an error message needs to be made user friendly
            // if it contains an error code, remove error code and show in separate field

            if (exceptionMessage.includes('[BE]')) { // business exception
              exceptionMessage = 'A business exception occurred while processing this request. The bot has either sent an email to the submitter with the details needed and/or created a banking task to Experience Operations for manual fulfillment. In most cases, depending on if the functionality is built in, CRM interaction notes would be created.';
            } else if (exceptionMessage.includes('[SE]')) { // system exception
              exceptionMessage = 'A system exception occurred while processing this request. The bot could not complete this request due to a technical problem. Don’t worry, it has been sent to Experience Operations for manual fulfillment.';
            } else if (exceptionMessage.includes('[VE]')) { // validation exception
              exceptionMessage = 'A validation exception occurred while processing this request. The bot is looking for more information and has sent an email to the submitter detailing the missing information. The submitter will need to refer to that email to fix the issue, then resubmit the request if applicable.';
            }
            // returns to custom tooltip component
            // return { exceptionMessage: exceptionMessage };
            return exceptionMessage;
          }
        },
        cellRenderer: (data) => {
          let returnMsg = data.data.status;
          if (data.data.status === 'Todo') {
            returnMsg = 'To Do';
          } else if (data.data.status === 'InProgress') {
            returnMsg = 'In Progress';
          }
          if (data.data.exceptionMessage.includes('[BE]')) { // business exception
            data.value = 'Sent to a team member for fulfillment/resolution';
          } else if (data.data.exceptionMessage.includes('[SE]')) { // system exception
            data.value = 'w/ Experience Operations';
          } else if (data.data.exceptionMessage.includes('[VE]')) { // validation exception
            data.value = 'Returned to Submitter';
          }

          if (data.data.exceptionMessage.length || data.data.exceptionType.length) {
            returnMsg = ' <i class="material-icons"> message </i> ' + data.value;
          }
          return returnMsg;
        }
      },
      (this.searchUtils.getInitiallyHiddenColumnDefinition('Group Name', 'groupName')),
      (this.searchUtils.getInitiallyHiddenColumnDefinition('Group Public Key', 'groupPublicKey')),
      (this.searchUtils.getInitiallyHiddenColumnDefinition('Requester Public Key', 'requesterPublicKey'))
     ];
  }

  /**
   * This sets the default column definitions.
   */
  private setDefaultColumnDefinitions(): void {
    this.defaultColDef = {
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      tooltipComponent: 'CustomTooltipComponent'
    };
  }

  /**
   * This sets the process property name mapping for search.
   */
  private setProcessPropertyNameMapping(): void {
    this.processPropertyNameMapping = {
      properties: [
        { propertyName: 'groupName', propertyUserFriendlyName: 'Group Name' },
        { propertyName: 'processDescription', propertyUserFriendlyName: 'Process Description' },
        { propertyName: 'requesterName', propertyUserFriendlyName: 'Requester Name' },
        { propertyName: 'requesterEmail', propertyUserFriendlyName: 'Requester Email' },
        { propertyName: 'createdAt', propertyUserFriendlyName: 'Submitted At', valueModifiers: [ValueModifier.DateShort] },
        { propertyName: 'updatedAt', propertyUserFriendlyName: 'Updated At', valueModifiers: [ValueModifier.DateShort] },
        { propertyName: 'status', propertyUserFriendlyName: 'Status' },
        { propertyName: 'exceptionMessage', propertyUserFriendlyName: 'Exception Message', optional: true,
          valueModifiers: [ValueModifier.ProcessDashboardExceptionMessage] }
      ]
    };
  }

//#endregion Setup Search Grid Items.

//#endregion Token Functions

}
