/**
 * This enum is used to announce user feedback through the EVA system through behavior subjects and as the ENUM for the
 * status of the users last state.
 */
export const enum ChatMLInitialUserResponse {
    GOOD = 'Good',
    TRY_AGAIN = 'Try Again',
    GET_BETTER = 'Get Better',
    WRONG_DOCUMENT = 'Wrong Document',
    RIGHT_DOCUMENT = 'Right Document'
}

/**
 * This enum is used for a second level of feedback announced through behavior subjects through the EVA application. It is
 * also used as the second level of status in the users last state.
 */
export const enum ChatMLGetBetterUserResponse {
    NOT_RELEVANT = 'Not Relevant',
    WRONG_FACTS = 'Wrong Facts',
    NO_UNDERSTANDING = 'No Understanding',
    OTHER = 'Other'
}

/**
 * This is the interface for the last state to include the updates from the user.
 */
export interface TfidfUserLastStateFeedBack {
    firstState?: ChatMLInitialUserResponse; // this is first state of feedback for the user in the EVA application.
    secondState?: ChatMLGetBetterUserResponse; // this is the second state of feedback for the user in the EVA application.
    secondStateUserResponse: string; // this is the second state and what the user provided in the EVA application.
}
