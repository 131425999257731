import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { TfidfFeedbackDocument, TfidfFeedbackGroupRequest } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { KnowledgeFeedbackService } from '@eva-services/knowledge/feedback/knowledge-feedback.service';
import { Group } from '@eva-model/group';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Grid, GridOptions } from 'ag-grid-community';
import { LoggingService } from '@eva-core/logging.service';
import { NotificationsFeedbackViewComponent } from '../notifications-feedback-view/notifications-feedback-view.component';

@Component({
  selector: 'app-notifications-feedback-group',
  templateUrl: './notifications-feedback-group.component.html',
  styleUrls: ['./notifications-feedback-group.component.scss']
})
export class NotificationsFeedbackGroupComponent implements OnInit, OnChanges {

  @Input() group: Group;
  @Input() status: boolean;

  @ViewChild(Grid) grid: Grid;

  // state
  loading = true; // initial load of page
  error = false;
  fetching = false; // getting more paged data from server

  // columns for display
  columnDefs = [
    {
      field: 'docName', headerName: 'Document', cellStyle: {'white-space': 'normal'}
    },
    { field: 'userFeedback', headerName: 'Comments', cellStyle: {'white-space': 'normal', 'line-height': '20px'} },
    { field: 'isReviewed', headerName: 'Status', valueFormatter: this.isReviewedFormatter },
    { field: 'query', headerName: 'Query', cellStyle: {'white-space': 'normal', 'line-height': '20px'} },
    {
      field: 'timestamp', headerName: 'Created At', valueFormatter: this.getReadableTimeFromTimestampFormatter,
      cellStyle: {'white-space': 'normal', 'line-height': '20px'}
    },
    { field: 'userEmail', headerName: 'Submitter' }
  ];

  // options for grid to customize
  gridApi: any;
  gridOptions: GridOptions = {
    rowStyle: {cursor: 'pointer'}
  };

  // table data
  feedbackData: TfidfFeedbackDocument[];
  selectedFeedbackData: TfidfFeedbackDocument = null;

  // custom table paging
  page = 1;

  // data
  reviewedFeedback: TfidfFeedbackDocument[];
  unreviewedFeedback: TfidfFeedbackDocument[];

  // data for request
  requestData: TfidfFeedbackGroupRequest;

  constructor(private knowledgeFeedbackService: KnowledgeFeedbackService,
              private loggingService: LoggingService,
              private router: Router,
              private dialog: MatDialog) {}

  ngOnInit() {
    // group is defined, create request
    this.requestData = {
      knowledgeGroupPublicKey: this.group.groupPublicKey,
      isReviewed: this.status
    };

    // get initial data
    this.getFeedback();
  }

  ngOnChanges(changes: SimpleChanges) {
    // check if ngOnInit has fired first. OnChanges seems to fire before ngOnInit.
    if (!this.requestData) {
      return;
    }

    this.selectedFeedbackData = null;

    // set our group public key to new key
    if (changes.group) {
      this.requestData.knowledgeGroupPublicKey = changes.group.currentValue.groupPublicKey;
    }

    // set status
    if (changes.status) {
      this.requestData.isReviewed = changes.status.currentValue;
    }

    // initiate getting new data
    this.getFeedback();
  }

  /**
   * When Grid is available, keep a reference to it's API
   */
  onGridReady(grid: any) {
    this.gridApi = grid.api;
  }

  /**
   * Called when the grid is ready. Does not load data if the user does not have search access
   * @param params this is an object that contains the Api, the columnApi and the grids type
   */
  onFirstDataRendered(grid: any): void {
    grid.api.sizeColumnsToFit();
  }

  /**
   * agGrid event
   */
  onRowSelectionChanged(grid: any): void {
    const selected = grid.api.getSelectedRows();
    if (selected.length > 0) {
      // set selected item
      this.selectedFeedbackData = selected[0];
      this.viewFeedbackDialog(this.selectedFeedbackData);
    } else {
      // clear selected item
      this.selectedFeedbackData = null;
    }
  }

  /**
   * Formats the boolean value of the status into something more readable.
   *
   * @param row row of agGrid and the data in the row.
   */
  isReviewedFormatter(row: any) {
    if (row.value) {
      return 'Reviewed';
    }
    return 'Needs Review';
  }

  /**
   * Used to get feedback from the server depending on the data inside requestData
   */
  async getFeedback(cursors: {nextCursor?: string, prevCursor?: string} = {}): Promise<void> {
    this.error = false;
    this.loading = true;

    try {
      const feedback = await this.knowledgeFeedbackService.getFeedbackByGroup({
        knowledgeGroupPublicKey: this.requestData.knowledgeGroupPublicKey,
        isReviewed: this.requestData.isReviewed,
        ...cursors
      });

      if (this.requestData.isReviewed) {
        this.reviewedFeedback = feedback.feedback;
        this.feedbackData = this.reviewedFeedback;
      } else {
        this.unreviewedFeedback = feedback.feedback;
        this.feedbackData = this.unreviewedFeedback;
      }

      this.loading = false;
    } catch (err) {
      this.error = true;
      this.loading = false;
      console.error(err);
      return Promise.reject(err);
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    // if first tab active (unreviewed feedback)
    if (event.index === 0) {
      this.requestData.isReviewed = false;
    }
    // if second tab active (reviewed feedback)
    if (event.index === 1) {
      this.requestData.isReviewed = true;
    }

    // get feedback with new request object
    this.getFeedback();
  }

  /**
   * Takes a grid row as a parameter and takes the app to the document and shows the feedback panel.
   */
  viewFeedback(feedback: TfidfFeedbackDocument) {
    this.knowledgeFeedbackService.showFeedbackPanel();

    const params = {
      id: feedback.docId,
      group: feedback.knowledgeGroupPublicKey,
      version: feedback.docVersion
    };

    this.router.navigate([`/knowledge/edit`], {queryParams: params});
  }

  /**
   * Shows the feedback in a modal
   */
  viewFeedbackDialog(feedback: TfidfFeedbackDocument) {
    this.dialog.open(NotificationsFeedbackViewComponent, {
      data: feedback,
      maxHeight: '75vh',
      minWidth: '75vw',
      maxWidth: '75vw'
    });
  }

  /**
   * Creates a readable timestamp value (in agGrid)
   */
  getReadableTimeFromTimestampFormatter(row: any): string {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(row.value);
    // Hours part from the timestamp
    const dateStr = date.toString();
    // Get index of time zone name
    const timezoneIndex = dateStr.indexOf('(');

    return dateStr.substr(0, timezoneIndex - 1);
  }

  /**
   * Get Request to get the next X items using the end id from the result set as a cursor
   */
  async nextPage() {
    // get last item id and use it as a cursor
    const cursorId = this.feedbackData[this.feedbackData.length - 1].id;
    this.fetching = true;

    try {
      await this.getFeedback({nextCursor: cursorId});
      this.page = this.page + 1;
      this.fetching = false;
    } catch (err) {
      this.fetching = false;
    }
  }

  /**
   * Get Request to get the prev X items using the beginning id from the result set as a cursor
   */
  async prevPage() {
    const cursorId = this.feedbackData[0].id;
    this.fetching = true;

    try {
      await this.getFeedback({prevCursor: cursorId});
      this.page = this.page - 1;
      this.fetching = false;
    } catch (err) {
      this.fetching = false;
    }
  }

}
