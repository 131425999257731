/**
 * Interaction node data with nested structure.
 * Each node has a label, and a type or a list of children.
 */
export class InteractionNode {
    children: InteractionNode[];
    label: string;
    type: any;
    data: any;
    collapsedIcon: string;
    expandedIcon: string;
  }
