import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AnnounceNewTab, AnnounceNewTabs } from '@eva-model/chat/chat';
import { filter, take } from 'rxjs/operators';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { LastStateTab, LastStateTabs } from '@eva-model/userLastState';
import { Routes } from '@eva-model/menu/defaults/mainMenu';

export interface AnnounceCreateNewFunction {
  data: Function;
  params: any[];
}

export interface AnnounceCloseTab {
  entityType: EntityType;
  entityId: string;
  closeSubject: Subject<boolean>;
  tabIndex: number;
}

export type EntityType = 'Process' | 'Workflow' | 'Interaction' | 'Knowledge' | 'Change' | 'Technical';

@Injectable({
  providedIn: 'root'
})
export class MultiViewService {

  // move tabs here with title to be updated on save of new interaction
  tabs: AnnounceNewTabs = {};
  currentEntityType: EntityType;

  // This subject is used for creating a new tab in the multi-view component
  private createNewTab: BehaviorSubject<AnnounceNewTab> = new BehaviorSubject(null);
  // This subject assigns the function to be called when Add '+' button is clicked in multi-view component
  private createNewTabFunction: BehaviorSubject<AnnounceCreateNewFunction> = new BehaviorSubject(null);
  // This subject closes the tab specified
  private closeTab: Subject<AnnounceCloseTab> = new Subject();
  // This subject announces when a tab is closed
  private removeTab: Subject<boolean> = new Subject();

  public createNewTab$: Observable<AnnounceNewTab> = this.createNewTab.asObservable();
  public createNewTabFunction$: Observable<AnnounceCreateNewFunction> = this.createNewTabFunction.asObservable();
  public closeTab$: Observable<AnnounceCloseTab> = this.closeTab.asObservable();
  public removeTab$: Observable<boolean> = this.removeTab.asObservable();

  constructor(private lastStateService: LastStateService) {
    this.lastStateService.lastState$.pipe(
      filter(state => !!state),
      filter(state => !!state.tabs),
      take(1)
    ).subscribe(
      (state) => {
        // this initializes service tabs to tabs fetched from last state
        // console.log('initializing tabs to multi view service', state.tabs);
        this.tabs = {
          ...this.tabs,
          ...JSON.parse(JSON.stringify(state.tabs))
        };
      }
    );
  }

  setCreateNewTab(data: AnnounceNewTab): void {
    this.createNewTab.next(data);
  }

  setCreateNewTabFunction(data: Function, ...params: any[]): void {
    this.createNewTabFunction.next({data, params});
  }

  setCloseTab(data: AnnounceCloseTab): void {
    this.closeTab.next(data);
  }

  /**
   * This function updates the local tabs object and triggers save to last state object with tab updates
   *
   * @param route Route being updated
   * @param action Action being performed on the tab
   * @param tab tab being updated
   * @param index index of the tab being updated
   */
  updateTabsAndSaveToLastState(route: Routes, action: 'Add' | 'Remove' | 'Update', tab?: AnnounceNewTab | Partial<AnnounceNewTab>,
    index?: number): void {
    let removedTab: LastStateTab;
    let uniqueTabId: string;

    switch (action) {
      case 'Add':
        if (!this.tabs[route]) {
          this.tabs[route] = [];
        }
        this.tabs[route].push({
          ...tab as AnnounceNewTab,
          createdAt: Date.now(),
          updatedAt: Date.now()
        });
        uniqueTabId = tab.additionalInstanceData.uniqueTabId;
        break;
      case 'Remove':
        removedTab = this.tabs[route].splice(index, 1)[0];
        uniqueTabId = removedTab.additionalInstanceData.uniqueTabId;
        this.tabs[route].forEach((routeTab, tabIndex) => {
          if (routeTab.componentRef) {
            routeTab.componentRef.instance.tabIndex = tabIndex;
          }
        });
        this.removeTab.next(true);
        break;
      case 'Update':
        if (!this.tabs[route] || this.tabs[route].length === 0) {
          this.tabs[route] = [{ tabName: undefined }];
        }
        Object.keys(tab).forEach(key => {
          this.tabs[route][index][key] = tab[key];
        });
        this.tabs[route][index].updatedAt = Date.now();
        uniqueTabId = this.tabs[route][index].additionalInstanceData.uniqueTabId;
        break;
      default: break;
    }
    // format local tabs so as to be saved to last state
    const lastStateTabs = this.updateTabsForLastState();
    // update last state tabs with new changes from local tabs object
    let currentLastState = this.lastStateService.getLastState();
    currentLastState = {
      ...currentLastState,
      tabs: lastStateTabs
    };
    // remove shouldNotUpdateDatabase property if exists to make sure database is updated
    delete currentLastState.shouldNotUpdateDatabase;
    this.lastStateService.updateSaveLastState(currentLastState, { route, action, removedTab, index, uniqueTabId });
  }

  /**
   * This function removes properties from last state tabs which are to be used locally per instance
   */
  private updateTabsForLastState(): LastStateTabs {
    const lastStateTabs: LastStateTabs = {};
    Object.keys(this.tabs).forEach((route: Routes) => {
      if (route === Routes.Home) {
        return;
      }
      this.tabs[route].forEach((tab: AnnounceNewTab) => {
        const { component, scrollPosition, componentRef, ...lastStateTab } = tab;
        if (!lastStateTabs[route]) {
          lastStateTabs[route] = [];
        }
        lastStateTabs[route].push(JSON.parse(JSON.stringify(lastStateTab)));
      });
    });
    Object.keys(lastStateTabs).forEach((route: Routes) => {
      lastStateTabs[route].forEach((tab: LastStateTab) => {
        this.removeUndefined(tab);
      });
    });
    return lastStateTabs;
  }

  /**
   * This function removes any undefined values from an object.
   * Firebase doesn't support undefined values so need to do clean up before sending update
   */
  private removeUndefined(currentObject: any) {
    if (!currentObject) {
      return;
    }
    Object.keys(currentObject).forEach((key: string) => {
      if (typeof currentObject[key] === 'undefined') {
        currentObject[key] = undefined;
      }
      if (Array.isArray(currentObject[key])) {
        currentObject[key].forEach(object => {
          this.removeUndefined(object);
        });
      }
      if (currentObject[key] === 'object' && !Array.isArray(currentObject[key])) {
        this.removeUndefined(currentObject[key]);
      }
    });
  }
}
