import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KnowledgeModel } from '@eva-model/knowledge';
import { KnowledgeDocument, KnowledgeDocumentTableOfContents, KnowledgeDocumentTableOfContentsItem } from '@eva-model/knowledge/knowledge';

@Component({
  selector: 'app-knowledge-document-section',
  templateUrl: './knowledge-document-section.component.html',
  styleUrls: ['./knowledge-document-section.component.scss']
})
export class KnowledgeDocumentSectionComponent implements OnInit {

  selectedSection: KnowledgeDocumentTableOfContentsItem;
  toc: KnowledgeDocumentTableOfContents;

  constructor(public dialogRef: MatDialogRef<KnowledgeDocumentSectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {selection: string, documentModel: KnowledgeModel, document: KnowledgeDocument}) { }

  ngOnInit(): void {
    this.toc = this.data.documentModel.createTableOfContents(this.data.document.id, this.data.document.sections);
  }

  /**
   * Close dialogs and passes the selected doc data in a shape the dialog callback expects to place into the editor.
   */
   closeDialogAndEmitData() {
    let linkText: string;

    if (this.data.selection !== '') {
      linkText = this.data.selection;
    }

    if (this.selectedSection) {
      this.dialogRef.close({
        href: `#${this.selectedSection.sectionId}`,
        text: linkText ? linkText : this.selectedSection.title
      });
      return;
    }

    this.dialogRef.close();
  }

}
