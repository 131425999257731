import { EVAGenericSignedObject } from "@eva-model/blockchain/signing";
import { GroupSpecificEncryptedObject } from "@eva-model/encryption/encryption";

/**
 * These interfaces are used to interact with the EVA storage project. Updates to these interfaces should be made in the
 * EVA Links project.
 */

export interface EVAStorageUnencryptedObject {
    status: string; // the status of the response
    unencryptedObject: any; // the storage object that was returned in unencrypted form
    existingKeys: string[]; // the existing public keys that are used as an offset to the unencrypted object.
}

/**
 * This interface is used to return the response of a storage request to the application
 * if successful there should be a id provided from EVA-links
 */
export interface EVAStorageRequestResponse {
    idUrl: string; // the id of the item that was stored.
    successful: boolean; // whether the storage request succeeded.
}

/**
 * This interface is used to return the response of a storage request to the application
 * if successful there should be a id provided from EVA-links
 */
export interface EVAStorageRequestResponseWithStorageObject {
    idUrl: string; // the id of the item that was stored.
    successful: boolean; // whether the storage request succeeded.
    encryptedSignedObject?: EVAGenericSignedObject; // this is the full signed encrypted object.
}

/**
 * This interface contains the data returned to EVA from a retrieval request to the EVA links project. It is used to obtain
 * the data in the encrypted object.
 */
export interface EVAStorageDataReturnObject {
    requesterKeyPairEncryption: GroupSpecificEncryptedObject; // this contains the encrypted data and iv for the encrypted data.
    encryptedData: string; // the hex encoding representation of the buffer object that was created.
    storagePublicKey?: string; // the public key that stored the object.
    existingPublicKeys: string[]; // this contains a list of all public keys that currently have access to the data.
    keyPairedTo?: string; // this is returned when a user gets the data that they have stored. it is a randomly chosen key that has a
    // assymetric big number that is tied to it.
}

export interface EVAStorageReturnObject {
    linkData: EVAStorageDataReturnObject;
}


/**
 * This interface is used to setup the eva link storage request and contains the details for which data to retreive.
 */
export interface StorageRequestDetails {
    linkUrl: string; // the link that is being requested.
    requesterPublicKey: string; // the public key of the requester asking for the data. (may be signing or encryption)
    requestTimestamp: number; // the EVA system time that the request was signed at.
}

/**
 * This interface is used to request data from the EVA links project.
 */
export interface StorageRequest {
    data: StorageRequestDetails; // the details of what data the user is trying to retreive.
    signature: string; // a crypto signature in hex format providing the proof of private key ownership.
}

/**
 * This interface is used to contain the initialization vector and password (random bytes) used to encrypt or unencrypt
 * a piece of data from EVA links.
 */
export interface PublicKeySpecificNode {
    iv: Buffer; // an initialization vector
    randomBytes: Buffer; // the password for the file.
}

/**
 * This interface is used to store a piece of data to the EVA-Links project and contains the data that once accepted,
 * will be added to the EVA links project. The incomingAccess node is the stringified version of PublicKeySpecificEncryptedObject
 * in the encryption models. This was transformed to a string for ease of movement to the eva links project.
 */
export class StorageObjectData {
      storagePublicKey: string; // the public key that was used as the private key verification of the request.
      idUrl: string; // the ID of the storage object. This is only set when updating and object and not on creation.
      timestamp: number; // the EVATime timestamp of the request.
      encryptedData: string; // the hexidecimal representation of the data that is being stored in the EVA system.
      incomingAccess: string; // the stringified representation of a PublicKeySpecificEncryptedObject
}

/**
 * This interface is used to send a request to store an item to the EVA-Links project and to store it.
 */
export class StorageObject {
    data: StorageObjectData; // the data required for storage.
    signature: string; // the crypto signature proof of the transaction.
}
