import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class ProcessCompareService {

  processes$: Observable<any[]>;

  constructor(
    private _evaGlobalSrv: EvaGlobalService,
    private _http: HttpClient,
    private perf: AngularFirePerformance
  ) { }

  async getProcessCompareReport(from: number, to: number) {
    const url = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'processCompare';

    try {

      const bodyData = {
        'uid': this._evaGlobalSrv.userId,
        'from': from,
        'to': to
      };

      const processIssues = await this._http.post<any>(url, bodyData).pipe(
        trace('process-compare-getProcessCompareReport')
      ).toPromise();
      return processIssues;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
    }
  }

}
