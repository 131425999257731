import { Injectable } from '@angular/core';

import {
  DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER,
  DYNAMIC_FORM_CONTROL_TYPE_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_INPUT,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_SELECT,
  DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
  DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
  DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,

  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK,

  DynamicFormControlModel,
  DynamicInputModel,
  DynamicCheckboxModel,
  DynamicCheckboxGroupModel,
  DynamicDatePickerModel,
  DynamicRadioGroupModel,
  DynamicSelectModel,
  DynamicSliderModel,
  DynamicSwitchModel,
  DynamicTextAreaModel,
  DynamicFormOption,
  DynamicFormControlLayout,
  DynamicFormOptionConfig,
  DynamicFormValueControlModel,
  MATCH_DISABLED,
  DynamicFormControlRelation
} from '@ng-dynamic-forms/core';

import { Guid } from '@eva-core/GUID/guid';
import { Observable, Subject, from } from 'rxjs';

import {
  DYNAMIC_FORM_CONTROL_TYPE_INPUT_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_SELECT_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_SLIDER_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_SWITCH_ELEMENTS,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP_ELEMENTS,
  formControlValidationPattern
} from './form-control-properties';

import { mockedInteractions } from '@eva-services/form-builder/mockedInteractions';

import { TreeNode } from 'primeng/api';

import { FRM_CNTRL_INPUT, FRM_CNTRL_DATETIME, FRM_CNTRL_ADDITIONAL, FRM_CNTRL_CUSTOM } from '../../model/formControls';
import { InteractionFlatModel } from '@eva-model/interactionFlatModel';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { IfThenLogicAction, IfThenLogicOptions, Relation, SubRelation } from '@eva-model/interactionElementRelationDialogModel';
import { ElementRelations } from '@eva-model/interaction/dynamicForm';

@Injectable()
export class FormBuilderService {

  // used to make sure an elements input type is in an acceptable format
  validInpuTypeArray = [
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK
  ];

  EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS = 'CHIPS';

  private inputType: any; // stores the input type of the form control globally
  private additionalObj: any; // used to store and set default/pre-set values
  inputTypeObs$: Observable<any>;
  private inputTypeSubject = new Subject<any>();

  constructor() {
  }
  // stores the input type of a valid element
  validInputType: string = null;

  /**
   * This function sets the input type of the form control to the global service variable
   */
  public setInputType(formControl: any): void {
    this.inputType = formControl.inputType;
  }

  /**
   * This function is used to build and set control models for dynamic forms
   *
   * @param type type of element, set in form-control-properties e.g. 'INPUT'
   * @param inputType type of input accepted for control, set in form-control-properties e.g. 'text'
   * @param controlId guid for control
   * @param controlName name of control within element e.g. label/disabled/hint
   * @param controlValue value stored in control
   * @param controlOptions allows controls to be built with a pre-existing array
   * @param formControl stores a DynamicFormControlModel
   */
  public createFormControlModel(
    type: string,
    inputType: string,
    controlId?: string,
    controlName?: string,
    controlValue?: any,
    controlOptions?: any,
    formControl?: any
  ): DynamicFormControlModel | null {

    // set the properties of the form control based on its type
    switch (type) {

      case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:

      return null;

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:

      return this.getDynamicCheckbox(controlId, controlValue, controlName);

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
      case DYNAMIC_FORM_CONTROL_TYPE_GROUP:

      return this.getDynamicCheckboxGroup(controlValue, controlId);

      case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:

      return this.getDynamicDatePicker(controlId, controlValue);

      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:

      return this.getDynamicInput(inputType, controlId, controlValue, controlName, formControl && formControl._list);

      case this.EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS:

      return this.getDynamicCHIPS(controlId, controlValue, inputType, controlName);

      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:

      return this.getDynamicRadioGroup(controlId, controlOptions, controlValue);

      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:

      return this.getDynamicSelect(controlId, controlValue, controlName, inputType, controlOptions);

      case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:

      return this.getDynamicSlider(controlId, controlValue, formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:

      return this.getDynamicSwitch(controlId, controlValue);

      case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:

      return this.getDynamicTextArea(controlId, controlName, controlValue);

      default:
      return null;
    }
  }

  /**
   * This function returns a dynamic date picker form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   */
  private getDynamicDatePicker(controlId: string, controlValue: any): DynamicFormControlModel {
    return new DynamicDatePickerModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      disabled: false,
      value: controlValue ? controlValue : new Date()
    });
  }

  /**
   * This function returns a dynamic text area form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlName Name of the form control to set
   * @param controlValue Value of the form control
   */
  private getDynamicTextArea(controlId: string, controlName: string, controlValue: any): DynamicFormControlModel {
    return new DynamicTextAreaModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: controlName ? controlName : `Text area`,
      rows: 3,
      value: controlValue ? controlValue : null
    });
  }

  /**
   * This function returns a dynamic switch form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   */
  private getDynamicSwitch(controlId: string, controlValue: any): DynamicSwitchModel {
    // set placeholder label depending on ID. if its ID is not 'additional', load normal label.
    let switchPlaceholder: string;
    if (controlId === 'additional') {
      switchPlaceholder = 'Default Switch Setting';
    } else { switchPlaceholder = 'Switch';
    }
    // set default value
    if (controlId === 'additional' && controlValue) {
      this.additionalObj = controlValue;
      controlValue = controlValue.defaultVal;
    }
    return new DynamicSwitchModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: switchPlaceholder,
      value: controlValue ? controlValue : false
    });
  }

  /**
   * This function returns a dynamic slider form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   * @param formControl form Control object
   */
  private getDynamicSlider(controlId: string, controlValue: any, formControl: any): DynamicSliderModel {
    // set default values for slider
    let sliderPlaceholder: string;
    if (controlId === 'additional') {
      sliderPlaceholder = 'Default Slider Setting';
    } else {
      sliderPlaceholder = 'Slider';
    }
    if (controlId === 'additional' && controlValue) {
      this.additionalObj = controlValue;
      controlValue = controlValue.defaultVal;
    }
    return new DynamicSliderModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: sliderPlaceholder,
      min: formControl ? formControl.min : 0,
      max: formControl ? formControl.max : 100,
      step: formControl ? formControl.step : 1,
      value: controlValue ? controlValue : null
    });
  }

  /**
   * This function returns a dynamic select form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   * @param controlName Name/label of the form control
   * @param inputType the input type of the control. if it is a country select control, it will be intlSelect
   * @param controlOptions List of options for select form control
   */
  private getDynamicSelect(
    controlId: string, controlValue: any, controlName: string, inputType: string, controlOptions: any[]
  ): DynamicSelectModel<string> {

   // #region automatically set the default field and options in the international select control
    // set default values for select input type
    if (controlId === 'additional' && controlValue) {
      this.additionalObj = controlValue;
      controlValue = controlValue.defaultVal;
    }

    let propertyValue; // if it is an international address, prepare the dropdown selection
    if (inputType === 'intlSelect') {
      controlOptions =
        [
          {
            value: 'Canada',
            label: 'Canada'
          },
          {
            value: 'USA',
            label: 'USA'
          }
        ];

      const defaultVal = [];
      defaultVal[0] = controlOptions[0].value;
      propertyValue = {'defaultVal': defaultVal};
    }

    // #End Region
    return new DynamicSelectModel<string>({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: controlName,
      multiple: (inputType === 'Multiple') ? true : false,
      placeholder: 'Click on drop down and select an option!',
      value: controlValue ? controlValue : null,
      options: Array.isArray(controlOptions) ? controlOptions :
        [
          {
            value: 'One',
            label: 'One'
          },
          {
            value: 'Two',
            label: 'Two'
          },
          {
            value: 'Three',
            label: 'Three'
          }
        ],
      additional: propertyValue
    });
  }

  /**
   * This function returns a dynamic radio group form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlOptions List of options for radio group form control
   * @param controlValue Value of the form control
   */
  private getDynamicRadioGroup(controlId: string, controlOptions: any, controlValue: any): DynamicRadioGroupModel<string> {
    const layout: DynamicFormControlLayout = {
      element: {
        container: '',
        control: '',
        errors: '',
        group: '',
        hint: '',
        host: '',
        label: 'paddingRight',
        option: 'paddingRight'
      }
    };
    return new DynamicRadioGroupModel<string>({
      id: controlId ? controlId : Guid.newGuid().toString(),
      options: Array.isArray(controlOptions) ? controlOptions :
        [
          {
            value: 'One',
            label: 'One'
          },
          {
            value: 'Two',
            label: 'Two'
          }
        ],
      value: controlValue ? controlValue : null
    }, layout);
  }

  /**
   * This function returns a dynamic chips form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   * @param inputType input type of the form control
   * @param controlName Name of the form control
   */
  private getDynamicCHIPS(controlId: string, controlValue: any, inputType: string, controlName: string): DynamicInputModel {
    // load default values for CHIP inputs if they exist
    let chipPlaceholder: string;
    if (controlId === 'additional') {
      chipPlaceholder = 'Enter default CHIPS value';
      if (controlValue) {
        this.additionalObj = controlValue;
        controlValue = controlValue.defaultVal;
      }
    } else {
      chipPlaceholder = 'Enter input CHIPS value';
    }
    this.validInputType = this.validInpuTypeArray.find(function (element) { return element === inputType; });

    return new DynamicInputModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      inputType: (this.validInputType) ? this.validInputType : DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
      label: controlName ? controlName : `Input ${(this.validInputType) ? this.validInputType : null}`,
      placeholder: chipPlaceholder,
      multiple: true,
      value: controlValue ? controlValue : null
    });
  }

  /**
   * This function returns a dynamic input form control
   *
   * @param inputType input type of the form control
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   * @param controlName Name of the form control
   */
  private getDynamicInput(inputType: string, controlId: string, controlValue: any, controlName: string, list?: string[])
    : DynamicInputModel {
    // find type of input for element
    this.validInputType = this.validInpuTypeArray.find(function (element) { return element === inputType; });
    let inputTypeFound = this.inputType;
    let inputPlaceholder;
    // if a value of type INPUT is stored in 'additional' this assumes it is the default value
    if (controlId === 'additional') {
      inputPlaceholder = 'Enter default input value';
    } else {
      inputPlaceholder = null;
      inputTypeFound = null;
    }
    // if a value of type INPUT is stored in 'additional', and has a value, load that value
    if (controlId === 'additional' && controlValue) {
      this.additionalObj = controlValue;
      controlValue = controlValue.defaultVal;
      if (controlValue && typeof controlValue === 'object') {
        controlValue = controlValue.defaultVal;
      }
    }
    return new DynamicInputModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      inputType: (this.validInputType) ? this.validInputType : inputTypeFound,
      label: controlName ? controlName : `Input ${(this.validInputType) ? this.validInputType : null}`,
      placeholder: inputPlaceholder,
      mask: this.inputMaskGenerator(inputType),
      pattern: this.inputValidationPattern(inputType),
      validators: this.inputValidatiors(inputType),
      value: controlValue ? controlValue : null,
      list: list
    });
  }

  /**
   * This function returns a dynamic checkbox group form control
   *
   * @param controlValue Value of the form control
   * @param controlId ID of the form control to set otherwise generates new one
   */
  private getDynamicCheckboxGroup(controlValue: any, controlId: string): DynamicCheckboxGroupModel {
    // for each checkbox in a checkbox group, set a new id and get the label
    const checkBoxGroup = [];
    if (controlValue && Array.isArray(controlValue)) {
      controlValue.forEach(chkBox => {
        checkBoxGroup.push(new DynamicCheckboxModel({ id: Guid.newGuid().toString(), label: chkBox.label, value: false }));
      });
    } else {
      for (let i = 1; i <= 3; i++) { // Just to make a sample check box group
        checkBoxGroup.push(new DynamicCheckboxModel({ id: `checkbox${i}`, label: `checkbox${i}`, value: false }));
      }
    }
    return new DynamicCheckboxGroupModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: 'Checkbox Group',
      group: checkBoxGroup
    });
  }

  /**
   * This function returns a dynamic checkbox form control
   *
   * @param controlId ID of the form control to set otherwise generates new one
   * @param controlValue Value of the form control
   * @param controlName Name of the form control
   */
  private getDynamicCheckbox(controlId: string, controlValue: any, controlName: string): DynamicCheckboxModel {

    // used for date times only - determines if date can be in the past
    if (controlId === 'isPastDate' && this.additionalObj) {
      controlValue = this.additionalObj.isPastDate;
    }
    // used for date times only - sets datetime to current date
    if (controlId === 'currentDateDefault' && this.additionalObj) {
      controlValue = this.additionalObj.currentDateDefault;
    }
    // used for date times only - sets datetime to current date
    if (controlId === 'additional' && controlValue) {
      this.additionalObj = controlValue;
      controlValue = controlValue.defaultVal;
    }
    return new DynamicCheckboxModel({
      id: controlId ? controlId : Guid.newGuid().toString(),
      label: controlName ? controlName : `Check box label`,
      value: controlValue ? controlValue : false,
    });
  }

  /**
   * This function generates input mask for input type passed
   *
   * @param inputType input type of the form control
   */
  private inputMaskGenerator(inputType: string): any {
    switch (inputType) {
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:
        return ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL:
        return null;

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT:
        return null;

      default:
        return null;
    }
  }

  /**
   * This function generates input validation pattern for inputType passed
   *
   * @param inputType input type of the form control
   */
  private inputValidationPattern(inputType: string): any {
    switch (inputType) {
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:
        return formControlValidationPattern.phone;

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL:
        return formControlValidationPattern.email;

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL:
        return formControlValidationPattern.url;

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT:

      default:
        return null;
    }
  }

  /**
   * This function generates the validators for the input type passed
   */
  private inputValidatiors(inputType: string): any {
    switch (inputType) {
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL:

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:

      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL:

      default:
        return null;
    }
  }

  /**
   * This function creates a form control setting model based on the form control passed
   *
   * @param formControl form control for which setting model is being generated
   */
  public createFormControlSettingModel(formControl: DynamicFormControlModel): DynamicFormControlModel[] | null {

    const type = formControl.type;
    switch (type) {

      case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:

        return this.createCheckBoxFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
      case DYNAMIC_FORM_CONTROL_TYPE_GROUP:

        return this.createCheckBoxGroupFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:

        return this.createInputFormCntrlSettingModel(formControl);

      case this.EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:

        return this.createRadioGroupFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:

        return this.createSelectFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:

        return this.createSliderFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:

        return this.createSwitchFormControlSettingModel(formControl);

      case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:

        return this.createInputTexAreaFormControlSettingModel(formControl);

      default:
        return null;
    }
  }

  /**
   * This function creates a form control setting model of input type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createInputFormCntrlSettingModel(formControl: any)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    const inputType = (formControl as DynamicInputModel).inputType;
    this.validInputType = this.validInpuTypeArray.find(function (element) { return element === inputType; });
    if (!this.validInputType) {
      return;
    }

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_INPUT_ELEMENTS) {

      if ((property.id === 'isPastDate' || property.id === 'currentDateDefault') &&
        (inputType !== 'date' &&
          inputType !== 'datetime-local' &&
          inputType !== 'week' &&
          inputType !== 'month' &&
          inputType !== 'time')) {
        continue;
      }

      if ((property.id === 'max' || property.id === 'min' || property.id === 'step') && inputType !== 'number') {
        continue;
      }

      if (property.id === 'currentDateDefault') {
        property.name = "Set to current date/time (disables default value)";
      }

      if (property.id === "additional") {
        property.inputType = (formControl as DynamicInputModel).inputType;
      }

      if (property.id === "additional" && (formControl as DynamicInputModel).multiple === true) {
        property.type = "CHIPS";
        property.inputType = "";
      } else if (property.id === "additional") {
        property.type = "INPUT";
      }

      if (property.id === "additional" && (formControl as DynamicInputModel).inputType === "password") {
        continue;
      }

      if (property.id === 'pattern' && inputType !== DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT) continue;

      let controlValue = null;

      if (property.id === 'list') {
        controlValue = this.getProperty(formControl, '_' + property.id);
        if (controlValue) {
          controlValue = controlValue.join();
        }
      } else {
        if (property.id === 'isPastDate' || property.id === 'currentDateDefault') {
          controlValue = this.getProperty(formControl.additional ? formControl.additional : {}, property.id);
        } else {
          controlValue = this.getProperty(formControl, property.id);
        }
      }

      const propertyCntrl: any =
        this.createFormControlModel(property.type, property.inputType, property.id, property.name, controlValue);

      if (property.id === 'additional') {
        const currentDateDefaultElement: any = retControlModelArray.find(element => element.id === 'currentDateDefault');
        if (currentDateDefaultElement && currentDateDefaultElement.value) {
          propertyCntrl.disabled = true;
        }
      }

      if (property.id === 'currentDateDefault') {
        if (propertyCntrl.value) {
          const defaultValElement: any = retControlModelArray.find(element => element.id === 'additional');
          if (!defaultValElement) { return; }
          const today = new Date();
          let defaultVal = null;
          if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE) {
            defaultVal = moment(today).format('YYYY-MM-DD');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL) {
            defaultVal = moment(today).format('YYYY-MM-DD') + 'T' + moment(today).format('hh:mm');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH) {
            defaultVal = moment(today).format('YYYY-MM');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK) {
            defaultVal = moment(today).format('YYYY') + '-W' + moment(today).format('ww');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME) {
            defaultVal = moment(today).format('hh:mm');
          }
          defaultValElement.value = defaultVal;
          defaultValElement.disabled = true;
        }
        propertyCntrl.valueChanges.subscribe(value => {
          const defaultValElement: any = retControlModelArray.find(element => element.id === 'additional');
          if (!defaultValElement) { return; }
          const today = new Date();
          let defaultVal = null;
          if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE) {
            defaultVal = moment(today).format('YYYY-MM-DD');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL) {
            defaultVal = moment(today).format('YYYY-MM-DD') + 'T' + moment(today).format('hh:mm');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH) {
            defaultVal = moment(today).format('YYYY-MM');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK) {
            defaultVal = moment(today).format('YYYY') + '-W' + moment(today).format('ww');
          } else if (formControl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME) {
            defaultVal = moment(today).format('hh:mm');
          }

          if (value) {
            defaultValElement.value = defaultVal;
          }
          defaultValElement.disabled = value;
        });
      }

      if (propertyCntrl) {
        retControlModelArray.push(propertyCntrl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for input text area type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createInputTexAreaFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA_ELEMENTS) {
      const controlValue = this.getProperty(formControl, property.id);
      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for check box type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createCheckBoxFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_ELEMENTS) {
      const controlValue = this.getProperty(formControl, property.id);
      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for select type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createSelectFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_SELECT_ELEMENTS) {
      let controlValue = this.getProperty(formControl, property.id);
      if (property.name === 'Options') {
        controlValue = this.convertOptionsToChips(controlValue);
      }

      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for radio group type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createRadioGroupFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP_ELEMENTS) {
      let controlValue = this.getProperty(formControl, property.id);
      if (property.name === 'Options') {
        controlValue = this.convertOptionsToChips(controlValue);
      }

      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for slider type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createSliderFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_SLIDER_ELEMENTS) {
      const controlValue = this.getProperty(formControl, property.id);
      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue,
          ((property as any).options) ? (property as any).options : null,
          formControl);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for switch type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createSwitchFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_SWITCH_ELEMENTS) {
      const controlValue = this.getProperty(formControl, property.id);
      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function creates a form control setting model for checkbox group type
   *
   * @param formControl form control for which setting model is being generated
   */
  private createCheckBoxGroupFormControlSettingModel(formControl: DynamicFormControlModel)
    : DynamicFormControlModel[] | null {
    const retControlModelArray: DynamicFormControlModel[] = [];

    for (const property of DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP_ELEMENTS) {
      let controlValue = this.getProperty(formControl, property.id);
      if (property.name === 'Group') {
        controlValue = this.convertGroupCheckBoxToChips(controlValue);
      }

      const propertyControl =
        this.createFormControlModel(
          property.type, property.inputType, property.id,
          property.name, controlValue, ((property as any).options) ? (property as any).options : null);

      if (propertyControl) {
        retControlModelArray.push(propertyControl);
      }
    }

    return retControlModelArray;
  }

  /**
   * This function converts options for chip input to chips
   *
   * @param options options being converted to chips
   */
  public convertOptionsToChips(options: any[]): Array<string> {
    const retChipsArray: Array<string> = [];
    if (!Array.isArray(options)) { return retChipsArray; }
    options.forEach(option => retChipsArray.push(option.value.toString()));

    return retChipsArray;
  }

  /**
   * This function converts array to options for form control
   *
   * @param values Values being converted to options
   */
  public convertArrayToOptions(values: any[]): Array<any> {
    const retOptionsArray: Array<any> = [];
    if (!Array.isArray(values)) { return retOptionsArray; }
    values.forEach(value => {
      retOptionsArray.push(new DynamicFormOption({
        disabled: false,
        label: value.toString(),
        value: value.toString()
      }));
    });

    return retOptionsArray;
  }

  /**
   * This function converts checkbox group to chips
   *
   * @param group List of checkbox group elements
   */
  public convertGroupCheckBoxToChips(group: any[]): Array<string> {
    const retChipsArray: Array<any> = [];
    if (!Array.isArray(group)) { return retChipsArray; }
    for (const grpItem of group) {
      retChipsArray.push(grpItem.label.toString());
    }

    return retChipsArray;
  }

  /**
   * Coverts chips values to an array of DynamicCheckboxModels
   *
   * @param values array of values to be consider for label of checkboxes
   * @param isDisabled to set the checkboxes enabled/disabled
   */
  public convertChipsToGroupCheckBox(values: any[], isDisabled: boolean): Array<string> {
    const retGroupChkBoxArray: Array<any> = [];
    if (!Array.isArray(values)) { return retGroupChkBoxArray; }
    values.forEach(value => {
      retGroupChkBoxArray.push(
        new DynamicCheckboxModel({
          id: Guid.newGuid().toString(),
          label: value,
          value: false,
          disabled: isDisabled
        }));
    });

    return retGroupChkBoxArray;
  }

  /**
   * This function returns the value of the key property of the object
   * @param object Object containing the value
   * @param key property name
   */
  public getProperty(object: any, key: string): any {
    return object[key];
  }

  /**
   * This function returns the form control setting model
   *
   * @param type type of the form control
   */
  public getFormControlSettingModel(type: string): any {

    switch (type) {

      case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:

        return DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
      case DYNAMIC_FORM_CONTROL_TYPE_GROUP:

        return DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:

        return DYNAMIC_FORM_CONTROL_TYPE_INPUT_ELEMENTS;

      case this.EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS:
        return null;

      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:

        return DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:

        return DYNAMIC_FORM_CONTROL_TYPE_SELECT_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:

        return DYNAMIC_FORM_CONTROL_TYPE_SLIDER_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:

        return DYNAMIC_FORM_CONTROL_TYPE_SWITCH_ELEMENTS;

      case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:

        return DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA_ELEMENTS;

      default:
        return null;
    }
  }

  /**
   * Creates a DynamicFormControlModel based on the incomingForm passed in.
   *
   * @param incomingForm - the form to base the DynamicFormControlModel on
   */
  public createDynamicFormControlModel(incomingForm: any, skipCopyingRelation?: boolean): EvaDynamicFormControlModel {

    if (!incomingForm) { return; }
    let formScreenElement = incomingForm;
    if (incomingForm.element) {
      formScreenElement = incomingForm.element; // added this for interactions from main interface.
    }

    let formControlModel: EvaDynamicFormControlModel;

    // Create the DynamicFormControlModel based on what type it is
    if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {

      // retroactive fix for default values being accidentally stored under id 'layout'
      if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP &&
        formScreenElement.layout &&
        !formScreenElement.additional) {
        formScreenElement.additional = { defaultVal: formScreenElement.layout };
        formScreenElement.layout = null;
      }
        formControlModel = this.createFormControlModel(formScreenElement.type, formScreenElement.inputType, null, null, []);
    } else if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
      formControlModel = this.createFormControlModel(
        formScreenElement.type, ((formScreenElement.multiple === true) ? 'Multiple' : ''), null, null, formScreenElement.value);
    } else {
      formControlModel = this.createFormControlModel(formScreenElement.type, formScreenElement.inputType,
        null, null, formScreenElement.value, null, formScreenElement);
    }

    // Add the original id for each element
    Object.assign(formControlModel, { originalId: (formScreenElement.originalId) ? formScreenElement.originalId : formScreenElement.id});

    // Add properties to the DynamicFormControlModel based on the settings for its type:
    const elementSettingModel: any[] = this.getFormControlSettingModel(formScreenElement.type);

    if ( elementSettingModel ) { // if type is found, loop through each property
      elementSettingModel.forEach(property => {

        // if it is a checkbox group, create the controls for each checkbox
        if (property.id === 'group' && formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          (<DynamicCheckboxGroupModel>formControlModel).group = <DynamicCheckboxModel[]>this.createCheckBoxGroupControls(formScreenElement);

          // for the properties options, if it is select or radio, map the options to the dynamic model
        } else if (property.id === 'options' &&
          (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT ||
            formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP)) {
          const optionFlatArray = formScreenElement.options.map( function(option: any) { return option.label; } );

          if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
            (<DynamicSelectModel<string>>formControlModel).options = this.convertArrayToOptions(optionFlatArray);
          } else {
            (<DynamicRadioGroupModel<string>>formControlModel).options = this.convertArrayToOptions(optionFlatArray);
          }

        } else if (property.id === 'list') { // property.id is not equal to 'options' or 'group'
          let propertyValue = null;
          if (this.getProperty(formScreenElement, property.id)) {
            propertyValue = this.getProperty(formScreenElement, property.id);
          } else {
            propertyValue = this.getProperty(formScreenElement, '_' + property.id);
          }
          formControlModel[property.id] = propertyValue;
        } else { // property.id is not equal to 'options' or 'group'
          const propertyValue = this.getProperty(formScreenElement, property.id);
          formControlModel[property.id] = propertyValue;
        }
      });
    }

    const isFrmControlItemElementLayOut: boolean = formScreenElement.layout && formScreenElement.layout.element;
    // copy the layout to the DynamicFormControlModel:
    if ( isFrmControlItemElementLayOut ) {
      formControlModel.layout = formScreenElement.layout;
    }

    if (!formControlModel['additional']) {
      formControlModel['additional'] = {};
    }

    if (!skipCopyingRelation) {
      // If there are conditional relations, copy them over:
      if (formScreenElement['additional'] && formScreenElement['additional'].relation
      && Array.isArray(formScreenElement['additional'].relation)
      && formScreenElement['additional'].relation.length > 0) {
        formControlModel['additional'].relation = JSON.parse(JSON.stringify(formScreenElement['additional'].relation));
      }

      // if old relations exists, move them to new array
      if (formScreenElement.relation && Array.isArray(formScreenElement.relation) && formScreenElement.relation.length > 0) {
        formControlModel['additional'].relation = JSON.parse(JSON.stringify(formScreenElement.relation));
        formControlModel.relation = [];
      }
    }

    return formControlModel;
  }

  /**
   * This method generates sub check box controls of a checkbox group control and returns them
   *
   * @param formScreenElement // original element which expect to be checkbox group control
   */
  public createCheckBoxGroupControls(formScreenElement: DynamicCheckboxGroupModel): DynamicFormControlModel[] {
    const checkBoxGroup: DynamicFormControlModel[] = [];
    if (!(formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP)) return checkBoxGroup;

    formScreenElement.group.forEach(control => {
      const formControlForGroup: DynamicFormControlModel =
        this.createFormControlModel(DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX, '', null, control.label, control.value, null, control);
        checkBoxGroup.push(formControlForGroup);
    });

    return checkBoxGroup;
  }

  public fetchInteractions(groupPublicKey: string) {
    return from(mockedInteractions);
  }

  /**
   * Returns a clone (with new ids) of the interaction object passed in.
   *
   * @param interactionObject the interaction object to clone
   */
  cloneInteraction(interactionObject: any, updateCheckBoxGroupValues?: boolean): any {
    const clonedInteraction: any = {};
    if (!(interactionObject
      && interactionObject['FormScreens']
      && Array.isArray(interactionObject['FormScreens']))) {
      return clonedInteraction;
    }
    // Copy values:
    clonedInteraction.name = interactionObject.name;
    clonedInteraction.id = Guid.newGuid().toString();
    clonedInteraction.originalId = interactionObject.id;
    clonedInteraction.FormScreens = [];
    let formScreens = interactionObject['FormScreens'];

    // Added this for bug in presenting dynamic interactions in the main chat component.
    // @ts-ignore
    if (formScreens && Array.isArray[formScreens] && formScreens.length > 0 && formScreens[0].length > 0) {
      formScreens = formScreens[0];
    }

    // For each screen, go through all elements and clone them.
    // Also clone the layouts:
    formScreens.forEach((formScreen: any) => {
      const formScreenElements = formScreen['FormElements'];

      const formScreenLayout: any = {};

      const dynFormControlModel: DynamicFormControlModel[] = [];
      if ( formScreenElements ) {
        // Clone a new DynamicFormControlModel for each element:
        formScreenElements.forEach((formScreenElement: any) => {
          const formControlModel: DynamicFormControlModel = this.createDynamicFormControlModel(formScreenElement);
          dynFormControlModel.push(formControlModel);

          //#region Element layout cloner
          const isFormControlItemElementLayout = formScreenElement.layout && formScreenElement.layout.element;
          formScreenLayout[formControlModel.id] = {
            element: {
              control: isFormControlItemElementLayout ? formScreenElement.layout.element.control : '',
              host: 'eva_frm_elmnt_Width95prcnt',
              hint: isFormControlItemElementLayout ? formScreenElement.layout.element.hint : '',
              option: isFormControlItemElementLayout ? formScreenElement.layout.element.option : '',
              label: isFormControlItemElementLayout ? formScreenElement.layout.element.label : '',
            }
          };
          //#endregion
        });
      }

      // Create a new screen object based on the dynamic form control model and layout we created,
      // and add to the cloned interaction's screen array:
      clonedInteraction.FormScreens.push({
        "name": formScreen.name,
        "FormElements": dynFormControlModel,
        "FormElementsLayout": formScreenLayout
      });
    });

    //#region elements array flattened
    let interactionElements: any[] = [];
    const interactionScreens: any[] = clonedInteraction.FormScreens;

    // Create a flat array of all form elements:
    interactionScreens.forEach((screen: any) => {
      interactionElements = [].concat.apply(interactionElements, screen.FormElements);
    });
    //#endregion

    //#region Interaction Special Control bindings should be cloned too
    if (interactionObject.specialControls) {
      clonedInteraction.specialControls = JSON.parse(JSON.stringify(interactionObject.specialControls));
    }
    //#endregion

    //#region Resolve target element id's for elements in relation object
    this.resolveRelations(interactionScreens, interactionElements, updateCheckBoxGroupValues);
    //#endregion

    return clonedInteraction;
  }

  //#region Resolve target element id's for elements in relation object
  /**
   * Copies the id of the of the control imposing the condition to the condition in the control being conditioned.
   *
   * @param interactionScreens the screen from the cloned interaction
   * @param interactionElements the elements from the cloned interaction as a flat array
   */
  private resolveRelations(interactionScreens: any[], interactionElements: any[], updateCheckBoxGroupValues: boolean): void {
    interactionScreens.forEach(screen => {
      screen.FormElements.forEach((element: DynamicFormControlModel) => {
        if (element['additional'] && element['additional'].relation && Array.isArray(element['additional'].relation)) {
          element['additional'].relation.forEach((relation: Relation) => {
            // relation.id.forEach((id, index) => {
            //   const targetConditionElement = interactionElements.find(interactionElement =>
            //     interactionElement.originalId === id);
            //     relation.id[index] = targetConditionElement.id;
            // });
            // TODO: Need to test this with checkbox group or radio group
            // if (relation.when && Array.isArray(relation.when)) {
            //   relation.when.forEach((condition: any) => {
            //     const targetConditionElement = interactionElements.find(interactionElement =>
            //       interactionElement.originalId === condition.id);
            //     if (targetConditionElement && targetConditionElement.id) {
            //       if (targetConditionElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            //         targetConditionElement.group.forEach(checkbox => {
            //           Object.keys(condition.value).forEach(checkboxLabel => {
            //             if (checkbox.label === checkboxLabel && updateCheckBoxGroupValues) {
            //               checkbox.value = condition.value[checkboxLabel];
            //             }
            //           });
            //         });
            //       }
            //       condition.id = targetConditionElement.id;
            //     }
            //   });
            // }
          });
        }
      });
    });
  }
  //#endregion

  /**
  * creates an array of all elements within an interaction
  *
  * @param interactionObject accepts this.dynfrm from all references
  * @param fullElement accepts true or defaults to false,
  */
  flatInteraction(interactionObject: any, fullElement: boolean = false) {
    // validating to see if interaction has screen
    if (!(interactionObject && interactionObject['FormScreens'] && Array.isArray(interactionObject['FormScreens']))) return null;
    const flatInteraction = new InteractionFlatModel(interactionObject.name, interactionObject.id, interactionObject.originalId, []);

    const formScreens: any[] = interactionObject['FormScreens'];

    formScreens.forEach((formScreen, screenIndex) => {
      const formScreenElements: any[] = formScreen['FormElements'];

      if (formScreenElements) {
        formScreenElements.forEach(formScreenElement => {

          //#region get element value when flattening to non full version
          let elementValue = formScreenElement.value;
          if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP
            && formScreenElement.group && Array.isArray(formScreenElement.group)) {
            elementValue = [];
            formScreenElement.group.forEach( (checkBox: DynamicCheckboxModel) => {
              elementValue.push( { label: checkBox.label, value: checkBox.value } );
            });
          }
          //#endregion

          const element: any = (fullElement)
            ? JSON.parse(JSON.stringify(formScreenElement))
            : {
              id: formScreenElement.id,
              originalId: formScreenElement.originalId,
              type: formScreenElement.type,
              inputType: (formScreenElement.inputType) ? formScreenElement.inputType : null,
              scrnIndex: screenIndex,
              disabled: (formScreenElement.disabled && formScreenElement.disabled === true) ? true : false,
              value: elementValue,
              prefix: (formScreenElement.prefix) ? formScreenElement.prefix : null,
              suffix: (formScreenElement.suffix) ? formScreenElement.suffix : null,
              maxLength: formScreenElement.maxLength ?? null,
              minLength: formScreenElement.minLength ?? null,
              max: formScreenElement.max ?? null,
              min: formScreenElement.min ?? null,
              step: formScreenElement.step ?? null
            };

          flatInteraction.elements.push(element);
        });
      }
    });

    return flatInteraction;
  }

  //#region Interaction to tree nodes convertor
  /**
   * This function generates the tree nodes for the interaction passed
   *
   * @param form interaction object
   * @param isElementDraggable whether the elements are draggable or not
   * @param isElementProperty whether the element property needs to be generated or not in the tree as nodes
   */
  interactionToTreeNodes(form: any, isElementDraggable: boolean = false, isElementProperty: boolean = false): TreeNode[] {
    const formTree: TreeNode[] = [];
    const FRM_CNTRL_ELEMENTS = ((FRM_CNTRL_INPUT.concat(FRM_CNTRL_DATETIME)).concat(FRM_CNTRL_ADDITIONAL)).concat(FRM_CNTRL_CUSTOM);


    let formNode: TreeNode = {};
    formNode = {
      "label": (form.name) ? form.name : "Dynamic interaction",
      "data": (form.name) ? form.name : "Dynamic interaction",
      "expandedIcon": "fab fa-wpforms",
      "collapsedIcon": "fab fa-wpforms",
      "expanded": true,
      "selectable": false,
      "children": []
    };

    const formScreens: any[] = form['FormScreens'];
    if (formScreens) {
      formScreens.forEach((screen, screenIndex) => {
        let screenNode: TreeNode = {};
        screenNode = {
          "label": screen.name,
          "data": screen.name,
          "expandedIcon": "fas fa-expand",
          "collapsedIcon": "fas fa-expand",
          "expanded": true,
          "selectable": false,
          "children": []
        };

        const formScreenElements: any[] = screen.FormElements;
        if (formScreenElements) {
          formScreenElements.forEach((element, elementIndex) => {
            let elementNode: TreeNode = {};
            const foundControlElement = FRM_CNTRL_ELEMENTS.find(function (formControlElement) {
              return element.type === formControlElement.type
              && (formControlElement.inputType === undefined
                || element.inputType === formControlElement.inputType);
            });

            elementNode = {
              "label": `${element.type} ${element.inputType ? (' :: ' + element.inputType) : ''}`,
              "data": {
                id: (element.originalId ? element.originalId : element.id),
                type: element.type,
                inputType: element.inputType ? element.inputType : '',
                scrnIndex: screenIndex,
                elmntIndex: elementIndex
              },
              "expandedIcon": foundControlElement ? foundControlElement.icon : "fa-leaf",
              "collapsedIcon": foundControlElement ? foundControlElement.icon : "fa-leaf",
              "expanded": false,
              "selectable": false,
              "draggable": isElementDraggable,
              "children": []
            };

            if (isElementProperty) {
              const elementSettingModel: any[] = this.getFormControlSettingModel(element.type);
              if (elementSettingModel) {
                elementSettingModel.forEach(property => {
                  let propertyNode: TreeNode = {};

                  const propertyValue = this.getProperty(element, property.id);
                  const propertyLabel = (property.id === 'group' || property.id === 'options') ? '' : ' :: ' + propertyValue;
                  propertyNode = {
                    "label": property.id + propertyLabel,
                    "data": property.id + propertyLabel,
                    "expandedIcon": "fas fa-ellipsis-h",
                    "collapsedIcon": "fas fa-ellipsis-h",
                    "expanded": false,
                    "selectable": false,
                    "children": []
                  };

                  //#region Group property of the Checkbox Group
                  if (property.id === 'group') {
                    const formElementGroupProperty: any[] = this.getProperty(element, property.id);

                    formElementGroupProperty.forEach(groupElement => {
                      let groupElementNode: TreeNode = {};
                      const foundControlGroupElement = FRM_CNTRL_ELEMENTS.find(function (formControlElement) {
                        return formControlElement.type === groupElement.type &&
                          (groupElement.inputType === undefined || formControlElement.inputType === groupElement.inputType);
                      });

                      if (foundControlGroupElement) {
                        groupElementNode = {
                          "label": `${groupElement.type} ${groupElement.inputType
                            ? (' :: ' + groupElement.inputType) : ''} :: ${groupElement.label}`,
                          "data": `${groupElement.type} ${groupElement.inputType
                            ? (' :: ' + groupElement.inputType) : ''} :: ${groupElement.label}`,
                          "expandedIcon": foundControlGroupElement ? foundControlGroupElement.icon : "fa-leaf",
                          "collapsedIcon": foundControlGroupElement ? foundControlGroupElement.icon : "fa-leaf",
                          "expanded": false,
                          "selectable": false,
                          "children": []
                        };
                      }

                      propertyNode.children.push(groupElementNode);
                    });
                  } else if (property.id === 'options') {
                    const formElementOptionProperty: any[] = this.getProperty(element, property.id);

                    formElementOptionProperty.forEach(optionElement => {
                      if (optionElement.label) {
                        const optionElementNode: TreeNode = {
                          "label": `${optionElement.label}`,
                          "data": `${optionElement.label}`,
                          "expandedIcon": foundControlElement ? foundControlElement.icon : "fa-leaf",
                          "collapsedIcon": foundControlElement ? foundControlElement.icon : "fa-leaf",
                          "expanded": false,
                          "selectable": false,
                          "children": []
                        };

                        propertyNode.children.push(optionElementNode);
                      }
                    });
                  }
                  //#endregion

                  elementNode.children.push(propertyNode);
                });
              }
            }

            screenNode.children.push(elementNode);
          });
        }

        formNode.children.push(screenNode);
      });
    }

    formTree.push(formNode);
    return formTree;
  }
  /**
   * This function fixes the shape of existing relation objects to support new version of ng-dynamic-forms for angular 9
   *
   * @param dynamicForm Dynamic Form object
   */
  fixOldShapeForRelations(dynamicForm: any): any {
    if (dynamicForm.FormScreens) {
      let orderIndex = -1;
      dynamicForm.FormScreens.forEach(screen => {
        if (screen.FormElements) {
          screen.FormElements.forEach(element => {
            if (element.relations && Array.isArray(element.relations) && element.relations.length > 0) {
              element.relation = JSON.parse(JSON.stringify(element.relations));
              delete element.relations;
              element.relation.forEach(relation => {
                if (relation.action === 'DISABLE') {
                  relation.match = MATCH_DISABLED;
                  delete relation.action;
                }
              });
            }
            if (!element.relation) {
              element.relation = element['additional']?.relation ?? [];
            }
            if (element.relation) {
              const newRelation: Relation[] = [];
              element.relation.forEach((relation: ElementRelations | Relation) => {
                if (((relation as DynamicFormControlRelation).match || (relation as any).action)
                  && (relation as DynamicFormControlRelation).when) {
                  (relation as DynamicFormControlRelation).when.forEach(condition => {
                    if (condition && condition.id) {
                      orderIndex++;
                      newRelation.push({
                        id: [condition.id],
                        order: orderIndex,
                        subRelation: [{
                          if: {
                            option: IfThenLogicOptions.IsEqualTo,
                            value: condition.value
                          },
                          then: {
                            action: IfThenLogicAction.Disable,
                            option: null,
                            value: null,
                            enableOpposite: true
                          },
                          ifConnective: newRelation.length > 0
                          ? (relation as ElementRelations).connective as ('AND' | 'OR') === 'AND'
                            ? 'OR'
                            : 'OR'
                          : undefined
                        }],
                        connective: newRelation.length > 0
                          ? (relation as ElementRelations).connective as ('AND' | 'OR') === 'AND'
                            ? 'OR'
                            : 'OR'
                          : undefined
                      });
                    }
                  });
                } else {
                  newRelation.push(relation as Relation);
                }
              });
              element.relation = newRelation;
              if (!element['additional']) {
                element['additional'] = {};
              }
              element['additional'].relation = newRelation;
            }
          });
        }
      });
    }
    return dynamicForm;
  }
  //#endregion
}
