import { Pipe, PipeTransform } from '@angular/core';
import { GenericSearchFilter } from '@eva-model/generic-search';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';

@Pipe({
  name: 'getFilterValue'
})
export class GetFilterValuePipe implements PipeTransform {
  constructor(private searchValueModifier: SearchValueModifierService) {}

  transform(filterValue: any, filter: GenericSearchFilter, index: number): unknown {
    let displayValue = this.getDisplayValue(filterValue, filter, index);

    if (!displayValue || Array.isArray(filterValue) && (filterValue[0] === null || filterValue[0] === undefined)) {
      if (filter.nullDisplayValue) {
        displayValue = filter.nullDisplayValue;
      }
    }

    return displayValue;
  }

  private getDisplayValue(filterValue: any, filter: GenericSearchFilter, index: number): unknown {
    //
    // specific filter.type logic
    //
    if (!filter.filterValueModifier) {
      // not a date range but default values is an array
      if (Array.isArray(filterValue) && filter.type !== 'date-range') {
        filterValue = filterValue.map(value => {
          if (filter.filterValueModifier) {
            return this.searchValueModifier.modifyValues(filter.filterValueModifier, value);
          } else {
            return value;
          }
        });
        // show first value and the count of the rest.
        if (filterValue.length > 1) {
          return `${filterValue[0]} + ${filterValue.length - 1}`;
        }
        return filterValue[0] ? filterValue[0] : '';
      }
  
      // is a date range
      if (Array.isArray(filterValue) && filter.type === 'date-range') {
        return filterValue[index];
      }
    }

    //
    // none-specific filter.type logic 
    //
    if (!index && filter) {
      // for generic-search-filter template
      if (filter.filterValueModifier) {
        return this.searchValueModifier.modifyValues(filter.filterValueModifier, filterValue);
      } else {
        return filterValue;
      }
    }

    if (filter.filterValueModifier) {
      return this.searchValueModifier.modifyValues(filter.filterValueModifier, filterValue);
    } else {
      return filterValue;
    }
  }

}