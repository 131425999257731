import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';
import { KnowledgeDeleteRestoreDialogData } from '@eva-model/knowledge/knowledge';
import { LoggingService } from '@eva-core/logging.service';

/**
 * This component is used with a dialog. It will either delete (archive) or restore (unarchive) the document
 * based on the value of `isDeleted` data property being passed in.
 */
@Component({
  selector: 'app-knowledge-delete',
  templateUrl: './knowledge-delete.component.html',
  styleUrls: ['./knowledge-delete.component.scss']
})
export class KnowledgeDeleteComponent implements OnInit {

  headerText = 'Archive?';
  buttonText = 'Archive Document';
  loading = false;

  constructor(public dialogRef: MatDialogRef<KnowledgeDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: KnowledgeDeleteRestoreDialogData,
              public knowledgeService: KnowledgeService,
              private loggingService: LoggingService) { }

  ngOnInit() {
    // set some initial values based on the state of the data being passed in.
    // if the doc is already deleted, set the component to a state where the user
    // is trying to restore the document.
    if (this.data.isDeleted) {
      this.buttonText = 'Restore Document';
      this.headerText = 'Restore?';
    }
  }

  async confirmAction() {
    this.loading = true;

    try {
      if (this.data.isDeleted) {
        // restore doc
        await this.knowledgeService.deleteRestoreKnowledge(this.data.groupPublicKey, this.data.docId, false, true);
      } else {
        // delete doc
        await this.knowledgeService.deleteRestoreKnowledge(this.data.groupPublicKey, this.data.docId, true, false);
      }

      // show message of success.
      const successMessage = this.data.isDeleted ? `Restored ${this.data.docName}` : `Archived ${this.data.docName}`;
      this.loggingService.logMessage(successMessage, false, 'black', null, 4000);

      this.loading = false;
      this.dialogRef.close(true);
    } catch (err) {
      // show message it failed and to try again.
      this.loggingService.logMessage('Failed. Please try again.', false, 'black', null, 4000);
      this.loading = false;
    }
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
