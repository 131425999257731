import { Injectable } from '@angular/core';
import { ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { ChatService } from '@eva-services/chat/chat.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const LOCAL_STORAGE_NAME = 'chatTextToSpeech';

@Injectable()
export class SpeechSynthesisService {
  public speechEnabledInChat$: Observable<boolean>;

  // internal config
  private voiceToUse = 'Microsoft Zira Desktop - English (United States)';
  private utterance: SpeechSynthesisUtterance; // current utterance being spoke
  private _speechEnabledInChat: boolean = false;
  private _speechEnabledInChat$: BehaviorSubject<boolean>;

  constructor(private chatService: ChatService) {
    // check if speech in chat is enabled
    if (localStorage.getItem(LOCAL_STORAGE_NAME)) {
      this._speechEnabledInChat = true;
    }
    
    this._speechEnabledInChat$ = new BehaviorSubject(this._speechEnabledInChat);
    this.speechEnabledInChat$ = this._speechEnabledInChat$.asObservable();

    // listen to chat for speaking
    this.chatService.chatEntities$.pipe(
      filter(() => this._speechEnabledInChat === true),
      filter((entities) => entities.length > 0),
      filter((entities) => entities[entities.length - 1].type !== ChatEntityType.Loading),
      map((entities) => entities[entities.length - 1]),
      filter((entity) => entity.author === ChatEntityAuthor.EVA),
      filter((entity) => !!entity.text),
    ).subscribe((latestEntity) => {
      this.speak(latestEntity.text);
    });
  }

  toggleChatSpeech(toggle: boolean) {
    this.stop();

    this._speechEnabledInChat = toggle;
    this._speechEnabledInChat$.next(toggle);
    if (toggle) {
      localStorage.setItem(LOCAL_STORAGE_NAME, 'active');
    } else {
      localStorage.removeItem(LOCAL_STORAGE_NAME);
    }
  }

  speak(text: string): void {
    // console.log('speaking ', text);
    this.utterance = new SpeechSynthesisUtterance(text);

    // this.utterence.text = text;
    // this.utterence.lang = 'en-US';
    // this.utterence.volume = 1;
    // this.utterence.rate = 1;
    // this.utterence.pitch = 1;
    
    window.speechSynthesis.speak(this.utterance);
  }

  pause(): void {
    window.speechSynthesis.pause();
  }

  stop(): void {
    // console.log('stopping');
    window.speechSynthesis.cancel();
  }

  resume(): void {
    window.speechSynthesis.resume();
  }
}
