import { InteractionValueEmitter } from "./InteractionEmitValueModel";

export interface InteractionFormControlIndex {
    id: string;
    scrnIdx?: number;
    elmntIdx?: number;
}

export class InteractionFormControlIndexModel implements InteractionFormControlIndex {

    constructor(
        public id: string,
        public scrnIdx?: number,
        public elmntIdx?: number
    ) {}

}

export enum searchDirection {
    forward = 'FORWARD',
    backward = 'BACKWARD'
}

export class InteractionControlRequest {
    constructor(
        public interactionId: string,
        public formControlId: string,
        public searchControlDirection: searchDirection,   // forward/backward
        public requestId: string,
        public sourceId: string,
        public interactionOriginalId: string,
        public formControlOriginalId: string,
        public lastEmittedFrom: InteractionValueEmitter,
        public elementIndex?: number,
        public isEditInteractionFlow?: boolean,
        public isValueUpdate?: boolean,
        public processId?: string,
        public updatedFromLastState?: boolean,
        public processTitle?: string
    ) {}
}
