
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EvaMaterialModule } from '@eva-ui/shared/material.module';
import { ClipboardModule } from 'ngx-clipboard';

import { DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';

// Layout components
import { HomeLayoutComponent } from '@eva-ui/layouts/home-layout.component';
import { LoginLayoutComponent } from '@eva-ui/layouts/login-layout.component';

// These are the page components / routing information
import { LoginPageComponent } from '@eva-ui/login-page/login-page.component';
import { DeleteInteractionDialogComponent } from '@eva-ui/form-builder/delete-interaction-dialog.component';
import { CreateGroupDialogComponent } from '@eva-ui/form-builder/create-group-dialog.component';
import { SaltChatComponent } from '@eva-ui/salt-chat/saltchat.component';
import { FormBuilderComponent } from '@eva-ui/form-builder/form-builder.component';
import { UserPreferencesComponent } from '@eva-ui/user-preferences/user-preferences.component';
import { FormControlDialogComponent } from '@eva-ui/form-control-dialog/form-control-dialog.component';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { SpinnerComponent } from '@eva-ui/spinner/spinner.component';
import { SpinnerService } from '@eva-ui/spinner/spinner.service';
import { ScrollableDirective } from '@eva-ui/salt-chat/scrollable.directive';
import { FormElementVisualizerComponent } from '@eva-ui/form-builder/form-element-visualizer/form-element-visualizer.component';
import { FormViewerComponent } from '@eva-ui/form-builder/form-viewer/form-viewer.component';

import { TreeModule } from 'primeng/tree';
// details if required https://gist.github.com/mrgoos/8bffca32eef7ae6c143b99d069145651
// import { GrowlModule } from 'primeng/growl'; // would have changed to Message from primeng/primeng in imports & exports
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';

import { FormObjectViewerComponent } from '@eva-ui/form-builder/form-object-viewer/form-object-viewer.component';
import { FormVisualizerComponent } from '@eva-ui/form-visualizer/form-visualizer.component';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { WorkflowBuilderComponent } from '@eva-ui/workflow-builder/workflow-builder.component';
import { ShowHistoryComponent } from '@eva-ui/salt-chat/show-history/show-history.component';
import {
  WorkflowInteractionPlaceholderComponent
} from '@eva-ui/workflow-builder/workflow-interaction-placeholder/workflow-interaction-placeholder.component';
import { InteractionVisualizerDialogComponent } from '@eva-ui/interaction-visualizer-dialog/interaction-visualizer-dialog.component';
import { EntityPickerDialogComponent } from '@eva-ui/form-builder/entity-picker-dialog/entity-picker-dialog.component';
import { ErrorPageComponent } from '@eva-ui/error-page/error-page.component';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionDialogComponent } from '@eva-ui/workflow-builder/workflow-interaction-condition-dialog/workflow-interaction-condition-dialog.component';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionComponent } from '@eva-ui/workflow-builder/workflow-interaction-condition/workflow-interaction-condition.component';
// eslint-disable-next-line max-len
import { WorkflowDestinationPickerComponent } from '@eva-ui/workflow-builder/workflow-destination-picker/workflow-destination-picker.component';
// eslint-disable-next-line max-len
import { WorkflowDestinationViewerComponent } from '@eva-ui/workflow-builder/workflow-destination-viewer/workflow-destination-viewer.component';
import { NotificationsComponent } from '@eva-ui/notifications/notifications.component';
import { GroupSearchComponent } from '@eva-ui/group-search/group-search.component';
import { GroupAdminComponent } from '@eva-ui/group-admin/group-admin.component';
import { GroupMembersDialogComponent } from '@eva-ui/group-admin/group-members-dialog/group-members-dialog.component';
// eslint-disable-next-line max-len
import { InteractionElementRelationDialogComponent } from '@eva-ui/form-builder/interaction-element-relation-dialog/interaction-element-relation-dialog.component';
import {
  InteractionElementRelationComponent
} from '@eva-ui/form-builder/interaction-element-relation/interaction-element-relation.component';
import { InteractionConditionDialogComponent } from '@eva-ui/interaction-condition-dialog/interaction-condition-dialog.component';
import { InteractionConditionBuilderComponent } from '@eva-ui/interaction-condition-builder/interaction-condition-builder.component';
import { TreeDragDropService } from 'primeng/api';
import { NgAisModule } from 'angular-instantsearch';
import { TotalCollaboratorsPipe } from '@eva-pipes/total-collaborators.pipe';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionViewerComponent } from '@eva-ui/workflow-builder/workflow-interaction-condition-viewer/workflow-interaction-condition-viewer.component';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { ProcessPickerDialogComponent } from '@eva-ui/process/process-picker-dialog/process-picker-dialog.component';
import { ProcessRunnerComponent } from '@eva-ui/process/process-runner/process-runner.component';
import { ProcessDetailViewerComponent } from '@eva-ui/process/process-detail-viewer/process-detail-viewer.component';
import { GraphComponent } from '@eva-ui/force-graph/graph/graph.component';
import { NodeComponent } from '@eva-ui/force-graph/node/node.component';
import { LinkComponent } from '@eva-ui/force-graph/link/link.component';
import { GraphZoomableDirective } from '@eva-ui/force-graph/graph-zoomable.directive';
import { NodeDraggableDirective } from '@eva-ui/force-graph/node-draggable.directive';
import { WorkflowGraphComponent } from '@eva-ui/workflow-builder/workflow-graph/workflow-graph.component';
import { UserInfoDisplayComponent } from '@eva-ui/user-info-display/user-info-display.component';
import { NotificationItemComponent } from '@eva-ui/notifications/notification-item/notification-item.component';
import { ProcessSummaryComponent } from '@eva-ui/process/process-summary/process-summary.component';
import { LandingPageComponent } from '@eva-ui/landing-page/landing-page.component';
import { EvaChatWorkflowsComponent } from '@eva-ui/eva-chat-workflows/eva-chat-workflows.component';

import { KeepHTMLPipe } from '@eva-pipes/keep-html.pipe';
import { GroupSearchMembersDialogComponent } from '@eva-ui/group-search/group-search-members/group-search-members.component';
import { AdminOverviewComponent } from '@eva-ui/admin-overview/admin-overview.component';
import { ConfirmWorkflowDialogComponent } from '@eva-ui/salt-chat/confirm-workflow-dialog/confirm-workflow-dialog.component';
import { AboutEvaComponent } from '@eva-ui/about-eva/about-eva.component';
import { GroupComponent } from '@eva-ui/group/group.component';

import { AlgoliaHitsDisplayPipe } from '@eva-pipes/algolia-hits-display.pipe';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { KnowledgeComponent } from '@eva-ui/admin-overview/knowledge/knowledge/knowledge.component';
import { UserVerifyResetComponent } from '@eva-ui/user/user-verify-reset/user-verify-reset.component';
import { UserMnemonicComponent } from '@eva-ui/user/user-mnemonic/user-mnemonic.component';
// eslint-disable-next-line max-len
import { ReleaseNotesComponent } from '@eva-ui/about-eva/release-notes/release-notes.component';
import { TrainingComponent } from '@eva-ui/about-eva/training/training.component';
import { TeamMembersComponent } from '@eva-ui/about-eva/team-members/team-members.component';
import { AboutPageDocumentsComponent } from '@eva-ui/about-eva/about-page-documents/about-page-documents.component';
import { AboutPageFAQsComponent } from '@eva-ui/about-eva/about-page-faqs/about-page-faqs.component';
import { AboutPageFeedbackComponent } from '@eva-ui/about-eva/about-page-feedback/about-page-feedback.component';
import { AddEditDocumentsComponent } from '@eva-ui/about-eva/about-page-documents/add-edit-documents/add-edit-documents.component';
import { AddEditFAQsComponent } from '@eva-ui/about-eva/about-page-faqs/add-edit-faqs/add-edit-faqs.component';
import { LearnEVAVideosComponent } from '@eva-ui/about-eva/learn-evavideos/learn-evavideos.component';
import {
  AddEditLearnEVAVideosComponent
} from '@eva-ui/about-eva/learn-evavideos/add-edit-learn-evavideos/add-edit-learn-evavideos.component';
import { AddEditReleaseNotesComponent } from '@eva-ui/about-eva/release-notes/add-edit-release-notes/add-edit-release-notes.component';
import { WrongEnvironmentComponent } from '@eva-ui/wrong-environment/wrong-environment.component';
import { ShareGroupDialogComponent } from '@eva-ui/group-admin/share-group-dialog/share-group-dialog.component';
import { GroupInfoDisplayComponent } from '@eva-ui/group-info-display/group-info-display.component';
import { AddEditTeamMembersComponent } from '@eva-ui/about-eva/team-members/add-edit-team-members/add-edit-team-members.component';
import { ProcessEditSummaryComponent } from '@eva-ui/process/process-edit-summary/process-edit-summary.component';
// eslint-disable-next-line max-len
import { WorkflowFormElementVisualizerComponent } from '@eva-ui/workflow-builder/workflow-form-element-visualizer/workflow-form-element-visualizer.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { IdMapDialogComponent } from './id-map-dialog/id-map-dialog.component';
import { IdMapInteractionChangesComponent } from './id-map-interaction-changes/id-map-interaction-changes.component';
import { IdMapInteractionElementDialogComponent } from './id-map-interaction-element-dialog/id-map-interaction-element-dialog.component';
import { GroupSelectDialogComponent } from './group-select-dialog/group-select-dialog.component';
import { IdMapWorkflowInteractionDialogComponent } from './id-map-workflow-interaction-dialog/id-map-workflow-interaction-dialog.component';
// eslint-disable-next-line max-len
import { IdMapWorkflowInteractionMapDialogComponent } from './id-map-workflow-interaction-map-dialog/id-map-workflow-interaction-map-dialog.component';
import { WorkflowViewerDialogComponent } from './workflow-viewer-dialog/workflow-viewer-dialog.component';
import { IdMapEntityGroupMapComponent } from './id-map-entity-group-map/id-map-entity-group-map.component';
import { IdMapIdChangesComponent } from './id-map-id-changes/id-map-id-changes.component';
import {
  IdMapWorkflowInteractionElementDialogComponent
} from './id-map-workflow-interaction-element-dialog/id-map-workflow-interaction-element-dialog.component';
import { WorkflowGraphVisualizerComponent } from './workflow-graph-visualizer/workflow-graph-visualizer.component';
import { WorkflowImportExportComponent } from './workflow-import-export/workflow-import-export.component';
import { InteractionImportExportComponent } from './interaction-import-export/interaction-import-export.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DeadEndComponent } from '@eva-ui/common/dead-end/dead-end.component';
import { GroupNamePipe } from '@eva-pipes/group-name.pipe';

import { KnowledgeCreateComponent} from '@eva-ui/admin-overview/knowledge/knowledge-create/knowledge-create.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { KnowledgeSectionComponent } from '@eva-ui/admin-overview/knowledge/knowledge-section/knowledge-section.component';
import { StickyElementDirective } from './shared/sticky-element/sticky-element.directive';
// eslint-disable-next-line max-len
import { KnowledgeFindAndReplaceDialogComponent } from '@eva-ui/admin-overview/knowledge/knowledge-find-and-replace-dialog/knowledge-find-and-replace-dialog.component';
import { KnowledgeSearchComponent } from '@eva-ui/admin-overview/knowledge/knowledge-search/knowledge-search.component';

import { SafeHtmlPipe } from '@eva-pipes/safe-html.pipe';
import { KnowledgeUploadTaskComponent } from '@eva-ui/admin-overview/knowledge/knowledge-upload-task/knowledge-upload-task.component';
import { FileUploadComponent } from '@eva-ui/file/file-upload/file-upload.component';
// eslint-disable-next-line max-len
import { ChildGroupInviteNotificationsComponent } from './notifications/child-group-invite-notifications/child-group-invite-notifications.component';
import { KnowledgeRevisionsComponent } from '@eva-ui/admin-overview/knowledge/knowledge-revisions/knowledge-revisions.component';
import { KnowledgeDiffComponent } from '@eva-ui/admin-overview/knowledge/knowledge-diff/knowledge-diff.component';
import { NotificationsFeedbackComponent } from '@eva-ui/notifications-feedback/notifications-feedback.component';
import { KnowledgeFeedbackItemComponent } from '@eva-ui/admin-overview/knowledge/knowledge-feedback-item/knowledge-feedback-item.component';
import { KnowledgeFeedbackComponent } from '@eva-ui/admin-overview/knowledge/knowledge-feedback/knowledge-feedback.component';

import { HomeComponent } from '@eva-ui/home/home.component';
import { InteractionComponent } from '@eva-ui/interaction/interaction.component';
import { ChatMessagesComponent } from '@eva-ui/salt-chat/chat-messages/chat-messages.component';
import { ChatMlComponent } from '@eva-ui/salt-chat/chat-ml/chat-ml.component';
import { IsUserInGroupPipe } from '@eva-pipes/is-user-in-group.pipe';
// eslint-disable-next-line max-len
import { ProcessDashboardComponent } from './process/process-dashboard/process-dashboard.component';
// eslint-disable-next-line max-len
import { NotificationsFeedbackGroupComponent } from '@eva-ui/notifications-feedback/notifications-feedback-group/notifications-feedback-group.component';
import { KnowledgeDeleteComponent } from '@eva-knowledge/knowledge-delete/knowledge-delete.component';
import { TutorialIntroComponent } from './tutorial-intro/tutorial-intro.component';
import { KnowledgeFeedbackPromptComponent } from '@eva-knowledge/knowledge-feedback-prompt/knowledge-feedback-prompt.component';
import { ChunkPipe } from '@eva-pipes/chunk.pipe';
import { KnowledgeMultiViewComponent } from '@eva-knowledge/knowledge-multi-view/knowledge-multi-view.component';
import { KnowledgeDocumentSearchComponent } from '@eva-knowledge/knowledge-document-search/knowledge-document-search.component';
import { CustomTooltipComponent } from '@eva-ui/process/process-dashboard/custom-dashboard-tooltip/custom-dashboard-tooltip.component';
import { KnowledgeSectionSearchComponent } from '@eva-knowledge/knowledge-section-search/knowledge-section-search.component';
import { EllipsisDirective } from '@eva-directives/ellipsis.directive';
// eslint-disable-next-line max-len
import { NotificationsFeedbackViewComponent } from './notifications-feedback/notifications-feedback-view/notifications-feedback-view.component';
import { ToastModule } from "primeng/toast";
import { OnlyNumberInputDirective } from '@eva-directives/keyboard/only-number-input.directive';
import { MaskInputDirective } from '@eva-directives/keyboard/mask-input.directive';
import { KnowledgeRevisionsViewComponent } from './admin-overview/knowledge/knowledge-revisions-view/knowledge-revisions-view.component';
import { LeftMenuComponent } from './nav/left-menu/left-menu.component';
import { BackgroundComponent } from './layouts/background/background.component';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { MultiViewComponent } from './home/multi-view/multi-view.component';
import { LaunchPadComponent } from './nav/launch-pad/launch-pad.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { GenericSearchFilterComponent } from './generic-search/generic-search-filter/generic-search-filter.component';
import { ShowIfUserMemberOfGroupTypeDirective } from '../directives/show-if-user-member-of-group-type.directive';
import { LaunchPadEditComponent } from './nav/launch-pad-edit/launch-pad-edit.component';
import { ChatMessageComponent } from './salt-chat/chat-message/chat-message.component';
import { ChatLoadingComponent } from './salt-chat/chat-loading/chat-loading.component';
import { ChatMlTrainingComponent } from './salt-chat/chat-ml-training/chat-ml-training.component';
// eslint-disable-next-line max-len
import { KnowledgeTableOfContentsComponent } from './admin-overview/knowledge/knowledge-table-of-contents/knowledge-table-of-contents.component';
import { ChatInteractionMessageComponent } from './salt-chat/chat-interaction-message/chat-interaction-message.component';
import { ActionTemplateRendererComponent } from './generic-search/action-template/action-template.component';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';
import { StatusComponent } from './status/status.component';
import { ChatRedirectComponent } from './salt-chat/chat-redirect/chat-redirect.component';
import { IsValidFilterValuePipe } from './generic-search/pipes/is-valid-filter-value.pipe';
import { GetFilterValuePipe } from './generic-search/pipes/get-filter-value.pipe';
import { GetPropertiesPipe } from './generic-search/pipes/get-properties.pipe';
import { GetPropertyUserFriendlyNamePipe } from './generic-search/pipes/get-property-user-friendly-name.pipe';
import { GetPropertyValuePipe } from './generic-search/pipes/get-property-value.pipe';
import { IsValidArrayPipe } from './generic-search/pipes/is-valid-array.pipe';
import { FromJsonPipe } from './generic-search/pipes/from-json.pipe';
import {NgxPrintModule} from 'ngx-print';
import { IsUserInGroupTypePipe } from '@eva-pipes/is-user-in-group-type.pipe';
import { UserFriendlyConditionPipe } from './form-builder/interaction-element-relation/pipes/user-friendly-condition.pipe';
import { GetScreenNamePipe } from './form-builder/interaction-element-relation/pipes/get-screen-name.pipe';
import { GetLogicOptionsPipe } from './form-builder/interaction-element-relation/pipes/get-logic-options.pipe';
import { GetLogicActionsPipe } from './form-builder/interaction-element-relation/pipes/get-logic-actions.pipe';
import { GetMathEquationOperatorsPipe } from './form-builder/interaction-element-relation/pipes/get-math-equation-operators.pipe';
import { GetNumberCandidateElementsPipe } from './form-builder/interaction-element-relation/pipes/get-number-candidate-elements.pipe';
import { GetCandidateElementsPipe } from './form-builder/interaction-element-relation/pipes/get-candidate-elements.pipe';
import { StringSeperatorDisplayPipe } from './generic-search/pipes/string-seperator-display.pipe';
import { ChatUnseenCountDirective } from './home/chat-unseen-count.directive';
import { KnowledgeGlobalFindAndReplaceComponent } from './admin-overview/knowledge/knowledge-global-find-and-replace/knowledge-global-find-and-replace.component';
import { KnowledgeGlobalFindAndReplaceEditDocumentComponent } from './admin-overview/knowledge/knowledge-global-find-and-replace/knowledge-global-find-and-replace-edit-document/knowledge-global-find-and-replace-edit-document.component';
import { InteractionElementRelationTipsComponent } from './form-builder/interaction-element-relation-tips/interaction-element-relation-tips.component';
import { TimestampPastPipe } from '@eva-pipes/timestamp-past.pipe';
import { KnowledgeDocumentSectionComponent } from './admin-overview/knowledge/knowledge-document-section/knowledge-document-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EvaMaterialModule,
    BrowserAnimationsModule,
    ClipboardModule,
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsMaterialUIModule,
    TreeModule,
    SidebarModule,
    ButtonModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    ToggleButtonModule,
    AccordionModule,
    ListboxModule,
    NgAisModule,
    SelectButtonModule,
    MultiSelectModule,
    AgGridModule,
    NgxGraphModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ToastModule,
    DragDropModule,
    UiComponentsModule,
    NgxPrintModule
  ],
  declarations: [
    CustomTooltipComponent,
    SpinnerComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    TutorialIntroComponent,
    LoginPageComponent,
    FormBuilderComponent,
    SaltChatComponent,
    UserPreferencesComponent,
    FormControlDialogComponent,
    GeneralDialogComponent,
    ScrollableDirective,
    FormElementVisualizerComponent,
    FormViewerComponent,
    FormObjectViewerComponent,
    FormVisualizerComponent,
    ViewContainerDirective,
    WorkflowBuilderComponent,
    ShowHistoryComponent,
    WorkflowInteractionPlaceholderComponent,
    InteractionVisualizerDialogComponent,
    EntityPickerDialogComponent,
    ErrorPageComponent,
    WorkflowInteractionConditionDialogComponent,
    WorkflowInteractionConditionComponent,
    WorkflowDestinationPickerComponent,
    WorkflowDestinationViewerComponent,
    NotificationsComponent,
    GroupSearchComponent,
    GroupAdminComponent,
    GroupMembersDialogComponent,
    GroupSearchMembersDialogComponent,
    InteractionElementRelationDialogComponent,
    InteractionElementRelationComponent,
    InteractionConditionDialogComponent,
    InteractionConditionBuilderComponent,
    TotalCollaboratorsPipe,
    KeepHTMLPipe,
    WorkflowInteractionConditionViewerComponent,
    ProcessComponent,
    ProcessPickerDialogComponent,
    ProcessRunnerComponent,
    ProcessDetailViewerComponent,
    GraphZoomableDirective,
    NodeDraggableDirective,
    GraphComponent,
    NodeComponent,
    LinkComponent,
    WorkflowGraphComponent,
    UserInfoDisplayComponent,
    NotificationItemComponent,
    ProcessSummaryComponent,
    LandingPageComponent,
    EvaChatWorkflowsComponent,
    LandingPageComponent,
    AdminOverviewComponent,
    ConfirmWorkflowDialogComponent,
    AboutEvaComponent,
    GroupComponent,
    AlgoliaHitsDisplayPipe,
    DeleteInteractionDialogComponent,
    CreateGroupDialogComponent,
    KnowledgeCreateComponent,
    KnowledgeComponent,
    UserVerifyResetComponent,
    UserMnemonicComponent,
    ReleaseNotesComponent,
    TrainingComponent,
    TeamMembersComponent,
    AboutPageDocumentsComponent,
    AboutPageFAQsComponent,
    AboutPageFeedbackComponent,
    AddEditDocumentsComponent,
    AddEditFAQsComponent,
    LearnEVAVideosComponent,
    AddEditLearnEVAVideosComponent,
    AddEditReleaseNotesComponent,
    WrongEnvironmentComponent,
    ProcessEditSummaryComponent,
    KnowledgeSectionComponent,
    AddEditTeamMembersComponent,
    WorkflowFormElementVisualizerComponent,
    StickyElementDirective,
    KnowledgeFindAndReplaceDialogComponent,
    KnowledgeSearchComponent,
    GroupNamePipe,
    TimestampPastPipe,
    SafeHtmlPipe,
    KnowledgeUploadTaskComponent,
    FileUploadComponent,
    ChildGroupInviteNotificationsComponent,
    ShareGroupDialogComponent,
    GroupInfoDisplayComponent,
    KnowledgeRevisionsComponent,
    KnowledgeDiffComponent,
    NotificationsFeedbackComponent,
    KnowledgeFeedbackItemComponent,
    KnowledgeFeedbackComponent,
    DeadEndComponent,
    HomeComponent,
    InteractionComponent,
    ChatMessagesComponent,
    ChatMlComponent,
    IsUserInGroupPipe,
    NotificationsFeedbackGroupComponent,
    ProcessDashboardComponent,
    IdMapDialogComponent,
    IdMapInteractionChangesComponent,
    IdMapInteractionElementDialogComponent,
    GroupSelectDialogComponent,
    IdMapWorkflowInteractionDialogComponent,
    IdMapWorkflowInteractionMapDialogComponent,
    WorkflowViewerDialogComponent,
    IdMapEntityGroupMapComponent,
    IdMapIdChangesComponent,
    IdMapWorkflowInteractionElementDialogComponent,
    WorkflowGraphVisualizerComponent,
    WorkflowImportExportComponent,
    InteractionImportExportComponent,
    KnowledgeDeleteComponent,
    KnowledgeFeedbackPromptComponent,
    ChunkPipe,
    KnowledgeMultiViewComponent,
    KnowledgeDocumentSearchComponent,
    KnowledgeSectionSearchComponent,
    EllipsisDirective,
    NotificationsFeedbackViewComponent,
    OnlyNumberInputDirective,
    MaskInputDirective,
    KnowledgeRevisionsViewComponent,
    LeftMenuComponent,
    BackgroundComponent,
    GenericSearchComponent,
    MultiViewComponent,
    LaunchPadComponent,
    UserAvatarComponent,
    GenericSearchFilterComponent,
    ShowIfUserMemberOfGroupTypeDirective,
    LaunchPadEditComponent,
    ChatMessageComponent,
    ChatLoadingComponent,
    ChatMlTrainingComponent,
    KnowledgeTableOfContentsComponent,
    ChatInteractionMessageComponent,
    ActionTemplateRendererComponent,
    KnowledgeTableOfContentsComponent,
    StatusComponent,
    ChatRedirectComponent,
    IsValidFilterValuePipe,
    GetFilterValuePipe,
    GetPropertiesPipe,
    GetPropertyUserFriendlyNamePipe,
    GetPropertyValuePipe,
    IsValidArrayPipe,
    FromJsonPipe,
    IsUserInGroupTypePipe,
    UserFriendlyConditionPipe,
    GetScreenNamePipe,
    GetLogicOptionsPipe,
    GetLogicActionsPipe,
    GetMathEquationOperatorsPipe,
    GetNumberCandidateElementsPipe,
    GetCandidateElementsPipe,
    StringSeperatorDisplayPipe,
    ChatUnseenCountDirective,
    KnowledgeGlobalFindAndReplaceComponent,
    KnowledgeGlobalFindAndReplaceEditDocumentComponent,
    InteractionElementRelationTipsComponent,
    KnowledgeDocumentSectionComponent
  ],
  exports: [
    EvaMaterialModule,
    LoginPageComponent,
    DynamicFormsCoreModule,
    DynamicFormsMaterialUIModule,
    TreeModule,
    SidebarModule,
    ButtonModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    ToggleButtonModule,
    AccordionModule,
    ListboxModule,
    TotalCollaboratorsPipe,
    KeepHTMLPipe,
    SelectButtonModule,
    GraphZoomableDirective,
    NodeDraggableDirective,
    GraphComponent,
    NodeComponent,
    LinkComponent,
    MultiSelectModule,
    AlgoliaHitsDisplayPipe,
    GroupNamePipe,
    TimestampPastPipe,
    IsUserInGroupPipe,
    IsUserInGroupTypePipe,
    ChunkPipe,
    OnlyNumberInputDirective,
    MaskInputDirective,
    UserFriendlyConditionPipe
  ],
  providers: [
    SpinnerService,
    TreeDragDropService,
    UserFriendlyConditionPipe
  ]
})
export class UiModule { }
