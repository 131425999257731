import { Component, OnInit, OnDestroy } from '@angular/core';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { environment } from '@environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { AuthService } from '@eva-core/auth.service';
import { UntypedFormBuilder } from '@angular/forms';
import { CryptoGroupRequest } from '@eva-model/invitation';
import { Group } from '@eva-model/group';
import { LoggingService } from '@eva-core/logging.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupSearchMembersDialogComponent } from './group-search-members/group-search-members.component';
import { distinctUntilChanged, map, filter, debounceTime, take } from 'rxjs/operators';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { User } from '@eva-model/User';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-search',
  templateUrl: './group-search.component.html',
  styleUrls: ['./group-search.component.css']
})
export class GroupSearchComponent implements OnInit, OnDestroy {

  groups: any[];
  groupSearchQuery: Subject<string> = new Subject();
  lastKeypress = 0;
  sent = false;
  selectedGroups: any[];

  _queryLoading = new BehaviorSubject<boolean>(false);
  _showGroupSearchResults = new BehaviorSubject<boolean>(false);

  queryLoading: Observable<boolean> = this._queryLoading.asObservable();
  showGroupSearchResults: Observable<boolean> = this._showGroupSearchResults.asObservable();

  invitationGroupType = environment.blockConfig.types.groups.types.invitation;

  user: User;
  componentTitle: string;

  constructor(
    private _is: InvitationService,
    private _as: AuthService,
    private _fb: UntypedFormBuilder,
    public gps: GroupProviderService,
    private _ls: LoggingService,
    private dialog: MatDialog,
    public evaGlobalService: EvaGlobalService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    }); }

  ngOnInit() {
    // synchronous
    // this.gps.getUsersGroups();
    // get the user from the angular fire auth;
    this._as.user.subscribe(user => {
      this.user = user;
      // the get the group query.
      this.groupSearchQuery.pipe(
        distinctUntilChanged(),
        map(q => q.toLowerCase()),
        filter(q => !!q),
        debounceTime(500)
      )
      .subscribe((query) => {
        this._queryLoading.next(true);
        // this.gps.groupsByName(query, query + '\uf8ff').subscribe(users => {
        this.gps.groupsByName(query).subscribe(results => {
          this._showGroupSearchResults.next(true);
          this.groups = this.checkIfMembership(results);
          this._queryLoading.next(false);
        });
        }
    );
    });

  }

  ngOnDestroy() {
    this.groupSearchQuery.unsubscribe();
  }

  // Loop through group search results to update the search result model
  // if auth user already belongs to group
  checkIfMembership(searchResults: Group[]): any[] {
    const memberGroupKeys = this.evaGlobalService.userGroups; // = this.gps.userGroupMembership;

    searchResults.forEach(group => {
      if (memberGroupKeys) {
        memberGroupKeys.forEach(key => {
          group['isMember'] = key.groupPublicKey === group.groupPublicKey;
        });
      }
    });
    return searchResults;
  }

  /**
   * This function finds the group based on the search text
   *
   * @param $event HTML key up event
   */
  findGroup($event) {
    // hide results if input is cleared
    if ($event.target.value.length === 0) {
      this.groups = [];
      // clear last query
      this.groupSearchQuery.next('');
      this._showGroupSearchResults.next(false);
      return;
    }

    const q = $event.target.value;
    this.groupSearchQuery.next(q);
    // this._showUserSearchResults.next(true);
  }

  /**
   * This function requests group access and creates an object in the database if it was accepted.
   * @param group the groupt that access is being requested to
   */
  async requestGroupAccess(group: Group): Promise<void> {
    group['requestingAccess'] = true;
    const groupRequest = new CryptoGroupRequest(Date.now(), group.groupPublicKey, this.user.signingPublicKey);

    try {
      const response = await this.gps.requestGroupAccess(this.user.email, this.user.preferredName, this.user.signingPublicKey,
        group.groupPublicKey, group.groupName, group.groupType, null);

      group['requestedAccess'] = false;
      this._ls.logMessage('Request sent successfully', false, 'success');
      return;
    } catch (err) {
      console.log(err);
      this._ls.logMessage('Error sending your request', false, 'error');
      group['requestingAccess'] = false;
      return;
    }
  }

  /**
   * This function open dialog with group information
   *
   * @param group group's information being requested
   */
  openMoreGroupInformation(group: Group) {
    const dialogData = new GeneralDialogModel(
      group.groupName,
      group.groupSigningMembership.length + ' Members',
      'Close', null, null,
      {
        'group': JSON.parse(JSON.stringify(group))
      }
    );
    const dialogComponent = GroupSearchMembersDialogComponent;

    const dialogRef = this.dialog.open(dialogComponent, {
      data: dialogData,
      minHeight: '300px',
      minWidth: '600px',
      disableClose: true
    });
  }
}
