/**
 * This contains the main menu file for the EVA application (default EVA Screen)
 */
import { EVAMenu } from "@eva-model/menu/menu";
import { knowledgeSVGIcon, processSVGIcon, dashboardsSVGIcon, aboutSVGIcon } from "./icons";

export enum Routes {
    Home = '/',
    About = '/about',
    Admin = '/admin',
    AdminEVALogs = '/admin/EVALogs',
    AdminFeedback = '/admin/Feedback',
    AdminProcessIssues = '/admin/ProcessIssues',
    AdminProcessStatus = '/admin/ProcessStatus',
    InteractionBuilder = '/builders/Interaction',
    Knowledge = '/knowledge',
    MachineLearning = '/ai/machineLearning',
    Preferences = '/user/preferences',
    Process = '/Process',
    ProcessDashboard = '/dashboards/Process',
    WorkflowBuilder = '/builders/Workflow'
}

export const MainMenu: EVAMenu[] = [{
    routerLink: Routes.Knowledge,
    svgIcon: knowledgeSVGIcon,
    badgeCount: 3,
    text: 'Knowledge'
}, {
    routerLink: Routes.Process,
    svgIcon: processSVGIcon,
    badgeCount: 3,
    text: 'Processes',
    id: 'ProcessesMenuItem'
}, {
    isEmptyRoute: true,
    emptyRouteName: 'dashboards',
    svgIcon: dashboardsSVGIcon,
    text: 'Dashboards'
}, {
    routerLink: '/about',
    svgIcon: aboutSVGIcon,
    text: 'About EVA'
}
];

// {
//     isEmptyRoute: true,
//     emptyRouteName: 'ai',
//     svgIcon: aiSVGIcon,
//     // badgeCount: 99,
//     text: 'AI'
// },

// {
//     isEmptyRoute: true,
//     emptyRouteName: 'builders',
//     svgIcon: buildersSVGIcon,
//     // badgeCount: 3,
//     text: 'Builders'
// },

// {
//     routerLink: '/admin',
//     svgIcon: administrationSVGIcon,
//     text: 'Administration'
// },
