<div class="form-container">
  <div>
    <div>
      <h3 class="component-title">{{ interactionName }}</h3>
    </div>
    <!-- loading interaction -->
    <div class="loading-container" *ngIf="interaction && interaction.loading">
      <mat-spinner></mat-spinner>
      Loading interaction...
    </div>
    <!-- error when retrieving interaction -->
    <div *ngIf="interaction && interaction.error">
      Failed to load interaction, please try again.
    </div>
    <ng-template #dynamicComponent></ng-template>
  </div><!-- END Interaction Container -->
</div><!-- End Form Container -->
