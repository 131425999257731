import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../providers/firestore/firestore.service';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../core/logging.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HoldableEmitter } from '@eva-model/directives';
@Component({
  selector: 'app-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss']
})
export class AdminOverviewComponent implements OnInit {

  // these are used for the ngIf on the sub components.
  enableProcessCompare = false;
  enableProcessStatus = false;
  enableLogs = false;
  enableMain = true;
  enableFeedback = false;

  loadingAdministration = true;
  isAdministrator = false;


  disableUpdateEVA = false;
  progress = 0; // The total progress of the hold to execute.
  versionUpdateDisabled = false;
  updateAllRecordsDisabled = false;
  componentTitle: string;

  // endpoints for use in administration
  updateAllRecordsUrl = environment.firebaseFunction.endpoint + '/forceInteractionSync';

  constructor(
    private firestoreService: FirestoreService,
    public authService: AuthService,
    private router: Router,
    private loggingService: LoggingService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  async ngOnInit() {
    try {
      const isAdministrator = await this.authService.isUserAdmin();
      if (!isAdministrator) {
        this.router.navigate(['/']);
      }
      this.isAdministrator = isAdministrator; // is the user an administrator
      this.loadingAdministration = !isAdministrator; // is this loading whether the user is an administrator.
    } catch (error) {
      console.log(error);
    }
  }

  //#region SideNavToMain

  /**
   * This opens the main item and the side nav.
   *
   * @param itemToOpen the item name to open.
   */
  openMain(itemToOpen: string): void {
    this.closeOpenItems();
    switch (itemToOpen) {
      case 'processStatus':
        this.enableProcessStatus = true;
        return;
      case 'processCompare':
        this.enableProcessCompare = true;
        return;
      case 'enableLogs':
        this.enableLogs = true;
        return;
      case 'enableFeedback':
        this.enableFeedback = true;
        return;
      default:
        this.enableMain = true;
        return;
    }
  }

  /**
   * This function closes all open items in the system.
   */
  closeOpenItems(): void {
    this.enableProcessCompare = false;
    this.enableProcessStatus = false;
    this.enableLogs = false;
    this.enableMain = false;
    this.enableFeedback = false;
  }

  // #endRegion SideNavToMain


  doSomething(e) {
    this.progress = e / 30;
    if (this.progress === 100) {
      console.log('yep');
    }
   }
  /**
   * This takes the interval from the appHoldable directive and provides this to the function. By dividing this
   * by 30, the update will occur after holding the button for 3 seconds. It then updates the version of EVA in the database
   * prompting the users to cause a refresh.
   *
   * @param emitter Emits progress of user action
   */
  async updateVersion(emitter: HoldableEmitter): Promise<void> {
    this.progress = emitter.holdTime / 30;
    // if this is at 100% fire.
    if (this.progress === 100) {
      this.disableUpdateEVA = true;
      this.versionUpdateDisabled = true;
      const versionRef$ = this.firestoreService.doc('EVAVersion/VersionNumber');
      try {
        await versionRef$.set({ version: Date.now() }, { merge: false });
        // this.loggingService.logMessage('Updated Version', false, 'success');
        this.versionUpdateDisabled = false;
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('An error occurred when creating a new version', false, 'error');
        this.versionUpdateDisabled = false;
      }
    }
  }

  /**
   * This forces the items in EVARecords to update all records which triggers an update to algolia.
   *
   * @param emitter Emits progress of user action
   */
  async updateAllRecords(emitter: HoldableEmitter): Promise<void> {
    this.progress = emitter.holdTime / 30;
    // if this is at 100% fire.
    if (this.progress === 100) {
      this.versionUpdateDisabled = true;
      try {
        this.loggingService.logMessage('We are starting to update all records.', false, 'success');
        await this.http.get(this.updateAllRecordsUrl).toPromise();
        this.loggingService.logMessage('All Records are set and will update through the scheduled task', false, 'success');
        this.versionUpdateDisabled = false;
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('An error occurred when creating a new version', false, 'error');
        this.versionUpdateDisabled = false;
      }
    }
  }
}
