import { KnowledgeUtils } from './knowledgeUtils';
import { KnowledgeDocument, KnowledgeDocumentSection, KnowledgeDocumentTableOfContents, KnowledgeDocumentTableOfContentsItem } from '@eva-model/knowledge/knowledge';
import * as Crypto from 'crypto';
import { get, set } from 'lodash';

/**
 * This is created when looping over a NodeList. Node list is creating from DOM elements.
 * We loop over the NodeList, looking for specific properties, we set the key of this node to the key
 * in our map, and then creating a SectionMapNode from certain data found in the Node.
 */
interface SectionMap {
  [key: number]: SectionMapNode;
}

/**
 * THis captures much information at the time when it's constructed, and is also filled in later. This is
 * an easy way to track many things about Nodes in our NodeList. We user this near the end to construct our
 * Section tree.
 */
interface SectionMapNode {
  id: string;
  order: string;
  idArr: number[];
  nextId: number;
  parentNodeIndex: number;
  baseNode: boolean;
}

export class KnowledgeModel {

  private knowledgeDocument: KnowledgeDocument; // Main object we will be editing

  // TODO: Add proper type to nodeList
  private nodeList: any[]; // List of html nodes
  private nodeIdMap: SectionMap; // Object collection of section ids that corroelate to the nodeList above
  private nodeIdTree: any = {}; // Object tree of new generated doc section ids
  private lastIdCreated: string = null; // tracks last id created and saved.

  // Handles tracking indexes for use with nextId / parentNode. How does it work?:
  //
  // newSibling() is called, if it's a base node, we set index 0 to the current nodeIdMap index.
  // every time newSibling() is called, we update this. Now, let's say newChild() was called...
  // we need to know who's the parent of this child. We use the values in this array depending on
  // the length of the section id. Once our full map is updated with all related nodes, we then calculate
  // the nextId attribute.
  private _parentIndexes: number[] = [];

  // #document from the knowledge document
  private _documentDOM: Document;

  constructor(knowledgeDoc?: KnowledgeDocument) {
    if (knowledgeDoc) {
      // Initialize properties in the class with existind data from the doc
      this.handleExistingDocInit(knowledgeDoc);
    } else {
      // No passed in doc, create a new empty doc
      this.knowledgeDocument = {
        groupPublicKey: null,
        name: null,
        sections: [],
        draft: true,
        updatedBy: '',
        nodeIds: [],
        approvalGroups: []
      };
    }
  }

  /**
   * Sets up this class to track existing section ids and sets our knowledge document property
   * with passed knowledge document.
   *
   * @param knowledgeDoc existing knowledge doc
   */
  private handleExistingDocInit(knowledgeDoc: KnowledgeDocument): void {
    // Set passed in doc
    this.knowledgeDocument = knowledgeDoc;

    // Create a section id tree of the existing ids
    knowledgeDoc.nodeIds.forEach((sectionId: string) => {
      this.trackSectionId(sectionId);
    });

    // create #document of knowledge document
    this._documentDOM = this.createLiveDocumentFromKnowledge();
  }

  /**
   * Returns a boolean of if the doc has an ID property or not. If it doesn't have an ID, this returns true.
   *
   * @return {boolean} whether the doc has an id property or not
   */
  public get isNewDoc(): boolean {
    return !!(!this.knowledgeDocument.id);
  }

  /**
   * Returns the id property of the document
   *
   * @return {string} id or null
   */
  public get id(): string {
    return this.knowledgeDocument.id;
  }

  /**
   * Returns the group public key of the document
   *
   * @return {string} group public key or null
   */
  public get groupPublicKey(): string {
    return this.knowledgeDocument.groupPublicKey;
  }

  /**
   * Returns if the document is draft
   *
   * @return {boolean}
   */
  public get draft(): boolean {
    return this.knowledgeDocument.draft;
  }

  /**
   * Returns the name of the document
   *
   * @return {string} name of doc
   */
  public get name(): string {
    return this.knowledgeDocument.name;
  }

  /**
   * Returns the version of the document
   *
   * @return {number} version number of doc
   */
  public get versionNumber(): number {
    return this.knowledgeDocument.versionNumber;
  }

  /**
   * Returns the revision note
   *
   * @return {string} revision note for this document version
   */
  public get revisionNote(): string {
    return this.knowledgeDocument.revisionNote;
  }

  /**
   * Returns the live document of the built knowledge doc
   * Be very careful, editintg this will alter what is returned in `getHTMLEditString`
   *
   * @return {Document}
   */
   public get documentDOM(): Document | undefined {
    return this._documentDOM;
  }

  /**
   * Set the document draft status
   * @param {boolean} status you'd like to set the document to
   */
  public set updateDraftStatus(status: boolean) {
    this.knowledgeDocument.draft = status;
  }

  /**
   * Set the document name
   * @param {string} name you'd like to set on the document
   */
  public set updateName(docName: string) {
    this.knowledgeDocument.name = docName;
  }

  /**
   * Set the revision note for this document version
   * @param {string}
   */
  public set updateRevisionNote(note: string) {
    this.knowledgeDocument.revisionNote = note;
  }

  /**
   * Set the group the document will be added to
   * @param {string} group public key
   */
  public set updateGroupPublicKey(groupPk: string) {
    this.knowledgeDocument.groupPublicKey = groupPk;
  }

  /**
   * Set the id of the document
   * @param {string} id - id of the document
   */
  public set updateId(id: string) {
    this.knowledgeDocument.id = id;
  }

  /**
   * Set the versionNumber of the document if it is newly created
   * @param {number} version - new versionNumber of the document
   */
  public set updateVersionNumber(version: number) {
    this.knowledgeDocument.versionNumber = version;
  }

  /**
   * Transform HTML string into Section(s) and adds it to the existing KnowledgeDocument, then returns it.
   * This object can then be passed to our knowledge endpoint and saved in the database.
   *
   * KnowledgeDocument
   *  |- Section
   *  |   |- Section
   *  |   '- Section
   *  '- Section
   *
   * @param {string} html - html string that comes from a WYSIWYG
   */
  public createKnowledgeDocumentRequest(html: string): KnowledgeDocument {
    // return a sanitized html string
    const sanitizedHtml = KnowledgeUtils.sanitizeHtml(html);

    // Reset index data
    this._parentIndexes = [];

    // TODO: Ensure that the wiki document being passed in is clean. Remove any sections or their will be issues.
    // Start fresh with an empty array.
    this.knowledgeDocument.sections = [];
    this.knowledgeDocument.text = '';

    // Create node list from HTML string
    // This is our list html string parsed into an array of HTMLElements in the order they appear in the string.
    this.nodeList = KnowledgeUtils.createNodeListAsArray(sanitizedHtml);

    // Map the HTMLElements (nodeList) to get existing section ID's and determine the order.
    const nodeIdMap = this.createSectionIdsMappedToNodes( this.nodeList );

    const nodeIds = [];
    // loop each key in the document and add the values into an array.
    Object.keys(nodeIdMap).forEach(key => {
      const id = nodeIdMap[key].id;
      // protect against elements that belong on the Doc .text property and have no id
      if (id) {
        nodeIds.push(id);
      }
    });

    // Takes the nodeList and SectionMap data and updates our knowledeDocument property with the changes.
    const doc: KnowledgeDocument = this.updateDocument(nodeIdMap);

    // add document hash
    doc['documentHash'] = this.createHash(doc.text + JSON.stringify(doc.sections));
    // add the array of the sections to ensure we can keep track with the machine learning.
    doc.nodeIds = nodeIds;

    return doc;
  }

  /**
   * Loops over the nodes and creates an object where the key is mapped to the array index of nodes.
   * This function attempts to determine what is above or below something else and returns the appropriate id for that index.
   * TODO: Fix Type on paramters
   *
   * @param nodes Array of Nodes
   */
  private createSectionIdsMappedToNodes( nodes: any[] ): SectionMap {

    this.nodeIdMap = {};

    // 1. Create a key value pair in our map object for every node element
    // 2. See if the node element has an ID attr, if so, set our node in our map to that id.
    this.nodeList.forEach((htmlElement, index) => {
      // Look for existing elements with existing id attributes on them,
      // if there are attributes, added them to our section id map and to our section nullree.
      if (htmlElement.id && htmlElement.id !== '') {
        this.nodeIdMap[index] = {id: htmlElement.id, order: null, idArr: null, nextId: null, parentNodeIndex: null, baseNode: false};

        // Track what the highest section id is in the document
        this.trackSectionId(htmlElement.id);
      } else {
        // Element has no id attribute, create an empty object we will populate later.
        this.nodeIdMap[index] = {id: null, order: null, idArr: null, nextId: null, parentNodeIndex: null, baseNode: false};
      }
    });

    let lowestHeader: number = null; // tracking lowest header weight
    let previousHeaderWeight: number = null; // tracking previous header weight

    // use For loop so we can skip elements when we need to
    for (let i = 0; i < nodes.length; i++) {
      const index = i;
      const node = nodes[i];

      // Define the previous node here. Before we get to this point, we are stripping some elements out of the NodeList and
      // the previousSibling property could be incorrect... for example if we remove an <hr> but then the next element we keep
      // and the next element still thinks its previous sibling is an <hr>.
      // Set the previous sibling to one index behind.
      const previousSibling = nodes[index - 1];

      if (KnowledgeUtils.isHeader(node.localName)) {
        const currentWeight = parseFloat(node.localName[1]);

        if (!lowestHeader) {
          lowestHeader = currentWeight;
        }

        // Previous node did exist AND was a header element
        if (previousSibling && KnowledgeUtils.isHeader(previousSibling.localName)) {
          // let newSectionId;
          if (currentWeight > previousHeaderWeight) {
            this.newChild(index);
          }

          if (currentWeight === previousHeaderWeight) {
            this.newSibling(index);
          }

          if (currentWeight < previousHeaderWeight) {
            this.newParent(index, currentWeight, previousHeaderWeight, previousSibling.localName);
          }

        // Previous node was not a header element
        } else {

          // if (currentWeight >= previousHeaderWeight) {
          if (!previousHeaderWeight || currentWeight === previousHeaderWeight) {
            this.newSibling(index);
          } else if (currentWeight > previousHeaderWeight) {
            this.newChild(index);
          } else {
            this.newParent(index, currentWeight, previousHeaderWeight, previousSibling.localName);
          }

        }

        // Finish up
        previousHeaderWeight = currentWeight;
        if (!lowestHeader) {
          lowestHeader = currentWeight;
        }

      } else {
        // Not an H element

        // Check if we have iterated over any header element before this
        // If we have not, we know this element belongs on the document itself, not in a section
        if (!previousHeaderWeight) {
          this.newDocElement(index);
          continue;
        }

        // keep the previous order for all non-header elements
        this.nodeIdMap[index].order = this.nodeIdMap[index - 1].order;

      }

    }

    // Let's create a map of the next highest id based on the parentNodeId
    // We will populate this object with the key as parentNodeIndex and the value, the calculated next highest nodeId

    // Create variables of our values we will be setting
    const childNextIdMap = {}; // keys correlate to the parentNodeIndex in nodeIdMap
    const baseNextIdMap = {}; // keys correlate to the key in nodeIdMap
    let baseNextId = 0; // Used to track the next highest base node, applied to knowledgeDoc on save.

    let currentBaseKey: number = null; // internal tracking of what nodeIdMap index we are 'under'
    // used to determine whether we are still under the same base node when looping. We can determine this by the length of the section id.
    let currentBaseChildIdLength: number = null;

    if (this._parentIndexes.length > 0) {
      // Get all SectionMapNode's with a parentNodeIndex or are a base section, also add the key of the nodeIdMap to the objects.
      const sectionsWithParents: SectionMapNode[] = Object.keys(this.nodeIdMap).map(k => Number(k)).map(k => {
        if (this.nodeIdMap[k].parentNodeIndex || this.nodeIdMap[k].baseNode) {
          const sectionWithParentsNode = {
            nodeIdMapIndex: k, // this sections' place (key) in our nodeIdMap
            ...this.nodeIdMap[k]
          };
          return sectionWithParentsNode;
        }
      }).filter(node => node); // Remove any undefined objects. (sections that are not a baseNode or have a parent)

      // Check if the baseNode property is true, if it is, look at the id of the node and see if we have it recorded in our variable.
      // The point is to get the highest id number we use on the document
      sectionsWithParents.forEach((v: any, k: number) => {
        // If this section is a base node
        if (v.baseNode) {
          if (v.idArr[0] > baseNextId) {
            baseNextId = v.idArr[0];
          }

          currentBaseChildIdLength = null;
          currentBaseKey = v.nodeIdMapIndex;

        } else {
          // If this section is NOT a base node
          // Get the end value of the section id
          const endIdNumber = v.idArr[v.idArr.length - 1];
          // Store the last sectionId value under the key of the parentNodeIndex
          // We do this so we can have a map where for each parentNodeIndex is a key in the object and the value is the
          // highest section id end value of all the section node related to this parentNodeIndex.
          // eg. {0: 13, 3: 21, 18: 3, 27: 2}
          //      ^ parentNodeIndex        ^ highest end value
          if (!childNextIdMap[v.parentNodeIndex] || endIdNumber > childNextIdMap[v.parentNodeIndex] ) {
            childNextIdMap[v.parentNodeIndex] = endIdNumber;
          }

          // Save some base node index data
          if (!currentBaseChildIdLength) {
            currentBaseChildIdLength = v.idArr.length;
            baseNextIdMap[currentBaseKey] = endIdNumber;
          } else {
            if (currentBaseChildIdLength === v.idArr.length && endIdNumber > baseNextIdMap[currentBaseKey]) {
              baseNextIdMap[currentBaseKey] = endIdNumber;
            }
          }
        }
      });
    }

    // Once we created our map with our values, we then apply our nextId to our sectionsNodes
    const keys = Object.keys(this.nodeIdMap).map(k => Number(k));
    keys.forEach((key) => {
      // Apply the next available base section id to every base node
      // Apply the next available section id to all of the parentNodexIndex that are the same
      if (this.nodeIdMap[key].parentNodeIndex) {
        // this.nodeIdMap[key].nextId = childNextIdMap[this.nodeIdMap[key].parentNodeIndex] + 1;
        // ---
        // Note: above line is commented out because children with no children of there own were having incorrect
        // nextId's set. They were seeing their parents next id which is not correct.
        this.nodeIdMap[key].nextId = 1;
      }
      // Apply the next available section id to all of the baseNode that are the same
      if (this.nodeIdMap[key].baseNode) {
        // Check if there is a key, if so, add 1 to the value, if base node exists with no child, set to 1
        this.nodeIdMap[key].nextId = (baseNextIdMap[key]) ? baseNextIdMap[key] + 1 : 1;
      }
    });

    // Apply next id to the base doc for base nodes
    this.knowledgeDocument.nextId = baseNextId + 1;

    // Return our updated nodeMap
    return this.nodeIdMap;
  }

  /**
   * Creates a hash of a string for checking if sections have changed.
   * ! This function must be here since if we moved this to a crypto service, we would have to import
   * ! the crypto service everywhere we used this class and inject the service instance. :/
   */
  private createHash(str: string) {
    return Crypto.createHash('md5').update(str).digest('hex');
  }

  /**
   * Takes the last section ID and creates the next section ID.
   * This is always called when there is a new child element, and the child will
   * always start at 1, so we just appened '_1' to the end of the last section ID.
   *
   * @param lastSectionId string of a number or numbers sperated by an underscore
   */
  private newChild(currentIndex: number) {
    const lastSection: SectionMapNode = this.nodeIdMap[currentIndex - 1];
    const currentSection: SectionMapNode = this.nodeIdMap[currentIndex];

    let childId = 1;

    // Set our new section as the last section order plus a child number
    currentSection.order = `${lastSection.order}_${childId}`;

    // We are creating a new child id, ensure it's not used already. First, lets test the order id and see if it's taken

    if (currentSection.id) {
      // Skip generating an id. If this node already has an id, it was already added to the tree. We're done here.
      // return;
    } else {
      // const exists = this.isSectionIdBeingUsed(currentSection.order);

      // // ID exists, get one that doesn't exist
      // if (exists) {
      //   // Setup path for lodash
      //   const path = lastSection.order.split('_').join('.');
      //   // Get all keys as an array of numbers at that tree location
      //   const usedIds: number[] = Object.keys( get(this.nodeIdTree, path) ).map(a => parseFloat(a));
      //   // Pass are array values into math function to get highest value
      //   const highestId = Math.max(...usedIds);
      //   // We got the highest value, now add 1 and set as the new childId
      //   childId = highestId + 1;
      // }

      // Instead of using an order id first that is more internal, let's generate an id. This way we won't run into collisions.

      // Get the last generated id
      const path = this.lastIdCreated.split('_').join('.');
      // Query its children, if any.
      const newIdArr = path.split('.').map(n => parseFloat(n));
      newIdArr.push(this.getNextHighestId(path));

      const newId = newIdArr.join('_');

      // Save generated id in string format to our section node
      currentSection.id = newId;
    }

    // Save generated id in an number array format to our section node
    currentSection.idArr = currentSection.id.split('_').map(a => parseFloat(a));

    // Do work to track its nodes parent
    const index = currentSection.idArr.length - 2;
    if (this._parentIndexes[index]) {
      currentSection.parentNodeIndex = this._parentIndexes[index];
      this._parentIndexes[currentSection.idArr.length - 1] = currentIndex;
    }

    // this._parentIndexes.push(currentIndex);

    this.trackSectionId(currentSection.id);
  }

  /**
   * takes the last section ID and creates the next section ID
   * First we parse the ID into an array of type number. Then we get the last number in
   * the ID and increment it by 1, then return a new string ID
   *
   * @param lastSectionId string of a number or numbers sperated by an underscore
   */
  private newSibling(currentIndex: number) {
    let lastSection: SectionMapNode = this.nodeIdMap[currentIndex - 1];
    const currentSection: SectionMapNode = this.nodeIdMap[currentIndex];

    // protect against a call where this is the first element being created or if the previous element belongs on the doc.text
    if (!lastSection || !lastSection.order) {
      lastSection = {id: null, order: '0', idArr: null, nextId: null, parentNodeIndex: null, baseNode: false};
    }

    const lastSectionOrder: number[] = lastSection.order.split('_').map(x => parseFloat(x)); // was sectionIds
    const depthIndex = lastSectionOrder.length - 1; // ending index of the section id.  // was index
    // create our new order id
    lastSectionOrder[depthIndex] = lastSectionOrder[depthIndex] + 1;
    currentSection.order = lastSectionOrder.join('_');

    // TODO: Break up into smaller functions whether we have an id or the id is new and must be generated
    if (currentSection.id) {
      // Skip generating an id. If this node already has an id.
      currentSection.idArr = currentSection.id.split('_').map(a => parseFloat(a));

      // Top level of map and no values, create an initial value
      // if (currentSection.idArr.length === 1 && !this._parentIndexes[0]) {
      //   this._parentIndexes.push(currentIndex);
      // }
    } else {
      // Check the order branch location for other branches.
      // const path = sectionIds.join('.');
      // const tree = this.nodeIdTree;
      // lodash#get - https://lodash.com/docs/4.17.11#get
      // const result = get(tree, path, undefined);

      // generate a new id.

      // check the depth of this node.
      const nodeDepth = currentSection.order.split('_').length;
      let idArr: number[];

      // check and get the next highest base id for this root branch
      if (nodeDepth === 1) {
        idArr = [this.getNextHighestId()];
      } else {
        // we are deeper than 1, get the depth and where we are checking.
        const lastIdArr = this.lastIdCreated.split('_').map(n => parseFloat(n));
        const idPath = lastIdArr.slice(0, lastIdArr.length - 1).join('.');
        const nextId = this.getNextHighestId(idPath);
        // update the last id number
        lastIdArr[lastIdArr.length - 1] = nextId;
        idArr = lastIdArr;
      }

      // const idUsed = this.isSectionIdBeingUsed(sectionIds.join('_'));

      // if (idUsed) {
      //   // id already exists, let's get the highest one

      //   // check if we are at the base of the tree and the id is only 1 number in length, let's get
      //   // all the used ids in our tree and get the highest id, and add 1.
      //   if (sectionIds.length === 1) {
      //     const ids = Object.keys(this.nodeIdTree).map(v => parseFloat(v)); // Get all ids at base of tree
      //     const highestId = Math.max(...ids); // Get the highest number in the array
      //     sectionIds[index] = highestId + 1;
      //   } else {
      //     // not at base, first we need get every number in the id except the last one, then create a string to get in our id tree.
      //     const getPath = sectionIds.slice(0, index).join('.'); // #.#.#
      //     const idTreeResult = get(this.nodeIdTree, getPath);
      //     if (idTreeResult) {
      //       const idValues = Object.keys(idTreeResult).map(k => parseFloat(k));
      //       const highestId = Math.max(...idValues);
      //       sectionIds[index] = highestId + 1;
      //     }
      //   }
      // }
      // Save generated id in string format to our section node
      currentSection.id = idArr.join('_');
      // Save generated id in an number array format to our section node
      currentSection.idArr = currentSection.id.split('_').map(a => parseFloat(a));
    }

    // if (currentSection.idArr.length === 1) {
    //   currentSection.baseNode = true;
    //   if (!this._parentIndexes[0]) {
    //     // If this is the first element in the array
    //     this._parentIndexes.push(currentIndex);
    //   }
    // } else {
    //   // Do work to track its nodes parent
    //   const i = currentSection.idArr.length - 2;
    //   if (this._parentIndexes[i]) {
    //     currentSection.parentNodeIndex = this._parentIndexes[i];
    //     this._parentIndexes[currentSection.idArr.length - 1] = currentIndex;
    //   }
    // }

    // set if this is a base node
    if (currentSection.idArr.length === 1) {
      currentSection.baseNode = true;
    }

    // update our parentIndex tracking and/or set this parentNodeIndex
    if (currentSection.baseNode) {
      this._parentIndexes[0] = currentIndex;
    } else {
      // not a base node, but is a sibling. We cannot tell how deep we are, let's determine it by the id
      const parentIndex = currentSection.idArr.length - 2;
      currentSection.parentNodeIndex = this._parentIndexes[parentIndex];

      // also update the index if a child crops up
      this._parentIndexes[currentSection.idArr.length - 1] = currentIndex;
    }

    this.trackSectionId(currentSection.id);
  }

  /**
   * Here we are determining using the current and previous header weight, what the next section is
   * and how *far back* we should adjust considering the new weight. Example:
   * Last header was <h3>, and the new header we just came across is an <h2>
   * Our section ID is 4_2_2
   * We take the last header weight number and substract the new header weight (2-1), we get 1.
   * From 1, we need to go back 1 index and increment that number. With an ID of 4_2_2, we are
   * changing 2 to 3. New result would be 4_3 for our ID we will return.
   *
   * @param lastSectionId string of a number or numbers sperated by an underscore
   */
  private newParent(currentIndex: number, currentWeight: number, previousHeaderWeight: number, prevEle: string) {
    const lastSection: SectionMapNode = this.nodeIdMap[currentIndex - 1];
    const currentSection: SectionMapNode = this.nodeIdMap[currentIndex];

    // If this is 0, we are probably going from a child non-header node to a header node...
    // just strip off the tail number.
    let weightDiff = previousHeaderWeight - currentWeight > 1 ? previousHeaderWeight - currentWeight : 1;

    if (weightDiff === 0) {
      weightDiff = 1;
    } else if (weightDiff < 0) {
      weightDiff = 0;
    }

    // If we are going backwards 1 or more, we also need to account for how deep we are. Check if the previous element was a header,
    // If not, move backwards 1 additional section id index
    if (previousHeaderWeight - currentWeight >= 1) {
    // if (previousHeaderWeight - currentWeight >= 1 && !this.isHeader(prevEle)) {
      weightDiff++;
    }

    const sectionIds = lastSection.order.split('_').map(x => parseFloat(x));
    // let index = sectionIds.length - 1 - weightDiff;
    let index = sectionIds.length - weightDiff;
    // Make sure we are not doing beyond the section id index
    if (index < 0) {
      index = 0;
    }
    sectionIds[index] = sectionIds[index] + 1;

    const orderId = sectionIds.splice(0, index + 1).join('_');

    currentSection.order = orderId;

    let finalResult: number[];

    if (currentSection.id) {

      // Skip generating an id. If this node already has an id, it was already added to the tree. We're done here.
      // return;

    } else {

      // Check the order branch location for other branches.
      // const path = orderId.split('_').join('.');
      // const tree = this.nodeIdTree;
      // lodash#get - https://lodash.com/docs/4.17.11#get
      // const result = get(tree, path, undefined);
      // const result = this.isSectionIdBeingUsed(orderId);

      finalResult = orderId.split('_').map(x => parseFloat(x));

      // if (result) {
        // id already exists, let's get the highest one

        // check if we are at the base of the tree
        if (finalResult.length === 1) {
          // const ids = Object.keys(this.nodeIdTree).map(v => parseFloat(v)); // Get all ids at base of tree
          // const highestId = Math.max(...ids); // Get the highest number in the array
          // finalResult[index] = highestId + 1;
          finalResult = [this.getNextHighestId()];
        } else {
          // not at base, check if id is used
          // let subPath = orderId.split('_').slice(0, index).join('_');
          // const subResult = this.isSectionIdBeingUsed(subPath);

          // if (subResult) {
          //   // our id still exists... go back one level and get the highest value
          //   subPath = orderId.split('_').slice(0, index - 1).join('_');
          //   const ids = Object.keys(result).map(v => parseFloat(v)); // Get all ids at this location
          //   const highestId = Math.max(...ids); // Get the highest number in the array
          //   finalResult[index] = highestId + 1;
          // } else {
            // our id doesn't exist, yay.
            // do something?
          // }
          // we are deeper than 1, get the depth and where we are checking.
          const lastIdArr = this.lastIdCreated.split('_').map(n => parseFloat(n));
          const idPath = lastIdArr.slice(0, lastIdArr.length - 2).join('.');
          const nextId = this.getNextHighestId(idPath);
          // update the last id number
          const newIdArr = idPath.split('.').map(n => parseFloat(n));
          newIdArr[newIdArr.length - 1] = nextId;
          currentSection.id = newIdArr.join('_');
        }
      // }

    }

    // Save generated id in string format to our section node
    if (!currentSection.id) {
      currentSection.id = finalResult.join('_');
    }
    // Save generated id in an number array format to our section node
    currentSection.idArr = currentSection.id.split('_').map(a => parseFloat(a));

    // Do work to track its nodes parent
    // if (currentSection.idArr.length === 1) {
    //   currentSection.baseNode = true;
    //   this._parentIndexes[0] = currentIndex;
    // } else {
    //   // TODO: Does this make sense?
    //   const i = currentSection.idArr.length - 2;
    //   if (this._parentIndexes[i]) {
    //     currentSection.parentNodeIndex = this._parentIndexes[i];
    //     this._parentIndexes[i] = currentIndex;
    //   } else {
    //     this._parentIndexes[0] = currentIndex;
    //   }
    // }

    // set if this is a base node
    if (currentSection.idArr.length === 1) {
      currentSection.baseNode = true;
    }

    // update our parentIndex tracking and/or set this parentNodeIndex
    if (currentSection.baseNode) {
      this._parentIndexes[0] = currentIndex;
    } else {
      // not a base node, but is a sibling. We cannot tell how deep we are, let's determine it by the id
      const parentIndex = currentSection.idArr.length - 2;
      currentSection.parentNodeIndex = this._parentIndexes[parentIndex];
    }

    this.trackSectionId(currentSection.id);
  }

  /**
   * This function is setting in our node tree, an element that belongs on the doc and not as or in a section itself.
   * This is only used when we have found an element that is NOT a header before we have found a header.
   * @param index index we are iterating upon
   */
  newDocElement(index: number) {
    this.nodeIdMap[index].order = null;
  }

  /**
   * If a section does not exist, create that section object and then return the child section
   * If section does exist, return the child section
   *
   * @param ref the current section Array if it exists, or null
   * @param indexToCheck number from the section ID
   * @param parentIndex index from the section ID map
   */
  private getOrCreateSectionRef(ref: KnowledgeDocument | KnowledgeDocumentSection | null,
                                indexToCheck: number,
                                parentIndex: number): KnowledgeDocumentSection {
    // if we loop through a section without sections, we need this in some way, create the property.
    if (!ref.sections) { ref.sections = []; }

    // section does not exist, immediately assume it's an H, create it and return new section
    if (!ref.sections[indexToCheck]) {
      // create element
      ref.sections[indexToCheck] = {
        title: `<${this.nodeList[parentIndex].localName}>${this.nodeList[parentIndex].innerText}</${this.nodeList[parentIndex].localName}>`,
        id: this.nodeIdMap[parentIndex].id,
        text: '',
        sections: null,
        nextId: this.nodeIdMap[parentIndex].nextId
      };
      return ref.sections[indexToCheck];
    }

    // section exists
    if (ref.sections[indexToCheck]) {
      switch (this.nodeList[parentIndex].localName) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          //
          break;
        default:
          //
      }
    }

    // return the object we just created
    return ref.sections[indexToCheck];
  }

  /**
   * Returns a document with a section tree structure based on the HTML nodes and section ids passed in
   *
   * @param doc a new or existing document in the minimal fields required on {KnowledgeDocument}
   * @param map Object of section ids with the key correlating to the index of the node
   */
  private updateDocument(map: SectionMap): KnowledgeDocument {
    // The loop below works as follows
    // Let's loop over our mapped section ID object, similar to this:
    // example mapped section IDs:
    // {
    //   0: { id: '1', order: null },
    //   1: { id: '1_1', order: '1' },
    //   2: { id: '1_1_1', order: '1' },
    //   3: { id: '1_2', order: '2' }
    // }
    // ========================================================================
    // forEach loop over keys in mappedSectionIds:
    //   0 --> create section obj
    //   1 -------(obj ref 0)-------> create section obj
    //   2 ------------------------------(obj ref 1)-------> create section obj
    //   3 -------(obj ref 0)-------> create section obj
    //   4 --> create section obj
    //   5 -------(obj ref 4)-------> create section obj
    // ========================================================================
    // We are looping and returning references to objects so we don't have to track
    // how deep we are in a child branch, etc... just return the ref and do the work.
    //
    // Loop through each mapped section and change the passed in doc

    const doc = this.knowledgeDocument;
    const mappedSections = Object.keys(map);

    let lastOrder;
    let previousRef: any;
    let accumulatedTextStr = '';

    for (let i = 0; i < mappedSections.length; i++) {

      // If section has no order, add it under the doc text
      if (!map[i].order) {
        doc.text += this.nodeList[i].outerHTML ? this.nodeList[i].outerHTML : '';
        // skip rest and loop again.
        continue;
      }

      // Convert our string ID into an Array<number>
      const order = map[i].order.split('_').map((n: string) => parseFloat(n));

      // Subtract 1 from all numbers in the Array<number> to make them fall inline with an array index
      const orderAsKeys = order.map((n: number) => n - 1);

      // reference to the specific array we are working on deep in our KnowledgeDocument .sections property
      let currentRef: any;

      // If there was a previous node that we created
      if (previousRef) {
        // check if this node is in the same branch and level
        if (map[i].order === lastOrder) {
          // collect the contents of this node and move on

          // LEGO-513:
          // Added checking to ensure outerHTML exists before apprending to string. This is to try and elminate
          // 'undefined' sometimes being added to text. If does not exist, just give an empty string.
          accumulatedTextStr += this.nodeList[i].outerHTML ? this.nodeList[i].outerHTML : '';
        }

        // node is on a new branch, updated the previous node with all our collection html
        if (lastOrder && map[i].order !== lastOrder) {
          previousRef.text = accumulatedTextStr;
          // reset variable
          accumulatedTextStr = '';

          // Finally, hash it
          this.addHashToRef(previousRef);
        }
      }

      // For each section branch, create a section or return the one we are working on
      // We are returning a reference to the section we are working on since we cannot know
      // what tree depth we are at, we just know for each number in our current ID, we need to create
      // a section object with some data on it. Once it's created, return that sections .section array
      // and use that in our next loop so we can keep going down the tree until there are no section ID's
      // left, then move onto the next section ID in our mapped Section ID object.
      orderAsKeys.forEach((value: number, orderIndex: number) => {
        // Every section ID starts at the root of the tree, so let's return our root array since we know it exists.
        if (orderIndex === 0) {
          currentRef = doc;
        }

        // We are in a child of the section ID, either return our child .section array or create it, then return its .section array.
        // We keep looping until we've created all children.
        currentRef = this.getOrCreateSectionRef(currentRef, value, i);
      });

      previousRef = currentRef;

      if (i === mappedSections.length - 1 && accumulatedTextStr !== '') {
        currentRef.text = accumulatedTextStr;
        this.addHashToRef(currentRef);
      }

      lastOrder = map[i].order;

      // Add our nextId to our reference
      if (this.nodeIdMap[i].nextId) {
        currentRef['nextId'] = this.nodeIdMap[i].nextId;
      }
    }

    return doc;
  }

  /**
   * ref is a Wiki Doc or Wiki Section and checks if it has either a title or text, if it has any of them, add those strings
   * to a variable and at the end, if the variable is not an empty string, hash it as md5 and add it to our object reference.
   *
   * @param ref section object reference
   */
  private addHashToRef(ref: any) {
    let toHash = '';
    // Check each prop we may want to hash to make sure it exists
    // Hashing with the ID to reduce potential collision in the same doc
    if (ref.id) {
      toHash += ref.id;
    }
    if (ref.title) {
      toHash += ref.title;
    }
    if (ref.text) {
      toHash += ref.text;
    }

    // Check if toHash was updated
    if (toHash !== '') {
      const hash = this.createHash(toHash);
      ref['sectionHash'] = hash;
    }
  }

  /**
   * The function will flatten the sections in the doc, and then produce an html string from
   * all those sections and their data where you can display on screen.
   *
   * @param document Entire doc -- returns an html string from the doc sections property.
   */
  public getHTMLViewString(): string {
    let htmlResult = '';
    const document = this.knowledgeDocument;

    if (document.text) {
      htmlResult += document.text;
    }

    // flatten the tree structure
    const sections = KnowledgeUtils.getSectionsAsFlatArray(document.sections);

    // loop through
    sections.forEach((section: KnowledgeDocumentSection) => {
      if (section.title || section.title === '') {
        htmlResult += KnowledgeUtils.addSectionIdToTitleHtml(section.title, section.id);
      }

      if (section.text || section.text === '') {
        htmlResult += section.text;
      }

    });

    return htmlResult;
  }

  /**
   * Takes the doc and creates an HTML string. Difference with this is that each section is wrapped
   * in a custom html element used for highlighting sections and other operations when editing a doc.
   * Once a doc is saved, these tags are sanitized out. They are only used to highlight sections for feedback.
   */
  public getHTMLEditString(): string {
    // If new doc, return empty string since documentDOM was not created in our constructor
    if (!this._documentDOM) {
      return ''
    }
    
    // If existing doc, return the HTML contents as string
    return this._documentDOM.body.innerHTML;
  }

  private createLiveDocumentFromKnowledge(): Document {
    let htmlResult = '';
    const document = this.knowledgeDocument;

    if (document.text) {
      htmlResult += document.text;
    }

    // flatten the tree structure
    const sections = KnowledgeUtils.getSectionsAsFlatArray(document.sections);

    // loop through each section
    sections.forEach((section: KnowledgeDocumentSection) => {
      if (section.title || section.title === '') {
        htmlResult += KnowledgeUtils.addSectionIdToTitleHtml(section.title, section.id);
      }
      if (section.text || section.text === '') {
        htmlResult += section.text;
      }
    });

    return KnowledgeUtils.parseHtmlStringToDocument(htmlResult);
  }

  /**
   * Use this function to determine if a section id is available to use. Pass in a string id and then it will
   * return true or false. Also, this function sets that id as not available now because the caller will always use
   * the id if not in use.
   * @param idToCheck a string section id to check against the map tree.
   */
  private isSectionIdBeingUsed(idToCheck: string): boolean {
    const path = idToCheck.split('_').join('.');
    const tree = this.nodeIdTree;
    // lodash#get - https://lodash.com/docs/4.17.11#get
    // The result will either be the object or undefined. We convert that into a boolean to check if it exists or not.
    const result = get(tree, path);
    const inUse: boolean = !!(result);

    return inUse;
  }

  /**
   * Every ID that we come across through new or existing documents, pass through this function
   * so we can track the highest level ID in the document. Then, when creating new sections in existing
   * documents, or even if the user dumps all the content in the doc and start rewriting it... we will have a new,
   * non existing ID to not mess with ML.
   */
  private trackSectionId(sectionId: string) {
    // Check if this section id does not exist in our tree. If it does, we don't want to reset it
    // because if it's a root section id or something, it will set it to {} and kill all the child objects.
    if (this.isSectionIdBeingUsed(sectionId)) {
      return;
    }

    // Add section id to our tree
    const path = sectionId.split('_').join('.');
    const tree = this.nodeIdTree;
    // lodash = _.set
    set(tree, path, {});

    // Save the id
    this.lastIdCreated = sectionId;
  }

  /**
   * Returns a number that can be used for this path. If no value, returns the next highest base id available.
   * @param path period delimitted object path
   */
  private getNextHighestId(path?: string): number {
    let value: number;
    if (path) {
      const pathResult: number[] = Object.keys(get(this.nodeIdTree, path)).map(n => parseFloat(n));
      if (pathResult && pathResult.length > 0) {
        // if the object was not empty
        value = Math.max(...pathResult) + 1;
      } else {
        // object was empty, doing a Math operation will result in -Infinity, just return 1.
        value = 1;
      }

      return value;
    }

    // return base node result
    const baseResult = Object.keys(this.nodeIdTree).map(n => parseFloat(n));
    if (baseResult && baseResult.length > 0) {
      // if the object was not empty
      value = Math.max(...baseResult) + 1;
    } else {
      // object was empty, doing a Math operation will result in -Infinity, just return 1.
      value = 1;
    }

    return value;
  }

  /**
   * Creates a flat array we can use on the view page as a table of contents
   * @param sections parent wiki document sections
   */
  public createTableOfContents(documentId: string, sections: KnowledgeDocumentSection[]): KnowledgeDocumentTableOfContents {
    const headings: KnowledgeDocumentTableOfContentsItem[] = [];
    const toc: KnowledgeDocumentTableOfContents = {
      documentId,
      headings: headings
    };

    let depth = 0;

    const traverse = (arr: KnowledgeDocumentSection[]) => {
      let i = 0;
      const length = arr.length;

      while (i < length) {
        if (arr[i].title) {
          // Get title from HTML title
          // Create a new div element
          const tempEle = document.createElement("div");
          // Set the HTML content with the providen
          tempEle.innerHTML = arr[i].title;

          headings.push({
            title: tempEle.textContent || tempEle.innerText || "",
            depth: depth,
            sectionId: arr[i].id
          });
        }
        if (arr[i].sections) {
          depth++;
          traverse(arr[i].sections);
        }
        i++;
      }

      if (depth > 0) { depth--; }
    };

    traverse(sections);

    return toc;
  }

  /**
   * Parses the html and queries to get all <img> src's and then returns them. We use this when a document is getting updated to check
   * if any images have changed from the updated doc to what we first got when we loaded the doc from the server. This is a better
   * way to check for images changes than doing it on the editor event, as the editor also supports Undo and we cannot listen to what
   * HTML was put back in on an undo, so we just do the work and check for image changes after the document updates.
   *
   * @param wikiDocumentHtml - wiki document
   */
  getAllDocumentImageSrc(docHtml: string): string[] {
    const parsed = new DOMParser().parseFromString(docHtml, 'text/html');

    // Search for an images
    // Returns nodeList of matches
    const nodeList = parsed.querySelectorAll('img');
    const matches = [];

    // Convert the NodeList to an Array
    // TODO: We should updated the convertNodeListtoArray class function to not have the switch statement in it, so we can used it.
    if (nodeList.length > 0) {
      let i = 1;
      while (i <= nodeList.length) {
        matches.push(nodeList[i - 1]);
        i++;
      }
    }

    // Just return the src attributes
    return matches.map(item => item.src);
  }

}
