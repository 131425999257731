export class Node {
    data: any;                          // Data for the node
    parent: Node;                       // Parent of the node
    children: Node[];                   // Children of the node

    constructor(data) {
        this.data = data;
        this.parent = null;
        this.children = [];
    }
}
