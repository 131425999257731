import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { KeysService } from '@eva-core/encryption/keys.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { ProcessNotification } from '@eva-model/process/processNotifications';
import { take } from 'rxjs/operators';
import { AuthService } from '@eva-core/auth.service';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { ProcessFlowGroupRequest, ProcessFlowResponse } from '@eva-model/process/process-flow';

@Injectable({
  providedIn: 'root'
})
export class WorkflowNotificationsService {
  private _EVA_ENDPOINT = environment.firebaseFunction.endpoint; // the eva firebase project endpoint

  groupNotificationRef$: Observable<any>;

  notificationResults = [];   // Array of process notifications ready for taking action to be moved to process page (to "Action")
  // Limited number of process notifications ready for "Action" per group.
  notificationResultsPerGroupLimit = environment.notificationResultsPerGroupLimit;
  workingOnProcesses = [];    // Array of process notifications ready to be acknowledged (to "Accept")
  workingOnProcessesLimit = environment.workingOnProcessesLimit;   // Limited number of process notifications ready for "Accept"
  myEndedProcesses = [];

  dynamicInteractionsEndpoint = environment.endPoints.DYNAMIC_INTERACTIONS.url;
  private evaEndpoint = environment.firebaseFunction.endpoint; // the eva firebase project endpoint
  acknowledgeProcessEndpoint = this.dynamicInteractionsEndpoint + 'acknowledgeProcess';
  doneProcessEndPoint = this.dynamicInteractionsEndpoint + 'doneProcess';


  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private arrayUtils: ArrayUtilsService,
    private evaGlobalService: EvaGlobalService,
    private keysService: KeysService,
    private http: HttpClient,
    private authService: AuthService,
    private perf: AngularFirePerformance,
  ) {
      this.authService.user.subscribe(user => {
        if (!user) {
          this.notificationResults = [];
          this.workingOnProcesses = [];
          this.myEndedProcesses = [];
        } else {
          this.init();
        }
      });
  }

  /**
   * Initializes the service required observables
   */
  init(): void {

  }

  /**
   * Setup workflow notifications
   */
  public getWorkflowNotifications(): void {
    this.setupGroupNotification();
    this.setupMyWorkingOnProcesses();
    this.setupEndedProcessesNotification();
  }

  /**
   * Removes specific notification record from notifications results array
   * notificationResults :: Array to keep processes which are waiting to get accepted by user
   * @param notification The notification which is supposed to be removed
   */
  dropNotificationResult(notification: ProcessNotification): void {
    const notificationIdx = this.notificationResults.map(n => n.processId).indexOf(notification.processId);
    if ( notificationIdx !== -1 ) this.notificationResults.splice(notificationIdx, 1);
  }

  /**
   * Acknowledges a process through backend
   *
   * @param groupPublicKey :: Group public key
   * @param processId      :: Process Id
   */
  async acknowledgeProcess(groupPublicKey: string, processId: string): Promise<any> {
    try {
      // TODO :: fix the promise to use userPublicKey from eva global
      const userPublicKey = await this.keysService.getUserPublicKey();

      const bodyData = {
        groupPublicKey: groupPublicKey,
        processId: processId,
        acknowledgerPublicKey: userPublicKey
      };

      const options = {
        headers: this.headers
      };

      await this.http.post(this.acknowledgeProcessEndpoint, bodyData, options).pipe(
        trace('workflow-notifications-acknowledgeProcess'),
        take(1)
      ).toPromise();

      await this.setupMyWorkingOnProcesses(); // get the processes that are done.
      return;
    } catch (err) {
      console.error(err);
    }

  }

  /**
   * Set the process to "done" status through backend endpoint
   *
   * @param processId :: Process Id
   */
  async doneProcess(processId: string, process: any): Promise<any> {
    // if (process.status === 'Done') this.processDashboardEmailNotification(process);
    try {
      const bodyData = {
        processId: processId,
        acknowledgerPublicKey: this.evaGlobalService.userPublicKey
      };

      const options = {
        headers: this.headers
      };

      await this.http.post(this.doneProcessEndPoint, bodyData, options).pipe(
        trace('workflow-notifications-doneProcess'),
        take(1)
      ).toPromise();
      await this.setupEndedProcessesNotification(); // get the processes that are done.
      return;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Sets up process level notifications for user based on group membership
   */
  async setupGroupNotification(): Promise<void> {
    try {

      // clear the notification results.
      if ( this.notificationResults ) this.notificationResults.length = 0;
      else this.notificationResults = [];
      //#region Validate user group(s) existence
      if ( !(this.evaGlobalService.userGroupsPublicKeys &&
          Array.isArray(this.evaGlobalService.userGroupsPublicKeys) &&
          this.evaGlobalService.userGroupsPublicKeys.length > 0) ) {
        return;
      }
      //#endregion

      //#region Filter the groups which user excepted to get notification from them
      const userNotificationGroupsPublicKeys =
        this.evaGlobalService.userGroupsPublicKeys.filter(
        ( el ) =>
          this.evaGlobalService.userNotificationExceptedGroups
          ? this.evaGlobalService.userNotificationExceptedGroups.indexOf(el) === -1
          : true );
      //#endregion

      //#region Check to drop process flow through group from notifications if user is not a permanent member of the
      // process flow through group

      // Condition to validate if there is any process flow through group
      let isProcessFlowThroughGroup = this.isProcessFlowThroughGroup();

      //#region Try to get "process flow through" group details if not available yet, EVA for ATB expect to have details about the group
      if ( !isProcessFlowThroughGroup  ) {
        await this.evaGlobalService.processFlowThroughGroupInConfigForceFetch();
      }

      isProcessFlowThroughGroup = this.isProcessFlowThroughGroup();
      //#endregion

      if ( isProcessFlowThroughGroup ) {

        // Check if there is permanent member(s) assigned to the flow through group
        const prcsFlowThroughMemberPublicKeys = this.evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys;
        if ( Array.isArray(prcsFlowThroughMemberPublicKeys) && prcsFlowThroughMemberPublicKeys.length > 0 ) {

          // Check if user is member of the flow through group
          const prcsFlowThroughGroupIndex =
            userNotificationGroupsPublicKeys.indexOf(this.evaGlobalService.processFlowThroughGroupConfig.groupPublicKey);

          if ( prcsFlowThroughGroupIndex !== -1 ) {
            // Check if user is a permanent member of flow through group otherwise drop flow through group to exclude related notifications
            const userInPrcsFlowThroughMembersIndex = prcsFlowThroughMemberPublicKeys.indexOf(this.evaGlobalService.userPublicKey);
            if ( userInPrcsFlowThroughMembersIndex === -1 ) {
              userNotificationGroupsPublicKeys.splice(prcsFlowThroughGroupIndex, 1);
            }
          }
        }

      }
      //#endregion

      // get the unacknowledgedProcesses
      const url = this._EVA_ENDPOINT + '/getGroupUnacknowledgedProcess';

      const processFlowRequest: ProcessFlowGroupRequest = {
        processGroupPublicKeys: userNotificationGroupsPublicKeys
      };

      let processFlowResponse: ProcessFlowResponse;
      if (userNotificationGroupsPublicKeys.length > 0) {
        // run the query and get the results.
        // create the request.
        processFlowResponse = await this.http.post<ProcessFlowResponse>(url, processFlowRequest).pipe(
          take(1),
          trace('workflow-notifications-setupGroupNotification')
        ).toPromise();
      }

      if (processFlowResponse && processFlowResponse.processFlows && processFlowResponse.processFlows.length > 0) {
        this.notificationResults = processFlowResponse.processFlows;
        this.notificationResults = this.arrayUtils.arrayObjectCustomSort(this.notificationResults, 'createdAt');
      }

    } catch ( err ) {

      throw err;

    }
  }

  /**
   * Check existence of Process Flow Through Group configuration, group public key, and its permanent members
   */
  isProcessFlowThroughGroup() {
    return this.evaGlobalService.processFlowThroughGroupConfig &&
      this.evaGlobalService.processFlowThroughGroupConfig.groupPublicKey &&
      this.evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys;
  }

  /**
   * Generates the response to the processes which are have had action & already ended,
   * then add them to the "ended processes" array of the service
   */
  public async setupEndedProcessesNotification(): Promise<void> {

    if ( !this.evaGlobalService.userPublicKey ) {
      if ( this.myEndedProcesses ) this.myEndedProcesses.length = 0;
      else this.myEndedProcesses = [];
      return;
    }

    // clear the ended processes
    if ( this.myEndedProcesses ) this.myEndedProcesses.length = 0;
    else this.myEndedProcesses = [];

    // get the process flows
    const url = this._EVA_ENDPOINT + '/getUserEndedProcess';

    const paramsOptions = new HttpParams().set('destinationPublicKey', this.evaGlobalService.userPublicKey);
    const options = {
      params: paramsOptions
    };
    // set the working on processes
    const myEndedProcessesResponse = await this.http.get<ProcessFlowResponse>(url, options).pipe(
      take(1),
      trace('workflow-notifications-setupEndedProcessesNotification')
    ).toPromise();
    if (myEndedProcessesResponse && myEndedProcessesResponse.processFlows && myEndedProcessesResponse.processFlows.length > 0) {
      this.myEndedProcesses = myEndedProcessesResponse.processFlows;
    }


  }

  /**
   * Generate processes which are acknowledged and add the processes to the "working on processes" list of service
   */
  public async setupMyWorkingOnProcesses(): Promise<void> {
    // make sure the user has a public key
    if ( !this.evaGlobalService.userPublicKey ) {
      if ( this.workingOnProcesses ) this.workingOnProcesses.length = 0;
      else this.workingOnProcesses = [];
      return;
    }

    // clear the working on processes
    if ( this.workingOnProcesses ) this.workingOnProcesses.length = 0;
    else this.workingOnProcesses = [];

    // get the process flows
    const url = this._EVA_ENDPOINT + '/getUserWorkingOnProcess';

    const paramsOptions = new HttpParams().set('acknowledgerPublicKey', this.evaGlobalService.userPublicKey);
    const options = {
      params: paramsOptions
    };
    // set the working on processes
    const workingOnProcessesResponse = await this.http.get<ProcessFlowResponse>(url, options).pipe(
      take(1),
      trace('workflow-notifications-setupMyWorkingOnProcesses')
    ).toPromise();
    if (workingOnProcessesResponse && workingOnProcessesResponse.processFlows && workingOnProcessesResponse.processFlows.length > 0) {
      this.workingOnProcesses = workingOnProcessesResponse.processFlows;
    }
  }
}
