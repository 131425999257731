import { NotificationWithGroupNames } from '@eva-model/notificationWithGroupNames';
import { Group } from '@eva-model/group';
import { Injectable, OnDestroy } from '@angular/core';
import { ChildGroupInvitation } from '@eva-model/childGroupInvitation';
import { SigningService } from '@eva-core/signing.service';
import { environment } from '@environments/environment';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import { GroupTransactionStructure } from '@eva-core/blocktransactionstructures/grouptransactionstructure';
import firebase from 'firebase/compat/app';
import { LogService } from '@eva-core/log/log.service';
import { LogLevel, EntityType, Log } from '@eva-model/log';
import { filter, take } from 'rxjs/operators';
import { EVAGenericSignedObject } from '@eva-model/signing/signing';
import { DeleteChildGroupInvitationResponse } from '@eva-model/group-requests/group-requests';
import { BlockChainTransactionResponse } from '@eva-model/blockchain/transaction';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class ChildGroupInvitationService implements OnDestroy {
  private _firebaseFunctionsEndpoint: string = environment.firebaseFunction.endpoint; // the project base endpoint.
  // the delete child invitation endpoint.
  private _deleteChildGroupInvitationEndpoint: string = this._firebaseFunctionsEndpoint + '/deleteChildGroupInvitation';
  private _allChildGroupInvitationsSub: Subscription; // the subscription for all child group invitations

  // flat array for notification page
  allChildGroupInvitations: NotificationWithGroupNames[] = []; // this contains all notifications for children groups.

  constructor(private _signingService: SigningService,
    private _firestoreService: FirestoreService,
    private _http: HttpClient,
    public evaGlobalService: EvaGlobalService,
    private _logService: LogService,
    private perf: AngularFirePerformance) {
      this.init();
    }

  /**
   * Initializes the child group invitations
   */
  init() {
    this.evaGlobalService.userGroupsChange$.pipe(
      filter(change => !!change)
    ).subscribe(() => this.setAllChildGroupInvitations());
  }

  //#region lifecycle hooks

  /**
   * Unsubscribes the _allChildGroupInvitationSub subscription.
   */
  ngOnDestroy() {
    if (this._allChildGroupInvitationsSub) {
      this._allChildGroupInvitationsSub.unsubscribe();
    }
  }

  //#endregion

  //#region Sending invitation

  /**
   * Sends the invitation.
   *
   * @param message - message to be sent with the invitation
   * @param childGroupPublicKey - the public key of the group receiving the invitation
   * @param parentGroupPublicKey - the public key of the group sending the invitation
   */
  async sendInvitation(
    message: string,
    childGroupPublicKey: string,
    parentGroupPublicKey: string
  ): Promise<firebase.firestore.DocumentReference> {
    try {
      if (this.validateInvitation(childGroupPublicKey, parentGroupPublicKey)) {
        const invitation = new ChildGroupInvitation(
          message,
          childGroupPublicKey,
          parentGroupPublicKey
        );
        const signedInvitation = await this.signInvitation(invitation);
        return await this.createInvitation(signedInvitation);
      } else {
        await this.logToFirebase('The child group invitation being sent is invalid. '
          + 'childGroupPublicKey = ' + childGroupPublicKey + ', '
          + 'parentGroupPublicKey = ' + parentGroupPublicKey);
        return Promise.reject('Invalid child group invitation');
      }
    } catch (err) {
      await this.logToFirebase('Error sending child group invitation: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Signed the child group invitation.
   *
   * @param invitation - The child group invitation to be signed.
   */
  private async signInvitation(invitation: ChildGroupInvitation): Promise<EVAGenericSignedObject> {
    try {
      const invitationData = this._signingService.getObjectToSignStructure(invitation.getDataForSigning());
      return await this._signingService.signObject(invitationData);
    } catch (err) {
      await this.logToFirebase('There was an error signing the invitation: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Saves the signed child group invitation to the database.
   *
   * @param signedInvitation - The child group invitation to be saved to database.
   */
  private async createInvitation(signedInvitation: any): Promise<firebase.firestore.DocumentReference> {
    try {
      const groupInvitedPublicKey = signedInvitation.data.unencryptedData.childGroupPublicKey;
      return await this._firestoreService.add(
        this._firestoreService.col(`ChildGroupInvitations/${groupInvitedPublicKey}/Invites`),
        signedInvitation);
    } catch (err) {
      await this.logToFirebase('Error creating child group invitation: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Validates that the parent group is a dynamic data group
   * and that the child group is a normal or invitation gourp.
   * Returns true if this is the case, false otherwise.
   *
   * @param childGroupPublicKey - the public key of the group receiving the invitation
   * @param parentGroupPublicKey - the public key of the group sending the invitation
   */
  private validateInvitation(childGroupPublicKey: string, parentGroupPublicKey: string): Boolean {
    const childGroup = this.evaGlobalService.userGroups.filter(group => group.groupPublicKey === childGroupPublicKey)[0];
    const parentGroup = this.evaGlobalService.userGroups.filter(group => group.groupPublicKey === parentGroupPublicKey)[0];

    if (parentGroup.groupType !== environment.blockConfig.types.groups.types.dynamicData) {
      return false;
    }

    if (childGroup.groupType !== environment.blockConfig.types.groups.types.normal
      && childGroup.groupType !== environment.blockConfig.types.groups.types.invitation) {
        return false;
      }

    return true;
  }

  //#endregion Sending invitation

  //#region Accepting invitation

  /**
   * Signs the acceptance and sends it to the block chain
   *
   * @param invitation - The invitation object as pulled from the database
   */
  async acceptInvitation(invitation: any, groupType: string): Promise<any> {
    try {
      const result = await this.sendAcceptance(invitation, groupType);
      if (result.accepted) {
        const childGroupPublicKey = invitation.data.unencryptedData.childGroupPublicKey;
        await this.deleteInvitation(invitation.id, childGroupPublicKey);
      } else {
        await this.logToFirebase('There was a problem accepting the child group invitation. '
          + 'invitation.id = ' + invitation.id);
        return Promise.reject('There was a problem accepting the child group invitation.');
      }
    } catch (err) {
      await this.logToFirebase('Error accepting invitation. '
        + 'invitation.id = ' + invitation.id + ', '
        + 'Error = ' + err);
      return Promise.reject('There was an error accepting the invitation.');
    }
  }

  /**
  * Calls the blockchain (which in turn calls dynamic forms) to add the child group to the parent group and visa-versa.
  *
  * @param signedInvitationAcceptance - A signed copy of the invitation acceptance
  */
  private async sendAcceptance(invitation: any, groupType: string): Promise<BlockChainTransactionResponse> {
    // Get data:

    const parentGroupPublicKey = invitation.data.unencryptedData.parentGroupPublicKey;
    const childGroupPublicKey: string = invitation.data.unencryptedData.childGroupPublicKey;
    const childGroupName: string = invitation.childGroupName;

    // Create a copy of invitation that doesn't have id or sending:
    const invitationCopy = Object.assign({}, invitation);
    delete invitationCopy.id;
    delete invitationCopy.accepting;
    delete invitationCopy.childGroupName;
    delete invitationCopy.parentGroupName;

    const blockTransaction = new GroupTransactionStructure();
    blockTransaction.setFromValues(childGroupPublicKey, childGroupName, groupType, false, null, null,
      null, invitation, parentGroupPublicKey, null, null);

    try {
      const evaSignedObject = this._signingService.getEVASignedObjectDataStructure(null, blockTransaction.groupTransactionBase);
      const unsignedTransaction = this._signingService.getObjectToSignStructure(evaSignedObject);
      const signedTransaction = await this._signingService.signObject(unsignedTransaction);
      return await this._signingService.sendObjectToEndPoint(signedTransaction);
    } catch (err) {
      await this.logToFirebase('Error accepting invitation: '
        + 'invitation.id = ' + invitation.id
        + 'Error = ' + err);
      return Promise.reject('There was an problem accepting the invitation:');
    }
  }

  /**
  * Calls the Firebase Functions to delete the invitation.
  *
  * @param invitationId - the id of the invitation
  * @param childGroupPublicKey - the public key of the child group
  */
  deleteInvitation(invitationId: string, childGroupPublicKey: string): Promise<DeleteChildGroupInvitationResponse> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const body = {
      invitationId: invitationId,
      childGroupPublicKey: childGroupPublicKey
    };

    return this._http.post<DeleteChildGroupInvitationResponse>(this._deleteChildGroupInvitationEndpoint, body, options)
    .pipe(
      trace('child-group-invitation-deleteInvitation')
    ).toPromise();
  }

  //#endregion Accepting invitation

  //#region notifications

  /**
   * Retrieves the child group invitations for the user's groups
   * and adds them to a flat array to be referenced on the page.
   */
  async setAllChildGroupInvitations(): Promise<void> {
    // userGroups in evaGlobalService may not be ready at this point:
    if ( !(this.evaGlobalService.userGroups &&
        Array.isArray(this.evaGlobalService.userGroups) &&
        this.evaGlobalService.userGroups.length > 0) ) {
          return;
    }

    // filtering and returning an array with normal or invitation groups:
    const normalAndInviteGroups = this.evaGlobalService.userGroups.filter(group =>
      group.groupType === environment.blockConfig.types.groups.types.normal
      || group.groupType === environment.blockConfig.types.groups.types.invitation);

    // For each of the user's groups, get any invitations that might exist:
    const obColl = [];
    normalAndInviteGroups.forEach(group => {
      const groupInviteCol = `ChildGroupInvitations/${group.groupPublicKey}/Invites`;
      obColl.push(this._firestoreService.colWithIds$(groupInviteCol));
    });

    // Add invitations to a flat array to be referenced from the notification page:
    this._allChildGroupInvitationsSub = combineLatest(obColl)
    .pipe(
      trace('child-group-invitation-setAllChildGroupInvitations')
    )
    .subscribe(async childGroupInvo => {
      this.allChildGroupInvitations = [];
      try {
        childGroupInvo.forEach(childNotifications => {
          if (childNotifications && Array.isArray(childNotifications) && childNotifications.length > 0) {
            childNotifications.forEach(async groupNotification => {
              const notificationWithGroupNames: NotificationWithGroupNames = await this.addGroupNamesToNotification(groupNotification);
              if (notificationWithGroupNames.childGroupName.length > 0
                && notificationWithGroupNames.parentGroupName.length > 0) {
                  this.allChildGroupInvitations.push(notificationWithGroupNames);
              } else {
                await this.deleteInvitation(notificationWithGroupNames.id,
                  groupNotification.data.unencryptedData.childGroupPublicKey);
              }
            });
          }
        });
      } catch (err) {
        await this.logToFirebase('Error occurred when trying to load child group invitations. '
          + 'Error: ' + err);
      }
    });
  }

  /**
   * Adds the child and parent group names to the notification.
   *
   * @param notification The notification to add group names to.
   */
  async addGroupNamesToNotification(notification: any): Promise<NotificationWithGroupNames> {
    let childGroupName = '';
    let parentGroupName = '';
    const childGroupPublicKey = notification.data.unencryptedData.childGroupPublicKey;
    const parentGroupPublicKey = notification.data.unencryptedData.parentGroupPublicKey;

    // Get child group name
    if (childGroupPublicKey) {
      const childGroup = this.evaGlobalService.userGroups.filter(group =>
        group.groupPublicKey === childGroupPublicKey);
      childGroupName = childGroup[0].groupName;
    } else {
      // childGroupName remains ''
    }

    // get parent group name
    if (parentGroupPublicKey) {
      // First, assume parent group is in userGroups
      const parentGroup = this.evaGlobalService.userGroups.filter(group =>
        group.groupPublicKey === parentGroupPublicKey);
      if (parentGroup && Array.isArray(parentGroup) && parentGroup.length > 0) {
        parentGroupName = parentGroup[0].groupName;
      } else {
        // If parent is not in userGroups, get it from Dynamic Forms:
        let group: Group;
        try {
          group = await this._firestoreService.col('GroupSigningKeys')
            .doc<Group>(parentGroupPublicKey)
            .valueChanges()
            .pipe(
              trace('child-group-invitation-addGroupNamesToNotification'),
              take(1)
            )
            .toPromise();
          parentGroupName = group.groupName;
        } catch (err) {
          // parentGroupName remains ''
        }
      }
    } else {
      // parentGroupName remains ''
    }

    // Return notification with group names:
    const notificationWithGroupNames: NotificationWithGroupNames = Object.assign(notification,
      { childGroupName, parentGroupName });
    return notificationWithGroupNames;
  }

  //#endregion notifications

  /**
   * Uses Log service to log an error messages to firebase.
   *
   * @param message - the message to log
   */
  async logToFirebase(message: string): Promise<void> {
    if (this.evaGlobalService && this.evaGlobalService.userId) {
      const log = new Log(
        LogLevel.Error,
        EntityType.other,
        null,
        message,
        this.evaGlobalService.userId);
      try {
        await this._logService.error(log);
      } catch (err) {
        console.log('The following message could not be logged to firebase:', message);
      }
    } else {
      console.log('The following message could not be logged to firebase:', message);
    }
  }
}
