import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { scan, tap, map, distinct, take } from 'rxjs/operators';
import { QueryConfig } from '@eva-model/get-chat';

/**
 * Code Reference: https://angularfirebase.com/lessons/infinite-scroll-firestore-angular/
 */
@Injectable()
export class GetChatService {
  // Source data
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);
  private query: QueryConfig;
  // Observable data
  data: Observable<any>;
  loading: Observable<boolean> = this._loading.asObservable();
  constructor(private afs: AngularFirestore) { }

  /**
   * This function initializes the chat service.
   * Initial query sets options and defines the Observable.
   *
   * @param path Collection being queried
   * @param field object property to order the data by
   * @param options Additional options for the query. This overrides the defaults.
  */
  init(path: string, field: string, options?: any): void {
    this.query = {
      path,
      field,
      limit: 10,
      reverse: false,
      prepend: false,
      ...options
    };
    const first = this.afs.collection(this.query.path, ref => {
      return ref
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit);
    });
    this.mapAndUpdate(first, 'backward');
    // Create the observable array for consumption in components
    this.data =
    this._data.asObservable()
      .pipe(
        map(_stuff => _stuff.filter(stuff => stuff.createdAt !== null)),
        scan((acc, val) => {
          const temp = val.concat(acc).sort();
          return temp;
        }),
        distinct()
      );
  }

  /**
   * This function paginates through DB collection
   *
   * @param direction Direction of the pagination - 'forward', 'backward'
   */
  more(direction: string): void {
    const cursor = this.getCursor(direction);
    const more = this.afs.collection(this.query.path, ref => {

      if (direction === 'forward') {

        return ref
          .orderBy(this.query.field, this.query.reverse ? 'asc' : 'desc')
          .startAfter(cursor)
          .limit(this.query.limit);

      } else if (direction === 'backward') {

        return ref
          .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
          .startAfter(cursor)
          .limit(this.query.limit);
      }
    });
    this.mapAndUpdate(more, direction);
  }

  /**
   * This function determines the document snapshot to paginate query
   *
   * @param direction Direction of the pagination - 'forward', 'backward'
   */
  private getCursor(direction: string) {
    const current = this._data.value;
    if (current.length) {
      return (direction === 'backward') ?
        current[0].doc :
        current[current.length - 1].doc;
    }
    return null;
  }

  /**
   * This function maps the snapshot to usable format the updates source.
   *
   * @param collection Collection being queried
   * @param direction Direction of the pagination - 'forward', 'backward'
   */
  private mapAndUpdate(collection: AngularFirestoreCollection<any>, direction: string) {
    if (this._loading.value) {
      return;
    }
    this._loading.next(true);
    // Map snapshot with document reference (needed for cursor)
    return collection.snapshotChanges()
      .pipe(
        tap(arr => {
          let values = arr.map(snap => {
            const data = snap.payload.doc.data();
            const doc = snap.payload.doc;
            return {
              ...data,
              doc
            };
          }).filter(objects => objects.doc.data().createdAt !== null);
          // If prepending, reverse the batch order
          values = direction === 'backward' ? values.reverse() : values;
          // update source with new values, done loading
          // if no more values, mark it as done
          if (!!values.length) {
            this._data.next(values);
          } else {
            console.warn('Need some data to use to disable the buttons when end is hit');
          }
          this._loading.next(false);
        }),
        take(1)
    ).subscribe();
  }

}
