import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ChatEntity, ChatEntityAuthor } from '@eva-model/chat/chat';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent {

  public author = {
    user: ChatEntityAuthor.User,
    eva: ChatEntityAuthor.EVA
  };

  @Input() chatEntity: ChatEntity;

  constructor() { }

}
