import { Injectable } from '@angular/core';
import { DeviceService } from '@eva-services/device/device.service';
import { SubtleEncryptionService } from '@eva-core/encryption/subtle-encryption.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { HttpClient } from '@angular/common/http';
import { EvaKey } from '@eva-model/evaKey';
import { Utils } from '@eva-core/encryption/utils';
import * as Elliptic from 'elliptic';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(
    private _deviceSrv: DeviceService,
    private _SubtleEncryptSrv: SubtleEncryptionService,
    private _evaGlobalSrv: EvaGlobalService,
    private _http: HttpClient
  ) { }

  generateMnemonic(): Observable<any> {
    const url = environment.firebaseFunction.endpoint + '/generateMnemonic';

    const bodyData = { 'uid': this._evaGlobalSrv.userId, 'userPassword': 'userpassword' };
    return this._http.post<any>(url, bodyData);
  }

  getUserEncryptionJWk(userSecurityKeys: EvaKey, keyType: "private" | "public") {

    try {

      const keys: Elliptic.ec.KeyPairOptions = {
        priv: Buffer.from(userSecurityKeys.encryptionPrivateKey, 'hex'),
        privEnc: null,
        pub: null,
        pubEnc: null
      };

      const keyOperations = []; // ["deriveKey", "deriveBits"];   // ['encrypt', 'decrypt']

      const jwk = this._SubtleEncryptSrv.generateJWKFromEllipticPairHex( keys, 'P-256', keyOperations, keyType);
      return jwk;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }

  generateUserSecurityKeys(encMnemonic: string, signMnemonic: string) {
    const url = environment.firebaseFunction.endpoint + '/generateUserSecurityKeys';

    const bodyData = {
      'uid': this._evaGlobalSrv.userId,
      'encryptionMnemonic': encMnemonic,
      'signingMnemonic': signMnemonic
    };

    return this._http.post<any>(url, bodyData);
  }

  async encCryptoKey() {
    try {

      const encryptionMnemonic = await this.generateMnemonic().toPromise();
      const signingMnemonic = await this.generateMnemonic().toPromise();
      const userSecurityKey: EvaKey =
        await this.generateUserSecurityKeys(encryptionMnemonic.mnemonic, signingMnemonic.mnemonic).toPromise();
      const encPublicKeyJwk = this.getUserEncryptionJWk(userSecurityKey, 'public');
      const encPublicKeyCryptoKey = await this._SubtleEncryptSrv.importKey('jwk', encPublicKeyJwk, {name: 'ECDH', namedCurve: 'P-256'});

      const deviceCryptoKey = await this._deviceSrv.getDeviceCryptoKey();
      const algorithm = {name: 'ECDH', public: encPublicKeyCryptoKey};
      const encCryptoKey: CryptoKey = await this._SubtleEncryptSrv.deriveEncryptionKey(algorithm, deviceCryptoKey, false);

      //#region For testing purpose
      const cryptoIv = this._SubtleEncryptSrv.getCryptoIv();

      const encryptedUserSecurityKey = await this._SubtleEncryptSrv.encryptObject(encCryptoKey, cryptoIv, userSecurityKey);
      // console.log(this._SubtleEncryptSrv.ArrayBufferToString(encryptedUserSecurityKey));

      let unEncryptedUserSecurityKey = await this._SubtleEncryptSrv.decryptToObject(encCryptoKey, cryptoIv,  encryptedUserSecurityKey);
      unEncryptedUserSecurityKey = JSON.parse(this._SubtleEncryptSrv.ArrayBufferToString(unEncryptedUserSecurityKey));
      // console.log(this._SubtleEncryptSrv.ArrayBufferToString(unEncryptedUserSecurityKey));

      console.log( JSON.stringify(userSecurityKey) === JSON.stringify(unEncryptedUserSecurityKey) );
      //#endregion

      return encCryptoKey;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }

}
