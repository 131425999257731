import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

const ANIMATION_CLASS_NAME = 'unseen-count-animation';
const SOUND_URL = 'assets/new-message-pop.mp3';

@Directive({
  selector: '[appChatUnseenCount]'
})
export class ChatUnseenCountDirective implements OnChanges {

  @Input() appChatUnseenCount: number;

  constructor(private el: ElementRef) {
    this.toggleClass();
  }

  ngOnChanges() {
    this.toggleClass()
  }

  private toggleClass(): void {
    if (this.appChatUnseenCount > 0) {
      // If the count has changed, we remove the class, wait and then re-add class. This makes the css animation replay from beginning.
      this.el.nativeElement.classList.remove(ANIMATION_CLASS_NAME);
      const audio = new Audio(SOUND_URL);
      audio.play();
      setTimeout(() => {
        this.el.nativeElement.classList.add(ANIMATION_CLASS_NAME);
      }, 50); 
    } else {
      this.el.nativeElement.classList.remove(ANIMATION_CLASS_NAME);
    }
  }

}
