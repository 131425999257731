<div class="spacer-container"></div>
<div class="full-width-container">
  <p-multiSelect
    [options]="userWorkflowDestinationGroups"
    [(ngModel)]="selectedGroups"
    [panelStyle]="{minWidth:'100%'}"
    filter="false" optionLabel="groupName"
    defaultLabel="Pick one or many group destination"
    (onChange)="grpSelectionOnChange($event)">

  </p-multiSelect>
</div>

<div class="full-width-container">
  <p-tree [value]="destinationTreeNodes" 
    selectionMode="checkbox" [(selection)]="selectedDestionations"
    [propagateSelectionUp]="false" [propagateSelectionDown]="false"
    (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnSelect($event)">

  </p-tree>
</div>
