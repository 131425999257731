import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { Pair, IdMapWorkflowInteractionMapDialogResult } from '@eva-model/idMap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-id-map-workflow-interaction-map-dialog',
  templateUrl: './id-map-workflow-interaction-map-dialog.component.html',
  styleUrls: ['./id-map-workflow-interaction-map-dialog.component.scss']
})
export class IdMapWorkflowInteractionMapDialogComponent implements OnDestroy {

  mapInteractions: any[] = [];                  // List of imported interactions selected by user for mapping
  newInteractions: any[] = [];                  // List of imported interactions selected by user as new
  pairs: Pair[] = [];                           // Array containing pairs of interactions mapped by the user
  isLinear = true;                              // Whether the MatHorizontalStepper is linear or not
  isWaiting = false;                            // Whether fetching interactions for selected user group for current environment or not
  userGroups: any[] = [];                       // List of user groups from current environment
  entities: any[] = [];                         // List of interactions for the selected group from current environment
  entityVersions: any[] = [];                   // List of versions for the selected interaction from current environment
  selectedNewInteractionAsNew: any;             // Imported Interaction selected from "new" section

  editGroupPublicKey: string;                   // Public key of the group selected by user from current environment
  editGroup: any;                               // Group selected by user from current environment
  editGroupName: string;                        // Name of the group selected by user from current environment
  editEntityId: string;                         // Id of the interaction selected by user from selected user group from current environment
  editEntity: any;                              // Interaction selected by user from current environment
  editEntityName: string;                       // Name of the interaction selected by user from current environment
  editEntityVersion: number;                    // Version of the interaction selected by user from current environment
  entitySubscription: Subscription;           // Subscription for fetching the interactions by user groups
  allMapSelected = false;                       // Whether all imported interactions are selected as mapped or not
  allNewSelected = false;                       // Whether all imported interactions are selected as new or not

  selectedStepperIndex = 0;                     // Index of the selected stepper from MatHorizontalStepper

  selectUserGroupFormGroup: UntypedFormGroup;          // Form Group for selecting user group
  selectEntityFormGroup: UntypedFormGroup;             // Form Group for selecting user interaction
  selectVersionFormGroup: UntypedFormGroup;            // Form Group for selecting version for selected user interaction

  constructor(public dialogRef: MatDialogRef<IdMapWorkflowInteractionMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public evaGlobalService: EvaGlobalService,
    private dynFormService: DynamicInteractionsService,
    private _formBuilder: UntypedFormBuilder) {
    this.selectUserGroupFormGroup = this._formBuilder.group({
      selectGroupCtrl: ['', Validators.required]
    });
    this.selectEntityFormGroup = this._formBuilder.group({
      selectEntityCtrl: ['', Validators.required]
    });
    this.selectVersionFormGroup = this._formBuilder.group({
      selectVersionCtrl: ['', Validators.required]
    });

    this.mapInteractions = dialogData.mapInteractions;
    this.newInteractions = dialogData.newInteractions;
    this.userGroups = dialogData.userGroups;
  }

  ngOnDestroy() {
    if (this.entitySubscription) {
      this.entitySubscription.unsubscribe();
    }
  }

  /**
   * This function resets the dialog box to initial state
   *
   * @param stepperCtrl Mat Horizontal Stepper
   * @param userGroupForm Form Group
   * @param entityForm Form Group
   * @param versionForm Form Group
   */
  onReset(stepperCtrl: MatStepper, userGroupForm: UntypedFormGroup, entityForm: UntypedFormGroup, versionForm: UntypedFormGroup): void {
    this.mapInteractions.forEach(interaction => {
      interaction.selected = false;
    });
    this.newInteractions.forEach(interaction => {
      interaction.selected = false;
      interaction.groupChanged = false;
    });
    this.pairs = [];
    this.pairs.length = 0;

    this.editGroupPublicKey = null;
    this.editGroup = null;
    this.editGroupName = null;
    this.editEntityId = null;
    this.editEntity = null;
    this.editEntityName = null;
    this.editEntityVersion = null;
    this.selectedNewInteractionAsNew = null;
    this.allMapSelected = false;
    this.allNewSelected = false;

    if (stepperCtrl) stepperCtrl.reset();
    if (userGroupForm) userGroupForm.reset();
    if (entityForm) entityForm.reset();
    if (versionForm) versionForm.reset();
  }

  /**
   * This function validates if all the selected interactions are paired
   */
  isValid(): boolean {
    return this.pairs.filter(pair => !pair.old).length === 0
      && this.pairs.length === this.mapInteractions.length
      && this.newInteractions.filter(interaction => !interaction.groupChanged).length === 0;
  }

  /**
   * This function checks if any new interaction is selected
   */
  isNewSelected(): boolean {
    if (this.pairs[0] && this.pairs[0].old) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * This function returns the group name from the group public key
   *
   * @param groupPublicKey Public key of the group
   */
  getGroupNameFromGroupPublicKey(groupPublicKey: string): string {
    const group = this.userGroups.find(userGroup => userGroup.groupPublicKey === groupPublicKey);
    if (group) {
      return group.groupName;
    }
    return '';
  }

  /**
   * This function selects the new interaction for mapping
   *
   * @param interaction New interaction being selected
   */
  selectNewInteractionForMap(interaction: any): void {
    interaction.selected = !interaction.selected;
    if (interaction.selected) {
      this.pairs.unshift({
        old: null,
        new: interaction
      });
    } else {
      const index = this.pairs.findIndex(pair => pair.new.interactionId === interaction.interactionId);
      if (index !== -1 && this.pairs[index].old) {
        this.pairs[index].old.selected = false;
      }
      this.pairs.splice(index, 1);
    }
  }

  /**
   * This function selects the new interaction as new interaction
   *
   * @param interaction New interaction being selected
   */
  selectNewInteractionAsNew(interaction: any): void {
    interaction.selected = !interaction.selected;
    if (interaction.selected) {
      this.selectedNewInteractionAsNew = interaction;
    } else {
      interaction.groupChanged = false;
      this.selectedNewInteractionAsNew = null;
    }
  }

  /**
   * This function selects the group selected by user
   */
  onGroupSelect(): void {
    const selectedGroup = this.selectUserGroupFormGroup.value.selectGroupCtrl;
    if (this.allNewSelected) {
      this.newInteractions.forEach(interaction => {
        interaction.groupChanged = true;
        interaction.interactionGroupPublicKey = selectedGroup;
        interaction.interaction.groupPublicKey = selectedGroup;
      });
      this.selectedNewInteractionAsNew = null;
    } else {
      this.selectedNewInteractionAsNew.groupChanged = true;
      this.selectedNewInteractionAsNew.interactionGroupPublicKey = selectedGroup;
      this.selectedNewInteractionAsNew.interaction.groupPublicKey = selectedGroup;
      this.selectedNewInteractionAsNew = null;
    }
  }

  /**
   * This function is called when new stepper is selected
   *
   * @param event HTML selection change event
   */
  selectionStepperChange(event: any): void {

    switch (event.selectedIndex) {
      case 0: {
        break;
      }
      case 1: {
        this.onSelectEntityStep();
        break;
      }
      case 2: {
        this.onSelectVersionStep();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * This function is called on select version step selection
   */
  onSelectVersionStep(): void {
    if (!this.selectEntityFormGroup.valid) return;

    const isInteractionChanged: boolean = (this.selectEntityFormGroup.value.selectEntityCtrl !== this.editEntityId);
    if (isInteractionChanged) {
      this.editEntityVersion = null;
      this.editEntityId = this.selectEntityFormGroup.value.selectEntityCtrl;
      const entityId = this.editEntityId;
      this.editEntity = this.entities.find((entity) => entity.id === entityId);
      this.editEntityName = this.editEntity.name;

      if (this.editEntity) {
        this.entityVersions = this.editEntity.Versions;

        if (this.entityVersions && Array.isArray(this.entityVersions)) {
          this.entityVersions = this.entityVersions.reverse();
        }
        this.selectVersionFormGroup.value.selectVersionCtrl = null;
      }
    }
  }

  /**
   * This function selects all the interactions as the action selected by user
   *
   * @param action Action selected by user - 'create' or 'map'
   */
  selectAll(action: string): void {
    if (action === 'Map') {
      if (this.allMapSelected) {
        this.allMapSelected = false;
        this.mapInteractions.forEach(interaction => {
          interaction.selected = false;
          const pairIndex = this.pairs.findIndex(pair => pair.new.interactionId === interaction.interactionId);
          if (pairIndex !== -1) {
            this.pairs.splice(pairIndex, 1);
          }
        });
        return;
      }
      this.mapInteractions.forEach(interaction => {
        interaction.selected = true;
        this.pairs.unshift({
          old: null,
          new: interaction
        });
      });
      this.allMapSelected = true;
    } else if (action === 'New') {
      if (this.allNewSelected) {
        this.allNewSelected = false;
        this.newInteractions.forEach(interaction => {
          interaction.selected = false;
        });
        this.selectedNewInteractionAsNew = null;
        return;
      }
      this.newInteractions.forEach(interaction => {
        interaction.selected = true;
        this.selectedNewInteractionAsNew = interaction;
      });
      this.allNewSelected = true;
    }
  }

  /**
   * This function selects the version for the interaction
   *
   * @param stepperCtrl MatHorizontalStepper
   * @param userGroupForm Form Group
   * @param entityForm Form Group
   * @param versionForm Form Group
   */
  selectVersion(stepperCtrl: MatStepper, userGroupForm: UntypedFormGroup, entityForm: UntypedFormGroup, versionForm: UntypedFormGroup): void {
    const selectedInteraction = this.entities.find(entity => entity.id === this.selectEntityFormGroup.value.selectEntityCtrl);
    selectedInteraction.version = this.selectVersionFormGroup.value.selectVersionCtrl;
    if (this.allMapSelected) {
      this.mapInteractions.forEach(interaction => {
        const pair = this.pairs.find(interactionPair => interactionPair.new.interactionId === interaction.interactionId);
        if (pair) {
          pair.old = selectedInteraction;
        }
      });
    } else {
      this.pairs[0].old = selectedInteraction;
    }
    this.editGroupPublicKey = null;
    this.editGroup = null;
    this.editGroupName = null;
    this.editEntityId = null;
    this.editEntity = null;
    this.editEntityName = null;
    this.editEntityVersion = null;

    if (stepperCtrl) stepperCtrl.reset();
    if (userGroupForm) userGroupForm.reset();
    if (entityForm) entityForm.reset();
    if (versionForm) versionForm.reset();
  }

  /**
   * This function is called on select entity step selection
   */
  onSelectEntityStep(): void {
    if (!this.selectUserGroupFormGroup.valid) return;

    const isGrpChanged: boolean = (this.selectUserGroupFormGroup.value.selectGroupCtrl !== this.editGroupPublicKey);
    if (isGrpChanged) {

      this.editEntityId = null;
      this.editEntity = null;
      this.editEntityName = null;
      this.editEntityVersion = null;

      this.editGroupPublicKey = this.selectUserGroupFormGroup.get('selectGroupCtrl').value;
      const grpPk = this.editGroupPublicKey;
      this.editGroup = this.userGroups.find((userGrp) => userGrp.groupPublicKey === grpPk);
      this.editGroupName = this.editGroup.groupName;

      this.isWaiting = true;

      this.entitySubscription =
        this.dynFormService.fetchInteractionsByGroup(this.editGroupPublicKey)
          .subscribe(
            (entityArray) => { this.entities = entityArray; },
            (err) => { console.log(err); },
            () => { this.isWaiting = false; }
          );
    }
  }

  /**
   * This function checks if any interaction is selected as map
   */
  isAnyMapSelected(): boolean {
    return this.mapInteractions.filter(interaction => interaction.selected).length !== 0;
  }

  /**
   * This function checks if any interaction is selected as new
   */
  isAnyNewSelected(): boolean {
    return this.newInteractions.filter(interaction => interaction.selected).length !== 0;
  }

  /**
   * This function checks if group is changed for any interaction
   */
  isAnyGroupChanged(): boolean {
    return this.newInteractions.filter(interaction => interaction.groupChanged).length !== 0;
  }

  /**
   * This function submits the response to the calling component
   */
  onSubmit(): void {
    const result: IdMapWorkflowInteractionMapDialogResult = { pairs: this.pairs, newInteractions: this.newInteractions };
    this.dialogRef.close(result);
  }

  /**
   * This function assigns the correct title to the dialog box based on the selected interactions
   */
  getCorrectTitle(): string {
    let title = '';

    if (this.mapInteractions.length > 0 && this.newInteractions.length > 0) {
      title = 'Map/Assign matching interactions by groups';
    } else if (this.mapInteractions.length > 0 && this.newInteractions.length === 0) {
      title = 'Map matching interactions by groups';
    } else if (this.mapInteractions.length === 0 && this.newInteractions.length > 0) {
      title = 'Assign matching interactions to groups';
    } else {
      title = '';
    }

    return title;
  }
}
