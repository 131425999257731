
export class InteractionVisualizerDialogModel {

    constructor(
        public title: string,
        public content: string,
        public interaction: any,
        public interactionVisualizerSubmit: boolean,
        public confirmBtnTxt: string,
        public cancelBtnTxt?: string,
        public disableAll?: boolean,
        public confirmBtnValidation?: boolean,
        public isJoinChange?: boolean
    ) {  }

}

// used to determine visualization mode of the interaction for process-runner and form-visualizer
export enum InteractionVisualizerMode {
    init = "Init", // interaction is being loaded the first time
    edit = "Edit" // interaction is being edited
}
