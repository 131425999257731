
export class FormViewerModel {

  constructor(
    public title: string,
    public content: string,
    public confirmBtnTxt: string,
    public cancelBtnTxt?: string,
    public dynFormMdl?: any
  ) { }

}
