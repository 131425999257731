import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { Observable, BehaviorSubject, Subscription, from } from 'rxjs';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Group, GroupAccessRequest } from '@eva-model/group';
import { NotificationDocument } from '@eva-model/notifications/notifications';
import { CryptoInvitationDoc } from '@eva-model/invitation';
import { filter, switchMap } from 'rxjs/operators';

@Injectable()
export class NotificationsService implements OnDestroy {

  invites: CryptoInvitationDoc[] = [];
  requests: GroupAccessRequest[] = [];
  groups: Group[] = [];
  notifications: NotificationDocument[] = [];

  groupRequests$: Observable<GroupAccessRequest[]>[];
  groupRequests$$: Subscription = new Subscription();
  invites$: Observable<CryptoInvitationDoc[]>;

  public notify = new BehaviorSubject<boolean>(false);

  public ping: Observable<boolean> = this.notify.asObservable();

  constructor(
    private _firestoreService: FirestoreService,
    public afAuth: AuthService,
    private _inviteService: InvitationService,
    private _groupProviderService: GroupProviderService
  ) {
    this.afAuth.user.pipe(
      filter(user => !(!!user))
    ).subscribe(user => {
      this.requests = [];
      this.invites = [];
      this.notifications = [];
    });
  }

  ngOnDestroy() {
    if (this.groupRequests$$) {
      this.groupRequests$$.unsubscribe();
    }
  }

  /**
   * This function returns group access requests
   *
   * @param userSigningKey public signing key of the user
   */
  getRequests(userSigningKey: string): void {
    this._groupProviderService.groupsByUserPublicKey(userSigningKey).subscribe(myGroups => {
      this.groups = myGroups;
      return this.getGroupRequests();
    });
  }

  /**
   * this gets all requests for a user to join a group and flattens them and then populates the information into
   * a flattened array.
   */
  getGroupRequests(): void {
    // make sure the user has groups defined.
    if (this.groups.length > 0) {
      // wipe the existing requests.
      this.requests = [];
      this.groupRequests$ = [];
      this.groupRequests$$.unsubscribe();
      this.groupRequests$$ = new Subscription();
      // for each group the user has, check if there are requests.
      this.groups.forEach(group => {
        this.groupRequests$.push(this._groupProviderService.getGroupAccessRequests(group.groupPublicKey));
      });

      this.groupRequests$.forEach(groupRequest => {
        this.groupRequests$$.add(groupRequest.subscribe(results => {
          // make sure there is a array and if so, push them individually.
          if (Array.isArray(results)) {
            results.forEach(each => {
              this.requests.push(each);
            });
          }
        }));
      });
    }
  }

  /**
   * This gets an observable of invitations for the User from EVA and subscribes to them and places them in
   * the invites array array.
   * @param email the email address we are looking for invitations from.
   */
  getInvitations(email: string): void {
    this.invites$ = this._inviteService.getInvitations(email);
    this.invites$.subscribe(invitesCol => {
      this.invites = invitesCol;
    });
  }

  /**
   * This gets an observable of notifications for the User from EVA and subscribes to them and places them in
   * the notifications array.
   */
  getNotifications(): void {
    this.getNotificationsFromDB().subscribe(notifications => {
      this.notifications = notifications;
    });
  }

  /**
   * This function returns an observable of notifications that exist for the user.
   * It is limited to 20.
   *
   * @return {Observable<NotificationDocument[]>} the observables of notifications for the user.
   */
  private getNotificationsFromDB(): Observable<NotificationDocument[]> {
    return from(this.afAuth.getUserId()).pipe(
      switchMap((uid) => {
        return this._firestoreService.colWithIds$<NotificationDocument[]>(`users/${uid}/Notifications`, ref => ref.limit(20));
      })
    );
  }

  /**
   * This function deletes a notification for the user by doc id
   * @param notificationId the doc Id of the notification.
   *
   * @return {Promise<void>} a empty fulfilled promise on success.
   */
  async deleteNotification(notificationId: string): Promise<void> {
    const id = await this.afAuth.getUserId();
    return this._firestoreService.delete('users/' + id + '/Notifications/' + notificationId);
  }

  /**
   * This deletes all notifications under the users path.
   *
   * @return {Promise<number>} a promise of the number of collections and documents deleted.
   */
  async deleteAllNotifications(): Promise<number> {
    const id = await this.afAuth.getUserId(); // the users ID
    return this._firestoreService.deleteCollections('users/' + id + '/Notifications/');
  }
}
