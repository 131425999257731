import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { Subscription } from 'rxjs';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { Pair, IdMapWorkflowInteractionDialogResult } from '@eva-model/idMap';
import { WorkflowInteraction } from '@eva-model/workflow';

@Component({
  selector: 'app-id-map-workflow-interaction-dialog',
  templateUrl: './id-map-workflow-interaction-dialog.component.html',
  styleUrls: ['./id-map-workflow-interaction-dialog.component.scss']
})
export class IdMapWorkflowInteractionDialogComponent implements OnDestroy {

  oldInteractions: any[] = [];                                  // List of workflow interactions from imported workflow
  newInteractions: any[] = [];                                  // List of workflow interactions from mapped workflow
                                                                // from current environment
  pairs: Pair[] = [];                                           // Array containing pairs of interactions mapped by user
  existingMappings: any[];                                      // Existing mappings if workflows have been already mapped
  interactionsByGroups: any = {};                               // List of user interactions by groups from current environment
  isWaitingForGroupInteractions: boolean;                       // Whether still fetching user group interactions or not
  onWaitForGroupInteractionsMessage: string;                    // Wait message for when fetching user group interactions
  interactionsByGroupsSubs: Subscription = new Subscription();  // Subscription for fetching interaction by user groups
  paired = false;                                               // Whether interactions are paired or not
  allOldSelected = false;                                       // Whether "select all" button was selected for imported interactions or not
  allNewSelected = false;                                       // Whether "select all" button was selected for mapped interactions or not

  constructor(public dialogRef: MatDialogRef<IdMapWorkflowInteractionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    public evaGlobalService: EvaGlobalService,
    private interactionService: DynamicInteractionsService) {
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.oldWorkflow &&
      Array.isArray(dialogData.dynFormMdl.oldWorkflow.interactions)) {
      this.oldInteractions = dialogData.dynFormMdl.oldWorkflow.interactions;
    } else {
      this.paired = true;
    }
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.newWorkflow &&
      Array.isArray(dialogData.dynFormMdl.newWorkflow.interactions)) {
      this.newInteractions = dialogData.dynFormMdl.newWorkflow.interactions;
    }
    this.existingMappings = dialogData.dynFormMdl.existingMappings;

    if (this.existingMappings) {
      const valuePairs = Object.entries(this.existingMappings);

      this.newInteractions.forEach(interaction => {
        if (this.existingMappings[interaction.interactionId]) {
          interaction.selected = true;
        } else {
          interaction.selected = false;
        }
      });

      this.oldInteractions.forEach(interaction => {
        for (const pair of valuePairs) {
          if (pair[1] === interaction.interactionId
            && ((pair[0] === pair[1] && this.getCurrentEnvironment() === dialogData.dynFormMdl.environment)
            || (pair[0] !== pair[1]))) {
            interaction.selected = true;
            const newSelectedInteraction = this.newInteractions.find(newInteraction => newInteraction.interactionId === pair[0]);
            if (!newSelectedInteraction) {
              interaction.selected = false;
              return;
            }
            this.pairs.unshift({
              old: interaction,
              new: newSelectedInteraction
            });
            break;
          }
        }
      });

      this.newInteractions.forEach(interaction => {
        if (!this.pairs.find(pair => pair.new && pair.new.interactionId === interaction.interactionId)) {
          interaction.selected = false;
        }
      });

      if (this.oldInteractions.length === this.newInteractions.length && this.newInteractions.length === this.pairs.length) {
        this.paired = true;
        this.allOldSelected = true;
        this.allNewSelected = true;
      }
    }
  }

  ngOnDestroy() {
    this.interactionsByGroupsSubs.unsubscribe();
  }

  /**
   * This function resets the dialog box to initial state
   */
  onReset(): void {
    this.newInteractions.forEach(interaction => {
      interaction.selectedAction = '';
      interaction.selected = false;
    });
    this.oldInteractions.forEach(interaction => {
      interaction.selected = false;
    });
    this.pairs = [];
    this.pairs.length = 0;
    this.paired = false;
    this.allOldSelected = false;
    this.allNewSelected = false;
  }

  /**
   * This function validates if all the selected interactions are paired
   */
  isValid(): boolean {
    return this.paired
      ? (this.newInteractions.filter(interaction => !interaction.selected && !interaction.selectedAction
        || (interaction.selectedAction && interaction.selectedAction === '')).length === 0)
      : this.pairs.filter(pair => !pair.new || !pair.old).length === 0;
  }

  /**
   * This function fetches interactions for single user group
   *
   * @param groupPk Group public key
   */
  getInteractionsByGroup(groupPk: string): void {

    this.interactionsByGroupsSubs.add(
      this.interactionService.fetchInteractionsByGroup(groupPk)
        .subscribe(
          (interactionArray) => {
            if (!this.interactionsByGroups) this.interactionsByGroups = {};
            if (interactionArray && Array.isArray(interactionArray)) {
              interactionArray.forEach(interaction => {
                interaction.Versions = interaction.Versions.reverse();
                interaction.selectedVersion = interaction.Versions[0].version;
              });
              this.interactionsByGroups[groupPk] = interactionArray;
              const groupName = this.getGroupNameByPublicKey(groupPk);
              this.onWaitForGroupInteractionsMessage = `Adding the interactions of ${groupName} (group)`;
            }
          },
          (err) => {
            this.isWaitingForGroupInteractions = false;
            console.log(err);
            throw err;
          },
          () => {
            const subs: any = this.interactionsByGroupsSubs;
            if (subs._subscriptions.length <= 1) {
              this.isWaitingForGroupInteractions = false;
            }
          }
        )
    );
  }

  /**
   * This function returns the group Name from group Public Key
   *
   * @param groupPk The group public key
   */
  getGroupNameByPublicKey(groupPk: string): string {
    return this.evaGlobalService.getGroupNameByPublicKey(groupPk);
  }

  /**
   * This function selects the interaction from old environment
   *
   * @param interaction Interaction being selected
   */
  selectOldInteraction(interaction: any): void {
    if (!this.paired || (this.allOldSelected && this.allNewSelected)) {
      interaction.selected = !interaction.selected;
    } else {
      return;
    }
    if (interaction.selected) {
      // if interaction is selected, add it to the paired array
      if (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old) {
        this.pairs[0].old = interaction;
      } else {
        this.pairs.unshift({
          old: interaction,
          new: null
        });
      }
    } else {
      // else if the interaction is deselected, remove it from the paired array
      const index = this.pairs.findIndex(pair => pair.old.interactionId === interaction.interactionId);
      if (index !== -1 && this.pairs[index].new) {
        this.pairs[index].new.selected = false;
      }
      this.pairs.splice(index, 1);
    }
    this.allOldSelected = false;
    this.allNewSelected = false;
    if (this.pairs.filter(pair => pair.new && pair.old).length === this.newInteractions.length) {
      this.paired = true;
    } else {
      this.paired = false;
    }
  }

  /**
   * This function selects the interaction from new environment
   *
   * @param interaction Interaction being selected
   */
  selectNewInteraction(interaction: any): void {
    if (!this.paired || (this.allOldSelected && this.allNewSelected)) {
      interaction.selected = !interaction.selected;
    } else {
      return;
    }
    if (interaction.selected) {
      // if interaction is selected, add it to the paired array
      if (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new) {
        this.pairs[0].new = interaction;
      } else {
        this.pairs.unshift({
          old: null,
          new: interaction
        });
      }
    } else {
      // else if the interaction is deselected, remove it from the paired array
      const index = this.pairs.findIndex(pair => pair.new.interactionId === interaction.interactionId);
      if (index !== -1 && this.pairs[index].old) {
        this.pairs[index].old.selected = false;
      }
      this.pairs.splice(index, 1);
    }
    this.allNewSelected = false;
    this.allOldSelected = false;
    if (this.pairs.filter(pair => pair.new && pair.old).length === this.newInteractions.length) {
      this.paired = true;
    } else {
      this.paired = false;
    }
  }

  /**
   * This function selects all the old interactions
   */
  selectAllOldInteraction(): void {
    if (this.allOldSelected) {
      // if all elements are deselected, remove them from the paired array
      this.deselectAllOldInteraction();
      return;
    }
    this.oldInteractions.forEach((interaction, index) => {
      interaction.selected = true;
      if (this.pairs[this.oldInteractions.length - 1 - index] && this.pairs[this.newInteractions.length - 1 - index].new
        && !this.pairs[this.newInteractions.length - 1 - index].old) {
        this.pairs[this.oldInteractions.length - 1 - index].old = interaction;
      } else {
        this.pairs.unshift({
          old: interaction,
          new: null
        });
      }
    });
    if (this.pairs.filter(pair => pair.new && pair.old).length === this.newInteractions.length) {
      this.paired = true;
    } else {
      this.paired = false;
    }
    this.allOldSelected = true;
  }

  /**
   * This function deselects all the old interactions
   */
  deselectAllOldInteraction(): void {
    this.oldInteractions.forEach((interaction, index) => {
      interaction.selected = false;
      if (this.pairs[this.oldInteractions.length - 1 - index] && this.pairs[this.oldInteractions.length - 1 - index].new) {
        this.pairs[this.oldInteractions.length - 1 - index].old = null;
      } else {
        this.pairs.shift();
      }
    });
    this.allOldSelected = false;
    this.paired = false;
  }

  /**
   * This function selects all the new interactions
   */
  selectAllNewInteraction(): void {
    if (this.allNewSelected) {
      // if all elements are deselected, remove them from the paired array
      this.deselectAllNewInteraction();
      return;
    }
    this.newInteractions.forEach((interaction, index) => {
      interaction.selected = true;
      if (this.pairs[this.newInteractions.length - 1 - index] && this.pairs[this.newInteractions.length - 1 - index].old
        && !this.pairs[this.newInteractions.length - 1 - index].new) {
        this.pairs[this.newInteractions.length - 1 - index].new = interaction;
      } else {
        this.pairs.unshift({
          old: null,
          new: interaction
        });
      }
    });
    if (this.pairs.filter(pair => pair.new && pair.old).length === this.newInteractions.length) {
      this.paired = true;
    } else {
      this.paired = false;
    }
    this.allNewSelected = true;
  }

  /**
   * This function deselects all the new interactions
   */
  deselectAllNewInteraction(): void {
    this.newInteractions.forEach((interaction, index) => {
      interaction.selected = false;
      if (this.pairs[this.newInteractions.length - 1 - index] && this.pairs[this.oldInteractions.length - 1 - index].old) {
        this.pairs[this.newInteractions.length - 1 - index].new = null;
      } else {
        this.pairs.shift();
      }
    });
    this.allNewSelected = false;
    this.paired = false;
  }

  /**
   * This function selects all interactions as the action selected by the user
   *
   * @param action Action selected by the user
   */
  selectAllAs(action: string): void {
    this.newInteractions.forEach(interaction => {
      if (!interaction.selected) {
        interaction.selectedAction = action;
      }
    });
  }

  /**
   * This function checks if any old interaction is selected
   */
  isAnyOldSelected(): boolean {
    return this.oldInteractions.filter(interaction => interaction.selected).length !== 0;
  }

  /**
   * This function checks if any new interaction is selected
   */
  isAnyNewSelected(): boolean {
    return this.newInteractions.filter(interaction => interaction.selected).length !== 0;
  }

  /**
   * This function checks if all old interactions are selected
   */
  areAllOldSelected(): boolean {
    return this.pairs.filter(pair => pair.old).length === this.oldInteractions.length;
  }

  /**
   * This function checks if all new interactions are selected
   */
  areAllNewSelected(): boolean {
    return this.pairs.filter(pair => pair.new).length === this.newInteractions.length;
  }

  /**
   * This function returns the selected old interactions count
   */
  getOldSelectedCount(): number {
    return this.oldInteractions.filter(interaction => interaction.selected).length;
  }

  /**
   * This function returns the selected new interactions count
   */
  getNewSelectedCount(): number {
    return this.newInteractions.filter(interaction => interaction.selected).length;
  }

  /**
   * This function submits the changes back to the calling component
   */
  onSubmit(): void {
    if (this.paired) {
      const result: IdMapWorkflowInteractionDialogResult = <IdMapWorkflowInteractionDialogResult>{};
      const newInteractions: WorkflowInteraction[] = this.newInteractions.filter(interaction => interaction.selectedAction === 'New');
      const mapInteractions: WorkflowInteraction[] = this.newInteractions.filter(interaction => interaction.selectedAction === 'Map');

      result['new'] = newInteractions;
      result['map'] = mapInteractions;
      result['pairs'] = this.pairs;
      this.dialogRef.close(result);
    } else {
      this.paired = true;
    }
  }

  /**
   * This function assigns the correct title to the dialog box based on the selected interactions
   */
  getCorrectTitle(): string {
    let title = '';

    if (this.paired) {
      if (this.oldInteractions.length === 0) {
        title = "Choose Action for interactions";
      } else {
        title = "Choose Action for unselected interactions";
      }
    } else {
      title = "Map matching interactions";
    }

    return title;
  }

  /**
   * This function disables the select all button in old section based on the current user input
   */
  disableOldSelectAllButton(): boolean {
    return (
      // disable the old select all button
      // if any old interaction is selected but old select all button is not clicked
      (this.isAnyOldSelected() && !this.allOldSelected)
      // or if any new interaction is selected but new select all button is not clicked
      || (this.isAnyNewSelected() && !this.allNewSelected)
      // or if newInteractions length is not equal to oldInteractions length
    ) || this.newInteractions.length !== this.oldInteractions.length;
  }

  /**
   * This function disables the select all button in new section based on the current user input
   */
  disableNewSelectAllButton(): boolean {
    return (
      // disable the new select all button
      // if any new interaction is selected but new select all button is not clicked
      (this.isAnyNewSelected() && !this.allNewSelected)
      // or if any old interaction is selected but old select all button is not clicked
      || (this.isAnyOldSelected() && !this.allOldSelected)
      // or if newInteractions length is not equal to oldInteractions length
    ) || this.newInteractions.length !== this.oldInteractions.length;
  }

  /**
   * This function disables old interactions based on user input
   *
   * @param interaction Interaction being checked
   */
  disableOldInteractions(interaction: any): boolean {
    return (
      (
        // disable old interactions
        (
          // if any old interaction is selected and is not paired with new interaction
          this.isAnyOldSelected() && (this.pairs[0] && (!this.pairs[0].new))
        )
        || (
          // or if number of pairs is same as newInteractions length
          // and selected old interactions length is same as new interactions length
          // and current interaction is not selected
          // and paired button is not clicked
          this.pairs.length === this.newInteractions.length
          && this.getOldSelectedCount() === this.newInteractions.length
          && !interaction.selected
          && !this.paired
        )
      )
      // or if new Interactions length is not equal to oldInteractions length
      // and current interaction is selected and paired button is clicked
      || (this.newInteractions.length !== this.oldInteractions.length && interaction.selected && this.paired)
    )
    // or if paired button is clicked and old select all button is not clicked
    || (this.paired && !this.allOldSelected);
  }

  /**
   * This function disables new interactions based on user input
   *
   * @param interaction Interaction being checked
   */
  disableNewInteractions(interaction: any): boolean {
    return (
      // disable new interactions
      // if any new interaction is selected and is not paired with old interaction
      (this.isAnyNewSelected() && (this.pairs[0] && (!this.pairs[0].old)))
      || (
        // or if number of pairs is same as oldInteractions length
          // and selected new interactions length is same as old interactions length
          // and current interaction is not selected
          // and paired button is not clicked
        this.pairs.length === this.oldInteractions.length
        && this.getNewSelectedCount() === this.oldInteractions.length
        && !interaction.selected
        && !this.paired
      )
    )
    // or if new Interactions length is not equal to oldInteractions length
    // and current interaction is selected and paired button is clicked
    || (this.newInteractions.length !== this.oldInteractions.length && interaction.selected && this.paired)
    // or if paired button is clicked and new select all button is not clicked
    || (this.allOldSelected && !this.allNewSelected);
  }

  /**
   * This function returns the string equivalent of the current environment based on window url
   */
  getCurrentEnvironment(): string {
    const url = window.location.href;
    let environmentName = '';

    // set the environment based on url
    if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
      environmentName = 'Development';
    } else if (url.includes('staging.atbeva.com')) {
      environmentName = 'Staging';
    } else if (url.includes('test.atbeva.com')) {
      environmentName = 'Test';
    } else if (url.includes('atbeva.com')) {
      environmentName = 'Production';
    }

    return environmentName;
  }
}
