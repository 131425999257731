<div class="toolbar flex">
    <div>
        <form fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="searchForm" autocomplete="off" (ngSubmit)="replaceOccurrence()">
            <!-- <mat-form-field class="example-full-width">
                <input matInput formControlName="find" placeholder="Find in Document" cdkFocusInitial>
            </mat-form-field> -->
            <mat-form-field class="example-full-width">
                <input matInput formControlName="replace" placeholder="Replace with">
            </mat-form-field>
            <button type="submit" mat-raised-button color="primary">Replace</button>
        </form>
        <!-- <button mat-stroked-button (click)="findAndReplaceService.saveDocumentChanges(activeDoc.htmlString)">Save Changes</button> -->
    </div>
    <div class="spacer"></div>
    <div style="text-align: right;">
        <!-- <button mat-icon-button color="primary" (click)="nextOccurrence()"><mat-icon>arrow_downward</mat-icon></button>
        <button mat-icon-button color="primary" (click)="prevOccurrence()"><mat-icon>arrow_upward</mat-icon></button> -->
    </div>
</div>
<div class="document-view" [innerHTML]="activeDoc.htmlString | safeHtml"></div>
