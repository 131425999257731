import { Injectable, OnDestroy } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '@eva-core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService implements OnDestroy {

  private appReloadSource = new Subject<string>();
  public appReload$: Observable<string> = this.appReloadSource.asObservable();

  public evaVersion: string;
  private sub: Subscription;

  constructor(
    private _firestoreService: FirestoreService,
    private authService: AuthService
  ) { }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public checkVersion() {
    this.authService.user.subscribe(user => {
      // unsubscribe if there is no user
      if (!user && this.sub) {
        this.sub.unsubscribe();
        return;
      }

      this.sub = this._firestoreService.doc$('EVAVersion/VersionNumber').subscribe(
        (evaVersion: {version: number}) => {
          if ( !(evaVersion && evaVersion.version) ) return;
          this.evaVersion = evaVersion.version.toString();

          const evaVersionStored = localStorage.getItem('evaVersion');
          if ( !evaVersionStored ) {
            localStorage.setItem('evaVersion', this.evaVersion);
          } else if ( evaVersionStored !== this.evaVersion ) {
            this.announceAppReload(this.evaVersion);
          }
        },
        ( err ) => console.log( err )
      );
    });
  }

  announceAppReload(newVersion: string) {
    this.appReloadSource.next(newVersion);
  }

  setEVAVersion(version: string) {
    if (version) {
      this.evaVersion = version;
      localStorage.setItem('evaVersion', version);
    }
  }
}
