import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { environment } from '@environments/environment';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { LoggingService } from '@eva-core/logging.service';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { Group } from '@eva-model/group';
import { distinctUntilChanged, map, filter, debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';

interface GroupUser {
  publicKey: string;
  isRemoving: boolean;
}

@Component({
  selector: 'app-group-members-dialog',
  templateUrl: './group-members-dialog.component.html',
  styleUrls: ['./group-members-dialog.component.css'],
})
export class GroupMembersDialogComponent implements OnInit, OnDestroy {

  users: any[];
  selectedUsers = [];
  groupUsers: GroupUser[] = [];
  group: Group = null;
  createUserForm: UntypedFormGroup;
  createInvitationForm: UntypedFormGroup;
  userSearchQuery = new Subject<string>();

  _queryLoading = new BehaviorSubject<boolean>(false);
  _sending = new BehaviorSubject<boolean>(false);
  _createSending = new BehaviorSubject<boolean>(false);
  _deleting = new BehaviorSubject<boolean>(false);
  _showUserSearchResults = new BehaviorSubject<boolean>(false);
  // used to track what members are being removed for the view
  _membersBeingRemoved = new BehaviorSubject<string[]>([]);

  queryLoading: Observable<boolean> = this._queryLoading.asObservable();
  sending: Observable<boolean> = this._sending.asObservable();
  createSending: Observable<boolean> = this._createSending.asObservable();
  deleting: Observable<boolean> = this._deleting.asObservable();
  showUserSearchResults: Observable<boolean> = this._showUserSearchResults.asObservable();
  membersBeingRemoved: Observable<string[]> = this._membersBeingRemoved.asObservable();

  snackBarSubs: Subscription = new Subscription();

  invitationGroupType = environment.blockConfig.types.groups.types.invitation;
  normalGroupType = environment.blockConfig.types.groups.types.normal;
  flowThroughGroupType = environment.blockConfig.types.groups.types.flowThrough;
  dynamicDataGroupType = environment.blockConfig.types.groups.types.dynamicData;

  constructor(
    private _fb: UntypedFormBuilder,
    private _is: InvitationService,
    private _ls: LoggingService,
    private _gps: GroupProviderService,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    public dialogRef: MatDialogRef<GroupMembersDialogComponent>,
    private snackBar: MatSnackBar,
    private clipboardService: ClipboardService
  ) {
    this.createUserForm = this._fb.group(
      {
        'userName': ['', [
          Validators.required
        ]],
        'userEmail': ['', [
          Validators.required,
          Validators.email
        ]],
        'message': ['', [
          Validators.required
        ]]
      });

    this.createInvitationForm = this._fb.group(
      {
        'invitationName': ['', [
          Validators.required
        ]],
        'invitationDescription': ['', [
          Validators.required
        ]]
      });
  }

  ngOnInit() {
    // bind passed data to group
    this.group = this.dialogData.extra.group;
    // for easier tracking of what user is being removed for UI purposes,
    // create a custom structure of group members
    this.group.groupSigningMembership.forEach(key => this.groupUsers.push({
      publicKey: key,
      isRemoving: false
    }));

    // Watch for add new member search queries
    this.userSearchQuery
      .pipe(
        distinctUntilChanged(),
        map(q => q.toLowerCase()),
        filter(q => !!q),
        debounceTime(500)
      )
      .subscribe((query) => {
        this._queryLoading.next(true);
        this._is.findUserTypeAhead(query, query + '\uf8ff').subscribe(users => {
            this.users = users;
            this._queryLoading.next(false);
          });
        }
    );
  }

  ngOnDestroy() {
    this.userSearchQuery.unsubscribe();

    if (this.snackBarSubs) {
      this.snackBarSubs.unsubscribe();
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  findUser($event) {
    // hide results if input is cleared
    if ($event.target.value.length === 0) {
      this.users = [];
      // clear last query
      this.userSearchQuery.next('');
      this._showUserSearchResults.next(false);
      return;
    }

    const q = $event.target.value;
    this.userSearchQuery.next(q);
    this._showUserSearchResults.next(true);
  }

  addToSelectedList(user, index: number) {
    const contains = this.selectedUsers.filter(userList => (userList.publicKey === user.publicKey));
    if (contains.length === 0) {
      this.selectedUsers.push(user);
      this.users.splice(index, 1);
    }
  }

  removeFromSelectedList(user, index: number) {
    this.selectedUsers.splice(index, 1);
    this.users.push(user);
  }

  addMembersToGroup() {
    if (this.selectedUsers.length !== 0) {
      this._sending.next(true);
      const keys = this.selectedUsers.map(user => user.publicKey);
      this._gps.addGroupMember(
        keys,
        this.dialogData.extra.group.groupPublicKey,
        this.dialogData.extra.group.groupName,
        this.dialogData.extra.group.groupType,
        null
      )
      .then(result => {
        // console.log(result);
        this._ls.logMessage('Added user(s) successfully', false, 'success');

        // success, add all selected users to the group in memory
        keys.forEach(k => this.groupUsers.push({
          publicKey: k,
          isRemoving: false
        }));

        this.selectedUsers = [];

        this._sending.next(false);
      })
      .catch( err => console.log(err) );
    } else {
      this._ls.logMessage('No user(s) selected or filled in', false, 'error');
    }
  }

  removeUserFromGroup(user: GroupUser) {
    const keys = [user.publicKey];
    user.isRemoving = true;

    this._gps.removeGroupMember(
      keys,
      this.dialogData.extra.group.groupPublicKey,
      this.dialogData.extra.group.groupName,
      this.dialogData.extra.group.groupType,
      null)
    .then(result => {
      this._ls.logMessage('Removed user successfully', false, 'success');

      // Manage the group membership in memory
      const index = this.groupUsers.findIndex(u => u.publicKey === user.publicKey);
      if (index >= 0) {
        this.groupUsers.splice(index, 1);
      }
    })
    .catch(error => {
      user.isRemoving = false;
      console.log(error);
      this._ls.logMessage('Error removing user from group', false, 'error');
    });
  }

  createInvitation() {
    this._sending.next(true);
    if (this.selectedUsers.length !== 0) { // users in from selection
      this.selectedUsers.forEach(user => {
        this._is.setupInvitationNew(
          user.emailAddress,
          user.preferredName,
          this.createInvitationForm.get('invitationDescription').value,
          this.createInvitationForm.get('invitationName').value,
          this.createInvitationForm.get('invitationDescription').value,
          this.dialogData.extra.group.groupPublicKey,
          this.dialogData.extra.group.groupType
        );
      });
      this.createInvitationForm.reset();

      this._ls.logMessage('Invitations sent successfully', false, 'success');
    } else {
      this._ls.logMessage('No users selected or filled in', false, 'error');
    }
  }

  createInvitationNewUser() {
    if (this.createUserForm.valid) { // filled in form to send to user outside system
      this._createSending.next(true);
      this._is.setupInvitationNew(
        this.createUserForm.get('userEmail').value,
        this.createUserForm.get('userName').value,
        this.createUserForm.get('message').value,
        this.dialogData.extra.group.groupName,
        this.createUserForm.get('message').value,
        this.dialogData.extra.group.groupPublicKey,
        this.dialogData.extra.group.groupType
      ).then(result => {
        this.createUserForm.reset();
        this._createSending.next(false);

        this._ls.logMessage('Invitation sent successfully', false, 'success');
      }).catch(error => {
        console.log(error);
        this._createSending.next(false);
        this._ls.logMessage('Error sending invitation', false, 'error');
      });
    } else {
      this._createSending.next(false);
      this._ls.logMessage('No users selected or filled in', false, 'error');
    }
  }

  checkIfUserIsMember(publicKey: string): boolean {
    const keyFound = this.groupUsers.findIndex(user => publicKey === user.publicKey);
    return keyFound >= 0;
  }

  trackByUid(index: number, item: any) {
    return item.uid;
  }

  trackByPublicKey(index: number, item: GroupUser) {
    return item.publicKey;
  }

  openGroupPublicKeySnackBar() {
    const snackBarRef =
      this.snackBar.open(`Group Public Key is ${this.group?.groupPublicKey}`, 'Copy', { duration: 6000, verticalPosition: 'top' });

    this.snackBarSubs.add(snackBarRef.onAction()
    .subscribe(
      () => {
        this.clipboardService.copyFromContent(this.group?.groupPublicKey);
        snackBarRef.dismiss();
      }
    ));
  }

}
