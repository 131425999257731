<h2 mat-dialog-title>Revisions <em *ngIf="versions && versions.length">({{versions.length}})</em></h2>
<mat-dialog-content>
  <mat-progress-bar *ngIf="( gettingDoc | async )" mode="indeterminate"></mat-progress-bar>

  <div fxLayout="row" fxFill fxLayoutGap="16px">
    <div [fxFlex]="currentDoc ? '50' : '100'">

      <!-- Revision Verions -->
      <mat-nav-list *ngIf="versions; else noVersionData">
          <h3 mat-subheader>Select a Revision</h3>
        <mat-list-item *ngFor="let v of versions" (click)="getDocVersion(v.version)" [ngClass]="{'selected': currentDoc?.versionNumber === v.version}">
          <h4 mat-line>{{ v.version | date:'medium' }}<span class="version-status" [ngClass]="{ 'published': v.published, 'draft': !v.published }">{{ v.published ? 'Published' : 'Draft' }}</span></h4>
        </mat-list-item>
      </mat-nav-list>
      <!-- Just incase we dont have version information for some reason -->
      <ng-template #noVersionData>
        <mat-list>
          <mat-list-item>
            No Revisions Available
          </mat-list-item>
        </mat-list>
      </ng-template>

    </div>

    <!-- Only sohw second column when there is a currentDoc selected -->
    <div *ngIf="currentDoc" fxFlex="50" class="current-doc-container">
      <ul>
        <li>
          <strong>Created By:</strong>
          <app-user-info-display [publicKey]="currentDoc.createdBy"></app-user-info-display>
        </li>
        <li *ngIf="currentDoc.revisionNote && currentDoc.revisionNote !== ''">
          <strong>Revision Note:</strong>
          <div class="revision-note">
            {{currentDoc.revisionNote}}
          </div>
        </li>
        <!-- <li>
          <strong>Revision Note:</strong>
          <div class="revision-note">
            <div *ngIf="currentDoc.revisionNote && currentDoc.revisionNote !== ''; else noRevisionNote">
              {{currentDoc.revisionNote}}
            </div>
            <ng-template #noRevisionNote>
              <em>No revision note added</em>
            </ng-template>
          </div>
        </li> -->
      </ul>

      <mat-nav-list>
        <h3 mat-subheader>Options</h3>
        <mat-list-item color="primary" (click)="loadDocIntoEditor()">
          <mat-icon matListIcon>open_in_browser</mat-icon>
          <h4 mat-line>Load Into Editor</h4>
        </mat-list-item>
        <mat-list-item color="primary" (click)="showDiff()">
          <mat-icon matListIcon>compare_arrows</mat-icon>
          <h4 mat-line color="primary">Compare with Different Version</h4>
        </mat-list-item>
      </mat-nav-list>

    </div>
  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
