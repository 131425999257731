<div class="create-group-container">
    <button mat-icon-button mat-dialog-close class="closeBtn">
        <mat-icon>close</mat-icon>
    </button>
    <h1 matDialogTitle>Create a New Group</h1>

    <mat-divider class="divider-margin"></mat-divider>

    <mat-dialog-content class="create-group-card-content">
        <form [formGroup]="formGroup">
            <mat-form-field>
                <input matInput class="fullWidth" formControlName="name" placeholder="Group Name"
                    (keyup)="changeName($event)" required>
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="Group Type" (selectionChange)="groupTypeSelectionChange($event)"
                    formControlName="type">
                    <mat-option *ngFor="let groupType of groupTypeNames" [value]='groupTypes[groupType]'>
                        {{ groupType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <textarea matInput (keyup)="changeDescription($event)" placeholder="Group description"
                    formControlName="description" required></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="returnGroupData()"
            [mat-dialog-close]="createGroupForm" class="create-group-button">Create
            group</button>
        <button mat-stroked-button mat-stroked-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>