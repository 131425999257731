import { Component, OnInit, Inject } from '@angular/core';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { YouTubeDocument } from '@eva-model/releaseNotes';

@Component({
  selector: 'app-add-edit-learn-evavideos',
  templateUrl: './add-edit-learn-evavideos.component.html',
  styleUrls: ['./add-edit-learn-evavideos.component.scss']
})
export class AddEditLearnEVAVideosComponent implements OnInit {

  videosForm: UntypedFormGroup; // the group that will handle the video validation

  constructor(
    public dialogRef: MatDialogRef <AddEditLearnEVAVideosComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _fb: UntypedFormBuilder) { }

  ngOnInit() {
    // setup the videoForm and the featured/ links information
    this.videosForm = this._fb.group({
      featured: this._fb.array([]),
      links: this._fb.array([])
    });
    // make sure information was provided and then setup the form
    if (this.dialogData.extra.data) {
      this.setupVideos(this.dialogData.extra.data);
    }
  }

  /**
   * This sets up the videos into the form group
   *
   * @param inputData the extra passed in from the parent control (this is the firestore doc)
   */
  setupVideos(inputData: YouTubeDocument) {
    // get the featured videos
    const control = <UntypedFormArray>this.videosForm.controls.featured;
    // setup the featured video information
    control.push(
      this._fb.group({
        title: new UntypedFormControl(inputData.featured.title, Validators.required),
        description: new UntypedFormControl(inputData.featured.description, Validators.required),
        youTubeId: new UntypedFormControl(inputData.featured.youTubeId, Validators.required)
      })
    );

    // get the links information
    const linkControl = <UntypedFormArray>this.videosForm.controls.links;
    // loop each link and add the information to the control.
    inputData.links.forEach(link => {
      linkControl.push(
        this._fb.group({
          title: new UntypedFormControl(link.title, Validators.required),
          description: new UntypedFormControl(link.description, Validators.required),
          youTubeId: new UntypedFormControl(link.youTubeId, Validators.required)
        })
      );
    });
  }

  /**
   * This adds a new video to the form control.
   */
  addVideo() {
    const control = <UntypedFormArray>this.videosForm.controls.links;
    control.insert(0,
      this._fb.group({
        title: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl('', Validators.required),
        youTubeId: new UntypedFormControl('', Validators.required)
      })
    );
  }

  /**
   * This function removes a video from the form group
   */
  deleteVideo(index: number) {
    const control = <UntypedFormArray>this.videosForm.controls.links;
    control.removeAt(index);
  }
  /**
   * Cancel the dialog and return to the main screen.
   */
  onCancelClick(): void {
    // close the dialog
    this.dialogRef.close();
  }

  /**
   * This function runs if each item in the form is valid. It will update and save the videos
   * back to the database.
   */
  addEditVideos() {
    // check that the form is valid.
    if (this.videosForm.valid) {
      const returnVideo: YouTubeDocument = {
        featured: this.videosForm.value.featured[0],
        links: this.videosForm.value.links
      };
      this.dialogRef.close(returnVideo);
    }
  }

}
