import { Directive, TemplateRef, ViewContainerRef, Input, OnDestroy } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Subscription } from 'rxjs';

/**
 * THIS IS A STRUCTURAL DIRECTIVE
 *
 * Directive depends on access to the template, you must use it prefixed with a * (asterisk)
 * The expected input is a string from GroupTypes class
 */

@Directive({
  selector: '[appShowIfUserMemberOfGroupType]'
})
export class ShowIfUserMemberOfGroupTypeDirective implements OnDestroy {

  userGroupChangeSub: Subscription;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private evaGlobalService: EvaGlobalService) { }

  @Input()
  set appShowIfUserMemberOfGroupType(type: string) {
    this.userGroupChangeSub = this.evaGlobalService.userGroupsChange$.subscribe((ready: boolean) => {
      if (!ready) { return; }

      // when groups are ready, check for argument group type
      const memberOfGroupType = !!this.evaGlobalService.userGroups.find(group => group.groupType === type);

      if (memberOfGroupType) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userGroupChangeSub) {
      this.userGroupChangeSub.unsubscribe();
    }
  }

}
