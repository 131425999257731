import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@eva-core/auth.service';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { EvaGlobalService } from '../eva-global.service';
import { BROWSERS } from 'ngx-device-detector';
import { LoggingService } from '@eva-core/logging.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private afAuth: AuthService,
    private router: Router,
    private logging: LoggingService,
    private evaGlobalService: EvaGlobalService) {

  }

  /**
   * This checks to ensure that the user is in the right environment and
   * can activate the route that has been requested.
   *
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    //#region Check if user is browing with Internet Explorer to redirect to 'dead end' page for messaging about not using IE
    if ( this.evaGlobalService.evaDeviceInfo.browser === BROWSERS.IE ) {
      this.router.navigate(['end']);
      return false;
    }
    //#endregion

    // check if the user is in the wrong environment.
    if (this.afAuth.wrongEnvironment) {
      this.router.navigate(['wrongEnvironment']);
      return false;
    }
    // get the user object and activate the route if possible, otherwise redirect to sign in.
    return this.afAuth.user
      .pipe(
        take(1),
        map(user => {
          // make sure the user and a private key exists and that a user has accepted the terms and conditions
          return !!(user && user.signingPublicKey && user.encryptionPublicKey && user.acceptedTermsAndCond);
        }),
        tap(loggedIn => {
          if (!loggedIn) {
            this.logging.logMessage('You must be signed in to use the application.', false, 'error');
            // kick a user to the login page if they are not authenticated. keep the current url they came to so that
            // if they successfully login, they can be returned to the page they originally wanted.
            // EX: invite link inside an email would return them back to the invite page
            this.router.navigate(['welcome'], { queryParams: { returnUrl: state.url } });
            return false;
          } else {
            return true;
          }
        })
      );

  }

}
