/**
 * This contains the main menu file for the EVA application (default EVA Screen)
 */
import { EVAMenu } from "@eva-model/menu/menu";
import { preferencesSVGIcon, logoutSVGIcon } from "./icons";
import { Routes } from "./mainMenu";

export const UserMenu: EVAMenu[] = [{
    routerLink: Routes.Preferences,
    svgIcon: preferencesSVGIcon,
    text: 'Preferences'
}, {
    isLogout: true,
    svgIcon: logoutSVGIcon,
    text: 'Logout'
}
];
