import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class CanadapostService {

  constructor(
    private http: HttpClient,
    private perf: AngularFirePerformance
  ) { }
  /**
   * sets parameters for the adddress list for canada post data
   * @param searchTerm user entered text to be used for search
   * @param lastId id from last element triggered on change
   * @param country the user selected country
   */
  findAddressComplete(searchTerm: string, lastId?: string, country = 'CAN'): Observable<Object> {
    let paramsOptions = new HttpParams().set('key', environment.cpAddressCompleteFind.key);
    paramsOptions = paramsOptions.set('SearchTerm', searchTerm);
    paramsOptions = paramsOptions.set('LastId', (lastId ? lastId : ''));
    paramsOptions = paramsOptions.set('SearchFor', 'Everything');
    paramsOptions = paramsOptions.set('Country', country);
    paramsOptions = paramsOptions.set('LanguagePreference', 'EN');
    paramsOptions = paramsOptions.set('MaxSuggestions', '7');
    paramsOptions = paramsOptions.set('MaxResults', '7');

    const headers =
      new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true',
        'Content-Type': 'application/json'
      });

    const httpOptions = {
      headers: headers,
      params: paramsOptions
    };

    return this.http.get(environment.cpAddressCompleteFind.url, httpOptions).pipe(
      trace('canadapost-findAddressComplete')
    );
  }
/**
 * function to retrieve address list
 * @param id id of input element
 */
  retrieveAddressComplete(id: string): Observable<Object> {
    let paramsOptions = new HttpParams().set('key', environment.cpAddressCompleteRetrieve.key);
    paramsOptions = paramsOptions.set('Id', id);

    const headers =
      new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true',
        'Content-Type': 'application/json'
      });

    const httpOptions = {
      headers: headers,
      params: paramsOptions
    };

    return this.http.get(environment.cpAddressCompleteRetrieve.url, httpOptions).pipe(
      trace('canadapost-retrieveAddressComplete')
    );
  }

}
