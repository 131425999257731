import { Injectable } from '@angular/core';
import { ValueModifier } from '@eva-model/search';
import { formatDate } from '@angular/common';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { environment } from '@environments/environment';
import { InstantSearchService } from './instant-search.service';

@Injectable({
  providedIn: 'root'
})
export class SearchValueModifierService {

  constructor(private evaGlobalService: EvaGlobalService,
    private instantSearchService: InstantSearchService) { }

    // case ValueModifier.GroupName:               return this.evaGlobalService.getGroupNameByPublicKey(propertyValue);
  modifyValues(modifier: ValueModifier, propertyValue: any, searchResult?: any): string | number | boolean | any[] {
    switch (modifier) {
        case ValueModifier.ObjectKeysLength:        return Object.keys(propertyValue).length;
        case ValueModifier.DateShort:               return (propertyValue && propertyValue !== '')
                                                      ? formatDate(propertyValue, 'short', 'en')
                                                      : '';
        case ValueModifier.GroupName:               return this.getGroupNamesByPublicKey(propertyValue);
        case ValueModifier.ParseJSONString:         return this.getParsedJSON(propertyValue);
        case ValueModifier.GetObjectPropertyName:   return Object.keys(propertyValue)[0];
        case ValueModifier.KnowledgeArchivedStatus: return this.getTrueFalseLabels([{boolean: true, label: 'Yes'}, {boolean: false, label: 'No'}], propertyValue, 'Showing All');
        case ValueModifier.KnowledgePublishedStatus: return this.getTrueFalseLabels([{boolean: true, label: 'Published'}, {boolean: false, label: 'Draft'}], propertyValue, 'Showing All');
        case ValueModifier.ProcessDashboardExceptionMessage:
                                                    return this.updateExceptionMessage(propertyValue);
    }
  }

  private getParsedJSON(propertyValue: any): any {
    try {
      return JSON.parse(propertyValue);
    } catch (err) {
      return JSON.parse(JSON.stringify(propertyValue));
    }
  }

  private updateExceptionMessage(propertyValue: string): string {
    let exceptionMessage = propertyValue;

    if (propertyValue.includes('[BE]')) { // business exception
      exceptionMessage = 'Sent to a team member for fulfillment/resolution';
    } else if (propertyValue.includes('[SE]')) { // system exception
      exceptionMessage = 'w/ Experience Operations';
    } else if (propertyValue.includes('[VE]')) { // validation exception
      exceptionMessage = 'Returned to Submitter';
    }

    return exceptionMessage;
  }

  /**
   * Returns a concatenated string of the filter based on values selected. This is meant to display a string of text
   * with the filter options selected. Only meant to be used with 2 options.
   */
  private getTrueFalseLabels(labels: {boolean: boolean, label: string}[], values: boolean[] | boolean, bothSelectedDisplayValue: string): string {
    let returnValue = '';
    if (Array.isArray(values)) {
      values.forEach((v) => {
        const found = labels.find((label) => label.boolean === v);
        if (found) {
          returnValue.length > 0 ? returnValue = `${returnValue}, ${found.label}` : returnValue = found.label
        }
      });
    } else {
      const found = labels.find((label) => label.boolean === values);
      if (found) {
        returnValue = found.label
      }
    }
    
    // check if both selected, if so display a passed in both selected value
    if (Array.isArray(values) && values.length > 1 && values.includes(labels[0].boolean) && values.includes(labels[1].boolean)) {
      returnValue = bothSelectedDisplayValue;
    }

    return returnValue;
  }

  private getGroupNamesByPublicKey(value: string[] | string): string {
    let returnValue = '';
    let groupCount = 0;
    if (Array.isArray(value)) {
      value.forEach((key) => {
        const groupName = this.evaGlobalService.getGroupNameByPublicKey(key);
        
        if (groupCount === 0) {
          returnValue = groupName;
        }

        groupCount++;
      })
    } else {
      returnValue = this.evaGlobalService.getGroupNameByPublicKey(value);
      groupCount++;
    }

    // append that there is more than 1 group selected
    if (groupCount > 1) {
      returnValue = `${returnValue} and ${groupCount-1} more`
    }

    return returnValue;
  }
}
