import { InteractionValueEmitter } from "./InteractionEmitValueModel";
import { searchDirection } from "./interactionFormControlModel";
import { InteractionVisualizerMode } from "./interactionVisualizerDialogModel";

export class InteractionControlModel {

    constructor(
        public interactionId: string,
        public interactionOriginalId: string,
        public FormControl: InteractionFrmCntrl,
        public screenIndex: number,
        public processId?: string,
        public targetId?: string,
        public requestId?: string,
        public errMsg?: string,
        public lastEmittedFrom?: InteractionValueEmitter,
        public errType?: searchDirection,
        public isSpecialControl?: boolean,
        public direction?: searchDirection,
        public isPreview?: boolean,
        public isSummaryScreen?: boolean,
        public isCanadaPostWatchControl?: boolean,
        public visualizerMode?: InteractionVisualizerMode,
        public isEditInteractionFlow?: boolean,
        public tabIndex?: number,
        public processTitle?: string
    ) { }
}

export class InteractionFrmCntrl {
    constructor(
        public id: string,
        public originalId: string,
        public name: string,
        public hint: string,
        public label: string,
        public type: string,
        public value: string,
        public inputType: string,
        public isDisable: boolean,
        public formCntrl: any,
        public options?: Array<any>,
        public multiple?: boolean,
        public group?: Array<any>,
        public _list?: Array<any>
    ) { }
}
