import { InteractionFlatModel } from "@eva-model/interactionFlatModel";

export class ProcessInteractionEmitValueModel {

    constructor(
        public processId: string,
        public workflowId: string,
        public interactionId: string,
        public interactionOriginalId: string,
        public interactionValues: InteractionFlatModel,
        public submitDirection?: string,
        public submitInteraction?: boolean
    ) {}
}

/**
 * used to set the direction interaction will be moving in.
 * selection will be pushed into submit direction in the model above
 */
export enum submitDirectionType {
    next = 'next',
    previous = 'previous'
}
