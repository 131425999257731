import {Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@eva-services/user/user.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {mnemonicType} from '@eva-model/mnemonicType';
import {ViewContainerDirective} from '@eva-ui/view-container/view-container.directive';
import {UserMnemonicComponent} from '@eva-ui/user/user-mnemonic/user-mnemonic.component';
import {DeviceService} from '@eva-services/device/device.service';
import {SignupService} from '@eva-core/signup/signup.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-user-verify-reset',
  templateUrl: './user-verify-reset.component.html',
  styleUrls: ['./user-verify-reset.component.scss'],
  providers: [MessageService]
})
export class UserVerifyResetComponent implements OnInit {

  frmGrp: UntypedFormGroup;
  mntype: any;

  isWaiting = false;
  isWaitingForEncryption = false;
  isWaitingForSigning = false;
  onWaitMessage = null;

  genericComponentRef: ComponentRef<any>;
  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;

  constructor(
    private _fb: UntypedFormBuilder,
    private _userService: UserService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _deviceService: DeviceService,
    private _signupService: SignupService,
    private messageService: MessageService
  ) {
    this.mntype = mnemonicType;
  }

  ngOnInit() {
    this.createForm();
    this.generateUserMnemonicReset();
  }

  getEntropy() {
    const x = this._userService.getEntropy();
  }

  createForm() {
    this.frmGrp = this._fb.group({
      encryptMnemonic: [{ value: '', disabled: this.isWaiting}],
      signMnemonic: [{ value: '', disabled: this.isWaiting}]
    });
  }

  mnemonicReset() {
    return true;
  }

  mnemonicVerify(mnemonicTypetoVerify: string) {

    let mnemonic = null;
    if ( mnemonicTypetoVerify === mnemonicType.encryption ) {
      mnemonic = this.frmGrp.get('encryptMnemonic').value;
      this.isWaitingForEncryption = true;
      this.onWaitMessage = `Verifying encryption mnemonics ...`;
      this.frmGrp.get('encryptMnemonic').disable();
    } else if ( mnemonicTypetoVerify === mnemonicType.signing ) {
      mnemonic = this.frmGrp.get('signMnemonic').value;
      this.isWaitingForSigning = true;
      this.onWaitMessage = `Verifying signing mnemonics ...`;
      this.frmGrp.get('signMnemonic').disable();
    } else {

      const msgSummary = `User ${mnemonicTypetoVerify} mnemonic verification result:`;
      const msgDetail = `Invalid`;

      this.messageService.clear();
      this.messageService.add({
        life: 5500,
        severity: 'error',
        summary: msgSummary,
        detail: msgDetail
      });

      return false;
    }

    this.isWaiting = true;

    this._userService.verifyMnemonic(mnemonic, mnemonicTypetoVerify)
    .subscribe(
      (mnemonicVerifyResult) => {
        this.clearWaiting();

        const msgSummary = `User ${mnemonicTypetoVerify} mnemonic verification result:`;
        const msgDetail = ( mnemonicVerifyResult && mnemonicVerifyResult.verified ) ? `Verified` : `Invalid`;

        this.messageService.clear();
        this.messageService.add({
          life: 5500,
          severity: ( mnemonicVerifyResult && mnemonicVerifyResult.verified ) ? 'info' : 'error',
          summary: msgSummary,
          detail: msgDetail
        });
      },
      (err) => {
        this.clearWaiting();
        console.log(err);

        const msgSummary = `User ${mnemonicTypetoVerify} mnemonic verification result:`;
        const msgDetail = 'Encountered with error';

        this.messageService.clear();
        this.messageService.add({
          life: 5500,
          severity: 'error',
          summary: msgSummary,
          detail: msgDetail
        });
      }
    );

  }

  private clearWaiting() {
    this.isWaiting = false;
    this.isWaitingForEncryption = false;
    this.isWaitingForSigning = false;
    this.onWaitMessage = null;

    this.frmGrp.get('encryptMnemonic').enable();
    this.frmGrp.get('signMnemonic').enable();
  }

  signMnemonicReset() {
    return true;
  }

  private generateUserMnemonicReset() {
    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(UserMnemonicComponent);
    this.genericComponentRef = viewContainerRef.createComponent(componentFactory);
  }

  public async getUserCryptoKey() {
    try {

      const cryptoKey  = await this._signupService.encCryptoKey();
      console.log( cryptoKey.algorithm, cryptoKey.type, cryptoKey.usages );

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }
}
