/* this model needs to match the one in the EVA firebase functions */
// T3JpZ2lu - Origin
// UHJldmlvdXM= - previous

//#region Invitation and Invitation Crypto

/**
 * This class contains the functions and shapes to provide a proper crypto invitation.
 */
export class CryptoInvitation {
  private _groupInvitedTo: string; // the public key of the group that they were invited to.
  private _publicKeyToInclude: string; // the public key to include in the group.
  private _invitedBy: string; // public key of user inviting the other user to a group.
  private _invitedAt: number; // the timestamp of when the invitation was provided.
  private _signature: string; // the crypto signature of the invitation.
  // private _cryptoInvitation: any;

  // setup the crypto invitation based on teh values provided.
  constructor(groupInvitedTo: string, invitedBy: string, publicKeyToInclude: string, invitedAt?: number, signature?: string) {
    this._groupInvitedTo = groupInvitedTo;
    this._publicKeyToInclude = publicKeyToInclude;
    this._invitedBy = invitedBy;
    this._invitedAt = (invitedAt) ? invitedAt : Date.now();
    this._signature = (signature) ? signature : null;
  }

  get signature() { return this._signature; }
  set signature(signature) { this._signature = signature; }
  get cryptoInvitation(): CryptoInvitationDoc {
    // this object needs to maintain alphabetical shape if changes are made.
    return {
      invitation: {
        groupInvitedTo: this._groupInvitedTo,
        invitedAt: this._invitedAt,
        invitedBy: this._invitedBy,
        publicKeyToInclude: this._publicKeyToInclude
      },
      signature: this._signature
    };
  }
}

/**
 * This class is used to create an invitation for the user with additional information from the storage.
 */
export class Invitation {
  private _recipientEmail: string; // the email address of the user that will received the invitation.
  private _recipientName: string; // the name of the user who is being invited to the group.
  private _invitationTypeId: string; // the type of invitation that is being provided to the user.
  private _message: string; // the message from the inviter to invitee
  private _inviterName: string; // the name of the user inviting the recipient to the group.
  private _inviterEmail: string; // the email address of the user who is inviting the user to the group.
  private _invitationName: string; // the name that the inviter has giving to the invitation.
  private _invitationDescription: string; // a description of why the invitation was sent to the user.
//  private _invitationToSign: string;
  private _cryptoInvitation: CryptoInvitation; // the crypto proof of the invitation for the user.

  constructor(invitationData?: any) {
    // if the invitation data has been provided, include this.
    if (invitationData) {
      this.setInvitationData(invitationData);
    }
  }
  /**
   *  This function sets the invitation data for a valid invitation object
   *
   * @param iData the invitation Doc to set into the invitation.
   */
  setInvitationData(iData: InvitationDoc): void {
    // determine if the basic information have been provided, if so, set the values.
    this._recipientEmail = (iData.recipientEmail) ? iData.recipientEmail : '';
    this._recipientName = (iData.recipientName) ? iData.recipientName : '';
    this._invitationTypeId = (iData.invitationTypeId) ? iData.invitationTypeId : '';
    this._message = (iData.message) ? iData.message : '';
    this._inviterName = (iData.inviterName) ? iData.inviterName : '';
    this._inviterEmail = (iData.inviterEmail) ? iData.inviterEmail : '';
    this._invitationName = (iData.invitationName) ? iData.invitationName : '';
    this._invitationDescription = (iData.invitationDescription) ? iData.invitationDescription : '';
    // check if the crypto invitation has been provided and include it if it is valid.
    if (iData.cryptoInvitation) {
      const cI = iData.cryptoInvitation;
      // console.log(cI);
      try {
        this._cryptoInvitation = new CryptoInvitation(
          cI.invitation.groupInvitedTo,
          cI.invitation.invitedBy,
          cI.invitation.publicKeyToInclude,
          cI.invitation.invitedAt,
          cI.signature
        );
      } catch (err) {
        throw Error('Error Occured while setting the crypto invitation. ' + err);
      }
    }
  }

  // return the invitation document (this doesn't need to be ordered since the crypto is the only part cryptographically checked.)
  get invitation(): InvitationDoc {
    return {
      recipientEmail: this._recipientEmail,
      recipientName: this._recipientName,
      invitationTypeId: this._invitationTypeId,
      message: this._message,
      inviterName: this._inviterName,
      inviterEmail: this._inviterEmail,
      invitationName: this._invitationName,
      invitationDescription: this._invitationDescription,
      cryptoInvitation: this._cryptoInvitation.cryptoInvitation
    };
  }
  // all the getter and setters
  set cryptoInvitation(cryptoInvitation: CryptoInvitation) { this._cryptoInvitation = cryptoInvitation; }
  get cryptoInvitation() { return this._cryptoInvitation; }
  get recipientEmail() { return this._recipientEmail; }
  set recipientEmail(recipientEmail) { this._recipientEmail = recipientEmail; }
  get recipientName() { return this._recipientName; }
  set recipientName(recipientName) { this._recipientName = recipientName; }
  get invitationTypeId() { return this._invitationTypeId; }
  set invitationTypeId(invitationTypeId) { this._invitationTypeId = invitationTypeId; }
  get message() { return this._message; }
  set message(message) { this._message = message; }
  get inviterName() { return this._inviterName; }
  set inviterName(inviterName) { this._inviterName = inviterName; }
  get inviterEmail() { return this._inviterEmail; }
  set inviterEmail(inviterEmail) { this._inviterEmail = inviterEmail; }
  get invitationName() { return this._invitationName; }
  set invitationName(invitationName) { this._invitationName = invitationName; }
  get invitationDescription() { return this._invitationDescription; }
  set invitationDescription(invitationDescription) { this._invitationDescription = invitationDescription; }
}

//#endregion Invitation and Invitation Crypto

//#region Acceptance and Acceptance Crypto

/**
 * This class is used to create and shape a request of a user to join a group.
 */
export class CryptoGroupRequest {
  private _requestedAt: number; // date and time of request.
  private _groupPublicKey: string; // public key of the group requesting access to.
  private _requesterPublicKey: string; // the hex encoding of the public key of the user requesting access to the group.
  private _signature: string; // the hex encoding of the DER signature of the user that has requested access to an invitation group.

  constructor(requestedAt: number, groupPublicKey: string, requesterPublicKey: string, signature?: string) {
    this._requestedAt = requestedAt;
    this._groupPublicKey = groupPublicKey;
    this._requesterPublicKey = requesterPublicKey;
    this._signature  = (signature) ? signature : null;
  }

  // return the crypto request in the proper shape to be able to prove the DER signature true.
  get cryptoRequest(): GroupRequestDoc {
    return {
      groupRequest: {
        groupPublicKey: this._groupPublicKey,
        requestedAt: this._requestedAt,
        requesterPublicKey: this._requesterPublicKey,
      },
      signature: this._signature
    };
  }
  set signature(signature) { this._signature = signature; }
  get signature() { return this._signature; }
}

/**
 * This class is used to shape the acceptance of an invitation by a user.
 */
export class CryptoInvitationAcceptance {
  private _acceptedAt: number; // the timestamp that the user accepted the invitation
  private _finalPublicKey: string; // the final public key that is to be added to the group
  private _cryptoInvitation: CryptoInvitation; // the original shape of the crypto invitation
  private _publicKey: string; // the public key of that has accepted the invitation. It will always match the key in the crypto invitation.
  private _signature: string; // the hex encoded DER signature of the public key that was invited to the group.

  constructor(cryptoInvitation: CryptoInvitation, acceptedAt?: number, finalPublicKey?: string, publicKey?: string,
    signature?: string) {
    this._cryptoInvitation = cryptoInvitation;
    this._acceptedAt = (acceptedAt) ? acceptedAt : null;
    this._finalPublicKey = (finalPublicKey) ? finalPublicKey : null;
    this._publicKey = (publicKey) ? publicKey : null;
    this._signature = (signature) ? signature : null;
  }
  // Shape the crypto invitation object. the ordering of the crypto invitation acceptance matters for proving the signature provided.
  get cryptoInvitationAcceptance(): GroupAcceptanceDoc {
    return {
      acceptance: {
        acceptedAt: this._acceptedAt,
        cryptoInvitation: this._cryptoInvitation.cryptoInvitation,
        finalPublicKey: this._finalPublicKey,
        publicKey: this._publicKey
      },
      signature: this._signature
    };
  }
  get signature() { return this._signature; }
  set signature(signature) { this._signature = signature; }
}

/**
 * This class allows shaping the invitation acceptance with crypto and any other information that the user has provided.
 */
export class InvitationAcceptance {
  private _invitation: Invitation; // the original invitation that was provided to the user that was invited to the group.
  private _cryptoInvitationAcceptance: CryptoInvitationAcceptance; // the full crypto acceptance for the user that was added.
  private _reason: string; // any information that the user has provided.

  constructor(invitation?: Invitation, invitationAcceptanceData?: any, reason?: string) {
    if (invitation) {
      this._invitation = invitation;
    }
    if (invitationAcceptanceData) {
      this.setInvitationAcceptanceData(invitationAcceptanceData);
    }
    if (reason) {
      this._reason = reason;
    }
  }

  /**
   * This sets the invitation acceptance for the information provided.
   *
   * @param iAData the invitation acceptance data.
   */
  setInvitationAcceptanceData(iAData) {
    // check that the acceptance crypto is valid
    if (iAData && iAData.acceptance.acceptedAt && iAData.acceptance.finalPublicKey && iAData.acceptance.publicKey) {
      try {
        this._cryptoInvitationAcceptance = new CryptoInvitationAcceptance(iAData.acceptance.cryptoInvitation,
          iAData.acceptance.acceptedAt, iAData.acceptance.finalPublicKey, iAData.acceptance.publicKey,
          (iAData.signature) ? iAData.signature : null
        );
      } catch (err) {
        console.log(err);
        throw Error('iAdata is not proper object.');
      }
      if (iAData.invitation) {
        console.log(iAData.invitation);
        try {
          this._invitation = new Invitation(iAData.invitation);
        } catch (err) {
          console.log(err);
          throw Error('iAdata is not proper object.');
        }
      }
    } else {
      throw Error('iAdata is not proper object.');
    }
  }
  /**
   * returns the invitation acceptance in the correct format.
   */
  get invitationAcceptance() {
    const invitationAcceptanceObject = {
      cryptoInvitationAcceptance: this._cryptoInvitationAcceptance.cryptoInvitationAcceptance,
      invitation: this._invitation.invitation,
      reason: this._reason
    };
    return invitationAcceptanceObject;
  }

  set reason(reason) { this._reason = reason; }
  get reason() { return this._reason; }
  set invitation(invitation) { this._invitation = invitation; }
  get invitation() { return this._invitation; }
  set cryptoInvitationAcceptance(cryptoInvitationAcceptance) { this._cryptoInvitationAcceptance = cryptoInvitationAcceptance; }
  get cryptoInvitationAcceptance() { return this._cryptoInvitationAcceptance; }
}

//#endregion Acceptance and Acceptance Crypto

/**
 * Is used as the base shape for the invitation that is stored in the EVA application.
 */
export interface InvitationDoc {
  recipientEmail: string; // the email address of the user that will received the invitation.
  recipientName: string; // the name of the user who is being invited to the group.
  invitationTypeId: string; // the type of invitation that is being provided to the user.
  message: string; // the message from the inviter to invitee
  inviterName: string; // the name of the user inviting the recipient to the group.
  inviterEmail: string; // the email address of the user who is inviting the user to the group.
  invitationName: string; // the name that the inviter has giving to the invitation.
  invitationDescription: string; // a description of why the invitation was sent to the user.
  cryptoInvitation: CryptoInvitationDoc; // the crypto proof of the invitation for the user.
}

/**
 * This interface is used to contain the base crypto invitiation to a group and contains the crypto proof of the invitation
 */
export interface CryptoInvitationDoc {
  invitation: CryptoInvitationBaseDoc; // the base shape that is signed for the proof of the invitation.
  signature: string; // the hex encoding of the DER signature of the user who invited the user to the group.
}

/**
 * This interface is the base cryptographic invitation that is required for a group invitation.
 */
export interface CryptoInvitationBaseDoc {
  groupInvitedTo: string; // the public key of the group that the user is invited to
  invitedAt: number; // the timestamp of the invitation that has been created.
  invitedBy: string; // the public key of the user who did the invitation.
  publicKeyToInclude: string; // the public key fo the user that was invited to join the group.
}

/**
 * This interface contains the base crypto acceptance document for the user accepting an invitation to a group.
 */
export interface GroupAcceptanceDoc {
  acceptance: GroupAcceptanceBaseDoc; // the base acceptance of the invitation.
  signature: string; // the hex encoded DER of the crypto proof.
}

/**
 * This contains the shape for a database item that contains the full details of a crypto invitation acceptance
 */
export interface GroupAcceptanceDatabaseDoc {
  cryptoInvitationAcceptance: GroupAcceptanceDoc; // this crypto acceptance
  id?: string; // an id provided by the database that contained the information
  reason: string; // the reason provided by the user to the response that they have provided.
}
/**
 * This interface contains the acceptance information to the invitation to a group.
 */
export interface GroupAcceptanceBaseDoc {
  acceptedAt: number; // the timestamp of when the user accepted the invitation.
  cryptoInvitation: CryptoInvitationDoc; // this is the original invitation to the group.
  finalPublicKey: string; // this is normally the key of the user invited. If they weren't a user of the EVA application at time of
  // invitation, this would be their final public key once they have signed up for the EVA application.
  publicKey: string; // the public key that was originally invited to the application.
}
/**
 * This interface contains the original crypto request to a group.
 */
export interface GroupRequestDoc {
  groupRequest: GroupRequestBaseDoc; // the crypto request of the original invitation.
  signature: string; // the hex encoding of the DER crypto proof for the request to a group.
}

/**
 * This interface contains the base details that are required to request access to a group that is of an invitation type.
 */
export interface GroupRequestBaseDoc {
  groupPublicKey: string; // the group that the user is requesting access to.
  requestedAt: number; // the timestamp that the user requested access to the group.
  requesterPublicKey: string; // the public key of the user that requested access to the group.
}

/**
 * This interface is used for a generic invitation or acceptance response in the EVA application.
 */
export interface GenericInvitationOrAcceptanceResponse {
  successful: boolean; // whether this completed successfully.
  message?: string; // any message that is included.
}
