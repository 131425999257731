import { DeleteFileResponse } from "@eva-model/fileStorageResponses";

/**
 * individual release notes for deployment.
 */
export interface ReleaseNote {
    updatedAt?: number;
    description: string;
    releaseDate: number;
    hover?: boolean;
    updatedBy?: string;
}

/**
 * this is the interface for last release notes for a single read.
 */
export interface ReleaseNotes {
    releaseNotes: ReleaseNote[];
}

/**
 * This is the interface for the team member / EVA users component.
 */
export interface TeamMemberData {
    imageUrl: string;
    name: string;
    position: string;
    description: string;
    contactMethods: string;
}

/**
 * This is the interface for team members for a single read.
 */
export interface TeamMembers {
    teamMembers: TeamMemberData[];
}

/**
 * This is the interface for team members' image delete response.
 */
export interface TeamMemberDeleteResponse {
    deleteStorageResponse: DeleteFileResponse;
    teamMemberData: TeamMemberData;
}
/**
 * This is the interface for the document in the about page for training and resources.
 */
export interface AboutPageDocumentData {
    documentName: string;
    url: string;
    category: string;
    dateUploaded: number;
    id?: string;
}

/**
 * This is the interface for the about page Frequently asked questions.
 */
export interface AboutPageFAQs {
    title: string;
    description: string;
    updatedAt?: number;
    updatedBy?: string;
    id?: string;
}

/**
 * This is the interface for the about page feedback data.
 */
export interface AboutPageFeedback {
    description: string;
    createdAt?: number;
    updatedAt?: number;
    updatedBy: string;
    submitterEmail: string;
    submittedUid: string;
    reply: string;
    replyAcknowledged: boolean;
}


/**
 * This is the interface for the about page YouTube link data.
 */
export interface YouTubeLinks {
    title: string;
    description: string;
    youTubeId: string;
    embedCode?: string;
    createdAt?: number;
    updatedAt?: number;
    updatedBy?: string;
}

/**
 * This is the interface for the about page YouTube documents.
 */
export interface YouTubeDocument {
    featured: YouTubeLinks;
    links: YouTubeLinks[];
}
