import { ChatEntity, FunctionCall } from "./chat/chat";
import { Routes } from "./menu/defaults/mainMenu";

export enum userLastStateType {
    dialogflow = 'DialogFlowResponse',
    watson = 'WatsonResponse',
    interaction = 'EVAInteraction',
    process = 'EVAProcess',
    TFIDF = 'EVATFIDF'
}

export type LastStateType = 'DialogFlowResponse' | 'WatsonResponse' | 'EVAInteraction' | 'EVAProcess' | 'EVATFIDF';

// export interface LastState {
//     query: string;
//     mlOriginalQuery?: string; // this is only used for the last state update.
//     type: LastStateType;                                        // type needs to be one of these matching strings
//     response?: any;                                             // dialogflow response - need type
//     count?: number; // the record of where this is in a knowledge array.
//     screen?: number; // the number of the screen that has focus.
//     id?: string; // the id of the element that has focus.
//     executingInteractionOriginalId?: string;                    // exists when in a process
//     interactionValues?: any;                                    // exists when in a process
//     interactionsValues?: any;                                   // exists when in a process
//     processId?: string;                                         // exists when in a process
//     interactionResponse?: InteractionMapped; // this is a saved interaction.
//     shouldNotUpdateDatabase?: boolean; // used in service to determine if this state should update document in database
//     sessionId?: string; // a random uuid saved in memory for each EVA session. Using this to determine where lastState doc id comes from.
//     firstStateUpdate?: boolean; // property added to object and set to true if this is the first time this session last state has emitted
// }

export interface LastState {
    tabs: LastStateTabs;
    chat: LastStateChat;
    version: number;
    sessionId?: string; // a random uuid saved in memory for each EVA session. Using this to determine where lastState doc id comes from.
    shouldNotUpdateDatabase?: boolean; // used in service to determine if this state should update document in database
    firstStateUpdate?: boolean; // property added to object and set to true if this is the first time this session last state has emitted
    tabsUpdated?: string[];
    updatedAt?: number;
}

export interface LastStateTabs extends Partial<Record<Routes, LastStateTab[]>> {}

export interface LastStateTab {
    entityId?: string;      // id of the entity being opened
    additionalInstanceData?: any;
    functionCalls?: FunctionCall[];
    tabName: string;
    removePaddingFromContainer?: boolean;
}

export interface LastStateChat {
    user: ChatEntity;
    eva: ChatEntity;
}

export interface UserLastState {
    createdAt: string; // initial creation of document
    updatedAt: string; // last updated
    lastState: LastState;
}

// TODO: Should this be moved?

/**
 * Contains information about the current active process
 */
export class ProcessState {
    constructor(
        public processId: string,
        public executingInteractionOriginalId: string,
        public interactionsValues: any,
        public query: string,
        public type: string
    ) {}
}
