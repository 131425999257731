import { Component, OnDestroy } from '@angular/core';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-layout',
  template: `
    <!-- <app-top-nav class="app-top-nav"></app-top-nav> -->
    <app-left-menu></app-left-menu>
    <div [class.full-background-spacing]="(menuNavigationService.isExpanded$ | async) === null || (menuNavigationService.isExpanded$ | async) === undefined || (menuNavigationService.isExpanded$ | async) === false" [class.full-background-spacing-expanded]="(menuNavigationService.isExpanded$ | async)">
      <app-home></app-home>
    </div>
  `,
  styles: []
})
export class HomeLayoutComponent implements OnDestroy {
  expandSub: Subscription;

  constructor(public menuNavigationService: MenuNavigationService) { }

  ngOnDestroy(): void {
    if (this.expandSub) {
      this.expandSub.unsubscribe();
    }
  }
}
