import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { formatDate } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-info-display',
  templateUrl: './user-info-display.component.html',
  styleUrls: ['./user-info-display.component.scss']
})
export class UserInfoDisplayComponent implements OnInit {

  user: any;
  userDataCollection: AngularFirestoreCollection<any>;
  public userData$: Observable<Array<any>>;
  public userSubscription: Subscriber<any>;
  userPublicKey: string;
  newPublicKey: string;

  toolTipContent: string;

  @Input()
  set publicKey(userPublicKey: string) {
    // if input changes and is already set/initialized, get the user again
    if (this.userPublicKey && userPublicKey !== this.userPublicKey) {
      this.userPublicKey = userPublicKey;
      this.findByPublicKey();
      return;
    }

    this.userPublicKey = userPublicKey;
  }

  @Input() processId?: string;

  constructor(
    private _afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private _firestoreService: FirestoreService,
    private _router: Router,
    private _route: ActivatedRoute,
    public authService: AuthService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    if (!this.userPublicKey) {
      this.userPublicKey = this._route.snapshot.paramMap.get('userPublicKey');
    }

    this.findByPublicKey();
  }

  findByPublicKey() {
    if (this.newPublicKey) {
      this.userPublicKey = this.newPublicKey;
    }

    this.userData$ = this._firestoreService.colWithIds$('UserDirectory', ref => ref.where('publicKey', '==', this.userPublicKey));

    this.userData$
    .pipe(
      take(1)
    )
    .subscribe(
      (userInfo) => {
        if ( userInfo && Array.isArray(userInfo) && userInfo.length > 0 ) {
          this.user = userInfo[0];
        } else if ( userInfo ) {
          this.user = userInfo;
        }

        this.toolTipContent = `
          Email: ${ this.user.emailAddress }
          Name: ${ this.user.preferredName }
          Created At: ${ formatDate( this.user.createdAt.seconds * 1000, 'medium', this.locale) }
          Updated At: ${ formatDate( this.user.updatedAt.seconds * 1000, 'medium', this.locale) }
          uid: ${ this.user.uid }
          ${ ( this.processId ) ? `Process Id: ${ this.processId }` : '' }
        `;
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }
}
