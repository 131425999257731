import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { WorkFlowCondition } from '@eva-model/workflow';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionService } from '@eva-services/workflow-interaction-condition/workflow-interaction-condition.service';
import { WorkflowDestinationPickerService } from '@eva-services/workflow-destination-picker/workflow-destination-picker.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { workflowInteractionDest } from '@eva-ui/shared/constants';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'eva-workflow-destination-picker',
  templateUrl: './workflow-destination-picker.component.html',
  styleUrls: ['./workflow-destination-picker.component.scss']
})
export class WorkflowDestinationPickerComponent implements OnInit, OnDestroy {

  destinationTreeNodes: TreeNode[] = [];
  selectedDestionations: TreeNode[] = [];
  selectedGroupDestinations: any[];
  selectedGroups: any[] = [];

  interactionId: string;
  interactionVersion: number;
  userGroups: any[];
  conditionId: string;
  interactionsByGroups: any;
  interactionConditions: WorkFlowCondition[] = [];

  interactionConditionChangeSubscription: ISubscription;

  public userWorkflowDestinationGroups: any[];

  @Input()
  set workflowInteractionId(workflowInteractionId: string) {
    if ( !workflowInteractionId ) { return; }
    this.interactionId = workflowInteractionId;
  }

  @Input()
  set workflowInteractionVersion(workflowInteractionVersion: number) {
    if ( !workflowInteractionVersion ) { return; }
    this.interactionVersion = workflowInteractionVersion;
  }

  @Input()
  set interactionConditionId(interactionConditionId: string) {
    if ( !interactionConditionId ) { return; }
    this.conditionId = interactionConditionId;
  }

  @Input() conditionResult: boolean;   // True/False to set destinations for True or False result of conditions

  @Input()
  set interactionsByGroupsObj(interactionsByGroupsObj: string) {
    if ( !interactionsByGroupsObj ) { return; }
    this.interactionsByGroups = JSON.parse(interactionsByGroupsObj);
  }

  @Input()
  set interactionConditionsObj(interactionConditionsObj: WorkFlowCondition[]) {
    if ( !interactionConditionsObj || !Array.isArray(interactionConditionsObj) ) { return; }
    this.interactionConditions = interactionConditionsObj;
  }

  constructor(
    private destinationPickerService: WorkflowDestinationPickerService,
    private interactionConditionService: WorkflowInteractionConditionService,
    public evaGlobalService: EvaGlobalService
  ) {
    this.userWorkflowDestinationGroups = [];
    this.userWorkflowDestinationGroups.length = 0;

    if ( this.evaGlobalService.userGroups &&
      Array.isArray(this.evaGlobalService.userGroups) &&
      this.evaGlobalService.userGroups.length > 0 ) {
      this.userWorkflowDestinationGroups = this.evaGlobalService.userGroups.slice();
    }

    if ( this.evaGlobalService.processFlowThroughGroup ) {
      const grpIdx =
        this.userWorkflowDestinationGroups.map(g => g.groupPublicKey).indexOf(this.evaGlobalService.processFlowThroughGroup.groupPublicKey);
      if ( grpIdx === -1 ) {   // Add if it doesn't exist
        this.userWorkflowDestinationGroups.push( this.evaGlobalService.processFlowThroughGroup );
      }
    }
  }

  ngOnInit() {
    this.selectedGroupDestinations = [];
    this.selectedGroupDestinations.length = 0;

    this.generateTreeNodes();
    this.setSelectedDestinations();

    this.interactionConditionChangeSubscription =
      this.interactionConditionService.conditionsChanged$
      .subscribe(
        interactionConditionsChangeObj => {
        if ( interactionConditionsChangeObj ) {
          this.interactionConditions = interactionConditionsChangeObj;
          this.generateConditionTreeNodes();
        }
      },
      err => { console.log(err); }
    );
  }

  private setSelectedDestinations() {
    const conditionIndex = this.interactionConditions.map(condition => condition.id).indexOf(this.conditionId);
    if (conditionIndex === -1) return;

    const intrctConditionDests = (this.conditionResult)
      ? this.interactionConditions[conditionIndex].trueDestination
      : this.interactionConditions[conditionIndex].falseDestination;

    if (!this.selectedDestionations || !Array.isArray(this.selectedDestionations) || this.selectedDestionations.length <= 0) {
      this.selectedDestionations = [];
    }

      intrctConditionDests.forEach(dest => {
        if (dest.type === workflowInteractionDest.condition) {

          const conditionNodes = this.destinationTreeNodes.filter(node => node.data.type === 'condition' && node.data.id === dest.id);
          if (conditionNodes && Array.isArray(conditionNodes) && conditionNodes.length > 0) {
            this.selectedDestionations.push(conditionNodes[0]);
          }

        } else if (dest.type === workflowInteractionDest.group) {

          const destGrp = this.evaGlobalService.userGroups.filter( grp => grp.groupPublicKey === dest.publicKey );
          if (destGrp && Array.isArray(destGrp) && destGrp.length > 0) {
            const grp = destGrp[0];
            this.selectedGroups.push(grp);

            const groupData = {
              "type": "group",
              "name": grp.groupName,
              "publicKey": grp.groupPublicKey,
              "icon": "fas fa-object-group"
            };
            this.selectedGroupDestinations.push(groupData);
          }

        } else if (dest.type === workflowInteractionDest.interaction) {

          const grpNodes =
            this.destinationTreeNodes.filter(node => node.data.type === 'group' && node.data.publicKey === dest.groupPublicKey);
          if (grpNodes && Array.isArray(grpNodes) && grpNodes.length > 0) {
            const interactionNodes = grpNodes[0].children;
            if (interactionNodes && Array.isArray(interactionNodes) && interactionNodes.length > 0) {
              const interactionNode = interactionNodes.filter(node => node.data === dest.id);
              if (interactionNode && Array.isArray(interactionNode) && interactionNode.length > 0) {
                const interactionVersionNodes = interactionNode[0].children;
                if (interactionVersionNodes && Array.isArray(interactionVersionNodes) && interactionVersionNodes.length > 0) {
                  const interactionVersionNode = interactionVersionNodes.filter(node => node.data.version === dest.version);
                  if (interactionVersionNode && Array.isArray(interactionVersionNode) && interactionVersionNode.length > 0) {
                    this.selectedDestionations.push(interactionVersionNode[0]);
                  }
                }
              }
            }
          }

        }
      });
  }

  ngOnDestroy() {
    if ( this.interactionConditionChangeSubscription ) {
      this.interactionConditionChangeSubscription.unsubscribe();
    }
  }

  generateTreeNodes() {

    this.destinationTreeNodes = [];
    this.destinationTreeNodes.length = 0;

    this.evaGlobalService.userGroups.forEach(grp => {
      const groupData = {
        "type": "group",
        "name": grp.groupName,
        "publicKey": grp.groupPublicKey,
        "icon": "fas fa-object-group"
      };

      const groupNode: TreeNode = {
        "label": grp.groupName,
        "data": groupData,
        "expandedIcon": "fas fa-object-group",
        "collapsedIcon": "fas fa-object-group",
        "expanded": false,
        "selectable": false,
        "styleClass": "selectableTreeNode",
        "children": []
      };

      this.interactionsByGroups[grp.groupPublicKey].forEach(intrct => {
        if ( intrct.id !== this.interactionId ) {
          const interactionNode: TreeNode = {
            "label": intrct.name,
            "data": intrct.id,
            "expandedIcon": "far fa-file",
            "collapsedIcon": "far fa-file",
            "expanded": false,
            "selectable": false,
            "styleClass": "unSelectableTreeNode",
            "children": []
          };

          intrct.Versions.forEach(intrctVer => {
            const interactionData = {
              "type": "interaction",
              "name": intrct.name,
              "id": intrct.id,
              "version": intrctVer.version,
              "groupPublicKey": intrct.groupPublicKey,
              "icon": "far fa-file"
            };

            const interactionVersionNode: TreeNode = {
              "label": new Date(intrctVer.version).toLocaleString(),
              "data": interactionData,
              "expandedIcon": "fas fa-code-branch",
              "collapsedIcon": "fas fa-code-branch",
              "expanded": false,
              "selectable": true,
              "styleClass": "selectableTreeNode",
              "children": []
            };

            interactionNode.children.push(interactionVersionNode);
          });

          groupNode.children.push(interactionNode);
       }
      });

      if ( groupNode.children && Array.isArray(groupNode.children) && groupNode.children.length > 0 ) {
        this.destinationTreeNodes.push(groupNode);
      }
    });

    this.generateConditionTreeNodes();
  }

  private generateConditionTreeNodes() {
    const destNodes =
      this.destinationTreeNodes
        .map( node => node.data )
        .filter( node => node.type === 'condition')
        .map( node => node.id );

    // Cleanup the condition destinations which don't exist anymore
    this.cleanDestinationTree();

    this.interactionConditions.forEach(condition => {
      if (condition.id !== this.conditionId) {
        // TODO :: if exists then ignore it
        // TODO :: if doesn't exist then add it

        const conditionIndex = destNodes.indexOf( condition.id );
        if ( conditionIndex === -1 ) {
          const conditionData = {
            "type": "condition",
            "name": condition.name,
            "id": condition.id,
            "icon": "fa fa-random"
          };

          const conditionNode: TreeNode = {
            "label": condition.name,
            "data": conditionData,
            "expandedIcon": "fa fa-random",
            "collapsedIcon": "fa fa-random",
            "expanded": true,
            "selectable": true,
            "styleClass": "selectableTreeNode",
            "children": []
          };

          this.destinationTreeNodes.push(conditionNode);
        }
      }
    });
  }

  cleanDestinationTree() {
    // TODO :: if a node dropped then remove it from destinationTreeNodes
    const destNodes =
      this.destinationTreeNodes
        .map( node => node.data )
        .filter( node => node.type === 'condition')
        .map( node => node.id );

    const conditions = this.interactionConditions.map( conditionItem => conditionItem.id ).filter( id => id !== this.conditionId );
    const destNodesToRemove = destNodes.filter( id => conditions.indexOf(id) === -1);
    if ( destNodesToRemove && Array.isArray(destNodesToRemove) && destNodesToRemove.length > 0 ) {
      destNodesToRemove.forEach(id => {
        const indexToRemove = this.destinationTreeNodes.findIndex(node => node.data.type === 'condition' && node.data.id === id);
        if (indexToRemove !== -1) {
          this.destinationTreeNodes.splice(indexToRemove, 1);
        }
      });
    }
  }

  nodeSelect(event) {
    if ( !(event && event.node) ) { return; }

    if ( event.node && event.node.data && event.node.data.type ) {
      this.selectedDestionations = [];
      this.resetDestinations();
      this.selectedDestionations.push(event.node);
    }

    //#region Remark
    // if ( event.node && event.node.data && event.node.data.type ) {
    //   if ( event.node.data.type === workflowInteractionDest.interaction &&
    //     event.node.parent && event.node.parent.parent && event.node.parent.parent.data &&
    //     event.node.parent.parent.data.type === workflowInteractionDest.group ) {
    //     this.resetDestinations();
    //     this.selectedDestionations.push(event.node.parent.parent);
    //     this.selectedDestionations.push(event.node);
    //   } else if (
    //     event.node.data.type === workflowInteractionDest.group ||
    //     event.node.data.type === workflowInteractionDest.condition ) {
    //       this.selectedDestionations = [];
    //       this.resetDestinations();
    //       this.selectedDestionations.push(event.node);
    //   }
    // }
    //#endregion

    this.destinationPickerService.announceChange(this.getdestinations());
  }

  resetDestinations() {
    this.selectedDestionations = [];
    this.selectedDestionations.length = 0;
  }

  nodeUnSelect(event) {
    // Now that destination can be [a Group]/[a Group & an Interaction]/[a Condition] the unselect means reset (no destination)
    this.resetDestinations();
    this.destinationPickerService.announceChange(this.getdestinations());
  }

  grpSelectionOnChange($event) {
    this.selectedGroupDestinations = [];
    this.selectedGroupDestinations.length = 0;

    if ( $event.value && Array.isArray($event.value) && $event.value.length > 0 ) {
      $event.value.forEach( grp => {
        const groupData = {
          "type": "group",
          "name": grp.groupName,
          "publicKey": grp.groupPublicKey,
          "icon": "fas fa-object-group"
        };
        this.selectedGroupDestinations.push(groupData);
      });
    }

    this.destinationPickerService.announceChange(this.getdestinations());
  }

  getdestinations() {
    return {
      "conditionId": this.conditionId,
      "conditionResult": this.conditionResult,
      "selectedDestinations": this.selectedGroupDestinations.concat(this.selectedDestionations.map( dest => dest.data ))
      // this.selectedDestionations.map( dest => dest.data )
    };
  }

}
