import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'algoliaHitsDisplay'
})
export class AlgoliaHitsDisplayPipe implements PipeTransform {

  transform(value: any): any {
    let returnedValue = null;

    // check type and store it
    const type = typeof value;

    if (type === 'string') {
      returnedValue = value;
    }

    // if object, check if it has a value property.. if yes, we probably need that.
    if (type === 'object') {
      if (value['value'] !== undefined) {
        returnedValue = value['value'];
      }
    }

    // check if the return value *might* be a date timestamp
    if (type === 'number') {
      returnedValue = this.convertTimestampToDate(value);
    }

    if (typeof returnedValue === 'number') {
      if (returnedValue.toString().length === 13) {
        // assume it's a date timestamp and format it like Angular' DatePipe 'short'...
        returnedValue = moment(returnedValue).format('MM/DD/YY, h:mm A');
      }
    }

    return returnedValue;
  }

  convertTimestampToDate(time: number): string {
    return moment(time).format('MM/DD/YY, h:mm A');
  }

}
