<h3 *ngIf="childGroupInvitationService.allChildGroupInvitations.length > 0" class="label" matSubheader>Dynamic Data Group Invitations</h3>
<mat-list>
  <mat-list-item *ngFor="let invitation of childGroupInvitationService.allChildGroupInvitations">
    <mat-icon matListIcon>email</mat-icon>
    <h3 matLine>The group <b>{{ invitation.parentGroupName }}</b> has invited your group <b>{{ invitation.childGroupName }}</b><br>to share their dynamic data.</h3>
    <h3 matLine *ngIf="invitation.data.unencryptedData.message">They sent a message:</h3>
    <h3 matLine *ngIf="invitation.data.unencryptedData.message"><i>"{{ invitation.data.unencryptedData.message }}"</i></h3>
    <h3 matLine>If you accept, you accept on behalf of all members of your group.</h3>
    <h3 matLine>If you decline, you decline on behalf of all members of your group.</h3>
    <button mat-raised-button color="primary" type="button" (click)="acceptInvitation(invitation)" [disabled]="invitation.accepting || invitation.declining">
      <mat-spinner diameter="24" *ngIf="invitation.accepting"></mat-spinner>
      <span *ngIf="!invitation.accepting">Accept</span>
    </button>
    <button mat-raised-button color="accent" type="button" (click)="declineInvitation(invitation)" [disabled]="invitation.accepting || invitation.declining">
      <mat-spinner diameter="24" *ngIf="invitation.declining"></mat-spinner>
      <span *ngIf="!invitation.declining">Decline</span>
    </button>
  </mat-list-item>
</mat-list>


