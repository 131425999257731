import { Component, Input, SimpleChanges } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'eva-process-detail-viewer',
  templateUrl: './process-detail-viewer.component.html',
  styleUrls: ['./process-detail-viewer.component.scss']
})
export class ProcessDetailViewerComponent {

  @Input()
  set userGroups(userGroups: any[]) {
    this.allUserGroups = userGroups;
    if ( this.prcsGrpPublicKey ) this.prcsGrpName = this.getGroupNameByPublicKey(this.prcsGrpPublicKey);
  }
  @Input() prcsName: string;
  @Input()
  set prcsGroupPublicKey(prcsGroupPublicKey: string) {
    this.prcsGrpPublicKey = prcsGroupPublicKey;
    if ( this.prcsGrpPublicKey )  this.prcsGrpName = this.getGroupNameByPublicKey(this.prcsGrpPublicKey);
  }
  @Input() prcsStatus: string;
  @Input()
  set prcsCreatedTimeStamp(prcsCreatedTimeStamp: number) {
     const dt = new Date(prcsCreatedTimeStamp);
     this.prcsCreatedDateTimeString = dt.toLocaleString();
  }
  @Input()
  set prcsLastUpdateTimeStamp(prcsLastUpdateTimeStamp: number) {
    const dt = new Date(prcsLastUpdateTimeStamp);
    this.prcsLastUpdateDateTimeString = dt.toLocaleString();
  }

  @Input() prcsWorkflowName: string;
  @Input() prcsInteractionName: string;

  allUserGroups: any[];
  prcsGrpName: string;
  prcsGrpPublicKey: string;
  prcsCreatedDateTimeString: string;
  prcsLastUpdateDateTimeString: string;

  constructor(public evaGlobalService: EvaGlobalService) { }

  getGroupNameByPublicKey(groupPk: string) {
    return this.evaGlobalService.getGroupNameByPublicKey(groupPk);
    // const grpIndex = this.allUserGroups.map(grp => grp.groupPublicKey).indexOf(groupPk);
    // return (grpIndex !== -1) ? this.allUserGroups[grpIndex].groupName : null;
  }
}
