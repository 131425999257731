<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="false" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Map matching destinations</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="form-container">
      <div class="small-width">
        <div class="type-title sticky-header old-sticky-header">
          New Destinations
          <div>
            <button class="select-all" (click)="selectAll()"
              [class.disabled]="selectedGroup || (!areAllGroupsSelected() && pairs.length !== 0)">Select all</button>
            <button class="select-all" (click)="selectAllSame()" [class.disabled]="!selectedGroup">Select all
              same</button>
          </div>
        </div>
        <div class="list-items">
          <div *ngFor="let group of newGroups; let groupIndex = index;">
            <fieldset class="list-item" [class.disabled]="this.pairs[0] && !this.pairs[0].old && selectedGroup && selectedGroup.group.publicKey !== group.group.publicKey"
              [class.selected]="group.selected" [class.wrong-selected]="false" (click)="selectGroup(group)">
              <legend class="list-item-legend">ID:
                {{group.group.publicKey.substr(0, 15) + '...' + group.group.publicKey.substr(group.group.publicKey.length - 16, group.group.publicKey.length - 1)}}
              </legend>
              Group Name : {{group.selected 
                ? getMatchedGroupName(group) !== '' 
                  ? getMatchedGroupName(group) 
                  : group.group.name 
                : group.group.name}} <br />
              Condition Name : {{group.conditionName}} <br />
              Interaction Name : {{group.interactionName}}

            </fieldset>
          </div>
        </div>
      </div>
      <span class="vertical-border"></span>
      <div class="big-width">
        <div class="type-title sticky-header">
          Old Destinations
        </div>
        <mat-horizontal-stepper [class.disabled]="(!isNewSelected() || pairs.length === 0)" #stepperCtrl [linear]="true"
          #stepper="matHorizontalStepper">

          <mat-step [stepControl]="selectUserGroupFormGroup" [optional]=false>
            <div class="card-container">
              <form [formGroup]="selectUserGroupFormGroup" #selectUserGroupForm class="fullWidth">
                <ng-template matStepLabel>Choose a group</ng-template>

                <mat-form-field class="fullWidth">
                  <mat-select placeholder="User group" formControlName="selectGroupCtrl" required>
                    <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupPublicKey">
                      {{ userGroup.groupName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div>
                  <button
                    [class.disabled]="!selectUserGroupFormGroup.value.selectGroupCtrl || selectUserGroupFormGroup.value.selectGroupCtrl === ''"
                    mat-button (click)="onGroupSelect()">Select</button>
                </div>

              </form>
            </div>
          </mat-step>

        </mat-horizontal-stepper>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [class.disabled]="!isValid()" mat-raised-button [mat-dialog-close]="{pairs: pairs}"
      color="primary">Submit</button>
    <button mat-stroked-button (click)="onReset(stepperCtrl, selectUserGroupForm)">Reset</button>
    <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
  </mat-dialog-actions>
</div>