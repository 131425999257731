import { Component } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Observable } from 'rxjs';
import { ChildGroupInvitationService } from '@eva-services/childGroupInvitation/child-group-invitation.service';
import { LoggingService } from '@eva-core/logging.service';
import { SigningService } from '@eva-core/signing.service';
import { LogService } from '@eva-core/log/log.service';
import { LogLevel, EntityType, Log } from '@eva-model/log';
import { environment } from '@environments/environment.spark-test';
import { FirestoreService } from '@eva-services/firestore/firestore.service';

@Component({
  selector: 'app-child-group-invite-notifications',
  templateUrl: './child-group-invite-notifications.component.html',
  styleUrls: ['./child-group-invite-notifications.component.scss']
})
export class ChildGroupInviteNotificationsComponent {

  allChildGroups$: Observable<any[]>[];
  allInvitations$: Observable<any[][]>;

  /**
   * Constructor for the ChildGroupInviteNotificationsComponent class
   *
   * @param _evaGlobalService - the EVA Global Service
   * @param _firestoreAtbDynamicService - the Firestore service for the ATB Dynamic Forms database
   * @param childGroupInvitationService - the child group invitation service
   * @param _loggingService - service for logging messages to the user
   * @param _signingService - the signing service
   * @param _logService - service for logging messages to firebase
   */
  constructor(private _evaGlobalService: EvaGlobalService,
    private _firestoreService: FirestoreService,
    public childGroupInvitationService: ChildGroupInvitationService,
    private _loggingService: LoggingService,
    private _signingService: SigningService,
    private _logService: LogService) { }

  /**
   * Accepts the invitation the user clicked on.
   *
   * @param invitation - the invitation being accepted
   */
  async acceptInvitation(invitation: any) {
    invitation.accepting = true;
    const isValid = await this.validateInvitation(invitation);
    if (isValid) {
      try {
        //  ||| must recode this
        const groupType = environment.blockConfig.types.groups.types.dynamicData;
        await this.childGroupInvitationService.acceptInvitation(invitation, groupType);
        this._loggingService.logMessage('You have accepted the invitation.', false, 'success');
        return;
      } catch (err) {
        await this.logToFirebase('Error sending the acceptance to the blockchain. '
          + 'Invitation id = ' + invitation.id + ', '
          + 'Error = ' + err);
        this._loggingService.logMessage('There was an error accepting the invitation.', false, 'error');
        invitation.accepting = false;
        return;
      }
    } else {
      await this.logToFirebase('Invalid invitation signature. '
        + 'Invitation id = ' + invitation.id + '.');
      const childGroupPublicKey = invitation.data.unencryptedData.childGroupPublicKey;
      try {
        await this.childGroupInvitationService.deleteInvitation(invitation.id, childGroupPublicKey);
        this._loggingService.logMessage(`The invitation signature was invalid, therefore the validity of the request
          could not be determined. We have deleted the request to keep you and your group safe.`, false, 'info');
        invitation.accepting = false;
        return;
      } catch (err) {
        await this.logToFirebase('Error deleting invitation. '
          + 'Invitation id = ' + invitation.id + ', '
          + 'Error = ' + err);
        this._loggingService.logMessage('There was an error deleting the invitation.', false, 'error');
        invitation.accepting = false;
        return;
      }
    }
  }

  /**
   * Declines the invitation the user clicked on.
   *
   * @param invitation - the invitation being declined
   */
  async declineInvitation(invitation) {
    invitation.declining = true;
    const childGroupPublicKey = invitation.data.unencryptedData.childGroupPublicKey;
    try {
      await this.childGroupInvitationService.deleteInvitation(invitation.id, childGroupPublicKey);
      this._loggingService.logMessage('You have declined the invitation.', false, 'success');
    } catch (err) {
      await this.logToFirebase('Error deleting invitation. '
        + 'invitation id = ' + invitation.id + ', '
        + 'Error = ' + err);
      this._loggingService.logMessage('Problem declining your invitation. Please try again by refreshing the page.', false, 'error');
      invitation.declining = false;
    }
  }

  //#region invitation validation

  /**
   * Validates the invitation.
   *
   * @param invitation - The invitation to validate
   */
  private async validateInvitation(invitation: any): Promise<boolean> {
    const isSignatureValid = this.validateInvitationSignature(invitation);
    try {
      const isInviterParentGroupMember = await this.validateInviterParentGroupMembership(invitation);
      if (isSignatureValid && isInviterParentGroupMember) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  /**
   * Validates the signature on the invitation.
   *
   * @param invitation - The invitation containing the unencrypted data and the signature.
   */
  private validateInvitationSignature(invitation: any): boolean {
    return this._signingService.validateSignedObject(invitation);
  }

  /**
   * Validates that the inviter is a member of the parent group.
   *
   * @param invitation - The invitation container the inviter and parent group public keys.
   */
  private async validateInviterParentGroupMembership(invitation: any): Promise<Boolean> {
    const parentGroupPublicKey = invitation.data.unencryptedData.parentGroupPublicKey;
    const inviterPublicKey = invitation.data.publicKey;
    const parentGroupDocRef = this._firestoreService.col('GroupSigningKeys').doc(parentGroupPublicKey).ref;

    try {
      const parentGroupDoc = await parentGroupDocRef.get();
      if (parentGroupDoc.exists) {
        const groupData = parentGroupDoc.data() as any;
        if (groupData && groupData.groupSigningMembership.indexOf(inviterPublicKey) > -1) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      } else {
        await this.logToFirebase('Parent group ' + parentGroupPublicKey + ' does not exist.');
        return Promise.resolve(false);
      }
    } catch (err) {
      return Promise.reject(false);
    }
  }

  //#endregion

  /**
   * Uses Log service to log an error messages to firebase.
   *
   * @param message - the message to log
   */
  async logToFirebase(message: string) {
    if (this._evaGlobalService && this._evaGlobalService.userId) {
      const log = new Log(
        LogLevel.Error,
        EntityType.other,
        null,
        message,
        this._evaGlobalService.userId);
      try {
        await this._logService.error(log);
      } catch (err) {
        console.log('The following message could not be logged to firebase:', message);
      }
    } else {
      console.log('The following message could not be logged to firebase:', message);
    }
  }
}
