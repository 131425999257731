/**
 * This contains the main menu file for the EVA application (default EVA Screen)
 */
import { EVAMenu } from "@eva-model/menu/menu";
import { processDashboardSVGIcon } from "./icons";
import { Routes } from "./mainMenu";

export const DashboardMenu: EVAMenu[] = [{
    routerLink: Routes.ProcessDashboard,
    svgIcon: processDashboardSVGIcon,
    text: 'Process'
}
];
