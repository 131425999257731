import { WorkFlow, WorkflowInteraction, WorkFlowCondition } from "@eva-model/workflow";

/**
 * This is the types for a process. If you update this file, you must update the following projects as well:
 * DynamicForms-FirebaseFunctions
 * EVA-Blockchain
 */
export class Process {

    constructor(
        public id: string,
        public name: string,
        public submitterPublicKey: string,
        public acknowledgerPublicKey: string,
        public destinationPublicKey: string,
        public groupPublicKey: string,
        public descriptions: string[],
        public workflows: WorkFlow[],
        public executingWorkflowId: string,
        public executingInteractionId: string,
        public interactionsValues: ProcessInteractionValues[],
        public status: string,   // Created/InProgress/Done/Reject
        public isDone: boolean,
        public lastUpdatePublicKey: string,
        public createTimestamp: number,
        public lastUpdateTimestamp?: number,
        public notes?: string,
        public storageId?: string
    ) {}
}

/**
 * This interface is used to when values are flattened and used to separate the data from an interaction.
 * This is primarily used when encrypting and storing values, or unencrypting and updating an interaction
 * with the data that comes back from EVA Storage.
 */
export class ProcessInteractionValues {
    constructor(
        public workflowId: string,
        public interactionId: string,
        public interactionValues: any,
        public conditionDestinations: any[],
        public order?: number,
        public submitted?: boolean,
        public previouslyCompleted?: boolean
    ) {}
}

/**
 * This is the enum for all process statuses.
 */
export enum ProcessStatus {
    Done = "Done",
    Reject = "Reject",
    InProgress = "InProgress",
    Created = "Created"
}

/**
 * This is a breakdown of a process record to be used to find specific processes in a report
 * It is a subset of the Process object above.
 */
export interface ProcessStatusRecord {
    id: string;
    createTimestamp: number;
    lastUpdateTimestamp: number;
    status: ProcessStatus;
    workflowName: string;
    submitterKey: string;
}

/**
 * This is a breakdown of a process record to be used to find specific processes in a report view on ag-grid.
 * It is used when converting a timestamp to a date string object.
 * It is a subset of the Process object above.
 */
export interface ProcessStatusRecordDisplay {
    processId: string;
    createdAt: string;
    updatedAt: string;
    status: ProcessStatus;
    workflowName: string;
    submitterKey: string;
}

/**
 * This is the request body for a process status report used in the process status service.
 */
export interface ProcessStatusReportRequestBody {
    uid: string;
    from: number;
    to: number;
}
/*
 * this interface is used to keep the shape of sensitive data in the process. We store data to an object like this to
 * use to encrypt the data anse store into EVA links. This is the interface of the sensitive data right before storage
 * or right after the data has been decrypted.
 */
export interface ProcessSensitiveData {
    name: string; // the name the user gave the process
    descriptions: string[]; // the descriptions provided in the process.
    interactionsValues: ProcessInteractionValues[]; // all interaction Values and their shapes
    notes: string; // notes the user has included in the process.
}
/**
 * this interface is used to display a message to a user when a process finishes
 * used to announce through a behaviour subject in the process service
 */
export interface ProcessAnnouncemenMessage {
    processId: string; // the id of the process sneding the messaghe
    msg: string; // message to display for the user when a process finishes
}

/**
 * this interface is used to announce changes in the process summary to the
 * process summary observable
 */
export interface ProcessSummaryMessage {
    processId: string;
    processRunnerId: string;
    isClose?: boolean; // whether a close button was clicked.
    isDone?: boolean; // whether the done button was clicked.
}
