
<div fxLayout="row" fxLayoutAlign="start">
    <div fxFlexFill>
        <div fxFlex="25%" class="">
        </div>
        <div fxFlex="50%" class="">
            <mat-card *ngIf="isIE">
                <mat-card-header class="ie-dead-end-card-header">
                    <mat-card-title class="ie-dead-end-title">
                        Warning
                    </mat-card-title>
                    <mat-card-subtitle class="ie-dead-end-subtitle">
                        Internet Explorer
                    </mat-card-subtitle>

                    <img mat-card-image class="ie-dead-end-card-header-img"
                        src="../../assets/images/eva-subscription.png" alt="Photo of ATB and EVA">
                </mat-card-header>
                <mat-card-content> 
                    <br/>
                    Hi! <br/><br/>
                    We see you're trying to open EVA in an Internet Explorer browser.<br/>
                    EVA doesn't work well in Internet Explorer.<br/>
                    For the best EVA experience, please go to your Google Chrome browser and open EVA there.<br/><br/>
                    Thanks, and have a great automated day!<br/>
                </mat-card-content> 
            </mat-card>
        </div>
        <div fxFlex="25%" class="">
        </div>
    </div>
</div>
