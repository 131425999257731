import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interactionElementFilter'
})
export class InteractionElementFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value;
  }

  // transform(items: any[], filter: any) {
  //   if (!items || !Array.isArray(items) || !filter || !filter.originalId)  {
  //       return items;
  //   }
  //   // filter items array, items which match and return true will be
  //   // kept, false will be filtered out
  //   return items.filter(item => item.originalId.indexOf(filter.originalId) !== -1);
  // }
}
