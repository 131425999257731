import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringSeperatorDisplay'
})
export class StringSeperatorDisplayPipe implements PipeTransform {
  transform(stringToUpdate: string, delimitter: string, replacement: string): string {
    return stringToUpdate.split(delimitter).join(replacement);
  }
}
