<h2 mat-dialog-title>
  Link to a Knowledge Document
</h2>

<mat-dialog-content>
  <div class="app-loading" *ngIf="isLoading; else knowledgeSearch">
    <mat-spinner diameter=100></mat-spinner>
  </div>

  <ng-template #knowledgeSearch>
    
    <ais-instantsearch *ngIf="!isLoading" [config]="searchConfig" (change)="onSearchChange($event)">
      <ais-configure [searchParameters]="searchParams"></ais-configure>

      <!-- Search Element -->
      <mat-card>
        <mat-card-content>
          <mat-form-field>
            <input matInput type="text" placeholder="Search by Name" [(ngModel)]="searchParams.query" cdkFocusInitial>
            <button mat-button *ngIf="searchParams.query" matSuffix mat-icon-button aria-label="Clear" (click)="searchParams.query=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <mat-card *ngFor="let doc of hits" (click)="selectDocumentResult(doc)" class="document-choice" [ngClass]="{'selected':selectedDoc && selectedDoc.id === doc.id}">
        <mat-card-title>{{doc.name}}</mat-card-title>
        <mat-card-subtitle>Last edited {{doc.updatedDate | date:'MMM d, yyyy'}}</mat-card-subtitle>
      </mat-card>

      <mat-card>
        <ais-pagination></ais-pagination>
      </mat-card>

    </ais-instantsearch>

  </ng-template>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="space-between center">
  <div>
    &nbsp;
  </div>
  <div>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="closeDialogAndEmitData()" [disabled]="!selectedDoc">Add</button>
  </div>
</mat-dialog-actions>