import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class OnlyNumberInputDirective {

  private hasDecimalPoint = false; // whether the element has a decimal point.
  // common actions that may occur.
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
    ' '
  ];
  isNumberOnlySet: boolean;

  @Input() decimal ? = false; // flag for decimals allowed in input
  @Input() decimalSeparator ? = '.'; // seperator for the decimal
  // @Input() isNumberOnly ? = 'false';
  @Input()
  set isNumberOnly(val: boolean) {
    this.isNumberOnlySet = val;
  }
  // set isNumberOnly(value: any) {
  //   this.isNumberOnlySet = value !== 'false';
  // } // whether only numbers can be entered.

  inputElement: HTMLInputElement; // the html element that contains the directive.
  regex: RegExp; // a regular expression that was included in the element

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement; // define the input element.
    if (this.inputElement.pattern) { // check if a regex exists on the element.
      this.regex = new RegExp(this.inputElement.pattern);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    // only check the key down event when the isNumberOnly is true;
    if (this.isNumberOnlySet) {
      if (
        this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
        (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
        (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
        (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
        (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
        (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
        (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
        (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
        (e.key === 'x' && e.metaKey === true) || // Allow: Cmd+X (Mac)
        (this.decimal && e.key === this.decimalSeparator && !this.hasDecimalPoint) // Allow: only one decimal point
      ) {
        // let it happen, don't do anything
        return;
      }

      // Ensure that it is a number and stop the keypress
      if (e.key === ' ' || isNaN(Number(e.key))) {
        e.preventDefault();
      }

      // check the input pattern RegExp
      if (this.regex) {
        const newValue = this.forecastValue(e.key);
        if (!this.regex.test(newValue)) {
          e.preventDefault();
        }
      }
    } else {
      return;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if (this.isNumberOnlySet) {
      this.updateDecimalPoint();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.isNumberOnlySet) {
      const pastedInput: string = event.clipboardData.getData('text/plain');
      this.pasteData(pastedInput);
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (this.isNumberOnlySet) {
      const textData = event.dataTransfer.getData('text');
      this.inputElement.focus();
      this.pasteData(textData);
      event.preventDefault();
    }
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this.inputElement;
      this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
    }
    this.updateDecimalPoint();
  }

  private sanitizeInput(input: string): string {
    let result = '';
    if (this.decimal && this.isValidDecimal(input)) {
      const regex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
      result = input.replace(regex, '');
    } else {
      result = input.replace(/[^0-9]/g, '');
    }

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }
    return result;
  }

  private isValidDecimal(string: string): boolean {
    if (!this.hasDecimalPoint) {
      return string.split(this.decimalSeparator).length <= 2;
    } else {
      // the input element already has a decimal separator
      const selectedText = this.getSelection();
      if (selectedText && selectedText.indexOf(this.decimalSeparator) > -1) {
        return string.split(this.decimalSeparator).length <= 2;
      } else {
        return string.indexOf(this.decimalSeparator) < 0;
      }
    }
  }

  updateDecimalPoint(): void {
    if (this.decimal) {
      this.hasDecimalPoint =
        this.inputElement.value.indexOf(this.decimalSeparator) > -1;
    }
  }

  get precision(): number {
    return this.inputElement.value.split('.')[1].length || 0;
  }

  private getSelection(): string {
    return this.inputElement.value.substring(
      this.inputElement.selectionStart,
      this.inputElement.selectionEnd
    );
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart;
    const selectionEnd = this.inputElement.selectionEnd;
    const oldValue = this.inputElement.value;
    const selection = oldValue.substring(selectionStart, selectionEnd);
    return selection
      ? oldValue.replace(selection, key)
      : oldValue.substring(0, selectionStart) +
          key +
          oldValue.substring(selectionStart);
  }
}
