import { DynamicFormService, DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from '@ng-dynamic-forms/core';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { Subscription } from 'rxjs';
import { InteractionElementRelationService } from '@eva-services/interacton-element-relation/interaction-element-relation.service';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';
import * as _ from 'lodash';
import { IfThenLogicAction, IfThenLogicOptions, Relation, SubRelation } from '@eva-model/interactionElementRelationDialogModel';
import { LoggingService } from '@eva-core/logging.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'eva-interaction-element-relation',
  templateUrl: './interaction-element-relation.component.html',
  styleUrls: ['./interaction-element-relation.component.scss']
})
export class InteractionElementRelationComponent implements OnInit, OnDestroy {

  // @Input() formScreenIndex: number;
  // @Input() formElementIndex: number;
  // @Input() control: any;
  // @Input() isElementDisabled: boolean;

  /**
   * Creates the dynamic form by parsing the stringified version of it passed in.
   */
  @Input()
  set dynFrmObj(dynFrmObj: string) {
    if (!dynFrmObj) { return; }
    const dynamicFormObj = dynFrmObj;

    this.dynFrm = this.formBuilderService.cloneInteraction(dynamicFormObj, true);
  }

  dynFrm: any = {};
  candidateControlFormScreens: {
    elements: EvaDynamicFormControlModel[], elementOriginalId: string,
    screenName: string, screenIndex: number }[] = [];
  FORM_CONTROL_ELEMENTS: any[] = [];

  frmBuilderGrp: UntypedFormGroup;

  relationControls: { relation: Relation[], originalId?: string[] }[] = [];
  generalDialogChangeSubscription: Subscription;
  generalDialogFormGroupList: UntypedFormGroup[] = []; // this holds a list of all formGroups
  generalDialogFormModel = [];
  ifThenLogicOptions = IfThenLogicOptions;
  ifThenLogicActions = IfThenLogicAction;
  valueChanges = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private formBuilderService: FormBuilderService,
    private arrayUtil: ArrayUtilsService,
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService,
    private elementRelationService: InteractionElementRelationService,
    private conditionalObjectCheckerService: ConditionalObjectCheckerService,
    private ngDynFormService: DynamicFormService,
    private loggingService: LoggingService) {
      // initialize this.frmBuilderGrp
      this.createForm();
  }

  ngOnInit() {
    this.FORM_CONTROL_ELEMENTS = this.conditionalObjectCheckerService.formControlElementTypes;

    this.dynFrm.FormScreens.forEach((dynamicFormScreen: any, formScreenIndex: number) => {
      dynamicFormScreen.FormElements.forEach((formScreenElement: any, elementIndex: number) => {
        const candidateControlFormScreen = {
          elementOriginalId: formScreenElement.originalId,
          screenName: '',
          screenIndex: 0,
          elements: []
        };
        this.dynFrm.FormScreens.forEach((formScreen: any, screenIndex: number) => {
          if (screenIndex > formScreenIndex) {
            return;
          }
          candidateControlFormScreen.screenName = formScreen.name;
          candidateControlFormScreen.screenIndex = screenIndex;
          candidateControlFormScreen.elements = candidateControlFormScreen.elements.concat(
            formScreen.FormElements.map((formElement: any, index: number) => {
              formElement['index'] = index;

              const controlElement = this.FORM_CONTROL_ELEMENTS.find( (element) => {
                return element.type === formElement.type
                  && (formElement.inputType === undefined || element.inputType === formElement.inputType);
              });

              formElement['icon'] = (controlElement) ? controlElement.icon : '';
              return formElement;
            })
          );
        });

        this.candidateControlFormScreens.push(candidateControlFormScreen);

        let relations = this.dynFrm.FormScreens[formScreenIndex].FormElements[elementIndex]['additional']
        ? this.dynFrm.FormScreens[formScreenIndex].FormElements[elementIndex]['additional'].relation : [];

        if (!relations) {
          relations = [];
        }
        this.initRelation(relations, formScreenElement);
      });
    });

    this.relationControls = this.relationControls.sort((a, b) => a.relation[0].order - b.relation[0].order);
  }

  /**
   * This function creates the initial form config
   */
  createForm(): void {
    this.frmBuilderGrp = this.fb.group({
      connective: "AND",
      subConnective: "AND"
    });
  }

  /**
   * Initializes the relations passed in.
   *
   * @param relations the relations to initialize
   */
  initRelation(relations: Relation[], control: EvaDynamicFormControlModel): void {
    if (!(relations && Array.isArray(relations) && relations.length > 0)) { return; }

    this.conditionalObjectCheckerService.getRelationControls(this.dynFrm.FormScreens, control,
      this.relationControls, this.valueChanges, this.emitElementRelationChange, this.disableDynamicFormElements);
    this.emitElementRelationChange();
  }

 /**
  * This function emits the relation change
  *
  * @param relation The relation being emitted
  */
  emitElementRelationChange = (): void => {
    this.relationControls = [...this.relationControls];
    this.elementRelationService.announceElementRelationChange(this.relationControls);
  }

  ngOnDestroy() {
    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }
    if (this.valueChanges) {
      this.valueChanges.unsubscribe();
    }
  }

  /**
   * This function converts any object to JSON string
   *
   * @param object Object to stringify
   */
  jsonStringify(object: any): string {
    return JSON.stringify(object);
  }

  addRelation() {
    const newRelation: Relation = {
      id: null,
      subRelation: [{
        formGroup: null,
        if: {
          option: null,
          value: null
        },
        then: {
          action: null,
          option: null,
          value: null,
          enableOpposite: true,
          model: this.formBuilderService.createDynamicFormControlModel(this.dynFrm.FormScreens[0].FormElements[0], true),
          operations: [{
            operator: null,
            value: null
          }]
        }
      }],
      ifControl: [this.candidateControlFormScreens[0].elements[0]],
      thenControl: [this.dynFrm.FormScreens[0].FormElements[0]],
      order: this.relationControls.length
    };
    this.relationControls.push({
      relation: [newRelation],
      originalId: [newRelation.ifControl[0].originalId]
    });
    const controlIndex = this.relationControls.length - 1;
    this.relationControls[controlIndex].relation[0].subRelation[0].if.model
      = this.formBuilderService.createDynamicFormControlModel(
        newRelation.ifControl[0], true);
    this.relationControls[controlIndex].relation[0].subRelation[0].then.model
      = this.formBuilderService.createDynamicFormControlModel(
        newRelation.thenControl[0], true);
    this.relationControls[controlIndex].relation[0].id = this.relationControls[controlIndex].originalId;
    const newFormGroup = [];
    newFormGroup.push(this.relationControls[controlIndex].relation[0].subRelation[0].if.model);
    newFormGroup.push(this.relationControls[controlIndex].relation[0].subRelation[0].then.model);
    newRelation.subRelation[0].formGroup = this.ngDynFormService.createFormGroup(newFormGroup);
    this.setValueChangesSubscription();
    // if (controlIndex !== 0) {
    //   this.relationControls[controlIndex].relation[0].connective = 'AND';
    // }
    this.emitElementRelationChange();
  }

  addMathOperation(controlIndex: number, relationIndex: number, subRelationIndex: number) {
    if (!this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex].then.operations) {
      this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex].then.operations = [];
    }
    this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex].then.operations.push({
      operator: null,
      value: null
    });
    this.emitElementRelationChange();
  }

  removeMathOperation(controlIndex: number, relationIndex: number, subRelationIndex: number, operationIndex: number) {
    this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex].then.operations.splice(operationIndex, 1);
    this.emitElementRelationChange();
  }

  relationConnectiveChange(event: MatButtonToggleChange, controlIndex: number, relationIndex: number) {
    this.relationControls[controlIndex].relation[relationIndex].connective = event.value;
    this.emitElementRelationChange();
  }

  subRelationIfConnectiveChange(event: MatButtonToggleChange, controlIndex: number, relationIndex: number, subRelationIndex: number) {
    this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex].ifConnective = event.value;
    const relation: Relation = this.relationControls[controlIndex].relation[relationIndex];
    const subRelation: SubRelation = this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex];
    if (event.value === 'AND' || event.value === 'OR') {
      subRelation.formGroup.controls[subRelation.then.model.id].disable();
      relation.thenControl = [this.formBuilderService.createDynamicFormControlModel(
        this.relationControls[controlIndex - 1].relation[relationIndex].thenControl[0], true)];
      subRelation.then.model = this.formBuilderService.createDynamicFormControlModel(
        this.relationControls[controlIndex - 1].relation[relationIndex].thenControl[0], true);
      const newFormGroup = [];
      newFormGroup.push(subRelation.if.model);
      newFormGroup.push(subRelation.then.model);
      subRelation.formGroup = this.ngDynFormService.createFormGroup(newFormGroup);
    } else {
      if (subRelation.then.action === IfThenLogicAction.Enable
        || subRelation.then.action === IfThenLogicAction.Disable
        || subRelation.then.action === IfThenLogicAction.MathEquation
        || (subRelation.then.action === IfThenLogicAction.MustBe
        && (subRelation.then.option === IfThenLogicOptions.IsEmpty
        || subRelation.then.option === IfThenLogicOptions.IsNotEmpty
        || subRelation.then.option === IfThenLogicOptions.DateIsToday
        || subRelation.then.option === IfThenLogicOptions.DateIsAfterToday
        || subRelation.then.option === IfThenLogicOptions.DateIsBeforeToday
        || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterToday
        || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeToday
        || subRelation.then.option === IfThenLogicOptions.DateIsNow
        || subRelation.then.option === IfThenLogicOptions.DateIsAfterNow
        || subRelation.then.option === IfThenLogicOptions.DateIsBeforeNow
        || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterNow
        || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeNow
        || subRelation.then.option === IfThenLogicOptions.IsCurrentMonth
        || subRelation.then.option === IfThenLogicOptions.MonthIsBeforeCurrentMonth
        || subRelation.then.option === IfThenLogicOptions.MonthIsAfterCurrentMonth
        || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
        || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
        || subRelation.then.option === IfThenLogicOptions.IsCurrentWeek
        || subRelation.then.option === IfThenLogicOptions.WeekIsBeforeCurrentWeek
        || subRelation.then.option === IfThenLogicOptions.WeekIsAfterCurrentWeek
        || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
        || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
        || subRelation.then.option === IfThenLogicOptions.IsCurrentTime
        || subRelation.then.option === IfThenLogicOptions.TimeIsBeforeCurrentTime
        || subRelation.then.option === IfThenLogicOptions.TimeIsAfterCurrentTime
        || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
        || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime))) {
        subRelation.formGroup.controls[subRelation.then.model.id].disable();
      } else {
        subRelation.formGroup.controls[subRelation.then.model.id].enable();
      }
    }
    this.emitElementRelationChange();
  }

  moveDownRelation(event: Event, relationControls: any[], controlIndex: number, type: 'if' | 'then') {
    if (controlIndex === 0 && this.relationControls[controlIndex + 1].relation[0].subRelation[0].ifConnective) {
      this.relationControls[controlIndex].relation[0].subRelation[0].ifConnective
        = this.relationControls[controlIndex + 1].relation[0].subRelation[0].ifConnective;
      delete this.relationControls[controlIndex + 1].relation[0].subRelation[0].ifConnective;
    }
    this.arrayUtil.array_move(relationControls, controlIndex, controlIndex + 1);
    const temp = this.relationControls[controlIndex + 1].relation[0].order;
    this.relationControls[controlIndex + 1].relation[0].order = this.relationControls[controlIndex].relation[0].order;
    this.relationControls[controlIndex].relation[0].order = temp;
    if (controlIndex === 0) {
      this.relationControls[controlIndex + 1].relation[0].connective = this.relationControls[controlIndex].relation[0].connective;
      this.relationControls[controlIndex].relation[0].connective = null;
    }
    this.emitElementRelationChange();
  }

  moveUpRelation(event: Event, relationControls: any[], controlIndex: number, type: 'if' | 'then') {
    if ((controlIndex - 1) === 0 && this.relationControls[controlIndex].relation[0].subRelation[0].ifConnective) {
      this.relationControls[controlIndex - 1].relation[0].subRelation[0].ifConnective
        = this.relationControls[controlIndex].relation[0].subRelation[0].ifConnective;
      delete this.relationControls[controlIndex].relation[0].subRelation[0].ifConnective;
    }
    this.arrayUtil.array_move(relationControls, controlIndex, controlIndex - 1);
    const temp = this.relationControls[controlIndex - 1].relation[0].order;
    this.relationControls[controlIndex - 1].relation[0].order = this.relationControls[controlIndex].relation[0].order;
    this.relationControls[controlIndex].relation[0].order = temp;
    if ((controlIndex - 1) === 0) {
      this.relationControls[controlIndex].relation[0].connective = this.relationControls[controlIndex - 1].relation[0].connective;
      this.relationControls[controlIndex - 1].relation[0].connective = null;
    }
    this.emitElementRelationChange();
  }

  removeRelation(event: Event, control: any, relationIndex: number, controlIndex: number) {
    control.relation.splice(relationIndex, 1);
    if (control.relation.length === 0) {
      this.relationControls.splice(controlIndex, 1);
    }
    if (this.relationControls.length > 0 && this.relationControls[0].relation[0].connective) {
      delete this.relationControls[0].relation[0].connective;
    }
    if (this.relationControls.length > 0 && this.relationControls[0].relation[0].subRelation[0].ifConnective) {
      delete this.relationControls[0].relation[0].subRelation[0].ifConnective;
    }
    this.relationControls.forEach((relationControl, index) => {
      relationControl.relation[0].order = index;
    });
    this.emitElementRelationChange();
  }

  relationValueChange(event: any, controlIndex: number, relationIndex: number, subRelationIndex?: number,
    type?: 'if' | 'then', property?: string, operationIndex?: number, operationProperty?: any) {
    if (typeof subRelationIndex !== 'undefined') {
      if (!operationIndex && !operationProperty) {
        this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex][type][property]
          = event.value ?? event.target?.value ?? event.checked;
      } else {
        this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex][type][property]
          [operationIndex][operationProperty] = event.value ?? event.target?.value ?? event.checked;
      }
      const subRelation: SubRelation = this.relationControls[controlIndex].relation[relationIndex].subRelation[subRelationIndex];
      setTimeout(() => {
        this.disableDynamicFormElements(subRelation);
      }, 100);
    } else {
      if (property === 'ifControl' || property === 'thenControl') {
        this.relationControls[controlIndex].relation[relationIndex][property] = [event.value ?? event.target?.value];
      } else {
        this.relationControls[controlIndex].relation[relationIndex][property] = event.value ?? event.target?.value;
      }
      if (property === 'ifControl' && (event.value ?? event.target?.value).id
        !== this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.originalId) {
        const oldValue = {};
        if (this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.type
          === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.group.forEach(control => {
            oldValue[control.label] = control.value;
          });
        }
        this.relationControls[controlIndex].relation[relationIndex].id = [(event.value ?? event.target?.value).originalId];
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model =
          this.formBuilderService.createDynamicFormControlModel(event.value ?? event.target?.value, true);
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model =
        this.formBuilderService.createDynamicFormControlModel(
          this.relationControls[controlIndex].relation[relationIndex].thenControl[0], true);
        const controls = [this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model];
        controls.push(this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model);
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup
          = this.ngDynFormService.createFormGroup(controls);
        if (this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.type
          === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          const newValue = {};
          // compare oldValue with if model value with labels and assign to newValue
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.group.forEach(control => {
            newValue[control.id] = oldValue[control.label];
          });
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup.controls[
            this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.id].setValue(newValue);
        } else {
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup.controls[
            this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.id].setValue(
              this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.value);
        }
        this.setValueChangesSubscription();
      }
      if (property === 'thenControl' && (event.value ?? event.target?.value).id
        !== this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model.originalId) {
        const oldValue = {};
        if (this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.type
          === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.group.forEach(control => {
            oldValue[control.label] = control.value;
          });
        }
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model =
          this.formBuilderService.createDynamicFormControlModel(
            this.relationControls[controlIndex].relation[relationIndex].ifControl[0], true);
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model =
        this.formBuilderService.createDynamicFormControlModel(event.value ?? event.target?.value, true);
        const controls = [this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model];
        controls.push(this.relationControls[controlIndex].relation[relationIndex].subRelation[0].then.model);
        this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup
          = this.ngDynFormService.createFormGroup(controls);
        if (this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.type
          === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          const newValue = {};
          // compare oldValue with if model value with labels and assign to newValue
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.group.forEach(control => {
            newValue[control.id] = oldValue[control.label];
          });
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup.controls[
            this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.id].setValue(newValue);
        } else {
          this.relationControls[controlIndex].relation[relationIndex].subRelation[0].formGroup.controls[
            this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.model.id].setValue(
              this.relationControls[controlIndex].relation[relationIndex].subRelation[0].if.value);
        }
        this.setValueChangesSubscription();
      }
      this.relationControls[controlIndex].relation[relationIndex].subRelation.forEach(subRelation => {
        setTimeout(() => {
          this.disableDynamicFormElements(subRelation);
        }, 100);
      });
    }
    this.emitElementRelationChange();
  }

  disableDynamicFormElements = (subRelation: SubRelation): void => {
    if (subRelation.if.option === IfThenLogicOptions.IsEmpty
      || subRelation.if.option === IfThenLogicOptions.IsNotEmpty
      || subRelation.if.option === IfThenLogicOptions.DateIsToday
      || subRelation.if.option === IfThenLogicOptions.DateIsAfterToday
      || subRelation.if.option === IfThenLogicOptions.DateIsBeforeToday
      || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrAfterToday
      || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrBeforeToday
      || subRelation.if.option === IfThenLogicOptions.DateIsNow
      || subRelation.if.option === IfThenLogicOptions.DateIsAfterNow
      || subRelation.if.option === IfThenLogicOptions.DateIsBeforeNow
      || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrAfterNow
      || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrBeforeNow
      || subRelation.if.option === IfThenLogicOptions.IsCurrentMonth
      || subRelation.if.option === IfThenLogicOptions.MonthIsBeforeCurrentMonth
      || subRelation.if.option === IfThenLogicOptions.MonthIsAfterCurrentMonth
      || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
      || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
      || subRelation.if.option === IfThenLogicOptions.IsCurrentWeek
      || subRelation.if.option === IfThenLogicOptions.WeekIsBeforeCurrentWeek
      || subRelation.if.option === IfThenLogicOptions.WeekIsAfterCurrentWeek
      || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
      || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
      || subRelation.if.option === IfThenLogicOptions.IsCurrentTime
      || subRelation.if.option === IfThenLogicOptions.TimeIsBeforeCurrentTime
      || subRelation.if.option === IfThenLogicOptions.TimeIsAfterCurrentTime
      || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
      || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime
      || subRelation.if.option === IfThenLogicOptions.MathEquation) {
      subRelation.formGroup.controls[subRelation.if.model.id].disable();
    } else {
      subRelation.formGroup.controls[subRelation.if.model.id].enable();
    }
    if (subRelation.then.action === IfThenLogicAction.Enable
      || subRelation.then.action === IfThenLogicAction.Disable
      || subRelation.then.action === IfThenLogicAction.MathEquation
      || (subRelation.then.action === IfThenLogicAction.MustBe
      && (subRelation.then.option === IfThenLogicOptions.IsEmpty
      || subRelation.then.option === IfThenLogicOptions.IsNotEmpty
      || subRelation.then.option === IfThenLogicOptions.DateIsToday
      || subRelation.then.option === IfThenLogicOptions.DateIsAfterToday
      || subRelation.then.option === IfThenLogicOptions.DateIsBeforeToday
      || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterToday
      || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeToday
      || subRelation.then.option === IfThenLogicOptions.DateIsNow
      || subRelation.then.option === IfThenLogicOptions.DateIsAfterNow
      || subRelation.then.option === IfThenLogicOptions.DateIsBeforeNow
      || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterNow
      || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeNow
      || subRelation.then.option === IfThenLogicOptions.IsCurrentMonth
      || subRelation.then.option === IfThenLogicOptions.MonthIsBeforeCurrentMonth
      || subRelation.then.option === IfThenLogicOptions.MonthIsAfterCurrentMonth
      || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
      || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
      || subRelation.then.option === IfThenLogicOptions.IsCurrentWeek
      || subRelation.then.option === IfThenLogicOptions.WeekIsBeforeCurrentWeek
      || subRelation.then.option === IfThenLogicOptions.WeekIsAfterCurrentWeek
      || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
      || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
      || subRelation.then.option === IfThenLogicOptions.IsCurrentTime
      || subRelation.then.option === IfThenLogicOptions.TimeIsBeforeCurrentTime
      || subRelation.then.option === IfThenLogicOptions.TimeIsAfterCurrentTime
      || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
      || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime))) {
        subRelation.formGroup.controls[subRelation.then.model.id].disable();
    } else {
      subRelation.formGroup.controls[subRelation.then.model.id].enable();
    }
  }

  setValueChangesSubscription() {
    this.valueChanges.unsubscribe();
    this.valueChanges = new Subscription();
    this.relationControls.forEach(control => {
      control.relation.forEach(relation => {
        relation.subRelation.forEach(subRelation => {
          this.valueChanges.add(subRelation.formGroup.valueChanges.subscribe(value => {
            subRelation.if.value = value[subRelation.if.model.id];
            subRelation.then.value = value[subRelation.then.model.id];
            this.emitElementRelationChange();
          }));
        });
      });
    });
  }
}
