import { KnowledgeDiffComponent } from '@eva-knowledge/knowledge-diff/knowledge-diff.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';
import { Component, OnInit, Inject } from '@angular/core';
import { KnowledgeVersionsDraftPublished, KnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { Subject } from 'rxjs';
import { KnowledgeModel } from '@eva-model/knowledge';
import { LoggingService } from '@eva-core/logging.service';

/**
 * Data passed into the modal about the currently viewed knowledge doc in the editor
 */
interface KnowledgeDocVersionData {
  currentVersion: number; // version of viewed doc
  groupPublicKey: string; // public key of viewed doc
  id: string; // id of viewed doc
  docHtml: string;  // all html of the viewed doc
  allVersions: number[]; // all versions of viewed doc
  publishedVersions: number[]; // any published versions of viewed doc
}

@Component({
  selector: 'app-knowledge-revisions',
  templateUrl: './knowledge-revisions.component.html',
  styleUrls: ['./knowledge-revisions.component.scss']
})
export class KnowledgeRevisionsComponent implements OnInit {

  versions: KnowledgeVersionsDraftPublished[] = null; // all versions of doc, what the current version is and if published
  currentDoc: KnowledgeDocument; // doc that was chosen be user
  gettingDoc: Subject<boolean> = new Subject(); // state of retrieving doc

  constructor(private knowledgeService: KnowledgeService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: KnowledgeDocVersionData,
              public dialogRef: MatDialogRef<KnowledgeRevisionsComponent>,
              private loggingService: LoggingService) { }

  ngOnInit(): void {
    // get the doc version information we have stored in the knowledge service
    this.versions = this.knowledgeService.getVersionInformationFromActiveDoc(
      this.data.currentVersion,
      this.data.allVersions,
      this.data.publishedVersions);
  }

  /**
   * Gets a specific version of a document and then binds the document to the current doc.
   *
   * @param {number} version - specific version of document to retrieve
   */
  async getDocVersion(version: number): Promise<void> {

    this.gettingDoc.next(true);

    try {
      // get specific knowledge document version from firestore
      const response = await this.knowledgeService.getKnowledgeByVersion(
        this.data.groupPublicKey,
        this.data.id,
        version.toString(),
        'bGV0IG1lIGluIHBsZWFzZSE');

      // Check if 200 but still failed
      if (!response.success) {
        this.loggingService.logMessage('Error getting document information. Please try again', false);
        throw new Error('failed');
      }

      // Bind doc
      this.currentDoc = response.additional.knowledgeDocument;

      this.gettingDoc.next(false);
    } catch (err) {
      // Failed to get doc data
      this.loggingService.logMessage('Error getting document information. Please try again', false);
      this.gettingDoc.next(false);
    }
  }

  /**
   * Closes the component modal and passes the chosen doc to a callback.
   */
  loadDocIntoEditor(): void {
    this.dialogRef.close( (this.currentDoc) ? this.currentDoc : false );
  }

  /**
   * Takes the current doc and a version doc, opens a modal and shows a comparison.
   */
  showDiff(): void {
    if (!this.currentDoc) {
      // could not show diff, no doc was loaded
      return;
    }

    const versionDocHtml = new KnowledgeModel(this.currentDoc).getHTMLViewString();

    this.dialog.open(KnowledgeDiffComponent, {
      data: {
        currentDocHtml: this.data.docHtml,
        versionDocHtml: versionDocHtml,
        versionDocTimestamp: this.currentDoc.versionNumber,
        docVersions: this.versions,
        groupPublicKey: this.data.groupPublicKey,
        id: this.data.id
      },
      minWidth: '85vw'
    });
  }

}
