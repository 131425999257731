<div mat-dialog-content>
  <div fxFlexFill>
    <div fxFlex>
      <div [ngClass]="{'completed': feedback.isReviewed}" class="status-pill status-feedback-complete">
        {{ feedback.isReviewed ? 'Reviewed' : 'Not Reviewed' }}
      </div>
    </div>
    <div fxFlex="100%" class="date-created-on">
      {{feedback.timestamp | date:'short'}}
    </div>
    <div fxFlex>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="document">
    <h5>Document</h5>
    <p>{{ feedback.docName }}</p>
  </div>
  <div class="query">
    <h5>Query</h5>
    <p>{{feedback.query}}</p>
  </div>
  <div class="feedback">
    <h5>Feedback</h5>
    <p>{{feedback.userFeedback}}</p>
  </div>
  <div class="submitter">
    <h5>Submitter</h5>
    <app-user-info-display [publicKey]="feedback.userPublicKey"></app-user-info-display>
  </div>
  <div class="actions">
    <button mat-stroked-button color="primary" [disabled]="completing" (click)="toggleComplete(feedback)"><span *ngIf="!feedback.isReviewed">Mark Complete</span><span *ngIf="feedback.isReviewed">Mark Incomplete</span></button>
    <button mat-button color="primary" (click)="viewFeedbackInDocument(feedback)">View Document</button>
  </div>
</div>