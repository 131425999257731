<mat-drawer-container class="admin-main-content"> 
   <!-- class="example-container"> -->
  <!-- <mat-drawer mode="side" opened>
   
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/">Main</a>
      <a mat-list-item (click)="openMain('processCompare')">Process Issues</a>
      <a mat-list-item (click)="openMain('processStatus')">Process Status</a>
      <a mat-list-item (click)="openMain('enableLogs')">EVA Logs</a>
      <a mat-list-item (click)="openMain('enableFeedback')">Feedback</a>
    </mat-nav-list>

    <div class="absolute">
      Global Updates:<br />
      <progress [value]="progress" max="100" class="danger" ></progress>
      <br />
      <button mat-button appHoldable [disabled]="versionUpdateDisabled" color="danger" (holdTime)="updateVersion($event)" matTooltip="Hold to execute" matTooltipClass="adminTooltip">Update EVA Version</button><br />
      <button mat-button appHoldable color="danger" (holdTime)="updateAllRecords($event)" matTooltip="Hold to execute" matTooltipClass="adminTooltip">Update All Records</button>
    </div>

  </mat-drawer> -->
  <mat-drawer-content>
      <div *ngIf="!loadingAdministration && isAdministrator">
        <div fxLayout="row" fxLayoutAlign="end" class="topChange">
          <div fxFlexFill>
            <div fxFlex="20%" fxLayoutAlign="begin"  class="topChange">
              <button mat-button color="primary" [routerLink]="['/']"><i class="far fa-arrow-alt-circle-left"></i> To EVA</button>
            </div>
            <br />
            <h3>{{ componentTitle }}</h3>
          </div>
        </div>
      </div>
    <mat-card-content *ngIf="isAdministrator">
      <!-- <eva-process-compare *ngIf="enableProcessCompare"></eva-process-compare>
      <eva-process-status *ngIf="enableProcessStatus"></eva-process-status>
      <eva-logs *ngIf="enableLogs"></eva-logs>
      <app-feedback *ngIf="enableFeedback"></app-feedback> -->
    </mat-card-content>

<!-- 
    <eva-app-back-to-top></eva-app-back-to-top> -->
    <div class="app-loading" *ngIf="loadingAdministration">
        <mat-spinner diameter=100></mat-spinner>
    </div>
  </mat-drawer-content>
</mat-drawer-container>