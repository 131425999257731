import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { InteractionStore, RetrieveInteraction, AnnounceInteractionStart,
    AnnounceInteractionFinish, InteractionAnnouncementStatus } from '@eva-model/chat/process/chatProcess';
import { userLastStateType } from '@eva-model/userLastState';
import { LastStateService } from '@eva-services/last-state/last-state.service';
// import { SaveChatService } from '../save-chat.service';
import { InteractionResponse } from '@eva-model/interaction/interactionResponse';
import { InteractionMapped } from '@eva-model/interaction/interactionMapped';
import { InteractionLastState } from '@eva-model/interaction/interactionLastState';
import { distinctUntilChanged, filter, take, pairwise } from 'rxjs/operators';
import { ChatEntity, ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { ChatService } from '../chat.service';

const cacheTimeout = 43200000; // milliseconds (12 hours)

@Injectable({
  providedIn: 'root'
})
export class ChatInteractionService {

  // Private - Interaction data store and workflow to retrieve
  private interactionStore: BehaviorSubject<InteractionStore> = new BehaviorSubject({});     // store of all cached interactions
  private nextInteraction: Subject<RetrieveInteraction> = new Subject<RetrieveInteraction>(); // interaction to retrieve from database
  private announceStartingInteraction: Subject<AnnounceInteractionStart> = new Subject<AnnounceInteractionStart>();
  private announceFinishedInteraction: Subject<AnnounceInteractionFinish> = new Subject<AnnounceInteractionFinish>();

  // Public - Interaction observables
  public interactionStore$: Observable<InteractionStore> = this.interactionStore.asObservable();
  public announceStartingInteraction$: Observable<AnnounceInteractionStart> = this.announceStartingInteraction.asObservable();
  public announceFinishedInteraction$: Observable<AnnounceInteractionFinish> = this.announceFinishedInteraction.asObservable();

  // Initialization check
  private finishedInitialLastStateCheck = false;

  constructor(
    private dynamicInteractionsService: DynamicInteractionsService,
    private chatService: ChatService,
    // private saveChatService: SaveChatService
  ) {
    // listen to next interaction that needs to load, if there is a change then try to fetch this interaction
    this.nextInteraction.pipe(
      distinctUntilChanged(),
      filter(v => !!(v)), // make sure the value is not null
    ).subscribe(value => this.getInteractionById(value.interactionId));

    /**
     * Subscribe to last state once when initializing service to check if we are currently in an EVAProcess,
     * if lastState was a process, emit to start a process and change the HomeComponent view.
     */
    // this.lastStateService.lastState$.pipe(
    //   filter(state => !!(state)), // ensure LastState is loaded and not null
    //   take(1)
    // ).subscribe((state) => {
    //   // last state was an interaction
    //   if (state.type === userLastStateType.interaction) {
    //     let interactionId = '';

    //     // check multiple state areas where the id is, just because you're on an interaction, state shape might not be the same
    //     if (state.interactionResponse ) {
    //       interactionId = state.interactionResponse.id;
    //     } else {
    //       interactionId = state.response.parameters.fields.Interactions.stringValue;
    //     }

    //     this.startInteraction( interactionId );
    //   }

    //   // set flag that we are done initial state check
    //   this.finishedInitialLastStateCheck = true;
    // });

    /**
     * Monitors lastState and fires an announcement if we have started a process or a process was cancelled/submitted
     */
    // this.lastStateService.lastState$.pipe(
    //   filter(state => !!(state)), // ensure LastState is loaded and not null
    //   filter(state => this.finishedInitialLastStateCheck), // do not fire until our initial check of state has happened
    //   pairwise() // combines the past and current value into an array and emits both (only when it has a past value)
    // ).subscribe(([prevState, currState]) => {
    //   let interactionId = '';

    //   // announce an interaction was started
    //   if (prevState.type !== userLastStateType.interaction && currState.type === userLastStateType.interaction) {
    //     this.startInteraction( currState.response.parameters.fields.Interactions.stringValue );
    //   }

    //   // announce an interaction was submitted or cancelled
    //   if (prevState.type === userLastStateType.interaction && currState.type !== userLastStateType.interaction) {
    //     if (prevState.interactionResponse ) {
    //       interactionId = prevState.interactionResponse.id;
    //     } else {
    //       interactionId = prevState.response.parameters.fields.Interactions.stringValue;
    //     }
    //     this.endInteraction( interactionId );
    //   }
    // });

  }

  /**
   * Intent to start an interaction. This is **always** called when starting an interaction.
   */
  startInteraction = (interactionId: string, createNewTab = true): void => {
    this.announceStartingInteraction.next({
        interactionId,
        started: Date.now(),
        createNewTab
    });

    // Check if interaction is in store and not expired
    const store: InteractionStore = this.interactionStore.getValue(); // get contents of store

    // Interaction does not exist in store or is expired
    if ( !store[interactionId] || Date.now() > store[interactionId].expires ) {
      // Update the state of the store for this interaction id
      const retrievingInteraction = {};
      retrievingInteraction[interactionId] = {
        loading: true,
        error: false,
        expires: null,
        interaction: null
      };
      const retrievalStore = this.interactionStore.getValue(); // get current state of store
      Object.assign( retrievalStore, retrievingInteraction );

      // update store
      this.interactionStore.next( retrievalStore );

      // Start fetch of interaction from database
      this.fetchInteractionById(interactionId);
    }
  }

  /**
   * Called and announces that an interaction is finished
   *
   * @param interactionId the interaction id to finish
   * @param status status of the interaction to include with finished
   */
  endInteraction(interactionId: string) {
    this.announceFinishedInteraction.next({
        interactionId,
        finished: Date.now()
    });
  }

  /**
   * Notify that that we would like to get this interaction from the database
   *
   * @param interactionId interaction to get next from database
   */
  public fetchInteractionById(interactionId: string): void {
    this.nextInteraction.next({interactionId});
  }

  // TODO: Break this into a check function to determine if we should get a new interaction
  /**
   * retrieves interaction id from the database.
   *
   * @param interactionId interaction to retrieve
   */
  public async getInteractionById(interactionId: string): Promise<InteractionMapped> {
    let fullInteraction: InteractionMapped = null; // contains mapped interaction

    // check if we have interaction in cache
    if (this.interactionStore[interactionId]) {
      fullInteraction = this.interactionStore[interactionId];
      return fullInteraction;
    }

    // No interaction was found in cache, fetch the interaction and then emit
    if (!this.interactionStore[interactionId]) {

      try {
        const interactionResponse: InteractionResponse = await this.dynamicInteractionsService.fetchInteractionsById(interactionId)
          .toPromise();
        fullInteraction = this.dynamicInteractionsService.interactionObjectMapper(interactionResponse);

        // create our object we will add to our data store
        const retrievedInteractionForStore: InteractionStore = {};
        retrievedInteractionForStore[interactionId] = {
          expires: Date.now() + cacheTimeout, // TODO: Move to environment file
          interaction: fullInteraction,
          loading: false,
          error: false
        };

        // add this interaction to our store
        const store = this.interactionStore.getValue(); // get current state of store
        Object.assign( store, retrievedInteractionForStore ); // add to store

        // update store
        this.interactionStore.next( store );

        // done
        return retrievedInteractionForStore[interactionId].interaction;
      } catch (err) {

        // update store with failed retrieval of interaction
        const interactionError = {};
        interactionError[interactionId] = {
          loading: false,
          error: true,
          expires: null,
          interaction: null
        };
        Object.assign( this.interactionStore.getValue(), interactionError );

        console.log(err);

        return Promise.reject(err);
      }
    }

    const message = 'Interaction has loaded';
    const interactionState: InteractionLastState = {
      interactionResponse: fullInteraction,
      interactionValues: {},
      query: message,
      type: userLastStateType.interaction,
      screen: 0,
      id: ''
    };

    let elementId = '';
    if (fullInteraction.FormScreens[0].FormElements[0].id) {
      elementId = fullInteraction.FormScreens[0].FormElements[0].id;
      interactionState.id = elementId;
    }

    // this.interactionScreenNumber = 0;
    // this.interactionElementId = elementId;

    // this.saveChatService.saveChatSession(interactionState.query, false, interactionState);
    // this.lastStateService.updateSaveLastState(interactionState);

    const evaSays: ChatEntity = {
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: message
    };
    this.chatService.newChatEntity(evaSays);
    // this.provideVerbalInteraction(interaction.interactionResponse);


    // We already have this interaction cached, emit the interaction

  }
}

