<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content class="padding-top">
  <h4 [innerHTML]="dialogData.content"></h4>

  <ng-template appViewContainer></ng-template>

</div>
<mat-divider class="fullWidth"></mat-divider>
<div mat-dialog-actions>
  <div class="button-container">
    <button mat-raised-button [disabled]="isConfirmButtonDisabled" color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
    <button mat-stroked-button (click)="onNoClick()"
      *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    <button mat-stroked-button (click)="onTipsClick()">Tips</button>
  </div>
</div>