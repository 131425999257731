import { Injectable } from '@angular/core';

@Injectable()
export class ArrayUtilsService {

    constructor() { }

    array_move(array, old_index, new_index) {
        while (old_index < 0) {
            old_index += array.length;
        }
        while (new_index < 0) {
            new_index += array.length;
        }
        if (new_index >= array.length) {
            let extraElemnts = new_index - array.length + 1;
            while (extraElemnts--) {
                array.push(undefined);
            }
        }
        array.splice(new_index, 0, array.splice(old_index, 1)[0]);
        return array;
    }

    uniqueArray(array) {
        return array.filter( (item, pos, self) => {
            return self.indexOf(item) === pos;
        });
    }

    //#region Sorts an array of objects, expect all the array elements which are object have the property
    // Generally pass an array and the property of the object to sort with, default is as·cend·ing order
    arrayObjectCustomSort(array, property: string, sortDirection?: string) {
        return array.sort( function(a, b) {
            return ( (a[property] || a[property] === 0) && (b[property] || b[property] === 0) )
                ? (( a[property] > b[property] )
                    ? ((sortDirection && sortDirection === 'DESC') ? -1 : 1)
                    : ( ( b[property] > a[property] )
                        ? ((sortDirection && sortDirection === 'DESC') ? 1 : -1)
                        : 0 ))
                : 0;
        });
    }
    //#endregion
}
