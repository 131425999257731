<div *ngIf="visualizerFormGroup" class="polaroid">
  <div class="polaroid-container">

    <div class="input-type-button">
      <i class="fas fa-plus-circle input-type-icon"></i>{{this.frmCntrlItem.label}}
    </div>
    <div *ngIf="enableOperatorSelectors" class="operator-selector">
      <mat-select (selectionChange)="onValueOperator($event.value)" [value]="valueOperator">
        <!-- <mat-option value="===">Equal to</mat-option>
        <mat-option value="!==">Not Equal to</mat-option> -->
        <mat-option *ngFor="let option of (undefined | getLogicOptions:undefined:undefined:undefined:undefined:'workflow':frmCntrlItem)" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
      <div *ngIf="frmElmntIndex < (frmScrnElmntCnt - 1)" class="connection-operator">
        <mat-select (selectionChange)="onConnectionOperator($event.value)" [value]="connectionOperator">
          <mat-option value="AND">AND</mat-option>
          <mat-option value="OR">OR</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-group-container">
      <form [formGroup]="visualizerFormGroup" class="Width95prcnt clear-left">

        <dynamic-material-form [group]="visualizerFormGroup" (dfChange)="onDynamicFormChange($event)"
          [layout]="visualizerFormLayout" [model]="visualizerFormModel">

          <ng-template modelId="{{this.frmCntrlItem.id}}" class="eva_frm_visual_Width95prcnt" align="START">
            <div class="float-right">
            </div>
          </ng-template>

        </dynamic-material-form>
        <mat-form-field class="relation-element-width">
          <mat-label>Second value</mat-label>
          <input type="number" matInput [value]="_secondValue"
            [disabled]="valueOperator !== ifThenLogicOptions.IsBetween
              && valueOperator !== ifThenLogicOptions.IsNotBetween"
            (keyup)="setSecondValue($event)">
        </mat-form-field>

      </form>
    </div>
    <div class="buttons-layout">
      <button (click)="onRemoveFormElmnt(this.frmCntrlItem)" mat-button class="controlsBtn"
        matTooltip="Drops the element" [matTooltipShowDelay]="tooltipShowDelay">
        <mat-icon class="controlsIcon">delete_forever</mat-icon>
      </button>
    </div>

  </div>
</div>