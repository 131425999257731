import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent {


  _isAdministrator = false;
  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  constructor() {

  }

}
