<svg:g [attr.transform]="'translate(' + node.x + ',' + node.y + ')'">
  <svg:circle
      class="node"
      [attr.fill]="fillColor(node.type, node.color)"
      cx="0"
      cy="0"
      [attr.r]="node.r">
  </svg:circle>
  <svg:text
      class="node-name"
      [attr.font-size]="node.fontSize">
    {{node.name}}
  </svg:text>
</svg:g>
