import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-interaction-element-relation-tips',
  templateUrl: './interaction-element-relation-tips.component.html',
  styleUrls: ['./interaction-element-relation-tips.component.scss']
})
export class InteractionElementRelationTipsComponent {

  constructor(public dialogRef: MatDialogRef<InteractionElementRelationTipsComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
