import { DynamicFormControlModel } from '@ng-dynamic-forms/core';

export class GeneralDialogModel {

    constructor(
        public title: string,
        public content: string,
        public confirmBtnTxt: string,
        public cancelBtnTxt?: string,
        // dynFormCntrlMdl :: optional :: To pass a dynamic form model to get rendered in a dialog
        public dynFormCntrlMdl?: DynamicFormControlModel[],
        // extra :: extra :: Any optional properties or settings to pass to dialog component
        public extra?: any
    ) {  }

}
