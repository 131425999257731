import { Component, OnInit } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-eva',
  templateUrl: './about-eva.component.html',
  styleUrls: ['./about-eva.component.scss']
})

export class AboutEvaComponent implements OnInit {

  _isAdministrator = false;
  componentTitle: string;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  async ngOnInit() {
    try {
      this._isAdministrator = await this.authService.isUserAdmin();
    } catch (error) {
      console.log(error);
    }
  }

}


