export enum ValueModifier {
    ObjectKeysLength,
    DateShort,
    GroupName,
    ParseJSONString,
    GetObjectPropertyName,
    KnowledgeArchivedStatus,
    ProcessDashboardExceptionMessage,
    KnowledgePublishedStatus
}

export interface Property {
    propertyName: string;
    propertyUserFriendlyName: string;
    valueModifiers?: ValueModifier[];
    defaultValueProperty?: string;
    optional?: boolean;
}

export interface PropertyMapping {
    properties: Property[];
}
