import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { DynamicSearch, DynamicSearchCriteria } from '@eva-model/dynamicSearch';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

@Injectable()
export class DynamicSearchService {

  private _searchPath: string;
  private _searchCriteria: boolean;

  constructor(private firestoreService: FirestoreService) { }

  search(interactionId: string, searchCriteria?: DynamicSearch, subPath?: string) {

    this._searchPath = 'EVAStorage/Records/' + interactionId;
    if (subPath) {
      // add this to the search collection path
      this._searchPath += subPath;
    }
    if (searchCriteria) {
      this._searchCriteria = true;
    }
    if (searchCriteria) {
      // console.log(searchCriteria.getQueryFn());
      return this.firestoreService.colWithIds$(this._searchPath, searchCriteria.getQueryFn());
    } else {
      return this.firestoreService.colWithIds$(this._searchPath, ref => {
        return ref.limit(5);
        });
    }
    // return this.firestoreService.colWithIds$(this._searchPath, ref => {
    //   return ref.limit(5);
    // });
  }
}
