import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {GeneralDialogModel} from '@eva-model/generalDialogModel';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ReleaseNotes, ReleaseNote} from '@eva-model/releaseNotes';
import {Subject, Subscription, zip, BehaviorSubject, Observable} from "rxjs";
import {StorageService} from "@eva-services/storage/storage.service";
import { environment } from '@environments/environment';
import { filter, take } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-add-edit-release-notes',
  templateUrl: './add-edit-release-notes.component.html',
  styleUrls: ['./add-edit-release-notes.component.scss']
})
export class AddEditReleaseNotesComponent implements OnInit, OnDestroy {

  releaseNoteForm: UntypedFormGroup;
  releaseNotes: ReleaseNote[];

  froalaModel: string;
  selectedReleaseNote: ReleaseNote;

  // Image handling
  filesToUpload: {
    elementId: string,
    fileName: string,
    path: string,
    data: File | Blob
  }[] = [];

  uploadResults: any;

  // contains all active subscriptions for this component.
  componentSubs = new Subscription();

  // Subject containing the file from the editor imageUpload event.
  fileEvent$: Subject<File | Blob> = new Subject();
  // Contains the controls for the editor and the editor instance
  editorControls$: BehaviorSubject<any> = new BehaviorSubject(null);

  // If we want the editor controls or the instance, subcribe to this observable
  editorControls: Observable<any> = this.editorControls$.asObservable().pipe(
    filter(v => v !== null),
    take(1)
  );

  // Subject containing the element from the editor imageUpload event that was created and inserted into the editor html.
  imageDomEvent$: Subject<{ el: any, editor: any }> = new Subject();

  // options for the froala editor
  froalaOptions = {
    // controls which toolbar options are shown
    toolbarButtons: [
      'undo', 'redo', '|', 'insertImage', 'insertLink', '|',
      'bold', 'italic', 'underline', 'strikeThrough', '|',
      'formatOL', 'formatUL', 'outdent', 'indent', '-', 'subscript', 'superscript',
      'clearFormatting', 'insertTable', '|', 'html'
    ],
    attribution: false,
    key: environment.froala.key,
    zIndex: 2501,
    height: 340,
    events: {
      'contentChanged': () => {
        this.editorControls.subscribe(editor => {
          this.selectedReleaseNote.description = editor.getEditor().html.get();
        });
      },
      'image.beforeUpload': (images: any[]) => {
        // This event is good because it returns us the actual file.
        // Since you cannot drop multiple images into the editor, lets grab the first
        // file and push it through our Observable.
        this.zone.run(() => {
          this.fileEvent$.next(images[0]);
        });
      },
      'image.inserted': ($img: any[]) => {
        // Create a temporary ID to search the DOM for.
        const id = `IMG_${Date.now()}_${Math.floor(Math.random() * 1000)}`;

        // Use jQuery to add generated ID to DOM element
        $img[0].setAttribute('id', id);

        this.zone.run(() => {
          this.imageDomEvent$.next($img[0]);
        });
      },
      'image.replaced': ($img: any[]) => {
        this.zone.run(() => {
          // Check if the image being removed has an ID, if it does, check and see if the image was pending upload.
          const elementId = $img[0].getAttribute('id');
          if (elementId) {
            this.removeFileFromPendingUpload(elementId);
          }
        });
      },
      'image.removed': ($img: any[]) => {
        this.zone.run(() => {
          // Check if the image being removed has an ID, if it does, check and see if the image was pending upload.
          const elementId = $img[0].getAttribute('id');
          if (elementId) {
            this.removeFileFromPendingUpload(elementId);
          }
        });
      }
    }
  };

  constructor(
    public dialogRef: MatDialogRef<AddEditReleaseNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _fb: UntypedFormBuilder,
    public zone: NgZone,
    private storage: StorageService,
  ) {
  }

  /**
   * Called from the template on the froala editor directive. When the editor controls are ready, pass the controls
   * to our editorControls subscription where we can initialize, destroy and get the instance of the editor whenever
   * we would like. I've found this is the best way to handle getting the current editor instance.
   *
   * @param {any} editorControls - controls for the froala editor
   */
  initialize(editorControls: any): void {
    editorControls.initialize();
    this.editorControls$.next(editorControls);
  }

  ngOnInit() {
    // setup the release notes form group
    const formGroup = {
      releaseDate: new UntypedFormControl(new Date(), Validators.required),
      description: new UntypedFormControl('', Validators.required)
    };

    // make sure information was provided and then setup the form
    if (this.dialogData.extra.data) {
      this.releaseNotes = JSON.parse(JSON.stringify(this.dialogData.extra.data.releaseNotes));

      this.releaseNotes = this.releaseNotes.map(note => {
        if (Array.isArray(note.description)) {
          note.description = note.description.join('<br><br>');
        }
        return note;
      });

      this.selectedReleaseNote = this.releaseNotes[0];
      formGroup.releaseDate.setValue(new Date(this.selectedReleaseNote.releaseDate));
      formGroup.description.setValue(this.selectedReleaseNote.description);
      this.froalaModel = this.selectedReleaseNote.description;
    }

    this.releaseNoteForm = this._fb.group(formGroup);

    // --- Image handling ---
    // Listen to when a file is placed in the editor and create an item for queue
    this.componentSubs.add(
      zip(
        this.imageDomEvent$,
        this.fileEvent$
      ).subscribe(([htmlEle, data]) => {
        // Add this image data to the queue
        this.prepareFileForUpload(htmlEle, data);
      })
    );
  }

  /**
   * Unsub from main subscription tracking all observables
   */
  ngOnDestroy(): void {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  /**
   * This function fires when mouse is hovered on list item to show delete button
   *
   * @param index index of list item being hovered
   */
  onMouseOver(index: number): void {
    this.releaseNotes[index].hover = true;
  }

  /**
   * This function fires when mouse is hovered away from list item to hide delete button
   *
   * @param index index of list item being hovered
   */
  onMouseLeave(index: number): void {
    this.releaseNotes[index].hover = false;
  }

  onDateChange(event: any): void {
    this.selectedReleaseNote.releaseDate = new Date(event.value).getTime();
  }

  /**
   * Add a release note to the release note.
   */
  addReleaseNote(): void {
    const date = new Date();
    const newReleaseNote: ReleaseNote = {
      releaseDate: date.getTime(),
      description: ''
    };
    const newReleaseNoteForm = {
      releaseDate: date,
      description: ''
    };

    this.releaseNotes.unshift(newReleaseNote);
    this.selectedReleaseNote = this.releaseNotes[0];
    this.releaseNoteForm.patchValue(newReleaseNoteForm);
  }

  /**
   * delete the release note.
   * @param index the index of the item
   */
  deleteReleaseNote(index: number): void {
    this.releaseNotes.splice(index, 1);
    this.selectedReleaseNote = this.releaseNotes[0];
    this.releaseNoteForm.patchValue({
      releaseDate: new Date(this.selectedReleaseNote.releaseDate),
      description: this.selectedReleaseNote.description
    });
  }

  /**
   * This function checks the validity of the forms
   */
  isValid(): boolean {
    return this.releaseNoteForm.valid
      && this.releaseNotes.findIndex(releaseNote =>
        releaseNote.description.length === 0
        || (releaseNote.description.length > 0 && releaseNote.description[0] === '')) === -1;
  }

  /**
   * This function checks the validity of current note
   *
   * @param index index of note being checked for validity
   */
  isNoteValid(index: number): boolean {
    if (this.isValid()) {
      return true;
    }

    return this.releaseNotes[index].releaseDate
      && (this.releaseNotes[index].description.length > 0 && this.releaseNotes[index].description[0] !== '');
  }

  /**
   * This function fires when a list item is selected
   *
   * @param event MatSelectionListChange event
   */
  onReleaseNoteSelectionChange(event: MatSelectionListChange): void {
    this.selectedReleaseNote = event.source._value[0] as any;

    const patchObj = {
      releaseDate: new Date(this.selectedReleaseNote.releaseDate),
      description: this.selectedReleaseNote.description
    };
    this.releaseNoteForm.patchValue(patchObj);
    this.froalaModel = this.selectedReleaseNote.description;
  }

  /**
   * Cancel the dialog and return to the main screen.
   */
  onCancelClick(): void {
    // close the dialog
    this.dialogRef.close();
  }

  /**
   * This function runs if each item in the form is valid. It will update and save the videos
   * back to the database.
   */
  async finalizeReleaseNotes(): Promise<void> {
    // check that the form is valid.
    if (this.releaseNoteForm.valid) {
      const returnReleaseNotes: ReleaseNotes = {
        releaseNotes: []
      };

      // upload the pending image files
      await this.uploadPendingImages();

      // get each set of notes
      for (let i = 0; i < this.releaseNotes.length; i++) {
        const note = this.releaseNotes[i];
        delete note.hover;
        // Run if there were files pending to be uploaded
        if (this.filesToUpload.length > 0) {
          // We must upload the images and update our editor HTML again and re-save the doc
          note.description = await this.tryUpdateEditorHtml(note.description);
        }

        returnReleaseNotes.releaseNotes.push({
          releaseDate: new Date(note.releaseDate).getTime(),
          description: note.description
        });
      }

      this.dialogRef.close(returnReleaseNotes);
    }
  }

  /**
   * Once an image elment is updated, remove it from our filesToUpload and re-set our filesToUpload array to a new
   * array without the image we removed.
   *
   * @param {string} elementId - attr 'id' of the DOM element
   */
  removeFileFromPendingUpload(elementId: string): void {
    this.filesToUpload = this.filesToUpload.filter(item => item.elementId !== elementId);
  }

  /**
   * processes the pending files to be uploaded
   */
  async uploadPendingImages(): Promise<void> {
    if (this.filesToUpload.length > 0) {
      const path = `/images/about/release-notes`;

      const files = this.filesToUpload.map((item) => {
        return {
          data: item.data,
          path: `${path}/${item.fileName}`
        };
      });

      this.uploadResults = await this.storage.startUploads(files);
    }
  }

  /**
   * If there are files uploadResults, this function will loop through the html and replace img tags with
   * the proper uploaded urls
   *
   * @param editorHtml - the editor html string
   */
  async tryUpdateEditorHtml(editorHtml: string): Promise<string> {
    // Parse our current HTML document so we can change the src of the image we just uploaded.
    const tempDOM = new DOMParser().parseFromString(editorHtml, 'text/html');

    try {
      for (let index = 0; index < this.uploadResults.length; index++) {
        const imgElement = (<HTMLImageElement>tempDOM.getElementById(this.filesToUpload[index].elementId));
        if (imgElement) {
          imgElement.src = this.uploadResults[index].downloadUrl;
        }
      }

      editorHtml = tempDOM.body.innerHTML;
    } catch (err) {
      throw new Error(err);
    }

    return editorHtml;
  }

  /**
   * This function is fired every time a file is added to the editor.
   * Creates an object with the necessary data we need to later create a file upload.s
   *
   * @param {any} htmlEle - html element (image)
   * @param {File|Blob} data - file or blob of thing placed into editor
   */
  prepareFileForUpload(htmlEle: any, data: File | Blob): void {
    let fileName: string;

    // Check if file has name (if it's File or Blob)
    if (data instanceof File) {
      fileName = `${Date.now()}_${data.name}`;
    } else {
      fileName = `${Date.now()}_${Math.floor(Math.random() * 99999)}`;
    }

    const imageData = {
      elementId: htmlEle.getAttribute('id'),
      fileName,
      path: null,
      data
    };

    this.filesToUpload.push(imageData);
  }
}
