import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SubscriptionLike as ISubscription } from 'rxjs';
import { ProcessService } from '@eva-services/process/process.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { UserService } from '@eva-services/user/user.service';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';

@Component({
  selector: 'eva-process-picker-dialog',
  templateUrl: './process-picker-dialog.component.html',
  styleUrls: ['./process-picker-dialog.component.scss']
})
export class ProcessPickerDialogComponent implements OnInit, OnDestroy {

  entityType: string = null;   // entity can be "Process" or ...
  entities: any[] = [];
  entityVersions: any[] = [];

  isLinear = true;
  isWaiting = false;

  selectedStepperIndex = 0;

  selectUserGroupFormGroup: UntypedFormGroup;
  selectEntityFormGroup: UntypedFormGroup;

  pickedGroupPublicKey: string;
  pickedGroup: any;
  pickedGroupName: string;
  pickedEntityId: string;
  pickedEntity: any;
  pickedEntityName: string;
  entitySubs?: ISubscription;
  userProcessSubs: ISubscription;

  constructor(
    public dialogRef: MatDialogRef<ProcessPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private generalDialogService: GeneralDialogService,
    private processService: ProcessService,
    private _formBuilder: UntypedFormBuilder,
    public evaGlobalService: EvaGlobalService,
    private userServivce: UserService,
    private arrayUtil: ArrayUtilsService) {

  }

  ngOnInit() {
    this.selectUserGroupFormGroup = this._formBuilder.group({
      selectGroupCtrl: ['', Validators.required]
    });
    this.selectEntityFormGroup = this._formBuilder.group({
      selectEntityCtrl: ['', Validators.required]
    });

    if ( this.dialogData.extra && this.dialogData.extra.entityType &&
      this.evaGlobalService.userGroups && Array.isArray(this.evaGlobalService.userGroups) && this.evaGlobalService.userGroups.length > 0 ) {
      this.entityType = this.dialogData.extra.entityType;
    }
  }

  ngOnDestroy() {
    if (this.entitySubs) {
      this.entitySubs.unsubscribe();
    }

    if ( this.userProcessSubs ) {
      this.userProcessSubs.unsubscribe();
    }
  }

  //#region Stepper control methods
  async selectionStepperChange(ev) {

    switch (ev.selectedIndex) {
      case 0: {
         break;
      }
      case 1: {
        await this.onSelectEntityStep();
         break;
      }
      default: {
         break;
      }
    }
  }

  onGroupChange(ev) {
  }

  onEntityChange(ev) {
    if ( ev.value ) this.pickedEntityId = ev.value;
  }

  async onSelectEntityStep() {
    if ( !this.selectUserGroupFormGroup.valid ) return;

    const isGrpChanged: boolean = (this.selectUserGroupFormGroup.value.selectGroupCtrl !== this.pickedGroupPublicKey);
    if (isGrpChanged ) {

      this.pickedEntityId = null;
      this.pickedEntity = null;
      this.pickedEntityName = null;

      this.pickedGroupPublicKey = this.selectUserGroupFormGroup.get('selectGroupCtrl').value;
      const grpPk = this.pickedGroupPublicKey;
      this.pickedGroup = this.evaGlobalService.userGroups.find( (userGrp) => userGrp.groupPublicKey === grpPk );
      this.pickedGroupName = this.pickedGroup.groupName;

      this.isWaiting = true;

      if ( this.entityType === 'process' ) {
        const processByGroup$ = await this.userServivce.fetchUserProcessesByGroup(this.pickedGroupPublicKey);
        this.userProcessSubs =
          processByGroup$
          .subscribe(
            (entitiyArray) => {
              this.entities = this.arrayUtil.arrayObjectCustomSort(entitiyArray, 'name');
              this.isWaiting = false;
            },
            (err) => {
              console.log(err);
              this.isWaiting = false;
            }
          );
      }
    }
  }

  stepperReset(stepperCtrl: MatStepper) {
    this.pickedGroupPublicKey = null;
    this.pickedGroup = null;
    this.pickedGroupName = null;
    this.pickedEntityId = null;
    this.pickedEntity = null;
    this.pickedEntityName = null;

    stepperCtrl.reset();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isPickerNotComplete() {
    return (this.pickedGroupPublicKey && this.pickedEntityId) ? false : true;
  }

  onPickEntity() {
    if ( !this.selectEntityFormGroup.valid ) return;

    const changeObj = {
      "groupublicKey": this.pickedGroupPublicKey,
      "entityId": this.pickedEntityId
    };

    this.generalDialogService.announceChange(changeObj);
  }
  //#endregion

}
