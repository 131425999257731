<!-- <mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" style="padding:0;">
    <div class="spinner" fxFlex="20px">
      <ng-container *ngIf="progress | async as p; else finished">
        <mat-spinner diameter="18"></mat-spinner>
      </ng-container>
      <ng-template #finished>
        <mat-icon>check</mat-icon>
      </ng-template>
    </div>
    <div class="upload-details" fxFlex>

        <div *ngIf="snapshot | async as snap">
          <ng-container *ngIf="isActive(snap); else finished">
            Uploading... {{ progress | async }}%
          </ng-container>
          <ng-template #finished>
            Finished
          </ng-template>
        </div>
      <ng-template #error>Failed :(</ng-template>
      <mat-progress-bar [value]="progress | async"></mat-progress-bar>
    </div>
  </div>

</mat-dialog-content> -->


<!-- <mat-dialog-content>
  <div *ngIf="snapshot | async as snap; else loadingOrError" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" style="padding:0;">
    <ng-container *ngIf="isActive(snap); else finished">
      <div fxFlex="20px">
        <mat-spinner diameter="18"></mat-spinner>
      </div>
      <div fxFlex>
        Uploading... {{ progress | async }}%
        <mat-progress-bar [value]="progress | async"></mat-progress-bar>
      </div>
    </ng-container>
    <ng-template #finished>
      <div fxFlex="20px">
        <mat-icon>check</mat-icon>
      </div>
      <div fxFlex>
        Done
        <mat-progress-bar [value]="100"></mat-progress-bar>
      </div>
    </ng-template>
  </div>
  <ng-template #loadingOrError>
    <ng-container *ngIf="error | async as err; else loading">
      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" style="padding:0;">
        <div fxFlex="20px">
          <mat-icon>error</mat-icon>>
        </div>
        <div fxFlex>
          Failed
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" style="padding:0;">
        <div fxFlex="20px">
          <mat-spinner diameter="18"></mat-spinner>
        </div>
        <div fxFlex>
          Starting...
          <mat-progress-bar [value]="0"></mat-progress-bar>
        </div>
      </div>
    </ng-template>
  </ng-template>
</mat-dialog-content> -->

<mat-dialog-content>
  <div *ngIf="currentlyUploading$ | async as status">
    <span *ngIf="status === true">
      Images Uploaded
    </span>
    <span *ngIf="status === false">
      Image Upload failed
    </span>
  </div>
  <ul *ngIf="activeUpload$ | async as uploads">
    <!-- <li *ngFor="let upload of uploads">
      {{ upload.percentageChanges() | async }}%
    </li> -->
  </ul>
</mat-dialog-content>

