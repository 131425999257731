import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { BROWSERS } from 'ngx-device-detector';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  _returnUrl: string;
  isReputaionChangeManagement = false;

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private evaGlobalService: EvaGlobalService
  ) {

  }

  ngOnInit() {

    // Check if user is browing with Internet Explorer to message about it
    this.banIfBrowserIsIE();

    if (!this._returnUrl) {
      try {
        this._returnUrl = this._route.snapshot.queryParams.returnUrl;
      } catch {
      }
    }

  }

  loginButton() {
    this.router.navigate(['login'], { queryParams: { returnUrl: this._returnUrl } });
  }

  goToHome() {
    this.router.navigate(['/welcome']);
  }

  /**
   * Check if the user browser is Internet Explorer to message about it
   */
  private banIfBrowserIsIE() {

    // Check if user is browing with Internet Explorer
    if ( this.evaGlobalService.evaDeviceInfo.browser !== BROWSERS.IE ) {
      return;
    }

    this.router.navigate(['end']);

    //#region Initializing the popup control (Material Snack Bar)
    // const snackBarConfig = new MatSnackBarConfig();
    // snackBarConfig.verticalPosition = 'top';   // Positioning the snack bar popup
    // snackBarConfig.duration = 6000;   // Snack bar popup display duration

    // snackBarConfig.panelClass = ['error'];   // Snack bar popup applied class for styling
    //#endregion

    // eslint-disable-next-line max-len
    // const message = 'For an amazing EVA experience, use the Google Chrome browser';
    // this.snackBar.open(message, 'X', snackBarConfig);   // open up a message popup!

  }

}
