<div class="group-admin">
  <app-page-header [title]="componentTitle" subTitle="Add and remove members in your groups"></app-page-header>
  <app-spinner name="getting-preferences" show="true" *ngIf="myGroupsLoading">
    <h6>Getting your groups...</h6>
  </app-spinner>
  <mat-list *ngIf="!myGroupsLoading">
    <mat-list-item *ngFor="let group of myGroups">
      <div *ngIf="group" matLine>
        <span>{{group.groupName}}</span>
        <span *ngIf="group.groupType === groupType.invitation" class="invite-type"> Invitation Group</span>
        <span *ngIf="group.groupType === groupType.flowThrough" class="invite-type"> Flow Through Group</span>
        <span *ngIf="group.groupType === groupType.knowledge" class="invite-type"> Knowledge Group</span>
        <span *ngIf="group.groupType === groupType.dynamicData" class="invite-type"> Dynanic Data Group</span>
        <button *ngIf="isMyGroup(group)" class="move-right" mat-button type="button" color="primary" (click)="editGroup(group)">Edit Group</button>
        <span *ngIf="!isMyGroup(group)" class="move-right">
          <span class="disabled-blurb">You don't have permissions to edit this group.</span>
        </span>
        <button *ngIf="isMyGroup(group) && group.groupType === groupType.dynamicData" class="move-right" mat-button type="button" color="accent" (click)="shareGroup(group)">Share</button>
      </div>
    </mat-list-item>
  </mat-list>
  <div *ngIf="myGroups?.length < 1 && !myGroupsLoading">
    <p>
      No Groups found.
    </p>
  </div>
</div>