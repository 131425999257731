<div class="app-loading" *ngIf="isWaiting">
  <mat-spinner></mat-spinner>
  <ng-content></ng-content>
  <h6>{{ onWaitMessage }}</h6>
</div>
<div class="process-container">
  <app-page-header [title]="componentTitle"></app-page-header>
  <form [formGroup]="frmBuilderGrp">
    <div class="process-sub-container" *ngIf="!isWaiting && !hideProcessTitleBar">
      <p-selectButton [options]="actions" formControlName="selectedAction" (onOptionClick)="onActionSelect($event)">
        <ng-template let-item>
          <span class="template-item">
            &nbsp;&nbsp;{{item.label}}&nbsp;&nbsp;<i class="{{item.icon}}"></i>&nbsp;&nbsp;
          </span>
        </ng-template>
      </p-selectButton>
      &nbsp;&nbsp;&nbsp;

      <button pButton type="button" label="Dynamic Processes" class="ui-button-info panelTitle" iconPos="left">
        <mat-icon class="dynamic-processes-icon">autorenew</mat-icon>
      </button>
      &nbsp;
      <button pButton type="button" label="Id" class="ui-button-info panelTitle" icon="fas fa-id-badge" iconPos="left"
        (click)="openSnackBarForId()"></button>
      &nbsp;
      <button pButton type="button" label=" " *ngIf="isSummary() && false" class="ui-button-info panelTitle"
        icon="far fa-newspaper" iconPos="left" (click)="toggleProcessSummary()"></button>

      <span *ngIf="isProcessReadyToRun">&nbsp;&nbsp;&nbsp;</span>
      <!-- <button pButton type="button" *ngIf="isProcessReadyToRun && !isProcessSummary" (click)="processExitEvent()"
        label="Exit" class="ui-button-primary buttonPrimary" icon="far fa-times-circle" iconPos="right">
      </button> -->
      <span *ngIf="isProcessReadyToRun">&nbsp;&nbsp;&nbsp;&nbsp;</span>

      <!-- <div style="float: right;padding: 5px 5px;">
                <mat-slide-toggle
                  class=""
                  [checked]="isProcessEncrypted"
                  (change)="toggleProcessEncryption($event)">
                  {{(isProcessEncrypted) ? 'Encrypted' : 'Not Encrypted'}}
                </mat-slide-toggle>
              </div> -->
    </div>

    <div class="app-loading" *ngIf="isWaitingToFetchProcess">
      <span class="spinner-inner-container" *ngIf="isWaitingToFetchProcess">
        <mat-spinner [diameter]=128 [strokeWidth]=12 *ngIf="isWaitingToFetchProcess"></mat-spinner>
      </span>
      <h6>
        <span class="verColor"
          *ngIf="isWaitingToFetchProcess">&nbsp;&nbsp;{{ onWaitToFetchProcessMessage }}&nbsp;&nbsp;&nbsp;</span>
      </h6>
    </div>

    <div *ngIf="isCreateProcess">
      <div>
        <div fxFlex="20%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <input matInput class="fullWidth" placeholder="Process Name" (change)="onProcessNameChange($event)"
              formControlName="name">
          </mat-form-field>
        </div>
        <div fxFlex="20%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <mat-select placeholder="Process group" (selectionChange)="onGroupSelectionChange($event)"
              formControlName="userGroup">
              <mat-option *ngFor="let userGroup of evaGlobalService.userGroups" [value]="userGroup.groupPublicKey">
                {{ userGroup.groupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="1%" class="rightMargin10">
        </div>
        <div fxFlex="15%" class="rightMargin10">
          <button mat-raised-button class="fullWidth buttonTopMargin" (click)="workflowPickerEvent()"
            *ngIf="!isWaitingForWorkflow">Pick an active Workflow&nbsp;<i class="far fa-arrow-alt-circle-down"></i>
          </button>
          <div class="app-loading" *ngIf="isWaitingForWorkflow">
            <mat-spinner [diameter]=30 [strokeWidth]=5></mat-spinner>
            <ng-content></ng-content>
            <h6>
              <span class="verColor">{{ onWaitForWorkflowMessage }}</span>
            </h6>
          </div>
        </div>
        <div fxFlex="4%" class="rightMargin10">
        </div>
        <div fxFlex="20%" class="rightMargin10">
          <!-- <button mat-raised-button class="fullWidth" (click)="undefined" [disabled]="true">Process Viewer</button> -->
        </div>
        <div fxFlex="10%" class="rightMargin10">
          <button mat-raised-button class="fullWidth buttonTopMargin" (click)="cancelCreatingProcess()">Cancel</button>
        </div>
        <div fxFlex="10%">
          <button mat-raised-button class="fullWidth buttonTopMargin" [disabled]="isProcessNotValid()"
            (click)="saveUserNewProcess()">Save</button>
        </div>
      </div>
      <div
        *ngIf="this.process && this.process.workflows && this.process.workflows.length && this.process.workflows.length > 0">
        <div fxFlex="20%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <input matInput class="fullWidth" placeholder="Workflow Name" formControlName="workflow_name"
              [readonly]="true">
          </mat-form-field>
        </div>
        <div fxFlex="20%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <input matInput class="fullWidth" placeholder="Workflow Version" formControlName="workflow_version"
              [readonly]=true>
          </mat-form-field>
        </div>
        <div fxFlex="60%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <input matInput class="fullWidth" placeholder="Workflow Description" formControlName="workflow_description"
              [readonly]=true>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="isCreateProcess">
      <div fxFlex="100%" class="rightMargin10">
        <mat-form-field class="fullWidth">
          <mat-chip-list #descriptionList>
            <mat-chip *ngFor="let desc of process.descriptions; let descIndex = index;" [selectable]="true"
              [removable]="true" (removed)="removeProcessDescriptionConfirm()" id={{desc}}{{descIndex}}>
              {{desc}}
              <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Process description(s), that's how EVA will interact with this process"
              [matChipInputFor]="descriptionList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addProcessDescription()" />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div *ngIf="isProcessSummary">
    <eva-process-summary [processObj]="process">
    </eva-process-summary>
  </div>

  <div *ngIf="isProcessReadyToRun && !isProcessSummary">
    <eva-process-runner
      [processObj]="process"
      [targetId]="targetId"
      [hideProcessTitleBar]="hideProcessTitleBar"
      [tabIndex]="tabIndex"
      [uniqueTabId]="uniqueTabId"
      [updatedFromLastState]="updatedFromLastState">
    </eva-process-runner>
  </div>

  <!-- <p-growl [(value)]="growl_msgs" life = 5500></p-growl> -->
  <!-- {{ this.frmBuilderGrp.value | json }} -->
</div>