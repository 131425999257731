import { Component } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { GroupProviderService } from "@eva-core/group-provider.service";
import { LoggingService } from "@eva-core/logging.service";
import { environment } from "@environments/environment";

@Component({
    selector: 'app-create-group-dialog',
    templateUrl: './create-group-dialog.component.html',
    styleUrls: ['./create-group-dialog.component.scss']
})
export class CreateGroupDialogComponent {

    createGroupForm: any;
    groupTypes: any;
    groupTypeNames = [];
    formGroup: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder,
        private groupProviderService: GroupProviderService,
        private loggingService: LoggingService) {
        this.createGroupForm = new Object();
        this.createGroupForm['name'] = '';
        this.createGroupForm['type'] = '';
        this.createGroupForm['description'] = '';
        this.groupTypes = environment.blockConfig.types.groups.types;
        delete this.groupTypes.flowThrough;
        delete this.groupTypes.dynamicData;
        this.groupTypeNames = Object.keys(this.groupTypes);
        this.formGroup = this.fb.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            description: ['', Validators.required]
        });
    }

    /**
     * This function changes name of the group being created
     *
     * @param event HTML key down event
     */
    changeName(event): void {
        this.createGroupForm['name'] = event.target.value;
    }

    /**
     * This function changes description of the group being created
     *
     * @param event HTML key down event
     */
    changeDescription(event): void {
        this.createGroupForm['description'] = event.target.value;
    }

    /**
     * This function changes group type of the group being created
     *
     * @param event HTML selection change event
     */
    groupTypeSelectionChange(event): void {
        this.createGroupForm['type'] = event.value;
    }

    /**
     * This function validates and creates the new group
     */
    returnGroupData() {
        if (this.createGroupForm && this.formGroup.valid) {
            this.groupProviderService.createNewGroupForBlockAndSend(this.createGroupForm.name.trim(),
                this.createGroupForm.type, this.createGroupForm.description)
                .then(blockResponse => {
                    this.loggingService.logMessage('Group ' + this.createGroupForm['name'] + ' created successfully', false, 'success');
                })
                .catch(err => {
                    console.log('An Error occured: ' + err);
                    this.loggingService.logMessage('Group ' + this.createGroupForm['name'] + ' creation failed', false, 'error');
                });
            return this.createGroupForm;
        }
    }
}
