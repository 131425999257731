import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getScreenName'
})
export class GetScreenNamePipe implements PipeTransform {

  transform(control: any, screens: any[]): string {
    const formScreen = screens.find(screen => screen.FormElements.find(element => element.originalId === control.originalId));
    return formScreen ? formScreen.name : '';
  }

}
