import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@eva-core/auth.service';

@Injectable()
export class GoogleInterceptor implements HttpInterceptor {
  // constructor (public auth: AuthService) {}
  // constructor(private authService: AuthService) { }
  // auth: AuthService;
  // add in an item to hold the OAuthToken
  oauthToken: any;

  constructor(private authService: AuthService) { // private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // original
    // if (req.url.includes('www.googleapis.com')) {
    //   this.authService.getTokenHeader()
    //     .then((tokenHeader) => {
    //       const clone = req.clone({ setHeaders: tokenHeader });
    //       return next.handle(clone);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       return next.handle(req);
    //     });
    // } else {
    //   return next.handle(req);
    // }
    // removed
    return next.handle(req);
  }
}
