import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CryptoInvitation, Invitation, CryptoInvitationAcceptance, InvitationAcceptance,
  CryptoInvitationDoc,
  GroupAcceptanceDatabaseDoc,
  GenericInvitationOrAcceptanceResponse,
  InvitationDoc} from '@eva-model/invitation';
import { SigningService } from '@eva-core/signing.service';
import { AuthService } from '@eva-core/auth.service';
import { KeysService } from '@eva-core/encryption/keys.service';
import { LoggingService } from '@eva-core/logging.service';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { AngularFirestore, } from '@angular/fire/compat/firestore';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { environment } from '@environments/environment';
import {combineLatest, Observable, Subscription, throwError} from 'rxjs';
import { ProtectedUserPrivateKeys } from '@eva-model/signingKeys/signingKeys';
import { User } from '@eva-model/User';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import {filter, map, take} from 'rxjs/operators';
import { UserDirectoryDocument } from '@eva-model/user/userDirectory';
import { CreateNotificationsService } from '@eva-services/notifications/create-notifications.service';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable()
export class InvitationService {
  private _cryptoInvitation: CryptoInvitation; // the crypto invitation that is to be used.
  private _invitation: Invitation; // the invitiation used for this service.
  private _cryptoInvitationAcceptance: CryptoInvitationAcceptance; // the crypto invitation acceptance
  private _invitationAcceptance: InvitationAcceptance; // the invitation acceptance with reason.
  // and observable that contains the group acceptances from the database.
  private _groupsInvitations$: Observable<GroupAcceptanceDatabaseDoc[]>[] = [];
  groupAcceptanceNotificationsSub: Subscription; // the subscriptions to each group that the user is subscribed to watch.

  notificationResults = [];   // Array of process notifications ready for taking action to be moved to process page (to "Action")

  firebaseFunctionsEndpoint = environment.firebaseFunction.endpoint; // the base project endpoint.
  headers = new HttpHeaders({ 'Content-Type': 'application/json' }); // headers for this type of request.
  // these contain the endpoints for the different things that can be done with invitations in the EVA application.
  acceptInvitationEndpoint = this.firebaseFunctionsEndpoint + '/acceptInvitation';
  declineInvitationEndpoint = this.firebaseFunctionsEndpoint + '/declineInvitation';
  createInvitationDocEndPoint = this.firebaseFunctionsEndpoint + '/createInvitationDoc';
  // deletePendingInvitationEndpoint = this.firebaseFunctionsEndpoint + '/deletePendingInvitation';
  // createChangeInvitationEndpoint = this.firebaseFunctionsEndpoint + '/createChangeInvitationDoc';
  // changeInvitationEmailEndpoint = this.firebaseFunctionsEndpoint + '/createChangeInvitationEmail';

  constructor(
    private _signingService: SigningService,
    private _keysService: KeysService,
    public afAuth: AuthService,
    private _afs: AngularFirestore,
    public loggingService: LoggingService,
    private _http: HttpClient,
    private _firestoreService: FirestoreService,
    private _groupProviderService: GroupProviderService,
    public evaGlobalService: EvaGlobalService,
    private _createNotificationService: CreateNotificationsService,
    private perf: AngularFirePerformance
  ) {
    // this.init(); // init the observables
  }


  init() {
    this.evaGlobalService.userGroupsChange$.pipe(
      filter(change => !!change)
    ).subscribe(
      () => this.setupGroupInvitationNotification(),
      (err) => { console.log(err); }
    );
  }

  /**
   * This creates an array of subscriptions for the invitation acceptance for the user from groups that the user may be a part of
   */
  setupGroupInvitationNotification(): void {
    // check to ensure the information
    if (this.evaGlobalService.userGroupsPublicKeys && Array.isArray(this.evaGlobalService.userGroupsPublicKeys) &&
    this.evaGlobalService.userGroupsPublicKeys.length > 0) {
      this.evaGlobalService.userGroupsPublicKeys.forEach(userGroupPublicKey => {
        const groupObsPath = `Pending/${userGroupPublicKey}/Invites`;
        const tempObservable: Observable<GroupAcceptanceDatabaseDoc[]> = this._firestoreService.colWithIds$(groupObsPath);
        this._groupsInvitations$.push(tempObservable);
      });
      // setup the subscription for all potential invites.
      this.groupAcceptanceNotificationsSub = combineLatest(this._groupsInvitations$).pipe(
        trace('invitation-setupGroupInvitationNotification'),
        map(groupAcceptances => groupAcceptances.filter(groupAcceptance => groupAcceptance.length > 0)),
      ).subscribe(groupAcceptanceDocs => {
        // get the appropriate level of object.
        if (groupAcceptanceDocs instanceof Array) {
          groupAcceptanceDocs.forEach(groupAcceptanceDoc => {
            groupAcceptanceDoc.forEach(groupAcceptance => {
              if (groupAcceptance && groupAcceptance.cryptoInvitationAcceptance && groupAcceptance.cryptoInvitationAcceptance.acceptance &&
              groupAcceptance.cryptoInvitationAcceptance.acceptance.cryptoInvitation &&
              groupAcceptance.cryptoInvitationAcceptance.acceptance.cryptoInvitation.invitation &&
              groupAcceptance.cryptoInvitationAcceptance.acceptance.cryptoInvitation.invitation.groupInvitedTo && groupAcceptance.id) {
                const groupToAddUserTo = groupAcceptance.cryptoInvitationAcceptance.acceptance.cryptoInvitation.invitation.groupInvitedTo;
                const docId = `Pending/${groupToAddUserTo}/Invites/${groupAcceptance.id}`;
                if (this.validateCryptoAcceptanceInvitationFromDoc(groupAcceptance)) {
                  // get the member that invited the user originally.
                  const inviter = groupAcceptance.cryptoInvitationAcceptance.acceptance.cryptoInvitation.invitation.invitedBy;
                  const finalUserPublicKeyToAdd = groupAcceptance.cryptoInvitationAcceptance.acceptance.finalPublicKey;
                  const groupObject = this.evaGlobalService.userGroups.find(group => group.groupPublicKey === groupToAddUserTo);
                  if (groupObject) { // this check should never be needed, but lets be super safe.
                    // check if the original inviter is still a member of the group
                    if (groupObject.groupSigningMembership.indexOf(inviter) > -1) {
                      // add the key to the group.
                      this.addMemberAndDeleteOriginal(docId, [finalUserPublicKeyToAdd], groupToAddUserTo, groupObject.groupName,
                      groupObject.groupType, groupAcceptance);
                      // this._groupProviderService.addGroupMember([finalUserPublicKeyToAdd], groupToAddUserTo,
                      //   groupObject.groupName, groupObject.groupType, groupAcceptance);
                    } else {
                      this.declineInviteAndNotifyUser(docId, [finalUserPublicKeyToAdd]);
                    }
                  }
                } else {
                  this._firestoreService.delete(docId);
                  // console.log('Deleted invitation.');
                }
              }
            });
          });
        }
      });
    }
  }

  /**
   * This function deletes an invitation and updates the users with a notification if the invite didn't
   * get approved.
   * @param docId The document id of the invitation to delete.
   * @param newMemberPublicKeys an array of users public keys that need to be sent the decline information.
   */
  async declineInviteAndNotifyUser(docId: string, newMemberPublicKeys: string[]): Promise<void> {
    try {
      await this._firestoreService.delete(docId);
      await newMemberPublicKeys.forEach(async userPublicKey => {
        const message = 'There were problems with a group invitation so it has been rejected and deleted.';
        await this._createNotificationService.createNotificationWithPublicKey(userPublicKey, message);
      });
      return;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This adds the public keys to the invitation group types and deletes the original invite.
   *
   * @param docId the document id of the invitation
   * @param newMemberPublicKeys an array of public keys to add to the blockchain.
   * @param groupPublicKey the group to add the public keys to.
   * @param groupName the name of the group
   * @param groupType the group type
   * @param message the additional information to include.
   * @param organizationalGroup is this an organizational group.
   * @return {Promise<void>}
   */
  async addMemberAndDeleteOriginal(docId: string, newMemberPublicKeys: string[], groupPublicKey: string, groupName?: string,
    groupType?: string, message?: any, organizationalGroup?: boolean): Promise<void> {

    try {
      await this._groupProviderService.addGroupMember(newMemberPublicKeys, groupPublicKey, groupName, groupType,
        message, organizationalGroup);
      await this._firestoreService.delete(docId);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * This returns whether an invitation is valid or not.
   *
   * @param invitationDoc the invitation document to validate.
   * @returns {boolean} whether the invitation is valid of not.
   */
  validateCryptoInvitationFromDoc(invitationDoc: CryptoInvitationDoc): boolean {
    return this._signingService.validateSignedObject(invitationDoc.invitation,
      invitationDoc.invitation.invitedBy, invitationDoc.signature);
  }

  /**
   * This returns whether an invitation and acceptance is valid. is valid or not.
   *
   * @param acceptanceDoc the acceptance document to validate.
   * @returns {boolean} whether the invitation is valid of not.
   */
  validateCryptoAcceptanceInvitationFromDoc(acceptanceDoc: GroupAcceptanceDatabaseDoc): boolean {
    const cryptoInvitationAcceptance = acceptanceDoc.cryptoInvitationAcceptance;

    return this._signingService.validateSignedObject(cryptoInvitationAcceptance.acceptance,
      cryptoInvitationAcceptance.acceptance.publicKey, cryptoInvitationAcceptance.signature);
  }

  //#region InvitationAndAcceptance Setup

  /**
   * This function creates a new group invitation and stores it in the class.
   *
   * @param recipientEmail the email address of the user that is being invited to a group.
   * @param recipientPreferredName the preferred name of the user that will be getting the invitation.
   * @param message // the message that is sent to the user.
   * @param invitationName // the name that the user has given the invitiation.
   * @param invitationDescription // the description that the user has provided to the invitiation.
   * @param groupInvitedToPublicKey // the group the user has been invited to.
   * @param invitationTypeId // the type of invitation that has been sent for the user.
   */
  async setupInvitationNew(recipientEmail: string, recipientPreferredName: string, message: string, invitationName: string,
    invitationDescription: string,groupInvitedToPublicKey: string, invitationTypeId: string): Promise<void> {
    this._invitation = new Invitation();
    this._invitation.recipientEmail = recipientEmail;
    this._invitation.message = message;
    this._invitation.invitationName = invitationName;
    this._invitation.invitationDescription = invitationDescription;
    this._invitation.invitationTypeId = invitationTypeId;

    let recipientPublicKey = '';
    const userObject = await this.findUserByEmail(recipientEmail).toPromise();

    // check if the user exists, if they don't we need to create some basic information.
    if (userObject && userObject.length > 0) {
      const single = userObject[0];
      this._invitation.recipientName = single['preferredName'];
      recipientPublicKey = single['publicKey'];
      // create the invite into the app.
      this.createInviteInformation(groupInvitedToPublicKey, recipientPublicKey);
    } else {
      // if they don't exist, create a random keypair for them.
      const keyPair = this._keysService.getKeyPair();
      recipientPublicKey = keyPair.getPublic('hex').toString();
      const recipientSecretKey = keyPair.getPrivate('hex');
      this._invitation.recipientName = recipientPreferredName;
      // create the user directory object.
      const userDirectoryObject: UserDirectoryDocument = {
        emailAddress: recipientEmail,
        preferredName: recipientPreferredName,
        publicKey: recipientPublicKey,
        tempUser: true
      };
      // create an object to contain the private key for when the user signs up.
      const tempUserKeyObject: ProtectedUserPrivateKeys = {
        emailAddress: recipientEmail,
        privateKey: recipientSecretKey.toString(),
        publicKey: recipientPublicKey
      };
      try {
        // set the user protected private keys
        await this.setUserProtectedPrivateKeys(recipientEmail, tempUserKeyObject);
        await this.upsertUserDirectoryObject(recipientEmail, userDirectoryObject);
        await this.createInviteInformation(groupInvitedToPublicKey, recipientPublicKey);
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('an error occured while creating the invite to the user', false, 'error');
      }
    }
  }

  /**
   * This creates the invite of for the user and puts it into the system.
   *
   * @param groupInvitedToPublicKey hex encoding of the public key that you were invited to.
   * @param recipientPublicKey the public key of the user that is being invited.
   */
  async createInviteInformation(groupInvitedToPublicKey: string, recipientPublicKey: string): Promise<void> {
    const user = await this.afAuth.user.pipe(take(1)).toPromise();
    this._invitation.inviterEmail = user.email;
    this._invitation.inviterName = user.preferredName;
    this._cryptoInvitation = new CryptoInvitation(groupInvitedToPublicKey, user.signingPublicKey,
      recipientPublicKey);

    const successfulSignature = await this.signCryptoInvitation();
    if (successfulSignature) {
      this._invitation.cryptoInvitation = this._cryptoInvitation;
      // then store to database.
      try {
        await this.createInvitation().toPromise();
        // console.log('Successfully created invitation.');
        return;
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    } else {
      console.log('Issue signing invitation.');
      return Promise.reject('Issue signing invitation.');
    }
  }

  /**
   * verifies and updates an invitation and send an acceptance node to the database to be auto-signed by a member of the group
   * @param  invitationData the invitation acceptance information
   * @param  invitationId   the invitation document ID to add the acceptance information to
   * @param  reason         the reason this invite is being accepted/this recipient is being included into the group
   * @return                200 if successful
   */
  // ||| Believe I will have to recode this.
  async setupAcceptanceNew(invitationData: any, invitationId: string, reason?: string): Promise<GenericInvitationOrAcceptanceResponse> {
    // get the users protected keys.
    let userProtectedKeys: ProtectedUserPrivateKeys = null;
    try {
      userProtectedKeys = await this.getUserProtectedPrivateKeys(invitationData.recipientEmail).pipe(
        trace('invitation-setupAcceptanceNew'),
        take(1)
      ).toPromise();
    } catch (err) {
      if (err.code === 'permission-denied' && err.name === 'FirebaseError') {
        // set the object user Protected keys to null and try the user object.
        userProtectedKeys = null;
      }
    }

    try {
      // check if there is an outstanding invitation ie: this is a new user who was invited before they had an EVA account.
      if (userProtectedKeys) {
        return this.createAcceptanceInformation(userProtectedKeys.privateKey, userProtectedKeys.publicKey, invitationData,
          invitationId, reason);
      } else {
        // the user is a current user of EVA, therefore no protected private keys.
        const user = await this.afAuth.user.pipe(take(1)).toPromise();
        if (user) {
          return this.createAcceptanceInformation(user.signingPrivateKey, user.signingPublicKey, invitationData, invitationId, reason);
        } else {
          return Promise.reject('No protected private keys or user object found.');
        }
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function creates the crypto acceptance object and accepts it in the EVA System.
   *
   * @param privateKeyHex the hex of the private key.
   * @param publicKeyHexOfSigning the string of the public key.
   * @param invitationData the data that was included in the invitation data.
   * @param invitationId the id of the invitation in the database.
   * @param reason the reason of the acceptance or rejection.
   */
  async createAcceptanceInformation(privateKeyHex: string, publicKeyHexOfSigning: string, invitationData: any,
  invitationId: string, reason?: string): Promise<GenericInvitationOrAcceptanceResponse> {

    // take the original invitation and start creating the acceptance object.
    const invitation = new Invitation(invitationData.invitation);
    this._invitationAcceptance = new InvitationAcceptance(invitation, undefined, reason);

    try {
      // get the users keys.
      const userSigningKey = await this._keysService.getSigningKey(); // get the signing key
      const publicUserKeyHex = userSigningKey.getPublic('hex').toString(); // get the public encoding
      // setup a new crypto acceptance.
      const cryptoAcceptance = new CryptoInvitationAcceptance(invitation.cryptoInvitation,
        Date.now(), publicUserKeyHex, publicKeyHexOfSigning); // create the acceptance object
      this._cryptoInvitationAcceptance = cryptoAcceptance;
      const isSignedProperly = this.signAcceptance(privateKeyHex); // this adds the crypto signature and makes it ok.
      if (isSignedProperly) {
        this._invitationAcceptance.cryptoInvitationAcceptance = this._cryptoInvitationAcceptance;
        return this.acceptInvitation(invitationId).toPromise(); // accept the invitation on the backend.
      } else {
        this.loggingService.logMessage('Issue with signing items, please try again', false, 'error',
          this._invitationAcceptance); // log the issue.
        return Promise.reject('No Signed Keys');
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  // #endRegion InvitationAndAcceptance Setup

  //#region Validation

  /**
   * This validates an invitation is properly cryptographically signed.
   */
  validateCryptoInvitation(): boolean {
    const cryptoInvitationObject = this._cryptoInvitation.cryptoInvitation;

    return (this._signingService.validateSignedObject(cryptoInvitationObject.invitation,
      cryptoInvitationObject.invitation.invitedBy, cryptoInvitationObject.signature));
  }

  /**
   * This validates an acceptance and invitation to ensure everything has proper cryptographic signatures.
   */
  validateCryptoAcceptanceInvitation(): boolean {
    const cryptoInvitationAcceptance = this._cryptoInvitationAcceptance.cryptoInvitationAcceptance;
    return (this._signingService.validateSignedObject(cryptoInvitationAcceptance.acceptance,
      cryptoInvitationAcceptance.acceptance.publicKey, cryptoInvitationAcceptance.signature));
  }

  //#endregion Validation

  //#region SetupCryptoFromData

  /**
   * This sets up the invitation from the data that is provided.
   *
   * @param invitationData the invitation doc
   */
  setupInvitationFromData(invitationData: InvitationDoc): void {
    this._invitation = new Invitation(invitationData);
  }

  /**
   * This sets up the invitation acceptance from the data provided.
   * TODO: setup the interface for this (believe it is not currently matching any that we have.)
   *
   * @param invitationAcceptanceData the invitation acceptance data
   */
  setupInvitationAcceptanceFromData(invitationAcceptanceData: any): void {
    const invitationAcceptance = new InvitationAcceptance();
    invitationAcceptance.setInvitationAcceptanceData(invitationAcceptanceData);
    this._invitationAcceptance = invitationAcceptance;
  }
  // #endRegion SetupCryptoFromData


//#region AcceptDeclineSendInvitations

  /**
   * This creates the invitation in the endpoints and saves it in the system.
   */
  createInvitation(): Observable<GenericInvitationOrAcceptanceResponse> {
    const bodyData = {
      invitation: this._invitation.invitation,
    };

    const options = {
      headers: this.headers
    };

    return this._http.post<GenericInvitationOrAcceptanceResponse>(this.createInvitationDocEndPoint, bodyData, options);
  }

  /**
   * Call to Firebase functions to accept the specified invitation
   *
   * @param  inviteId the invite document the recipient is accepting
   * @return 200 if successful
   */
  acceptInvitation(inviteId: string): Observable<GenericInvitationOrAcceptanceResponse> {
    // check that the user object exists.
    if (this.afAuth.user) {
      const bodyData = {
        invitation: this._invitationAcceptance.invitation.invitation,
        cryptoInvitationAcceptance: this._invitationAcceptance.cryptoInvitationAcceptance.cryptoInvitationAcceptance,
        reason: this._invitationAcceptance.reason,
        invitee: this._invitationAcceptance.invitation.recipientEmail,
        inviteId: inviteId
      };

      const options = {
        headers: this.headers
      };

      return this._http.post<GenericInvitationOrAcceptanceResponse>(this.acceptInvitationEndpoint, bodyData, options).pipe(
        trace('invitation-acceptInvitation')
      );
    } else {
      return throwError('No User is currently logged in, or no valid auth token');
    }
  }

  /**
   *  Call to Firebase functions to decline the specified invitation
   * @param  invitee  the email of the person declining the invitation
   * @param  inviteId the ID of invitation doc under the invitee's email
   * @return 200 if successfully declined, and the invitation that was declined
   */
  declineInvitation(invitee: string, inviteId: string, reason?: string): Observable<GenericInvitationOrAcceptanceResponse> {
    if (this.afAuth.user) {
      let paramsOption = new HttpParams();

      paramsOption = paramsOption.set('invitee', invitee);
      paramsOption = paramsOption.set('inviteId', inviteId);
      if (reason) {
        paramsOption = paramsOption.set('reason', reason);
      }

      const options = {
        params: paramsOption,
        headers: this.headers
      };

      return this._http.get<GenericInvitationOrAcceptanceResponse>(this.declineInvitationEndpoint, options).pipe(
        trace('invitation-declineInvitation')
      );
    } else {
      return throwError('No User is currently logged in, or no valid auth token');
    }
  }

  // /**
  //  * this function deletes the group Id and the invite Id from the database if the user is the same
  //  * user that was invited. It is validated on the backend of the database.
  //  *
  //  * @param groupId the hex encoding public key of the group
  //  * @param inviteId the ID of the invite.
  //  */
  // deletePendingInvitation(groupId: string, inviteId: string): Observable<any> {
  //   if (this.afAuth.user) {
  //     let paramsOption = new HttpParams();

  //     paramsOption = paramsOption.set('groupId', groupId);
  //     paramsOption = paramsOption.set('inviteId', inviteId);

  //     const options = {
  //       params: paramsOption,
  //       headers: this.headers
  //     };

  //     return this._http.get(this.deletePendingInvitationEndpoint, options);
  //   } else {
  //     return Observable.throwError('No User is currently logged in, or no valid auth token');
  //   }
  // }

//#endregion AcceptDeclineSendInvitations


  // async addToGroupAndDeletePending(cryptoInvitation: any, cryptoAcceptanceInvite: any, inviteId: string) {

  //   const keys = [cryptoInvitation.cryptoInvitation.invitation.publicKeyToInclude];

  //   this._groupProviderService.addGroupMember(keys,
  //     cryptoInvitation.cryptoInvitation.invitation.groupInvitedTo, null, null,
  //     cryptoAcceptanceInvite).then(
  //       (result) => {
  //         console.log(result);
  //         this.deletePendingInvitation(cryptoInvitation.cryptoInvitation.invitation.groupInvitedTo, inviteId)
  //           .toPromise().then((deleted) => {
  //             console.log(deleted);
  //           });
  //       }
  //     ).catch(err => {
  //       console.log(err);
  //       console.log('continue with rest of invitations');
  //     });
  // }

  // getPendingInvites() {
  //   return this._firestoreService.colWithIds$('Pending/');
  // }

  getInvitations(invitee: string): Observable<CryptoInvitationDoc[]> {
    return this._firestoreService.colWithIds$<CryptoInvitationDoc[]>('Invitations/' + invitee + '/Invites').pipe(
      trace('invitation-getInvitations')
    );
  }

  /**
   * this is used to find users in the directory based on a type ahead style of search it is limited to 10 results
   *
   * @param startAt the string that will be searched.
   * @param endAt not in use
   * @return {Observable<UserDirectoryDocument[]>} an observable of user directory documents.
   */
  findUserTypeAhead(startAt: string, endAt): Observable<UserDirectoryDocument[]> {
    return this._afs.collection<UserDirectoryDocument>('UserDirectory/', ref => ref.orderBy(`searchableIndex.${startAt}`)
      .limit(10)).valueChanges().pipe(
        trace('invitation-findUserTypeAhead')
      );
  }

  /**
   * This gets an observable of the user directory based on the email address provided.
   *
   * @param email email address to find
   * @return {Observable<UserDirectoryDocument[]>} the directory object.
   */
  findUserByEmail(email: string): Observable<UserDirectoryDocument[]> {
    return this._afs.collection<UserDirectoryDocument>('UserDirectory/',
      ref => ref.where('emailAddress', '==', email).limit(1)).valueChanges().pipe(
        trace('invitation-findUserByEmail'),
        take(1)
      );
  }

  /**
   * This updates or inserts a user document into the user directory based on the email addrress
   * and userobject provided.
   * @param email email address of the user to update.
   * @param userObject the user object to update.
   * @return {Promise<void>} successful on insert / update
   */
  upsertUserDirectoryObject(email: string, userObject: UserDirectoryDocument): Promise<void> {
    return this._firestoreService.upsert('UserDirectory/' + email, userObject);
  }

  /**
   * This returns a single document observable from the user directory based on the email address provided.
   *
   * @param email the email address to find a document on
   * @return {Observable<UserDirectoryDocument>} the observable of the document.
   */
  getUserDirectoryObject(email: string): Observable<UserDirectoryDocument> {
    return this._firestoreService.doc$<UserDirectoryDocument>('UserDirectory/' + email);
  }
  //#endregion CallbackfromComponent

//#region CryptoSignatures

  /**
   * This function takes a private key hex and uses it to sign the cryptoinvitation object.
   *
   * @param privateKeyHex the hex encoded private key.
   * @return {boolean} successful or not in trying to sign the acceptance.
   */
  signAcceptance(privateKeyHex: string): boolean {
    const cryptoAcceptance = this._cryptoInvitationAcceptance.cryptoInvitationAcceptance;
    const cryptoAcceptanceObject = cryptoAcceptance.acceptance;
    try {
      // get the signature from the signing service
      const signature = this._signingService.getObjectSignatureNoBlockFromProvidedKey(cryptoAcceptanceObject, privateKeyHex);
      this._cryptoInvitationAcceptance.signature = signature.toString();
      return true;
    } catch (err) {
      console.log('Error during signing cryptoInvitation: ' + err);
      return false;
    }
  }
  /**
   * This function signs the cryptoinvitation object and returns true if this worked.
   *
   * @return {boolean} true or false if the invitation was signed correctly.
   */
  async signCryptoInvitation(): Promise<boolean> {
    const cryptoInvitationObject = this._cryptoInvitation.cryptoInvitation; // get the crypto invitation to sign.
    try {
      // get the signature from the signing service
      const signature = await this._signingService.getObjectSignatureNoBlockFromUserKey(cryptoInvitationObject.invitation);
      this._cryptoInvitation.signature = signature.toString();
      return true;
    } catch (err) {
      console.log('Error during signing cryptoInvitation: ' + err);
      return false;
    }
  }

//#endregion CryptoSignatures

//#region User ProtectedPrivateKeys

  /**
   * This function sets a users private key based on the email address provided.
   * It is used when a user doesn't exist in EVA but has been invited to a group or other action
   * that should email the user and make the offer to join.
   *
   * @param email email address of the user that the key was created for.
   * @param userKeyObject the object with private and public key
   */
  setUserProtectedPrivateKeys(email: string, userKeyObject: ProtectedUserPrivateKeys): Promise<void> {
    return this._firestoreService.set('ProtectedPrivateKeys/' + email, userKeyObject);
  }

  /**
   * This gets any user private keys that exist in the database that other users have invited them to do.
   *
   * @param email the users email address. this path is protected through an auth token validation in firestore
   * that ensures the user is the valid email user.
   */
  getUserProtectedPrivateKeys(email: string): Observable<ProtectedUserPrivateKeys> {
    return this._firestoreService.doc$<ProtectedUserPrivateKeys>('ProtectedPrivateKeys/' + email);
  }

//#endregion User ProtectedPrivateKeys

//#region GetterAndSetters
  set cryptoInvitationAcceptance(cIA: CryptoInvitationAcceptance) { this._cryptoInvitationAcceptance = cIA; }
  get cryptoInvitationAcceptance() { return this._cryptoInvitationAcceptance; }
  set cryptoInvitation(cI: CryptoInvitation) { this._cryptoInvitation = cI; }
  get cryptoInvitation() { return this._cryptoInvitation; }

//#endregion GetterAndSetters

}
