<form [formGroup]="frmBuilderGrp" style="height: 70vh">
  <div style="display: flex; flex-direction: row; height: 100%;">
    <!-- <div style="width: 30%;">
      <p-accordion *ngFor="let formScreen of dynFrm.FormScreens; let screenIndex = index;">
        <p-accordionTab *ngIf="screenIndex <= formScreenIndex" [header]="formScreen.name"
          style="padding: 0em !important;">
          <mat-nav-list>
            <mat-list-item *ngFor="let element of candidateControlFormScreens[screenIndex]; let elementIndex = index;">
              <p matLine data-form-control="true" draggable="true" (dragstart)="dragStartHandler($event)"
                [attr.data-form-screen-index]="screenIndex" [attr.data-form-control-type]="element.type"
                [attr.data-form-control-input-type]="element.inputType" id="{{element.originalId}}">
                <i class="{{element.originalId}}"></i>
                <span>&nbsp;<i class="{{element.icon}}"></i>&nbsp;&nbsp;#{{element.index + 1}} _
                  {{element.type}}{{element.inputType ? ' :: ' + element.inputType : ''}}</span><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{element.label ? element.label : ''}}</span>
              </p>
            </mat-list-item>
          </mat-nav-list>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="spacer-container">
    </div> -->
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
      <!-- <div *ngIf="relationControls.length > 0">
        <button class="fullWidth" mat-raised-button (click)="setRelationCondition($event)">Set the relation
          condition</button>
      </div> -->
      <!-- <div *ngIf="relationControls.length > 1">
        <mat-button-toggle-group (change)="relationConnectiveChange($event)" formControlName="connective"
          class="fullWidth" aria-label="Font Style">
          <mat-button-toggle class="fullWidth" value="AND">AND</mat-button-toggle>
          <mat-button-toggle class="fullWidth" value="OR">OR</mat-button-toggle>
        </mat-button-toggle-group>
      </div> -->
      <!-- <div id="relationDropZone" (drop)="dropHandler($event)" (dragover)="dargOverHandler($event)"> -->
      <div style="width: 100%; border: 1px solid rgba(0, 0, 0, .12); min-height: 100px; max-height: 20%; display: flex; align-items: center; justify-content: center; overflow: scroll; text-align: center; border-radius: 5px; padding: 0 30px; white-space: pre-wrap;">
        <div style="margin: auto">{{relationControls | userFriendlyCondition:dynFrm.FormScreens }}</div>
      </div>
      <div id="relationDropZone">
        <div style="width: 100%; height: 40px; background-color: var(--color-primary); color: #fff; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; border-top-left-radius: 5px; border-top-right-radius: 5px;">
          Relations
          <div style="cursor: pointer;" (click)="addRelation()">Add Relation &nbsp;<i class="fas fa-plus-circle"></i></div>
        </div>
        <div *ngIf="relationControls.length === 0">
          
        </div>
        <div *ngIf="relationControls.length > 0" style="position: relative; height: calc(100% - 8 * 5px); overflow-y: scroll;">
          <div style="padding: 20px; display: flex; flex-direction: column; width: 100%;">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; position: relative; margin-bottom: 20px;">
              <div style="width: 45%;">If<mat-divider></mat-divider></div>
              <div style="width: 45%;">Then<mat-divider></mat-divider></div>
            </div>
            <div style="background-color: #fff; padding: 20px; border-radius: 5px; border: 1px solid #ccc;">
              <ng-container *ngFor="let relationControl of relationControls; let controlIndex = index">
                <ng-container *ngFor="let relation of relationControl.relation; let relationIndex = index">
                  <ng-container *ngFor="let subRelation of relation.subRelation; let subRelationIndex = index;">
                    <div *ngIf="controlIndex !== 0" style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; position: relative;">
                      <div style="padding: 10px 0; width: 45%; display: flex; justify-content: center; align-items: center;">
                        <mat-divider style="width: 20%;"></mat-divider>
                        <mat-button-toggle-group (change)="subRelationIfConnectiveChange($event, controlIndex, relationIndex, subRelationIndex)" [value]="relation.subRelation[subRelationIndex].ifConnective">
                          <mat-button-toggle style="width: 100px !important;" value="AND">AND</mat-button-toggle>
                          <mat-button-toggle style="width: 100px !important;" value="OR">OR</mat-button-toggle>
                          <mat-button-toggle style="width: 100px !important;" [value]="undefined">NONE</mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-divider style="width: 20%;"></mat-divider>
                      </div>
                      <div style="display: flex; align-items: center; justify-content: center; padding: 0 10px; flex-direction: column; width: 10%;">
                        <div *ngIf="subRelation.ifConnective !== 'AND' && subRelation.ifConnective !== 'OR'" style="display: block; margin: 0; border-left-width: 1px; border-left-style: solid; border-color: #ddd; height: 20%;"></div>
                        <mat-button-toggle-group *ngIf="subRelation.ifConnective !== 'AND' && subRelation.ifConnective !== 'OR' && relationControls.length > 1 && controlIndex !== 0" [vertical]="true" (change)="relationConnectiveChange($event, controlIndex, relationIndex)" [value]="relationControls[controlIndex].relation[0].connective">
                          <mat-button-toggle style="width: 100px !important;" value="AND">AND</mat-button-toggle>
                          <mat-button-toggle style="width: 100px !important;" value="OR">OR</mat-button-toggle>
                          <mat-button-toggle style="width: 100px !important;" [value]="undefined">NONE</mat-button-toggle>
                        </mat-button-toggle-group>
                        <div *ngIf="subRelation.ifConnective !== 'AND' && subRelation.ifConnective !== 'OR'" style="display: block; margin: 0; border-left-width: 1px; border-left-style: solid; border-color: #ddd; height: 20%;"></div>
                        <div *ngIf="subRelation.ifConnective === 'AND' || subRelation.ifConnective === 'OR'" style="display: block; margin: 0; border-left-width: 1px; border-left-style: solid; border-color: #ddd; height: 99px;"></div>
                      </div>
                      <div style="width: 45%;">
                        <mat-divider *ngIf="subRelation.ifConnective !== 'AND' && subRelation.ifConnective !== 'OR'" style="width: 100%;"></mat-divider>
                      </div>
                    </div>
                    <div style="width: 100%; height: 40px; background-color: var(--color-secondary); color: #fff; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; margin-bottom: 10px; border-top-left-radius: 5px; border-top-right-radius: 5px;">
                      <div style="display: flex;">
                        Condition {{controlIndex + 1}}
                      </div>
                      <div style="display: flex;">
                        <mat-icon *ngIf="controlIndex !== (relationControls.length - 1)" (click)="moveDownRelation($event, relationControls, controlIndex, 'if')" class="controlsIcon">arrow_downward</mat-icon>
                        <mat-icon *ngIf="controlIndex !== 0" (click)="moveUpRelation($event, relationControls, controlIndex, 'if')" class="controlsIcon">arrow_upward</mat-icon>
                        <mat-icon (click)="removeRelation($event, relationControl, relationIndex, controlIndex)" class="controlsIcon">delete_forever</mat-icon>
                      </div>
                    </div>
                    <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; position: relative;">
                      <!-- Left -->
                      <div style="display: flex; flex-direction: column; width: 45%; justify-content: space-around; align-items: center;">
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%; justify-content: space-around; align-items: center;">
                          <mat-form-field class="relation-element-width" appearance="fill">
                            <mat-label>form element</mat-label>
                            <!-- <mat-select [value]="relation.ifControl[subRelationIndex]"
                              (selectionChange)="relationValueChange($event, controlIndex, relationIndex, undefined, undefined, 'ifControl')">
                              <ng-container *ngFor="let screen of dynFrm.FormScreens; let screenIndex = index;">
                                <ng-container *ngIf="screenIndex <= (relationControls | getCandidateElements:candidateControlFormScreens:relationControl:relationIndex:subRelationIndex:controlIndex:'if':dynFrm.FormScreens:FORM_CONTROL_ELEMENTS)[0].screenIndex">
                                  <mat-optgroup [label]="screen.name">
                                  <mat-option *ngFor="let element of screen.FormElements; let elementIndex = index;" [value]="element">
                                    {{element.label}}
                                  </mat-option>
                                </mat-optgroup>
                                </ng-container>
                              </ng-container>
                            </mat-select> -->
                            <mat-select [value]="relation.ifControl[subRelationIndex]"
                            (selectionChange)="relationValueChange($event, controlIndex, relationIndex, undefined, undefined, 'ifControl')">
                              <ng-container *ngFor="let screen of (relationControls | getCandidateElements:candidateControlFormScreens:relationControl:relationIndex:subRelationIndex:controlIndex:'if':dynFrm.FormScreens:FORM_CONTROL_ELEMENTS); let screenIndex = index;">
                                <ng-container *ngFor="let currentScreen of screen.elements">
                                  <mat-optgroup [label]="currentScreen.screenName">
                                    <mat-option *ngFor="let element of currentScreen.elements; let elementIndex = index;" [value]="element">
                                      {{element.label}}
                                    </mat-option>
                                  </mat-optgroup>
                                </ng-container>
                              </ng-container>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="relation-element-width" appearance="fill">
                            <mat-label>Options</mat-label>
                            <mat-select [value]="subRelation.if.option"
                            (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'if', 'option')">
                              <mat-option *ngFor="let option of (relationControls | getLogicOptions:relation:controlIndex:'if':subRelationIndex)" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <dynamic-material-form-control [group]="subRelation.formGroup" [model]="subRelation.if.model"></dynamic-material-form-control>
                          <mat-form-field class="relation-element-width">
                            <mat-label>Second value</mat-label>
                            <input type="number" matInput [value]="subRelation.if.secondValue ? subRelation.if.secondValue : ''"
                              [disabled]="subRelation.if.option !== ifThenLogicOptions.IsBetween
                                && subRelation.if.option !== ifThenLogicOptions.IsNotBetween"
                              (keyup)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'if', 'secondValue')">
                          </mat-form-field>
                        </div>
                      </div>
                      <!-- Center -->
                      <div style="display: flex; align-items: center; justify-content: center; padding: 0 10px; flex-direction: column; width: 10%;">
                        <div style="display: block; margin: 0; border-left-width: 1px; border-left-style: solid; border-color: #ddd; height: 100%"></div>
                      </div>
                      <!-- Right -->
                      <div style="display: flex; flex-direction: column; width: 45%; justify-content: space-around; align-items: center;">
                        <ng-container *ngIf="subRelation.ifConnective !== 'AND' && subRelation.ifConnective !== 'OR'">
                          <mat-checkbox
                            [disabled]="subRelation.then.action === ifThenLogicActions.MustBe"
                            style="margin-bottom: 10px;"
                            matTooltip="Not available for 'must be' action. If unchecked, the opposite of 'then' condition will not fire for negative of 'if' condition. For e.g. If the condition is 'If name is equal to John Doe Then disable password', once the name entered is 'John Doe', the password field will be disabled and will not be automatically enabled when 'if' condition is false unless enabled using some other condition"
                            (change)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'enableOpposite')"
                            [checked]="subRelation.then.enableOpposite">
                            Enable Opposite
                          </mat-checkbox>
                          <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%; justify-content: space-around; align-items: center;">
                            <mat-form-field class="relation-element-width" appearance="fill">
                              <mat-label>form element</mat-label>
                              <mat-select [value]="relation.thenControl[subRelationIndex]"
                              (selectionChange)="relationValueChange($event, controlIndex, relationIndex, undefined, undefined, 'thenControl')">
                                <ng-container *ngFor="let screen of dynFrm.FormScreens; let screenIndex = index;">
                                  <ng-container *ngIf="screenIndex <= (relationControls | getCandidateElements:candidateControlFormScreens:relationControl:relationIndex:subRelationIndex:controlIndex:'then')[0].screenIndex">
                                    <mat-optgroup [label]="screen.name">
                                    <mat-option *ngFor="let element of screen.FormElements; let elementIndex = index;" [value]="element">
                                      {{element.label}}
                                    </mat-option>
                                  </mat-optgroup>
                                  </ng-container>
                                </ng-container>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="relation-element-width" appearance="fill">
                              <mat-label>Action</mat-label>
                              <mat-select [value]="subRelation.then.action"
                              (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'action')">
                                <mat-option *ngFor="let action of (relationControls | getLogicActions:subRelation)" [value]="action">
                                  {{action}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="relation-element-width" appearance="fill">
                              <mat-label>Options</mat-label>
                              <mat-select [value]="subRelation.then.option"
                                [disabled]="subRelation.then.action === ifThenLogicActions.Enable
                                  || subRelation.then.action === ifThenLogicActions.Disable
                                  || subRelation.then.action === ifThenLogicActions.DefaultValue
                                  || subRelation.then.action === ifThenLogicActions.MathEquation"
                                (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'option')">
                                <mat-option *ngFor="let option of (relationControls | getLogicOptions:relation:controlIndex:'then':subRelationIndex)" [value]="option">
                                  {{option}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <dynamic-material-form-control [group]="subRelation.formGroup" [model]="subRelation.then.model"></dynamic-material-form-control>
                            <mat-form-field class="relation-element-width">
                              <mat-label>Second value</mat-label>
                              <input type="number" matInput [value]="subRelation.then.secondValue ? subRelation.then.secondValue : ''"
                                [disabled]="subRelation.then.option !== ifThenLogicOptions.IsBetween
                                  && subRelation.then.option !== ifThenLogicOptions.IsNotBetween
                                  || subRelation.then.action !== ifThenLogicActions.MustBe"
                                (keyup)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'secondValue')">
                            </mat-form-field>
                            <div style="width: 100%;" *ngIf="subRelation.then.action === ifThenLogicActions.MathEquation">
                              <div style="cursor: pointer; float: right;" (click)="addMathOperation(controlIndex, relationIndex, subRelationIndex)">Add Operation &nbsp;<i class="fas fa-plus-circle"></i></div>
                              <div style="margin-bottom: 10px;">Math Equation<mat-divider></mat-divider></div>
                              <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
                                <ng-container *ngFor="let operation of subRelation.then.operations; let operationIndex = index;">
                                  <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-evenly; align-items: center;">
                                    <div *ngIf="operationIndex === 0"></div>
                                    <mat-form-field *ngIf="operationIndex > 0" class="relation-element-width" appearance="fill">
                                      <mat-label>Operator</mat-label>
                                      <mat-select [value]="operation.operator"
                                        (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'operations', operationIndex, 'operator')">
                                        <mat-option *ngFor="let operator of (relationControl | getMathEquationOperators)" [value]="operator">
                                          {{operator}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="relation-element-width" appearance="fill">
                                      <mat-label>Value</mat-label>
                                      <mat-select [value]="operation.value"
                                        (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'operations', operationIndex, 'value')">
                                        <mat-option *ngFor="let element of (relationControl | getNumberCandidateElements:dynFrm.FormScreens)" [value]="element.originalId">
                                          {{element.label}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <div style="cursor: pointer; float: right;" (click)="removeMathOperation(controlIndex, relationIndex, subRelationIndex, operationIndex)"><i class="fas fa-minus-circle"></i></div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <!-- <mat-accordion class="relation-accordion" multi="true">
              <ng-container *ngFor="let relationControl of relationControls; let controlIndex = index">
                <ng-container *ngFor="let relation of relationControl.relation; let relationIndex = index">
                  <mat-expansion-panel [(expanded)]="relationControl.expanded">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{relationControl ? relationControl.label: ''}}
                      </mat-panel-title>
                      <mat-panel-description>
                        <div>{{relationControl | getScreenName:dynFrm.FormScreens }}</div>
                        <div style="display: flex;">
                          <mat-icon *ngIf="controlIndex !== (relationControls.length - 1)" (click)="moveDownRelation($event, relationControls, controlIndex)" class="controlsIcon">arrow_downward</mat-icon>
                          <mat-icon *ngIf="controlIndex !== 0" (click)="moveUpRelation($event, relationControls, controlIndex)" class="controlsIcon">arrow_upward</mat-icon>
                          <mat-icon (click)="removeRelation($event, relationControl, relationIndex, controlIndex)" class="controlsIcon">delete_forever</mat-icon>
                      </div>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                      <div *ngIf="relation.newRelation">
                        <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative;">
                          <div style="margin-bottom: 10px">Choose form element<mat-divider></mat-divider></div>
                          <mat-form-field class="relation-element-width" appearance="fill">
                            <mat-label>form element</mat-label>
                            <mat-select [formControl]="relation.control">
                              <ng-container *ngFor="let screen of dynFrm.FormScreens; let screenIndex = index;">
                                <ng-container *ngIf="screenIndex <= formScreenIndex">
                                  <mat-optgroup [label]="screen.name">
                                  <mat-option *ngFor="let element of candidateControlFormScreens[screenIndex]; let elementIndex = index;" [value]="element">
                                    {{element.label}}
                                  </mat-option>
                                </mat-optgroup>
                                </ng-container>
                              </ng-container>
                            </mat-select>
                          </mat-form-field>
                          <button mat-raised-button color="primary" [disabled]="" (click)="addFormElementForNewRelation(controlIndex, relationIndex)">Select</button>
                        </div>
                      </div>
                      <div *ngIf="!relation.newRelation" style="width: 100%; height: 40px; display: flex; align-items: center; justify-content: flex-end;">
                        <div style="cursor: pointer;" (click)="addSubRelation(controlIndex, relationIndex)">Add &nbsp;<i class="fas fa-plus-circle"></i></div>
                      </div>
                      <ng-container *ngIf="!relation.newRelation">
                      <ng-container *ngFor="let subRelation of relation.subRelation; let subRelationIndex = index;">
                        <div style="display: flex; flex-direction: column; border-radius: 5px; border: 1px solid #ddd; overflow: hidden;">
                          <div style="width: 100%; height: 40px; background-color: var(--color-secondary); color: #fff; display: flex; align-items: center; justify-content: space-between; padding: 0 15px;">
                            Sub Relation
                            <div style="display: flex;">
                              <mat-icon *ngIf="subRelationIndex !== (relation.subRelation.length - 1)" (click)="moveDownSubRelation(relation, subRelationIndex)" class="controlsIcon">arrow_downward</mat-icon>
                              <mat-icon *ngIf="subRelationIndex !== 0" (click)="moveUpSubRelation(relation, subRelationIndex)" class="controlsIcon">arrow_upward</mat-icon>
                              <mat-icon (click)="removeSubRelation(relation, subRelationIndex)" class="controlsIcon">delete_forever</mat-icon>
                            </div>
                          </div>
                          <div class="relation-container">
                            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; position: relative;">
                              <div style="width: 49%;">If<mat-divider></mat-divider></div>
                              <div style="width: 49%;">Then<mat-divider></mat-divider></div>
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 10px 0; position: relative;">
                              <div style="display: flex; flex-direction: column; width: 49%; justify-content: space-around; align-items: center;"> -->
                                <!-- Left -->
                                <!-- <div *ngIf="controlIndex !== 0">
                                  <mat-button-toggle-group (change)="subRelationIfConnectiveChange($event, controlIndex, relationIndex, subRelationIndex)" [value]="relation.subRelation[subRelationIndex].ifConnective">
                                    <mat-button-toggle style="width: 100px !important;" value="AND">AND</mat-button-toggle>
                                    <mat-button-toggle style="width: 100px !important;" value="OR">OR</mat-button-toggle>
                                    <mat-button-toggle style="width: 100px !important;" [value]="undefined">NONE</mat-button-toggle>
                                  </mat-button-toggle-group>
                                </div>
                                <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%; justify-content: space-around; align-items: center;">
                                  <mat-form-field class="relation-element-width" appearance="fill">
                                    <mat-label>Options</mat-label>
                                    <mat-select [value]="subRelation.if.option"
                                    (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'if', 'option')">
                                      <mat-option *ngFor="let option of (relationControl | getLogicOptions:relation:controlIndex:'if':control)" [value]="option">
                                        {{option}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <dynamic-material-form-control [group]="subRelation.formGroup" [model]="subRelation.if.model"></dynamic-material-form-control>
                                  <mat-form-field class="relation-element-width">
                                    <mat-label>Second value</mat-label>
                                    <input type="number" matInput [value]="subRelation.if.secondValue ? subRelation.if.secondValue : ''"
                                      [disabled]="subRelation.if.option !== ifThenLogicOptions.IsBetween
                                        && subRelation.if.option !== ifThenLogicOptions.IsNotBetween"
                                      (keyup)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'if', 'secondValue')">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div style="display: block; margin: 0; border-left-width: 1px; border-left-style: solid; border-color: #ddd;"></div>
                              <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 49%; justify-content: space-between; align-items: center;"> -->
                                <!-- Right -->
                                <!-- <mat-form-field class="relation-element-width" appearance="fill">
                                  <mat-label>Action</mat-label>
                                  <mat-select [value]="subRelation.then.action"
                                  [disabled]="relation.subRelation[subRelationIndex].ifConnective === 'AND' || relation.subRelation[subRelationIndex].ifConnective === 'OR'"
                                  (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'action')">
                                    <mat-option *ngFor="let action of (relationControls | getLogicActions:subRelation)" [value]="action">
                                      {{action}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="relation-element-width" appearance="fill">
                                  <mat-label>Options</mat-label>
                                  <mat-select [value]="subRelation.then.option"
                                    [disabled]="subRelation.then.action === ifThenLogicActions.Enable
                                      || subRelation.then.action === ifThenLogicActions.Disable
                                      || subRelation.then.action === ifThenLogicActions.DefaultValue
                                      || subRelation.then.action === ifThenLogicActions.MathEquation
                                      || relation.subRelation[subRelationIndex].ifConnective === 'AND'
                                      || relation.subRelation[subRelationIndex].ifConnective === 'OR'"
                                    (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'option')">
                                    <mat-option *ngFor="let option of (control | getLogicOptions:relation:controlIndex:'then':control)" [value]="option">
                                      {{option}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                  <dynamic-material-form-control [group]="subRelation.formGroup" [model]="subRelation.then.model"></dynamic-material-form-control>
                                <mat-form-field class="relation-element-width">
                                  <mat-label>Second value</mat-label>
                                  <input type="number" matInput [value]="subRelation.then.secondValue ? subRelation.then.secondValue : ''"
                                    [disabled]="subRelation.then.option !== ifThenLogicOptions.IsBetween
                                      && subRelation.then.option !== ifThenLogicOptions.IsNotBetween
                                      || subRelation.then.action !== ifThenLogicActions.MustBe
                                      || relation.subRelation[subRelationIndex].ifConnective === 'AND'
                                      || relation.subRelation[subRelationIndex].ifConnective === 'OR'"
                                    (keyup)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'secondValue')">
                                </mat-form-field>
                                <div style="width: 100%;" *ngIf="subRelation.then.action === ifThenLogicActions.MathEquation">
                                  <div style="cursor: pointer; float: right;" (click)="addMathOperation(controlIndex, relationIndex, subRelationIndex)">Add Operation &nbsp;<i class="fas fa-plus-circle"></i></div>
                                  <div style="margin-bottom: 10px;">Math Equation<mat-divider></mat-divider></div>
                                  <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
                                    <ng-container *ngFor="let operation of subRelation.then.operations; let operationIndex = index;">
                                      <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-evenly; align-items: center;">
                                        <mat-form-field class="relation-element-width" appearance="fill">
                                          <mat-label>Operator</mat-label>
                                          <mat-select [value]="operation.operator"
                                            (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'operations', operationIndex, 'operator')">
                                            <mat-option *ngFor="let operator of (control | getMathEquationOperators)" [value]="operator">
                                              {{operator}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="relation-element-width" appearance="fill">
                                          <mat-label>Value</mat-label>
                                          <mat-select [value]="operation.value"
                                            (selectionChange)="relationValueChange($event, controlIndex, relationIndex, subRelationIndex, 'then', 'operations', operationIndex, 'value')">
                                            <mat-option *ngFor="let element of (control | getNumberCandidateElements:dynFrm.FormScreens)" [value]="element.originalId">
                                              {{element.label}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                        <div style="cursor: pointer; float: right;" (click)="removeMathOperation(controlIndex, relationIndex, subRelationIndex, operationIndex)"><i class="fas fa-minus-circle"></i></div>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: center; padding: 5px 0;" *ngIf="relation.subRelation.length > 1 && subRelationIndex !== relation.subRelation.length - 1">
                          <mat-button-toggle-group (change)="subRelationConnectiveChange($event, controlIndex, relationIndex, subRelationIndex)" [value]="relation.subRelation[subRelationIndex + 1].subConnective">
                            <mat-button-toggle style="width: 100px !important;" value="AND">AND</mat-button-toggle>
                            <mat-button-toggle style="width: 100px !important;" value="OR">OR</mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                      </ng-container>
                    </ng-container>
                  </mat-expansion-panel>
                  <div style="display: flex; align-items: center; justify-content: center; margin: 10px 0;" *ngIf="relationControls.length > 1 && controlIndex !== relationControls.length - 1">
                    <mat-button-toggle-group (change)="relationConnectiveChange($event, controlIndex, relationIndex)" [value]="relationControls[controlIndex + 1].relation[0].connective">
                      <mat-button-toggle style="width: 100px !important;" value="AND">AND</mat-button-toggle>
                      <mat-button-toggle style="width: 100px !important;" value="OR">OR</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </ng-container>
              </ng-container>
            </mat-accordion> -->
          </div>
          <!-- <ul>
            <li *ngFor="let relControl of relationControls; let elementIndex = index;"> -->
              <!-- <app-form-element-visualizer [fromControlItem]="this.jsonStringify(relControl)"
                [formScreenName]="relControl.formScreenName" [formScreenIndex]="0" [formElementIndex]="elementIndex"
                [formScreenElementCount]="relationControls.length" [enableSetting]="false" [enableRelation]="false"
                (removeFormElementConfirm)="removeFormElementConfirm($event)"
                (moveUpFormElement)="moveUpFormElement($event)" (moveDownFormElement)="moveDownFormElement($event)">
              </app-form-element-visualizer> -->
              
            <!-- </li>
          </ul> -->
        </div>
      </div>
    </div>
    <br />
  </div>
</form>