import { Component, OnInit, Optional } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { AboutPageFeedback } from '@eva-model/releaseNotes';
import { AuthService } from '@eva-core/auth.service';
import { LoggingService } from '@eva-core/logging.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-about-page-feedback',
  templateUrl: './about-page-feedback.component.html',
  styleUrls: ['./about-page-feedback.component.scss']
})
export class AboutPageFeedbackComponent implements OnInit {

  selectedCategory = null;
  // I'd like to...
  categories = [
    'Make an ATB Knowledge document change',
    'Get help finding or understanding ATB Knowledge',
    'Get help with a Process',
    'Suggest a Process change',
    'Send Feedback about the EVA Platform'
  ];

  _aboutPageFeedbackColRef = 'About/Feedback/Feedback';
  sendFeedbackForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private loggingService: LoggingService,
    private formBuilder: UntypedFormBuilder,
    @Optional() private dialogRef: MatDialogRef<AboutPageFeedbackComponent>
    ) {}

  ngOnInit() {
    this.sendFeedbackForm = this.formBuilder.group({
      feedbackDescription: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  get f() { return this.sendFeedbackForm.controls; }

  changeFeedbackType(value: string): void {
    this.selectedCategory = value;
  }
  
  /**
   * This stores the feedback for action by the admin team.
   */
  async sendFeedback() {
    this.submitted = true;
    if (this.sendFeedbackForm.valid) {
      this.sendFeedbackForm.disable();

      // get user data for feedback
      const uid: string = await this.authService.getUserId();
      const email: string = await this.authService.getUserEmail();

      // feedback obj
      const feedback: AboutPageFeedback = {
        description: this.sendFeedbackForm.controls.feedbackDescription.value,
        updatedBy: uid,
        submittedUid: uid,
        submitterEmail: email,
        reply: null,
        replyAcknowledged: false
      };

      // submit
      try {
        await this.firestoreService.add(`About/Feedback/Feedback`, feedback);

        this.dialogRef.afterClosed().subscribe(() => {
          this.loggingService.logMessage(`Thank you for your feedback!`, false, 'success');
        })

        this.dialogRef.close();
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('An error occured while providing your feedback. Please try to re-submit', false, 'error');
        this.submitted = false;

        this.sendFeedbackForm.enable();
      }

      this.submitted = false;
    }
  }
}
