import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@eva-core/auth.service';
import { environment } from '@environments/environment';
import { ChatMLInitialUserResponse, ChatMLGetBetterUserResponse } from '@eva-model/chat/chatML';
import { TfidfFeedbackDocument, TfidfFeedbackResponse, TfidfFeedbackGroupRequest,
  TfidfFeedbackGroupResponse, TfidfFeedbackDocRequest, TfidfFeedbackDocResponse } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { filter, map, take } from 'rxjs/operators';
import { User } from '@eva-model/User';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { ChatService } from '@eva-services/chat/chat.service';
import { ChatEntityAuthor, ChatEntityType, NextChatQueryType } from '@eva-model/chat/chat';

// Endpoint URLs
const createUpdateFeedbackUrl = environment.endPoints.EVA_HEART.url + 'incomingTfIdfFeedbackData';
const feedbackByGroupUrl = environment.endPoints.EVA_HEART.url + 'getKnowledgeFeedbackByGroup';
const feedbackByDocUrl = environment.endPoints.EVA_HEART.url + 'getKnowledgeFeedbackByDocId';

// default http options used in these endpoints
const defaultHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class KnowledgeFeedbackService {

  // toggles to show feedback component on knowledge-edit
  private activeFeedbackSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // announces if a section should be highlighted
  private announceSectionHighlightSource: Subject<string> = new Subject();

  public activeFeedback$ = this.activeFeedbackSubject.asObservable();
  public announceSectionHighlight$ = this.announceSectionHighlightSource.asObservable();

  constructor(private http: HttpClient,
              private afAuth: AuthService,
              private perf: AngularFirePerformance,
              private chatService: ChatService) {
                // listen for feedback coming through chat about the latest knowledge response
                this.chatService.nextChatQuery$.pipe(
                  filter(e => e.type === NextChatQueryType.MLRESPONSE_GETBETTER_OTHER),
                  map(e => e.query)
                ).subscribe(async (userFeedback: string) => {
                  // first, post the feedback the user typed into the chat
                  this.chatService.newChatEntity({
                    author: ChatEntityAuthor.User,
                    type: ChatEntityType.Text,
                    text: userFeedback
                  });

                  // get the previous knowledge response information to know what we are sending feedback about
                  const entity = this.chatService.getLatestEntityOfType(ChatEntityType.KnowledgeResponse);
                  // if we didn't find the entity, exit out.
                  if (!entity) {
                    console.error(`got feedback ${userFeedback} but did not find knowledge entity!`);
                    return;
                  }

                  try {
                    // show we are trying to send this feedback
                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Loading
                    });

                    const section = entity.componentData.data;
                    const feedbackObj = await this.createTfidfFeedbackObject(section, ChatMLGetBetterUserResponse.OTHER, userFeedback);
                    await this.sendTfidfFeedbackData(feedbackObj);
                    this.chatService.resetNextChatQueryType();

                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Text,
                      text: `Thanks for your feedback. I've sent it to where it needs to go! 📫`
                    });

                  } catch (err) {
                    console.error(err);

                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Text,
                      text: `Thanks for your feedback. Unfortunately I was unable to deliver the message. 😔`
                    });

                    this.chatService.resetNextChatQueryType();
                  }
                });
              }

  /**
   * Sets the subject to show feedback component when on knowledge-edit.
   */
  showFeedbackPanel(): void {
    this.activeFeedbackSubject.next(true);
  }

  /**
   * Hide feedback component when on the knowledge-edit page.
   */
  hideFeedbackPanel(): void {
    this.activeFeedbackSubject.next(false);
  }

  /**
   * If the user is viewing the knowledge-edit page, the section id will be announce and the
   * DOM will be search for that section id, if found it will highlight the section in the DOM
   * and scroll to it if necessary.
   *
   * @param sectionId section id to search for
   */
  announceSectionHighlight(sectionId: string): void {
    if (!sectionId) {
      return;
    }

    this.announceSectionHighlightSource.next(sectionId);
  }

  //#region HTTP Calls to Endpoints

  /**
   * Hits an endpoint for feedback by group.
   * Will return either reviewed or unreviewed depending on what was supplied in the request.
   */
  async getFeedbackByGroup(body: TfidfFeedbackGroupRequest): Promise<TfidfFeedbackGroupResponse> {
    try {
      const response = await this.http.post<TfidfFeedbackGroupResponse>(feedbackByGroupUrl, body, defaultHttpOptions).pipe(
        trace('knowledge-feedback-getFeedbackByGroup'),
        take(1)
      ).toPromise();
      return response;
    } catch (err) {
      console.error('Feedback error: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Hits an endpoint for feedback by document.
   * Will return either reviewed or unreviewed depending on what was supplied in the request.
   */
  async getFeedbackByDocument(groupPk: string, docId: string, version: number, isReviewed: boolean): Promise<TfidfFeedbackDocResponse> {
    const body: TfidfFeedbackDocRequest = {
      knowledgeGroupPublicKey: groupPk,
      knowledgeDocId: docId,
      knowledgeVersion: version,
      isReviewed
    };

    try {
      const response = await this.http.post<TfidfFeedbackGroupResponse>(feedbackByDocUrl, body, defaultHttpOptions).pipe(
        trace('knowledge-feedback-getFeedbackByDocument'),
        take(1)
      ).toPromise();
      return response;
    } catch (err) {
      console.error('Feedback error: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Sends the feedback object to the database to be created.
   *
   * @param feedback new or existing feedback object
   */
  async sendTfidfFeedbackData(body: TfidfFeedbackDocument): Promise<TfidfFeedbackResponse> {
    try {
      const response = await this.http.post<TfidfFeedbackResponse>(createUpdateFeedbackUrl, body, defaultHttpOptions).pipe(
        trace('knowledge-feedback-sendTfidfFeedbackData'),
        take(1)
      ).toPromise();
      return response;
    } catch (err) {
      console.error('Feedback error: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * Updates the feedback object in the database. Adds the timestamp and the user who updated the feedback.
   *
   * @param feedback new or existing feedback object
   */
  async updateTfidfFeedbackAsReviewed(body: TfidfFeedbackDocument): Promise<TfidfFeedbackResponse> {
    try {
      const user: User = await this.afAuth.user.pipe(take(1)).toPromise();

      // create shallow copy of object to not alter view
      const payload = {...body};

      // update feedback object with reviewer details
      payload.isReviewed = true;
      payload.reviewerId = user.signingPublicKey;
      payload.reviewedOn = Date.now();

      const response = await this.http.post<TfidfFeedbackResponse>(createUpdateFeedbackUrl, payload, defaultHttpOptions).pipe(
        trace('knowledge-feedback-updateTfidfFeedbackAsReviewed'),
        take(1)
      ).toPromise();
      return response;
    } catch (err) {
      console.error('Feedback error: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * This function generates the TfIdf Feedback object.
   * You must generate one with this function before sending to endpoint.
   */
  async createTfidfFeedbackObject(knowledgeData: {
    query: string;
    groupPublicKey: string;
    modelVersion: string;
    docName: string;
    docId: string;
    docVersion: number;
    docSection: string;
    sectionHtml: string;
  }, feedbackType: ChatMLInitialUserResponse | ChatMLGetBetterUserResponse,
    userFeedback: string): Promise<TfidfFeedbackDocument> {
      let tfidfFeedback: TfidfFeedbackDocument = null; // create the ididf training object.
    try {
      // get thee user object
      const user = await this.afAuth.user.pipe(take(1)).toPromise();
      if (userFeedback) {
        tfidfFeedback = {
          query: knowledgeData.query, // this is the users query.
          knowledgeGroupPublicKey: knowledgeData.groupPublicKey,
          modelVersion: knowledgeData.modelVersion,
          docName: knowledgeData.docName,
          docId: knowledgeData.docId,
          docSection: knowledgeData.docSection,
          docVersion: knowledgeData.docVersion,
          feedbackType: feedbackType,
          timestamp: Date.now(),
          userId: user.uid,
          userEmail: user.email,
          userPublicKey: user.signingPublicKey,
          isReviewed: false,
          reviewerId: null,
          reviewedOn: null,
          userFeedback: userFeedback,
          internalNote: null,
          userNotified: false
        };
      } else {
        return Promise.reject('user feedback text needs to be provided.');
      }
      return tfidfFeedback;
    } catch (err) {
      console.log(err); // log the error.
      return Promise.reject(err); // this is the rejection of the error.
    }
  }

  // #endregion

}
