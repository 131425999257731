import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromJson'
})
export class FromJsonPipe implements PipeTransform {

  transform(jsonString: string): unknown {
    // return data if not a string as JSON.parse will error
    if (typeof jsonString !== 'string') {
      return jsonString;
    }

    return JSON.parse(jsonString);
  }

}
