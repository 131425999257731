import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';

// these components are for external connections from eva
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// these components are used for form generation/implementation
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// These components are used for connections to the real time firestore database
import { AngularFireModule } from '@angular/fire/compat';

// This module helps to detect "browser", "os", "device", "userAgent", and "os version"
import { DeviceDetectorModule } from 'ngx-device-detector';

// These are the components used for speech recognition and speech synthesis
import { SpeechRecognitionService } from './speech-synthesis/speech-recognition.service';
import { SpeechSynthesisService } from './speech-synthesis/speech-synthesis.service';

// These components are used for authentication
import { ChromeAuthenticationComponent } from './chrome-authentication/chrome-authentication.component';

// This section has access to the google drive components.
import { GoogleDriveService } from './providers/google/drive/google-drive.service';

// Data Services
import { CoreModule } from '@eva-core/core.module';
import { SaveChatService } from './providers/chat/save-chat.service';
import { LastStateService } from './providers/last-state/last-state.service';
import { GetChatService } from './providers/chat/get-chat.service';

// These components are used for connections to the real time firestore database
import { FirestoreService } from './providers/firestore/firestore.service';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';


// import UI related modules and services
import { UiModule } from './ui/ui.module';
import { httpInterceptorProviders } from './providers/interceptors/interceptor-config';
import { ArrayUtilsService } from './providers/utils/array-utils.service';
import { SearchUtilsService } from './providers/utils/search-utils.service';
import { GeneralDialogService } from './providers/general-dialog/general-dialog.service';
import { FormBuilderService } from './providers/form-builder/form-builder.service';
import { WorkflowBuilderService } from './providers/workflow-builder/workflow-builder.service';
import { InvitationService } from './providers/invitation/invitation.service';
import { WorkflowDestinationPickerService } from './providers/workflow-destination-picker/workflow-destination-picker.service';
import { WorkflowInteractionConditionService } from './providers/workflow-interaction-condition/workflow-interaction-condition.service';
import { NotificationsService } from './providers/notifications/notifications.service';
import { InteractionSubmissionService } from './providers/dynamic-interactions/interaction-submission.service';
import { DynamicInteractionSyncService } from './providers/dynamic-interactions/dynamic-interaction-sync.service';
import { InteractionElementFilterPipe } from './pipes/interaction-element-filter.pipe';
import { InteractionElementRelationService } from './providers/interacton-element-relation/interaction-element-relation.service';
import { DynamicSearchService } from './providers/dynamicsearch/dynamic-search.service';
import { DynamicDatabaseService } from './providers/dynamicdatabase/dynamic-database.service';
import { WorkflowService } from './providers/workflow/workflow.service';
import { InteractionNodeService } from './providers/interaction-node/interaction-node.service';
import { InstantSearchService } from './providers/search/instant-search.service';
// tslint:disable-next-line:max-line-length
import { WorkflowInteractionConditionBuilderService } from './providers/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
// tslint:disable-next-line:max-line-length
import { WorkflowInteractionConditionViewerService } from './providers/workflow-interaction-condition-viewer/workflow-interaction-condition-viewer.service';
import { ProcessService } from './providers/process/process.service';
import { WorkflowNotificationsService } from './providers/notifications/workflow-notifications.service';
import { UserService } from './providers/user/user.service';
import { LogService } from '@eva-core/log/log.service';
import { VersionCheckService } from './providers/version-check/version-check.service';
import { UtilsService } from './providers/utils/utils.service';
import { SignupService } from '@eva-core/signup/signup.service';
import { WINDOW_PROVIDERS } from './providers/injection';
import { EmbedVideoService } from './providers/embed-video/embed-video.service';
import { ProcessCompareService } from './providers/process/process-compare.service';
import { ProcessStatusService } from './providers/process/process-status.service';
import { WindowScrollingService } from '@eva-services/window-scrolling/window-scrolling.service';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DISABLED_MATCHER_PROVIDER } from '@ng-dynamic-forms/core';
import {CanDeactivateGuard} from "./core/auth-guard/can-deactivate.guard";
import {DialogService} from "@eva-ui/guard/dialog.service";
import { AdministrationModule } from './ui/administration/administration.module';
import { StateContext } from '@ngxs/store';
import { NgxsHmrLifeCycle, NgxsHmrSnapshot as Snapshot } from '@ngxs/hmr-plugin';

// include the config
export const firebaseConfig = environment.firebaseConfig;

// Froala V3 plugins
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import { CustomUrlSerializer } from './custom-url-serializer';
import { UrlSerializer } from '@angular/router';
import { DatePipe } from '@angular/common';
import { KnowledgeGlobalFindReplaceService } from '@eva-services/knowledge/knowledge-global-find-replace.service';

@NgModule({
  declarations: [
    AppComponent,
    ChromeAuthenticationComponent,
    InteractionElementFilterPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig, '[DEFAULT]'),
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    UiModule,
    AngularFirePerformanceModule,
    DeviceDetectorModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    AdministrationModule
  ],
  providers: [
    CanDeactivateGuard,
    DialogService,
    httpInterceptorProviders,
    SpeechRecognitionService,
    SpeechSynthesisService,
    GoogleDriveService,
    FirestoreService,
    UtilsService,
    ArrayUtilsService,
    SearchUtilsService,
    GeneralDialogService,
    FormBuilderService,
    SaveChatService,
    GetChatService,
    LastStateService,
    WorkflowBuilderService,
    InvitationService,
    InteractionSubmissionService,
    WorkflowDestinationPickerService,
    WorkflowInteractionConditionService,
    NotificationsService,
    DynamicInteractionSyncService,
    InteractionElementRelationService,
    DynamicSearchService,
    DynamicDatabaseService,
    WorkflowService,
    InteractionNodeService,
    InstantSearchService,
    WorkflowInteractionConditionBuilderService,
    WorkflowInteractionConditionViewerService,
    ProcessService,
    ProcessStatusService,
    WorkflowNotificationsService,
    UserService,
    LogService,
    VersionCheckService,
    SignupService,
    EmbedVideoService,
    WINDOW_PROVIDERS,
    ProcessCompareService,
    WindowScrollingService,
    KnowledgeGlobalFindReplaceService,
    DISABLED_MATCHER_PROVIDER,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    DatePipe
  ],
  exports: [
    InteractionElementFilterPipe,
    AngularFireModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements NgxsHmrLifeCycle<Snapshot> {
  public hmrNgxsStoreOnInit(ctx: StateContext<Snapshot>, snapshot: Partial<Snapshot>) {
    ctx.patchState(snapshot);
  }

  public hmrNgxsStoreBeforeOnDestroy(ctx: StateContext<Snapshot>): Partial<Snapshot> {
    return ctx.getState();
  }
}
