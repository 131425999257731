import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { WorkflowDestinationPickerService } from '../../../providers/workflow-destination-picker/workflow-destination-picker.service';
import { workflowInteractionDest } from '../../shared/constants';
import * as shape from 'd3-shape';
import { NodeColors } from '@eva-model/workflow';
import { Tree } from '@eva-ui/shared/Tree';
import { Node } from '@eva-ui/shared/Node';

@Component({
  selector: 'eva-workflow-destination-viewer',
  templateUrl: './workflow-destination-viewer.component.html',
  styleUrls: ['./workflow-destination-viewer.component.scss']
})
export class WorkflowDestinationViewerComponent implements OnInit, OnDestroy {

  NodeColors = NodeColors;                                  // Enum for node colors for ngx-graph
  workflowInteractionCondition: any;                        // Interaction Condition object
  conditionDestinationChangeSubscription: ISubscription;    // Subscription to condition destination changes

  center$: Subject<boolean> = new Subject();                // Observable to center the ngx-graph
  zoomToFit$: Subject<boolean> = new Subject();             // Observable to zoom to fit the ngx-graph
  curve: any = shape.curveMonotoneX;                        // Shape of the links between nodes in ngx-graph
  nodes = [];                                               // List of all the nodes in ngx-graph
  links = [];                                               // List of all the links in ngx-graph
  viewHeight = 150;                                         // Height of the ngx-graph
  flag = false;                                             // toggle for resizing ngx-graph
  index = 1;                                                // Number for generating unique id's for nodes
  tree: Tree = null;                                        // Tree object used for managing graph structure

  @Input()
  set workflowInteractionConditionObj(workflowInteractionConditionObj: string) {
    if (!workflowInteractionConditionObj) { return; }
    this.workflowInteractionCondition = JSON.parse(workflowInteractionConditionObj);

    this.generateConditionTreeNodes();
  }

  constructor(private destinationPickerService: WorkflowDestinationPickerService) { }

  ngOnInit() {
    this.conditionDestinationChangeSubscription =
      this.destinationPickerService.destinationsChanged$.subscribe(
        changeObj => {
          if (changeObj) {
            if (changeObj.conditionId === this.workflowInteractionCondition.conditionId) {
              if (changeObj.conditionResult) {
                this.workflowInteractionCondition.trueDestination = changeObj.selectedDestinations;
              } else {
                this.workflowInteractionCondition.falseDestination = changeObj.selectedDestinations;
              }

              this.generateConditionTreeNodes();
            }
          }
        },
        err => { console.log(err); }
      );
  }

  ngOnDestroy() {
    if (this.conditionDestinationChangeSubscription) {
      this.conditionDestinationChangeSubscription.unsubscribe();
    }
  }

  /**
   * This function generates nodes and links for ngx-graph
   */
  generateConditionTreeNodes(): void {
    this.nodes = [];
    this.links = [];
    this.links.length = 0;
    this.nodes.length = 0;

    if (!this.workflowInteractionCondition) {
      return;
    }

    // root node for the tree
    const node = {
      id: '' + this.workflowInteractionCondition.id,
      label: this.workflowInteractionCondition.name,
      color: this.NodeColors.condition
    };

    this.tree = new Tree(node);
    // true destination node
    const trueNode = {
      id: node.id + '-true',
      label: 'True (Not Linked)',
      color: this.NodeColors.true
    };
    if (this.workflowInteractionCondition.trueDestination.length > 0) {
      trueNode.label = 'True (Linked)';
    }
    this.tree.add(trueNode, this.tree.root.data);

    this.workflowInteractionCondition.trueDestination.forEach((dest: any) => {
      const newNode = this.generateDestinationNode(dest);
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === trueNode.id) {
          if (treeNode.children.length === 0) {
            this.tree.add(newNode, treeNode.data);
          } else {
            (function traverseTree(trNode, that) {
              trNode.children.forEach(child => {
                if (child.children.length === 0) {
                  that.tree.add(newNode, child.data);
                } else {
                  traverseTree(child, that);
                }
              });
            })(treeNode, this);
          }
        }
      });
    });
    // false destination node
    const falseNode = {
      id: node.id + '-false',
      label: 'False (Not Linked)',
      color: this.NodeColors.false
    };
    if (this.workflowInteractionCondition.falseDestination.length > 0) {
      falseNode.label = 'False (Linked)';
    }
    this.tree.add(falseNode, this.tree.root.data);

    this.workflowInteractionCondition.falseDestination.forEach((dest: any) => {
      const newNode = this.generateDestinationNode(dest);
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === falseNode.id) {
          if (treeNode.children.length === 0) {
            this.tree.add(newNode, treeNode.data);
          } else {
            (function traverseTree(trNode, that) {
              trNode.children.forEach(child => {
                if (child.children.length === 0) {
                  that.tree.add(newNode, child.data);
                } else {
                  traverseTree(child, that);
                }
              });
            })(treeNode, this);
          }
        }
      });
    });

    // generate nodes and links from tree
    this.tree.traverseDF((treeNode: Node) => {
      this.nodes.push(treeNode.data);
      if (treeNode.parent) {
        this.links.push({
          source: treeNode.parent.data.id,
          target: treeNode.data.id
        });
      }
    });
  }

  /**
   * This function generates node with correct label based on destination type
   *
   * @param dest Destination object
   */
  generateDestinationNode(dest: any): any {
    const subNode = {
      id: '' + this.index,
      label: dest.name,
      color: this.NodeColors.interaction
    };
    this.index++;

    if (dest.type === workflowInteractionDest.group) {
      subNode.label = dest.name;
      subNode.color = this.NodeColors.group;
    } else if (dest.type === workflowInteractionDest.interaction) {
      const destVersion = dest.version ? (new Date(Number(dest.version))).toLocaleString() : '';
      subNode.label = `${dest.name} :: ${destVersion}`;
      subNode.color = this.NodeColors.interaction;
    } else if (dest.type === workflowInteractionDest.condition) {
      subNode.label = dest.name;
      subNode.color = this.NodeColors.condition;
    }

    return subNode;
  }

  /**
   * This function adds and removes the nodes from ngx-graph on collapse
   *
   * @param node Node being collapsed
   */
  updateNodesTree(node: any): void {
    if (node.collapse) {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF((n: Node) => {
            if (node.id !== n.data.id) {
              this.nodes = this.nodes.filter(graphNode => graphNode.id !== n.data.id);
              this.links = this.links.filter(link => link.source !== n.parent.data.id && link.target !== n.data.id);
            } else {
              this.nodes.forEach(graphNode => {
                if (graphNode.id === node.id) {
                  graphNode.collapse = true;
                }
              });
            }
          }, node);
        }
      });
    } else {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF(n => {
            if (node.id !== n.data.id) {
              this.nodes = [...this.nodes, n.data];
              this.links = [...this.links, { source: n.parent.data.id, target: n.data.id }];
              this.nodes.forEach(graphNode => {
                if (graphNode.id === n.data.id) {
                  graphNode.collapse = false;
                }
              });
            } else {
              this.nodes.forEach(graphNode => {
                if (graphNode.id === node.id) {
                  graphNode.collapse = false;
                }
              });
            }
          }, node);
        }
      });
    }
  }

  /**
   * This functions toggles the collapse of nodes in ngx-graph
   *
   * @param node Node being collapsed
   */
  toggleTree(node: any): void {
    node.collapse = !node.collapse;
    this.updateNodesTree(node);
  }

  /**
   * This function center's the ngx-graph
   */
  centerGraph(): void {
    this.center$.next(true);
  }

  /**
   * This function zoom to fit ngx-graph
   */
  zoomToFitGraph(): void {
    this.zoomToFit$.next(true);
    this.centerGraph();
  }

  /**
   * This function toggles height of the ngx-graph
   */
  toggleViewHeight(): void {
    this.flag = !this.flag;
    this.flag ? this.viewHeight = 450 : this.viewHeight = 150;
  }
}
