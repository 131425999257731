import { ChatMLInitialUserResponse, ChatMLGetBetterUserResponse } from "@eva-model/chat/chatML";
import { KnowledgeDocumentSection, KnowledgeDocumentSectionFlat } from "@eva-model/knowledge/knowledge";

/**
 * Changes to this file must be replicated into the EVA and EVA heart projects.
 */

/**
 * This is the object that is returned fromt he training response.
 */
export interface TfidfTrainingResponse {
    documentTrainingId: string; // the ID of the object that was saved for training.
    passageTrainingId: string; // the ID for the passage that was saved for training.
    timestamp: number; // the timestamp of the request.
}

/**
 * Training type
 */
export type TfidfTraining = TfidfTrainingSection | TfidfTrainingCorrectText;

//#region TfidfTrainingInterfaces

/**
 * This is the interface for the tfidf training information coming into EVA heart functions.
 */
interface TfidfTrainingBasic {
    query: string; // this is the query that the user had.
    knowledgeGroupPublicKey: string; // this is the public key of the knowledge group.
    modelVersion: string; // the version of the model.
    docId: string; // the document Id of the original response.
    feedbackType: ChatMLInitialUserResponse | ChatMLGetBetterUserResponse; // the type of feedback provided.
    timestamp: number; // the timestamp in UTC that was provided for the training.
    userId: string; // the user Id that has been provided.
    userEmail: string; // the email address of the user.
    userPublicKey: string; // the public key of the user.
    knowledgeDocumentSection?: KnowledgeDocumentSectionFlat[]; // The included knowledge document seection.
    correctText?: string; // This is the correct response for the query provided.
    providedAnswers?: TfidfFeedbackProvidedAnswer[] | null; // answers the user saw from the model
}

/**
 * This interface is used to pass a correct section to the EVA application.
 */
interface TfidfTrainingSection extends TfidfTrainingBasic {
    knowledgeDocumentSection: KnowledgeDocumentSectionFlat[]; // The included knowledge document seection.
}

/**
 * This interface is used for providing a correct answer in text form from EVA.
 */
interface TfidfTrainingCorrectText extends TfidfTrainingBasic {
    correctText: string; // This is the correct response for the query provided.
}

//#endregion TfidfTrainingInterfaces

//#region TfidfFeedbackInterfaces

/**
 * This interface is used for feedback actioning on a document.
 */
export interface TfidfFeedbackDocument extends TfidfTrainingBasic {
    id?: string; // id in database
    docName: string; // name of doc
    docVersion: number; // doc version the feedback was made on
    docSection: string; // section id of the doc, instead of including whole section (since it will expose doc information)
    isReviewed: boolean; // was reviewed
    reviewerId: string | null; // public key of user who reviewed feedback
    reviewedOn: number | null; // timestamp in UTC of when reviewer marked as reviewed
    userFeedback: string; // feedback provided by user about this doc section
    internalNote?: string | null; // internal reviewer comments visible to group
    userNote?: string | null; // comments provided by the reviewer about this feedback sent to user
    userNotified: boolean; // if the userNote was sent as notification to the user
}

/**
 * This is the object that is returned from the feedback response.
 */
export interface TfidfFeedbackResponse {
    documentFeedbackId: string; // the ID of the object that was saved for feedback.
    message: string; // whether is was created or updated.
    timestamp: number; // the timestamp of the request.
}

/**
 * Shape of request object to get feedback based on a group
 */
export interface TfidfFeedbackGroupRequest {
    knowledgeGroupPublicKey: string; // knowledge group public key
    isReviewed: boolean; // to get reviewed or unreviewed feedback
    prevCursor?: string; // id to start before
    nextCursor?: string; // id to start after
}

/**
 * Shape of feedback group response.
 */
export interface TfidfFeedbackGroupResponse {
    feedback: TfidfFeedbackDocument[]; // array of feedback results
}

/**
 * Shape of request object to get feedback based on a knowledge doc id
 */
export interface TfidfFeedbackDocRequest {
    knowledgeGroupPublicKey: string; // knowledge group public key the doc id belongs to
    knowledgeDocId: string; // doc id of the knowledge document
    knowledgeVersion: number; // version of the doc the feedback was created on
    isReviewed: boolean; // to get reviewed or unreviewed feedback
    page?: number; // page to retrieve
}

/**
 * Shape of feedback doc response.
 */
export interface TfidfFeedbackDocResponse {
    feedback: TfidfFeedbackDocument[]; // array of feedback results
    page?: number; // for paginated results
}

/**
 * The data that the chat currently has visible. This is added to the feedback data being sent to server.
 */
export interface TfidfFeedbackProvidedAnswer extends KnowledgeDocumentSectionFlat {
    docId: string;
}

//#endregion IfidfFeedbackInterfaces
