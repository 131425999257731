import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { Pair } from '@eva-model/idMap';

@Component({
  selector: 'app-id-map-workflow-interaction-element-dialog',
  templateUrl: './id-map-workflow-interaction-element-dialog.component.html',
  styleUrls: ['./id-map-workflow-interaction-element-dialog.component.scss']
})
export class IdMapWorkflowInteractionElementDialogComponent {

  selectedStepperIndex = 0;                         // Index of the selected stepper from MatHorizontalStepper
  interactionPairs: Pair[] = [];                    // Pairs of interactions mapped by user
  pairs: Pair[] = [];                               // Array containing pairs of form elements mapped by user
  existingMappings: any[];                          // Existing mappings if interactions have been already mapped
  selectedInteractionPairs = [];                    // Selected interaction and form screen for imported and mapped workflow
  isNewSpecialControlSelected = false;              // Whether the form element selected in mapped interaction is
                                                    // special control or not
  isOldSpecialControlSelected = false;              // Whether the form element selected in imported interaction is
                                                    // special control or not

  @ViewChild("stepperCtrl", { read: ElementRef }) matHorizontalStepper: ElementRef;
  @ViewChild("stepperCtrl", { read: MatStep }) currentStep: MatStep;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    public formBuilderService: FormBuilderService) {
    this.interactionPairs = dialogData.dynFormMdl.interactionPairs || [];
    this.interactionPairs = this.interactionPairs.reverse();
    this.existingMappings = dialogData.dynFormMdl.existingMappings;
    this.interactionPairs.forEach(_ => {
      this.selectedInteractionPairs.push({
        selectedOldScreen: 0,
        selectedNewScreen: 0
      });
    });
    // loop all screens and elements and select existing mappings
    if (this.existingMappings) {
      const valuePairs = Object.entries(this.existingMappings);

      this.interactionPairs.forEach(pair => {
        if (!pair.new.interaction['FormScreens'] || !pair.old.interaction['FormScreens']) {
          return;
        }

        pair.new.interaction['FormScreens'].forEach((formScreen, screenIndex) => {
          if (!formScreen['FormElements']) {
            return;
          }
          formScreen['FormElements'].forEach(formElement => {
            if (this.existingMappings[formElement.id]) {
              formElement.selected = true;
              formElement.formScreenIndex = screenIndex;
            } else {
              formElement.selected = false;
            }
          });
        });

        pair.old.interaction['FormScreens'].forEach((formScreen, screenIndex) => {
          if (!formScreen['FormElements']) {
            return;
          }
          formScreen['FormElements'].forEach(formElement => {
            valuePairs.forEach(valuePair => {
              if (valuePair[1] === formElement.id) {
                formElement.selected = true;
                formElement.formScreenIndex = screenIndex;
                let newFormElement = null;
                pair.new.interaction['FormScreens'].forEach(screen => {
                  if (!newFormElement) {
                    newFormElement = screen['FormElements'].find(element => element.id === valuePair[0]);
                  }
                });
                if (!newFormElement) {
                  formElement.selected = false;
                  return;
                }
                this.pairs.unshift({
                  old: formElement,
                  new: newFormElement
                });
              }
            });
          });
        });

        pair.new.interaction['FormScreens'].forEach(formScreen => {
          if (formScreen['FormElements']) {
            formScreen['FormElements'].forEach(formElement => {
              if (!this.pairs.find(interactionPair => interactionPair.new && interactionPair.new.id === formElement.id)) {
                formElement.selected = false;
              }
            });
          }
        });
      });
    }
  }

  /**
   * This function resets the dialog box to initial state
   */
  onReset(): void {
    this.isNewSpecialControlSelected = false;
    this.isOldSpecialControlSelected = false;
    this.interactionPairs.forEach(interactionPair => {

      interactionPair.old.interaction.FormScreens.forEach(screen => {
        screen.marked = false;
        screen.FormElements.forEach(element => {
          element.selected = false;
        });
      });
      interactionPair.new.interaction.FormScreens.forEach(screen => {
        screen.marked = false;
        screen.FormElements.forEach(element => {
          element.selected = false;
        });
      });
    });
    this.toggleMatStepper(false);
    this.pairs.length = 0;
    this.pairs = [];
  }

  /**
   * This function validates if all the selected elements are paired
   */
  isValid(): boolean {
    return this.pairs.filter(pair => !pair.new).length === 0 && this.pairs.filter(pair => !pair.old).length === 0;
  }

  /**
   * This function gets the index of the selected stepper from MatHorizontalStepper
   *
   * @param event Mat Horizontal Stepper selection change event
   */
  selectionStepperChange(event: any): void {
    this.selectedStepperIndex = event.selectedIndex;
    this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen
      = this.interactionPairs[this.selectedStepperIndex].old[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
        || 0;
    this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen
      = this.interactionPairs[this.selectedStepperIndex].new[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
        || 0;
  }

  /**
   * This function gets the screen index when user changes the tab
   *
   * @param event Mat tab selected tab change
   */
  onOldSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen = event.index;
    this.interactionPairs[this.selectedStepperIndex].old[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
      = this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen;
  }

  /**
   * This function gets the screen index when user changes the tab
   *
   * @param event Mat tab selected tab change
   */
  onNewSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen = event.index;
    this.interactionPairs[this.selectedStepperIndex].new[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
      = this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen;
  }

  /**
   * This function toggles the Mat Horizontal Stepper based on user selection
   *
   * @param flag Toggle for showing or hiding the Mat Stepper
   */
  toggleMatStepper(flag: boolean): void {
    if (this.matHorizontalStepper) {
      const matStepper = this.matHorizontalStepper.nativeElement as HTMLElement;

      const matStepHeaderArray = Array.from(matStepper.children[0].children);
      matStepHeaderArray.forEach((matStepHeader: HTMLElement, index) => {
        if (index % 2 === 0 && flag) {
          matStepHeader.style.opacity = '0.4';
          matStepHeader.style.pointerEvents = 'none';
        } else {
          matStepHeader.style.opacity = '1';
          matStepHeader.style.pointerEvents = 'auto';
        }
      });
    }
  }

  /**
   * This function selects all the old elements in the selected form screen
   *
   * @param formScreen Form Screen containing the form elements being selected
   */
  selectAllOld(formScreen: any): void {
    formScreen.marked = !formScreen.marked;
    if (formScreen.marked) {
      formScreen.FormElements.forEach((element: any) => {
        this.pairs.forEach((pair, pairIndex) => {
          if (pair.old && pair.old.id === element.id) {
            pair.old.selected = false;
            if (pair.new) {
              pair.new.selected = false;
            }
            this.pairs.splice(pairIndex, 1);
          }
        });
      });
      // if form screen is selected, add all the elements to the paired array
      formScreen.FormElements.forEach((element: any, index: number) => {
        element.selected = true;
        element.formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen;
        element.stepperIndex = this.selectedStepperIndex;
        if (this.pairs[formScreen.FormElements.length - 1 - index] && !this.pairs[formScreen.FormElements.length - 1 - index].old) {
          if (this.pairs[formScreen.FormElements.length - 1 - index].new
            && this.pairs[formScreen.FormElements.length - 1 - index].new.type === element.type
            && this.pairs[formScreen.FormElements.length - 1 - index].new.inputType === element.inputType) {
            this.pairs[formScreen.FormElements.length - 1 - index].old = element;
          } else {
            this.pairs[formScreen.FormElements.length - 1 - index].new.selected = false;
            this.pairs.splice(formScreen.FormElements.length - 1 - index, 1);
          }
        } else {
          this.pairs.unshift({
            old: element,
            new: null
          });
        }
      });
    } else {
      // else if form screen is deselected, remove all elements from the paired array
      formScreen.FormElements.forEach(element => {
        element.selected = false;
        const index = this.pairs.findIndex(pair => pair.old.id === element.id);
        if (index !== -1) {
          this.pairs.splice(index, 1);
        }
      });
    }
  }

  /**
   * This function selects all the new elements in the selected form screen
   *
   * @param formScreen Form Screen containing the form elements being selected
   */
  selectAllNew(formScreen: any): void {
    formScreen.marked = !formScreen.marked;
    if (formScreen.marked) {
      formScreen.FormElements.forEach((element: any) => {
        this.pairs.forEach((pair, pairIndex) => {
          if (pair.new && pair.new.id === element.id) {
            if (pair.old) {
              pair.old.selected = false;
            }
            pair.new.selected = false;
            this.pairs.splice(pairIndex, 1);
          }
        });
      });
      // if form screen is selected, add all the elements to the paired array
      formScreen.FormElements.forEach((element: any, index: number) => {
        element.selected = true;
        element.formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen;
        element.stepperIndex = this.selectedStepperIndex;
        if (this.pairs[formScreen.FormElements.length - 1 - index] && !this.pairs[formScreen.FormElements.length - 1 - index].new) {
          if (this.pairs[formScreen.FormElements.length - 1 - index].old
            && this.pairs[formScreen.FormElements.length - 1 - index].old.type === element.type
            && this.pairs[formScreen.FormElements.length - 1 - index].old.inputType === element.inputType) {
            this.pairs[formScreen.FormElements.length - 1 - index].new = element;
          } else {
            this.pairs[formScreen.FormElements.length - 1 - index].old.selected = false;
            this.pairs.splice(formScreen.FormElements.length - 1 - index, 1);
          }
        } else {
          this.pairs.unshift({
            old: null,
            new: element
          });
        }
      });
    } else {
      // else if form screen is deselected, remove all elements from the paired array
      formScreen.FormElements.forEach(element => {
        element.selected = false;
        const index = this.pairs.findIndex(pair => pair.new && pair.new.id === element.id);
        if (index !== -1) {
          this.pairs[index].new = null;
        }
      });
    }
  }

  /**
   * This function maps all elements from all form screens and interaction pairs
   */
  mapAllElements(): void {
    for (const interactionPair of this.interactionPairs) {
      for (let i = 0; i < interactionPair.old.interaction.FormScreens.length; i++) {
        this.selectAllOld(interactionPair.old.interaction.FormScreens[i]);
        this.selectAllNew(interactionPair.new.interaction.FormScreens[i]);
      }
    }
  }

  /**
   * This function selects the form element from old interaction
   *
   * @param formScreen Form screen
   * @param elementIndex Index of the element in the screen
   */
  selectOldElement(formScreen: any, elementIndex: number): void {
    formScreen.FormElements[elementIndex].selected = !formScreen.FormElements[elementIndex].selected;
    if (formScreen.FormElements[elementIndex].selected) {
      // if form element is selected, add it to the paired elements array
      if (this.isOldElementSpecialControl(formScreen, elementIndex)) {
        // if selected form element is a special control, select all special controls
        this.selectOldSpecialControl(formScreen, elementIndex);
      } else {
        formScreen.FormElements[elementIndex].formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen;
        formScreen.FormElements[elementIndex].stepperIndex = this.selectedStepperIndex;
        if (this.pairs[0] && !this.pairs[0].old) {
          this.pairs[0].old = formScreen.FormElements[elementIndex];
        } else {
          this.pairs.unshift({
            old: formScreen.FormElements[elementIndex],
            new: null
          });
        }
        this.isOldSpecialControlSelected = false;
      }
    } else {
      // else if form element is deselected, remove it from the paired array
      if (this.isOldElementSpecialControl(formScreen, elementIndex)) {
        // if deselected form element is a special control, deselect all special controls
        this.deselectOldSpecialControl(formScreen, elementIndex);
      } else {
        const index = this.pairs.findIndex(pair => pair.old.id === formScreen.FormElements[elementIndex].id);
        if (index !== -1) {
          if (this.pairs[index].new) {
            this.interactionPairs[this.selectedStepperIndex].new.interaction.FormScreens[this.pairs[index].new.formScreenIndex]
              .FormElements.forEach(element => {
                if (element.id === this.pairs[index].new.id) {
                  element.selected = false;
                }
              });
          }
          if (this.pairs[index].old) {
            this.interactionPairs[this.selectedStepperIndex].old.interaction.FormScreens[this.pairs[index].old.formScreenIndex]
              .FormElements.forEach(element => {
                if (element.id === this.pairs[index].old.id) {
                  element.selected = false;
                }
              });
          }
          this.pairs.splice(index, 1);
        }
      }
    }
    if (this.isValid()) {
      this.toggleMatStepper(false);
    } else {
      this.toggleMatStepper(true);
    }
  }

  /**
   * This function selects the form element from new interaction
   *
   * @param formScreen Form screen
   * @param elementIndex Index of the element in the screen
   */
  selectNewElement(formScreen: any, elementIndex: number): void {
    formScreen.FormElements[elementIndex].selected = !formScreen.FormElements[elementIndex].selected;
    if (formScreen.FormElements[elementIndex].selected) {
      // if form element is selected, add it to the paired elements array
      if (this.isNewElementSpecialControl(formScreen, elementIndex)) {
        // if selected form element is a special control, select all special controls
        this.selectNewSpecialControl(formScreen, elementIndex);
      } else {
        if (this.pairs[0] && !this.pairs[0].new) {
          this.pairs[0].new = formScreen.FormElements[elementIndex];
        } else {
          this.pairs.unshift({
            old: null,
            new: formScreen.FormElements[elementIndex]
          });
        }
        this.pairs[0].new.formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen;
        this.pairs[0].new.stepperIndex = this.selectedStepperIndex;
      }
    } else {
      // else if form element is deselected, remove it from the paired array
      if (this.isNewElementSpecialControl(formScreen, elementIndex)) {
        // if deselected form element is a special control, deselect all special controls
        this.deselectNewSpecialControl(formScreen, elementIndex);
      } else {
        const index = this.pairs.findIndex(pair => pair.new.id === formScreen.FormElements[elementIndex].id);
        if (index !== -1) {
          if (this.pairs[index].new) {
            this.interactionPairs[this.selectedStepperIndex].new.interaction.FormScreens[this.pairs[index].new.formScreenIndex]
              .FormElements.forEach(element => {
                if (element.id === this.pairs[index].new.id) {
                  element.selected = false;
                }
              });
          }
          if (this.pairs[index].old) {
            this.interactionPairs[this.selectedStepperIndex].old.interaction.FormScreens[this.pairs[index].old.formScreenIndex]
              .FormElements.forEach(element => {
                if (element.id === this.pairs[index].old.id) {
                  element.selected = false;
                }
              });
          }
          this.pairs.splice(index, 1);
        }
      }
    }
    if (this.isValid()) {
      this.toggleMatStepper(false);
    } else {
      this.toggleMatStepper(true);
    }
  }

  /**
   * This function checks if any old element is selected in the selected form screen
   */
  isAnyOldSelected(): boolean {
    if (this.interactionPairs[this.selectedStepperIndex]
      && this.interactionPairs[this.selectedStepperIndex].old
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
      && this.interactionPairs[this.selectedStepperIndex].old.interaction.FormScreens
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].FormElements) {
      for (let i = 0; i < this.interactionPairs[this.selectedStepperIndex].old.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].FormElements.length; i++) {
        if (this.interactionPairs[this.selectedStepperIndex].old.interaction
          .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].FormElements[i].selected) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * This function checks if any new element is selected in the selected form screen
   */
  isAnyNewSelected(): boolean {
    if (this.interactionPairs[this.selectedStepperIndex]
      && this.interactionPairs[this.selectedStepperIndex].new
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
      && this.interactionPairs[this.selectedStepperIndex].new.interaction.FormScreens
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].FormElements) {
      for (let i = 0; i < this.interactionPairs[this.selectedStepperIndex].new.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].FormElements.length; i++) {
        if (this.interactionPairs[this.selectedStepperIndex].new.interaction
          .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].FormElements[i].selected) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * This function checks if all the old elements are selected in the selected form element
   */
  areAllOldSelected(): boolean {
    return this.interactionPairs[this.selectedStepperIndex]
      && this.interactionPairs[this.selectedStepperIndex].old
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
      && this.interactionPairs[this.selectedStepperIndex].old.interaction.FormScreens
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
      && this.interactionPairs[this.selectedStepperIndex].old.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].marked;
  }

  /**
   * This function checks if all the new elements are selected in the selected form element
   */
  areAllNewSelected(): boolean {
    return this.interactionPairs[this.selectedStepperIndex]
      && this.interactionPairs[this.selectedStepperIndex].new
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
      && this.interactionPairs[this.selectedStepperIndex].new.interaction.FormScreens
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
      && this.interactionPairs[this.selectedStepperIndex].new.interaction
        .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].marked;
  }

  /**
   * This function checks if new element selected is of same type as old selected element
   *
   * @param newFormElement formElement being compared
   */
  isSelectedSameNewType(newFormElement: any): boolean {
    return this.pairs[0]
      && this.pairs[0].new
      && this.pairs[0].new.type === newFormElement.type
      && this.pairs[0].new.inputType === newFormElement.inputType
      ? true : false;
  }

  /**
   * This function checks if old element selected is of same type as new selected element
   *
   * @param oldFormElement formElement being compared
   */
  isSelectedSameOldType(oldFormElement: any): boolean {
    return this.pairs[0]
      && this.pairs[0].old
      && this.pairs[0].old.type === oldFormElement.type
      && this.pairs[0].old.inputType === oldFormElement.inputType
      ? true : false;
  }

  /**
   * This function checks if the selected element is a special control in the old interaction in selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  isOldElementSpecialControl(formScreen: any, elementIndex: number): boolean {
    let flag = false;
    this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach(control => {
        if (control.id === formScreen.FormElements[elementIndex].id) {
          flag = true;
        }
      }));
    return flag;
  }

  /**
   * This function checks if the selected element is a special control in the new interaction in selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  isNewElementSpecialControl(formScreen: any, elementIndex: number): boolean {
    let flag = false;
    this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach(control => {
        if (control.id === formScreen.FormElements[elementIndex].id) {
          flag = true;
        }
      }));
    return flag;
  }

  /**
   * This function selects the special controls from old interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  selectOldSpecialControl(formScreen: any, elementIndex: number): void {
    let flag = false;
    let index = 0;
    this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach((control: any) => {
        if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
          return;
        }
        formScreen.FormElements.forEach((element: any) => {
          if (element.id === control.id) {
            element.selected = true;
            element.formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen;
            element.stepperIndex = this.selectedStepperIndex;
            if (this.pairs[specialControl.controls.length - 1 - index] && !flag
              && this.pairs[specialControl.controls.length - 1 - index].new
              && !this.pairs[specialControl.controls.length - 1 - index].old) {
              this.pairs[specialControl.controls.length - 1 - index].old = element;
            } else {
              flag = true;
              this.pairs.unshift({
                old: element,
                new: null
              });
            }
            index++;
          }
        });
      }));
    this.isOldSpecialControlSelected = true;
  }

  /**
   * This function selects the special controls from new interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  selectNewSpecialControl(formScreen: any, elementIndex: number): void {
    let flag = false;
    let index = 0;
    this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach((control: any) => {
        if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
          return;
        }
        formScreen.FormElements.forEach((element: any) => {
          if (element.id === control.id) {
            element.selected = true;
            element.formScreenIndex = this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen;
            element.stepperIndex = this.selectedStepperIndex;
            if (this.pairs[specialControl.controls.length - 1 - index] && !flag
              && this.pairs[specialControl.controls.length - 1 - index].old
              && !this.pairs[specialControl.controls.length - 1 - index].new) {
              this.pairs[specialControl.controls.length - 1 - index].new = element;
            } else {
              flag = true;
              this.pairs.unshift({
                old: null,
                new: element
              });
            }
            index++;
          }
        });
      }));
    this.isNewSpecialControlSelected = true;
  }

  /**
   * This function deselects the special controls from old interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  deselectOldSpecialControl(formScreen: any, elementIndex: number): void {
    let newElementId = null;
    this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
          return;
        }
        formScreen.FormElements.forEach(element => {
          if (element.id === control.id) {
            element.selected = false;
            const index = this.pairs.findIndex(pair => pair.old.id === element.id);
            if (index !== -1) {
              newElementId = this.pairs[index].new && this.pairs[index].new.id;
              this.pairs.splice(index, 1);
            }
          }
        });
      }));
    this.isOldSpecialControlSelected = false;
    this.isNewSpecialControlSelected = false;
    this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls.forEach(specialControl => {
      specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === newElementId)) {
          return;
        }
        this.interactionPairs[this.selectedStepperIndex].new.interaction
          .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
          .FormElements.forEach(element => {
            if (element.id === control.id) {
              element.selected = false;
            }
          });
      });
    });
  }

  /**
   * This function deselects the special controls from new interaction in the selected form screen
   *
   * @param formScreen Form Screen
   * @param elementIndex Index of the form element
   */
  deselectNewSpecialControl(formScreen: any, elementIndex: number): void {
    let oldElementId = null;
    this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls
      .forEach(specialControl => specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === formScreen.FormElements[elementIndex].id)) {
          return;
        }
        formScreen.FormElements.forEach(element => {
          if (element.id === control.id) {
            element.selected = false;
            const index = this.pairs.findIndex(pair => pair.new.id === element.id);
            if (index !== -1) {
              oldElementId = this.pairs[index].old && this.pairs[index].old.id;
              this.pairs.splice(index, 1);
            }
          }
        });
      }));
    this.isNewSpecialControlSelected = false;
    this.isOldSpecialControlSelected = false;
    this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls.forEach(specialControl => {
      specialControl.controls.forEach(control => {
        if (!specialControl.controls.find(currentControl => currentControl.id === oldElementId)) {
          return;
        }
        this.interactionPairs[this.selectedStepperIndex].old.interaction
          .FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
          .FormElements.forEach(element => {
            if (element.id === control.id) {
              element.selected = false;
            }
          });
      });
    });
  }

  /**
   * This function checks if paired interactions have same form elements length and same type
   */
  doesPairedInteractionsHaveSameFormElementsLengthAndType(): boolean {
    let flag = false;
    for (const interactionPair of this.interactionPairs) {
      if (interactionPair.old.interaction.FormScreens.length === interactionPair.new.interaction.FormScreens.length) {
        for (let index = 0; index < interactionPair.old.interaction.FormScreens.length; index++) {
          if (interactionPair.old.interaction.FormScreens[index].FormElements.length
            === interactionPair.new.interaction.FormScreens[index].FormElements.length) {
            for (let elementIndex = 0; elementIndex < interactionPair.old.interaction.FormScreens[index].FormElements.length;
              elementIndex++) {
              if (interactionPair.new.interaction.FormScreens[index].FormElements[elementIndex].type ===
                interactionPair.old.interaction.FormScreens[index].FormElements[elementIndex].type
                && (interactionPair.new.interaction.FormScreens[index].FormElements[elementIndex].inputType ===
                  interactionPair.old.interaction.FormScreens[index].FormElements[elementIndex].inputType)) {
                flag = true;
              } else {
                flag = false;
                return flag;
              }
            }
          } else {
            flag = false;
            return flag;
          }
        }
      }
    }
    return flag;
  }

  /**
   * This function disables the mat step based on user selection
   *
   * @param stepperCtrl Horizontal Mat Stepper
   * @param currentStep Current selected step in Mat Stepper
   */
  disableMatStep(stepperCtrl: MatStepper, currentStep: MatStep): boolean {
    return (
      // disable horizontal mat stepper's step
      // if old select all button is clicked and not all elements are paired
      (this.areAllOldSelected() && !this.isValid())
      // or if new select all button is clicked and not all elements are paired
      || (this.areAllNewSelected() && !this.isValid())
      // or if old element is selected but not paired with new element
      || (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new)
      // or if new element is selected but not paired with old element
      || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
      // and current step is not the selected step
    ) && stepperCtrl.selected !== currentStep;
  }

  /**
   * This function disables the Mat Tab in old section based on user selection
   *
   * @param oldTab current tab
   */
  disableOldMatTab(oldTab: MatTab): boolean {
    return (
      // disable old mat tab
      // if old select all button is clicked and not all elements are paired
      (this.areAllOldSelected() && !this.isValid())
      // or if new select button is clicked and all elements are paired
      || (this.areAllNewSelected() && !this.isValid())
      // or if old element is selected but not paired with new element
      || (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new)
      // or if new element is selected but not paired with old element
      || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
       // and it's not the current selected tab
    ) && !oldTab.isActive;
  }

  /**
   * This function disables the Mat Tab in new section based on user selection
   *
   * @param newTab current tab
   */
  disableNewMatTab(newTab: MatTab): boolean {
    return (
      // disable new mat tab
      // if new select all button is clicked and not all elements are paired
      (this.areAllNewSelected() && !this.isValid())
      // or if new element is selected but not paired with old element
      || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
      // and it's not the current selected tab
    ) && !newTab.isActive;
  }


  /**
   * This function disables the select all button in old section
   *
   * @param interaction Current interaction selected
   * @param screenIndex Index of the form screen in current interaction selected
   */
  disableOldSelectAllButton(interaction: any, screenIndex: number): boolean {
    // disable the old select all button
    // if selected old form screen index is not equal to selected new form screen index
    return this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen
      !== this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen
      || (
        interaction.old.interaction.FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
        && interaction.new.interaction.FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
        // or if selected old form screen's form elements length is not equal to selected new form screen's form elements length
        && interaction.old.interaction.FormScreens
          [this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].FormElements.length
        !== interaction.new.interaction.FormScreens
          [this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].FormElements.length
      )
      // or if current screen index is not equal to selected old screen
      || screenIndex !== this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen
      // or if any pair exists and old select all and new select all buttons are not clicked
      || (this.pairs.length > 0 && this.areAllOldSelected() && this.areAllNewSelected());
  }

  /**
   * This function disables the select all button in new section
   *
   * @param interaction Current interaction selected
   * @param screenIndex Index of the form screen in current interaction selected
   */
  disableNewSelectAllButton(interaction: any, screenIndex: number): boolean {
    // disable the new select all button
    // if selected old form screen index is not equal to selected new form screen index
    return this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen
      !== this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen
      || (
        interaction.old.interaction.FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen]
        && interaction.new.interaction.FormScreens[this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen]
        // or if selected old form screen's form elements length is not equal to selected new form screen's form elements length
        && interaction.old.interaction.FormScreens
          [this.selectedInteractionPairs[this.selectedStepperIndex].selectedOldScreen].FormElements.length
        !== interaction.new.interaction.FormScreens
          [this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen].FormElements.length
      )
      // or if current screen index is not equal to selected new screen
      || screenIndex !== this.selectedInteractionPairs[this.selectedStepperIndex].selectedNewScreen
      // or if any pair exists and new select all and old select all buttons are not clicked
      || (this.pairs.length > 0 && this.areAllNewSelected() && this.areAllOldSelected());
  }

  /**
   * This function disables the form element in old section
   *
   * @param formElement current form element
   * @param elementIndex index of the form element
   * @param screen form screen object
   */
  disableOldFormElement(formElement: any, elementIndex: number, screen: any): boolean {
    const oldSpecialControl =  this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls.find(specialControl =>
      specialControl.controls.find(control => control.id === formElement.id));
    const newSpecialControl = this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls.find(specialControl =>
      specialControl.controls.find(control =>
        control.id === (this.pairs[0] && this.pairs[0].new ? this.pairs[0].new.id : '')));
    return (
      // disable old form element
      // if selected new form element is not of same type
      // and new select all button is not clicked
      // and any new form element is selected
      // and pair exists
      // but either old element or new element doesn't exist in pair
      // and current old element is not a special control element
      // and current old form element is not selected
      !this.isSelectedSameNewType(formElement)
      && !this.areAllNewSelected()
      && this.isAnyNewSelected()
      && this.pairs[0]
      && (!this.pairs[0].old || !this.pairs[0].new)
      && !this.isOldElementSpecialControl(screen, elementIndex) && !formElement.selected
    )
    // or if new select all button is clicked and old select all button is not clicked
    || (this.areAllNewSelected() && !this.areAllOldSelected())
    // or if current old form element is not a special control and new special control form element is selected
    // and old form element doesn't exist in the pair and old special control is not selected
    || (!this.isOldElementSpecialControl(screen, elementIndex) && this.isNewSpecialControlSelected && !this.pairs[0].old
      && !this.isOldSpecialControlSelected)
    || (this.isOldElementSpecialControl(screen, elementIndex) && this.isNewSpecialControlSelected
      && this.pairs[0].new && !this.pairs[0].old
      && (newSpecialControl && newSpecialControl.controls ? newSpecialControl.controls.length : 0)
      !== (oldSpecialControl && oldSpecialControl.controls ? oldSpecialControl.controls.length : 0))
    // or if any old element is selected and pair exists but new form element doesn't exist in pair
    || ((this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new))
    && (this.pairs[0] && this.pairs[0].old && formElement.id !== this.pairs[0].old.id) && !this.areAllOldSelected())
    // or if any new element is selected and pair exists but old form element doesn't exist in pair and current form element is selected
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old) && formElement.selected)
    // or if any new element is selected and pair exists but old form element doesn't exist in pair
    // and current form element is a special control element and new special control element is selected
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old) && this.isOldElementSpecialControl(screen, elementIndex)
      && !this.isNewSpecialControlSelected);
  }

  /**
   * This function disables the form element in new section
   *
   * @param formElement current form element
   * @param elementIndex index of the form element
   * @param screen form screen object
   */
  disableNewFormElement(formElement: any, elementIndex: number, screen: any): boolean {
    const newSpecialControl = this.interactionPairs[this.selectedStepperIndex].new.interaction.specialControls.find(specialControl =>
      specialControl.controls.find(control => control.id === formElement.id));
    const oldSpecialControl = this.interactionPairs[this.selectedStepperIndex].old.interaction.specialControls.find(specialControl =>
      specialControl.controls.find(control =>
        control.id === (this.pairs[0] && this.pairs[0].old ? this.pairs[0].old.id : '')));
    return (
      // disable new form element
      // if selected old form element is not of same type
      // and old select all button is not clicked
      // and any old form element is selected
      // and pair exists
      // but either old element or new element doesn't exist in pair
      // and current new element is not a special control element
      // and current nwe form element is not selected
      !this.isSelectedSameOldType(formElement)
      && !this.areAllOldSelected()
      && this.isAnyOldSelected()
      && this.pairs[0]
      && (!this.pairs[0].old || !this.pairs[0].new)
      && !this.isNewElementSpecialControl(screen, elementIndex)
      && !formElement.selected
    )
    // or if old select all button is clicked and new select all button is not clicked
    || (this.areAllOldSelected() && !this.areAllNewSelected())
    // or if current new form element is not a special control and old special control form element is selected
    // and new form element doesn't exist in the pair and new special control is not selected
    || (!this.isNewElementSpecialControl(screen, elementIndex) && this.isOldSpecialControlSelected && !this.pairs[0].new
      && !this.isNewSpecialControlSelected)
    || (this.isNewElementSpecialControl(screen, elementIndex) && this.isOldSpecialControlSelected
      && this.pairs[0].old && !this.pairs[0].new
      && (newSpecialControl && newSpecialControl.controls ? newSpecialControl.controls.length : 0)
      !== (oldSpecialControl && oldSpecialControl.controls ? oldSpecialControl.controls.length : 0))
      // or if any new element is selected and pair exists but old form element doesn't exist in pair
    || (this.isAnyNewSelected() && (this.pairs[0] && !this.pairs[0].old))
    && (this.pairs[0] && this.pairs[0].new && formElement.id !== this.pairs[0].new.id)
    // or if any old element is selected and pair exists but new form element doesn't exist in pair and current form element is selected
    || (this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new) && formElement.selected)
    // or if any old element is selected and pair exists but new form element doesn't exist in pair
    // and current form element is a special control element and old special control element is selected
    || (this.isAnyOldSelected() && (this.pairs[0] && !this.pairs[0].new) && this.isNewElementSpecialControl(screen, elementIndex)
      && !this.isOldSpecialControlSelected);
  }

  /**
   * This function disables the previous button based on user selection
   *
   * @param stepper Horizontal Mat Stepper
   * @param currentStep Current selected step in Mat Stepper
   */
  disablePreviousButton(stepper: MatStepper, currentStep: MatStep): boolean {
    // disable previous button
    // if horizontal mat stepper index is 0
    return stepper.selectedIndex === 0
    || (
      (
        // or if old select all button is clicked and not all elements are paired
        (this.areAllOldSelected() && !this.isValid())
        // or if old element is selected but not paired with new element
        || (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new)
        // or if new element is selected but not paired with old element
        || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
      )
      // and current step is not selected step
      && stepper.selected !== currentStep
    );
  }

  /**
   * This function disables the next button based on user selection
   *
   * @param stepper Horizontal Mat Stepper
   * @param currentStep Current selected step in Mat Stepper
   */
  disableNextButton(stepper: MatStepper, currentStep: MatStep): boolean {
    // disable previous button
    // if horizontal mat stepper index is length - 1
    return stepper.selectedIndex === (this.interactionPairs.length - 1)
    || (
      (
        // or if old select all button is clicked and not all elements are paired
        (this.areAllOldSelected() && !this.isValid())
        // or if new select all button is clicked and not all elements are paired
        || (this.areAllNewSelected() && !this.isValid())
        // or if old element is selected but not paired with new element
        || (this.pairs[0] && this.pairs[0].old && !this.pairs[0].new)
        // or if new element is selected but not paired with old element
        || (this.pairs[0] && this.pairs[0].new && !this.pairs[0].old)
      )
      // and current step is not selected step
      && stepper.selected !== currentStep
    );
  }

  /**
   * This function returns the string equivalent of the current environment based on window url
   */
  getCurrentEnvironment(): string {
    const url = window.location.href;
    let environmentName = '';

    // set the environment based on url
    if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
      environmentName = 'Development';
    } else if (url.includes('staging.atbeva.com')) {
      environmentName = 'Staging';
    } else if (url.includes('test.atbeva.com')) {
      environmentName = 'Test';
    } else if (url.includes('atbeva.com')) {
      environmentName = 'Production';
    }

    return environmentName;
  }
}
