import { Component, OnInit, Inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LaunchPadMenuItem, LaunchPadMenuItemGroup } from '@eva-model/menu/menu';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-launch-pad-edit',
  templateUrl: './launch-pad-edit.component.html',
  styleUrls: ['./launch-pad-edit.component.scss']
})
export class LaunchPadEditComponent implements OnInit {

  menu$: Observable<LaunchPadMenuItemGroup[]>;
  userItemsGroupId = 'ji864Yx1Sfk67MMn0G8I';

  constructor(private launchPadService: LaunchPadService) { }

  ngOnInit(): void {
    this.menu$ = this.launchPadService.menu$;
  }

  dropMenuItem(event: CdkDragDrop<string[]>, list: any[], groupId: string) {
    // console.log(event);

    // return if the position hasn't changed
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    // moveItemInArray(list, event.previousIndex, event.currentIndex);

    // if (groupId === 'pinned') {
    //   this.launchPadService.reorderPinnedItem(event.item.data.id, event.currentIndex);
    //   return;
    // }

    this.launchPadService.reorderGroupItem(event.item.data.id, groupId, event.currentIndex);
  }

  pinMenuItem(id: string) {
    this.launchPadService.pinItem(id);
  }

  unpinMenuItem(id: string) {
    this.launchPadService.unpinItem(id);
  }

  removeUserItem(item: LaunchPadMenuItem, index: number) {
    this.launchPadService.removeCustomItem(item, index);
  }

}
