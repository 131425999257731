<div *ngIf="(groupData$ | async) as groupData">
  <p *ngIf="groupData"
        matTooltip="
        Group name: {{ group.groupName }}
        Group type: {{ getGroupTypeText(group.groupType) }}
        "
        matTooltipClass="groupInfoTooltip"
        aria-label="Group info">
        <b>{{ group.groupName }}</b> ({{ getGroupTypeText(group.groupType) }} group)
  </p>
  <p *ngIf="!groupData">Group Unknown</p>
</div>


