<div class="id-map-container">
  <button mat-icon-button mat-dialog-close class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>{{
    getCorrectTitle()
  }}</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="form-container">
      <div class="small-width">
        <div class="type-title sticky-header">
          New Workflow Interactions
        </div>
        <div class="list-items" [class.display-none]="mapInteractions.length === 0">
          <div class="sub-heading">Map</div>
          <div *ngFor="let interaction of mapInteractions; let interactionIndex = index;">
            <fieldset class="list-item"
              [class.disabled]="(this.pairs[0] && !this.pairs[0].old) || this.selectedNewInteractionAsNew"
              [class.selected]="interaction.selected" [class.wrong-selected]="false"
              (click)="selectNewInteractionForMap(interaction)">
              <legend class="list-item-legend">ID: {{interaction.interactionId}}</legend>
              Name: {{interaction.interactionName}} <br />
              Group: {{getGroupNameFromGroupPublicKey(interaction.interactionGroupPublicKey)}} <br />
              Version: {{interaction.interactionVersion | date:'MMM d, y @ h:mm:ss a'}}
            </fieldset>
          </div>
        </div>
        <div class="list-items" [class.display-none]="newInteractions.length === 0">
          <div class="sub-heading">New <button class="select-all" (click)="selectAll('New')"
            [class.disabled]="isAnyMapSelected() && !pairs[0].old || (isAnyNewSelected() && !allNewSelected)">Select
            all</button></div>
          <div *ngFor="let interaction of newInteractions; let interactionIndex = index;">
            <fieldset class="list-item"
              [class.disabled]="selectedNewInteractionAsNew || (this.pairs[0] && !this.pairs[0].old)"
              [class.selected]="interaction.selected" [class.wrong-selected]="false"
              (click)="selectNewInteractionAsNew(interaction)">
              <legend class="list-item-legend">ID: {{interaction.interactionId}}</legend>
              Name: {{interaction.interactionName}} <br />
              Group: {{getGroupNameFromGroupPublicKey(interaction.interactionGroupPublicKey)}} <br />
              Version: {{interaction.interactionVersion | date:'MMM d, y @ h:mm:ss a'}}
            </fieldset>
          </div>
        </div>

      </div>
      <span class="vertical-border"></span>
      <div class="big-width">
        <div class="type-title sticky-header">
          Old Interaction
        </div>
        <mat-horizontal-stepper
          [class.disabled]="(!selectedNewInteractionAsNew && (!isNewSelected() || pairs.length === 0))" #stepperCtrl
          [linear]="isLinear" #stepper="matHorizontalStepper" [selectedIndex]="this.selectedStepperIndex"
          (selectionChange)="selectionStepperChange($event)">

          <mat-step [stepControl]="selectUserGroupFormGroup" [optional]=false>
            <div class="card-container">
              <form [formGroup]="selectUserGroupFormGroup" #selectUserGroupForm class="fullWidth">
                <ng-template matStepLabel>Choose a group</ng-template>

                <mat-form-field class="fullWidth">
                  <mat-select placeholder="User group" formControlName="selectGroupCtrl" required>
                    <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupPublicKey">
                      {{ userGroup.groupName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div *ngIf="!selectedNewInteractionAsNew">
                  <button mat-button matStepperNext>Next</button>
                </div>
                <div *ngIf="selectedNewInteractionAsNew">
                  <button
                    [class.disabled]="!selectUserGroupFormGroup.value.selectGroupCtrl || selectUserGroupFormGroup.value.selectGroupCtrl === ''"
                    mat-button (click)="onGroupSelect()">Select</button>
                </div>

              </form>
            </div>
          </mat-step>

          <mat-step [stepControl]="selectEntityFormGroup" *ngIf="!selectedNewInteractionAsNew">

            <div class="card-container">
              <form [formGroup]="selectEntityFormGroup" #selectEntityForm class="fullWidth">
                <ng-template matStepLabel>Select an Interaction</ng-template>

                <div *ngIf="!isWaiting">
                  <mat-form-field class="fullWidth">
                    <mat-select placeholder="Select an Interaction" formControlName="selectEntityCtrl" required>
                      <mat-option *ngFor="let entity of entities" [value]="entity.id">
                        {{ entity.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button matStepperNext>Next</button>
                  </div>
                </div>

                <div *ngIf="isWaiting">
                  <app-spinner name="getting-keys" show="true">
                    <h6>Processing ...</h6>
                  </app-spinner>
                </div>
              </form>
            </div>
          </mat-step>

          <mat-step [stepControl]="selectVersionFormGroup" *ngIf="!selectedNewInteractionAsNew">
            <div class="card-container">
              <form [formGroup]="selectVersionFormGroup" #selectVersionForm class="fullWidth">
                <ng-template matStepLabel>Select the version</ng-template>

                <mat-form-field class="fullWidth">
                  <mat-select placeholder="Select a version" formControlName="selectVersionCtrl" required>
                    <mat-option *ngFor="let entityVer of entityVersions" [value]="entityVer.version">
                      <i *ngIf="editEntity && (entityVer.version === editEntity.activeVersion)"
                        class="fas fa-bolt">&nbsp;::Active::&nbsp;</i>
                      {{ entityVer.version | date:'full' }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div>
                  <button mat-button matStepperPrevious>Back</button>
                  <button mat-button
                    (click)="selectVersion(stepperCtrl, selectUserGroupForm, selectEntityForm, selectVersionForm)">Select</button>
                </div>

              </form>
            </div>
          </mat-step>

        </mat-horizontal-stepper>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" [class.disabled]="!isValid()"
      (click)="onSubmit()">Submit</button>
    <button mat-stroked-button
      (click)="onReset(stepperCtrl, selectUserGroupForm, selectEntityFormGroup, selectVersionFormGroup)">Reset</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>