<h2 mat-dialog-title>Comparing {{comparingVersion | date:'medium'}} against {{ selectedVersion | date:'medium' }} </h2>
<mat-dialog-content>
  <mat-spinner diameter="40" *ngIf="loadingVersion && !selectedVersion"></mat-spinner>
  <div *ngIf="!fullDiffHtml; else diffContent">
    <mat-nav-list>
      <h3 mat-subheader>Choose a Document Version to Compare</h3>
      <mat-list-item *ngFor="let version of docVersions" (click)="selectVersion = version.version; viewComparison();">
        <h4 mat-line>{{ version.version | date:'medium' }} <mat-spinner diameter="20" *ngIf="selectedVersion === version.version && loadingVersion" class="float-right"></mat-spinner></h4>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <ng-template #diffContent>
    <div id="document-diff-print-target">
      <div id="document-diff" [innerHTML]="fullDiffHtml | safeHtml"></div>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions>


  <!-- <button mat-icon-button color="primary" ngxPrint matTooltip="Print Diff" printSectionId="document-diff" [useExistingCss]="true" aria-label="Button opens print dialog">
    <mat-icon>print</mat-icon>
  </button> -->
  <button *ngIf="fullDiffHtml" mat-stroked-button color="primary" ngxPrint matTooltip="Print Diff" printSectionId="document-diff-print-target" [useExistingCss]="true">Print</button>
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
