// import the dynamic types
import {
  // DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
  // DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
  // DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER,
  // DYNAMIC_FORM_CONTROL_TYPE_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_INPUT,
  // DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_SELECT,
  // DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
  DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
  // DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
  // DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
  // DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
  // DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
  DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_SLIDER
  // DynamicInputModel,
  // DynamicFormControlModel,
  // DynamicFormValidationService
} from '@ng-dynamic-forms/core';

import { Component, OnInit, OnDestroy, ViewChild,
  AfterViewInit,
  ElementRef} from '@angular/core';
import { SaveChatService } from '@eva-services/chat/save-chat.service';
import { SpeechRecognitionService } from '@eva-speech/speech-recognition.service';
import { SpeechSynthesisService } from '@eva-speech/speech-synthesis.service';
import { AuthService } from '@eva-core/auth.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';

// bring in the router
import { Router } from '@angular/router';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

import { GroupProviderService } from '@eva-core/group-provider.service';
import { LoggingService } from '@eva-core/logging.service';

import { EvaHeartService } from '../../providers/chat/eva-heart.service';
import { InvitationService } from '../../providers/invitation/invitation.service';
import { DynamicInteractionSyncService } from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import { InteractionEmitValueModel, InteractionValueEmitter } from '@eva-model/InteractionEmitValueModel';
import { ProcessService } from '@eva-services/process/process.service';
import { Guid } from '@eva-core/GUID/guid';
import { InteractionControlModel } from '@eva-model/visualizedInteractionModel';
import { InteractionControlRequest, searchDirection } from '@eva-model/interactionFormControlModel';
import { EvaChatWorkflowsService } from '@eva-services/eva-chat-workflow/eva-chat-workflows-service.service';
import { UserService } from '@eva-services/user/user.service';
import { userLastStateType, ProcessState, LastState } from '@eva-model/userLastState';
import { InteractionAndElementEmitModel } from '@eva-model/interactionAndElementEmitModel';
import { TutorialService } from '@eva-services/tutorial/tutorial.service';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { ChatInteractionService } from '@eva-services/chat/process/chat-interaction.service';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { EvaChatEventTypes, NextChatQueryType, EVAQueryResponse, EvaHeartResponseTypes,
  CustomIdidfEVAKnowledgeResponse,
  EvaChatDirection,
  ChatEntityAuthor,
  ChatEntityType,
  EVAHeartQuery,
  ChatEntity} from '@eva-model/chat/chat';
import { filter, delay, take, tap, mergeMap, map } from 'rxjs/operators';
import { WorkFlow } from '@eva-model/workflow';
import { ChatService } from '@eva-services/chat/chat.service';
import { TfidfDocumentMatches } from '@eva-model/eva-custom-nlp/eva-custom-nlp';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { formatDate } from '@angular/common';
import { InteractionVisualizerMode } from '@eva-model/interactionVisualizerDialogModel';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { Process } from '@eva-model/process/process';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';

@Component({
  selector: 'app-saltchat',
  templateUrl: './saltchat.component.html',
  styleUrls: ['./saltchat.component.scss'],
  animations: [
    trigger('newText', [
      state('in', style({
        opacity: 1,
        transition: 'translateY(0)'
      })),
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }),
        animate(600)
      ])
    ]),
    trigger('toggleChat', [
      state('open', style({
        transform: 'translateY(0)',
        opacity: '1'
      })),
      state('closed', style({
        transform: 'translateY(100%)',
        opacity: '0'
      })),
      transition('open => closed', [
        animate('0.5s cubic-bezier(0,0,0,1)')
      ]),
      transition('closed => open', [
        animate('0.5s cubic-bezier(0,0,0,1)')
      ]),
    ])
  ]
})
export class SaltChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  @ViewChild('questionInput') questionInput: MatInput;

  chatId: string; // id of the chat for processes to use
  queryInputPlaceholder = '';
  chatInputHints$: Observable<string[]>;
  speechEnabled = false;

  retry = true; // this function is used to retry the speech service if it fails.
  result: any; // this is the full response from the EVA Heart project.
  newQuestion: string; // this is the value that is entered into the EVA chat component.
  currentlyRecording = false; // this is used to manage the visualization and code for whether EVA is currently listening.

  recordOngoing: boolean; // this is used to determine if the recording needs to keep capturing during transcription.

  showWorkflowButton = true; // hide/show button that toggles available workflows

  isTranscribe = false; // this is the status of whether EVA is currently in transcribe mode.
  public formEntryTriggered = false; // this is used to determine if the dynamic interactions is triggered (for a process)

  // for maintaining state across devices.
  lastStateObject: any; // this contains the last state of the application that the user is currently in.

  // for interactions
  interactionId: string; // this is the ID of an open interaction;
  interactionScreenNumber: number; // this is used for the current screen number that is open in an interaction.
  interactionElementId: string; // this is the current element that had current status.
  interactionElementValues = {}; // this is the value of the currently executing interaction.

  dynamicInteractionReference: any = null; // this is used for generating interactions and notifying services on the changes
  // occurring in the application

  // this is used to determine if this is during the entry into the dynamic interactions.
  isInDynamicInteractions = false; // is the application currently generating a dynamic interaction.
  interactionType = ''; // this is the interaction element that is currently executing.
  interactionInputType = ''; // this is the type of input in terms of dynamic interactions.
  formVisualizationData: InteractionEmitValueModel; // this is interaction data that is being visualized.
  showDynamicInput = false; // TODO: Seems to be a wrapper for a <mat-select> element also with an ngIf on it, do we really need this?
  dynamicOptions: any[]; // collection of dynamic options

  currentProcessId = null;
  currentInteractionFormElement = null;
  processInteractionId: string; // this is the ID of the currently running interaction in a process.
  processInteractionOriginalId: string; // this is the originally saved ID of the process interaction.
  processInteractionControlId = ''; // this is the ID of the control that is currently being used in an interaction.
  processInteractionControlOriginalId = '';
  dynamicMultipleInput: boolean; // this indicates whether the control is for multiple interactions.
  isProcessInteractionSync = true; // this indicates whether processes interactions are syncing.
  changeInteractionDirection = null;
  isProcessSummaryScreen = false;
  isEditInteractionFlow = false;
  visualizerMode: InteractionVisualizerMode;

  knowledgeOnly = false; // indicates all or only knowledge response from heart.

   // this contains all subscriptions that are created in the component to enable cleanup when the component is destroyed.
  private componentSubs = new Subscription();
  private processDoneSubs: Subscription[] = []; // track our process done subscription independently
  private processCancelSubs: Subscription[] = []; // track our process done subscription independently

  //#region General dialog related
  // related for process creation
  processWorkflowId = null; // the id of the workflow created in the process.
  processState: ProcessState; // this is the current state of the process.
  //#endregion
  workflowId: string; // this allows a workflow id to be passed in to EVA and the workflow started.
  workflowName: string; // this is the name of the workflow passed into a dialog when first created.

  onlyNumbersInChat = false; // this is whether chat should only have numbers entered.
  isChatOpen = true;
  heartRequestSubscription: Subscription = null; // if a heart query is in progress,
  // the subscription is assigned here for the duration of the request

  constructor(
    private speechRecognitionService: SpeechRecognitionService,
    private speechSynthesisService: SpeechSynthesisService,
    private authService: AuthService,
    // private saveChat: SaveChatService,
    private router: Router,
    public dialog: MatDialog,
    public groupProviderService: GroupProviderService,
    private loggingService: LoggingService,
    private evaHeartService: EvaHeartService,
    public evaGlobalService: EvaGlobalService,
    private _inviteService: InvitationService,
    public interactionSyncService: DynamicInteractionSyncService,
    public processService: ProcessService,
    public dynamicInteractionSyncService: DynamicInteractionSyncService,
    private workflowData: EvaChatWorkflowsService,
    private _userService: UserService,
    private lastStateService: LastStateService,
    private chatInteractionService: ChatInteractionService,
    private chatProcessService: ChatProcessService,
    public chatService: ChatService,
    public tutorialService: TutorialService,
    private multiViewService: MultiViewService
  ) { }

  //#region ngAfterViewInit / ngOnInit / OnDestroy
  // TODO: Instead of at the end checking if chatId === interaction targetId,
  // TODO: we can change this so we filter based on targetId and compared with our chatId
  ngAfterViewInit() {
    let currentChatEntity$: Observable<ChatEntity>;
    this.componentSubs.add(
      this.interactionSyncService.interactionControl$
      .pipe(
        mergeMap(control => {
          currentChatEntity$ = this.chatService.currentChatEntity$.pipe(take(1));
          return combineLatest([of(control), currentChatEntity$]);
        }),
        // if on summary page, minimize chat otherwise continue
        map(([control, currentChatEntity]) => {
          if (this.formVisualizationData) {
            this.formVisualizationData.tabIndex = control.tabIndex;
            this.formVisualizationData.processTitle = control.processTitle;
          }
          const message = `Please review the changes on the Summary page. You can edit an interaction using the edit button next
          to the interaction name, otherwise the process can be submitted using the Submit button on that page`;

          if (control?.isSummaryScreen && currentChatEntity.text !== message) {
            this.isProcessSummaryScreen = true;
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: message,
              originator: control.processTitle
            }, null, true);
            this.newQuestion = '';
          } else {
            this.isProcessSummaryScreen = false;
          }
          return control;
        }),
        // if coming to edit an interaction
        filter(control => {
          if (control?.visualizerMode) {
            this.visualizerMode = control.visualizerMode;
          } else {
            this.visualizerMode = null;
          }
          return control;
        }),
        // if continuing after edit interaction
        filter(control => {
          if (control?.isEditInteractionFlow) {
            this.isEditInteractionFlow = control.isEditInteractionFlow;
          } else {
            this.isEditInteractionFlow = false;
          }
          return control;
        }),
        // sanity check - ensure that there is an interaction control
        filter(control => !!(control)),
        // check the target chatId
        // filter(control => control.targetId === this.chatId),
        // ensure object has properties we need
        filter(control => control.interactionId && control.interactionOriginalId),
        // ensure it's not the preview mode from form builder
        filter(control => !control.isPreview),
        // only fire if the control change is coming from a process (this check prevents chat from updating from executed interaction)
        filter(control => control.processId)
      )
      .subscribe(
        async (interactionControl: InteractionControlModel) => {
          this.showDynamicInput = false;
          this.dynamicMultipleInput = false;

          this.currentInteractionFormElement = interactionControl?.FormControl;

          // this.fullResponse = interactionFormElement.hint;
          this.newQuestion = typeof this.currentInteractionFormElement?.value === 'string'
            ? '' + this.currentInteractionFormElement?.value : '';
          this.currentProcessId = interactionControl.processId;
          this.processInteractionId = interactionControl.interactionId;
          this.processInteractionOriginalId = interactionControl.interactionOriginalId;
          this.processInteractionControlId = interactionControl?.FormControl?.id;
          this.processInteractionControlOriginalId = this.currentInteractionFormElement?.originalId;
          this.interactionType = this.currentInteractionFormElement?.type;
          this.interactionInputType = this.currentInteractionFormElement?.inputType;

          this.formVisualizationData = new InteractionEmitValueModel(
            this.processInteractionId,
            this.processInteractionOriginalId,
            this.currentInteractionFormElement?.hint ? this.currentInteractionFormElement?.hint : this.currentInteractionFormElement?.label,
            this.currentInteractionFormElement?.label,
            this.currentInteractionFormElement?.originalId,
            interactionControl.screenIndex,
            this.currentInteractionFormElement?.id,
            this.currentInteractionFormElement?.value,
            this.currentInteractionFormElement?.inputType,
            this.currentInteractionFormElement?.type,
            null,
            this.currentInteractionFormElement?.options,
            interactionControl.processId,
            this.currentInteractionFormElement?.group?.map(group => {
              return { id: group.id, label: group.label };
            }),
            interactionControl.isSpecialControl,
            searchDirection.forward,
            null,
            interactionControl.isCanadaPostWatchControl,
            interactionControl.isEditInteractionFlow,
            interactionControl.tabIndex,
            interactionControl.processTitle
          );

          if (this.currentInteractionFormElement
            && (this.currentInteractionFormElement['disabled'] || this.currentInteractionFormElement.isDisable)) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: 'Next field "' + interactionControl.FormControl.label + '" is disabled, taking you to the next one',
              originator: interactionControl.processTitle
            }, null);
            return this.interactionSyncService.announceControlValueUpdate(this.formVisualizationData);
          }

          if (this.currentInteractionFormElement
            && ((this.currentInteractionFormElement.formCntrl && this.currentInteractionFormElement.formCntrl.readOnly)
            || (this.currentInteractionFormElement.readOnly))) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: 'Next field "' + interactionControl.FormControl.label + '" is read only, taking you to the next one',
              originator: interactionControl.processTitle
            }, null);
            return this.interactionSyncService.announceControlValueUpdate(this.formVisualizationData);
          }

          if (this.currentInteractionFormElement
            && ((this.currentInteractionFormElement.formCntrl && this.currentInteractionFormElement.formCntrl.hidden)
            || (this.currentInteractionFormElement.hidden))) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: 'Next field "' + interactionControl.FormControl.label + '" is hidden, taking you to the next one',
              originator: interactionControl.processTitle
            }, null);
            return this.interactionSyncService.announceControlValueUpdate(this.formVisualizationData);
          }

          this.interactionInputType = (this.currentInteractionFormElement?.inputType
            ? this.currentInteractionFormElement?.inputType : this.currentInteractionFormElement?.type);

          // TODO: initialize select box here if interactionInputType === select
          if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {

            this.showDynamicInput = true;
            this.dynamicOptions = this.currentInteractionFormElement.options;
            if (this.currentInteractionFormElement?.multiple) {
              this.dynamicMultipleInput = true;
            }

          }

          if (interactionControl.errMsg) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: interactionControl.errMsg,
              originator: interactionControl.processTitle
            }, null);
            if (interactionControl.errType) {
              this.changeInteractionDirection = interactionControl.errType;
              this.chatService.newChatEntity({
                author: ChatEntityAuthor.EVA,
                type: ChatEntityType.Text,
                text: interactionControl.errType === searchDirection.forward
                  ? 'Would you like to go to the next interaction? (Yes/No)'
                  : 'Would you like to go to the previous interaction? (Yes/No)',
                originator: interactionControl.processTitle
              }, null);
            }
            this.newQuestion = '';
            return;
          }
          this.changeInteractionDirection = null;

          // const interactionHint = this.currentInteractionFormElement.hint
          // ? this.currentInteractionFormElement.hint : this.currentInteractionFormElement.label;
          // this.fullResponse = interactionHint;
          // this.newQuestion = typeof this.currentInteractionFormElement.value === 'string'
          // ? this.currentInteractionFormElement.value : '';
          // this.processInteractionId = interactionControl.interactionId;
          // this.processInteractionControlId = interactionControl.FormControl.id;

          if (interactionControl.visualizerMode !== InteractionVisualizerMode.edit) {
            let validInput = '';
            const evaChatEventType = this.determineEventForEVA();
            if (evaChatEventType === EvaChatEventTypes.SWITCH) {
              validInput = ' (e.g. Yes/No)';
            } else if (evaChatEventType === EvaChatEventTypes.CHECKBOXGROUP) {
              validInput = ' (e.g. 1st/Second etc)';
              if ((this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_SELECT && interactionControl.FormControl.multiple === true)
                || this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                validInput = ' (e.g. First and 3rd/Fifth etc)';
              }
            } else if (evaChatEventType === EvaChatEventTypes.DATE) {
              validInput = ' (e.g. today/next sunday/23rd Feb this year etc)';
            } else if (evaChatEventType === EvaChatEventTypes.DATETIME) {
              validInput = ' (e.g. today at 10pm/next sunday 5am/23rd Feb this year 9am etc)';
            } else if (evaChatEventType === EvaChatEventTypes.EMAIL) {
              validInput = ' (e.g. joe@example.com)';
            } else if (evaChatEventType === EvaChatEventTypes.MONTH) {
              validInput = ' (e.g. january this year/next month etc)';
              if (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                && this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK) {
                  validInput = ' (e.g. 26th week, 52nd week etc)';
                }
            } else if (evaChatEventType === EvaChatEventTypes.NUMBER) {
              validInput = ' (e.g. 123, five etc)';
            } else if (evaChatEventType === EvaChatEventTypes.TEL) {
              validInput = ' (e.g. 2111111111 etc)';
            } else if (evaChatEventType === EvaChatEventTypes.TIME) {
              validInput = ' (e.g. 12:45pm etc)';
            } else if (evaChatEventType === EvaChatEventTypes.URL) {
              validInput = ' (e.g. https://box.com etc)';
            } else {
              if (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                && this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
                && interactionControl.FormControl.multiple) {
                  validInput = ' (Multiple values separated by commas(,) e.g. One, Two etc)';
                }
            }

            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.InteractionResponse,
              text: interactionControl?.FormControl?.label + validInput,
              metadata: {
                interactionFormElement: this.currentInteractionFormElement,
                formVisualizationData: this.formVisualizationData
              },
              originator: interactionControl.processTitle
            }, null);
          } else {
            this.newQuestion = '';
          }

          if (interactionControl.isSpecialControl && interactionControl.lastEmittedFrom === InteractionValueEmitter.DialogFlow
            && this.currentInteractionFormElement.value) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.User,
              type: ChatEntityType.Text,
              text: this.currentInteractionFormElement?.value
            }, null);
          }

          if ( this.processState &&
              interactionControl.processId &&
              this.processState.processId === interactionControl.processId &&
              typeof interactionControl.lastEmittedFrom === 'undefined') {

            if ( this.processState.executingInteractionOriginalId !== interactionControl.interactionOriginalId ) {

              this.processState.executingInteractionOriginalId = interactionControl.interactionOriginalId;
              const clonedProcessState = JSON.parse(JSON.stringify(this.processState));
              const currentLastState = this.lastStateService.getLastState();
              currentLastState.tabs[Routes.Process][interactionControl.tabIndex] = {
                ...currentLastState.tabs[Routes.Process][interactionControl.tabIndex],
                additionalInstanceData: clonedProcessState
              };

              this.lastStateService.updateSaveLastState(currentLastState);

            } else if ( !this.isProcessInteractionSync ) {
              // TODO :: Loop through interaction values and update the by original interaction id and process id
              if ( this.processState && this.processState.interactionsValues ) {
                const processStateInteraction = (this.processState.interactionsValues as InteractionAndElementEmitModel);
                if ( processStateInteraction.interactionFlatValues &&
                  processStateInteraction.interactionFlatValues.elements &&
                  Array.isArray(processStateInteraction.interactionFlatValues.elements) &&
                  processStateInteraction.interactionFlatValues.elements.length > 0 ) {
                    const currentFormControl = processStateInteraction.interactionFlatValues.elements.find(element =>
                      element.originalId === this.processInteractionControlOriginalId);
                    if (currentFormControl) {
                      this.newQuestion = currentFormControl.value;
                    }

                    // Check elements as Array
                    processStateInteraction.interactionFlatValues.elements.forEach( element => {

                      const emitObject = new InteractionEmitValueModel(
                        processStateInteraction.interactionFlatValues.id,
                        processStateInteraction.interactionFlatValues.originalId,
                        null,
                        null,
                        element.originalId,
                        element.scrnIndex,
                        element.id,
                        element.value,
                        null,
                        element.type,
                        InteractionValueEmitter.LastState,
                        null,
                        processStateInteraction.processId,
                        null,
                        false,
                        searchDirection.forward,
                        null,
                        null,
                        this.isEditInteractionFlow
                      );

                      if (this.currentInteractionFormElement?.originalId === element.originalId && (element.value || element.value === 0)) {
                        this.chatService.newChatEntity({
                          author: ChatEntityAuthor.User,
                          type: ChatEntityType.Text,
                          text: element.value
                        }, null);
                        this.interactionSyncService.announceControlValueUpdate(emitObject);
                      }
                    });
                }
              }

              this.isProcessInteractionSync = true;
            }
          }

        },
        ( err ) => console.log(err)
      )
    );

    this.componentSubs.add(
      this.interactionSyncService.interactionAndLastElementValueChanged$
      .pipe(
        // check for process state
        filter(interactionAndElement => !!(this.processState)),
        // check for process id
        filter(interactionAndElement => interactionAndElement.hasOwnProperty('processId')),
        // check if process id emitted is matching current process id
        filter(interactionAndElement => this.processState.processId === interactionAndElement.processId),
        // check if interaction ids match
        filter(interactionAndElement =>
          interactionAndElement.interactionFlatValues.originalId === this.processState.executingInteractionOriginalId),
        // ensure it's not the preview mode from form builder
        filter(control => !control.isPreview)
      )
      .subscribe(
        async ( interactionAndElement: InteractionAndElementEmitModel ) => {
          if (interactionAndElement.isPreview) {
            return;
          }
          this.processState.interactionsValues = interactionAndElement;
          const clonedProcessState = JSON.parse(JSON.stringify(this.processState));
          try {
            // NOTE: This runs for as many elements as there are in this interaction
            const currentLastState = this.lastStateService.getLastState();
            currentLastState.tabs[Routes.Process][interactionAndElement.tabIndex] = {
              ...currentLastState.tabs[Routes.Process][interactionAndElement.tabIndex],
              additionalInstanceData: clonedProcessState
            };

            this.lastStateService.updateSaveLastState(currentLastState);
          } catch (err) {
            console.warn('Unable to update state', err);
          }
        },
        ( err ) => console.error(err)
      )
    );

    this.componentSubs.add(
      this.chatService.isChatMinimized$.subscribe(value => this.isChatOpen = !value)
    );

    this.componentSubs.add(
      this.speechSynthesisService.speechEnabledInChat$.subscribe(value => this.speechEnabled = value)
    );

    this.componentSubs.add(
      this.chatInteractionService.announceStartingInteraction$.subscribe(announcement => {
        this.interactionId = announcement.interactionId;
      })
    );

    this.componentSubs.add(
      this.interactionSyncService.invalidCurrentForm$.subscribe(update => {
        if (Array.isArray(update.invalidElements) && update.invalidElements.length > 0) {
          let textMessage = 'I cannot take you to the next interaction because the following elements are invalid on the current page:';

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: update.processTitle
          }, null);

          textMessage = '';
          update.invalidElements.forEach((element, index) => {
            textMessage += `${index + 1}. ${element.label ?? element.hint}<br>`;
            textMessage += `<i style="margin-left: 25px">${element.value ?? 'No value'}</i><br>`;
          });

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: update.processTitle
          }, null);

          textMessage = 'Please add the required values before proceeding to next interaction.';

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: update.processTitle
          }, null);

          if (this.currentInteractionFormElement) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.InteractionResponse,
              text: this.currentInteractionFormElement?.label,
              metadata: {
                interactionFormElement: this.currentInteractionFormElement,
                formVisualizationData: this.formVisualizationData
              },
              originator: update.processTitle
            }, null);
          } else {
            const emitObject = new InteractionEmitValueModel(
              this.formVisualizationData.interactionId,
              this.formVisualizationData.interactionOriginalId,
              this.formVisualizationData.interactionHint,
              this.formVisualizationData.interactionLabel,
              this.formVisualizationData.elementOriginalId,
              this.formVisualizationData.scrnIndex,
              this.formVisualizationData.formControlId,
              this.formVisualizationData.value,
              this.formVisualizationData.inputType,
              this.formVisualizationData.type,
              InteractionValueEmitter.LastState,
              null,
              this.formVisualizationData.processId,
              null,
              false,
              searchDirection.forward,
              null,
              null,
              this.isEditInteractionFlow
            );
            this.handleInteractionDirectionCommand(emitObject, searchDirection.forward, 0,
              this.formVisualizationData.isEditInteractionFlow, this.formVisualizationData.processId,
              this.formVisualizationData.processTitle);
          }
        } else {
          if (update.fulfillmentText) {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: update.fulfillmentText,
              originator: update.processTitle
            }, null);
          }
        }
      })
    );

    this.componentSubs.add(
      this.interactionSyncService.processInteractionEdit$.subscribe((data) => {
        if (Array.isArray(data.values) && data.values.length > 0) {
          let textMessage = 'You entered following values for this page:';

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: data.processTitle
          }, null);

          textMessage = '';
          let currentIndex = 0;
          data.values.forEach((_, screenIndex) => {
            data.values[screenIndex].forEach((element, index) => {
              let value = element.value;
              if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                if (!element.value) {
                  element.value = {};
                  element.group.forEach(control => {
                    element.value[control.id] = control.value;
                  });
                }
                value = '';
                Object.keys(element.value).forEach(key => {
                  if (element.value[key]) {
                    if (value.length > 0) {
                      value += ', ';
                    }
                    value += element?.group?.find(control => control.id === key)?.label;
                  }
                });
                if (value.length === 0) {
                  value = null;
                }
              }
              textMessage += `${currentIndex + 1}. ${element.label ?? element.hint}<br>`;
              textMessage += `<i style="margin-left: 25px">${value ?? 'No value'}</i><br>`;
              currentIndex++;
            });
          });

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: data.processTitle
          }, null);

          textMessage = "Which element would you like to update? You can say the position to go to that element (e.g. first, 5th etc.)"
            + " or you can go to next interaction by saying 'next interaction'.";

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage,
            originator: data.processTitle
          }, null);
        }
      })
    );

    this.componentSubs.add(
      this.chatProcessService.announceFetchProcess$.subscribe(workflow => {
        this.handleWorkflowFromDialog(workflow);
      })
    );
  }

  // initializeDataSync(usersLastState: any): void {
  //   // this is to ensure replication to all logged in devices.
  //   this.componentSubs.add(
  //     this.interactionSyncService.controlValueDatabase$.pipe(
  //       // ensure it's not the preview mode from form builder
  //       filter(control => !control.isPreview)
  //     )
  //     .subscribe(
  //       (data) => {
  //         if (!this.interactionElementValues[data.elementOriginalId]) {
  //           this.interactionElementValues[data.elementOriginalId] = data;
  //         } else {
  //           Object.assign(this.interactionElementValues, { [data.elementOriginalId]: data });
  //         }

  //         this.result = JSON.parse(JSON.stringify(usersLastState)); // convert user state into 'new' object without ref?
  //         this.result.interactionValues = JSON.parse(JSON.stringify(this.interactionElementValues));  // same as above.
  //       },
  //       ( err ) => console.log(err)
  //     )
  //   );
  // }

  /**
   * initialization hook
   */
  ngOnInit() {
    // bind chat hint messages, subscribe on template
    this.chatInputHints$ = this.chatService.saltChatHint$;

    this.componentSubs.add(
      this.chatService.announceChatPlaceholderText$.pipe(
        delay(0)
      ).subscribe((text) => {
        this.queryInputPlaceholder = text;
      })
    );

    // Generate a chat id for targeting of interaction elements
    this.chatId = Guid.newGuid().toString();
    // Save our chatId in our service for further usage by the process service
    this.chatProcessService.setChatId(this.chatId);

    // Subscribe to the announce of a process starting and alter the view
    // In this case, remove the select a process button.
    this.componentSubs.add(
      this.chatProcessService.announceStartingProcess$.subscribe((value) => {
        this.showWorkflowButton = value ? false : true;
      })
    );

    // Subscribe to the announcement that a process is finished.
    // show the process picker button again.
    this.componentSubs.add(
      this.processService.processDone$.subscribe((value) => {
        if (value) {
          this.showWorkflowButton = true;
          this.breakRequest(null, true, true);
        }
      })
    );

    /**
     * Handles listening to the queries that the user is typing and actions them accordingly.
     */
    this.componentSubs.add(
      this.chatService.nextChatQuery$.subscribe((queryObj) => {
        if (queryObj.type === NextChatQueryType.BREAK) {
          this.breakRequest();
        }

        if (queryObj.type === NextChatQueryType.PROCESS_NAME) {
          this.actionIsKnownWorkflow(this.processWorkflowId, queryObj.query);
          this.setOnlyNumbersInChat(false);
          this.chatService.resetNextChatQueryType();
        }

        if (queryObj.type === null) {
          this.handleQuestion(queryObj.query);
        }
        // this.chatService.newChatEntityTest(queryObj.query);
      })
    );

    this.componentSubs.add(
      this.chatService.announceFocusChatInput$.subscribe(() => {
        if (this.questionInput) {
          this.questionInput.focus();
        }
      })
    );

    // This subscription is listening to the workflow Data observable to determine if the chat component needs to generate /
    // prompt for the user to provide a process name.
    this.componentSubs.add(
      this.workflowData.currentWorkflow$.subscribe(workflow => {
        this.handleWorkflowFromDialog(workflow);
      })
    );

    // this sets up the service to keep items in sync across devices.
    /// ||| to do for last state.
    this.componentSubs.add(
      this.authService.user
      .subscribe(
        async (user) => {
          if (!user) return;

          // this.user = user; TODO: this may be needed when show message history is brought back.
          // this.componentSubs.add(
          //   this.lastStateService.lastState$
          //   .subscribe(
          //     (usersLastState) => {

          //       // last state has not be initialized yet
          //       if (!usersLastState) return;

          //       // check if this has been initialized.
          //       this.initializeBasedOnLastState(usersLastState);

          //       // check if this is the EVA interaction
          //       if (usersLastState.type && usersLastState.type === userLastStateType.interaction) {
          //         usersLastState.interactionValues = {};
          //         this.setupLastStateFromKeys(usersLastState);
          //         this.initializeDataSync(usersLastState);
          //       }

          //       // check if this is an EVA process
          //       // if (usersLastState.type && usersLastState.type === userLastStateType.process) {
          //       //   if ( usersLastState.interactionsValues &&
          //       //     usersLastState.interactionsValues.processId &&
          //       //     usersLastState.interactionsValues.interactionLastElementValueChange ) {

          //       //     const interactionElement = usersLastState.interactionsValues.interactionLastElementValueChange;
          //       //     const emitObject = new InteractionEmitValueModel(
          //       //       null,
          //       //       interactionElement.interactionOriginalId,
          //       //       null,
          //       //       null,
          //       //       interactionElement.elementOriginalId,
          //       //       interactionElement.scrnIndex,
          //       //       null,
          //       //       interactionElement.value,
          //       //       null,
          //       //       interactionElement.type,
          //       //       null,
          //       //       usersLastState.interactionsValues.processId
          //       //     );

          //       //     this.interactionSyncService.announceControlValueUpdate(emitObject);
          //       //   }
          //       // }
          //     },
          //     ( err ) => console.log('error subscribing to state. ', err)
          //   )
          // );
      })
    );

    this._inviteService.init();

    // initialize and start the tutorials for this page if applicable
    this.tutorialService.showTutorial('welcome').subscribe(() => {});

    // check for existence of knowledge only toggle
    if (localStorage.getItem('heartQueryFilter')) {
      this.knowledgeOnly = true;
    }
  }
//#endregion ngOnInit / OnDestroy

  // onSelectChange(targetValue) {
  //   // console.log("targetValue: ", targetValue);
  //   this.newQuestion = targetValue;
  // }

  ngOnDestroy() {
    this.speechRecognitionService.DestroySpeechObject();
    // this.saveChat.getLastState()

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }

    if (this.processDoneSubs) {
      this.processDoneSubs.forEach(subscription => {
        subscription.unsubscribe();
      });
    }

    if (this.processCancelSubs) {
      this.processCancelSubs.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }
  //#endregion ngOnInit / OnDestroy

  //#region InitializeBasedOnLastState
  initializeBasedOnLastState(usersLastState: any): void {
    // last state is not known
    if (!usersLastState) {
      return;
    }

    // state is known
    // this.askedQuestion = usersLastState.query;
    const typeML = usersLastState.type;

    switch (typeML) {
      case userLastStateType.dialogflow:
        // this.fullResponse = usersLastState.response.fulfillmentText;
        // this.machineLearningResponse = false;
        this.isInDynamicInteractions = false; // set the state of dynamic interaction
        break;

      case userLastStateType.watson:
        // this.fullResponse = usersLastState.response[usersLastState.count].passage_text;
        // this.machineLearningResponse = true;
        this.isInDynamicInteractions = false; // set the state of dynamic interaction
        break;

      case userLastStateType.interaction:
        this.isInDynamicInteractions = true; // set the state of dynamic interaction
        // this.machineLearningResponse = false;
        this.interactionScreenNumber = usersLastState.screen;
        this.interactionElementId = usersLastState.id;

        // if ( usersLastState && usersLastState.interactionResponse ) {
        //   // this.disableAdminControlsIfNotChangeAdmin(usersLastState.interactionResponse);
        //   this.provideTextInteraction(usersLastState.interactionResponse);
        // }

        break;

      case userLastStateType.process:
        // TODO :: Expecting state property type is ProcessState
        this.processState = new ProcessState (
          usersLastState.processId,
          usersLastState.executingInteractionOriginalId,
          usersLastState.interactionsValues,
          usersLastState.query,
          usersLastState.type
        );

        this.isProcessInteractionSync = false;
        this.triggerLastStateProcess(usersLastState.processId);
        break;

      default:
        this.isInDynamicInteractions = false;
        break;
    }
  }
  //#endregion InitializeBasedOnLastState

  //#region sendQuestionsForResponse
  determineEventForEVA(): EvaChatEventTypes {
    switch (this.interactionType) {
      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
        // check the subtype:
        if (this.interactionInputType !== '') {
          return this.determineSubType();
        } else {
          return EvaChatEventTypes.DEFAULT;
        }
      case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
        return EvaChatEventTypes.DATE;
      case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
        return EvaChatEventTypes.SWITCH;
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
        return EvaChatEventTypes.CHECKBOX;
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
        return EvaChatEventTypes.CHECKBOXGROUP;
      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
        return EvaChatEventTypes.RADIOGROUP;
      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
        return EvaChatEventTypes.SELECT;
      case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:
        return EvaChatEventTypes.SLIDER;
      default:
        return EvaChatEventTypes.DEFAULT;
    }
  }

  determineControlTypeFromEVA(evaChatEvent: EvaChatEventTypes): any {
    switch (evaChatEvent) {
      case EvaChatEventTypes.DEFAULT:
        // check the subtype:
        if (this.interactionInputType !== '') {
          return this.determineElementSubType(evaChatEvent);
        } else {
          return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT;
        }
      case EvaChatEventTypes.DATE:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE;
      case EvaChatEventTypes.DATETIME:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL;
      case EvaChatEventTypes.TIME:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME;
      case EvaChatEventTypes.MONTH:
      // case EvaChatEventTypes.WEEK:
        return this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH
          ? DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH
          : DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK;
      case EvaChatEventTypes.SWITCH:
      // case EvaChatEventTypes.CHECKBOX:
        return this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SWITCH
          ? DYNAMIC_FORM_CONTROL_TYPE_SWITCH
          : DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX;
      case EvaChatEventTypes.SELECT:
      // case EvaChatEventTypes.RADIOGROUP:
        return this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT
          ? DYNAMIC_FORM_CONTROL_TYPE_SELECT
          : DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP;
      default:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT;
    }
  }

  determineSubType(): EvaChatEventTypes {
    switch (this.interactionInputType) {
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE:
        return EvaChatEventTypes.DATE;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL:
        return EvaChatEventTypes.DATETIME;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL:
        return EvaChatEventTypes.EMAIL;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE:
        return EvaChatEventTypes.FILE;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH:
        return EvaChatEventTypes.MONTH;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER:
        return EvaChatEventTypes.NUMBER;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:
        return EvaChatEventTypes.TEL;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME:
        return EvaChatEventTypes.TIME;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL:
        return EvaChatEventTypes.URL;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK:
        return EvaChatEventTypes.WEEK;
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD:
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH:
      case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT:
      case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:
      default:
        return EvaChatEventTypes.DEFAULT;
      // this is unknown DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
    }
  }

  determineElementSubType(action: EvaChatEventTypes): any {
    switch (action) {
      case EvaChatEventTypes.DATE:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE;
      case EvaChatEventTypes.DATETIME:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL;
      case EvaChatEventTypes.EMAIL:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL;
      case EvaChatEventTypes.FILE:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE;
      case EvaChatEventTypes.MONTH:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH;
      case EvaChatEventTypes.NUMBER:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER;
      case EvaChatEventTypes.TEL:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL;
      case EvaChatEventTypes.TIME:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME;
      case EvaChatEventTypes.URL:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL;
      case EvaChatEventTypes.WEEK:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK;
      default:
        return DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT;
      // this is unknown DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
    }
  }

  async askQuestion(newQuestion: string) {
    newQuestion = newQuestion.trim(); // add in a trim to make sure there is data available.
    if (newQuestion.length === 0) {
      return;
    }

    if (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT
      && this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD) {
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.User,
        type: ChatEntityType.Text,
        text: '' + (Array(newQuestion.length).join('*'))
      });
    } else {
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.User,
        type: ChatEntityType.Text,
        text: newQuestion
      });
    }

    // query was a break
    if (('' + newQuestion).toLowerCase() === 'break' || ('' + newQuestion).toLowerCase() === 'cancel') {
      this.chatService.setNextChatQueryType(NextChatQueryType.BREAK);

      // disable any loading indicator if showing
      // this.chatService.newChatEntity({
      //   author: ChatEntityAuthor.EVA,
      //   type: ChatEntityType.Text,
      //   text: `Break!`
      // });
    }

    // Send user query through observable
    this.chatService.setNextChatQueryMessage('' + newQuestion);
    // const currentLastState = this.lastStateService.getLastState();
    //   const newLastState = {
    //     ...currentLastState,
    //     chat: {
    //       ...currentLastState.chat,
    //       user: userSays
    //     }
    //   };
    //   this.lastStateService.updateSaveLastState(newLastState);

    // clear the query input
    this.newQuestion = '';
  }

  onQueryValueChange(event: KeyboardEvent): void {
    let flag = true;
    const valueChange: InteractionEmitValueModel = {
      ...this.formVisualizationData,
      value: (<HTMLInputElement>event.target).value,
      lastEmittedFrom: InteractionValueEmitter.Chat
    };

    switch (valueChange.type) {
      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
        switch (valueChange.inputType) {
          // case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE:
          //   if (isNaN(new Date(valueChange.value).getTime())
          //     || !(new RegExp(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/).test(valueChange.value))) {
          //     flag = false;
          //   }
          //   break;
          // case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL:
          //   flag = false;
          //   break;
          // case DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH:
          //   flag = false;
          //   break;
          // case DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK:
          //   flag = false;
          //   break;
          // case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME:
          //   flag = false;
          //   break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT:
            if (!this.formVisualizationData.isSpecialControl) {
              flag = false;
            }
            break;
          default: flag = false; break;
        }
        break;
      // case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
      //   flag = false;
      //   break;
      // case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
      // case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
      // case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
      //   flag = false;
      //   break;
      default: flag = false; break;
    }

    if (flag) {
      this.interactionSyncService.announceControlValueChange(valueChange);
    }
  }

  handleQuestion(newQuestion: string) {
    if (this.determineIfInInteraction() && !this.knowledgeOnly) {
      // if there is an interaction in focus, handle this, otherwise let it be.
      this.handleQuestionInInteraction(newQuestion);
    } else {
      this.sendHeartRequest(newQuestion);
    }
  }

  /**
   * Sends a query to EVA Heart.
   * If invoked more than once and a previous query has not returned, it cancels
   * the previous query and sends a new one.
   */
  sendHeartRequest(query: string): void {
    // cancel request if there is currently on ongoing request
    if (this.heartRequestSubscription) {
      this.heartRequestSubscription.unsubscribe();
      this.heartRequestSubscription = null;

      // display the previous request is cancelled
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: `Okay, I'll look for ${query} instead...`
      });
    }

    this.newQuestion = ''; // clear the question

    // create request object
    let request: EVAHeartQuery;
    if (!this.isInDynamicInteractions || this.knowledgeOnly) {
      request = {query};
    } else {
      const evaEvent = this.determineEventForEVA();
      request = {
        query,
        event: evaEvent,
        direction: EvaChatDirection.DYNAMIC_INTERACTION_FOLLOWUP
      };
    }

    this.heartRequestSubscription = this.evaHeartService.sendRequest(request).subscribe(
      (res) => this.handleHeartResponse(res),
      (err) => this.handleHeartError(err)
    );
  }

  handleHeartResponse(res: EVAQueryResponse) {
    this.result = res;

    // set the result to the state that has come back.
    if (res.type === EvaHeartResponseTypes.TFIDF) {
      this.formatHeartResponseAsKnowledge(res);
    }

    // store the response to the user's object
    // this.saveChat.saveChatSession(res.response.fulfillmentText, false, this.result);

    // this.lastStateService.updateSaveLastState(this.result);

    // if there is additional followup let this go ahead.
    if (this.result.response.allRequiredParamsPresent) {
      // get the next item.
      this.getAdditionalFulfillment(this.result);
    } else {
      // additional params are required, show the next question in the chat
      if (res.type !== EvaHeartResponseTypes.TFIDF) {
        this.chatService.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: res.response.fulfillmentText
        });
      }
    }

    // clear out the query request subscription
    if (this.heartRequestSubscription) {
      this.heartRequestSubscription.unsubscribe();
      this.heartRequestSubscription = null;
    }
  }

  /**
   * Error handler for subscription to heart query
   */
  handleHeartError(err: any) {
    console.error(err);
    // TODO: handle this and release the chat back to thee user.
    this.loggingService.logMessage('an error occurred while sending your query.', false, 'error');
    return Promise.reject(err);
  }

  /** Formats the heart response and creates a new chat entity as knowledge response */
  private formatHeartResponseAsKnowledge(res: EVAQueryResponse) {
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: `Here's what I found for you:`
    });

    // get the section that was found.
    const tfidfMl = res.response as CustomIdidfEVAKnowledgeResponse;
    // get the best response from the item
    // TODO: move this to a TFIDF - nlp service.
    if (tfidfMl.tfidfDocumentMatches.length > 0) {
      // get thee best match.
      const bestMatch: TfidfDocumentMatches = tfidfMl.tfidfDocumentMatches[0];
      tfidfMl.count = 0;
      // get the section we are looking for by breaking down the id and then rejoining it for the proper spot.
      const splitIds = bestMatch.docId.split('_');

      // [ doc id , version , section ]
      const bestSectionId: string = splitIds.slice(2, splitIds.length).join('_');

      // get the best section for the document based on what has been entered.
      // sometimes, documents don't have sections, so this can be an empty array.
      const flatSections = KnowledgeUtils.getSectionsAsFlatArray(tfidfMl.knowledgeDocument.sections);
      const bestSection = flatSections.filter(item => item.id === bestSectionId);
      // get the text for the section.

      // ensure this index exists
      if (bestSection[0]) {
        tfidfMl.fulfillmentText = bestSection[0].text.trim();
        // if after trim, it is an empty string, set a default string.
        if (tfidfMl.fulfillmentText = '') {
          tfidfMl.fulfillmentText = 'Please open the full source to see the response.';
        }
      } else {
        // if there is no section, set a default string.
        tfidfMl.fulfillmentText = 'Please open the full source to see the response.';
      }
    }

    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.KnowledgeResponse,
      componentData: {
        data: KnowledgeUtils.getSectionFromLastState(this.result),
        lastState: this.result
      }
    });
  }

  /**
   * This function handles the question when the current focus is in an interaction that is on the screen.
   */
  async handleQuestionInInteraction(newQuestion: string) {
      // filling out forms
      const elementToUpdate = this.formVisualizationData;
      // the element to update is the currently selected and working on item.
      const directionCommand = this.checkForDirectionCommand(this.newQuestion);

      if (directionCommand) {
        // handle the interaction announcements if required.
        this.handleInteractionDirectionCommand(elementToUpdate, directionCommand);
      } else {
        this.handleInteractionByTextInput(newQuestion, elementToUpdate);
      }
  }


  /**
   * This function is used to determine if the question has a loaded interaction and if it does, return true;
   */
  determineIfInInteraction(): boolean {
    if (typeof this.processInteractionId !== 'undefined' && this.processInteractionId !== '') {
      return true;
    } else {
      return false;
    }
  }

  async handleInteractionByTextInput(newQuestion: string, elementToUpdate: InteractionEmitValueModel) {
    // determine the type of input/design that exists for the interactions.
    let evaEvent = this.determineEventForEVA();
    let isEditVisualizerMode = false;

    if (this.isProcessSummaryScreen) {
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: 'You are on the summary page, please review your changes',
        originator: this.formVisualizationData.processTitle
      }, null, true);
      return;
    }

    if (this.changeInteractionDirection) {
      evaEvent = EvaChatEventTypes.INTERACTIONCHANGE;
    }

    if ((evaEvent === 'any' || this.interactionInputType === '') && this.visualizerMode !== InteractionVisualizerMode.edit
      && newQuestion !== 'next element' && newQuestion !== 'previous element' && newQuestion !== 'next interaction'
      && newQuestion !== 'previous interaction') {
      // create the announcement, there is no need to try and figure out the intent since any value is correct that is
      // passed along.
      this.handleGenericAnyElement(elementToUpdate);
      return;
    } else {
      // determine the mapping of the type of object to the types returned in the request.
      let parameterType = this.determineSpeechTypeByInputType();
      if (this.changeInteractionDirection) {
        parameterType = ['Boolean', 'direction'];
      }
      if (this.visualizerMode === InteractionVisualizerMode.edit) {
        isEditVisualizerMode = true;
        parameterType = ['ordinal', 'number', 'time'];
      }
      const evaResponse = await this.evaHeartService.send({
        query: newQuestion,
        event: evaEvent,
        direction: EvaChatDirection.DYNAMIC_INTERACTION_FOLLOWUP
      });

      const eventType = evaResponse.response as any;
      let invalidValue = false;

      let evaHeartReply: number | string | any;
      // try to determine the string value of the text that has been returned.
      try {
        const valueChange: InteractionEmitValueModel = {
          ...this.formVisualizationData,
          value: null,
          lastEmittedFrom: InteractionValueEmitter.DialogFlow
        };

        if (eventType.action === 'input.unknown') {
          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: 'The value entered is not valid',
            originator: valueChange.processTitle
          }, null);
        }

        if ( (Array.isArray(parameterType)
          ? parameterType.filter(type => eventType.parameters.fields[type]).length > 0
          : eventType.parameters.fields[parameterType]) || eventType.action === EvaChatEventTypes.INTERACTIONCHANGE
          || eventType.action === EvaChatEventTypes.INTERACTIONELEMENTCHANGE
          || this.visualizerMode === InteractionVisualizerMode.edit ) {
          // Caused error & wrapped here
          if (Array.isArray(parameterType)) {
            evaHeartReply = eventType.parameters.fields;
            parameterType.forEach(type => {
              if (eventType.parameters.fields[type]?.kind === 'listValue') {
                evaHeartReply[type] = evaHeartReply[type][evaHeartReply[type].kind].values.map(value => value[value.kind])
                  ?? evaHeartReply[type];
              } else {
                evaHeartReply[type] = evaHeartReply[type]?.[evaHeartReply[type]?.kind];
              }
            });
          } else {
            if (eventType.parameters.fields?.[parameterType]?.kind) {
              evaHeartReply = eventType.parameters.fields[parameterType]
                [eventType.parameters.fields[parameterType].kind];
            } else {
              evaHeartReply = eventType.parameters.fields;
            }
            if (eventType.parameters?.fields?.[parameterType]?.kind === 'listValue') {
              evaHeartReply = evaHeartReply.values.map(value => value[value.kind]);
            }
          }

          if (eventType.action !== EvaChatEventTypes.INTERACTIONCHANGE && eventType.action !== 'Action.IsKnownWorkflow') {
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: eventType.fulfillmentText,
              originator: valueChange.processTitle
            }, null);
          }
          if (eventType.action === 'Action.IsKnownWorkflow') {
            const textMessage = 'The value entered is not valid';
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: textMessage,
              originator: valueChange.processTitle
            }, null);
          }

          const elementType = this.determineControlTypeFromEVA(eventType.action as EvaChatEventTypes);
          switch (eventType.action) {
            case EvaChatEventTypes.DATE:
              if (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT && elementType === this.interactionInputType) {
                valueChange.value = formatDate(evaHeartReply, 'yyyy-MM-dd', 'en');
              }
              break;
            case EvaChatEventTypes.DATETIME:
              let newValue = null;
              if (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT && elementType === this.interactionInputType) {
                if (evaHeartReply[parameterType[0]].kind) {
                  newValue = evaHeartReply[parameterType[0]][evaHeartReply[parameterType[0]].kind].substring(
                    0, evaHeartReply[parameterType[0]][evaHeartReply[parameterType[0]].kind].indexOf('T'));
                } else {
                  newValue = evaHeartReply[parameterType[0]].substring(
                    0, evaHeartReply[parameterType[0]].indexOf('T'));
                }

                if (evaHeartReply[parameterType[1]].kind) {
                  newValue += evaHeartReply[parameterType[1]][evaHeartReply[parameterType[1]].kind].substring(
                    evaHeartReply[parameterType[1]][evaHeartReply[parameterType[1]].kind].indexOf('T'),
                    evaHeartReply[parameterType[1]][evaHeartReply[parameterType[1]].kind].indexOf('T') + 9);
                } else {
                  newValue += evaHeartReply[parameterType[1]].substring(
                    evaHeartReply[parameterType[1]].indexOf('T'),
                    evaHeartReply[parameterType[1]].indexOf('T') + 9);
                }
              }
              valueChange.value = newValue;
              break;
            case EvaChatEventTypes.EMAIL:
              valueChange.value = evaHeartReply;
              break;
            case EvaChatEventTypes.FILE: break;
            case EvaChatEventTypes.MONTH:
            case EvaChatEventTypes.WEEK:
              if (Array.isArray(parameterType)) {
                let fullYear = null;
                if (evaHeartReply[parameterType[0]]?.[0]?.fields?.['startDate']?.kind) {
                  fullYear = new Date(evaHeartReply[parameterType[0]]?.[0]?.fields?.['startDate']
                    [evaHeartReply[parameterType[0]]?.[0]?.fields?.['startDate']?.kind]).getFullYear();
                } else {
                  fullYear = new Date(evaHeartReply[parameterType[0]]?.[0]?.fields?.['startDate']).getFullYear();
                }
                if (isNaN(fullYear)) {
                  fullYear = new Date().getFullYear();
                }
                let week = null;
                if (evaHeartReply[parameterType[1]].kind) {
                  week = evaHeartReply[parameterType[1]][evaHeartReply[parameterType[1]].kind];
                } else {
                  week = evaHeartReply[parameterType[1]];
                }
                if (week < 10) {
                  week = '0' + week.toString();
                } else {
                  week = week.toString();
                }
                valueChange.value = '' + fullYear + '-W' + week;
              } else {
                let monthAsDate = null;
                if (evaHeartReply[0].fields['startDate'].kind) {
                  monthAsDate = evaHeartReply[0].fields['startDate'][evaHeartReply[0].fields['startDate'].kind];
                } else {
                  monthAsDate = evaHeartReply[0].fields['startDate'];
                }
                valueChange.value = monthAsDate.substring(0, 7);
              }
              break;
            case EvaChatEventTypes.NUMBER:
            case EvaChatEventTypes.SLIDER:
              if (this.visualizerMode === InteractionVisualizerMode.edit) {
                let index = -1;

                if (Array.isArray(parameterType)) {
                  parameterType.forEach(type => {
                    if (!evaHeartReply[type]
                      || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                      || evaHeartReply === {}) {
                        return;
                    }
                    if (Array.isArray(evaHeartReply[type])) {
                      evaHeartReply[type].forEach(value => {
                        index = (value as number) - 1;
                      });
                    } else if (type === 'time') {
                      let parsedNumber = Number((evaHeartReply[type] as string).substring(
                        (evaHeartReply[type] as string).lastIndexOf('T') + 1, (evaHeartReply[type] as string).lastIndexOf('T') + 3));
                      if (parsedNumber === 0) {
                        parsedNumber = 24;
                      }
                      index = parsedNumber - 1;
                    } else {
                      index = (evaHeartReply[type] as number) - 1;
                    }
                  });
                } else {
                  if (Array.isArray(evaHeartReply)) {
                    evaHeartReply.forEach(value => {
                      index = (value as number) - 1;
                    });
                  } else {
                    index = (evaHeartReply as number) - 1;
                  }
                }
                this.handleInteractionDirectionCommand(elementToUpdate, searchDirection.forward, index, true,
                  this.currentProcessId, valueChange.processTitle);
                break;
              }
              if (this.dynamicMultipleInput) {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
                  if (Array.isArray(parameterType)) {
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        valueChange.value = '';
                        evaHeartReply[type].forEach((value, index) => {
                          valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            valueChange.value += ',';
                          }
                        });
                      } else {
                        valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                  } else {
                    if (Array.isArray(evaHeartReply)) {
                      valueChange.value = '';
                      evaHeartReply.forEach((value, index) => {
                        valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                        if (index < evaHeartReply.length - 1) {
                          valueChange.value += ',';
                        }
                      });
                    } else {
                      valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply as number) - 1]?.value ?? null;
                    }
                  }
                }
              } else {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }

                      if (Array.isArray(evaHeartReply[type])) {
                        response = '';
                        evaHeartReply[type].forEach((value, index) => {
                          response += elementToUpdate.checkboxGroups?.[(value as number) - 1]?.label ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            response += ',';
                          }
                        });
                      } else {
                        response = elementToUpdate.checkboxGroups?.[(evaHeartReply[type] as number) - 1]?.label ?? null;
                      }
                    });
                    valueChange.value = response;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT
                  || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        response = elementToUpdate.dynamicOptions?.[
                          (evaHeartReply[type][evaHeartReply[type].length - 1] as number) - 1]?.value ?? null;
                      } else {
                        response = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                    valueChange.value = response;
                  } else {
                    valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[evaHeartReply.length - 1] as number) - 1]?.value
                      ?? null;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER
                  || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SLIDER) {
                  if (Array.isArray(evaHeartReply)) {
                    valueChange.value = evaHeartReply[0];
                  } else {
                    if (Array.isArray(parameterType)) {
                      let response = null;
                      parameterType.forEach(type => {
                        if (!evaHeartReply[type]
                          || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                          || evaHeartReply === {}) {
                            return;
                        }
                        if (Array.isArray(evaHeartReply[type])) {
                          response = evaHeartReply[type][0];
                        } else {
                          response = evaHeartReply[type];
                        }
                      });
                      valueChange.value = response;
                    } else {
                      valueChange.value = evaHeartReply;
                    }
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        response = evaHeartReply[type][0];
                      } else {
                        response = evaHeartReply[type];
                      }
                    });
                    valueChange.value = response;
                  } else {
                    valueChange.value = evaHeartReply;
                  }
                }
              }
              break;
            case EvaChatEventTypes.SELECT:
            case EvaChatEventTypes.RADIOGROUP:
            case EvaChatEventTypes.CHECKBOXGROUP:
              if (this.visualizerMode === InteractionVisualizerMode.edit) {
                let index = -1;

                if (Array.isArray(parameterType)) {
                  parameterType.forEach(type => {
                    if (!evaHeartReply[type]
                      || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                      || evaHeartReply === {}) {
                        return;
                    }
                    if (Array.isArray(evaHeartReply[type])) {
                      evaHeartReply[type].forEach(value => {
                        index = (value as number) - 1;
                      });
                    } else if (type === 'time') {
                      let parsedNumber = Number((evaHeartReply[type] as string).substring(
                        (evaHeartReply[type] as string).lastIndexOf('T') + 1, (evaHeartReply[type] as string).lastIndexOf('T') + 3));
                      if (parsedNumber === 0) {
                        parsedNumber = 24;
                      }
                      index = parsedNumber - 1;
                    } else {
                      index = (evaHeartReply[type] as number) - 1;
                    }
                  });
                } else {
                  if (Array.isArray(evaHeartReply)) {
                    evaHeartReply.forEach(value => {
                      index = (value as number) - 1;
                    });
                  } else {
                    index = (evaHeartReply as number) - 1;
                  }
                }
                this.handleInteractionDirectionCommand(elementToUpdate, searchDirection.forward, index, true,
                  this.currentProcessId, valueChange.processTitle);
                break;
              }
              if (this.dynamicMultipleInput) {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
                  if (Array.isArray(parameterType)) {
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        valueChange.value = '';
                        evaHeartReply[type].forEach((value, index) => {
                          valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            valueChange.value += ',';
                          }
                        });
                      } else {
                        valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                  } else {
                    if (Array.isArray(evaHeartReply)) {
                      valueChange.value = '';
                      evaHeartReply.forEach((value, index) => {
                        valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                        if (index < evaHeartReply.length - 1) {
                          valueChange.value += ',';
                        }
                      });
                    } else {
                      valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply as number) - 1]?.value ?? null;
                    }
                  }
                }
              } else {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }

                      if (Array.isArray(evaHeartReply[type])) {
                        response = '';
                        evaHeartReply[type].forEach((value, index) => {
                          response += elementToUpdate.checkboxGroups?.[(value as number) - 1]?.label ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            response += ',';
                          }
                        });
                      } else {
                        response = elementToUpdate.checkboxGroups?.[(evaHeartReply[type] as number) - 1]?.label ?? null;
                      }
                    });
                    valueChange.value = response;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT
                  || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        response = elementToUpdate.dynamicOptions?.[
                          (evaHeartReply[type][evaHeartReply[type].length - 1] as number) - 1]?.value ?? null;
                      } else {
                        response = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                    valueChange.value = response;
                  } else {
                    valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[evaHeartReply.length - 1] as number) - 1]?.value
                      ?? null;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER
                  || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SLIDER) {
                    if (Array.isArray(evaHeartReply)) {
                      valueChange.value = evaHeartReply[0];
                    } else {
                      if (Array.isArray(parameterType)) {
                        let response = null;
                        parameterType.forEach(type => {
                          if (!evaHeartReply[type]
                            || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                            || evaHeartReply === {}) {
                              return;
                          }
                          if (Array.isArray(evaHeartReply[type])) {
                            response = evaHeartReply[type][0];
                          } else {
                            response = evaHeartReply[type];
                          }
                        });
                        valueChange.value = response;
                      } else {
                        valueChange.value = evaHeartReply;
                      }
                    }
                }
              }

              break;
            case EvaChatEventTypes.SWITCH:
            case EvaChatEventTypes.CHECKBOX:
              if (Array.isArray(parameterType)) {
                parameterType.forEach(type => {
                  if (!evaHeartReply[type]
                    || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                    || evaHeartReply === {}) {
                      return;
                  }
                  if (this.changeInteractionDirection && evaHeartReply[type] === 'true') {
                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Loading
                    }, null);
                    this.interactionSyncService.announceProcessInteractionChange(this.changeInteractionDirection, null,
                      this.currentProcessId, valueChange.processTitle);
                  } else if (this.changeInteractionDirection && evaHeartReply[type] === 'false') {
                    this.interactionSyncService.announceProcessStayOnCurrentInteraction(true, this.currentProcessId);
                    this.visualizerMode = InteractionVisualizerMode.edit;
                  } else {
                    valueChange.value = evaHeartReply[type] === 'true';
                  }
                });
              } else {
                if (typeof evaHeartReply[parameterType] !== 'undefined') {
                  if (this.changeInteractionDirection && evaHeartReply[parameterType] === 'true') {
                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Loading
                    }, null);
                    this.interactionSyncService.announceProcessInteractionChange(this.changeInteractionDirection, null,
                      this.currentProcessId, valueChange.processTitle);
                  } else if (this.changeInteractionDirection && evaHeartReply[parameterType] === 'false') {
                    this.interactionSyncService.announceProcessStayOnCurrentInteraction(true, this.currentProcessId);
                    this.visualizerMode = InteractionVisualizerMode.edit;
                  }  else {
                    valueChange.value = evaHeartReply[parameterType] === 'true';
                  }
                } else {
                  if (this.changeInteractionDirection && evaHeartReply === 'true') {
                    this.chatService.newChatEntity({
                      author: ChatEntityAuthor.EVA,
                      type: ChatEntityType.Loading
                    }, null);
                    this.interactionSyncService.announceProcessInteractionChange(this.changeInteractionDirection, null,
                      this.currentProcessId, valueChange.processTitle);
                  } else if (this.changeInteractionDirection && evaHeartReply === 'false') {
                    this.interactionSyncService.announceProcessStayOnCurrentInteraction(true, this.currentProcessId);
                    this.visualizerMode = InteractionVisualizerMode.edit;
                  }  else {
                    valueChange.value = evaHeartReply === 'true';
                  }
                }
              }
              break;
            case EvaChatEventTypes.INTERACTIONCHANGE:
              this.chatService.newChatEntity({
                author: ChatEntityAuthor.EVA,
                type: ChatEntityType.Loading
              }, null);
              this.interactionSyncService.announceProcessInteractionChange(evaHeartReply['direction'][evaHeartReply['direction'].kind
                ?? evaHeartReply['direction'] ?? this.changeInteractionDirection], eventType.fulfillmentText, this.currentProcessId,
                valueChange.processTitle);
              break;
            case EvaChatEventTypes.INTERACTIONELEMENTCHANGE:
              this.chatService.newChatEntity({
                author: ChatEntityAuthor.EVA,
                type: ChatEntityType.Loading
              }, null);
              this.handleInteractionDirectionCommand(elementToUpdate, evaHeartReply['direction'][evaHeartReply['direction'].kind]
                ?? evaHeartReply['direction'] ?? this.changeInteractionDirection, null, valueChange.isEditInteractionFlow,
                this.currentProcessId, valueChange.processTitle);
              break;
            case EvaChatEventTypes.TEL:
              if (this.dynamicMultipleInput) {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
                  if (Array.isArray(parameterType)) {
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        valueChange.value = '';
                        evaHeartReply[type].forEach((value, index) => {
                          valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            valueChange.value += ',';
                          }
                        });
                      } else {
                        valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                  } else {
                    if (Array.isArray(evaHeartReply)) {
                      valueChange.value = '';
                      evaHeartReply.forEach((value, index) => {
                        valueChange.value += elementToUpdate.dynamicOptions?.[(value as number) - 1]?.value ?? null;
                        if (index < evaHeartReply.length - 1) {
                          valueChange.value += ',';
                        }
                      });
                    } else {
                      valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply as number) - 1]?.value ?? null;
                    }
                  }
                }
              } else {
                if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }

                      if (Array.isArray(evaHeartReply[type])) {
                        response = '';
                        evaHeartReply[type].forEach((value, index) => {
                          response += elementToUpdate.checkboxGroups?.[(value as number) - 1]?.label ?? null;
                          if (index < evaHeartReply[type].length - 1) {
                            response += ',';
                          }
                        });
                      } else {
                        response = elementToUpdate.checkboxGroups?.[(evaHeartReply[type] as number) - 1]?.label ?? null;
                      }
                    });
                    valueChange.value = response;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT
                  || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        response = elementToUpdate.dynamicOptions?.[
                          (evaHeartReply[type][evaHeartReply[type].length - 1] as number) - 1]?.value ?? null;
                      } else {
                        response = elementToUpdate.dynamicOptions?.[(evaHeartReply[type] as number) - 1]?.value ?? null;
                      }
                    });
                    valueChange.value = response;
                  } else {
                    valueChange.value = elementToUpdate.dynamicOptions?.[(evaHeartReply[evaHeartReply.length - 1] as number) - 1]?.value
                      ?? null;
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER
                || this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SLIDER) {
                  if (Array.isArray(evaHeartReply)) {
                    valueChange.value = evaHeartReply[0];
                  } else {
                    if (Array.isArray(parameterType)) {
                      let response = null;
                      parameterType.forEach(type => {
                        if (!evaHeartReply[type]
                          || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                          || evaHeartReply === {}) {
                            return;
                        }
                        if (Array.isArray(evaHeartReply[type])) {
                          response = evaHeartReply[type][0];
                        } else {
                          response = evaHeartReply[type];
                        }
                      });
                      valueChange.value = response;
                    } else {
                      valueChange.value = evaHeartReply;
                    }
                  }
                } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL) {
                  if (Array.isArray(parameterType)) {
                    let response = null;
                    parameterType.forEach(type => {
                      if (!evaHeartReply[type]
                        || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                        || evaHeartReply === {}) {
                          return;
                      }
                      if (Array.isArray(evaHeartReply[type])) {
                        response = evaHeartReply[type][0];
                      } else {
                        response = evaHeartReply[type];
                      }
                    });
                    valueChange.value = response;
                  } else {
                    valueChange.value = evaHeartReply;
                  }
                }
              }
              break;
            case EvaChatEventTypes.TIME:
              if (this.visualizerMode === InteractionVisualizerMode.edit) {
                let index = -1;

                if (Array.isArray(parameterType)) {
                  parameterType.forEach(type => {
                    if (!evaHeartReply[type]
                      || (Array.isArray(evaHeartReply[type]) && evaHeartReply[type].length === 0)
                      || evaHeartReply === {}) {
                        return;
                    }
                    if (Array.isArray(evaHeartReply[type])) {
                      evaHeartReply[type].forEach(value => {
                        index = (value as number) - 1;
                      });
                    } else if (type === 'time') {
                      let parsedNumber = Number((evaHeartReply[type] as string).substring(
                        (evaHeartReply[type] as string).lastIndexOf('T') + 1, (evaHeartReply[type] as string).lastIndexOf('T') + 3));
                      if (parsedNumber === 0) {
                        parsedNumber = 24;
                      }
                      index = parsedNumber - 1;
                    } else {
                      index = (evaHeartReply[type] as number) - 1;
                    }
                  });
                } else {
                  if (Array.isArray(evaHeartReply)) {
                    evaHeartReply.forEach(value => {
                      index = (value as number) - 1;
                    });
                  } else {
                    index = (evaHeartReply as number) - 1;
                  }
                }
                this.handleInteractionDirectionCommand(elementToUpdate, searchDirection.forward, index, true,
                  this.currentProcessId, valueChange.processTitle);
                break;
              }
              valueChange.value = evaHeartReply.substring(evaHeartReply?.indexOf?.('T') + 1, evaHeartReply?.indexOf?.('T') + 9);
              break;
            case EvaChatEventTypes.URL:
              valueChange.value = evaHeartReply;
              break;
            case EvaChatEventTypes.DEFAULT:
            case EvaChatEventTypes.NONE:
            default: break;
          }
        } else {
          if (elementToUpdate.type !== DYNAMIC_FORM_CONTROL_TYPE_SELECT
            && elementToUpdate.type !== DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP
            && elementToUpdate.type !== DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            invalidValue = true;
            this.chatService.newChatEntity({
              author: ChatEntityAuthor.EVA,
              type: ChatEntityType.Text,
              text: 'Invalid value, please provide a valid '
                + (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT ? this.interactionInputType : this.interactionType),
              originator: valueChange.processTitle
            }, null);
          }
        }

        if (elementToUpdate.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT
          || elementToUpdate.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP
          || elementToUpdate.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          if (this.dynamicMultipleInput) {
            const newValue = [];
            elementToUpdate.dynamicOptions?.forEach(option => {
              if (newQuestion.trim().toLowerCase().includes(option.value.trim().toLowerCase())) {
                newValue.push(option.value);
              }
            });

            if (!valueChange.value) {
              valueChange.value = newValue.toString();
            } else {
              valueChange.value += ',' + newValue.toString();
            }
          } else {
            if (elementToUpdate.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
              const newValue = [];
              elementToUpdate.checkboxGroups?.forEach(group => {
                if (newQuestion.trim().toLowerCase().includes(group.label.trim().toLowerCase())) {
                  newValue.push(group.label);
                }
              });

              if (!valueChange.value) {
                valueChange.value = newValue.toString();
              } else {
                valueChange.value += ',' + newValue.toString();
              }
            } else if (this.interactionInputType === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
              const newValue = elementToUpdate.dynamicOptions?.find(option =>
                newQuestion.trim().toLowerCase().includes(option.value.trim().toLowerCase()));
              valueChange.value = newValue?.value ?? valueChange.value;
            }
          }
        }

        if (Array.isArray(valueChange.value)
          ? valueChange.value.length > 0
          : (valueChange.value !== null && typeof valueChange.value !== 'undefined')) {
          // put together the response to determine what type of response has been triggered.
          // const emitObject = new InteractionEmitValueModel(
          //   elementToUpdate.interactionId,
          //   elementToUpdate.interactionOriginalId,
          //   elementToUpdate.interactionHint,
          //   elementToUpdate.interactionLabel,
          //   elementToUpdate.elementOriginalId,
          //   elementToUpdate.scrnIndex,
          //   elementToUpdate.formControlId,   // screenElement.id,
          //   this.newQuestion
          // );
          this.interactionSyncService.controlValueValid$.pipe(take(1))
            .subscribe(formControlFromFormGroup => {
              if (formControlFromFormGroup.valid) {
                // announce the interactions to the service.
                this.interactionSyncService.announceControlValueUpdate(valueChange);
              } else {
                const textMessage = ['The value entered is not valid'];
                if (formControlFromFormGroup.errors) {
                  Object.keys(formControlFromFormGroup.errors).forEach(error => {
                    switch (error) {
                      case 'min': textMessage.push(`, it must be at least ${formControlFromFormGroup.errors[error].min}`); break;
                      case 'max': textMessage.push(`, it must be at most ${formControlFromFormGroup.errors[error].max}`); break;
                      case 'required':
                      case 'requiredTrue': textMessage.push(`, it must have a value`); break;
                      case 'email': textMessage.push(`, it must be a valid email`); break;
                      case 'minlength':
                        textMessage.push(`, it must be at least ${formControlFromFormGroup.errors[error].requiredLength} characters`);
                        break;
                      case 'maxlength':
                        textMessage.push(`, it must be at most ${formControlFromFormGroup.errors[error].requiredLength} characters`);
                        break;
                      case 'pattern':
                        // textMessage.push(`, it must follow the pattern: ${formControlFromFormGroup.errors[error].requiredPattern}`);
                        // break;
                        if (formControlFromFormGroup.hint) {
                          textMessage.push(`, it must follow the pattern: ${formControlFromFormGroup.hint}`);
                        }
                        break;
                      default: break;
                    }
                  });
                }

                this.chatService.newChatEntity({
                  author: ChatEntityAuthor.EVA,
                  type: ChatEntityType.Text,
                  text: textMessage[0] + textMessage.splice(0, 1).join(' and '),
                  originator: valueChange.processTitle
                }, null);

                this.chatService.newChatEntity({
                  author: ChatEntityAuthor.EVA,
                  type: ChatEntityType.InteractionResponse,
                  text: this.currentInteractionFormElement?.label,
                  metadata: {
                    interactionFormElement: this.currentInteractionFormElement,
                    formVisualizationData: this.formVisualizationData
                  },
                  originator: valueChange.processTitle
                }, null);
              }
          });
          this.interactionSyncService.announceControlValueChange(valueChange);
        } else if (!invalidValue) {
          if (this.changeInteractionDirection || eventType.action === EvaChatEventTypes.INTERACTIONCHANGE
            || eventType.action === EvaChatEventTypes.INTERACTIONELEMENTCHANGE
            || isEditVisualizerMode) {
            return;
          }
          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: 'Invalid value, please provide a valid '
              + (this.interactionType === DYNAMIC_FORM_CONTROL_TYPE_INPUT ? this.interactionInputType : this.interactionType),
            originator: valueChange.processTitle
          }, null);
        }
      } catch ( err ) {
        console.error("EvaHeart Error: ", err);
      }
    }
  }

  /**
   * This function takes the interaction element and returns a type to determine what should be checked in the response
   * type.
   * TODO: update the parameters to a enum for determination.
   */
  determineSpeechTypeByInputType(): string | string[] {
    // setup the any parameter type.
    let parameterType: string | string[] = 'any';

    // check the interaction input type.
    switch (this.interactionType) {
      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
        switch (this.interactionInputType) {
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE:
            parameterType = 'date';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL:
            parameterType = ['date', 'time'];
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL:
            parameterType = 'email';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE:
            parameterType = 'none';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH:
            parameterType = 'date-period';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER:
            parameterType = ['number', 'phone-number', 'ordinal'];
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:
            parameterType = ['phone-number', 'number'];
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME:
            parameterType = 'time';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL:
            parameterType = 'url';
            break;
          case DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK:
            parameterType = ['date-period', 'ordinal'];
            break;
          default:
            parameterType =  'any';
          // this is unknown DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
            break;
        }
        break;
      case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
        parameterType = 'Boolean';
        break;
      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP: //
        parameterType = ['ordinal', 'number', 'phone-number'];
        break;
      case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:
        parameterType = ['number', 'ordinal', 'phone-number'];
        break;
      default: break;
  }
    return parameterType;
  }

  /**
   * This function handles the announcements for an interaction that are of an any type (ie. any value entered into them would)
   * be a valid value.
   *
   * @param elementToUpdate Ths model that has information that we will emit.
   */
  handleGenericAnyElement(elementToUpdate: InteractionEmitValueModel): void {
    // create an announcement object.
    const emitObject = new InteractionEmitValueModel(
      elementToUpdate.interactionId,
      elementToUpdate.interactionOriginalId,
      elementToUpdate.interactionHint,
      elementToUpdate.interactionLabel,
      elementToUpdate.elementOriginalId,
      elementToUpdate.scrnIndex,
      elementToUpdate.formControlId,   // screenElement.id,
      this.newQuestion,
      elementToUpdate.inputType,
      elementToUpdate.type,
      InteractionValueEmitter.Chat,
      elementToUpdate.dynamicOptions,
      elementToUpdate.processId,
      elementToUpdate.checkboxGroups,
      elementToUpdate.isSpecialControl,
      searchDirection.forward,
      elementToUpdate.isPreview,
      null,
      elementToUpdate.isEditInteractionFlow
    );

    this.interactionSyncService.controlValueValid$.pipe(take(1))
      .subscribe(formControlFromFormGroup => {
        if (formControlFromFormGroup.valid) {
          // let the system know that the item has been updated.
          this.interactionSyncService.announceControlValueUpdate(emitObject);
        } else {
          const textMessage = ['The value entered is not valid'];
          if (formControlFromFormGroup.errors) {
            Object.keys(formControlFromFormGroup.errors).forEach(error => {
              switch (error) {
                case 'min': textMessage.push(`, it must be at least ${formControlFromFormGroup.errors[error].min}`); break;
                case 'max': textMessage.push(`, it must be at most ${formControlFromFormGroup.errors[error].max}`); break;
                case 'required':
                case 'requiredTrue': textMessage.push(`, it must have a value`); break;
                case 'email': textMessage.push(`, it must be a valid email`); break;
                case 'minlength':
                  textMessage.push(`, it must be at least ${formControlFromFormGroup.errors[error].requiredLength} characters`);
                  break;
                case 'maxlength':
                  textMessage.push(`, it must be at most ${formControlFromFormGroup.errors[error].requiredLength} characters`);
                  break;
                case 'pattern':
                  // textMessage.push(`, it must follow the pattern: ${formControlFromFormGroup.errors[error].requiredPattern}`);
                  // break;
                  if (formControlFromFormGroup.hint) {
                    textMessage.push(`, it must follow the pattern: ${formControlFromFormGroup.hint}`);
                  }
                  break;
                default: break;
              }
            });
          }

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: textMessage[0] + textMessage.slice(1).join(' and '),
            originator: this.formVisualizationData.processTitle
          }, null);

          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.InteractionResponse,
            text: this.currentInteractionFormElement?.label,
            metadata: {
              interactionFormElement: this.currentInteractionFormElement,
              formVisualizationData: this.formVisualizationData
            },
            originator: this.formVisualizationData.processTitle
          }, null);
        }
    });
    this.interactionSyncService.announceControlValueChange(emitObject);
  }

  /**
   * This function handles the announcement in a chat if the user has specified forward or backwards while in an interaction
   */
  handleInteractionDirectionCommand(elementToUpdate: InteractionEmitValueModel, directionCommand: searchDirection,
    index?: number, isEditInteractionFlow?: boolean, processId?: string, processTitle?: string): void {
    // declare the object to announce to the interaction.
    const interactionControlReq = new InteractionControlRequest(
      elementToUpdate.interactionId,
      elementToUpdate.formControlId,
      directionCommand,
      Guid.newGuid().toString(),
      this.chatId,
      elementToUpdate.interactionOriginalId,
      elementToUpdate.elementOriginalId,
      elementToUpdate.lastEmittedFrom,
      index,
      isEditInteractionFlow,
      null,
      processId,
      false,
      processTitle
    );
    // announce the action for the user.
    this.interactionSyncService.announceInteractionControlRequest(interactionControlReq);
  }

  /**
   * This function checks if the chat direction has been included in the chat command and if so, returns the direction
   * if not, returns null
   *
   * @param question the question the user has provided.
   */
  checkForDirectionCommand(question: string): searchDirection {
    let directionCommand = null;
    try {
      const chatSearchDirection = question.toLowerCase();
      if (searchDirection.hasOwnProperty( chatSearchDirection )) {
        directionCommand = chatSearchDirection;
      }
    } catch (err) {// is not a directional command
      directionCommand = null;
    }

    return directionCommand;
  }

//#endregion handle Commands in interactions

  /**
   * This updates the chat components and the input components.
   * @param evaSays // what eva is saying to the user
   * @param userSays // what the user is saying to EVA.
   */
  private createEVADialog(evaSays: string, userSays?: string, forceOpen?: boolean) {
    this.showDynamicInput = false;

    if (!userSays) {
      // userSays = this.askedQuestion;
    }

    const response: LastState = {
      ...this.lastStateService.getLastState(),
      chat: {
        eva: {
          text: evaSays,
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text
        },
        user: {
          text: userSays,
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text
        }
      }
    };

    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: evaSays
    }, null, null, forceOpen);

    // this.fullResponse = evaSays;
    // this.listOfQuestions.push(userSays);
    // this.askedQuestion = userSays;
    this.newQuestion = '';
    // this.iwanton = false;
    // this.machineLearningResponse = false;   // disable machine learning feedback
    // this.processEntryTriggered = false;
    this.processInteractionId = '';
    this.processInteractionOriginalId = '';
    this.processInteractionControlId = '';
    // this.isInProcessSetup = false;
    this.processWorkflowId = null;
    this.result = response;
    // this.saveChat.saveChatSession(evaSays, false, this.result);
    // this.lastStateService.updateSaveLastState(this.result);
  }

  /**
   * This function resets the EVA environment.
   * @param newQuestion the quest coming in from the user.
   */
  private async breakRequest(idToReplace?: string, failedLoadingProcess?: boolean, hideChatMessage?: boolean) {
    this.chatService.resetNextChatQueryType();
    this.chatService.resetPlaceholderText();
    const currentLastState = this.lastStateService.getLastState();
    // update last state
    const evaSays = {
      text: `Ok. Let's start over, how can I help you?`,
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text
    };
    const breakResponse: LastState = {
      chat: {
        eva: evaSays,
        user: {
          text: 'break',
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text
        }
      },
      tabs: currentLastState.tabs,
      version: currentLastState.version,
      sessionId: currentLastState.sessionId
    };

    this.newQuestion = '';

    this.result = breakResponse;
    // this.saveChat.saveChatSession(breakResponse.response.fulfillmentText, false, this.result);

    // const processIdToDelete =  ( this.currentProcessId && this.currentProcessId.trim().length > 0 ) ? this.currentProcessId : null

    try {
      const lastIndex = (this.multiViewService.tabs[Routes.Process]?.length ?? 0) - 1;
      if (lastIndex > 0 && !failedLoadingProcess) {
        const dialogData = new GeneralDialogModel(
          'Clear all Processes',
          `Are you sure you want to close all opened processes?`,
          'Yes', 'Cancel'
        );
        this.dialog.open(GeneralDialogComponent, {
          data: dialogData
        }).afterClosed().subscribe(result => {
          if (result) {
            this.showDynamicInput = false;
            this.showWorkflowButton = true; // ensure workflow button re-shown when break called
            this.cleanupProcess();

            const tabIds = this.multiViewService.tabs[Routes.Process].map(tab =>
              tab.additionalInstanceData && tab.additionalInstanceData.processId);
            for (const tabId of tabIds) {
              const index = this.multiViewService.tabs[Routes.Process].findIndex(tab =>
                tab.additionalInstanceData && (tab.additionalInstanceData.processId === tabId));
              if (index !== -1) {
                this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, "Remove", null, index);
              }
            }
            // Add new chat entity
            this.chatService.newChatEntity(evaSays, idToReplace);
          } else {
            evaSays.text = 'Ok, I have kept your processes opened';
            this.chatService.newChatEntity(evaSays, idToReplace);
          }
        });
      } else {
        this.showDynamicInput = false;
        this.showWorkflowButton = true; // ensure workflow button re-shown when break called
        this.cleanupProcess();
        if (!hideChatMessage) {
          this.chatService.newChatEntity(evaSays, idToReplace);
        }
      }
      // TODO: remove from last state processes collection here
        // await this._userService.userProcessDelete(processIdToDelete);
      // }
      // await this.lastStateService.updateSaveLastState(breakResponse);
    } catch ( err ) {
      console.error( err );
    }
  }

  //#endregion sendQuestionsForResponse

  //#region
  /**
   * This fires the event to get additional fulfillment which means depending on the response action, the
   * app will forward to a specific page.
   */
  getAdditionalFulfillment(result: any): void {
    let chatResponse = true;
    // Check if there is action to take
    if (!result.response.action || result.response.action === '') {
      // console.log('additional fulfillment - no action to take');
      return;
    }

    switch (result.response.action) {
      // case 'search.google':
      //   this.executeGoogleSearch(result);
      //   break;
      // case 'dynamic.form.initialized':
      //   this.showMessageHistory.next(false);
      //   break;
      case 'dynamic.form.builder':
        this.router.navigate(['builders/Interaction']);
        break;
      case 'Action.Administration':
        this.router.navigate([Routes.Admin]);
        break;
      case 'workflow.builder':
        this.router.navigate([Routes.WorkflowBuilder]);
        break;
      case 'process.builder':
        this.router.navigate(['/Process']);
        break;
      // case 'show.message.history':
      //   this.showHistory();
      //   break;
      // case 'hide.message.history':
      //   this.showMessageHistory.next(false);
      //   break;
      case 'create.a.group':
        this.createAGroup(result);
        break;
      // case 'i.want':
      //   this.iwant();
      //   break;
      case 'Action.IsKnownInteraction':
        // this.showMessageHistory.next(false);
        this.actionIsKnownInteraction(result);
        chatResponse = false;
        break;
      case 'Action.IsKnownWorkflow':
        this.setOnlyNumbersInChat(true);
        // this.showMessageHistory.next(false);
        this.handleWorkflowProcess(result);
        chatResponse = false;
        break;
      // case 'Action.IWantTo.Change.AnAddress':
      //   break;
      default:
        // action does not match anything EVA related, create a text chat entity
        this.chatService.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: result.response.fulfillmentText
        });
        chatResponse = false;
        break;
    }

    if (chatResponse) {
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: result.response.fulfillmentText
      });
    }
  }

//#region ProcessCaptureInChat

  /**
   * This function sets up the capture of a process name from the user for a workflow that is about to be passed
   * into a process object for execution.
   *
   * @param workflow the workflow object to start handling.
   */
  handleWorkflowFromDialog(workflow: WorkFlow): void {
    if (workflow && workflow.id) {
      this.chatProcessService.fetchWorkflow( workflow.id );
      // TODO: Rework for organizational groups
      // this.createEVADialog('Please provide a unique description for this request (ex. Customer Name)', workflow.name);
      const evaSays = `Please provide the BP Number for this request. You can add the last 4 digits of a Loan or Account Number
      if needed. (ex.  BP1111 LN1111)`;
      this.setOnlyNumbersInChat(true);
      this.createEVADialog(evaSays, workflow.name, true);

      // Set next query user sends to be seen as a process name.
      this.chatService.setNextChatQueryType(NextChatQueryType.PROCESS_NAME);

      // this.isInProcessSetup = true;
      this.processWorkflowId = workflow.id;
    } else {
      if (this.currentProcessId) {
        this.chatService.newChatEntity({
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: `Start a Process`
        });
      // this.loggingService.logMessage(
      //   'EVA only supports one process at a time for now, please finish or close it before opening new.', false, 'info');
      } else {
        this.createEVADialog('We didn\'t seem to start this setup correctly. <br /><br /> can you try this again. ');
      }
    }
  }

  /**s
   * This will allow only numbers in chat.
   * TODO: Change this to organizational groups
   *
   * @param isActive whether this will be active.
   */
  setOnlyNumbersInChat(isActive: boolean): void {
    this.onlyNumbersInChat = isActive;
  }

  /**
   * Starts a process and notifying the chat that the next question asked should be used
   * as the process name. At this point, we can try to retrieve the workflow in the background
   * while the user types the name of the process to set up.
   *
   * @param result response from chat
   */
  handleWorkflowProcess(result: any) {
    if (result && result.response &&
      result.response.parameters &&
      result.response.parameters.fields &&
      result.response.parameters.fields.Workflows &&
      result.response.parameters.fields.Workflows.stringValue) {
        /** pop text entity into chat */
        this.chatService.createChatEntitiesFromDialogFlow(result, true);

        this.chatService.setNextChatQueryType(NextChatQueryType.PROCESS_NAME);
        /** Workflow id to retrieve **/
        this.processWorkflowId = result.response.parameters.fields.Workflows.stringValue;
        /** Start getting workflow in the background */
        this.chatProcessService.fetchWorkflow( this.processWorkflowId );
      } else {
        this.createEVADialog(
          'We have an idea as to what you are trying to do, but we seem to be struggling getting' +
          ' this to work. <br /><br />Can you help us by saying this a slightly different way?');
      }

  }

//#endregion ProcessCaptureInChat


  actionIsKnownWorkflow(workflowId: string, processName: string) {

    // Block function if no workflow or we are already loading a process.
    if (!workflowId) { // || this.processIsLoading) {
      return;
    }

    // Show loading indicator if user has supplied a process name but we are still getting the workflow.
    this.chatService.setChatInProgress(true);

    this.currentProcessId = null;

    this.processService.initializeProcess(workflowId, processName)
    .then( async (processData: {processId: string, tabIndex: number, process: Process}) => {
      // Process is created and ready, announce process id and chat id target
      this.chatProcessService.announceProcessStart(processData.processId, this.chatId);

      this.processState =  new ProcessState(
        processData.processId,
        null,
        null,
        'Process has loaded',
        userLastStateType.process
      );

      const clonedProcess = JSON.parse(JSON.stringify(processData.process));
      const clonedProcessState: any = JSON.parse(JSON.stringify(this.processState));

      const additionalInstanceData = {
        processId: processData.processId,
        hideProcessTitleBar: true,
        targetId: this.chatId,
        ...clonedProcessState,
        process: clonedProcess
      };

      this.router.navigate([Routes.Process]).then(value => {
        this.multiViewService.setCreateNewTab({
          component: ProcessComponent,
          additionalInstanceData,
          tabName: 'Loading...'
        });

        this.initializeBasedOnLastState(this.processState);

        // await this.saveChat.saveChatSession(this.processState.query, false, clonedProcessState);
      });

      // process is initialized.
      // disable any loading indicator if showing
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: `Process has loaded, You can go to next or previous element by saying 'next element' or 'previous element'`
      }, null);

      if (this.knowledgeOnly) {
        this.toggleKnowledgeOnly();
      }

      this.currentProcessId = processData.processId;
      // this.isInProcessSetup = false;
      this.processWorkflowId = null;

      // TODO: Look at this
      // this.subscribeToProcessDone(processData.processId);
    })
    .catch(err => {
      console.error('Error in actionIsKnownWorkflow: ', err);
      this.loggingService.logMessage('An Error occurred while getting this started.', false, 'error');

      this.breakRequest(null, true);
    });
  }

  /**
   * checking last state, a process was detected and the user was last doing.
   * Prepare some process data in this component.
   */
  triggerLastStateProcess(processId: string) {
    if (! processId) return;

    this.currentProcessId = processId;
    this.processWorkflowId = null;

    this.subscribeToProcessDone(processId);
  }

  /**
   * Tracks the processDone subscription so we only subscribe once even if the function may be called multiple times when state changes.
   *
   * @param processId id of the process
   */
  private subscribeToProcessDone(processId: string) {
    this.processDoneSubs.push(this.processService.processDone$.pipe(
      filter(data => data.processId === processId)
    ).subscribe((processDoneObj) => {
      // re-enable the chat input if the user cancelled a process while on a select element
      this.dynamicMultipleInput = false;

      this.cleanupProcess();
      this.configureChatForProcessDone(processDoneObj.msg);
    }));

    this.processCancelSubs.push(this.processService.processCancel$.subscribe((cancelled) => {
      this.cleanupProcess();
      this.breakRequest(null, true, true);
    }));
  }

  private cleanupProcess(): void {
    // this.isInProcessSetup = false;
    this.processWorkflowId = null;
    this.currentProcessId = null;
    // this.processIsLoading = false;
    // this.processEntryTriggered = false;
    this.showDynamicInput = false;
    this.processInteractionId = '';
    this.processInteractionOriginalId = '';
    this.processInteractionControlId = '';
  }

  configureChatForProcessDone(message: string) {
    const newQuestion = '(Submitted Process)';
    const currentLastState = this.lastStateService.getLastState();
    const response: LastState = {
      ...currentLastState,
      chat: {
        eva: {
          text: message,
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text
        },
        user: {
          text: newQuestion,
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text
        }
      }
    };

    this.chatService.createChatEntitiesFromLastState(response);

    this.newQuestion = '';

    this.result = response;
    // this.saveChat.saveChatSession(message, false, this.result);

    // force an update immediately in case use closes app or something
    // if we do not force an update to last state immediately than the next time the user comes
    // to the app, it will try to retrieve a process that does not exist.
    // this.lastStateService.updateSaveLastState(currentLastState);
  }

  /**
   * fired when the chat replies with a known interaction action
   *
   * @param result last state object
   */
  async actionIsKnownInteraction(result: any) {
    this.interactionId = result.response.parameters.fields.Interactions.stringValue;

    this.chatService.createChatEntitiesFromDialogFlow(result, true);

    // ! commented out for now, current the chat-interaction service listens to last state and announces this instead.
    try {
      this.chatInteractionService.startInteraction(this.interactionId);
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: 'Interaction has loaded'
      });
    } catch (err) {
      console.error('error getting interaction', err);
    }

  }

  //#region GroupFunctions
  /**
   * This function creates a group and sends this to the blockchain.
   * @param result the result of the dialogflow.
   */
  async createAGroup(result): Promise<void> {
    if (result.response.allRequiredParamsPresent) {
      const groupName = result.response.parameters.fields.groupName.stringValue;
      const groupType = result.response.parameters.fields.groupType.stringValue;
      const groupDescription = result.response.parameters.fields.groupDescription.stringValue;

      try {
        await this.groupProviderService.createNewGroupForBlockAndSend(groupName, groupType, groupDescription, null);

        const successMessage = `Group ${groupName} created successfully`;

        this.chatService.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: successMessage
        });
      } catch (err) {
        this.loggingService.logMessage('An error occurred creating the group', false, 'error');
      }
    }
  }
  //#endregion GroupFunctions

  resetSpeechButton(): void {
    this.speechRecognitionService.DestroySpeechObject();
    this.currentlyRecording = false;
  }

  // //#region EasterEggs
  // iwant() {
  //   this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/4fndeDfaWCg?rel=0');
  //   this.iwanton = true;
  // }
  // //#endregion EasterEggs

  //#region SpeechSynthesisControls
  toggleSpeech(toggle: boolean) {
    this.speechSynthesisService.toggleChatSpeech(toggle ? false : true);
  }
  //#endregion SpeechSynthesisControls

  //#region SpeechAndTranscriptionCapture
  activateTranscriptionMode(): void {
    // this.showSearchButton = false;
    this.componentSubs.add(
      this.speechRecognitionService.record()
      .subscribe(
        (value) => {
          // add the response from continuous transcription to the systems.
          this.newQuestion += value;
        },
        (err) => {
          console.log(err);
          if (err.error === 'no-speech') {
            if (this.retry === true) {
              // restart the service
              console.log('Speech Capture --> Attempting to restart the service');
              this.retry = false;
              this.activateTranscriptionMode();
            } else {
              console.log('Speech Capture --> Speech recognition service failed. Will not retry');
            }
          }
        },
        () => {
          // console.log('SR Transcription--> successful word capture');
        } // end completion
      ) // end active transcription record.
    );
  }

  /**
   * This function activates the speech recording and returns either an error or the
   * speech value that has been generated.
   * If successful it sends this to the AI to determine the next steps.
   */
  activateSpeech(): void {
    // this.showSearchButton = false;
    this.componentSubs.add(
      this.speechRecognitionService.record()
      .subscribe(
        // listener
        (value) => {
          // console.log('value from speech activation function');
          // console.log(value);
          this.newQuestion += value;
          this.askQuestion(this.newQuestion);
        },
        // error
        (err) => {
          console.log(err);
          if (err.error === 'no-speech') {
            if (this.retry === true) {
              // restart the service
              // console.log('Speech Capture --> Attempting to restart the service');
              this.retry = false;
              this.activateSpeech();
            } else {
              // console.log('Speech Capture --> Speech recognition service failed. Will not retry');
            }
          }
        },
        // completion
        () => {
          // this.showSearchButton = true;
          this.toggleSpeechRecording();
        }
      ) // end subscribe / record
    );
  } // end activate speech

  /**
   * this toggles the speech recording into the information and items.
   */
  toggleSpeechRecording(): void {
    if (this.currentlyRecording === false) {
      this.recordOngoing = true;
      // console.log('Toggle speech --> entering recording...');
      this.newQuestion = '';
      // console.log(this.isTranscribe);
      if (!this.isTranscribe) {
        // console.log('normal speech');
        this.activateSpeech();
      } else {
        // console.log('transcription');
        this.activateTranscriptionMode();
      }
      // need this here so new question doesn't flash before it get cleared
      this.currentlyRecording = true;
    } else {
      this.currentlyRecording = false;
      this.recordOngoing = false;
      // console.log('Toggle speech --> exiting speech...');
      this.speechRecognitionService.DestroySpeechObject();
    }
  }
  //#endregion SpeechAndTranscriptionCapture

  // disableAdminControlsIfNotChangeAdmin(dynFormObj: any) {

  // TODO: Ben
  // provideTextInteraction(dynamicInteraction) {
  //   const currentInteractionScreen = dynamicInteraction.FormScreens[this.interactionScreenNumber];
  //   let description = 'I haven\'t been provided enough information to know what the designer wanted from this interaction';
  //   currentInteractionScreen.FormElements.forEach(formElement => {
  //     if (formElement.id === this.interactionElementId) {
  //       // this.currentInteractionElement = JSON.parse(JSON.stringify(formElement));
  //       if (formElement.hint) {
  //         description = formElement.hint;
  //       } else if (formElement.label) {
  //         description = formElement.label;
  //       }
  //     }
  //   });
  //   this.fullResponse = description;
  // }

  // provideVerbalInteraction(dynamicInteraction) {
  //   // get the current status.
  //   const currentInteractionScreen = dynamicInteraction.FormScreens[this.interactionScreenNumber];
  //   let description = 'I haven\'t been provided enough information to know what the designer wanted from this interaction';
  //   currentInteractionScreen.FormElements.forEach(formElement => {
  //     if (formElement.id === this.interactionElementId) {
  //       if (formElement.hint) {
  //         description = formElement.hint;
  //       } else if (formElement.label) {
  //         description = formElement.label;
  //       }
  //     }
  //   });
  //   // this.fullResponse = description;
  // }

  /**
   * Connected to a switch which sets localStorage and the view to only query knowledge.
   */
  toggleKnowledgeOnly() {
    if (this.knowledgeOnly) {
      localStorage.removeItem('heartQueryFilter');
      this.knowledgeOnly = false;
      return;
    }

    localStorage.setItem('heartQueryFilter', 'knowledge');
    this.knowledgeOnly = true;
  }

  /**
   * Shows a dialog with available workflows
   */
  toggleAvailableWorkflows() {
    this.workflowData.toggleAvailableWorkflows();
  }

  minimizeChat(): void {
    this.chatService.minimizedByUser = true;
    this.chatService.setChatMinimizedState(true);
  }

}
