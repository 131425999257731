import { Injectable } from '@angular/core';
import { formControlValidationPattern } from '@eva-services/form-builder/form-control-properties';
import { DynamicInputModel } from '@ng-dynamic-forms/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  validateEmail(email) {
    // eslint-disable-next-line max-len
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = new RegExp(formControlValidationPattern.email);
    return re.test(String(email).toLowerCase());
  }

  convertTimestampToDate(time: number): string {
    return moment(time).format('YYYY/MM/DD hh:mm:ss');
  }

/**
 *
 * This is to check if an string value contains a URL. If it is, it must be converted to a hyperlink for display
 * @param str a string value from either an elements label or its value
 * @param matLabel boolean to mark if the string comes from a material design input label
 * @param formControlModel model of the control the string is being changed on, only used for hints
 */
findURL(str: string, matLabel?: boolean, formControlModel?: DynamicInputModel): string {
  const urlRegex = /(https?:\/\/[^\s]+)|(www[^\s]+)/g;

  // if it is a material design label it will not properly display the hyperlink so remove the link and add it to the hint
  if (matLabel && str) {
    str.replace(urlRegex, function (url) {
      if (formControlModel) {
      if (formControlModel.hint && formControlModel.hint.length) { // if there is already a hint, append the hyperlinks
         formControlModel.hint += ' <br> ' + url;
        } else { // if there is not already a hint, set one
          formControlModel.hint = url;
        }
      }
      return url;
    });
  } else if (str && str.indexOf('<a href="') === -1) { // no existing links found
    str = str.replace(urlRegex, function (url) {
      // before return, check to make sure it starts with https://
      if ( url.substring(0, 8) !== 'https://' && url.substring(0, 7) !== 'http://') {
        url = 'https://' + url;
      }
      return '<a href="' + url + '" rel="noopener noreferrer" target="_blank" >' + url + ' </a>';
    });
  }
  return str;
}

getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
}
