import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KnowledgeDocumentGetTransaction, KnowledgeDocument, KnowledgeDocumentSaveTransaction,
  KnowledgeRemoveImages, KnowledgeVersionsDraftPublished, KnowledgeFormUrlReplacementResponse,
  KnowledgeFormUrlReplacementJSON } from '@eva-model/knowledge/knowledge';
import { environment } from '@environments/environment';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { SigningService } from '@eva-core/signing.service';
import { KnowledgeReturnObject, DeleteImageFromKnowledgeResponse, MoveImagesKnowledgeResponseItem, KnowledgeDocumentVersions } from '@eva-model/return-objects/returnObjects';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeService {

  constructor(
    private _http: HttpClient,
    public evaGlobalService: EvaGlobalService,
    public signingService: SigningService,
    private fireStorage: AngularFireStorage,
    private perf: AngularFirePerformance) { }

  /**
   * This function gets the newest knowledge by group public key, knowledge doc id and whether its a published
   * version of the document (the most recent published version). If no published version exists, this returns
   * the newest created draft.
   *
   * @param groupPublicKey the group public key that owns the knowledge.
   * @param knowledgeDocId the docId that has been assigned to the knowledge.
   * @param publishedVersion boolean, determines if the most recent draft or published version is returned.
   */
  getNewestKnowledge(groupPublicKey: string, knowledgeDocId: string, publishedVersion: boolean, viewKey?: string):
    Promise<KnowledgeReturnObject> {
    // put together the get knowledge transaction object.
    const getKnowledgeTransaction: KnowledgeDocumentGetTransaction = {
      groupPublicKey: groupPublicKey,
      knowledgeDocId: knowledgeDocId,
      publishedVersion: publishedVersion
    };

    // include viewkey if passed
    if (viewKey) {
      getKnowledgeTransaction['viewKey'] = viewKey;
    }

    // the url to obtain the knowledge from.
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.newestKnowledgeByGroupDocId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this._http.post<KnowledgeReturnObject>(url, getKnowledgeTransaction, httpOptions).pipe(
      trace('knowledge-getNewestKnowledge')
    ).toPromise();
  }

  /**
   * This gets the knowledge document by the group public key that owns the knowledge, the doc ID of the knowledge
   * and the version that has been saved. If any part is not found, will return an error code.
   *
   * @param groupPublicKey the group public key that owns the knowledge.
   * @param knowledgeDocId the docId that has been assigned to the knowledge.
   * @param version the version number of the knowledge.
   */
  getKnowledgeByVersion(groupPublicKey: string, knowledgeDocId: string, version: string, viewKey?: string): Promise<KnowledgeReturnObject> {
    // put together the get knowledge transaction object.
    const getKnowledgeTransaction: KnowledgeDocumentGetTransaction = {
      groupPublicKey: groupPublicKey,
      knowledgeDocId: knowledgeDocId,
      version: '' + version
    };

    // include viewkey if passed
    if (viewKey) {
      getKnowledgeTransaction['viewKey'] = viewKey;
    }

    // the url to obtain the knowledge from.
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.knowledgeByGroupDocIdAndVersion;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this._http.post<KnowledgeReturnObject>(url, getKnowledgeTransaction, httpOptions).pipe(
      trace('knowledge-getKnowledgeByVersion')
    ).toPromise();
  }

  /**
   * This function adds or updates knowledge in the backend for the public key, doc ID (if it exists)
   * and the new version of the document.
   *
   * @param knowledgeDocument the knowledge document to be added or updated on.
   */
  async addUpdateKnowledge(knowledgeDocument: KnowledgeDocument): Promise<KnowledgeReturnObject> {
    // create the knowledge signed knowledge transaction.
    const knowledgeDocumentSaveTransaction: KnowledgeDocumentSaveTransaction =
      await this.signingService.signKnowledgeObject(knowledgeDocument);

          // the url to obtain the knowledge from.
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.addUpdateKnowledge;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // send the data and get a response.
    return this._http.post<KnowledgeReturnObject>(url, knowledgeDocumentSaveTransaction, httpOptions).pipe(
      trace('knowledge-addUpdateKnowledge')
    ).toPromise();
  }

  async deleteRestoreKnowledge(groupPublicKey: string, docId: string, toDelete: boolean = false, toRestore: boolean = false) {
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.deleteRestoreKnowledge;

    const body = {
      groupPublicKey,
      docId,
      deleteDoc: toDelete,
      restoreDoc: toRestore
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // send the data and get a response.
    return this._http.post<KnowledgeReturnObject>(url, body, httpOptions).pipe(
      trace('knowledge-deleteRestoreKnowledge')
    ).toPromise();
  }

  /**
   * Updates the current knowledge doc version with a collection of deleted image paths.
   *
   * @param knowledgeDocument the knowledge document to be added or updated on.
   */
  async markImagesRemovedFromKnowledge(deletedImagesData: KnowledgeRemoveImages): Promise<DeleteImageFromKnowledgeResponse> {
          // the url to obtain the knowledge from.
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.addRemovedImages;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // send the data and get a response.
    return this._http.post<DeleteImageFromKnowledgeResponse>(url, deletedImagesData, httpOptions).pipe(
      trace('knowledge-markImagesRemovedFromKnowledge')
    ).toPromise();
  }

  /**
   * Checks the class member activeDocSearchResult. If it exists, returns a list of the docs versions and whether
   * the version is published or not.
   *
   * @param {string} currentVersion - the current version of the doc currently being viewed
   */
  getVersionInformationFromActiveDoc(
    currentVersion: number,
    allVersions: number[],
    publishedVersions: number[]): KnowledgeVersionsDraftPublished[] | null {
    // Do a few quick checks to create a better dataset to show on the view about the doc version information.
    return allVersions.map((version: number) => {
      // Turn the version numbers into objects
      return {
        version,
        currentVersion: currentVersion === version ? true : false,
        published: publishedVersions.includes(version)
      };
    });
  }

  /**
   * Gets a document from the server and returns the version history of it
   */
  async getDocumentRevisions(id: string, groupPublicKey: string, publishedVersion: boolean): Promise<KnowledgeDocumentVersions> {
    try {
      const document = await this.getNewestKnowledge(groupPublicKey, id, publishedVersion);
      return {
        docName: document.additional.knowledgeDocument.name,
        allVersions: document.additional.versions,
        publishedVersions: document.additional.publishedVersions
      };
    } catch(err) {
      Promise.reject(err);
    }
  }



}
