<div class="full-width-container" [style.height.px]="viewHeight" #containerGraph>
  <!-- <p-tree [value]="conditionTreeNodes" layout="horizontal"></p-tree> -->
  <div class="graph-buttons">
    <button (click)="zoomToFitGraph()">Fit to view</button>
    <button (click)="toggleViewHeight()">{{viewHeight === 450 ? 'Shrink' : 'Expand'}}</button>
  </div>
  <ngx-graph class="chart-container" [links]="links" [nodes]="nodes" [scheme]="'nightLights'" [curve]="curve"
    [draggingEnabled]="false" [autoCenter]="true" [center$]="center$" [zoomToFit$]="zoomToFit$"
    [view]="[containerGraph.offsetWidth, containerGraph.offsetHeight]">

    <ng-template #defsTemplate>
      <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
        <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
      </svg:marker>
    </ng-template>

    <ng-template #nodeTemplate let-node>
      <svg:g class="node" ngx-tooltip (click)="toggleTree(node)">
        <svg:rect [attr.width]="node.dimension.width" [attr.height]="node.dimension.height"
          [attr.fill]="node.color || node.options.color" />
        <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2" class="node-label">
          {{node.label}} {{node.collapse ? '&#43;' : '&#8722;'}}
        </svg:text>
      </svg:g>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge">
        <svg:path class="line" stroke-width="2" marker-end="url(#arrow)">
        </svg:path>
        <svg:text class="edge-label" text-anchor="middle">
          <textPath class="text-path" [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline"
            startOffset="50%">
            {{link.label}}
          </textPath>
        </svg:text>
      </svg:g>
    </ng-template>

  </ngx-graph>
</div>