<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="setUserAction(null)" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Choose Action</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="button-layout">
      <div class="flex-direction-column right-vertical-border">
        <button mat-raised-button color="primary" [mat-dialog-close]="setUserAction('create')" cdkFocusInitial
          class="create-new-button">Create New</button>
        <span class="padding-all">This will create a new interaction with same properties as the imported one.</span>
      </div>
      <div class="flex-direction-column">
        <button mat-raised-button color="primary" [mat-dialog-close]="setUserAction('map')"
          class="create-new-button">Map Existing</button>
        <span class="padding-all">This will allow you to update an existing interaction with the imported one.</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="setUserAction(null)">Cancel</button>
  </mat-dialog-actions>
</div>