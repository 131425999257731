import { Pipe, PipeTransform } from '@angular/core';
import { PropertyMapping } from '@eva-model/search';

@Pipe({
  name: 'getPropertyUserFriendlyName'
})
export class GetPropertyUserFriendlyNamePipe implements PipeTransform {
  transform(property: string, propertyNameMapping: PropertyMapping): string {
    const currentProperty = propertyNameMapping.properties.find(mappedProperty => {
      if (mappedProperty.propertyName === property) {
        return true;
      }
      return false;
    });

    return currentProperty && currentProperty.propertyUserFriendlyName ? currentProperty.propertyUserFriendlyName : '';
  }
}