import { Component } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { StatusService } from '@eva-services/status/status.service';
import { ThemeService } from '@eva-services/theme/theme.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    public evaGlobalService: EvaGlobalService,
    private statusService: StatusService,
    private themeService: ThemeService
  ) {

  }

}
