<div *ngIf="(workflowData$ | async) as workflow">
  <mat-spinner *ngIf="!workflow || workflow.loading"></mat-spinner>

  <div *ngIf="!workflow.loading && !workflow.error">
    <h2 mat-dialog-title>{{ workflow.workflow.name }}</h2>
    <hr>
    <mat-dialog-content>
      <strong>Opening a new workflow. Are you sure you want to continue?</strong>
    </mat-dialog-content>
    <hr>
    <mat-dialog-actions>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button color="primary" cdkFocusInitial (click)="continue()">Continue</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="workflow.error">
    <h2 mat-dialog-title>No workflowId found</h2>
    <hr>
    <mat-dialog-content>
      <strong>An error occured or the workflow ID was not found.</strong>
    </mat-dialog-content>
    <hr>
    <mat-dialog-actions>
      <button mat-button (click)="cancel()">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>

