import { Component, OnInit, Input, Inject, forwardRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgAisInstantSearch } from 'angular-instantsearch';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { GenericSearchFilter } from '@eva-model/generic-search';

@Component({
  selector: 'app-generic-search-filter',
  templateUrl: './generic-search-filter.component.html',
  styleUrls: ['./generic-search-filter.component.scss']
})
export class GenericSearchFilterComponent implements OnInit {

  // @Input() _index: number;
  @Output() _selectedFilterChange = new EventEmitter<GenericSearchFilter>();

  selectionList: {selected: boolean; value: any}[];

  _selectedFilter: GenericSearchFilter;
  @Input()
  set selectedFilter(_selectedFilter: GenericSearchFilter) {
    this._selectedFilter = _selectedFilter;
    this.updatedSelectedFilter();
  }

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent: NgAisInstantSearch,
    private searchValueModifierService: SearchValueModifierService,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.updatedSelectedFilter();
  }

  updatedSelectedFilter() {
    // generate what is selected and what's not
    if (this._selectedFilter.type === 'select' && this._selectedFilter.filterValues) {
      // loop potential selection and see if any are already selected
      this.selectionList = this._selectedFilter.filterValues.map((value) => {
        return { value: value, selected: this._selectedFilter.defaultValue.includes(value) };
      });
    }

    // if hidden, apply css style.
    if (this._selectedFilter.hidden) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

  valueChanged(index: number) {
    let currentFilterValue: any;
    let newDefaultValue: any;

    if (this._selectedFilter.type === 'date' || this._selectedFilter.type === 'date-range') {
      // check if default value is a Date
      if (this._selectedFilter.defaultValue[index] instanceof Date) {
        currentFilterValue = this._selectedFilter.defaultValue[index].getTime();
      } else {
        currentFilterValue = this._selectedFilter.defaultValue[index];
      }
      newDefaultValue = JSON.parse(JSON.stringify(this._selectedFilter.defaultValue));
    } else if (this._selectedFilter.type === 'select') {
      currentFilterValue = this._selectedFilter.defaultValue[0];
      newDefaultValue = this.selectionList.filter(option => option.selected === true).map(option => option.value);
    }

    // pass null back if there are no selections
    if (!newDefaultValue || newDefaultValue.length === 0) {
      newDefaultValue = [null];
    }

    // update value on current object for view
    this._selectedFilter.defaultValue = newDefaultValue;

    // pass newly created object to search to execute new search
    this._selectedFilterChange.emit({
      ...this._selectedFilter,
      defaultValue: newDefaultValue
    });
  }

  clearFilterValue(element: any, index: number) {
    const newDefaultValue = JSON.parse(JSON.stringify(this._selectedFilter.defaultValue));
    newDefaultValue[index] = null;

    this._selectedFilter.defaultValue = newDefaultValue;

    switch (this._selectedFilter.type) {
      case 'date-range':
        element.value = null;
        break;
      default:
        break;
    }

    this._selectedFilterChange.emit({
      ...this._selectedFilter,
      defaultValue: newDefaultValue
    });
  }

  getFilterValue(filterValue: any) {
    if (this._selectedFilter.filterValueModifier) {
      return this.searchValueModifierService.modifyValues(this._selectedFilter.filterValueModifier, filterValue);
    } else {
      return filterValue;
    }
  }

  fetchSelectionList() {
    this.updatedSelectedFilter();
  }

}
