import { Injectable } from '@angular/core';
// bring in the http clients
import { HttpClient, HttpParams } from '@angular/common/http';
// import rxjs operators
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { GroupTransactionStructure } from './blocktransactionstructures/grouptransactionstructure';
import { KeysService } from '@eva-core/encryption/keys.service';
import { SigningService } from '@eva-core/signing.service';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Group, GroupAccessRequest } from '@eva-model/group';
import { CryptoInvitation, CryptoInvitationAcceptance, CryptoGroupRequest } from '@eva-model/invitation';
import { EVASignedObject } from '@eva-model/signing/signing';
import { DeleteGroupResponse, RequestGroupAccessResponse } from '@eva-model/group-requests/group-requests';
import { UserDirectoryDocument } from '@eva-model/user/userDirectory';
import { BlockChainTransactionResponse } from '@eva-model/blockchain/transaction';
import { GroupPrivateKeyInformation } from '@eva-model/group/groupKeys';
import {take, map} from "rxjs/operators";
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
@Injectable()
export class GroupProviderService {

  private _EVA_ENDPOINT = environment.firebaseFunction.endpoint; // the eva firebase project endpoint
  private _DYNAMICFORMS_ENDPOINT = environment.endPoints.DYNAMIC_INTERACTIONS.url; // the dynamic forms project
  private _groupConfig = environment.blockConfig.types.groups; // root level of the group configuration.

  usersGroup: Group[]; // an array of users groups
  userGroupMembership: string[]; // the public keys of the groups that the user is a member of.

  constructor(
    private http: HttpClient,
    private keysService: KeysService,
    private signingService: SigningService,
    private _firestoreService: FirestoreService,
    private perf: AngularFirePerformance
  ) {
  }

  //#region GroupMembership

  /**
   * This gets the encryption membership (if exists) or the signing membership (if exists)
   *
   * @param groupPublicKey the group public key to get the encryption membership.
   */
  async getEncryptionGroupMembership(groupPublicKey: string): Promise<string[]> {
    try {
      const fullGroup = await this.getGroupObservable(groupPublicKey).pipe(
        take(1),
        trace('group-providers-getEncryptionGroupMembership')
        ).toPromise();
      // check if the group exists.
      if (fullGroup) {
        // if the encryption membership exists, return this.
        if (fullGroup.groupEncryptionMembership) {
          return fullGroup.groupEncryptionMembership;
        } else if (fullGroup.groupSigningMembership) {
          // default to the signing membership if the encryption membership can not be found.
          return fullGroup.groupSigningMembership;
        } else {
          // if neither the encryption membership or signing membership exists, reject the promise.
          return Promise.reject({error: 'No Group Memberships found'});
        }
      } else {
        // if no group is found, reject the promise.
        return Promise.reject({error: 'Group Not Found'});
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  //#endregion GroupMembership

  //#region GetGroupInformation

  /**
   * This function gets an observable of the groups by the users public key.
   *
   * @param userPublicKey the suers public key to match
   */
  public groupsByUserPublicKey(userPublicKey: string): Observable<Group[]> {
    return this._firestoreService.col<Group>('GroupSigningKeys/', ref => ref.orderBy(`signingMembership.${userPublicKey}`)
      .limit(5)).valueChanges().pipe(
        trace('group-providers-groupsByUserPublicKey')
      );
  }

  /**
   * This function returns the Observable for groups from groupName
   *
   * @param groupName Name of the group being searched
   */
  public groupsByName(groupName): Observable<Group[]> {
    return this._firestoreService.col<Group>('GroupSigningKeys/', reference => reference.orderBy(`searchableIndex.${groupName}`)
      .limit(10)).valueChanges().pipe(
        trace('group-providers-groupsByName')
      );
  }

  /**
   * Returns aon Observable of this group of found by public key. Returns the group name then completes.
   *
   * @param groupPublicKey public key of the group
   */
  public groupByPublicKey(groupPublicKey: string): Observable<Group> {
    return this._firestoreService.doc<Group>(`GroupSigningKeys/${groupPublicKey}`).valueChanges();
  }

  /**
   * This function returns the group member info from memberId
   *
   * @param memberId Id of the group member
   */
  getGroupMemberInfo(memberId: string): Observable<UserDirectoryDocument[]> {
    const url = this._EVA_ENDPOINT + '/getGroupMemberInfo';

    const paramsOptions = new HttpParams().set('memberId', memberId);
    const options = {
      params: paramsOptions
    };
    return this.http.get<UserDirectoryDocument[]>(url, options).pipe(
      trace('group-providers-getGroupMemberInfo')
    );
  }

  /**
   * This function creates a new group for the block and sends it to the blockchain.
   * @param groupName name of the group
   * @param groupType the type of group
   * @param groupDescription Description of the group
   * @param groupSubType subtype of the group if it exists.
   */
  async createNewGroupForBlockAndSend(groupName: string, groupType: string, groupDescription:  string,
    groupSubType?: string): Promise<BlockChainTransactionResponse> {

    // create the key pair, add my membership and return the keypair
    const keyPair = this.keysService.getKeyPair(); // get a public key.
    const groupPublicKey = keyPair.getPublic('hex'); // get the public key in hex form
    const groupPrivateKey = keyPair.getPrivate('hex').toString(); // get the private key to store and make available for new encryption.
    const addpublicKeys: string[] = []; // the public keys to add. This should always include at least one
    let blockTransaction: GroupTransactionStructure = null;

    try {
      // get the users signing key and hex encoding of the public key.
      const signingKey = await this.keysService.getSigningKey();
      const hexKey = signingKey.getPublic('hex');
      addpublicKeys.push(hexKey); // add this to the group transaction.

      // create a new group block transaction
      blockTransaction = new GroupTransactionStructure();
      blockTransaction.setFromValues(groupPublicKey, groupName, groupType, false, addpublicKeys, null, groupSubType, null, null,
        null, groupDescription);
      // create an object to keep the group's private keys.
      const keys: GroupPrivateKeyInformation = {
        groupName: groupName,
        publicKey: groupPublicKey,
        privateKey: groupPrivateKey
      };
      // create a reference to the private key location and create the object there.
      const $colPrivateKeys = this._firestoreService.col('groupPrivateKeys');
      await this._firestoreService.add($colPrivateKeys, keys);

      // sign the object with the new group keypair and send it to the blockchain.
      return this.signingService.signObjectWithOtherKeyAndSendToBlock(blockTransaction.groupTransactionBase,
        keyPair, this._groupConfig.typeId);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function adds one or many public keys to a group.
   *
   * @param newMemberPublicKeys the new public keys to add
   * @param groupPublicKey the public key of the group to add.
   * @param groupName the name of the group to add
   * @param groupType the type of group to add.
   * @param message additional information to include in the request.
   * @param organizationalGroup is this an organizational group?
   */
  async addGroupMember(newMemberPublicKeys: string[], groupPublicKey: string, groupName?: string, groupType?: string, message?: any,
  organizationalGroup?: boolean) {
    const blockTransaction = new GroupTransactionStructure();
    blockTransaction.setFromValues(groupPublicKey, groupName, groupType, organizationalGroup, newMemberPublicKeys, null, null, message);

    // the signObject function will add the users public key, a blockchain timestamp and the crypto signature
    const sendObject: EVASignedObject = {
      data: {
        unencryptedData: blockTransaction.groupTransactionBase,
        type: environment.blockConfig.types.groups.typeId,
        publicKey: '',
        timeStamp: Date.now()
      },
      signature: ''
    };

    try {
      const signedObject = await this.signingService.signObject(sendObject);
      await this.signingService.sendObjectToEndPoint(signedObject);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This removes one or many members of the group from the blockchain
   *
   * @param oldMemberPublicKeys the public keys to remove
   * @param groupPublicKey the public key for the transaction to occur against.
   * @param groupName the group name
   * @param groupType the group type
   * @param message additional information to include.
   * @return {Promise<any>} the promise from the Blockchain.
   */
  async removeGroupMember(oldMemberPublicKeys: string[], groupPublicKey: string, groupName?: string, groupType?: string,
  message?: any): Promise<any> {

    const blockTransaction = new GroupTransactionStructure();
    blockTransaction.setFromValues(groupPublicKey, groupName, groupType, false, null, oldMemberPublicKeys, null, message);

    // the signObject function will add the users public key, a blockchain timestamp and the crypto signature
    const sendObject: EVASignedObject = {
      data: {
        unencryptedData: blockTransaction.groupTransactionBase,
        type: environment.blockConfig.types.groups.typeId,
        publicKey: '',
        timeStamp: Date.now()
      },
      signature: ''
    };

    try {
      const objectToSend = await this.signingService.signObject(sendObject);
      return await this.signingService.sendObjectToEndPoint(objectToSend);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  //#endregion GroupBlockchainTransactions

  //#region GroupAccessRequests

  /**
   * This is used to request group access this would be for an invitation or normal
   * @param email the email of the user requesting access
   * @param name the name of the user requesting the access
   * @param userPublicKey the requestor's public key.
   * @param groupRequestedToPublicKey the public key of the group the user is requesting access to.
   * @param groupRequestedToName the name of the group
   * @param groupRequestedToType the type of the group
   * ||| add in the crypto items.
   */
  requestGroupAccess(email: string, name: string, userPublicKey: string,
    groupRequestedToPublicKey: string, groupRequestedToName: string, groupRequestedToType: string,
    cryptoGroupRequest?: CryptoGroupRequest,
    cryptoAcceptance?: CryptoInvitationAcceptance,
    cryptoInvitation?: CryptoInvitation): Promise<RequestGroupAccessResponse> {
    const url = this._EVA_ENDPOINT + '/createGroupRequestAccess';

    // create the request.
    const groupAccessRequest: GroupAccessRequest = {
      requesterEmail: email,
      requesterName: name,
      requesterPublicKey: userPublicKey,
      groupPublicKey: groupRequestedToPublicKey,
      groupName: groupRequestedToName,
      groupType: groupRequestedToType
    };

    // create the request.
    return this.http.post<RequestGroupAccessResponse>(url, groupAccessRequest).pipe(
      take(1),
      trace('group-providers-requestGroupAccess'),
      // { attributes: {
      //   userPk: (userPublicKey.length > 20) ? userPublicKey.substring(0, 20) : userPublicKey,
      //   groupRequestedToPublicKey: (groupRequestedToPublicKey.length > 20)
      //     ? groupRequestedToPublicKey.substring(0, 20) : groupRequestedToPublicKey
      // }}),
    ).toPromise();
  }

  /**
   * This returns an observable of group Access requests to return.
   *
   * @param groupPublicKey the group public key to check
   */
  getGroupAccessRequests(groupPublicKey: string): Observable<GroupAccessRequest[]> {
    return this._firestoreService.colWithIds$<GroupAccessRequest>('GroupAccess/' + groupPublicKey + '/Requests')
    .pipe(
      trace('group-providers-getGroupAccessRequests')
    );
  }

  /**
   * This function deletes the group access request from the database.
   *
   * @param groupAccessRequest the group request for the item.
   */
  deleteRequest(groupAccessRequest: GroupAccessRequest): Observable<DeleteGroupResponse> {
    const url = this._EVA_ENDPOINT + '/deleteRequest';

    // setup the parameters for this call.
    let paramsOption = new HttpParams();

    paramsOption = paramsOption.set('groupPublicKey', groupAccessRequest.groupPublicKey);
    paramsOption = paramsOption.set('requestId', groupAccessRequest.id);

    const options = {
      params: paramsOption
    };

    return this.http.get<DeleteGroupResponse>(url, options).pipe(
      trace('group-providers-deleteRequest')
    );
  }

  //#endregion GroupAccessRequests

  //#region groupObservables

  /**
   * This returns an observable of a group in the EVA ecosystem by the public key fo the group.
   *
   * @param groupPublicKey the group public key to obtain.
   */
  public getGroupObservable(groupPublicKey: string): Observable<Group> {
    const that = this;

    const encryptionGroupRef$: Observable<Group> = that._firestoreService.doc<Group>('GroupSigningKeys/' + groupPublicKey).valueChanges();
    return encryptionGroupRef$;
  }

  //#endregion groupObservables
}
