import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@eva-core/auth.service';
import { LoggingService } from '@eva-core/logging.service';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable, SubscriptionLike as ISubscription, Subscription } from 'rxjs';
import { PasswordValidation } from './password-validation';
import { ErrorStateMatcher } from '@angular/material/core';
import { saveAs } from 'file-saver';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { environment } from '@environments/environment';
import { UserService } from '@eva-services/user/user.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { BROWSERS } from 'ngx-device-detector';
import { LastStateService } from '@eva-services/last-state/last-state.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') private stepper: MatStepper;

  matcher = new MyErrorStateMatcher();
  signupForm: UntypedFormGroup;
  detailForm: UntypedFormGroup;
  mnemonicForm: UntypedFormGroup;
  finito: UntypedFormGroup;
  initForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  linkForm: UntypedFormGroup;
  tacForm: UntypedFormGroup;
  signingMnemonicForm: UntypedFormGroup;
  encryptionMnemonicForm: UntypedFormGroup;
  userId;
  user;
  emailVerified = false;
  sendingVerificationEmail = false;
  link = false;
  loading = false;
  encryptionMnemonic = '';
  signingMnemonic = '';
  splitSigningMnemonic: string[] = [];
  splitEncryptionMnemonic: string[] = [];
  init = +this._route.snapshot.params['init'] || 0;
  googleLabel = (+this._route.snapshot.params['init'] === 4) ? "Sign up with Google" : "Login with Google";
  formLabel = (+this._route.snapshot.params['init'] === 4) ? "EVA Sign Up" : "EVA Sign In";
  returnUrl: string;
  firebaseFunctionsEndpoint = environment.firebaseFunction.endpoint;

  angularFireUser: any;
  verifiedUser = false;
  isLinear = false;

  private loginPageSubs = new Subscription();

  constructor(
    public afAuth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private _http: HttpClient,
    private _inviteService: InvitationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public logging: LoggingService,
    private userService: UserService,
    private evaGlobalService: EvaGlobalService,
    private lastStateService: LastStateService
    // private angularFireAuth: AngularFireAuth
  ) {
    //#region Check if user is browing with Internet Explorer to redirect to 'dead end' page for messaging about not using IE
    if ( this.evaGlobalService.evaDeviceInfo.browser === BROWSERS.IE ) {
      this._router.navigate(['end']);
    }
    //#endregion

    this.loginPageSubs.add(
      this.afAuth.user
      .subscribe(user => {
        if (user) {
          this.user = user;

          if (user.signingMnemonic && user.encryptionMnemonic) {
            this.signingMnemonic = user.signingMnemonic;
            this.encryptionMnemonic = user.encryptionMnemonic;

            // Create mnemonic forms based on generated mnemonics.
            this.signingMnemonicForm = this.createMnemonicFormGroup(this.signingMnemonic);
            this.encryptionMnemonicForm = this.createMnemonicFormGroup(this.encryptionMnemonic);

            this.splitSigningMnemonic = this.signingMnemonic.split(' ');
            this.splitEncryptionMnemonic = this.encryptionMnemonic.split(' ');
          }

          this.angularFireUser = this.afAuth.getCurrentAuthState();
          if (this.angularFireUser) {
            if (this.angularFireUser.emailVerified) {
              this.emailVerified = true;
            }
          }

          this.userId = user.uid;

          if (user.signingPublicKey && user.encryptionPublicKey && user.acceptedTermsAndCond) {
            if (!this.afAuth.returnedToPreviousPage) {
              this.afAuth.returnedToPreviousPage = true;
              this.goToPreviousPage();
            } else {
              this._router.navigate(['/']);
              this.lastStateService.initializeLastState(true);
            }
          }
        }
      })
    );

    this.initForm = this._fb.group(
      {
        'email': ['', [
          Validators.email,
          Validators.required
        ]]
      });

    this.signupForm = this._fb.group(
      {
        'email': ['', [
          Validators.email,
          Validators.required,
          this.googleDomainEmailValidator(['atb.com', 'gmail.com'])
        ]],
        'password': ['', [
          Validators.required
        ]],
        'confirmPassword': ['', [
          Validators.required
        ]]
      },
      {
        validator: PasswordValidation.MatchPassword('password', 'confirmPassword')// your validation method
      });

    this.loginForm = this._fb.group(
      {
        'email': ['', [
          Validators.email,
          Validators.required
        ]],
        'password': ['', [
          Validators.required
        ]]
      });

    this.linkForm = this._fb.group(
      {
        'password': ['', [
          Validators.required
        ]]
      });

    // this.tacForm = this._fb.group(
    //   {
    //     'tacChecked': ['', [
    //       Validators.required
    //     ]]
    //   }, );
    this.detailForm = this._fb.group(
      {
        'privateKeyPassword': ['', [
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&._]).{8,25}$/),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#$^+=!*()@%&._?`;:'"<>,.{}|\]\[\\\/]).{8,25}$/),
          Validators.required,
        ]],
        'confirmPrivateKeyPassword': ['', [
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#$^+=!*()@%&._?`;:'"<>,.{}|\]\[\\\/]).{8,25}$/),
          Validators.required,
        ]]
      },
      {
        validator: PasswordValidation.MatchPassword('privateKeyPassword', 'confirmPrivateKeyPassword')// your validation method
      });
  }

  //#region implementing AfterViewInit, OnInit, OnDestroy interfaces
  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy(): void {
    if ( this.loginPageSubs ) {
      this.loginPageSubs.unsubscribe();
    }
  }
  //#endregion

  resendVerification() {
    this.afAuth.sendVerificationEmail(this.angularFireUser);
  }

  checkVerification() {
    location.reload();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  signIn() {
    const email = this.initForm.get('email').value;
    this.afAuth.check(email).then((result) => {
      // the first provider in the list will be the "recommended" provider to use.
      if (result[0] === 'google.com') {
        this.logging.logMessage('You already have an account with us. Sign in using the google button.', false, 'error');
        return;
      }
      if (result[0] === 'password') {
        // user exists, log in
        this.link = true;
        this.loginForm.setValue({ 'email': this.initForm.get('email').value, 'password': '' });
        this.init = 2;
        return;
      }
      if (result.length === 0) {
        if (email.substring(email.lastIndexOf("@") + 1) === 'atb.com' || email.substring(email.lastIndexOf("@") + 1) === 'gmail.com') {
          this.logging.logMessage('Please use the Google button to sign in.', false, 'info');
        } else {
          // user doesnt exist, sign up
          const newSignupFormValues = {
            email: '',
            password: '',
            confirmPassword: ''
          };
          // take the email they provided and pass it to the sign up form,
          // then disable the email input so they cannot bypass gmail.com/atb.com email check...
          // (however they can just bypass this by refreshing the page...)
          const emailControl = new UntypedFormControl({
            value: this.initForm.get('email').value,
            disabled: true
          }, [
            Validators.email,
            Validators.required
          ]);
          this.signupForm.setValue(newSignupFormValues);
          this.signupForm.setControl('email', emailControl);
          this.init = 4;
        }
        return;
      }
    });
  }

  loginGoogle() {
    // required to login to google.
    this.afAuth.googleLogin().then((data) => {
    });
  }

  loginEmail() {
    this.afAuth.emailLogin(this.loginForm.get('email').value, this.loginForm.get('password').value).then((data) => {
    });
  }

  // signUpEmail() {
  //   this.afAuth.signUpEmail(this.signupForm.get('email').value, this.signupForm.get('password').value).then((data) => {
  //   });
  // }

  // if a user refuses the terms and conditions then remove their profile from the system
  cancelTAC() {
    const dialogData = new GeneralDialogModel(
      'Are you sure you want to decline the Terms and Conditions?',
      'You will not be able to use the application.',
      'Okay', 'Cancel', null
    );

    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.afAuth.deleteUser();
      }
    });
  }

  goToHome() {
    this._router.navigate(['/welcome']);
  }


  setPrivateKey(stepper?: MatStepper) {

    // determine if the user has a public or private key generated already, if so, don't regenerate.
    if (this.signingMnemonic !== '' && this.encryptionMnemonic !== '') {
      if (stepper) { stepper.next(); }
      return;
    }

    this.loading = true;
    const url = this.firebaseFunctionsEndpoint + '/initializeUserPrivatePublicKey';

    this.afAuth.getIdToken()
    .then(async authToken => {
        // console.log(authToken);

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization': 'Bearer ' + authToken
        })
      };

      // Add in the user ID:
      const bodyData = { 'uid': await this.afAuth.getUserId(), 'userPassword': 'userpassword' }; // this.getPrivateKeyPassword() };
      // TODO: This <any> is super lazy and should be refactored asap
      return this._http.post<any>(url, bodyData, httpOptions).toPromise();
    })
    .then(res => {
      this.signingMnemonic = res.signingKeyInfo.signingMnemonic;
      this.encryptionMnemonic = res.encryptionKeyInfo.encryptionMnemonic;

      // set or update user directory with tempuser set to false since they are now a proper
      // user observable has not updated here by the time the res comes back (race conditions, yay!)
      // but we can get the signing public key from the response
      const userDirectoryObject = {
        uid: this.user.uid,
        emailAddress: this.user.email,
        preferredName: this.user.preferredName,
        publicKey: res.signingKeyInfo.signingPublicKey,
        tempUser: false
      };

      return this._inviteService.upsertUserDirectoryObject(this.user.email, userDirectoryObject);
    })
    .then( data => {
      return this.userService.initializeUserPreferences(this.user);
    })
    .then(userPrefStatus => {
      return this.userService.initializeSignupEVAConversation(this.user);
    })
    .then(res => {
      this.loading = false;
      if (stepper) {
        stepper.next();
      }
    })
    .catch(err => {
      this.loading = false;
      this.logging.logMessage('Issues creating your keys. Try signing up again later.', false, 'error', err);
    });
  }

  mnemonicRecorded() {
    this.encryptionMnemonic = '';
    this.signingMnemonic = '';

    const res = { acceptedTermsAndCond: true, encryptionMnemonic: null, signingMnemonic: null };
    this.afAuth.updateUser(this.user, res)
    .then(updatedUser => {
      this._router.navigate([this.returnUrl], { queryParams: {} });
    })
    .catch(err => {
      console.log(err);
      this.logging.logMessage('An error occured while completing signup, please try again later.', false, 'error', err);
    });

    // this._router.navigateByUrl(this.returnUrl, queryParams: {});
  }

  goToPreviousPage() {
    this._router.navigate([this.returnUrl], { queryParams: {} });
  }

  openSnackBarCopy() {
    this.snackBar.open('Copied mnemonic to clipboard.', '', {
      duration: 1500,
    });
  }

  openSnackBarSave() {
    this.snackBar.open('Saved mnemonic to desktop.', '', {
      duration: 1500,
    });
  }

  private saveToFile(input, title) {
    const blob = new Blob([input], { type: 'text/plain' });
    saveAs(blob, title);
    this.openSnackBarSave();
  }

  resetPassword() {
    this.afAuth.sendPasswordResetEmail(this.loginForm.get('email').value);
  }

  createMnemonicFormGroup(mnemonic: string): UntypedFormGroup {
    const group = this._fb.group({});
    const split = mnemonic.split(' ');

    split.forEach((word, index) => {
      let control: UntypedFormControl;
      if (index === 2 || index === 5 || index === 8) {
        control =
          new UntypedFormControl(
            {value: '', disabled: false},
            [Validators.required, this.wordMatchValidator(word, index, split)]
          );
      } else {
        control = new UntypedFormControl({value: word, disabled: true});
      }
      group.addControl(word, control);
    });
    return group;
  }

  wordMatchValidator(word: string, index: number, array: string[]) {
    return (control): {[key: string]: any} | null => {
      const match = control.value === array[index];
      return match ? null : {'wordmatch': true};
    };
  }

  // checks email against supplied domains, if domain is found will set
  // the FormControl with an error
  googleDomainEmailValidator(emailDomains: string[]) {
    return (control): {[key: string]: any} | null => {
      const found = emailDomains.find(i => control.value.substring(control.value.lastIndexOf("@") + 1) === i);
      return found ? {'googleDomainFound': true} : null;
    };
  }
}
