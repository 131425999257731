import {Injectable} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';

import { InteractionNode } from '@eva-model/interactionNode';
import { InteractionFlatNode } from '@eva-model/interactionFlatNode';

@Injectable()
export class InteractionNodeService {
  dataChange: BehaviorSubject<InteractionNode[]> = new BehaviorSubject<InteractionNode[]>([]);

  get data(): InteractionNode[] { return this.dataChange.value; }

  constructor() {}

  initialize(interactionData: any) {
    // Build the tree nodes from Json object. The result is a list of `InteractionNode` with nested node as children.
    const data = this.buildInteractTree(interactionData, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the interaction structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `InteractionNode`.
   */
  buildInteractTree(value: any, level: number): InteractionNode[] {
    const data: any[] = [];

    if ( value && Array.isArray(value) && value.length > 0 ) {
      value.forEach( k => {
        const node = new InteractionNode();
        node.label = k.label;
        node.data = k.data;
        node.type = '';
        node.collapsedIcon = k.collapsedIcon;
        node.expandedIcon = k.expandedIcon;

        if (k.children && Array.isArray(k.children) && k.children.length > 0) {
          node.children = this.buildInteractTree(k.children, level + 1);
        }

        data.push(node);
      });
    }
    return data;
  }
}
