import {
  Component, OnInit, OnDestroy, ViewChildren, QueryList,
  ComponentFactoryResolver, ViewChild, TemplateRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject, of, combineLatest, Subscription } from 'rxjs';

import {
  DYNAMIC_FORM_CONTROL_TYPE_SELECT,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP, DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_INPUT, DynamicFormControlModel, MATCH_ENABLED, MATCH_DISABLED,
  DynamicFormOption, DynamicCheckboxModel
} from '@ng-dynamic-forms/core';

import { ProjectSettings } from '../../settings/globalvariables';

// Components
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';
import { FormViewerComponent } from './form-viewer/form-viewer.component';
import {
  InteractionElementRelationDialogComponent
} from './interaction-element-relation-dialog/interaction-element-relation-dialog.component';

// Services
import { GeneralDialogService } from '../../providers/general-dialog/general-dialog.service';
import { ArrayUtilsService } from '../../providers/utils/array-utils.service';
import { FormBuilderService } from '../../providers/form-builder/form-builder.service';
import { FormElementVisualizerComponent } from './form-element-visualizer/form-element-visualizer.component';
import { Guid } from '../../core/GUID/guid';
import { FormViewerModel } from '../../model/formViewerModel';

import { ViewContainerDirective } from '../view-container/view-container.directive';
import { FormVisualizerComponent } from '../form-visualizer/form-visualizer.component';

import { SigningService } from '../../core/signing.service';
import { DynamicInteractionsService } from '../../providers/dynamicforms/dynamic-forms.service';
import { InteractionElementRelationService } from '../../providers/interacton-element-relation/interaction-element-relation.service';
import { LoggingService } from '../../core/logging.service';
import { EvaGlobalService } from '../../core/eva-global.service';

// Models
import { IfThenLogicAction, InteractionElementRelationDialogModel, Relation, SubRelation } from '../../model/interactionElementRelationDialogModel';
import { InteractionSpecialControlBind, SpecialSubCntrl } from '../../model/interactionSpecialControlBind';
import { InteractionData, InteractionTableData, Page } from '../../model/interactionBuilder';
import { GeneralDialogModel } from '../../model/generalDialogModel';
import {
  FRM_CNTRL_INPUT, FRM_CNTRL_DATETIME, FRM_CNTRL_ADDITIONAL,
  FRM_CNTRL_CUSTOM, FRM_CNTRL_SPECIAL, FrmCntrl, FrmCntrlSpcl,
  FrmControlSpecialCustomName
} from '../../model/formControls';
import { DeleteInteractionDialogComponent } from './delete-interaction-dialog.component';
import { CreateGroupDialogComponent } from './create-group-dialog.component';
import { DynamicComponent } from '@eva-model/interaction/interaction';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import {DialogService} from "@eva-ui/guard/dialog.service";
import {tap, take, filter} from "rxjs/operators";
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { environment } from '@environments/environment';
import { SearchAuthService } from '@eva-core/search-auth-service';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { ValueModifier, PropertyMapping } from '@eva-model/search';
import { GenericSearchFilter } from '@eva-model/generic-search';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { isEqual } from 'lodash';
import { UtilsService } from '@eva-services/utils/utils.service';
import { AnnounceNewTab } from '@eva-model/chat/chat';
import { ChatService } from '@eva-services/chat/chat.service';
import { SearchUtilsService } from '@eva-services/utils/search-utils.service';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { InteractionMapped } from '@eva-model/interaction/interactionMapped';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy {

  PageEnum = Page;                                                  // Enum for all interaction builder pages
  page: Page = this.PageEnum.MyInteractions;                        // Current Page for interaction builder

  dynamicForm: any;                                                 // Dynamic Form containing all the form elements
  dynFrm: any = {};                                                 // Stores new dynamic form generated from new ContainerRef
                                                                    // in form visualizer
  frmBuilderGrp: UntypedFormGroup;                                         // This stores the group of form controls for validation

  isWaiting = false;                                                // checks for whether waiting for interaction submission
                                                                    // or opening preview
  waitMessage: string = null;                                       // message displayed when isWaiting is true
  isWaitingForGroups = false;                                       // status for whether we are waiting for groups
  isWaitingForInteractions = false;                                 // status for whether we are waiting for user interactions
  onWaitForGroupsMessage = 'Fetching your groups';                  // message displayed when isWaitingForGroups is true
  isDynamicFormEncrypted = true;                                    // status for whether dynamic form is encrypted or not

  frmCntrlsBasic: FrmCntrl[] = FRM_CNTRL_INPUT;                     // Types of Basic Form Controls
  frmCntrlsDateTime: FrmCntrl[] = FRM_CNTRL_DATETIME;               // Types of Date/Time Form Controls
  frmCntrlsAdditional: FrmCntrl[] = FRM_CNTRL_ADDITIONAL;           // Types of Additional Form Controls
  frmCntrlsCustom: FrmCntrl[] = FRM_CNTRL_CUSTOM;                   // Types of Custom Form Controls
  frmCntrlsSpecial: FrmCntrlSpcl[] = FRM_CNTRL_SPECIAL;             // Types of Special Form Controls

  private componentSubs: Subscription = new Subscription();         // Tracks all component-wide subscriptions

  componentReference: any;                                          // ComponentReference to new ComponentRef generated for form visualizer

  showPreview = false;                                              // toggle preview screen
  showPreviewFullWidth = false;                                     // toggle preview full width screen
  showDynamicObjects = false;                                       // toggle dynamic objects screen
  showDynamicData = false;                                          // toggle dynamic data screen
  showSummary = false;                                              // toggle summary screen
  loadMyInteractions = false;                                       // toggle for loading screen when loading interactions
  currentTab = 0;                                                   // status of current screen selected in builder
  movingScreen = -1;                                                // index of form screen being dragged
  movingControl = -1;                                               // index of form control being dragged

  userInteractions: InteractionData[] = [];                         // list of user interactions
  userInteractionsTableData: InteractionTableData[] = [];           // list of user interactions for MatTableDataSource
  interactionSubmittedToBlockSubscription: Subject<any>
    = new Subject();                                                // This is the subscription for when the imported interaction gets
                                                                    // submitted to block
  importedInteractionTimestamp = '';                                // Timestamp of the imported interaction
  formBuilderGroupValues: { name: string, userGroup: string};

  displayedColumns: string[]
    = ['interaction', 'group', 'lastModified', 'actions'];          // list of MatTableDataSource column headings
  interactionsData: MatTableDataSource<InteractionTableData> = new MatTableDataSource(this.userInteractionsTableData);

  @ViewChildren(FormElementVisualizerComponent) visualElements: QueryList<FormElementVisualizerComponent>;
  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;


  private lastStringifiedDynamicForm = '';                  // last saved string representation of the interaction
  hasHovered: Map<number, boolean> = new Map();             // Moved hover state to here from persisted interaction

  paginator: MatPaginator;
  generalDialogChangeSubs: any;
  componentTitle: string;
  tabIndex: number;
  // for algolia search
  searchConfig = { indexName: '', searchClient: {} }; // the search configuration
  searchClient: SearchClient; // the algolia search client
  algoliaIndex = 'Interactions' + environment.algolia.indexEnding; // the Algolia index to query.
  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.INTERACTION_BUILDER;
  searchAccess = false; // is search access ready.
  currentlyLoading = true; // currently getting a token
  searchToken: AlgoliaSearchToken; // the algolia search token used for this component.
  // searchReady = false; // removed with UI changes.
  interactionPropertyNameMapping: PropertyMapping; // the property mapping for interactions config
  interactionFilters: GenericSearchFilter[] = []; // the interaction search filters.
  tab: AnnounceNewTab;

  state = {
    filters: [{defaultValue: []}],
    searchText: ''
  };

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.interactionsData.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;
  public defaultColDef: ColDef;                             // the default column definitions
  public rowHeight: number;                                 // the height of individual rows
  public columnDefs: (ColDef | ColGroupDef)[];              // stores definitions for each column
  context: any;
  uniqueTabId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private arrayUtil: ArrayUtilsService,
    private interactionService: DynamicInteractionsService,
    private generalDialogService: GeneralDialogService,
    private signingService: SigningService,
    private formBuilderService: FormBuilderService,
    private componentFactoryResolver: ComponentFactoryResolver,
    // private interactionSyncService: DynamicInteractionSyncService, no longer in use
    private elementRelationService: InteractionElementRelationService,
    private logService: LoggingService,
    public evaGlobalService: EvaGlobalService,
    public dialogService: DialogService,
    private multiViewService: MultiViewService,
    private searchAuthService: SearchAuthService,
    private valueModifierService: SearchValueModifierService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService,
    private searchUtils: SearchUtilsService,
    private menuNavigationService: MenuNavigationService) {
    this.setDefaultInteractionSearchSettings(); // set the default interaction search settings.
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    this.multiViewService.setCreateNewTabFunction(this.showCreateNewInteraction);

    // add a subscription watching for closing tab changes. If this matches then add a subscription to the can
    this.componentSubs.add(
      this.multiViewService.closeTab$.pipe(
        filter((closeTab) => closeTab && this.dynamicForm && closeTab.entityType === 'Interaction'),
        filter((closeTab) => this.uniqueTabId === closeTab.entityId),
        filter((closeTab) => this.tabIndex === closeTab.tabIndex)).subscribe(closeTab => {
        this.canDeactivate().subscribe(value => {
          if (value) {
            setTimeout(() => {
              closeTab.closeSubject.next(true);
            });
          }
        });
      })
    );

    this.menuNavigationService.updateCurrentMenu('builders');

    this.createForm(); // create the form.

    this.dynamicForm = new Object();
    this.dynamicForm['name'] = undefined;
    this.dynamicForm['FormScreens'] = [];
  }

  async ngOnInit() {
    this.tab = this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex];
    this.frmBuilderGrp = this.fb.group({
      name: [this.formBuilderGroupValues?.name ?? this.dynamicForm.name, Validators.required],
      userGroup: [this.formBuilderGroupValues?.userGroup ?? this.dynamicForm.groupPublicKey, Validators.required],
      listBasicCntrlss: ''
    });
    this.isWaitingForInteractions = true;
    this.isWaitingForGroups = true;
    // add a subscription to what for whether groups are waiting to load.
    this.componentSubs.add(
      this.evaGlobalService.userGroupsChange$.subscribe(change => {
        this.isWaitingForGroups = false;
      })
    );

    await this.getSearchToken(); // get the search token and setup the search token.

    // add a subscription to watch whether the users group public keys are loading.
    this.componentSubs.add(
      this.evaGlobalService.userGroupsPublicKeys$.subscribe(userGroupsPublicKeys => {
        if (userGroupsPublicKeys) {
          this.interactionFilters[0].filterValues = userGroupsPublicKeys;
          // this.searchAuthService.getSearchToken(userGroupsPublicKeys, true).then(token => {
          //   this.searchToken.next(token);
          // });
        }
      })
    );
  }

  /**
   * If this call returns false Angular will block block navigation to a new route until the user confirms.
   * @link https://angular.io/api/router/CanActivate
   */
  canDeactivate = (): Observable<boolean> => {
    if (this.lastStringifiedDynamicForm === '') {
      return of(true); // this page displays more than just a single interaction, and should not have last state in all views

    }

    if (!this.isNotEdited()) {
      return this.dialogService.confirm('You have unsaved changes that will be lost.').pipe(
        tap(x => {
          if (x && x === true) { // if true the user would like to navigate away; let's reset last saved interaction
            this.lastStringifiedDynamicForm = '';
          }
        })
      );
    }
    return of(true);
  }

  /**
   * This function fetches all the interactions with their versions for all user groups
   */
  getInteractions(): void {
    // if no user groups, then return
    if (!this.evaGlobalService.userGroups) {
      this.page = this.PageEnum.MyInteractionsEmpty;
      this.isWaitingForInteractions = false;
      return;
    }

    const allGroupsInteractions: Observable<InteractionData[]>[] = [];
    this.evaGlobalService.userGroups.forEach(data => {
      allGroupsInteractions.push(this.interactionService.fetchInteractionsByGroup(data.groupPublicKey));
    });

    this.componentSubs.add(
      combineLatest(allGroupsInteractions).subscribe(groupsInteractions => {
        // clear user interaction array before new data
        this.userInteractions.splice(0, this.userInteractions.length);
        this.userInteractionsTableData.splice(0, this.userInteractionsTableData.length);
        // loop through array of groups
        groupsInteractions.forEach(groupinteractions => {
          // loop through array of interactions inside a group
          groupinteractions.forEach(interaction => {
            this.userInteractions.push(interaction);
            let activeVersion = interaction.version;
            if (interaction.Versions) {
              const versionExist = interaction.Versions.find(version => Number(version.version) === Number(interaction.version));
              if (!versionExist) {
                activeVersion = interaction.Versions.reverse()[0].version;
              }
            }
            this.userInteractionsTableData.push({
              Versions: interaction.Versions && interaction.Versions.reverse(),
              name: interaction.name,
              version: '' + activeVersion,
              group: this.evaGlobalService.userGroups
                .filter(item => item.groupPublicKey === interaction.groupPublicKey)[0].groupName,
              id: interaction.id,
              groupPublicKey: interaction.groupPublicKey,
              selectedVersion: '' + activeVersion
            });
          });
        });
        this.userInteractionsTableData.sort(function (a, b) { return Number(b.version) - Number(a.version); });
      }, (err) => {
        console.log(err);
      }, () => {
        // if no interactions available, then show empty my interactions page
        if (this.userInteractionsTableData.length === 0) {
          this.page = this.PageEnum.MyInteractionsEmpty;
        } else {
          if (this.page !== this.PageEnum.CreateNewInteraction) {
            this.page = this.PageEnum.MyInteractions;
          }
          // put recently updated interactions at the top
          this.userInteractionsTableData.sort(function (a, b) { return Number(b.version) - Number(a.version); });
          this.interactionsData.paginator = this.paginator;
          this.interactionsData.sort = this.sort;
          this.isWaitingForInteractions = false;
        }
      })
    );
  }

  /**
   * This function filters the interactions based on their name
   *
   * @param filterValue User input search string
   */
  applyFilter(filterValue: string): void {
    this.interactionsData.filter = filterValue.trim().toLowerCase();

    if (this.interactionsData.paginator) {
      this.interactionsData.paginator.firstPage();
    }
  }

  /**
   * This function toggles preview section's visibility
   */
  previewEvent(): void {
    this.showDynamicData = false;
    this.showDynamicObjects = false;
    this.showSummary = false;
    this.showPreviewFullWidth = false;
    this.showPreview = !this.showPreview;
    // update preview if currently visible on screen
    // wait for view container to become visible to user
    setTimeout(() => {
      if (this.viewContainerHost) {
        this.formVisualizer();
      }
    });
  }

  /**
   * This function toggles dynamic objects section's visibility
   */
  dynObjectEvent(): void {
    this.showDynamicData = false;
    this.showSummary = false;
    this.showPreview = false;
    this.showPreviewFullWidth = false;
    this.showDynamicObjects = !this.showDynamicObjects;
  }

  /**
   * This function toggles dynamic data section's visibility
   */
  dynDataEvent(): void {
    this.showDynamicObjects = false;
    this.showSummary = false;
    this.showPreview = false;
    this.showPreviewFullWidth = false;
    this.showDynamicData = !this.showDynamicData;
  }

  /**
   * This function toggles summary section's visibility
   */
  summaryEvent(): void {
    this.showDynamicData = false;
    this.showDynamicObjects = false;
    this.showPreview = false;
    this.showPreviewFullWidth = false;
    this.showSummary = !this.showSummary;
  }

  /**
   * This function starts the editing process for the interaction
   *
   * @param row InteractionTableData object - row being edited
   */
  startEdit = (row: InteractionTableData): void => {
    const changeObj = {
      "groupublicKey": row.groupPublicKey,
      "entityId": row.id,
      "entityVersion": row.version
    };

    // this.showPreview = false;
    // this.showPreviewFullWidth = false;
    // this.showDynamicObjects = false;
    // this.showDynamicData = false;

    this.generalDialogService.announceChange(changeObj);
    this.editInteraction({
      that: this,
      generalDialogOnChange: changeObj
    });
  }

  /**
   * This function stores the selected version of interaction from the drop down
   *
   * @param event SelectionChange event for MatSelect
   * @param interaction InteractionTableData object - the row being affected
   */
  setSelectedVersion(event: MatSelectChange, interaction: InteractionTableData): void {
    interaction.selectedVersion = event.value;
  }

  /**
   * This function opens the dialog for deleting an interaction
   * Delete interaction functionality not implemented yet
   */
  deleteInteraction(): void {
    const deletedialogRef = this.dialog.open(DeleteInteractionDialogComponent);
  }

  /**
   * This function opens the dialog for creating a new group
   */
  openNewGroupDialog(): void {
    const newGroupDialogRef = this.dialog.open(CreateGroupDialogComponent);
    newGroupDialogRef.afterClosed().subscribe(groupData => {
      if (groupData) {
        this.evaGlobalService.userGroupsChange$.subscribe(
          (userChangeMsg: boolean) => {
            const group = this.evaGlobalService.userGroups.filter(groupInfo => {
              return groupInfo['groupName'] === groupData['name']
                && groupInfo['groupDescription'] === groupData['description']
                && groupInfo['groupType'] === groupData['type'];
            });
            if (group.length > 0 && group[0]) {
              this.dynamicForm['groupPublicKey'] = group[0]['groupPublicKey'];
              this.frmBuilderGrp.controls['userGroup'].setValue(this.dynamicForm['groupPublicKey']);
              this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
              if (this.multiViewService.tabs[Routes.InteractionBuilder]
                && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
                this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
              }
            }
          },
          (err) => { console.log(err); }
        );
      }
    });
  }

  /**
   * This function changes current group to selected group in drop down
   *
   * @param event HTML Event for selection in drop down
   */
  onGroupSelectionChange(event: MatSelectChange): void {
    this.dynamicForm['groupPublicKey'] = event.value;
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
  }

  /**
   * This function toggles the encryption selection
   *
   * @param event HTML Event for toggle value change
   */
  toggleFormEncryption(event: MatSlideToggleChange): void {
    this.isDynamicFormEncrypted = event.checked;
  }

  /**
   * This function changes name of the interaction
   *
   * @param event HTML input value change event
   */
  onInteractionNameChange(event: any): void {
    this.dynamicForm['name'] = event.target.value;
    // Add default starting screen
    if (!this.dynamicForm['FormScreens']) {
      this.dynamicForm['FormScreens'] = [];
    }
    if (this.dynamicForm['FormScreens'] && this.dynamicForm['FormScreens'].length === 0) {
      this.addNewFormScreen();
    }
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
  }

  /**
   * This function checks the validity of interaction
   */
  isInteractionNotValid(): boolean {
    let isNotValid = false;

    if (this.dynamicForm['name'] &&
      this.dynamicForm['groupPublicKey'] &&
      this.evaGlobalService.userGroups &&
      Array.isArray(this.evaGlobalService.userGroups) &&
      this.evaGlobalService.userGroups.map(grp => grp.groupPublicKey).includes(this.dynamicForm['groupPublicKey']) &&
      this.dynamicForm['FormScreens'] &&
      Array.isArray(this.dynamicForm['FormScreens']) &&
      this.dynamicForm['FormScreens'].length > 0) {

      this.dynamicForm['FormScreens'].forEach(scrn => {
        if (!(scrn['FormElements'] &&
          Array.isArray(scrn['FormElements']) &&
          scrn['FormElements'].length > 0)) {
          isNotValid = true;
        }
      });

    } else {
      isNotValid = true;
    }
    return isNotValid;
  }

  /**
   * This function submits the form with updates to the Blockchain
   */
  submitFormToBlock = (data: any): void => {
    const that: FormBuilderComponent = data.that;
    if (!that.dynamicForm['id']) {
      that.dynamicForm['id'] = Guid.newGuid().toString();
    }
    that.dynamicForm['timestamp'] = Date.now();
    that.importedInteractionTimestamp = that.dynamicForm['timestamp'];
    that.dynamicForm['encryptedByDefault'] = that.isDynamicFormEncrypted;

    that.dynamicForm['FormScreens'].forEach((scrn: any, index: number) => {
      scrn['order'] = index;
    });

    that.onSubmit(data).then(() => {
      that.lastStringifiedDynamicForm = that.jsonStringify(that.dynamicForm); // Save a local copy to detect user changes
      that.tab.additionalInstanceData.lastStringifiedDynamicForm = that.lastStringifiedDynamicForm;
      that.tab.additionalInstanceData.dynamicForm = that.dynamicForm;
      that.tab.tabName = that.dynamicForm.name;
      if (that.multiViewService.tabs[Routes.InteractionBuilder] && that.multiViewService.tabs[Routes.InteractionBuilder][that.tabIndex]) {
        that.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", that.tab, that.tabIndex);
      }
    });
  }

  /**
   * This returns true if the current dynamic form is the same as the last saved dynamic form.
   */
  isNotEdited(): boolean {
    if (!this.lastStringifiedDynamicForm) {
      return;
    }

    const interaction = JSON.parse(this.lastStringifiedDynamicForm);
    interaction.FormScreens?.forEach((screen, screenIndex) => {
      screen.FormElements.forEach((element, elementIndex) => {
        if (element.options) {
          element.options.forEach(option => {
            option = new DynamicFormOption({...option});
          });
        }
        if (element.group) {
          element.group.forEach(groupElement => {
            groupElement = new DynamicCheckboxModel({...groupElement});
          });
        }
        if (!element['additional']) {
          element['additional'] = {
            relation: []
          };
        }
        if (!element['additional'].relation) {
          element['additional'].relation = element.relation ?? [];
        }
        element.relation = element['additional']?.relation;
        if (this.dynamicForm.FormScreens[screenIndex]
          && this.dynamicForm.FormScreens[screenIndex].FormElements[elementIndex]
          && !this.dynamicForm.FormScreens[screenIndex].FormElements[elementIndex]['additional']) {
            element['additional'] = null;
        }
        if (this.dynamicForm.FormScreens[screenIndex]
          && this.dynamicForm.FormScreens[screenIndex].FormElements[elementIndex]
          && !this.dynamicForm.FormScreens[screenIndex].FormElements[elementIndex].relation) {
            delete element.relation;
        }
      });
    });

    return isEqual(interaction, this.dynamicForm);
    // return JSON.stringify(interaction) === JSON.stringify(this.dynamicForm);
  }

  /**
   * This function sends the changes to the Blockchain and shows message response
   */
  async onSubmit(data: any): Promise<void> {
    const that: FormBuilderComponent = data.that;
    const isImportSubmit: boolean = data.isImportSubmit;
    that.isWaiting = true;
    that.waitMessage = "Saving ...";

    try {
      await that.signingService.signAndSendObject(that.dynamicForm, ProjectSettings.types.dynamicInteractions.typeId);
      that.logService.logMessage(
        `Interaction submitted and saved, Interaction ${that.dynamicForm['name']} with version ${that.dynamicForm['timestamp']} recorded`
        , false, 'success');

      that.isWaiting = false;
      if (isImportSubmit) {
        that.page = that.PageEnum.MyInteractions;
        data.that.multiViewService.setCreateNewTab({
          component: FormBuilderComponent,
          tabName: that.dynamicForm.name,
          additionalInstanceData: {
            componentTitle: that.componentTitle,
            page: that.PageEnum.CreateNewInteraction,
            showPreview: false,
            showPreviewFullWidth: false,
            showDynamicObjects: false,
            showDynamicData: false,
            entitySubs: null,
            currentlyLoading: false,
            dynamicForm: that.dynamicForm,
            lastStringifiedDynamicForm: JSON.stringify(that.dynamicForm),
            formBuilderGroupValues: {
              name: that.dynamicForm.name,
              userGroup: that.dynamicForm.userGroup
            }
          }
        });
        if (that.interactionSubmittedToBlockSubscription) {
          that.interactionSubmittedToBlockSubscription.next(this.importedInteractionTimestamp);
        }
        return;
      }

      const patchObj = {
        name: data.that.dynamicForm['name'],
        userGroup: data.that.dynamicForm['groupPublicKey']
      };

      data.that.frmBuilderGrp.patchValue(patchObj);
      that.userInteractions = [];
      that.userInteractions.length = 0;
      if (that.loadMyInteractions) {
        that.getInteractions();
      }
      that.page = that.PageEnum.CreateNewInteraction;
      if (that.interactionSubmittedToBlockSubscription) {
        that.interactionSubmittedToBlockSubscription.next(this.importedInteractionTimestamp);
      }
    } catch (err) {
      that.logService.logMessage(
        `Interaction submission failed, Interaction ${that.dynamicForm['name']} failed to be recorded :: ERROR :: ${err}`
        , false, 'Error');

      that.isWaiting = false;
      if (!isImportSubmit) {
        that.page = that.PageEnum.CreateNewInteraction;
      }
      if (that.interactionSubmittedToBlockSubscription) {
        that.interactionSubmittedToBlockSubscription.next(this.importedInteractionTimestamp);
      }
    }
  }

  /**
   * This function creates a new form with required fields
   */
  createForm = (): void => {
    this.frmBuilderGrp = this.fb.group({
      name: ['', Validators.required],
      userGroup: ['', Validators.required],
      listBasicCntrlss: ''
    });
  }

  /**
   * This function opens the dialog for deleting a screen from an interaction
   *
   * @param formScreen FormScreen being deleted
   */
  removeFormScreenConfirm(formScreen: any): void {
    if (!formScreen || !formScreen.name) {
      alert('There is no valid form screen to be deleted, please try again!');
      return;
    }

    let dialogData: GeneralDialogModel;
    if (formScreen['FormElements'] && formScreen['FormElements'].length > 0) {
      dialogData = new GeneralDialogModel(
        'Delete Screen', `There appears to be element(s) on this screen, would you like to delete this screen?`, 'Delete', 'Cancel');
    } else {
      this.removeFormScreen({
        formScreen: formScreen,
        theDynamicForm: this.dynamicForm
      });
      return;
    }

    this.openGeneralDialog(dialogData, this.removeFormScreen, { formScreen: formScreen, theDynamicForm: this.dynamicForm });
  }

  /**
   * This function is the callback for the delete screen dialog
   */
  removeFormScreen = (data: any): void => {
    if (!data || !data.formScreen || !data.theDynamicForm || !data.theDynamicForm.FormScreens) {
      return;
    }

    const index = data.theDynamicForm.FormScreens.indexOf(data.formScreen);

    if (index >= 0) {
      data.theDynamicForm.FormScreens.splice(index, 1);
      this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
      if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
      }
      // update preview if currently visible on screen
      if (this.viewContainerHost) {
        this.formVisualizer();
      }
    }
  }

  /**
   * This function is for drag start event for new form controls
   *
   * @param event HTML drag start event
   */
  dragStartHandler(event: any): void {
    event.dataTransfer.setData('elementId', event.target.id);
    event.dataTransfer.dropEffect = 'copy';
  }

  /**
   * This function is for drag start event for new form controls
   *
   * @param event HTML drag start event
   */
  dargOverHandler(event: any): boolean {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
    return false;
  }

  /**
   * This function is for drop event for new form controls
   *
   * @param event HTML drag start event
   */
  dropHandler(event: any): boolean {

    event.preventDefault();
    event.stopPropagation();

    let dropOnElement = event.target;
    // let isWorkflow = false;
    if (dropOnElement.id !== 'formControlDropZone') {
      while (dropOnElement.offsetParent.id !== 'formControlDropZone') {
        dropOnElement = dropOnElement.offsetParent;
      }
      if (dropOnElement.offsetParent.id === 'formControlDropZone') {
        dropOnElement = dropOnElement.offsetParent;
      } else {
        return;
      }
    }

    const dropOnElementScreenIndex = +dropOnElement.dataset.formScreenIndex;
    const dragElementId = event.dataTransfer.getData('elementId');
    const dragElement = document.getElementById(dragElementId);
    // Not expected element to be dropped!
    if (!dragElement || dragElement.dataset.formControl !== 'true') {
      return;
    }

    const dragElementType = dragElement.dataset.formControlType;
    const dragElementInputType = dragElement.dataset.formControlInputType;

    if (dragElementType === 'SPECIAL') {
      this.injectInteractionSpecialControl(dragElementId, dropOnElementScreenIndex);
      // TODO :: Handle special controls through its factory element(s)
    } else {
      this.injectInteractionControl(dragElementType, dragElementInputType, dropOnElementScreenIndex);
    }
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    return false;
  }

  /**
   * This function runs only if a special control is dropped into the interaction form screen visualizer
   * @param dragElementId the id of the element being dragged into the form
   * @param dropOnElementScreenIndex the form screen index the control is being dropped on the interaction form screen visualizer
   */
  private injectInteractionSpecialControl(dragElementId: any, dropOnElementScreenIndex: number): void {
    const specialControlName = dragElementId;
    const specialControlIndex = this.frmCntrlsSpecial.map(special => special.name).indexOf(specialControlName);
    if (specialControlIndex !== -1) { // if the special control is found in the array of special controls
      const specialControl: FrmCntrlSpcl = this.frmCntrlsSpecial[specialControlIndex];
      if (specialControl && specialControl.factory && Array.isArray(specialControl.factory) && specialControl.factory.length > 0) {

        const specialSubControls = [];
        // define the properties of the interaction control, then add it to specialSubCntrls
        specialControl.factory.forEach((frmCntrl: FrmCntrl) => {
          const injectedCntrl = this.injectInteractionControl(
            frmCntrl.type, frmCntrl.inputType, dropOnElementScreenIndex, frmCntrl.name, specialControlName);
          specialSubControls.push(injectedCntrl);
        });

        if (!this.dynamicForm['specialControls']) this.dynamicForm['specialControls'] = [];
        const specialControlBind = new InteractionSpecialControlBind(specialControl.name, specialControl.subType,
          specialControl.customType, []);
        // for each sub control within the special control selected, push the id and screen index number into spclCntrlBind
        specialSubControls.forEach(subControl => {
          specialControlBind.controls.push(new SpecialSubCntrl(subControl.id, dropOnElementScreenIndex));
        });

        // add specialControlBind to the special controls item in the dynamicForm array which stores the form being created
        this.dynamicForm['specialControls'].push(specialControlBind);
      }
    }
  }

  /**
  * This function injects information taken from formControls into the form builder
  *
  * @param dragElementType Form Control type
  * @param dragElementInputType Form Control input type
  * @param dropOnElementScreenIndex Index of form screen being updated
  * @param specialCntrlLabel the label of the element being created
  * @param controlName the name of the control
  **/
  private injectInteractionControl(
    dragElementType: string, dragElementInputType: string, dropOnElementScreenIndex: number,
    specialControlLabel?: string, controlName?: string) {
    let formControlModel: DynamicFormControlModel;
    // if it is an international control, insert the placeholder name into controlName
    if (controlName === FrmControlSpecialCustomName.CanadaPostInternationalDataListRetrieve
      || controlName === FrmControlSpecialCustomName.CanadaPostDataListRetrieve
      || controlName === FrmControlSpecialCustomName.CanadaPostRetrieve) {
      formControlModel = this.formBuilderService.createFormControlModel(dragElementType, dragElementInputType, null, specialControlLabel);
    } else { // if it is not a international control, proceed as normal
      formControlModel = this.formBuilderService.createFormControlModel(dragElementType, dragElementInputType, null, specialControlLabel);
    }

    const formScreen = this.dynamicForm['FormScreens'][dropOnElementScreenIndex];
    if (!formScreen['FormElements']) {
      formScreen['FormElements'] = [];
    }
    formScreen['FormElements'].push(formControlModel);
    // update preview if currently visible on screen
    if (this.viewContainerHost) {
      this.formVisualizer();
    }
    return formControlModel;
  }

  /**
   * This function opens dialog for removing the form control from screen
   * @param $event HTML click event
   */
  removeFormElementConfirm($event: any): void {
    const formScreenIndex = +$event.formScreenIndex;
    const formElementId = $event.formElementId;

    //#region Check to find if control is part of a special control
    let relatedControls = [];
    let specialControl = null;
    if (this.dynamicForm.specialControls &&
      Array.isArray(this.dynamicForm.specialControls) &&
      this.dynamicForm.specialControls.length > 0) {

      this.dynamicForm.specialControls.every((control: InteractionSpecialControlBind) => {
        const controlIndex = control.controls.map(formControl => formControl.id).indexOf(formElementId);
        if (controlIndex !== -1) {
          relatedControls = control.controls.slice();
          specialControl = control;
          return false;
        } else return true;
      });
    }
    //#endregion

    let dialogContent = `Are you sure you want to delete this element ?`;
    if (relatedControls && Array.isArray(relatedControls) && relatedControls.length > 0) {
      dialogContent = `Are you sure you want to delete the elements ?`;
    }

    const dialogData = new GeneralDialogModel(
      'Delete Input', dialogContent, 'Delete', 'Cancel');
    this.openGeneralDialog(
      dialogData,
      this.removeFormElement,
      {
        theDynamicForm: this.dynamicForm,
        formScreenIndex: formScreenIndex,
        formElementId: formElementId,
        relatedControls: relatedControls,
        specialControl: specialControl
      });
  }

  /**
   * This function is the callback for remove element dialog
   *
   * @param data Dynamic Form from which element being removed
   */
  private removeFormElement = (data: any): void => {
    if (!data || (!data.formScreenIndex && data.formScreenIndex !== 0) || !data.formElementId ||
      !data.theDynamicForm || !data.theDynamicForm.FormScreens) {

      return;
    }

    const formScreen = data.theDynamicForm['FormScreens'][data.formScreenIndex];
    if (formScreen['FormElements'] && formScreen['FormElements'].length > 0) {

      let elementIdsToRemove = [];
      if (data.relatedControls && Array.isArray(data.relatedControls) && data.relatedControls.length > 0) {
        elementIdsToRemove = data.relatedControls.map((element: any) => element.id);
      } else elementIdsToRemove.push(data.formElementId);

      elementIdsToRemove.forEach(elementId => {
        // get index of form element object with id
        const removeIndex = formScreen['FormElements'].map(function(formElement) { return formElement.id; }).indexOf(elementId);

        data.theDynamicForm['FormScreens'].forEach(screen => {
          screen['FormElements'].forEach(element => {
            const relations: Relation[] = element['additional'] && element['additional'].relation
              && element['additional'].relation.length > 0 ? element['additional'].relation : element.relation;
            relations?.forEach((relation, index, arr) => {
              if (relation?.subRelation) {
                if (relation.id.find(id => id === elementId)) {
                  arr.splice(index, 1);
                }
                relation.subRelation.forEach(subRelation => {
                  if (subRelation?.then?.operations) {
                    subRelation.then.operations.forEach((operation, operationIndex, operationsArr) => {
                      if (operation.value === elementId) {
                        operationsArr.splice(operationIndex, 1);
                      }
                    });
                  }
                });
              }
            });
            if (!element['additional']) {
              element['additional'] = {};
            }
            element['additional'].relation = relations;
            element.relation = relations;
          });
        });
        if (removeIndex !== -1) {
          // remove form element object
          formScreen['FormElements'].splice(removeIndex, 1);
          // update preview if currently visible on screen
          if (this.viewContainerHost) {
            this.formVisualizer();
          }
        }
      });
      // if element is sub control of special control, remove it from the sub control array
      if (data.specialControl) {

        const interactionSpecialControls = data.theDynamicForm['specialControls'];
        if (interactionSpecialControls && Array.isArray(interactionSpecialControls) && interactionSpecialControls.length > 0) {
          const specialControlIndex = interactionSpecialControls.map(specialControl => specialControl.id).indexOf(data.specialControl.id);
          if (specialControlIndex !== -1) {
            interactionSpecialControls.splice(specialControlIndex, 1);
            // update preview if currently visible on screen
            if (this.viewContainerHost) {
              this.formVisualizer();
            }
          }
        }

      }
      this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
      if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
      }
    }
  }

  /**
   * This function moves form element one index up
   *
   * @param $event HTML move up button click event
   */
  moveUpFormElement($event: any): void {
    const formScreenIndex = +$event.formScreenIndex;
    const formElementIndex = +$event.formElementIndex;

    if (formScreenIndex < 0 || formElementIndex < 1 ||
      (this.dynamicForm['FormScreens'].length - 1) < formScreenIndex) {
      return;
    }

    const formScreen = this.dynamicForm['FormScreens'][formScreenIndex];
    if (formScreen['FormElements'] && formScreen['FormElements'].length - 1 >= formElementIndex) {
      this.arrayUtil.array_move(formScreen['FormElements'], formElementIndex, formElementIndex - 1);
      this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
      if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
      }
      // update preview if currently visible on screen
      if (this.viewContainerHost) {
        this.formVisualizer();
      }
    }
  }

  /**
   * This function moves form element one index down
   *
   * @param $event HTML move down button click event
   */
  moveDownFormElement($event: any): void {
    const formScreenIndex = +$event.formScreenIndex;
    const formElementIndex = +$event.formElementIndex;

    if (formScreenIndex < 0 || formElementIndex < 0 ||
      (this.dynamicForm['FormScreens'].length - 1) < formScreenIndex) {
      return;
    }

    const formScreen = this.dynamicForm['FormScreens'][formScreenIndex];
    if (formScreen['FormElements'] && formScreen['FormElements'].length - 1 >= formElementIndex + 1) {
      this.arrayUtil.array_move(formScreen['FormElements'], formElementIndex, formElementIndex + 1);
      this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
      if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
      }
      // update preview if currently visible on screen
      if (this.viewContainerHost) {
        this.formVisualizer();
      }
    }
  }

  /**
   * This function opens dialog for updating form control's settings
   *
   * @param $event HTML setting button click event
   */
  settingFormElement($event: any): void {
    const formScreenIndex = +$event.formScreenIndex;
    const formElementIndex = +$event.formElementIndex;
    const formControl = $event.formControl;
    const formElementId = $event.formElementId;

    let dynFrmCntrlMdl: DynamicFormControlModel[] = null;

    if (formControl.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
      this.dynamicForm['FormScreens'].forEach(screen => {
        if (!screen['FormElements']) {
          return;
        }
        screen['FormElements'].forEach(element => {
          if (element.id === formControl.originalId) {
            // formControl.additional = element.additional;
            element.group?.forEach(groupControl => {
              if (groupControl.additional?.['defaultVal']) {
                const formControlGroupControl = formControl.group.find(control => control.label === groupControl.label);
                if (formControlGroupControl) {
                  if (!formControlGroupControl.additional) {
                    formControlGroupControl.additional = {};
                  }
                  formControlGroupControl.additional['defaultVal'] = groupControl.additional?.['defaultVal'];
                  if (!formControl.additional
                    || (formControl.additional
                      && (!formControl.additional.defaultVal || !Array.isArray(formControl.additional.defaultVal)))) {
                    formControl.additional = { defaultVal: [] };
                  }
                  formControl.additional['defaultVal'].push(groupControl.label);
                }
              }
            });
          }
        });
      });
    }

    dynFrmCntrlMdl = this.formBuilderService.createFormControlSettingModel(formControl);

    const dialogData = new GeneralDialogModel(
      'Edit Element properties',
      `Setting for ${formElementId} control`,
      'Save', 'Cancel',
      dynFrmCntrlMdl,
    );

    this.openGeneralDialog(dialogData, this.setFormElement,
      { theDynamicForm: this.dynamicForm, formScreenIndex: formScreenIndex, formElementId: formElementId, that: this });
  }

  /**
   * This is the callback function for this.openGeneralDialog in settingFormElement
   * sets element value fields based on default/stored values
   * @param data returned data from this.openGeneralDialog
   */
  setFormElement = (data: any): void => {
    if (!data || !data.formElementId || (!data.formScreenIndex && data.formScreenIndex !== 0) ||
      !data.generalDialogOnChange ||
      !data.theDynamicForm || !data.theDynamicForm.FormScreens) {
      return;
    }

    const formScreen = data.theDynamicForm.FormScreens[data.formScreenIndex];
    if (!formScreen || !formScreen['FormElements']) {
      // form screen doesn't exist
      return;
    }

    const formElementIndex = formScreen['FormElements'].map(function (formElement: any) { return formElement.id; })
      .indexOf(data.formElementId);
    if (formElementIndex === -1) {
      // form element not found
      return;
    }

    const formScreenElement = formScreen['FormElements'][formElementIndex];
    // if additional property doesn't exist, add it to the form element object with relation array if exists
    // we are moving the relation array from relation property of form element to additional to fix ENABLED/DISABLED bug
    if (!formScreenElement.additional) {
      if (formScreenElement.relation && formScreenElement.relation.length > 0) {
        formScreenElement.additional = {'relation': JSON.parse(JSON.stringify(formScreenElement.relation))};
        formScreenElement.relation = [];
      } else {
        formScreenElement.additional = {'relation': []};
      }
    }
    if (formScreenElement.additional && !formScreenElement.additional.relation) {
      formScreenElement.additional.relation = [];
    }
    const objectEntries = Object.entries(data.generalDialogOnChange);

    // for every property of the element, add the values updated by user to the actual form element
    for (let i = 0; i < objectEntries.length; i++) { // for each object in the callback data
      const property = objectEntries[i][0];
      let propertyValue = objectEntries[i][1];

      if (property === 'additional') { // additional stores defaultValues and relation array
        // this check will see if default values are being stored the old way
        // and convert the property value to be structured properly
        let isPastDate = null;
        if (formScreenElement.additional && typeof formScreenElement.additional.isPastDate === 'boolean') {
          isPastDate = formScreenElement.additional.isPastDate;
        }
        if (formScreenElement.additional && (typeof formScreenElement.additional !== "object")) {
          const tempAdditionalValue = formScreenElement.additional;
          propertyValue = {'defaultVal': tempAdditionalValue};
          formScreenElement.additional = propertyValue;
        } else if ( formScreenElement.additional && (typeof formScreenElement.additional === "object") ) {
          // check if relation array exists, if not create new one
          if (formScreenElement.additional.relation) {
            propertyValue = {'defaultVal': propertyValue, 'relation': formScreenElement.additional.relation};
          } else {
            propertyValue = {'defaultVal': propertyValue, 'relation': []};
          }
          if (typeof isPastDate === 'boolean') {
            formScreenElement.additional = { ...(propertyValue as object), isPastDate: isPastDate };
          } else {
            formScreenElement.additional = propertyValue;
          }
          formScreenElement.additional = propertyValue;
        } else {
          formScreenElement.additional = {};
          propertyValue = { 'defaultVal': propertyValue };
          if (typeof isPastDate === 'boolean') {
            formScreenElement.additional = { ...(propertyValue as object), isPastDate: isPastDate };
          } else {
            formScreenElement.additional = propertyValue;
          }
        }

        if (formScreenElement.additional?.defaultVal) {
          if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            formScreenElement.group?.forEach(groupControl => {
              if (formScreenElement.additional?.defaultVal?.find(label => label === groupControl.label)) {
                if (!groupControl.additional) {
                  groupControl.additional = {};
                }
                groupControl.additional['defaultVal'] = true;
              }
            });
            delete formScreenElement.additional?.defaultVal;
          }
        }

      } else if (property === 'isPastDate') { // is stored in additional object so check for a value. Makes it so dates can't be in the past
        if (!formScreenElement.additional) {
          formScreenElement.additional = {};
        }
        formScreenElement.additional.isPastDate = propertyValue;

      } else if (property === 'currentDateDefault') { // is stored in additional object so check for a value. Makes date default to current
        if (!formScreenElement.additional) {
          formScreenElement.additional = {};
        }
        formScreenElement.additional.currentDateDefault = propertyValue;

      } else if (property === 'options' &&
        (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT ||
          formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP)
      ) {
        propertyValue = data.that.formBuilderService.convertArrayToOptions(propertyValue);
      } else if (property === 'group' && formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {

        // if property is checkbox group, create DynamicCheckboxModels
        const disabledIndex = objectEntries.map(entry => entry[0]).indexOf("disabled");   // check if there is "disabled" property
        const isCheckBoxGroupDisabled = (disabledIndex !== -1) ? objectEntries[disabledIndex][1] : false;   // set disabled parameter
        propertyValue = data.that.formBuilderService.convertChipsToGroupCheckBox(propertyValue, isCheckBoxGroupDisabled);
        data.that.dynamicForm['FormScreens'].forEach(screen => {
          if (screen['FormElements']) {
            screen['FormElements'].forEach(formElement => {
              if (formElement.relation && Array.isArray(formElement.relation)) {
                formElement.relation.forEach(relation => {
                  // TODO: Need to test this with checkbox group or radio group
                  // relation.when.forEach(whenCondition => {
                  //   if (whenCondition.id === formScreenElement.id && propertyValue && Array.isArray(propertyValue)) {
                  //     Object.keys(whenCondition.value).forEach(conditionLabel => {
                  //       if (!(propertyValue as any[]).find(propertyLabel => propertyLabel.label ===  conditionLabel)) {
                  //         delete whenCondition.value[conditionLabel];
                  //       }
                  //     });
                  //     propertyValue.forEach(propertyLabel => {
                  //       if (!Object.keys(whenCondition.value).find(conditionLabel =>
                  //         conditionLabel === propertyLabel.label)) {
                  //         whenCondition.value[propertyLabel.label] = false;
                  //       }
                  //     });
                  //   }
                  // });
                });
              }
            });
          }
        });

      } else if (property === 'list' &&
        formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT) {

        if (typeof propertyValue === 'string' || propertyValue instanceof String) {
          // if it is a list of type string/input, use split to break the different list values into an array
          propertyValue = propertyValue.split(',');
        } else {
          propertyValue = [];
        }
      } else if (property === 'disabled') {

        //#region to set the "disabled" property of checkbox group sub checkboxes
        if ( propertyValue === true ) {
          if (formScreenElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            formScreenElement.disabled = true;
          }
        }

        //#endregion

      }

      // sets the key/value pair for the element
      if (property !== 'additional') {
        setProperty(formScreenElement, property, propertyValue);
      }
    }

    const element = formScreen['FormElements'].splice(formElementIndex, 1);
    formScreen['FormElements'].splice(formElementIndex, 0, element[0]);
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    // update preview if currently visible on screen
    if (this.viewContainerHost) {
      this.formVisualizer();
    }
  }

  /**
   * Click event handler for the conditional logic button.
   * Opens the InteractionElementRelationDialog.
   *
   * @param $event HTML button click event
   */
  relationForFormElement(): void {
    // const formScreenIndex = +$event.formScreenIndex;
    // const formElementIndex = +$event.formElementIndex;
    // const formControl = $event.formControl;
    // const formElementId = $event.formElementId;

    this.dynamicForm.FormScreens.forEach((screen, formScreenIndex) => {
      screen.FormElements.forEach((formControl, formElementIndex) => {
        // if form control is a checkbox group, store the relations in the relations array to save the relations properly
        // and also update the additional property where the relation array is stored generally
        if (formControl.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          if (!formControl['additional']) {
            formControl['additional'] = {};
          }
          formControl['additional'].relation = this.dynamicForm['FormScreens'][formScreenIndex].FormElements[formElementIndex]['additional']
            ? this.dynamicForm['FormScreens'][formScreenIndex].FormElements[formElementIndex]['additional'].relation : [];
            this.dynamicForm['FormScreens'][formScreenIndex].FormElements[formElementIndex].relation = formControl['additional'].relation;
        }
      });
    });


    // const interactionScreen = this.dynamicForm['FormScreens'][formScreenIndex];

    // const dialogData: InteractionElementRelationDialogModel =
    //   new InteractionElementRelationDialogModel(
    //     'Related Elements',
    //     `${formControl.type.charAt(0).toUpperCase() + formControl.type.toLowerCase().slice(1)} `
    //     + ((formControl.inputType) ? `${formControl.inputType}` : '')
    //     + ` :: ${formControl.originalId} in interaction screen "${interactionScreen.name}"`,
    //     formScreenIndex, formElementIndex, formControl, this.dynamicForm, 'Save condition', 'Cancel', formControl.disabled);

    const dialogData: InteractionElementRelationDialogModel =
      new InteractionElementRelationDialogModel(
        'Related Elements', null, null, null, null, this.dynamicForm, 'Save condition', 'Cancel', null);


    // all values are now stored in objects
    this.openInteractionElementRelationDialog(
      dialogData,
      this.setRelationForFormElement,
      { that: this });
  }

  /**
   * Sets the relation in the element based on the screen and element ID in the data passed in
   *
   * @param data Dynamic Form being updated
   */
  setRelationForFormElement = (data: any): void => {
    // const interactionScreen = data.that.dynamicForm['FormScreens'][data.formScreenIndex];
    // const interactionScreenElement: EvaDynamicFormControlModel = interactionScreen.FormElements
    //   .find((element: any) => element.id === data.formElementId);
    // if (!interactionScreenElement['additional']) {
    //   interactionScreenElement['additional'] = {};
    // }
    // interactionScreenElement['additional'].relation = data.elementRelationDialogOnChange.map(control => control.relation[0]);
    // interactionScreenElement.relation = data.elementRelationDialogOnChange.map(control => control.relation[0]);

    data.that.dynamicForm['FormScreens'].forEach(screen => {
      screen.FormElements.forEach(element => {
        if (data.elementRelationDialogOnChange.find(control => control.relationElementId === element.id)) {
          if (!element['additional']) {
            element['additional'] = {};
          }
          element['additional'].relation = data.elementRelationDialogOnChange
            .filter(formControl => formControl.relationElementId === element.id).map(formControl => formControl.relation[0]);
          element.relation = data.elementRelationDialogOnChange
            .filter(formControl => formControl.relationElementId === element.id).map(formControl => formControl.relation[0]);
        } else {
          if (element['additional']?.relation) {
            element['additional'].relation = [];
          }
          if (element.relation) {
            element.relation = [];
          }
        }
      });
    });

    if (data.elementRelationDialogOnChange?.length === 0) {
      data.that.dynamicForm['FormScreens'].forEach(screen => {
        screen.FormElements.forEach(formElement => {
          if (formElement['additional']?.relation) {
            formElement['additional'].relation = [];
          }
          if (formElement.relation) {
            formElement.relation = [];
          }
        });
      });
    }

    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    // update preview if currently visible on screen
    if (this.viewContainerHost) {
      this.formVisualizer();
    }

  }
  //#endregion

  /**
   * This function opens the dialog for previewing current screen
   *
   * @param formScreenIndex Index of the form screen being previewed
   */
  onFormScreenViewer(formScreenIndex: number): void {
    const formScreen = this.dynamicForm['FormScreens'][formScreenIndex];
    if (!formScreen || !formScreen['FormElements'] || !(formScreen['FormElements'].length > 0)) {
      return;
    }

    const dynamicFormCloned = this.formBuilderService.cloneInteraction(JSON.parse(JSON.stringify(this.dynamicForm)));
    if (!dynamicFormCloned ||
      !dynamicFormCloned.FormScreens ||
      !dynamicFormCloned.FormScreens[formScreenIndex] ||
      !dynamicFormCloned.FormScreens[formScreenIndex].FormElements) {
      return;
    }

    const dialogData = new GeneralDialogModel(
      'Form Screen Viewer',
      `Form Screen Elements preview`,
      'Ok', undefined,
      dynamicFormCloned.FormScreens[formScreenIndex].FormElements
    );

    this.openGeneralDialog(dialogData, null, null);
  }

  /**
   * This function opens the form viewer dialog.
   *
   * @param interaction This is the interactionTableData being viewed
   */
  onFormViewer(interaction: any): void {
    const dialogData: FormViewerModel = new FormViewerModel(
      'Dynamic Form Viewer',
      `Dynamic Form viewer with individual screens`,
      'Ok', undefined, this.dynamicForm);

    this.openFormViewerDialog(dialogData, undefined, undefined);
  }

  /**
   * This function loads the preview of the form
   */
  formVisualizer(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormVisualizerComponent);

    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: any = viewContainerRef.createComponent(componentFactory);
    this.componentReference = componentRef;

    (<DynamicComponent>componentRef.instance).dynFrmObj = this.dynamicForm;
    (<DynamicComponent>componentRef.instance).isPreview = true;
  }

  /**
   * This function removes the form visualizer from the view
   */
  removeFormVisualizer(): void {
    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();
  }

  /**
   * This is a general purpose function to open dialog window
   *
   * @param dialogModel GeneralDialogModel object
   * @param callback Callback function to call when observable is resolved
   * @param callbackData Callback Data to pass through
   */
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback: Function, callbackData: any): void {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel,
      minWidth: '500px'
    });

    this.componentSubs.add(
      this.generalDialogService.generalDialogChanged$.subscribe(
        changeObj => {
          if (changeObj) {
            if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback functions and callback data.
              callbackData['generalDialogOnChange'] = changeObj;
            }
          }
        },
        err => { console.log(err); }
      )
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * Opens and subscribes to the Form Viewer dialog
   *
   * @param dialogModel the model for the dialog
   * @param callback the callback function to call when dialog closes
   * @param callbackData the data to pass into the callback function
   */
  public openFormViewerDialog = (dialogModel: FormViewerModel, callback: Function, callbackData: any): void => {
    const dialogRef: MatDialogRef<FormViewerComponent> = this.dialog.open(FormViewerComponent, {
      data: dialogModel,
      minWidth: 500
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * Opens and subscribes to the InteractionElementRelationDialog
   *
   * @param dialogModel - the model for the dialog
   * @param callback - the callback function for when the dialog closes
   * @param callbackData - the data to pass to the callback function
   */
  private openInteractionElementRelationDialog(
    dialogModel: InteractionElementRelationDialogModel,
    callback?: Function,
    callbackData?: any): void {
    const dialogRef = this.dialog.open(InteractionElementRelationDialogComponent,
      { data: dialogModel, minWidth: '90vw', minHeight: '90vh' });

    this.componentSubs.add(
      this.elementRelationService.elementRelationDialogChanged$.subscribe(
        (changeObj: any[]) => {
          if (changeObj) {
            if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback function and callback data.
              callbackData['elementRelationDialogOnChange'] = changeObj;
            }
          }
        },
        err => { console.log(err); }
      )
    );

    dialogRef.afterClosed().subscribe((result: boolean) => {
      callbackData['elementRelationDialogOnChange']?.forEach(control => {
        (control?.relation[0] as Relation)?.subRelation?.forEach((subRelation: SubRelation) => {
          if (subRelation.then.action !== IfThenLogicAction.MathEquation) {
            subRelation.then.operations = [subRelation.then.operations?.[0] ?? {
              operator: null,
              value: null
            }];
          }
          const that: FormBuilderComponent = callbackData.that;
          that.dynamicForm.FormScreens.forEach(screen => {
            screen.FormElements.forEach(element => {
              if (subRelation.if.model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                if (element.id === subRelation.if.model.originalId) {
                  subRelation.if.model.group.forEach(groupControl => {
                    const groupElement = element.group.find(elementGroupControl => elementGroupControl.label === groupControl.label);
                    if (groupElement && subRelation.if.value) {
                      subRelation.if.value[groupElement.label] = subRelation.if.value[groupControl.id];
                      delete subRelation.if.value[groupControl.id];
                    }
                  });
                }
              }
              if (subRelation.then.model.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                if (element.id === subRelation.then.model.originalId) {
                  subRelation.then.model.group.forEach(groupControl => {
                    const groupElement = element.group.find(elementGroupControl => elementGroupControl.label === groupControl.label);
                    if (groupElement && subRelation.then.value) {
                      subRelation.then.value[groupElement.label] = subRelation.then.value[groupControl.id];
                      delete subRelation.then.value[groupControl.id];
                    }
                  });
                }
              }
            });
          });
          delete subRelation.formGroup;
          delete subRelation.if.model;
          delete subRelation.then.model;
          delete (control.relation[0] as Relation).ifControl;
          control.relationElementId = (control.relation[0] as Relation).thenControl[0].originalId;
          delete (control.relation[0] as Relation).thenControl;
          if (subRelation.then.action !== IfThenLogicAction.MathEquation) {
            delete subRelation.then.operations;
          }
          if (typeof subRelation.then.value === 'undefined') {
            subRelation.then.value = null;
          }
        });
      });
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * This function fetches data for the selected interaction for editing
   *
   * @param data The data of the interaction being fetched
   */
  editInteraction = (data: any): void => {
    // this.page = this.PageEnum.None;
    if (!data || !data.that || !data.generalDialogOnChange) {
      return;
    }

    data.that.chatService.setChatMinimizedState(true);

    if (data.generalDialogOnChange.groupublicKey &&
      data.generalDialogOnChange.entityId &&
      data.generalDialogOnChange.entityVersion) {

      const interactionId = data.generalDialogOnChange.entityId;
      const version = data.generalDialogOnChange.entityVersion;
      const versionToDate = new Date(Number(version));

      data.that.isWaiting = true;
      data.that.waitMessage = `Fetching interaction ${interactionId} version ${versionToDate}`;

      data.that.entitySubs = data.that.interactionService.fetchInteractionsByIdAndVer(interactionId, version)
        .subscribe(
          (interactionData) => {
            const dynamicForm = data.that.interactionService.interactionObjectMapper(interactionData);
            const lastStringifiedDynamicForm = data.that.jsonStringify(dynamicForm);
            const patchObj = {
              name: dynamicForm['name'],
              userGroup: dynamicForm['groupPublicKey']
            };

            // data.that.frmBuilderGrp.patchValue(patchObj);

            data.that.multiViewService.setCreateNewTab({
              component: FormBuilderComponent,
              tabName: dynamicForm.name,
              additionalInstanceData: {
                componentTitle: data.that.componentTitle,
                page: data.that.PageEnum.CreateNewInteraction,
                showPreview: false,
                showPreviewFullWidth: false,
                showDynamicObjects: false,
                showDynamicData: false,
                entitySubs: null,
                currentlyLoading: false,
                dynamicForm: dynamicForm,
                lastStringifiedDynamicForm: lastStringifiedDynamicForm,
                formBuilderGroupValues: patchObj
              }
            });
          },
          (err: any) => {
            data.that.isWaiting = false;
            console.log(err);
            this.page = this.PageEnum.MyInteractions;
          },
          () => {
            data.that.isWaiting = false;
            this.page = this.PageEnum.MyInteractions;
            // this.page = this.PageEnum.CreateNewInteraction;
          }
        );
    }
  }

  ngOnDestroy() {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }

    // this.searchAuthService.stopRefresh(AlgoliaTokenType.INTERACTION_BUILDER);
  }

    /**
   * This function shows create new interaction page in interaction builder
   */
  showCreateNewInteraction = (): void => {
    this.chatService.setChatMinimizedState(true);
    // this.page = this.PageEnum.CreateNewInteraction;
    // this.showPreview = false;
    // this.showPreviewFullWidth = false;
    // this.showDynamicObjects = false;
    // this.showDynamicData = false;
    // // set everything to default
    // this.entitySubs = null;
    const dynamicForm = new Object();
    dynamicForm['name'] = null;
    dynamicForm['FormScreens'] = [];
    // // update form state, so user is prompted to save
    // this.lastStringifiedDynamicForm = this.jsonStringify(this.dynamicForm);
    // this.createForm();
    this.multiViewService.setCreateNewTab({
      component: FormBuilderComponent,
      tabName: 'New Interaction',
      additionalInstanceData: {
        componentTitle: this.componentTitle,
        page: this.PageEnum.CreateNewInteraction,
        showPreview: false,
        showPreviewFullWidth: false,
        showDynamicObjects: false,
        showDynamicData: false,
        entitySubs: null,
        currentlyLoading: false,
        dynamicForm: dynamicForm,
        lastStringifiedDynamicForm: this.jsonStringify(dynamicForm)
      },
      functionCalls: [{
        functionName: 'createForm'
      }]
    });
  }

  /**
   * This function shows the my interaction page
   */
  showMyInteractions(): void {
    this.canDeactivate().subscribe(value => { // Blocks user from navigating away from page if they have unsaved changes
      if (value === true) {
        if (this.userInteractions.length > 0 || this.loadMyInteractions || this.isWaitingForInteractions) {
          this.page = this.PageEnum.MyInteractions;
        } else if (this.loadMyInteractions) {
          this.page = this.PageEnum.MyInteractionsEmpty;
        } else {
          this.page = this.PageEnum.MyInteractions;
        }
        this.interactionsData.filter = '';
      }
    });

  }

  /**
   * This function opens preview for selected interaction from table
   *
   * @param interaction Interaction being previewed
   */
  openPreview = (interaction: InteractionTableData): void => {
    const data = {
      "groupublicKey": interaction.groupPublicKey,
      "entityId": interaction.id,
      "entityVersion": interaction.version
    };
    this.generalDialogService.announceChange(data);

    if (data.groupublicKey &&
      data.entityId &&
      data.entityVersion) {

      this.isWaiting = true;
      this.waitMessage = 'Loading the viewer content';
      const interactionId = data.entityId;
      const version = data.entityVersion;

      this.componentSubs.add(
        this.interactionService.fetchInteractionsByIdAndVer(interactionId, Number(version)).subscribe(
          (interactionData) => {
            this.dynamicForm = this.interactionService.interactionObjectMapper(interactionData);
            const patchObj = {
              name: this.dynamicForm['name'],
              userGroup: this.dynamicForm['groupPublicKey']
            };

            this.frmBuilderGrp.patchValue(patchObj);
          },
          (err) => {
            this.isWaiting = false;
            this.isWaitingForInteractions = false;
            console.log(err);
            this.page = this.PageEnum.MyInteractions;
            this.waitMessage = '';
          },
          () => {
            this.isWaiting = false;
            this.isWaitingForInteractions = false;
            this.onFormViewer(interaction);
            this.page = this.PageEnum.MyInteractions;
            this.waitMessage = '';
          }
        )
      );
    }
  }

  /**
   * This function is called when tab label is hovered
   *
   * @param index Index of the tab being hovered
   */
  onHoverTabLabel(index: number): void {
     // Moved has hovered from saved dynamic form as it was unnecessarily and made detecting actual user changes difficult
    this.hasHovered.set(index, true);
  }

  /**
   * This function is called when hover leaves tab label
   *
   * @param index Index of the tab being hovered
   */
  onHoverLeaveTabLabel(index: number): void {
     // Moved has hovered from saved dynamic form as it was unnecessarily and made detecting actual user changes difficult
    this.hasHovered.set(index, false);
  }

  /**
   * This function adds new screen to the interaction
   */
  addNewFormScreen(): void {
    // Add the form screen or set the empty 
    if (!this.dynamicForm['FormScreens']) {
      this.dynamicForm['FormScreens'] = [];
    }
    // add a blank screen to the dynamic form design.
    this.dynamicForm['FormScreens'].push(
      {
        name: 'Screen ' + (this.dynamicForm['FormScreens'].length + 1),
        FormElements: []
      }
    );
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    // update preview if currently visible on screen
    if (this.viewContainerHost) {
      this.formVisualizer();
    }
  }

  /**
   * This function updates the label of the tab
   * @param event HTML key down event
   * @param index Index of the tab being changed
   */
  onTabLabelChange(event: any, index: number): void {
    event.stopPropagation();
    if (event.keyCode === 13 || event.charCode === 13) {
      event.preventDefault();
      event.target.blur();
      // update preview if currently visible on screen
      if (this.viewContainerHost) {
        this.formVisualizer();
      }
    }
  }

  /**
   * This function checks for the validity of new tab label
   * @param event HTML focus out event
   * @param index Index of the tab being changed
   */
  onTabLabelBlur(event: any, index: number): void {
    const input = event.target.innerText;

    if (input === '') {
      alert('Screen name cannot be empty');
      event.target.innerText = this.dynamicForm['FormScreens'][index].name;
    } else {
      this.dynamicForm['FormScreens'][index].name = input;
      this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
      if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
      }
    }
  }

  /**
   * This function adds new form control on add button click
   * @param formControlType Form Control Type
   * @param formControlInputType Form Control Input Type
   */
  addFormElement(formControlType: string, formControlInputType: string): void {
    const formScreens = this.dynamicForm['FormScreens'];
    if (formScreens.length === 0) {
      return;
    }
    if (formControlType === 'SPECIAL') {
      this.injectInteractionSpecialControl(formControlInputType, this.currentTab);
    } else {
      this.injectInteractionControl(formControlType, formControlInputType, this.currentTab);
    }
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function sets current screen tab being viewed for mat-tab-group
   *
   * @param event HTML selected tab change event
   */
  setCurrentScreenTab(event: any): void {
    this.currentTab = event.index;
  }

  /**
   * This function re-orders the form screens on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the screen being switched with the screen being dragged
   */
  onDropReorderScreens(event: any, index: number): void {
    if (this.movingScreen === -1) {
      return;
    }
    const dynamicForm = this.dynamicForm['FormScreens'];

    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [dynamicForm[this.movingScreen], dynamicForm[index]] = [dynamicForm[index], dynamicForm[this.movingScreen]];
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    this.movingScreen = -1;
    if (this.viewContainerHost) {
      this.formVisualizer();
    }
  }

  /**
   * This function prevents default action on dragOver event
   * @param event HTML dragOver event
   */
  onDragOverAllowdrop(event: any): void {
    event.preventDefault();
  }

  /**
   * This function stores the index for form screen being dragged
   * @param event HTML dragStart event
   * @param index Index of the form screen being dragged
   */
  onDragScreenTab(event: any, index: number): void {
    this.movingScreen = index;
  }

  /**
   * This function re-orders the form controls on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the form control being switched with the form control being dragged
   */
  onDropReorderControl(event: any, screenIndex: number, formElementIndex: number): void {
    if (this.movingControl === -1) {
      return;
    }
    const formScreen = this.dynamicForm['FormScreens'][screenIndex];
    const formElements = formScreen['FormElements'];

    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [formElements[this.movingControl], formElements[formElementIndex]]
      = [formElements[formElementIndex], formElements[this.movingControl]];
    this.tab.additionalInstanceData.dynamicForm = this.dynamicForm;
    if (this.multiViewService.tabs[Routes.InteractionBuilder] && this.multiViewService.tabs[Routes.InteractionBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.InteractionBuilder, "Update", this.tab, this.tabIndex);
    }
    this.movingControl = -1;
    if (this.viewContainerHost) {
      this.formVisualizer();
    }
  }

  /**
   * This function stores the index for form control being dragged
   * @param event HTML dragStart event
   * @param index Index of the form control being dragged
   */
  onDragControl(event: any, index: number): void {
    this.movingControl = index;
  }

  /**
   * This function opens log info message with current interaction ID
   */
  getInteractionId() {
    this.logService.logMessage('Dynamic Interaction ID is ' + this.dynamicForm['id'], false, 'info');
  }

  /**
   * This function loads user interactions
   */
  loadInteractions() {
    this.loadMyInteractions = true;
    // fetch all interactions for all user groups
    this.componentSubs.add(
      this.evaGlobalService.userGroupsChange$.subscribe(
        () => {
          this.getInteractions();
        },
        (err) => { console.log(err); }
      )
    );
  }

  /**
   * This function returns any json object to string
   *
   * @param object object being stringified
   */
  jsonStringify(object: any): string {
    return JSON.stringify(object, this.utilsService.getCircularReplacer());
  }

//#region Token Functions

  /**
   * This gets the search token for interaction builder
   */
  private async getSearchToken(): Promise<void> {
    this.searchAuthService.getSearchToken(AlgoliaTokenType.INTERACTION_BUILDER).pipe(take(1)).subscribe(
      (token) => {
        this.searchToken = token;
        this.searchClient = algoliasearch(environment.algolia.appId, this.searchToken.securedAPIKey);
        // this.searchConfig.apiKey = this.searchToken.securedAPIKey;
        this.searchConfig = {
          indexName: this.algoliaIndex,
          searchClient: this.searchClient
        };

        this.setInteractionPropertyNameMapping(); // set the default property names.
        this.setDefaultColumnDefinitions(); // set the default column definitions.

        this.rowHeight = 48; // default row height.
        this.context = { componentParent: this };

        this.setColumnDefinitions(); // set the search column definitions.

        this.currentlyLoading = false; // set that that is no longer loading.

        // make sure that a token was issued and is valid.
        if (this.searchToken.securedAPIKey === '') {
          this.searchAccess = true;
        }

        return Promise.resolve();
      },
      (err) => Promise.reject(err)
    );
  }

//#region Setup Search Grid Items.

  /**
   * This sets the default communication search settings for the component.
   */
  private setDefaultInteractionSearchSettings(): void {
    this.interactionFilters = [{
      filterName: ['Group'],
      defaultValue: [null],
      attribute: 'groupPublicKey',
      type: 'select',
      filterValueModifier: ValueModifier.GroupName
    }];
  }

  /**
   * This sets the column definitions for search.
   */
  private setColumnDefinitions(): void {
    this.columnDefs = [
      (this.searchUtils.getColumnDefinition('Name', 'name')),
      (this.searchUtils.getColumnDefinition('ID', 'id')),
      {...(this.searchUtils.getColumnDefinition('Group Name', 'groupPublicKey')),
      cellRenderer: (data) => {
        return this.valueModifierService.modifyValues(ValueModifier.GroupName, data.data.groupPublicKey) as string;
      }},
      {...(this.searchUtils.getColumnDefinitionForDateTime('Last Modified', 'Versions')),
      cellRenderer: (data) => {
        const versions: any[] = this.valueModifierService.modifyValues(ValueModifier.ParseJSONString, data.data.Versions) as any[];
        versions.sort((a, b) => Number(b['version']) - Number(a['version']));
        return this.valueModifierService.modifyValues(ValueModifier.DateShort,
          versions.length > 0 ? versions[0]['version'] : '') as string;
      }},
      {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'Versions')),
      cellRenderer: 'actionTemplateRenderer'}
    ];
  }

  /**
   * This sets the default column definitions.
   */
  private setDefaultColumnDefinitions(): void {
    this.defaultColDef = {
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      tooltipComponent: 'CustomTooltipComponent'
    };
  }

  /**
   * This sets the interaction property name mapping for search.
   */
  private setInteractionPropertyNameMapping(): void {
    this.interactionPropertyNameMapping = {
      properties: [
        { propertyName: 'groupPublicKey', propertyUserFriendlyName: 'Group Name', valueModifiers: [ValueModifier.GroupName] },
        { propertyName: 'Versions', propertyUserFriendlyName: 'Last Modified',
          valueModifiers: [ValueModifier.ParseJSONString], defaultValueProperty: 'version' }
      ]
    };
  }

//#endregion Setup Search Grid Items.

//#endregion Token Functions

}

/**
 * This function sets the property of an object
 */
function setProperty<T, K extends keyof T>(object: T, key: K, value: T[K]) {
  object[key] = value;
}

//#region CommentedOutCode

  // /**
  //  * Returns a object that represents a column definition.
  //  * @param headerName the display name of the column
  //  * @param field the field that this will match with.
  //  */
  // private getColumnDefinition(headerName: string, field: string): ColDef {
  //   return {
  //     headerName: headerName,
  //     field: field,
  //     sortable: false,
  //     filter: 'agTextColumnFilter',
  //     filterParams: {
  //       filterOptions: ["contains", "equals", "notEqual"]
  //     }
  //   };
  // }

  // /**
  //  * Returns object that represents a column definition and is initially hidden.
  //  * @param headerName
  //  * @param field
  //  */
  // private getInitiallyHiddenColumnDefinition(headerName: string, field: string): ColDef {
  //   return Object.assign(this.getColumnDefinition(headerName, field), {
  //     hide: true
  //   });
  // }


  // /**
  //  * Returns a object that represents a column definition where cellstyle is {'white-space': 'normal !important'} and autoheight is true.
  //  * @param headerName
  //  * @param field
  //  */
  // private getColumnDefinitionWithStyleAndAutoHeightEnabled(headerName: string, field: string): ColDef {
  //   return Object.assign(this.getColumnDefinition(headerName, field), {
  //     cellStyle: {'white-space': 'normal !important'},
  //     autoHeight: true
  //   });
  // }

  // /**
  //  * Returns a object that represents a column definition where date format is 'MM/DD/YYYY HH:mm'.
  //  * @param headerName
  //  * @param field
  //  */
  // private getColumnDefinitionForDateTime(headerName: string, field: string): ColDef {
  //   return Object.assign(this.getColumnDefinition(headerName, field), {
  //     filterParams: {
  //       filterOptions: ["equals", "notEqual"],
  //       comparator: function (filterValue: any, cellValue: any) {
  //         const filterDate = new Date(filterValue);
  //         const cellDate = new Date(cellValue);
  //         if (filterDate.getDate() === cellDate.getDate()
  //           && filterDate.getMonth() === cellDate.getMonth()
  //           && filterDate.getFullYear() === cellDate.getFullYear()) {
  //           return 0;
  //         } else if (filterDate < cellDate) {
  //           return 1;
  //         } else if (filterDate > cellDate) {
  //           return -1;
  //         }
  //       }
  //     },
  //     cellRenderer: (data: any) => {
  //       return moment(data.value).format('MM/DD/YYYY HH:mm');
  //     }
  //   });
  // }

//#endregion CommentedOutCode
