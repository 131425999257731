import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Group } from '@eva-model/group';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notifications-feedback',
  templateUrl: './notifications-feedback.component.html',
  styleUrls: ['./notifications-feedback.component.scss']
})
export class NotificationsFeedbackComponent implements OnInit {

  // user data
  knowledgeGroups: Group[]; // knowledge groups the user is a member of

  // view
  selectedGroup: Group;
  selectedStatus = false;

  @ViewChild(MatSort) sort: MatSort;
  componentTitle: string;

  constructor(private evaGlobalService: EvaGlobalService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    }); }

  ngOnInit() {
    // get all knowledge groups the user is a member of
    const userKnowledgeGroups = this.evaGlobalService.userGroups.filter((group) => {
      return group.groupType === environment.blockConfig.types.groups.types.knowledge;
    });

    // set if there is at least 1 user group, otherwise view will show user does not belong to knowledge groups.
    if (userKnowledgeGroups.length > 0) {
      this.selectedGroup = userKnowledgeGroups[0];
      this.knowledgeGroups = userKnowledgeGroups;
    }
  }

  groupChanged(event: MatSelectChange) {
    this.selectedGroup = event.value;
  }

}
