<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content>
  <div>
        <form [formGroup]="documentForm">
            <!-- (ngSubmit)="addEditDocument()"> -->
            <mat-form-field class="full-width-input">
              <input matInput type="text" formControlName="documentName" placeholder="Document Name" required>
              <mat-error *ngIf="documentForm.get('documentName').hasError('required')">
                Document Name Required
              </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="full-width-input">
              <input matInput type="text" formControlName="category" placeholder="Category" required>
              <mat-error *ngIf="documentForm.get('category').hasError('required')">
                Category Required
              </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="full-width-input">
              <input matInput type="text" formControlName="url" placeholder="Url for document" required>
              <mat-error *ngIf="documentForm.get('url').hasError('required')">
                Url Required
              </mat-error>
              <mat-error *ngIf="documentForm.get('url').hasError('pattern')">
                Enter a valid Url
              </mat-error>
              
            </mat-form-field>
            <br />
          </form>
        </div>
</div>
<div mat-dialog-actions>
  <button (click)="addEditDocument()" mat-raised-button color="primary" type="submit" [disabled]="!documentForm.valid && !addUpdate">{{ dialogData.confirmBtnTxt }}</button>
  <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
</div>