// Data in this file will move to FireStore database later
import { FormControlItem } from '@eva-model/formControlItem';

import {
    DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER,
    DYNAMIC_FORM_CONTROL_TYPE_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_INPUT,
    DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_SELECT,
    DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
    DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
    DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,

    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK
} from '@ng-dynamic-forms/core';
import { SubCntrl } from '@eva-model/interactionSpecialControlBind';

  // Note: name property value is a unique string in between all introduced controls

  //#region Interaction Special Control
  /**
   * interface for the special AddressComplete controls
   */
  export interface InteractionSpecialControl {
    id: string;
    type: string;
    subType: string;
    customType: string;
    name: string;
    controls: SubCntrl[];
  }
  //#endregion

  /**
   * basic interface for a form controls properties
   */
  export interface FrmCntrl {
    type: string;
    inputType: string;
    name: string;
    icon: string;
    multiple?: boolean;
  }

  /**
   * basic interface for a special form controls properties
   */
  export interface FrmCntrlSpclBase {
    type: string;
    subType: string;
    customType: string;
    name: string;
    icon: string;
    multiple?: boolean;
  }
  /**
  *  interface for special controls to create a factory of form controls
  */
  export interface FrmCntrlSpcl extends FrmCntrlSpclBase {
    factory: FrmCntrl[];
  }

  /**
   *  used to Check to see if Special Control type is typeahead for CanadaPost retrievals
   */
  export enum FrmControlSpecialType {
    Typeahead = 'TYPEAHEAD'
  }

  /**
   *  Enumerator to help define which address control is being used
   */
  export enum FrmControlSpecialCustomType {
    CanadaPostFind = 'CP_FIND_ADDRESS',
    CanadaPostRetrieve = 'CP_RETRIEVE_ADDRESS',
    CanadaPostDataListRetrieve = 'CP_RETRIEVE_ADDRESS_DATA_LIST',
    TypeaheadTextList = 'TYPEAHEAD_TEXT_LIST',
    CanadaPostInternationalDataListRetrieve = 'CP_FIND_ADDRESS_INTERNATIONAL'
  }

  /**
   * Enumerator to help define the name of the address control that is being used
   */
  export enum FrmControlSpecialCustomName {
    CanadaPostFind = 'Find Address Complete (Canada Post)',
    CanadaPostRetrieve = 'Retrieve Address Complete (Canada Post)',
    CanadaPostDataListRetrieve = 'Retrieve Address Data List (Canada Post)',
    TypeaheadTextList = 'Typeahead Input Text List',
    CanadaPostInternationalDataListRetrieve = 'International Retrieve Address Data List (Canada Post)'
  }

  /** canada post data API enumerator to determine if AddressComplete will be returning
   * data to fill either one element (find) or several elements (retrieve) with the address data
  */
  export enum CpApiType {
    Find = 'Find',
    Retrieve = 'Retrieve'
  }

  /**
   *  list of selectable text input form control elements
   */
  export const FRM_CNTRL_INPUT: FrmCntrl[] = [
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Input Text', icon: 'fas fa-terminal'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL}`, name: 'Input Email', icon: 'fas fa-at'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, name: 'Input Number', icon: 'fas fa-sort-numeric-down'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD}`, name: 'Input Password', icon: 'fas fa-key'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH}`, name: 'Input Search', icon: 'fas fa-search'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL}`, name: 'Input Tel', icon: 'fas fa-phone-square'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL}`, name: 'Input Url', icon: 'fas fa-link'}
  ];

/**
 *  list of selectable date time form control elements
 */
  export const FRM_CNTRL_DATETIME: FrmCntrl[] = [
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE}`, name: 'Input Date', icon: 'far fa-calendar-alt'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL}`, name: 'Input Date Local',
     icon: 'far fa-calendar-check'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH}`, name: 'Input Month', icon: 'far fa-calendar'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK}`, name: 'Input Week', icon: 'far fa-calendar-minus'},
    { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME}`, name: 'Input Time', icon: 'far fa-clock'},
  ];

  /**
   *  list of additional form control elements
   */
  export const FRM_CNTRL_ADDITIONAL: FrmCntrl[] = [
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA}`, inputType: '', name: 'Input Text Area', icon: 'fas fa-text-height' },
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', name: 'Check box', icon: 'fas fa-check-square' },
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: '', name: 'List', multiple: false, icon: 'fas fa-list-ul' },
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: 'Multiple', name: 'List Multi Select', multiple: true, icon: 'fas fa-list' },
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP}`, inputType: '', name: 'Radio Group', icon: 'far fa-dot-circle' },
  ];

  /**
   * list of custom form control elements
   */
  export const FRM_CNTRL_CUSTOM: FrmCntrl[] = [
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP}`, inputType: '', name: 'Check box group', icon: 'fas fa-th'},
    { type: 'CHIPS', inputType: '', name: 'Chips', icon: 'fas fa-id-badge' },
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_SLIDER}`, inputType: '', name: 'Slider', icon: 'fas fa-sliders-h'},
    { type: `${DYNAMIC_FORM_CONTROL_TYPE_SWITCH}`, inputType: '', name: 'Slide Toggle', icon: 'fas fa-toggle-on' }
  ];

  /**
   *  This array contains all controls with AddressComplete enabled to autofill addresses using a typeahead function
   */
  export const FRM_CNTRL_SPECIAL: FrmCntrlSpcl[] = [
    {
      type: `SPECIAL`,
      subType: 'TYPEAHEAD',
      customType: FrmControlSpecialCustomType.CanadaPostFind,
      name: FrmControlSpecialCustomName.CanadaPostFind,
      icon: 'fas fa-location-arrow',
      factory: [
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Enter an Address', icon: 'fas fa-terminal' }
      ]
    },
    { // AddressComplete array of controls with an address list, shows canada addresses only
      type: `SPECIAL`,
      subType: 'TYPEAHEAD',
      customType: FrmControlSpecialCustomType.CanadaPostRetrieve,
      name: FrmControlSpecialCustomName.CanadaPostRetrieve,
      icon: 'fas fa-globe',
      factory: [
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Enter an Address', icon: 'fas fa-terminal' },
        { type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: '', name: 'Address List', multiple: false, icon: 'fas fa-list-ul' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Street Address', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Address Line 2', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'City', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Province', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Postal Code', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Country', icon: 'fas fa-terminal' }
      ]
    },
    { // AddressComplete array of controls, shows canada addresses only
      type: `SPECIAL`,
      subType: 'TYPEAHEAD',
      customType: FrmControlSpecialCustomType.CanadaPostDataListRetrieve,
      name: FrmControlSpecialCustomName.CanadaPostDataListRetrieve,
      icon: 'fas fa-compass',
      factory: [
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Enter an Address', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Street Address', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Address Line 2', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'City', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Province', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Postal Code', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Country', icon: 'fas fa-terminal' }
      ]
    },
    { // AddressComplete special control with the ability to filter by country
      type: `SPECIAL`,
      subType: 'TYPEAHEAD',
      customType: FrmControlSpecialCustomType.CanadaPostInternationalDataListRetrieve,
      name: FrmControlSpecialCustomName.CanadaPostInternationalDataListRetrieve,
      icon: 'fas fa-location-arrow',
      factory: [
        { type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: 'intlSelect',
          name: 'Country List', multiple: false, icon: 'fas fa-list-ul'},
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Enter an Address', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Street Address', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Address Line 2', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'City', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Province/State', icon: 'fas fa-terminal' },
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Postal Code/Zip Code', icon: 'fas fa-terminal' }
      ]
    },
    { // single AddressComplete address control with typeahead feature enabled
      type: `SPECIAL`,
      subType: 'TYPEAHEAD',
      customType: FrmControlSpecialCustomType.TypeaheadTextList,
      name: 'Typeahead Input Text List',
      icon: 'fas fa-keyboard',
      factory: [
        { type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, name: 'Input Typeahead', icon: 'fas fa-terminal' }
      ]
    }

  ];

  //#endregion
