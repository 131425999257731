import { Params } from "@angular/router";

/*****************
 * If these interfaces are updated, please also update the project:
 * EVA-FirebaseFunctions
 *****************/

/**
 * Base shape for any type of URL data being passed as an id through a URL
 */
export interface UrlShortenerData<T> {
    createdAt: number;
    type: UrlDataType;
    data: T;
}

/**
 * Shape of data passed to the create short URL function
 */
export interface UrlShortenerRequest {
    type: UrlDataType;
    data: UrlShortenerData<any>;
}

/**
 * Response from API when creating a url
 */
export interface UrlShortenerResponse {
    success: boolean;
    id: string;
    message: string;
}

export enum UrlDataType {
    KNOWLEDGE = 'KNOWLEDGE'
}

/**
 * Object we store with the last shortlink used in the current session of the app.
 * Contains all data that was in the url string and what was retrieved from the database.
 */
export interface UrlShortlinkData<T> {
    id: string; // shortlink database doc id
    type: UrlDataType; // type of data, correlates to the shape of data
    data: T; // url data in the shape of a defined `UrlDataType` -- important!
    queryParams: Params; // can be set to null of no query params
}
