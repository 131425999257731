import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InteractionElementRelationService {

  // Observable any sources
  private elementRelationChangeSource = new Subject<any>();
  private elementRelationDialogChangeSource = new Subject<any>();

  // #region Observable any streams
  elementRelationChanged$ = this.elementRelationChangeSource.asObservable();
  elementRelationDialogChanged$ = this.elementRelationDialogChangeSource.asObservable();
  // #endregion

  constructor() { }

  // #region Service message commands

  /**
   * Gets the elementRelationChangeSource to emit the change.
   *
   * @param change the changed object
   */
  announceElementRelationChange(change: any) {
    this.elementRelationChangeSource.next(change);
  }

  /**
   * Gets the elementRelationDialogChangeSource to emit the update.
   *
   * @param update the updated object
   */
  announceElementRelationDialogChange(update: any) {
    this.elementRelationDialogChangeSource.next(update);
  }

  // #endregion

}
