import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WorkflowStoreObject } from '@eva-model/chat/process/chatProcess';
import { EvaChatWorkflowsService } from '@eva-services/eva-chat-workflow/eva-chat-workflows-service.service';

@Component({
  selector: 'app-confirm-workflow-dialog',
  templateUrl: './confirm-workflow-dialog.component.html',
  styleUrls: ['./confirm-workflow-dialog.component.scss']
})
export class ConfirmWorkflowDialogComponent implements OnInit {

  workflowData$: Observable<WorkflowStoreObject>;
  private workflowData: WorkflowStoreObject; // used when pressing continue, we pass the workflow to a service to announce it.

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmWorkflowDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public workflowName: any
    @Inject(MAT_DIALOG_DATA) private workflowModalData: {launchWorkflowId: string},
    private chatProcessService: ChatProcessService,
    private evaChatWorkflowService: EvaChatWorkflowsService
  ) { }

  ngOnInit() {
    // this.workflowSubscription = this.chatProcessService.workflowStore$.pipe(
    //   filter(store => !!(store[this.workflowData.launchWorkflowId])),
    //   map(store => store[this.workflowData.launchWorkflowId])
    // ).subscribe(workflowObj => this.workflow = workflowObj);
    this.chatProcessService.fetchWorkflow(this.workflowModalData.launchWorkflowId);

    this.workflowData$ = this.chatProcessService.workflowStore$.pipe(
      filter(store => !!(store[this.workflowModalData.launchWorkflowId])),
      map(store => store[this.workflowModalData.launchWorkflowId]),
      // save our workflow object internally if the user wants to continue.
      tap(workflowObj => this.workflowData = workflowObj)
    );

    // if (this.workflowName.workflowName) {
    //   this.dialogMessage = 'Opening a new workflow. Are you sure you want to continue?';
    //   this.showContinue = true;
    // } else {
    //   this.workflowName.workflowName = 'Invalid ID';
    //   this.dialogMessage = 'No workflow with this ID can be found';
    //   this.showContinue = false;
    // }
  }

  cancel() {
    this.dialogRef.close();
  }
  continue() {
    this.evaChatWorkflowService.sendData(this.workflowData.workflow);
    this.dialogRef.close(true);
  }

}
