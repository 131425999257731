<mat-card #animateZoom class="question-card">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill class="flex-row-content">
    <div class="prompt-text">
      <mat-icon>feedback</mat-icon>
      <span>{{text}}</span><span *ngIf="selectingSections && (sectionsSelected$ | async).length">&nbsp;({{(sectionsSelected$ | async).length}} selected)</span>
    </div>
    <div class="flex-row-buttons">
      <ng-container *ngIf="showButtons">
        <button mat-raised-button class="answer-button" (click)="yes()">Yes</button>
        <button mat-raised-button class="answer-button" (click)="no()">No</button>
      </ng-container>
      <ng-container *ngIf="selectingSections && (sectionsSelected$ | async) as selected">
        <button mat-raised-button class="answer-button" (click)="submitFeedback()" [disabled]="selected.length < 1">Submit Feedback</button>
      </ng-container>
      <button mat-icon-button matTooltip="Dismiss" (click)="dismissComponent()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="documentChoices.length && knowledgeSearchQuery === ''" class="document-card">
  <app-knowledge-section-search (searchQueryChanged)="searchQueryChanged($event)" (resultClicked)="searchResultClicked($event)"></app-knowledge-section-search>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill *ngFor="let columns of documentChoices | chunk:2" class="document-choice-row" [@listAnimation]="documentChoices.length">
    <div *ngFor="let doc of columns" (click)="goToDocument(doc)" fxFlex="50%">
      {{ doc.documentName }}
    </div>
  </div>
</mat-card>
