<div class="app-loading" *ngIf="isLoading">
  <mat-spinner diameter=100></mat-spinner>
</div>

<!-- Retrieved document -->
<div *ngIf="!isLoading && knowledgeDoc">

    <!-- Component View (viewing by passing document data as input) -->
    <div class="document-component-header-container" [attr.id]="'document-header-'+id" *ngIf="isComponentView">
      <div class="document-component-header">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="flexbox-container">
          <div class="document-meta">
            <h4 class="document-name">{{knowledgeDoc.name}} <span class="draft-indicator" *ngIf="knowledgeDoc.draft">DRAFT</span></h4>
            <p class="document-group-name">
              Last edited {{knowledgeDoc.updatedDate | date:'MMM d, yyyy'}}<ng-container *ngIf="updatedBy"> by {{updatedBy}}</ng-container>
            </p>
          </div>
          <div class="document-actions">
            <ng-container *ngIf="knowledgeDoc.groupPublicKey | isUserInGroup | async">
              <button mat-icon-button color="primary" matTooltip="Edit Document" (click)="editDocument(knowledgeDoc.name)" aria-label="Button navigates user to the edit document page">
                <mat-icon>edit</mat-icon>
              </button>
            </ng-container>
            <button mat-icon-button color="primary" [matMenuTriggerFor]="tableOfContentsMenu" matTooltip="Show Table of Contents" aria-label="Button opens table of contents for document page">
              <mat-icon>list_alt</mat-icon>
            </button>
            <button mat-icon-button color="primary" [matMenuTriggerFor]="knowledgeDocumentMenu" matTooltip="More Document Options" aria-label="Button opens up more knowledge document options">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #knowledgeDocumentMenu="matMenu">
              <button mat-menu-item color="primary" (click)="getDocumentLink()" aria-label="Button copies document link to clipboard">
                <mat-icon color="primary">link</mat-icon>
                <span>Copy Link to Document</span>
              </button>
              <button mat-menu-item color="primary" (click)="pinToLaunchPad()" aria-label="Button adds this knowledge document to launch pad">
                <mat-icon color="primary">push_pin</mat-icon>
                <span>Add to Launchpad</span>
              </button>
              <button mat-menu-item color="primary" ngxPrint printSectionId="document-body-{{knowledgeDoc.id}}" printTitle="{{knowledgeDoc.name}} - Revision: {{knowledgeDoc.updatedDate | date:'long'}}" [useExistingCss]="true" aria-label="Button opens print dialog">
                <mat-icon color="primary">print</mat-icon>
                <span>Print Document</span>
              </button>
              <button mat-menu-item color="primary" [matMenuTriggerFor]="revisionsMenu" aria-label="Button shows menu of previous revisions">
                <mat-icon color="primary">update</mat-icon>
                <span>Show Previous Revisions</span>
              </button>
              <button mat-menu-item aria-label="Button copies document link to clipboard" (click)="knowledgeCompare()">
                <mat-icon color="primary">compare</mat-icon>
                <span>Compare Revisions</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div *ngIf="promptForFeedback && !hideFeedbackPrompt && dialogflowResponse" id="knowledge_feedback_prompt">
        <app-knowledge-feedback-prompt 
          [documentId]="id" 
          [groupPublicKey]="groupPk"
          [modelVersion]="modelVersion"
          [dialogflowResponse]="dialogflowResponse"
          (enableSectionSelection)="enableSectionSelection()" 
          (disableSectionSelection)="disableSectionSelection()" 
          (destroyComponent)="destroyFeedbackPrompt()"></app-knowledge-feedback-prompt>
      </div>
    </div>

    <!-- Page View (knowledge/view/...)-->
    <div class="document-header" appStickyElement *ngIf="!isComponentView" [attr.id]="'document-header-'+id">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="flexbox-container">
        <div class="document-meta">
          <h4 class="document-name">{{knowledgeDoc.name}} <span class="draft-indicator" *ngIf="knowledgeDoc.draft">DRAFT</span></h4>
          <!-- For printing purposes only -->
          <p class="document-print-revision-date">Revision Date: {{knowledgeDoc.updatedDate | date:'long'}}</p>
          <p class="document-group-name">
            Last edited {{knowledgeDoc.updatedDate | date:'MMM d, yyyy'}}<ng-container *ngIf="updatedBy"> by {{updatedBy}}</ng-container>
          </p>
        </div>
        <div class="document-actions">
          <ng-container *ngIf="knowledgeDoc.groupPublicKey | isUserInGroup | async">
            <button mat-icon-button color="primary" matTooltip="Edit Document" (click)="editDocument(knowledgeDoc.name)" aria-label="Button navigates user to the edit document page">
              <mat-icon>edit</mat-icon>
            </button>
          </ng-container>
          <button mat-icon-button color="primary" [matMenuTriggerFor]="tableOfContentsMenu" matTooltip="Show Table of Contents" aria-label="Button opens table of contents for document page">
            <mat-icon>list_alt</mat-icon>
          </button>
          <button mat-icon-button color="primary" [matMenuTriggerFor]="knowledgeDocumentMenu" matTooltip="More Document Options" aria-label="Button opens up more knowledge document options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #knowledgeDocumentMenu="matMenu">
            <button mat-menu-icon color="primary" matTooltip="Copy Link to Document" (click)="getDocumentLink()" aria-label="Button copies document link to clipboard">
              <mat-icon>link</mat-icon>
              <span>Copy Link to Document</span>
            </button>
            <button mat-menu-icon color="primary" (click)="pinToLaunchPad()" matTooltip="Add to Launchpad" aria-label="Button adds this knowledge document to launch pad">
              <mat-icon>push_pin</mat-icon>
              <span>Add to Launchpad</span>
            </button>
            <button mat-menu-icon color="primary" ngxPrint matTooltip="Print Document" printSectionId="document-body-{{knowledgeDoc.id}}" printTitle="{{knowledgeDoc.name}} - Revision: {{knowledgeDoc.updatedDate | date:'long'}}" [useExistingCss]="true" aria-label="Button opens print dialog">
              <mat-icon>print</mat-icon>
              <span>Print Document</span>
            </button>
            <button mat-menu-icon color="primary" [matMenuTriggerFor]="revisionsMenu" matTooltip="Show Previous Revisions" aria-label="Button shows menu of previous revisions">
              <mat-icon>update</mat-icon>
              <span>Show Previous Revisions</span>
            </button>
            <button mat-menu-icon matTooltip="Compare Revisions" aria-label="Button copies document link to clipboard">
              <mat-icon>compare</mat-icon>
              <span>Compare Revisions</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <mat-menu #tableOfContentsMenu="matMenu" class="knowledge-menu-table-of-contents">
      <ng-template matMenuContent>
        <mat-toolbar>
          <span>Table of Contents</span>
          <span style="flex: 1 1 auto"></span>
          <button mat-icon-button aria-label="close" matTooltip="Close">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
        <app-knowledge-table-of-contents [toc]="tableOfContents"></app-knowledge-table-of-contents>
      </ng-template>
    </mat-menu>

    <mat-menu #revisionsMenu="matMenu" class="knowledge-menu-revisions">
      <ng-template matMenuContent>
        <mat-toolbar>
          <span>Revisions</span>
          <span style="flex: 1 1 auto"></span>
          <button mat-icon-button aria-label="close" matTooltip="Close">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
        <app-knowledge-revisions-view [id]="id" [groupPublicKey]="groupPk" [currentVersion]="version" (versionSelected)="switchVersion($event)"></app-knowledge-revisions-view>
      </ng-template>
    </mat-menu>

    <div [attr.id]="'document-body-'+id" class="document-body fr-view" [class.component-view]="isComponentView">
      <div fxLayout="row" fxFill>
        <div fxFlex="!isComponentView ? '80' : '100'">
          <!-- /start print-only elements -->
          <h3 class="knowledge-print-show">{{knowledgeDoc.name}}</h3>
          <h5 class="knowledge-print-show">Revision Date: {{knowledgeDoc.updatedDate|date:'long'}}</h5>
          <!-- /end print-only elements -->

          <!-- Page View (knowledge/view/...)-->
          <mat-card *ngIf="!isComponentView; else documentComponentView">
            <app-knowledge-section #knowledgeSection
              class="app-knowledge-section"
              *ngFor="let section of allSections"
              [sectionData]="section"
              [docId]="id"
              (copyLink)="getDocumentLink(section.id)"
              (feedbackSelected)="trackSelectedSection(knowledgeSection)"
            ></app-knowledge-section>
          </mat-card>
          <!-- Component View (viewing by passing document data as input) -->
          <ng-template #documentComponentView>
            <app-knowledge-section #knowledgeSection
              *ngFor="let section of allSections"
              [sectionData]="section"
              [docId]="id"
              (copyLink)="getDocumentLink(section.id)"
              (feedbackSelected)="trackSelectedSection(knowledgeSection)"
            ></app-knowledge-section>
          </ng-template>
        </div>
        <div *ngIf="!isComponentView" fxFlex="20">
          <app-knowledge-table-of-contents></app-knowledge-table-of-contents>
        </div>
      </div>
    </div>

</div>

<!-- Error retrieving document -->
<div *ngIf="!isLoading && !knowledgeDoc" class="knowledge-failed">
  <!-- <div class="document-component-header-container" *ngIf="isComponentView">
    <div class="document-component-header">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="flexbox-container">
        <div class="document-meta">
          <h4 class="document-name">&nbsp;</h4>
          <p class="document-updated-at">&nbsp;</p>
        </div>
        <div class="document-actions">
          <button mat-icon-button (click)="closeComponentView()" matTooltip="Close" aria-label="Button closes the document pane">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div fxLayout="row" fxLayoutAlign="start center" class="failed-message">
    <div fxFlex>
      <mat-icon>error</mat-icon>
    </div>
    <div fxFlex="100%">
      <p>Failed to get document. Please try again.</p>
      <p>If it still doesn't work, the document may not exist or has been archived.</p>
    </div>
  </div>
</div>
