<div class="workflow-builder">
  <div class="app-loading height-limit" *ngIf="isWaiting">
    <mat-spinner></mat-spinner>
    <ng-content></ng-content>
    <h4 class="wait-message">{{ waitMessage }}</h4>
  </div>

  <div [class.header-wrapper]="!isWaiting" [class.header-wrapper-hidden]="isWaiting">
    <app-page-header [title]="componentTitle" subTitle="The Workflow builder enables you to build workflows like forms into EVA. As these workflows are
    built and used, more and more processes at ATB will be automated. Below are the workflows that you have access to."></app-page-header>
  </div>
  <!-- If there are no interactions for user -->
  <!-- <mat-card [class.header-wrapper-hidden]="isWaiting" class="workflows-card-nodata" *ngIf="page === PageEnum.MyWorkflowsEmpty">
    <mat-card-content class="inner-card-content">
      <div class="workflow-card-image" fxFlex="30%">
        <img src="../../../assets/images/Unique-Protection.png" alt="Workflow-Card-Image">
      </div>
      <div class="workflow-card-content" fxFlex="55%">
        <h3>My Workflows</h3>
        <p>The Workflow Builder enables you to build workflows like forms into EVA.
          As these forms are built and used, more and more processes at ATB will be automated within EVA.
          <br /><br />
          You don’t have any workflows set up yet. Click the button below to get started.
          <br /><br />
        </p>
        <button mat-raised-button (click)="showCreateNewWorkflow()" class="white-bg-blue-border-btn">Create new
          workflow</button>
      </div>
      <div fxFlex="15%"></div>
    </mat-card-content>
  </mat-card> -->

  <div *ngIf="currentlyLoading" class="waiting-spinner">
    <mat-spinner diameter=50></mat-spinner>
    <p>{{ onWaitForWorkflowMessage }}</p>
  </div>
  <div *ngIf="searchAccess">
    You do not have permission to access this resource.
  </div>


  <!-- List of interactions if exists for the user -->
  <div class="workflows-card" [class.header-wrapper-hidden]="isWaiting" *ngIf="!currentlyLoading && !searchAccess && page === PageEnum.MyWorkflows">

    <div class="inner-card-content">
      <div class="create-workflow-div">
        <button mat-raised-button color="primary" (click)="showCreateNewWorkflow()">Create new
          workflow</button>
          <div class="import-container">
            <app-workflow-import-export
              buttonType="Upload"
              [(isWaitingForGroupInteractions)]="isWaitingForGroupInteractions"
              [submitWorkFlowToBlock]="submitWorkFlowToBlock"
              [workflowBuilderComponent]="this"
              [workflowSubmittedToBlockSubscription]="workflowSubmittedToBlockSubscription"
              [openGeneralDialog]="openGeneralDialog"
              [getInteractionsByUserGroups]="getInteractionsByUserGroups"
              [generalDialogChangeSubscription]="generalDialogChangeSubscription"
              [interactionsByGroups]="interactionsByGroups"
              [workflow]="workflow"
              [(isWaiting)]="isWaiting"
              [(waitMessage)]="waitMessage"
              [(waitForGroupInteractionsMessage)]="waitForGroupInteractionsMessage"></app-workflow-import-export>
          </div>
        <!-- <button *ngIf="!loadWorkflow" mat-raised-button (click)="loadWorkflows()"
          class="white-bg-blue-border-btn">
          Load my workflows</button> -->
      </div>
    <!-- <div class="inner-card-content">
      <div class="create-workflow-div">
        <button mat-raised-button (click)="showCreateNewWorkflow()" class="white-bg-blue-border-btn">Create new
          workflow</button>
          <div class="import-container">
            <app-workflow-import-export
              buttonType="Upload"
              [(isWaitingForGroupInteractions)]="isWaitingForGroupInteractions"
              [submitWorkFlowToBlock]="submitWorkFlowToBlock"
              [workflowBuilderComponent]="this"
              [workflowSubmittedToBlockSubscription]="workflowSubmittedToBlockSubscription"
              [openGeneralDialog]="openGeneralDialog"
              [getInteractionsByUserGroups]="getInteractionsByUserGroups"
              [generalDialogChangeSubscription]="generalDialogChangeSubscription"
              [interactionsByGroups]="interactionsByGroups"
              [workflow]="workflow"
              [(isWaiting)]="isWaiting"
              [(waitMessage)]="waitMessage"
              [(waitForGroupInteractionsMessage)]="waitForGroupInteractionsMessage"></app-workflow-import-export>
          </div>
        <button *ngIf="!loadWorkflow" mat-raised-button (click)="loadWorkflows()"
          class="white-bg-blue-border-btn">
          Load my workflows</button>
      </div>

      <div *ngIf="!loadWorkflow" class="padding-div"></div>
      <div *ngIf="loadWorkflow">
        <div *ngIf="isWaitingForGroupWorkflows" class="waiting-spinner">
          <mat-spinner diameter=50></mat-spinner>
          <p class="padding-top">{{ onWaitForWorkflowMessage }}</p>
        </div>

        <div class="workflows-table" *ngIf="!isWaitingForGroupWorkflows">
            <h3 class="component-title">My Workflows</h3>
          <mat-form-field class="filter-field">
            <input [value]="workflowsData.filter" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <table mat-table [dataSource]="workflowsData" matSort class="fullWidth">

            <ng-container matColumnDef="workflow">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Workflows </th>
              <td mat-cell *matCellDef="let element" class="workflow-name">
                <span (click)="startEdit(element)" class="cursor-pointer">{{element.name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="group">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
              <td mat-cell *matCellDef="let element"> {{element.group}} </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last modified </th>
              <td mat-cell *matCellDef="let element">
                <mat-select placeholder="Select a version" [value]="element.selectedVersion" class="version-dropdown"
                  (selectionChange)="setSelectedVersion($event, element)">
                  <mat-option *ngFor="let version of element.Versions" [value]="version.version.toString()">
                    <i *ngIf="element.activeVersion === version.version.toString()"
                      class="fas fa-bolt">&nbsp;::Active::&nbsp;</i>
                    {{version.version | date:'full'}}
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>

              <td mat-cell class="action-buttons" *matCellDef="let row; let i=index;">
                <button mat-icon-button (click)="startEdit(row)">
                  <mat-icon aria-label="Edit">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="onActiveVersion(row)"
                  [disabled]="row.selectedVersion === row.activeVersion">
                  <mat-icon aria-label="Activate" [class.enabledAction]="row.selectedVersion !== row.activeVersion"
                    [class.disabledAction]="row.selectedVersion === row.activeVersion">flash_on</mat-icon>
                </button>
                <app-workflow-import-export
                  buttonType="Download"
                  [(isWaitingForGroupInteractions)]="isWaitingForGroupInteractions"
                  [submitWorkFlowToBlock]="submitWorkFlowToBlock"
                  [workflowBuilderComponent]="this"
                  [workflowSubmittedToBlockSubscription]="workflowSubmittedToBlockSubscription"
                  [openGeneralDialog]="openGeneralDialog"
                  [getInteractionsByUserGroups]="getInteractionsByUserGroups"
                  [generalDialogChangeSubscription]="generalDialogChangeSubscription"
                  [workflow]="row"
                  [(isWaiting)]="isWaiting"
                  [(waitMessage)]="waitMessage"
                  [(waitForGroupInteractionsMessage)]="waitForGroupInteractionsMessage"></app-workflow-import-export>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator #paginator [length]="workflowsData.filteredData.length" [pageIndex]="0" [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </div>
      </div>
    </div> -->
  
      <ng-template #actionTemplate let-hit="hit">
        <button mat-icon-button matTooltip="Edit" (click)="startEdit(hit)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        <button mat-icon-button (click)="onActiveVersion(hit)" matTooltip="Activate"
            [disabled]="hit['version'] === hit['activeVersion']">
            <mat-icon aria-label="Activate"
                [class.enabledAction]="hit['version'] !== hit['activeVersion']"
                [class.disabledAction]="hit['version'] === hit['activeVersion']">flash_on</mat-icon>
        </button>
        <app-workflow-import-export
          matTooltip="Download"
          buttonType="Download"
          [(isWaitingForGroupInteractions)]="isWaitingForGroupInteractions"
          [submitWorkFlowToBlock]="submitWorkFlowToBlock"
          [workflowBuilderComponent]="this"
          [workflowSubmittedToBlockSubscription]="workflowSubmittedToBlockSubscription"
          [openGeneralDialog]="openGeneralDialog"
          [getInteractionsByUserGroups]="getInteractionsByUserGroups"
          [generalDialogChangeSubscription]="generalDialogChangeSubscription"
          [workflow]="hit"
          [(isWaiting)]="isWaiting"
          [(waitMessage)]="waitMessage"
          [(waitForGroupInteractionsMessage)]="waitForGroupInteractionsMessage"
        ></app-workflow-import-export>
      </ng-template>
      <app-generic-search
        [actionTemplate]="actionTemplate"
        [searchFilters]="workflowFilters"
        [indexName]="algoliaIndex"
        [tokenType]="algoliaTokenType"
        searchResultTitleProperty="name"
        searchResultSubTitleProperty="id"
        [propertyNameMapping]="workflowPropertyNameMapping"
        [rowHeight]="rowHeight"
        [columnDefs]="columnDefs"
        [agGridContext]="context"
        [defaultColumnDef]="defaultColDef"
      ></app-generic-search>

    </div>
  </div>

  <!-- <ng-template #interactionActionTemplate let-hit="hit">
    <button mat-icon-button (click)="addInteraction(hit, hit.version)">
      <mat-icon>add</mat-icon>
    </button>
  </ng-template> -->

  <ng-template #sendToInteractionTemplate let-hit="hit" let-additionalActionTemplateData="additionalActionTemplateData">
    <button *ngIf="interactionConditions[additionalActionTemplateData.conditionIndex].selectedTrueInteractionVersion !== hit.version" mat-icon-button (click)="onInteractionCheckboxClick(hit, hit.version, additionalActionTemplateData.conditionIndex, additionalActionTemplateData.conditionType)">
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="interactionConditions[additionalActionTemplateData.conditionIndex].selectedTrueInteractionVersion === hit.version" mat-icon-button (click)="onInteractionCheckboxClick(hit, hit.version, additionalActionTemplateData.conditionIndex, additionalActionTemplateData.conditionType)">
      <mat-icon>remove</mat-icon>
    </button>
    <button *ngIf="interactionConditions[additionalActionTemplateData.conditionIndex].selectedTrueInteractionVersion === hit.version" mat-icon-button (click)="onInteractionCheckboxClick(hit, hit.version, additionalActionTemplateData.conditionIndex, additionalActionTemplateData.conditionType)">
      <i class="fas fa-minus"></i>
    </button>
  </ng-template>

  <!-- Form Builder for Group & Interaction -->
  <div class="workflow-creation-card" *ngIf="page === PageEnum.CreateNewWorkflow" [class.header-wrapper-hidden]="isWaiting">
    <form [formGroup]="formBuilderGroup">
          <h3 class="workflow-group-container">
            Select a workflow group
            <mat-icon class="info-icon workflow-group-icon" matTooltip="Select from one of your groups or create a new one"
            matTooltipPosition="after">info_outline</mat-icon>
          </h3>
      <div class="workflow-creation-card-content">
        <p>Select or create a group of team members that will be involved with the workflow.</p>
        <div class="group-row">
          <mat-form-field class="group-selection" *ngIf="!isWaitingForGroups">
            <mat-select placeholder="Select group" (selectionChange)="onGroupSelectionChange($event)"
              formControlName="userGroup">
              <mat-option *ngFor="let userGroup of evaGlobalService.userGroups" [value]="userGroup.groupPublicKey">
                {{ userGroup.groupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="app-loading" *ngIf="isWaitingForGroups">
            <mat-spinner [diameter]=30 [strokeWidth]=5></mat-spinner>
            <ng-content></ng-content>
            <span class="verColor">
              <h6>{{ onWaitForGroupsMessage }}</h6>
            </span>
          </div>
          <div class="or">OR</div>
          <div class="create-group-link"><a (click)="openNewGroupDialog()">Create a new group</a></div>
          <div class="group-spacer"></div>
        </div>
      </div>

      <div [class.disabled]="workflow.groupPublicKey === null">
        <h3 class="workflow-group-container">
          Build/edit workflow
          <mat-icon class="info-icon workflow-group-icon" matTooltip="When we say workflow, we mean combination of interactions.
                      Existing ATB processes that require a form can be built into EVA. Over time,
                      EVA will learn to ask you questions, saving you the time of filling out all of these forms."
            matTooltipPosition="after">
            info_outline
          </mat-icon>
        </h3>
        <div class="workflow-creation-card-content">
          <div class="workflow-name-row">
            <mat-form-field class="workflow-name-input">
              <input matInput required class="fullWidth" placeholder="Workflow name"
                (keyup)="onWorkflowNameChange($event)" formControlName="name">
            </mat-form-field>
            <div fxFlex="2%"></div>
            <mat-form-field class="workflow-name-input" fxFlex="18%">
              <input matInput required class="fullWidth" placeholder="Workflow Sub-group"
                (keyup)="onWorkflowSubGroupChange($event)" formControlName="subGroup">
            </mat-form-field>
            <div fxFlex="2%"></div>
            <mat-form-field class="fullWidth" fxFlex="73%">
              <mat-chip-list #descriptionList required>
                <mat-chip *ngFor="let desc of workflow.descriptions; let descIndex = index;" [selectable]="true"
                  [removable]="true" (removed)="removeWorkflowDescriptionConfirm(desc)" id={{desc}}{{descIndex}}>
                  {{desc}}
                  <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Workflow keyword(s)" [matChipInputFor]="descriptionList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addWorkflowDescription($event)"/>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="workflow-designer" [class.disabled]='workflow.groupPublicKey === null ? false : workflow.name === ""'>
            <div class="workflow-screen-config-row">
              <div fxFlex></div>
              <div class="screen-config-options">
                <!-- <a (click)="testWorkflow()">Test workflow</a>
                <span class="vertical-left-border"></span> -->
                <a class="screen-config-option" (click)="dynamicWorkflowViewer()"
                  [class.disabled]="workflowInteractionConditionViewer">
                  {{ showDynamicWorkflowViewer ? "Hide dynamic workflow viewer" : "Dynamic workflow viewer"}}
                </a>
                <span class="vertical-left-border"></span>
                <mat-slide-toggle class="customToggle" [checked]="isWorkflowEncrypted"
                  (change)="toggleWorkflowEncryption($event)">
                  Encryption:
                </mat-slide-toggle>
              </div>
            </div>

            <div class="workflow-builder-container">
                <div class="controlsPanel"
                  *ngIf="!showDynamicWorkflowViewer && !workflowInteractionConditionViewer">
                  <h3>My interactions</h3>
                  <ng-template #interactionActionTemplate let-hit="hit">
                    <div>
                      <button mat-icon-button (click)="addInteraction(hit, hit.version)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </ng-template>
                  <div class="generic-search-container" *ngIf="!currentlyLoading && !searchAccess && page === PageEnum.CreateNewWorkflow">
                    <app-generic-search
                      [actionTemplate]="interactionActionTemplate"
                      [indexName]="interactionsAlgoliaIndex"
                      [tokenType]="interactionsAlgoliaTokenType"
                      searchResultTitleProperty="name"
                      [searchFilters]="interactionFilters"
                      searchResultSubTitleProperty="id"
                      [searchResultPaginationPadding]="1"
                      [showSearchResultPaginationShowFirstButton]="false"
                      [showSearchResultPaginationShowLastButton]="false"
                      [propertyNameMapping]="interactionPropertyNameMapping"
                      [hideViewToggle]="true"
                    ></app-generic-search>
                  </div>
                </div>

                <div class="spacer-container" *ngIf="!showDynamicWorkflowViewer && !workflowInteractionConditionViewer"></div>

                <div class="half-width" fxFlex="64%" *ngIf="!showDynamicWorkflowViewer && !workflowInteractionConditionViewer">
                  <div id="interactionDropZone" class="mat-elevation-z" (drop)="dropHandlerForInteraction($event)"
                    (dragover)="dargOverHandlerForInteraction($event)">
                    <div
                      [class.default-message-text]="!workflow.interactions || (workflow.interactions && workflow.interactions.length === 0)"
                      [class.default-message-text-hide]="workflow.interactions && workflow.interactions.length > 0">
                      <p>Click the add button on the interactions from the left to build your workflow.</p>
                    </div>
                    <ul *ngIf="workflow.interactions.length > 0" class="none-list-style">
                      <li *ngFor="let interact of workflow.interactions; let interactionIndex = index;" draggable="true"
                        (dragstart)="onDragControl($event, interactionIndex)"
                        (drop)="reorderControl($event, interactionIndex)" (dragover)="allowdrop($event)"
                        class="cursor-all-scroll">
                        <eva-workflow-interaction-placeholder [interactionItem]="jsonStringify(interact)"
                          [interactionIndex]="interactionIndex" [interactionCnt]="workflow.interactions.length"
                          [isWaiting]="interact.isWaiting" [onWaitMessage]="interact.onWaitMessage"
                          (removeIneractionConfirm)="removeInteractionConfirm($event)"
                          (moveUpInteraction)="moveUpInteraction($event)"
                          (moveDownInteraction)="moveDownInteraction($event)" (showInteraction)="showInteraction($event)"
                          (settingInteractionCondition)="setInteractionCondition($event)">
                        </eva-workflow-interaction-placeholder>
                      </li>
                      <br /><br /><br />
                    </ul>
                  </div>
                </div>

                <div class="fullWidth" *ngIf="showDynamicWorkflowViewer">
                  <div class="dynamic-workflow-viewer">
                    <button mat-icon-button class="closeBtn" (click)="dynamicWorkflowViewer()">
                      <mat-icon>close</mat-icon>
                    </button>

                    <div>
                      <h3 class="component-title-with-subtitle">Workflow viewer</h3>
                      <p class="component-subtitle">Dynamic Workflow and Interactions with conditions</p>
                    </div>

                    <div class="graph-container">
                      <div #containerGraph class="container-graph">
                        <app-workflow-graph-visualizer
                          [containerGraph]="containerGraph"
                          [workflow]="workflow"
                          [(interactionsByGroups)]="interactionsByGroups$"
                        ></app-workflow-graph-visualizer>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width-nav-list" *ngIf="workflowInteractionConditionViewer">
                  <div class="dynamic-workflow-viewer">
                    <button mat-icon-button class="closeBtn" (click)="workflowInteractionConditionViewerSwitch()">
                      <mat-icon>close</mat-icon>
                    </button>
                    <h3 class="component-title">{{ workflowInteractionConditionName }} <span class="heading-title">(Condition Dialog)</span>
                    </h3>
                    <a (click)="addCondition()" class="add-condition-button">Add condition &nbsp; <i
                        class="fas fa-plus-circle"></i></a>
                    <div class="viewer-body"
                      *ngIf="interactionConditions !== undefined && interactionConditions.length > 0 && selectedInteractionIndex !== -1">
                      <mat-tab-group *ngIf="interactionConditions.length > 0" class="full-width-nav-list">
                        <mat-tab *ngFor="let condition of interactionConditions; let conditionIndex = index;">
                          <ng-template mat-tab-label>
                            <div (mouseover)="onHoverTabLabel(conditionIndex)"
                              (mouseleave)="onHoverLeaveTabLabel(conditionIndex)" draggable="true"
                              (dragstart)="onDrag($event, conditionIndex)" (drop)="reorderScreens($event, conditionIndex)"
                              (dragover)="allowdrop($event)" class="tab-label-template">
                              <span class="tab-label-text" contenteditable="true"
                                (focusout)="onTabLabelBlur($event, conditionIndex)"
                                (keydown)="onTabLabelChange($event, conditionIndex)">{{ condition.name }}</span>
                              &nbsp;
                              <i (click)="removeConditionConfirm(conditionIndex)"
                                [class.deleteButtonHide]="!interactionConditions[conditionIndex].hovered"
                                [class.deleteButtonShow]="interactionConditions[conditionIndex].hovered"
                                class="far fa-trash-alt"></i>
                            </div>
                          </ng-template>
                          <div class="condition-builder-heading">
                            <h4 class="sub-heading">Interaction Condition Builder</h4> &nbsp; <a class="view-button"
                              (click)="openConditionBuilder(selectedInteractionIndex, conditionIndex)">View</a>
                          </div>
                          <div #conditionSetGraphContainer class="condition-set-graph">
                            <eva-workflow-interaction-condition-viewer
                              [conditionIndex]="conditionIndex"
                              [containerGraph]="conditionSetGraphContainer"
                              [workflowInteractionConditionDefinition]="jsonStringify(interactionConditions[conditionIndex])">
                            </eva-workflow-interaction-condition-viewer>
                          </div>
                          <mat-accordion multi="false">
                            <mat-expansion-panel class="expansion-panel-workflow">
                              <mat-expansion-panel-header class="expansion-header">
                                <mat-panel-title>
                                  <span><i class="fas fa-check-square true-condition-color"></i>
                                    &nbsp; True condition
                                    destination(s)</span>
                                </mat-panel-title>
                                <mat-panel-description class="condition-description">
                                  <span class="version-dropdown">When condition evaluates to True -
                                    <span *ngIf="interactionConditions[conditionIndex].trueDestination
                                    && interactionConditions[conditionIndex].trueDestination.length > 0">
                                      <span
                                        *ngFor="let dest of interactionConditions[conditionIndex].trueDestination; let destIndex = index;"
                                        class="font-weight-bold">
                                        {{ dest.name }}
                                        <span
                                          *ngIf="destIndex < interactionConditions[conditionIndex].trueDestination.length - 1">
                                          ,
                                        </span>
                                      </span>
                                    </span>
                                    <span *ngIf="!interactionConditions[conditionIndex].trueDestination
                                      || (interactionConditions[conditionIndex].trueDestination
                                      && interactionConditions[conditionIndex].trueDestination.length === 0)"
                                      class="font-weight-bold">Not Linked</span>
                                  </span>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <ng-template matExpansionPanelContent>
                                <div class="send-container">
                                  <h5 class="send-to-group">Send to Group</h5>
                                  <mat-form-field>
                                    <mat-label>
                                      <p class="padding-top margin-left-13">Select Group(s)</p>
                                    </mat-label>
                                    <mat-select class="select-group" multiple
                                      [value]="this.interactionConditions[conditionIndex].selectedTrueGroups"
                                      (selectionChange)="groupSelectionChange($event, conditionIndex, true)"
                                      matTooltip="Disables when 'Send to Condition' is selected"
                                      [disabled]="this.interactionConditions[conditionIndex].selectedTrueConditionIndex !== -1">
                                      <mat-option
                                        *ngFor="let userGroup of evaGlobalService.userGroups; let groupIndex = index;"
                                        [value]="userGroup.groupPublicKey">{{ userGroup.groupName }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <form>
                                    <div class="interaction-container">
                                      <h5 class="send-to-interaction">Send to Interaction</h5>
                                      <!-- <div class="filter-by">
                                        <span>Filter by:</span> &nbsp; <mat-form-field>
                                          <input matInput (keyup)="onSearch($event)" [value]="searchText"
                                            placeholder="Enter search term">
                                          <mat-icon matSuffix class="blue-search-icon">search</mat-icon>
                                        </mat-form-field>
                                      </div> -->
                                    </div>
                                  </form>
                                  <div class="generic-search-container-condition-builder" *ngIf="!currentlyLoading && !searchAccess && page === PageEnum.CreateNewWorkflow">
                                    <app-generic-search
                                      [actionTemplate]="sendToInteractionTemplate"
                                      [indexName]="interactionsAlgoliaIndex"
                                      [tokenType]="interactionsAlgoliaTokenType"
                                      searchResultTitleProperty="name"
                                      [searchFilters]="interactionFilters"
                                      searchResultSubTitleProperty="id"
                                      [searchResultPaginationPadding]="1"
                                      [showSearchResultPaginationShowFirstButton]="false"
                                      [showSearchResultPaginationShowLastButton]="false"
                                      [propertyNameMapping]="interactionPropertyNameMapping"
                                      [additionalActionTemplateData]="{conditionIndex: conditionIndex, conditionType: true}"
                                      [hideViewToggle]="true"
                                    ></app-generic-search>
                                  </div>
                                  <!-- <mat-accordion multi="false">
                                    <mat-expansion-panel class="expansion-panel-workflow"
                                      *ngFor="let userGroup of evaGlobalService.userGroups; let i = index;">
                                      <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'"
                                        *ngIf='this.interactionsByGroups && this.interactionsByGroups[userGroup.groupPublicKey] && this.interactionsByGroups[userGroup.groupPublicKey].length > 0'>
                                        <mat-panel-title class="group-title">
                                          <i class="far fa-object-group"
                                            class="margin-right"></i>{{ userGroup.groupName }}
                                          <span
                                            class="interaction-count-destination">({{ this.interactionsByGroups[userGroup.groupPublicKey].length }})</span>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <mat-accordion multi="false" class="full-width-nav-list">
                                          <mat-expansion-panel class="box-shadow-none"
                                            *ngFor="let interact of this.interactionsByGroups[userGroup.groupPublicKey]; let i = index;">
                                            <mat-expansion-panel-header [collapsedHeight]="'35px'"
                                              [expandedHeight]="'40px'">
                                              <mat-panel-title>
                                                <i [class]="this.workflowsByGroups
                                                          && this.workflowsByGroups[userGroup.groupPublicKey]
                                                          && this.workflowsByGroups[userGroup.groupPublicKey].includes(interact)
                                                            ? 'fas fa-exchange-alt' : 'far fa-file'"
                                                  class="margin-right"></i><span
                                                  class="margin-left">{{ interact.name }}</span>
                                              </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ng-template matExpansionPanelContent>
                                              <div class="interaction-version-container">
                                                <mat-checkbox
                                                  *ngFor="let intrctVersion of this.getInteractionVersions(interact)"
                                                  class="interaction-version-checkbox" [value]="intrctVersion.version"
                                                  matTooltip="Disables when 'Send to Condition' is selected, or when same interaction is selected in False condition destination(s)"
                                                  [disabled]="this.interactionConditions[conditionIndex].selectedTrueConditionIndex !== -1
                                                          || this.interactionConditions[conditionIndex].selectedFalseInteractionVersion === intrctVersion.version"
                                                  [checked]="this.interactionConditions[conditionIndex].selectedTrueInteractionVersion === intrctVersion.version"
                                                  (change)="onInteractionCheckboxClick($event, interact, intrctVersion, conditionIndex, true)">
                                                  <span
                                                    class="font-weight-normal">&nbsp;&nbsp;{{ intrctVersion.version | date:'MMM d, y @ h:mm:ss a z'}}</span>
                                                  <i class="fas fa-eye show-interaction"
                                                    (click)="showInteraction($event, interact, i, intrctVersion.version)"></i>
                                                </mat-checkbox>
                                              </div>
                                            </ng-template>
                                          </mat-expansion-panel>
                                        </mat-accordion>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion> -->
                                  <div class="send-to-condition-container">
                                    <h5 class="send-to-condition">Send to Condition</h5>
                                  </div>
                                  <div class="condition-container">
                                    <mat-checkbox
                                      *ngFor="let condition of getConditions(condition); let cndtnIndex = index"
                                      [checked]="this.interactionConditions[conditionIndex].selectedTrueConditionIndex === condition.name.split(' ').join('')"
                                      (change)="onConditionCheckboxClick($event, condition, conditionIndex, condition.name.split(' ').join(''), true)"
                                      [value]="condition.id"
                                      matTooltip="Disables when either 'Send to Group' or 'Send to Interaction' is selected, or when same condition is selected in False condition destination(s)"
                                      [disabled]="this.interactionConditions[conditionIndex].selectedTrueInteractionVersion !== -1
                                                  || this.interactionConditions[conditionIndex].selectedTrueGroups.length !== 0
                                                  || this.interactionConditions[conditionIndex].selectedFalseConditionIndex === condition.name.split(' ').join('')">
                                      <i class="fas fa-exchange-alt" class="margin-right"></i><span
                                        class="font-weight-normal">{{ condition.name }}</span>
                                    </mat-checkbox>
                                  </div>
                                </div>
                              </ng-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="expansion-panel-workflow">
                              <mat-expansion-panel-header class="expansion-header">
                                <mat-panel-title>
                                  <span class="false-panel-title">
                                    <div class="false-color-box">
                                      <i class="fas fa-times color-white"></i>
                                    </div>
                                    <span class="margin-left-13">False condition destination(s)</span>
                                  </span>
                                </mat-panel-title>
                                <mat-panel-description class="condition-description">
                                  <span class="version-dropdown">When condition evaluates to False -
                                    <span *ngIf="interactionConditions[conditionIndex].falseDestination
                                        && interactionConditions[conditionIndex].falseDestination.length > 0">
                                      <span
                                        *ngFor="let dest of interactionConditions[conditionIndex].falseDestination; let destIndex = index;"
                                        class="font-weight-bold">
                                        {{ dest.name }}
                                        <span
                                          *ngIf="destIndex < interactionConditions[conditionIndex].falseDestination.length - 1">
                                          ,
                                        </span>
                                      </span>
                                    </span>
                                    <span *ngIf="!interactionConditions[conditionIndex].falseDestination
                                        || (interactionConditions[conditionIndex].falseDestination
                                        && interactionConditions[conditionIndex].falseDestination.length === 0)"
                                      class="font-weight-bold">Not Linked</span>
                                  </span>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <ng-template matExpansionPanelContent>
                                <div class="send-container">
                                  <h5 class="send-to-group">Send to Group</h5>
                                  <mat-form-field>
                                    <mat-label>
                                      <p class="padding-top margin-left-13">Select Group(s)</p>
                                    </mat-label>
                                    <mat-select class="select-group" multiple
                                      [value]="this.interactionConditions[conditionIndex].selectedFalseGroups"
                                      (selectionChange)="groupSelectionChange($event, conditionIndex, false)"
                                      matTooltip="Disables when 'Send to Condition' is selected"
                                      [disabled]="this.interactionConditions[conditionIndex].selectedFalseConditionIndex !== -1">
                                      <mat-option
                                        *ngFor="let userGroup of evaGlobalService.userGroups; let groupIndex = index;"
                                        [value]="userGroup.groupPublicKey">{{ userGroup.groupName }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <form>
                                    <div class="interaction-container">
                                      <h5 class="send-to-interaction">Send to Interaction</h5>
                                      <!-- <div class="filter-by">
                                        <span>Filter by:</span> &nbsp; <mat-form-field>
                                          <input matInput (keyup)="onSearch($event)" [value]="searchText"
                                            placeholder="Enter search term">
                                          <mat-icon matSuffix class="blue-search-icon">search</mat-icon>
                                        </mat-form-field>
                                      </div> -->
                                    </div>
                                  </form>
                                  <div class="generic-search-container-condition-builder" *ngIf="!currentlyLoading && !searchAccess && page === PageEnum.CreateNewWorkflow">
                                    <app-generic-search
                                      [actionTemplate]="sendToInteractionTemplate"
                                      [indexName]="interactionsAlgoliaIndex"
                                      [tokenType]="interactionsAlgoliaTokenType"
                                      searchResultTitleProperty="name"
                                      [searchFilters]="interactionFilters"
                                      searchResultSubTitleProperty="id"
                                      [searchResultPaginationPadding]="1"
                                      [showSearchResultPaginationShowFirstButton]="false"
                                      [showSearchResultPaginationShowLastButton]="false"
                                      [propertyNameMapping]="interactionPropertyNameMapping"
                                      [additionalActionTemplateData]="{conditionIndex: conditionIndex, conditionType: false}"
                                      [hideViewToggle]="true"
                                    ></app-generic-search>
                                  </div>
                                  <!-- <mat-accordion multi="false">
                                    <mat-expansion-panel class="expansion-panel-workflow"
                                      *ngFor="let userGroup of evaGlobalService.userGroups; let i = index;">
                                      <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'"
                                        *ngIf='this.interactionsByGroups && this.interactionsByGroups[userGroup.groupPublicKey] && this.interactionsByGroups[userGroup.groupPublicKey].length > 0'>
                                        <mat-panel-title class="group-title">
                                          <i class="far fa-object-group"
                                            class="margin-right"></i>{{ userGroup.groupName }}
                                          <span
                                            class="interaction-count-destination">({{ this.interactionsByGroups[userGroup.groupPublicKey].length }})</span>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <mat-accordion multi="false" class="full-width-nav-list">
                                          <mat-expansion-panel class="box-shadow-none"
                                            *ngFor="let interact of this.interactionsByGroups[userGroup.groupPublicKey]; let i = index;">
                                            <mat-expansion-panel-header [collapsedHeight]="'35px'"
                                              [expandedHeight]="'40px'">
                                              <mat-panel-title>
                                                <i [class]="this.workflowsByGroups
                                              && this.workflowsByGroups[userGroup.groupPublicKey]
                                              && this.workflowsByGroups[userGroup.groupPublicKey].includes(interact)
                                                ? 'fas fa-exchange-alt' : 'far fa-file'" class="margin-right"></i><span
                                                  class="margin-left">{{ interact.name }}</span>
                                              </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ng-template matExpansionPanelContent>
                                              <div class="interaction-version-container">
                                                <mat-checkbox
                                                  *ngFor="let intrctVersion of this.getInteractionVersions(interact)"
                                                  [value]="intrctVersion.version" class="interaction-version-checkbox"
                                                  [checked]="this.interactionConditions[conditionIndex].selectedFalseInteractionVersion === intrctVersion.version"
                                                  (change)="onInteractionCheckboxClick($event, interact, intrctVersion, conditionIndex, false)"
                                                  matTooltip="Disables when 'Send to Condition' is selected, or when same condition is selected in True condition destination(s)"
                                                  [disabled]="this.interactionConditions[conditionIndex].selectedFalseConditionIndex !== -1
                                            || this.interactionConditions[conditionIndex].selectedTrueInteractionVersion === intrctVersion.version">
                                                  <span
                                                    class="font-weight-normal">&nbsp;&nbsp;{{ intrctVersion.version | date:'MMM d, y @ h:mm:ss a z'}}</span>
                                                  <i class="fas fa-eye show-interaction"
                                                    (click)="showInteraction($event, interact, i, intrctVersion.version)"></i>
                                                </mat-checkbox>
                                              </div>
                                            </ng-template>
                                          </mat-expansion-panel>
                                        </mat-accordion>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion> -->
                                  <div class="send-to-condition-container">
                                    <h5 class="send-to-condition">Send to Condition</h5>
                                  </div>
                                  <div class="condition-container">
                                    <mat-checkbox
                                      *ngFor="let condition of getConditions(condition); let cndtnIndex = index"
                                      [checked]="this.interactionConditions[conditionIndex].selectedFalseConditionIndex === condition.name.split(' ').join('')"
                                      (change)="onConditionCheckboxClick($event, condition, conditionIndex, condition.name.split(' ').join(''), false)"
                                      [value]="condition.id"
                                      matTooltip="Disables when either 'Send to Group' or 'Send to Interaction' is selected, or when same condition is selected in True condition destination(s)"
                                      [disabled]="this.interactionConditions[conditionIndex].selectedFalseInteractionVersion !== -1
                                    || this.interactionConditions[conditionIndex].selectedFalseGroups.length !== 0
                                    || this.interactionConditions[conditionIndex].selectedTrueConditionIndex === condition.name.split(' ').join('')">
                                      <i class="fas fa-exchange-alt margin-right"></i><span
                                        class="font-weight-normal">{{ condition.name }}</span>
                                    </mat-checkbox>
                                  </div>
                                </div>
                              </ng-template>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <div>
                            <h5 class="workflow-preview-heading">Workflow Preview</h5>
                            <div #destinationViewerContainer class="destination-viewer-container">
                              <eva-workflow-destination-viewer
                                [containerGraph]="destinationViewerContainer"
                                [workflowInteractionConditionObj]="jsonStringify(interactionConditions[conditionIndex])">
                              </eva-workflow-destination-viewer>
                            </div>
                          </div>
                          <div class="button-container">
                            <button mat-raised-button color="primary" (click)="saveConditionBuilderChanges()" [class.disabled]="false">Save</button>
                            &nbsp;
                            <button mat-stroked-button
                              (click)="workflowInteractionConditionViewerSwitch()">Back</button>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                    <div class="empty-message" *ngIf="interactionConditions.length === 0">
                      <p>Add a new condition to start</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </form>
  </div>
  <div class="builder-actions" *ngIf="page === PageEnum.CreateNewWorkflow && !isWaiting" [class.header-wrapper-hidden]="isWaiting" >
    <div class="button-row-left">
      <button mat-raised-button color="primary" [class.disabled]="isWorkflowNotValid() || isNotEdited() || workflowInteractionConditionViewer"
        [disabled]="isWorkflowNotValid() || isNotEdited() || workflowInteractionConditionViewer"
        (click)="workflowActivationConfirm()">Save Workflow</button>
      <button mat-stroked-button (click)="showWorkflowId()">Workflow ID</button>
    </div>
  </div>
</div>