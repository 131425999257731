<div class="id-map-container">
  <button mat-icon-button mat-dialog-close class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>{{getCorrectTitle()}}</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="form-container">
      <div class="half-width" *ngIf="oldInteractions.length > 0">
        <div class="type-title sticky-header" [class.small-width]="oldInteractions.length > 0">
          <span>
            Old Workflow : {{dialogData.dynFormMdl.oldWorkflow.name}}
            <br/>
            Environment: {{getCurrentEnvironment()}}
          </span>
          <button class="select-all" (click)="selectAllOldInteraction()" [class.disabled]="disableOldSelectAllButton()"
            [class.display-none]="this.paired">Select
            all</button>
        </div>
        <div class="list-items">
          <div *ngFor="let interaction of oldInteractions; let interactionIndex = index;">
            <fieldset class="list-item" [class.selected]="interaction.selected" [class.disabled]="disableOldInteractions(interaction)"
              (click)="selectOldInteraction(interaction) || (this.allNewSelected && !this.allOldSelected)"
              [class.wrong-selected]="false">
              <legend class="list-item-legend">ID: {{interaction.interactionId}}</legend>
              Name: {{interaction.interactionName}} <br />
              Version: {{interaction.interactionVersion | date:'MMM d, y @ h:mm:ss a'}}
            </fieldset>
          </div>
        </div>
      </div>
      <span class="vertical-border" *ngIf="oldInteractions.length > 0"></span>
      <div [class.half-width]="oldInteractions.length > 0" [class.full-width]="oldInteractions.length === 0">
        <div class="type-title sticky-header" [class.small-width]="oldInteractions.length > 0"
          [class.big-width]="oldInteractions.length === 0">
          <span>
            New Workflow : {{dialogData.dynFormMdl.newWorkflow.name}}
            <br/>
            Environment: {{dialogData.dynFormMdl.environment || ''}}
          </span>
          <div class="header-buttons">
            <button class="select-all" (click)="selectAllNewInteraction()" [class.disabled]="disableNewSelectAllButton()"
              [class.display-none]="this.paired">Select
              all</button>
            <button (click)="selectAllAs('New')" [class.display-none]="!this.paired
            || (this.paired && getNewSelectedCount() === newInteractions.length)">New all</button>
            <button (click)="selectAllAs('Map')" [class.display-none]="!this.paired
            || (this.paired && getNewSelectedCount() === newInteractions.length)">Map all</button>
          </div>
        </div>
        <div class="list-items">
          <div *ngFor="let interaction of newInteractions; let interactionIndex = index;">
            <fieldset class="list-item" [class.selected]="interaction.selected"
              [class.disabled]="disableNewInteractions(interaction)"
              (click)="selectNewInteraction(interaction)" [class.wrong-selected]="false">
              <legend class="list-item-legend">ID: {{interaction.interactionId}}</legend>
              <div [class.flex-column]="oldInteractions.length > 0" [class.flex-row]="oldInteractions.length === 0">
                <div class="interaction-info-container">
                  Name: {{interaction.interactionName}} <br />
                  Version: {{interaction.interactionVersion | date:'MMM d, y @ h:mm:ss a'}}
                </div>
                <div *ngIf="paired && !interaction.selected">
                  <button mat-stroked-button (click)="interaction.selectedAction = 'New'"
                    [class.selected]="interaction.selectedAction === 'New'" [class.disabled]="interaction.selected"
                    matTooltip="Create as a new interaction">New</button>
                  <button mat-stroked-button (click)="interaction.selectedAction = 'Map'"
                    [class.selected]="interaction.selectedAction === 'Map'" [class.disabled]="interaction.selected"
                    matTooltip="Map this interaction to other interaction">Map</button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" [class.disabled]="!isValid()"
      (click)="onSubmit()">{{paired ? "Submit" : "Pair"}}</button>
    <button *ngIf="oldInteractions.length > 0" mat-stroked-button (click)="onReset()">Reset</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>