import { FormControl, UntypedFormGroup } from "@angular/forms";
import { DynamicFormControlModel } from "@ng-dynamic-forms/core";
import { EvaDynamicFormControlModel } from "./evaDynamicFormControlModel";

/**
 * This is the Dialog model object for InteractionElementRelationDialog
 */
export class InteractionElementRelationDialogModel {

    constructor(
        public title: string,                       // title of the dialog box
        public content: string,                     // content of the dialog box
        public formScreenIndex: number,               // index of the form screen
        public formElementIndex: number,              // index of the form control in the form screen
        public formControl: any,                    // form control object
        public interaction: any,                    // the dynamic form object
        public confirmBtnTxt: string,               // confirm button text to show
        public cancelBtnTxt?: string,               // cancel button text to show
        public isElementDisabled?: boolean          // is form control disabled by default or not
    ) {  }

}

/**
 * List of all the logic options used in if then condition
 */
export enum IfThenLogicOptions {
    IsEqualTo = 'is equal to',
    IsNotEqualTo = 'is not equal to',
    StartsWith = 'starts with',
    EndsWith = 'ends with',
    IsEmpty = 'is empty',
    IsNotEmpty = 'is not empty',
    // XasDefault = 'X as default',
    GreaterThan = 'is greater than',
    LessThan = 'is less than',
    GreaterThanOrEqualTo = 'is greater than or equal to',
    LessThanOrEqualTo = 'is less than or equal to',
    IsBetween = 'is between',
    IsNotBetween = 'is not between',
    MathEquation = 'math equation',
    DateIsToday = 'date is today',
    DateIsAfterToday = 'date is after today',
    DateIsBeforeToday = 'date is before today',
    DateIsEqualToOrAfterToday = 'date is equal to or after today',
    DateIsEqualToOrBeforeToday = 'date is equal to or before today',
    DateIsNow = 'date is now',
    DateIsAfterNow = 'date is after now',
    DateIsBeforeNow = 'date is before now',
    DateIsEqualToOrAfterNow = 'date is equal to or after now',
    DateIsEqualToOrBeforeNow = 'date is equal to or before now',
    DateIsAfterX = 'date is after X',
    DateIsBeforeX = 'date is before X',
    DateIsEqualToOrAfterX = 'date is equal to or after X',
    DateIsEqualToOrBeforeX = 'date is equal to or before X',
    IsCurrentMonth = 'is current month',
    MonthIsBeforeCurrentMonth = 'month is before current month',
    MonthIsAfterCurrentMonth = 'month is after current month',
    MonthIsEqualToOrAfterCurrentMonth = 'month is equal to or after current month',
    MonthIsEqualToOrBeforeCurrentMonth = 'month is equal to or before current month',
    MonthIsAfterX = 'month is after X',
    MonthIsBeforeX = 'month is before X',
    MonthIsEqualToOrAfterX = 'month is equal to or after X',
    MonthIsEqualToOrBeforeX = 'month is equal to or before X',
    IsCurrentWeek = 'is current week',
    WeekIsBeforeCurrentWeek = 'week is before current week',
    WeekIsAfterCurrentWeek = 'week is after current week',
    WeekIsEqualToOrAfterCurrentWeek = 'week is equal to or after current week',
    WeekIsEqualToOrBeforeCurrentWeek = 'week is equal to or before current week',
    WeekIsAfterX = 'week is after X',
    WeekIsBeforeX = 'week is before X',
    WeekIsEqualToOrAfterX = 'week is equal to or after X',
    WeekIsEqualToOrBeforeX = 'week is equal to or before X',
    IsCurrentTime = 'is current time',
    TimeIsBeforeCurrentTime = 'time is before current time',
    TimeIsAfterCurrentTime = 'time is after current time',
    TimeIsEqualToOrAfterCurrentTime = 'time is equal to or after current time',
    TimeIsEqualToOrBeforeCurrentTime = 'time is equal to or before current time',
    TimeIsAfterX = 'time is after X',
    TimeIsBeforeX = 'time is before X',
    TimeIsEqualToOrAfterX = 'time is equal to or after X',
    TimeIsEqualToOrBeforeX = 'time is equal to or before X',
    TextDoesNotContain = 'does not contain',
    TextContains = 'contains'
}

export enum MathEquationOperators {
    Add = 'Add',
    Subtract = 'Subtract',
    MultipliedBy = 'Multiplied by',
    DividedBy = 'Divided by'
}

/**
 * This represents logic option object used in drop down menu
 */
export class LogicOption {

    public label: string;

    constructor(
        public value: string
    ) {
        this.label = value;
    }
}

/**
 * List of all the value types used by form controls in the dynamic form
 */
export enum elementTypes {
    string = 'string',
    tel = 'tel',
    boolean = 'boolean',
    number = 'number',
    date = 'date',
    dateTime = 'dateTime',
    time = 'time',
    month = 'month',
    week = 'week',
    chips = 'chips',
    checkboxGroup = 'checkboxGroup',
    multiSelect = 'multiSelect'
}

export interface Relation {
    id: string[];
    subRelation: SubRelation[];
    order: number;
    connective?: 'AND' | 'OR';
    ifControl?: EvaDynamicFormControlModel[];
    thenControl?: EvaDynamicFormControlModel[];
}

export interface SubRelation {
    if: SubRelationIf;
    then: SubRelationThen;
    subConnective?: 'AND' | 'OR';
    ifConnective?: 'AND' | 'OR';
    formGroup?: UntypedFormGroup;
}

export interface SubRelationIf {
    option: IfThenLogicOptions;
    value: any;
    model?: EvaDynamicFormControlModel;
    secondValue?: any;
}

export interface SubRelationThen {
    action: IfThenLogicAction;
    option: IfThenLogicOptions;
    value: any;
    enableOpposite: boolean;
    model?: EvaDynamicFormControlModel;
    secondValue?: any;
    operations?: MathOperation[];
}

export interface MathOperation {
    operator: MathEquationOperators;
    value: string;
}

export enum IfThenLogicAction {
    Enable = 'enable',
    Disable = 'disable',
    MustBe = 'must be',
    MathEquation = 'evaluate',
    DefaultValue = 'value is'
}
