import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { trigger, style, transition, animate, query, stagger } from '@angular/animations';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { take, filter, tap } from 'rxjs/operators';
import { userLastStateType } from '@eva-model/userLastState';
import { TfidfDocumentMatches } from '@eva-model/eva-custom-nlp/eva-custom-nlp';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { KnowledgeDocumentFeedbackService } from '@eva-services/knowledge/feedback/knowledge-document-feedback.service';
import { Observable } from 'rxjs';
import { EvaHeartTrainingService } from '@eva-services/chat/eva-heart-training.service';
import { ChatService } from '@eva-services/chat/chat.service';
import { ChatMLInitialUserResponse } from '@eva-model/chat/chatML';
import { KnowledgeDocumentSectionFlat, KnowledgeSearchRecord } from '@eva-model/knowledge/knowledge';
import { TutorialService } from '@eva-services/tutorial/tutorial.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-knowledge-feedback-prompt',
  templateUrl: './knowledge-feedback-prompt.component.html',
  styleUrls: ['./knowledge-feedback-prompt.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s', style({ opacity: 0 }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class KnowledgeFeedbackPromptComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('animateZoom', { read: ElementRef }) animateZoomEle: ElementRef;

  @Input() documentId: string;
  @Input() groupPublicKey: string;
  @Input() modelVersion: string;
  @Input() dialogflowResponse: any;

  @Output() enableSectionSelection = new EventEmitter();
  @Output() disableSectionSelection = new EventEmitter();

  // emits an event for the parent to destroy this component since we cannot destroy it within itself.
  @Output() destroyComponent = new EventEmitter();

  chatQuery: string = null; // on init, we need this if the user does submit feedback.
  knowledgeSearchQuery = ''; // comes from knowledge-search component output
  showInitialPrompt = true;
  showButtons = true;

  timeout = 1600;

  text: string;
  questionIndex = 0;
  questions = [
    'Is this the document you were looking for?',
    'Did this section answer your question?'
  ];
  documentChoices = [];

  selectingSections = false;
  sectionsSelected$: Observable<KnowledgeDocumentSectionFlat[]>;

  constructor(private lastState: LastStateService,
              private chatKnowledgeService: ChatKnowledgeService,
              private knowledgeFeedbackDocumentService: KnowledgeDocumentFeedbackService,
              private heartTrainingService: EvaHeartTrainingService,
              private chatService: ChatService,
              private tutorialService: TutorialService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.chatQuery = this.chatService.getLastQueryMessage();
    this.showInitialPrompt = true;
    this.text = this.questions[this.questionIndex];

    // create our section selection observable for this document
    this.sectionsSelected$ = this.knowledgeFeedbackDocumentService.getSectionSelectionByDocId(this.documentId);

    // setTimeout(() => {
    //   this.animateZoomEle.nativeElement.classList.add('animated', 'zoomIn', 'delay-1s');
    // }, 0);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.tutorialService.showTutorial('documentFeedback');
    }, 1500);
  }

  ngOnDestroy() {
    this.allowLeftPaneScrolling(true);
  }

  yes() {
    switch (this.questionIndex) {
      case 0:
        this.text = 'Excellent!';
        this.toggleButtons(this.timeout);
        this.sendSelectedSectionsAsTraining(ChatMLInitialUserResponse.RIGHT_DOCUMENT);

        setTimeout(() => {
          this.questionIndex++;
          this.text = this.questions[this.questionIndex];
        }, this.timeout);
        break;
      case 1:
        this.toggleButtons();
        this.sendSelectedSectionsAsTraining(ChatMLInitialUserResponse.GOOD);
        this.text = 'Thank you for your feedback!';
        this.dismissComponentAfterDuration();
    }
  }

  no() {
    switch (this.questionIndex) {
      case 0:
        this.text = 'Choose a document below';
        this.toggleButtons();
        this.showDocumentChoices();

        // send training that this current answer was wrong
        this.sendSelectedSectionsAsTraining(ChatMLInitialUserResponse.WRONG_DOCUMENT);
        break;
      case 1:
        // notify parent component to initialize section selection
        this.text = 'Please select the correct sections';

        // send training that this current answer was wrong
        this.sendSelectedSectionsAsTraining(ChatMLInitialUserResponse.TRY_AGAIN);

        // deselect the highlighted section since it's wrong
        this.knowledgeFeedbackDocumentService.clearAllSelectionsByDocument(this.documentId);

        this.enableSectionSelection.emit(true);
        this.toggleButtons();

        // trigger showing our observable on the template
        this.selectingSections = true;
    }
  }

  toggleButtons(ms?: number) {
    this.showButtons = !this.showButtons;
    if (ms) {
      setTimeout(() => {
        this.showButtons = !this.showButtons;
      }, ms);
    }
  }

  showDocumentChoices() {
    // get all other docs from response
    if (!this.dialogflowResponse || (this.dialogflowResponse && !this.dialogflowResponse.response)) {
      return;
    }
    const matches = this.dialogflowResponse.response.tfidfDocumentMatches;
    const activeResult = matches[this.dialogflowResponse.response.count];

    const uniqueMatches = {};
    uniqueMatches[activeResult.documentName] = activeResult;

    // get only unique documents
    matches.forEach(result => {
      if (!uniqueMatches[result.documentName]) {
        uniqueMatches[result.documentName] = result;
      }
    });

    const unsortedDocs: TfidfDocumentMatches[] = Object.values(uniqueMatches);

    // sort document matches in ascending order by doc name
    this.documentChoices = unsortedDocs.sort((a, b) => (a.documentName > b.documentName) ? 1 : -1);
    this.allowLeftPaneScrolling(false);
  }

  /**
   * Emit the current doc information to be fetched/shown.
   */
  goToDocument(data: TfidfDocumentMatches) {
    const docId = data.docId.split('_')[0];
    const docGroup = data.modelVersion.split('_')[0];
    const docVersion = data.docId.split('_')[1];
    const sectionId = data.docId.split('_').slice(2).join('_');
    const modelVersion = data.modelVersion;

    this.allowLeftPaneScrolling(true);

    this.chatKnowledgeService.announceKnowledgeShow({
      docId,
      docGroup,
      docVersion: Number(docVersion),
      modelVersion,
      sectionId,
      promptForFeedback: true,
      dialogflowResponse: this.dialogflowResponse
    });
  }

  /**
   * Announces that we should show this document that was clicked. Passing dialogflowResponse to show results in other documents.
   * @param result result from search provider
   */
  searchResultClicked(result: KnowledgeSearchRecord) {
    this.chatKnowledgeService.announceKnowledgeShow({
      docId: result.docId,
      docGroup: result.groupPublicKey,
      sectionId: result.sectionId,
      promptForFeedback: true,
      dialogflowResponse: this.dialogflowResponse
    });

    this.allowLeftPaneScrolling(true);
  }

  /**
   * triggered by the 'Submit Feedback' button on the template
   */
  submitFeedback() {
    this.selectingSections = false;
    this.disableSectionSelection.emit();
    this.sendSelectedSectionsAsTraining(ChatMLInitialUserResponse.GOOD);

    // update component UI for completion of feedback loop
    this.text = 'Thank you for your feedback!';

    this.knowledgeFeedbackDocumentService.clearAllSelectionsByDocument(this.documentId);

    this.dismissComponentAfterDuration();
  }

  /**
   * Sends the selected section that was given by knowledge for training with a specific type, "GOOD", "BAD", etc
   * @param trainingType
   */
  async sendSelectedSectionsAsTraining(trainingType: ChatMLInitialUserResponse) {
    const sections = await this.knowledgeFeedbackDocumentService.getSectionSelectionByDocId(this.documentId).pipe(take(1)).toPromise();

    const trainingObject = await this.heartTrainingService.createTfidfTrainingObject(
      this.chatQuery ? this.chatQuery : '',
      this.groupPublicKey,
      this.modelVersion,
      this.documentId,
      trainingType,
      null,
      sections
    );

    // send without regard of a failure or having the user wait, let the user forget about it
    await this.heartTrainingService.sendTfidfTrainingData(trainingObject);
  }

  /**
   * Freezes or unfreezes the scrolling of left pane
   * @param freeze to freeze or unfreeze
   */
  allowLeftPaneScrolling(allowScroll: boolean) {
    const leftPaneEle = document.getElementsByClassName('left-pane')[0] as HTMLDivElement;
    if (leftPaneEle) {
      allowScroll ? leftPaneEle.style.removeProperty('overflow') : leftPaneEle.style.setProperty('overflow', 'hidden');
    }
  }

  /**
   * immediately emits an event so the parent component can destroy the component
   */
  dismissComponent() {
    this.allowLeftPaneScrolling(true);
    this.knowledgeFeedbackDocumentService.clearAllSelectionsByDocument(this.documentId);
    this.destroyComponent.emit();
  }

  /**
   * emits an event after a duration so the parent component can destroy the component
   * @param ms timeout for a dismiss event
   */
  private dismissComponentAfterDuration(ms: number = 4000) {
    setTimeout(() => this.dismissComponent(), ms);
  }

  /**
   * Emits every time the search query changes
   * @param ev search query
   */
  searchQueryChanged(ev: string) {
    this.knowledgeSearchQuery = ev;
  }
}
