<div *ngIf="_isAdministrator">
  <button mat-button color="primary" class="new-faq-button" (click)="addFAQ()">New FAQ</button>
</div>
<div *ngIf="aboutPageFAQs$ | async; let aboutPageFAQs; else loading;">
  <mat-accordion class="faq-accordion">
    <mat-expansion-panel class="faq-content" *ngFor="let faq of aboutPageFAQs">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div *ngIf="_isAdministrator">
            <button class="rightMargin15" (click)='editFAQ(faq)' matTooltip="Edit FAQ"><i class="far fa-edit"></i></button>
          </div>
          {{ faq.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <p class="FAQDescription">
        {{ faq.description }}
      </p>
    </mat-expansion-panel>

  </mat-accordion>
</div>

<ng-template #loading>Loading FAQs...</ng-template>