import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'app-workflow-viewer-dialog',
  templateUrl: './workflow-viewer-dialog.component.html',
  styleUrls: ['./workflow-viewer-dialog.component.scss']
})
export class WorkflowViewerDialogComponent {

  workflow = null;
  interactionsByGroups: any = {};
  submitToBlock = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    public evaGlobalService: EvaGlobalService) {
    this.interactionsByGroups = dialogData.interactionsByGroups;
    this.workflow = dialogData.workflow;
  }
}
