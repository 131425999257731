import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * Display component
 */
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {

  /**
   * Required
   */
  @Input()
  title: string;

  /**
   * Optional
   */
  @Input()
  subTitle: string;

  @Input()
  actions: TemplateRef<any>;

  @Input()
  hideSearchTitle: boolean;

  @Input()
  hideSearchSubTitle: boolean;

  constructor() { }

}
