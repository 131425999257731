import { Pipe, PipeTransform } from '@angular/core';
import { PropertyMapping } from '@eva-model/search';

@Pipe({
  name: 'getProperties'
})
export class GetPropertiesPipe implements PipeTransform {
  transform(data: object, propertyNameMapping: PropertyMapping, titleProperty: string, subTitleProperty: string): string[] {
    const returnedData = {};

    propertyNameMapping.properties.forEach(property => {
      if (property.optional ? data[property.propertyName + '-modified'] ? true : data[property.propertyName] : true) {
        returnedData[property.propertyName] = data[property.propertyName];
      }
    });

    if (titleProperty) {
      delete returnedData[titleProperty];
    }
    if (subTitleProperty) {
      delete returnedData[subTitleProperty];
    }

    return Object.keys(returnedData);
  }
}