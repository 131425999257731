import {AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Process, ProcessInteractionValues, ProcessSensitiveData, ProcessStatus} from '@eva-model/process/process';
import {WorkFlow, WorkflowInteraction} from '@eva-model/workflow';
import {DynamicInteractionSyncService} from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import {FormVisualizerComponent} from '@eva-ui/form-visualizer/form-visualizer.component';
import {ViewContainerDirective} from '@eva-ui/view-container/view-container.directive';
import {Subscription, Subject} from 'rxjs';
import {ProcessInteractionEmitValueModel, submitDirectionType} from '@eva-model/processInteractionEmitValueModel';
import {ProcessInteractionConditionService} from '@eva-services/process/process-interaction-condition.service';
import {workflowInteractionDest} from '@eva-ui/shared/constants';
import {ProcessService} from '@eva-services/process/process.service';
import {SigningService} from '@eva-core/signing.service';
import {ProjectSettings} from '@eva-settings/globalvariables';
import {UserService} from '@eva-services/user/user.service';
import {EvaGlobalService} from '@eva-core/eva-global.service';
import {Router} from '@angular/router';
import {Guid} from '@eva-core/GUID/guid';
import {GeneralDialogModel} from '@eva-model/generalDialogModel';
import {GeneralDialogComponent} from '@eva-ui/general-dialog/general-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GeneralDialogService} from '@eva-services/general-dialog/general-dialog.service';
import {DynamicFormControlModel, DynamicTextAreaModel} from '@ng-dynamic-forms/core';
import {LogService} from '@eva-core/log/log.service';
import {EntityType, Log, LogLevel} from '@eva-model/log';
import {DataStorageService} from '@eva-core/storage/data-storage.service';
import {InteractionLoadService} from '@eva-services/dynamic-interactions/interaction-load.service';
import {ProcessEdit, ProcessEditAction, ProcessEditDetails} from '@eva-model/process/processEdit';
import {InteractionVisualizerMode} from '@eva-model/interactionVisualizerDialogModel';
import {DynamicComponent} from '@eva-model/interaction/interaction';
import {LoggingService} from '@eva-core/logging.service';
import {AngularFirePerformance, trace} from '@angular/fire/compat/performance';
import {ProcessDashboardStatus} from '@eva-model/process/processDashboard';
import {environment} from '@environments/environment';
import {ProcessDashboardService} from '@eva-services/process/process-dashboard.service';
import {MessageService} from 'primeng/api';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { filter, take } from 'rxjs/operators';
import { InteractionControlModel } from '@eva-model/visualizedInteractionModel';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { InteractionFlatModel } from '@eva-model/interactionFlatModel';
import { searchDirection } from '@eva-model/interactionFormControlModel';
import { InteractionEmitValueModel } from '@eva-model/InteractionEmitValueModel';
import { ChatService } from '@eva-services/chat/chat.service';
import { ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';

@Component({
  selector: 'eva-process-runner',
  templateUrl: './process-runner.component.html',
  styleUrls: ['./process-runner.component.scss'],
  providers: [MessageService]
})
export class ProcessRunnerComponent implements OnInit, OnDestroy, AfterViewInit {

  process: Process;
  processRunnerId: string;
  runningWorkFlow: WorkFlow;
  runningInteraction: WorkflowInteraction;
  runningWorkflowId: string;
  runningInteractionId: string;
  preservedInteractions: any[]; // array to store interactions that were completed before moving to previous/selected interaction

  processRunnerSubs = new Subscription(); // process runner subscriptions are added to this then destroyed in ngOnDestroy

  intrctVisualizerRef: ComponentRef<any>;
  isWaiting = false;
  onWaitMessage = '';
  isWaitingForProcess = false;
  onWaitForProcessMessage = '';
  isWaitingForProcessAndSpin = false;

  isProcessRunnerWaiting = false; // spinner for process runner when its updating
  onProcessRunnerWaitMessage = ''; // message to tell users process is updating

  isActionedProcess: boolean;
  isNgAfterViewInit: boolean;
  isProcessSummary: boolean;
  isProcessEditSummary: boolean; // when true, triggers the loading of the process-edit-summary in the process-runner html code

  isDoneButtonEnableInSummary: boolean;
  selectedFormScreen: number; // the screen in the interaction selected by the user
  lastProcessDestination: any; // stores the process destination (interaction/group) that was traversed by the user before the current one

  @Input() uniqueTabId: string;
  // Template flex sizing values
  public visualizerSize = '75%';
  public processRelatedFunctionsSize = '15%';

  // Template variables to show or hide buttons
  public isRejectEnabled = false;
  public isCancelEnabled = true;
  public isActionPanelEnabled = true;
  @Input() updatedFromLastState: boolean;
  @Input() tabIndex: number;

  interactionSize = '75%';

  Form_Reject_Notes_INPUT =
    new DynamicTextAreaModel({
      id: Guid.newGuid().toString(),
      label: 'Reject note',
      placeholder: `Enter a note for rejection`,
      rows: 3,
      validators: {required: null, minLength: 5},
      value: null
    });

  Form_Reject_Notes_MODEL: DynamicFormControlModel[] = [
    this.Form_Reject_Notes_INPUT
  ];

  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;

  @Input()
  set processObj(processObj: Process) {
    if ( !processObj ) { return; }

    this.initProcessRunner(processObj);
  }
  @Input() targetId?: string;
  @Input() hideProcessTitleBar: boolean;

  constructor(
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private interactionSyncService: DynamicInteractionSyncService,
    private prcsInteractionConditionServie: ProcessInteractionConditionService,
    private processService: ProcessService,
    private signingService: SigningService,
    private userService: UserService,
    public  evaGlobalService: EvaGlobalService,
    private logService: LogService,
    private _dataStorageService: DataStorageService,
    public router: Router,
    public interactionLoadService: InteractionLoadService,
    private logging: LoggingService,
    private perf: AngularFirePerformance,
    private processDashboardService: ProcessDashboardService,
    private messageService: MessageService,
    private multiViewService: MultiViewService,
    private chatService: ChatService
  ) {
    this.processRunnerId = Guid.newGuid().toString();
  }

//#region InitializeProcessRunner

  /**
   * This function takes a process object and either starts it, or initizes it to the state it was at when last touched.
   *
   * @param processObj the process object that will be iniaitized.
   */
  private initProcessRunner(processObj: Process): void {
    // determine the size of the interaction based on whether the hide process title bar is set
    if (this.hideProcessTitleBar) {
      this.interactionSize = '100%';
    }

    // validate that the process is valid to run otherwise return void.
    if ( !this.validateProcessToRun(processObj) ) return;
    // set the process to equal the one passed into the runner
    this.process = processObj;
    // set the first workflow to equal the first on in the process.
    this.runningWorkFlow = this.process.workflows[0];

    // check if the process status is equal to the created status.
    if (this.process.status === ProcessStatus.Created ) {
      // since this was just created, set the first interaction equal to the first on in the workflow.
      this.runningInteraction = this.runningWorkFlow.interactions[0];
      this.runningWorkflowId = this.runningWorkFlow.id;
      this.runningInteractionId = this.runningInteraction.interactionId;

      // set the process to equal the executing id's in this component,
      this.process.executingWorkflowId = this.runningWorkflowId;
      this.process.executingInteractionId = this.runningInteractionId;
      this.process.status = ProcessStatus.InProgress;

    } else if (this.process.status === ProcessStatus.InProgress) {  // if status is "InProgress" or other options if necessary
      // if the process is in progress, change this component to match the values of the workflow and interactions
      this.runningWorkflowId = processObj.executingWorkflowId;
      this.runningInteractionId = processObj.executingInteractionId;

      // try to find the interaction that is supposed to be executing based on the interaction existing in the
      // workflow interaction arrays. This is 0 or a greater array location if it exists.
      const interactionIndex = this.runningWorkFlow.interactions.map(i => i.interactionId).indexOf(this.runningInteractionId);
      if ( interactionIndex !== -1 ) {
        this.runningInteraction = this.runningWorkFlow.interactions[interactionIndex];
      }

      // initialize the process that currently in execution.
      this.initializeActionedProcess();

    } else if (this.process.status === ProcessStatus.Done || this.process.status === ProcessStatus.Reject) {
      // if status is "Done" or other options if necessary
      if ( this.process.destinationPublicKey === this.process.submitterPublicKey ) {
        // TODO :: set a flag to show "DONE" button in summary component
        this.isDoneButtonEnableInSummary = true;
      }

      // setup the process summary and reshape the process for this to be done.
      this.isActionedProcess = true;
      this.isProcessSummary = true;
      this.isProcessEditSummary = false;
    }

    this.isRejectEnable();
    this.isCancelEnable();
    this.isActionPanelEnabled = false;
  }

  /**
   * This function validates that a process object has at least on workflow and this workflow has at least one interaction.
   * If the process in already in flight, it makes sure that the executing workflow and executing interaction exists in the
   * appropriate place for execution.
   *
   * @param processObj the process to validate that it is complete.
   */
  private validateProcessToRun(processObj: Process): boolean {
    let isProcessValid = false;
    // check toi ensure that there is atleast one workflow in the process object.
    if (processObj.workflows && Array.isArray(processObj.workflows) && processObj.workflows.length > 0) {
      // set the running workflow to equal the first workflow in the process.
      this.runningWorkFlow = processObj.workflows[0];
      // ensure that there are interactions in the workflow.
      if (this.runningWorkFlow.interactions && Array.isArray(this.runningWorkFlow.interactions) &&
      this.runningWorkFlow.interactions.length > 0) {
        // check whether this is in progress and determine if the interaction is valid in the workflow.
        if (processObj.status === ProcessStatus.InProgress) {
          if ( processObj.executingWorkflowId && processObj.executingInteractionId ) {
            const workflowIndex = processObj.workflows.map( wf => wf.id).indexOf(processObj.executingWorkflowId);
            if ( workflowIndex !== -1 ) {
              const interactionIndex =
                processObj.workflows[workflowIndex].interactions.map(i => i.interactionId).indexOf(processObj.executingInteractionId);
              if ( interactionIndex !== -1 ) isProcessValid = true;
            }
          }
        } else {
          isProcessValid = true;
        }
      }
    }
    // return the outcome of the checks.
    return isProcessValid;
  }

  /**
   * this runs if an interaction is in progress and is actioned after having been submitted to group
   */
  private initializeActionedProcess(): void {

    // If this is inProgress and the interactions exist and then return void.
    if ( !(this.process.status === ProcessStatus.InProgress &&
      this.runningInteractionId && this.process.executingInteractionId &&
      this.process.interactionsValues && Array.isArray(this.process.interactionsValues) && this.process.interactionsValues.length > 0)
    ) return;  // Checked to find out if possible to be an actioned process

    const interactionIndex = this.process.interactionsValues.map(iv =>
      iv.interactionValues.originalId).indexOf(this.process.executingInteractionId);
    if ( interactionIndex === -1 ) return;   // Checked to find out if the executing process's interaction has already been submitted or not
    setTimeout(() => {
      if (this.updatedFromLastState && this.intrctVisualizerRef) {
        this.intrctVisualizerRef.instance.updatedFromLastState = this.updatedFromLastState;
        const executingInteractionValues = this.process.interactionsValues.find(values =>
          values.interactionValues.originalId === this.process.executingInteractionId);
        (<DynamicComponent>this.intrctVisualizerRef.instance).processInteractionValues = executingInteractionValues;
        this.intrctVisualizerRef.instance.dynFrmObj = this.runningInteraction.interaction;
      }
    });
    // if interaction is not submitted, do not show process summary
    if ( !this.process.interactionsValues[interactionIndex].submitted ) return;

    // otherwise show the process summary and that it is complete.
    this.isActionedProcess = true;
    this.isProcessSummary = true;
    this.isProcessEditSummary = false;
  }

//#endregion InitializeProcessRunner

//#endregion ComponentInitilization

  ngOnInit() {
    const that = this;

    // Set sizing of the flex elements - here we are overriding the default sizing if we are hiding the process title bar
    if (this.hideProcessTitleBar) {
      this.visualizerSize = '100%';
      this.processRelatedFunctionsSize = '30%';
    }

    this.processRunnerSubs.add(
      this.interactionSyncService.processInteractionSubmit$
      .subscribe(
        (emittedObj) => {
          if ( emittedObj instanceof ProcessInteractionEmitValueModel) {
            if ( emittedObj.processId === that.process.id &&
              emittedObj.workflowId === that.runningWorkflowId &&
              emittedObj.interactionOriginalId === that.runningInteractionId ) {

                let order = 0;
                if (that.process.interactionsValues &&
                  Array.isArray(that.process.interactionsValues) &&
                  that.process.interactionsValues.length > 0) {
                    // Get the latest interaction object order plus 1
                    order = Math.max(...that.process.interactionsValues.map(iv => iv.order)) + 1;
                }

                const prcsIntrctValue = new ProcessInteractionValues(
                  emittedObj.workflowId,
                  emittedObj.interactionOriginalId,
                  emittedObj.interactionValues,
                  [],
                  order
                );

                /**
                 * check if interaction wants to move to previous. if it does, do not push values into interactionsValues array.
                 * values can instead be pushed directly into the preservedInteractions to be reloaded later.
                 */
                if ( emittedObj.submitDirection && emittedObj.submitDirection === submitDirectionType.previous ) {

                  if (!this.preservedInteractions) {
                    this.preservedInteractions = [];
                  }
                  this.preservedInteractions.push(prcsIntrctValue);

                } else  {
                  // that.process.interactionsValues.push(prcsIntrctValue);
                  this.runningInteraction = prcsIntrctValue.interactionValues;
                  this.runningInteractionId = prcsIntrctValue.interactionId;
                  this.process.executingInteractionId = prcsIntrctValue.interactionId;
                  if (!this.process.interactionsValues.find(values =>
                    values.interactionValues.originalId === emittedObj.interactionOriginalId)) {
                    this.process.interactionsValues.push(prcsIntrctValue);
                    this.multiViewService.tabs[Routes.Process][this.tabIndex].additionalInstanceData.process = this.process;
                    this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, "Update",
                      this.multiViewService.tabs[Routes.Process][this.tabIndex], this.tabIndex);
                  }
                }

                if (emittedObj.submitInteraction) {
                  const submitDirection: submitDirectionType =
                    (emittedObj.submitDirection &&  emittedObj.submitDirection === submitDirectionType.previous)
                    ? emittedObj.submitDirection
                    : submitDirectionType.next;

                  this.processDestinationAnalyzer(null, submitDirection);
                }
              }
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );

    this.processRunnerSubs.add(
      this.processService.processSummaryDone$
      .pipe(
        trace('process-runner-com-processSummaryDone')
      )
      .subscribe(
        async (prcsDoneObj) => {
          if ( that.process && prcsDoneObj && prcsDoneObj.processId && that.process.id === prcsDoneObj.processId &&
            prcsDoneObj.processRunnerId && prcsDoneObj.processRunnerId === this.processRunnerId ) {
            that.isProcessSummary = false;

            if ( this.process.destinationPublicKey === this.process.submitterPublicKey ) {
              this.processDoneBySubmitter();
              setTimeout(() => {
                this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Remove', null, this.tabIndex);
              });
            } else {
              if (prcsDoneObj.isClose) {
                await this.processDestinationAnalyzer(undefined, undefined, true);
              } else {
                await this.processDestinationAnalyzer();
              }
            }
          }
        },
        (err) => {
          console.log(err);
          throw err;
        },
        () => {
        }
      )
    );

    // observable to listen for when the process object is either submitted or edited
    this.processRunnerSubs.add(
      this.processService.processEditSummaryDone$
      .pipe(
        filter(processEditSummary => processEditSummary.detail.processId === this.process.id),
        trace('process-runner-com-processEditSummaryDone')
      ).subscribe(
        async (prcsEditSummary: ProcessEdit) => {
          try {
            if ( prcsEditSummary.action === ProcessEditAction.submit ) {
              this.isProcessEditSummary = false;
              await this.trySubmit();
              this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Remove', null, this.tabIndex);
            } else if ( prcsEditSummary.action === ProcessEditAction.edit ) {
              this.isProcessEditSummary = false;
              await this.processDestinationAnalyzer( prcsEditSummary.detail );
            }
          } catch (err) {
            console.log(err);
            this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Update',
              {tabName: 'Error submitting the process'}, this.tabIndex);
          }
        },
        (err) => {
          console.log(err);
          throw err;
        },
        () => {
        }
      )
    );

    this.processRunnerSubs.add(
      this.interactionSyncService.interactionValuesUpdate$.pipe(
        // ensure it's not the preview mode from form builder
        filter(update => update.processId === this.process.id),
        filter(update => !update.fromLastState)
      )
      .subscribe(
        (update) => {
          const data = update.interactionValues;
          // if (!this.interactionElementValues[data.elementOriginalId]) {
          //   this.interactionElementValues[data.elementOriginalId] = data;
          // } else {
          //   Object.assign(this.interactionElementValues, { [data.elementOriginalId]: data });
          // }

          // this.result = JSON.parse(JSON.stringify(usersLastState)); // convert user state into 'new' object without ref?
          // this.process.interactionsValues = JSON.parse(JSON.stringify(this.interactionElementValues));  // same as above.

          if (!this.process.interactionsValues) {
            this.process.interactionsValues = [];
          }

          const interaction = this.process.interactionsValues.find(values => values.interactionValues.originalId === data.originalId);
          if (!interaction) {
            let order = 0;
            if (that.process.interactionsValues &&
              Array.isArray(that.process.interactionsValues) &&
              that.process.interactionsValues.length > 0) {
                // Get the latest interaction object order plus 1
                order = Math.max(...that.process.interactionsValues.map(iv => iv.order)) + 1;
            }

            this.process.interactionsValues.push(new ProcessInteractionValues(
              this.runningWorkflowId,
              data.id,
              data,
              [],
              order
            ));
          } else {
            interaction.interactionValues = data;
          }
          const tab = this.multiViewService.tabs[Routes.Process][this.tabIndex];
          tab.additionalInstanceData = {
            ...tab.additionalInstanceData,
            process: this.process
          };
          this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, "Update", tab, this.tabIndex);
        },
        ( err ) => console.log(err)
      )
    );
  }

  ngAfterViewInit() {
    this.isNgAfterViewInit = true;
    if ( ! this.isActionedProcess ) {
      this.interactionVisualizer();
    }

    // forces window-scrolling service to check dimensions
    setTimeout(() => {
      const element = document.getElementsByClassName("left-pane")[0];
      if (element) {
        element.dispatchEvent(new CustomEvent('scroll'));
      }
    }, 100);
  }

//#endregion ComponentInitilization

/**
 * called on submit button click and from processEditSummaryDone$ subscription
 * if there is no group process destination it will submit to done, otherwise it will submit to group
 */
  private async trySubmit() {
    const processDestination = this.lastProcessDestination;
    if ( !processDestination ) {
      await this.trySubmitFinal();
    } else if ( processDestination.type === workflowInteractionDest.group)  {
      await this.trySubmitToGroup(processDestination);
    }
  }

/**
 * checks if the user is a part of the group the interaction is submitting to
 * then sets the destination and runs the processDestinationAnalyzer
 * @param processDestination the group to be submitted to
 */
  async trySubmitToGroup(processDestination: any) {

    const processName = this.process.name;
    const processGroupDestination = this.evaGlobalService.getGroupNameByPublicKey(processDestination.publicKey);

    try {
      if (!(this.evaGlobalService.processFlowThroughGroupConfig &&
        this.evaGlobalService.processFlowThroughGroupConfig.groupPublicKey)) {
          await this.evaGlobalService.processFlowThroughGroupInConfigForceFetch();
      }
      //#region If the destination is a process flow through group and user is a member, then move on!
      if ( this.evaGlobalService.processFlowThroughGroupConfig &&
        this.evaGlobalService.processFlowThroughGroupConfig.groupPublicKey &&
        this.evaGlobalService.processFlowThroughGroupConfig.groupPublicKey === processDestination.publicKey &&
        this.evaGlobalService.processFlowThroughGroup.groupSigningMembership &&
        Array.isArray(this.evaGlobalService.processFlowThroughGroup.groupSigningMembership) &&
        this.evaGlobalService.processFlowThroughGroup.groupSigningMembership.length > 0 ) {

        // const grpMemBerIdx =
        //   this.evaGlobalService.processFlowThroughGroup.groupSigningMembership.indexOf(this.evaGlobalService.userPublicKey);
        // if ( grpMemBerIdx !== -1 ) {
        this.setProcessInteractionDestination(processDestination);
        this.processDestinationAnalyzer();

        if ( ! this.lastProcessDestination ) {
          await this.trySubmitFinal();
        } else if ( (this.lastProcessDestination !== processDestination) &&
          (this.lastProcessDestination.type === workflowInteractionDest.group) ) {
          await this.trySubmitToGroup(this.lastProcessDestination);
        }
        return;
        // }
      }
      //#endregion

      this.isWaiting = true; // boolean to make spinner show up
      this.onWaitMessage = `Submitting process ${processName} to ${processGroupDestination} group`;

      // TODO :: Please add comment

      await this.processDestToGroup(processDestination);
      this.isWaiting = false;
      this.onWaitMessage  = '';

    } catch ( err ) {

      const errMsg = (err && typeof err === 'object') ? JSON.stringify(err) : ((err && typeof err === 'string') ? err : '');
      const msg = `Process ${processName} failed to move to a group :: ERROR :: ${errMsg}`;

      const log = new Log(LogLevel.Error, EntityType.process, this.process.id, msg, this.evaGlobalService.userId);
      this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));

      this.messageService.add({
        severity: 'error',
        summary: "Process moving to next group failed",
        detail: msg,
        life: 30000
      });

    }
  }

/**
 * called when submitting a process that has no destination
 */
  async trySubmitFinal() {

    const processName = this.process.name;

    try {

      this.isWaiting = true;
      this.onWaitMessage = `Submitting process ${processName}`;

      await this.processDone(); // Completes the process. This shows either a reject message based on user input, or success message below
      this.processService.announceProcessDone({ processId: this.process.id, msg: `Process ${processName} is done successfully` });

    } catch ( err ) {

      const errMsg = (err && typeof err === 'object') ? JSON.stringify(err) : ((err && typeof err === 'string') ? err : '');
      const msg = `Process ${processName} failed to be done :: ERROR :: ${errMsg}`;

      const log = new Log(LogLevel.Error, EntityType.process, this.process.id, msg, this.evaGlobalService.userId);
      this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));

      this.messageService.add({
        severity: 'error',
        summary: "Process moving to next group failed",
        detail: msg,
        life: 30000
      });

    }

  }

  /**
   * @param interactionSelected stores user selected interaction object. Object also contains interactions field data.
   * @param submitDirection determines if the interaction is moving forward or backward
   */
  private async processDestinationAnalyzer(
    interactionSelected?: ProcessEditDetails,
    submitDirection?: submitDirectionType, isClose?: boolean) {

    const processName = this.process.name;
    let processDestination;

    let intrctVisualInstance = null;
    if ( this.intrctVisualizerRef && this.intrctVisualizerRef.instance) {
      intrctVisualInstance = (<DynamicComponent>this.intrctVisualizerRef.instance);
    }

    // check if interaction was selected from summary screen. if not, check if it is going to previous
    if (interactionSelected) {

      const numInteractions = this.process.interactionsValues.length;
      this.selectedFormScreen = interactionSelected.selectedFormScreen;

      // add all values ahead of selected interaction to preserved
      // add a marker to show the interactions were previously completed and do not need to be loaded again unless selected
      for (let i = numInteractions - 1; i > interactionSelected.interactionPosition; i--) {

        this.process.interactionsValues[i].previouslyCompleted = true;
        const popedInteraction  = this.process.interactionsValues.pop();
        if ( !this.preservedInteractions ) {
          this.preservedInteractions = [];
        }

        const prsrvdIndex = this.preservedInteractions.map( intrct => intrct.id ).indexOf(popedInteraction.interactionId);
        if (  prsrvdIndex !== -1 ) {
          this.preservedInteractions[prsrvdIndex] = popedInteraction;
        } else {
          this.preservedInteractions.push(popedInteraction);
        }

      }

      this.runningInteractionId = interactionSelected.selectedInteraction.id;
      this.runningInteraction = <any>this.process.interactionsValues.find(values =>
        values.interactionValues.originalId === this.runningInteractionId)?.interactionValues;
      this.runningInteraction.interaction = interactionSelected.selectedInteraction;
      this.process.executingInteractionId = interactionSelected.selectedInteraction.id;
      processDestination = interactionSelected.selectedInteraction;
      processDestination["type"] = "interaction";

    } else if (submitDirection === submitDirectionType.previous ) {
      if (typeof this.preservedInteractions === 'undefined') {
        this.preservedInteractions = [];
      }

      if (!this.process.interactionsValues || (this.process.interactionsValues && this.process.interactionsValues.length === 0)) {
        processDestination = this.process.workflows[0].interactions[0].interaction;
      } else {
        // get last added id from stored interaction values
        let lastAddedId = this.process.interactionsValues[this.process.interactionsValues.length - 1].interactionValues.originalId;
        // if user has already loaded up summary screen, last added interaction will be the current interaction
        // this prevents it from moving back, so if last added is same as current, move back one
        if ( this.runningInteraction.interactionId === lastAddedId) {
          this.process.interactionsValues.pop();
          lastAddedId = this.process.interactionsValues[this.process.interactionsValues.length - 1].interactionValues.originalId;
        }

        // use found id to search the workflow and set process destination to last added interaction
        for (let p = 0; p < this.process.workflows[0].interactions.length; p++) {
          if (lastAddedId === this.process.workflows[0].interactions[p].interactionId) {
            processDestination = this.process.workflows[0].interactions[p].interaction;
          }
        }
      }
      processDestination["type"] = "interaction";

    } else { // moving forward, check presevered
      processDestination = this.prcsInteractionConditionServie.processConditionEvaluator(this.process);
    }

    if (processDestination) {

      if (processDestination.type === workflowInteractionDest.interaction) {
        //#region Process destination is an Interaction
        // possible to await it if there was other things to do after that
        await this.handleProcessDestinationToInteraction(intrctVisualInstance, processDestination, processName, interactionSelected);
        //#endregion
      } else if (processDestination.type === workflowInteractionDest.group) {
        //#region Process destination is a Group
        if (!this.lastProcessDestination) {
          const announceInteractionControl = new InteractionControlModel(null, null, null, null, null, null, null, null,
            null, null, null, null, null, isClose ? false : true, null, null, true, this.tabIndex,
            this.multiViewService.tabs[Routes.Process][this.tabIndex].tabName);
          this.interactionSyncService.announceInteractionControl(announceInteractionControl);
        }
        this.lastProcessDestination = processDestination;
        this.isProcessSummary = false;
        this.isProcessEditSummary = true;
        //#endregion
      }

    } else {
      //#region Process is done!
      this.lastProcessDestination = processDestination;
      this.isProcessSummary = false;
      this.isProcessEditSummary = true;
      const includeSummary = !isClose; // should the summary state be included?

      const announceInteractionControl = new InteractionControlModel(null, null, null, null, null, null, null, null,
        null, null, null, null, null, isClose ? false : true, null, null, true, this.tabIndex,
        this.multiViewService.tabs[Routes.Process][this.tabIndex].tabName);
      this.interactionSyncService.announceInteractionControl(announceInteractionControl);
      //#endregion
    }
  }

  /**
   * Runs if the workflow is going to try moving to an interaction next. Tells the user the process is updating, or if its failed,
   * then runs processDestinationToInteraction() to find the next interaction, then generateInteractionVisualizer() to load it.
   *
   * @param intrctVisualInstance instance of the interaction used to submit messages (<DynamicComponent>this.intrctVisualizerRef.instance)
   * @param processDestination the next interaction or group the workflow will move to
   * @param processName name of the process that is running. Used for error messaging
   * @param interactionSelected interaction selected by user from process edit summary
   */
  private async handleProcessDestinationToInteraction(
    intrctVisualInstance: any,
     processDestination: any,
     processName: string,
     interactionSelected: ProcessEditDetails) {

    try {
      this.isWaitingForProcess = true;
      this.isProcessRunnerWaiting = !!(interactionSelected);
      this.onProcessRunnerWaitMessage = this.onWaitForProcessMessage = `Process ${this.process.name} is updating, please wait`;

      if (intrctVisualInstance) {
        intrctVisualInstance.isSubmittingSimulate = true;
        intrctVisualInstance.isSubmittingSimulateMsg = `Process ${this.process.name} is updating`;
      } else this.isWaitingForProcessAndSpin = true;

      // makes sure the interaction that the workflow wants to move to is actually in the workflow, then encrypts the data
      await this.processDestinationToInteraction(processDestination);

      this.isActionedProcess = false; // Even if it was an actioned process it isn't anymore now
      this.isWaitingForProcess = false;
      this.isProcessRunnerWaiting = false;
      this.onProcessRunnerWaitMessage = this.onWaitForProcessMessage = '';

      if (this.isNgAfterViewInit) { // In case if an actioned process evaluated to new Interaction destination
        this.messageService.clear();
        this.messageService.add({
          life: 30000,
          severity: 'info',
          summary: "Process moves to next interaction",
          detail: `Process ${this.process.name} with latest update as
                      ${(new Date(this.process.lastUpdateTimestamp)).toLocaleString()}, the process is moving into next step`
        });

        if (intrctVisualInstance) {
          intrctVisualInstance.isSubmittingSimulate = false;
          intrctVisualInstance.isSubmittingSimulateMsg = ``;
        } else this.isWaitingForProcessAndSpin = false;

        const intrctVisualizerMode =
          ( interactionSelected && interactionSelected.selectedInteraction ) ? InteractionVisualizerMode.edit : null;
        setTimeout(() => {
          this.generateInteractionVisualizer( intrctVisualizerMode );
        });
      }

    } catch (err) {

      if (intrctVisualInstance) {
        intrctVisualInstance.isSubmittingSimulate = false;
        intrctVisualInstance.isSubmittingSimulateMsg = ``;
      } else this.isWaitingForProcessAndSpin = false;

      const msg = `Process ${processName} failed to move to next interaction :: ERROR :: ${JSON.stringify(err)}`;
      this.isWaitingForProcess = false;
      this.isProcessRunnerWaiting = false;
      this.onProcessRunnerWaitMessage = this.onWaitForProcessMessage = '';

      this.messageService.add({
        severity: 'error',
        summary: "Process moving to next interaction failed",
        detail: msg,
        life: 30000
      });
    }
  }

  /**
   * Returns a message about the destination for the process.
   *
   * @param processName name of process
   * @param prcsGrpDest name of group
   * @param processDestination destination
   */
  private processGroupDestMessage(processName: string, prcsGrpDest: any, processDestination: any): string {
    let successMsg = `Process ${processName} has been submitted to group ${prcsGrpDest}`;
    if (this.evaGlobalService.processGroupsCustomMessage &&
      Array.isArray(this.evaGlobalService.processGroupsCustomMessage) &&
      this.evaGlobalService.processGroupsCustomMessage.length > 0) {

      const groupIndex =
        this.evaGlobalService.processGroupsCustomMessage.map(group => group.groupPublicKey).indexOf(processDestination.publicKey);
      if (groupIndex !== -1) {
        const groupCustomMsg = this.evaGlobalService.processGroupsCustomMessage[groupIndex].processCustomMessage;
        successMsg = `Process ${processName} has been submitted. ${groupCustomMsg}`;
      }

    }

    return successMsg;
  }

  private setProcessInteractionDestination(processDestination: any) {
    const ivIndex = this.process.interactionsValues.map(iv => iv.interactionValues.originalId).indexOf(this.process.executingInteractionId);
    if (ivIndex !== -1) {
      if (!this.process.interactionsValues[ivIndex].conditionDestinations ||
        !Array.isArray(this.process.interactionsValues[ivIndex].conditionDestinations)) {
        this.process.interactionsValues[ivIndex].conditionDestinations = [];
      }
      this.process.interactionsValues[ivIndex].conditionDestinations.push(processDestination);

      // Check if buttons should be enabled/disabled
      this.isCancelEnable();
      this.isRejectEnable();
    }
  }

  /**
   * makes sure the interaction that the workflow wants to move to is actually in the workflow, then encrypts the data
   * @param processDestination which interaction the current interaction will move to next
   */
  private async processDestinationToInteraction(processDestination: any): Promise<void> {

    try {
      // Check if buttons should be enabled/disabled
      this.isCancelEnable();
      this.isRejectEnable();

      // get the index number of the interaction that is currently running in the workflow
      const interactionIds = this.runningWorkFlow.interactions.map(i => {
        return {
          interactionId: i.interactionId,
          interactionVersion: i.interactionVersion
        };
      });
      const intrctIndex = interactionIds.findIndex(interaction => {
        if (interaction.interactionId === processDestination.id
          && ('' + interaction.interactionVersion) === ('' + processDestination.version)) {
          return true;
        }
        return false;
      });
      if (intrctIndex !== -1) {
        this.runningInteraction = this.runningWorkFlow.interactions[intrctIndex];
        this.runningInteractionId = this.runningInteraction.interactionId;
        this.process.executingInteractionId = this.runningInteractionId;
        this.process.lastUpdateTimestamp = Date.now();

        // await this.userService.userProcessUpsert(this.process);
        // const currentLastState = this.lastStateService.getLastState();
        // currentLastState.tabs[Routes.Process][this.tabIndex] = {
        //   ...currentLastState.tabs[Routes.Process][this.tabIndex],
        //   additionalInstanceData: {
        //     ...currentLastState.tabs[Routes.Process][this.tabIndex].additionalInstanceData,
        //     process: this.process
        //   }
        // };

      } else {

        const msg = `Process ${this.process.name} :: the interaction wasn't found in the workflow`;
        const log = new Log(LogLevel.Error, EntityType.process, this.process.id, msg, '');
        this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));

      }
    } catch (err) {

      const msg = `Process ${this.process.name} to be updated with next interaction :: ERROR :: ${JSON.stringify(err)}`;
      const log = new Log(LogLevel.Error, EntityType.process, this.process.id, msg, '');
      this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));

      throw err;
    }

  }

  private processDestToGroup(processDestination: any): Promise<any> {
    this.process.lastUpdateTimestamp = Date.now();
    this.process.destinationPublicKey = processDestination.publicKey;
    this.process.acknowledgerPublicKey = null;

    this.setProcessInteractionDestination(processDestination);

    const prcsGrpDest = this.evaGlobalService.getGroupNameByPublicKey(processDestination.publicKey);
    const successMsg = this.processGroupDestMessage(this.process.name, prcsGrpDest, processDestination);

    return this.submitProcess(successMsg);
  }

  private processDone(rejectNote?: string): Promise<any> {
    this.process.destinationPublicKey = this.process.submitterPublicKey;
    this.process.acknowledgerPublicKey = null;

    this.runningWorkflowId = "";
    this.runningInteractionId = "";
    this.process.executingWorkflowId = "";
    this.process.executingInteractionId = "";
    this.process.lastUpdateTimestamp = Date.now();
    this.process.status = (rejectNote) ? ProcessStatus.Reject : ProcessStatus.Done;
    this.process.isDone = true;

    if (rejectNote) this.process.notes = rejectNote;

    return (rejectNote)
      ? this.submitProcess(`Process ${this.process.name} is rejected`)
      : this.submitProcess();
  }

  //#region Cancel/Reject a process

  /**
   * Check if a process has a destination and is in progress, determines the if the cancel button should be shown or not.
   */
  isRejectEnable() {
    this.isRejectEnabled =
      this.process &&
      this.hasProcessAnyDestination() &&
      this.process.status === ProcessStatus.InProgress;
  }

  /**
   * Check if a process has no destination and is in progress, determines the if the cancel button should be shown or not.
   */
  isCancelEnable() {
    this.isCancelEnabled =
      this.process &&
      !this.hasProcessAnyDestination() &&
      this.process.status === ProcessStatus.InProgress;
  }

  /**
   * Determines if the active process has a destination.
   */
  private hasProcessAnyDestination(): boolean {
    let returnValue = false;
    if ( this.process && this.process.interactionsValues &&
      Array.isArray(this.process.interactionsValues) && this.process.interactionsValues.length > 0 ) {

      for (let iCtr = 0; iCtr < this.process.interactionsValues.length; iCtr++ ) {
        const intrctValues = this.process.interactionsValues[iCtr];
        if (intrctValues.conditionDestinations &&
          Array.isArray(intrctValues.conditionDestinations) && intrctValues.conditionDestinations.length > 0) {
            returnValue = true;
            break;
        }
      }
    }

    return returnValue;
  }

  rejectProcessConfirm(): void {
    const dialogData = new GeneralDialogModel(
      'Process Rejection Dialog', `Do you want to reject this process :: ${this.process.name} :: ?`, 'Yes', 'No',
      this.Form_Reject_Notes_MODEL);
    this.openGeneralDialog(dialogData, this.rejectProcess, { that: this });
  }

  rejectProcess(data) {
    if ( !( data && data.that && data.that.Form_Reject_Notes_MODEL[0].id &&
      data.generalDialogOnChange )) {
      data.that.growl_msgs.push({
        severity: 'error',
        summary: `Process "${this.process.name}" rejection encountered with error and ignored.`,
        detail: `Process id :: ${this.process.id}`
      });

      if ( data.generalDialogOnChange ) data.generalDialogOnChange[data.that.Form_Reject_Notes_MODEL[0].id] = '';
      return;
    }

    const rejectNote = data.generalDialogOnChange[data.that.Form_Reject_Notes_MODEL[0].id];

    const processName = data.that.process.name;
    data.that.isWaitingForProcessAndSpin = true;

    //#region Process is rejected!
    data.that.processDone(rejectNote)
    .then(() => {
      data.that.isWaitingForProcessAndSpin = false;
      data.that.processService.announceProcessDone(
        { processId: data.that.process.id, msg: `Process ${processName} is rejected successfully` });
      setTimeout(() => {
        data.that.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Remove', null, data.that.tabIndex);
      });
    })
    .catch(err => {
      console.log(err);

      data.that.isWaitingForProcessAndSpin = false;
      const msg = `Process ${this.process.name} failed to be rejected :: ERROR :: ${JSON.stringify(err)}`;
      const log = new Log(LogLevel.Error, EntityType.process, this.process.id, msg, '');
      this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));
    });
    //#endregion
  }

  cancelProcessConfirm(closeSubject: Subject<boolean>) {
    const dialogData = new GeneralDialogModel(
      'Process Cancellation Dialog', `Do you want to cancel this process ? <br> ::
        ${this.process.workflows[0].name} - ${this.process.name} ::`, 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.cancelProcess, { that: this, closeSubject }, () => closeSubject.next(false));
  }

  cancelProcess(data) {
    data.closeSubject.next(true);
    data.that.processService.announceProcessCancel();
    data.that.processDoneBySubmitter( `Process "${data.that.process.workflows[0].name} - ${data.that.process.name}" is canceled! `);
  }
  //#endregion


  processDoneBySubmitter(message?: string) {
    // this.isWaitingForProcessAndSpin = true;
    // this.isWaitingForProcess = true;
    // this.onWaitForProcessMessage = `Remove the ${this.process.name} process!`;
    if (message) {
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: message
      });
    }
    // TODO: remove from last state processes collection here
    // this.userService.userProcessDelete(this.process.id)
    // .then( () => {
    //   this.isWaitingForProcessAndSpin = false;
    //   this.isWaitingForProcess = false;
    //   this.messageService.add({
    //     life: 30000,
    //     severity: 'info',
    //     summary: (msg) ? msg : `Process "${this.process.name}" is done and successfully handled.`,
    //     detail: `Process id :: ${this.process.id}`
    //   });

    //   const successMsg = (msg) ? msg : `Process ${this.process.name} is done.`;
    //   this.processService.announceProcessDone({ processId: this.process.id, msg: successMsg });
    // })
    // .catch( (err) => {
    //   this.isWaitingForProcessAndSpin = false;
    //   this.isWaitingForProcess = false;
    //   console.log(err);

    //   const errMsg = `Process ${this.process.name} failed to be done by submitter :: ERROR :: ${JSON.stringify(err)}`;
    //   const log = new Log(LogLevel.Error, EntityType.process, this.process.id, errMsg, '');
    //   this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));
    // });
  }



/**
 * Submits the process and displays a message for the user regarding its status.
 * Sends sensitive data to the dataStorageService to be encrypted before submission then to the signingService to
 * sign and send to a the eva blockchain. On completion, the process is dropped from the users object.
 * @param successMsg string to confirm for the user that the process has been submitted.
 */
  async submitProcess(successMsg?: string): Promise<void> {
    // check if the process is already submitted and processed to prevent duplicates
    const process = await this.processService.fetchProcess(this.process.id).pipe(take(1)).toPromise();
    if (process) {
      if (process.status === ProcessStatus.Done || process.isDone || process.destinationPublicKey === process.submitterPublicKey) {
        this.logging.logMessage('Request has already been processed, please check the status in process dashboard for id: '
          + this.process.id, true, 'error', null, 20000);
        return;
      } else if (process.acknowledgerPublicKey !== null && process.destinationPublicKey === this.lastProcessDestination?.publicKey) {
        this.logging.logMessage(`Process request has already been submitted and being processed, please check the status
          in process dashboard for id: ` + this.process.id, true, 'error', null, 20000);
        return;
      }
    }
    // loops through interactions to mark them as submitted
    // prevents users from seeing interactions that have already been submitted
    this.process.interactionsValues.forEach( interaction => interaction.submitted = true );
    let submitProcessState: string;
    const processName = this.process.name; // we grab this so we can display the process data if this was successful.

    this.isWaitingForProcess = true; // set this to waiting.
    this.onWaitForProcessMessage = `Process ${processName} is updating, please wait`;

    // create a process with the sensitive data.
    const processSensitiveData = this.removeSensitiveProcessData();

    // get the storage keys for encryption.
    let encryptionGroupPublicKey = this.process.destinationPublicKey;
    const encryptionUserPublicKeys: string[] = [];

    if (this.process.destinationPublicKey === this.process.submitterPublicKey ) {
      encryptionGroupPublicKey = null;
      encryptionUserPublicKeys.push(this.process.destinationPublicKey);
    }

    submitProcessState = 'Encrypting process data';
    try {
      // this tries to store the storage item to the eva-links project three times. If successful, it breaks out of the loop
      // and returns here.
      const evaStorageResponse = await this._dataStorageService.encryptData(processSensitiveData, encryptionGroupPublicKey,
        encryptionUserPublicKeys);

      //#region Update process with id of stored sensitive data record to be able to retrieve when necessary
      if ( evaStorageResponse.successful ) {
        this.process.storageId = evaStorageResponse.idUrl;
      } else {
        throw new Error('Error storing encrypted data.');
      }
      //#endregion

      let successSummaryMsg = '';
      if (evaStorageResponse.successful) {
        successSummaryMsg = successMsg ? successMsg : `Process ${processName} was stored to the storage location \n`;
      } else {
        this.logging.logMessage('Error storing encrypted data. Submission may have timed out.', true, 'error');
        return Promise.reject('Error storing encrypted data.');
      }

      // submitProcessState = 'Insert/Update process in ProcessesToSend collection';
      // await this.userService.userProcessPriorSendUpsert(this.process);

      // store the process to a new location |||
      // Add in the loop to send.
      const response = await this.signingService.signAndSendObject(this.process, ProjectSettings.types.processes.typeId);

      if (!response.accepted) {
        return Promise.reject(response.message.join(' '));
      }

      let isATBGlobal = false;
      if (this.isProcessGroupATBGlobal(this.process.groupPublicKey)) {
        isATBGlobal = true;
      }

      const evaProcessStatus: ProcessDashboardStatus = {
        processId: this.process.id,
        workflowId: this.process.workflows[0].id,
        status: 'Todo',
        exceptionType: '',
        exceptionMessage: '',
        additional: {
          processDescription: processName,
          isATBGlobal: isATBGlobal
        },
        RVZBaXN0aGViZXN0: 'RVZBaXN0aGViZXN0'
      };
      this.processDashboardService.createNewRecord(evaProcessStatus);
      // TODO: remove from last state processes collection here
      // await this.userService.userProcessDelete(this.process.id);
      this.isWaitingForProcess = false;
      this.logging.logMessage(successSummaryMsg  + `\n\n Process id :: ${this.process.id}`, true, 'success');
      // clean up the process if this wasn't successful.
      if (successMsg) {
        this.processService.announceProcessDone({ processId: this.process.id, msg: successMsg });
      }
      return;

      //#region Remarked :: TODO: clean up
      // // if a string is returned, it threw an error deleting the user process record
      // if (typeof dropUserResponse === 'string') {
      //   this.logging.logMessage('Error storing encrypted data. Submission may have timed out.', true, 'error');
      //   successMsg = 'Error storing encrypted data. Submission may have timed out.';
      // } else { // did not throw any errors

      // this.isWaitingForProcess = false;
      //   this.growl_msgs.push({
      //     severity: 'info',
      //     summary: successSummaryMsg,
      //     detail: `Process id :: ${this.process.id}`
      //     });
      //   }
      //#endregion

    } catch ( err ) {
      if (err.name === "HttpErrorResponse") {
        this.logging.logMessage('Error storing encrypted data. Submission may have timed out.', true, 'error');
        // successMsg = 'Error storing encrypted data. Submission may have timed out.';
        console.log(err);
      }
      ( !err || (Object.keys(err).length === 0 && err.constructor === Object) )
      ? submitProcessState
      : JSON.stringify(err);

      // const msg = `Process ${processName} failed to be recorded :: ERROR :: ${errMsg}`;
      const msg = `Process ${processName} failed to be recorded. \n\n This happens when the network seems to be slow. \n\n
      Please refresh EVA, wait 30 seconds and try again`;

      this.isWaitingForProcess = false;
      this.logging.logMessage(msg, false, 'error', null , 60000); // keep on screen for one minute.
    }
  }

  /**
   * This function removes sensitive data from the Process and creates it in a new object that will be used to
   * encrypt and store the data in EVA-links.
   */
  removeSensitiveProcessData(): ProcessSensitiveData {
    const processSensitiveData: ProcessSensitiveData = {
      name: this.process.name,
      descriptions: this.process.descriptions,
      interactionsValues: this.process.interactionsValues,
      notes: this.process.notes
    };

    // wipe the data from the process
    this.process.name = null;
    this.process.descriptions = null;
    this.process.interactionsValues = null;
    this.process.notes = null;

    return processSensitiveData;
  }

  // /**
  //  * This function tries to store the object to the EVA-links project. It will retry 3 times before it fails. This returns
  //  * an object of a storage request regardless of what happened.
  //  *
  //  * @param processSensitiveData this is the sensitive data used in a process
  //  * @param encryptGroupPublickey the group that the storage object is moving to.
  //  * @param encryptUserPublicKey the users encryption public key.
  //  */
  // async tryToStoreProcessData(processSensitiveData: ProcessSensitiveData, encryptionGroupPublicKey: string,
  //   encryptionUserPublicKeys: string[]): Promise<EVAStorageRequestResponse> {

  //   let evaStorageRequestResponse: EVAStorageRequestResponse = { idUrl: '', successful: false };
  //   let retryStorage = 3;
  //   // retry the storage 3 times to try and get the storage to work.

  //     while (retryStorage > 0) {
  //       retryStorage -= 1;
  //       try {
  //         const storageResponse = await this._dataStorageService.encryptData(processSensitiveData, encryptionGroupPublicKey,
  //           encryptionUserPublicKeys);
  //         // this only occurs on success.
  //         if (storageResponse.successful) {
  //           evaStorageRequestResponse = { idUrl: storageResponse.idUrl, successful: true };
  //           retryStorage = 0;
  //         }
  //       } catch (err) {
  //       }
  //     }
  //     return evaStorageRequestResponse;
  // }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.
    if (this.processRunnerSubs) {
      this.processRunnerSubs.unsubscribe();
    }
  }

  processCurrentWorkFlowName() {
    const wfIndex = this.process.workflows.map(wf => wf.id).indexOf(this.runningWorkflowId);
    return (wfIndex !== -1) ? this.process.workflows[wfIndex].name : null;
  }

  processCurrentInteractionName() {
    let intrctName = null;
    const wfIndex = this.process.workflows.map(wf => wf.id).indexOf(this.runningWorkflowId);
    if ( wfIndex !== -1 ) {
      const intrctIndex = this.process.workflows[wfIndex].interactions.map(i => i.interactionId).indexOf(this.runningInteractionId);
      if ( intrctIndex !== -1 ) intrctName = this.process.workflows[wfIndex].interactions[intrctIndex].interactionName;
    }
    return intrctName;
  }

  //#region interaction visualizer
  interactionVisualizer() {
    if (!this.runningInteractionId) return;

    // this is commented out since it was causing changeDetection angular errors..
    // this.growl_msgs = [];
    // this.growl_msgs.length = 0;

    this.messageService.add({
      life: 30000,
      severity: 'info',
      summary: "Please proceed with entering all required information",
      detail: ''
    });

    this.generateInteractionVisualizer();
  }
/**
 * loads the dynamic form visualizer. <DynamicComponent>componentRef.instance used to set values for form to be loaded
 * @param intrctVisualizerMode determines if the interaction is being run for the first time or if it is being edited
 */
  private generateInteractionVisualizer( intrctVisualizerMode?: InteractionVisualizerMode ) {
    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormVisualizerComponent);
    const componentRef: any = viewContainerRef.createComponent(componentFactory);
    this.intrctVisualizerRef = componentRef;

    // pass the user selected form screen into visualizer
    if (this.selectedFormScreen > -1) {
      (<DynamicComponent>componentRef.instance).selectedFormScreen = this.selectedFormScreen;
      this.selectedFormScreen = -1;
    }

    // check if previous interaction exists, if it does, check if it was already submitted
    // if it was already submitted, user should not see a back button
    let isPreviousInteractionToEdit = false;
    const intrctIndex = this.process.interactionsValues.map(iv =>
      iv?.interactionValues?.originalId).indexOf(this.runningInteraction?.interaction?.id);
    if ( intrctIndex === -1 ) {
      if (this.process.interactionsValues.length > 0) {
        const lastInteractionIndex = this.process.interactionsValues.length - 1;
        isPreviousInteractionToEdit = !(this.process.interactionsValues[lastInteractionIndex].submitted);
      }
    } else {
      if ( intrctIndex > 0 ) {
        isPreviousInteractionToEdit = !(this.process.interactionsValues[intrctIndex - 1].submitted);
      }
    }

    (<DynamicComponent>componentRef.instance).showBackButton = isPreviousInteractionToEdit;

    // push preserved data into interactionValues, if it exists.
    // if data exists, use previouslyCompleted to indicate a jump to next interaction
    // if previous button was pushed, no 'finished' marker will be added, so just load the values as the interaction is not yet completed
    if (this.preservedInteractions) {         // eslint-disable-next-line max-len
      for (let preservedInteractionsIndex = 0; preservedInteractionsIndex < this.preservedInteractions.length; preservedInteractionsIndex++) {
        if (this.runningInteraction.interaction.id
            === this.preservedInteractions[preservedInteractionsIndex].interactionValues.originalId) {

          this.runningInteraction.interaction =
            this.interactionLoadService.mergeInteractionValuesForProcessRunner(
              this.runningInteraction.interaction,
              this.preservedInteractions[preservedInteractionsIndex].interactionValues.elements);

          // marker to jump to next interaction
          if (this.preservedInteractions[preservedInteractionsIndex].previouslyCompleted ) {
            (<DynamicComponent>componentRef.instance).previouslyCompleted = true;
          } else {
            (<DynamicComponent>componentRef.instance).previouslyCompleted = false;
          }

          this.process.interactionsValues.push(this.preservedInteractions[preservedInteractionsIndex]);
          // value was used so remove it after add back to interactionValues array
          this.preservedInteractions.splice(preservedInteractionsIndex, 1);
        }
      }
    }

    // look for valid this.interactionsValues item to load into form visualizer
    for (let interactionsValuesIndex = 0; interactionsValuesIndex < this.process.interactionsValues.length; interactionsValuesIndex++) {
      if (this.runningInteraction.interaction.id
        === this.process.interactionsValues[interactionsValuesIndex].interactionValues.originalId) {
        // eslint-disable-next-line max-len
        this.runningInteraction.interaction = this.interactionLoadService.mergeInteractionValuesForProcessRunner(this.runningInteraction.interaction, this.process.interactionsValues[interactionsValuesIndex].interactionValues.elements);
        this.process.interactionsValues.splice(interactionsValuesIndex, 1);
      }
    }

    if ( intrctVisualizerMode && intrctVisualizerMode === InteractionVisualizerMode.edit ) {
      (<DynamicComponent>componentRef.instance).visualizerMode = intrctVisualizerMode;
    }
    const executingInteractionValues = this.process.interactionsValues.find(values =>
      values.interactionValues.originalId === this.process.executingInteractionId);
    (<DynamicComponent>componentRef.instance).processInteractionValues = executingInteractionValues;
    (<DynamicComponent>componentRef.instance).dynFrmObj = this.runningInteraction.interaction;
    (<DynamicComponent>componentRef.instance).processId = this.process.id;
    (<DynamicComponent>componentRef.instance).processTitle = this.multiViewService.tabs[Routes.Process][this.tabIndex].tabName;
    (<DynamicComponent>componentRef.instance).tabIndex = this.tabIndex;
    (<DynamicComponent>componentRef.instance).processId = this.process.id;
    (<DynamicComponent>componentRef.instance).workflowId = this.runningWorkflowId;
    (<DynamicComponent>componentRef.instance).targetId = this.targetId;

  }

  removeInteractionVisualizer() {
    this.viewContainerHost.viewContainerRef.clear();
    this.messageService.clear();
  }
  //#endregion

  //#region Dialog related functions
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback, callbackData: any, callbackForFalse = null) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, { data: dialogModel });

    this.processRunnerSubs.add(
      this.generalDialogService.generalDialogChanged$
      .subscribe(
        changeObj => {
          if (changeObj) {
            if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
              callbackData['generalDialogOnChange'] = changeObj;
            }
          }
        },
        err => { console.log(err); }
      )
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callbackData.result = true;
          callback(callbackData);
        }
      } else {
        if (callbackForFalse) {
          callbackData.result = false;
          callbackForFalse(callbackData);
        }
      }
    });
  }
  //#endregion

  jsonStringify(jsonObj) {
    return JSON.stringify(jsonObj);
  }

  isProcessGroupATBGlobal(groupPublicKey: string): boolean {
    let result = false;
    const atbGlobalSubGroups = environment.atbGlobalSubGroups;
    if (atbGlobalSubGroups.find(subGroup => subGroup === groupPublicKey)) {
      result = true;
    }

    return result;
  }
  //#endregion
}
