import { Component } from '@angular/core';

@Component({
  selector: 'app-id-map-dialog',
  templateUrl: './id-map-dialog.component.html',
  styleUrls: ['./id-map-dialog.component.scss']
})
export class IdMapDialogComponent {

  userAction: string = null;        // user action selected - 'create' or 'map'

  constructor() { }

  /**
   * This function changes the user action based on user selection
   *
   * @param action Action selected by user - 'create' or 'map'
   */
  setUserAction(action: string): string {
    this.userAction = action;
    return action;
  }

}
