<mat-icon *ngIf="(sectionSelected$ | async) && sectionFeedbackModeActive" class="selected-icon">check_circle</mat-icon>
<ng-container *ngIf="section; else showHtml">
  <mat-menu #sectionMenu="matMenu">
    <button mat-menu-item (click)="triggerLink($event)"><mat-icon>link</mat-icon> Copy link to this section</button>
    <!-- <button mat-menu-item (click)="triggerFeedback($event)"><mat-icon>feedback</mat-icon> Send section feedback</button> -->
  </mat-menu>
  <button mat-icon-button [matMenuTriggerFor]="sectionMenu" class="section-link-button knowledge-print-hide" color="primary">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="0.36em" height="1.6em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 88 384"><path d="M42.5 107q-17.5 0-30-12.5T0 64t12.5-30.5t30-12.5t30 12.5T85 64T72.5 94.5t-30 12.5zm0 42q17.5 0 30 12.5T85 192t-12.5 30.5t-30 12.5t-30-12.5T0 192t12.5-30.5t30-12.5zm0 128q17.5 0 30 12.5T85 320t-12.5 30.5t-30 12.5t-30-12.5T0 320t12.5-30.5t30-12.5z" fill="currentColor"/></svg>
  </button>
  <a [id]="sectionId"></a>
  <div [innerHtml]="html | safeHtml" class="knowledge-section-html"></div>
</ng-container>
<ng-template #showHtml>
  <div [innerHtml]="html | safeHtml" class="knowledge-section-html"></div>
</ng-template>