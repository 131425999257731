
<h1 mat-dialog-title>Drag and Dropped control</h1>
<div mat-dialog-content>
  Dropped control is: {{ data.formControl }}
</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </div>
</div>


