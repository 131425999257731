/**
 * This contains the main menu file for the EVA application (default EVA Screen)
 */
import { EVAMenu } from "@eva-model/menu/menu";
import { machineLearningSVGIcon } from "./icons";
import { Routes } from "./mainMenu";

export const AIMenu: EVAMenu[] = [{
    routerLink: Routes.MachineLearning,
    svgIcon: machineLearningSVGIcon,
    text: 'Machine Learning'
}
];
