import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { AuthService } from '@eva-core/auth.service';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { UserLastState, LastState, LastStateTab, LastStateTabs } from '@eva-model/userLastState';
import { filter, pluck, debounceTime, take } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, from } from 'rxjs';
import { isEqual } from 'lodash';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { UtilsService } from '@eva-services/utils/utils.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class LastStateService {

  // Last State Document Reference
  private lastStateRef: AngularFirestoreDocument<UserLastState>;
  // Sub to our Last State Document
  // TODO: Unsubscribe this subscription upon logout
  private lastStateRefSub: Subscription;

  // This is our lastState store. This contains any local (current device) changes to lastState and also updates
  // if the lastState user doc has been changed by another device. This is the source of truth for the current device.
  // This store is always updated by another function and should not be updated directly.
  // TODO: This needs typing of all the different types of data we are storing under lastState property
  private lastState: BehaviorSubject<LastState> = new BehaviorSubject(null);
  private lastStateLocalObject: LastState;

  // Generate a UUID for this session of EVA. This way if there are multiple sessions open nuder this user, we know that if
  // the lastState update does not match our current session ID, then to accept that when listening to lastState Doc changes.
  private sessionId: string;

  // After the lastState is first received from the db and the app is initialized, this is set to true for the rest of the session.
  // This flag is to add a property to lastState upon first emission of the session, so that other components can do logic based on
  // if the state initially loaded (app initially loaded) or lastState has been updated more than once already this session.
  private firstStateEmitted = false;
  private userId: string;
  private currentTabsUpdate: { route: Routes, action: 'Add' | 'Remove' | 'Update', removedTab?: LastStateTab,
    index?: number, uniqueTabId: string }[] = [];
  private tabData = {};
  private saveIndicator: BehaviorSubject<{show: boolean, message?: string}> = new BehaviorSubject({show: false});

  // components and services subscribe to access last state store
  public lastState$: Observable<LastState> = this.lastState.asObservable();
  public saveIndicator$: Observable<{show: boolean, message?: string}> = this.saveIndicator.asObservable();

  constructor(private fireStoreService: FirestoreService,
              private authService: AuthService,
              private perf: AngularFirePerformance,
              private utilsService: UtilsService) {
    // get our last state document and set it in the store
    this.initializeLastState();
  }

  public initializeLastState(forceUpdate?: boolean) {
    from(this.authService.getUserId()).subscribe(userId => {
      if (userId) {
        this.userId = userId;
        this.initialize(userId, forceUpdate);
      }
    });
  }

  /**
   * Gets the type of the lastState when first retrieved on app load
   * Once this is checked, we set to null so we don't check it again and assume it's unchecked.
   */
  // get initializedType(): LastStateType {
  //   const type = this.lastStateInitializedType;
  //   this.lastStateInitializedType = null;
  //   return type;
  // }

  /**
   * this function triggers the last state save to firebase for updates done to state object locally in current instance.
   *
   * @param state State object with changes
   * @param tabsUpdate tab being updated
   */
  public updateSaveLastState(state: LastState, tabsUpdate?: { route: Routes, action: 'Add' | 'Remove' | 'Update',
    removedTab?: LastStateTab, index?: number, uniqueTabId: string }): void {
    // if what we are trying to save is not different than what we current have in our store, discard.
    if (!this.isLastStateDifferent(state)) {
      return;
    }
    delete state.firstStateUpdate;
    if (tabsUpdate) {
      this.currentTabsUpdate.push(tabsUpdate);
    }
    state['sessionId'] = this.sessionId; // append current session id to state before doc update
    state.updatedAt = Date.now();
    // update lastState store
    this.lastStateLocalObject = Object.assign({}, state);
    // console.log(state);
    this.lastState.next(state);
    this.saveIndicator.next({show: true, message: 'Saving...'});
  }

  /**
   * gets last state document of currently authenticated user
   *
   * @return - current state inside the datastore
   */
  // TODO: Don't get a new doc here since we always subscribed now
  public getLastState(): LastState {
    return this.lastState.getValue();
  }

  /**
   * compares any obj with the latest state and check if different
   *
   * @param newState an object to compare with the latest state
   */
  private isLastStateDifferent(newState: LastState): boolean {
    const prevState = this.lastStateLocalObject;

    const value = !isEqual( prevState, newState );

    return value;
  }

  /**
   * initializes the service with the current user last state
   *
   * subscribes to last state document and updates our lastState subject
   *
   * observes our lastState and monitors if this client or a different client made a change. if this client did then
   * we update the state document in the database.
   */
  private initialize(userId: string, forceUpdate?: boolean) {
    // generate a session id for this EVA session to attach to all updates to lastState coming from this session.
    let update = forceUpdate;
    this.sessionId = this.generateSessionId();

    // set last state document reference
    this.lastStateRef = this.fireStoreService.doc<UserLastState>('users/' + userId + '/State/LastStateV2');

    // subscribe to our last state document changes and update our lastState store for the client since starting subject value is null.
    this.lastStateRefSub = this.lastStateRef.valueChanges().pipe(
      trace('last-state-initialize'),
      filter(state => !!state),
      // only get the lastState property of the doc object
      pluck('lastState'),
      filter(stateDocChange => !!stateDocChange),
      // ensure we are only updating our local store if the doc session id is different than our session id
      filter((stateDocChange: LastState) => {
        return (!stateDocChange.sessionId || stateDocChange.sessionId !== this.sessionId || update);
      })
    ).subscribe((updatedState: LastState) => {
      // console.log(updatedState);

      // add prop to lastState for all subscribers if this is the first lastState of the session.
      if (!this.firstStateEmitted) {
        updatedState['firstStateUpdate'] = true;
      }

      if (!updatedState.tabs) {
        updatedState.tabs = {};
      }

      updatedState['shouldNotUpdateDatabase'] = update ? false : true;
      // ensure the update coming through doesn't cause an endless doc update loop.
      this.lastStateLocalObject = Object.assign({}, updatedState);
      this.lastState.next(updatedState);
      update = false;
    });

    // listen for local state changes and if no new state changes in 3000ms, if this device was the last one to update state then
    // send that last change to the database to be recorded to the document.
    this.lastState$.pipe(
      debounceTime(3000),
      filter(state => state && !state.shouldNotUpdateDatabase)
    ).subscribe(async (state) => {
      try {
        state['sessionId'] = this.sessionId; // append current session id to state before doc update
        // remove local last state object properties if they got saved accidentally to the database
        delete state.shouldNotUpdateDatabase;
        delete state.firstStateUpdate;
        delete state.tabs[Routes.Home];
        state.tabsUpdated = [];
        // if there are any local tab updates, update the tab documents for respective route collections in the database
        if (this.currentTabsUpdate.length > 0) {
          const arrDistinct = [];
          for (const update of this.currentTabsUpdate) {
            let flag = false;
            if (arrDistinct.indexOf(update.uniqueTabId) === -1) {
              arrDistinct.push(update.uniqueTabId); // arr with unique tab ids for all updates
            }
            if (update.action === 'Add' || update.action === 'Update') {
              const updateItems: {index: number}[] = [];
              // go through all the updates and find duplicates, if removing a tab, discard all other updates to that tab
              this.currentTabsUpdate.forEach((item, arrIndex) => {
                if ((item.action === 'Update' || item.action === 'Add') && item.uniqueTabId === update.uniqueTabId) {
                  updateItems.push({
                    index: arrIndex
                  });
                }
                if (item.action === 'Remove' && item.uniqueTabId === update.uniqueTabId && updateItems.length > 0) {
                  flag = true;
                  arrDistinct.splice(arrDistinct.indexOf(update.uniqueTabId));  // remove all occurrences of this tabId
                }
              });
              // remove any duplicate updates
              updateItems.forEach((item, arrIndex) => {
                if (arrIndex !== (updateItems.length - 1) || flag) {
                  this.currentTabsUpdate.splice(item.index, 1);
                }
              });
            }
          }
          // append all the local tab changes to tabsUpdated array for every update in a distinct tab
          state.tabsUpdated = state.tabsUpdated.concat(arrDistinct);
          // update the route collections with updated tab data for respective tab documents
          state.tabs = await this.updateLastStateTabCollections(state.tabs);
          // clear local tab updates since they have been saved to the database
          this.currentTabsUpdate = [];
          // go through the last state object and remove any additional data for tabs and keep only the uniqueTabId to be stored to database
          Object.keys(state.tabs).forEach((route: Routes) => {
            for (const tab of state.tabs[route]) {
              if (tab.additionalInstanceData && tab.additionalInstanceData.uniqueTabId
                && Object.keys(tab.additionalInstanceData).length > 1) {
                // store the additional tab data locally which gets re-added afterwards to local state object
                this.tabData[tab.additionalInstanceData.uniqueTabId] = JSON.parse(JSON.stringify(tab.additionalInstanceData));
                // preserve knowledge additional data
                if (route !== Routes.Knowledge) {
                  tab.additionalInstanceData = { uniqueTabId: tab.additionalInstanceData.uniqueTabId };
                }
              }
            }
          });
        }
        // save the last state object to the database
        await this.fireStoreService.upsert(this.lastStateRef, JSON.parse(JSON.stringify({lastState: state})));

        // console.log('state update sent: ', JSON.parse(JSON.stringify(state)));
        // this function updates the local state object with previously saved tab data
        this.updateLocalLastStateWithTabData(state.tabs);
        // console.log('local state after update sent: ', state);
        // this updates the save indicator to saved because at this point the changes have been saved to the database
        this.saveIndicator.next({show: false, message: 'Changes saved'});
        // this clears the save indicator text after 3 seconds
        setTimeout(() => {
          this.saveIndicator.next({show: false, message: ''});
        }, 3000);
      } catch (err) {
        console.error('failed to update state...', err);
      }
    });

    // subscribe and get the first valid submission, setting the type lastState
    // was on initialization and that lastState emitted, then complete.
    this.lastState$.pipe(
      filter(state => !!state),
      take(1)
    ).subscribe((state) => {
      this.firstStateEmitted = true;
    });
  }

  /**
   * This function updates the local state object with removed additional tab data which is stored in the sub-collection
   * and not in last-state object directly
   *
   * @param tabs all of last state tabs
   */
  private updateLocalLastStateWithTabData(tabs: LastStateTabs): void {
    Object.keys(this.tabData).forEach(uniqueTabId => {
      let changedTab: LastStateTab;
      Object.keys(tabs).forEach((tab: Routes) => {
        const currentRouteTabs = tabs[tab];
        changedTab = currentRouteTabs.find(routeTab =>
          routeTab.additionalInstanceData && routeTab.additionalInstanceData.uniqueTabId === uniqueTabId);
      });
      if (changedTab) {
        changedTab.additionalInstanceData = {
          ...changedTab.additionalInstanceData,
          ...this.tabData[uniqueTabId]
        };
      }
    });
  }

  /**
   * This function updates the route collection in the database for the updated tabs with respective uniqueTabId as tab document
   *
   * @param tabs all the tabs in local last state object
   */
  private async updateLastStateTabCollections(tabs: LastStateTabs): Promise<LastStateTabs> {
    for (const tabUpdate of this.currentTabsUpdate) {
      const currentRouteTabs = tabs[tabUpdate.route];
      if (!currentRouteTabs) {
        continue;
      }
      // perform updates to the database documents based on the action
      switch (tabUpdate.action) {
        case 'Add':
          const newAddedTab = currentRouteTabs[currentRouteTabs.length - 1];
          currentRouteTabs[currentRouteTabs.length - 1] = await this.addUpdateRouteTabToCollection(newAddedTab, tabUpdate.route);
          break;
        case 'Remove':
          await this.removeRouteTabFromCollection(tabUpdate.removedTab, tabUpdate.route);
          break;
        case 'Update':
          const updatedTab = currentRouteTabs[tabUpdate.index];
          if (updatedTab) {
            currentRouteTabs[tabUpdate.index] = await this.addUpdateRouteTabToCollection(updatedTab, tabUpdate.route);
          }
          break;
        default: break;
      }
      tabs[tabUpdate.route] = currentRouteTabs;
    }
    return tabs;
  }

  /**
   * This function adds or updates the tab being updated
   *
   * @param tab tab being added or updated
   * @param route Route containing the tab
   */
  private async addUpdateRouteTabToCollection(tab: LastStateTab, route: Routes): Promise<LastStateTab> {
    const userLastStateDocPath = 'users/' + this.userId + '/State/LastStateV2/';
    let collectionPath = userLastStateDocPath;

    // Grab the collection name based on the route being updated
    switch (route) {
      case Routes.InteractionBuilder: collectionPath += 'InteractionBuilder'; break;
      case Routes.WorkflowBuilder: collectionPath += 'WorkflowBuilder'; break;
      case Routes.Process: collectionPath += 'Processes'; break;
      case Routes.Knowledge: collectionPath += 'Knowledge'; break;
      default: return;
    }

    const collection = this.fireStoreService.col<{data: string}[]>(collectionPath);
    // console.log('added/updated tab: ', tab.additionalInstanceData);
    if (Object.keys(tab.additionalInstanceData).length > 1) {
      await collection.doc<{data: string}>(tab.additionalInstanceData.uniqueTabId).set({
        data: JSON.stringify(tab.additionalInstanceData, this.utilsService.getCircularReplacer())
      }, { merge: true });
      // this.tabData[tab.additionalInstanceData.uniqueTabId] = JSON.parse(JSON.stringify(tab.additionalInstanceData));
      // tab.additionalInstanceData = { uniqueTabId: tab.additionalInstanceData.uniqueTabId };
    }
    return tab;
  }

  /**
   * This function removes the tab document from the specified route collection
   *
   * @param tab tab being removed
   * @param route route being updated
   */
  private async removeRouteTabFromCollection(tab: LastStateTab, route: Routes): Promise<void> {
    const userLastStateDocPath = 'users/' + this.userId + '/State/LastStateV2/';
    let collectionPath = userLastStateDocPath;

    // Grab the collection name based on the route being updated
    switch (route) {
      case Routes.InteractionBuilder: collectionPath += 'InteractionBuilder'; break;
      case Routes.WorkflowBuilder: collectionPath += 'WorkflowBuilder'; break;
      case Routes.Process: collectionPath += 'Processes'; break;
      case Routes.Knowledge: collectionPath += 'Knowledge'; break;
      default: return;
    }
    const collection = this.fireStoreService.col<{data: string}[]>(collectionPath);
    // console.log('removed tab: ', tab.additionalInstanceData.uniqueTabId);
    delete this.tabData[tab.additionalInstanceData.uniqueTabId];
    return await collection.doc<{data: string}>(tab.additionalInstanceData.uniqueTabId).delete();
  }

  /**
   * This function fetches the tab data from the specified route collection
   *
   * @param tab tab being fetched containing uniqueTabId for the doc name
   * @param route Route being updated with fetched tab doc
   */
  public getRouteTabFromCollection(tab: LastStateTab, route: Routes)
    : Observable<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    const userLastStateDocPath = 'users/' + this.userId + '/State/LastStateV2/';
    let collectionPath = userLastStateDocPath;

    // Grab the collection name based on the route being read
    switch (route) {
      case Routes.InteractionBuilder: collectionPath += 'InteractionBuilder'; break;
      case Routes.WorkflowBuilder: collectionPath += 'WorkflowBuilder'; break;
      case Routes.Process: collectionPath += 'Processes'; break;
      case Routes.Knowledge: collectionPath += 'Knowledge'; break;
      default: return;
    }
    const collection = this.fireStoreService.col<{data: string}[]>(collectionPath);
    return collection.doc<{data: string}>(tab.additionalInstanceData.uniqueTabId).get();
  }

  /**
   * This function returns the current session ID
   */
  public getSessionId(): string {
    return '' + this.sessionId;
  }

  /**
   * Clears any state inside our data store
   */
  public clearLastStateStore(): void {
    this.lastState.next(null);
  }

  /**
   * Generates a unique UUID purely for session tracking on lastState.
   * Does not need to always be unique since it's just used temporarily and generated with each new EVA Session.
   * from: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
   */
  private generateSessionId(): string {
    const S4 = function() {
      // eslint-disable-next-line no-bitwise
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  }

}
