import { Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-control-dialog',
  templateUrl: './form-control-dialog.component.html',
  styleUrls: ['./form-control-dialog.component.css']
})
export class FormControlDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<FormControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

