
import { Guid } from "@eva-core/GUID/guid";

export class Log {

    public id: string;

    constructor(
        public logLevel: string,
        public entityType: string,
        public entityId: string,
        public error: string,
        public uid: string
    ) {
        this.id = Guid.newGuid().toString();
    }
}

export enum LogLevel {
    Info = "Info",
    Warn = "Warn",
    Error = "Error",
    Fatal = "Fatal"
}

export enum EntityType {
    interaction = "interaction",
    workflow = "workflow",
    process = "process",
    api = "api",
    technicalRequirement = "technical requirement",
    change = "change",
    other = "other"
}
