import {TutorialModel} from "./tutorial";

/**
 * This file contains the shapes to find and the steps that will occur when a tutorial is loaded.
 */

export const dashboardTutorialModel: TutorialModel = {
  introJsConfig: {
    steps:  [{
      intro: '<b style="white-space: nowrap;">Welcome to the Process Dashboard!</b> <br/><br/>'
      + 'This new feature will allow you to see real time updates on all submitted processes '
      + '(including that of others!). Let us walk you through how it works.'
      ,
      position: 'top'
    },
    {
      element: '#dashboardTutorialStep2',
      intro: 'Here you can search by Process ID, Name, Description, Requester and/or Status',
      position: 'top'
    },
    {
      element: '',
      intro: 'To rearrange columns, drag the column header',
      position: 'bottom'
    },
    {
      element: '#dashboardTutorialStep1',
      intro: 'If a process has thrown an exception, you will see a comment icon next to the status of the process.'
        + ' You can hover over this icon for 2 seconds to see the details of the exception.',
      position: 'top'
    },
    {
      element: '#dashboardTutorialStep3',
      intro: 'If there are service disruptions or announcements, you’ll see a status message above the table',
      position: 'top'
    },
    {
      element: '#dashboardTutorialStep4',
      intro: 'Select this if you would like to refresh the table data after every 10 seconds for a minute after inactivity',
      position: 'bottom'
    },
    {
      element: '#dashboardTutorialStep5',
      intro: 'When turned on you’ll receive a completion email on successfully completed processes that you’ve submitted',
      position: 'bottom'
    }],
    exitOnEsc: false,
    exitOnOverlayClick: false,
    disableInteraction: true
  }
};

