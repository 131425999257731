import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { from as fromPromise } from 'rxjs';

// import { flatMap } from 'rxjs/observable/flatMap';

import { AuthService } from '@eva-core/auth.service';
import { environment } from '@environments/environment';
import { WINDOW } from '../injection';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class EVAInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
    @Inject(WINDOW) private window: Window) {
     // private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // check the different values for where things are headed.
    if (this.includeTokenInRequest(this.window.location.hostname, req)) {
      const authToken = this.authService.firebaseAuthState;
      // console.log(this.authService.firebaseAuthState);

      let refreshToken = false;
      if (authToken && authToken.Nb && authToken.Nb.h && authToken.Nb.h.c) {
        const nowDate = Date.now() + 3300000;
        const tokenDate = authToken.Nb.h.c;
        // console.log('Now: ', nowDate);
        // console.log('Auth Token: ', tokenDate);
        if (nowDate <= tokenDate) {
          refreshToken = true;
        }
      }

      // if this is expired:
      return fromPromise(this.authService.getIdToken(refreshToken))
      .pipe(
        switchMap(token => {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'AuthApp': environment.authApp,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
          });
          const clone = req.clone({ headers });

          return next.handle(clone);
        }),
        catchError((err, caught) => {
          if (req.headers.get('handleError') === 'onService') {
            console.log('Interceptor does nothing...');
          } else {
            console.log('onInterceptor handle ', err);
          }
          return throwError(err);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  // ensure the information is headed to the correct place and in the correct environment.
  includeTokenInRequest(domain, req) {
    switch (domain) {
      case 'localhost':
        return this.checkLocalOrDev(req);
      case 'dev.atbeva.com':
        return this.checkLocalOrDev(req);
      case 'test.atbeva.com':
        return this.checkTest(req);
      case 'staging.atbeva.com':
        return this.checkStaging(req);
      case 'atbeva.com':
        return this.checkProd(req);
    }
  }

  checkLocalOrDev(req) {
    if (req.url.includes('us-central1-atb-dynamic-form.cloudfunctions.net') // interactions
    || req.url.includes('us-central1-eva-atb-prod.cloudfunctions.net') // base
    || req.url.includes('localhost:4200') // local
    || req.url.includes('localhost:5000') // local
    || req.url.includes('us-central1-eva-heart-test.cloudfunctions.net') // heart
    || req.url.includes('us-central1-block-genesis.cloudfunctions.net') // blockchain
    || req.url.includes('us-central1-atb-eva-search.cloudfunctions.net') // search
    || req.url.includes('us-central1-eva-links-atb-test.cloudfunctions.net') // links ATB
    || req.url.includes('us-central1-eva-links-test.cloudfunctions.net') // links public
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkTest(req) {
    if (req.url.includes('us-central1-spark-dynamic-form-test.cloudfunctions.net') // interactions
    || req.url.includes('us-central1-eva-spark-test.cloudfunctions.net') // base
    || req.url.includes('us-central1-eva-heart-spark.cloudfunctions.net') // heart
    || req.url.includes('us-central1-spark-block-genesis-test.cloudfunctions.net') // blockchain
    || req.url.includes('us-central1-atb-eva-search-spark.cloudfunctions.net') // search
    || req.url.includes('us-central1-eva-links-atb-spark.cloudfunctions.net') // links ATB
    || req.url.includes('us-central1-eva-links-spark.cloudfunctions.net') // links public
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkStaging(req) {
    if (req.url.includes('us-central1-wiki-dynamic-form-test.cloudfunctions.net') // interactions
    || req.url.includes('us-central1-eva-wiki-test.cloudfunctions.net') // base
    || req.url.includes('us-central1-eva-heart-wiki.cloudfunctions.net') // heart
    || req.url.includes('us-central1-wiki-block-genesis-test.cloudfunctions.net') // blockchain
    || req.url.includes('us-central1-atb-eva-search-wiki.cloudfunctions.net') // search
    || req.url.includes('us-central1-eva-links-atb-wiki.cloudfunctions.net') // links ATB
    || req.url.includes('us-central1-eva-links-wiki.cloudfunctions.net') // links public
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkProd(req) {
    if (req.url.includes('us-central1-atb-interactions.cloudfunctions.net') // interactions
    || req.url.includes('us-central1-atb-eva.cloudfunctions.net') // base
    || req.url.includes('us-central1-eva-heart.cloudfunctions.net') // heart
    || req.url.includes('us-central1-atb-eva-blockchain.cloudfunctions.net') // blockchain
    || req.url.includes('us-central1-atb-eva-search-prod.cloudfunctions.net') // search
    || req.url.includes('us-central1-eva-links.cloudfunctions.net') // links ATB
    || req.url.includes('us-central1-eva-links-atb.cloudfunctions.net') // links public
    ) {
      return true;
    } else {
      return false;
    }
  }

  // not currently used:
  // || req.url.includes('us-central1-atb-workflow.cloudfunctions.net')
  // || req.url.includes('us-central1-atb-service-now.cloudfunctions.net')
  // || req.url.includes('us-central1-spark-workflow-test.cloudfunctions.net')
  // || req.url.includes('us-central1-wiki-workflow-test.cloudfunctions.net')
}
