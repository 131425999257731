import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { EVAThemes } from '@eva-model/themes';
import { OverlayContainer } from '@angular/cdk/overlay';

const LOCAL_STORAGE_KEY = 'eva-theme';
const THEME_PREFIX = 'theme-';

/**
 * Controls the theme of EVA
 * 
 * If you want to use css and add selectors to a theme, the body of the application 
 * will have the class `theme-eva` or whatever theme is active as the suffix
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private _currentTheme: string = EVAThemes[0].id;
  renderer: Renderer2; // because this is a service, we need to create a renderer

  constructor(private rendererFactory: RendererFactory2,
              private overlayContainer: OverlayContainer) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    // set theme from users preferences
    this.loadTheme();
  }

  /**
   * Returns active theme name
   * Will be a theme id from EVAThemes model
   */
  get currentTheme(): string {
    return this._currentTheme;
  }

  get currentThemeType(): string {
    return EVAThemes.find((theme) => theme.id === this._currentTheme).type;
  }

  /**
   * Loads the currently set theme in user preferences
   */
  private loadTheme() {
    const userTheme: string = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (userTheme && EVAThemes.map(t => t.id).includes(userTheme)) {
      this.setTheme(userTheme);
    } else {
      this.setTheme(this._currentTheme);
    }
  }

  /**
   * Sets the current theme in storage and makes active
   */
  setTheme(theme: string) {
    // theme css classes
    const currentTheme = `${THEME_PREFIX}${this._currentTheme}`;
    const newTheme = `${THEME_PREFIX}${theme}`;

    // remove current theme
    this.renderer.removeClass(document.body, currentTheme);
    this.overlayContainer.getContainerElement().classList.remove(currentTheme);
    // set new theme
    this.renderer.addClass(document.body, newTheme);
    // set overlay containers with new theme
    this.overlayContainer.getContainerElement().classList.add(newTheme);

    this._currentTheme = theme;

    // save selection choice
    localStorage.setItem(LOCAL_STORAGE_KEY, theme);
  }
}
