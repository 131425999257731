<div fxLayout="column" fxLayoutAlign="stretch" class="chat-full-container" [@toggleChat]="isChatOpen ? 'open': 'closed'">
  <div class="eva-box-top-inline">
    <span class="eva-box-top-title">EVA Chat</span>
    <button mat-icon-button (click)="minimizeChat()" aria-label="Minimize EVA Chat" matTooltip="Minimize EVA Chat" focusable="false">
      <mat-icon>expand_more</mat-icon>
    </button>
    <!-- <svg class="svg-inline--fa fa-chevron-down fa-w-14" (click)="minimizeChat()" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg> -->
  </div>
  <div class="top-section" id="chatScrollContainer">
    <div class="chat-messages-container">
      <app-chat-messages class="app-chat-messages" [heartResponse]="result"></app-chat-messages>
    </div>
  </div><!-- END Top Section -->

  <div class="bottom-section-card">
    <div class="bottom-section">
      <div class="input-bottom">
        <!-- Input Form -->
        <form #questions="ngForm" (keydown.enter)="$event.preventDefault()">
          <!-- Input Field For When Transcribe Mode Off -->
          <mat-form-field class="full-width-input question-input" *ngIf="!isTranscribe" floatLabel="never">
            <input #questionInput="matInput" id="step1" matInput (keydown.enter)="askQuestion(newQuestion)" (keyup)="onQueryValueChange($event)" autocomplete="off" [(ngModel)]="newQuestion" name="newQuestion" [placeholder]="queryInputPlaceholder" autofocus> <!--[disabled]="chatService.chatInProgress$ | async"-->
            <!-- Helpful hints on what to ask EVA -->
            <!-- <mat-hint align="start" *ngFor="let hint of (chatInputHints$ | async)" @newText>
              <div>{{ hint }}</div>
            </mat-hint> -->

            <!-- SEND Button -->
            <div fxLayout="row" class="float-right send-knowledge-switch" fxLayoutAlign="center center">
              <button fxFlex *ngIf="!currentlyRecording" [disabled]="!newQuestion" mat-raised-button color="primary" type="button" (click)="askQuestion(newQuestion)">
                Send
              </button>
              <mat-slide-toggle fxFlex color="primary" id="chat_knowledge_toggle" class="chat-knowledge-switch" [checked]="knowledgeOnly" (toggleChange)="toggleKnowledgeOnly()">Knowledge Only</mat-slide-toggle>
            </div>
          </mat-form-field> <!-- END Input Field For When Transcribe Mode Off -->

          <!-- Input / Record Section For When Transcribe Mode On -->
          <mat-form-field class="full-width-input question-input" *ngIf="isTranscribe">
            <!-- Input Text Area -->
            <textarea #questionInput="matInput" id="step1" matInput (keydown.enter)="askQuestion(newQuestion)" autocomplete="off" [(ngModel)]="newQuestion" name="newQuestion" autofocus></textarea> <!-- [disabled]="chatService.chatInProgress$ | async" -->
            <!-- Helpful hints on what to ask EVA -->
            <!-- <mat-hint align="start" *ngFor="let hint of (chatInputHints$ | async)" @newText>
              <div>{{ hint }}</div>
            </mat-hint> -->
            <!-- SEND Button -->
            <div fxLayout="row" class="float-right send-knowledge-switch" fxLayoutAlign="center center">
              <button fxFlex class="right-float" *ngIf="!currentlyRecording" [disabled]="!newQuestion" mat-raised-button color="primary" type="button" (click)="askQuestion(newQuestion)">
                Send
              </button>
              <mat-slide-toggle fxFlex color="primary" id="chat_knowledge_toggle" class="chat-knowledge-switch" [checked]="knowledgeOnly" (click)="toggleKnowledgeOnly()">Knowledge Only</mat-slide-toggle>
            </div>
          </mat-form-field><!-- END Duplicate Input / Record Section -->
        </form> <!-- END Input Form When Transcribe Mode On -->
      </div> <!-- END Input Bottom -->

      <!-- ACTION Buttons Voice On/Mute/Unmute, Speech Mode & Send -->
      <mat-card-actions class="mat-card-actions-space" float="clear">
        <!-- VOICE ON/Mute/Unmute Button -->
        <button id="step2" mat-stroked-button color="primary" type="button" (click)="toggleSpeech(speechEnabled)" [matTooltip]="speechEnabled ? 'Stop announcing EVA Chat' : 'Announce EVA Chat'">
          <mat-icon *ngIf="speechEnabled">volume_off</mat-icon>
          <mat-icon *ngIf="!speechEnabled">volume_up</mat-icon>
          <span *ngIf="(chatService.isLeftPaneActive$ | async) === null || (chatService.isLeftPaneActive$ | async) === undefined || (chatService.isLeftPaneActive$ | async) === false" class="hide-button-text">{{ speechEnabled ? ' Mute EVA' : ' Unmute EVA' }}</span>
        </button>

        <!-- Show Workflows Button -->
        <!-- <button id="step5" *ngIf="showWorkflowButton" mat-raised-button type="button" color="primary" (click)="toggleAvailableWorkflows()" matTooltip="Show available Processes">
          <mat-icon id="workflowButton">open_in_new</mat-icon>
          <span *ngIf="!(chatService.isLeftPaneActive$ | async)" class="hide-button-text">Processes</span>
        </button> -->

        <!-- spacer for each side -->
        <div fxFlex></div>

        <div class="button-row-left">
          <!-- Record?/Talk to EVA Button -->
          <button id="step3" matSuffix mat-stroked-button [color]="currentlyRecording ? 'warn' : 'primary'" type="button" (click)="toggleSpeechRecording()" [matTooltip]="currentlyRecording ? 'Stop talking to EVA' : 'Talk to EVA'">
            <mat-icon *ngIf="currentlyRecording">mic_off</mat-icon>
            <mat-icon *ngIf="!currentlyRecording">mic</mat-icon>
            <span *ngIf="(chatService.isLeftPaneActive$ | async) === null || (chatService.isLeftPaneActive$ | async) === undefined || (chatService.isLeftPaneActive$ | async) === false" class="hide-button-text">{{ currentlyRecording ? ' Listening...' : ' Talk to EVA' }}</span>
          </button>
  
          <!-- <span class="vertical-divider"></span> -->
  
          <!-- SPEECH MODE Button -->
          <button id="step4" [disabled]="currentlyRecording" mat-stroked-button [color]="isTranscribe ? 'warn' : 'primary'" type="button" (click)="isTranscribe = !isTranscribe" [matTooltip]="isTranscribe ? 'Transcribe Mode' : 'Speech Mode'">
            <mat-icon *ngIf="isTranscribe">edit_off</mat-icon>
            <mat-icon *ngIf="!isTranscribe">create</mat-icon>
            <span *ngIf="(chatService.isLeftPaneActive$ | async) === null || (chatService.isLeftPaneActive$ | async) === undefined || (chatService.isLeftPaneActive$ | async) === false" class="hide-button-text">{{ isTranscribe ? ' Transcribe Mode' : ' Speech Mode' }}</span>
          </button>
        </div>

      </mat-card-actions><!-- END ACTION Buttons Voice On/Mute/Unmute, Speech Mode & Send -->
    </div><!-- END Bottom Section -->
  </div>

</div>
