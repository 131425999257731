import { searchDirection } from "./interactionFormControlModel";

/**
 * This enum represents the emitters who emit the value update for a form control in an interaction in a process
 */
export enum InteractionValueEmitter {
    LastState,      // Value updated from last state
    Chat,           // Value updated by user from chat
    FormControl,    // Value updated from form control directly in the interaction
    DialogFlow      // Value updated from dialogflow
}

/**
 * This is the model for the emitted value announcement
 */
export class InteractionEmitValueModel {

    constructor(
        public interactionId: string,                           // Id of the interaction
        public interactionOriginalId: string,                   // Original Id of the interaction
        public interactionHint: string,                         // form control hint
        public interactionLabel: string,                        // form control label
        public elementOriginalId: string,                       // form control original id
        public scrnIndex: number,                               // form screen index containing the form control
        public formControlId: string,                           // form control id
        public value: any,                                      // form control value
        public inputType?: string,                              // form control input type (only if "type" is Input)
        public type?: string,                                   // form control type
        public lastEmittedFrom?: InteractionValueEmitter,       // who emitted the value update
        public dynamicOptions?: Array<any>,                     // form control options (if exists, e.g. options to select)
        public processId?: string,                              // process id
        public checkboxGroups?: any[],                          // form control checkbox group options
        public isSpecialControl?: boolean,                      // whether the current form control is special or not
        public direction?: searchDirection,                     // choose the next form control in this direction
        public isPreview?: boolean,                             // whether this value update from a preview or not
        public isCanadaPostWatchControl?: boolean,              // whether this is the canada post watch control
        public isEditInteractionFlow?: boolean,                 // whether this is the edit interaction flow
        public tabIndex?: number,                               // current tab index this process is running on
        public processTitle?: string,                           // title of the process,
        public updatedFromLastState?: boolean
    ) {  }

}
