import { Component, Input } from '@angular/core';
import { Process } from '@eva-model/process/process';
import { ProcessService } from '@eva-services/process/process.service';
import { WorkflowNotificationsService } from '@eva-services/notifications/workflow-notifications.service';

@Component({
  selector: 'eva-process-summary',
  templateUrl: './process-summary.component.html',
  styleUrls: ['./process-summary.component.scss']
})
export class ProcessSummaryComponent {

  process: Process;
  processInteractions: any[];

  isWaitingForDoneProcess: boolean;

  isWaiting: boolean;
  onWaitMessage: string;

  @Input()
  set processObj(processObj: Process) {
    if (!processObj) { return; }

    this.process = processObj;
    this.initSummary();
  }

  @Input() isCloseButtonDisable: boolean;
  @Input() isDoneButtonEnable: boolean;
  @Input() processRunnerId: string;

  constructor(
    private processService: ProcessService,
    private workflowNotificationService: WorkflowNotificationsService
  ) { }

  initSummary() {
    this.processInteractions = [];
    this.processInteractions.length = 0;

    if (!(this.process.interactionsValues &&
      Array.isArray(this.process.interactionsValues) &&
      this.process.interactionsValues.length > 0)) return;

    for (let iCtr = 0; iCtr < this.process.interactionsValues.length; iCtr++) {
      // TODO :: Look into the order property as it's optional
      // const ivIndex = this.process.interactionsValues.map(iv => iv.order).indexOf(iCtr);
      const ivIndex = iCtr;
      if (ivIndex !== -1) {

        const intrctValueObj: any = this.process.interactionsValues[ivIndex];
        const wfIntrctIndex = this.process.workflows[0].interactions.map(i => i.interactionId)
          .indexOf(intrctValueObj.interactionValues.originalId);
        if (wfIntrctIndex !== -1) {
          const intrct: any = JSON.parse(JSON.stringify(this.process.workflows[0].interactions[wfIntrctIndex].interaction));
          if (intrct.FormScreens && Array.isArray(intrct.FormScreens) && intrct.FormScreens.length > 0 &&
            intrctValueObj.interactionValues &&
            intrctValueObj.interactionValues.elements &&
            Array.isArray(intrctValueObj.interactionValues.elements) &&
            intrctValueObj.interactionValues.elements.length > 0) {

            intrctValueObj.interactionValues.elements.forEach(elmnt => {
              const intrctScrnIdx = intrct.FormScreens.map(ifs => ifs.order).indexOf(elmnt.scrnIndex);
              if (intrctScrnIdx !== -1) {
                const intrctElmntIdx = intrct.FormScreens[intrctScrnIdx].FormElements.map(iElmnt => iElmnt.id).indexOf(elmnt.originalId);
                if (intrctElmntIdx !== -1) intrct.FormScreens[intrctScrnIdx].FormElements[intrctElmntIdx].value = elmnt.value;
              }
            });
          }

          this.processInteractions.push(intrct);
        }
      }
    }
  }

  closeProcessSummary() {
    this.processService.announceProcessSummaryDone({ processId: this.process.id, processRunnerId: this.processRunnerId, isClose: true });
  }

  doneProcess() {

    this.isWaitingForDoneProcess = true;

    this.workflowNotificationService.doneProcess(this.process.id, this.process)
      .then(response => {
        this.isWaitingForDoneProcess = false;
        this.processService.announceProcessSummaryDone({ processId: this.process.id, processRunnerId: this.processRunnerId, isDone: true });
      })
      .catch(err => {
        this.isWaitingForDoneProcess = false;

        console.log(err);
      });

  }

  /**
   * This is used to stringify the object in the HTML functions.
   * @param jsonObj any object that is going to be bound in the component.
   */
  jsonStringify(jsonObj: any): string {
    return JSON.stringify(jsonObj);
  }

}
