import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowGraphService {

  // Observable any sources
  private workflowGraphChangeSource = new Subject<any>();

  // Observable any streams
  workflowGraphChanged$ = this.workflowGraphChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceChange(change: any) {
    this.workflowGraphChangeSource.next(change);
  }
}
