import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { KnowledgeFeedbackService } from '@eva-services/knowledge/feedback/knowledge-feedback.service';
import { Subscription } from 'rxjs';
import { TfidfFeedbackDocument } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { LoggingService } from '@eva-core/logging.service';

@Component({
  selector: 'app-knowledge-feedback-item',
  templateUrl: './knowledge-feedback-item.component.html',
  styleUrls: ['./knowledge-feedback-item.component.scss']
})
export class KnowledgeFeedbackItemComponent implements OnInit, OnDestroy {

  @Input() feedback: TfidfFeedbackDocument;
  announceHighlight$: Subscription;

  feedbackClicked = false;
  updatingFeedback = false;

  constructor(
    private knowledgeFeedbackService: KnowledgeFeedbackService,
    private logging: LoggingService
  ) { }

  ngOnInit() {
    this.announceHighlight$ = this.knowledgeFeedbackService.announceSectionHighlight$.subscribe((sectionId) => {
      if (sectionId === this.feedback.docSection) {
        this.feedbackClicked = true;
      } else {
        this.feedbackClicked = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.announceHighlight$) {
      this.announceHighlight$.unsubscribe();
    }
  }

  highlightSectionInEditor(sectionId: string) {
    this.knowledgeFeedbackService.announceSectionHighlight(sectionId);
  }

  /**
   * Sends the feedback object to the server for an update.
   */
  async updateFeedback(): Promise<void> {
    try {
      this.feedback.isReviewed = true;
      await this.knowledgeFeedbackService.updateTfidfFeedbackAsReviewed(this.feedback);
    } catch (err) {
      this.feedback.isReviewed = false; // reset status
      this.logging.logMessage('Failed to update feedback', false, 'error');
    }
  }

}
