import { Component, ComponentFactory, ComponentRef, ViewContainerRef, Inject, ViewChild, AfterViewInit,
  ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { IfThenLogicAction, IfThenLogicOptions, InteractionElementRelationDialogModel, Relation } from '@eva-model/interactionElementRelationDialogModel';
// eslint-disable-next-line max-len
import { InteractionElementRelationComponent } from '@eva-ui/form-builder/interaction-element-relation/interaction-element-relation.component';
import { InteractionElementRelationService } from '@eva-services/interacton-element-relation/interaction-element-relation.service';
import { Subscription } from 'rxjs';
import { InteractionElementRelationTipsComponent } from '../interaction-element-relation-tips/interaction-element-relation-tips.component';

@Component({
  selector: 'eva-interaction-element-relation-dialog',
  templateUrl: './interaction-element-relation-dialog.component.html',
  styleUrls: ['./interaction-element-relation-dialog.component.scss']
})
export class InteractionElementRelationDialogComponent implements OnDestroy, AfterViewInit {

  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  private elementRelationChangeSubscription: Subscription;
  isConfirmButtonDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<InteractionElementRelationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: InteractionElementRelationDialogModel,
    private componentFactoryResolver: ComponentFactoryResolver,
    private elementRelationService: InteractionElementRelationService,
    private dialog: MatDialog
  ) {}

  /**
   * Initializes the InteractionElementRelation dialog and subscribes to it.
   */
  ngAfterViewInit() {
    const componentFactory: ComponentFactory<InteractionElementRelationComponent>
      = this.componentFactoryResolver.resolveComponentFactory(InteractionElementRelationComponent);

    const viewContainerRef: ViewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<InteractionElementRelationComponent>
      = viewContainerRef.createComponent(componentFactory);
    // componentRef.instance.formScreenIndex = this.dialogData.formScreenIndex;
    // componentRef.instance.formElementIndex = this.dialogData.formElementIndex;
    // componentRef.instance.control = this.dialogData.formControl;
    componentRef.instance.dynFrmObj = this.dialogData.interaction;
    // componentRef.instance.isElementDisabled = this.dialogData.isElementDisabled;

    this.elementRelationChangeSubscription = this.elementRelationService.elementRelationChanged$.subscribe (
      (data: any[]) => {
        if (!this.isRelationNotValid(data)) {
          this.elementRelationService.announceElementRelationDialogChange(data);
        }
      },
      (err) => { console.log(err); }
    );
  }

  isRelationNotValid(data: any[]): boolean {
    let flag = false;
    if (data && data.length > 0) {
      data.forEach(control => {
        if (control.relation && control.relation.length > 0) {
          control.relation.forEach((relation: Relation) => {
            if (relation.subRelation && relation.subRelation.length > 0) {
              relation.subRelation.forEach(subRelation => {
                if (subRelation.if.model && subRelation.then.model
                  && subRelation.if.model.originalId === subRelation.then.model.originalId) {
                  flag = true;
                }
                if (subRelation.if.option) {
                  switch (subRelation.if.option) {
                    case IfThenLogicOptions.IsEmpty:
                    case IfThenLogicOptions.IsNotEmpty:
                    case IfThenLogicOptions.DateIsToday:
                    case IfThenLogicOptions.DateIsAfterToday:
                    case IfThenLogicOptions.DateIsBeforeToday:
                    case IfThenLogicOptions.DateIsEqualToOrAfterToday:
                    case IfThenLogicOptions.DateIsEqualToOrBeforeToday:
                    case IfThenLogicOptions.DateIsNow:
                    case IfThenLogicOptions.DateIsAfterNow:
                    case IfThenLogicOptions.DateIsBeforeNow:
                    case IfThenLogicOptions.DateIsEqualToOrAfterNow:
                    case IfThenLogicOptions.DateIsEqualToOrBeforeNow:
                    case IfThenLogicOptions.IsCurrentMonth:
                    case IfThenLogicOptions.MonthIsBeforeCurrentMonth:
                    case IfThenLogicOptions.MonthIsAfterCurrentMonth:
                    case IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth:
                    case IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth:
                    case IfThenLogicOptions.IsCurrentWeek:
                    case IfThenLogicOptions.WeekIsBeforeCurrentWeek:
                    case IfThenLogicOptions.WeekIsAfterCurrentWeek:
                    case IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek:
                    case IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek:
                    case IfThenLogicOptions.IsCurrentTime:
                    case IfThenLogicOptions.TimeIsBeforeCurrentTime:
                    case IfThenLogicOptions.TimeIsAfterCurrentTime:
                    case IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime:
                    case IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime:
                    case IfThenLogicOptions.MathEquation:
                      break;
                    case IfThenLogicOptions.IsBetween:
                    case IfThenLogicOptions.IsNotBetween:
                      if (typeof subRelation.if.value === 'undefined' || subRelation.if.value === null
                        || !subRelation.if.secondValue || typeof subRelation.if.secondValue === 'undefined'
                        || subRelation.if.secondValue === null || subRelation.if.secondValue === '') {
                          flag = true;
                      }
                      if (subRelation.if.value && subRelation.if.secondValue
                        && subRelation.if.secondValue < subRelation.if.value) {
                          flag = true;
                      }
                      break;
                    default:
                      if (typeof subRelation.if.value === 'undefined' || subRelation.if.value === null
                        || (Array.isArray(subRelation.if.value)
                          ? subRelation.if.value.length === 0
                          : ('' + subRelation.if.value).length === 0)) {
                        flag = true;
                      }
                      break;
                  }
                } else {
                  flag = true;
                }
                if (subRelation.then.action) {
                  switch (subRelation.then.action) {
                    case IfThenLogicAction.Enable:
                    case IfThenLogicAction.Disable:
                      break;
                    case IfThenLogicAction.DefaultValue:
                      if (typeof subRelation.then.value === 'undefined' || subRelation.then.value === null) {
                        flag = true;
                      }
                      break;
                    case IfThenLogicAction.MathEquation:
                      if (subRelation.then.operations && subRelation.then.operations.length > 0) {
                        subRelation.then.operations.forEach((operation, operationIndex) => {
                          if (operationIndex > 0) {
                            if (!(operation && operation.operator && typeof operation.value !== 'undefined' && operation.value !== null)) {
                              flag = true;
                            }
                          } else {
                            if (!(operation && typeof operation.value !== 'undefined' && operation.value !== null)) {
                              flag = true;
                            }
                          }
                        });
                      } else {
                        flag = true;
                      }
                      break;
                    case IfThenLogicAction.MustBe:
                      if (subRelation.then.option) {
                        switch (subRelation.then.option) {
                          case IfThenLogicOptions.IsEmpty:
                          case IfThenLogicOptions.IsNotEmpty:
                          case IfThenLogicOptions.DateIsToday:
                          case IfThenLogicOptions.DateIsAfterToday:
                          case IfThenLogicOptions.DateIsBeforeToday:
                          case IfThenLogicOptions.DateIsEqualToOrAfterToday:
                          case IfThenLogicOptions.DateIsEqualToOrBeforeToday:
                          case IfThenLogicOptions.DateIsNow:
                          case IfThenLogicOptions.DateIsAfterNow:
                          case IfThenLogicOptions.DateIsBeforeNow:
                          case IfThenLogicOptions.DateIsEqualToOrAfterNow:
                          case IfThenLogicOptions.DateIsEqualToOrBeforeNow:
                          case IfThenLogicOptions.IsCurrentMonth:
                          case IfThenLogicOptions.MonthIsBeforeCurrentMonth:
                          case IfThenLogicOptions.MonthIsAfterCurrentMonth:
                          case IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth:
                          case IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth:
                          case IfThenLogicOptions.IsCurrentWeek:
                          case IfThenLogicOptions.WeekIsBeforeCurrentWeek:
                          case IfThenLogicOptions.WeekIsAfterCurrentWeek:
                          case IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek:
                          case IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek:
                          case IfThenLogicOptions.IsCurrentTime:
                          case IfThenLogicOptions.TimeIsBeforeCurrentTime:
                          case IfThenLogicOptions.TimeIsAfterCurrentTime:
                          case IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime:
                          case IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime:
                            break;
                          case IfThenLogicOptions.IsBetween:
                          case IfThenLogicOptions.IsNotBetween:
                            if (typeof subRelation.then.value === 'undefined' || subRelation.then.value === null
                              || !subRelation.then.secondValue || typeof subRelation.then.secondValue === 'undefined'
                              || subRelation.then.secondValue === null || subRelation.then.secondValue === '') {
                                flag = true;
                            }
                            if (subRelation.then.value && subRelation.then.secondValue
                              && subRelation.then.secondValue < subRelation.then.value) {
                                flag = true;
                            }
                            break;
                          default:
                            if (typeof subRelation.then.value === 'undefined' || subRelation.then.value === null
                              || (Array.isArray(subRelation.then.value)
                                ? subRelation.then.value.length === 0
                                : ('' + subRelation.then.value).length === 0)) {
                              if (typeof subRelation.ifConnective === 'undefined') {
                                flag = true;
                              }
                            }
                            break;
                        }
                      } else {
                        flag = true;
                      }
                      break;
                    default: break;
                  }
                } else {
                  if (!subRelation.ifConnective) {
                    flag = true;
                  }
                }
              });
            } else {
              flag = true;
            }
          });
        } else {
          flag = true;
        }
      });
    } else {
      flag = false;
    }
    this.isConfirmButtonDisabled = flag;
    return flag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onTipsClick(): void {
    this.openTipsDialog();
  }

  public openTipsDialog = (): void => {
    const dialogRef: MatDialogRef<InteractionElementRelationTipsComponent> = this.dialog.open(InteractionElementRelationTipsComponent, {
      minWidth: 500
    });
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.
    if (this.elementRelationChangeSubscription) {
      this.elementRelationChangeSubscription.unsubscribe();
    }
  }
}
