import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { AuthService } from '@eva-core/auth.service';
import { Chat } from '@eva-model/chat';

@Injectable()
export class SaveChatService {

  // Reference to collection
  chats: AngularFirestoreCollection<Chat>;

  constructor(private _fireStoreService: FirestoreService,
              private _authService: AuthService) {
    // from(this._authService.getUserId()).subscribe(userId => {
    //   this.chats = this._fireStoreService.col<Chat>('users/' + userId + '/Chats');
    // });
  }

  // /**
  //  * public saveChatSession - saves the current chat session to Firebase
  //  *
  //  * @param  value string the message to save/what was said by the user or the bot
  //  * @param  user boolean true - is human, false - is the bot
  //  * @param  moreinfo any if there is more information to pass on such as object
  //  * @return               promise from Firebase based on if it was successful or not
  //  */
  // public saveChatSession(value: string, user: boolean, moreinfo: any): Promise<firebase.firestore.DocumentReference> {
  //   const chat: Chat = { message: value, user: user, moreinfo: moreinfo };
  //   return this._fireStoreService.add(this.chats, chat);
  // }

}
