import { Component, OnInit, Input } from '@angular/core';
import { AboutPageFAQs } from '@eva-model/releaseNotes';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoggingService } from '@eva-core/logging.service';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { AddEditFAQsComponent } from './add-edit-faqs/add-edit-faqs.component';
@Component({
  selector: 'app-about-page-faqs',
  templateUrl: './about-page-faqs.component.html',
  styleUrls: ['./about-page-faqs.component.scss']
})
export class AboutPageFAQsComponent implements OnInit {

  _aboutPageFAQColPath = 'About/FAQs/FAQs';
  aboutPageFAQCollection: AngularFirestoreCollection<AboutPageFAQs>;
  aboutPageFAQs$: Observable<AboutPageFAQs[]>;

  _isAdministrator = false;

  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  constructor(private firestoreService: FirestoreService,
    private dialog: MatDialog,
    private ls: LoggingService
    ) { }

  ngOnInit() {
    this.getAboutPageFAQs();
  }

  /**
   * this gets the about page frequently asked questions.
   */
  getAboutPageFAQs() {
    this.aboutPageFAQCollection = this.firestoreService.col(this._aboutPageFAQColPath,
      ref => ref.orderBy('updatedAt', 'desc'));

    this.aboutPageFAQs$ = this.firestoreService.colWithIds$(this.aboutPageFAQCollection);
  }

  /**
   * This function adds an item to the datatable.
   */
  addFAQ() {
    const title = 'New FAQ';
    const confirmButtonText = 'Add';

    this.addEditFAQs(title, confirmButtonText);
  }

  /**
   * This function allows an edit in the about document.
   */
  editFAQ(faq) {
    const title = 'Edit FAQ';
    const confirmButtonText = 'Edit';

    this.addEditFAQs(title, confirmButtonText, faq);
    // console.log(document);

  }

  addEditFAQs(title: string, confirmButtonText: string, data?: string) {
    let dialogData = null;
    let dialogComponent = null;

    const content = '';
    const cancelButtonText = 'Cancel';

    dialogData = new GeneralDialogModel(
      title,
      content, confirmButtonText,
      cancelButtonText, null,
      {
        data: data
      }
    );
    dialogComponent = AddEditFAQsComponent;
    const dialogRef: MatDialogRef<AddEditFAQsComponent, AboutPageFAQs>
      = this.dialog.open(dialogComponent, {
      data: dialogData,
      disableClose: true,
      minHeight: '400px',
      minWidth: '600px'
    });

    /**
     * Fires after this completes.
     */
    dialogRef.afterClosed().subscribe(async result => {
      // check for a result.
      if (result) {
        const doc: AboutPageFAQs = {
          title: result.title,
          description: result.description,
        };
        if (result.id) { // this is an edit.
          const updatePath = this._aboutPageFAQColPath + `/${result.id}`;
          try {
            await this.firestoreService.upsert(updatePath, doc);
            await this.ls.logMessage('Successfully Edited FAQ', false, 'success');
          } catch (err) {
            await this.ls.logMessage('Error Editing Document', false, 'error', err);
          }
        } else { // this is an add.
          try {
            await this.firestoreService.add(this._aboutPageFAQColPath, doc);
            await this.ls.logMessage('Successfully Added FAQ', false, 'success');
          } catch (err) {
            await this.ls.logMessage('Error Adding Document', false, 'error', err);
          }
        }
      }
    });
  }

}
