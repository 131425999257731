
export class InteractionConditionDialogModel {

    constructor(
        public title: string,
        public content: string,
        public interaction: any,
        public conditionIndex: number,
        public condition: any,
        public confirmBtnTxt: string,
        public cancelBtnTxt?: string,
    ) {  }

}
