import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BootstrapModuleFn as Bootstrap, WebpackModule } from '@ngxs/hmr-plugin';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { hmrBootstrap } from './hmr';

declare const module: WebpackModule;

if (environment.production) {
  enableProdMode();
}

import {LicenseManager} from "ag-grid-enterprise";
// eslint-disable-next-line max-len
LicenseManager.setLicenseKey("ATB_Financial_Multi_Multiple_Applications_1_Devs__15_February_2021_[v2]_MTYxMzM0NzIwMDAwMA==cec98e2eb64e7231a8f4614ece7257da");

const bootstrap: Bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}
