export class ElementEmitValueModel {

    constructor(
        public interactionId: string,
        public elementOriginalId: string,
        public conditionSetIndex: number,
        public elementIndex: number,
        public value: any,
        public valueOperator?: string,
        public connectionOperator?: string,
        public secondValue?: any
    ) {  }

}
