import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GeneralDialogService {

  // Observable any sources
  private generalDialogChangeSource = new Subject<any>();

  // Observable any streams
  generalDialogChanged$ = this.generalDialogChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceChange(change: any) {
    this.generalDialogChangeSource.next(change);
  }

}
