import { Component, ViewChild, ComponentRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { LoggingService } from '@eva-core/logging.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, FormControl, Validators } from '@angular/forms';
import { UserService } from '@eva-services/user/user.service';
import { UserPreferences, IntroTutorials } from '@eva-model/UserPreferences';
import { User } from '@eva-model/User';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { UserVerifyResetComponent } from '@eva-ui/user/user-verify-reset/user-verify-reset.component';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { GenericSearchView } from '@eva-model/generic-search';
import { ThemeService } from '@eva-services/theme/theme.service';
import { EVATheme, EVAThemes } from '@eva-model/themes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnDestroy {
  user: User = null; // the user object from the database.
  userPreferences: UserPreferences = null; // the users preferences.
  userForm: UntypedFormGroup; // the user form group.
  enableUpdateButton = false; // whether the update button is valid.
  nameValid = true; // is the users name valid?
  GenericSearchView = GenericSearchView;
  GenericSearchViewKeys: string[];
  themes: EVATheme[] = EVAThemes;
  themeSub: Subscription;

  genericComponentRef: ComponentRef<any>;
  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  componentTitle: string;

  constructor(
    private _auth: AuthService,
    private _fb: UntypedFormBuilder,
    private _componentFactoryResolver: ComponentFactoryResolver,
    public _us: UserService, // making this public to use the existing user service if it exists.
    private _fs: FirestoreService,
    public logging: LoggingService,
    private activatedRoute: ActivatedRoute,
    public themeService: ThemeService) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    this.GenericSearchViewKeys = Object.keys(GenericSearchView).filter(key => !isNaN(Number(GenericSearchView[key])));

    this.userForm = this._fb.group(
      {
        preferredName: ['', [
          Validators.required
        ]],
        experimentalPreferences: ['', []],
        uniqueKeyPreferences: ['', []],
        formJsonPreferences: ['', []],
        speechEnabled: ['', []],
        autoRefreshProcessDashboard: ['', []],
        toggleEmailsProcessDashboard: ['', []],
        tutorialList: this._fb.array([]),
        genericSearchView: ['', []],
        isChatMinimized: ['', []],
        selectedTheme: [this.themeService.currentTheme, []]
      });
    this.getUserPreferences();

    this.themeSub = this.userForm.controls['selectedTheme'].valueChanges.subscribe((value) => {
      this.themeService.setTheme(value);
    })
  }


  /**
   * get the user preferences and setup the preference form.
   */
  async getUserPreferences() {
    this.user = await this._auth.user.pipe(take(1)).toPromise();

    // if the user exists try to get the user preferences.
    if (this.user) {
      // this is now self healing if there are no user preferences, therefore it should always return user preferences.
      this.userPreferences = await this._us.getUserPreferences(this.user);

      // setup the users form.
      this.userForm.setValue({
        preferredName: this.userPreferences.name ?? '',
        speechEnabled: this.userPreferences.speechEnabled,
        experimentalPreferences: this.userPreferences.experimentalEva,
        uniqueKeyPreferences: this.userPreferences.useUniqueKeyEachTransaction,
        formJsonPreferences: this.userPreferences.formJSONenabled,
        autoRefreshProcessDashboard: this.userPreferences.autoRefreshProcessDashboard
          ? this.userPreferences.autoRefreshProcessDashboard : false,
        tutorialList: [],
        toggleEmailsProcessDashboard: this.userPreferences.toggleEmailsProcessDashboard
          ? this.userPreferences.toggleEmailsProcessDashboard : false,
        genericSearchView: this.userPreferences.genericSearchView ?? GenericSearchView.Tiles,
        isChatMinimized: this.userPreferences.isChatMinimized ?? false,
        selectedTheme: this.themeService.currentTheme
      });
      // add in the tutorial data.
      if (this.userPreferences.tutorials && this.userPreferences.tutorials.tutorialList) {
        // get each tutorial from the list.
        const tutorialList = this.userPreferences.tutorials.tutorialList;
        Object.keys(tutorialList).forEach(tutorialName => {
          this.tutorialForms.push(
            this._fb.group({
              tutorialName: tutorialName,
              completed: tutorialList[tutorialName]
            })
          );
        });
      }
    }
  }

  /**
   * This gets the tutorial list form array. It simplifies the html and codee here making it a getter object.
   */
  get tutorialForms(): UntypedFormArray {
    return this.userForm.get('tutorialList') as UntypedFormArray;
  }

  /**
   * This takes the dropdownlist values and compares them to the database values in a form array binding.
   * It will keep looping until the condition is true or the two values don't match. In which case the drop down list
   * will not show a value but it will still exist in the reactive forms.
   * @param dropDownValue the drop down value to compare.
   * @param databaseValue The database or value that has been set.
   */
  compareTutorialObjects(dropDownValue: string, databaseValue: boolean): boolean {
    if (dropDownValue === 'false') {
      if (!databaseValue) {
        return true;
      } else {
        return false;
      }
    } else {
      if (databaseValue) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy() {
    this.themeSub.unsubscribe();
  }

  /**
   * This returns wheteher the users preferred name is null or undefined.
   */
  isPreferredNameEmpty(): boolean {  // is it null or undefined
    return (this.userPreferences.name && this.userPreferences.name !== '') ? false : true;
  }

  /**
   * This function saves the new users preferences.
   */
  async saveUserPreferences(): Promise<void> {

    // make sure some data for preferred name is entered.
    if (this.userForm.value.preferredName.length > 0) {
      // console.log(this.userForm);
      this.nameValid = true;
      this.userPreferences.name = this.userForm.value.preferredName;
      this.userPreferences.speechEnabled = this.userForm.value.speechEnabled;
      this.userPreferences.experimentalEva = this.userForm.value.experimentalPreferences;
      this.userPreferences.useUniqueKeyEachTransaction = this.userForm.value.uniqueKeyPreferences;
      this.userPreferences.formJSONenabled = this.userForm.value.formJsonPreferences;
      this.userPreferences.createdAt = (this.userPreferences.createdAt) ? this.userPreferences.createdAt : this._fs.timestamp;
      this.userPreferences.image = (this.user.photoURL) ? this.user.photoURL : '';
      this.userPreferences.tutorials.tutorialList = this.getTutorialListForSaving(this.userForm.value.tutorialList);
      this.userPreferences.autoRefreshProcessDashboard = this.userForm.value.autoRefreshProcessDashboard;
      this.userPreferences.toggleEmailsProcessDashboard = this.userForm.value.toggleEmailsProcessDashboard;
      this.userPreferences.genericSearchView = this.userForm.value.genericSearchView;
      this.userPreferences.isChatMinimized = this.userForm.value.isChatMinimized;

      try {
        // update the user preferences
        await this._us.updateUserPreferences(this.userPreferences);
        this.logging.logMessage('Save Successful', false, 'success');
        this.userForm.markAsPristine();
        return;
      } catch (err) {
        console.log(err);
        this.logging.logMessage('Problem saving your user preferences. Please try again.', false, 'error');
      }
    } else { // nothing entered in name
      this.nameValid = false;
    }
  }

  /**
   * This takes the values from the form array for tutorials and creates an intro tutorial with them.
   *
   * @param formTutorial the form intro tutorial that is checked.
   */
  getTutorialListForSaving(formTutorial: Array<any>): IntroTutorials {
    const introTutorial: IntroTutorials = {};
    formTutorial.forEach(tutorial => {
      let tutorialCompleted = true;
      if (tutorial.completed === 'false') {
        tutorialCompleted = false;
      } else {
        if (!tutorial.completed) {
          tutorialCompleted = false;
        }
      }
      introTutorial[tutorial.tutorialName] = tutorialCompleted;
    });
    return introTutorial;
  }
  /**
   * This function gets the users default values for the form that has been created.
   */
  async revertDefaultUserPreferences() {
    const defaultPrefs = await this._us.getDefaultUserPreferenceObject();

    if (defaultPrefs.name !== this.userForm.value.preferredName ||
      defaultPrefs.speechEnabled !== this.userForm.value.speechEnabled ||
      defaultPrefs.experimentalEva !== this.userForm.value.experimentalPreferences ||
      defaultPrefs.useUniqueKeyEachTransaction !== this.userForm.value.uniqueKeyPreferences ||
      defaultPrefs.formJSONenabled !== this.userForm.value.formJsonPreferences ||
      defaultPrefs.autoRefreshProcessDashboard !== this.userForm.value.autoRefreshProcessDashboard ||
      defaultPrefs.toggleEmailsProcessDashboard !== this.userForm.value.toggleEmailsProcessDashboard
    ) {
      // reset the form to it's default values.
      this.userForm.setValue({
        preferredName: defaultPrefs.name,
        speechEnabled: defaultPrefs.speechEnabled,
        experimentalPreferences: defaultPrefs.experimentalEva,
        uniqueKeyPreferences: defaultPrefs.useUniqueKeyEachTransaction,
        formJsonPreferences: defaultPrefs.formJSONenabled,
        autoRefreshProcessDashboard: defaultPrefs.autoRefreshProcessDashboard,
        toggleEmailsProcessDashboard: defaultPrefs.toggleEmailsProcessDashboard,
        tutorialList: []
      });

      // get the default tutorial list and set it's values.
      const tutorialList = defaultPrefs.tutorials.tutorialList;
      Object.keys(tutorialList).forEach(tutorialName => {
        // console.log(tutorialList[tutorialName]);
        this.tutorialForms.push(
          this._fb.group({
            tutorialName: tutorialName,
            completed: tutorialList[tutorialName]
          })
        );
      });
      this.userForm.markAsDirty();
    }
  }

  /**
   * This function generates a User Verify reset.
   */
  private generateUserVerifyReset() {
    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(UserVerifyResetComponent);
    const componentRef: any = viewContainerRef.createComponent(componentFactory);
    this.genericComponentRef = componentRef;
  }

}
