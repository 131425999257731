import { Component, AfterViewInit, Inject, ViewChild, ComponentFactoryResolver, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { FormVisualizerComponent } from '@eva-ui/form-visualizer/form-visualizer.component';
import { InteractionVisualizerDialogModel } from '@eva-model/interactionVisualizerDialogModel';
import { DynamicInteractionSyncService } from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { DynamicComponent } from '@eva-model/interaction/interaction';


@Component({
  selector: 'app-interaction-visualizer-dialog',
  templateUrl: './interaction-visualizer-dialog.component.html',
  styleUrls: ['./interaction-visualizer-dialog.component.scss']
})
export class InteractionVisualizerDialogComponent implements AfterViewInit, OnDestroy {

  // object IDs:
  private readonly _REQUIREMENTS_ID = 'd4382777-ceba-4658-997d-440e40d830f4';
  private readonly _REVIEW_CUTOFF_DATETIME_ID = '8b335d6c-b85b-4706-8b13-f068e30d3826';
  private readonly _IMPLEMENTATION_START_DATETIME_ID = '1e005e91-1b80-42bb-ab50-7b21082cbb91';
  private readonly _IMPLIMENTATION_END_DATETIME_ID = 'a068ac7b-0fe6-4825-a21a-746b280c6f91';

  @Input()
  set selectControlsOptions(selectControlsOptions: any[]) {
    if (!selectControlsOptions ||
      !Array.isArray(selectControlsOptions) ||
      selectControlsOptions.length <= 0 ||
      !this.componentReference
    ) { return; }

    (<FormVisualizerComponent>this.componentReference.instance).selectControlsOptions = selectControlsOptions;
  }

  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  interactionValueChangeSubs: ISubscription;
  componentReference: any;

  constructor(
    public dialogRef: MatDialogRef<InteractionVisualizerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: InteractionVisualizerDialogModel,
    private componentFactoryResolver: ComponentFactoryResolver,
    private interactionSyncService: DynamicInteractionSyncService,
    private generalDialogService: GeneralDialogService,
    private _dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    const that = this;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormVisualizerComponent);

    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentReference = componentRef;
    (<DynamicComponent>componentRef.instance).isAllDisabled = this.dialogData.disableAll ? this.dialogData.disableAll : false;
    (<DynamicComponent>componentRef.instance).dynFrmObj = this.dialogData.interaction;
    (<DynamicComponent>componentRef.instance).isSubmitEnable = this.dialogData.interactionVisualizerSubmit;
    (<DynamicComponent>componentRef.instance).isJoinChange = this.dialogData.isJoinChange;
    (<DynamicComponent>componentRef.instance).isPreview = true;

    this.interactionValueChangeSubs =
      this.interactionSyncService.interactionValueChanged$
        .subscribe((flatInteraction) => {
          if (flatInteraction.originalId === that.dialogData.interaction.id) {
            flatInteraction.isInteractionValid = this.isValid();
            this.generalDialogService.announceChange(flatInteraction);
          }
        });
  }

  onNoClick() {
    this.dialogRef.close({ saved: false });
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.interactionValueChangeSubs) {
      this.interactionValueChangeSubs.unsubscribe();
    }
  }

  saveClicked(event) {
    if (this.validateForm()) {
      this.dialogRef.close({ saved: true, valid: this.isValid() });
    }
  }

  validateForm() {
    switch (this.componentReference.instance.dynFrm.originalId) {
      case this._REQUIREMENTS_ID:
        if (!this.validateOverviewDates()) {
          this.confirmPastDates();
          return false;
        }
        return true;
      // Create cases for other interactions here
      default:
        return true;
    }
  }

  /**
   * This function validates the dates by checking whether they are before NOW or not
   */
  validateOverviewDates(): boolean {
    let flag = false;
    this.componentReference.instance.flatInteraction.elements.some(elm => {
      if (elm.originalId === this._REVIEW_CUTOFF_DATETIME_ID
        || elm.originalId === this._IMPLEMENTATION_START_DATETIME_ID
        || elm.originalId === this._IMPLIMENTATION_END_DATETIME_ID) {
        if (new Date(elm.value).getTime() < Date.now()) {
          flag = true;
          return;
        }
      }
    });
    if (flag) return false;
    return true;
  }

  confirmPastDates() {
    const dialog = new GeneralDialogModel(
      'Past Dates/Times Detected',  // Title
      'One or more dates and/or times on the Overview page are in the past. Is this intentional?', // Content text
      'Yes',                        // Confirm button text
      'No');                        // Cancel button text
    const dialogRef = this._dialog.open(GeneralDialogComponent, { data: dialog });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close({ saved: true, valid: this.isValid() });
      }
    });
  }

  /**
   * This function checks the validity of the forms in the visualizer
   */
  isValid(): boolean {
    if (this.componentReference &&
      this.componentReference.instance &&
      this.componentReference.instance.formVisualizerFormGroupArray &&
      Array.isArray(this.componentReference.instance.formVisualizerFormGroupArray) &&
      this.componentReference.instance.formVisualizerFormGroupArray.length > 0) {

      let isValid = true;
      this.componentReference.instance.formVisualizerFormGroupArray.forEach(frm => {
        isValid = isValid && frm.valid;
      });

      return isValid;
    } else {
      return true;
    }

  }

}
