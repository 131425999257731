import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeysService } from '@eva-core/encryption/keys.service';
import { CryptoFunctionsService } from '@eva-core/cryptographic/crypto-functions.service';

import * as Elliptic from 'elliptic';
import { environment } from '@environments/environment';
import { KnowledgeDocumentSaveTransaction, KnowledgeDocument, KnowledgeDocumentSaveData } from '@eva-model/knowledge/knowledge';
import { BlockchainTransactionBase } from '@eva-model/blockchain/transaction';
import { StorageRequestDetails, StorageRequest } from '@eva-model/storage/evaStorage';
import { EVATime } from '@eva-model/time';
import { EVASignedObjectData, EVASignedObject, EVAGenericSignedObject } from '@eva-model/signing/signing';
import { BlockChainTransactionResponse } from '@eva-model/blockchain/transaction';

@Injectable()
export class SigningService {
  private BLOCK_END_POINT = environment.endPoints.BLOCK_ENDPOINT.url + 'addBlockTransaction'; // the block transaction endpoint
  private TIME_END_POINT = environment.endPoints.BLOCK_ENDPOINT.url + 'currentTime'; // the current time endpoint

  constructor(
    private http: HttpClient,
    public keysService: KeysService,
    private cryptoFunctionsService: CryptoFunctionsService
  ) {
  }

  /**
   * This gets the current time in the EVA ecosystem.
   */
  getCurrentTime(): Promise<EVATime> {
    // ||| can the http options be removed because this gets picked up in the interceptors.
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true',
      })
    };

    return this.http.get<EVATime>(`${this.TIME_END_POINT}`, (httpOptions)).toPromise();
  }

  /**
   * This function takes an object, creates a block transaction and signs it with the users signing key and
   * sends it to the eva blockchain.
   *
   * @param unencryptedObj the object to send to the endpoint
   * @param transactionTypeId the type of transaction that is being sent.
   */
  async signAndSendObject(unencryptedObj: Object, transactionTypeId: string): Promise<BlockChainTransactionResponse> {
    // we can leave this blank as the sign object function will add the publickey, timestamp and signature
    const evaSignedObject: EVASignedObject = {
      data: {
        type: transactionTypeId,
        publicKey: '',
        timeStamp: 0,
        unencryptedData: unencryptedObj
      },
      signature: ''
    };

    try {
      const successfulResponse = await this.trySignAndSendObjectToEndpoint(evaSignedObject);
      return successfulResponse;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This tries to send an object to the blockchain a number of times and returns depending on that
   * @param evaSignedObject the initial signed object that has been created to sign, timestamp and send to the blockchain./
   */
  async trySignAndSendObjectToEndpoint(evaSignedObject: EVASignedObject) {
    let successful = false;
    let blockResponse = null;
    let retryBlockchain = 3;

    while (retryBlockchain > 0) {
      retryBlockchain -= 1;
      try {

        // create a properly signed object
        const finalizedObject = await this.signObject(evaSignedObject);
        // send to the endpoint and get the response.
        blockResponse = await this.sendObjectToEndPoint(finalizedObject);
        successful = true;
        // retryBlockchain = 0;
        break;
      } catch (err) {
        blockResponse = err;
        console.log(err);
      }
    }
    // return the object either way, if still a failure
    if (successful) {
      return blockResponse;
    } else {
      return Promise.reject(blockResponse);
    }
  }

  /**
   * This function sends the block transaction to the blockchain endpoint
   *
   * @param blockEndPointObject the object that is being sent to the block end point
   */
  sendObjectToEndPoint(blockEndPointObject: EVAGenericSignedObject): Promise<BlockChainTransactionResponse> {

    // Post the entity to EVA blockchain to get handled there based on entity type
    return this.http.post<BlockChainTransactionResponse>(`${this.BLOCK_END_POINT}`, JSON.stringify(blockEndPointObject)).toPromise();

  }

  /**
   * This takes an object, gets the eva system time and signs the object with the signing key.
   *
   * @param objectToSign the object to sign with the signing key
   */
  async signObject(objectToSign: EVAGenericSignedObject): Promise<EVAGenericSignedObject> {
    try {
      const signingKeyPair = await this.keysService.getSigningKey();
      const timeObject = await this.getCurrentTime();
      // override these object values if they exist.
      objectToSign.data.publicKey = signingKeyPair.getPublic('hex');
      objectToSign.data.timeStamp = timeObject.time;

      const signatureHex = this.getObjectSignatureNoBlockFromProvidedKey(objectToSign.data, signingKeyPair);
      objectToSign.signature = signatureHex;
      return objectToSign;
    } catch (err) {
      console.log('An error occured while obtaining the signing keys: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * This takes an object with a data node and signature and signs it returning the signed object. This uses the encryption key
   * instead of the signing key for the signature. This is used when the user was the originating user on a storage request.
   * This allows a offset for assymetric encryption that still allows the user to get data they previously stored.
   *
   * @param objectToSign the generic signed object to sign
   */
  async signObjectWithEncryptionKey(objectToSign: EVAGenericSignedObject): Promise<EVAGenericSignedObject> {
    try {
      const encryptionKeyPair = await this.getStorageKeyForSigning(true);

      const signatureHex = this.getObjectSignatureNoBlockFromProvidedKey(objectToSign.data, encryptionKeyPair);
      objectToSign.signature = signatureHex;
      return objectToSign;
    } catch (err) {
      console.log('An error occured while obtaining the signing keys: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * This function creates and signs the storage request object. This involves adding the timestamp of the EVA blockchain.
   * It should be used right before sending to the storage location as the acceptance is time sensitive.
   *
   * @param storageId the id of the storage request.
   * @param isOriginator whether the user is the originator of the data. (original creator)
   */
  async createAndSignStorageRequest(storageId: string, isOriginator: boolean): Promise<StorageRequest> {
    const storageRequestDetails: StorageRequestDetails = {
      linkUrl: storageId,
      requesterPublicKey: '',
      requestTimestamp: 0
    };

    // setup the storage request object.
    try {
      const storageKeyPair = await this.getStorageKeyForSigning(isOriginator);
      storageRequestDetails.requesterPublicKey = storageKeyPair.getPublic('hex');
      const timeObject = await this.getCurrentTime();
      storageRequestDetails.requestTimestamp = timeObject.time;

      const signatureHex = this.getObjectSignatureNoBlockFromProvidedKey(storageRequestDetails, storageKeyPair);

      // create the storage request.
      const storageRequest: StorageRequest = {
        data: storageRequestDetails,
        signature: signatureHex
      };
      // return the storage request.
      return storageRequest;
    } catch (err) {
      console.log('error creating the storage request.');
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This takes an object, signing key pair and sends the data to the blockchain. It is used when temporary key pairs are created
   * and signs with them. The EVA system does this on new group creation.
   *
   * @param objectToSign this object to sign
   * @param signingKeyPair ellpitic key pair object
   * @param type the block transaction type
   * @param encryptedData encrypted data to include.
   */
  async signObjectWithOtherKeyAndSendToBlock(objectToSign: any, signingKeyPair: Elliptic.ec.KeyPair,
    type?: string, encryptedData?: any): Promise<BlockChainTransactionResponse> {

    const publicKey: string = signingKeyPair.getPublic('hex'); // get the public key of the key pair.

    try {
      const timeObject = await this.getCurrentTime();
      // create the data to be signed.
      const signedObjectData: EVASignedObjectData = this.getEVASignedObjectDataStructure(publicKey, objectToSign,
        timeObject.time, type, encryptedData);

      const signature = this.getObjectSignatureNoBlockFromProvidedKey(signedObjectData, signingKeyPair);
      const evaSignedObject: EVASignedObject = this.getObjectToSignStructure(signedObjectData, signature);

      // send the object to the blockchain endpoint.
      return this.sendObjectToEndPoint(evaSignedObject);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  //#region ObjectToCryptoSignatures

  /**
   * This uses the users private key to sign the object that is provided to the function.
   *
   * @param objectToSign an object to sign
   * @return {Promise<string>}  provides a crypto signature in hex format.
   */
  async getObjectSignatureNoBlockFromUserKey(objectToSign: any): Promise<string> {
    try {
      const signingKey = await this.keysService.getSigningKey(); // get the signing key.
      return this.getObjectCryptoSignatureFromKeyPair(objectToSign, signingKey); // return the signature.
    } catch (err) {
      console.log('An error occured while obtaining the signing keys: ' + err);
      return Promise.reject(err);
    }
  }

  /**
   * This signs an object from a private key provided to the service. this returns the hex format
   * of a DER signature
   * @param objectToSign any object
   * @param privateKey: hex of a private key or a ellipic keypair object.
   */
  getObjectSignatureNoBlockFromProvidedKey(objectToSign: any, privateKey: string | Elliptic.ec.KeyPair): string {

    try {
      // return a signed object depending on the type of object passed to the function
      if (typeof privateKey === 'string') {
        const privateKeyPair = this.keysService.createKeyPairFromPrivateKeyHex(privateKey);
        return this.getObjectCryptoSignatureFromKeyPair(objectToSign, privateKeyPair);
      } else {
        return this.getObjectCryptoSignatureFromKeyPair(objectToSign, privateKey);
      }
    } catch (err) {
        console.log('An error occured while obtaining the signing keys: ' + err);
        return null;
    }
  }

  //#endregion ObjectToCryptoSignatures

  //#region CryptoSignatureValidations

  /**
   * This function is used to generate a public key object to use to verify a transaction or a crypto object in the EVA
   * ecosystem.
   * @param objectToValidate the object to validate
   * @param publicKey public key that has provided the signature. If not provided will check in EVASignedObject location
   * @param signature the hex encoded signature. If not provided will check in EVASignedObject location
   */
  validateSignedObject(objectToValidate: any, publicKey?: string, signature?: string): boolean {
    let messageHash: string;
    // make sure that the object has a minimum of requirements.
    // then get the hash of the object.data or of the object.
    if (objectToValidate.data && objectToValidate.signature) {
      messageHash = this.cryptoFunctionsService.getMessageHash(JSON.stringify(objectToValidate.data));
    } else {
      messageHash = this.cryptoFunctionsService.getMessageHash(JSON.stringify(objectToValidate));
    }
    let publicKeyToValidate = '';
    let signatureToValidate = '';
    if (publicKey || objectToValidate.data.publicKey) {
      publicKeyToValidate = (!publicKey) ? objectToValidate.data.publicKey : publicKey;
    }
    if (signature || objectToValidate.signature) {
      signatureToValidate = (!signature) ? objectToValidate.signature : signature;
    }

    // create a key pair that can be used to validate the transaction.
    const verificationKey = this.keysService.getVerificationKeyFromPublicKey(publicKeyToValidate);
    return verificationKey.verify(messageHash, signatureToValidate);
  }

  //#endregion CryptoSignatureValidations

  //#region KnowledgeTransactions

  /**
   * This creates a signed transaction for knowledge to go to the knowledge area.
   *
   * @param knowledgeDocument the knowledge document to sign.
   */
  async signKnowledgeObject(knowledgeDocument: KnowledgeDocument): Promise<KnowledgeDocumentSaveTransaction> {
    let systemTime = 0;

    try {
      // get the current time
      const timeObject = await this.getCurrentTime();
      systemTime = timeObject.time;
    } catch (err) {
      console.log('Error obtaining the time object from the system');
      return Promise.reject(err); // return null for the object.
    }

    // get the signing key.
    try {
      const signingKey = await this.keysService.getSigningKey();
      const publicKey = signingKey.getPublic('hex');
      const knowledgeDocumentSaveData: KnowledgeDocumentSaveData = {
        publicKey: publicKey,
        timeStamp: systemTime,
        knowledgeDocument: knowledgeDocument
      };

      //#region make the signature
      const jsonObject = JSON.stringify(knowledgeDocumentSaveData); // stringify the object
      const messageHash = this.cryptoFunctionsService.getMessageHash(jsonObject); // this.getMessageHash(jsonObject);
      const signature = signingKey.sign(messageHash).toDER();
      const signatureHex: string = this.cryptoFunctionsService.toHexString(signature);

      // create the return object
      const knowledgeTransaction: KnowledgeDocumentSaveTransaction = {
        data: knowledgeDocumentSaveData,
        signature: signatureHex
      };

      return knowledgeTransaction;
      //#endregion
    } catch (err) {
      return Promise.reject(err);
    }
  }

  //#endregion KnowledgeTransactions

  //#region GetEncryptionKeys

  /**
   * This gets the appropriate storage key for signing
   */
  async getStorageKeyForSigning(isOriginator: boolean): Promise<Elliptic.ec.KeyPair> {
    // got elliptical curve object for ECDH asymmetric encryption
    if (isOriginator) {
      return this.keysService.getEncryptionKey();
    } else {
      return this.keysService.getSigningKey();
    }
  }

  //#endregion GetEncryptionKeys


  //#region CryptoSigningFunctions

  /**
   * This function takes an object and elliptical keypair and provides a crypto signature in hex format.
   *
   * @param objectToSign any object to sign.
   * @param signingKeyPair the keypair that will do the signing.
   */
  getObjectCryptoSignatureFromKeyPair(objectToSign: any, signingKeyPair: Elliptic.ec.KeyPair): string {
    const jsonObject = JSON.stringify(objectToSign); // stringify the object to sign.
    const messageHash = this.cryptoFunctionsService.getMessageHash(jsonObject); // get the hash of the string object;
    const signature = signingKeyPair.sign(messageHash).toDER(); // this is the Distinguished Encoding Rules format
    const signatureHex: string = this.cryptoFunctionsService.toHexString(signature); // convert the DER format to hex
    return signatureHex;
  }

  //#endregion CryptoSigningFunctions

  //#region CryptoSignatureObjectCreation

  /**
   * This function creates a EVA signed Object from a evaSignedObject data
   * @param evaSignedObjectData the EVA signed Object data.
   * @param signature the hex format crypto signature. (will default to an empty string if not included)
   */
  getObjectToSignStructure(evaSignedObjectData: EVASignedObjectData, signature?: string): EVASignedObject {
    // create the EVA object for signing.
    const evaSignedObject: EVASignedObject = {
      data: evaSignedObjectData,
      signature: (signature) ? signature : ''
    };

    return evaSignedObject;
  }

  /**
   * This function creates an EVA signed object data structure for the EVA Blockchain.
   * @param publicKey the public key to include
   * @param unencryptedData unencrypted data to include
   * @param encryptedData encryptedData to include (object node will not exist if not included)
   * @param type the type of transaction if if should be included. (object node will not exist if not included)
   * @param timeStamp the time the object was signed at. (will default to the system time if not included.)
   */
  getEVASignedObjectDataStructure(publicKey: string, unencryptedData: any, timeStamp?: number, type?: string,
    encryptedData?: any): EVASignedObjectData {

    const evaSignedObjectData: EVASignedObjectData = {
      publicKey: (publicKey) ? publicKey : '',
      timeStamp: (timeStamp) ? timeStamp : Date.now(),
      unencryptedData: unencryptedData
    };

    // assign the type if provided.
    Object.assign(evaSignedObjectData,
      type ? { type: type } : null
    );

    // assign the encrypted data if included.
    Object.assign(evaSignedObjectData,
      encryptedData ? { encryptedData: encryptedData } : null
    );

    return evaSignedObjectData;
  }
  //#endregion CryptoSignatureObjectCreation
}
