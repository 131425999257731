import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SearchUtilsService {

  constructor() { }

  //#region Manage Search Column Definitions

  /**
   * Returns a object that represents a column definition.
   * @param headerName the display name of the column
   * @param field the field that this will match with.
   */
  public getColumnDefinition(headerName: string, field: string): ColDef {
    return {
      headerName: headerName,
      field: field,
      sortable: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ["contains", "equals", "notEqual"]
      }
    };
  }

  /**r
   * Returns object that represents a column definition and is initially hidden.
   * @param headerName
   * @param field
   */
  public getInitiallyHiddenColumnDefinition(headerName: string, field: string): ColDef {
    return Object.assign(this.getColumnDefinition(headerName, field), {
      hide: true
    });
  }


  /**
   * Returns a object that represents a column definition where cell-style is {'white-space': 'normal !important'} and auto-height is true.
   * @param headerName
   * @param field
   */
  public getColumnDefinitionWithStyleAndAutoHeightEnabled(headerName: string, field: string): ColDef {
    return Object.assign(this.getColumnDefinition(headerName, field), {
      cellStyle: {'white-space': 'normal !important'},
      autoHeight: true
    });
  }

  /**
   * Returns a object that represents a column definition where date format is 'MM/DD/YYYY HH:mm'.
   * @param headerName
   * @param field
   */
  public getColumnDefinitionForDateTime(headerName: string, field: string): ColDef {
    return Object.assign(this.getColumnDefinition(headerName, field), {
      filterParams: {
        filterOptions: ["equals", "notEqual"],
        comparator: function (filterValue: any, cellValue: any) {
          const filterDate = new Date(filterValue);
          const cellDate = new Date(cellValue);
          if (filterDate.getDate() === cellDate.getDate()
            && filterDate.getMonth() === cellDate.getMonth()
            && filterDate.getFullYear() === cellDate.getFullYear()) {
            return 0;
          } else if (filterDate < cellDate) {
            return 1;
          } else if (filterDate > cellDate) {
            return -1;
          }
        }
      },
      cellRenderer: (data: any) => {
        return moment(data.value).format('MM/DD/YYYY HH:mm');
      }
    });
  }

//#endregion Manage Search column Definitions

}
