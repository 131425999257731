<div class="delete-interaction-container">
    <mat-dialog-content>
        <p>
            Are you sure you want to delete this interaction?
        </p>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-raised-button color="primary" [mat-dialog-close]="1" class="confirm-delete-button">Confirm
            Delete</button>
        <button mat-stroked-button [mat-dialog-close]="-1">Cancel</button>
    </div>
</div>