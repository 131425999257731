<div class="general-dialog">
  <h1 mat-dialog-title>{{ dialogData.title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="margin-top">
    <span [innerHTML]="dialogData.content"></span>

    <div *ngIf="generalDialogFormGroup">
      <form [formGroup]="generalDialogFormGroup">

        <dynamic-material-form [group]="generalDialogFormGroup" [model]="generalDialogFormModel">
        </dynamic-material-form>

      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="left-align">
      <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
        class="confirm-button">{{ dialogData.confirmBtnTxt }}</button>
      <button mat-stroked-button class="confirm-button" (click)="onNoClick()"
        *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    </div>
  </div>
</div>