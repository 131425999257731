<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="submitToBlock" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Select New {{dialogData}} Group</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <mat-form-field class="groups-container">
      <mat-label>Select a group</mat-label>
      <mat-select (selectionChange)="onSelectGroup($event)" required>
        <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupPublicKey">
          {{userGroup.groupName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button-lg" mat-raised-button [mat-dialog-close]="{groupPublicKey: selectedGroupKey}"
      color="primary" [disabled]="!selectedGroupKey">Submit</button>
    <button class="button-lg" mat-stroked-button [mat-dialog-close]="submitToBlock">Cancel</button>
  </mat-dialog-actions>
</div>