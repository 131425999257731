import { TutorialModel } from "./tutorial";

/**
 * This file contains the shapes to find and the steps that will occur when a tutorial is loaded.
 */

export const documentFeedbackTutorial: TutorialModel = {
    introJsConfig: {
        steps:  [{
            element: '#knowledge_feedback_prompt',
            intro: 'Provide Feedback to EVA to get better over time',
            position: 'right'
        }],
        exitOnEsc: false,
        exitOnOverlayClick: false
    }
};

