import { Injectable } from '@angular/core';
import { SubtleEncryptionService } from '@eva-core/encryption/subtle-encryption.service';
import * as Elliptic from 'elliptic';
import { GroupProviderService } from '@eva-core/group-provider.service';
import * as Crypto from 'crypto';
import * as utilsService from '@eva-core/encryption/utils';
import { KeysService } from '@eva-core/encryption/keys.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { StorageObjectData, PublicKeySpecificNode, StorageObject } from '@eva-model/storage/evaStorage';
import { environment } from '@environments/environment';
import { DecryptionAttemptObject, PublicKeySpecificEncryptedObject, EncryptedGroupMembersStorageObject,
  GroupSpecificEncryptedObject } from '@eva-model/encryption/encryption';
import { EVAStorageReturnObject } from '@eva-model/storage/evaStorage';
// const Crypto = require("crypto");


@Injectable()
export class EvaEncryptionService {

  private _encryptionKey: CryptoKey;
  private _signingKey: CryptoKeyPair;
  private EC = Elliptic.ec; // Elliptic.ec; // classany;
  private ec: Elliptic.ec;
  pbkdf2IVByteSize = 32;
  pbkdf2Digest = 'sha512';
  pbkdf2Iterations = 2000;

  constructor(
    private groupProviderService: GroupProviderService,
    private keysService: KeysService,
    private _evaGlobalService: EvaGlobalService
  ) {
    this.ec = new this.EC(environment.blockConfig.ENCRYPTION_CURVE);
  }

  /**
   * This function attempts to decrypt the Storage Object and returns the storage object.
   *
   * @param evaStorageReturnObject this is the object that has returned from EVA links
   * @param isUserLastUpdate was this used the last one to update the object? if so, they need to offset with their encryption key
   */
  async decryptStorageObjects(evaStorageReturnObject: EVAStorageReturnObject, isUserLastUpdate: boolean): Promise<DecryptionAttemptObject> {
    // get the specific data that we care about.
    const linkData = evaStorageReturnObject.linkData;
    // convert the encrypted data to a buffer.
    const encryptedData = new Buffer(linkData.encryptedData, 'hex');
    // get all existing public keys.
    const existingPublicKeys: string[] = linkData.existingPublicKeys;

    // determine which key is the offset.
    let publicKeyToUse = '';
    if (isUserLastUpdate) {
      publicKeyToUse = linkData.keyPairedTo;
    } else {
      publicKeyToUse = linkData.storagePublicKey;
    }
    // get the specific data that the user shares a assymetric key with.
    const encryptedUserSpecificData = new Buffer(linkData.requesterKeyPairEncryption.userSpecificEnc, 'hex');
    const salt = new Buffer(linkData.requesterKeyPairEncryption.salt, 'hex');

    let encryptionKeyObject: Elliptic.ec.KeyPair = null;
    try {
      if (isUserLastUpdate) {
        encryptionKeyObject = await this.keysService.getEncryptionKey();
      } else {
        encryptionKeyObject = await this.keysService.getSigningKey();
      }
      // get the bignumber instance of the shared encryption key in a string format.
      const stringSharedEncryptionKey = this.getSharedKey(encryptionKeyObject, publicKeyToUse).toString();
      // take the shared key and hash it according to the system and generate the password that is used for the encrypted data.
      const key: Buffer = Crypto.pbkdf2Sync(stringSharedEncryptionKey, salt, this.pbkdf2Iterations,
        this.pbkdf2IVByteSize, this.pbkdf2Digest);
      // decrypt the data and return it in the desired format.
      const individualDecrypted = this.decryptSync(encryptedUserSpecificData, key, salt);
      // parse the object for use.
      const itemEncryptionInfo = JSON.parse(individualDecrypted);
      // console.log(itemEncryptionInfo);
      // get the full data.
      const unencryptedObject = JSON.parse(
        this.decryptSync(encryptedData, itemEncryptionInfo.randomBytes.data, itemEncryptionInfo.iv.data));

      return { unencryptedObject: unencryptedObject, existingKeys: existingPublicKeys, status: 'OK', successful: true };
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function takes the groups and additional public keys. It creates a random password and initialization vector and
   * encrypts this object. It then takes the keys that should have access to the data and encrypts a shared key between them and
   * the user storing the data into the database. Once complete, the Partially completed storage object is returned.
   *
   * @param objectToEncrypt any object that you want to encrypt.
   * @param groupPublicKey the group public key to encrypt with
   * @param existingKeysToInclude an array of additional public keys to use.
   * @param idUrl the storage id if it exists.
   */
  async createStorageAndEncryptedData(objectToEncrypt: any, groupPublicKey?: string, existingKeysToInclude?: string[],
  idUrl?: string): Promise<StorageObject> {
    const objectToStore: StorageObjectData = new StorageObjectData();
    // check if the ID of this is known.
    if (idUrl) {
      objectToStore.idUrl = idUrl;
    }
    // create a random initialization vector and a random password (randomBytesToUse)
    const ivToUse = utilsService.Utils.createRandomIv();
    const randomBytesToUse = utilsService.Utils.createRandomKeyBytesForEncryption();
    // syncronyously encrypt the object using the random password and random initialization vector.
    const encryptedData = this.encryptSync(objectToEncrypt, randomBytesToUse, ivToUse);
    // convert the buffer to a hex representation of the data.
    objectToStore.encryptedData = Buffer.from(encryptedData).toString('hex');

    // create the object that has the initialization vector and password for the encryption.
    const encryptionObjectUserSpecific: PublicKeySpecificNode = {
      iv: ivToUse,
      randomBytes: randomBytesToUse
    };

    try {
      const userSpecificEncryptionObject = await this.getEncryptionGroupMembersObject(encryptionObjectUserSpecific, groupPublicKey,
        existingKeysToInclude);
      // set the objects that were returned.
      objectToStore.incomingAccess = userSpecificEncryptionObject.incomingAccess;
      objectToStore.storagePublicKey = userSpecificEncryptionObject.requesterPublicKey;

      // create the full storage object and add the data to it.
      const fullStorage: StorageObject = new StorageObject();
      fullStorage.data = objectToStore;
      return fullStorage;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This gets an existing group and existing keys and creates a new tree object with information available
   * to decrypt the data for each group key that is included.
   *
   * @param groupPublicKey groupPublicKey to include with encryption
   * @param encryptionObjectUserSpecific specific object to include with encryption (iv and random bytes)
   * @param existingKeysToInclude  additional keys to include in encryption tree
   */
  async getEncryptionGroupMembersObject(
    encryptionObjectUserSpecific: PublicKeySpecificNode,
    groupPublicKey?: string,
    existingKeysToInclude?: string[]): Promise<EncryptedGroupMembersStorageObject> {

    // create the encrypted object
    const unencryptionLocationObject: PublicKeySpecificEncryptedObject = {};

    // create the group membership
    let groupMembership: string[] = [];
    try {
      const encryptionKeyObject = await this.keysService.getEncryptionKey();
      // got elliptical curve object for ECDH asymmetric encryption
      if (groupPublicKey &&
      this._evaGlobalService.processFlowThroughGroup &&
      this._evaGlobalService.processFlowThroughGroup.groupPublicKey &&
      this._evaGlobalService.processFlowThroughGroup.groupPublicKey === groupPublicKey &&
      this._evaGlobalService.processFlowThroughGroupConfig &&
      this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys &&
      Array.isArray(this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys) &&
      this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys.length > 0 ) {

        groupMembership =
          this._evaGlobalService.processFlowThroughGroupConfig.permanentMembersPublicKeys.slice();
      } else if (groupPublicKey) {
        groupMembership = await this.groupProviderService.getEncryptionGroupMembership(groupPublicKey);
      }
        //#region determine if group membership was returned. If it was, add the keys as required.
        if (groupMembership && Array.isArray(groupMembership) && groupMembership.length > 0) {
          groupMembership.forEach(groupPK => {
            // get the shared key for asymmetric encryption of the keys object.
            const sharedEncryptionKey = this.getSharedKey(encryptionKeyObject, groupPK);
            const salt: Buffer = Crypto.randomBytes(16);
            const stringSharedEncryptionKey = sharedEncryptionKey.toString();
            const key: Buffer =
              Crypto.pbkdf2Sync(
                stringSharedEncryptionKey,
                salt,
                this.pbkdf2Iterations,
                this.pbkdf2IVByteSize,
                this.pbkdf2Digest);

            const encryptedUserSpecificObject = this.encryptSync(encryptionObjectUserSpecific, key, salt);

            const groupSpecificEncryptedObject: GroupSpecificEncryptedObject = {
              salt: Buffer.from(salt).toString('hex'),
              userSpecificEnc: Buffer.from(encryptedUserSpecificObject).toString('hex'),
            };

            // assign the group public keys' encryption into the items.
            unencryptionLocationObject[groupPK] = groupSpecificEncryptedObject;
          });
        }
        //#endregion

        //#region check for existing keys to include
        if (existingKeysToInclude) {
          existingKeysToInclude.forEach(keyToInclude => {
            const sharedEncryptionKey = this.getSharedKey(encryptionKeyObject, keyToInclude);
            const salt: Buffer = Crypto.randomBytes(16);
            const stringSharedEncryptionKey = sharedEncryptionKey.toString();
            const key: Buffer =
              Crypto.pbkdf2Sync(
                stringSharedEncryptionKey,
                salt,
                this.pbkdf2Iterations,
                this.pbkdf2IVByteSize,
                this.pbkdf2Digest);

            const encryptedUserSpecificObject = this.encryptSync(encryptionObjectUserSpecific, key, salt);
            const groupSpecificEncryptedObject: GroupSpecificEncryptedObject = {
              salt: Buffer.from(salt).toString('hex'),
              userSpecificEnc: Buffer.from(encryptedUserSpecificObject).toString('hex'),
            };

            // assign the group public keys' encryption into the items.
            unencryptionLocationObject[keyToInclude] = groupSpecificEncryptedObject;
          });
        }
        //#endregion
        if (Object.keys(unencryptionLocationObject).length === 0) {
          throw new Error('Failed to add any access info for the encrypted data');
        }
        const responseObject: EncryptedGroupMembersStorageObject = {
          incomingAccess: JSON.stringify(unencryptionLocationObject),
          requesterPublicKey: encryptionKeyObject.getPublic('hex')
        };

        return responseObject;
    } catch (err) {
      console.log('An error occured creating the keys object', err);
      return Promise.reject(err);
    }
  }


  /**
   * This returns a big number instance of the shared keys for use in the system.
   * It's not typed in the elliptic types so we've left it as any here.
   *
   * @param userEncKey the elliptic key pair that you are setting up against.
   * @param otherPublicKey the public key in hex format that you are wanting to create an instance against.
   */
  getSharedKey(userEncKey: Elliptic.ec.KeyPair, otherPublicKey: string): any {
    const key2 = this.ec.keyFromPublic(otherPublicKey, 'hex');
    return userEncKey.derive(key2.getPublic());
  }

//#region Encrypt and Decrypt

  /**
   * This function takes the data and encrypts it with the random bytes and initialization vector provided. If the algorithm isn't
   * provided it uses the AES 256 Cipher-Block-Chain algorithm.
   *
   * @param data this is any object that you want to encrypt
   * @param randomBytes the password that is used to encrypt (this must be a buffer size that is equivalent to the algorithm used)
   * ie. is the algorithm is 256 bits, this must be 32 bytes long 8 * 32 = 256
   * @param iv the initialization vector used (this is a random item used in the encryption to make brute force breaking of the data)
   * harder. It is half the size of the algorithm 16 Bytes - 8 * 16 = 128
   */
  encryptSync(data: any, randomBytes: Buffer, iv: Buffer, algorithm?: string): Buffer {
    try {
      const algorithmToUse = (algorithm) ? algorithm : 'aes-256-cbc';
      // create a crypto cipher based on the algorythm and that password (randombytes) and the random initialization vector
      const cipherIV = Crypto.createCipheriv(algorithmToUse, randomBytes, iv);
      // encrypt the data using the cipher and return the buffer to the calling function
      const encryptedData = Buffer.concat([cipherIV.update(new Buffer(JSON.stringify(data), "utf8")), cipherIV.final()]);
      return encryptedData;
    } catch (exception) {
        throw new Error(exception.message);
    }
  }

  /**
   * This function syncronously decrypts the data provided using the password, initialization vector and optional algorithm
   * provided. It then returns the data in the string format encoding that was provided.
   *
   * @param encryptedData the buffer of the encrypted data.
   * @param randomBytes the password for the encrypted object.
   * @param iv the initialization vector of the encrypted object
   * @param algorithm the algorithm to use for decrypting (if not provided is AES 256 Cipher Block Chain)
   * @param encoding the encoding to return the object in (if not specified UTF-8) which is JSON.parsable into a javascript object.
   */
  decryptSync(encryptedData: Buffer, randomBytes: Buffer, iv: Buffer, algorithm?: string, encoding?: string): string {
    try {
      const algorithmToUse = (algorithm) ? algorithm : 'aes-256-cbc';
      const encodingToUse = (encoding) ? encoding : 'utf-8';
      // create the decipher object from the alogrithm, password and initialization vector
      const decipher = Crypto.createDecipheriv(algorithmToUse, randomBytes, iv);
      // start decrypting the data.
      let decrypted = decipher.update(encryptedData);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      // return the UTF8 encoding of the encypted object.
      return Buffer.from(decrypted).toString(encodingToUse);

    } catch (exception) {
        throw new Error(exception.message);
    }
  }

//#endregion Encrypt and Decrypt

//#region getters and setters

  set EncryptionKey(key: any) {
    this._encryptionKey = key;
  }

  get EncryptionKey() {
    return this._encryptionKey;
  }

  set SigningKey(key: any) {
    this._signingKey = key;
  }

  get SigningKey() {
    return this._signingKey;
  }

//#endregion getters and setters

}
