import { Component, Input } from '@angular/core';
import { Link } from '@eva-model/link';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() link: Link;

}
