import { TutorialModel } from "../tutorial";

/**
 * This contains the tutorial for the chat service when it is triggered.
 */
export const feedbackModel: TutorialModel = {
  dialogConfig: {
    disableClose: true,
    data: {
      imageLink: '/assets/images/machine-learning-infographic.png',
      imageAlt: 'EVA Machine Learning Image',
      html: `<h3>Give your feedback to EVA!</h3>
      <p><strong>Machine Learning</strong></p>
      <p>EVA relies on user feedback to improve. This quick tutorial will teach you how to help EVA get better.</p>`
    }
  },
  introJsConfig: {
    steps:  [{
      element: '#knowledge_doc_name',
      intro: '<strong>Document Name</strong><br />This is the source document that the answer has been provided from.',
      position: 'top'
    },
    {
      element: '#knowledge_good',
      // eslint-disable-next-line max-len
      intro: '<strong>This is good</strong><br />Let EVA know it provided a helpful answer and contained no inaccuracies. This helps create more accurate responses in the future.',
      position: 'top',
    },
    {
      element: '#knowledge_feedback',
      // eslint-disable-next-line max-len
      intro: '<strong>Feedback</strong><br />If the answer EVA provides has incorrect information or is hard to understand, click to provide feedback about the document to the knowledge owners.',
      position: 'top'
    },
    {
      element: '#knowledge_see_document',
      intro: '<strong>See Document</strong><br />Click the chat message to see the source of the knowledge provided.',
      position: 'top'
    }],
    exitOnEsc: false,
    exitOnOverlayClick: false,
    scrollToElement: true,
    scrollPadding: 30,
    keyboardNavigation: true,
    scrollTo: 'element'
  }
};
