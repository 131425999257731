import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-chat-loading',
  templateUrl: './chat-loading.component.html',
  styleUrls: ['./chat-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLoadingComponent {

  constructor() { }

}
