import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { WorkFlowInteractionConditionSet } from '@eva-model/workflowInteractionConditionSet';

import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';

import {
  DynamicInputModel,
  DynamicFormControlModel,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
} from '@ng-dynamic-forms/core';

import { TreeNode } from 'primeng/api';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { TreeDragDropService } from 'primeng/api';
import { InteractionNodeService } from '@eva-services/interaction-node/interaction-node.service';

import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Observable, of as observableOf} from 'rxjs';

import { InteractionNode } from '@eva-model/interactionNode';
import { InteractionFlatNode } from '@eva-model/interactionFlatNode';
import { WorkFlowInteractionCondition } from '@eva-model/workflowInteractionCondition';
import { ElementSyncService } from '@eva-services/dynamic-interactions/element-sync.service';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionBuilderService } from '@eva-services/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'eva-interaction-condition-builder',
  templateUrl: './interaction-condition-builder.component.html',
  styleUrls: ['./interaction-condition-builder.component.scss']
})
export class InteractionConditionBuilderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('interactionMatTree') interactionMatTree;

  interaction: any;
  flatInteraction: any;
  interactionTree: TreeNode[] = null;

  conditionSets: WorkFlowInteractionConditionSet[] = [];
  tooltipShowDelay = 700;

  separatorKeysCodes = [ENTER, COMMA];

  Condition_Set_Edit_Name_INPUT =
    new DynamicInputModel({
      inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
      id: 'conditionSetEdit',
      label: 'Condition Set name',
      placeholder: `The condition set name`,
      value: null
    });

  Condition_Set_Edit_MODEL: DynamicFormControlModel[] = [
    this.Condition_Set_Edit_Name_INPUT
  ];

  generalDialogChangeSubscription: ISubscription;
  elementValueChangeSubscription: ISubscription;
  interactionNodeDataChangeSubscription: ISubscription;

  treeControl: FlatTreeControl<InteractionFlatNode>;
  treeFlattener: MatTreeFlattener<InteractionNode, InteractionFlatNode>;
  dataSource: MatTreeFlatDataSource<InteractionNode, InteractionFlatNode>;
  movingConditionSet = -1;

  @Input()
  set interactionObj(interactionObj: string) {
    if (!interactionObj) { return; }
    this.interaction = JSON.parse(interactionObj);
    this.interactionTree = this.formBuilderService.interactionToTreeNodes(this.interaction, true, false);
    this.interactionTreeInitializer();
  }

  @Input()
  set condition(condition: any) {

    if (!Array.isArray(condition)) { return; }
    condition.forEach(conditionItem => {
      if (conditionItem.name && Array.isArray(conditionItem.conditions)) {
        const cndtnSet =
          new WorkFlowInteractionConditionSet(
            conditionItem.name, [],
            conditionItem.connectionOperator !== '' ? conditionItem.connectionOperator : 'AND',
            conditionItem.order ? conditionItem.order : null
          );

        conditionItem.conditions.forEach(cndtn => {
          // if (cndtn.elementOriginalId && cndtn.operator) {
          const cndtnSetCndtn =
            new WorkFlowInteractionCondition(
              cndtn.elementOriginalId,
              cndtn.operator,
              cndtn.value,
              cndtn.scrnIdx,
              cndtn.elmntIdx,
              cndtn.connectionOperator !== '' ? cndtn.connectionOperator : 'AND',
              undefined,
              cndtn.secondValue);
          cndtnSet.conditions.push(cndtnSetCndtn);
          // }
        });

        this.conditionSets.push(cndtnSet);
      }
    });

    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }

  constructor(
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService,
    private arrayUtil: ArrayUtilsService,
    private formBuilderService: FormBuilderService,
    private treeDragDropService: TreeDragDropService,
    private interactionNodeService: InteractionNodeService,
    private elementSyncService: ElementSyncService,
    private interactionConditionBuilderService: WorkflowInteractionConditionBuilderService
  ) { }

  interactionTreeInitializer() {
    this.interactionNodeService.initialize(this.interactionTree);

    this.treeFlattener =
      new MatTreeFlattener(
        this.transformer,
        this._getLevel,
        this._isExpandable,
        this._getChildren);
    this.treeControl = new FlatTreeControl<InteractionFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.interactionNodeDataChangeSubscription =
      this.interactionNodeService.dataChange
        .subscribe(data => {
          this.dataSource.data = data;
        });
  }

  ngOnInit() {
    const that = this;
    this.flatInteraction = this.formBuilderService.flatInteraction(this.interaction, true);

    this.elementValueChangeSubscription =
      this.elementSyncService.elementValueChanged$
        .subscribe(
          (data) => {
            if (data && data.interactionId &&
              (data.conditionSetIndex || data.conditionSetIndex === 0) &&
              (data.elementIndex || data.elementIndex === 0) &&
              (this.interaction.id === data.interactionId)) {

              if (that.conditionSets &&
                Array.isArray(that.conditionSets) &&
                that.conditionSets.length > data.conditionSetIndex &&
                that.conditionSets[data.conditionSetIndex].conditions &&
                Array.isArray(that.conditionSets[data.conditionSetIndex].conditions) &&
                that.conditionSets[data.conditionSetIndex].conditions.length > data.elementIndex) {

                const conditionElement = that.conditionSets[data.conditionSetIndex].conditions[data.elementIndex];
                if (conditionElement.elementOriginalId === data.elementOriginalId) {
                  conditionElement.value = data.value;
                  conditionElement.secondValue = data.secondValue;
                  conditionElement.operator = data.valueOperator;
                  conditionElement.connectionOperator = data.connectionOperator;

                  this.interactionConditionBuilderService.announceConditionSetChange(that.conditionSets);
                }
              }
            }
          },
          (err) => { console.log(err); },
          () => { }
        );
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.
    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }

    if (this.elementValueChangeSubscription) {
      this.elementValueChangeSubscription.unsubscribe();
    }

    if (this.interactionNodeDataChangeSubscription) {
      this.interactionNodeDataChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.treeControl &&
      this.treeControl.dataNodes &&
      Array.isArray(this.treeControl.dataNodes) &&
      this.treeControl.dataNodes.length > 0) {

      const mainTreeNode = this.treeControl.dataNodes[0];
      if (mainTreeNode) this.treeControl.expand(mainTreeNode);
    }

    if (this.conditionSets.length === 0) {
      this.addConditionSet();
    }

    // this.interactionMatTree.treeControl.expandAll();
  }

  transformer = (node: InteractionNode, level: number) => {
    const flatNode = new InteractionFlatNode();
    flatNode.label = node.label;
    flatNode.type = node.type;
    flatNode.data = node.data;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    return flatNode;
  }

  private _getLevel = (node: InteractionFlatNode) => node.level;
  private _isExpandable = (node: InteractionFlatNode) => node.expandable;
  private _getChildren = (node: InteractionNode): Observable<InteractionNode[]> => {
    return observableOf(node.children);
  }
  hasChild = (_: number, _nodeData: InteractionFlatNode) => _nodeData.expandable;

  //#region Add/Edit/Remove condition set(s)
  addConditionSet(): void {
    const cndtnSet = new WorkFlowInteractionConditionSet(`Condition Set ${this.conditionSets.length + 1}`, [], 'AND');
    this.conditionSets.push(cndtnSet);
    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }

  /**
   * This function opens a dialog to confirm the removal of condition set.
   * If only 1 condition set left, removes it without the dialog box
   *
   * @param conditionSet Condition Set to remove
   */
  removeConditionSetConfirm(conditionSet: WorkFlowInteractionConditionSet): void {
    if (!conditionSet || !conditionSet.name) {
      alert('There is no valid condition set to be deleted, please try again!');
      return;
    }

    if (conditionSet.conditions.length === 0) {
      this.removeConditionSet({ conditionSet, that: this });
      return;
    }

    const dialogData = new GeneralDialogModel(
      'Delete Condition Set',
      `There appears to be element(s) on this condition set, would you like to delete this condition set?`,
      'Delete', 'Cancel');
    this.openGeneralDialog(dialogData, this.removeConditionSet,
      { conditionSet: conditionSet, that: this });
  }

  removeConditionSet(data: any): void {
    if (!data || !data.conditionSet) {
      return;
    }

    const index = data.that.conditionSets.indexOf(data.conditionSet);

    if (index >= 0) {
      data.that.conditionSets.splice(index, 1);
      if (data.that.conditionSets.length > 0) {
        data.that.conditionSets[data.that.conditionSets.length - 1].connectionOperator = '';
      }

      data.that.interactionConditionBuilderService.announceConditionSetChange(data.that.conditionSets);
    }
  }

  editConditionSetEvent(conditionSet, conditionSetIndex) {
    if (!conditionSet || !conditionSet.name) {
      alert('There is no valid condition set to be deleted, please try again!');
      return;
    }

    (this.Condition_Set_Edit_MODEL[0] as DynamicInputModel).value = conditionSet.name;
    const dialogData = new GeneralDialogModel(
      'Condition Set Edit dialog',
      `You may edit the name of selected condition set :: ${conditionSet.name}`,
      'Done', 'Cancel',
      this.Condition_Set_Edit_MODEL
    );

    this.openGeneralDialog(
      dialogData, this.editConditionSet,
      { conditionSet: conditionSet, conditionSetIndex: conditionSetIndex, conditionSets: this.conditionSets });
  }

  editConditionSet(data: any): void {
    if (!data || !data.conditionSet ||
      (!data.conditionSetIndex && data.conditionSetIndex !== 0) ||
      !data.generalDialogOnChange ||
      !data.conditionSets) {

      return;
    }

    if (data.generalDialogOnChange.conditionSetEdit && data.conditionSets[data.conditionSetIndex]) {
      data.conditionSets[data.conditionSetIndex].name = data.generalDialogOnChange.conditionSetEdit;
    }
  }
  //#endregion

  //#region Dialog related methods
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback, callbackData: any) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel
    });

    this.generalDialogChangeSubscription =
      this.generalDialogService.generalDialogChanged$
        .subscribe(
          changeObj => {
            if (changeObj) {
              if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
                callbackData['generalDialogOnChange'] = changeObj;
              }
            }
          },
          err => { console.log(err); }
        );

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }
  //#endregion

  //#region Condition set drag & drop related methods
  dragStartConditionSetHandler(ev) {
    ev.dataTransfer.setData('elmntId', 100);   // ev.target.id
    ev.dataTransfer.dropEffect = 'move';
  }

  dargOverConditionSetHandler(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'move';
  }

  dropConditionSetHandler(ev) {
    ev.preventDefault();
    const dropOnElmnt = ev.target;

    // Not expected drop zone to be drop a dragged element!
    if (!dropOnElmnt || dropOnElmnt.dataset.conditionSet !== 'true') { return; }

    const dragElmntId = ev.dataTransfer.getData('elmntId');
    const dragElmnt = document.getElementById(dragElmntId);

    // Not expected element to be dropped!
    if (!dragElmnt || dragElmnt.dataset.conditionSet !== 'true') { return; }

    const dragElmntIndex = +dragElmnt.dataset.conditionSetIndex;
    const dropOnElmntIndex = +dropOnElmnt.dataset.conditionSetIndex;

    // Drag and drop on same element means no action
    if (dropOnElmntIndex === dragElmntIndex) { return; }

    const dropElmntBox = dropOnElmnt.getBoundingClientRect();
    const dragNdropDirection = dropOnElmntIndex > dragElmntIndex ? 1 : -1;   // LTR vs RTL in this case
    const indexFixer =
      (Math.abs(dropElmntBox.right - ev.clientX) < Math.abs(dropElmntBox.left - ev.clientX))
        ? ((dragNdropDirection > 0) ? 0 : 1)
        : ((dragNdropDirection > 0) ? -1 : 0);

    const dragElmntNewIndex = dropOnElmntIndex + indexFixer;

    // Drag and drop which ends up on same location means no action
    if (dropElmntBox === dragElmntNewIndex) { return; }

    this.arrayUtil.array_move(this.conditionSets, dragElmntIndex, dragElmntNewIndex);
  }
  //#endregion

  //#region drag & Drop functionality related to condition elements in a condition set
  dragStartConditionElementHandler(ev) {
    ev.dataTransfer.setData('isFormControl', ev.target.dataset.formControl);
    ev.dataTransfer.setData('data', ev.target.dataset.formControlData);
    ev.dataTransfer.dropEffect = 'move';
  }

  dargOverConditionElementHandler(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'copy';
    return false;
  }

  /**
   * This function drops an element handler to the drop zone on drop event
   *
   * @param event HTML on drop event
   */
  dropConditionElementHandler(event: any): boolean {
    event.preventDefault();
    event.stopPropagation();

    let dropOnElmnt = event.target;
    if (dropOnElmnt.id !== 'conditionSetDropZone') {
      while (dropOnElmnt.offsetParent.id !== 'conditionSetDropZone') {
        dropOnElmnt = dropOnElmnt.offsetParent;
      }
      if (dropOnElmnt.offsetParent.id === 'conditionSetDropZone') {
        dropOnElmnt = dropOnElmnt.offsetParent;
      } else {
        return;
      }
    }   // Validate dropping zone
    const conditionSetIndex = +dropOnElmnt.dataset.conditionSetIndex;

    const dragElmntIsFormControl = event.dataTransfer.getData('isFormControl');
    if (!dragElmntIsFormControl) return;   // Validate dragged element

    let dragElmntData = event.dataTransfer.getData('data');
    if (dragElmntData) { dragElmntData = JSON.parse(dragElmntData); }
    if (!dragElmntData || !dragElmntData.id) return;
    const dragElmntId = dragElmntData.id;

    const condition = new WorkFlowInteractionCondition(dragElmntData.id, '', null, dragElmntData.scrnIndex, dragElmntData.elmntIndex);
    this.conditionSets[conditionSetIndex].conditions.push(condition);

    return false;
  }
  //#endregion

  /**
   * This function returns a form control from interaction elements based on ID
   *
   * @param elementOriginalId ID of the form control element
   */
  getFormControlById(elementOriginalId: string): any {
    let formControl = null;

    if (this.flatInteraction &&
      this.flatInteraction.elements &&
      Array.isArray(this.flatInteraction.elements)) {

        formControl = this.flatInteraction.elements.find(elmnt => elmnt.id === elementOriginalId);
    }
    return formControl;
  }

  //#region Condition Element's related functionalities
  removeConditionElementConfirm($event) {
    // {frmScrnIndex: number, frmElementId: string}
    const cndtnSetIndex = +$event.frmScrnIndex;
    const cndtnElementId = $event.frmElementId;
    const formElementIndex = +$event.frmElmntIndex;

    const dialogData = new GeneralDialogModel(
      'Condition Element Removal dialog', `Are you sure to remove the condition element ?`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.removeConditionElement,
      { theConditionSets: this.conditionSets, cndtnSetIndex: cndtnSetIndex, cndtnElementId: cndtnElementId, that: this,
        conditionElementIndex: formElementIndex });
  }

  private removeConditionElement(data) {
    if (!data || (!data.cndtnSetIndex && data.cndtnSetIndex !== 0) || !data.cndtnElementId ||
      !data.theConditionSets ||
      !Array.isArray(data.theConditionSets) ||
      !data.theConditionSets[data.cndtnSetIndex] ||
      !data.theConditionSets[data.cndtnSetIndex].conditions || (!data.conditionElementIndex && data.conditionElementIndex !== 0)) {

      return;
    }

    const cndtnSet = data.theConditionSets[data.cndtnSetIndex];
    if (cndtnSet.conditions && Array.isArray(cndtnSet.conditions) && cndtnSet.conditions.length > 0) {

      // get index of form element object with id
      const removeIndex = data.conditionElementIndex;
      if (removeIndex !== -1) {
        // remove form element object
        cndtnSet.conditions.splice(removeIndex, 1);
        if (cndtnSet.conditions.length > 0) {
          cndtnSet.conditions[cndtnSet.conditions.length - 1].connectionOperator = '';
        }
        data.that.interactionConditionBuilderService.announceConditionSetChange(data.that.conditionSets);
      }
    }
  }

  /**
   * This function moves condition element up by 1 in the conditions list
   *
   * @param $event Event
   */
  moveUpConditionElement($event: any): void {
    const cndtnSetIndex = +$event.frmScrnIndex;
    const cndtnIndex = +$event.frmElmntIndex;

    if (cndtnSetIndex < 0 || cndtnIndex < 1 ||
      (this.conditionSets.length - 1) < cndtnSetIndex) {
      return;
    }

    const cndtnSet = this.conditionSets[cndtnSetIndex];
    if (cndtnSet.conditions && cndtnSet.conditions.length - 1 >= cndtnIndex) {
      this.arrayUtil.array_move(cndtnSet.conditions, cndtnIndex, cndtnIndex - 1);
      if (cndtnSet.conditions.length > 0) {
        cndtnSet.conditions[cndtnSet.conditions.length - 1].connectionOperator = '';
      }
    }
    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }

  moveDownConditionElement($event) {
    const cndtnSetIndex = +$event.frmScrnIndex;
    const cndtnIndex = +$event.frmElmntIndex;

    if (cndtnSetIndex < 0 || cndtnIndex < 0 ||
      (this.conditionSets.length - 1) < cndtnSetIndex) {
      return;
    }

    const cndtnSet = this.conditionSets[cndtnSetIndex];
    if (cndtnSet.conditions && cndtnSet.conditions.length - 1 >= cndtnIndex + 1) {
      this.arrayUtil.array_move(cndtnSet.conditions, cndtnIndex, cndtnIndex + 1);
      if (cndtnSet.conditions.length > 0) {
        cndtnSet.conditions[cndtnSet.conditions.length - 1].connectionOperator = '';
      }
    }
    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }
  //#endregion

//#region ComponentHTML Funcitons

  /**
   * This is used in the HTML as part of a click event to set the condition as either and AND or an OR condition.
   */
  onConditionSetConnectionOperator(conditionSetIndex: number, connectionOperator: string): void {
    this.conditionSets[conditionSetIndex].connectionOperator = connectionOperator;
  }

  /**
   * This is used to stringify the object in the HTML functions.
   * @param jsonObj any object that is going to be bound in the component.
   */
  jsonStringify(jsonObj: any): string {
    return JSON.stringify(jsonObj);
  }

  /**
   * This function is called when tab label is hovered
   *
   * @param index Index of the tab being hovered
   */
  onHoverTabLabel(index): void {
    this.conditionSets[index].hovered = true;
  }

  /**
   * This function is called when hover leaves tab label
   *
   * @param index Index of the tab being hovered
   */
  onHoverLeaveTabLabel(index): void {
    this.conditionSets[index].hovered = false;
  }

  /**
   * This function updates the label of the tab
   * @param event HTML key down event
   * @param index Index of the tab being changed
   */
  onTabLabelChange(event: any, index: number): void {
    event.stopPropagation();
    if (event.keyCode === 13 || event.charCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  /**
   * This function checks for the validity of new tab label
   * @param event HTML focus out event
   * @param index Index of the tab being changed
   */
  onTabLabelBlur(event: any, index: number): void {
    const input = event.target.innerText;

    if (input.trim() === '') {
      alert('Condition Set name cannot be empty');
      event.target.innerText = this.conditionSets[index].name;
    } else {
      this.conditionSets[index].name = input.trim();
    }
    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }

  /**
   * This function re-orders the condition sets on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the condition set being switched with the condition set being dragged
   */
  reorderScreens(event: any, index: number): void {
    if (this.movingConditionSet === -1) {
      return;
    }

    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [this.conditionSets[this.movingConditionSet], this.conditionSets[index]]
      = [this.conditionSets[index], this.conditionSets[this.movingConditionSet]];
    this.movingConditionSet = -1;
    this.interactionConditionBuilderService.announceConditionSetChange(this.conditionSets);
  }

  /**
   * This function prevents default action on dragOver event
   * @param event HTML dragOver event
   */
  allowdrop(event: any): void {
    event.preventDefault();
  }

  /**
   * This function stores the index for condition set being dragged
   * @param event HTML dragStart event
   * @param index Index of the condition set being dragged
   */
  onDrag(event: any, index: number): void {
    this.movingConditionSet = index;
  }

  setConditionSetConnectionOperator(event: MatSelectChange, conditionSet: any) {
    conditionSet.connectionOperator = event.value;
  }

}
