import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowInteractionConditionBuilderService {

  // Observable any sources
  private interactionConditionSetChangeSource = new Subject<any>();
  private interactionConditionSetDialogChangeSource = new Subject<any>();

  // Observable any streams
  conditionSetChanged$ = this.interactionConditionSetChangeSource.asObservable();
  conditionSetDialogChanged$ = this.interactionConditionSetDialogChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceConditionSetChange(change: any) {
    this.interactionConditionSetChangeSource.next(change);
  }

  // Service message commands
  announceConditionSetDialogChange(change: any) {
    this.interactionConditionSetDialogChangeSource.next(change);
  }

}
