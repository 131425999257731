import { Component, Input } from '@angular/core';
import { LastState } from '@eva-model/userLastState';
import { ChatService } from '@eva-services/chat/chat.service';
import { ChatEntityAuthor, ChatEntityType, NextChatQueryType } from '@eva-model/chat/chat';
import { EvaHeartTrainingService } from '@eva-services/chat/eva-heart-training.service';
import { ChatMLGetBetterUserResponse, ChatMLInitialUserResponse } from '@eva-model/chat/chatML';
import { KnowledgeDocumentSectionFlat } from '@eva-model/knowledge/knowledge';

@Component({
  selector: 'app-chat-ml-training',
  templateUrl: './chat-ml-training.component.html',
  styleUrls: ['./chat-ml-training.component.scss']
})
export class ChatMlTrainingComponent {

  goodText = 'This is good';
  feedbackText = 'Content Feedback';

  @Input() chatId: string;
  @Input() lastState: LastState;
  @Input() data: {
    query: string;
    groupPublicKey: string;
    modelVersion: string;
    docName: string;
    docId: string;
    docVersion: number;
    docSection: string;
    sectionHtml: string;
    section: KnowledgeDocumentSectionFlat;
  };

  constructor(private chatService: ChatService,
              private knowledgeTrainingService: EvaHeartTrainingService) { }

  mlResponse(type: 'good' | 'content-feedback' | 'knowledge-update'): void {
    // populate chat entity
    switch (type) {
      case 'good':
        this.handleGood();
        break;
      case 'content-feedback':
        this.handleFeedback();
        break;
      case 'knowledge-update':
        this.handleKnowledgeUpdate();
        break;
    }
  }

  handleGood() {
    // create a replacement entity for this entity that shows what the user clicked.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.User,
      type: ChatEntityType.Text,
      text: this.goodText
    }, this.chatId);

    try {
      // create a response from EVA about what they clicked. We don't really care if this
      // fails, at least the user shouldn't.. so just handle the error if it happens.
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: `Thanks for the feedback! I'll use this to get better.`
      });

      this.sendGoodTraining();
    } catch (err) {
      console.error('failed to send good training', err);
    }
  }

  handleFeedback() {
    // create a replacement entity for this entity that shows what the user clicked.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.User,
      type: ChatEntityType.Text,
      text: this.feedbackText
    }, this.chatId);

    // create a response from EVA about what they clicked.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: 'Please share any feedback about the answer you received'
    });

    // set chat to emit type on next user input
    this.chatService.setNextChatQueryType(NextChatQueryType.MLRESPONSE_GETBETTER_OTHER);
  }

  handleKnowledgeUpdate() {
    // create a replacement entity for this entity that shows what the user clicked.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.User,
      type: ChatEntityType.Text,
      text: 'Knowledge Update'
    }, this.chatId);

    // create a response from EVA about what they clicked.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: 'If you would like to amend existing or propose new knowledge in EVA, please fill out the <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbp48HWqJtVaE_SaEgSKqgZfEUnlfCN_xsk9mcM2WjPRAhog/viewform" target="_blank">ATB Knowledge Update Request</a> form.'
    });
  }

  async sendGoodTraining() {
    try {
      const training = await this.knowledgeTrainingService.createTfidfTrainingObject(
        this.data.query,
        this.data.groupPublicKey,
        this.data.modelVersion,
        this.data.docId,
        ChatMLInitialUserResponse.GOOD,
        null,
        this.data.section
      );
      await this.knowledgeTrainingService.sendTfidfTrainingData(training);
    } catch (err) {
      console.error('failed to send good training', err);
    }
  }

}
