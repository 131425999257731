import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InteractionData } from "@eva-model/interactionBuilder";

@Component({
    selector: 'app-delete-interaction-dialog',
    templateUrl: './delete-interaction-dialog.component.html',
    styleUrls: ['./delete-interaction-dialog.component.scss']
})
export class DeleteInteractionDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: InteractionData) { }
}