import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { WorkFlow } from '@eva-model/workflow';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'app-id-map-id-changes',
  templateUrl: './id-map-id-changes.component.html',
  styleUrls: ['./id-map-id-changes.component.scss']
})
export class IdMapIdChangesComponent {

  entity: (WorkFlow | any);         // The imported entity - "workflow", "interaction" etc.
  idList: any[];                    // List of all the id mappings for the imported entity with entity in current environment
  oldEntityEnvironment = '';        // Environment name for imported entity object
  oldEntityVersion = '';            // Version of the imported entity object
  oldGroupName = '';                // Name of the group imported entity belonged to in previous environment
  newEntityEnvironment = '';        // Current environment name
  newEntityVersion = '';            // New version of the entity object in current environment after submitting to block
  entityType = '';                  // Type of the entity object - "workflow", "interaction" etc.
  interactions: any[] = [];         // List of workflow interactions for workflow entity object or interaction entity object itself
  interactionPairIds: any[] = [];   // Pair of interaction id's
  importedEntityName = '';        // name of the imported workflow

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    public evaGlobalService: EvaGlobalService) {
    this.entityType = dialogData.dynFormMdl.type;
    this.entity = dialogData.dynFormMdl.entity;
    this.idList = dialogData.dynFormMdl.idList;
    this.oldEntityEnvironment = dialogData.dynFormMdl.environment;
    this.oldEntityVersion = new Date(Number(dialogData.dynFormMdl.oldEntityVersion)).toLocaleString();
    this.newEntityVersion = new Date(Number(dialogData.dynFormMdl.newEntityVersion)).toLocaleString();
    this.oldGroupName = dialogData.dynFormMdl.oldGroupName;
    this.importedEntityName = dialogData.dynFormMdl.importedEntityName;
    this.interactionPairIds = dialogData.dynFormMdl.interactionPairIds || [];
    const url = window.location.href;

    // get the current environment name
    if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
      this.newEntityEnvironment = 'Development';
    } else if (url.includes('staging.atbeva.com')) {
      this.newEntityEnvironment = 'Staging';
    } else if (url.includes('test.atbeva.com')) {
      this.newEntityEnvironment = 'Test';
    } else if (url.includes('atbeva.com')) {
      this.newEntityEnvironment = 'Production';
    }

    if (this.entityType === 'Workflow') {
      this.interactions = this.entity.interactions;
    } else if (this.entityType === 'Interaction') {
      this.interactions = [{ interaction: this.entity }];
    }
  }

  /**
   * This function checks if the form element is a special control or not
   *
   * @param interaction Interaction containing the form element
   * @param formElement Form element being checked
   */
  isSpecialControlElement(interaction: any, formElement: any): boolean {
    let flag = false;
    for (const specialControl of interaction.specialControls) {
      for (const control of specialControl.controls) {
        if (control.id === formElement.id) {
          flag = true;
          break;
        }
      }
      if (flag) {
        break;
      }
    }
    return flag;
  }

  /**
   * This function returns the previous environment id for the newId
   *
   * @param newId new Id for which oldId is being returned
   */
  getOldId(newId: string): string {
    const pair = this.idList.find(idPair => idPair.newId === newId);
    if (pair) {
      const interactionPairObject = this.interactionPairIds.find(interactionPair => interactionPair.oldId === newId);
      if (interactionPairObject) {
        return interactionPairObject.newId;
      }
      return pair.oldId;
    } else {
      return '';
    }
  }

}
