<h2 mat-dialog-title>EVA Chat History</h2>
<div mat-dialog-content class="scroll-messages" appScrollable (scrollPosition)="scrollHandler($event)">
  <mat-spinner *ngIf="getChat.loading | async"></mat-spinner>
  <ng-container *ngFor="let message of getChat.data | async">
    <div class="message" [ngClass]="{ 'eva': message.user === false, 'user': message.user === true }">
      <h6 *ngIf="!message.user">EVA says:</h6>
      <h6 *ngIf="message.user">You said:</h6> {{ message.message }}
      <span class="time-stamp">{{message.createdAt | date:'medium'}}</span>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions>
  <div fxFlex>
  </div>
  <button mat-raised-button color="accent" (click)="onNoClick()">Okay</button>
</div>