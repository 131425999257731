<p>
    Group: {{ this.evaGlobalService.getGroupNameByPublicKey(notifyObj.destinationPublicKey) }}
</p>
<p>
    Created On: {{ notifyObj.createdAt | date:'medium' }}
</p>
<p>
  Updated At: {{ notifyObj.updatedAt | date:'medium' }}
</p>

