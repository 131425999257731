const GRAPH_CONFIG = {
    N: 10,
    SPECTRUM: [
        "rgb(176,212,243)",
        "rgb(128,186,236)",
        "rgb(77,158,228)",
        "rgb(38,137,223)",
        "rgb(0,116,217)",
        "rgb(0,106,197)"
    ]
};

export default GRAPH_CONFIG;
