/**
 * This contains the main menu file for the EVA application (default EVA Screen)
 */
import { EVAMenu } from "@eva-model/menu/menu";
import { interactionBuilderSVGIcon, workflowBuilderSVGIcon } from "./icons";
import { Routes } from "./mainMenu";

export const BuilderMenu: EVAMenu[] = [{
    routerLink: Routes.InteractionBuilder,
    svgIcon: interactionBuilderSVGIcon,
    text: 'Interaction'
}, {
    routerLink: Routes.WorkflowBuilder,
    svgIcon: workflowBuilderSVGIcon,
    text: 'Workflow'
}
];
