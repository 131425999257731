import { Component, OnInit, Inject } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-group-select-dialog',
  templateUrl: './group-select-dialog.component.html',
  styleUrls: ['./group-select-dialog.component.scss']
})
export class GroupSelectDialogComponent implements OnInit {

  submitToBlock = false;                  // whether submit the imported interaction to block or not
  selectedGroupKey: string = null;        // Public key of the group selected by user
  userGroups: any[] = [];                 // List of user groups from current environment

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    public evaGlobalService: EvaGlobalService) { }

  ngOnInit() {
    this.evaGlobalService.userGroupsChange$.subscribe(changeObj => {
      if (this.evaGlobalService.userGroups) {
        this.userGroups = this.evaGlobalService.userGroups;
      }
    });
  }

  /**
   * This function changes the selected group based on user selection
   *
   * @param event Selection Change Event of MatSelect
   */
  onSelectGroup(event: MatSelectChange): void {
    this.selectedGroupKey = event.value;
  }
}
