import { Component, OnInit, Input } from '@angular/core';
import { WorkFlow, WorkFlowCondition } from '@eva-model/workflow';
import { Node } from '@eva-model/node';
import { Link } from '@eva-model/link';
import { WorkflowGraphService } from '@eva-services/workflow-graph/workflow-graph.service';
import { workflowInteractionDest } from '@eva-ui/shared/constants';

@Component({
  selector: 'eva-workflow-graph',
  templateUrl: './workflow-graph.component.html',
  styleUrls: ['./workflow-graph.component.scss']
})
export class WorkflowGraphComponent implements OnInit {

  wf: WorkFlow;

  nodes: Node[] = [];
  links: Link[] = [];

  @Input()
  set wrkflw(wrkflw: WorkFlow ) {
    this.wf = JSON.parse(JSON.stringify(wrkflw));
    this.workflowfSegmnenter(this.wf);
  }

  constructor(private wfGraphService: WorkflowGraphService) { }

  ngOnInit() {
    const that = this;

    //#region Remarked
    // this.wfGraphService.workflowGraphChanged$
    // .subscribe(
    //   (wf) => {
    //     that.workflowfSegmnenter(wf);
    //   },
    //   (err) => {
    //     console.log(err);
    //   },
    //   () => {}
    // );
    //#endregion
  }

  workflowfSegmnenter(workflow: WorkFlow): any {
    this.nodes.length = 0;
    this.links.length = 0;

    if (!(workflow && workflow.interactions && Array.isArray(workflow.interactions) && workflow.interactions.length > 0 )) return;

    workflow.interactions.forEach( (intrct, intrctIdx) => {
      this.nodes.push(new Node(intrct.interactionId, intrct.interactionName, 'interaction'));

      if ( intrct.conditions && Array.isArray(intrct.conditions) && intrct.conditions.length > 0 ) {
        intrct.conditions.forEach( (cndtn, cndtnIdx) => {
          this.nodes.push(new Node(cndtn.id, cndtn.name, 'condition'));
        });
      }
    });

    workflow.interactions.forEach( (intrct, intrctIdx) => {
      if ( intrct.conditions && Array.isArray(intrct.conditions) && intrct.conditions.length > 0 ) {
        const cndtn = intrct.conditions[0];
        this.conditionDestinations(intrct.interactionId, cndtn, intrct.conditions);
      }
    });

  }

  conditionDestinations(parentNodeId: string, cndtn: WorkFlowCondition, intrctConditions: WorkFlowCondition[]) {
    if (!( cndtn && parentNodeId )) return;
    this.links.push(new Link(parentNodeId, cndtn.id));

    const cndtnDestinations: any[] = [];
    if (Array.isArray(cndtn.trueDestination) && cndtn.trueDestination.length > 0) cndtnDestinations.push(cndtn.trueDestination);
    if (Array.isArray(cndtn.falseDestination) && cndtn.falseDestination.length > 0) cndtnDestinations.push(cndtn.falseDestination);

    cndtnDestinations.forEach( ( cndtnDestination ) => {
      let destination = null;
      for (const dest of cndtnDestination ) {
        destination = JSON.parse(JSON.stringify(dest));
        if (dest.type !== workflowInteractionDest.group) break;
      }

      if ( destination.type === workflowInteractionDest.interaction ) {
        this.links.push(new Link(cndtn.id, destination.id));
      } else if ( destination.type === workflowInteractionDest.group ) {
        // TODO :: Just a placeholder for now and later it needs a link to Group
      } else if ( destination.type === workflowInteractionDest.condition ) {
        const destCndtnIdx = intrctConditions.map(c => c.id).indexOf(destination.id);
        if ( destCndtnIdx !== -1 ) {
          this.conditionDestinations(cndtn.id, intrctConditions[destCndtnIdx], intrctConditions);
        }
      }
    });

  }

}
