<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-title>
      Members
    </mat-card-title>
    <mat-list *ngIf="publicKeys.length > 0">
      <mat-list-item *ngFor="let key of publicKeys">
          <app-user-info-display matLine [publicKey]="key"></app-user-info-display>
      </mat-list-item>
    </mat-list>
    <mat-list *ngIf="publicKeys.length === 0">
      <mat-list-item>
        <div matLine>
          No Members in this Group
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button color="accent" (click)="openGroupPublicKeySnackBar()">Group Key</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
  </div>
</div>