<div class="process-detail-viewer-container">
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process Name"
          [(ngModel)]="prcsName"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process group name"
          [(ngModel)]="prcsGrpName"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process Status"
          [(ngModel)]="prcsStatus"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process create date time"
          [(ngModel)]="prcsCreatedDateTimeString"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process last update date time"
          [(ngModel)]="prcsLastUpdateDateTimeString"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process current workflow"
          [(ngModel)]="prcsWorkflowName"
        />
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="100%">
      <mat-form-field class="fullWidth">
        <input
          matInput
          class="fullWidth"
          [readonly]="true"
          placeholder="Process current interaction"
          [(ngModel)]="prcsInteractionName"
        />
      </mat-form-field>
    </div>
  </div>
</div>
