<div *ngFor="let interaction of compactProcess.interactions">

  <div class="interaction-summary-header">
        <h3 class="interaction-name">&nbsp;&nbsp;&nbsp;Process Summary for {{ interaction.name }}</h3>
  </div>

  <mat-card class="interaction-summary-body">
    <mat-dialog-content class="interaction-summary-step">
      <div *ngFor="let formScreen of interaction.screens">
        <h4 class="interaction-summary-step-header"> {{ formScreen.name }}
            <a class="edit-interaction-btn" (click)="goToInteraction(interaction.originalId, interaction.order, formScreen.order)" matTooltip="Edit this Interaction?"><mat-icon>edit</mat-icon></a>
        </h4>
        <mat-list>
          <div *ngFor="let field of formScreen.elements">
            <mat-list-item *ngIf='field.isDisabled === false' class="summary-list-item">
              <div><div class="bold inline" [innerHTML]="field.label"> </div>: <div class="inline" [innerHTML]="getCompactElementValue(field)"></div></div>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
    </mat-dialog-content>
  </mat-card>

</div>

<br/>

<mat-card class="sticky-process-action-bar">
  <mat-dialog-actions class="action-button-right">
    <button mat-raised-button color="primary" (click)="submitProcess()">Submit</button>
  </mat-dialog-actions>
</mat-card>
