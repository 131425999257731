import { Injectable } from '@angular/core';

import * as hash from 'hash.js';

@Injectable()
export class CryptoFunctionsService {

  private hashFunction: any; // for use in the hash functions.

  constructor() {
    this.hashFunction = hash;
  }

  /**
 * this function takes a string object and creates a hash from it.
 * @param objectString this object to hash
 */
  getMessageHash(objectString: string): string {
    /// ||| this might be the way to do it and type to a string
    // return this.hashFunction.utils.toHex(this.hashFunction.sha256().update(objectString));
    return this.hashFunction.sha256().update(objectString).digest('hex');
    // .update(objectString, 'hex').toString();
    // .update(objectString).digest('hex');
  }
  /**
   * This function takes a byte array and creates a hexString from it.
   * @param byteArray
   */
    // ||| is this typed right?
  /* eslint-disable no-bitwise */
  toHexString(byteArray): string {
    return Array.prototype.map.call(byteArray, function(byte) {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('');
  }

  /**
   * This function takes a hexstring and creates a bytes array.
   * @param hexString
   */
  /* eslint-disable no-bitwise */
  toByteArray(hexString): number[] {
    const result: number[] = [];
    while (hexString.length >= 2) {
      result.push(parseInt(hexString.substring(0, 2), 16));
      hexString = hexString.substring(2, hexString.length);
    }
    return result;
  }
}
