import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Mark from 'mark.js';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { MarkQueryResult } from './MarkQueryResult';

@Component({
  selector: 'app-knowledge-find-and-replace-dialog',
  templateUrl: './knowledge-find-and-replace-dialog.component.html',
  styleUrls: ['./knowledge-find-and-replace-dialog.component.scss']
})
export class KnowledgeFindAndReplaceDialogComponent implements OnInit {
  originalHtml: string; // html being passed in, to be parsed into a document
  newHtml: string; // html to be outputted back to dialog caller
  liveDocument: HTMLDocument; // live doc from originHtml

  results: MarkQueryResult[];
  resultsFound: number;
  resultsChanged = 0; // set as the total results that have been changed
  noResults = false;

  findAndReplaceForm: UntypedFormGroup;

  markJsOpts = {
    caseSensitive: false,
    className: 'marked-item',
    each: (node: any) => {
      // TODO: Consider replacing this with regex, the approach below seems a bit... much.
      // Get our contents
      const innerHTMLSnapshot = node.parentNode.innerHTML;

      // Parse the html
      const parsedHTMLSnapshot = new DOMParser().parseFromString(innerHTMLSnapshot, 'text/html');

      // Query to see how many marked items there are in there
      const markDoc = parsedHTMLSnapshot.getElementsByTagName('mark');

      // If elements are found
      while (markDoc.length > 1) {
        // Since this is a live collection, always focus on the first element in colletion
        const mark = markDoc[0];

        // Place to store any children elements
        const docFrag = document.createDocumentFragment();
        // Move each child node to our fragment
        while (mark.firstChild) {
            const child = mark.removeChild(mark.firstChild);
            docFrag.appendChild(child);
        }
        // replace mark element with document fragment (the children of the mark element)
        mark.parentNode.replaceChild(docFrag, mark);
      }

      // Get the innerHTML for display
      const displayHTMLString = parsedHTMLSnapshot.body.innerHTML;

      this.results.push({
        liveNode: node,
        displayHTML: displayHTMLString,
        changed: false,
        id: this.results.length + 1
      });
    },
    done: (matches: number) => {
      this.resultsFound = matches;
    },
    noMatch: () => {
      this.results = null;
      this.noResults = true;
    }
  };

  constructor(public dialogRef: MatDialogRef<KnowledgeFindAndReplaceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {html: string}) { }

  ngOnInit() {
    this.originalHtml = this.data.html;

    // Create our find and replace inputs
    this.findAndReplaceForm = new UntypedFormGroup({
      find: new UntypedFormControl('', Validators.required),
      replace: new UntypedFormControl('', Validators.required)
    });
  }

  /**
   * Converts the html string from the previous component (that was passed in) to a live HTMLDocument,
   * then use a package to query our doc and place <mark> elements around any matches. This allows us
   * to query the document later for these elements and replace them.
   */
  searchDocument() {
    // Check if there is a value in the find input field
    if (!this.findAndReplaceForm.valid) {
      return;
    }

    this.noResults = false;
    this.results = [];

    // Create an HTML Doc from our wysiwyg html
    this.liveDocument = new DOMParser().parseFromString(this.data.html, 'text/html');

    // Create instance of mark.js from our knowledge doc
    const markJs = new Mark(this.liveDocument.body.childNodes);

    // Invoke mark and set component state
    markJs.mark(this.findAndReplaceForm.get('find').value, this.markJsOpts);
  }

  /**
   * Modify the HTMLDocument by replacing the mark element with the value of our replace control.
   * @param index index of the result we would like to replace with the value of the our replace control
   */
  updateFoundTermWithReplacement(index: number) {
    const ele = this.results[index].liveNode;
    this.updateNodeWithReplaceValue(ele);

    // Update our results with the change
    this.results[index].changed = true;
    // Count the update
    this.resultsChanged = this.resultsChanged + 1;
  }

  /**
   * Modify the HTMLDocument by replacing all mark elements with the value of our replace control.
   */
  updateAllFoundTermsWithReplacement() {
    this.results.forEach((r: MarkQueryResult) => {
      if (!r.changed) {
        this.updateNodeWithReplaceValue(r.liveNode);

        // Update our results with the change
        r.changed = true;
        // Count the update
        this.resultsChanged = this.resultsChanged + 1;
      }
    });
  }

  /**
   * Replace a node with a text node and set the text node value to the replace FormControl value.
   */
  private updateNodeWithReplaceValue(node: any): void {
    node.parentNode.replaceChild(document.createTextNode(this.findAndReplaceForm.get('replace').value), node);
  }

  /**
   * Emits the html and closes the dialog. We must do a few things first,
   * 1) find any existing <mark> elements and remove them
   * 2) convert the HTMLDocument back into a string
   * 3) close dialog and pass back html string
   */
  closeDialogAndEmitNewHTML() {
    // 1) Let markJS do the work for us by removing any loftover <mark> elements...
    const markJs = new Mark(this.liveDocument.body.childNodes);

    // Invoke mark and set component state
    markJs.unmark({
      done: () => {
        // 2) set html to empty and start adding to it
        this.newHtml = '';
        const allNodes = KnowledgeUtils.createNodeList(this.liveDocument.body.innerHTML);
        allNodes.forEach((node: any) => {
          this.newHtml += node.outerHTML;
        });

        // 3) Return our updated html as string
        this.dialogRef.close(this.newHtml);
      }
    });
  }

  /**
   * Used with ngFor to track which items in the result set has changed, helps for performance if there are many results.
   */
  trackById(index: number, item: MarkQueryResult) {
    return item.id;
  }

}
