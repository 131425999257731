<svg:g>
  <svg:defs>
    <svg:marker id="arrow"
      markerUnits="strokeWidth"
      markerWidth="12"
      markerHeight="12"
      viewBox="0 0 12 12"
      refX="6"
      refY="6"
      orient="auto">
      <svg:path d="M2,2 L10,6 L2,10 L6,6 L2,2" style="fill: #f00;"></svg:path>
    </svg:marker>
  </svg:defs>

  <svg:line
    class="link"
    [attr.x1]="link.source.x"
    [attr.y1]="link.source.y"
    [attr.x2]="link.target.x"
    [attr.y2]="link.target.y">
  </svg:line>
</svg:g>
