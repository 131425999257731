/**
 * This service provides information about the current scroll position of an element passed by class name.
 **/

import {
  Injectable,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import {
  fromEvent,
  BehaviorSubject,
  Observable,
  of
} from 'rxjs';
import {
  map,
  share,
  startWith
} from 'rxjs/operators';
import { ScrollPosition } from "@eva-model/util/scrollPosition";

@Injectable()
export class WindowScrollingService {

  private targetClassName: string;

  constructor (
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  /**
   * send positional scroll data about a passed element
   * @param ele - a passed element for reporting position on
   * @private
   */
  static _sendPositionData(ele: Element) {
    const position: ScrollPosition = {
      isScrollable: ele ? ele.scrollHeight > ele.clientHeight : false,
      isAtTop: ele ? ele.scrollTop === 0 : false,
      isAtBottom: ele ? ele.scrollTop === (ele.scrollHeight - ele.clientHeight) : false,
      scrollTop: ele ? ele.scrollTop : 0,
      scrollHeight: ele ? ele.scrollHeight : 0,
      clientHeight: ele ? ele.clientHeight : 0,
      maxHeight: ele ? ele.scrollHeight - ele.clientHeight : 0
    };

    return position;
  }

  /**
   * initializes the service by attaching a scroll event listener to an observable through a passed class name
   * @param targetClassName - the classname of the element to listen to
   */
  initialize (targetClassName: string): Observable<ScrollPosition> {
    this.targetClassName = targetClassName;

    // set up event based observable if on a browser
    if (isPlatformBrowser(this.platformId) && document.getElementsByClassName(this.targetClassName).length > 0) {
      return fromEvent(document.getElementsByClassName(this.targetClassName), 'scroll').pipe(
        startWith({ target: document.getElementsByClassName(this.targetClassName)[0] }), // send mock event initial scroll data element
        map((event: Event) => {
          // cast event target to element
          const ele = (event.target as Element);

          // send updated scrolling data
          return WindowScrollingService._sendPositionData(ele);
        }),
        share()
      );
    } else {
      return of(null);
    }
  }
}
