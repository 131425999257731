<div *ngIf="_isAdministrator">
  <button mat-button color="primary" class="release-notes-button" (click)="addEditReleaseNotes()">Add / Edit Release Notes</button>
</div>
<div class="updates-card" *ngIf="releaseDoc">
  <h3 class="component-title">Updates</h3>

  <div class="updates-card-content">
    <section class="section slider">

      <input type="radio" name="slider" id="{{'slide-' + (releaseIndex + 1)}}" class="slider__radio"
        (click)="changeReleaseNote(release, releaseIndex)" [checked]="releaseIndex === 0" [value]="release"
        *ngFor="let release of releaseDoc.releaseNotes; let releaseIndex = index;">

      <div class="slider__holder">
        <label for="{{'slide-' + (releaseIndex + 1)}}"
          class="{{'slider__item slider__item--' + (releaseIndex + 1) + ' card'}}"
          *ngFor="let release of releaseDoc.releaseNotes; let releaseIndex = index;">
          <div class="slider__item-content">
            <p class="release-date-header">Release Date:</p>
            <p class="release-date-value">{{release.releaseDate | date:shortDate }}</p>
            <div class="release-date-description" *ngIf="release.description"
              [innerHtml]="release.description | safeHtml"></div>
          </div>
        </label>
      </div>

      <div class="bullets">
        <label for="{{'slide-' + (releaseDocIndex + 1)}}"
          class="{{'bullets__item bullets__item--' + (releaseDocIndex + 1)}}"
          *ngFor="let release of releaseDoc.releaseNotes; let releaseDocIndex = index;">
          <i *ngIf="releaseIndex !== releaseDocIndex" class="fa fa-circle-thin" aria-hidden="true"></i>
          <i *ngIf="releaseIndex === releaseDocIndex" class="fa fa-circle" aria-hidden="true"></i>
        </label>
      </div>

    </section>
  </div>
</div>
<ng-template *ngIf="!releaseDoc">Loading Release Data...</ng-template>