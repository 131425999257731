import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chrome-authentication',
  templateUrl: './chrome-authentication.component.html',
  styleUrls: ['./chrome-authentication.component.css']
})
export class ChromeAuthenticationComponent implements OnInit {

  authToken: string;
  // authorizationToken = new EventEmitter<authToken>();

  isChromium: any;
  winNav: Navigator;
  vendorName: string;
  isOpera: boolean;
  isIEedge: boolean;
  isIOSChrome: RegExpMatchArray;
  type: string;
  constructor() { } // private _window:WindowRef) { }

  ngOnInit() {
    /**
     * please note,
     * that IE11 now returns undefined again for window.chrome
     * and new Opera 30 outputs true for window.chrome
     * and new IE Edge outputs to true now for window.chrome
     * and if not iOS Chrome check
     * so use the below updated condition
    **/
    // this.isChromium = window.chrome;
    this.winNav = window.navigator;
    this.vendorName = this.winNav.vendor;
    this.isOpera = this.winNav.userAgent.indexOf('OPR') > -1;
    this.isIEedge = this.winNav.userAgent.indexOf('Edge') > -1,
      this.isIOSChrome = this.winNav.userAgent.match('CriOS');
  }

  /**
   * This function creates the appropriate authorization flow depending on
   * where salt is existing for the user.
   */
  /**
  login() {
    this
     .googleAuthService
     .login()
     .subscribe(
       (success) => {
         // do whatever you want with success response here
       },
       (error) => {
         // handle error here
       })
    }
  auth(): void {
    if ()
  }
    /**
    function determineType() {
      if (this.isIOSChrome) {
        this.type = 'Chrome - Apple';
      } else if (this.isChromium !== )

    }
if(isIOSChrome){
console.log('is Google Chrome on IOS');
alert('is Google Chrome on IOS');
} else if(isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
console.log('is Google Chrome');
alert('is Google Chrome');
} else {
console.log('not Google Chrome');
alert('not Google Chrome');
}
  }
 */
  /**
   * The following function determines what
   */

}
