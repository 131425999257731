import { Injectable } from '@angular/core';
import { AnnounceKnowledgeEdit, AnnounceKnowledgeShow } from '@eva-model/chat/knowledge/chatKnowledge';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Params, Router } from '@angular/router';
import { KnowledgeDocumentTableOfContents, KnowledgeSectionFocusAnnounce } from '@eva-model/knowledge/knowledge';
import { filter, tap } from 'rxjs/operators';
import { UrlShortenerService } from '@eva-services/url-shortener/url-shortener.service';
import { ClipboardService } from 'ngx-clipboard';
import { LoggingService } from '@eva-core/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ChatKnowledgeService {
  /**
   * announce showing knowledge is a behaviour subject, because in some cases, we would like to show a document, but need to do a
   * router change first... so we keep this as a 'queue' and once the route has been hit, get the waiting value. If no waiting announcement,
   * will be a null value.
   */
  private announceShowingKnowledge: BehaviorSubject<AnnounceKnowledgeShow | null> = new BehaviorSubject(null);
  private announceHidingKnowledge: Subject<boolean> = new Subject();
  private announceEditKnowledge: BehaviorSubject<AnnounceKnowledgeEdit | null> = new BehaviorSubject(null);

  // public announcer streams
  public announceShowKnowledge$: Observable<AnnounceKnowledgeShow> = this.announceShowingKnowledge.asObservable();
  public announceHideKnowledge$: Observable<boolean> = this.announceHidingKnowledge.asObservable();
  public announceEditKnowledge$: Observable<AnnounceKnowledgeEdit> = this.announceEditKnowledge.asObservable();

  // table of contents
  private announceShowTableOfContents: Subject<boolean> = new Subject();
  public announceShowTableOfContents$: Observable<boolean> = this.announceShowTableOfContents.asObservable();

  // passing table of contents data
  private tableOfContents: BehaviorSubject<KnowledgeDocumentTableOfContents> = new BehaviorSubject(null);
  public tableOfContents$: Observable<KnowledgeDocumentTableOfContents> = this.tableOfContents.asObservable();

  /**
   * All knowledge components subscribe to this so we can notify them if they need to refocus a new section.
   * This is because we cannot access the MatTab template component from MatTab events, etc in any way. :(
   */
  private knowledgeSectionFocusSource = new Subject<KnowledgeSectionFocusAnnounce>();

  // data keeping
  private activeTabDocumentId: string;
  private scrollPositions: {[docId: string]: number} = {};

  constructor(private router: Router,
              private urlShortenerService: UrlShortenerService,
              private clipboardService: ClipboardService,
              private loggingService: LoggingService) { }

  /**
   * Called by the chat to change the view on the {@link HomeComponent}. This announcement contains
   * enough process information to be injected in the {@link ProcessComponent} when it is created
   * and injected into the view on the {@link HomeComponent}.
   */
  public async announceKnowledgeShow(announcement: AnnounceKnowledgeShow) {
    this.announceShowingKnowledge.next(announcement);
  }

  /**
   * Announce that the left pane should hide if it's currently showing the Knowledge pane.
   */
  public announceKnowledgeHide(): void {
    this.announceHidingKnowledge.next(true);
  }

  /**
   * Announucement to create a tab or open an existing tab to edit a knowledge document
   */
  public announceKnowledgeEdit(announcement: AnnounceKnowledgeEdit): void {
    this.announceEditKnowledge.next(announcement);
  }

  /**
   * Controls show/hide of table of contents dialog and setting data being displayed in dialog
   */
  public showTableOfContents(): void {
    this.announceShowTableOfContents.next(true);
  }

  public hideTableOfContents(): void {
    this.announceShowTableOfContents.next(false);
  }

  public setTableOfContents(data: KnowledgeDocumentTableOfContents): void {
    this.tableOfContents.next(data);
  }

  public clearTableOfContents(): void {
    this.tableOfContents.next(null);
  }

  /**
   * Clears the announce observable. Usually run once a view has recieved the announcement and is actioning it.
   * This could potentially be run as a pipe on the observable, but a non-viewed component could still be listening and clear
   * the value prematurely.
   */
  public clearShowAnnounce(): void {
    this.announceShowingKnowledge.next(null);
  }

  /**
   * Records the position the user is at in a document. This is user for knowledge tabbing. When switching
   * tabs, we restore the position the user was at in the specified document.
   * @param docId id of the document
   * @param position px from the top of the document
   */
  public saveScrollPosition(docId: string, position: number): void {
    if (this.activeTabDocumentId && docId !== this.activeTabDocumentId) {
      return;
    }

    this.scrollPositions[docId] = position;
  }

  /**
   * Gets an existing scroll position, or returns a 0
   */
  public getScrollPosition(docId: string): number {
    return this.scrollPositions[docId] ? this.scrollPositions[docId] : 0;
  }

  /**
   * Keeps track of the active tab document id being viewed
   * Used to block scroll events from being recorded since when you switch tabs, the previous tab
   * is scrolled to the top and the last scroll position is overwritten to a place the user was not at.
   */
  public setActiveTabDocumentId(docId: string): void {
    this.activeTabDocumentId = docId;
  }

  /**
   * Takes query params from the url and announces a knowledge document
   *
   * Supported query params:
   * type = knowledge
   * id = doc id
   * gpk = group public key
   * v = doc version
   * sid = section id to highlight
   * p = published
   */
  public handleKnowledgeFromQueryParams(params: Params) {
    const announce: AnnounceKnowledgeShow = {
      docId: params['id'],
      docGroup: params['gpk'],
      promptForFeedback: false
    };

    // check for any other supported query params

    // optional: published to false
    // can either be 0 or 1 for true/false
    // if no published is specified, defaults to get the last published version
    if (params['p'] && params['p'] === '0') {
      announce.published = false;
    } else {
      announce.published = true;
    }

    // optional: version
    // only obeys version request if published is false
    if (params['v'] && !announce.published) {
      announce.docVersion = Number(params['v']);
    }

    // hightlight section id
    if (params['sid']) {
      announce.sectionId = params['sid'];
    }

    this.announceKnowledgeShow(announce);
  }

  /**
   * Takes an anchor href attribute and parses it for any query params,
   * then announces the link to be shown.
   * @param href href attribute from an anchor <a> element
   */
  public parseUrlStringAndAnnounce(href: string) {
    // strip off url before query params
    href = href.replace(/https?:\/\/([^?]*)/, '');

    const queryParams = {};
    const pairs = (href[0] === '?' ? href.substr(1) : href).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    if (queryParams['id'] && queryParams['gpk']) {
      this.handleKnowledgeFromQueryParams(queryParams);
    }
  }

  /**
   * Takes an anchor href attribute and parses it for any query params,
   * then navigates the app to this url via the router.
   * @param href href attribute from an anchor <a> element
   */
  public parseUrlStringAndNavigate(href: string) {
    // strip off url before query params
    href = href.replace(/https?:\/\/([^?]*)/, '');

    const queryParams = {};
    const pairs = (href[0] === '?' ? href.substr(1) : href).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    // passing 0 will load published version of the doc.
    this.router.navigateByUrl(`/knowledge/view/${queryParams['id']}/${queryParams['gpk']}/0}`);
  }

  /**
   * Emits a docId and sectionId to focus on. All knowledge components are subscribed and if they are the target,
   * they will scroll to the section and set that has highlighted. This is because we cannot access the inner Knowledge
   * component from the MatTab component.
   */
  public focusSectionId(docId: string, sectionId: string, highlight: boolean): void {
    this.knowledgeSectionFocusSource.next({docId, sectionId, highlight});
  }

  /**
   * Returns an observable watching this doc id for section announcements
   * @param docId document id
   */
  public listenForSectionFocusByDocId(docId: string): Observable<KnowledgeSectionFocusAnnounce> {
    return this.knowledgeSectionFocusSource.asObservable().pipe(
      filter(v => v.docId === docId)
    );
  }

  /**
   * Generates a link to the document and copies it to the clipboard.
   *
   * @param sectionId section id (eg. 14_1)
   * @param version number - only provided on a draft document!
   */
  copyDocumentLinkToClipboard(documentId: string, groupPublicKey: string, sectionId?: string, version?: number) {
    const params = {
      type: 'knowledge',
      id: documentId,
      gpk: groupPublicKey
    };

    // if linking to a specific section
    if (sectionId) {
      params['sid'] = sectionId;
    }

    // if linking from a doc that is a draft, create link directly to this draft.
    if (version) {
      params['v'] = version;
    }

    const urlToCopy = this.urlShortenerService.createUrlWithQueryParams('/', params);

    this.clipboardService.copyFromContent(urlToCopy);

    // Notify of the copy command that happened
    this.loggingService.logMessage('Link copied!', false, 'info', null, 3000);
  }

}
