<div class="form-viewer-component">
  <h1 mat-dialog-title>{{ dialogData.title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="margin-top">
    {{ dialogData.content }}
    <br /><br />

    <mat-horizontal-stepper>

      <mat-step *ngFor="let scrn of formScreens; let scrnIndex = index;">
        <ng-template matStepLabel>{{scrn.name}}</ng-template>
        <mat-divider></mat-divider>
        <br />

        <div *ngIf="formViewerFormGroupArray[scrnIndex]" class="full-width">
          <form [formGroup]="formViewerFormGroupArray[scrnIndex]">

            <dynamic-material-form [group]="formViewerFormGroupArray[scrnIndex]"
              [layout]="formViewerLayoutArray[scrnIndex]" [model]="formViewerModelArray[scrnIndex]">
            </dynamic-material-form>

          </form>
        </div>

        <br />
        <div [class.buttons-row]="scrnIndex < formScreens.length - 1 && scrnIndex !== 0"
          [class.flex-end]="scrnIndex === 0">
          <button *ngIf="scrnIndex > 0" mat-raised-button matStepperPrevious type="button">Back</button>
          <button *ngIf="scrnIndex < formScreens.length - 1" mat-raised-button matStepperNext
            type="button">Next</button>
        </div>
        <br />
      </mat-step>
    </mat-horizontal-stepper>

  </div>
  <div mat-dialog-actions>
    <br />
    <div class="margin-left-auto">
      <button mat-stroked-button (click)="onNoClick()"
        *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true"
        cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
    </div>
  </div>
</div>