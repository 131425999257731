import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '@eva-core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ATBGlobalGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) {
  }


  /**
   * This checks to make sure that the user is a member of the ATB global group before loading process dashboard data.
   * If they are not a member, this will redirect the user to the homepage
   * @param next
   * @param state
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      const userEmail = await this.authService.getUserEmail();
      if (userEmail.endsWith('@atb.com')) {
        return resolve(true);
      } else {
        this.router.navigate(['/']);
        return resolve(false);
      }
    });
  }
}
