import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WorkFlow } from '@eva-model/workflow';

@Injectable({
  providedIn: 'root'
})
export class EvaChatWorkflowsService {

  private workflowSource = new Subject<WorkFlow>();

  private showAvailableWorkflowsState = false; // when change, emit threw showAvailableWorkflows
  private announceShowAvailableWorkflows = new Subject<boolean>();

  public currentWorkflow$: Observable<WorkFlow> = this.workflowSource.asObservable();
  public announceShowAvailableWorkflows$: Observable<boolean> = this.announceShowAvailableWorkflows.asObservable();

  constructor() { }

  /**
   * when a workflow is selected
   */
  sendData(workflow: WorkFlow) {
    this.workflowSource.next(workflow);
  }

  /**
   * shows the workflow chooser dialog or hides it
   *
   * @param toggle - true or false if you want to show workflow ui or not
   */
  toggleAvailableWorkflows() {
    this.showAvailableWorkflowsState = !this.showAvailableWorkflowsState;
    this.announceShowAvailableWorkflows.next( this.showAvailableWorkflowsState );
  }
}

