<app-page-header [title]="componentTitle" subTitle="Frequently updated statuses on submitted Processes"></app-page-header>

<form [formGroup]="prcsStatusReportFormGrp">
  <div class="form-container">
    <div class="date-filter-container">
      <mat-form-field class="fullWidth">
        <input matInput class="fullWidth" type="datetime-local" placeholder="From (Date time)"
          (change)="onFromDateTimeChange($event.target.value)" formControlName="fromDateTime" value="">
      </mat-form-field>
    </div>

    <div class="date-filter-container">
      <mat-form-field class="fullWidth">
        <input matInput class="fullWidth" type="datetime-local" placeholder="To (Date time)"
          (change)="onToDateTimeChange($event.target.value)" formControlName="toDateTime" value="">
      </mat-form-field>
    </div>

    <div>
      <button mat-raised-button *ngIf="!isWaiting" class="get-process-status-report-button" color="primary" (click)="getProcessStatusReport()"
        [disabled]="!this.toDateTime || !this.fromDateTime">
        <i class="material-icons">bug_report</i>
        Process Status Report
      </button>
    </div>

  </div>
</form>

<div class="app-loading" *ngIf="isWaiting">
  <mat-spinner></mat-spinner>
  <ng-content></ng-content>
  <h6>{{ onWaitMessage }}</h6>
</div>

<ag-grid-angular *ngIf="!isWaiting" #prcsReportGrid class="ag-theme-dark ag-grid-container"
  [gridOptions]="prcsStatusReportGridOptions" [rowData]="prcsStatusReportData" rowSelection="multiple"
  (gridReady)="prcsStatusReportGridReady()" (modelUpdated)="prcsStatusReportGridModelUpdated()"
  (rowGroupOpened)="prcsStatusReportGridRowGroupOpened()" (firstDataRendered)="prcsStatusReportGridFirstDataRendered()">
</ag-grid-angular>

<div *ngIf="isWaiting">
  <br /><br /><br /><br /><br /><br />
</div>

<!-- <p-toast></p-toast> -->