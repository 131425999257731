<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content>
  <div>
        <form [formGroup]="FAQForm">
            <!-- (ngSubmit)="addEditDocument()"> -->
            <mat-form-field class="full-width-input">
              <input matInput type="text" formControlName="title" placeholder="FAQ Title" required>
              <mat-error *ngIf="FAQForm.get('title').hasError('required')">
                FAQ Title Required
              </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="full-width-input">
              <textarea matInput type="textarea" formControlName="description" rows="4" placeholder="FAQ Description" required></textarea>
              <mat-error *ngIf="FAQForm.get('description').hasError('required')">
                FAQ Description Required
              </mat-error>
            </mat-form-field>
            <br />
          </form>
        </div>
</div>
<div mat-dialog-actions>
  <button (click)="addEditFAQs()" mat-raised-button color="primary" type="submit" [disabled]="!FAQForm.valid && !addUpdate">{{ dialogData.confirmBtnTxt }}</button>
  <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
</div>