//#region Classes for process edit summary
// these classes are used to build a compacted process model which uses only the data that is relevant to the process edit summary

// stores data relevant to the process as a whole
export class CompactProcess  {
    constructor (
        public name: string,
        public interactions: CompactProcessInteraction[]
    ) {}
}

// stores the interaction screen array and data relevant to the interaction as a whole
export class CompactProcessInteraction {
    constructor (
        public name: string,
        public originalId: string,
        public order: number, // position it was added in to the interactionValues array
        public screens: CompactProcessInteractionScreeen[] // form screens
    ) {}
}

// stores the interaction's form screen and its relevant data
export class CompactProcessInteractionScreeen {
    constructor (
        public name: string,
        public order: number, // form screens position within the interaction
        public elements: CompactProcessInteractionElement[] // individual elements
    ) {}
}

// store an interactions element and its relevant data to be displayed in the process edit summary
export class CompactProcessInteractionElement {
    constructor (
        public type: string, // elements type, relevant for some
        public label: string,
        public value: any,
        public order: number, // position within form screen
        public isDisabled: boolean,
        public prefix?: string | null,   // Dynamic Input control prefix value
        public suffix?: string | null,   // Dynamic Input control suffix value
    ) {}
}

//#endregion End classes for process edit summary
