<app-page-header [title]="componentTitle" subTitle="Logs of what is happening in the EVA system"></app-page-header>

<div>
    <ag-grid-angular #evaLogsGrid class="ag-grid-container ag-theme-dark" [gridOptions]="evaLogsGridOptions"
        [rowData]="logData" rowSelection="multiple" (gridReady)="evaLogsGridReady()"
        (modelUpdated)="evaLogsGridModelUpdated()" (rowGroupOpened)="evaLogsGridRowGroupOpened()"
        (RowDataChanged)="evaLogsGridRowDataChanged()" (firstDataRendered)="evaLogsGridFirstDataRendered()">
    </ag-grid-angular>

    <br />
    <button mat-raised-button color="primary" class="delete-button" (click)="deleteSelectedRowsConfirm()">
        <i class="material-icons">delete_forever</i>
        Delete selected logs
    </button>
    <br />

    <!-- <p-growl [(value)]="growl_messages" life = "7000"></p-growl> -->
</div>