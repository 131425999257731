import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowInteractionConditionViewerService {

  // Observable any sources
  private interactionConditionChangeSource = new Subject<any>();

  // Observable any streams
  interactionConditionChanged$ = this.interactionConditionChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceInteractionConditionChange(change: any) {
    this.interactionConditionChangeSource.next(change);
  }

}
