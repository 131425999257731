import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ReleaseNotesService } from '@eva-services/about/release-notes.service';
import { ReleaseNote, ReleaseNotes } from '@eva-model/releaseNotes';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { AddEditReleaseNotesComponent } from './add-edit-release-notes/add-edit-release-notes.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  loadingReleaseNotes = true; // spinner for loading
  releaseNotes$: Observable<any>; // observable for release notes
  releaseDoc: ReleaseNotes; // release note document

  releaseDescription: string; // description about the release.
  releaseDate: number; // the timestamp of the release note
  releaseIndex: number; // index of the current release doc being viewed
  innerWidth = 0; // width of the user display
  viewChangeWidth = 1030; // width below which the slider UI changes

  _isAdministrator = false; // whether the user is an EVA admin
  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  constructor(
    private releaseNotesService: ReleaseNotesService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.releaseIndex = 0;
    this.getReleaseNotes();
  }

  /**
   * This function gets the release notes and sets them up for the component.
   */
  getReleaseNotes() {
    this.releaseNotesService.getReleaseNotes()
    .pipe(
      take(1)
    )
    .subscribe(notes => {
      // console.log(notes);
      this.releaseDoc = notes;

      setTimeout(() => {
        this.updateInitialSliderView();
      }, 0);
      // ensure a release note was created for the environment.
      if (typeof this.releaseDoc !== 'undefined') {
        this.setupInitialSettings();
      }
    });
  }

  /**
   * this sets up the initial release note with the value from the array
   */
  setupInitialSettings() {
    // get the last value that has been entered into the release notes.
    if (Array.isArray(this.releaseDoc.releaseNotes) && this.releaseDoc.releaseNotes.length > 0) {
      this.releaseDescription = this.releaseDoc.releaseNotes[0].description;
      this.releaseDate = this.releaseDoc.releaseNotes[0].releaseDate;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = event.target.innerWidth;
    this.updateSliderView(this.releaseIndex);
  }

  updateInitialSliderView(): void {
    const elements = document.getElementsByClassName('slider__item');

    for (let releaseIndex = 0; releaseIndex < elements.length; releaseIndex++) {
      (elements[releaseIndex] as HTMLElement).style.position = '';
      (elements[releaseIndex] as HTMLElement).style.zIndex = '-1';
      (elements[releaseIndex] as HTMLElement).style.opacity = '0';
      (elements[releaseIndex] as HTMLElement).style.transform = '';
    }

    const element = (document.getElementsByClassName('slider__item--' + (this.releaseIndex + 1))[0] as HTMLElement);
    if (element) {
      element.style.zIndex = '2';
      element.style.position = 'relative';
      element.style.opacity = '1';
      element.style.transform = 'translate(0) scale(1)';
    }

    const nextElement = (document.getElementsByClassName('slider__item--' + (this.releaseIndex + 2))[0] as HTMLElement);
    if (nextElement) {
      nextElement.style.zIndex = '1';
      nextElement.style.opacity = '1';
      if (this.innerWidth > this.viewChangeWidth) {
        nextElement.style.transform = 'translateX(100px) scale(0.85)';
      } else {
        nextElement.style.transform = 'translateX(10%) scale(0.85)';
      }
    }

    if (this.releaseIndex < this.releaseDoc.releaseNotes.length - 2 && this.innerWidth > this.viewChangeWidth) {
      const secondNextElement = (document.getElementsByClassName('slider__item--' + (this.releaseIndex + 3))[0] as HTMLElement);
      if (secondNextElement) {
        secondNextElement.style.zIndex = '0';
        secondNextElement.style.opacity = '1';
        secondNextElement.style.transform = 'translateX(210px) scale(0.65)';
      }
    }
  }

  updateSliderView(index: number): void {
    const elements = document.getElementsByClassName('slider__item');

    for (let releaseIndex = 0; releaseIndex < elements.length; releaseIndex++) {
      (elements[releaseIndex] as HTMLElement).style.position = '';
      (elements[releaseIndex] as HTMLElement).style.zIndex = '-1';
      (elements[releaseIndex] as HTMLElement).style.opacity = '0';
      (elements[releaseIndex] as HTMLElement).style.transform = '';
    }

    const element = (document.getElementsByClassName('slider__item--' + (index + 1))[0] as HTMLElement);

    if (element) {
      element.style.zIndex = '2';
      element.style.opacity = '1';
      element.style.position = 'relative';
      element.style.transform = 'translate(0) scale(1)';
    }

    const previousElement = (document.getElementsByClassName('slider__item--' + (index))[0] as HTMLElement);
    if (previousElement) {
      previousElement.style.zIndex = '1';
      previousElement.style.opacity = '1';
      if (this.innerWidth > this.viewChangeWidth) {
        previousElement.style.transform = 'translateX(-100px) scale(0.85)';
      } else {
        previousElement.style.transform = 'translateX(-10%) scale(0.85)';
      }
    }

    if (index > 1 && this.innerWidth > this.viewChangeWidth) {
      const secondPreviousElement = (document.getElementsByClassName('slider__item--' + (index - 1))[0] as HTMLElement);
      if (secondPreviousElement) {
        secondPreviousElement.style.zIndex = '0';
        secondPreviousElement.style.opacity = '1';
        secondPreviousElement.style.transform = 'translateX(-210px) scale(0.65)';
      }
    }

    const nextElement = (document.getElementsByClassName('slider__item--' + (index + 2))[0] as HTMLElement);
    if (nextElement) {
      nextElement.style.zIndex = '1';
      nextElement.style.opacity = '1';
      if (this.innerWidth > this.viewChangeWidth) {
        nextElement.style.transform = 'translateX(100px) scale(0.85)';
      } else {
        nextElement.style.transform = 'translateX(10%) scale(0.85)';
      }
    }

    if (index < this.releaseDoc.releaseNotes.length - 2 && this.innerWidth > this.viewChangeWidth) {
      const secondNextElement = (document.getElementsByClassName('slider__item--' + (index + 3))[0] as HTMLElement);
      if (secondNextElement) {
        secondNextElement.style.zIndex = '0';
        secondNextElement.style.opacity = '1';
        secondNextElement.style.transform = 'translateX(210px) scale(0.65)';
      }
    }
  }

  /**
   * Change the selected release note for the user.
   *
   * @param release this is the release note that will be used
   */
  changeReleaseNote(release: ReleaseNote, index: number): void {
    this.releaseDescription = release.description;
    this.releaseDate = release.releaseDate;

    this.updateSliderView(index);

    this.releaseIndex = index;
  }

  /**
   * This brings up the add or edit dialog for release notes.
   */
  addEditReleaseNotes() {
    let dialogData = null;
    let dialogComponent = null;

    const title = 'Release Notes';
    const confirmButtonText = 'Save';
    const content = '';
    const cancelButtonText = 'Cancel';

    dialogData = new GeneralDialogModel(
      title,
      content, confirmButtonText,
      cancelButtonText, null,
      {
        data: this.releaseDoc
      }
    );
    dialogComponent = AddEditReleaseNotesComponent;
    const dialogRef: MatDialogRef<AddEditReleaseNotesComponent, ReleaseNotes> = this.dialog.open(dialogComponent, {
      data: dialogData,
      disableClose: true,
      minHeight: '400px',
      minWidth: '800px'
    });

    /**
     * Fires after this completes.
     */
    dialogRef.afterClosed().subscribe(async result => {
      // check for a result.
      if (result) {
        try {
          // update the release note.
          await this.releaseNotesService.storeReleaseNotes(result);
        } catch (err) {
          console.log(err);
        }
        // finally refactor the release notes to show the outcome of the updates
        this.getReleaseNotes();
      }
    });
  }
}
