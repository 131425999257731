import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { WorkflowStore, RetrieveWorkflow, AnnounceProcessStart,
  AnnounceProcessFinish, WorkflowStoreObject } from '@eva-model/chat/process/chatProcess';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { distinctUntilChanged, filter, map, flatMap, withLatestFrom, take, pairwise } from 'rxjs/operators';
import { WorkflowService } from '@eva-services/workflow/workflow.service';
import { WorkFlow } from '@eva-model/workflow';
import { userLastStateType } from '@eva-model/userLastState';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
// import { get, set, keys, del, Store } from 'idb-keyval';

// const cacheTimeout = 86400000; // milliseconds (24 hours)
// const cacheTimeout = 43200000; // milliseconds (12 hours)

// const localDBWorkflowStore = new Store('atbEVA', 'workflows'); // local db (indexDB) storage (db name, table name)

@Injectable({
  providedIn: 'root'
})
export class ChatProcessService {

  /******************/
  /*  Workflows
  /******************/

  // Private - Workflow data store and workflow to retrieve
  private workflowStore: BehaviorSubject<WorkflowStore> = new BehaviorSubject({});
  private nextWorkflow: BehaviorSubject<RetrieveWorkflow> = new BehaviorSubject(null);
  private announceStartingProcess: Subject<AnnounceProcessStart> = new Subject<AnnounceProcessStart>();
  private announceFinishedProcess: Subject<AnnounceProcessFinish> = new Subject<AnnounceProcessFinish>();
  private announceFetchProcess: Subject<WorkFlow> = new Subject<WorkFlow>();

  // Public - Workflow observables
  public workflowStore$: Observable<WorkflowStore> = this.workflowStore.asObservable();
  public nextWorkflow$: Observable<RetrieveWorkflow> = this.nextWorkflow.asObservable();
  public announceStartingProcess$: Observable<AnnounceProcessStart> = this.announceStartingProcess.asObservable();
  public announceFinishedProcess$: Observable<AnnounceProcessFinish> = this.announceFinishedProcess.asObservable();
  public announceFetchProcess$: Observable<WorkFlow> = this.announceFetchProcess.asObservable();

  // Chat
  private chatId: BehaviorSubject<string> = new BehaviorSubject(null);
  public chatId$: Observable<string> = this.chatId.asObservable();

  // Local DB - key is the workflow id , value is workflow id with timestamp (key in local db)
  // this set up enables us to easily see if a workflow in local db has expired without having to retrieve it.
  private workflowsInLocalDb: Map<string, string> = new Map();

  // Initialization, sets to true when we have done our first check on the lastState object.
  finishedInitialLastStateCheck = false;

  constructor(
    private workflowService: WorkflowService,
    private lastStateService: LastStateService,
    private perf: AngularFirePerformance
  ) {
    // initialize the service and subjects with any data from local storage
    // this.initializeStoreFromLocalDB();

    // listen to next workflow that needs to be retrieved
    this.nextWorkflow$.pipe(
      distinctUntilChanged(),
      filter(v => !!(v)), // make sure the value is not null
    ).subscribe(value => {
      this.getWorkflow(value.workflowId, value.version);
    });

    /**
     * Subscribe to last state once when intializing service to check if we are currently in an EVAProcess,
     * if lastState was a process, emit to start a process and change the HomeComponent view.
     */
    // this.lastStateService.lastState$.pipe(
    //   trace('chat-process-constructor-lastStateService-1'),
    //   filter(state => !!(state)), // ensure LastState is loaded and not null
    //   withLatestFrom(this.chatId$),
    //   take(1)
    // ).subscribe(([state, chatId]) => {
    //   // last state was an process
    //   if (state.type === userLastStateType.process) {
    //     this.announceProcessStart(state.processId, chatId);
    //   }

    //   // set flag so other observable can now run.
    //   this.finishedInitialLastStateCheck = true;
    // });

    /**
     * Monitors lastState and fires an announcement if we have started a process or a process was cancelled/submitted
     */
    // this.lastStateService.lastState$.pipe(
    //   trace('chat-process-constructor-lastStateService-2'),
    //   filter(state => !!(state)), // ensure LastState is loaded and not null
    //   filter(state => this.finishedInitialLastStateCheck), // do not fire until our initial check of state has happened
    //   pairwise(), // combines the past and current value into an array and emits both (only when it has a past value)
    //   withLatestFrom(this.chatId$)
    // ).subscribe(([[prevState, currState], chatId]) => {
    //   // announce a process was started
    //   if ((prevState.type !== userLastStateType.process && prevState.type !== userLastStateType.TFIDF)
    //     && currState.type === userLastStateType.process) {
    //     this.announceProcessStart( currState.processId, chatId );
    //   }

    //   // announce a process was submitted or cancelled
    //   if (prevState.type === userLastStateType.process && currState.type !== userLastStateType.process) {
    //     // this.announceProcessFinish( prevState.processId );
    //   }
    // });

  }

  /**
   * Called by the chat to change the view on the {@link HomeComponent}. This announcement contains
   * enough process information to be injected in the {@link ProcessComponent} when it is created
   * and injected into the view on the {@link HomeComponent}.
   */
  public async announceProcessStart(processId: string, chatId?: string) {
    this.announceStartingProcess.next({
      processId,
      chatId: chatId ? chatId : null
    });
  }

  /**
   * Called by the chat to change the view on {@link HomeComponent}
   * Whether a process was cancelled or submitted, this is called regardless.
   * TODO: Add a status if it was cancelled or submitted.
   */
  public async announceProcessFinish(processId: string) {
    this.announceFinishedProcess.next({
      finished: Date.now(),
      processId
    });
  }

  public async announceProcessFetch(workflow: WorkFlow) {
    this.announceFetchProcess.next(workflow);
  }

  /**
   * Starts getting a workflow in the background if it does not exist in store. If it does exist
   * in the store and is not expired, then does nothing.
   */
  public fetchWorkflow(workflowId: string, version?: number) {
    this.nextWorkflow.next({
      workflowId: workflowId.trim(),
      version: version ? version : null
    });
  }

  /**
   * Gets a workflow and puts it into storage, access the workflow from the store only
   */
  private async getWorkflow(workflowId: string, workflowVersion: number | null): Promise<boolean> {
    let fullWorkflow: WorkFlow = null; // contains mapped workflow
    let workflowResponse: any = null; // contains workflow from server

    // check if we have workflow cached already (in memory)
    // if ( this.doesWorkflowExistAndValid(workflowId) ) {
      // workflow in memory and valid
      // return true;
    // }

    // check if workflow in local db
    // because we are keeping the local db workflow keys _ sperated by timestamp, lets create an array of just
    // the workflow ids to quickly check
    // if ( this.workflowsInLocalDb.has(workflowId) ) {
    // if ( false ) {
    //   // workflow in local db, use map value as local db key
    //   const workflowObj = await get<WorkflowStoreObject>(this.workflowsInLocalDb.get(workflowId), localDBWorkflowStore);

    //   // double check if workflow has not expired
    //   if (workflowObj.expires > Date.now()) {
    //     // workflow data from local db is still valid
    //     this.addPlaceholderToStore(workflowId);
    //     this.updateWorkflowDataInStore(workflowId, workflowObj.workflow, workflowObj.workflow.activeVersion);

    //     // memory store is updated.
    //     return true;
    //   }
    // }

    // update store because we are getting a workflow
    this.addPlaceholderToStore(workflowId);

    // TODO: Need workflowResponse type
    try {
      // Get workflow by specific version or the latest workflow
      if (workflowVersion) {
        workflowResponse = await this.workflowService.fetchWorkflowsByIdAndVer( workflowId, workflowVersion ).toPromise();
      } else {
        workflowResponse = await this.workflowService.fetchActiveWorkflowsById( workflowId ).toPromise();
      }

      // Map workflow
      fullWorkflow = this.workflowService.workflowObjectMapper(workflowResponse);

      // Update store with new workflow data
      this.updateWorkflowDataInStore(workflowId, fullWorkflow, fullWorkflow.activeVersion);

      return true;
    } catch (err) {
      // update store with error since fetching workflow failed
      this.updateWorkflowWithError(workflowId);

      return Promise.reject(err);
    }
  }

  /**
   * Returns a specific workflow id storage object and then completes
   *
   * @param workflowId id of the workflow in cache
   */
  getWorkflowById$(workflowId: string): Observable<WorkFlow> {
    // check if workflow key exists in storage
    const store = this.workflowStore.getValue();
    // If there is no workflow id key, create a stub of it and then start getting workflow
    if ( !store[workflowId] ) {
      this.fetchWorkflow( workflowId );
    }

    return this.workflowStore$.pipe(
      map(storeData => storeData[workflowId]),
      filter((workflowObj: WorkflowStoreObject) => !workflowObj.loading),
      // check for errors, else emit store value
      map((workflowObj: WorkflowStoreObject) => {
        if (workflowObj.error) {
          return throwError('Error retrieving workflow. Please try again.');
        } else {
          return of(workflowObj.workflow);
        }
      }),
      // merge the inner observable value into the outer observable
      flatMap((obs: Observable<any>) => obs)
    );
  }

  /**
   * Checks memory store and returns the workflow if it is done loading and has no errors,
   * if it does have errors then it will reject promise.
   *
   * @param workflowId id of the workflow
   */
  // private doesWorkflowExistAndValid( workflowId: string ): boolean {
  //   // check store for workflow
  //   const workflowStore = this.workflowStore.getValue();

  //   // No workflow found in store
  //   if ( !workflowStore[workflowId] || workflowStore[workflowId].error ) {
  //     return false;
  //   }

  //   // Passed initial checks, check if expired (last validity check)
  //   return false;
  //   // return Date.now() < workflowStore[workflowId].expires;
  // }

  /**
   * Adds an object to the store that contains state of the workflow we are retrieving. This object
   * does not have any workflow data yet.
   *
   * @param workflowId id of the workflow
   */
  private addPlaceholderToStore(workflowId: string): void {
    const store = this.workflowStore.getValue();

    const workflowPlaceholder: WorkflowStoreObject = {
      workflow: null,
      workflowVersion: null,
      expires: Date.now(),
      loading: true,
      error: false
    };

    const dataToAdd = {};
    dataToAdd[workflowId] = workflowPlaceholder;

    // Update store with loading data
    this.workflowStore.next( Object.assign( store, dataToAdd ) );
  }

  /**
   * Updates workflow object in store with new workflow data
   *
   * @param workflowId - id of workflow
   * @param workflowData - mapped workflow data
   * @param workflowVersion - version of workflow
   */
  private updateWorkflowDataInStore(workflowId: string, workflowData: WorkFlow, workflowVersion?: number): void {
    const store = this.workflowStore.getValue();

    const workflowObj = store[workflowId];

  //   // update workflow object values
    workflowObj.workflow = workflowData;
    workflowObj.workflowVersion = workflowVersion ? workflowVersion : null;
    workflowObj.expires = Date.now();
    workflowObj.loading = false;
    workflowObj.error = false;

    const updatedWorkflowObj = {};
    updatedWorkflowObj[workflowId] = workflowObj;

    // Update storage with new workflow data
    // We set the key in local storage as the workflow id and then the expiry timestamp seperated by _
    // this way we can quickly determine on load of local sb keys if one has expired without actually retrieving it.
    // set(`${workflowId}_${workflowObj.expires}`, workflowObj, localDBWorkflowStore);

    // cleanup expired workflow - delete any workflow of the same id in local db
    // if (this.workflowsInLocalDb.has(workflowId)) {
      // use the map value as the key for local db
      // del( this.workflowsInLocalDb.get(workflowId), localDBWorkflowStore );
    // }

    // finally update our map with the new local db key
    // this.workflowsInLocalDb.set(workflowId, `${workflowId}_${workflowObj.expires}`);

    this.workflowStore.next( Object.assign( store, updatedWorkflowObj ) );
  }

  /**
   * Update a workflow object in subject (memory) as a failure
   *
   * @param workflowId - id of the workflow to update
   */
  private updateWorkflowWithError(workflowId: string): void {
    const store = this.workflowStore.getValue();

    // Update properties on our workflow id key
    const workflowObj = store[workflowId];
    workflowObj.error = true;
    workflowObj.loading = false;

    const updatedWorkflowObj = {};
    updatedWorkflowObj[workflowId] = workflowObj;

    // delete workflow if it exists in local db
    // del(workflowId, localDBWorkflowStore);

    this.workflowStore.next( Object.assign( store, updatedWorkflowObj ) );
  }

  /**
   * Sets a subject with the generated chat id. This way process elements know where to emit to.
   *
   * @param id - saltchat id
   */
  public setChatId( id: string ): void {
    this.chatId.next(id);
  }

  /**
   * Queries the local db for any workflows in the workflows table. If there are, get the keys and save them in memory.
   * When we want to get a workflow, we get the workflow data out of local db.
   */
  // private async initializeStoreFromLocalDB() {
  //   // get all workflow keys from local db
  //   const localWorkflowKeys: IDBValidKey[] = await keys(localDBWorkflowStore);

  //   // set our map
  //   localWorkflowKeys.forEach((localDbKey: string) => {
  //     const workflowId = localDbKey.split('_')[0];
  //     this.workflowsInLocalDb.set( workflowId, localDbKey );
  //   });

  //   // check if any workflows have expired and if so, delete them
  //   const currentTime = Date.now();

  //   localWorkflowKeys.forEach((key: string) => {
  //     const expiry = Number( key.split('_')[1] ); // convert expiry timestamp sring to number

  //     if (currentTime > expiry) {
  //       // workflow has expired, attempt to delete it from local db
  //       try {
  //         del(key, localDBWorkflowStore);
  //         this.workflowsInLocalDb.delete( key.split('_')[0] ); // update map of deletion
  //       } catch (err) {
  //         console.error('failed deleting expired workflow from local', err);
  //       }
  //     }
  //   });
  // }

}

