import { Injectable, ComponentRef } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { OpenKnowledgeDocument, KnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { KnowledgeComponent } from '@eva-ui/admin-overview/knowledge/knowledge/knowledge.component';
import { filter, map, delay } from 'rxjs/operators';

@Injectable()
export class KnowledgeMultiService {

  private _openDocs: OpenKnowledgeDocument[] = [];
  private _activeIndex = 0;

  constructor() { }

  get getOpenDocs(): OpenKnowledgeDocument[] {
    return this._openDocs;
  }

  get getActiveIndex(): number {
    return this._activeIndex;
  }

  get activeComponentRef(): ComponentRef<KnowledgeComponent> {
    return this._openDocs[this._activeIndex].component;
  }

  /**
   * Returns the index of the doc in our list of open docs, else -1
   * @param docId document id
   */
  public indexOfDocId(docId: string): number {
    return this._openDocs.findIndex((doc) => doc.docId === docId);
  }

  /**
   * Updates the tab data with docName and that the document has loaded.
   */
  public updateLoadingStatus(docId: string, loading: boolean, docName: string): void {
    const openDocIndex = this._openDocs.findIndex((doc) => {
      return doc.docId === docId;
    });

    if (openDocIndex >= 0) {
      this._openDocs[openDocIndex].loading = loading;
      this._openDocs[openDocIndex].docName = docName;
    }
  }

  /**
   * Updates a tab name (used if the document needs to be fetched from the server)
   */
  public updateOpenDocName(docId: string, name: string) {
    const doc = this._openDocs.find((openDoc) => openDoc.docId === docId);
    if (doc) {
      doc.docName = name;
    }
  }

}
