import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessDashboardStatus, ProcessDashboardResponse } from '@eva-model/process/processDashboard';
import { environment } from '@environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcessDashboardService {

  constructor(private http: HttpClient) { }

  /**
   * Creates a new record for the process dashboard whenever a process is submitted through EVA
   * @param evaProcessStatus the request object coming to EVA for process status dashboard
   */
  createNewRecord(evaProcessStatus: ProcessDashboardStatus): Promise<ProcessDashboardResponse> {
    const payload = Buffer.from(JSON.stringify(evaProcessStatus)).toString('base64');
    return this.http.post<ProcessDashboardResponse>(environment.endPoints.DYNAMIC_INTERACTIONS.url + 'processDashboard',
      {payload: payload}).pipe(take(1)).toPromise();
  }
}
