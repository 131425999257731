
import { Component, Inject , ViewEncapsulation, ViewChild, ComponentFactoryResolver, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionComponent } from '@eva-ui/workflow-builder/workflow-interaction-condition/workflow-interaction-condition.component';
import { InteractionConditionDialogModel } from '@eva-model/interactionConditionDialogModel';
import { InteractionConditionDialogComponent } from '@eva-ui/interaction-condition-dialog/interaction-condition-dialog.component';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionService } from '@eva-services/workflow-interaction-condition/workflow-interaction-condition.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
  selector: 'app-workflow-interaction-condition-dialog',
  templateUrl: './workflow-interaction-condition-dialog.component.html',
  styleUrls: ['./workflow-interaction-condition-dialog.component.scss']
})
export class WorkflowInteractionConditionDialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;

  interactionConditionChangeSubs: ISubscription;

  constructor(
    public dialogRef: MatDialogRef<WorkflowInteractionConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private interactionConditionService: WorkflowInteractionConditionService
  ) {}

  ngAfterViewInit() {
    const that = this;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WorkflowInteractionConditionComponent);

    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<WorkflowInteractionConditionComponent>componentRef.instance).interactionIndexInWorkflow = this.dialogData.extra.intrctnIndex;
    (<WorkflowInteractionConditionComponent>componentRef.instance).interactionObj = JSON.stringify(this.dialogData.extra.interaction);
    (<WorkflowInteractionConditionComponent>componentRef.instance).interactionsByGroupsObj =
      JSON.stringify(this.dialogData.extra.interactionsByGroups);
    (<WorkflowInteractionConditionComponent>componentRef.instance).interactionConditionsObj = this.dialogData.extra.intrctnConditions;

    this.interactionConditionChangeSubs =
      this.interactionConditionService.conditionsChanged$
      .subscribe(
        (data) => {
          const emitData = {
            interactionIndex: that.dialogData.extra.intrctnIndex,
            interactionConditions: data
          };
          this.interactionConditionService.announceWorkflowInteractionConditionChange(emitData);
        },
        (err) => { console.log(err); }
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if ( this.interactionConditionChangeSubs ) {
      this.interactionConditionChangeSubs.unsubscribe();
    }
  }

}
