import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isValidArray'
})
export class IsValidArrayPipe implements PipeTransform {
  transform(hit: object, propertyName: string): boolean {
    if (hit[`${propertyName}-modified`]) {
      return Array.isArray(hit[`${propertyName}-modified`]);
    }
    return Array.isArray(hit[propertyName]);
  }
}