/**
 * This is the interface for Interaction Data fetched from Database.
 */
export interface InteractionData {
    Versions: any[];                                // This array stores all the versions of interation
    encryptedByDefault: boolean;                    // Whether interaction is encrypted or not by default
    groupPublicKey: string;                         // Public Key of group the interaction is part of
    id: string;                                     // ID of the interaction
    name: string;                                   // Name of the interaction
    timestamp: number;                              // The time at which interaction was created
    version: string;                                // Latest version of the interaction
}

/**
 * This is the interface for the Interaction Table Data for MatTableDataSource
 */
export interface InteractionTableData {
    Versions: any[];                                // This array stores all the versions of interation
    name: string;                                   // Name of the interaction
    version: string;                                // Latest version of the interaction
    group: string;                                  // Name of group the interaction is part of
    id: string;                                     // ID of the interaction
    groupPublicKey: string;                         // Public Key of group the interaction is part of
    selectedVersion: string;                        // Version selected by the user from the list in MatTableDataSource
}

/**
 * Enum for page types in the interaction builder
 * @readonly
 * @enum {number}
 */
export enum Page {
    MyInteractionsEmpty,
    MyInteractions,
    CreateNewInteraction,
    None
}
