import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WorkFlowCondition } from '@eva-model/workflow';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Guid } from '@eva-core/GUID/guid';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { DynamicInputModel, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT, DynamicFormControlModel } from '@ng-dynamic-forms/core';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionService } from '@eva-services/workflow-interaction-condition/workflow-interaction-condition.service';
import { WorkflowDestinationPickerService } from '@eva-services/workflow-destination-picker/workflow-destination-picker.service';
import { InteractionConditionDialogModel } from '@eva-model/interactionConditionDialogModel';
import { InteractionConditionDialogComponent } from '@eva-ui/interaction-condition-dialog/interaction-condition-dialog.component';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionBuilderService } from '@eva-services/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
// eslint-disable-next-line max-len
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Component({
  selector: 'eva-workflow-interaction-condition',
  templateUrl: './workflow-interaction-condition.component.html',
  styleUrls: ['./workflow-interaction-condition.component.scss']
})
export class WorkflowInteractionConditionComponent implements OnInit, OnDestroy {

  interactionIndex: number;
  interaction: any;
  interactionsByGroups: any;
  interactionConditions: WorkFlowCondition[] = [];

  generalDialogChangeSubscription: ISubscription;
  conditionDestinationChangeSubscription: ISubscription;
  interactionConditionSetDialogChangeSubs: ISubscription;


  Interaction_Condition_Edit_Name_INPUT =
    new DynamicInputModel({
      inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
      id: 'interactionConditionEdit',
      label: 'Inyteraction Condition name',
      placeholder: `The interaction's condition name`,
      value: null
    });

  Interaction_Condition_Edit_MODEL: DynamicFormControlModel[] = [
    this.Interaction_Condition_Edit_Name_INPUT
  ];

  separatorKeysCodes = [ENTER, COMMA];

  @Input()
  set interactionIndexInWorkflow(interactionIndexInWorkflow: number) {
    if (!interactionIndexInWorkflow) { return; }
    this.interactionIndex = interactionIndexInWorkflow;
  }

  @Input()
  set interactionObj(interactionObj: string) {
    if (!interactionObj) { return; }
    this.interaction = JSON.parse(interactionObj);
  }

  @Input()
  set interactionsByGroupsObj(interactionsByGroupsObj: string) {
    if (!interactionsByGroupsObj) { return; }
    this.interactionsByGroups = JSON.parse(interactionsByGroupsObj);
  }

  @Input()
  set interactionConditionsObj(interactionConditionsObj: WorkFlowCondition[]) {
    if (!interactionConditionsObj || !Array.isArray(interactionConditionsObj)) { return; }
    this.interactionConditions = interactionConditionsObj;
    if (this.interactionConditions.length === 0) {
      this.interactionConditions.push(new WorkFlowCondition(Guid.newGuid().toString(), "Start", "true"));
    }
  }

  constructor(
    private destinationPickerService: WorkflowDestinationPickerService,
    private interactionConditionService: WorkflowInteractionConditionService,
    private dialog: MatDialog,
    private arrayUtil: ArrayUtilsService,
    private generalDialogService: GeneralDialogService,
    private interactionConditionBuilderService: WorkflowInteractionConditionBuilderService,
    public evaGlobalService: EvaGlobalService) { }

  ngOnInit() {
    //#region Subscription to condition destination change observable
    this.conditionDestinationChangeSubscription =
      this.destinationPickerService.destinationsChanged$.subscribe(
        changeObj => {
          if (changeObj) {
            const conditionIndex = this.interactionConditions.map((condition) => condition.id).indexOf(changeObj.conditionId);
            if (conditionIndex !== -1) {
              const intrctCndtn = this.interactionConditions[conditionIndex];
              if (changeObj.conditionResult) {
                if (!Array.isArray(intrctCndtn.trueDestination)) { intrctCndtn.trueDestination = []; }
                intrctCndtn.trueDestination = changeObj.selectedDestinations;
              } else {
                if (!Array.isArray(intrctCndtn.falseDestination)) { intrctCndtn.falseDestination = []; }
                intrctCndtn.falseDestination = changeObj.selectedDestinations;
              }
            }
          }
        },
        err => { console.log(err); }
      );
    //#endregion
  }

  //#region Conditions drag & drop
  dragStartConditionHandler(event) {
    event.dataTransfer.setData('elmntId', event.target.id);
    event.dataTransfer.dropEffect = 'move';
  }

  dargOverConditionHandler(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  /**
   * This function drops the condition in the drop zone in drop event
   *
   * @param event HTML on drop event
   */
  dropConditionHandler(event: any): void {
    event.preventDefault();
    const dropOnElmnt = event.target;

    // Not expected drop zone to be drop a dragged element!
    if (!dropOnElmnt || dropOnElmnt.dataset.interactionCondition !== 'true') { return; }

    const dragElmntId = event.dataTransfer.getData('elmntId');
    const dragElmnt = document.getElementById(dragElmntId);

    // Not expected element to be dropped!
    if (!dragElmnt || dragElmnt.dataset.interactionCondition !== 'true') { return; }

    const dragElmntIndex = +dragElmnt.dataset.interactionConditionIndex;
    const dropOnElmntIndex = +dropOnElmnt.dataset.interactionConditionIndex;

    // Drag and drop on same element means no action
    if (dropOnElmntIndex === dragElmntIndex) { return; }

    const dropElmntBox = dropOnElmnt.getBoundingClientRect();
    const dragNdropDirection = dropOnElmntIndex > dragElmntIndex ? 1 : -1;   // LTR vs RTL in this case
    const indexFixer =
      (Math.abs(dropElmntBox.right - event.clientX) < Math.abs(dropElmntBox.left - event.clientX))
        ? ((dragNdropDirection > 0) ? 0 : 1)
        : ((dragNdropDirection > 0) ? -1 : 0);

    const dragElmntNewIndex = dropOnElmntIndex + indexFixer;

    // Drag and drop which ends up on same location means no action
    if (dropElmntBox === dragElmntNewIndex) { return; }

    this.arrayUtil.array_move(this.interactionConditions, dragElmntIndex, dragElmntNewIndex);
  }

  //#endregion

  //#region Dialogs
  /**
   * This function opens a general dialog and calls the callback if dialog results true
   *
   * @param dialogModel Dialog Model data for Dialog box
   * @param callback Callback function
   * @param callbackData Data to be passed to callback function
   */
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback: Function, callbackData: any) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel
    });

    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback function and callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }
  //#endregion

  /**
   * This function prompts user to confirm the removal of interaction condition
   *
   * @param condition Condition to be removed
   */
  removeIntractionConditionConfirm(condition: WorkFlowCondition): void {
    if (!condition || !condition.name) {
      alert('There is no valid condition to be deleted, please try again!');
      return;
    }

    const dialogData = new GeneralDialogModel(
      `Interaction's condition removal dialog`, `Are you sure to remove the interaction condition :: ${condition.name} ?`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.removeIntractionCondition, { condition: condition, that: this });
  }

  /**
   * This function removes the condition from the interaction
   *
   * @param data Data containing condition to be removed and { that: this }
   */
  removeIntractionCondition(data: any): void {
    if (!data || !data.condition) { return; }

    //#region check to see if that's in use as a destination in any other conditions
    const destinationIds =
      data.that.interactionConditions.map(condition => condition.trueDestination.concat(condition.falseDestination))
        .filter(trueDestination => trueDestination.length > 0);
    const flatDestinationIds = [].concat(...destinationIds).filter(item => item.type === 'condition').map(item => item.id);
    const flatUniqueDestinationIds = flatDestinationIds.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
    if (flatUniqueDestinationIds.indexOf(data.condition.id) !== -1) {

      data.that.growl_msgs = [];
      data.that.growl_msgs.length = 0;

      data.that.growl_msgs.push({
        severity: 'error',
        summary: "Workflow interaction condition delete failed",
        detail: `Condition ${data.condition.name} has been set as a destination in another condition.`
      });

      return;
    }
    //#endregion

    const index = data.that.interactionConditions.indexOf(data.condition);
    if (index >= 0) {
      data.that.interactionConditions.splice(index, 1);
      data.that.interactionConditionService.announceChange(data.that.interactionConditions);
    }
  }

  /**
   * This function prompts user with the edit dialog box for editing interaction condition
   *
   * @param condition Condition to be edited
   * @param conditionIndex Index of the condition to be edited
   */
  editInteractionConditionEvent(condition, conditionIndex: number): void {
    if (!condition || !condition.name) {
      alert('There is no valid interaction condition to be deleted, please try again!');
      return;
    }

    (this.Interaction_Condition_Edit_MODEL[0] as DynamicInputModel).value = condition.name;
    const dialogData = new GeneralDialogModel(
      'Interaction Condition Edit dialog',
      `You may edit the name of selected interaction's condition :: ${condition.name}`,
      'Done', 'Cancel',
      this.Interaction_Condition_Edit_MODEL
    );

    this.openGeneralDialog(
      dialogData, this.editInteractionCondition,
      { condition: condition, conditionIndex: conditionIndex, that: this });
  }

  /**
   * This function edits the interaction condition after user changes
   *
   * @param data Data containing edited condition and { that: this }
   */
  editInteractionCondition(data: any): void {
    if (!data || !data.condition || (!data.conditionIndex && data.conditionIndex !== 0) || !data.that) { return; }

    if (data.generalDialogOnChange.interactionConditionEdit &&
      data.that.interactionConditions[data.conditionIndex]) {
      data.that.interactionConditions[data.conditionIndex].name = data.generalDialogOnChange.interactionConditionEdit;
    }
  }

  /**
   * This function adds a new interaction condition
   *
   * @param event Mat chip input event
   */
  addInteractionCondition(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add the form screen
    if ((value || '').trim()) {
      if (!Array.isArray(this.interactionConditions)) { this.interactionConditions = []; }
      this.interactionConditions.push(new WorkFlowCondition(Guid.newGuid().toString(), value.trim(), "true"));
      this.interactionConditionService.announceChange(this.interactionConditions);
    }

    // Reset the input value
    if (input) { input.value = ''; }
  }

  public callInteractionConditionDialog(conditionIndex: number) {
    const dialogData =
      new InteractionConditionDialogModel(
        'Dynamic Interaction Condition Builder dialog',
        `${this.interaction.name}`,
        this.interaction,
        conditionIndex,
        this.interactionConditions[conditionIndex].condition,
        'Ok', 'Cancel');
    this.openInteractionConditionBuilderDialog(dialogData, this.interactionConditionUpdate, { that: this, conditionIndex: conditionIndex });
  }

  private openInteractionConditionBuilderDialog(dialogModel: InteractionConditionDialogModel, callback?, callbackData?: any) {
    const dialogRef = this.dialog.open(InteractionConditionDialogComponent, { data: dialogModel });

    this.interactionConditionSetDialogChangeSubs =
      this.interactionConditionBuilderService.conditionSetDialogChanged$
        .subscribe(
          changeObj => {
            if (changeObj) {
              if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
                callbackData['interactionConditionDialogOnChange'] = changeObj;
              }
            }
          },
          err => { console.log(err); }
        );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * This function updates the interaction condition and announces changes
   *
   * @param data Data containing updated interaction condition and { that: this }
   */
  interactionConditionUpdate(data: any): void {
    if (!(data && (data.conditionIndex || data.conditionIndex === 0) && data.interactionConditionDialogOnChange)) { return; }
    if (data.that.interactionConditions[data.conditionIndex]) {
      data.that.interactionConditions[data.conditionIndex].condition = data.interactionConditionDialogOnChange;

      const conditionChange = {
        conditionIndex: data.conditionIndex,
        condition: data.that.interactionConditions[data.conditionIndex].condition
      };

      data.that.interactionConditionViewerService.announceInteractionConditionChange(conditionChange);
      data.that.interactionConditionService.announceChange(data.that.interactionConditions);
    }
  }

  ngOnDestroy() {
    if (this.conditionDestinationChangeSubscription) {
      this.conditionDestinationChangeSubscription.unsubscribe();
    }

    if (this.interactionConditionSetDialogChangeSubs) {
      this.interactionConditionSetDialogChangeSubs.unsubscribe();
    }

    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }
  }

  /**
   * This is used to stringify the object in the HTML functions.
   * @param jsonObj any object that is going to be bound in the component.
   */
  jsonStringify(jsonObj: any): string {
    return JSON.stringify(jsonObj);
  }
}

