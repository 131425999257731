import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { ChatService } from '@eva-services/chat/chat.service';
import { Subscription } from 'rxjs';
import { LastState } from '@eva-model/userLastState';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { KnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { TutorialService } from '@eva-services/tutorial/tutorial.service';

const HTML_CONTENT_MAX_HEIGHT = 400;

@Component({
  selector: 'app-chat-ml',
  templateUrl: './chat-ml.component.html',
  styleUrls: ['./chat-ml.component.scss']
})
export class ChatMlComponent implements OnInit, OnDestroy, AfterViewInit {

  sectionHtml: string; // html string to render
  documentName: string; // name of document for view

  tutorialSubscription: Subscription = new Subscription(); // the subscription for the tutorials.

  knowledgeDocRequest: any = null; // knowledge doc information store for the currently viewed answer. // TODO: Need new type

  showExpandContentsButton: boolean;
  expandedContents = false;

  // initialize component with ML responses
  @Input() data: {
    query: string;
    groupPublicKey: string;
    modelVersion: string;
    docName: string;
    docId: string;
    docVersion: number;
    docSection: string;
    section: any;
    sectionHtml: string;
  };

  @Input() dialogflowResponse: any;

  @ViewChild('htmlContainer', { read: ElementRef }) htmlContainer: ElementRef; // wrapper element that html is injected into.

  constructor(private chatKnowledgeService: ChatKnowledgeService,
              private chatService: ChatService,
              public tutorialService: TutorialService) { }

  ngOnInit(): void {
    // check if sectionHtml is an empty string, if it is, show the section header
    if (this.data.sectionHtml && this.data.sectionHtml !== '') {
      this.sectionHtml = this.data.sectionHtml;
    } else {
      // add a body denoting that the section has no body contents
      this.sectionHtml = `${this.data.section.title}<p><em><small>Section has no content</small></em></p>`;
    }

    this.documentName = this.data.docName;

    this.tutorialService.showTutorial('feedback').subscribe(() => {});

    // after this type of message is loaded, ask questions about this content after.
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.KnowledgeResponseTraining,
      componentData: {
        lastState: this.dialogflowResponse,
        data: this.data
      }
    });

    this.setKnowledgeDocumentInfo();
  }

  ngAfterViewInit(): void {
    // check height of the innerHtml content and see if we need to show an expand button.
    const elem = this.htmlContainer.nativeElement;
    if (elem.firstElementChild.scrollHeight > HTML_CONTENT_MAX_HEIGHT) {
      // hack to avoid expression detection error
      setTimeout(() => {
        this.showExpandContentsButton = true;
      }, 0);
    }
  }

  expandContent() {
    this.expandedContents = true;
    // setTimeout(() => {
    //   document.getElementById('chatBottomAnchor').scrollIntoView({ behavior: 'smooth' });
    // }, 125);
  }

  /**
   * When called, reads lastState and sets a variable with information taken from the currently selected ML result
   * for other functions to get this information
   */
  setKnowledgeDocumentInfo() {
    const currentTfidfSection = this.dialogflowResponse.response.tfidfDocumentMatches[0]; // object at the count index of matches
    const fullDocument: KnowledgeDocument = this.dialogflowResponse.response.knowledgeDocument;

    // parse the section information
    if (currentTfidfSection) {
      const splitDocIds = currentTfidfSection.docId.split('_');
      const docId = splitDocIds[0];
      const docVersion = splitDocIds[1];
      const sectionId = splitDocIds.slice(2, splitDocIds.length).join('_');
      const splitModelVersion = currentTfidfSection.modelVersion.split('_');
      const publicKey = splitModelVersion[0];

      this.knowledgeDocRequest = {
        docId,
        docVersion: docVersion ? Number(docVersion) : null,
        docGroup: publicKey,
        modelVersion: currentTfidfSection.modelVersion,
        sectionId: sectionId,
        document: fullDocument ? fullDocument : null
      };

      // setTimeout fixes an expression error on the knowledge viewer.
      setTimeout(() => {
        this.announceKnowledgeView();
      }, 0);
    }
  }

  /**
   * Destroy the subscriptions.
   */
  ngOnDestroy() {
    if (this.tutorialSubscription) {
      this.tutorialSubscription.unsubscribe();
    }
  }

  /**
   * Emit the current doc information to be fetched/shown.
   */
  announceKnowledgeView() {
    const announcement = {
      docId: this.knowledgeDocRequest.docId,
      docGroup: this.knowledgeDocRequest.docGroup,
      docVersion: this.knowledgeDocRequest.docVersion,
      modelVersion: this.knowledgeDocRequest.modelVersion,
      sectionId: this.knowledgeDocRequest.sectionId,
      document: this.knowledgeDocRequest.document,
      promptForFeedback: true,
      dialogflowResponse: this.dialogflowResponse
    };

    // if (this.skipFeedbackPrompt) {
    //   announcement.promptForFeedback = false;
    //   this.skipFeedbackPrompt = false;
    // }

    this.chatKnowledgeService.announceKnowledgeShow(announcement);
  }

}
