import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WorkflowInteractionConditionService {

  // Observable any sources
  private interactionConditionChangeSource = new Subject<any>();
  private workflowInteractionConditionChangeSource = new Subject<any>();

  // Observable any streams
  conditionsChanged$ = this.interactionConditionChangeSource.asObservable();
  workflowInteractionConditionsChanged$ = this.workflowInteractionConditionChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceChange(change: any) {
    this.interactionConditionChangeSource.next(change);
  }

  announceWorkflowInteractionConditionChange(change: any) {
    this.workflowInteractionConditionChangeSource.next(change);
  }

}
