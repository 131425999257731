<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="false" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Changes</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="id-container">
      <div class="half-width">
        Current {{entityType}}: <span>{{entity?.name}}</span><br />
        Environment: <span>{{newEntityEnvironment}}</span><br />
        Group: <span>{{entity ? evaGlobalService.getGroupNameByPublicKey(entity.groupPublicKey) : ''}}</span><br />
        Version: <span>{{newEntityVersion}}</span><br /><br />
        <div class="list-item-custom" [class.display-none]="entityType === 'Interaction'">
          {{entity?.id}}
        </div>
        <div *ngFor="let interaction of interactions">
          <div class="row-flex">
            <div [class.interaction-width]="entityType === 'Workflow'"></div>
            <div>
              Interaction Name: <span>{{interaction.interaction.name}}</span>
              <div class="list-item-custom">
                {{interaction.interaction.id}}
              </div>
            </div>
          </div>
          <div *ngFor="let formScreen of interaction.interaction.FormScreens">
            <div class="row-flex">
              <div [class.form-screen-width]="entityType === 'Workflow'"
                [class.interaction-width]="entityType === 'Interaction'"></div>
              <span>{{formScreen.name}}</span>
            </div>
            <div *ngFor="let formElement of formScreen.FormElements">
              <div class="row-flex">
                <div [class.element-width]="entityType === 'Workflow'"
                  [class.interaction-width]="entityType === 'Interaction'"></div>
                <fieldset class="list-item">
                  <legend class="list-item-legend">
                    {{formElement.label}}{{isSpecialControlElement(interaction.interaction, formElement) ? ' - Special Control' : ''}}
                  </legend>
                  {{formElement.id}}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="vertical-border"></span>
      <div class="half-width">
        Old {{entityType}}: <span>{{importedEntityName}}</span><br />
        Environment: <span>{{oldEntityEnvironment}}</span><br />
        Group: <span>{{oldGroupName}}</span><br />
        Version: <span>{{oldEntityVersion}}</span><br /><br />
        <div class="list-item-custom" [class.display-none]="entityType === 'Interaction'">
          {{entity ? getOldId(entity.id) : ''}}
        </div>
        <div *ngFor="let interaction of interactions">
          <div class="row-flex">
            <div [class.interaction-width]="entityType === 'Workflow'"></div>
            <div>
              Interaction Name: <span>{{interaction.interaction.name}}</span>
              <div class="list-item-custom">
                {{getOldId(interaction.interaction.id)}}
              </div>
            </div>
          </div>
          <div *ngFor="let formScreen of interaction.interaction.FormScreens">
            <div class="row-flex">
              <div [class.form-screen-width]="entityType === 'Workflow'"
                [class.interaction-width]="entityType === 'Interaction'"></div>
              <span>{{formScreen.name}}</span>
            </div>
            <div *ngFor="let formElement of formScreen.FormElements">
              <div class="row-flex">
                <div [class.element-width]="entityType === 'Workflow'"
                  [class.interaction-width]="entityType === 'Interaction'"></div>
                <fieldset class="list-item">
                  <legend class="list-item-legend">
                    {{formElement.label}}{{isSpecialControlElement(interaction.interaction, formElement) ? ' - Special Control' : ''}}
                  </legend>
                  {{getOldId(formElement.id)}}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true" color="primary">Done</button>
  </mat-dialog-actions>
</div>