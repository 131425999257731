import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/*****************************************
 *
 *  Do NOT use this unless you know what you're doing.
 *  https://angular.io/api/platform-browser/DomSanitizer#bypasssecuritytrusthtml
 *
 *****************************************/

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(htmlValue: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlValue);
  }
}
