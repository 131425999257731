export interface ChatWorkflowSubGroup {
    workflowArray: WorkFlow[];
    workflowPublicKey: string;
    workflowSubGroup: string;
}
export class WorkFlow {

    constructor(
        public id: string,                                      // unique id of the workflow
        public name: string,                                    // name of the workflow
        public groupType: string,                               // group type that workflow belongs to
        public groupPublicKey: string,                          // unique public key of the workflow's group
        public timestamp: number,                               // current version of the workflow
        public encryptedByDefault: boolean,                     // whether workflow is encrypted or not
        public descriptions: string[],                          // list of workflow descriptions
        public interactions: WorkflowInteraction[],             // list of interactions in the workflow
        public activeVersion?: number,                          // current active version of workflow
        public activated?: boolean,                             // whether current workflow version is activated or not
        public subGroup?: string,                               // sub group that workflow belongs to
        public selectedVersion?: number                         // selected version being fetched
    ) { }
}

export class WorkflowInteraction {
    constructor(
        public interactionId: string,                           // unique id of the workflow interaction
        public interactionName: string,                         // name of the interaction
        public interactionVersion: number,                      // version of the interaction
        public interactionGroupPublicKey: string,               // unique public key of the interaction's group
        public interaction: any = null,                         // interaction object from db
        public isWaiting: boolean = false,                      // whether waiting for the action or not for current interaction
        public onWaitMessage: string = null,                    // wait message to display when isWaiting is true
        public conditions: WorkFlowCondition[] = [],            // list of interaction conditions
        public order?: number,                                  // order of the interaction
        public submitted?: string,
        public activated?: boolean,
        public activeVersion?: number,
        public index?: number                                   // index of the interaction node in graph
    ) { }
}

export class WorkFlowCondition {
    constructor(
        public id: string,                                      // unique id of the workflow condition
        public name: string,                                    // name of the condition
        public condition: any = null,                           // condition object
        public trueDestination: any[] = [],                     // list of true destinations
        public falseDestination: any[] = [],                    // list of false destinations
        public order?: number,                                  // order of the condition
        public hovered?: boolean,                               // whether condition is hovered or not
        public selectedTrueInteractionVersion?: number,         // selected Interaction Version in True Destination
        public selectedFalseInteractionVersion?: number,        // selected Interaction Version in False Destination
        public selectedTrueConditionIndex?: number,             // selected condition index in True Destination
        public selectedFalseConditionIndex?: number,            // selected condition index in False Destination
        public selectedTrueGroups?: string[],                   // selected groups' index in True Destination
        public selectedFalseGroups?: string[],                  // selected groups' index in False Destination
        public index?: number,                                  // index of the condition node in graph
        public trueInteractionIndex?: number,                   // index of the interaction in True Destination
        public falseInteractionIndex?: number,                  // index of the interaction in False Destination
        public trueConditionIndex?: number,                     // index of the condition in True Destination
        public falseConditionIndex?: number                     // index of the condition in False Destination
    ) { }
}

/**
 * Enum for page types in the interaction builder
 * @readonly
 * @enum {number}
 */
export enum Page {
    MyWorkflowsEmpty,
    MyWorkflows,
    CreateNewWorkflow,
    None
}

/**
 * Enum for Node Colors for ngx-graph
 * @readonly
 * @enum {string}
 */
export enum NodeColors {
    workflow = '#68EDCB',
    interaction = '#006275',
    group = '#FF5F37',
    condition = '#9F9773',
    conditionSet = '#364D20',
    elementCondition = '#AB6629',
    true = '#80c343',
    false = '#fd5373'
}
