import { Component, Input, OnDestroy, ViewContainerRef, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { InteractionMapped } from '@eva-model/interaction/interactionMapped';
import { filter, map, take } from 'rxjs/operators';
import { FormVisualizerComponent } from '@eva-ui/form-visualizer/form-visualizer.component';
import { DynamicComponent } from '@eva-model/interaction/interaction';
import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { ChatInteractionService } from '@eva-services/chat/process/chat-interaction.service';
import { InteractionStoreObject } from '@eva-model/chat/process/chatProcess';
import { Group } from '@eva-model/group';
import { environment } from '@environments/environment';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { DialogService } from '@eva-ui/guard/dialog.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';

@Component({
  selector: 'eva-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss']
})
export class InteractionComponent implements OnDestroy {

  id: string; // interaction id

  interactionSub: Subscription;
  interactionName: string;
  uniqueTabId: string;

  interaction: InteractionStoreObject;

  @ViewChild('dynamicComponent', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  visualizerRef: any;
  componentSubs: Subscription = new Subscription();
  tabIndex: number;

  @Input()
  set interactionId(id: string) {
    this.id = id;
    // console.log('init interaction component with id ', this.id);

    // set up our listener for when the interaction store changes
    this.interactionSub = this.loadInteractionById(id).subscribe(interaction => {
      this.interaction = interaction;

      if (interaction.interaction) {
        setTimeout(() => this.loadFormVisualizer(interaction.interaction), 0);
      }
    });
  }

  constructor(private chatInteractionService: ChatInteractionService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private evaGlobalService: EvaGlobalService,
              public dialogService: DialogService,
              private multiViewService: MultiViewService) {
                this.componentSubs.add(
                  this.multiViewService.closeTab$.pipe(
                    filter((closeTab) => closeTab && closeTab.entityType === 'Change'),
                    filter((closeTab) => this.uniqueTabId === closeTab.entityId),
                    filter((closeTab) => this.tabIndex === closeTab.tabIndex)
                  ).subscribe(closeTab => {
                    this.canDeactivate().subscribe(value => {
                      if (value) {
                        setTimeout(() => {
                          closeTab.closeSubject.next(true);
                        });
                      }
                    });
                  })
                );
              }

  ngOnDestroy() {
    if (this.interactionSub) {
      this.interactionSub.unsubscribe();
    }

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  canDeactivate = (): Observable<boolean> => {
    return this.dialogService.confirm('Are you sure you want to exit? Any unsaved changes will be lost.');
  }

  /**
   * get an interaction from the cache when it's ready
   */
  private loadInteractionById(id: string): Observable<InteractionStoreObject> {
    return this.chatInteractionService.interactionStore$.pipe(
      filter((interactions) => !!(interactions[id])),
      map((interactions) => interactions[id])
    );
  }

  private loadFormVisualizer(interaction: InteractionMapped) {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormVisualizerComponent);

    // adjusted to ensure initial error doesn't throw on first implementation of the form visualizer.
    // let viewContainerRef: ViewContainerRef;
    // try {
    //   viewContainerRef = this.viewContainerHost.viewContainerRef;
    //   viewContainerRef.clear();
    // } catch (err) {
    //   console.log(err);
    // }

    const componentRef = this.viewContainerRef.createComponent(componentFactory);

    // const loadInteraction = this.disableAdminControlsIfNotChangeAdmin(interaction);
    (<DynamicComponent>componentRef.instance).dynFrmObj = interaction;

    // this is the cloned version with specific cloned interactions
    this.visualizerRef = componentRef.instance; // console.log(componentRef.instance);
    this.interactionName = interaction.name;
  }
}
