import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

import { Observable } from 'rxjs';
import { DeleteFileResponse } from '@eva-model/fileStorageResponses';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageImageUploadService {

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadUrl: Observable<string>;

  constructor(
    private storage: AngularFireStorage
  ) { }

  /**
   * This function uploads the image to firebase storage at the path specified if file exists
   * and returns the Observable of new image URL.
   *
   * @param path The firebase storage location at which the image is to be uploaded.
   * @param file The image file to upload
   */
  async uploadImage(path: string, file: File) {
    if (!file) {
      return;
    }
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
      return;
    }

    this.task = this.storage.upload(path + file.name, file);

    this.percentage = this.task.percentageChanges();  // tracks the progress of upload
    this.snapshot = this.task.snapshotChanges();

    this.downloadUrl = await this.task.then(data => {
      this.downloadUrl = this.storage.ref(data.ref.fullPath).getDownloadURL();  // the url of the image from firebase storage
      return this.downloadUrl;
    });
    return this.downloadUrl;
  }

  /**
   * This function deletes the image from firebase storage
   *
   * @param imageUrl Image URL of the image being deleted
   */
  async deleteImage(imageUrl: string): Promise<DeleteFileResponse> {
    const promiseResponse: DeleteFileResponse = {
      url: '',
      successful: false
    };
    promiseResponse.url = imageUrl;
    try {
      const imageRef = this.storage.storage.refFromURL(imageUrl);
      await imageRef.delete();
      promiseResponse.successful = true;
    } catch (error) {
      console.log(error);
      promiseResponse.successful = false;
    }
    // finally
    return promiseResponse;
  }
}
