import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WorkflowDestinationPickerService {

  // Observable any sources
  private destinationPickerChangeSource = new Subject<any>();

  // Observable any streams
  destinationsChanged$ = this.destinationPickerChangeSource.asObservable();

  constructor() { }

  // Service message commands
  announceChange(change: any) {
    this.destinationPickerChangeSource.next(change);
  }

}
