import { Injectable } from '@angular/core';
import { Process } from '@eva-model/process/process';
import * as moment from 'moment';
import {
  CompactProcess,
  CompactProcessInteraction,
  CompactProcessInteractionScreeen,
  CompactProcessInteractionElement } from '@eva-model/process/compactProcess';
import { DYNAMIC_FORM_CONTROL_TYPE_SWITCH, DynamicInputModel } from '@ng-dynamic-forms/core';
import { isString } from 'lodash';
import { UtilsService } from "../../providers/utils/utils.service";


@Injectable({
  providedIn: 'root'
})
export class CompactProcessService {

  constructor(
   private _utilsService: UtilsService
  ) { }

  /**
   * @param process process object to be compacted
   *
   *  converts process object to compact version with only relevant data for process-edit-summary
   */
  public processCompactor(process: Process): CompactProcess | null {
    if (!(process && process.name)) return null;

    // add name of process to compact process
    const compactProcess = new CompactProcess(process.name, []);

    // for each of the stored interactions completed by user, add it to the compact process object
    for (let interactionIndex = 0; interactionIndex < process.interactionsValues.length; interactionIndex++) {
      if (process.interactionsValues[interactionIndex].submitted) continue; // skip interactions that have been submitted

      const interactionName = process.interactionsValues[interactionIndex].interactionValues.name;
      const interactionOriginalId = process.interactionsValues[interactionIndex].interactionValues.originalId;

      // store interaction name, id, and order added into compactInteraction object. Adds empty array for formscreens
      const compactInteraction = new CompactProcessInteraction(interactionName, interactionOriginalId, interactionIndex, []);
      compactProcess.interactions.push(compactInteraction);

      let elementScreenIndex = -1;
      let elementOrder = 0;

      // eslint-disable-next-line max-len, 
      for (let interactionElementIndex = 0; interactionElementIndex < process.interactionsValues[interactionIndex].interactionValues.elements.length; interactionElementIndex++) {

        const element = process.interactionsValues[interactionIndex].interactionValues.elements[interactionElementIndex];
        // stores current elements screen number into elementScreenIndex.
        // if next element is not in the same screen, adds a new compact form screen
        if (element.scrnIndex !== elementScreenIndex) {
          elementScreenIndex = element.scrnIndex;
          const screenName = this.getInteractionScreenName(process, interactionOriginalId, elementScreenIndex);
          const compactInteractionScreen = new CompactProcessInteractionScreeen(screenName, elementScreenIndex, []);
          compactInteraction.screens.push(compactInteractionScreen);
          elementOrder = 0;
        }

        const elementOriginalId: string = element.originalId;
        const formElement = this.getInteractionScreenElement(process, interactionOriginalId, elementScreenIndex, elementOriginalId);
        const elementLabel = this.getLabel(formElement);

        //#region get element value
        let elementValue: any = element.value;

        elementValue = this.getValue(elementValue, element);

        // check if it is a switch element, value may need to be changed to reflect labels
        // check if there are custom values for on/off toggle, show those instead of true/false
        if (formElement.type === DYNAMIC_FORM_CONTROL_TYPE_SWITCH) {
          if (element.value === true && formElement.onLabel) {
            elementValue = formElement.onLabel;
          } else if (formElement.offLabel) {
            elementValue = formElement.offLabel;
          }
        }
        //#endregion

        //#region set the compact element
        const compactElement =
          new CompactProcessInteractionElement(
            element.type,
            elementLabel,
            elementValue,
            elementOrder,
            element.disabled ? element.disabled : formElement.hidden,
            element.prefix ? element.prefix : null,
            element.suffix ? element.suffix : null);

        compactInteraction.screens[elementScreenIndex].elements.push(compactElement);
        //#endregion

        elementOrder++;
      }
    }

    return compactProcess;
  }

  /**
   *
   * @param process process to be compacted
   * @param interactionOriginalId interactionID used to find form screen name
   * @param screenIndex index of the form screen
   *
   * get the names of formscreens for display
   */
  getInteractionScreenName(process: Process, interactionOriginalId: string, screenIndex: number): string {
    let interactionScreenName = '';
    const processInteractions = process.workflows[0].interactions;
    // get the index of the interaction element
    const interactionIndex = processInteractions.map(prcsIntrct => prcsIntrct.interactionId).indexOf(interactionOriginalId);
    if (interactionIndex !== -1) {
      // use the index of the interaction element to find the index of the screen for that element
      const interactionScreenIndex =
        processInteractions[interactionIndex].interaction.FormScreens.map(intrctScreen => intrctScreen.order).indexOf(screenIndex);
      if (interactionScreenIndex !== -1) {
        // use the index of the screen for that element to get the name
        interactionScreenName = processInteractions[interactionIndex].interaction.FormScreens[interactionScreenIndex].name;
      }
    }
    return interactionScreenName;
  }

  /**
   * @param process process to be compacted
   * @param interactionOriginalId interactionID used to find form screen name
   * @param screenIndex index of the form screen
   * @param elementOriginalId id of the element to be used to find the
   *
   * gets an element to be stored in the compactProcess and displayed
   */
  getInteractionScreenElement(process: Process, interactionOriginalId: string, screenIndex: number, elementOriginalId: string) {
    let interactionElement = null;
    const processInteractions = process.workflows[0].interactions;

    const interactionIndex = processInteractions.map(prcsIntrct => prcsIntrct.interactionId).indexOf(interactionOriginalId);
    if (interactionIndex !== -1) {

      const interactionScreenIndex =
        processInteractions[interactionIndex].interaction.FormScreens.map(intrctScreen => intrctScreen.order).indexOf(screenIndex);
      if (interactionScreenIndex !== -1) {

        const interactionScreen = processInteractions[interactionIndex].interaction.FormScreens[interactionScreenIndex];
        const interactionElementIndex = interactionScreen.FormElements.map(elmnt => elmnt.id).indexOf(elementOriginalId);
        if (interactionElementIndex !== -1) {
          interactionElement = interactionScreen.FormElements[interactionElementIndex];
        }
      }
    }

    return interactionElement;
  }

  /**
   * handles labels for workflow summary in case there is no label entered in
   *
   * @param field a field with a value in it taken from interactionData
   */
  getLabel(field: DynamicInputModel): string {
    let fieldLabel;
    if (!field.label) {
      if (field.hint) { // check if a hint can be used for label
        fieldLabel = field.hint;
      } else {   // no hint so make a label using type or custom message
        switch (field.type) {
          case "DYNAMIC_FORM_CONTROL_TYPE_SELECT":
          fieldLabel = "Dropdown Selection";
            break;
          case "DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP":
          fieldLabel = "Radio Group Selection";
            break;
          default:
          fieldLabel = field.type;
            break;
        }
      }
    }

    // checks if a url is in the label
    if (isString(field.label)) {
      fieldLabel = this._utilsService.findURL(field.label);
    }

    return fieldLabel;
  }

  /**
   * @param elementValue value of element to be formatted
   * @param element object of element to be formatted
   *
   * this function is to format elements to be displayed cleaner in the process edit summary
   */
  getValue(elementValue: any, element: any) {
    if (elementValue) {
      if (element.inputType === 'datetime-local') {
        const dt = new Date(elementValue);
        elementValue = moment(dt).format('DD/MM/YYYY, HH:mm:ss A');
      } else if (element.inputType === 'week') {
        elementValue = elementValue.replace('-W', ', Week ');
      }
    }

    // checks if the elements value is a string, then checks if a url is in the string
    if (isString(elementValue)) {
      elementValue = this._utilsService.findURL(elementValue);
    }
    return elementValue;
  }
}
