import { Component, OnInit, Inject , ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormService } from '@ng-dynamic-forms/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormViewerComponent implements OnInit {

  formViewerModelArray = [];   // Array of DynamicFormControlModel[]
  formViewerFormGroupArray: UntypedFormGroup[] = [];
  formViewerLayoutArray = [];

  formScreens: any;
  dynFrm: any = {};

  constructor(
    public dialogRef: MatDialogRef<FormViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    private ngDynFormService: DynamicFormService,
    public formBuilderService: FormBuilderService
  ) {
    if (dialogData.dynFormMdl && dialogData.dynFormMdl.FormScreens && Array.isArray(dialogData.dynFormMdl.FormScreens)) {
      this.formScreens = dialogData.dynFormMdl.FormScreens;
    }
  }

  /**
   * Clones the dynamic form model and initializes the viewer based on it.
   */
  ngOnInit() {
    this.dynFrm = this.formBuilderService.cloneInteraction(this.dialogData.dynFormMdl);
    if (!this.dynFrm) return;
    this.formScreens = this.dynFrm.FormScreens;   // Utilized in template

    //#region Loop through the interaction screens to generate form groups beside keep collecting form's model and layouts

    if (this.formScreens) {
      this.formScreens.forEach(frmScrn => {
        this.formViewerModelArray.push(frmScrn.FormElements);
        this.formViewerFormGroupArray.push(this.ngDynFormService.createFormGroup(frmScrn.FormElements));
        this.formViewerLayoutArray.push(frmScrn.FormElementsLayout);
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(ev) {
  }
}





