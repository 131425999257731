<!-- loader -->
<div class="loader-spinner" *ngIf="loading; else showFeedback">
  <mat-spinner></mat-spinner>
</div>

<!--- (rowClicked)="viewFeedback($event)" --->
<ng-template #showFeedback>
  <ng-container *ngIf="feedbackData.length > 0 && !error; else noFeedbackData">
    <h4>{{group?.groupName}}</h4>
    <div>
      <ag-grid-angular 
        class="ag-grid-container ag-theme-balham"
        domLayout="autoHeight"
        rowSelection="single"
        [rowData]="feedbackData"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (selectionChanged)="onRowSelectionChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
      </ag-grid-angular>
    </div>
    <!-- custom pagination since no idea how many pages are in db -->
    <br/>
    <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
      <div fxFlex="15" class="prev-button-container">
        <button mat-button color="primary" *ngIf="page > 1" (click)="prevPage()">< Prev Page</button>
      </div>
      <div fxFlex="100" class="page-container">
        Page {{page}}
      </div>
      <div fxFlex="15" class="next-button-container">
        <button mat-button color="primary" *ngIf="feedbackData.length === 20" (click)="nextPage()">Next Page ></button>
      </div>
    </div>
    <br/>
  </ng-container>
  <ng-template #noFeedbackData>
    <div class="no-feedback-data">
      No Feedback to show
    </div>
  </ng-template>
</ng-template>
