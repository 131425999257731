import { environment } from "@environments/environment";
/**
 * Changes to this interface needs to be replicated to all cloud projects.
 */

/**
 * This interface is used for containing information about groups.
 */
export interface Group {
    childGroupPublicKeys?: string[]; // the public keys of the child groups to that this group is a parent to.
    description?: string; // the description of the purpose of the group.
    groupEncryptionMembership?: string[]; // the encryption keys of users in the group.
    groupName: string; // the name of the group.
    groupPublicKey: string; // the public key of the group.
    groupSigningMembership: string[]; // the array of public keys that are used for the signing membership of the group.
    groupSubType?: string; // optional sub type of group.
    groupType: string; // the type of group that is represented.
    parentGroupPublicKeys?: string[]; // the public keys of parent groups to that this group is a child of.
    searchableIndex?: {}; // an array of this group that is searchable for apple: { a: true, ap: true, app: true, etc.. }
    signingMembership?: {}; // the signing membership fo this group same format as the item above expect it has keys on it.
    // was created this way to be searchable.
}

/**
 * This interface is used to put in a request for the group access for the user.
 */
export interface GroupAccessRequest {
    groupPublicKey: string; // the public key that the user is requesting access to.
    groupName: string; // the name of the group that the user was requesting access to.
    groupType: string; // the group type that exists.
    id?: string; // the id of the group access request. (assigned by the database when created.)
    requesterEmail: string; // the email address of the user requesting access to the group.
    requesterName: string; // the name that the user has provided for the users.
    requesterPublicKey: string; // the public key of the user who is requesting access to the group.
}

/**
 * This contains the different types of groups that exist in the EVA environment.
 */
export class GroupType {
    normal = environment.blockConfig.types.groups.types.normal;
    invitation = environment.blockConfig.types.groups.types.invitation;
    flowThrough = environment.blockConfig.types.groups.types.flowThrough;
    knowledge = environment.blockConfig.types.groups.types.knowledge;
    dynamicData = environment.blockConfig.types.groups.types.dynamicData;
}
