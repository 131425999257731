import { Pipe, PipeTransform } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Group } from '@eva-model/group';
import { take, skip } from 'rxjs/operators';

 /**
  * Checks the users groups if they belong to a group of a specific type.
  * Returns true or false.
  *
  * ---
  * NOTE:
  *
  * For this pipe to work effectively:
  * Groups are usually ready when the app is ready, however there may be instances when a user is navigating directly to a page
  * from an app cold start or when not all the user data is loaded into memory. In order for this pipe to work, you must
  * add the async pipe to the end (in case groups are not ready)
  *
  * @example
  * <div *ngIf="groupType | isUserInGroupType | async">User is a member of this group type!</div>
  */
@Pipe({
  name: 'isUserInGroupType'
})
export class IsUserInGroupTypePipe implements PipeTransform {

  constructor(private evaGlobal: EvaGlobalService) {}

  async transform(groupType: string): Promise<boolean> {
    let userGroups = this.evaGlobal.userGroups;
    if (!userGroups || userGroups.length === 0) {

      // unfortunately, the BehaviorSubject is initialized with the value of true
      // when the groups are ready, the subject again emits true...
      // very difficult to understand when something is ready...
      // so lets skip the first `true` and when the groups are ready, take the next `true`
      await this.evaGlobal.userGroupsChange$.pipe(
        skip(1),
        take(1)
      ).toPromise();

      // groups ready now.
      userGroups = this.evaGlobal.userGroups;
      // return this.checkGroupsForType( userGroupKeys, groupType );
    }
    
    return this.checkGroupsForType(userGroups, groupType);
  }

  checkGroupsForType(groups: Group[], type: string): boolean {
    const found = groups.find((group) => group.groupType === type);
    // if find has a key, the key was found, else find will be undefined.
    return found ? true : false;
  }

}
