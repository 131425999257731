<mat-card [ngClass]="{ 'feedback-done': feedback.isReviewed, 'feedback-not-done': !feedback.isReviewed, 'feedback-clicked': feedbackClicked }">
  <mat-card-content>
    <h6 class="feedback-item-title">Feedback</h6>
    <p>{{ feedback.userFeedback }}</p>
    <h6 class="feedback-item-title">Question Asked</h6>
    <p>{{ feedback.query }}</p>
    <hr/>
    <div class="feedback-metadata">
      <app-user-info-display [publicKey]="feedback.userPublicKey"></app-user-info-display>
      <p>{{ feedback.timestamp | date:'short' }}</p>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-icon-button (click)="highlightSectionInEditor(feedback.docSection)" matTooltip="Go to Section"><mat-icon>search</mat-icon></button>
    <ng-container *ngIf="feedback.isReviewed; else incomplete">
      <button mat-flat-button color="primary" disabled><mat-icon>checkmark</mat-icon> Complete</button>
    </ng-container>
    <ng-template #incomplete> 
      <button mat-flat-button color="primary" (click)="updateFeedback()">Mark Completed</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
