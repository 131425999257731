import { Directive, HostListener, EventEmitter, Output, Input } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { HoldableEmitter } from '@eva-model/directives';

@Directive({
  selector: '[appHoldable]'
})
export class HoldableDirective {

  @Output() holdTime: EventEmitter<HoldableEmitter> = new EventEmitter(); // the time the item has been held for.
  @Input() originalData: any;

  state: Subject<string> = new Subject(); // current state of the press
  cancel: Observable<string>; // the observable that can be listened to for the events.

  constructor() {
    // setup the cancel event.
    this.cancel = this.state.pipe(
      filter(v => v === 'cancel'),
      tap(v => {
        console.log('%c stopped hold', 'color: #ec6969; font-weight: bold;');
        this.holdTime.emit({ holdTime: 0, originalData: this.originalData });
      })
    );
  }

  // setup when the mouse event ends.
  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onExit() {
    this.state.next('cancel');
  }
  // setup when the mouse event starts.
  @HostListener('mousedown', ['$event'])
  onHold() {
    console.log('%c started hold', 'color: #5fba7d; font-weight: bold;');

    this.state.next('start');

    const n = 100;
    // setup the interval to emit every n ms until the event is finished.
    interval(n).pipe(
      takeUntil(this.cancel),
      tap(v => {
        this.holdTime.emit({ holdTime: (v * n), originalData: this.originalData });
      }),
    ).subscribe();
  }
}
