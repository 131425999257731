import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdministrationOverviewComponent } from '@eva-ui/administration/administration-overview/administration-overview.component';
import { ProcessStatusComponent } from '@eva-ui/administration/process-status/process-status.component';
import { ProcessCompareComponent } from '@eva-ui/administration/process-compare/process-compare.component';
import { FeedbackComponent } from '@eva-ui/administration/feedback/feedback.component';
import { CompleteGridChildComponent } from '@eva-ui/administration/feedback/complete-grid-child/complete-grid-child.component';
import { EvaLogsComponent } from '@eva-ui/administration/eva-logs/eva-logs.component';

import { AgGridModule } from 'ag-grid-angular';
import { EvaMaterialModule } from '@eva-ui/shared/material.module';
import { BackToTopComponent } from '@eva-ui/common/back-to-top/back-to-top.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdministrationGuard } from '@eva-core/auth-guard/administration.guard';
import { AuthGuard } from '@eva-core/auth-guard/auth.guard';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';

const routes: Routes = [{
  path: '',
  component: AdministrationOverviewComponent,
  canActivate: [AdministrationGuard, AuthGuard]
}, {
  path: 'ProcessIssues',
  component: ProcessCompareComponent,
  canActivate: [AdministrationGuard, AuthGuard],
  data: {
    componentTitle: 'Process Issues'
  }
}, {
  path: 'ProcessStatus',
  component: ProcessStatusComponent,
  canActivate: [AdministrationGuard, AuthGuard],
  data: {
    componentTitle: 'Process Status Report'
  }
}, {
  path: 'EVALogs',
  component: EvaLogsComponent,
  canActivate: [AdministrationGuard, AuthGuard],
  data: {
    componentTitle: 'EVA Logs'
  }
}, {
  path: 'Feedback',
  component: FeedbackComponent,
  canActivate: [AdministrationGuard, AuthGuard],
  data: {
    componentTitle: 'Feedback'
  }
}];

@NgModule({
  declarations: [
    AdministrationOverviewComponent,
    ProcessStatusComponent,
    ProcessCompareComponent,
    FeedbackComponent,
    CompleteGridChildComponent,
    EvaLogsComponent,
    BackToTopComponent
  ],
  imports: [
    AgGridModule.withComponents([
      CompleteGridChildComponent
    ]),
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    EvaMaterialModule,
    UiComponentsModule
    // GrowlModule
  ],
  exports: [
    EvaMaterialModule,
    RouterModule
  ]
})
export class AdministrationModule { }
