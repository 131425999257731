import { Pipe, PipeTransform } from '@angular/core';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';

@Pipe({
  name: 'getMathEquationOperators'
})
export class GetMathEquationOperatorsPipe implements PipeTransform {

  constructor(private conditionObjectCheckerService: ConditionalObjectCheckerService) {}

  transform(): string[] {
    return this.conditionObjectCheckerService.getMathEquationOperators().map(option => option.value);
  }

}
