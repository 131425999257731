import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@eva-core/auth.service';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { SigningService } from '@eva-core/signing.service';
import { EvaEncryptionService } from '@eva-core/encryption/eva-encryption.service';
import { StorageRequest, StorageObject } from '@eva-model/storage/evaStorage';
import { take } from 'rxjs/operators';
import { EVAStorageUnencryptedObject, EVAStorageRequestResponse,
  EVAStorageRequestResponseWithStorageObject } from '@eva-model/storage/evaStorage';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  constructor(
    private http: HttpClient,
    public afAuth: AuthService,
    private _fireStoreService: FirestoreService,
    private signingService: SigningService,
    private _evaEncryptionService: EvaEncryptionService
    ) { }

  /**
   * This takes an encrypted object to store and sends it to the appropriate storage location.
   *
   * @param encryptedObjectToStore // a properly signed encrypted object request.
   */
  async storeEncryptedObjects(encryptedObjectToStore: StorageObject): Promise<EVAStorageRequestResponse> {

    let url = '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    try {
      const user = await this.afAuth.user.pipe(take(1)).toPromise();
      const email: string = user.email;

      if (email.includes("@atb.com")) {
        url = environment.endPoints.EVA_STORAGE.urlATB + environment.endPoints.EVA_STORAGE.addLocationData;
      } else {
        url = environment.endPoints.EVA_STORAGE.urlNonATB + environment.endPoints.EVA_STORAGE.addLocationData;
      }
      return this.http.post<EVAStorageRequestResponse>(url, encryptedObjectToStore, httpOptions).pipe(take(1)).toPromise();
    } catch (err) {
      return Promise.reject('Error in storage service: ' + JSON.stringify(err));
    }
  }

  /**
   * This gets the encrypted object from the appropriate system.
   * @param storageRequest // the storage request object. ex.
   */
  async getEncryptedStorageObjects(storageRequest: StorageRequest): Promise<any> {
    let url = '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let retryFetch = 3;
    let successful = false;
    let result = null;

    while (retryFetch > 0) {
      retryFetch -= 1;
      try {
        const user = await this.afAuth.user.pipe(take(1)).toPromise();
        const email: string = user.email;

        if (email.includes("@atb.com")) {
          url = environment.endPoints.EVA_STORAGE.urlATB + environment.endPoints.EVA_STORAGE.getLocationData;
        } else {
          url = environment.endPoints.EVA_STORAGE.urlNonATB + environment.endPoints.EVA_STORAGE.getLocationData;
        }
        result = await this.http.post(url, storageRequest, httpOptions).pipe(take(1)).toPromise();
        successful = true;
        break;
      } catch (err) {
        return Promise.reject('Error in storage service: ' + JSON.stringify(err));
      }
    }

    if (successful) {
      return result;
    } else {
      return Promise.reject(result);
    }
  }

  /**
   * This function gets the storage request from eva-links, tries to decrypt it and then
   * returns the object and status to the calling function.
   *
   * @param idUrl the storage ID you are looking to retrieve,
   * @param isUserLastUpdate whether the current user was the user to last update the storage object.
   */
  async decryptData(idUrl: string, isUserLastUpdate: boolean): Promise<EVAStorageUnencryptedObject>  {

    try {
      const storageRequest = await this.signingService.createAndSignStorageRequest(idUrl,
        isUserLastUpdate);
      // check if a storage request was received.
      if (storageRequest) {
        const storageResponse = await this.getEncryptedStorageObjects(storageRequest);
        if (storageResponse.dataExists && storageResponse.isValidRequest) {
          return this._evaEncryptionService.decryptStorageObjects(storageResponse, isUserLastUpdate);
        }
      } else {
        return { status: 'No Request', unencryptedObject: {}, existingKeys: [] };
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }


  /**
   * This creates an encrypted object with a random password and initialization vector using symmetric encryption. It then takes
   * this password and encrypts it assymetrically against each public key retrieved from the group and the keys that were included.
   * Once it obtains this, it then tries to store it to the EVA-links project. Once accepted to the EVA-links project,
   * it stores this object to the EncryptedData collection under the users document.
   *
   * @param objectToEncrypt the object to encrypt. It can be any object.
   * @param groupPublicKey the group publicKey to obtain membership information from to include in the encryption.
   * @param existingKeysToInclude additional public keys to incllude in the encryption.
   */
  async encryptData(objectToEncrypt: any, groupPublicKey: string,
  existingKeysToInclude?: string[]): Promise<EVAStorageRequestResponseWithStorageObject> {
    // create the new storage object.
    const storageObject = new StorageObject();

    try {
      const objectToSend = await this._evaEncryptionService.createStorageAndEncryptedData(objectToEncrypt,
        groupPublicKey, existingKeysToInclude);
      // check the creation
      if (objectToSend && objectToSend.data) {
        storageObject.data = objectToSend.data;
      } else {
        return Promise.reject('Failure to create storage object');
      }

      // this tries to store the encrypted object to the EVA links project
      const storageResponse = await this.tryToStoreProcessData(storageObject);
      if (storageResponse.successful) {
        // get the local storage object for storage to the user data.
        const tempLocalStorageObject = JSON.parse(JSON.stringify(storageResponse.encryptedSignedObject));
        await this.userEncryptedDataUpsert(tempLocalStorageObject, storageResponse.idUrl);
        return storageResponse;
      } else {
        return Promise.reject('Error storing encrypted data to eva links.');
      }

    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
   /**
   * This function tries to store the object to the EVA-links project. It will retry 3 times before it fails. This returns
   * an object of a storage request regardless of what happened.
   *
   * @param processSensitiveData this is the sensitive data used in a process
   * @param encryptGroupPublickey the group that the storage object is moving to.
   * @param encryptUserPublicKey the users encryption public key.
   */
  async tryToStoreProcessData(storageObject: StorageObject): Promise<EVAStorageRequestResponseWithStorageObject> {
    let evaStorageRequestResponse: EVAStorageRequestResponseWithStorageObject = { idUrl: '', successful: false };
    let retryStorage = 3;

    while (retryStorage > 0) {
      retryStorage -= 1;
      try {
        // get the EVA time.
        const currentTime = await this.signingService.getCurrentTime();
        // set the time of the object
        if (storageObject.data) {
          storageObject.data.timestamp = currentTime.time;
        }

        // sign the encrypted object
        const signedEncryptionObject = await this.signingService.signObjectWithEncryptionKey(storageObject);

        // send this to the appropriate storage location.
        const storageResponse = await this.storeEncryptedObjects(signedEncryptionObject);

        // this only occurs on success.
        if (storageResponse.successful) {
          evaStorageRequestResponse = { idUrl: storageResponse.idUrl, successful: true, encryptedSignedObject: signedEncryptionObject };
          // retryStorage = 0;
          break;
        }
      } catch (err) {
        console.log(err);
      }
    }
    return evaStorageRequestResponse;
  }

  /**
   * This takes an encrypted object and stores it under the users document in a encryptedObjectToStore collection
   * it's used to ensure that fast encryption and unencryption can occur between interactions in a workflow
   * while the user is working on them.
   *
   * @param encryptedObjectToStore The encrypted object to store on the user object.
   * @param idUrl the id that the object will be stored under.
   */
  private async userEncryptedDataUpsert(encryptedObjectToStore: any, idUrl: string): Promise<void> {
    const userId = await this.afAuth.getUserId();
    const encryptedObjectCollection =
      this._fireStoreService.col<any>('users/' + userId + '/EncryptedObject');
    const encDoc = encryptedObjectCollection.doc(idUrl);

    try {
      return this._fireStoreService.upsert(encDoc, encryptedObjectToStore);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
}
