<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <span [innerHTML]="dialogData.content"></span><br/>
  {{ pickedGroupName }} :: {{ pickedEntityName }}

  <br/><br/>

  <mat-horizontal-stepper #stepperCtrl
    [linear]="isLinear" #stepper="matHorizontalStepper"
    [selectedIndex]="this.selectedStepperIndex"
    (selectionChange)="selectionStepperChange($event)">

    <mat-step [stepControl]="selectUserGroupFormGroup" [optional]=false>
      <mat-card>
        <form [formGroup]="selectUserGroupFormGroup">
          <ng-template matStepLabel>Choose a group</ng-template>
          
          <mat-form-field class="fullWidth">
            <mat-select placeholder="User group" formControlName="selectGroupCtrl" required (selectionChange)="onGroupChange($event)">
              <mat-option *ngFor="let userGroup of evaGlobalService.userGroups" [value]="userGroup.groupPublicKey">
                {{ userGroup.groupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
            <button mat-button matStepperNext>Next</button>
          </div>

        </form>
      </mat-card>
    </mat-step>

    <mat-step [stepControl]="selectEntityFormGroup">
      <mat-card>
        <form [formGroup]="selectEntityFormGroup">
          <ng-template matStepLabel>Select a {{entityType}}</ng-template>

          <div *ngIf="!isWaiting">
            <mat-form-field class="fullWidth">
              <mat-select placeholder="Select an {{entityType}}" formControlName="selectEntityCtrl" required (selectionChange)="onEntityChange($event)">
                <mat-option *ngFor="let entity of entities" [value]="entity.id">
                  {{ entity.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button (click)="stepperReset(stepperCtrl)">Reset</button>
              <button mat-button (click)="onPickEntity()" mat-raised-button color="primary" [mat-dialog-close]="true" [disabled]="isPickerNotComplete()">Pick</button>
            </div>
          </div>

          <div *ngIf="isWaiting">
            <app-spinner name="getting-keys" show="true">
              <h6>Processing ...</h6>
            </app-spinner>
          </div>
        </form>
      </mat-card>
    </mat-step>

  </mat-horizontal-stepper>

</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
  </div>
</div>
