import { Component, OnInit, Input } from '@angular/core';
import { KnowledgeFeedbackService } from '@eva-services/knowledge/feedback/knowledge-feedback.service';
import { TfidfFeedbackDocument, TfidfFeedbackDocRequest } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-knowledge-feedback',
  templateUrl: './knowledge-feedback.component.html',
  styleUrls: ['./knowledge-feedback.component.scss']
})
export class KnowledgeFeedbackComponent implements OnInit {

  @Input() docId: string;
  @Input() groupKey: string;
  @Input() version: number;

  loading = true;
  error = false;

  isReviewed = false; // for the doc requests

  // We keep feedback seperate so we know what we have from the server,
  // and so we don't keep making requests on every tab change.
  reviewedFeedbackItems: TfidfFeedbackDocument[] = []; // reviewed feedback objects passed into child component
  unreviewedFeedbackItems: TfidfFeedbackDocument[] = []; // unreviewed feedback objects passed into child component

  constructor(private knowledgeFeedbackService: KnowledgeFeedbackService) { }

  ngOnInit() {
    this.getFeedback();
  }

  /**
   * Hits route to get feedback about this document.
   */
  async getFeedback() {

    this.loading = true;
    this.error = false;

    try {
      const feedbackResponse = await this.knowledgeFeedbackService.getFeedbackByDocument(
        this.groupKey,
        this.docId,
        this.version,
        this.isReviewed);

      // bind results
      if (this.isReviewed) {
        this.reviewedFeedbackItems = feedbackResponse.feedback;
      } else {
        this.unreviewedFeedbackItems = feedbackResponse.feedback;
      }

      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.error = true;
    }
  }

  /**
   * Fires off a new request for feedback that is reviewed/unreviewed.
   */
  tabChanged(event: MatTabChangeEvent): void {

    this.loading = true;

    // Unreviewed feedback
    if (event.index === 0) {
      this.isReviewed = false;
      // get feedback if we don't have this feedback yet to reduce api calls on tab changes
      if (!this.unreviewedFeedbackItems.length) {
        this.getFeedback();
      } else {
        this.loading = false;
      }
    }

    // Reviewed Feedback
    if (event.index === 1) {
      this.isReviewed = true;
      // get feedback if we don't have this feedback yet to reduce api calls on tab changes
      if (!this.reviewedFeedbackItems.length) {
        this.getFeedback();
      } else {
        this.loading = false;
      }
    }
  }

  /**
   * Hides the feedback panel from view
   */
  closeFeedback(): void {
    this.knowledgeFeedbackService.hideFeedbackPanel();
  }

}
