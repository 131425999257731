"use strict";

// :: Copied from DynamicForms-FirebaseFunction project :: attn: consistency reason ::

/**
 * This contains the settings which allow the different projects to communicate
 */
export const ProjectSettings = {
    globalDatabase: {
        publicKeyPath: 'PublicKeys'
    },
    types: {
        dynamicInteractions: {
            typeId: 'RmFyaGFkJ3MgYW1hemluZyBkeW5hbWljIGludGVyYWN0aW9ucw==',
            knownKeyInteractionPath: 'ATBDynamicInteractions',
            publicKeyInteractionPath: 'PublicDynamicInteractions'
        },
        groups: {
            typeId: 'SSBmZWxsIGRvd24uIE15IGdyb3VwIG9mIGZyaWVuZHMgcGlja2VkIG1lIHVwLg==',
            groupSigningPath: 'GroupSigningKeys',
            knownGroupEncryptionPath: 'ATBGroupEncryptionKeys',
        },
        workflows: {
            typeId: 'VG8gd2FsayB1cHN0cmVhbSBpbiBhIHJpdmVyIGFuZCB0aHJvdWdoIGEgd2F0ZXJmYWxsLCBpcyBoYXJkIQ==',
            knownKeyWorkflowPath: 'ATBWorkflows',
            publicKeyWorkflowPath: 'PublicWorkflows'
        },
        processes: {
            typeId: 'RnJlZWRvbSdzIGp1c3QgYW5vdGhlciB3b3JkIGZvciBub3RoaW5nIGxlZnQgdG8gbG9zZQ==',
            knownKeyProcessPath: 'ATBProcesses',
            publicKeyProcessPath: 'PublicProcesses'
        }
    }
};
