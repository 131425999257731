import { Injectable } from '@angular/core';
import { Utils } from '@eva-core/encryption/utils';
import * as Elliptic from 'elliptic';
import { environment } from '@environments/environment';

interface ImportedUserKeys {
  priv: string;
  privEnc: string;
  pub: string;
  pubEnc: string;
}

// add this to hangle the IE implementation
declare global {
  interface Window { msCrypto: any; }
}

const ec = new Elliptic.ec(environment.blockConfig.ENCRYPTION_CURVE);

@Injectable()
export class SubtleEncryptionService {

  crypto = window.crypto || window.msCrypto;

  constructor() { }

  // Returns one JWK specified by keyType (public or private)
  generateJWKFromEllipticPairHex(keys: Elliptic.ec.KeyPairOptions, curve: string, keyOps: string[], keyType: 'private'|'public'):
  JsonWebKey {
    const keyPair = ec.keyPair(keys);

    const jwk = {
      crv: curve,
      ext: false,
      key_ops: keyOps,
      kty: 'EC',
      x: null,
      y: null
    };

    jwk.x = Utils.base64EncodeUrl(keyPair.getPublic().getX().toArray('be', 32));
    jwk.y = Utils.base64EncodeUrl(keyPair.getPublic().getY().toArray('be', 32));

    if (keyType === 'private') {
        jwk['d'] = Utils.base64EncodeUrl(keyPair.getPrivate()); // .toArray('be', 32));
    }

    return jwk;
  }

  // Returns one JWK specified by keyType (public or private)
  generateJWKFromCryptoPairHex(keys: ImportedUserKeys, curve: string, keyOps: string[], keyType: 'private'|'public'): JsonWebKey {
    const jwk = {
      crv: curve,
      ext: false,
      key_ops: keyOps,
      kty: 'EC',
      x: null,
      y: null
    };

    const publicKeyArray = Utils.convertHexToArray(keys.pub).slice(1);
    const privateKeyArray = Utils.convertHexToArray(keys.priv);

    const x = [];
    const y = [];

    for (let i = 0; i < 32; i++) {
        x.push(publicKeyArray[i]);
    }

    for (let i = 0; i < 32; i++) {
        y.push(publicKeyArray[i + 32]);
    }

    // split our array
    jwk.x = Utils.base64EncodeUrl(x);
    jwk.y = Utils.base64EncodeUrl(y);

    if (keyType === 'private') {
        jwk['d'] = Utils.base64EncodeUrl(privateKeyArray);
    }

    return jwk;
  }

  // ex. importKey('jwk', pubSigningJWK, {name: 'ECDSA', namedCurve: 'P-256'})
  async importKey(format: 'jwk', keyData: JsonWebKey, algorithm: any): Promise<CryptoKey> {

    return await crypto.subtle.importKey(format, keyData, algorithm, keyData.ext, keyData.key_ops as KeyUsage[]);
  }

  // algorithm - {name: 'ECDH', public: publicKey}`
  // ^ public key would be the device offset key
  async deriveEncryptionKey(algorithm: any, devicePrivKey: CryptoKey, extractable: boolean): Promise<CryptoKey> {
    return await this.crypto.subtle.deriveKey(
      algorithm, devicePrivKey, {name: 'AES-CBC', length: 256}, extractable, ['encrypt', 'decrypt']
    );
  }

  async encryptObjectToHex(encryptionKey: CryptoKey, iv: Uint8Array, data: any): Promise<string> {
    const encData = await this.crypto.subtle.encrypt({name: 'AES-CBC', iv: iv}, encryptionKey, Utils.convertObjectToArrayBufferView(data));
    return Utils.convertArrayBufferViewToHex(encData);
  }

  async decryptHexToObject(encryptionKey: CryptoKey, iv: Uint8Array, data: string) {
    const decData = await this.crypto.subtle.decrypt({name: 'AES-CBC', iv: iv}, encryptionKey, Utils.convertHextoArrayBufferView(data));
    return Utils.convertArrayBufferViewToObject(decData);
  }

  async encryptObject(encryptionKey: CryptoKey, iv: Uint8Array, data: any): Promise<any> {
    const encData =
      await this.crypto.subtle.encrypt({name: 'AES-CBC', iv: iv}, encryptionKey, this.stringToArrayBuffer(JSON.stringify(data)));

    return encData;
  }

  async decryptToObject(encryptionKey: CryptoKey, iv: Uint8Array, data: string) {
    const decData = await this.crypto.subtle.decrypt({name: 'AES-CBC', iv: iv}, encryptionKey, data);

    return this.ArrayBufferToString(decData);
  }

  //#region :: https://github.com/dy/string-to-arraybuffer
  stringToArrayBuffer (str: string) {
    const array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      array[i] = str.charCodeAt(i);
    }
    return array.buffer;
  }
  //#endregion

  //#region :: https://github.com/dy/arraybuffer-to-string
  ArrayBufferToString (buffer, encoding?: string) {
    if (encoding == null) encoding = 'utf8';

    return Buffer.from(buffer).toString(encoding);
  }
  //#endregion

  getCryptoIv(): Uint8Array {
    return <Uint8Array>crypto.getRandomValues(new Uint8Array(16));
  }

}
