import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

const LOCAL_STORAGE_KEY = 'eva-menu-expanded';

@Injectable({
  providedIn: 'root'
})
export class MenuNavigationService {

  private currentMenuName: BehaviorSubject<string> = new BehaviorSubject(null); // contains the currently active menu
  private isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(null); // contains the expanded status of left menu
  private currentMenuNameLocal = 'main'; // the services last state version.
  public currentMenuName$: Observable<string> = this.currentMenuName.asObservable();
  public isExpanded$: Observable<boolean> = this.isExpanded.asObservable().pipe(
    filter(v => v !== null)
  );

  constructor() {
    this.currentMenuName.next(this.currentMenuNameLocal); // emit the main menu value.

    // subscribe to isExpanded and update it if null value
    this.isExpanded.asObservable().pipe(
      filter(value => value === null),
      take(1)
    ).subscribe((currentValue) => {
      let newMenuState = true;
      // check localStorage for state of menu
      const localMenuState = localStorage.getItem(LOCAL_STORAGE_KEY);

      // if no localstorage, set an initial value for user
      if (!localMenuState) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newMenuState));
      } else {
        if (localMenuState === 'true') {
          newMenuState = true;
        }
        if (localMenuState === 'false') {
          newMenuState = false;
        }
      }

      this.isExpanded.next(newMenuState);
    })
  }


  /**
   * This function updates the current menu path for the user.
   *
   * @param currentMenuName the name of active menu that would be displayed.
   */
  public updateCurrentMenu(currentMenuName: string, forceUpdate?: boolean): void {
    // don't emit the change if it is the same as the current state.
    if (currentMenuName === this.currentMenuNameLocal && !forceUpdate) {
      return;
    }
    this.currentMenuNameLocal = currentMenuName; // update the local menu options.
    this.currentMenuName.next(currentMenuName);
  }

  public toggleExpandedStatus(): void {
    let newMenuState = !this.isExpanded.value;
    this.isExpanded.next(newMenuState);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newMenuState));
  }
}
