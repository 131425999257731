  export class ChildGroupInvitation {
    private _message: string;
    private _childGroupPublicKey: string;
    private _parentGroupPublicKey: string;

    constructor(message: string, childGroupPublicKey: string, parentGroupPublicKey: string) {
      this._message = message;
      this._childGroupPublicKey = childGroupPublicKey;
      this._parentGroupPublicKey = parentGroupPublicKey;
    }

    /**
     * Returns a version of this ChildGroupInvitation suitable for signing and storing to firebase.
     */
    getDataForSigning(): any {
      return {
        // Keep properties in alphabetical order.
        // Otherwise database will rearrange them in alphabetical order
        // which will cause trouble for signing
        // as signing involves stringifying this object.
        childGroupPublicKey: this._childGroupPublicKey,
        message: this._message,
        parentGroupPublicKey: this._parentGroupPublicKey
      };
    }
  }

