<div *ngIf="_isAdministrator">
    <button mat-button color="primary" class="learn-videos-button" (click)="addEditLearnEVAVideos()">Add / Edit EVA Learning Videos</button>
</div>
<div id="video-player" *ngIf="learnEVAVideosDoc" class="learn-eva-videos" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2.5%">
    <div class="featured_learning" *ngIf="featuredVideoReady" fxFlex="70%">
        <!-- <div class="videoWrapper"> -->
        <div class="embed-responsive embed-responsive-16by9" [innerHtml]="currentPlayingVideo.embedCode">
            <!-- <div class="featured-video" [innerHtml]="learnEVAVideosDoc.featured.embedCode"></div>  -->
        </div>
        <h2>{{ currentPlayingVideo.title }}</h2>
        <p class="learnEVADescription">
          {{ currentPlayingVideo.description }}
        </p>
    </div>
    <div fxLayout="column" fxFlex="30%" class="video-list">
        <h4 class="no-toppadding-head">Uploaded Videos</h4>
        <!-- keep all the videos together -->
        <mat-list>
            <mat-list-item (click)="changeCurrentPlaying(learnEVAVideosDoc.featured)">
                <a class="link-title" (click)="scrollToElement()">{{ learnEVAVideosDoc.featured.title}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let link of learnEVAVideosDoc.links" (click)="changeCurrentPlaying(link)">
                <!-- <div [innerHtml]="links.embedCode"></div> -->
                <a class="link-title" (click)="scrollToElement()">{{ link.title }}</a>
                <!-- <p class="learnEVADescription">
                {{ link.description }}
                </p> -->
            </mat-list-item>
        </mat-list>
    </div>
</div>