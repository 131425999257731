import { Injectable } from '@angular/core';
import * as Crypto from 'crypto';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
// eslint-disable-next-line max-len
import { UrlShortenerData, UrlDataType, UrlShortenerRequest, UrlShortenerResponse, UrlShortlinkData } from '@eva-model/url-shortener/urlShortenerBase';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { Params } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlShortenerService {

  createRoute = `${environment.firebaseFunction.endpoint}/createShortenedUrl`; // cloud function to call to create urls
  collection = 'Urls'; // base colletion name containing all urls

  /**
   * Contains the last url shortlink in memory. Also used to notify any subscribers a new link has been passed in the url.
   */
  private lastUrlShortlinkData: BehaviorSubject<UrlShortlinkData<any>> = new BehaviorSubject(null);

  constructor(private firestore: FirestoreService,
              private http: HttpClient,
              private location: Location) { }

  /**
   * Create shortened URL with the provided data. Provides an id to use in the redirect route.
   *
   * If the url hash already exists in the database, just return the hash id
   */
  public async createNewUrl(urlType: UrlDataType, data: any): Promise<string> {
    const urlObject = this.createUrlObject(urlType, data);
    const hash = this.getHash(urlObject.data);
    const refPath = `${this.collection}/${hash}`;

    try {
      const urlDocSnapshot = await this.firestore.doc(refPath).get().toPromise();
      if (urlDocSnapshot.exists) {
        return hash;
      } else {
        const urlResponse = await this.createUrlInDatabase(urlType, urlObject);
        return urlResponse.id;
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  /**
   * Returns an observable, filtering by the provided UrlDataType and only  the observable if a match is found for the UrlDataType
   */
  public listenForUrlByType(urlType: UrlDataType): Observable<UrlShortlinkData<any>> {
    return this.lastUrlShortlinkData.pipe(
      filter(urlData => !!urlData),
      filter(urlData => urlData.type === urlType)
    );
  }

  /**
   * Creates a URL shortener object that can be stored in the database.
   * @param urlType type of the data
   * @param data data to store
   */
  private createUrlObject<T>(urlType: UrlDataType, data: any): UrlShortenerData<T> {
    return {
      createdAt: Date.now(),
      type: urlType,
      data
    };
  }

  /**
   * Takes a UrlShortenerObject and attemps to save it into the database. Returns a doc id of the url object
   * if the object passed all checks on the backend.
   * @param urlType type of the data
   * @param data data to store
   */
  private async createUrlInDatabase(urlType: UrlDataType, data: UrlShortenerData<any>): Promise<UrlShortenerResponse> {
    const request: UrlShortenerRequest = {
      type: urlType,
      data
    };

    try {
      return await this.http.post<UrlShortenerResponse>(this.createRoute, request).toPromise();
    } catch (err) {
      Promise.reject(err);
    }
  }


  /**
   * Get md5 hash based on object
   */
  private getHash(object: any): string {
    if (typeof object !== 'string') {
      object = JSON.stringify(object);
    }

    return Crypto.createHash('sha256').update(object).digest('hex');
  }

  /**
   * get the url id data, if it doesn't exist then returns null.
   * @param docId - doc id (an md5 hash)
   */
  public async getUrl(docId: string): Promise<UrlShortenerData<any>> {
    try {
      const urlDataSnap = await this.firestore.doc(`${this.collection}/${docId}`).get().pipe(take(1)).toPromise();
      if (urlDataSnap.exists) {
        return urlDataSnap.data() as UrlShortenerData<any>;
      }

      // no url data in the database
      return null;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * Returns an observable with the last link data, then completes.
   */
  public getLastShortlink(): Observable<UrlShortlinkData<any>> {
    return this.lastUrlShortlinkData.pipe(take(1));
  }

  /**
   * Once the data has been used, clear it.
   */
  public clearLastShortlink(): void {
    this.lastUrlShortlinkData.next(null);
  }

  /**
   * Requests the url data and then passes a `UrlShortlinkData<any>` object through the observable
   * @param urlHash doc id of the url object in database
   * @param params any query params in an object format, will be empty object if no query params
   */
  public async getUrlDataAndNotify(urlHash: string, params?: Params): Promise<void> {
    // check url hash exists in firestore and get its data
    let urlDocData: UrlShortenerData<any>;

    try {
      urlDocData = await this.getUrl(urlHash);
    } catch (err) {
      return Promise.reject(err);
    }

    // checkpoint to see if doc does not exist.
    if (!urlDocData) {
      return;
    }

    // create our notification data
    const newUrlShortlinkData: UrlShortlinkData<any> = {
      id: urlHash,
      type: urlDocData.type,
      data: urlDocData.data,
      queryParams: null
    };

    // backfill the query params
    // determine if there are any keys in our params object
    if (params && Object.keys(params).length > 0) {
      // just because params is passed, does not mean the obj has any keys
      newUrlShortlinkData.queryParams = params;
    }

    this.lastUrlShortlinkData.next(newUrlShortlinkData);

    // finally, if we are pushing new data to our shortlink observable, it's likely the data is currently in the url bar.
    // let's clear the url so when the user refreshes, it doesn't do the same action again.
    this.location.replaceState('/');
  }

  /**
   * Returns a url relating to the correct environment with query params
   * @param path after the domain - eg. '/about'
   * @param queryParams query params to return in url string
   */
  createUrlWithQueryParams(path: string, params: Params): string {
    let queryString = '';

    Object.entries(params).forEach(([k, v]) => {
      queryString += k + '=' + v + '&';
    });

    // cut the end ampersand off
    queryString = queryString.substring(0, queryString.length - 1);

    return `https://${window.location.host}` + path + `?${queryString}`;
  }

}
