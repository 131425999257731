import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

const BACKGROUND_COLORS = [
  "#e51c23",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#5677fc",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#259b24",
  "#8bc34a",
  "#afb42b",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b"
];

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnChanges {

  /**
   * Size of the element including unit measurement. This will change the width and height of element to this size.
   */
  @Input() size = '48px';

  /**
   * Full name of the user who you'd like to show the avatar for.
   */
  @Input() name: string;

  /**
   * photo URL will override name if provided
   */
  @Input() photo: string;

  public initials: string = null;
  public backgroundColor = '#000000';

  constructor() { }

  ngOnInit(): void {
    this.initials = this.getInitials(this.name);
    this.backgroundColor = this.getBackgroundColor(this.initials);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name && changes.name.currentValue) {
      this.initials = this.getInitials(changes.name.currentValue);
      this.backgroundColor = this.getBackgroundColor(this.initials);
    }
  }

  /**
   * Converts full name into initals
   */
  private getInitials(name: string): string {
    if (!name) {
      return '';
    }

    return name.split(' ').map(str => str.substr(0, 1)).join('').substr(0, 2);
  }

  /**
   * Gets a background color based on the initials so it's always the same
   */
  private getBackgroundColor(initials: string): string {
    if (initials === '' || initials === null) {
      return `#000000`;
    }

    let hash = 0;
    if (initials.length === 0) return '#000000';
    for (let i = 0; i < initials.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = initials.charCodeAt(i) + ((hash << 5) - hash);
        // eslint-disable-next-line no-bitwise
        hash = hash & hash;
    }
    hash = ((hash % BACKGROUND_COLORS.length) + BACKGROUND_COLORS.length) % BACKGROUND_COLORS.length;
    return BACKGROUND_COLORS[hash];
  }

}
