import { Injectable } from '@angular/core';

import { Guid } from '@eva-core/GUID/guid';
import { Observable } from 'rxjs';



@Injectable()
export class WorkflowBuilderService {

  constructor() { }

  public getInteractionsByGroup(groupName: string) {
    return null;
  }
}
