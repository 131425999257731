<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="submitToBlock" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Changes</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div #containerGraph class="container-graph">
      <app-workflow-graph-visualizer
        [containerGraph]="containerGraph"
        [workflow]="workflow"
        [interactionsByGroups]="interactionsByGroups"
      ></app-workflow-graph-visualizer>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="!submitToBlock" color="primary">Submit</button>
    <button mat-stroked-button [mat-dialog-close]="submitToBlock">Cancel</button>
  </mat-dialog-actions>
</div>