import { InteractionEmitValueModel } from "@eva-model/InteractionEmitValueModel";
import { InteractionFlatModel } from "@eva-model/interactionFlatModel";

export class InteractionAndElementEmitModel {

    constructor(
        public interactionFlatValues: InteractionFlatModel,
        public interactionLastElementValueChange: InteractionEmitValueModel,
        public processId?: string,
        public isPreview?: boolean,
        public tabIndex?: number
    ) {  }

}

export interface FormControlValidAnnouncement {
    valid: boolean;
    errors: any;
    type: string;
    inputType: string;
    hint: string;
}
