<!-- font awesome code pen example for svg codes:
https://codepen.io/fontawesome/pen/mZOqKp
-->
<nav class="navbar" [class.expand-navbar]="(menuNavigationService.isExpanded$ | async)" [class.minimized-full-chat]="(chatService.isChatMinimized$ | async)">
  <!-- logo -->
  <ul class="navbar-nav">
    <li class="logo">
      <a routerLink="/" class="nav-link" (click)="resetMainMenu()">
        <span class="link-text logo-text">EVA</span>
        <svg *ngIf="!appReload" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"/>
          <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
        </svg>
        <mat-icon (click)="applicationReload()" class="app-reload" *ngIf="appReload" [matTooltip]="reloadMessage" color="primary">refresh</mat-icon>
      </a>
    </li>
  </ul>

  <!-- This is the main menu -->
  <div class="menu-items-container">
    <!-- <div style="flex: 1 0 0; position: relative;" [@slideListLeft]="menuItems.length && currentMenuName !== 'main'" [@slideListRight]="menuItems.length && currentMenuName === 'main'"> -->
    <div style="flex: 1 0 0; position: relative;">
      <ul class="navbar-nav" *ngFor="let menu of menus" [@menuMovement]="currentMenuName === 'main' ? 'right' : 'left'" [class.minimized-full-chat]="(chatService.isChatMinimized$ | async)">
        <li class="nav-item" [class.nav-item-active]="isActiveRoute(menuItem.routerLink)" *ngFor="let menuItem of menu" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
          [matTooltip]="menuItem.text" matTooltipPosition="right" matTooltipHideDelay="100" [attr.id]="menuItem.id ? menuItem.id : null">
          <a *ngIf="menuItem.isBackButton" (click)="resetMainMenu()" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.routerLink" [routerLink]="menuItem.routerLink" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
              <span *ngIf="isValidBadgeCount(menuItem)" class="nav-link-badge">{{ getBadgeCount(menuItem) }}</span>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.isEmptyRoute" (click)="updateMenu(menuItem.emptyRouteName)" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
              <span *ngIf="isValidBadgeCount(menuItem)" class="nav-link-badge">{{ getBadgeCount(menuItem) }}</span>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.isLogout" (click)="logoutUser()" class="nav-link">
            <div *ngIf="menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item launch-pad" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="Notifications" matTooltipPosition="right" matTooltipHideDelay="100">
        <!-- <a [routerLink]="'/notifications'" class="nav-link"> -->
        <a (click)="openNotifications()" class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fix-width" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
          </svg>
          <span *ngIf="notificationsService.notifications.length > 0 || notificationsService.invites.length > 0 || notificationsService.requests.length > 0" class="nav-link-badge">{{ notificationsService.notifications.length + notificationsService.invites.length + notificationsService.requests.length }}</span>
          <span class="link-text">Notifications</span>
        </a>
      </li>
      <li class="nav-item launch-pad launch-pad-button" (click)="clickLaunchPad()" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="Launchpad" matTooltipPosition="right" matTooltipHideDelay="100">
        <a class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fix-width" viewBox="0 0 16 16">
            <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
          </svg>
          <span class="link-text">Launchpad</span>
        </a>
      </li>
      <li class="nav-item launch-pad launch-pad-button" (click)="openFeedback()" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="EVA Feedback" matTooltipPosition="right" matTooltipHideDelay="100">
        <a class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" class="fix-width" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
            <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
          </svg>
          <span class="link-text">EVA Feedback</span>
        </a>
      </li>
      <li class="nav-item" #tooltip="matTooltip" *ngIf="userData$ | async as user" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        [matTooltip]="user.name" matTooltipPosition="right" matTooltipHideDelay="100">
        <a (click)="this.updateMenu('user')" class="nav-link">
          <app-user-avatar size="32px" [name]="user.name" [photo]="user.photoURL"></app-user-avatar>
          <span class="link-text">{{user.name}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div (click)="expandMenu()" class="expand-button" [class.expanded]="(menuNavigationService.isExpanded$ | async)" matTooltip="Expand Menu" matTooltipPosition="right" matTooltipHideDelay="100" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)">
    <i class="fa fa-chevron-right expand-icon" aria-hidden="true"></i>
  </div>
</nav>
