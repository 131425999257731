/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GoogleInterceptor } from '@eva-services/interceptors/google-interceptor';
import { EVAInterceptor } from '@eva-services/interceptors/eva-connected-interceptors';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: GoogleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EVAInterceptor, multi: true }
];
