import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@eva-core/auth.service';
import { InvitationService } from '@eva-services/invitation/invitation.service';
import { NotificationsService } from '@eva-services/notifications/notifications.service';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { LoggingService } from '@eva-core/logging.service';
import { Invitation } from '@eva-model/invitation';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { WorkflowNotificationsService } from '@eva-services/notifications/workflow-notifications.service';
import { MatDialog } from '@angular/material/dialog';
import {
  DynamicInputModel,
} from '@ng-dynamic-forms/core';
import { take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { UserService } from '@eva-services/user/user.service';
import { ProcessService } from '@eva-services/process/process.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LogService } from '@eva-core/log/log.service';
import { EntityType, LogLevel, Log } from '@eva-model/log';
import { CreateNotificationsService } from '@eva-services/notifications/create-notifications.service';
import {MessageService} from 'primeng/api';
import { GroupType } from '@eva-model/group';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';

export enum notificationActionType {
  Action = 1,
  Accept,
  View
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [MessageService]
})
export class NotificationsComponent implements OnInit {

  user;
  groupWorkflowNotifications$: Observable<any>;
  groupNotifications = [];

  isWaitingForActionProcess: boolean;
  isWaitingForAcceptProcess: boolean;
  isWaitingForEndedProcess: boolean;
  onWaitForPrcsMsg: string;

  isWaitingForDeleteAllNotifications: boolean;
  onWaitForDeleteAllNotificationsMsg: string;
  componentTitle: string;
  groupTypes = new GroupType;

  constructor(
    private _router: Router,
    private _inviteService: InvitationService,
    private afAuth: AngularFireAuth,
    private _authService: AuthService,
    public notificationsService: NotificationsService,
    private _groupProviderService: GroupProviderService,
    private _ls: LoggingService,
    public dialog: MatDialog,
    private generalDialogService: GeneralDialogService,
    public workflowNotificationService: WorkflowNotificationsService,
    public evaGlobalService: EvaGlobalService,
    private prcsService: ProcessService,
    private userService: UserService,
    private logService: LogService,
    private _createNotificationService: CreateNotificationsService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private multiViewService: MultiViewService) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  ngOnInit() {
    this._authService.user.pipe(take(1)).subscribe(user => {
      if (user) {
        this.user = user;
        this.workflowNotificationService.getWorkflowNotifications();
      }
    });
    // console.log(this.multiViewService.tabs);
    // this.setupGroupNotification();
  }

  async deleteNotification(notification: any) {
    await this.notificationsService.deleteNotification(notification.id);
  }

  deleteAllNotifications() {
    this.isWaitingForDeleteAllNotifications = true;
    this.onWaitForDeleteAllNotificationsMsg = "Delete all notifications, please wait.";

    this.notificationsService.deleteAllNotifications()
    .then( () => {
      this.isWaitingForDeleteAllNotifications = false;
    })
    .catch( err => {
      this.isWaitingForDeleteAllNotifications = false;
      console.log(err);
    });
  }

  acceptInvitation(invite: any) {
    const acceptInvite = new Invitation(invite);

    invite.sending = true;

    this._inviteService.setupAcceptanceNew(acceptInvite, invite.id)
      .then(() => {
        invite.sending = false;
        this._ls.logMessage(`You have accepted the invitation.`, false, 'success');

      }).catch(error => {
        invite.sending = false;
        console.log(error);

        this._ls.logMessage('Problem accepting your invitation. Please try again by refreshing the page.', false, 'error');
      });
  }

  declineInvitation(invite: any) {
    const callbackData = {};
    const callback = {};
    invite.sending = true;
    const response = new DynamicInputModel({
      id: 'reason',
      label: 'Reason for declining this invitation:',
      placeholder: null,
      value: null
    });

    const responseCollection = [response];
    const dialogData = new GeneralDialogModel(
      'Are you sure you want to decline this invitation?',
      null,
      'OK', 'Cancel Decline', responseCollection
    );

    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogData,
      disableClose: true
    });

    this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['reason'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let reason = null;
        if (callback) {
          reason = callbackData['reason'].reason;
        }

        this._inviteService.declineInvitation(this.user.email, invite.id).toPromise()
        .then(() => {
          invite.sending = false;
          this._ls.logMessage(`You've successfully declined ${invite.inviterName}'s group.
           We will let them know.`, false, 'success');

          const message = invite.recipientName
            + ' declined your invitation to '
            + invite.invitationName + ' for the following reason: ' + reason;

          this._createNotificationService.createNotificationWithEmail(invite.inviterEmail, message)
          .then(notification => {
            console.log(notification);
          });
        }).catch(() => {
          invite.sending = false;
          this._ls.logMessage('Problem declining your invitation. Please try again by refreshing the page.', false, 'error');
        });
      }
    });
  }

  acceptRequest(request: any) {
    const acceptRequest = request;
    request.sending = true;
    const keys = [acceptRequest.requesterPublicKey];
    this._groupProviderService.addGroupMember(keys, request.groupInvitedTo, request.groupName, request.groupType, acceptRequest)
    .then( () => {
      this._groupProviderService.deleteRequest(acceptRequest).toPromise()
      .then( () => {
        request.sending = false;
        const requestIndex = this.notificationsService.requests.findIndex(serviceRequest => serviceRequest.id === request.id);
        if (requestIndex !== -1) {
          this.notificationsService.requests.splice(requestIndex, 1);
        }
        this._ls.logMessage('Request accepted', false, 'success');
      });
    }).catch(err => {
      request.sending = false;
      console.log(err);
      this._ls.logMessage('Error accepting request', false, 'error');
    });
  }

  declineRequest(request: any) {

    const callbackData = {};
    const callback = {};
    request.sending = true;
    const response = new DynamicInputModel({
      id: 'reason',
      label: 'Reason for declining this request:',
      placeholder: null,
      value: null
    });
    const responseCollection = [response];
    const dialogData = new GeneralDialogModel(
      'Are you sure you want to decline this request?',
      null,
      'OK', 'Cancel Decline', responseCollection
    );

    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogData,
      disableClose: true
    });

    this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['reason'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let reason = null;
        if (callback) {
          reason = callbackData['reason'].reason;
        }

        this._groupProviderService.deleteRequest(request).toPromise()
        .then(() => {
            request.sending = false;
            this._ls.logMessage('Request deleted, we will let the requester know.', false, 'success');
            const requestIndex = this.notificationsService.requests.findIndex(serviceRequest => serviceRequest.id === request.id);
            if (requestIndex !== -1) {
              this.notificationsService.requests.splice(requestIndex, 1);
            }

            const message = 'Your request to join '
              + request.groupName + ' was declined for the following reason: ' + reason;

            this._createNotificationService.createNotificationWithEmail(request.requesterEmail, message).then(notification => {
              console.log(notification);
            });

          }).catch(error => {
            request.sending = false;
            console.log(error);
            this._ls.logMessage('Error deleting request.', false, 'error');
          });
      }
    });
  }

  /**
   * gotToView - function for navigating to a url provided by a notification
   *
   * @param  {type} notification: any the notification that has the url
   * @return {type}                   void
   */
  gotToView(notification: any) {
    if (notification.url !== undefined) {
      this._router.navigate([notification.url]);
    }
  }

  //#region Process related notifications
  acknowledgeProcess(notification: any) {
    notification.sending = true;
    this.isWaitingForAcceptProcess = true;
    this.onWaitForPrcsMsg = `Taking 'Accept' on process ${notification.processId}`;

    this.workflowNotificationService.acknowledgeProcess(notification.destinationPublicKey, notification.processId)
    .then(() => {
      this.workflowNotificationService.dropNotificationResult(notification);
      notification.sending = false;
      this.isWaitingForAcceptProcess = false;
    })
    .catch(err => {
      notification.sending = false;
      this.isWaitingForAcceptProcess = false;

      console.log(err);
    });
  }

  async notificationProcess(notification: any, type: notificationActionType) {   // Moves the notification to process
    const that = this;

    notification.sending = true;
    this.waitingForProcess( type, true );
    this.onWaitForPrcsMsg = `Try to fetch the ${notification.processId} process`;
    notification.sending = false;
    this.waitingForProcess( type, false );
    this._router.navigate([Routes.Process, notification.processId ]);
    // TODO: fetch from last state processes collection here
    // const userProcess = await that.userService.fetchUserProcess(notification.processId);

    // userProcess
    // .pipe(
    //   take(1)
    // )
    // .subscribe(
    //   (userPrcsData: any) => {
    //     if ( userPrcsData && userPrcsData.processSerialized ) {
    //       notification.sending = false;
    //       this.waitingForProcess( type, false );
    //       this._router.navigate([Routes.Process, notification.processId ]);
    //     } else {
    //       this.prcsService.fetchProcess(notification.processId)
    //       .subscribe(
    //         (processData) => {
    //           // need to get the process values from the encrypted servers.
    //           const process = that.prcsService.processObjectMapper(processData);

    //           //#region Validate the fetch process
    //           if (! (process && process.id &&
    //             process.groupPublicKey && process.createTimestamp &&
    //             (process.acknowledgerPublicKey || type === notificationActionType.View ) &&
    //             process.workflows && Array.isArray(process.workflows) && process.workflows.length > 0)) {

    //               notification.sending = false;
    //               this.waitingForProcess( type, false );

    //             this.messageService.clear();
    //               this.messageService.add({
    //                 life: 5000,
    //                 severity: 'error',
    //                 summary: `Couldn't validate the fetched process ${notification.processId}`,
    //                 detail: ``
    //               });

    //               let errMsg = `${notification.processId} :: `;
    //               if ( ! (process && process.id) ) {
    //                 errMsg += 'process fetch issue.';
    //               } else if ( !process.acknowledgerPublicKey && type !== notificationActionType.View ) {
    //                 errMsg += 'process acknowledger missed.';
    //               } else if ( !(process.workflows && Array.isArray(process.workflows) && process.workflows.length > 0) ) {
    //                 errMsg += 'process workflow issue.';
    //               }

    //               const log = new Log(LogLevel.Error, EntityType.process, process.id, errMsg, this.evaGlobalService.userId);
    //               this.logService.error(log).then( () => {} ).catch( (e) => console.log(e));

    //               return;
    //           }
    //           //#endregion

    //           that.userService.userProcessUpsert(process, false)
    //           .then( () => {
    //             if ( notification !== "{}" && notification.processId ) {
    //               notification.sending = false;
    //               this.waitingForProcess( type, false );
    //               this._router.navigate([Routes.Process, notification.processId ]);
    //             }
    //           })
    //           .catch( err => {
    //             console.log( err );
    //             notification.sending = false;
    //             this.waitingForProcess( type, false );
    //           });
    //         },
    //         (err) => {
    //           console.log(err);
    //           notification.sending = false;
    //           this.waitingForProcess( type, false );
    //         }
    //       );
    //     }
    //   },
    //   ( err ) => {
    //     console.log(err);
    //     notification.sending = false;
    //     this.waitingForProcess( type, false );
    //   }
    // );
  }

  waitingForProcess(type: notificationActionType, value: boolean) {
    if ( type === notificationActionType.Action ) this.isWaitingForActionProcess = value;
    else if ( type === notificationActionType.Accept ) this.isWaitingForAcceptProcess = value;
    else if ( type === notificationActionType.View ) this.isWaitingForEndedProcess = value;
  }
  
  /**
   * This is used to action a process in the system
   *
   * @param notification the process to action.
   */
  async actionProcess(notification: any) { // Moves the notification to process
    await this.notificationProcess(notification, notificationActionType.Action );
  }

  /**
   * This will view a process that is currently assigned to the user.
   *
   * @param notification the users ended process.
   */
  async viewProcess(notification: any) {
    await this.notificationProcess(notification, notificationActionType.View );
  }

  async doneProcess(notification: any) {
    // TODO :: Needs to clean up the process from the user too
    if ( !( notification && notification.processId ) ) return;

    notification.sending = true;
    this.isWaitingForEndedProcess = true;
    this.onWaitForPrcsMsg = `Taking 'Done' on process ${notification.processId}`;
    // fetch process object to get submitter key to check if a notification email needs to be sent

    this.prcsService.fetchProcess(notification.processId)
      .pipe(take(1))
      .subscribe( process => {
    this.workflowNotificationService.doneProcess(notification.processId, process)
    .then(() => {
      // TODO: remove from last state processes collection here
      // return this.userService.userProcessDelete(notification.processId);
    })
    .then( () => {
      notification.sending = false;
      this.isWaitingForEndedProcess = false;
    })
    .catch(err => {
      notification.sending = false;
      this.isWaitingForEndedProcess = false;

      console.log(err);
    });
  });
  }

  getNameByPublicKey(publicKey: string) {
    return this.evaGlobalService.getGroupNameByPublicKey(publicKey) !== 'ATB'
    ? this.evaGlobalService.getGroupNameByPublicKey(publicKey)
    : this.userService.userPreferences$
      ? this.userService.userPreferences$
      : '';
  }
  //#endregion

  rejectWorkflow(workflow: any) {

    const callbackData = {};
    const callback = {};
    workflow.sending = true;
    const response = new DynamicInputModel({
      id: 'reason',
      label: 'Reason for rejecting this workflow:',
      placeholder: null,
      value: null
    });
    const responseCollection = [response];
    const dialogData = new GeneralDialogModel(
      'Are you sure you want to reject this workflow?',
      null,
      'OK', 'Cancel Rejection', responseCollection
    );

    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogData,
      disableClose: true
    });

    this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['reason'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      // console.log('hello');
      workflow.sending = false;

      if (result === true) {
        let reason = null;
        if (callback) {
          reason = callbackData['reason'].reason;
        }

        //#region Remarked
        // this._workflowService.rejectWorkflow(workflow)
        //   .toPromise().then((deleted) => {
        //     request.sending = false;
        //     this._ls.logMessage('Request deleted, we will let the requester know.', false, 'success');
        //
        //     const message = 'Your request to join '
        //       + request.groupName + ' was declined for the following reason: ' + reason;
        //
        //     this._createNotificationService.createNotificationWithEmail(request.requesterEmail, message).then(notification => {
        //       console.log(notification);
        //     });
        //
        //   }).catch(error => {
        //     request.sending = false;
        //     console.log(error);
        //     this._ls.logMessage('Error deleting request.', false, 'error');
        //   });
        //#endregion
      }
    });
  }

  jsonStringify(jsonObj: any): string {
    return JSON.stringify(jsonObj);
  }

  isEmptyObj(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
}
