import { EVASVGIcon } from "./defaults/icons";

/**
 * These interfaces are used to generate menu items in the EVA application
 */
export interface EVAMenu {
    routerLink?: string; // the routerLink to allow data movement for the application.
    isEmptyRoute?: boolean; // whether the current menu item redirects to any component or not (used for menu updates only)
    isBackButton?: boolean; // button on click is treated different if the menu item is a back button
    isLogout?: boolean; // whether logout link or not
    emptyRouteName?: string; // if isEmptyRoute is true, provide this name to load appropriate menu items on click
    svgIcon?: EVASVGIcon; // svg icon to use
    badgeCount?: number | string; // a badge count to display on the menu
    text: string; // the text of the menu item.
    description?: string; // an optional description to include in the menu
    id?: string; // id attr to add to menu item element when rendered.
}

export interface LaunchPadItem {
    id: string; // random string id to track the menu item
    groupId?: string; // the group the item belongs to
}

/**
 * Used with the launchpad. Difference is the launchpad can be reordered.
 */
export interface LaunchPadMenuItem extends LaunchPadItem {
    type: LaunchPadEntityType; // differentiates what prop `data` will contain and how item is actioned
    // data: EVAMenu; // relates to the prop `type` of LaunchPadMenuItem
    data: string; // stringified data for this type
    title: string; // display title
    icon: EVASVGIcon;
    subTitle?: string; // description or other text
    pinned?: boolean; // whether the item is pinned
}

export interface LaunchPadMenuItemGroup {
    id: string;
    title: string;
    items: LaunchPadMenuItem[];
}

export interface LaunchPadMenuItemCustomOrder {
    id: string;
    index: number;
}

export interface LaunchPadLocalItem extends LaunchPadItem {
    order: number; // index-based ordering
}

export enum LaunchPadEntityType {
    Route,
    Knowledge,
    Workflow,
    Interaction
}

export interface ILaunchPad {
    pinnedItems?: LaunchPadLocalItem[];
    reorderedItems?: LaunchPadLocalItem[];
    userItems?: LaunchPadMenuItem[];
}
