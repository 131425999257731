import { Pipe, PipeTransform } from '@angular/core';
import { DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT, DYNAMIC_FORM_CONTROL_TYPE_INPUT } from '@ng-dynamic-forms/core';

@Pipe({
  name: 'getNumberCandidateElements'
})
export class GetNumberCandidateElementsPipe implements PipeTransform {

  transform(control: any, screens: any[]): any[] {
    const elements = [];
    screens.forEach(screen =>
      screen.FormElements.forEach(element => {
        if (element.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
          && (element.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER
            || element.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT)
          && !control.originalId.includes(element.originalId)) {
          elements.push(element);
        }
      })
    );
    return elements;
  }

}
