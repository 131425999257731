import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  styleUrls: ['./spinner.component.css'],
  // ng-content allows us to place anything between the app-spinner element tags
  // so we can add messages or videos or images if we want
  template: `
    <div class="app-loading" *ngIf="show">
      <mat-spinner></mat-spinner>
      <ng-content></ng-content>
    </div>
  `
})
export class SpinnerComponent implements OnInit, OnDestroy {
  constructor(private spinnerService: SpinnerService) { }

  @Input() name: string;
  @Input() group: string;

  private isShowing = false;

  @Input()
  get show(): boolean {
    return this.isShowing;
  }

  @Output() showChange = new EventEmitter();

  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  ngOnInit(): void {
    if (!this.name) {
      throw new Error(`Spinner must have a 'name' attribute.`);
    }
    this.spinnerService._register(this);
  }

  ngOnDestroy(): void {
    this.spinnerService._unregister(this);
  }
}
