
import * as crypto from 'crypto';
export class Utils {
  /**
   * can polyfill with
   * https://github.com/inexorabletash/text-encoding
   */
  static RANDOM_KEY_FOR_ENCRYPTION_BYTE_SIZE = 32;
  static SIGNING_VERIFICATION_ALGORITHMS = [
    'RSASSA-PKCS1-v1_5',
    'RSA-PSS',
    'ECDSA',
    'HMAC'
  ];
  static DIGEST_ALGORITHMS = [
    'SHA-1',
    'SHA-256',
    'SHA-384',
    'SHA-512'
  ];
  static DERIVE_KEY_BITS_ALGORITHMS = [
    'ECDH',
    'HKDF',
    'PBKDF2'
  ];
  static ENCRYPT_DECRYPT_ALGORITHMS = [
    'RSA-OAEP',
    'AES-CTR',
    'AES-CBC',
    'AES-GCM'
  ];
  static GENERATE_KEY_ALGORITHMS = [
    'RSASSA-PKCS1-v1_5',
    'RSA-PSS',
    'RSA-OAEP',
    'ECDSA',
    'ECDH',
    'AES-CTR',
    'AES-CBC',
    'AES-GCM',
    'AES-KW',
    'HMAC'
  ];
  static IMPORT_KEY_ALGORITHMS = [
    'RSASSA-PKCS1-v1_5',
    'RSA-PSS',
    'RSA-OAEP',
    'ECDSA',
    'ECDH',
    'AES-CTR',
    'AES-CBC',
    'AES-GCM',
    'AES-KW',
    'HMAC',
    'HKDF',
    'PBKDF2'
  ];
  static EXPORT_KEY_ALGORITHMS = [
    'RSASSA-PKCS1-v1_5',
    'RSA-PSS',
    'RSA-OAEP',
    'ECDSA',
    'ECDH',
    'AES-CTR',
    'AES-CBC',
    'AES-GCM',
    'AES-KW',
    'HMAC'
  ];
  static WRAP_KEY_ALGORITHMS = [
    'RSA-OAEP',
    'AES-CTR',
    'AES-CBC',
    'AES-GCM',
    'AES-KW'
  ];
  // static getArrayFromString(str: string) {
  //   return new TextEncoder('utf-8').encode(str);
  // }

  /** static getRandomValues(): ArrayBuffer {
      const array = new Int32Array(16);
      window.crypto.getRandomValues(array);
      return array.buffer;
  }**/
  static throwError(err) {
    throw new Error(err);
  }

  static convertStringToArrayBufferView(str): ArrayBuffer {
    const buf = new ArrayBuffer(str.length * 2);
    const bufView = new Uint16Array(buf);
    for (let i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }


  static convertArrayBufferViewToString(buffer: ArrayBuffer): string {
    let str = '';
    for (let i = 0; i < buffer.byteLength; i++) {
      str += String.fromCharCode(buffer[i]);
    }

    return str;
  }

  /**
   * this creates random bytes for an encryption key.
  */
  static createRandomKeyBytesForEncryption() {
    return crypto.randomBytes(Utils.RANDOM_KEY_FOR_ENCRYPTION_BYTE_SIZE);
  }

  static convertObjectToArrayBufferView(obj: Object): ArrayBuffer {
    return Utils.convertStringToArrayBufferView(JSON.stringify(obj));
  }

  static convertArrayBufferViewToObject(buffer: ArrayBuffer): Object {
    const stringOfBuffer = Utils.convertArrayBufferViewToString(buffer);

    return JSON.parse(stringOfBuffer);
  }

  static convertHextoArrayBufferView(hex: string): ArrayBuffer {
    const arr = [];
    for (let i = 0, len = hex.length; i < len; i += 2) {
      arr.push(parseInt(hex.substr(i, 2), 16));
    }
    return new Uint8Array(arr).buffer as ArrayBuffer;
  }

  static convertArrayBufferViewToHex(buffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
      .map(x => ('00' + x.toString(16)).slice(-2))
      .join('');
  }
  /**
   * Create the random initialization vector for the system.
   */
  static createRandomIv() {
    return Buffer.from(crypto.randomBytes(16));
  }

  static base64EncodeUrl(int8Array: any) {
    const base64data = Buffer.from(String.fromCharCode.apply(null, int8Array), 'ascii').toString('base64');
    return base64data.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
  }

  static convertHexToArray(hex: string) {
    const arr = [];
    for (let i = 0, len = hex.length; i < len; i += 2) {
        arr.push(parseInt(hex.substr(i, 2), 16));
    }
    return arr;
  }

}
