export const EVAThemes = [
  {
    id: "eva-light",
    displayName: "EVA - Light",
    type: 'light'
  },
  {
    id: "eva-dark",
    displayName: "EVA - Dark (Beta)",
    type: 'dark'
  }
];

export interface EVATheme {
    id: string;
    displayName: string;
    type: string;
}