import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject, SubscriptionLike as ISubscription } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { EvaChatWorkflowsService } from '@eva-services/eva-chat-workflow/eva-chat-workflows-service.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { ChatWorkflowSubGroup, WorkFlow } from '@eva-model/workflow';
import { environment } from '@environments/environment';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { sortBy, words } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { PropertyMapping, ValueModifier } from '@eva-model/search';
import algoliasearch from 'algoliasearch';
import { GenericSearchFilter } from '@eva-model/generic-search';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { InstantSearchService } from '@eva-services/search/instant-search.service';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';
import { LaunchPadEntityType } from '@eva-model/menu/menu';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { LoggingService } from '@eva-core/logging.service';
import { SearchUtilsService } from '@eva-services/utils/search-utils.service';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';

@Component({
  selector: 'app-eva-chat-workflows',
  templateUrl: './eva-chat-workflows.component.html',
  styleUrls: ['./eva-chat-workflows.component.scss']
})
export class EvaChatWorkflowsComponent implements OnInit, AfterViewInit, OnDestroy {

  user: any;

  // for getting workflows
  groupCol: ChatWorkflowSubGroup[] = [];
  groupCol$: ChatWorkflowSubGroup[] = [];
  entities: any[] = [];
  workflowFilterFormControl: UntypedFormControl = new UntypedFormControl();

  onWaitForWorkflowMessage = 'Fetching your processes'; // Message displayed when loading workflows
  isWaitingForGroupWorkflows = true;                    // whether waiting for group workflows or not
  // https://github.com/angular/components/issues/13870
  disableAnimation = true;

  // Workaround for angular component issue #13870
  userIsMemberOfGlobal = false;
  private groupNameMap: Map<string, string> = new Map<string, string>();
  private searchFilterText = '';
  componentTitle: string;
  // for algolia search
  algoliaIndex = 'Workflows' + environment.algolia.indexEnding; // the Algolia index to query.
  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.PROCESS;
  // searchReady = false; // removed with UI changes.
  processPropertyNameMapping: PropertyMapping; // the property mapping for process config
  processFilters: GenericSearchFilter[] = []; // the interaction search filters.
  public defaultColDef: ColDef;                             // the default column definitions
  public rowHeight: number;                                 // the height of individual rows
  public columnDefs: (ColDef | ColGroupDef)[];              // stores definitions for each column


  context: any;

  state = {
    filters: [{defaultValue: []}],
    searchText: ''
  };

  private componentSubs: Subscription = new Subscription();
  routerParameterSub: ISubscription;

  constructor(
    private workflowData: EvaChatWorkflowsService,
    public evaGlobalService: EvaGlobalService,
    private route: ActivatedRoute,
    private multiViewService: MultiViewService,
    private valueModifierService: SearchValueModifierService,
    private instantSearchService: InstantSearchService,
    private launchPadService: LaunchPadService,
    private router: Router,
    private loggingService: LoggingService,
    private searchUtils: SearchUtilsService
  ) {
    this.setDefaultProcessSearchFilters(); // set the default search settings.
    this.route.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
  }

  async ngOnInit() {
    this.instantSearchService.clearSearchConfig(this.algoliaIndex); // clear the search config for the index.
    this.setProcessPropertyNameMapping(); // set the default property names.
    this.setDefaultColumnDefinitions(); // set the default column definitions.

    this.rowHeight = 48; // default row height.
    this.context = { componentParent: this };

    this.setColumnDefinitions(); // set the search column definitions.

    this.userIsMemberOfGlobal = this.isUserMemberOfGlobal();

    this.routerParameterSub =
      this.route.params.pipe(
        take(1)
      ).subscribe(
        (params) => {
          let processId = '';
          if (params['id']) {
            processId = params['id'];
          }

          if (processId ) {
            this.router.navigate([Routes.Process]).then(value => {
              this.multiViewService.setCreateNewTab({
                component: ProcessComponent,
                tabName: 'Loading...',
                additionalInstanceData: {
                  processId: processId
                }
              });
            });
          }
        }
      );

    this.componentSubs.add(
      this.getSubscriptionIfWorkFlowsAreStillLoading()
    );
    this.componentSubs.add(
      this.searchFilterSubscription()
    );
  }

  /**
   * This cleans up all subscriptions added to the component.
   */
  ngOnDestroy(): void {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  // https://github.com/angular/components/issues/13870
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  /**
   * Opens a workflow and closes the side panel
   *
   * @param workflow - clicked/selected workflow
   */
  workflowSelected = (workflow: WorkFlow): void => {
    this.workflowData.sendData(workflow);
    // this.dialogRef.close();
  }

  onSelectionChange(event: any, hit: any, property: string) {
    hit[this.processPropertyNameMapping.properties.find(prop => prop.propertyName === property).defaultValueProperty] = event.value;
  }

  /**
   * Subscription to capture whether the workflows are still loading
   * {@link evaGlobalService.fetchActiveWorkflowsInGroupsNotifier} emits every time a group with workflows is retrieved and stores the last
   * value to replay.  This subject also sends {@link ActiveWorkflowsInGroups.isNotComplete} if more workflows are expected.
   */
  private getSubscriptionIfWorkFlowsAreStillLoading(): Subscription {
    return this.evaGlobalService.fetchActiveWorkflowsInGroupsNotifier.subscribe(value => {
      this.groupCol = this.getSortedWorkflows(value.activeWorkflowInGroups);
      if (this.searchFilterText !== '') {
        this.groupCol$ = this.filterAndGetProcessGroups(this.searchFilterText);
      } else {
        this.groupCol$ = this.groupCol;
      }
      this.isWaitingForGroupWorkflows = value.isNotComplete;
    });
  }

  /**
   *
   * Returns the list of workflow groups sorted by workflowPublicKey name and workflowSubGroup
   *
   * @param chatWorkflowSubGroups[] unsorted array
   * @return ChatWorkflowSubGroup[] workflows that have been fetched
   */
  private getSortedWorkflows(chatWorkflowSubGroups: ChatWorkflowSubGroup[]): ChatWorkflowSubGroup[] {
    return sortBy(chatWorkflowSubGroups, [
      group => this.evaGlobalService.getGroupNameByPublicKey(group['workflowPublicKey']).toLowerCase()
        + group['workflowSubGroup'].toLowerCase()
    ]);

  }

  /**
   * Will return true if any {@this ChatWorkflowSubGroup} in {@this groupCol} contains a {@this ChatWorkflowSubGroup.workflowPublicKey}
   * strictly equal to {Link environment.atbGlobalGroup}.
   * @return {boolean}
   */
  private isUserMemberOfGlobal(): boolean {

    return !!this.groupCol.find(group => group && group.workflowPublicKey === environment.atbGlobalGroup);
  }

  /**
   * Return a {@link Subscription} that responds to key entry events emitted by search input if:
   * 1. 400 milliseconds have passed since last input
   * 2. the value is not null (if the value is null reset to workflow to original list {@this groupCol})
   * 3. the value is different than previous input
   * @private
   * @return {Subscription} a {@link Subscription} that responds to key entry events emitted by search input
   */
  private searchFilterSubscription(): Subscription {
    return this.workflowFilterFormControl.valueChanges.pipe(
      debounceTime(400),
      map(value => String(value).trim()),
      filter(value => {
        const validSearch = (value) && value.length > 0;
        if (!validSearch) {// reset process menu list to full unfiltered list
          this.groupCol$ = this.groupCol;
        }
        return validSearch;
      }),
      distinctUntilChanged(),
      tap(searchFilterText => this.searchFilterText = searchFilterText),
      map(searchFilterText => this.filterAndGetProcessGroups(searchFilterText))
    ).subscribe(
      value => this.groupCol$ = value,
      error => console.error(error)
    );
  }

  /**
   * @private
   * Returns a {@link ChatWorkflowSubGroup} [] filtered subset of processes
   * {@link groupCol} based on search criteria {@param filterText} including:
   * * {@link ChatWorkflowSubGroup.workflowPublicKey} groups whose name contains filterText or all search words in no particular order
   * * {@link ChatWorkflowSubGroup.workflowSubGroup} sub groups whose name contains filterText or all search words in no particular order
   * * {@link WorkFlow} within {@link ChatWorkflowSubGroup.workflowArray} whose name contains filterText or all search words
   * in no particular order
   * @param {string} filterText filter criteria
   * @return {ChatWorkflowSubGroup[] } filtered array ChatWorkflowSubGroup[]
   */
  private filterAndGetProcessGroups(filterText: string): ChatWorkflowSubGroup[] {

    const searchText = filterText.toLowerCase();
    const searchWords = words(searchText); // do this once and pass around with searchText
    const processGroups = [];

    this.groupCol.forEach(chatWorkflowSubGroup => {
      this.populateGroupNameMap(chatWorkflowSubGroup);
      const workflowPublicKey = chatWorkflowSubGroup.workflowPublicKey;
      if (this.doesFieldContainSearch(this.groupNameMap.get(workflowPublicKey).toLowerCase(), searchText, searchWords)) {
        processGroups.push(chatWorkflowSubGroup);
      } else if (this.doesFieldContainSearch(chatWorkflowSubGroup.workflowSubGroup.toLowerCase(), searchText, searchWords)) {
        processGroups.push(chatWorkflowSubGroup);
      } else {// search child workflows names that match search filter text
        const workflows: WorkFlow[] = chatWorkflowSubGroup.workflowArray.filter(workflow => {
          return this.doesFieldContainSearch(
            `${workflow.name.toLowerCase()} ${this.groupNameMap.get(workflowPublicKey).toLowerCase()}` +
            ` ${chatWorkflowSubGroup.workflowSubGroup.toLowerCase()}`,
            searchText,
            searchWords);
        });
        if (workflows && workflows.length > 0) {
          processGroups.push(
            {
              workflowArray: workflows,
              workflowPublicKey: chatWorkflowSubGroup.workflowPublicKey,
              workflowSubGroup: chatWorkflowSubGroup.workflowSubGroup
            });
        }
      }
    });
    return processGroups;
  }

  /**
   * Populate {@this groupNameMap} with {@link ChatWorkflowSubGroup.workflowPublicKey} -> groupName pairs and cache for future look ups
   * @param chatWorkflowSubGroup
   */
  private populateGroupNameMap(chatWorkflowSubGroup: ChatWorkflowSubGroup) {
    if (!this.groupNameMap.has(chatWorkflowSubGroup.workflowPublicKey)) {
      this.groupNameMap.set(
        chatWorkflowSubGroup.workflowPublicKey
        , this.evaGlobalService.getGroupNameByPublicKey(chatWorkflowSubGroup.workflowPublicKey)
      );
    }
  }

  /**
   * @private
   * Returns true if search field has exactly search text or all search text words in any order.
   * @param field to be searched for {@link searchText} or {@param searchWords}
   * @param searchText exact phrase to search for in the {@link field}
   * @param searchWords is each word in the {@link searchText} converted to an array, and passed in so that
   * it does not need to be created for each field being searched
   */
  private doesFieldContainSearch(field: string, searchText: string, searchWords: string[]): boolean {
    if (field && field !== '' && searchText && searchText !== '') {
      if (field.includes(searchText)) {
        return true;
      }
      const parsedStringToSearch = words(field);
      return searchWords.every(searchWord => {
        return parsedStringToSearch.includes(searchWord) || field.includes(searchWord);
      });
    }
    return false;
  }

  pinToLaunchPad = (event: MouseEvent, hit: WorkFlow): void => {
    event.stopPropagation();
    delete (<any>hit)._highlightResult;
    delete (<any>hit).Versions;
    this.launchPadService.addCustomItem(LaunchPadEntityType.Workflow, hit.name, {
      workflow: hit
    });
  }

  //#region Token Functions

//#region Setup Search Grid Items.

  /**
   * This sets the default communication search settings for the component.
   */
  private setDefaultProcessSearchFilters(): void {
    this.processFilters = [{
      filterName: ['Sub Group'],
      defaultValue: [null],
      attribute: 'subGroup',
      type: 'select',
      filterValues: ['Address Change', 'Customer Relief', 'Deceased & Estates', 'Deposit Account Servicing', 'Deposit Operations', 'Document Management', 'Investments', 'Loan Origination', 'Loan Servicing', 'Mastercard', 'Traces']
    }, {
      filterName: ['Activated'],
      defaultValue: [true],
      attribute: 'activated',
      hidden: true,
      type: 'select'
    }];
  }

  /**
   * This sets the column definitions for search.
   */
  private setColumnDefinitions(): void {
    this.columnDefs = [
      (this.searchUtils.getColumnDefinition('Name', 'name')),
      (this.searchUtils.getColumnDefinition('ID', 'id')),
      {...(this.searchUtils.getColumnDefinition('Group Name', 'groupPublicKey')),
      cellRenderer: (data) => {
        return this.valueModifierService.modifyValues(ValueModifier.GroupName, data.data.groupPublicKey) as string;
      }},
      (this.searchUtils.getColumnDefinition('Sub Group Name', 'subGroup')),
      {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'Versions')),
      cellRenderer: 'actionTemplateRenderer'}
    ];
  }

  /**
   * This sets the default column definitions.
   */
  private setDefaultColumnDefinitions(): void {
    this.defaultColDef = {
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      tooltipComponent: 'CustomTooltipComponent'
    };
  }

  /**
   * This sets the process property name mapping for search.
   */
  private setProcessPropertyNameMapping(): void {
    this.processPropertyNameMapping = {
      properties: [
        { propertyName: 'groupPublicKey', propertyUserFriendlyName: 'Group Name', valueModifiers: [ValueModifier.GroupName] },
        { propertyName: 'subGroup', propertyUserFriendlyName: 'Sub Group Name', optional: true }
      ]
    };
  }

//#endregion Setup Search Grid Items.

//#endregion Token Functions

}
