import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { AboutPageDocumentData } from '@eva-model/releaseNotes';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { map } from 'rxjs/operators';
import { AuthService } from '@eva-core/auth.service';
import { AddEditDocumentsComponent } from './add-edit-documents/add-edit-documents.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { LoggingService } from '@eva-core/logging.service';


@Component({
  selector: 'app-about-page-documents',
  templateUrl: './about-page-documents.component.html',
  styleUrls: ['./about-page-documents.component.scss']
})
export class AboutPageDocumentsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private generalDialogChangeSubs = new Subscription();

  displayedColumns: string[] = ['buttons', 'documentName', 'category', 'dateUploaded'];
  dataSource: MatTableDataSource<AboutPageDocumentData>;

  _aboutPageDocColPath = 'About/Documents/Documents';
  aboutPageDocCollection: AngularFirestoreCollection<AboutPageDocumentData>;
  aboutPageDocs$: Observable<AboutPageDocumentData[]>;
  aboutPageSubscription: Subscription;

  _isAdministrator = false;
  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  constructor(private firestoreService: FirestoreService,
    public authService: AuthService,
    private dialog: MatDialog,
    private ls: LoggingService) {
   }

  ngOnInit() {
    this.getAboutPageDocuments();
  }

  /**
   * Make sure we unsubscribe from the document.
   */
  ngOnDestroy() {
    if (this.aboutPageSubscription) {
      this.aboutPageSubscription.unsubscribe();
    }
  }
  /**
   * get the about page documents for displaying for the user.
   */
  getAboutPageDocuments() {
    this.aboutPageDocCollection = this.firestoreService.col(this._aboutPageDocColPath,
      ref => ref.orderBy('dateUploaded'));

    this.aboutPageDocs$ = this.firestoreService.colWithIds$(this.aboutPageDocCollection);

    this.aboutPageSubscription = this.aboutPageDocs$.subscribe((aboutDocs) => {
      this.dataSource = new MatTableDataSource<AboutPageDocumentData>(aboutDocs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  /**
   * This function adds an item to the datatable.
   */
  addDocument() {
    const title = 'New document';
    const confirmButtonText = 'Add';

    this.addEditDocument(title, confirmButtonText);
    // this.workflowActivationConfirm(title, conButtonText);
  }

  /**
   * This function allows an edit in the about document.
   */
  editDocument(document) {
    const title = 'Edit document';
    const confirmButtonText = 'Edit';

    this.addEditDocument(title, confirmButtonText, document);
    // console.log(document);

  }

  /**
   * this function will add or update a document depending on how it gets initialized.
   * @param title the title for the dialog
   * @param confirmButtonText the confirmation button descrition
   * @param data the data that is being passed to the add / edit dialog (undefined for an add)
   */
  addEditDocument(title: string, confirmButtonText: string, data?: string) {
    let dialogData = null;
    let dialogComponent = null;

    const content = '';
    const cancelButtonText = 'Cancel';

    dialogData = new GeneralDialogModel(
      title,
      content, confirmButtonText,
      cancelButtonText, null,
      {
        data: data
      }
    );

    dialogComponent = AddEditDocumentsComponent;
    const dialogRef: MatDialogRef<AddEditDocumentsComponent, AboutPageDocumentData> =
    this.dialog.open(dialogComponent, {
      data: dialogData,
      disableClose: true,
      minHeight: '400px',
      minWidth: '600px'
    });

    /**
     * Fires after this completes.
     */
    dialogRef.afterClosed().subscribe(async result => {
      // check for a result.
      if (result) {
        const doc: AboutPageDocumentData = {
          category: result.category,
          dateUploaded: result.dateUploaded,
          documentName: result.documentName,
          url: result.url
        };
        if (result.id) { // this is an edit.
          const updatePath = this._aboutPageDocColPath + `/${result.id}`;
          try {
            await this.firestoreService.upsert(updatePath, doc);
            await this.ls.logMessage('Successfully Edited Document', false, 'success');
          } catch (err) {
            await this.ls.logMessage('Error Editing Document', false, 'error', err);
          }

        } else { // this is an add.
          try {
            await this.firestoreService.add(this._aboutPageDocColPath, doc);
            await this.ls.logMessage('Successfully Added Document', false, 'success');
          } catch (err) {
            this.ls.logMessage('Error Adding Document', false, 'error', err);
          }
        }
      }
    });
  }
}
