import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class LoggingService {

  constructor(private snackBar: MatSnackBar) { }

  /**
   * logMessage - create one type of messaging notice on the system to display some
   * information to a user. Using the Material Snackbar that pops from the top of the screen
   *
   * @param  {type} message: string      the message to display
   * @param  {type} systemSave: boolean  save to some logging service? not implmented atm
   * @param  {type} panelClass = 'error' panel class is success (green), (red) error, (black) info, default is error
   * @param  {type} objectToLog?: any    potential object to log if we decide to do system logs
   */
  logMessage(message: string, systemSave: boolean, panelClass = 'error',
  objectToLog?: any, duration?: number, actionMessage: string = 'X') {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.duration = (duration) ? duration : 7000; // seven second duration before it closes
    config.panelClass = [panelClass]; // css will change which colours will appear depending on panel class.
    this.snackBar.open(message, actionMessage, config);
  }
}
