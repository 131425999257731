import { EVAMenu } from "../menu";
import { processStatusSVGIcon, logsSVGIcon, processIssuesSVGIcon, feedbackSVGIcon } from "./icons";
import { Routes } from "./mainMenu";

/**
 * This contains the outline to the administration menu for EVA.
 */
export const AdministrationMenu: EVAMenu[] = [
    {
        routerLink: Routes.AdminProcessIssues,
        svgIcon: processIssuesSVGIcon,
        text: 'Process Issues'
    }, {
        routerLink: Routes.AdminProcessStatus,
        svgIcon: processStatusSVGIcon,
        text: 'Process Status'
    }, {
        routerLink: Routes.AdminEVALogs,
        svgIcon: logsSVGIcon,
        text: 'EVA Logs'
    }, {
        routerLink: Routes.AdminFeedback,
        svgIcon: feedbackSVGIcon,
        text: 'Feedback'
    }
];
