import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChatEntity, ChatEntityAuthor } from '@eva-model/chat/chat';

@Component({
  selector: 'app-chat-redirect',
  templateUrl: './chat-redirect.component.html',
  styleUrls: ['./chat-redirect.component.scss']
})
export class ChatRedirectComponent {

  public author = {
    user: ChatEntityAuthor.User,
    eva: ChatEntityAuthor.EVA
  };

  @Input() chatEntity: ChatEntity;
  @Input() data: { route: string; queryParams: object; buttonText: string };

  constructor(private router: Router) { }

  doRedirect() {
    this.router.navigate([this.data.route], { queryParams: this.data.queryParams });
  }

}
