import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Fingerprint from 'fingerprintjs2';
import * as Crypto from 'crypto';

import { Utils } from '@eva-core/encryption/utils';
import { environment } from '@environments/environment';

import { UserService } from '@eva-services/user/user.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { SubtleEncryptionService } from '@eva-core/encryption/subtle-encryption.service';
import { take } from 'rxjs/operators';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private _SubtleEncryptSrv: SubtleEncryptionService,
    private _userSrv: UserService,
    private _evaGlobalSrv: EvaGlobalService,
    private _http: HttpClient,
    private perf: AngularFirePerformance
  ) { }

  private async getFingerprint(): Promise<any> {
    return new Promise((resolve, reject) => {
      new Fingerprint().get((fingerprint) => {
        resolve(fingerprint);
      }, (err) => reject(err));
    });
  }

  async getDeviceId(): Promise<string> {
    const fingerprint = this.getFingerprint();
    const deviceDigestBuffer = await crypto.subtle.digest('SHA-256', Utils.convertStringToArrayBufferView(fingerprint));
    return Utils.convertArrayBufferViewToHex(deviceDigestBuffer);
  }

  async getDeviceData() {

    try {
      const deviceId = await this.getDeviceId();
      const userDevice = await this._userSrv.getUserDevice(deviceId)
        .pipe(
          trace('device-getDeviceData'),
          take(1)
        )
        .toPromise();
      return ( userDevice && Array.isArray(userDevice) && userDevice.length > 0 ) ? userDevice[0] : null;
    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }

  }

  async doesDeviceExist() {

    try {
      const deviceId = await this.getDeviceId();
      const userDevice = await this._userSrv.getUserDevice(deviceId).pipe(
        trace('device-doesDeviceExist-getUserDevice'),
        take(1)
      ).toPromise();
      return ( userDevice && Array.isArray(userDevice) && userDevice.length > 0 ) ? true : false;
    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }

  }

  async createDeviceKeyPair(device_id: string) {
    // TODO: This iterations/salt should be saved on the device obj
    const iterations = Math.floor(Math.random() * (11000 - 9500) + 9500);

    const salt = Crypto.randomBytes(128).toString('hex');

    const hash = Crypto.pbkdf2Sync(device_id, Buffer.from(salt, 'hex'), iterations, 32, 'sha256');

    const deviceKeys = Crypto.createECDH('prime256v1');
    deviceKeys.setPrivateKey(
        Crypto.createHash('sha256').update(hash).digest()
    );

    return {
        public: deviceKeys.getPublicKey('hex'),
        private: deviceKeys.getPrivateKey('hex')
    };
  }

  async getDeviceKey() {
    const url = environment.firebaseFunction.endpoint + '/getDeviceKey';

    try {

      const deviceId = await this.getDeviceId();
      const bodyData = {
        'uid': this._evaGlobalSrv.userId,
        'deviceId': deviceId
      };

      const key =  await this._http.post<any>(url, bodyData)
      .pipe(
        trace('device-getDeviceKey')
      )
      .toPromise();
      return (key && key.deviceKey) ? key.deviceKey : null;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }

  async getDeviceJWk() {

    try {

      const deviceKey  = await this.getDeviceKey();
      if ( !deviceKey ) return null;

      const keys = {
        priv: deviceKey,
        privEnc: null,
        pub: null,
        pubEnc: null
      };

      const keyOperations = ["deriveKey", "deriveBits"];   // ['encrypt', 'decrypt']

      const deviceJwk = this._SubtleEncryptSrv.generateJWKFromEllipticPairHex( keys, 'P-256', keyOperations, 'private');
      return deviceJwk;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }

  async getDeviceCryptoKey() {

    try {

      const deviceJwk  = await this.getDeviceJWk();
      const deviceCryptoKey = await this._SubtleEncryptSrv.importKey('jwk', deviceJwk, {name: 'ECDH', namedCurve: 'P-256'});
      return deviceCryptoKey;

    } catch ( err ) {
      // TODO :: Log error in error collection
      console.log(err);
      throw err;
    }
  }


}
