import { Pipe, PipeTransform } from '@angular/core';
import { PropertyMapping } from '@eva-model/search';

@Pipe({
  name: 'getPropertyValue'
})
export class GetPropertyValuePipe implements PipeTransform {
  transform(hit: any, property: string, propertyNameMapping: PropertyMapping): unknown {
    let propertyValue = hit[property + '-modified'] ? hit[property + '-modified'] : hit[property];
    // sorter
    const sortFunc = (a, b) =>
    Number(b[propertyNameMapping.properties.find(prop => prop.propertyName === property).defaultValueProperty])
    - Number(a[propertyNameMapping.properties.find(prop => prop.propertyName === property).defaultValueProperty]);

    const isModified = hit[property + '-modified'] ? true : false;
    if (Array.isArray(propertyValue)) {
      let values = [''];
      if (isModified) {
        values = hit[property + '-modified'].sort(sortFunc);
      } else {
        values = hit[property].sort(sortFunc);
      }
      if (values.length > 0) {
        propertyValue = values[0]
          [propertyNameMapping.properties.find(prop => prop.propertyName === property).defaultValueProperty];
      }
    }
    return '' + (propertyValue ?? '');
  }

}