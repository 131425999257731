import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { Group } from '@eva-model/group';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-group-search-members-dialog',
  templateUrl: './group-search-members.component.html',
  styleUrls: ['./group-search-members.component.css'],
})
export class GroupSearchMembersDialogComponent implements OnInit, OnDestroy {

  group: Group = null;
  publicKeys: string[];

  snackBarSubs: Subscription = new Subscription();

  constructor(
    private _gps: GroupProviderService,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    public dialogRef: MatDialogRef<GroupSearchMembersDialogComponent>,
    private snackBar: MatSnackBar,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    // bind passed data to group
    this.group = this.dialogData.extra.group;
    this.publicKeys = this.group.groupSigningMembership;
  }

  ngOnDestroy() {
    if (this.snackBarSubs) {
      this.snackBarSubs.unsubscribe();
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  openGroupPublicKeySnackBar() {
    const snackBarRef =
      this.snackBar.open(`Group Public Key is ${this.group?.groupPublicKey}`, 'Copy', { duration: 6000, verticalPosition: 'top' });

    this.snackBarSubs.add(snackBarRef.onAction()
    .subscribe(
      () => {
        this.clipboardService.copyFromContent(this.group?.groupPublicKey);
        snackBarRef.dismiss();
      }
    ));
  }

}
