import { Component } from '@angular/core';
import { GridOptions, GridApi } from "ag-grid-community";
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CreateNotificationsService } from '@eva-services/notifications/create-notifications.service';
import { AboutPageFeedback } from '@eva-model/releaseNotes';
import { CompleteGridChildComponent } from './complete-grid-child/complete-grid-child.component';
import { AuthService } from '@eva-core/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  gridOptions: GridOptions;
  initialRowDataLoad$: Observable<AboutPageFeedback[]>;
  changeCollection = '/About/Feedback/Feedback/';
  defaultColDef; // this is defined in the html as a bind object.
  private gridColumnApi; // this is defined in the html as a bind object.

  private gridApi: GridApi;
  componentTitle: string;

  constructor(private firestoreService: FirestoreService,
    private _createNotificationService: CreateNotificationsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    this.initialRowDataLoad$ = this.firestoreService.colWithIds$<AboutPageFeedback>(this.changeCollection,
      ref => ref.where('replyAcknowledged', '==', false));

    this.gridOptions = <GridOptions> {
      enableRangeSelection: true,
      columnDefs: this.createChangeColumnDefs(),

      immutableData: true,
      getRowNodeId: function (data) {
          // the code is unique, so perfect for the id
          return data.id;
      },
      onGridReady: (params) => {
          this.gridApi = params.api;
          this.gridColumnApi = params.columnApi;
          this.initialRowDataLoad$.subscribe(
              rowData => {
                  // the initial full set of data
                  // note that we don't need to un-subscribe here as it's a one off data load
                  if (this.gridOptions.api) { // can be null when tabbing between the examples
                      this.gridOptions.api.setRowData(rowData);
                  }
              }
          );

      },
      context: { // added the context to allow for a child component.
        componentParent: this
      },
      onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
      }
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
  }

  /**
   * This event fires when a column is resized.
   */
  onColumnResized() {
    this.gridApi.resetRowHeights();
  }
  /**
   * This fires when a cell value is changed in the row. Since only one cell is editable, we know which one to fire.
   * @param params Grid row parameters
   */
  async onCellValueChanged(params): Promise<void> {
    try {
      const id: string = params.data.id;
      let reply: string = params.data.reply;
      if (!reply || reply.trim() === '') {
        reply = null; // store this as null if this has nothing in it.
      }
      await this.firestoreService.update<AboutPageFeedback>(this.changeCollection +
        id + '/', {reply: reply, updatedBy: await this.authService.getUserId()});
    } catch (err) {
      console.log(err);
    }
    this.gridApi.redrawRows(); // force the rows to update
  }

  /**
   * This function defines the columns for the grid.
   */
  private createChangeColumnDefs() {
    return [
      {headerName: "id", field: "id", width: 70, hide: true},
      {headerName: "Created At", field: "createdAt.seconds", valueFormatter: formatDateUnix},
      {headerName: 'Created By', field: 'submitterEmail', hide: false},
      {headerName: "Description", field: "description", hide: false, autoHeight: true,
        cellStyle: { "white-space": "normal" }},
      {headerName: 'Reply', field: 'reply', editable: true, cellEditor: "agLargeTextCellEditor", autoHeight: true,
        cellStyle: { "white-space": "normal" }},
      {headerName: 'Reply Acknowledged', field: 'replyAcknowledged', hide: true},
      {headerName: 'Submitted Uid', field: 'submittedUid', hide: true},
      {headerName: 'Update At', field: 'updatedAt.seconds', valueFormatter: formatDateUnix},
      {headerName: 'Updated By', field: 'updatedBy', hide: true},
      {headerName: 'Complete', cellRendererFramework: CompleteGridChildComponent, colId: "params"}
    ];
  }

  /**
   * This will complete the feedback that was provided in EVA.
   * @param cellData the data that was in the cell.
   */
  public async completeFeedbackRecord(cellData) {

    const responseData = 'In response to your feedback: \n"' + cellData.description + '"\n\nWe wanted to say: \n' + cellData.reply;
    const feedbackId = cellData.id;
    const submittedUid = cellData.submittedUid;
    try {
      await this.firestoreService.update<AboutPageFeedback>(this.changeCollection + feedbackId + '/',
        { replyAcknowledged: true, updatedBy: await this.authService.getUserId()});
      this._createNotificationService.createNotificationByUserId(submittedUid, responseData);
    } catch (err) {
      console.log(err);
    }
  }
}

/**
 * This formats a unix timestamp as a moment date.
 *
 * @param number a unix timestamp (number.value)
 */
function formatDateUnix(number) {
  return moment(number.value * 1000).format('L LTS');
}
