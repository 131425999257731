<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content>
  <div>
      <form [formGroup]="videosForm">
        <div formArrayName="featured">
          <div *ngFor="let feat of videosForm.get('featured')['controls']; let i=index">
            <div [formGroupName]="i">
              <fieldset>
                <legend><h2>Featured Video</h2></legend>
                <mat-form-field class="full-width-input">
                  <input matInput type="text" formControlName="title" placeholder="Featured Video Title" required>
                  <mat-error *ngIf="feat.get('title').hasError('required')">
                    Featured Video Title Required
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                    <input matInput type="text" formControlName="youTubeId" placeholder="Youtube ID (no url)" required>
                    <mat-error *ngIf="feat.get('description').hasError('required')">
                      Featured Video You Tube ID Required
                    </mat-error>
                  </mat-form-field>
                  <br />
                <mat-form-field class="full-width-input">
                    <textarea matInput type="text" formControlName="description" placeholder="Featured Video Description" required></textarea>
                    <mat-error *ngIf="feat.get('description').hasError('required')">
                      Featured Video Description Required
                    </mat-error>
                  </mat-form-field>
                <br />

              </fieldset>
            </div>
          </div>
        </div>
        <div formArrayName="links">
          <h2>All Videos</h2>
          <button (click)="addVideo()"><i class="fas fa-plus"></i> Add</button><br />
          <div *ngFor="let link of videosForm.get('links')['controls']; let j=index">
            <div [formGroupName]="j">
              <fieldset>
                <legend><h4>Video {{ j+1 }} <button (click)="deleteVideo(j)"><i class="fas fa-trash-alt"></i></button></h4></legend>
                <mat-form-field class="full-width-input">
                  <input matInput type="text" formControlName="title" placeholder="Video Title" required>
                  <mat-error *ngIf="link.get('title').hasError('required')">
                    Video Title Required
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                    <input matInput type="text" formControlName="youTubeId" placeholder="Youtube ID (no url)" required>
                    <mat-error *ngIf="link.get('youTubeId').hasError('required')">
                      Video You Tube ID Required
                    </mat-error>
                  </mat-form-field>
                <br />
                <mat-form-field class="full-width-input">
                  <textarea matInput type="textarea" formControlName="description" placeholder="Video Description" required></textarea>
                  <mat-error *ngIf="link.get('description').hasError('required')">
                    Video Description Required
                  </mat-error>
                </mat-form-field>
                <br />
              </fieldset>
            </div>
          </div>

        </div>
      </form>
    </div>
</div>
<div mat-dialog-actions>
  <button (click)="addEditVideos()" mat-raised-button color="primary" type="submit" [disabled]="!videosForm.valid">{{ dialogData.confirmBtnTxt }}</button>
  <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
</div>