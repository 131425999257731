<div class="group-search">
  <app-page-header [title]="componentTitle"></app-page-header>

  <form>
    <mat-form-field class="full-width-input">
      <input matInput type="text" placeholder="Search for Groups..." (keyup)="findGroup($event)">
      <mat-spinner matSuffix diameter="24" *ngIf="queryLoading | async"></mat-spinner>
    </mat-form-field>
  </form>

  <div *ngIf="showGroupSearchResults | async">
    <div *ngFor="let group of groups">
      <div>
        <h3>{{group.groupName}}</h3>
        <span *ngIf="group.groupType === 'Tm9ybWFsR3JvdXBSdWxlcw=='" class="group-type">normal group</span>
        <span *ngIf="group.groupType === 'SWxpa2VEaW5uZXJQYXJ0aWVzIQ=='" class="group-type">invitation group</span>
        <span *ngIf="group.groupType === 'QmVuIHNheXMgImdvIHdpdGggdGhlIGZsb3ci'" class="group-type">flow through group</span>
        <h5>{{group.groupSigningMembership.length}} Members</h5>
      </div>
      <div>
        <button style="padding-left: 0" mat-button color="accent" (click)="openMoreGroupInformation(group)">Member List</button>
        <button mat-button color="primary" *ngIf="!group.requestingAccess && !group.requestedAccess" (click)="requestGroupAccess(group)" [disabled]="group.isMember">{{group.isMember ? 'Already member of group' : 'Request Group Access'}}</button>
        <button mat-button color="primary" *ngIf="group.requestingAccess" disabled>
          <mat-spinner diameter="24"></mat-spinner>
        </button>
        <button mat-button *ngIf="group.requestedAccess" disabled>
          Requested to Join
        </button>
      </div>
    </div>
  </div>

</div>