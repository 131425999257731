import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatEntity, ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { InteractionEmitValueModel } from '@eva-model/InteractionEmitValueModel';
import { InteractionFrmCntrl } from '@eva-model/visualizedInteractionModel';
import { ChatService } from '@eva-services/chat/chat.service';
import { DynamicInteractionSyncService } from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import { Subscription } from 'rxjs';
import { delay, filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-interaction-message',
  templateUrl: './chat-interaction-message.component.html',
  styleUrls: ['./chat-interaction-message.component.scss']
})
export class ChatInteractionMessageComponent implements OnInit, OnDestroy {

  private componentSubs = new Subscription();

  public author = {
    user: ChatEntityAuthor.User,
    eva: ChatEntityAuthor.EVA
  };
  public _formControl: InteractionFrmCntrl;
  formVisualizationData: InteractionEmitValueModel;

  @Input()
  set metadata(metadata: {interactionFormElement: InteractionFrmCntrl, formVisualizationData: InteractionEmitValueModel}) {
    this._formControl = metadata.interactionFormElement;
    this.formVisualizationData = metadata.formVisualizationData;
  }
  @Input() chatEntity: ChatEntity;
  @Input() chatId: string;

  @Output() contentChange: EventEmitter<any> = new EventEmitter();

  constructor(public chatService: ChatService,
              private interactionSyncService: DynamicInteractionSyncService) { }

  ngOnInit() {
    /**
     * Because the _formControl.list is updated and not announced and the object reference never changes,
     * we need to know when the _list property contents change in order to emit an event to adjust the chat
     * position.
     */
    if (this.formVisualizationData.isCanadaPostWatchControl) {
      this.componentSubs.add(
        this.interactionSyncService.interactionControlListChanged$.pipe(
          filter((change) => change.id === this._formControl.id),
          delay(200),
          tap(() => { this.contentChange.emit() }),
          delay(200)
        ).subscribe(() => {
          this.contentChange.emit();
        })
      )
    }

    this.componentSubs.add(
      // track the latest chat entity id, if it's not this one, unsubscribe.
      this.chatService.currentChatEntity$.pipe(
        filter(entity => !!entity),
        map(entity => entity.id),
        filter(id => !!id),
        filter(id => id !== this.chatId),
        take(1)
      ).subscribe(() => {
        // terminate subscription
        this.componentSubs.unsubscribe();
      })
    )
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }

  selectOption(option: any): void {
    if (this._formControl._list) {
      const selectedItem = this._formControl._list.find(item => item === option);
      if (selectedItem) {
        this.chatService.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: this.formVisualizationData.interactionLabel,
          originator: this.formVisualizationData.processTitle
        }, this.chatId);
        this.chatService.newChatEntity({
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: selectedItem
        });
        const valueChange: InteractionEmitValueModel = {
          ...this.formVisualizationData,
          value: selectedItem
        };
        this.interactionSyncService.announceControlValueChange(valueChange);
        this.interactionSyncService.announceControlValueUpdate(valueChange);
      }
    } else if (this._formControl.options) {
      const selectedItem = this._formControl.options.find(item => item.label === option.label);
      if (selectedItem) {
        if (this._formControl.multiple) {
          selectedItem.selected = !selectedItem.selected;
        } else {
          this.chatService.newChatEntity({
            author: ChatEntityAuthor.EVA,
            type: ChatEntityType.Text,
            text: this.formVisualizationData.interactionLabel,
            originator: this.formVisualizationData.processTitle
          }, this.chatId);
          this.chatService.newChatEntity({
            author: ChatEntityAuthor.User,
            type: ChatEntityType.Text,
            text: selectedItem.label
          });
          const valueChange: InteractionEmitValueModel = {
            ...this.formVisualizationData,
            value: selectedItem.value
          };
          this.interactionSyncService.announceControlValueChange(valueChange);
          this.interactionSyncService.announceControlValueUpdate(valueChange);
        }
      }
    } else if (this._formControl.group) {
      const selectedItem = this._formControl.group.find(item => item.label === option.label);
      if (selectedItem) {
        selectedItem.value = !selectedItem.value;
      }
    }
  }

  submitMultiSelect(): void {
    let selectedItems = [];
    if (this._formControl.options) {
      selectedItems = this._formControl.options.filter(option => option.selected).map(option => option.value);
    } else if (this._formControl.group) {
      selectedItems = this._formControl.group.filter(option => option.value).map(option => option.label);
    }
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.EVA,
      type: ChatEntityType.Text,
      text: this.formVisualizationData.interactionLabel,
      originator: this.formVisualizationData.processTitle
    }, this.chatId);
    this.chatService.newChatEntity({
      author: ChatEntityAuthor.User,
      type: ChatEntityType.Text,
      text: selectedItems.toString()
    });
    const valueChange: InteractionEmitValueModel = {
      ...this.formVisualizationData,
      value: selectedItems.toString()
    };
    this.interactionSyncService.announceControlValueChange(valueChange);
    this.interactionSyncService.announceControlValueUpdate(valueChange);
  }

  isAnySelected(): boolean {
    if (this._formControl.options) {
      return this._formControl.options.filter(option => option.selected).length === 0;
    } else if (this._formControl.group) {
      return this._formControl.group.filter(option => option.value).length === 0;
    }
  }
}
