import { Injectable } from '@angular/core';
import { KnowledgeModel } from '@eva-model/knowledge';
import { KnowledgeSearchRecord } from '@eva-model/knowledge/knowledge';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { KnowledgeCreateComponent } from '@eva-ui/admin-overview/knowledge/knowledge-create/knowledge-create.component';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';

export enum ReplacementQueueStatus {
  IDLE = 'IDLE',
  WORKING = 'WORKING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum ReplacementComponentState {
  SEARCHING = 'SEARCHING',
  VIEWING = 'VIEWING',
  DONE = 'DONE'
}

enum ReplaceQueueActiveDocumentState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

interface FindReplaceQueueItem {
  groupPublicKey: string;
  documentId: string;
  documentName: string;
  find: string;
  caseSensitive: boolean;
}

interface ReplacementQueueActiveDocument {
  state: ReplaceQueueActiveDocumentState;
  groupPublicKey: string;
  documentId: string;
  documentName: string;
  knowledgeModel: KnowledgeModel;
  htmlString: string;
  htmlDocument: Document;
  activeMatchIndex: number;
  matches: ReplacementMatch[];
}

interface ReplacementMatch {
  node: any;
  viewed: boolean;
  replaced: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class KnowledgeGlobalFindReplaceService {

  // controls the find and replace view
  public currentState: ReplacementComponentState = ReplacementComponentState.SEARCHING;

  private documentQueue: BehaviorSubject<FindReplaceQueueItem[]> = new BehaviorSubject([]);
  private currentQueueDocument: BehaviorSubject<FindReplaceQueueItem | undefined> = new BehaviorSubject(undefined);
  private activeDocument: BehaviorSubject<ReplacementQueueActiveDocument> = new BehaviorSubject(null);

  // exposed subjects
  public documentQueue$: Observable<FindReplaceQueueItem[]> = this.documentQueue.asObservable();
  public activeDocument$: Observable<ReplacementQueueActiveDocument | null> = this.activeDocument.asObservable();

  public currentQueueIndex: number;

  constructor(private chatKnowledgeService: ChatKnowledgeService) {
    this.documentQueue$.pipe(
      filter((queue) => queue.length > 0),
      map((queue) => queue[0])
    ).subscribe((queueItem) => {
      this.currentQueueDocument.next(queueItem);
    });
  }

  public addToQueue(hit: KnowledgeSearchRecord, terms: {searchTerm: string, caseSensitive: boolean}): void {
    const newItem: FindReplaceQueueItem = {
      groupPublicKey: hit.groupPublicKey,
      documentId: hit.docId,
      documentName: hit.docName,
      find: terms.searchTerm,
      caseSensitive: terms.caseSensitive
    }

    const queue = this.documentQueue.getValue();

    // check if document is already in queue with the same 'find' term.
    const foundMatch = queue.find((queueItem) => {
      return queueItem.documentId === newItem.documentId && queueItem.find == newItem.find;
    });


    // disregard add
    if (foundMatch) {
      return;
    }

    queue.push(newItem);
    this.documentQueue.next(queue);
  }

  public removeFromQueue(item: FindReplaceQueueItem): void {
    const queue = this.documentQueue.getValue();

    // check if document is already in queue with the same 'find' term.
    const index = queue.findIndex((queueItem) => {
      return queueItem.documentId === item.documentId && queueItem.find == item.find;
    });

    if (index < 0) {
      return;
    }

    queue.splice(index, 1);
    this.documentQueue.next(queue);
  }

  private removeCurrentDocumentFromQueue(): FindReplaceQueueItem {
    const queue = this.documentQueue.getValue();
    const removedItem = queue.shift();
    this.documentQueue.next(queue);
    return removedItem;
  }

  public clearQueue(): void {
    this.documentQueue.next([]);
  }

  public nextQueueItem(): void {
    this.currentQueueIndex = this.currentQueueIndex + 1;
  }

  public getDocumentStatus(documentId: string): {current: FindReplaceQueueItem, upcoming: FindReplaceQueueItem | null} | null {
    const queue = this.documentQueue.getValue();
    const index = queue.findIndex((item) => item.documentId === documentId);
    if (index < 0) {
      return null;
    }
    return {
      current: queue[index],
      upcoming: queue[index+1] ? queue[index+1] : null
    }
  }

  /**
   * Trims space and removes quotes from the ends of a string
   */
  public cleanSearchQuery(query: string): string {
    // cleanse search term of quotes and things
    let cleansedTerm = query.trim();

    // cleanse any quotes on ends of string
    if (
      cleansedTerm[0] === "\"" &&
      cleansedTerm[cleansedTerm.length-1] === "\"" ||
      cleansedTerm[0] === "\'" &&
      cleansedTerm[cleansedTerm.length-1] === "\'"
    ) {
      cleansedTerm = cleansedTerm.slice(1, cleansedTerm.length - 1);
    }

    return cleansedTerm;
  }

  public openNextDocument(initialOpen = false): void {
    // remove current doc and go to next doc.
    if (initialOpen === false) {
      this.removeCurrentDocumentFromQueue();
    }

    // subscribe for the next document in queue.
    this.currentQueueDocument.asObservable().pipe(
      take(1)
    ).subscribe((item) => {
      if (!item) {
        return;
      }

      this.chatKnowledgeService.announceKnowledgeEdit({
        docGroup: item.groupPublicKey,
        docId: item.documentId,
        docName: item.documentName,
        published: true,
        viewKey: 'bGV0IG1lIGluIHBsZWFzZSE'
      })
    })
  }
}
