<div fxLayout="row">
  <div fxFlexFill>
    <div fxFlex="32%">
      <mat-card class="padding10">
        <mat-tree #interactionMatTree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="25"
            data-form-control="true" draggable="true" (dragstart)="dragStartConditionElementHandler($event)"
            [attr.data-form-control-data]=jsonStringify(node.data)>

            {{node.label}}
          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="20"
            data-form-control="false" draggable="false" (dragstart)="dragStartConditionElementHandler($event)"
            [attr.data-form-control-data]=null>

            <button class="mat-icon-button-24" mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.label">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.label}}

          </mat-tree-node>
        </mat-tree>
      </mat-card>

    </div>
    <div fxFlex="1%">
    </div>
    <div fxFlex="67%">
      <mat-card id="ConditionSetZone" class="interaction-condition-builder">
        <span (click)="addConditionSet()" class="add-condition-button">Add condition &nbsp; <i
            class="fas fa-plus-circle"></i></span>
        <mat-tab-group dynamicHeight=false *ngIf="conditionSets !== undefined && conditionSets.length > 0">
          <mat-tab *ngFor="let conditionSet of conditionSets; let conditionSetIndex = index;">
            <ng-template mat-tab-label>
              <div class="tab-label" (mouseover)="onHoverTabLabel(conditionSetIndex)"
                (mouseleave)="onHoverLeaveTabLabel(conditionSetIndex)" draggable="true"
                (dragstart)="onDrag($event, conditionSetIndex)" (drop)="reorderScreens($event, conditionSetIndex)"
                (dragover)="allowdrop($event)">
                <span class="condition-set-title" contenteditable="true"
                  (focusout)="onTabLabelBlur($event, conditionSetIndex)"
                  (keydown)="onTabLabelChange($event, conditionSetIndex)">
                  {{ conditionSet.name }}
                </span>
                <span>{{ (conditionSet.connectionOperator) ? ' (' + conditionSet.connectionOperator + ')' : '' }}</span>
                <i (click)="removeConditionSetConfirm(conditionSet)"
                  [class.deleteButtonHide]="!conditionSets[conditionSetIndex].hovered"
                  [class.deleteButtonShow]="conditionSets[conditionSetIndex].hovered" class="far fa-trash-alt"></i>
              </div>
            </ng-template>
            <mat-card id="conditionSetDropZone" (drop)="dropConditionElementHandler($event)"
              (dragover)="dargOverConditionElementHandler($event)" [attr.data-condition-set-index]=conditionSetIndex>
              <div id="condition-set-connective" *ngIf="conditionSets.length > 1 && conditionSetIndex !== (conditionSets.length - 1)">
                <span id="condition-set-message">Condition Set Connective</span>
                <mat-select [(value)]="conditionSet.connectionOperator" (selectionChange)="setConditionSetConnectionOperator($event, conditionSet)">
                  <mat-option value="AND">AND</mat-option>
                  <mat-option value="OR">OR</mat-option>
                </mat-select>
              </div>
              <ul class="list-style-none">
                <li *ngFor="let element of conditionSet.conditions; let elmntIndex = index;">

                  <app-workflow-form-element-visualizer [interactionId]="this.interaction.id"
                    [fromControlItem]="this.jsonStringify(this.getFormControlById(element.elementOriginalId))"
                    [frmScrnIndex]="conditionSetIndex" [frmElmntIndex]="elmntIndex"
                    [frmScrnElmntCnt]="conditionSet.conditions.length" [enableSetting]=false [enableRelation]=false
                    [enableOperatorSelectors]=true [enableValueEmiiter]=true [elementValueOperator]="element.operator"
                    [elementConnectionOperator]="element.connectionOperator" [scrnIdx]="element.scrnIdx"
                    [elmntIdx]="element.elmntIdx" [initValue]="element.value"
                    [secondValue]="element.secondValue"
                    (removeFormElementConfirm)="removeConditionElementConfirm($event)"
                    (moveUpFormElement)="moveUpConditionElement($event)"
                    (moveDownFormElement)="moveDownConditionElement($event)">
                  </app-workflow-form-element-visualizer>

                </li>
              </ul>
              <br /><br /><br />
            </mat-card>
          </mat-tab>
        </mat-tab-group>

      </mat-card>
    </div>
  </div>
</div>