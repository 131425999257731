
// process edit interface to define it as a type
export interface ProcessEdit {
    action: string; // action that user takes by choosing edit the process or submitting it
    detail?: ProcessEditDetails; // contains selected interaction to edit
}

// process edit class which implements the related interface
export class ProcessEdit implements ProcessEdit {
    constructor(
        public action: string, // action that user takes by choosing edit the process or submitting it
        public detail?: ProcessEditDetails // contains selected interaction to edit
    ) {}
}

// stores user selectd details, to use in process-runner
export class ProcessEditDetails {
    constructor(
        public selectedInteraction: any, // stores interaction selected by user
        public interactionPosition: number, // stores interactions position within workflow
        public selectedFormScreen: number, // stores the form screen within the interaction selected by the user
        public processId: string // stores the form screen within the interaction selected by the user
    ) {}
}

// used to indicate whether the interaction is going to submit or go to another interaction to edit in the process-runner
export enum ProcessEditAction {
    submit = "Submit",
    edit = "Edit"
}
