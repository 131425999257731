// eslint-disable-next-line max-len
import { Component, OnInit, Input, ChangeDetectionStrategy, ElementRef, Output, EventEmitter, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { KnowledgeDocumentSection, KnowledgeDocumentSectionFlat } from '@eva-model/knowledge/knowledge';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { KnowledgeDocumentFeedbackService } from '@eva-services/knowledge/feedback/knowledge-document-feedback.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-knowledge-section',
  templateUrl: './knowledge-section.component.html',
  styleUrls: ['./knowledge-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeSectionComponent implements OnInit, OnDestroy {

  @Input()
  public set sectionData(data: KnowledgeDocumentSectionFlat) {
    if (data.id) {
      this.sectionId = data.id;
    }

    // if there is html data, update the anchor tags to open in a new tab/window
    if (data.text) {
      data.text = KnowledgeUtils.updateAnchorTagsToOpenNewTab(data.text);
    }

    this.section = data;
  }

  @Input()
  public set docId(id: string) {
    this.documentId = id;
  }

  @Input()
  public set toggleFeedbackMode(toggle: boolean) {
    this.toggleSectionFeedbackMode(toggle);
  }

  // This should only be set if we are passing in top document html. We use this component to print out the top html of the knowledge doc
  // because we want to share the CSS styles that we apply here in the top HTML chunk of the doc also.
  @Input()
  public set documentTextHtml(html: string) {
    this.html = KnowledgeUtils.updateAnchorTagsToOpenNewTab(html);
  }

  @Output() copyLink = new EventEmitter<Event>(); // emits event, then parent component knows to copy specific section
  @Output() sendFeedback = new EventEmitter<Event>(); // emits event, then parent component knows which section
  @Output() feedbackSelected = new EventEmitter();

  html: string; // html to display
  public section: KnowledgeDocumentSectionFlat;

  public documentId: string;
  public sectionId = '0'; // set a default value until set through the @Input() (this is for top document text html)
  public elementRef: ElementRef; // this components element reference // TODO: This can change to an elementRef import from Angular.

  // changes the appearance of the section
  public sectionFeedbackModeActive = false;

  // feedback selection
  public sectionSelected$: Observable<boolean>;
  private sectionSelectedSub: Subscription;

  @HostListener('click')
  onClick() {
    if (this.sectionFeedbackModeActive) {
      this.knowledgeFeedbackDocumentService.toggleSelectedSection(this.documentId, this.sectionId, this.section);
    }
  }

  constructor(private ref: ElementRef,
              private knowledgeFeedbackDocumentService: KnowledgeDocumentFeedbackService,
              private cdr: ChangeDetectorRef) {
    this.elementRef = ref;
  }

  ngOnInit() {
    this.sectionSelected$ = this.knowledgeFeedbackDocumentService.getSectionSelectionByDocAndSectionId(this.documentId, this.sectionId);

    // If this component is being used to print the top html of the doc, stop doing other stuff.
    if (!this.section) {
      return;
    }

    // create html string from section
    this.html = KnowledgeUtils.createHtmlStringFromSection(this.section);
  }

  ngOnDestroy() {
    if (this.sectionSelectedSub) {
      this.sectionSelectedSub.unsubscribe();
    }
  }

  /**
   * uses an EventEmitter and outputs a mouse click event. This triggers our parent component to create and copy
   * a link with this section id.
   */
  triggerLink(e: Event): void {
    this.copyLink.emit(e);
  }

  /**
   * uses an EventEmitter and outputs a mouse click event. This triggers our parent component to create and show
   * a feedback dialog that is for this specific section. This allows us to funnel all feedback to inside EVA in one place.
   */
  triggerFeedback(e: Event): void {
    this.sendFeedback.emit(e);
  }

  toggleSectionFeedbackMode(toggle: boolean): void {
    // check if toggle is already the state of the component
    if (this.sectionFeedbackModeActive === toggle) {
      return;
    }

    this.sectionFeedbackModeActive = toggle;
    this.elementRef.nativeElement.classList.toggle('feedback-select-mode');

    // check if subscription already exists
    if (this.sectionSelectedSub) {
      return;
    }

    // create our watching observable
    this.sectionSelectedSub = this.sectionSelected$.subscribe((selected) => {
      if (selected) {
        this.elementRef.nativeElement.classList.add('selected');
      } else {
        this.elementRef.nativeElement.classList.remove('selected');
      }
    });

    // setting this from the parent doesn't run change detection. This is not a hack, but
    // we will run change detection manually just in this case.
    this.cdr.detectChanges();
  }
}
