
<div class="app-loading" *ngIf="isWaiting">
  <mat-spinner></mat-spinner>
  <ng-content></ng-content>
  <h6>{{ onWaitMessage }}</h6>
</div>

<div  *ngIf="!isWaiting">

  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlexFill>
      <mat-card>
        <div fxFlex="100%">
          <mat-accordion multi="false"> 
            <mat-card>
              <div fxFlex="1%" class="rightMargin10"> 
              </div>
              <div fxFlex="68%" class="rightMargin10">
                <button pButton type="button" label="Process Interactions Summary" class="ui-button-secondary pCustomButton"
                  icon="far fa-newspaper" iconPos="left">
                </button>
              </div>
              <div fxFlex="15%"> 
                <button pButton *ngIf="isDoneButtonEnable && !isWaitingForDoneProcess" type="button" label="Done" class="ui-button-info pCustomButton fullWidth"
                  icon="fas fa-check-square" iconPos="left" (click)="doneProcess()">
                </button>

                <div class="app-loading" *ngIf="isWaitingForDoneProcess">
                  <span class="is-waiting" *ngIf="isWaitingForDoneProcess">
                    <mat-spinner [diameter]=24 [strokeWidth]=6 *ngIf="isWaitingForDoneProcess"></mat-spinner>
                  </span>
                  <span class="verColor" *ngIf="isWaitingForDoneProcess"><h6>&nbsp;Done&nbsp;...&nbsp;&nbsp;</h6></span>
                </div>
              </div>
              <div fxFlex="1%" class="rightMargin10"> 
              </div>
              <div fxFlex="15%"> 
                <button pButton *ngIf="!isCloseButtonDisable" type="button" label="Close" class="ui-button-info pCustomButton fullWidth"
                  icon="fas fa-sign-out-alt" iconPos="left" (click)="closeProcessSummary()">
                </button>
              </div>
            </mat-card>

            <mat-expansion-panel *ngFor="let intrct of this.processInteractions; let i = index;">
              <mat-expansion-panel-header >
                <mat-panel-title>
                  Interaction name: {{ intrct.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ intrct.id }} 
                </mat-panel-description>
              </mat-expansion-panel-header>

              <app-form-visualizer
                [isAllDisabled]="true"
                [dynFrmObj]="intrct"
                [isSubmitEnable]="false">
              </app-form-visualizer>

            </mat-expansion-panel>

          </mat-accordion>
        </div>

      </mat-card>
    </div>
  </div>
  
</div>
