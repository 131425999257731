<div class="about-eva">
  <app-page-header [title]="componentTitle"></app-page-header>

  <div>
    <!-- Training & Resources Card -->
    <app-training [isAdministrator]="_isAdministrator"></app-training>

    <!-- What we did/Updates Card -->
    <app-release-notes [isAdministrator]="_isAdministrator"></app-release-notes>

    <!-- Featured Team Members Card -->
    <app-team-members [isAdministrator]="_isAdministrator"></app-team-members>

  </div>
</div>
