import {
  Component,
  Input, Output, EventEmitter,
  SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';

import { FRM_CNTRL_INPUT, FRM_CNTRL_DATETIME, FRM_CNTRL_ADDITIONAL, FRM_CNTRL_CUSTOM } from '@eva-model/formControls';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';

@Component({
  selector: 'app-form-object-viewer',
  templateUrl: './form-object-viewer.component.html',
  styleUrls: ['./form-object-viewer.component.scss']
})
export class FormObjectViewerComponent {

  formTree: TreeNode[] = null;
  enableObjectEnable = true;
  FRM_CNTRL_ELEMENTS = [];

  selectedItems: TreeNode[];

  @Input()
  set dynFrmObj(dynFrmObj: string) {
    if ( !dynFrmObj ) { return; }
    const dynFrm = JSON.parse(dynFrmObj);
    this.formTree = this.formBuilderService.interactionToTreeNodes(dynFrm, false, true);
  }

  constructor(private cd: ChangeDetectorRef, private formBuilderService: FormBuilderService) { }

  toggleChange($event) {
    this.enableObjectEnable = $event.checked;
  }

}
