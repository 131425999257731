import { Injectable } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { KnowledgeDocumentSectionFlat } from '@eva-model/knowledge/knowledge';
import { ChatMLInitialUserResponse, ChatMLGetBetterUserResponse } from '@eva-model/chat/chatML';
import { TfidfTraining, TfidfTrainingResponse } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { KnowledgeDocumentFeedbackService } from '@eva-services/knowledge/feedback/knowledge-document-feedback.service';

@Injectable()
export class EvaHeartTrainingService {
  private _updateTfidfTrainingDataUrl = environment.endPoints.EVA_HEART.url + 'incomingTfIdfTrainingData';

  constructor(private http: HttpClient,
              private afAuth: AuthService,
              private perf: AngularFirePerformance,
              private knowledgeDocumentFeedback: KnowledgeDocumentFeedbackService) { }

  async sendTfidfTrainingData(tfidfTraining: TfidfTraining): Promise<TfidfTrainingResponse> {

    const url = this._updateTfidfTrainingDataUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      const tfidfResponse = await this.http.post<TfidfTrainingResponse>(url, tfidfTraining, httpOptions).pipe(
        trace('eva-heart-training-sendTfidfTrainingData'),
        take(1)
      ).toPromise();

      return tfidfResponse;
    } catch (err) {
      console.log('Error in chat service: ' + err);
      return Promise.reject(err);
    }
  }

//#region CreateTrainingObjects

  /**
   * This function generates the Tfidf Machine learning algorithm for the AI.
   */
  async createTfidfTrainingObject(query: string, knowledgeGroupPublicKey: string, modelVersion: string, docId: string,
    feedbackType: ChatMLInitialUserResponse | ChatMLGetBetterUserResponse,  correctText?: string,
    knowledgeDocumentSection?: KnowledgeDocumentSectionFlat | KnowledgeDocumentSectionFlat[]): Promise<TfidfTraining> {
      let tfidfTraining: TfidfTraining = null; // create the ididf training object.

    try {
      // get thee user object
      const user = await this.afAuth.user.pipe(take(1)).toPromise();
      if (correctText) {
        tfidfTraining = {
          query: query, // this is the users query.
          knowledgeGroupPublicKey: knowledgeGroupPublicKey,
          modelVersion: this.knowledgeDocumentFeedback.getModelVersion(),
          docId: docId,
          feedbackType: feedbackType,
          timestamp: Date.now(),
          userId: user.uid,
          userEmail: user.email,
          userPublicKey: user.signingPublicKey,
          correctText,
          providedAnswers: this.knowledgeDocumentFeedback.getProvidedAnswers()
        };
      } else if (knowledgeDocumentSection) {
        tfidfTraining = {
          query: query, // this is the users query.
          knowledgeGroupPublicKey: knowledgeGroupPublicKey,
          modelVersion: this.knowledgeDocumentFeedback.getModelVersion(),
          docId: docId,
          feedbackType: feedbackType,
          timestamp: Date.now(),
          userId: user.uid,
          userEmail: user.email,
          userPublicKey: user.signingPublicKey,
          knowledgeDocumentSection: Array.isArray(knowledgeDocumentSection) ? knowledgeDocumentSection : [knowledgeDocumentSection],
          providedAnswers: this.knowledgeDocumentFeedback.getProvidedAnswers()
        };
      } else {
        return Promise.reject('Section or Straight text need to be provided.');
      }
      return tfidfTraining;
    } catch (err) {
      console.log(err); // log the error.
      return Promise.reject(err); // this is the rejection of the error.
    }
  }

//#endregion CreateTrainingObjects
}
