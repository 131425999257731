import { Node } from './Node';

export class Tree {
  root: Node;       // root node

  constructor(data: any) {
    const node = new Node(data);
    this.root = node;
  }

  /**
   * This function traverses the tree in Depth-First manner and calls callback function for each node
   *
   * @param callback Callback function being called for each node
   */
  traverseDF(callback: any): void {
    this.recurse(this.root, callback);
  }

  /**
   * Recursive function to traverse through nodes in the tree, calls callback for the leaf node first
   *
   * @param currentNode Current node being traversed
   * @param callback Callback function being called for each node
   */
  recurse(currentNode: Node, callback: any): void {
    for (let i = 0, length = currentNode && currentNode.children ? currentNode.children.length : 0; i < length; i++) {
      this.recurse(currentNode.children[i], callback);
    }
    if (currentNode) {
      callback(currentNode);
    }
  }

  /**
   * Recursive function to traverse through nodes in the tree, calls callback for the root node first
   *
   * @param currentNode Current node being traversed
   * @param callback Callback function being called for each node
   */
  reverseRecurse(currentNode: Node, callback: any): void {
    let blockNode = null;
    if (currentNode) {
      blockNode = callback(currentNode);
    }
    for (let i = 0, length = currentNode && currentNode.children ? currentNode.children.length : 0; i < length; i++) {
      if (blockNode && blockNode.data.id === currentNode.data.id) {
        return;
      }
      this.reverseRecurse(currentNode.children[i], callback);
    }
  }

  /**
   * This function traverses the tree in Depth-First manner and calls callback function for each children of root node data passed
   * Callback is called first for parent then child
   *
   * @param callback Callback function being called for each child node
   * @param root Data of the node of which children needs to be traversed
   * @param searchCurrentTree Whether to search current tree or search root
   */
  traverseNodeDF(callback: any, root: any, searchCurrentTree?: boolean): void {
    let rootTreeNode = null;
    if (!searchCurrentTree) {
      this.contains((node: Node) => {
        if (node.data.id === root.id) {
          rootTreeNode = node;
        }
      });
    } else {
      rootTreeNode = root;
    }
    if (rootTreeNode) {
      this.reverseRecurse(rootTreeNode, callback);
    }
  }

  /**
   * This function traverses the tree in Depth-First manner and calls callback function for each children of root node data passed
   * Callback is called first for child then parent
   *
   * @param callback Callback function being called for each child node
   * @param root Data of the node of which children needs to be traversed
   */
  traverseReverseNodeDF(callback: any, root: any): void {
    let rootTreeNode = null;
    this.contains(node => {
      if (node.data.id === root.id) {
        rootTreeNode = node;
      }
    });
    if (rootTreeNode) {
      this.recurse(rootTreeNode, callback);
    }
  }

  /**
   * This function calls callback function for all the nodes to check existence of node
   * uses traverseDF for traversal
   *
   * @param callback Callback function being called for each node
   */
  contains(callback: any): void {
    this.traverseDF.call(this, callback);
  }

  /**
   * This function adds new node to the tree
   *
   * @param data Data of the node being added
   * @param toData Data of the parent node
   */
  add(data: any, toData: any): Node {
    const child = new Node(data);
    let parent = null;
    const callback = function (node) {
      if (node.data.id === toData.id) {
        parent = node;
      }
    };

    this.contains(callback);

    if (parent) {
      parent.children.push(child);
      child.parent = parent;
      return child;
    } else {
      console.log(toData, data);
      throw new Error('Cannot add node to a non-existent parent.');
    }
  }

  /**
   * This function removes the node from the tree
   *
   * @param data Data of the node being removed
   * @param toData Data of the parent node
   */
  remove(data: any, fromData: any): Node[] {
    let parent: Node = null,
      childToRemove: Node[] = null,
      index: number;

    const callback = function (node) {
      if (node.data.id === fromData.id) {
        parent = node;
      }
    };

    this.contains(callback);

    if (parent) {
      index = findIndex(parent.children, data);

      if (index === undefined) {
        throw new Error('Node to remove does not exist.');
      } else {
        childToRemove = parent.children.splice(index, 1);
      }
    } else {
      throw new Error('Parent does not exist.');
    }

    return childToRemove;
  }
}

/**
 * This is a helper function that finds index of the data in the array
 *
 * @param arr Array being traversed
 * @param data Data whose index needs to be found
 */
export function findIndex(arr: any[], data: any): number {
  let index: number;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].data.id === data.id) {
      index = i;
    }
  }
  return index;
}
