import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkFlow, WorkflowInteraction, WorkFlowCondition } from '@eva-model/workflow';
import { Guid } from '@eva-core/GUID/guid';
import { environment } from '@environments/environment';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable()
export class WorkflowService {

  private getWorkflowsByGroupUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getWorkflowsByGroup';
  private getActiveWorkflowsByGroupUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getActiveWorkflowsByGroup';
  private getWorkflowByIdAndVerUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getWorkflowByIdAndVer';
  private activateWorkflowVersionUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'activateWorkflowVersion';
  private getActiveWorkflowById = environment.endPoints.EVA_FF.url + environment.endPoints.EVA_FF.getActiveWorkflowById;

  constructor(private http: HttpClient,
    private perf: AngularFirePerformance) { }

  fetchWorkflowsByIdAndVer(workflowId: string, version: number): Observable<any> {
    if (workflowId) {
    workflowId = workflowId.trim();
    }

    // Add safe, URL encoded search parameter if there is a search term
    let paramsOption = new HttpParams().set('workflowId', workflowId);
    paramsOption = paramsOption.set('version', version.toString());

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getWorkflowByIdAndVerUrl, options).pipe(
      trace('workflow-fetchWorkflowsByIdAndVer')
    );
  }

  /**
   * This gets the activated workflow and returns it to the application. If no active version is found, this returns empty
   * @param workflowId workflow Id for the EVA application
   */
  fetchActiveWorkflowsById(workflowId: string): Observable<any> {
    if (workflowId) {
    workflowId = workflowId.trim();
    }

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('workflowId', workflowId);
    // paramsOption = paramsOption.set('version', version.toString());

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getActiveWorkflowById, options).pipe(
      trace('workflow-fetchActiveWorkflowsById')
    );
  }

  fetchWorkflowsByGroup(groupPk: string): Observable<any> {
    groupPk = groupPk.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('groupPublicKey', groupPk);

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getWorkflowsByGroupUrl, options).pipe(
      trace('workflow-fetchWorkflowsByGroup')
    );
  }

  fetchActiveWorkflowsByGroup(groupPk: string): Observable<any> {
    groupPk = groupPk.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('groupPublicKey', groupPk);

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getActiveWorkflowsByGroupUrl, options).pipe(
      trace('workflow-fetchActiveWorkflowsByGroup')
    );
  }

  activateWorkflowVersion(workflowId: string, activeVersion: number): Observable<any> {
    if (workflowId) {
    workflowId = workflowId.trim();
    }

    // Add safe, URL encoded search parameter if there is a search term
    let paramsOption = new HttpParams().set('workflowId', workflowId);
    paramsOption = paramsOption.set('activeVersion', activeVersion.toString());

    const options = {
      params: paramsOption
    };

    return this.http.get(this.activateWorkflowVersionUrl, options).pipe(
      trace('workflow-activateWorkflowVersion')
    );
  }

  workflowObjectMapper(workflowObj): WorkFlow {
    const workflow = new WorkFlow(
      workflowObj.id ? workflowObj.id : Guid.newGuid().toString(),
      workflowObj.name, '',
      workflowObj.groupPublicKey,
      Date.now(),
      workflowObj.isWorkflowEncrypted,
      workflowObj.descriptions,
      [],
      workflowObj.activeVersion ? workflowObj.activeVersion : null,
      workflowObj.activated ? true : false,
      workflowObj.subGroup
    );

    if (workflowObj.interactions && Array.isArray(workflowObj.interactions) && workflowObj.interactions.length > 0) {
      workflowObj.interactions.forEach(intrct => {
        const interaction = new WorkflowInteraction(
          intrct.id,
          intrct.name,
          intrct.version,
          intrct.groupPublicKey,
          JSON.parse(intrct.interaction),
          false, '', [],
          intrct.order
        );

        if (intrct.conditions && Array.isArray(intrct.conditions) && intrct.conditions.length > 0) {
          intrct.conditions.forEach(cndtn => {
            const condition =
              new WorkFlowCondition(cndtn.id, cndtn.name, cndtn.condition, cndtn.trueDestination, cndtn.falseDestination, cndtn.order);
            interaction.conditions.push(condition);
          });
        }

        workflow.interactions.push(interaction);
      });
    }

    return workflow;
  }

}
