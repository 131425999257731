import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { KnowledgeGlobalFindReplaceService } from '@eva-services/knowledge/knowledge-global-find-replace.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-knowledge-global-find-and-replace-edit-document',
  templateUrl: './knowledge-global-find-and-replace-edit-document.component.html',
  styleUrls: ['./knowledge-global-find-and-replace-edit-document.component.scss']
})
export class KnowledgeGlobalFindAndReplaceEditDocumentComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  activeDoc: any;

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    replace: new UntypedFormControl('', Validators.required)
  });

  constructor(public findAndReplaceService: KnowledgeGlobalFindReplaceService) { }

  ngOnInit(): void {
    this.subs.add(
      this.findAndReplaceService.activeDocument$.subscribe((doc) => {
        this.activeDoc = doc;
      })
    )
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  replaceOccurrence() {
    const index = this.activeDoc.activeMatchIndex;
    // this.findAndReplaceService.replaceNodeWithText( this.activeDoc.matches[index].node, this.searchForm.get('replace').value );
    this.activeDoc.matches[index].node = null;
    this.activeDoc.matches[index].replaced = true;

    // next occurence
    this.activeDoc.activeMatchIndex = this.activeDoc.activeMatchIndex + 1;
  }

  nextOccurrence() {
    this.activeDoc.activeMatchIndex = this.activeDoc.activeMatchIndex + 1;
    const index = this.activeDoc.activeMatchIndex;
    
    if (this.activeDoc.matches[index]) {
      this.activeDoc.matches[index].viewed = true;
      if (this.activeDoc.matches[index].node) {
        // this.findAndReplaceService.highlightMark(this.activeDoc.matches[index].node);
      }
    }
  }

  prevOccurrence() {
    this.activeDoc.activeMatchIndex = this.activeDoc.activeMatchIndex - 1;
    const index = this.activeDoc.activeMatchIndex;
    
    if (this.activeDoc.matches[index]) {
      this.activeDoc.matches[index].viewed = true;
      if (this.activeDoc.matches[index].node) {
        // this.findAndReplaceService.highlightMark(this.activeDoc.matches[index].node);
      }
    }
  }

}
