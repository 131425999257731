import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalCollaborators'
})
export class TotalCollaboratorsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // return the number of collaborators on the change.
    return Object.keys(value).length;
  }

}
