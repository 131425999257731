import { Component } from '@angular/core';
import { ITooltipAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-custom-dashboard-tooltip',
  templateUrl: './custom-dashboard-tooltip.component.html',
  styleUrls: ['./custom-dashboard-tooltip.component.scss']
})
export class CustomTooltipComponent implements ITooltipAngularComp {

  exceptionType: string;
  exceptionMessage: string;

  /**
   * Takes the data from the split up string and places it into exceptionMessage to be displayed in a tooltip
   * @param params accepts data returned by cellRenderer on status in process-dashboard.ts
   */
  agInit(params): void { // string splitting and validation checking handled in cellRenderer component
      this.exceptionMessage = params.value;
  }
}
