/**
 * this provider checks a users preferences and displays a tutorial is not completed
 */
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { UserService } from '@eva-services/user/user.service';
import { UserPreferences } from '@eva-model/UserPreferences';
import { LoggingService } from "@eva-core/logging.service";
import { catchError, map, switchMap, take, tap, filter } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { TutorialIntroJsConfig, TutorialModel, TutorialState } from '@eva-model/tutorials/tutorial';
import introJs from 'intro.js/intro.js';
import { welcomeModel } from '@eva-model/tutorials/main-screen/welcome';
import { feedbackModel } from '@eva-model/tutorials/main-screen/feedback';
import { dashboardTutorialModel } from '@eva-model/tutorials/processDashboardTutorial';
import { documentFeedbackTutorial } from '@eva-model/tutorials/documentFeedbackTutorial';
import { MatDialog } from '@angular/material/dialog';
import { TutorialIntroComponent } from '@eva-ui/tutorial-intro/tutorial-intro.component';

const availableTutorials: { [key: string]: TutorialModel } = {
  welcome: welcomeModel,
  feedback: feedbackModel,
  processDashboard: dashboardTutorialModel,
  documentFeedback: documentFeedbackTutorial
};

@Injectable({
  providedIn: 'root'
})

export class TutorialService {
  // private userPreferences$: Observable<UserPreferences> = from(this._userService.getUserPreferences()).pipe(
  //   share()
  // );

  // If the value is null, no tutorials are currently active; else emits active tutorial.
  private tutorialIdActive: BehaviorSubject<string|null> = new BehaviorSubject(null);

  // the default state that no tutorials are presenting
  // private lastTutorialStateValue: TutorialState = { currentlyPresenting: false, tutorialName: '' };
  private lastTutorialStateValue: TutorialState = { currentlyPresenting: false, tutorialName: '' };
  // the state of the tutorial, which will prevent conflicts between multiple tutorials
  private currentTutorialState: BehaviorSubject<TutorialState> = new BehaviorSubject(this.lastTutorialStateValue);
  // the observable to subscribe to in other components.
  public currentTutorialState$: Observable<TutorialState> = this.currentTutorialState.asObservable();

  constructor(
    private _userService: UserService,
    private _loggingService: LoggingService,
    private dialog: MatDialog
  ) { }

  private getUserPreferences(): Observable<UserPreferences> {
    return from(this._userService.getUserPreferences());
  }

  /**
   * This updates the current tutorial state if it is ok to update.
   *
   * @param state: any the response from EVA
   * @return               promise from Firebase
   */
  public updateCurrentTutorialState(tutorialState: TutorialState): void {
    // if what we are trying to save is not different than what we current have in our store, discard.
    if (tutorialState.currentlyPresenting === this.lastTutorialStateValue.currentlyPresenting) {
      // then do not announce or allow the update. This should always go from presenting to not presenting.
      return;
    } else {
      // allow the update and announce it to the site.
      this.lastTutorialStateValue = tutorialState;
      this.currentTutorialState.next(this.lastTutorialStateValue);
    }
  }

  /**
   * marks a tutorial as completed in user prefs and loads the next tutorial if exists
   *
   * @param tutorialName - the name of the tutorial to be marked complete
   */
  async tutorialComplete(tutorialId: string): Promise<void> {
    const userPreferences = await this.getUserPreferences().toPromise();
    const latestVersion = environment.defaultTutorials.version;

    if (!this.doesUserHaveTutorials) {
      // if user does not have tutorials, create this now.
      userPreferences['tutorials'] = {
        tutorialList: {
          [tutorialId]: true
        },
        version: latestVersion
      };
    } else {
      // tutorials found in users preferences.
      userPreferences.tutorials.tutorialList[tutorialId] = true;
      userPreferences.tutorials.version = latestVersion;
    }

    try {
      await this._userService.updateUserPreferences(userPreferences);
      this.tutorialIdActive.next(null);
    } catch (err) {
      console.error(err);
      this.tutorialIdActive.next(null);
      this._loggingService.logMessage('Error updating the tutorial object.', false, 'error');
    }
  }

  private createIntroJsInstance(config: TutorialIntroJsConfig) {
    return introJs.introJs().setOptions(config);
  }

  private doesUserHaveTutorials(preferences: UserPreferences): boolean {
    return !!(preferences && preferences.tutorials && preferences.tutorials.tutorialList);
  }

  private hasUserSeenTutorial(preferences: UserPreferences, tutorialId: string): boolean {
    return !!(preferences.tutorials.tutorialList[tutorialId]);
  }

  private ifUserTutorialsOutOfDate(preferences: UserPreferences): boolean {
    return preferences.tutorials.version !== environment.defaultTutorials.version;
  }

  public showTutorial(tutorialId: string): Observable<boolean> {
    const tutorialShow$ = this.getUserPreferences().pipe(
      map((preferences) => {
        // tutorial decision time!...

        // check if the user even has a tutorials object. If not, we can safely show tutorial.
        if (!this.doesUserHaveTutorials(preferences)) {
          // user does not have tutorial in their preferences. show this tutorial.
          this.startTutorialById(tutorialId);
          this.tutorialIdActive.next(tutorialId);
          return true;
        }

        // if user has tutorial object in preferences, check if tutorialId exists or is false. If not, show tutorial.
        if (!this.hasUserSeenTutorial(preferences, tutorialId)) {
          this.startTutorialById(tutorialId);
          this.tutorialIdActive.next(tutorialId);
          return true;
        }

        // if user has tutorial object in preferences, but tutorial version is different, show tutorial.
        if (this.ifUserTutorialsOutOfDate(preferences)) {
          this.startTutorialById(tutorialId);
          this.tutorialIdActive.next(tutorialId);
          return true;
        }

        this.tutorialIdActive.next(null);
        return false;
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );

    if (this.tutorialIdActive.getValue() !== null) {
      return this.tutorialIdActive.pipe(
        // wait until the active tutorial is cleared.
        filter(active => active === null),
        take(1),
        tap(() => {
          this.tutorialIdActive.next(tutorialId);
        }),
        switchMap(() => {
          return tutorialShow$;
        })
      );
    }

    this.tutorialIdActive.next(tutorialId);
    return tutorialShow$;
  }

  private presentIntroJs(tutorialId: string, config: TutorialIntroJsConfig) {
    this.createIntroJsInstance(config).onexit(async () => {
      // mark tutorial as complete once exited.
      await this.tutorialComplete(tutorialId);
    }).start();
  }

  private startTutorialById(tutorialId: string): any {
    const tutorial = availableTutorials[tutorialId];

    // show our tutorial dialog if this tutorial contains a dialog.
    if (tutorial.dialogConfig) {
      const tutorialDialogRef = this.dialog.open(TutorialIntroComponent, tutorial.dialogConfig);

      // track all our subscribes to actions that can happen in the tutorial intro dialog component.
      const dialogSubscriptions = new Subscription();

      // subscribe if user is interested in taking tutorial
      dialogSubscriptions.add(
        tutorialDialogRef.componentInstance.onLaunchIntro.subscribe(() => {
          if (tutorial.introJsConfig) {
            this.presentIntroJs(tutorialId, tutorial.introJsConfig);
          }
          dialogSubscriptions.unsubscribe();
        })
      );

      // subscribe if user wants to skip tutorial
      dialogSubscriptions.add(
        tutorialDialogRef.componentInstance.onSkipTutorial.subscribe(async () => {
          await this.tutorialComplete(tutorialId);
          dialogSubscriptions.unsubscribe();
        })
      );
      return;
    }

    if (tutorial.introJsConfig) {
      // initialize instance of introJS and watch for completion.
      this.presentIntroJs(tutorialId, tutorial.introJsConfig);
      return;
    }
  }
}
