<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content class="dialog-box">
  <div>
      <form [formGroup]="teamMembersForm">
        <div formArrayName="teamMembers">
          <button (click)="addTeamMember()"><i class="fas fa-plus"></i> Add</button><br/>
          <div *ngFor="let teamMember of teamMembersForm.get('teamMembers')['controls']; let i=index" draggable="true" (dragstart)="onDrag($event, i)" (drop)="reorderMembers($event, i)" (dragover)="allowdrop($event)">
              <div [formGroupName]="i">
                <fieldset>
                  <legend class="order-button" (click)="hideMemberInfo()">
                    <h5>
                      <span class="item-heading">Team Member {{ i+1 }}</span> &nbsp;
                      <button (click)="deleteTeamMember(i)"><i class="fas fa-trash-alt"></i></button>
                      <span *ngIf="hideInfo">&nbsp; {{ teamMember.value.name }}</span>
                      <span class="order-button" *ngIf="hideInfo">Expand <i class="fas fa-plus"></i></span>
                      <span class="order-button" *ngIf="!hideInfo">Shrink <i class="fas fa-minus"></i></span>
                    </h5>
                  </legend>
                  <div *ngIf="!hideInfo">
                    <mat-form-field class="full-width-input">
                      <input matInput type="text" formControlName="name" placeholder="Name" required>
                      <mat-error *ngIf="teamMember.get('name').hasError('required')">
                        Name Required
                      </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="full-width-input">
                      <input matInput type="text" formControlName="position" placeholder="Position" required>
                      <mat-error *ngIf="teamMember.get('position').hasError('required')">
                        Position Required
                      </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="full-width-input">
                      <input matInput type="text" formControlName="contactMethods" placeholder="Contact Methods" required>
                      <mat-error *ngIf="teamMember.get('contactMethods').hasError('required')">
                          Contact Methods Required
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="full-width-input">
                      <textarea matInput type="text" formControlName="description" placeholder="Description" required></textarea>
                      <mat-error *ngIf="teamMember.get('description').hasError('required')">
                        Description Required
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width-input">
                      <input matInput class="file-input" type="text" formControlName="imageUrl" placeholder="Image" required>
                      <div class="dropzone" [class.drag-over]="fileDragged" [class.drag-leave]="!fileDragged" (drop)="fileDrop($event, i)" (dragover)="onFileDragOver($event)" (dragleave)="onFileDragLeave($event)">
                        <div class="text-wrapper">
                          <div class="centered">Drop your file here!</div>
                        </div>
                      </div>
                      <mat-error *ngIf="teamMember.get('imageUrl').hasError('required')">
                        Image Required
                      </mat-error>
                    </mat-form-field>
                      <div class="dropzone-list">
                        <span *ngIf="fileList[i]">{{ fileList[i].name }}</span>
                        <span *ngIf="teamMember.value.imageUrl && teamMember.value.imageUrl !== ' '">Image URL: {{ teamMember.value.imageUrl }}</span>
                      </div>
                    <input class="file-input" type="file" #imageFileInput (change)="onFileChange($event)">
                  </div>
                </fieldset>
              </div>
          </div>
        </div>
      </form>
    </div>
</div>
<div mat-dialog-actions>
  <button (click)="finalizeTeamMembers()" mat-raised-button color="primary" type="submit" [disabled]="!teamMembersForm.valid || submitting">{{ dialogData.confirmBtnTxt }}</button>
  <button mat-button color="accent" (click)="onCancelClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
</div>