import {
  AfterViewInit,
  Component,
  Input, OnDestroy
} from '@angular/core';
import { ProcessService } from '@eva-services/process/process.service';
import {
  ProcessEdit,
  ProcessEditAction,
  ProcessEditDetails
} from '@eva-model/process/processEdit';
import {
  CompactProcess,
  CompactProcessInteraction,
  CompactProcessInteractionElement,
  CompactProcessInteractionScreeen
} from '@eva-model/process/compactProcess';
import { Process } from '@eva-model/process/process';
import { CompactProcessService } from '@eva-services/process/compact-process.service';
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from '@ng-dynamic-forms/core';
import { isString } from 'lodash';
import { Subscription } from 'rxjs';
import { WindowScrollingService } from '@eva-services/window-scrolling/window-scrolling.service';
import { ChatService } from '@eva-services/chat/chat.service';

@Component({
  selector: 'app-process-edit-summary',
  templateUrl: './process-edit-summary.component.html',
  styleUrls: ['./process-edit-summary.component.scss']
})

export class ProcessEditSummaryComponent implements AfterViewInit, OnDestroy {
/**
 * when the component loads, get all the interactions and all of the interaction values within the workflow.
 * stores summary data in this.compactProcess
 * Assumes there will only be one workflow
 * Accepts a process, then compacts it to store only relevant data for summary
 */
  @Input()
  set processObj(processObj: Process) {
    this.mainProcess = processObj;
    this.compactProcess = this._compactProcessSrv.processCompactor(processObj);

    // eslint-disable-next-line max-len
    // Chedck to see if there is no editable interaction then it submit the process which actually send it back to process runner component and it calls destination analyzer again
    if (!( this.compactProcess &&
        this.compactProcess.interactions &&
        Array.isArray(this.compactProcess.interactions) &&
        this.compactProcess.interactions.length > 0 )) {

      this.submitProcess();
    }

    //#region :: Check for any element of type CheckBox_Group to only show the sub checkbox control labels which are checked

    // for each element in each screen in each interaction, check if it is a checkbox group
    this.compactProcess.interactions.forEach( (intrct: CompactProcessInteraction) => {
      intrct.screens.forEach( (screen: CompactProcessInteractionScreeen) => {
        screen.elements.forEach( (element: CompactProcessInteractionElement) => {
          if ( element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP ) {

            let elementCheckBoxGroupValue = '';
            if ( element.value && Array.isArray(element.value) && element.value.length > 0 ) { // check if checkbox group has values
              const trueCheckBoxArray = element.value.filter( obj => obj.value === true); // store values that were checked as true
              if ( Array.isArray(trueCheckBoxArray) && trueCheckBoxArray.length > 0 ) { // check if trueCheckBoxArray has values
                elementCheckBoxGroupValue = trueCheckBoxArray.map( obj => obj.label ).toString(); // map trueCheckBoxArray values to label
              }
            }
            element.value = elementCheckBoxGroupValue; // set element value to array of checkbox values marked as true for display
          }
        });
      });
    });
    //#endregion

  }

  compactProcess: CompactProcess; // stores the relevant summary data from the process
  mainProcess: Process; // set via input. stores the process object from the workflow
  componentSubs: Subscription = new Subscription();

  constructor(
    private _processSrv: ProcessService,
    private _compactProcessSrv: CompactProcessService,
    private windowScrollingService: WindowScrollingService,
    private chatService: ChatService
  ) {}

  ngAfterViewInit(): void {
    // EVA-3115: forces scroll to top
    setTimeout(() => {
      const mainContainerEle = document.getElementsByClassName("main-container")[0];
      if (mainContainerEle) {
        mainContainerEle.scrollTo(0, 0);
      }

      // forces window-scrolling service to check dimensions
      const leftPaneEle = document.getElementsByClassName("left-pane")[0];
      if (leftPaneEle) {
        leftPaneEle.dispatchEvent(new CustomEvent('scroll'));
      }

      this.componentSubs.add(this.chatService.isChatMinimized$.subscribe(value => {
        setTimeout(() => this.updateSubmitButtonPosition(), 500);
      }));

      this.componentSubs.add(
        this.windowScrollingService.initialize('scrollable-container').subscribe(scrollPosition => {
          this.updateSubmitButtonPosition();
        })
      );
    }, 1);
  }

  updateSubmitButtonPosition() {
    if (window.innerWidth > 600) {
      const rect1 = document.getElementsByClassName('sticky-process-action-bar')?.[0]?.getBoundingClientRect();
      const rect2 = document.getElementsByClassName('minimized-chat')?.[0]?.getBoundingClientRect();
      if (!rect1 || !rect2) {
        return;
      }
      const overlap = !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom);
      if (overlap) {
        (document.getElementsByClassName('action-button-right')[0] as HTMLElement).style.marginRight = '80px';
      } else {
        (document.getElementsByClassName('action-button-right')[0] as HTMLElement).style.marginRight = '0px';
      }
    }
  }

  /**
   * return a compacted element value
   * @param element the element for compaction
   */
  getCompactElementValue(element: CompactProcessInteractionElement) {
    let retValue = element.value;

    const prefix = (element.prefix && element.prefix.length && element.prefix.length > 0) ? element.prefix : null;
    const suffix = (element.suffix && element.suffix.length && element.suffix.length > 0) ? element.suffix : null;

    if ( isString(element.value) ) {
      if ( prefix ) {
        retValue = prefix + retValue;
      }

      if ( suffix ) {
        retValue = retValue + suffix;
      }
    }

    return retValue;
  }

  /**
   * returns an interaction object based on the process and interactionId passed into the function
   * @param process the running process
   * @param interactionOriginalId the id of the interaction to be returned
   */
  getInteraction( process: Process, interactionOriginalId: string ): Object  {
    let interaction = null;
    if ( !( process && process.workflows &&
      Array.isArray(process.workflows) &&
      process.workflows.length > 0 &&
      process.workflows[0].interactions ) ) {
        return interaction;
    }

    const processInteractions = process.workflows[0].interactions;

    // get location of selected interaction in processInteractions array
    const interactionIndex = processInteractions.map(prcsIntrct => prcsIntrct.interactionId).indexOf(interactionOriginalId);

    // if found, store in interaction variable then return to goToInteraction function
    if ( interactionIndex !== -1) {
      interaction = processInteractions[interactionIndex].interaction;
    }

    return interaction;
  }

  /**
   * Announces selected interaction to subscription processEditSummaryDone in the process-runners ngOninit.
   * Notifies process runner to submit the process, sends ProcessEdit object marked as 'submit'
   */
  submitProcess(): void {
    const processEditDetail = new ProcessEditDetails(null, null, null, this.mainProcess.id);
    const submitProcessObj = new ProcessEdit(ProcessEditAction.submit, processEditDetail);
    this._processSrv.announceProcessEditSummaryDone(submitProcessObj);
  }


  /**
   * function that runs when user selects an interaction
   * stores interaction details to be sent to process-runner by processEditSummaryDone$ subscription
   *
   * @param interactionOriginalId original id of the interaction selected
   * @param interactionPosition the interactions position within the workflow. Corresponds to order number in InteractionValues
   * @param formScreenPosition position of the formscreen within the interaction
   */
  goToInteraction(interactionOriginalId: string, interactionPosition: number, formScreenPosition: number): void {

    // store the details for the selected interaction
    const selectedInteraction = this.getInteraction( this.mainProcess, interactionOriginalId );

    // store found details into model
    const processEditDetail = new ProcessEditDetails(selectedInteraction, interactionPosition, formScreenPosition, this.mainProcess.id);
    const editProcessObj = new ProcessEdit(ProcessEditAction.edit, processEditDetail);

    // announce selected interaction to observable in process-runners ngOninit
    // notifies process runner to edit the process
    this._processSrv.announceProcessEditSummaryDone(editProcessObj);
  }

  ngOnDestroy(): void {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
    // EVA-3115: forces scroll to top
    setTimeout(() => {
      const mainContainerEle = document.getElementsByClassName("main-container")[0];
      if (mainContainerEle) {
        mainContainerEle.scrollTo(0, 0);
      }
    }, 1);
  }
}
