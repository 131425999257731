import { Component, ViewChild } from '@angular/core';
import { ProcessCompareService } from '@eva-services/process/process-compare.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { UtilsService } from '@eva-services/utils/utils.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {MessageService} from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'eva-process-compare',
  templateUrl: './process-compare.component.html',
  styleUrls: ['./process-compare.component.scss'],
  providers: [MessageService]
})
export class ProcessCompareComponent {

  prcsReportData = [];
  isWaiting = false;
  onWaitMessage: string = null;

  public prcsReportGridColumnDefs = [
    { headerName: 'Created at', field: 'createdAt', width: 190, resizable: true, sortable: true, filter: true, checkboxSelection: true },
    { headerName: 'Process Id', field: 'processId', width: 300, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Issue', field: 'issue', width: 260, enableRowGroup: true,
      resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Message', field: 'message', width: 400, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Updated at', field: 'updatedAt', width: 180, resizable: true, sortable: true, filter: true }
  ];
  @ViewChild('evaLogsGrid') evaLogsGrid: AgGridAngular;

  public prcsReportGridOptions: GridOptions;

  prcsIssueReportFormGrp: UntypedFormGroup;
  fromDateTime: number;
  toDateTime: number;
  componentTitle: string;

  constructor(
    private fb: UntypedFormBuilder,
    private _ProcessCompareSrv: ProcessCompareService,
    private _arrayUtilsSrv: ArrayUtilsService,
    private _utils: UtilsService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.prcsReportGridOptions = <GridOptions>{};
    this.prcsReportGridOptions.columnDefs = this.prcsReportGridColumnDefs;

    this.prcsReportGridOptions.animateRows = true;
    this.prcsReportGridOptions.pagination = true;
    this.prcsReportGridOptions.paginationPageSize = 15;

    this.prcsReportGridOptions.suppressDragLeaveHidesColumns = true;
    this.prcsReportGridOptions.suppressMakeColumnVisibleAfterUnGroup = true;
    this.prcsReportGridOptions.rowGroupPanelShow = 'always';
    this.prcsReportGridOptions.groupUseEntireRow = true;

    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });

    this.createForm();
  }

  createForm() {
    this.prcsIssueReportFormGrp = this.fb.group({
      fromDateTime: ['', Validators.required],
      toDateTime: ['', Validators.required]
    });
  }

  onFromDateTimeChange(ev) {
    this.fromDateTime = new Date(ev.target.value).getTime();
  }

  onToDateTimeChange(ev) {
    this.toDateTime = new Date(ev.target.value).getTime();
  }

  async getProcessReport() {

    let detailMessage = 'No process issue found at this moment';

    try {

      if ( !(this.fromDateTime && this.toDateTime && this.toDateTime >= this.fromDateTime) ) {
        detailMessage  = '';
        if (!this.fromDateTime) {
          detailMessage += "'From' date time should be valid. \r\f";
        }

        if (!this.toDateTime) {
          detailMessage += "'To' date time should be valid. \r\f";
        }

        if (this.toDateTime < this.fromDateTime) {
          detailMessage += "'From' date time should be less than or equal to 'To' date time. \r\f";
        }

        this.messageService.clear()
        this.messageService.add({
          life: 7000,
          severity: 'warn',
          summary: 'Processes issues report',
          detail: detailMessage
        });

        return;
      }

      this.isWaiting = true;
      this.onWaitMessage = 'Generating process issues report';

      await this.processCompareReport(this.fromDateTime, this.toDateTime);

      if ( this.prcsReportData && Array.isArray( this.prcsReportData ) && this.prcsReportData.length > 0 ) {
        detailMessage = `Processes report is ready with ${this.prcsReportData.length} issue${this.prcsReportData.length > 1 ? 's' : ''}.`;
      }

    } catch ( err ) {

      detailMessage = 'Encountered with error';
      throw err;

    } finally {

      this.isWaiting = false;

      this.messageService.clear()
      this.messageService.add({
        life: 7000,
        severity: 'info',
        summary: 'Processes issues report',
        detail: detailMessage
      });

    }

  }

  async processCompareReport(from: number, to: number) {
    const processReport = await this._ProcessCompareSrv.getProcessCompareReport(from, to);
    // TODO :: extra necessary things to do

    let dataArray = [];
    this.prcsReportData.length = 0;

    if (processReport) {
      processReport.forEach( (prcsItem: any) => {
        const log = {
          processId: prcsItem.processId,
          createdAt: this._utils.convertTimestampToDate(prcsItem.createdAt),
          updatedAt: this._utils.convertTimestampToDate(prcsItem.updatedAt),
          issue: prcsItem.issue,
          message: prcsItem.message
        };
        dataArray.push(log);
      });
    }

    dataArray = this._arrayUtilsSrv.uniqueArray(dataArray);
    this.prcsReportData = this._arrayUtilsSrv.arrayObjectCustomSort(dataArray, 'createdAt', 'DESC');
  }

  // TODO
  // actionOnSelectedRowsConfirm() {
  // }

  //#region Agnostic grid events
  public prcsReportGridReady() {
    // On Grid Ready
  }

  public prcsReportGridModelUpdated() {
    // On Grid Model Updated
  }

  public prcsReportGridRowGroupOpened() {
    // On Grid row group Expand/Collapse
  }

  public prcsReportGridFirstDataRendered() {
    // On Grid first data rendering
  }

  public prcsReportGridRowDataChanged() {
    // On Grid row data change
  }
  //#endregion

}
