<button 
  [class.hideButton]="buttonType !== 'Download'" 
  [class.showButton]="buttonType === 'Download'" 
  mat-icon-button 
  matTooltip="Export"
  (click)="downloadJSON()">
  <mat-icon aria-label="Export">save_alt</mat-icon>
</button>
<button
  mat-raised-button
  color="primary"
  [class.hideButton]="buttonType !== 'Upload'"
  [class.showButton]="buttonType === 'Upload'"
  (click)="uploadJSON()">
  Import Interaction
  <input type="file" class="upload-file" #uploadJSONFile (change)="uploadFile($event)" />
</button>