export class DynamicSearch {

    constructor(
      public dynamicSearchCriteria: DynamicSearchCriteria[]
    ) { }

    // This provides the object that is a Query Fn type.
    getQueryFn() {
        const arrowFunction = ref => {
            // let ref;
            this.dynamicSearchCriteria.forEach(dynamicSearchCriteria => {
                if (dynamicSearchCriteria.type === 'where') {
                    ref = ref.where(dynamicSearchCriteria.field, dynamicSearchCriteria.operator, dynamicSearchCriteria.match);
                }
                if (dynamicSearchCriteria.type === 'orderBy') {
                    ref = ref.orderBy(dynamicSearchCriteria.field);
                    // , (dynamicSearchCriteria.field) ? dynamicSearchCriteria.field : 'desc');
                }
                if (dynamicSearchCriteria.type === 'limit') {
                    ref = ref.limit(dynamicSearchCriteria.match);
                }
                if (dynamicSearchCriteria.type === 'startAt') {
                    ref = ref.startAt(dynamicSearchCriteria.match);
                }
            });
            // add in start at and end at.
            return ref;
        };
        return arrowFunction;
    }
}

export const SEARCH_CRITERIA_TYPE = {
    where: 'where',
    orderBy: 'orderBy',
    limit: 'limit',
    startAt: 'startAt',
    startAfter: 'startAfter',
    endAt: 'endAt',
    endBefore: 'endBefore'
};
export class DynamicSearchCriteria {
    constructor(
        public field: string,
        public type: string,
        public match?: string | number | boolean,
        public operator?: string,
    ) {}
}
