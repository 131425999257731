import { ComponentRef } from "@angular/core";
import { KnowledgeComponent } from "@eva-ui/admin-overview/knowledge/knowledge/knowledge.component";

/**
 * The document interface contains a reference to the knowledge that has been uploaded.
 */
export interface KnowledgeDocument {
    groupPublicKey: string; // the group that uploaded the data.
    name: string; // the name of the doc
    sections: KnowledgeDocumentSection[]; // one or many sections.
    draft: boolean; // whether it is ingested by ML or not
    nodeIds: string[]; // this contains an array of all sections within the document by their location.
    // this contains an array of keys of groups that the knowledge needs to flow through to successfully publish
    approvalGroups: string[];
    updatedBy?: string; // key of the user
    id?: string; // assigned from firebase functions
    updatedDate?: number; // timestamp in javascript.
    createdBy?: string; // key of the user
    createdDate?: number; // timestamp in javascript.
    updateByTrigger?: boolean; // used for replication -- this should be false coming from the system.
    nextId?: number; // the next ID number for the base section
    documentHash?: string; // the hash of all of the documents sections from the last update.
    versionNumber?: number; // the version number of the document.
    text?: string; // this is the text that is in a document before any sections.
    revisionNote?: string; // user provided note about why this revision was created.
}

/**
 * This contains the document sections that go with a wiki document.
 */
export interface KnowledgeDocumentSection {
    title: string; // HTML - section title (<h2>title</h2>)
    id?: string; // id of the section.
    text?: string; // HTML - the text in the section. If no text, this isn;'t included.
    sections?: KnowledgeDocumentSection[]; // additional sections
    nextId?: number; // the next ID number for the section
    sectionHash?: string; // hash of the section.
}

/**
 * Flattened section from a doc.
 */
export interface KnowledgeDocumentSectionFlat {
    title: string; // HTML - section title (<h2>title</h2>)
    titlePath: string; // if section is a child, this is a delimitted string of it's parent titles all the way to root.
    id: string; // id of the section.
    text: string; // HTML - the text in the section. If no text, this isn;'t included.
}

/**
 * This contains the interface for the base knowledge document and what is needed to
 * update for the user.
 */
export interface BaseKnowledgeDocument {
    groupPublicKey: string; // public key of the group that owns the knowledge document.
    name: string; // the name of the doc
    id: string; // assigned from firebase functions
    // this contains an array of public keys of groups that the knowledge needs to flow through to successfully publish
    approvalGroups: string[];
    lastEditVersion: number; // the edited version
    versions: number[]; // all the versions of the document that are saved.
    publishedVersions: number[]; // this contains the published versions that were passed to the AI
    updatedDate: number; // timestamp in javascript.
    updatedBy: string; // public key of the user
    updateByTrigger: boolean; // used for replication -- this should be false coming from the system.
    createdBy: string; // public key of the creating user.
    createdDate: number; // javascript timestamp of the base document creation.
    isDeleted: boolean; // is deleted, also used in algolia knowledge search result
    lastPublishedName?: string; // this is the published Name of the document (the last ingested by ML)
    lastPublishedBy?: string; // this is the the Public Key that published the document.
    lastPublishedVersion?: number; // the last published version.
    mlDataBucketUpdateNeeded?: boolean; // this is set if a ml data bucket update is required.
}

/**
 * this contains the interface for a transaction to be stored in the eva heart project.
 */
export interface KnowledgeDocumentSaveTransaction {
    data: KnowledgeDocumentSaveData; // shape of knowledge for saving.
    signature: string; // the crypto signature of the document.
}

/**
 * This contains the shape for a knowledge object for the data service.
 */
export interface KnowledgeDocumentSaveData {
    publicKey: string; // the publicKey of the user
    timeStamp: number; // the time of the version.
    knowledgeDocument: KnowledgeDocument; // the document to save.
}

/**
 * Data required to get a knowledge doc from the backend.
 */
export interface KnowledgeDocumentGetTransaction {
    groupPublicKey: string; // this is the group public key that owns the knowledge
    knowledgeDocId: string; // this is the document Id that is assigned to the knowledge
    publishedVersion?: boolean; // whether or not to use the published version
    version?: string; // if you want a specific version.
    viewKey?: string; // to bypass a 401 if the document has been archived.
}

/**
 * This interface is used to contain the information that is returned when a knowledge document is found
 * on the backend systems and returned in a KnowledgeReturnObject and returned to the EVA system.
 */
export interface KnowledgeDocumentReturn {
    knowledgeDocument?: KnowledgeDocument; // the knowledge document returned.
    docId: string; // the ID of the knowledge document.
    versionNumber: number; // version that was created.
    publishedVersions: number[]; // any published versions
    versions: number[]; // all versions of doc.
    publicKey: string; // the owning public key
    isDeleted: boolean; // if the base document is deleted (archived)
}

/**
 * this interface is used to return the document ID of a knowledge document that has successfully been
 * created or updated.
 */
export interface KnowledgeSuccessID {
    docId: string; // the ID of the document that successfully completed the function on.
}

/**
 * Algolia knowledge doc structure, used for quickly searching the entire knowledge docs
 */
export interface KnowledgeSearchResult {
    approvalGroups: string[]; // pk of groups
    groupPublicKey: string; // group the doc bleongs to
    id: string; // algolia id
    lastEditVersion: number; // last edit timestamp
    name: string; // name of doc
    objectID: string; // document id in the database
    publishedVersions: number[]; // version published
    updateByTrigger: boolean;
    updatedBy: string; // pk of user
    updatedDate: number; // timestamp last updated
    versions: number[]; // all versions created (timestamp)
    isDeleted: boolean; // if it's deleted, we still keep the data
}

/**
 * Interface for what the delete knowledge image function expects.
 */
export interface KnowledgeRemoveImages {
    groupPublicKey: string; // pk the doc belongs to
    docId: string; // the doc id itself
    version: number; // timestamp
    removed: KnowledgeRemovedImage[]; // array obj of image urls
}

/**
 * Removed image source stored in a doc
 */
export interface KnowledgeRemovedImage {
    imageSrc: string;
}

/**
 * Used for DIFFing and viewing revisions. We need to track the current version
 * vs what the user has selected.
 */
export interface KnowledgeVersionsDraftPublished {
    version: number; // date.now
    currentVersion: boolean;
    published: boolean;
}

/**
 * If there are old form urls, we get the new urls as JSON.
 * This is JSON format:
 */
export interface KnowledgeFormUrlReplacementJSON {
    [oldUrl: string]: string; // url to replace is key, new url is value
}

/**
 * if the image was replaced, both old url and new url will be populated.
 */
export interface KnowledgeFormUrlReplacementResponse {
    oldUrl: string; // old url that needs replacing
    newUrl: string; // new url to replace old url with
}

/**
 * Froala editor is initialized when we want, so the controls are typed.
 */
export interface KnowledgeEditorControls {
    initialize: () => void; // defined by froala
    destroy: () => void; // defined by froala
    getEditor: () => any; // defined by froala
}

/**
 * Shape of the object passed to the delete/restore knowledge dialog component
 */
export interface KnowledgeDeleteRestoreDialogData {
    groupPublicKey: string;
    docId: string;
    docName: string;
    isDeleted: boolean; // if the doc is currently deleted or not
}

/**
 * Data of the currently open document inside the Knowledge Multi component
 */
export interface OpenKnowledgeDocument {
    docId: string;
    docGroup: string;
    docVersion: number;
    docName: string | null;
    modelVersion: string | null;
    sectionId: string | null;
    promptForFeedback: boolean;
    loading: boolean;
    component?: ComponentRef<KnowledgeComponent>;
    published?: boolean;
    document?: KnowledgeDocument;
    viewKey?: string;
    dialogflowResponse?: any;
}

/**
 * Shape saved in algolia for a knowledge section
 */
export interface KnowledgeSearchRecord {
    isATB: boolean;
    docName: string;
    docId: string;
    docVersion: number;
    objectID: string; // algolia id
    sectionId: string | null;
    groupPublicKey: string;
    text: string;
    title: string;
    titlePath: string; // delimitted by a pipe, |
    updatedAt: number | null;
    access: {
        [groupPublicKey: string]: boolean
    };
    isPublished: boolean;
    modelVersion?: number; // added to document at index insertion time by task runner
    isDeleted?: boolean;
}

/**
 * Table of contents for knowledge documents
 */
export interface KnowledgeDocumentTableOfContents {
    documentId: string;
    headings: KnowledgeDocumentTableOfContentsItem[];
}

export interface KnowledgeDocumentTableOfContentsItem {
    title: string;
    depth: number;
    sectionId: string;
}

export interface KnowledgeSectionFocusAnnounce {
    docId: string;
    sectionId: string;
    highlight: boolean;
}
