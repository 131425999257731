import { ValueModifier } from "./search";

export interface GenericSearchFilter {
    filterName: string[];
    attribute: string;
    defaultValue?: any[];
    filterValues?: any[];
    disabled?: boolean;
    hidden?: boolean;
    type: GenericSearchFilterType;
    filterValueModifier?: ValueModifier;
    negatedDefaultValue?: boolean;
    nullDisplayValue?: string; // filter display text if the defaultValue is null
}

export type GenericSearchFilterType = 'date' | 'date-range' | 'select';

export enum GenericSearchView {
    Tiles,
    Grid
}
