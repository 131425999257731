<div *ngIf="_isAdministrator">
  <button mat-button color="primary" class="team-member-button" (click)="addEditTeamMembers()">Add / Edit Featured Team Members</button>
</div>

<div #teamMembersCard class="team-members-card">
  <h3 class="component-title">Featured Team Members</h3>

    <div class="team-card-content">
      <div class="team-card-container">
        <div class="employee-card" *ngFor="let member of filteredArrayOfMembers(); let i = index">
          <div *ngIf="member">
              <div class="content">
                <div class="content-overlay"></div>
                <img mat-card-image class="content-image" [src]="member.imageUrl" [alt]="member.name">
                <div class="content-details fadeIn-bottom">
                  <h4 class="content-title">{{ member.name }}</h4>
                  <p>{{ member.position }}</p>
                  <p class="employee-description">{{ member.description }}</p>
                  <p>Contact Methods: {{ member.contactMethods }}</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="page-counter">
        <span *ngFor="let in of counter(); let i = index" class="page-counter-label">
          <a class="change-current-page" (click)="changeCurrentPage(i)" [class.selected]="i === currentPage">{{i+1}}</a> &nbsp;
        </span>
      </div>
    </div>
  </div>