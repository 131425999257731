import { Component, OnInit, Input } from '@angular/core';
import { WorkFlow, NodeColors, WorkflowInteraction, WorkFlowCondition } from '@eva-model/workflow';
import { Tree } from '@eva-ui/shared/Tree';
import { Node } from '@eva-ui/shared/Node';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Subscription, Subject } from 'rxjs';
import * as shape from 'd3-shape';
import * as _ from 'lodash';

@Component({
  selector: 'app-workflow-graph-visualizer',
  templateUrl: './workflow-graph-visualizer.component.html',
  styleUrls: ['./workflow-graph-visualizer.component.scss']
})
export class WorkflowGraphVisualizerComponent implements OnInit {

  NodeColors = NodeColors;                                              // List of Node colors for the graph
  center$: Subject<boolean> = new Subject();                            // Observable to center the ngx-graph
  zoomToFit$: Subject<boolean> = new Subject();                         // Observable to zoom to fit ngx-graph
  curve: shape.CurveFactory = shape.curveMonotoneX;                     // Shape of the links between nodes in ngx-graph
  nodes = [];                                                           // List of all the nodes in ngx-graph
  links = [];                                                           // List of all the links in ngx-graph
  index = 1;                                                            // Number for generating unique id's for nodes
  toggleLegend = false;                                                 // Toggles Legend in ngx-graph
  tree: Tree = null;                                                    // Tree object used for managing graph structure
  trueAddCondition = -1;                                                // index of the true condition for current interaction
  falseAddCondition = -1;                                               // index of the false condition for current interaction
  addInteraction: number[] = [];                                        // Array to manage all the interactions being added to the tree

  @Input() workflow: WorkFlow;                                          // workflow object
  @Input() containerGraph: HTMLElement;                                 // HTML Element containing this component
  @Input() interactionsByGroups: any;                                   // List of user interactions by groups from current environment
  currentWorkflow: WorkFlow = null;                                       // workflow object

  constructor(private evaGlobalService: EvaGlobalService) { }

  ngOnInit() {
    this.currentWorkflow = JSON.parse(JSON.stringify(this.workflow));
    this.generateEditNodes();
  }

  /**
   * This function center's the dynamic workflow viewer graph
   */
  centerGraph(): void {
    this.center$.next(true);
  }

  /**
   * This function zoom to fit dynamic workflow viewer graph and center's it
   */
  zoomToFitGraph(): void {
    this.zoomToFit$.next(true);
    this.centerGraph();
  }

  /**
   * This function returns the list for colors for nodes for legend in dynamic workflow viewer
   */
  getNodeColorKeys(): string[] {
    return Object.keys(this.NodeColors);
  }

  /**
   * This function toggles the collapse of nodes in ngx-graph
   *
   * @param node Node being collapsed
   */
  toggleTree(node: any): void {
    node.collapse = !node.collapse;
    this.updateNodesTree(node);
  }

  /**
   * This function adds and removes the nodes from ngx-graph on collapse
   *
   * @param node Node being collapsed
   */
  updateNodesTree(node: any): void {
    if (node.collapse) {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF((n: Node) => {
            if (node.id !== n.data.id) {
              this.nodes = this.nodes.filter(graphNode => graphNode.id !== n.data.id);
              this.links = this.links.filter(link => link.source !== n.parent.data.id && link.target !== n.data.id);
            } else {
              this.nodes.forEach(graphNode => {
                if (graphNode.id === node.id) {
                  graphNode.collapse = true;
                }
              });
            }
          }, node);
        }
      });
    } else {
      this.generateChildNodes(node);
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF((n: Node) => {
            if (node.id !== n.data.id) {
              this.nodes = [...this.nodes, n.data];
              this.links = [...this.links, { source: n.parent.data.id, target: n.data.id }];
            }
          }, node);
        }
      });
    }
  }

  /**
   * This function generates child nodes on clicking the node in the graph
   *
   * @param node Node clicked
   */
  generateChildNodes(node: any): void {
    let interaction: WorkflowInteraction = null;
    // get interaction represented by the node
    this.currentWorkflow.interactions.forEach(workflowInteraction => {
      if (node.label.includes(workflowInteraction.interactionName)
      && node.label.includes(new Date(workflowInteraction.interactionVersion).toLocaleString())) {
        interaction = workflowInteraction;
      }
    });

    // get parent id of the node clicked from the tree
    const parent = this.links.find(link => link.target === node.id);
    if (!parent || !interaction) {
      return;
    }

    let parentNode: Node = null;

    // get parent node for parent id
    this.tree.traverseNodeDF((treeNode: Node) => {
      if (treeNode.data.id === parent.source) {
        parentNode = treeNode;
      }
    }, this.tree.root.data);

    if (!parentNode) {
      return;
    }

    let childTree = [];
    let visitedNode = null;
    // copy children from already existed same interaction node and paste it to this node
    this.tree.traverseNodeDF((treeNode: Node) => {
      if (treeNode.data.label.includes(interaction.interactionName)
      && treeNode.data.label.includes(new Date(interaction.interactionVersion).toLocaleString())) {
        if (treeNode.children.length > 0) {
          childTree = treeNode.children;
          visitedNode = treeNode;
        }
      }
    }, this.tree.root.data);

    this.tree.traverseNodeDF((treeNode: Node) => {
      if (treeNode.data.label.includes(interaction.interactionName)
      && treeNode.data.label.includes(new Date(interaction.interactionVersion).toLocaleString())) {
        if (treeNode.children.length === 0)  {
          if (treeNode.parent.data.id !== parentNode.data.id || !visitedNode) {
            return;
          }

          if (childTree.length > 0) {
            childTree = _.cloneDeep(childTree);
          } else {
            return;
          }

          this.index++;
          childTree.forEach((child: Node) => {
            this.tree.traverseNodeDF((childNode: Node) => {
              childNode.data.id = '' + this.index;
              this.index++;
              if (childNode.data.color === this.NodeColors.interaction) {
                childNode.data.collapse = true;
                childNode.children = [];
                return childNode;
              }
            }, child, true);
          });
          childTree[0].parent.data.id = treeNode.data.id;
          treeNode.children = childTree;
        }
      }
    }, this.tree.root.data);
  }

  /**
   * This function generates the tree from workflow being edited
   */
  generateEditNodes(): void {
    this.nodes = [];
    this.links = [];
    this.links.length = 0;
    this.nodes.length = 0;
    this.index = 1;

    // Root of the tree
    const root = {
      id: '' + this.currentWorkflow.id + this.index,
      label: this.currentWorkflow.name,
      color: this.NodeColors.workflow
    };
    this.index++;
    this.tree = new Tree(root);
    this.currentWorkflow.interactions.forEach(interaction => {
      interaction.index = undefined;
      interaction.conditions.forEach(condition => {
        condition.index = undefined;
      });
    });
    this.addInteraction = [];
    if (this.currentWorkflow.interactions.length > 0) {
      this.currentWorkflow.interactions[0].index = this.index;
    } else {
      this.generateNodes();
      return;
    }
    this.index++;
    this.addInteraction.push(this.currentWorkflow.interactions && this.currentWorkflow.interactions.length > 0
      ? this.currentWorkflow.interactions[0].index : -1);

    this.updateTreeForEveryWorkflowInteraction();

    this.generateNodes();
  }

  /**
   * This function adds all workflow interaction nodes to the tree
   */
  updateTreeForEveryWorkflowInteraction(): void {
    this.currentWorkflow.interactions.forEach((interaction, interactionIndex) => {
      let interactionNode = {
        id: 'interaction' + this.index,
        label: interaction.interactionName + ' :: ' + new Date(Number(interaction.interactionVersion)).toLocaleString(),
        color: this.NodeColors.interaction
      };
      // if interaction already has index assigned, assign it to the node being added to the tree
      if (interaction.index) {
        interactionNode.id = 'interaction' + interaction.index;
      }
      // If first interaction being added, add it to the tree
      if (interactionIndex === 0) {
        this.tree.add(interactionNode, this.tree.root.data);
        interaction.index = this.index;
        this.index++;
      } else if (interaction.index && this.addInteraction.includes(interaction.index)) {
        // else if interaction already exists in tree, assign it's id to the current interaction node
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === 'interaction' + interaction.index) {
            interactionNode.id = treeNode.data.id;
          }
        });
      } else {
        // else if interaction is not in the tree and it's not first interaction, don't add it
        interactionNode = null;
        return;
      }

      this.updateTreeForEveryWorkflowInteractionCondition(interaction, interactionNode);
    });
  }

  /**
   * This function adds all workflow interaction condition nodes to the tree
   *
   * @param interaction Interaction containing the conditions
   * @param interactionNode tree node for interaction
   */
  updateTreeForEveryWorkflowInteractionCondition(interaction: WorkflowInteraction, interactionNode: any): void {
    this.trueAddCondition = interaction.conditions && interaction.conditions.length > 0 ? interaction.conditions[0].index : -1;
    this.falseAddCondition = -1;

    interaction.conditions.forEach((condition, conditionIndex) => {
      condition.selectedTrueConditionIndex = -1;
      condition.selectedFalseConditionIndex = -1;
      condition.selectedTrueInteractionVersion = -1;
      condition.selectedFalseInteractionVersion = -1;
      condition.selectedTrueGroups = [];
      condition.selectedFalseGroups = [];
      // If condition being added is in the true or false destinations, add it to the tree else don't add it
      if (this.trueAddCondition === condition.index || this.falseAddCondition === condition.index) {
        if (!condition.index && conditionIndex > 0) {
          return;
        }
        const conditionNode = {
          id: '' + this.index,
          label: condition.name,
          color: this.NodeColors.condition
        };
        const trueNode = {
          id: conditionNode.id + '-true',
          label: 'True (Not Linked)',
          color: this.NodeColors.true
        };
        const falseNode = {
          id: conditionNode.id + '-false',
          label: 'False (Not Linked)',
          color: this.NodeColors.false
        };
        if (condition.trueDestination.length > 0) {
          trueNode.label = 'True (Linked)';
        }
        if (condition.falseDestination.length > 0) {
          falseNode.label = 'False (Linked)';
        }
        // if interaction Node exists and it's the first condition, then add it to the tree
        if (interactionNode && conditionIndex === 0) {
          let conditionExist = false;
          this.tree.contains((treeNode: Node) => {
            if (treeNode.data.id === '' + condition.index) {
              conditionExist = true;
            }
          });
          // check if condition doesn't exists already, if it doesn't then add it
          if (!conditionExist) {
            condition.index = this.index;
            this.index++;
            this.tree.contains((treeNode: Node) => {
              if (treeNode.data.id === interactionNode.id) {
                if (treeNode.children.length === 0) {
                  this.tree.add(conditionNode, interactionNode);
                } else {
                  this.tree.traverseNodeDF((trNode: Node) => {
                    trNode.children.forEach(child => {
                      if (child.children.length === 0) {
                        this.tree.add(conditionNode, child.data);
                      }
                    });
                  }, treeNode.data);
                }
              }
            });
            this.tree.add(trueNode, conditionNode);
            this.tree.add(falseNode, conditionNode);
          }
        }

        this.updateTreeForEveryWorkflowInteractionConditionForTrueDestination(condition, interaction);

        this.updateTreeForEveryWorkflowInteractionConditionForFalseDestination(condition, interaction);
      }
    });
  }

  /**
   * This function adds all workflow interaction condition nodes for true destination to the tree
   *
   * @param condition Interaction condition containing true destinations
   * @param interaction Interaction object containing condition
   */
  updateTreeForEveryWorkflowInteractionConditionForTrueDestination(condition: WorkFlowCondition,
    interaction: WorkflowInteraction): void {
    condition.trueDestination.forEach((dest, destIndex) => {
      // if destination is an interaction
      if (dest.type === 'interaction') {
        this.updateTreeForInteractionTypeDestination(condition, dest, destIndex, '-true');
      } else if (dest.type === 'group') {
        // else if destination is a group
        this.updateTreeForGroupTypeDestination(condition, dest, destIndex, '-true');
      } else if (dest.type === 'condition') {
        // else if destination is a condition
        this.updateTreeForConditionTypeDestination(condition, interaction, dest, '-true');
      }
    });
  }

  /**
   * This function adds all workflow interaction condition nodes for false destination to the tree
   *
   * @param condition Interaction condition containing true destinations
   * @param interaction Interaction object containing condition
   */
  updateTreeForEveryWorkflowInteractionConditionForFalseDestination(condition: WorkFlowCondition,
    interaction: WorkflowInteraction): void {
    condition.falseDestination.forEach((dest, destIndex) => {
      // if destination is an interaction
      if (dest.type === 'interaction') {
        this.updateTreeForInteractionTypeDestination(condition, dest, destIndex, '-false');
      } else if (dest.type === 'group') {
        this.updateTreeForGroupTypeDestination(condition, dest, destIndex, '-false');
      } else if (dest.type === 'condition') {
        // else if destination is a condition
        this.updateTreeForConditionTypeDestination(condition, interaction, dest, '-false');
      }
    });
  }

  /**
   * This function adds interaction type destination to the tree
   *
   * @param condition Interaction condition containing true destinations
   * @param dest destination from true/false destinations from interaction condition
   * @param destIndex Index of the destination
   * @param conditionType type of the condition - true/false
   */
  updateTreeForInteractionTypeDestination(condition: WorkFlowCondition, dest: any,
    destIndex: number, conditionType: string): void {
      if (conditionType === '-true') {
      condition.selectedTrueInteractionVersion = dest.version;
    } else if (conditionType === '-false') {
      condition.selectedFalseInteractionVersion = dest.version;
    }
    const node = {
      id: dest.type + this.index,
      label: dest.name + ' :: ' + new Date(Number(dest.version)).toLocaleString(),
      color: this.NodeColors.interaction
    };
    if (conditionType === '-true') {
      condition.trueInteractionIndex = this.index;
    } else if (conditionType === '-false') {
      condition.falseInteractionIndex = this.index;
    }
    // if interaction already exists in interaction list of workflow, then assign the new index to the interaction
    this.currentWorkflow.interactions.forEach(workflowInteraction => {
      if (workflowInteraction.interactionName === dest.name && ('' + workflowInteraction.interactionVersion) === ('' + dest.version)) {
        workflowInteraction.index = this.index;
        this.addInteraction.push(workflowInteraction.index);
      }
    });
    this.index++;
    // if interaction is the first true/false destination, add it to the True/False destination node respectively
    if (destIndex === 0) {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === condition.index + conditionType) {
          if (treeNode.children.length === 0) {
            this.tree.add(node, { id: condition.index + conditionType });
          } else {
            treeNode.children.forEach(child => {
              const newTreeNode = this.tree.add(node, child.parent.data);
              const ind = child.parent.children.findIndex(ch => ch.data.id === child.data.id);
              child.parent.children.splice(ind, 1);
              child.parent = newTreeNode;
              newTreeNode.children.push(child);
            });
          }
        }
      });
    } else {
      // else find the parent node
      let parentDest = null;
      if (conditionType === '-true') {
        parentDest = condition.trueDestination[destIndex - 1];
      } else if (conditionType === '-false') {
        parentDest = condition.falseDestination[destIndex - 1];
      }
      let parentId = null;
      // if parent is a group, get list of all the groups
      if (parentDest.type === 'group') {
        const groupIndex = [];
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === condition.index + conditionType) {
            this.tree.traverseNodeDF((trNode: Node) => {
              trNode.children.forEach(child => {
                if (child.data.color === this.NodeColors.condition) {
                  return;
                } else if (child.data.color === this.NodeColors.group) {
                  groupIndex.push(child.data);
                }
              });
            }, treeNode.data);
          }
        });
        const parentIndex = groupIndex.findIndex(ind => ind.label === parentDest.name);
        if (parentIndex !== -1) {
          parentId = groupIndex[parentIndex].id;
        }
      }
      // if parent group exists, add the interaction to the group Node
      if (parentId) {
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === parentId) {
            if (treeNode.children.length === 0) {
              this.tree.add(node, treeNode.data);
            } else {
              this.updateChildren(treeNode, node, this.tree);
            }
          }
        });
      }
    }
    this.index++;
    // if interaction already exists in workflow, don't create new one
    if (this.currentWorkflow.interactions.find(workflowInteraction =>
      ('' + workflowInteraction.interactionVersion) === ('' + dest.version))) {
      const destinationInteraction = this.currentWorkflow.interactions.find(workflowInteraction =>
        ('' + workflowInteraction.interactionVersion) === ('' + dest.version));
      if (destinationInteraction && destinationInteraction.conditions.length > 0) {
        let conditionExist = false;
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === '' + destinationInteraction.conditions[0].index
            && treeNode.data.label === destinationInteraction.conditions[0].index) {
            conditionExist = true;
          }
        });
        // if condition doesn't exists already for the interaction, then add it to the tree
        if (!conditionExist) {
          if (!destinationInteraction.conditions[0].index) {
            destinationInteraction.conditions[0].index = this.index;
            this.index++;
          }
          const conditionTreeNode = {
            id: '' + destinationInteraction.conditions[0].index,
            label: destinationInteraction.conditions[0].name,
            color: this.NodeColors.condition
          };
          const trNode = {
            id: conditionTreeNode.id + '-true',
            label: 'True (Not Linked)',
            color: this.NodeColors.true
          };
          const falNode = {
            id: conditionTreeNode.id + '-false',
            label: 'False (Not Linked)',
            color: this.NodeColors.false
          };
          let interactionTreeNode = null;
          this.tree.contains((treeNode: Node) => {
            if (treeNode.data.label.includes(destinationInteraction.interactionName)
              && treeNode.data.label.includes(new Date(destinationInteraction.interactionVersion).toLocaleString())) {
              if (treeNode.children.length === 0) {
                interactionTreeNode = treeNode;
              }
            }
          });
          // if interaction node exists, check if condition for that interaction exists
          if (interactionTreeNode) {
            let conditionNodeExist = false;
            this.tree.contains((treeNode: Node) => {
              if (treeNode.data.id === conditionTreeNode.id) {
                conditionNodeExist = true;
              }
            });
            // if condition doesn't exists already, add it to the tree
            if (!conditionNodeExist) {
              if (destinationInteraction.conditions[0].trueDestination.length > 0) {
                trNode.label = 'True (Linked)';
              }
              if (destinationInteraction.conditions[0].falseDestination.length > 0) {
                falNode.label = 'False (Linked)';
              }
              this.tree.add(conditionTreeNode, interactionTreeNode.data);
              this.tree.add(trNode, conditionTreeNode);
              this.tree.add(falNode, conditionTreeNode);
            }
          }
        }
      }
      return;
    } else {
      // if interaction doesn't exists in workflow, add it to the interactions list
      let userInteraction = null;
      this.evaGlobalService.userGroups.forEach(group => {
        const interactions = this.interactionsByGroups[group.groupPublicKey];
        userInteraction = (interactions && Array.isArray(interactions)) ? interactions.find(workflowInteraction =>
          workflowInteraction['Versions-modified']?.find(version => version.version === dest.version)
          && workflowInteraction.name === dest.name) : null;
        if (userInteraction) {
          this.currentWorkflow.interactions.push(new WorkflowInteraction(userInteraction.id, userInteraction.name,
            Number(userInteraction.version), group.groupPublicKey, null, false, null, [], null, null, null,
            null, this.index));
        }
      });
    }
  }

  /**
   * This function adds group type destination to the tree
   *
   * @param condition Interaction condition containing true destinations
   * @param dest destination from true/false destinations from interaction condition
   * @param destIndex Index of the destination
   * @param conditionType type of the condition - true/false
   */
  updateTreeForGroupTypeDestination(condition: WorkFlowCondition, dest: any, destIndex: number, conditionType: string): void {
    if (conditionType === '-true') {
      if (!condition.selectedTrueGroups) {
        condition.selectedTrueGroups = [];
      }
    } else if (conditionType === '-false') {
      if (!condition.selectedFalseGroups) {
        condition.selectedFalseGroups = [];
      }
    }
    const groupData = this.evaGlobalService.userGroups.find(grp => grp.groupName === dest.name);
    if (groupData) {
      if (conditionType === '-true') {
        condition.selectedTrueGroups.push(groupData.groupPublicKey);
      } else if (conditionType === '-false') {
        condition.selectedFalseGroups.push(groupData.groupPublicKey);
      }
    }
    const node = {
      id: dest.type + this.index,
      label: dest.name,
      color: this.NodeColors.group
    };
    this.index++;
    // if group is the first true/false destination, add it to the True/False destination node
    if (destIndex === 0) {
      this.tree.add(node, { id: condition.index + conditionType });
    } else {
      // else find the parent node

      let parentDest = null;
      if (conditionType === '-true') {
        parentDest = condition.trueDestination[destIndex - 1];
      } else if (conditionType === '-false') {
        parentDest = condition.falseDestination[destIndex - 1];
      }
      let parentId = null;
      // if parent is a group, get list of all the groups
      if (parentDest.type === 'group') {
        const groupIndex = [];
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === condition.index + conditionType) {
            this.tree.traverseNodeDF((trNode: Node) => {
              trNode.children.forEach(child => {
                if (child.data.color === this.NodeColors.condition) {
                  return;
                } else if (child.data.color === this.NodeColors.group) {
                  groupIndex.push(child.data);
                }
              });
            }, treeNode.data);
          }
        });
        const parentIndex = groupIndex.findIndex(ind => ind.label === parentDest.name);
        if (parentIndex !== -1) {
          parentId = groupIndex[parentIndex].id;
        }
      } else if (parentDest.type === 'interaction') {
        // else if parent is an interaction, get parentId
        if (conditionType === '-true') {
          parentId = parentDest.type + condition.trueInteractionIndex;
        } else if (conditionType === 'false') {
          parentId = parentDest.type + condition.falseInteractionIndex;
        }
      }
      // if parent exists, add the group to it's corresponding position in the chain
      if (parentId) {
        this.tree.contains((treeNode: Node) => {
          if (treeNode.data.id === parentId) {
            if (treeNode.children.length === 0) {
              this.tree.add(node, treeNode.data);
            } else {
              this.updateChildren(treeNode, node, this.tree);
            }
          }
        });
      }
    }
  }

  /**
   * This function adds condition type destination to the tree
   *
   * @param condition Interaction condition containing true destinations
   * @param interaction Interaction object containing the condition
   * @param dest destination from true/false destinations from interaction condition
   * @param conditionType type of the condition - true/false
   */
  updateTreeForConditionTypeDestination(condition: WorkFlowCondition, interaction: WorkflowInteraction,
    dest: any, conditionType: string): void {
    // if condition doesn't exist, return
    const interactionCondition = interaction.conditions.find(workflowInteractionCondition =>
      workflowInteractionCondition.name === dest.name);
    if (!interactionCondition) {
      return;
    }
    let conditionExist = false;

    this.tree.contains((treeNode: Node) => {
      if (treeNode.data.id === '' + interactionCondition.index && treeNode.data.label === dest.name) {
        conditionExist = true;
      }
    });
    // check if condition already exists in the tree, if not then add it
    if (!conditionExist) {
      interactionCondition.index = this.index;
      if (conditionType === '-true') {
        this.trueAddCondition = interactionCondition.index;
        condition.selectedTrueConditionIndex = dest.name.split(' ').join('');
        condition.trueConditionIndex = interactionCondition.index;
      } else if (conditionType === '-false') {
        this.falseAddCondition = interactionCondition.index;
        condition.selectedFalseConditionIndex = dest.name.split(' ').join('');
        condition.falseConditionIndex = interactionCondition.index;
      }

      const node = {
        id: '' + interactionCondition.index,
        label: dest.name,
        color: this.NodeColors.condition
      };

      const trueConditionNode = {
        id: '' + node.id + '-true',
        label: 'True (Not Linked)',
        color: this.NodeColors.true
      };
      const falseConditionNode = {
        id: '' + node.id + '-false',
        label: 'False (Not Linked)',
        color: this.NodeColors.false
      };
      this.index++;
      if (interactionCondition.trueDestination.length > 0) {
        trueConditionNode.label = 'True (Linked)';
      }
      if (interactionCondition.falseDestination.length > 0) {
        falseConditionNode.label = 'False (Linked)';
      }
      this.tree.add(node, { id: condition.index + conditionType });
      this.tree.add(trueConditionNode, node);
      this.tree.add(falseConditionNode, node);
    }
  }

  /**
   * This is a recursive function to traverse through tree and update children of the trNode
   * @param trNode parentNode to which children are to be added
   * @param node new node data to be added
   * @param tree Tree being updated
   */
  updateChildren(trNode: Node, node: any, tree: Tree): void {
    if (!trNode) {
      return;
    }
    const conditionChild = trNode.children.find(child => child.data.color === this.NodeColors.condition);
    if (conditionChild) {
      tree.add(node, trNode.data);
      tree.contains((tNode: Node) => {
        if (tNode.data.id === node.id) {
          conditionChild.parent = tNode;
          tNode.children.push(conditionChild);
          tree.remove(conditionChild.data, trNode.data);
        }
      });
    } else {
      this.updateChildren(trNode.children[trNode.children.length - 1], node, tree);
    }
  }

  /**
   * This function generate nodes and links for ngx-graph based on tree
   *
   * @param workflowType Type of the workflow for which tree is being generated
   */
  generateNodes(): void {
    const nodes = [];
    const links = [];
    links.length = 0;
    nodes.length = 0;

    this.currentWorkflow.interactions.forEach(interaction => {
      let count = 0;
      this.tree.traverseNodeDF((treeNode: Node["data"]) => {
        if (treeNode.data.label.includes(interaction.interactionName)
          && treeNode.data.label.includes(new Date(interaction.interactionVersion).toLocaleString())) {
          count++;
        }
      }, this.tree.root, true);
      this.tree.traverseNodeDF((treeNode: Node["data"]) => {
        if (treeNode.data.label.includes(interaction.interactionName)
          && treeNode.data.label.includes(new Date(interaction.interactionVersion).toLocaleString())) {
          if (treeNode.children.length === 0 && count > 1) {
            treeNode.data.collapse = true;
          }
        }
      }, this.tree.root, true);
    });

    this.tree.traverseDF((treeNode: Node) => {
      nodes.push(treeNode.data);
      if (treeNode.parent) {
        links.push({
          source: treeNode.parent.data.id,
          target: treeNode.data.id
        });
      }
    });

    this.nodes = nodes;
    this.links = links;
  }
}
