import { Component, OnInit } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { BROWSERS } from 'ngx-device-detector';

@Component({
  selector: 'app-dead-end',
  templateUrl: './dead-end.component.html',
  styleUrls: ['./dead-end.component.scss']
})
export class DeadEndComponent implements OnInit {

  // Dead End component can be utilized for what ever condition that needs to redirect user to a place without extra navigation.
  // Dead End componnet view may consist of multiple blocks to show different messages based on buisness logic then it's expandable

  isIE = false;

  constructor(
    private evaGlobalService: EvaGlobalService   // Injected Eva Global Service to have access to the service members (Data or Methods)
  ) { }

  ngOnInit() {
    // Check if user using browser IE browser
    this.isIE = ( this.evaGlobalService.evaDeviceInfo.browser === BROWSERS.IE );
  }

}
