<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <span [innerHTML]="dialogData.content"></span>

  <ng-template appViewContainer></ng-template>

</div>
<div mat-dialog-actions>
  <div class="left-none">
    <button mat-stroked-button (click)="onNoClick()"
      *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    <button mat-raised-button color="primary" [class.confirmButtonEmpty]="dialogData.confirmBtnTxt.length === 0"
    [class.confirmButton]="dialogData.confirmBtnTxt.length > 0" (click)="saveClicked($event)"
      [disabled]="this.dialogData.confirmBtnValidation && isValid()"
      cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
  </div>
</div>