<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <span [innerHTML]="dialogData.content"></span>

  <ng-template appViewContainer></ng-template>
  
</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button color="accent" (click)="onNoClick()" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
  </div>
</div>