import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Observable } from 'rxjs';
import { YouTubeDocument } from '@eva-model/releaseNotes';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class LearnEVAVideosService {

  _learnEVADocPath = 'About/YouTubeLinks';

  constructor(private firestoreService: FirestoreService,
    private perf: AngularFirePerformance) {

  }

  /**
   * This function returns an observable of the youtube / eva learn videos document
   */
  getYouTubeDocument(): Observable<YouTubeDocument> {
    return this.firestoreService.doc$<YouTubeDocument>(this._learnEVADocPath).pipe(
      trace('learn-evavideos-getYouTubeDocument')
    );
  }

  /**
   * This function sets the youtube document in the database. Not an upsert, because all the
   * information for the form and shape comes back from the dialog.
   */
  storeYoutubeDocument(docData: YouTubeDocument) {
    return this.firestoreService.set(this._learnEVADocPath, docData);
  }
}
