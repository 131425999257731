import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { ChatService } from '@eva-services/chat/chat.service';
import { Observable, combineLatest } from 'rxjs';
import { EVAMenu } from '@eva-model/menu/menu';
import { MainMenu } from '@eva-model/menu/defaults/mainMenu';
import { AdministrationMenu } from '@eva-model/menu/defaults/administrationMenu';
import { LaunchPadComponent } from '@eva-ui/nav/launch-pad/launch-pad.component';
import { BuilderMenu } from '@eva-model/menu/defaults/builderMenu';
import { DashboardMenu } from '@eva-model/menu/defaults/dashboardMenu';
import { AIMenu } from '@eva-model/menu/defaults/aiMenu';
import { UserMenu } from '@eva-model/menu/defaults/userMenu';
import { Router } from '@angular/router';
import { AuthService } from '@eva-core/auth.service';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { backSVGIcon } from '@eva-model/menu/defaults/icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotificationsComponent } from '@eva-ui/notifications/notifications.component';
import { VersionCheckService } from '@eva-services/version-check/version-check.service';
import { NotificationsService } from '@eva-services/notifications/notifications.service';
import { AboutPageFeedbackComponent } from '@eva-ui/about-eva/about-page-feedback/about-page-feedback.component';
import { LoggingService } from '@eva-core/logging.service';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';
import { UserPreferences } from '@eva-model/UserPreferences';
import { UserService } from '@eva-services/user/user.service';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { LastStateService } from '@eva-services/last-state/last-state.service';

const menuMovementAnimation = trigger('menuMovement', [
  state('left', style(
    { opacity: 1 }
  )),
  state('right', style(
    { opacity: 1 }
  )),
  transition('left => *', [
    style({ opacity: 1, transform: 'translate3d(0%, 0, 0)', position: 'absolute', top: '0' }),
    animate('500ms ease-out', style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' }))
  ]),
  transition('right => *', [
    style({ opacity: 1, transform: 'translate3d(0%, 0, 0)', position: 'absolute', top: '0' }),
    animate('500ms ease-out', style({ opacity: 0, transform: 'translate3d(100%, 0, 0)' }))
  ]),
  transition('* => left', [
    style({ opacity: 0, transform: 'translate3d(100%, 0, 0)', position: 'absolute', top: '0' }),
    animate('500ms ease-out', style({ opacity: 1, transform: 'translate3d(0%, 0, 0)' }))
  ]),
  transition('* => right', [
    style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)', position: 'absolute', top: '0' }),
    animate('500ms ease-out', style({ opacity: 1, transform: 'translate3d(0%, 0, 0)' }))
  ])
]);

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [menuMovementAnimation]
})
export class LeftMenuComponent implements OnInit {
  currentMenuName = 'main'; // the name of the current menu that is being displayed.
  menus: EVAMenu[][] = []; // a collection of menu items that will be shown on the left / bottom
  userData$: Observable<{name: string, photoURL: string}>;
  mainMenu: EVAMenu[];
  appReload = false;
  evaVersion: string;
  reloadMessage = 'New version of EVA is available, please reload the page!';

  constructor(
    public menuNavigationService: MenuNavigationService,
    public chatService: ChatService,
    private dialog: MatDialog,
    public router: Router,
    private authService: AuthService,
    private multiViewService: MultiViewService,
    private changeDetection: ChangeDetectorRef,
    public notificationsService: NotificationsService,
    public versionCheckService: VersionCheckService,
    public loggingService: LoggingService,
    private launchPadService: LaunchPadService,
    private userService: UserService,
    private searchService: SearchAuthService,
    private lastStateService: LastStateService
  ) {
    this.mainMenu = MainMenu.filter(item => item.text !== 'Administration'
      && item.text !== 'Builders' && item.text !== 'AI'); // remove administration.

    this.authService.getUserEmail().then(async userEmail => {
      if (userEmail) {
        // const isChangeUser = await this.authService.isChangeUser();
        const isAdmin = await this.authService.isUserAdmin();
        // const isBuilder = await this.authService.isBuilder();
        let fullMenu = MainMenu;
        if (!isAdmin) {
          fullMenu = fullMenu.filter(item => item.text !== 'Administration'); // remove administration.
        }
        // if (!isBuilder) {
        //   fullMenu = fullMenu.filter(item => item.text !== 'Builders' && item.text !== 'AI'); // remove administration.
        // }
        setTimeout(() => {
          this.mainMenu = fullMenu;
        });
        if (this.currentMenuName === 'main') {
          this.updateMenu('main', true);
        }
      }
    });

    if (this.router.url.includes('admin')) {
      this.menuNavigationService.updateCurrentMenu('administration');
    } else if (this.router.url.includes('dashboards')) {
      this.menuNavigationService.updateCurrentMenu('dashboards');
    } else if (this.router.url.includes('builders')) {
      this.menuNavigationService.updateCurrentMenu('builders');
    } else if (this.router.url.includes('ai')) {
      this.menuNavigationService.updateCurrentMenu('ai');
    } else if (this.router.url.includes('user')) {
      this.menuNavigationService.updateCurrentMenu('user');
    }
    this.menuNavigationService.currentMenuName$.pipe(
      filter(currentMenuName => !!currentMenuName)
    ).subscribe(
      (currentMenuName) => {
        // update the current menu based on the information provided.
        this.currentMenuName = currentMenuName;
        this.switchMenus();
      }
    );
  }

  ngOnInit() {
    this.userData$ = combineLatest([this.userService.userPreferences$, this.authService.user]).pipe(
      map(([prefs, user]) => {
        const data = {name: '', photoURL: ''};
        if (prefs && prefs.name) {
          data.name = prefs.name;
        }
        if (user) {
          if (!data.name) {
            data.name = user.preferredName;
          }
          data.photoURL = user.photoURL;
        }
        return data;
      })
    )

    this.authService.user.subscribe(async user => {
      if (user) {
        this.notificationsService.getInvitations(user.email);
        this.notificationsService.getRequests(user.signingPublicKey); // commented to get other stuff.
        this.notificationsService.getNotifications();
        this.launchPadService.generateMenu();
      }
    });

    this.versionCheckService.checkVersion();

    this.versionCheckService.appReload$
    .subscribe(
      ( evaVersion ) => {
        this.appReload = true;
        this.evaVersion = evaVersion;

        this.loggingService.logMessage(this.reloadMessage, false, 'info', null, 10000);
      },
      ( err ) => console.log(err)
    );

    window.onunload = () => this.versionCheckService.setEVAVersion(this.evaVersion);
  }

  resetMainMenu() {
    this.menuNavigationService.updateCurrentMenu('main');
  }

  expandMenu() {
    this.menuNavigationService.toggleExpandedStatus();
  }

  switchMenus(): void {
    switch (this.currentMenuName) {
      case 'main':
        this.menus.push(this.getMainMenu());
        break;
      case 'administration':
        this.menus.push(this.getAdministrationMenu());
        break;
      case 'builders':
        this.menus.push(this.getBuilderMenu());
        break;
      case 'dashboards':
        this.menus.push(this.getDashboardMenu());
        break;
      case 'ai':
        this.menus.push(this.getAIMenu());
        break;
      case 'user':
        this.menus.push(this.getUserMenu());
        break;
      default:
        this.menus.push(this.getMainMenu());
        break;
    }

    if (this.menus.length > 1) {
      // run change detection before splicing to trigger animation
      this.changeDetection.detectChanges();
      // if there are 2 menus, remove the oldest menu. This is for animation purposes.
      this.menus.shift();
    }
  }

  logoutUser(): void {
    this.updateMenu('main');
    this.multiViewService.tabs = {};
    this.lastStateService.clearLastStateStore();
    this.searchService.clearTokens();
    this.chatService.clearChatEntities();
    this.authService.signOut();
  }

  /**
   * This function is used to open the launch pad.
   */
  clickLaunchPad(): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'launchpad-dialog',
      backdropClass: 'launchpad-backdrop',
      autoFocus: false,
      hasBackdrop: true,
      id: 'launchpad-dialog'
    };
    this.dialog.open(LaunchPadComponent, dialogConfig);
  }

  openNotifications(): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'notifications-dialog',
      backdropClass: 'notifications-backdrop',
      autoFocus: false,
      hasBackdrop: true,
      id: 'notifications-dialog'
    };
    this.dialog.open(NotificationsComponent, dialogConfig);
  }

  openFeedback(): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'feedback-dialog'
    };
    this.dialog.open(AboutPageFeedbackComponent, dialogConfig);
  }

  updateMenu(menuName: string, forceUpdate?: boolean): void {
    this.menuNavigationService.updateCurrentMenu(menuName, forceUpdate);
  }

  /**
   * This gets the main menu and returns the values to the application.
   */
  getMainMenu(): EVAMenu[] {
    return this.mainMenu;
  }

  /**
   * This gets the administration menu and returns the values to the application
   */
  getAdministrationMenu(): EVAMenu[] {
    return [
      { isBackButton: true, svgIcon: backSVGIcon, text: 'Back' },
      ...AdministrationMenu
    ];
  }

  /**
   * This gets the builder menu and returns the values to the application
   */
  getBuilderMenu(): EVAMenu[] {
    return [
      { isBackButton: true, svgIcon: backSVGIcon, text: 'Back' },
      ...BuilderMenu
    ];
  }

  /**
   * This gets the dashboard menu and returns the values to the application
   */
  getDashboardMenu(): EVAMenu[] {
    return [
      { isBackButton: true, svgIcon: backSVGIcon, text: 'Back' },
      ...DashboardMenu
    ];
  }

  /**
   * This gets the AI menu and returns the values to the application
   */
  getAIMenu(): EVAMenu[] {
    return [
      { isBackButton: true, svgIcon: backSVGIcon, text: 'Back' },
      ...AIMenu
    ];
  }

  /**
   * This gets the user menu and returns the values to the application
   */
  getUserMenu(): EVAMenu[] {
    return [
      { isBackButton: true, svgIcon: backSVGIcon, text: 'Back' },
      ...UserMenu
    ];
  }

  isValidBadgeCount(menuItem: EVAMenu): boolean {
    // check if the route is of a parent, if it is, sum up the child route badge count
    // else get current route badge count
    // check if total is greater than 1
    return this.getBadgeCount(menuItem) > 0;
  }

  getBadgeCount(menuItem: EVAMenu): number {
    // check if the route is of a parent, if it is, sum up the child route badge count
    // else return current route badge count
    let total = 0;
    let menu: EVAMenu[] = [];
    if (menuItem.isEmptyRoute) {
      switch (menuItem.emptyRouteName) {
        case 'main': menu = this.getMainMenu(); break;
        case 'administration': menu = this.getAdministrationMenu(); break;
        case 'builders': menu = this.getBuilderMenu(); break;
        case 'dashboards': menu = this.getDashboardMenu(); break;
        case 'ai': menu = this.getAIMenu(); break;
        case 'user': menu = this.getUserMenu(); break;
        default: menu = [menuItem]; break;
      }
    } else {
      menu = [menuItem];
    }

    menu.forEach(item => {
      if (this.multiViewService.tabs
        && this.multiViewService.tabs[item.routerLink] && this.multiViewService.tabs[item.routerLink]?.length > 1) {
        total += (this.multiViewService.tabs[item.routerLink]?.length - 1);
      }
    });

    return total;
  }

  isActiveRoute(routerLink: string) {
    return this.router.url.startsWith(routerLink);
  }

  applicationReload() {
    this.appReload = false;
    if (this.evaVersion) {
      localStorage.setItem('evaVersion', this.evaVersion.toString());
    }
    location.reload();
  }

}
