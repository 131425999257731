import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

interface EVAStatus {
  available: boolean;
  message: string;
}

const statusPath = `EVAStatus/CurrentStatus`;

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router) {
                // create observable for checking doc if authenticated
                const unavailable$ = this.afAuth.authState.pipe(
                  filter(user => !!user),
                  switchMap(() => {
                    return this.getEVAStatus();
                  })
                );

                // watch EVA status
                unavailable$.subscribe(
                  (status) => {
                    if (!status.available) {
                      this.redirectToStatusRoute(status.message);
                    } else if ( this.router.url.includes(`/status`) ) {
                      // If the active route is status page when a change occurs, redirect user back home
                      this.redirectToHome();
                    }
                  },
                  (err) => { console.error(`EVA :: Status`, err) }
                );
              }

  private getEVAStatus(): Observable<EVAStatus> {
    return this.afs.doc<EVAStatus>(statusPath).valueChanges()
  }

  private redirectToStatusRoute(status: string): void {
    this.router.navigate(['/status'], {queryParams: {message: status}});
  }

  private redirectToHome(): void {
    this.router.navigate(['/']);
  }
}
