<div class="login-page">

  <!-- <div class="top-space"></div> -->
  <div class="login-container">
    <ng-container *ngIf="(afAuth.user | async) || {} as user">

        <mat-horizontal-stepper linear #step #stepper="matHorizontalStepper" *ngIf="user.uid" [linear]="false">
            <!-- First Step "Security Keys" -->
            <mat-step label="Security keys">
              <mat-card *ngIf="isWaiting">
                <app-spinner name="getting-keys" show="true">
                  <h6>Plumbing the internet for information...</h6>
                </app-spinner>
              </mat-card>
              <mat-card *ngIf="!isWaiting">
                <mat-card-content>
                  <div class="signupProcessImageSection" fxFlex="30%">
                    <img class="signupProcessImage securityContentImage" src="../../../assets/images/protection.png">
                  </div>
                  <div class="signupProcessContentSection" fxFlex="70%">
                    <mat-card-title class="signupCardTitle">Security keys</mat-card-title>
                    <div class="securityCardContent">
                      <p>When using EVA, you are protected by both <b>signing</b> and <b>encryption keys</b>.</p>
                      <p>The signing key protects your identity, the encryption key protects your data.</p>
                      <p>You can’t access any individual piece of data without both of these security keys.</p>
                      <br />
                      <p>It is the safest way to protect your data.</p>
                      <p>Access is enabled by <b>mnemonics</b>, a series of 12 words that function like your password.</p>
                      <p>To get started, click on <b>"Generate signing mnemonic"</b> button below.</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <div class="footerDiv">
                <button class="singlenextBtn" mat-button type="button"  (click)="getMnemonic(this.mntype.signing, stepper)" *ngIf="(!signingMnemonic || signingMnemonic.length === 0) && !isWaiting">Generate signing mnemonic</button>
                <button class="singlenextBtn" mat-button type="button" color="primary" *ngIf="isSigningMnemonicValid()" matStepperNext>Next</button>
              </div>
            </mat-step>
    
            <!-- Second Step "Signing Mnemonic" -->
            <mat-step label="Sign mnemonic">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/Safe_illustration.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Signing Mnemonic</mat-card-title>
                  <mat-card-content>
                    <div class="mnemonicDiv">
                      <p>{{ signingMnemonic }}</p>
                    </div>
                    <button mat-button type="button" color="accent"
                      ngxClipboard [cbContent]="signingMnemonic"
                      (cbOnSuccess)="openSnackBarCopy()">Copy To Clipboard</button>

                    <button mat-button type="button" color="accent"
                      (click)=" saveToFile(signingMnemonic, 'signing-mnemonic')">Save to Device</button>
    
                    <div class="greyImportantNote">
                      <div class="iconDiv" fxFlex=15%>
                        <img src="../../../assets/images/warning.svg" alt="Important Note">
                      </div>
                      <div fxFlex="85%">
                        <p>
                          <b>Important!</b> You must save this mnemonic somewhere safe, with the words in the correct order. 
                          Write it down, copy it into a document, or save the mnemonic file on your computer.
                        </p>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
              <div class="footerDiv">
                <button mat-button class="backBtn" matStepperPrevious>Back</button>
                <button class="nextBtn" mat-button type="button" matStepperNext>Next</button>
              </div>
            </mat-step>
    
            <!-- Third Step "Encryption Key" -->
            <mat-step label="Encryption key">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage encryptionContentImage" src="../../../assets/images/encryption.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title class="signupCardTitle">Encryption key</mat-card-title>
                  <div class="encryptionCardContent">
                    <p>Now that you’ve captured your signing key, we’ll do the same thing with your encryption key.</p>
                    <p>Your encryption key protects your data, and requires that you save your encryption mnemonic in addition to your signing mnemonic.</p>
                  </div>
                </div>
              </mat-card>
              <div class="footerDiv">
                <button class="backBtn" mat-button type="button" matStepperPrevious>Back</button>
                <button class="nextBtn" mat-button type="button" (click)="getMnemonic(this.mntype.encryption, stepper)" *ngIf="(!encryptionMnemonic || encryptionMnemonic.length === 0) && !isWaiting">Generate encryption mnemonic</button>
                <button class="nextBtn" mat-button type="button" *ngIf="isEncryptionMnemonicValid()" matStepperNext>Next</button>
              </div>
            </mat-step>
    
            <!-- Fourth Step "Encryption Mnemonic" -->
            <mat-step label="Encrypt mnemonic">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/Safe_illustration.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Encryption Mnemonic</mat-card-title>
                  <mat-card-content>
                    <div class="mnemonicDiv">
                      <p>{{ encryptionMnemonic }}</p>
                    </div>
                    <button mat-button type="button" color="accent" ngxClipboard [cbContent]="encryptionMnemonic"
                      (cbOnSuccess)="openSnackBarCopy()">Copy To Clipboard</button>
                    <button mat-button type="button" color="accent" (click)="saveToFile(encryptionMnemonic, 'encryption-mnemonic')">Save
                      to Device</button>
    
                    <div class="greyImportantNote">
                      <div class="iconDiv" fxFlex=15%>
                        <img src="../../../assets/images/warning.svg" alt="Important Note">
                      </div>
                      <div fxFlex="85%">
                        <p>
                          <b>Important!</b> You must save this mnemonic somewhere safe, with the words in the correct order. 
                          Write it down, copy it into a document, or save the mnemonic file on your computer.
                        </p>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
              <div class="footerDiv">
                <button class="backBtn" mat-button type="button" matStepperPrevious>Back</button>
                <button class="nextBtn" mat-button type="button" matStepperNext>Next</button>
              </div>
            </mat-step>
    
            <!-- Fifth Step "Confirm Mnemonics" -->
            <mat-step label="Verify mnemonics">
              <mat-card>
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage" src="../../../assets/images/illy-atb-system.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title>Confirm mnemonics</mat-card-title>
                  <mat-card-content>
                    <p>
                      Enter the missing words from your signing and encryption mnemonics below. We just want to make 
                      sure that you’ve recorded them.
                    </p>
    
                    <h3>Signing mnemonic</h3>
                    <div class="mnemonicDiv" [ngClass]="{'valid': signingMnemonicForm.valid, 'invalid': signingMnemonicForm.invalid}">
                      <form [formGroup]="signingMnemonicForm">
                        <input type="text" class="confirmMnemonics" [formControlName]="control" *ngFor="let control of splitSigningMnemonic">
                      </form>
                      <p>{{ signingMnemonic }}</p>
                      <div class="mnemonicHelpText" [ngClass]="{'valid': signingMnemonicForm.valid, 'invalid': signingMnemonicForm.invalid}">
                        <ng-container *ngIf="isSigningMnemonicValid() || signingMnemonicForm.invalid">
                          Please complete the signing mnemonic to continue
                        </ng-container>
                        <ng-container *ngIf="isSigningMnemonicValid() && signingMnemonicForm.valid">
                          Looks good!
                        </ng-container>
                      </div>
                    </div>
    
                    <h3>Encryption mnemonic</h3>
                    <div class="mnemonicDiv" [ngClass]="{'valid': encryptionMnemonicForm.valid, 'invalid': encryptionMnemonicForm.invalid}">
                        <form [formGroup]="encryptionMnemonicForm">
                          <input type="text" class="confirmMnemonics" [formControlName]="control" *ngFor="let control of splitEncryptionMnemonic">
                        </form>
                      <p>{{ encryptionMnemonic }}</p>
                      <div class="mnemonicHelpText" [ngClass]="{'valid': encryptionMnemonicForm.valid, 'invalid': encryptionMnemonicForm.invalid}">
                        <ng-container *ngIf="isEncryptionMnemonicValid() || encryptionMnemonicForm.invalid">
                          Please complete the encryption mnemonic to continue
                        </ng-container>
                        <ng-container *ngIf="isEncryptionMnemonicValid() && encryptionMnemonicForm.valid">
                          Looks good!
                        </ng-container>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
              <div class="footerDiv">
                <button class="backBtn" mat-button type="button" matStepperPrevious>Back</button>
                <button class="nextBtn" mat-button matStepperNext [disabled]="(signingMnemonicForm && signingMnemonicForm.invalid) || (encryptionMnemonicForm && encryptionMnemonicForm.invalid)">Next</button>
              </div>
            </mat-step>

            <!-- sixth Step "Confirm updating the security keys" -->
            <mat-step label="Reset security keys" editable="false">
              <mat-card *ngIf="isWaiting">
                <app-spinner name="getting-keys" show="true">
                  <h6>Plumbing the internet for information...</h6>
                </app-spinner>
              </mat-card>
              <mat-card *ngIf="!isWaiting">
                <div class="signupProcessImageSection" fxFlex="30%">
                  <img class="signupProcessImage encryptionContentImage" src="../../../assets/images/encryption.png">
                </div>
                <div class="signupProcessContentSection" fxFlex="70%">
                  <mat-card-title class="signupCardTitle">Update user security keys</mat-card-title>
                  <div class="encryptionCardContent">
                    <p>Now that you confirmed your mnemonics, you update your security keys.captured your signing key, </p>
                    <p>Note: In case of updating security keys you will loose access to existing data, entities, and objects which you may have already recorded.</p>
                  </div>
                </div>
              </mat-card>
              <div class="footerDiv">
                <button class="backBtn" mat-button type="button" *ngIf="!isWaiting" matStepperPrevious>Back</button>
                <button class="nextBtn" mat-button type="button" (click)="confirmResettingUserSecurityKeys()" *ngIf="(isEncryptionMnemonicValid() && encryptionMnemonicForm.valid) && (isSigningMnemonicValid() && signingMnemonicForm.valid) && !isWaiting">Reset user security keys</button>
              </div>
            </mat-step>

          </mat-horizontal-stepper>

        </ng-container>
      </div>
    </div>