import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@eva-core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BuilderGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  /**
   * can the route be activated.
   * @param next activated route
   * @param state the router state.
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(async (resolve) => {
        const isBuilder = await this.authService.isBuilder();

        if (!isBuilder) {
          this.router.navigate(['/']);
          return resolve(false);
        } else {
          return resolve(true);
        }
      });
  }
  /**
   * can children be loaded.
   * @param next route snapshot
   * @param state angular router state
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(async (resolve) => {
        const isBuilder = await this.authService.isBuilder();

        if (!isBuilder) {
          this.router.navigate(['/']);
          return resolve(false);
        } else {
          return resolve(true);
        }
      });
  }
  /**
   * this is used to check if a route or segment can be loaded.
   * @param route the route to load
   * @param segments the segments to load
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise(async (resolve) => {
        const isBuilder = await this.authService.isBuilder();

        if (!isBuilder) {
          this.router.navigate(['/']);
          return resolve(false);
        } else {
          return resolve(true);
        }
      });
  }
  
}
