import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from "@ng-dynamic-forms/core";

class InteractionValues {
  id: string;
  inputType: string;
  originalId: string;
  scrnIndex: number;
  type: string;
  value: any;
}
@Injectable({
  providedIn: "root"
})
export class InteractionLoadService {
  constructor() {}

  mergeInteractionValues(originalInteraction, interactionValuesIncoming: any) {
    // get the interaction values and populate them into the interaction
    const mergeObject = JSON.parse(JSON.stringify(originalInteraction));
    const interactionValues = this.interactionValueConversion(
      interactionValuesIncoming
    );
    const valueKeys = Object.keys(interactionValues);
    valueKeys.forEach(key => {
      const objectToFind = interactionValues[key];
      const screenIndex =
        typeof objectToFind.scrnIndex === "number"
          ? objectToFind.scrnIndex
          : objectToFind.screenIndex;
      const screen = mergeObject.FormScreens[screenIndex];

      if (screen) {
        const items = screen.FormElements.find(elmnt => elmnt.id === key);
        if (items) {
          items.value = interactionValues[key].value;
        }
      }
    });
    // console.log(mergeObject);
    return mergeObject;
  }

  /**
   * @param originalInteraction interaction to accept new values
   * @param interactionValuesIncoming interaction to give new values
   *
   *  used to merge values from preservedInteractions/interactionsValues Array with values from the
   *  current runninginteraction.interaction object
   */
  mergeInteractionValuesForProcessRunner(originalInteraction: any, interactionValuesIncoming: any) {
    // get the interaction values and populate them into the interaction
    const mergeObject = JSON.parse(JSON.stringify(originalInteraction));

    // get array of interaction values out of formscreen objects within interaction
    const interactionValues = this.interactionValueConversion(
      interactionValuesIncoming
    );

    // for each interaction element, set the value based on the value from the incoming interaction
    interactionValues.forEach(element => {

      // get the screen index of the interaction element
      const screen = mergeObject.FormScreens[element.scrnIndex];

      if (screen) {
        // find the element that matches the original element within the screen
        const originalInteractionElement = screen.FormElements.find(
          elmnt => elmnt.id === element.originalId
        );

        if (originalInteractionElement) {
          //#region Set element value
          if (
            originalInteractionElement.type ===
            DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP
          ) {
            //#region if element is a "check box group", it needs to get values for each individual checkbox member
            originalInteractionElement.group.forEach(
              (checkbox, index: number) => {
                if ( // if checkbox value is found, load it into elements values as array
                  element.value &&
                  Array.isArray(element.value) &&
                  element.value.length > index
                ) {
                  checkbox.value = element.value[index].value;
                }
              }
            );
            //#endregion
          } else { // if it is not a checkbox group, just set the value to equal the incoming value
            originalInteractionElement.value = element.value;
            originalInteractionElement.disabled = element.disabled;
          }
          //#endregion
        }
      }
    });

    return mergeObject;
  }

  /**
   * used to format 'datetime-local' input fields using moment.js
   * @param interactionValues 'datetime-local' interaction value to be converted
   */
  interactionValueConversion(interactionValues) {
    Object.keys(interactionValues).forEach(key => {
      if (
        interactionValues[key].type === "INPUT" &&
        interactionValues[key].inputType === "datetime-local"
      ) {
        const dt = new Date(interactionValues[key].value);
        interactionValues[key].value = moment(dt)
          .format()
          .substr(0, 16);
      }
    });
    return interactionValues;
  }

  private getValueForStorage(interaction: any) {
    let confidenceScore = 2;
    let changeName = "";
    const interactionId = interaction.originalId; // get the interaction Id. this will be the path in the firestore application
    const formScreens = interaction.FormScreens; // get the form screens
    const returnObject = {
      status: "ERROR",
      message: "",
      firestoreInteractionId: ""
    };
    // since this will be valid in order to submit, we will get the database records.
    const interactionObject = {};
    formScreens.forEach((screen, screenIndex) => {
      const formElements = screen.FormElements;
      formElements.forEach(formElement => {
        // set the values of the screen elements.
        const objectToStore = {};
        const objectToSet = {};
        // ensure there is a value to store. // this is a reputaiton change
        if (formElement.originalId === "00ea5a4f-f036-477f-bfb2-2d1433efab0c") {
          formElement.hint =
            "This is the current overall confidence of the change";
          formElement.label = "Change Group Confidence";
          formElement.disabled = true;
          confidenceScore = formElement.value;
        }
        if (formElement.originalId === "8563a2a2-d227-4859-bc8b-2672c5b1b355") {
          changeName = formElement.value;
        }
        if (formElement.value) {
          let isElmntConvertedValue = false;
          let elmntConvertedValue: any;
          if (
            formElement.type === "INPUT" &&
            formElement.inputType === "datetime-local" &&
            formElement.value
          ) {
            isElmntConvertedValue = true;
            const elmntValue = new Date(formElement.value);
            elmntConvertedValue = elmntValue.toISOString();
          }
          const inputType = formElement.inputType;
          Object.assign(
            objectToSet,
            { elementOriginalId: formElement.originalId },
            {
              value: isElmntConvertedValue
                ? elmntConvertedValue
                : formElement.value
            },
            { formControlId: "" },
            { type: formElement.type },
            { interactionHint: formElement.hint },
            { interactionId: "" },
            { interactionLabel: formElement.label },
            { screenIndex: screenIndex },
            inputType ? { inputType: inputType } : null
            /** (formElement.inputType) ? { [formElement.originalId]: {
              inputType: formElement.inputType
              }} : null **/
          );
          Object.assign(objectToStore, {
            [formElement.originalId]: objectToSet
          });
          Object.assign(interactionObject, objectToStore);
        }
      });
    });
    const fullObject = {
      originalInteractionId: interactionId
    };
    const interactionValues = {
      interactionValues: interactionObject
    };
    Object.assign(fullObject, interactionValues);
    return {
      interactionId,
      confidenceScore,
      fullObject,
      interactionValues,
      returnObject,
      changeName
    };
  }
}
