import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WorkflowService } from '@eva-services/workflow/workflow.service';
import { entityType, entityPickType } from '@eva-model/entity';

@Component({
  selector: 'app-entity-picker-dialog',
  templateUrl: './entity-picker-dialog.component.html',
  styleUrls: ['./entity-picker-dialog.component.scss']
})
export class EntityPickerDialogComponent implements OnInit, OnDestroy {

  entityType: string = null;   // entity can be "Interaction" or "Workflow" or ...
  entityTypes = entityType;
  pickType: string = null;
  userGroups: any[] = [];
  entities: any[] = [];
  entityVersions: any[] = [];

  isLinear = true;
  isWaiting = false;

  selectedStepperIndex = 0;

  selectUserGroupFormGroup: UntypedFormGroup;
  selectEntityFormGroup: UntypedFormGroup;
  selectVersionFormGroup: UntypedFormGroup;

  editGroupPublicKey: string;
  editGroup: any;
  editGroupName: string;
  editEntityId: string;
  editEntity: any;
  editEntityName: string;
  editEntityVersion: number;
  private entitySub: Subscription;

  isWaitingActivation = false;
  onWaitForActivation = '';

  constructor(
    public dialogRef: MatDialogRef<EntityPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private generalDialogService: GeneralDialogService,
    private dynFormService: DynamicInteractionsService,
    private workflowService: WorkflowService,
    private _formBuilder: UntypedFormBuilder) { }

  ngOnInit() {

    this.selectUserGroupFormGroup = this._formBuilder.group({
      selectGroupCtrl: ['', Validators.required]
    });
    this.selectEntityFormGroup = this._formBuilder.group({
      selectEntityCtrl: ['', Validators.required]
    });
    this.selectVersionFormGroup = this._formBuilder.group({
      selectVersionCtrl: ['', Validators.required]
    });

    if (this.dialogData.extra && this.dialogData.extra.entityType &&
      this.dialogData.extra.userGroups && Array.isArray(this.dialogData.extra.userGroups)) {
      this.entityType = this.dialogData.extra.entityType;
      this.userGroups = this.dialogData.extra.userGroups;
      if (this.dialogData.extra.pickType) this.pickType = this.dialogData.extra.pickType;
    }

  }

  /**
   * This function is called on select entity step selection
   */
  onSelectEntityStep() {
    if (!this.selectUserGroupFormGroup.valid) return;

    const isGrpChanged: boolean = (this.selectUserGroupFormGroup.value.selectGroupCtrl !== this.editGroupPublicKey);
    if (isGrpChanged) {

      this.editEntityId = null;
      this.editEntity = null;
      this.editEntityName = null;
      this.editEntityVersion = null;

      this.editGroupPublicKey = this.selectUserGroupFormGroup.get('selectGroupCtrl').value;
      const grpPk = this.editGroupPublicKey;
      this.editGroup = this.userGroups.find((userGrp) => userGrp.groupPublicKey === grpPk);
      this.editGroupName = this.editGroup.groupName;

      this.isWaiting = true;

      if (this.entityType === entityType.interaction) {
        this.entitySub =
          this.dynFormService.fetchInteractionsByGroup(this.editGroupPublicKey)
            .subscribe(
              (entityArray) => {
                this.entities = entityArray;
                this.entities.sort((a, b) => Number(b.version) - Number(a.version));
              },
              (err) => { console.log(err); },
              () => { this.isWaiting = false; }
            );
      } else if (this.entityType === entityType.workflow) {
        this.entitySub =
          this.workflowService.fetchWorkflowsByGroup(this.editGroupPublicKey)
            .subscribe(
              (entityArray) => {
                this.entities = entityArray;
                this.entities.sort((a, b) => Number(b.version) - Number(a.version));
              },
              (err) => { console.log(err); },
              () => { this.isWaiting = false; }
            );
      }
    }
  }

  /**
   * This function is called on group change
   *
   * @param ev HTML change event
   */
  onGroupChange(ev) {
  }

  /**
   * This function is called on select version step selection
   */
  onSelectVersionStep() {
    if (!this.selectEntityFormGroup.valid) return;

    const isIntrctChanged: boolean = (this.selectEntityFormGroup.value.selectEntityCtrl !== this.editEntityId);
    if (isIntrctChanged) {
      this.editEntityVersion = null;
      this.editEntityId = this.selectEntityFormGroup.value.selectEntityCtrl;
      const entityId = this.editEntityId;
      this.editEntity = this.entities.find((entity) => entity.id === entityId);
      this.editEntityName = this.editEntity.name;

      if (this.editEntity) {
        if (this.entityType === entityType.workflow && this.pickType === entityPickType.justActive) {
          this.entityVersions = this.editEntity.Versions.filter(ev => ev.version === this.editEntity.activeVersion);
        } else this.entityVersions = this.editEntity.Versions;

        if (this.entityVersions && Array.isArray(this.entityVersions)) {
          this.entityVersions = this.entityVersions.reverse();
        }
        this.selectVersionFormGroup.value.selectVersionCtrl = null;
      }
    }
  }

  /**
   * This function is called on entity change
   *
   * @param ev HTML change event
   */
  onEntityChange(ev) {
  }

  /**
   * This function is called when version is selected
   */
  onSelectVersion() {
    if (!this.selectVersionFormGroup.valid) return;

    this.editEntityVersion = this.selectVersionFormGroup.value.selectVersionCtrl;
    const changeObj = {
      "groupublicKey": this.editGroupPublicKey,
      "entityId": this.editEntityId,
      "entityVersion": this.editEntityVersion
    };

    this.generalDialogService.announceChange(changeObj);
  }

  /**
   * This function is called when new stepper is selected
   *
   * @param ev HTML selection change event
   */
  selectionStepperChange(ev) {

    switch (ev.selectedIndex) {
      case 0: {
        break;
      }
      case 1: {
        this.onSelectEntityStep();
        break;
      }
      case 2: {
        this.onSelectVersionStep();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * This function is called on version change
   *
   * @param event HTML change event
   */
  onVersionChange(event) {
    // selectVersionCtrl
  }

  /**
   * This function activates the current version for the workflow
   */
  onActiveVersion() {
    if (!this.selectVersionFormGroup.valid) return;
    const that = this;

    this.editEntityVersion = this.selectVersionFormGroup.value.selectVersionCtrl;
    this.isWaitingActivation = true;
    this.onWaitForActivation = `Activating ${this.editEntityName} workflow with version ${this.editEntityVersion}`;

    this.workflowService.activateWorkflowVersion(this.editEntityId, this.editEntityVersion)
      .subscribe(
        (data) => {
          that.editEntity.activeVersion = that.editEntityVersion;
        },
        (err) => { console.log(err); },
        () => {
          that.isWaitingActivation = false;
          that.onWaitForActivation = '';
        }
      );
  }

  /**
   * This function resets the stepper
   *
   * @param stepperCtrl Stepper Control being reset
   */
  stepperReset(stepperCtrl: MatStepper) {
    this.editGroupPublicKey = null;
    this.editGroup = null;
    this.editGroupName = null;
    this.editEntityId = null;
    this.editEntity = null;
    this.editEntityName = null;
    this.editEntityVersion = null;

    stepperCtrl.reset();
  }

  /**
   * This function closes the dialog on cancel button click
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * This function validates if picker is complete or not
   */
  isPickerNotComplete() {
    return (this.editGroupPublicKey && this.editEntityId && this.selectVersionFormGroup.value.selectVersionCtrl) ? false : true;
  }

  /**
   * This function checks if current version is active
   */
  isActiveVersion() {
    return this.isPickerNotComplete() || (this.editEntity &&
      (this.selectVersionFormGroup.value.selectVersionCtrl === this.editEntity.activeVersion));
  }

  ngOnDestroy() {
    if (this.entitySub) {
      this.entitySub.unsubscribe();
    }
  }

}
