import { KnowledgeDocumentReturn, KnowledgeSuccessID } from "@eva-model/knowledge/knowledge";


export class ReturnObject {
    formId: string;
    error: string;

    constructor(id: string, err: string) {
        this.formId = id;
        this.error = err;
    }
}

/**
 * This interface is used to return a knowledge document to the front end of the EVA system.
 */
export interface KnowledgeReturnObject {
    success: boolean; // whether the request was successful.
    message: string; // the message from the system. If this is successful, this will be an empty string
    // The knowledge document being returned from the request or the successful transacion completion ID
    additional?: KnowledgeDocumentReturn;
}

/**
 * This interface is used for the status of deleting an image from a document.
 */
export interface DeleteImageFromKnowledgeResponse {
    success: boolean; // whether the request was successful.
}

/**
 * This interface is used to define the response when attempting to copy images to a specific knowledge doc.
 * When calling the function to move the images, if all is successful, you end up with an array of these.
 */
export interface MoveImagesKnowledgeResponseItem {
    oldUrl: string;
    newUrl: string;
}

/**
 * Server response when calling the cloud function to move images from one place to another.
 */
export interface KnowledgeImageMoveResponse {
    originalSrc: string;
    storagePath: string;
    fileName: string;
    success: boolean;
    storageURI: string;
}

/**
 * Return object for making a request specifically to get versions of the document from the server
 */
export interface KnowledgeDocumentVersions {
    docName: string;
    allVersions: number[];
    publishedVersions: number[];
}