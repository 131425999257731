import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { InteractionEmitValueModel } from '@eva-model/InteractionEmitValueModel';
import { FormControlValidAnnouncement, InteractionAndElementEmitModel } from '@eva-model/interactionAndElementEmitModel';
import { InteractionControlModel } from '@eva-model/visualizedInteractionModel';
import { InteractionControlRequest, searchDirection } from '@eva-model/interactionFormControlModel';
import { InteractionFlatModel } from '@eva-model/interactionFlatModel';

@Injectable()
export class DynamicInteractionSyncService {

  // Observable any sources
  private interactionControlSource = new Subject<any>();
  private interactionControlRequestSource = new Subject<any>();
  private interactionControlListUpdateSource = new Subject<{id: string; control: any}>(); // fires when an interaction control ._list prop value is changed
  private interactionControlValueChangeSource = new Subject<InteractionEmitValueModel>();
  private interactionControlValueValidSource = new Subject<FormControlValidAnnouncement>();
  private interactionInvalidCurrentFormSource = new Subject<{invalidElements: any[], fulfillmentText: string, processTitle: string}>();
  private interactionValueChangeSource = new Subject<any>();
  private interactionControlValueChangeForDatabase = new Subject<InteractionEmitValueModel>();
  private interactionControlValueUpdateSource = new Subject<InteractionEmitValueModel>();
  private processInteractionValueSubmitSource = new Subject<any>();
  private processInteractionValueEditSource = new Subject<{values: any[], processTitle: string}>();
  private processStayOnCurrentInteractionSource = new Subject<{stay: boolean, processId: string}>();
  private processInteractionChangeSource = new Subject<{direction: searchDirection, fulfillmentText: string, processId: string,
    processTitle: string}>();
  private interactionValuesUpdateSource = new Subject<{interactionValues: InteractionFlatModel, processId: string}>();
  private interactionAndLastElementValueChangeSource = new Subject<InteractionAndElementEmitModel>();

  // Observable any streams
  interactionControl$ = this.interactionControlSource.asObservable();
  interactionControlRequest$ = this.interactionControlRequestSource.asObservable();
  interactionControlListChanged$ = this.interactionControlListUpdateSource.asObservable();
  controlValueChanged$ = this.interactionControlValueChangeSource.asObservable();
  controlValueValid$ = this.interactionControlValueValidSource.asObservable();
  invalidCurrentForm$ = this.interactionInvalidCurrentFormSource.asObservable();
  interactionValueChanged$ = this.interactionValueChangeSource.asObservable();
  controlValueUpdated$ = this.interactionControlValueUpdateSource.asObservable();
  controlValueDatabase$: Observable<InteractionEmitValueModel> = this.interactionControlValueChangeForDatabase.asObservable();
  processInteractionSubmit$ = this.processInteractionValueSubmitSource.asObservable();
  processInteractionEdit$ = this.processInteractionValueEditSource.asObservable();
  processInteractionChange$ = this.processInteractionChangeSource.asObservable();
  processStayOnCurrentInteraction$ = this.processStayOnCurrentInteractionSource.asObservable();
  // eslint-disable-next-line max-len
  interactionAndLastElementValueChanged$: Observable<InteractionAndElementEmitModel> = this.interactionAndLastElementValueChangeSource.asObservable();
  interactionValuesUpdate$: Observable<{interactionValues: InteractionFlatModel, processId: string, fromLastState?: boolean}>
    = this.interactionValuesUpdateSource.asObservable();
  currentInteractionControlId: string = null;

  constructor() { }

  // Service message commands
  announceInteractionControl(interactionAndControl: InteractionControlModel) {
    this.currentInteractionControlId = interactionAndControl.FormControl?.originalId;
    this.interactionControlSource.next(interactionAndControl);
  }

  announceInteractionControlRequest(interactionControlRequest: InteractionControlRequest) {
    this.interactionControlRequestSource.next(interactionControlRequest);
  }

  announceInteractionControlListChange(id: string, control: InteractionControlModel) {
    this.interactionControlListUpdateSource.next({id, control});
  }

  announceControlValueChange(change: InteractionEmitValueModel) {
    this.interactionControlValueChangeSource.next(change);
  }

  announceControlValidChange(announce: FormControlValidAnnouncement) {
    this.interactionControlValueValidSource.next(announce);
  }

  announceInteractionValueChange ( interactionChange: any) {
    this.interactionValueChangeSource.next(interactionChange);
  }

  announceControlValueUpdate(update: InteractionEmitValueModel) {
    this.interactionControlValueUpdateSource.next(update);
  }

  announceInvalidCurrentForm(invalidElements: any[], fulfillmentText: string, processTitle: string) {
    this.interactionInvalidCurrentFormSource.next({ invalidElements, fulfillmentText, processTitle });
  }

  /**
   * every time an interaction control value is changed, the interaction value model is announced
   *
   * @param change interaction control value
   */
  announceControlValueChangeForDatabase(change: InteractionEmitValueModel) {
    this.interactionControlValueChangeForDatabase.next(change);
  }

  announceProcessInteractionSubmit(submitValues: any) {
    this.processInteractionValueSubmitSource.next(submitValues);
  }

  announceProcessInteractionEdit(submitValues: any[], processTitle: string) {
    this.processInteractionValueEditSource.next({ values: submitValues, processTitle });
  }

  announceProcessStayOnCurrentInteraction(stay: boolean, processId: string) {
    this.processStayOnCurrentInteractionSource.next({ stay, processId });
  }

  announceProcessInteractionChange(direction: searchDirection, fulfillmentText?: string, processId?: string, processTitle?: string) {
    this.processInteractionChangeSource.next({direction, fulfillmentText, processId, processTitle});
  }

  /**
   * when an interaction changes, the full, flat interaction is emitted as well as the last element changed
   *
   * @param change flat interaction and last element changed
   */
  announceInteractionAndLastElementValueChange( change: InteractionAndElementEmitModel ) {
    this.interactionAndLastElementValueChangeSource.next(change);
  }

  announceInteractionValues(interactionValuesUpdate: { interactionValues: InteractionFlatModel, processId: string,
    fromLastState?: boolean }) {
    this.interactionValuesUpdateSource.next(interactionValuesUpdate);
  }
}
