<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <br /><span [innerHTML]="dialogData.content"></span><br />
  {{ editGroupName }} :: {{ editEntityName }} :: {{ editEntityVersion | date:'full' }}

  <br /><br />

  <mat-horizontal-stepper class="entity-picker-dialog" #stepperCtrl [linear]="isLinear" #stepper="matHorizontalStepper"
    [selectedIndex]="this.selectedStepperIndex" (selectionChange)="selectionStepperChange($event)">

    <mat-step [stepControl]="selectUserGroupFormGroup" [optional]=false>
      <div class="card-container">
        <form [formGroup]="selectUserGroupFormGroup" class="fullWidth">
          <ng-template matStepLabel>Choose a group</ng-template>

          <mat-form-field class="fullWidth">
            <mat-select placeholder="User group" formControlName="selectGroupCtrl" required
              (selectionChange)="onGroupChange($event)">
              <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupPublicKey">
                {{ userGroup.groupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
            <button mat-button matStepperNext>Next</button>
          </div>

        </form>
      </div>
    </mat-step>

    <mat-step [stepControl]="selectEntityFormGroup">

      <div class="card-container">
        <form [formGroup]="selectEntityFormGroup" class="fullWidth">
          <ng-template matStepLabel>Select {{(entityType === entityTypes.interaction ? "an " : "a ") + entityType}}
          </ng-template>

          <div *ngIf="!isWaiting">
            <mat-form-field class="fullWidth">
              <mat-select placeholder="Select an {{entityType}}" formControlName="selectEntityCtrl" required
                (selectionChange)="onEntityChange($event)">
                <mat-option *ngFor="let entity of entities" [value]="entity.id">
                  {{ entity.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>

          <div *ngIf="isWaiting">
            <app-spinner name="getting-keys" show="true">
              <h4>Processing ...</h4>
            </app-spinner>
          </div>
        </form>
      </div>
    </mat-step>

    <mat-step [stepControl]="selectVersionFormGroup">
      <div class="card-container">
        <form [formGroup]="selectVersionFormGroup" class="fullWidth">
          <ng-template matStepLabel>Select the version</ng-template>

          <mat-form-field class="fullWidth">
            <mat-select placeholder="Select a version" formControlName="selectVersionCtrl" required
              (selectionChange)="onVersionChange($event)" [disabled]="isWaitingActivation">
              <mat-option *ngFor="let entityVer of entityVersions" [value]="entityVer.version">
                <i *ngIf="editEntity && (entityVer.version === editEntity.activeVersion)"
                  class="fas fa-bolt">&nbsp;::Active::&nbsp;</i>
                {{ entityVer.version | date:'full' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
            <button mat-button matStepperPrevious>Back</button>
          </div>

          <div class="app-loading" *ngIf="isWaitingActivation">
            <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
            <ng-content></ng-content>
            <span class="verColor">
              <h4>{{ onWaitForActivation }}</h4>
            </span>
          </div>

        </form>
      </div>
    </mat-step>

  </mat-horizontal-stepper>


</div>
<div mat-dialog-actions>
  <div *ngIf="!isWaitingActivation">
    <button (click)="onSelectVersion()" mat-raised-button color="primary" [mat-dialog-close]="true"
      [disabled]="isPickerNotComplete()">{{dialogData.confirmBtnTxt}}</button>
    <button (click)="onActiveVersion()" mat-stroked-button
      *ngIf="entityType === entityTypes.workflow && !dialogData.extra.fromImport" [disabled]="isActiveVersion()">Activate</button>
    <button mat-stroked-button (click)="stepperReset(stepperCtrl)">Reset</button>
    <button mat-stroked-button (click)="onCancelClick()"
      *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
  </div>
</div>