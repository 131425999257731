import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ElementEmitValueModel } from '@eva-model/elementEmitValueModel';

/**
 * This service is used to sync information in the conidtion builder for the interaction conditional logic and
 * to announce to other parts of the EVA application.
 */
@Injectable({
  providedIn: 'root'
})
export class ElementSyncService {

  // Observable any sources
  private elementValueChangeSource = new Subject<ElementEmitValueModel>();

  // Observable any streams
  elementValueChanged$ = this.elementValueChangeSource.asObservable();

  constructor() { }

  /**
   * This announces the elementatl value changes for a conditional interaction change that has been set.
   * @param change the item and details that have changed.
   */
  announceElementValueChange(change: ElementEmitValueModel) {
    this.elementValueChangeSource.next(change);
  }

}
