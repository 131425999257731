import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { Node } from '@eva-model/node';
import { ForceDirectedGraph } from '@eva-model/forceGraph';
import { ForceGraphService } from '@eva-services/force-graph/force-graph.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[draggableNode]'
})
export class NodeDraggableDirective implements OnInit {
// eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('draggableNode') draggableNode: Node;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('draggableInGraph') draggableInGraph: ForceDirectedGraph;

    constructor(private forceGraphService: ForceGraphService, private _element: ElementRef) { }

    ngOnInit() {
        this.forceGraphService.applyDraggableBehaviour(this._element.nativeElement, this.draggableNode, this.draggableInGraph);
    }
}
