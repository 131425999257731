<h1 mat-dialog-title>{{ dialogData.title }} &nbsp;
  <button (click)="addReleaseNote()"><i class="fas fa-plus"></i>Add</button></h1>
<div mat-dialog-content>
  <div class="add-edit-release-notes mat-card-layout">
      <form [formGroup]="releaseNoteForm">
        <div class="note-container">
          <div class="left-panel">
            <mat-selection-list [multiple]="false" (selectionChange)="onReleaseNoteSelectionChange($event)">
              <mat-list-option *ngFor="let releaseNote of releaseNotes; let releaseNoteIndex=index;"
                [value]="releaseNote" (mouseover)="onMouseOver(releaseNoteIndex)"
                (mouseleave)="onMouseLeave(releaseNoteIndex)">
                <span [class.invalidData]="!isNoteValid(releaseNoteIndex)">
                  {{ releaseNote.releaseDate | date:shortDate }}
                </span>
                <span *ngIf="releaseNote.hover" (click)="deleteReleaseNote(releaseNoteIndex)">
                  <i class="fas fa-trash"></i>
                </span>
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div class="spacing"></div>
          <div class="right-panel">
            <mat-form-field class="full-width-input">
              <input matInput type="text" [matDatepicker]="picker" formControlName="releaseDate"
                placeholder="Release Date" required (dateInput)="onDateChange($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="releaseNoteForm.get('releaseDate').hasError('required')">
                Release Date Required
              </mat-error>
            </mat-form-field>
            <div formControlName="description" [froalaEditor]="froalaOptions" (froalaInit)="initialize($event)"
              [(froalaModel)]="froalaModel"></div>
          </div>
        </div>
      </form>
    </div>
</div>
<div mat-dialog-actions>
  <button (click)="finalizeReleaseNotes()" mat-raised-button color="primary" type="submit"
    [disabled]="!isValid()">{{ dialogData.confirmBtnTxt }}</button>
  <button mat-button color="accent" (click)="onCancelClick()"
    *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
</div>