import { Injectable } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
// import the cryptographic libraries.
import * as Elliptic from 'elliptic';
import { environment } from '@environments/environment';
import { User } from '@eva-model/User';
import {take} from "rxjs/operators";

@Injectable()
export class KeysService {

  private signingKey: Elliptic.ec.KeyPair = null;
  private publicKey: string = null;
  private encryptionKey: Elliptic.ec.KeyPair = null;
  private EC = Elliptic.ec; // Elliptic.ec; // classany;
  private ec: Elliptic.ec;

  constructor(
    public afAuth: AuthService
  ) {
    // this.EC = Elliptic.ec;
    this.ec = new this.EC(environment.blockConfig.SIGNING_CURVE);
  }

  /**
   * This returns the user private signing key pair.
   */
  async getSigningKey(): Promise<Elliptic.ec.KeyPair> {
    // get the signing key
    if (this.signingKey !== null) {
      return this.signingKey;
    } else {
      try {
        const signingKey = await this.generateKeys('signing');
        if (signingKey) {
          return this.signingKey;
        }
      } catch (err) {
        console.log('Error Occured obtaining the signing key: ' + err);
        return Promise.reject(err);
      }
    }
  }

  /**
   * this gets the users public key in Hex format.
   */
  async getUserPublicKey(): Promise<string> {
    if (this.publicKey) {
      return this.publicKey;
    } else {
      try {
        const signingKey =  await this.getSigningKey();
        this.publicKey = (signingKey) ? signingKey.getPublic('hex') : null;
        return this.publicKey;
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    }
  }

  /**
   * This function returns the users private encryption keys pair
   */
  async getEncryptionKey(): Promise<Elliptic.ec.KeyPair> {

    if (this.encryptionKey !== null) {
      return this.encryptionKey;
    } else {
      try {
        const encryptionKey = await this.generateKeys('encryption');
        if (encryptionKey) {
          return this.encryptionKey;
        }
      } catch (err) {
        console.log('Error Occured obtaining the encryption key: ' + err);
        return Promise.reject(err);
      }
    }
  }

  /**
   * This generates the different keys and returns the one requested (or the signing key)
   * @param keyToReturn the encryption or signing key you want to return.
   */
  async generateKeys(keyToReturn: string): Promise<Elliptic.ec.KeyPair> {

    try {
      // get the user object.
      const user: User = await this.afAuth.user.pipe(take(1)).toPromise();
      // make sure there is a user function.
      if (!(user && user.signingPrivateKey && user.encryptionPrivateKey)) {
        return Promise.reject(null);
      }

      const privateSigningKey = user.signingPrivateKey; // the private key for signing.
      const privateEncryptionKey = user.encryptionPrivateKey;   // the private key for encryption

        this.signingKey = this.ec.keyFromPrivate(privateSigningKey, 'hex');
        this.encryptionKey = this.ec.keyFromPrivate(privateEncryptionKey, 'hex');

      // return the appropriate key pair.
      switch (keyToReturn) {
        case 'encryption':
          return this.encryptionKey;
        default:
          return this.signingKey;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * This generates a elliptic key pair from a private key hex provided.
   * @param privateKey the hex encoded private key
   */
  createKeyPairFromPrivateKeyHex(privateKey: string): Elliptic.ec.KeyPair {
    return this.ec.keyFromPrivate(privateKey, 'hex');
  }

  /**
   * This generates a random key pair object for the user.
   */
  getKeyPair(): Elliptic.ec.KeyPair {
    return this.ec.genKeyPair();
  }

  /**
   * This gets a public key pair (for validation) from the hex encoded public key.
   *
   * @param publicKeyHex The hex encoding of the public keypair
   */
  getVerificationKeyFromPublicKey(publicKeyHex: string): Elliptic.ec.KeyPair {
    return this.ec.keyFromPublic(publicKeyHex, 'hex'); // get the public key in a key object
  }
}
