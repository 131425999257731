<div>
  <div *ngIf="!process">
    <div class="app-loading">
      <span class="spinner-inner-container">
        <mat-spinner [diameter]=128 [strokeWidth]=12></mat-spinner>
      </span>
      <h6>
        <span class="verColor">&nbsp;&nbsp;Loading&nbsp;&nbsp;&nbsp;</span>
      </h6>
    </div>
  </div>
  <div class="json-container" *ngIf="process && !hideProcessTitleBar">
    <pre matLine>
      <!-- Required at time of writing for the ipa side -->
      <span class="fontSizeZero">processJsonStart</span>
      {{this.jsonStringify(
        {
          'root': {
            'rows': (this.process) ? this.process.interactionsValues : null
          }
        }
      )+'EndOfProcessJsonObject'}}
      <span class="fontSizeZero">processJsonEnd</span>
    </pre>
  </div>

  <div class="full-process-container" *ngIf="process">
      <div class="full-process-container-left" *ngIf="!hideProcessTitleBar">
        <mat-card class="left-container">
          <button
            pButton
            *ngIf="isRejectEnabled && !isWaitingForProcessAndSpin"
            [disabled]="isWaitingForProcess"
            type="button"
            label="Reject"
            class="mat-raised-button mat-blank"
            (click)="rejectProcessConfirm()"
          ></button>
          <eva-process-detail-viewer
            [userGroups]="evaGlobalService.userGroups"
            [prcsName]="process.name"
            [prcsGroupPublicKey]="process.groupPublicKey"
            [prcsStatus]="process.status"
            [prcsCreatedTimeStamp]="process.createTimestamp"
            [prcsLastUpdateTimeStamp]="process.lastUpdateTimestamp"
            [prcsWorkflowName]="processCurrentWorkFlowName()"
            [prcsInteractionName]="processCurrentInteractionName()"
          >
          </eva-process-detail-viewer>
        </mat-card>
      </div>
      <div class="full-process-container-right" [class.not-left]="hideProcessTitleBar">
        <!-- <div>
          <span class="eva-box-top-inline-multiple-menu"> 
            <button
              *ngIf="isCancelEnabled && !isWaitingForProcessAndSpin"
              [disabled]="isWaitingForProcess"
              type="button"
              (click)="cancelProcessConfirm()"
            >
              X
            </button>
          </span>
        </div> -->
        <div [fxFlex]="visualizerSize">

          <!-- The Top Bar -->
          <div *ngIf="isActionPanelEnabled" class="sticky-process-bar">
            <div fxFlex="68%" fxLayoutAlign="start">
              <button pButton type="button" label="Process Related Functions" class="ui-button-secondary pCustomButton"
                      icon="fas fa-cogs" iconPos="left">
              </button>
            </div>
            <div fxFlex="15%" fxHide.lt-lg="true" *ngIf="!hideProcessTitleBar">
            </div>
            <div fxFlex="1%" class="rightMargin10">
            </div>
            <div [fxFlex]="processRelatedFunctionsSize" fxFlex.lt-lg="30%" fxLayoutAlign="end">
              <div class="app-loading" *ngIf="isWaitingForProcessAndSpin">
                <span class="loading-spinner" *ngIf="isWaitingForProcessAndSpin">
                  <mat-spinner [diameter]=24 [strokeWidth]=6 *ngIf="isWaitingForProcessAndSpin"></mat-spinner>
                </span>
                <h6 *ngIf="isWaitingForProcessAndSpin">
                  <span class="verColor" >&nbsp;Please wait&nbsp;...&nbsp;&nbsp;</span>
                </h6>
              </div>

              <button pButton *ngIf="isRejectEnabled && !isWaitingForProcessAndSpin" [disabled]="isWaitingForProcess"
                      type="button" label="Reject" class="mat-raised-button mat-blank"
                      (click)="rejectProcessConfirm()">
              </button>
              <button pButton *ngIf="isCancelEnabled && !isWaitingForProcessAndSpin" [disabled]="isWaitingForProcess"
                      type="button" label="Cancel" class="mat-raised-button mat-blank"
                      (click)="cancelProcessConfirm()">
              </button>
            </div>
          </div>
          <!-- The Top Bar -->

          <div *ngIf="!isWaiting && !isProcessSummary && !isProcessEditSummary" >
            <div class="process-loading-container" *ngIf="process && process.status !== 'Done'">
              <div class="app-loading" *ngIf="isProcessRunnerWaiting">
                <br />
                <mat-spinner></mat-spinner>
                <ng-content></ng-content>
                <h6>{{ onProcessRunnerWaitMessage }}</h6>
              </div>

              <ng-template appViewContainer></ng-template>
            </div>
          </div>

          <div *ngIf="isProcessEditSummary && !isProcessSummary && !isWaiting">
            <app-process-edit-summary [processObj]="process">
            </app-process-edit-summary>
          </div>

          <div
            *ngIf="
              process && isProcessSummary && !isProcessEditSummary && !isWaiting
            "
          >
            <eva-process-summary
              [processObj]="process"
              [processRunnerId]="processRunnerId"
              [isDoneButtonEnable]="isDoneButtonEnableInSummary"
            >
            </eva-process-summary>
          </div>

          <div class="app-loading" *ngIf="isWaiting">
            <mat-spinner></mat-spinner>
            <ng-content></ng-content>
            <h6>{{ onWaitMessage }}</h6>
          </div>
        </div>
      </div>
      <div *ngIf="isWaitingForProcessAndSpin"
      [fxFlex]="processRelatedFunctionsSize"
      fxFlex.lt-lg="30%"
      fxLayoutAlign="end"
    >
      <div class="app-loading">
        <span
          class="loading-spinner"
          *ngIf="isWaitingForProcessAndSpin"
        >
          <mat-spinner
            [diameter]="24"
            [strokeWidth]="6"
            *ngIf="isWaitingForProcessAndSpin"
          ></mat-spinner>
        </span>
        <span class="verColor" *ngIf="isWaitingForProcessAndSpin"
          ><h6>&nbsp;Please wait&nbsp;...&nbsp;&nbsp;</h6></span
        >
      </div>
    </div>
  </div>
</div>

<!-- <p-growl [(value)]="growl_msgs" life="30000"></p-growl> -->

<!-- <p-toast></p-toast> -->

<!-- {{ this.process.id }} -->
