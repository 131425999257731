
<h2 mat-dialog-title>
    Link to another section in this document
</h2>

<mat-dialog-content>
    <ul *ngIf="toc">
        <li *ngFor="let heading of toc.headings" [ngClass]="['section-depth-'+heading.depth, selectedSection && selectedSection.sectionId === heading.sectionId ? 'selected' : '' ]" (click)="selectedSection = heading">
            <a>{{heading.title}}</a>
        </li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="space-between center">
    <div>
        &nbsp;
    </div>
    <div>
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="closeDialogAndEmitData()" [disabled]="!selectedSection">Add</button>
    </div>
</mat-dialog-actions>