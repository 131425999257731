import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { EvaHeartService } from '@eva-services/chat/eva-heart.service';
import { LastState, userLastStateType } from '@eva-model/userLastState';
import { InteractionMapped } from '@eva-model/interaction/interactionMapped';
import { InteractionControlModel } from '@eva-model/visualizedInteractionModel';
import { delay, filter, skip } from 'rxjs/operators';
import { DynamicInteractionSyncService } from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import { InteractionEmitValueModel } from '@eva-model/InteractionEmitValueModel';
import { ChatService } from '@eva-services/chat/chat.service';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { ChatEntity, ChatEntityType, ChatEntityAuthor } from '@eva-model/chat/chat';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.7s ease-out', style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  // this contains all subscriptions that are created in the component to enable cleanup when the component is destroyed.
  private componentSubs = new Subscription();

  askedQuestion = ''; // this is the question that has been asked by the user.
  fullResponse = ''; // this is the text that is visualized for the user. From the heart project

  // this is the code for enabling dynamic input between the components and the chat
  showDynamicInput = false; // this is the dynamic input for duplicating user controls.

  interactionScreenNumber: number; // this is used for the current screen number that is open in an interaction.
  interactionElementId: string; // this is the current element that had current status.
  dialogflowResponse: any; // the last state of the user

  // knowledgeResponseGroupName$: Observable<string>; // template observable used if the state is a knowledge response
  // expose chat enums for consumption
  chatType = {
    loading: ChatEntityType.Loading,
    text: ChatEntityType.Text,
    knowledgeResponse: ChatEntityType.KnowledgeResponse,
    knowledgeResponseTraining: ChatEntityType.KnowledgeResponseTraining,
    interactionResponse: ChatEntityType.InteractionResponse,
    routeRedirect: ChatEntityType.RouteRedirect
  };

  chatAuthor = {
    eva: ChatEntityAuthor.EVA,
    user: ChatEntityAuthor.User
  };

  @ViewChild('chatBottomAnchor', {read: ElementRef}) chatBottomAnchor: ElementRef;
  @Input() heartResponse(response: any) {
    if (!response) {
      return;
    }
    this.initializeBasedOnDialogFlow(response);
  };

  constructor(
    private lastStateService: LastStateService,
    public evaHeartService: EvaHeartService, // used as part of the html to determine last state.
    public interactionSyncService: DynamicInteractionSyncService,
    public chatService: ChatService
  ) { }

  ngOnInit() {
    // monitor new messages and scroll view
    this.componentSubs.add(
      this.chatService.chatEntities$.pipe(
        delay(100)
      ).subscribe(() => {
        this.scrollToBottom();
      })
    );

    this.componentSubs.add(
      this.chatService.isLeftPaneActive$.pipe(
        skip(1),
        delay(100)
      ).subscribe(() => {
        this.scrollToBottom();
      })
    );

    this.componentSubs.add(
      this.interactionSyncService.controlValueChanged$
      .subscribe(
        (data: InteractionEmitValueModel) => {
          if (data.interactionLabel) {
            this.fullResponse = data.interactionLabel;
          } else if (data.interactionHint) {
            this.fullResponse = data.interactionHint;
          } else {
            this.fullResponse = `The designer of this interaction hasn't given us enough information
             to figure out what they want us to ask you.`;
          }
        }
      )
    );

    // this.knowledgeResponseGroupName$ = this.createKnowledgeGroupNameObservable();
  }

  ngOnDestroy() {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

//#region initializeBasedOnDialogFlow

  /**
   * This creates the chat messages  based on what has been added to the system and the shape of lastState
   *
   * @param usersLastState The last state of the user object.
   */
  initializeBasedOnDialogFlow(usersLastState: any): void {
    // last state is not known
    if (!usersLastState) {
      return;
    }

    this.dialogflowResponse = usersLastState;
    // state is known
    this.askedQuestion = usersLastState.query;
    const typeML = usersLastState.type;

    switch (typeML) {
      case userLastStateType.dialogflow:
        this.fullResponse = usersLastState.response.fulfillmentText;
        break;
      case userLastStateType.TFIDF:
        // console.log('machine learning response triggered');
        // eslint-disable-next-line max-len
        // this.fullResponse =
        // `Found results in <strong>${usersLastState.response.tfidfDocumentMatches[usersLastState.response.count].documentName}</strong>`;
        this.fullResponse = KnowledgeUtils.getBestSectionMatchHTMLFromLastState(usersLastState);

        break;

      case userLastStateType.watson:
        if (usersLastState.count) {
          this.fullResponse = usersLastState.response[usersLastState.count].passage_text;
        } else {
          this.fullResponse = `We are unsure what you are asking. We are currently in a training cycle and may have the
            answer shortly.`;
        }

        break;

      case userLastStateType.interaction:
        this.interactionScreenNumber = usersLastState.screen;
        this.interactionElementId = usersLastState.id;
        if ( usersLastState && usersLastState.interactionResponse ) {
          this.provideTextInteraction(usersLastState.interactionResponse);
        }
        break;

      default:
        break;
    }
  }

  /**
   * This determines what should be shown in the eva says response based on what is sent from the dynamic interactions.
   *
   * @param dynamicInteraction the interaction that was found.
   */
  provideTextInteraction(dynamicInteraction: InteractionMapped) {
    const currentInteractionScreen = dynamicInteraction.FormScreens[this.interactionScreenNumber];
    let description = 'I haven\'t been provided enough information to know what the designer wanted from this interaction';
    currentInteractionScreen.FormElements.forEach(formElement => {
      if (formElement.id === this.interactionElementId) {
        if (formElement.hint) {
          description = formElement.hint;
        } else if (formElement.label) {
          description = formElement.label;
        }
      }
    });
    this.fullResponse = description;
  }

//#endregion InitializeBasedOnLastState


  ngAfterViewInit() {
    this.componentSubs.add(
      this.interactionSyncService.interactionControl$
      .pipe(
        // sanity check - ensure that there is an interaction control
        filter(control => !!(control)),
      )
      .subscribe(
        (interactionControl: InteractionControlModel) => {
          if (interactionControl.FormControl?.label) {
            this.fullResponse = interactionControl.FormControl.label;
          } else if (interactionControl.FormControl?.hint) {
            this.fullResponse = interactionControl.FormControl.hint;
          } else {
            this.fullResponse = `The designer of this interaction hasn't given us enough information
             to figure out what they want us to ask you.`;
          }
        },
        ( err ) => console.log(err)
      )
    );
  }

  /**
   * Used to compare list items to reduce change collection runs when a new item is added to our chat entities.
   */
  public chatEntityTrackBy(index: number, item: ChatEntity): number {
    return index;
  }

  /**
   * Scrolls the latest point in chat into view so the latest messages are full visible.
   */
  public scrollToBottom(): void {
    this.chatBottomAnchor.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
