
import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { ForceGraphService } from '@eva-services/force-graph/force-graph.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[graphZoomableOf]'
})

export class GraphZoomableDirective implements OnInit {
// eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('graphZoomableOf') graphZoomableOf: ElementRef;

    constructor(private forceGraphService: ForceGraphService, private _element: ElementRef) {}

    ngOnInit() {
        this.forceGraphService.applyZoomableBehaviour(this.graphZoomableOf, this._element.nativeElement);
    }
}
