import { Component, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { saveAs } from 'file-saver';
import { IdMapperService } from '@eva-services/id-mapper/id-mapper.service';
import { IdMapDialogComponent } from '@eva-ui/id-map-dialog/id-map-dialog.component';
import { IdMapInteractionChangesComponent } from '@eva-ui/id-map-interaction-changes/id-map-interaction-changes.component';
// eslint-disable-next-line max-len
import { IdMapInteractionElementDialogComponent } from '@eva-ui/id-map-interaction-element-dialog/id-map-interaction-element-dialog.component';
import { GroupSelectDialogComponent } from '@eva-ui/group-select-dialog/group-select-dialog.component';
import { entityType, entityPickType } from '@eva-model/entity';
import { EntityPickerDialogComponent } from '../form-builder/entity-picker-dialog/entity-picker-dialog.component';
import { IdMapIdChangesComponent } from '@eva-ui/id-map-id-changes/id-map-id-changes.component';
import { ExportImportInteraction, GroupSelectDialogResult, Pair } from '@eva-model/idMap';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { Subject, Subscription } from 'rxjs';
import { LoggingService } from '@eva-core/logging.service';
import { MatDialog } from '@angular/material/dialog';
import { Guid } from '@eva-core/GUID/guid';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { FormBuilderComponent } from '@eva-ui/form-builder/form-builder.component';
import { take } from 'rxjs/operators';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { IdMapping, IDMappingResponse, IdMappingType } from '@eva-model/id-mapping/id-mapping';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-interaction-import-export',
  templateUrl: './interaction-import-export.component.html',
  styleUrls: ['./interaction-import-export.component.scss']
})
export class InteractionImportExportComponent implements OnDestroy {
  private idMapperGetUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getMappingId'; // the id mapper for the settings

  idList: any[] = [];                                                     // This is the list for storing all the mapped id's for
  uploadedJSONSource: Subject<any>;                                       // This is the subscription for importing a file for workflow
  uploadedJSONSubscription: Subscription;
  importedInteractionGroupName = '';                                      // Group name of the imported interaction
  generalDialogChangeSubs: Subscription;

  @ViewChild('uploadJSONFile') uploadFileInput: ElementRef;               // reference of import input html element
  @Input() isWaiting: boolean;                                            // whether waiting for interaction submission or not
  @Input() waitMessage: string;                                           // message displayed when isWaiting is true
  @Input() buttonType: string;                                            // Type of the button to show - "Upload"/"Download"
  @Input() interactionSubmittedToBlockSubscription: Subject<boolean>;     // This is the subscription for when the imported interaction
                                                                          // gets submitted to block
  @Input() dynamicForm: any;                                              // Interaction being imported
  @Input() submitFormToBlock: Function;                                   // This function submits the form with updates to the Blockchain
  @Input() openFormViewerDialog: Function;                                // Opens and subscribes to the Form Viewer dialog
  @Input() interactionBuilderComponent: FormBuilderComponent;             // Form builder component

  @Output() isWaitingChange: EventEmitter<boolean>
  = new EventEmitter<boolean>();                                          // Emitter for isWaiting value changes
  @Output() waitMessageChange: EventEmitter<string>                       // Emitter for waitMessage value changes
  = new EventEmitter<string>();
  importedInteractionName = '';                                           // Name of the imported interaction

  constructor(private idMapperService: IdMapperService,
    private interactionService: DynamicInteractionsService,
    private evaGlobalService: EvaGlobalService,
    private formBuilderService: FormBuilderService,
    private _http: HttpClient,
    private logService: LoggingService,
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService) { }

  ngOnDestroy() {
    if (this.generalDialogChangeSubs) {
      this.generalDialogChangeSubs.unsubscribe();
    }

    if (this.uploadedJSONSubscription) {
      this.uploadedJSONSubscription.unsubscribe();
    }
  }

  /**
  * This function downloads the selected interaction as .json file
  *
  * @param interaction Interaction being exported as .json file
  */
 downloadJSON(): void {
  const interactionId = this.dynamicForm.id;
  const interactionVersion = this.dynamicForm.version;

  this.isWaiting = true;
  this.isWaitingChange.emit(this.isWaiting);
  this.waitMessage = 'Loading the interaction for export';
  this.waitMessageChange.emit(this.waitMessage);
  this.interactionService.fetchInteractionsByIdAndVer(interactionId, Number(interactionVersion))
    .subscribe(
      (interactionData) => {
        const interactionObject = this.interactionService.interactionObjectMapper(interactionData);
        let environmentName = '';
        const url = window.location.href;

        // set the environment based on url
        if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
          environmentName = 'Development';
        } else if (url.includes('staging.atbeva.com')) {
          environmentName = 'Staging';
        } else if (url.includes('test.atbeva.com')) {
          environmentName = 'Test';
        } else if (url.includes('atbeva.com')) {
          environmentName = 'Production';
        }

        // create a json object and save it as string in the file to be downloaded
        const interactionJSON = JSON.stringify(<ExportImportInteraction>{
          interactionVersion: interactionVersion,
          interactionMapperObject: interactionObject,
          environment: environmentName,
          groupName: this.evaGlobalService.getGroupNameByPublicKey(interactionObject.groupPublicKey)
        });
        const blob = new Blob([interactionJSON], { type: 'text/json' });
        saveAs(blob, this.dynamicForm.name + '-interaction' + '.json');
      },
      (err) => {
        console.log(err);
        this.isWaiting = false;
        this.isWaitingChange.emit(this.isWaiting);
        this.waitMessage = '';
        this.waitMessageChange.emit(this.waitMessage);
      },
      () => {
        this.isWaiting = false;
        this.isWaitingChange.emit(this.isWaiting);
        this.waitMessage = '';
        this.waitMessageChange.emit(this.waitMessage);
      }
    );
}
/**
 * This function initiates interaction import
 */
uploadJSON(): void {
  try {
    this.idList = [];
    this.idList.length = 0;
    const inputElement = this.uploadFileInput.nativeElement as HTMLElement;
    inputElement.click();
  } catch (err) {
    console.log(err);
  }
}

/**
 * This function imports the interaction from .json file
 *
 * @param event HTMLChangeEvent
 */
uploadFile(event: any): void {
  const files = event.target.files;                         // list of files being imported
  if (files.length > 0) {
    if (files[0].type === 'application/json') {
      this.uploadedJSONSource = new Subject<any>();   // subscription for reading data from json file once ready
      const fileReader = new FileReader();                      // async file reader js library
      fileReader.readAsText(files[0], "UTF-8");                 // specify file to be opened as text
      fileReader.onload = () => {                               // this runs once file is ready
        try {
          const jsonData = JSON.parse(fileReader.result as string);
          // if json object is not a valid workflow object, notify user
          if (!jsonData['interactionMapperObject']) {
            this.logService.logMessage('Please upload an interaction file', false, 'error');
            event.target.value = '';
            return;
          }
          // update subscription with json file data
          this.uploadedJSONSource.next(jsonData);
        } catch (error) {
          this.uploadedJSONSource.unsubscribe();
          console.error(error);
        }
      };
      fileReader.onerror = (error) => {                         // this runs if opening file fails
        this.logService.logMessage(error.type, false, 'error');
      };
      // open user choice dialog once json file is opened
      this.uploadedJSONSubscription = this.uploadedJSONSource.subscribe(jsonData => {
        // open id map dialog box for choosing action to perform - create new or map existing
        if (jsonData) {
          this.openImportActionDialog(jsonData);
          this.importedInteractionName = jsonData['interactionMapperObject'].name;
        }
        this.uploadedJSONSubscription.unsubscribe();
      });
    } else {
      this.logService.logMessage('Invalid file type. Please upload .json file.', false, 'error');
    }
    event.target.value = '';
  }
}

/**
 * This function is called when user chooses to create a new interaction from imported one
 * and opens a dialog box for user to choose the action
 *
 * @param jsonData Interaction data from imported .json file
 */
private openCreateNewInteractionDialog(jsonData: ExportImportInteraction): void {
  this.openGroupSelectDialog(jsonData);
}

/**
 * This function opens a dialog box of type GroupSelectDialogComponent
 *
 * @param jsonData Interaction data from imported .json file
 */
private openGroupSelectDialog(jsonData: ExportImportInteraction): void {
  const groupSelectDialogRef = this.dialog.open(GroupSelectDialogComponent, {
    data: entityType.interaction,
    disableClose: true
  });
  // After groupSelectDialog is closed, update the imported interaction id's
  groupSelectDialogRef.afterClosed().subscribe(async (userSelection: GroupSelectDialogResult) => {
    if (userSelection) {
      const newInteractionId = Guid.newGuid().toString();
      this.isWaiting = true;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = 'Updating interaction id\'s';
      this.waitMessageChange.emit(this.waitMessage);

      try {
        // change all the id's in the imported interaction
        await this.changeIds(jsonData, newInteractionId, []);
        // send the new id mappings to the database
        const dbInteractionUpdateResult = await this.idMapperService.sendInteractionIds();
        const dbElementUpdateResult = await this.idMapperService.sendElementIds();
        // if not all id mappings are added, Notify the user
        if (!dbInteractionUpdateResult || !dbElementUpdateResult) {
          this.logService.logMessage('Not all ids added', false, 'error');
          return Promise.reject('Not all ids added');
        }
      } catch (error) {
        this.logService.logMessage('Not all ids added', false, 'error');
        console.error(error);
      }
      // assign the selected group and new id to the imported interaction
      jsonData['interactionMapperObject'].id = newInteractionId;
      jsonData['interactionMapperObject'].groupPublicKey = userSelection.groupPublicKey;
      this.interactionBuilderComponent.dynamicForm = jsonData['interactionMapperObject'];
      this.dynamicForm = jsonData['interactionMapperObject'];

      const dialogData: FormViewerModel = new FormViewerModel(
        'Do you want to create the following interaction?',
        undefined,
        'Yes', 'No', this.interactionBuilderComponent.dynamicForm);
      this.isWaiting = false;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = '';
      this.waitMessageChange.emit(this.waitMessage);
      // open interaction viewer to let user visualize the imported interaction being submitted to block
      this.openFormViewerDialog(dialogData, this.submitFormToBlock, { that: this.interactionBuilderComponent, isImportSubmit: true });

      // once workflow is saved, open IdMapIdChangesComponent dialog for changed id's
      this.interactionSubmittedToBlockSubscription.pipe(take(1)).subscribe(importedInteractionTimestamp => {
        if (importedInteractionTimestamp) {
          const changesDialogData: FormViewerModel = new FormViewerModel(
            undefined,
            undefined,
            'Ok', 'Cancel',
            {
              entity: jsonData['interactionMapperObject'],
              type: 'Interaction',
              idList: this.idList,
              environment: jsonData.environment,
              oldEntityVersion: jsonData.interactionVersion,
              newEntityVersion: importedInteractionTimestamp,
              oldGroupName: this.importedInteractionGroupName,
              importedEntityName: this.importedInteractionName
            }
          );
          this.dialog.open(IdMapIdChangesComponent, {
            data: changesDialogData,
            disableClose: true,
            minWidth: '975px'
          });
        }
      });
    }
  });
}

/**
 * This function is called when user chooses to map an existing workflow from imported one
 *
 * @param jsonData Workflow data from imported .json file
 */
openMapExistingInteractionDialog(jsonData: ExportImportInteraction): void {
  this.openIdMapInteractionSelectDialog(jsonData);
}

/**
 * This function opens dialog box of type EntityPickerDialogComponent
 *
 * @param jsonData Interaction data from imported .json file
 */
openIdMapInteractionSelectDialog(jsonData: ExportImportInteraction): void {
  const dialogData = new GeneralDialogModel(
    'Select an Interaction',
    `You may choose a group then an interaction and next its version to pick the interaction`,
    'Submit', 'Cancel', null,
    {
      "userGroups": JSON.parse(JSON.stringify(this.evaGlobalService.userGroups)),
      "entityType": entityType.interaction,
      "pickType": entityPickType.all
    }
  );
  const callbackData = { data: dialogData };
  const idMapInteractionSelectRef = this.dialog.open(EntityPickerDialogComponent, callbackData);
  this.generalDialogChangeSubs = this.generalDialogService.generalDialogChanged$.subscribe(
    changeObj => {
      if (changeObj) {
        callbackData['generalDialogOnChange'] = changeObj;
      }
    },
    err => { console.log(err); }
  );
  idMapInteractionSelectRef.afterClosed().subscribe((result: boolean) => {
    if (result) {
      this.openIdMapInteractionElementDialog(jsonData, callbackData);
    }
  });
}

/**
 * This function updates the imported interaction with new ids
 *
 * @param jsonData Interaction object being updated
 * @param callbackData Data from EntityPickerDialogComponent
 * @param elementPairs Pairs of mapped form elements
 * @param interaction Interaction imported interaction being mapped to
 */
async updateInteractionObject(jsonData: ExportImportInteraction, callbackData: any, elementPairs: Pair[],
  interaction: any): Promise<any> {
    try {
      // change all id's of the imported interaction
      await this.changeIds(jsonData, callbackData['generalDialogOnChange'].entityId,
        JSON.parse(JSON.stringify(elementPairs)));
      const dbInteractionUpdateResult = await this.idMapperService.sendInteractionIds();
      const dbElementUpdateResult = await this.idMapperService.sendElementIds();

      if (!dbInteractionUpdateResult || !dbElementUpdateResult) {
        this.logService.logMessage('Not all ids added', false, 'error');
        return Promise.reject('Not all ids added');
      }
    } catch (error) {
      console.error(error);
      this.logService.logMessage('Not all ids added', false, 'error');
        return Promise.reject(error);
    }
  const interactionKeys = Object.keys(interaction);
  const idListItem = this.idList.find(idPair => idPair.newId === jsonData['interactionMapperObject'].id);
  if (idListItem) {
    idListItem.newId = interaction.id;
  }
  interactionKeys.forEach(key => {
    const value = interaction[key];
    if (typeof value !== 'object') {
      jsonData['interactionMapperObject'][key] = value;
    }
  });
}

/**
 * This function opens dialog box of type IdMapInteractionElementDialogComponent
 *
 * @param jsonData Imported Interaction object
 * @param callbackData Data from EntityPickerDialogComponent
 */
openIdMapInteractionElementDialog(jsonData: ExportImportInteraction, callbackData: any): void {
  this.isWaiting = true;
  this.isWaitingChange.emit(this.isWaiting);
  this.waitMessage = 'Fetching the selected interaction';
  this.waitMessageChange.emit(this.waitMessage);
  this.interactionService.fetchInteractionsByIdAndVer(callbackData['generalDialogOnChange'].entityId,
    callbackData['generalDialogOnChange'].entityVersion).subscribe(async interactionData => {
      const interaction = this.interactionService.interactionObjectMapper(interactionData);
      this.isWaiting = false;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = '';
      this.waitMessageChange.emit(this.waitMessage);

      let idExist = false;
      let idMapping: IdMapping = null;
      try {
        // fetch the mapped id's if exists
        const docId = jsonData['interactionMapperObject'].id + '_' + callbackData['generalDialogOnChange'].entityId;
        const idMappingRequest = {
          type: IdMappingType.ELEMENT,
          docId: docId
        };
        const documentData = await this._http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
          take(1)
        ).toPromise();

        if (typeof documentData.idMapping !== 'undefined') {
          idExist = true;
          idMapping = documentData.idMapping;
        }

      } catch (err) {
        console.log(err);
      }
      const mapDialogData: FormViewerModel = new FormViewerModel(
        undefined,
        undefined,
        'Ok', 'Cancel',
        {
          oldInteraction: interaction,
          newInteraction: jsonData['interactionMapperObject'],
          existingMapping: idExist ? idMapping : null,
          environment: jsonData.environment
        });

      const idMapInteractionElementRef = this.dialog.open(IdMapInteractionElementDialogComponent, {
        data: mapDialogData,
        disableClose: true
      });
      idMapInteractionElementRef.afterClosed().subscribe(async (elementPairs: Pair[]) => {
        if (elementPairs) {
          this.openIdMapInteractionChangesDialog(jsonData, interaction, idExist ? idMapping : null,
            callbackData, elementPairs);
        }
      });
    });
}

/**
 * This function opens dialog box of type IdMapInteractionChangesComponent
 *
 * @param jsonData Imported interaction object
 * @param interaction Interaction imported interaction being mapped to
 */
openIdMapInteractionChangesDialog(jsonData: ExportImportInteraction, interaction: any, existingMapping: any,
  callbackData: any, elementPairs: Pair[]): void {
  const changesDialogData: FormViewerModel = new FormViewerModel(
    undefined,
    undefined,
    'Ok', 'Cancel',
    {
      oldInteraction: interaction,
      newInteraction: jsonData['interactionMapperObject'],
      entityType: 'interaction',
      elementPairs: elementPairs
    });

  // open the IdMapInteractionChangesComponent Dialog to show changed form elements in the form screens
  const idMapInteractionChangesRef = this.dialog.open(IdMapInteractionChangesComponent, {
    data: changesDialogData,
    disableClose: true
  });

  // After the dialog is closed, submit the new interaction version to block
  idMapInteractionChangesRef.afterClosed().subscribe(async (userSelection: boolean) => {
    if (userSelection) {
      try {
        this.isWaiting = true;
        this.isWaitingChange.emit(this.isWaiting);
        this.waitMessage = 'Updating interaction id\'s';
        this.waitMessageChange.emit(this.waitMessage);
        await this.updateInteractionObject(jsonData, callbackData, elementPairs, interaction);
      } catch (error) {
        console.error(error);
      }

      // stop the loading
      this.isWaiting = false;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = '';
      this.waitMessageChange.emit(this.waitMessage);
      this.dynamicForm = jsonData['interactionMapperObject'];
      this.interactionBuilderComponent.dynamicForm = jsonData['interactionMapperObject'];
      this.submitFormToBlock({ that: this.interactionBuilderComponent, isImportSubmit: true });
      // once interaction is saved, open IdMapIdChangesComponent dialog for changed id's
      this.interactionSubmittedToBlockSubscription.pipe(take(1)).subscribe(importedInteractionTimestamp => {
        if (importedInteractionTimestamp) {
          const idChangesDialogData: FormViewerModel = new FormViewerModel(
            undefined,
            undefined,
            'Ok', 'Cancel',
            {
              entity: jsonData['interactionMapperObject'],
              type: 'Interaction',
              idList: this.idList,
              environment: jsonData.environment,
              oldEntityVersion: jsonData.interactionVersion,
              newEntityVersion: importedInteractionTimestamp,
              oldGroupName: this.importedInteractionGroupName,
              importedEntityName: this.importedInteractionName
            }
          );
          this.dialog.open(IdMapIdChangesComponent, {
            data: idChangesDialogData,
            disableClose: true,
            minWidth: '975px'
          });
        }
      });
    }
  });
}

/**
 * This function opens dialog box of type IdMapDialogComponent
 *
 * @param jsonData Interaction data from imported .json file
 */
openImportActionDialog(jsonData: ExportImportInteraction): void {
  this.importedInteractionGroupName = jsonData.groupName;

  jsonData.interactionMapperObject = this.formBuilderService.fixOldShapeForRelations(jsonData.interactionMapperObject);

  const idMapDialogRef = this.dialog.open(IdMapDialogComponent);
  idMapDialogRef.afterClosed().subscribe(userAction => {
    if (userAction === 'create') {
      this.openCreateNewInteractionDialog(jsonData);
    } else if (userAction === 'map') {
      this.openMapExistingInteractionDialog(jsonData);
    }
  });
}

/**
 * This function calls the recursive function to update ids of the imported interaction object
 *
 * @param jsonData Imported interaction object being updated
 * @param existingInteractionID Id of the existing interaction being mapped, if creating new, use same id as interaction.id
 * @param elementPairs Pairs of mapped form elements
 */
async changeIds(jsonData: any, existingInteractionID: string, elementPairs: Pair[]): Promise<any> {
  try {
    await this.searchObj(jsonData['interactionMapperObject'], 'id', jsonData['interactionMapperObject'].id,
      existingInteractionID, elementPairs, jsonData['interactionMapperObject']);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * This is a recursive function that updates the ids of the imported workflow object
 *
 * @param object imported workflow object being updated
 * @param query property of object being updated
 * @param interactionID Id of the imported interaction object from old environment
 * @param existingInteractionID Id of the existing interaction being mapped, if creating new, use same id as interaction.id
 * @param elementPairs Pairs of matched form elements
 * @param workflowMapperObject Imported workflow object
 */
async searchObj(object: any, query: string, interactionID: string, existingInteractionID: string,
  elementPairs: Pair[], interactionMapperObject: any): Promise<any> {
  try {
    for (const key in object) {
      const value = object[key];

      // if property is an object, recurse through it
      if (typeof value === 'object') {
        await this.searchObj(value, query, interactionID, existingInteractionID, elementPairs, interactionMapperObject);
      }

      // remove temporary now unnecessary properties
      if (key === 'marked' || key === 'selected') {
        delete object[key];
      }

      // if key is query and value is a valid GUID, update the value
      if (key === query && Guid.isValid(value) || (key === 'value' && Guid.isValid(value))) {
        const compareValue = Array.isArray(value) ? value[0] : value;
        const objectWithId = {
          oldId: compareValue,
          newId: null,
          docId: interactionID + '_' + existingInteractionID
        };

        // check if id exists as a pair in paired elements
        // if so, use the mapped id else check if the id exists in the database otherwise use new id
        const pair = elementPairs.find(elementPair => elementPair.new.id === compareValue);
        if (pair) {
          objectWithId.newId = pair.old.id;
          if (interactionID === compareValue) {
            await this.idMapperService.mapInteractionId(compareValue, interactionID, existingInteractionID, pair.old.id);
          } else if (interactionMapperObject.specialControls.find(specialControl => specialControl.id === compareValue)) {
            await this.idMapperService.mapElementId(compareValue, interactionID, existingInteractionID, pair.old.id);
          } else if (interactionMapperObject.FormScreens.find((formScreen: any) =>
            formScreen.FormElements.find((formElement: any) =>
              formElement.id === compareValue
            )
          ) || interactionMapperObject.specialControls.find((specialControl: any) =>
            specialControl.controls.find((control: any) =>
              control.id === compareValue)
          )
          ) {
            await this.idMapperService.mapElementId(compareValue, interactionID, existingInteractionID, pair.old.id);
          }
        } else {
          if (interactionID === compareValue) {
            // if value is interaction id, assign it existingInteractionID
            objectWithId.newId = await this.idMapperService
              .mapInteractionId(compareValue, interactionID, existingInteractionID, existingInteractionID);
          } else if (this.idList.find(idPair => idPair.oldId === compareValue)) {
            // if id already exists in the already paired id's list, use that mapped id
            const idListPair = this.idList.find(idPair => idPair.oldId === compareValue);
            if (idListPair) {
              objectWithId.newId = idListPair.newId;
            }
          } else if (interactionMapperObject.specialControls.find(specialControl => specialControl.id === compareValue)) {
            objectWithId.newId = await this.idMapperService.mapElementId(compareValue, interactionID, existingInteractionID, null, true);
          } else if (interactionMapperObject.FormScreens.find((formScreen: any) =>
            formScreen.FormElements.find((formElement: any) =>
              formElement.id === compareValue
            )
          ) || interactionMapperObject.specialControls.find((specialControl: any) =>
            specialControl.controls.find((control: any) =>
              control.id === compareValue)
          )
          ) {
            // else if id is of a form element, check the element collection
            objectWithId.newId = await this.idMapperService.mapElementId(compareValue, interactionID, existingInteractionID, null, true);
          }
        }

        // update id map pair old id with the imported entity id from previous environment based on type of entity
        objectWithId['oldId'] = compareValue;
        if (!this.idList.find(idPair => idPair.newId === compareValue)) {
          object[key] = Array.isArray(value) ? [objectWithId.newId] : objectWithId.newId;
        }
        // update form element name property if current object is a form element
        if (object['name'] === compareValue) {
          object['name'] = objectWithId.newId;
        }
        // add mapped pair to the list
        this.idList.push(objectWithId);
      }
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
  return Promise.resolve();
}

}
