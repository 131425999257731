import { InteractionVisualizerMode } from "@eva-model/interactionVisualizerDialogModel";
import { ProcessInteractionValues } from "@eva-model/process/process";

export interface DynamicComponent {
    dynFrmObj: any;
    isSubmitEnable: boolean;
    isAllDisabled: boolean; // to disable all controls
    dbId: string;
    isSubmittingSimulate: boolean; // to flag simulate submitting
    isSubmittingSimulateMsg: string; // to message while simulating submitting
    processId?: string;
    workflowId?: string;
    targetId?: string; // the id of the target in case of emitting an object through subject observables
    previouslyCompleted?: boolean;   // checks if the interaction is being reloaded
    formScreenPosition?: number; // index number of form screen in interaction
    selectedFormScreen?: number; // form screen selected by used
    visualizerMode?: InteractionVisualizerMode; // determines if the interaction is being edited or not
    showBackButton?: boolean; // boolean to determine whether or not to show the back button
    isJoinChange?: boolean; // boolean to determine whether the dialog box is a Join Change or not
    isPreview?: boolean;
    processTitle?: string;
    tabIndex?: number;
    processInteractionValues?: ProcessInteractionValues;
  }

  // Canada Post Address Complete related soecial control "Select" control for choosing found addresses
  export interface CpCntrlChangeWatch {
    id: string;
    scrnIdx: number; // screen index of the interaction
    customType: string; // the custom element type
    spclCntrlId: string; // interaction special control id
  }
