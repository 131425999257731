<div class="id-map-container">
  <button mat-icon-button [mat-dialog-close]="submitToBlock" class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Changes</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div [class.form-container]="entityType === entityTypes.interaction"
      [class.workflow-container]="entityType === entityTypes.workflow">
      <div *ngIf="entityType === entityTypes.interaction" class="half-width">
        <div class="type-title">
          Current Version : {{dialogData.dynFormMdl.oldInteraction.name}}
        </div>
        <mat-horizontal-stepper #oldMatHorizontalStepper>

          <mat-step *ngFor="let screen of oldFormScreens; let screenIndex = index;">
            <ng-template matStepLabel>{{screen.name}}</ng-template>
            <mat-divider></mat-divider>
            <br />

            <div *ngIf="oldFormViewerFormGroupArray[screenIndex]" [class.full-width]="!oldWorkflow">
              <form [formGroup]="oldFormViewerFormGroupArray[screenIndex]">

                <dynamic-material-form [group]="oldFormViewerFormGroupArray[screenIndex]"
                  [layout]="oldFormViewerLayoutArray[screenIndex]" [model]="oldFormViewerModelArray[screenIndex]">
                </dynamic-material-form>

              </form>
            </div>

            <br />
            <div>
              <button *ngIf="screenIndex > 0" mat-raised-button matStepperPrevious type="button">Back</button>
              <button *ngIf="screenIndex < oldFormScreens.length - 1" mat-raised-button matStepperNext
                type="button">Next</button>
            </div>
            <br />
          </mat-step>
        </mat-horizontal-stepper>
      </div>
      <span *ngIf="entityType === entityTypes.workflow">Current Version : {{this.oldWorkflow.name}}</span>
      <div *ngIf="entityType === entityTypes.workflow" class="container-graph" #containerGraph>
        <app-workflow-graph-visualizer
          [containerGraph]="containerGraph"
          [workflow]="oldWorkflow"
          [interactionsByGroups]="interactionsByGroups"
        ></app-workflow-graph-visualizer>
      </div>
      <span class="vertical-border" [class.full-height]="entityType === entityTypes.interaction"></span>
      <div *ngIf="entityType === entityTypes.interaction" class="half-width">
        <div class="type-title">
          New Version : {{dialogData.dynFormMdl.newInteraction.name}}
        </div>
        <mat-horizontal-stepper #newMatHorizontalStepper>

          <mat-step *ngFor="let screen of newFormScreens; let screenIndex = index;">
            <ng-template matStepLabel>{{screen.name}}</ng-template>
            <mat-divider></mat-divider>
            <br />

            <div *ngIf="newFormViewerFormGroupArray[screenIndex]" [class.full-width]="!oldWorkflow">
              <form [formGroup]="newFormViewerFormGroupArray[screenIndex]">

                <dynamic-material-form [group]="newFormViewerFormGroupArray[screenIndex]"
                  [layout]="newFormViewerLayoutArray[screenIndex]" [model]="newFormViewerModelArray[screenIndex]">
                </dynamic-material-form>

              </form>
            </div>

            <br />
            <div>
              <button *ngIf="screenIndex > 0" mat-raised-button matStepperPrevious type="button">Back</button>
              <button *ngIf="screenIndex < newFormScreens.length - 1" mat-raised-button matStepperNext
                type="button">Next</button>
            </div>
            <br />
          </mat-step>
        </mat-horizontal-stepper>
      </div>
      <span *ngIf="entityType === entityTypes.workflow">New Version : {{this.newWorkflow.name}}</span>
      <div *ngIf="entityType === entityTypes.workflow" class="container-graph" #containerGraphNew>
        <app-workflow-graph-visualizer
        [containerGraph]="containerGraphNew"
        [workflow]="newWorkflow"
        [interactionsByGroups]="interactionsByGroups"
      ></app-workflow-graph-visualizer>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="!submitToBlock" color="primary">Submit</button>
    <button mat-stroked-button [mat-dialog-close]="submitToBlock">Cancel</button>
    <div class="legend" *ngIf="entityType === entityTypes.interaction">
      <div class="added margin-left"></div> <span class="margin-left-small">- Added</span>
      <div class="removed margin-left"></div> <span class="margin-left-small">- Removed</span>
      <div class="updated margin-left"></div> <span class="margin-left-small">- Updated</span>
    </div>
  </mat-dialog-actions>
</div>