<div class="outer-margins process-dashboard">
  <div class="heading-row">
    <h2 class="heading">{{ componentTitle }}</h2>
    <div class="action-buttons button-row-left">
      <button mat-stroked-button *ngIf="isAdministrator" (click)="showAdminMessage = !showAdminMessage">Change Admin Message</button>
      <button id="dashboardTutorialStep4" matTooltip="Select this if you would like to refresh the table data after every 10 seconds for a minute after inactivity"
        mat-stroked-button [class.refreshButton]="!toggleRefresh" *ngIf="!refreshLoading"
        [class.refreshButtonSelected]="toggleRefresh" (click)="toggleAutoRefresh()">
        <mat-icon>refresh</mat-icon>
        {{ toggleRefresh ? 'On' : 'Off' }}
      </button>
      <div class="action-buttons-spinner" *ngIf="refreshLoading" [class.hidden]="!refreshLoading" [class.show]="refreshLoading">
        <mat-spinner diameter="20" backgroundColor="#000"></mat-spinner>
      </div>
      <button id="dashboardTutorialStep5" *ngIf="!refreshEmailLoading" matTooltip="When turned on you’ll receive a completion email to {{ userEmail }} on successfully completed processes that you’ve submitted"
      mat-stroked-button [ngClass]="emailToggle ? 'emailToggleButtonSelected' : 'emailToggleButton' " (click)="toggleSendEmails()">
        <mat-icon>mail_outline</mat-icon>
        {{ emailToggle ? 'On' : 'Off' }}
      </button>
      <div class="action-buttons-spinner" *ngIf="refreshEmailLoading" [class.hidden]="!refreshEmailLoading" [class.show]="refreshEmailLoading">
        <mat-spinner diameter="20" backgroundColor="#000"></mat-spinner>
      </div>
    </div>
  </div>
  <!-- set rowData to async when using live data -->
  <br>

  <mat-card *ngIf="showAdminMessage" class="reasonCard">
    <mat-form-field class="textarea-input">
      <textarea #adminMessageInput matInput value="{{ administratorMessage }}" type="textarea" id="messageText" (input)="onAdminMessageChange()"
        placeholder="Enter a new message for EVA users on the Process Dashboard"></textarea>
    </mat-form-field>
    <div class="button-container">
      <button mat-raised-button (click)="showAdminMessage = !showAdminMessage">Close</button>
      <button mat-raised-button (click)="showAdminMessage = !showAdminMessage" (click)="submitNewMessage(adminMessageInput, true)">Clear Message</button>
      <mat-spinner [class.hidden]="!adminLoading" [class.show]="adminLoading" diameter="20" backgroundColor="#000"></mat-spinner>
      <button mat-button type="button" (click)="submitNewMessage(adminMessageInput)" *ngIf="!adminLoading" [disabled]="disableSubmitButton">Submit</button>
    </div>
  </mat-card>

  <div id="dashboardTutorialStep3">
    <h3 *ngIf="administratorMessage && administratorMessage.trim().length > 0" class="margin-bottom">
      <b> Dashboard Administrator Message: </b> 
      {{ administratorMessage.includes('EVA Support Model')
        ? administratorMessage.substring(0, administratorMessage.indexOf('EVA Support Model'))
        : administratorMessage }}
        <a *ngIf="administratorMessage.includes('EVA Support Model')" target="_blank" href="https://docs.google.com/document/d/1g86gfsY5UITmSseEkf1vEL7B1nRppG0psY8AuT-FRwk/edit">EVA Support Model</a>
        {{ administratorMessage.includes('EVA Support Model')
        ? administratorMessage.substring(administratorMessage.indexOf('EVA Support Model') + 17)
        : '' }}
    </h3>
  </div>

  <div class="instant-search-container">
    <app-generic-search
      (algoliaSearchRef)="onReferenceChange($event)"
      (agGridRef)="onAgGridReferenceChange($event)"
      (filterModelEvent)="onFilterModelChange($event)"
      [indexName]="indexName"
      [tokenType]="algoloaTokenType"
      searchResultTitleProperty="processName"
      searchResultSubTitleProperty="processId"
      [propertyNameMapping]="processPropertyNameMapping"
      [rowHeight]="rowHeight"
      [columnDefs]="columnDefs"
      [defaultColumnDef]="defaultColDef"
      searchResultTooltipProperty="exceptionMessage"
    ></app-generic-search>
  </div>
</div>
