import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormViewerModel } from '@eva-model/formViewerModel';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { DynamicFormService } from '@ng-dynamic-forms/core';
import { isEqual } from 'lodash';
import { WorkFlow } from '@eva-model/workflow';
import { entityType } from '@eva-model/entity';
import { Pair } from '@eva-model/idMap';

@Component({
  selector: 'app-id-map-interaction-changes',
  templateUrl: './id-map-interaction-changes.component.html',
  styleUrls: ['./id-map-interaction-changes.component.scss']
})
export class IdMapInteractionChangesComponent implements OnInit, AfterViewInit {

  oldFormViewerModelArray = [];                                   // List of Form Elements for mapped interaction
  oldFormViewerFormGroupArray: UntypedFormGroup[] = [];                  // List of Form Groups for Form Elements for mapped interaction
  oldFormViewerLayoutArray = [];                                  // List of Form Screen layouts from Form Screens for mapped interaction
  entityTypes = entityType;                                       // List of entity types
  elementPairs: Pair[];                                           // mapped elements from IdMapInteractionElementDialogComponent

  oldFormScreens: any;                                            // List of form screens for mapped interaction
  oldDynamicForm: any = {};                                       // Mapped Interaction object

  newFormViewerModelArray = [];                                   // List of Form Elements for imported interaction from current environment
  newFormViewerFormGroupArray: UntypedFormGroup[] = [];                  // List of Form Groups for Form Elements for imported interaction
  newFormViewerLayoutArray = [];                                  // List of Form Screen layouts from Form Screens for imported interaction

  newFormScreens: any;                                            // List of form screens for imported interaction from current environment
  newDynamicForm: any = {};                                       // Imported interaction object from current environment
  entityType: string = null;                                      // Current entity type being imported
  oldWorkflow: WorkFlow = null;                                   // Workflow object being mapped
  newWorkflow: any = null;                                        // Imported workflow object from current environment

  interactionsByGroups: any;                                      // List of user interactions by groups from current environment

  submitToBlock = false;                                          // Whether to submit changes to block or not

  @ViewChild("oldMatHorizontalStepper", { read: ElementRef }) oldMatHorizontalStepper: ElementRef;
  @ViewChild("newMatHorizontalStepper", { read: ElementRef }) newMatHorizontalStepper: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: FormViewerModel,
    public formBuilderService: FormBuilderService,
    private ngDynFormService: DynamicFormService) {
    // get form screens for the mapped interaction from current environment
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.oldInteraction &&
      dialogData.dynFormMdl.oldInteraction.FormScreens &&
      Array.isArray(dialogData.dynFormMdl.oldInteraction.FormScreens)) {
      this.oldFormScreens = dialogData.dynFormMdl.oldInteraction.FormScreens;
    }
    // get form screens for the imported interaction
    if (dialogData.dynFormMdl &&
      dialogData.dynFormMdl.newInteraction &&
      dialogData.dynFormMdl.newInteraction.FormScreens &&
      Array.isArray(dialogData.dynFormMdl.newInteraction.FormScreens)) {
      this.newFormScreens = dialogData.dynFormMdl.newInteraction.FormScreens;
    }
    this.entityType = dialogData.dynFormMdl.entityType;
    this.interactionsByGroups = dialogData.dynFormMdl.interactionsByGroups;
    this.oldWorkflow = dialogData.dynFormMdl.oldWorkflow;
    this.newWorkflow = dialogData.dynFormMdl.newWorkflow;
  }

  ngOnInit() {
    this.oldDynamicForm = this.formBuilderService.cloneInteraction(this.dialogData.dynFormMdl.oldInteraction);
    if (!this.oldDynamicForm) return;
    this.oldFormScreens = this.oldDynamicForm.FormScreens;   // Utilized in template
    this.elementPairs = this.dialogData.dynFormMdl.elementPairs;

    // Loop through the mapped interaction screens to generate form groups beside keep collecting form's model and layouts

    if (this.oldFormScreens) {
      this.oldFormScreens.forEach(formScreen => {
        this.oldFormViewerModelArray.push(formScreen.FormElements);
        this.oldFormViewerFormGroupArray.push(this.ngDynFormService.createFormGroup(formScreen.FormElements));
        this.oldFormViewerLayoutArray.push(formScreen.FormElementsLayout);
      });
    }

    this.newDynamicForm = this.formBuilderService.cloneInteraction(this.dialogData.dynFormMdl.newInteraction);
    if (!this.newDynamicForm) return;
    this.newFormScreens = this.newDynamicForm.FormScreens;   // Utilized in template

    // Loop through the imported interaction screens to generate form groups beside keep collecting form's model and layouts

    if (this.newFormScreens) {
      this.newFormScreens.forEach(formScreen => {
        this.newFormViewerModelArray.push(formScreen.FormElements);
        this.newFormViewerFormGroupArray.push(this.ngDynFormService.createFormGroup(formScreen.FormElements));
        this.newFormViewerLayoutArray.push(formScreen.FormElementsLayout);
      });
    }
  }

  /**
   * This function updates the UI for the elements that are changed from the previous version
   */
  ngAfterViewInit() {
    if (this.oldMatHorizontalStepper && this.newMatHorizontalStepper) {
      const oldMatHorizontalStepper = this.oldMatHorizontalStepper.nativeElement as HTMLElement;
      const newMatHorizontalStepper = this.newMatHorizontalStepper.nativeElement as HTMLElement;
      const oldMatSteps = oldMatHorizontalStepper.children.item(1);
      const newMatSteps = newMatHorizontalStepper.children.item(1);

      // create copies of interaction objects for modifying them for UI changes
      let oldDynamicFormCopy = null, newDynamicFormCopy = null;
      oldDynamicFormCopy = JSON.parse(JSON.stringify(this.dialogData.dynFormMdl.oldInteraction));
      newDynamicFormCopy = JSON.parse(JSON.stringify(this.dialogData.dynFormMdl.newInteraction));

      // For every form screen in mapped interaction, compare form elements with imported
      // interaction form elements and add the styles accordingly
      oldDynamicFormCopy.FormScreens.forEach((oldFormScreen: any, oldScreenIndex: number) => {
        let oldChildItem = null;
        let newChildItem = null;
        const oldMatStep = oldMatSteps.children.item(oldScreenIndex); // mat step html tag reference from form visualizer
        const oldFormDiv = oldMatStep.children.item(2); // get form container div tag reference from form visualizer
        const oldForm = oldFormDiv.children.item(0);  // get form tag reference from form visualizer for mapped interaction
        const oldFormControlsElement = oldForm.children.item(0);  // get form elements for the selected screen from form visualizer

        if (oldScreenIndex > newDynamicFormCopy.FormScreens.length - 1) {
          // if mapped interaction form screen index > imported interaction form screen length,
          // means the form screen have been removed in the imported interaction so add "Red" background to removed form elements
          oldFormScreen.FormElements.forEach((oldFormElement: any, oldElementIndex: number) => {
            const oldItem = oldFormControlsElement.children.item(oldElementIndex);
            if (oldItem) {
              oldChildItem = oldItem.children.item(0) as HTMLElement;
              oldChildItem.style.backgroundColor = 'rgba(255,0,0,0.2)';
            }
          });
        }

        // for every form screen in imported interaction, compare form elements with mapped
        // interaction form elements and add the styles accordingly
        newDynamicFormCopy.FormScreens.forEach((newFormScreen: any, newScreenIndex: number) => {
          const newMatStep = newMatSteps.children.item(newScreenIndex); // mat step html tag reference from form visualizer
          const newFormDiv = newMatStep.children.item(2); // get form container div tag reference from form visualizer
          const newForm = newFormDiv.children.item(0);  // get form tag reference from form visualizer for imported interaction
          const newFormControlsElement = newForm.children.item(0);  // get form elements for the selected screen from form visualizer

          if (oldScreenIndex === newScreenIndex) {
            // if mapped interaction form screen index is same as imported interaction form screen index,
            // compare the form elements for those screens
            oldFormScreen.FormElements.forEach((oldFormElement: any, oldElementIndex: number) => {
              const oldItem = oldFormControlsElement.children.item(oldElementIndex);  // mapped form element container tag reference
              if (oldItem) {
                oldChildItem = oldItem.children.item(0) as HTMLElement; // mapped form element reference
                let oldElementExists = false;
                // For every imported interaction form element, compare with mapped interaction form element,
                // and add the styles accordingly
                newFormScreen.FormElements.forEach((newFormElement: any, newElementIndex: number) => {
                  const newItem = newFormControlsElement.children.item(newElementIndex);  // imported form element container tag reference
                  if (newItem) {
                    newChildItem = newItem.children.item(0) as HTMLElement; // imported form element reference
                    if (this.elementPairs && this.elementPairs
                        .find(pair => pair.new.id === newFormElement.id && pair.old.id === oldFormElement.id)) {
                      // if old and new element are already paired
                      const oldFormElementCopy = JSON.parse(JSON.stringify(oldFormElement));
                      const newFormElementCopy = JSON.parse(JSON.stringify(newFormElement));
                      // remove id property from the clones for checking equality since that property can be different
                      this.removeProperty(oldFormElementCopy, 'id');
                      this.removeProperty(newFormElementCopy, 'id');
                      // check if form elements are equal
                      if (!isEqual(oldFormElementCopy, newFormElementCopy)) {
                        // if not, change colors to "Yellow" for updated form elements
                        oldChildItem.style.backgroundColor = 'rgba(255,255,0,0.2)';
                        newChildItem.style.backgroundColor = 'rgba(255,255,0,0.2)';
                      } else {
                        // else if equal, don't add any color
                        oldChildItem.style.backgroundColor = 'rgba(0,0,0,0)';
                      }
                    }
                    if ((this.elementPairs && this.elementPairs
                        .find(pair => pair.new.id === newFormElement.id && pair.old.id === oldFormElement.id))
                      && oldFormElement.type === newFormElement.type) {
                      oldElementExists = true;
                    }
                  }
                });
                if (!oldElementExists) {
                  // if mapped Element doesn't exist in imported interaction, mark it as "Red" for removed
                  oldChildItem.style.backgroundColor = 'rgba(255,0,0,0.2)';
                }
              }
            });
            // for every imported form screen element, check if any new elements are added
            newFormScreen.FormElements.forEach((newFormElement: any, newElementIndex: number) => {
              const newItem = newFormControlsElement.children.item(newElementIndex);
              if (newItem) {
                newChildItem = newItem.children.item(0) as HTMLElement;
                let newElementExists = false;
                oldFormScreen.FormElements.forEach(oldFormElement => {
                  if ((this.elementPairs && this.elementPairs
                    .find(pair => pair.new.id === newFormElement.id && pair.old.id === oldFormElement.id))
                    && newFormElement.type === oldFormElement.type) {
                    newElementExists = true;
                  }
                });
                if (!newElementExists) {
                  // if imported form element is new, mark it as "Green" for newly added
                  newChildItem.style.backgroundColor = 'rgba(0,255,0,0.2)';
                }
              }
            });
          }
        });
      });

      if (newDynamicFormCopy.FormScreens.length > oldDynamicFormCopy.FormScreens.length) {
        // if imported interaction form screen length > mapped interaction form screen length,
        // means new form screen have been added, mark all the form elements as "Green" as newly added
        let newChildItem = null;
        newDynamicFormCopy.FormScreens.forEach((newFormScreen: any, newScreenIndex: number) => {
          const newMatStep = newMatSteps.children.item(newScreenIndex); // mat step html tag reference from form visualizer
          const newFormDiv = newMatStep.children.item(2); // get form container div tag reference from form visualizer
          const newForm = newFormDiv.children.item(0);  // get form tag reference from form visualizer for imported interaction
          const newFormControlsElement = newForm.children.item(0);  // get form elements for the selected screen from form visualizer

          if (newScreenIndex > oldDynamicFormCopy.FormScreens.length - 1) {
            newFormScreen.FormElements.forEach((newFormElement: any, newElementIndex: number) => {
              const newItem = newFormControlsElement.children.item(newElementIndex);
              if (newItem) {
                newChildItem = newItem.children.item(0) as HTMLElement; // imported form element reference
                newChildItem.style.backgroundColor = 'rgba(0,255,0,0.2)';
              }
            });
          }
        });
      }
    }
  }

  /**
   * This function removes the query property from the object
   *
   * @param object Current object being updated
   * @param query Property being removed
   */
  removeProperty(object: any, query: string): void {
    for (const key in object) {
      const value = object[key];

      if (value && typeof value === 'object') {
        this.removeProperty(value, query);
      }

      if (key === query || key === 'name' || key === 'marked' || key === 'selected') {
        delete object[key];
      }
    }
  }

}
