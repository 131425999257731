import { Component, Input, HostListener, ElementRef, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TeamMemberData, TeamMembers } from '@eva-model/releaseNotes';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { AddEditTeamMembersComponent } from './add-edit-team-members/add-edit-team-members.component';
import { TeamMembersService } from '@eva-services/about/team-members.service';
import { take, debounceTime } from 'rxjs/operators';
import { ChatService } from '@eva-services/chat/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements AfterViewInit, OnDestroy, OnInit {

  teamMembers: TeamMemberData[] = [];
  pageSize = 3;     // Team members per page
  totalSize = 0;    // Total Team members
  totalPages = 0;
  currentPage = 0;
  innerWidth = 0;

  _isAdministrator = false;
  componentSubs: Subscription = new Subscription();
  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  @ViewChild('teamMembersCard') elementRef: ElementRef;

  constructor(
    public teamMemberDialog: MatDialog,
    private teamMembersService: TeamMembersService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    this.componentSubs.add(this.chatService.isChatMinimized$.pipe(debounceTime(500)).subscribe(value => {
      this.updateInnerWidth();
      this.totalPages = Math.ceil(this.totalSize / this.pageSize);
      this.currentPage = 0;
    }));
  }

  ngAfterViewInit() {
    this.updateInnerWidth();
    this.getTeamMembers();
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }

  updateInnerWidth() {
    this.innerWidth = (this.elementRef.nativeElement as HTMLElement).offsetWidth;
    const size = Math.floor((this.innerWidth) / 400);
    this.pageSize = size > 0 ? size : 1;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateInnerWidth();
    this.totalPages = Math.ceil(this.totalSize / this.pageSize);
    this.currentPage = 0;
  }

  /**
   * This function gets the team members and sets them up for the component.
   */
  getTeamMembers() {
    this.teamMembersService.getTeamMembers().pipe(take(1)).subscribe(members => {
      this.teamMembers = (members.teamMembers) ? members.teamMembers : [];
      this.totalSize = members.teamMembers.length;
      this.totalPages = Math.ceil(this.totalSize / this.pageSize);
    });
  }

  /**
   * This function returns the team members for the selected page number.
   * Team members are divided in page groups based on this.pageSize limit
   */
  filteredArrayOfMembers(): TeamMemberData[] {
      const trimmedArray: TeamMemberData[]
        = this.teamMembers.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
      return trimmedArray;
  }

  /**
   * This function is used to print page numbers
   */
  counter(): Array<number> {
    return new Array(this.totalPages);
  }

  /**
   * This function updates the current page for team members
   * @param page current page number
   */
  changeCurrentPage(page: number) {
    this.currentPage = page;
  }

  /**
   * This brings up the add or edit dialog for team members.
   */
  addEditTeamMembers() {
    let dialogData = null;
    let dialogComponent = null;

    const title = 'Featured Team Members';
    const confirmButtonText = 'Save';
    const content = '';
    const cancelButtonText = 'Cancel';

    dialogData = new GeneralDialogModel(
      title,
      content, confirmButtonText,
      cancelButtonText, null,
      {
        data: this.teamMembers
      }
    );
    dialogComponent = AddEditTeamMembersComponent;
    const dialogRef: MatDialogRef<AddEditTeamMembersComponent, TeamMembers> = this.teamMemberDialog.open(dialogComponent, {
      data: dialogData,
      disableClose: true,
      minHeight: '400px',
      minWidth: '700px'
    });

    /**
     * Fires after this completes.
     */
    dialogRef.afterClosed().subscribe(async result => {
      // check for a result.
      if (result) {
        try {
          // update the team member.
          await this.teamMembersService.storeTeamMembers(result);
        } catch (err) {
          console.log(err);
        }
        // finally refactor the team members to show the outcome of the updates
        this.getTeamMembers();
      }
    });
  }

}
