import { Component, OnDestroy } from '@angular/core';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnDestroy {
  _isAdministrator = false;
  isLeftMenuExpanded = false;
  expandSub: Subscription;

  constructor(private menuNavigationService: MenuNavigationService) {
    this.expandSub = this.menuNavigationService.isExpanded$.subscribe(isExpanded => {
      this.isLeftMenuExpanded = isExpanded;
    });
  }

  ngOnDestroy(): void {
    if (this.expandSub) {
      this.expandSub.unsubscribe();
    }
  }

}
