// eslint-disable-next-line max-len
import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy, Inject } from '@angular/core';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { OpenKnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { KnowledgeMultiService } from '@eva-services/knowledge/knowledge-multi/knowledge-multi.service';
import { Subscription, Observable } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { AnnounceKnowledgeShow } from '@eva-model/chat/knowledge/chatKnowledge';
import { MatTabGroup, MatTabBody, MatTabChangeEvent } from '@angular/material/tabs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-knowledge-multi-view',
  templateUrl: './knowledge-multi-view.component.html',
  styleUrls: ['./knowledge-multi-view.component.scss'],
  providers: [KnowledgeMultiService]
})
export class KnowledgeMultiViewComponent implements OnInit, OnDestroy {
  tabs = this.knowledgeMultiService.getOpenDocs;
  tabsSelectedIndex = 0;

  showAnnouncer: Subscription;
  openKnowledgeDocuments$: Observable<OpenKnowledgeDocument[]>;

  @ViewChild('documentContainer', { read: ViewContainerRef }) documentContainer: ViewContainerRef;
  @ViewChild('knowledgeTabs') knowledgeTabs: MatTabGroup;
  @ViewChild(MatTabBody) tabContents: MatTabBody;

  constructor(
    private chatKnowledgeService: ChatKnowledgeService,
    private knowledgeMultiService: KnowledgeMultiService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.showAnnouncer = this.chatKnowledgeService.announceShowKnowledge$.pipe(
      filter((v) => !!(v)),
      throttleTime(500) // if happening too quickly, drop announces
    ).subscribe((announcement) => {
      this.chatKnowledgeService.clearShowAnnounce();

      const documentIndex = this.tabs.findIndex(tab => tab.docId === announcement.docId);

      if (documentIndex === -1) {
        // doc id is not open, create it and show
        // this.showNewDocument(announcement);
        this.createNewTab(announcement);
        return;
      }

      this.openExistingTab(documentIndex, (announcement.sectionId) ? announcement.sectionId : null);
    });
  }

  ngOnDestroy() {
    if (this.showAnnouncer) {
      this.showAnnouncer.unsubscribe();
    }
  }

  createNewTab(knowledge: AnnounceKnowledgeShow) {
    const openKnowledgeDoc: OpenKnowledgeDocument = {
      docId: knowledge.docId,
      docGroup: knowledge.docGroup,
      docVersion: knowledge.docVersion,
      modelVersion: knowledge.modelVersion,
      sectionId: knowledge.sectionId,
      promptForFeedback: knowledge.promptForFeedback,
      published: knowledge.published,
      viewKey: knowledge.viewKey,
      loading: true,
      docName: null,
      document: null
    };

    // check and see if we already have the document
    if (knowledge.document) {
      openKnowledgeDoc.document = knowledge.document;
      openKnowledgeDoc.docName = knowledge.document.name;
    }

    this.tabs.push(openKnowledgeDoc);

    // Preventing an expressionChangedAfterChecked error...
    // maybe when YOU have time, you should actually fix it.
    // ...
    // don't be a slacker now...
    setTimeout(() => {
      this.tabsSelectedIndex = this.tabs.length - 1;
      this.chatKnowledgeService.setActiveTabDocumentId(this.tabs[this.tabsSelectedIndex].docId);
    }, 0);

  }

  /**
   * listener for the angular material tabs component when the active tab is changed to another tab.
   */
  selectedIndexChanged(index: number) {
    this.chatKnowledgeService.setActiveTabDocumentId(this.tabs[index].docId);
  }

  /**
   * listener for the angular material tabs component when the active tab is changed to another tab.
   */
  selectedTabChanged(tab: MatTabChangeEvent) {
    // This is a complete hack. Material Tabs have no way to control the tab scroll and many other deficiences.
    // Oh well, am already committed. More: https://github.com/angular/components/issues/9592
    const tabScrollElement = this.document.querySelector('.mat-tab-body.mat-tab-body-active .mat-tab-body-content');
    tabScrollElement.scrollTop = this.chatKnowledgeService.getScrollPosition(this.tabs[tab.index].docId);
  }

  /**
   * activates an existing tab
   */
  openExistingTab(index: number, sectionId?: string) {
    this.tabsSelectedIndex = index;

    // once switched tabs, tell component to focus different section
    if (sectionId) {
      this.chatKnowledgeService.focusSectionId(this.tabs[index].docId, sectionId, true);
    }
  }

  /**
   * Removes an item from our tabs array, effectively removing a table from our tabs.
   */
  removeTab(index: number) {
    this.tabs.splice(index, 1);

    // determine if the left side needs to hide on home component
    if (this.tabs.length === 0) {
      // destroy cmp and hide left pane since no docs to show.
      this.chatKnowledgeService.announceKnowledgeHide();
    }
  }

}
