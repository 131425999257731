import { Pipe, PipeTransform } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { take, skip } from 'rxjs/operators';

 /**
  * Checks the users public keys for the argument.
  * Returns true or false if user belongs to group or not.
  *
  * ---
  * NOTE:
  *
  * For this pipe to work effectively:
  * Groups are usually ready when the app is ready, however there may be instances when a user is navigating directly to a page
  * from an app cold start or when not all the user data is loaded into memory. In order for this pipe to work, you must
  * add the async pipe to the end (in case groups are not ready)
  *
  * @example
  * <div *ngIf="publicKeyToCheck | isUserInGroup | async">User belongs to group!</div>
  */
@Pipe({
  name: 'isUserInGroup'
})
export class IsUserInGroupPipe implements PipeTransform {

  constructor(private evaGlobal: EvaGlobalService) {}

  async transform(groupPk: string): Promise<boolean> {
    let userGroupKeys = this.evaGlobal.userGroupsPublicKeys;
    if (!userGroupKeys || userGroupKeys.length === 0) {

      // unfortunately, the BehaviorSubject is initialized with the value of true
      // when the groups are ready, the subject again emits true...
      // very difficult to understand when something is ready...
      // so lets skip the first `true` and when the groups are ready, take the next `true`
      await this.evaGlobal.userGroupsChange$.pipe(
        skip(1),
        take(1)
      ).toPromise();

      // groups ready now.
      userGroupKeys = this.evaGlobal.userGroupsPublicKeys;
      return this.checkGroupsForKey( userGroupKeys, groupPk );
    } else {
      return this.checkGroupsForKey( userGroupKeys, groupPk );
    }
  }

  checkGroupsForKey(allGroups: string[], groupPk: string): boolean {
    const found = allGroups.find((pk) => pk === groupPk);
    // if find has a key, the key was found, else find will be undefined.
    return found ? true : false;
  }

}
