import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TfidfFeedbackDocument } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { KnowledgeFeedbackService } from '@eva-services/knowledge/feedback/knowledge-feedback.service';
import { Router } from '@angular/router';
import { LoggingService } from '@eva-core/logging.service';

@Component({
  selector: 'app-notifications-feedback-view',
  templateUrl: './notifications-feedback-view.component.html',
  styleUrls: ['./notifications-feedback-view.component.scss']
})
export class NotificationsFeedbackViewComponent {

  // loading state
  completing = false;

  constructor(@Inject(MAT_DIALOG_DATA) public feedback: TfidfFeedbackDocument,
              private dialogRef: MatDialogRef<NotificationsFeedbackViewComponent>,
              private knowledgeFeedbackService: KnowledgeFeedbackService,
              private loggingService: LoggingService,
              private router: Router) { }

  /**
   * Takes feedback and then navigates the user to the document this feedback was created on
   */
  viewFeedbackInDocument(feedback: TfidfFeedbackDocument) {
    this.knowledgeFeedbackService.showFeedbackPanel();

    const params = {
      id: feedback.docId,
      group: feedback.knowledgeGroupPublicKey,
      version: feedback.docVersion
    };

    this.router.navigate([`/knowledge/edit`], {queryParams: params}).then(() => this.dialogRef.close());
  }

  async toggleComplete(feedback: TfidfFeedbackDocument): Promise<void> {
    this.completing = true;

    try {
      await this.knowledgeFeedbackService.updateTfidfFeedbackAsReviewed(feedback);

      if (feedback.isReviewed) {
        feedback.isReviewed = false;
        this.loggingService.logMessage('Feedback marked incomplete.', false, 'success');
      } else {
        feedback.isReviewed = true;
        this.loggingService.logMessage('Feedback marked complete.', false, 'success');
      }

      this.completing = false;
    } catch (err) {
      this.completing = false;
      this.loggingService.logMessage('Feedback failed to complete. Please try again.', false, 'error');
    }
  }

}
