import { Component, Input } from '@angular/core';
import { KnowledgeDocumentTableOfContents } from '@eva-model/knowledge/knowledge';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';

@Component({
  selector: 'app-knowledge-table-of-contents',
  templateUrl: './knowledge-table-of-contents.component.html',
  styleUrls: ['./knowledge-table-of-contents.component.scss']
})
export class KnowledgeTableOfContentsComponent {

  /**
   * Table of contents being passed in from the parent component
   */
  @Input() toc: KnowledgeDocumentTableOfContents;

  constructor(private chatKnowledgeService: ChatKnowledgeService) { }

  itemClicked(docId: string, sectionId: string, highlight = false) {
    this.chatKnowledgeService.focusSectionId(docId, sectionId, highlight);
  }

}
