import { Component, OnInit, Inject , ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  DynamicCheckboxModel,
  DynamicInputModel,
  DynamicRadioGroupModel
} from '@ng-dynamic-forms/core';

import { DynamicFormControlModel, DynamicFormService } from '@ng-dynamic-forms/core';
import { UntypedFormGroup } from '@angular/forms';

import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralDialogComponent implements OnInit, OnDestroy {

  generalDialogFormModel: DynamicFormControlModel[] = null;
  generalDialogFormGroup: UntypedFormGroup;

  private formGroupsValueChangeSubs = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private ngDynFormService: DynamicFormService,
    public generalDialogService: GeneralDialogService) {}

  ngOnInit() {
    if ( this.dialogData.dynFormCntrlMdl && this.dialogData.dynFormCntrlMdl.length ) {
      this.generalDialogFormModel = this.dialogData.dynFormCntrlMdl;
      this.generalDialogFormGroup = this.ngDynFormService.createFormGroup(this.generalDialogFormModel);

      //#region Subscribe to value change "Rx Subject" for all elements to detect the changes
      Object.keys(this.generalDialogFormGroup.controls).forEach( (key, index) => {
        const frmGrpControl: any = this.generalDialogFormGroup.get(key);

        this.formGroupsValueChangeSubs.add(
          frmGrpControl.valueChanges
          .subscribe(
            (value) => {
              const formValueCloned = JSON.parse(JSON.stringify(this.generalDialogFormGroup.value));
              formValueCloned[key] = value;
              this.generalDialogService.announceChange(formValueCloned);
            }
          )
        );
      });
      //#endregion
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.formGroupsValueChangeSubs) {
      this.formGroupsValueChangeSubs.unsubscribe();
    }
  }

}
