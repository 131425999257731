import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TutorialIntro } from '@eva-model/tutorials/tutorial';

@Component({
  selector: 'app-tutorial-intro',
  templateUrl: './tutorial-intro.component.html',
  styleUrls: ['./tutorial-intro.component.scss']
})
export class TutorialIntroComponent {
  onLaunchIntro = new EventEmitter();
  onSkipTutorial = new EventEmitter();
  incomingData: TutorialIntro;


  constructor(
    private dialogRef: MatDialogRef<TutorialIntroComponent>,
    @Inject(MAT_DIALOG_DATA) data: TutorialIntro
    ) {
      this.incomingData = data;
    }

  /**
   * open the intro dialog modal
   */
  launchIntro() {
    this.dialogRef.close();
    this.onLaunchIntro.emit();
  }

  /**
   * fires the skip tutorial event
   */
  skipTutorial() {
    this.onSkipTutorial.emit();
  }
}
