import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LaunchPadMenuItemGroup, LaunchPadLocalItem, LaunchPadMenuItem, LaunchPadEntityType, EVAMenu } from "@eva-model/menu/menu";
// import { get, set, del } from "idb-keyval";
import { LaunchPadDefaultItems, LaunchPadDefaultGroups } from "@eva-model/menu/defaults/launchPadMenu";
import { ChatKnowledgeService } from "@eva-services/chat/knowledge/chat-knowledge.service";
import { Router } from "@angular/router";
import { AnnounceKnowledgeShow } from "@eva-model/chat/knowledge/chatKnowledge";
import { ChatProcessService } from "@eva-services/chat/process/chat-process.service";
import { ChatService } from "@eva-services/chat/chat.service";
import { ChatEntityAuthor, ChatEntityType, NextChatQueryType } from "@eva-model/chat/chat";
import { getMatIconFailedToSanitizeLiteralError } from "@angular/material/icon";
import { EVASVGIcon, knowledgeSVGIcon, processSVGIcon, userSVGIcon } from "@eva-model/menu/defaults/icons";
import { tap } from "rxjs/operators";
import { LoggingService } from "@eva-core/logging.service";
import { WorkFlow } from "@eva-model/workflow";
import { MultiViewService } from "@eva-services/home/multi-view/multi-view.service";
import { UserService } from "@eva-services/user/user.service";

/**
 * Generates a random ID for a menu item
 */
const getRandomId = (): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

/**
 * Returns the correct icon for the menu item type
 */
const getIcon = (type: LaunchPadEntityType): EVASVGIcon => {
  switch (type) {
    case LaunchPadEntityType.Knowledge:
      return knowledgeSVGIcon;
    case LaunchPadEntityType.Interaction:
      return processSVGIcon;
    case LaunchPadEntityType.Workflow:
      return processSVGIcon;
    default:
      return userSVGIcon;
  }
};

/**
 * Local Database Keys
 */
const LAUNCHPAD_ITEMS_ORDER_DB_KEY = 'launchPadOrder';
const LAUNCHPAD_ITEMS_PINNED_DB_KEY = 'launchPadPinned';
const LAUNCHPAD_ITEMS_USER_DB_KEY = 'launchPadUser';

const USER_ITEMS_GROUP_ID = 'ji864Yx1Sfk67MMn0G8I';
const KNOWLEDGE_VIEW_KEY = 'bGV0IG1lIGluIHBsZWFzZSE';

@Injectable({
  providedIn: "root",
})
export class LaunchPadService {
  private menu: BehaviorSubject<LaunchPadMenuItemGroup[]> = new BehaviorSubject<LaunchPadMenuItemGroup[]>([]);
  public menu$: Observable<LaunchPadMenuItemGroup[]> = this.menu.asObservable();

  constructor(private chatKnowledgeService: ChatKnowledgeService,
              private chatProcessService: ChatProcessService,
              private chatService: ChatService,
              private loggingService: LoggingService,
              private router: Router,
              private multiViewService: MultiViewService,
              private userService: UserService) { }

  /**
   * Populated from storage if they exist
   */
  private pinnedItems: LaunchPadLocalItem[] = [];
  private reorderedItems: LaunchPadLocalItem[] = [];
  private userItems: LaunchPadMenuItem[] = []; // users launch pads

  /**
   * Returns a menu
   */
  public async generateMenu(skipDbCheck = false): Promise<void> {
    // load data in memory
    if (!skipDbCheck) {
      await this.loadDataFromDatabase();
    }
    // transform menu items into groups of menu items
    const generatedMenu = this.sortMenuItemsIntoGroups();
    // emit generated menu
    this.menu.next(generatedMenu);

    return;
  }

  /**
   * Gets any existing data in Database and sets it in memory.
   */
  private async loadDataFromDatabase(): Promise<boolean> {
    const launchPadItems = await this.userService.getUserLaunchPadItems();

    if (launchPadItems?.pinnedItems) {
      this.pinnedItems = JSON.parse(JSON.stringify(launchPadItems.pinnedItems));
    }
    if (launchPadItems?.reorderedItems) {
      this.reorderedItems = JSON.parse(JSON.stringify(launchPadItems.reorderedItems));
    }
    if (launchPadItems?.userItems) {
      this.userItems = JSON.parse(JSON.stringify(launchPadItems.userItems));
      this.userItems.forEach(item => item.icon = getIcon(item.type));
    }
    // // get both data, if any exist
    // this.pinnedItems = await get<LaunchPadLocalItem[]>(
    //   LAUNCHPAD_ITEMS_PINNED_DB_KEY
    // );
    // this.reorderedItems = await get<LaunchPadLocalItem[]>(
    //   LAUNCHPAD_ITEMS_ORDER_DB_KEY
    // );
    // this.userItems = await get<LaunchPadMenuItem[]>(
    //   LAUNCHPAD_ITEMS_USER_DB_KEY
    // );

    return true;
  }

  /**
   * Uses arrays of menuItems and returns each group with menu items added to them
   * Important to note at this point, the items are in the groups, but the items
   * are unsorted if the user has any custom ordering.
   */
  private sortMenuItemsIntoGroups(): any {
    const groupIndexMap = new Map<string, number>();
    const pinnedItemsSet = this.pinnedItems
      ? new Set<string>(this.pinnedItems.map((i) => i.id))
      : new Set<string>();

    // create new Arrays of the default menu items and groups.
    const defaultItems = LaunchPadDefaultItems.map(i => Object.assign({}, i));
    const defaultGroups = LaunchPadDefaultGroups.map(i => {
      const newObj = Object.assign({}, i);
      newObj.items = [];
      return newObj;
    });
    const pinnedGroup = LaunchPadDefaultGroups.find((group) => group.id === 'pinned');

    // create a Map of default group indexes to quickly know where to put menu items
    for (let i = 0; i < defaultGroups.length; ++i) {
      const group = defaultGroups[i];
      groupIndexMap.set(group.id, i);
    }

    // iterate over menu items and put them in their default groups
    for (const menuItem of defaultItems) {
      // check if the menu item has been pinned and reflect that
      if (pinnedItemsSet.has(menuItem.id)) {
        menuItem.pinned = true;
        // add this menu item to it's own group items
        pinnedGroup.items.push(menuItem);
      }
      // get group index the menu item belongs to for push
      const groupIndex: number = groupIndexMap.get(menuItem.groupId);
      defaultGroups[groupIndex].items.push(menuItem);
    }

    // if user items exist, put them into the user group.
    if (this.userItems && this.userItems.length > 0) {
      const userItemsGroup = defaultGroups.find((group) => {
        return group.id === USER_ITEMS_GROUP_ID;
      });
      userItemsGroup.items = [...this.userItems];

      // check user items for pinned items
      for (const menuItem of this.userItems) {
        // check if the menu item has been pinned and reflect that
        if (pinnedItemsSet.has(menuItem.id)) {
          menuItem.pinned = true;
          // add this menu item to it's own group items
          pinnedGroup.items.push(menuItem);
        } else {
          menuItem.pinned = false;
        }
      }
    }

    // continue the menu generation by sorting any ordering in each group
    return this.reorderMenuItemsInGroups(defaultGroups);
  }

  /**
   * Recursively sorts all groups menu items to fit the custom ordering that
   * the user has done.
   */
  private reorderMenuItemsInGroups(menuGroups: LaunchPadMenuItemGroup[]): any {
    // exit early if no reordering needs to occur
    if (!this.reorderedItems) {
      return menuGroups;
    }

    // Map <groupId, Map<itemId, index>>
    const orderMap = new Map<string, Map<string, number>>();

    // create a Mapping for quick reordering.
    for (const item of this.reorderedItems) {
      if (!orderMap.has(item.groupId)) {
        // this groupId does not exist yet, create it.
        orderMap.set(item.groupId, new Map().set(item.id, item.order));
        continue;
      }

      orderMap.get(item.groupId).set(item.id, item.order);
    }

    // loop over groups and make sorting adjustments
    for (const group of menuGroups) {
      // if group does not exist in orderMap, skip this iteration
      if (!orderMap.has(group.id)) {
        continue;
      }

      const menuItemsToMove = orderMap.get(group.id);

      // loop the group menuItems and check if reordering is required for each item
      menuItemsToMove.forEach((newIndex, id) => {
        const currentIndex = group.items.findIndex(item => item.id === id);
        if (currentIndex !== -1) {
          // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
          if (currentIndex < group.items.length && newIndex < group.items.length && group.items[currentIndex] && group.items[newIndex]) {
            [group.items[currentIndex], group.items[newIndex]] = [group.items[newIndex], group.items[currentIndex]];
          }
        }
      });
    }

    return menuGroups;
  }

  public reorderGroupItem(id: string, groupId: string, newIndex: number): void {
    // if no reordered items, create a new array with our item.
    const reorder = { id, groupId, order: newIndex };
    if (!this.reorderedItems) {
      this.reorderedItems = [reorder];
      this.syncDatabase("REORDER");
      return;
    }

    const existing = this.reorderedItems.find((item) => item.id === id);
    let oldIndex = -1;
    if (existing) {
      oldIndex = existing.order;
      existing.order = newIndex;
    } else {
      this.reorderedItems.push(reorder);
    }

    // update reordered list orders to maintain position correctly
    if (oldIndex !== -1) {
      const collidingItemIndex = this.reorderedItems.findIndex(item =>
        item.id !== id && item.groupId === groupId && item.order === newIndex);
      if (collidingItemIndex !== -1) {
        if (oldIndex > newIndex) {
          for (let index = 0; index < this.reorderedItems.length; index++) {
            if (this.reorderedItems[index].order >= newIndex && this.reorderedItems[index].order <= oldIndex
              && this.reorderedItems[index].id !== id) {
              this.reorderedItems[index].order++;
            }
          }
        } else if (oldIndex < newIndex) {
          for (let index = 0; index < this.reorderedItems.length; index++) {
            if (this.reorderedItems[index].order >= oldIndex && this.reorderedItems[index].order <= newIndex
              && this.reorderedItems[index].id !== id) {
              this.reorderedItems[index].order--;
            }
          }
        }
      }
    }

    // Save change in Database/notify of changes
    this.syncDatabase("REORDER");
  }

  public unpinItem(id: string): void {
    // item.pinned = false;
    const indexToRemove = this.pinnedItems.findIndex(
      (pinnedItem) => pinnedItem.id === id
    );
    this.pinnedItems.splice(indexToRemove, 1);

    // Save change in Database/notify of changes
    this.syncDatabase("PIN");
  }

  public pinItem(id: string): void {
    if (!this.pinnedItems || this.pinnedItems.length === 0) {
      this.pinnedItems = [{ id, order: 0 }];
      this.syncDatabase("PIN");
      return;
    }

    // pin to end of array
    this.pinnedItems.push({ id, order: this.pinnedItems.length });

    // Save change in Database/notify of changes
    this.syncDatabase("PIN");
  }

  /**
   * Pin a custom, existing entity like a process or knowledge document
   */
  public addCustomItem(type: LaunchPadEntityType, displayName: string, data: any): void {
    // create the custom menu item
    const customItem = this.generateCustomItem(type, displayName, data);

    // add menu item to the menu array on the view
    if (!this.userItems || this.userItems.length === 0) {
      // TODO: add some custom logic here like a tutorial, "Hey you just added your first launchpad item!"
      this.userItems = [customItem];
      this.loggingService.logMessage('Added to your Launchpad', false, 'success');
    } else {
      let itemExists = false;
      // check if this item is already in launchpad
      if (type === LaunchPadEntityType.Knowledge) {
        itemExists = this.checkIfUserItemInLaunchpad(type, data.docId);
      }
      if (type === LaunchPadEntityType.Workflow) {
        itemExists = this.userItems.find(item =>
          item.data && this.getDataAsObject<{workflow: WorkFlow}>(item.data)?.workflow?.id === data?.workflow?.id) ? true : false;
      }

      if (!itemExists) {
        this.userItems.push(customItem);
        this.loggingService.logMessage('Added to your Launchpad', false, 'success');
      } else {
        this.loggingService.logMessage('Already added to your Launchpad', false, 'error');
      }
    }

    // sync the change to local storage
    this.syncDatabase('CUSTOM');
  }

  /**
   * Removes a custom item from the users list
   */
  public removeCustomItem(item: LaunchPadMenuItem, index: number): void {
    const itemIndex = this.userItems.findIndex(userItem => userItem.id === item.id);
    if (itemIndex !== -1) {
      this.userItems.splice(itemIndex, 1);
    } else {
      this.userItems.splice(index, 1);
    }
    const deletedOrder = this.reorderedItems.find(reorderedItem => reorderedItem.id === item.id)?.order;
    if (deletedOrder || deletedOrder === 0) {
      for (let deletedIndex = 0; deletedIndex < this.reorderedItems.length; deletedIndex++) {
        if (this.reorderedItems[deletedIndex].order > deletedOrder) {
          this.reorderedItems[deletedIndex].order--;
        }
      }
    }

    this.syncDatabase('CUSTOM');
  }

  private generateCustomItem(type: LaunchPadEntityType, title: string, data: any): LaunchPadMenuItem {
    return {
      type,
      title,
      id: getRandomId(),
      groupId: USER_ITEMS_GROUP_ID,
      data: JSON.stringify(data),
      icon: getIcon(type)
    };
  }

  /**
   * Does the action relating to the type of a launch pad item. Depending on type, a
   * different action will occur when the item is clicked/actioned.
   */
  public doAction(item: LaunchPadMenuItem) {
    switch (item.type) {
      case LaunchPadEntityType.Route:
        const routeData = this.getDataAsObject<EVAMenu>(item.data);
        this.router.navigateByUrl(routeData.routerLink);
        break;
      case LaunchPadEntityType.Knowledge:
        const knowledgeData = this.getDataAsObject<AnnounceKnowledgeShow>(item.data);
        this.chatKnowledgeService.announceKnowledgeShow(knowledgeData);
        break;
      case LaunchPadEntityType.Workflow:
        const workflowData = this.getDataAsObject<{workflow: WorkFlow}>(item.data);
        this.chatProcessService.announceProcessFetch(workflowData.workflow);
    }
  }

  private async syncDatabase(action: "PIN" | "REORDER" | "CUSTOM") {
    try {
      let launchPadItems = await this.userService.getUserLaunchPadItems();
      if (!launchPadItems) {
        launchPadItems = {};
      }
      launchPadItems = JSON.parse(JSON.stringify(launchPadItems));
      switch (action) {
        case "PIN":
          // Regenerate menu since we don't live update the object for pins
          await this.generateMenu(true);

          if (this.pinnedItems && this.pinnedItems.length > 0) {
            // await set(LAUNCHPAD_ITEMS_PINNED_DB_KEY, this.pinnedItems);
            launchPadItems.pinnedItems = this.pinnedItems;
          }

          if (
            !this.pinnedItems ||
            (this.pinnedItems && this.pinnedItems.length === 0)
          ) {
            // const pinnedItemsFromDb = await get(LAUNCHPAD_ITEMS_PINNED_DB_KEY);
            const pinnedItemsFromDb = launchPadItems.pinnedItems ?? [];
            if (!!pinnedItemsFromDb) {
              // await del(LAUNCHPAD_ITEMS_PINNED_DB_KEY);
              launchPadItems.pinnedItems = [];
            }
          }
          break;
        case "REORDER":
          // generate a new menu
          await this.generateMenu(true);

          // set reordered items in db if it exists in memory
          if (this.reorderedItems && this.reorderedItems.length > 0) {
            // await set(LAUNCHPAD_ITEMS_ORDER_DB_KEY, this.reorderedItems);
            launchPadItems.reorderedItems = this.reorderedItems;
          }

          // check if data needs deleting from Database because data is not set or empty
          if (
            !this.reorderedItems ||
            (this.reorderedItems && this.reorderedItems.length === 0)
          ) {
            // const reorderedItemsFromDb = await get(LAUNCHPAD_ITEMS_ORDER_DB_KEY);
            const reorderedItemsFromDb = launchPadItems.reorderedItems ?? [];
            if (!!reorderedItemsFromDb) {
              // await del(LAUNCHPAD_ITEMS_ORDER_DB_KEY);
              launchPadItems.reorderedItems = [];
            }
          }
          break;
        case "CUSTOM":
          await this.generateMenu(true);
          // await set(LAUNCHPAD_ITEMS_USER_DB_KEY, this.userItems);
          launchPadItems.userItems = JSON.parse(JSON.stringify(this.userItems));
      }
      // get previous launch pad items from the service before changes
      const currentLaunchPadItems = await this.userService.getUserLaunchPadItems();
      // remove any items from the reordered list containing removed items from the launchpad
      const removedItems = currentLaunchPadItems?.userItems?.filter(item => !this.userItems.some(userItem => userItem.id === item.id));
      if (removedItems && removedItems.length > 0) {
        removedItems.forEach(item => {
          const itemToDeleteIndex = this.reorderedItems.findIndex(reorderedItem => reorderedItem.id === item.id);
          if (itemToDeleteIndex !== -1) {
            this.reorderedItems.splice(itemToDeleteIndex, 1);
          }
        });
        launchPadItems.reorderedItems = this.reorderedItems;
      }
      // remove icons from user items to prevent storing them in the database
      launchPadItems.userItems.forEach(item => delete item.icon);
      // update reordered list orders to maintain position correctly
      this.userItems.forEach((userItem, index) => {
        const itemIndex = this.reorderedItems.findIndex(item => item.id === userItem.id);
        if (itemIndex === -1) {
          this.reorderedItems.push({ id: userItem.id, groupId: userItem.groupId, order: index });
        }
      });
      launchPadItems.reorderedItems = this.reorderedItems;
      // save launchpad changes to database
      await this.userService.updateUserLaunchPadItems(launchPadItems);
      // add the icons back for the local instance for UI
      this.userItems.forEach(item => item.icon = getIcon(item.type));
    } catch (err) {
      console.error("Failed to sync Database!", err);
    }
  }

  /**
   * Parses a launch pad data prop for use from storage
   * @param data
   */
  private getDataAsObject<T>(data: string): T {
    return JSON.parse(data);
  }

  /**
   * Checks the users items in the launch pad for the existence of this new item.
   */
  private checkIfUserItemInLaunchpad(type: LaunchPadEntityType, id: string): boolean {
    if (!this.userItems || this.userItems.length === 0) {
      return false;
    }

    const filtered = this.userItems.filter((item) => item.type === type);
    const found = filtered.find((item) => {
      const data = this.getDataAsObject(item.data);
      if (type === LaunchPadEntityType.Knowledge && (data as AnnounceKnowledgeShow).docId === id) {
        return item;
      }
    });

    // return result as a boolean
    return !!found;
  }

}
