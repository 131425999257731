import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';

@Injectable()
export class GoogleDriveService {

  private baseUrl = environment.endPoints.GOOGLE_DRIVE.url;
  constructor(private http: HttpClient, private perf: AngularFirePerformance) {
  }

  getAllDriveFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}files`).pipe(
      trace('google-drive-getAllDriveFiles')
    );
  }
}
