import { Pipe, PipeTransform } from '@angular/core';
import { GenericSearchFilter } from '@eva-model/generic-search';

@Pipe({
  name: 'isValidFilterValue'
})
export class IsValidFilterValuePipe implements PipeTransform {
  transform(index: number, filter: GenericSearchFilter): boolean {
    return filter.defaultValue[index] !== null && typeof filter.defaultValue[index] !== 'undefined';
  }
}