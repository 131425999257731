import { Pipe, PipeTransform } from '@angular/core';
import { EvaGlobalService } from '@eva-core/eva-global.service';

@Pipe({
  name: 'groupName'
})
export class GroupNamePipe implements PipeTransform {

  constructor(private evaGlobal: EvaGlobalService) {}

  transform(groupPk: string): any {
    return this.evaGlobal.getGroupNameByPublicKey(groupPk);
  }

}
