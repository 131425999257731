import { Guid } from '@eva-core/GUID/guid';

export interface SubCntrl {
    id: string;
    interactionScrnIndex: number;
}

export class SpecialSubCntrl implements SubCntrl {
    constructor(
        public id: string,
        public interactionScrnIndex: number
    ) {}
}

export interface InteractionControlBind {
    id: string;
    type: string;
    name: string;
    subType: string;
    customType: string;
    controls: SubCntrl[];
}

export class InteractionSpecialControlBind implements InteractionControlBind {
  public id: string;
  public type: string;

  constructor(
    public name: string,
    public subType: string,
    public customType: string,
    public controls: SubCntrl[]
  ) {
      this.id = Guid.newGuid().toString();
      this.type = 'SPECIAL';
    }
}
