'use strict';
/**
 * This interface is the shape of an ID Mapping for Interaction, Workflow, Dynamic Data, or Element
 */
export interface IdMapping {
    [key: string]: string;
}

/**
 * Used to return an item from the id mapping endpoints.
 */
export interface IDMappingResponse { 
    success: boolean; // whether the response was successful
    idMapping?: IdMapping; // the ID mapping that was returned. 
}

/**
 * This is the request type to send to EVA Cloud.
 */
export enum IdMappingType {
    INTERACTION = 'interaction', // the interaction Type
    WORKFLOW = 'workflow', // the workflow type
    ELEMENT = 'element', // the element mapping type
    DYNAMIC_DATA = 'dynamic-data' // the dynamic data type
}
