import { Component, Inject, ViewChild, ComponentFactoryResolver, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ViewContainerDirective } from '@eva-ui/view-container/view-container.directive';
import { Subscription } from 'rxjs';
import { InteractionConditionDialogModel } from '@eva-model/interactionConditionDialogModel';
import { InteractionConditionBuilderComponent } from '@eva-ui/interaction-condition-builder/interaction-condition-builder.component';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionBuilderService } from '@eva-services/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
import { WorkFlowInteractionConditionSet } from '@eva-model/workflowInteractionConditionSet';
import { IfThenLogicOptions } from '@eva-model/interactionElementRelationDialogModel';

@Component({
  selector: 'app-interaction-condition-dialog',
  templateUrl: './interaction-condition-dialog.component.html',
  styleUrls: ['./interaction-condition-dialog.component.scss']
})
export class InteractionConditionDialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild(ViewContainerDirective) viewContainerHost: ViewContainerDirective;
  interactionConditionSetChangeSubscription: Subscription;
  isConfirmButtonDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<InteractionConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: InteractionConditionDialogModel,
    private componentFactoryResolver: ComponentFactoryResolver,
    private interactionConditionBuilderService: WorkflowInteractionConditionBuilderService
  ) { }

  ngAfterViewInit() {
    const that = this;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InteractionConditionBuilderComponent);

    const viewContainerRef = this.viewContainerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<InteractionConditionBuilderComponent>componentRef.instance).interactionObj = JSON.stringify(this.dialogData.interaction);
    (<InteractionConditionBuilderComponent>componentRef.instance).condition = this.dialogData.condition;

    this.interactionConditionSetChangeSubscription =
      this.interactionConditionBuilderService.conditionSetChanged$
        .subscribe(
          (data: WorkFlowInteractionConditionSet[]) => {
            if (!this.isRelationNotValid(data)) {
              this.interactionConditionBuilderService.announceConditionSetDialogChange(data);
            }
          },
          (err) => { console.log(err); }
        );
  }

  isRelationNotValid(data: WorkFlowInteractionConditionSet[]) {
    let flag = false;
    if (data && data.length > 0) {
      data.forEach((conditionSet, conditionSetIndex) => {
        if (data.length > 1 && conditionSetIndex !== data.length - 1) {
          if (!conditionSet.connectionOperator || conditionSet.connectionOperator?.length === 0) {
            flag = true;
          }
        }
        conditionSet.conditions.forEach((condition, conditionIndex) => {
          if (conditionSet.conditions.length > 1 && conditionIndex !== conditionSet.conditions.length - 1) {
            if (!condition.connectionOperator || condition.connectionOperator?.length === 0) {
              flag = true;
            }
          }
          if (!condition.operator || condition.operator?.length === 0) {
            flag = true;
          }
          if (condition.operator) {
            switch (condition.operator) {
              case IfThenLogicOptions.IsEmpty:
              case IfThenLogicOptions.IsNotEmpty:
              case IfThenLogicOptions.DateIsToday:
              case IfThenLogicOptions.DateIsAfterToday:
              case IfThenLogicOptions.DateIsBeforeToday:
              case IfThenLogicOptions.DateIsEqualToOrAfterToday:
              case IfThenLogicOptions.DateIsEqualToOrBeforeToday:
              case IfThenLogicOptions.DateIsNow:
              case IfThenLogicOptions.DateIsAfterNow:
              case IfThenLogicOptions.DateIsBeforeNow:
              case IfThenLogicOptions.DateIsEqualToOrAfterNow:
              case IfThenLogicOptions.DateIsEqualToOrBeforeNow:
              case IfThenLogicOptions.IsCurrentMonth:
              case IfThenLogicOptions.MonthIsBeforeCurrentMonth:
              case IfThenLogicOptions.MonthIsAfterCurrentMonth:
              case IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth:
              case IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth:
              case IfThenLogicOptions.IsCurrentWeek:
              case IfThenLogicOptions.WeekIsBeforeCurrentWeek:
              case IfThenLogicOptions.WeekIsAfterCurrentWeek:
              case IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek:
              case IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek:
              case IfThenLogicOptions.IsCurrentTime:
              case IfThenLogicOptions.TimeIsBeforeCurrentTime:
              case IfThenLogicOptions.TimeIsAfterCurrentTime:
              case IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime:
              case IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime:
              case IfThenLogicOptions.MathEquation:
                break;
              case IfThenLogicOptions.IsBetween:
              case IfThenLogicOptions.IsNotBetween:
                if (typeof condition.value === 'undefined' || condition.value === null
                  || !condition.secondValue || typeof condition.secondValue === 'undefined'
                  || condition.secondValue === null || condition.secondValue === '') {
                    flag = true;
                }
                if (condition.value && condition.secondValue
                  && condition.secondValue < condition.value) {
                    flag = true;
                }
                break;
              default:
                if (typeof condition.value === 'undefined' || condition.value === null
                  || (Array.isArray(condition.value)
                    ? condition.value.length === 0
                    : ('' + condition.value).length === 0)) {
                  flag = true;
                }
                break;
            }
          } else {
            flag = true;
          }
        });
      });
    } else {
      flag = false;
    }
    this.isConfirmButtonDisabled = flag;
    return flag;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.interactionConditionSetChangeSubscription) {
      this.interactionConditionSetChangeSubscription.unsubscribe();
    }
  }

}

