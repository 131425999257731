import { Injectable } from '@angular/core';
import { Component, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}

@Injectable()
export class SpeechRecognitionService {
  speechRecognition: any;
  constructor(private zone: NgZone) {

  }

  record(): Observable<string> {
    return new Observable(observer => {
      const { webkitSpeechRecognition }: IWindow = <IWindow><unknown>window;
      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.continuous = false;
      // this.speechRecognition.interimResults = true;
      this.speechRecognition.lang = 'en-us';
      this.speechRecognition.maxAlternatives = 1;
      this.speechRecognition.onresult = speech => {
        let term = '';
        if (speech.results) {
          const result = speech.results[speech.resultIndex];
          const transcript = result[0].transcript;
          if (result.isFinal) {
            if (result[0].confidence < 0.3) {
              // console.log('We aren\'t confident with what we heard, please try again');
              // console.log('Capture --> ' + transcript);
            } else {
              term = _.trim(transcript);
              // console.log('Recorded -> ' + term);
            }
          }
        } // end speech.results
        this.zone.run(() => {
          observer.next(term);
        });
      }; // speechRecognition.onresult
      this.speechRecognition.onerror = error => {
        observer.error(error);
      };
      this.speechRecognition.onend = () => {
        observer.complete();
      };

      this.speechRecognition.start();
      // console.log('Recording');
    }); // end Observable.create
  }

  recordTranscription(): Observable<string> {
    return new Observable(observer => {
      const { webkitSpeechRecognition }: IWindow = <IWindow><unknown>window;
      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      this.speechRecognition.lang = 'en-us';
      this.speechRecognition.maxAlternatives = 2;
      this.speechRecognition.onresult = speech => {
        let term = '';
        if (speech.results) {
          const result = speech.results[speech.resultIndex];
          const transcript = result[0].transcript;
          if (result.isFinal) {
            if (result[0].confidence < 0.3) {
              // console.log('We aren\'t confident with what we heard, please say that again');
            } else {
              term = _.trim(transcript);
              // console.log('Recorded -> ' + term);
            }
          }
        } // end speech.results
        this.zone.run(() => {
          observer.next(term);
        });
      }; // speechRecognition.onresult
      this.speechRecognition.onerror = error => {
        observer.error(error);
      };
      this.speechRecognition.onend = () => {
        observer.complete();
      };

      this.speechRecognition.start();
      // console.log('Recording');
    }); // end Observable.create

  }
  DestroySpeechObject() {
    if (this.speechRecognition) {
      this.speechRecognition.stop();
    }
  }
}
