import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FirestoreService } from '@eva-services/firestore/firestore.service';

@Component({
  selector: 'app-group-info-display',
  templateUrl: './group-info-display.component.html',
  styleUrls: ['./group-info-display.component.scss']
})
export class GroupInfoDisplayComponent implements OnInit {

  private _groupPublicKey: string;

  groupData$: Observable<any>;
  group: any;

  @Input()
  set groupPublicKey(groupPublicKey: string) {
    this._groupPublicKey = groupPublicKey;
  }

  constructor(private _route: ActivatedRoute,
    private _firestoreService: FirestoreService) { }

  ngOnInit() {
    this.findByPublicKey();
  }

  findByPublicKey() {
    const groupDocRef = this._firestoreService.col('GroupSigningKeys').doc(this._groupPublicKey);
    this.groupData$ = groupDocRef.snapshotChanges();
    this.groupData$.subscribe(snapshot => {
      this.group = snapshot.payload.data();
    });
  }

  getGroupTypeText(groupType) {
    switch (groupType) {
      case environment.blockConfig.types.groups.types.normal:
        return 'normal';
      case environment.blockConfig.types.groups.types.invitation:
        return 'invitation';
      case environment.blockConfig.types.groups.types.flowThrough:
        return 'flow through';
      case environment.blockConfig.types.groups.types.dynamicData:
        return 'dynamic data';
      default:
        return 'unknown group type';
    }
  }
}
