import { Directive, Input, HostListener, Renderer2, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ChatService } from '@eva-services/chat/chat.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appStickyElement]'
})
export class StickyElementDirective implements AfterViewInit, OnDestroy {

  constructor(private ele: ElementRef,
              private renderer: Renderer2,
              private chatService: ChatService) {}

  private scrollableParentHeight: number;
  private parentWidth: number;
  private topOffset: number;

  private scrollableParent: any;

  // any style properties added to the element are added here to quickly remove them once unstickied.
  private stylePropertyList: string[] = [];

  private chatMinimizedSub: Subscription;
  private resizeObservable$: Observable<Event>
  private resizeSubscription$: Subscription

  /**
   * initialization happens here since the directive has been found after elements rendered on page.
   */
  ngAfterViewInit() {
    this.resizeObservable$ = fromEvent(window, 'resize').pipe(debounceTime(300));
    this.resizeSubscription$ = this.resizeObservable$.subscribe((event: any) => {
      this.parentWidth = this.ele.nativeElement.parentElement.offsetWidth;
      if (this.parentWidth !== 0) {
        this.setStylePropsOnHostElement('width', `${this.parentWidth}px`);
      }
    })

    // get the scroll parent
    // keep bubbling until found
    this.scrollableParent = this.getScrollElement();

    if (!this.scrollableParent) {
      return;
    }

    // set viewport height
    this.scrollableParentHeight = this.scrollableParent.offsetHeight;
    this.parentWidth = this.ele.nativeElement.parentElement.offsetWidth;
    this.topOffset = this.scrollableParent.getBoundingClientRect().top;

    this.setStylePropsOnHostElement('top', `${this.topOffset}px`);
    this.setStylePropsOnHostElement('height', `${this.scrollableParentHeight}px`);
    this.setStylePropsOnHostElement('overflow-y', 'auto');
    this.setStylePropsOnHostElement('position', 'fixed');
    this.setStylePropsOnHostElement('padding-bottom', '8em'); // fix chat close button hovering over side panel buttons
    setTimeout(() => {
      if (this.parentWidth !== 0) {
        this.setStylePropsOnHostElement('width', `${this.parentWidth}px`);
      }
    }, 50);

    // subscribe to the chat opening and closing
    this.chatMinimizedSub = this.chatService.isChatMinimized$.subscribe((state) => {
      if (state === true) {
        setTimeout(() => {
          const width = this.ele.nativeElement.parentElement.offsetWidth;
          if (width !== 0) {
            this.setStylePropsOnHostElement('width', `${width}px`);
          }
        }, 1000);
      } else {
        const width = this.ele.nativeElement.parentElement.offsetWidth;
        if (width !== 0) {
          this.setStylePropsOnHostElement('width', `${width}px`);
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.chatMinimizedSub) {
      this.chatMinimizedSub.unsubscribe();
    }

    this.resizeSubscription$.unsubscribe();
  }

  private getScrollElement(): any | undefined {
    return document.getElementsByClassName('mat-tab-body-wrapper')[0];
  }

  /**
   * To set a style on an element, use this function. Also tracks what styles were added for removal
   */
  private setStylePropsOnHostElement(propertyName: string, propertyValue: any): void {
    this.renderer.setStyle(this.ele.nativeElement, propertyName, propertyValue);
    this.stylePropertyList.push(propertyName);
  }

  /**
   * Removes all styles from an element that were added
   */
  private removeStylePropsFromHostElement(): void {
    // ensure element is scrolled to top if was scrolled when overflow prop was added.
    this.ele.nativeElement.scrollTop = 0;

    // remove properties
    this.stylePropertyList.forEach(prop => this.renderer.removeStyle(this.ele.nativeElement, prop));
  }
}
