import { Component, Input } from '@angular/core';
import { Node } from '@eva-model/node';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[node]',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent {
  @Input() node: Node;

  fillColor(type: string, defaultColor: string) {
    let returnColor = defaultColor;
    switch (type) {
      case 'interaction':
        returnColor = 'rgb(155,237,170)';
        break;
      case 'condition':
        returnColor = 'rgb(255,137,70)';
        break;
      case 'parent':
        returnColor = 'rgb(155,237,170)';
        break;
      case 'child':
        returnColor = 'rgb(155,237,170)';
        break;
    }

    return returnColor;
  }
}
