import GRAPH_CONFIG from '../graph.config';
import * as d3 from 'd3';

export class Node implements d3.SimulationNodeDatum {
    index?: number;
    x?: number;
    y?: number;  vx?: number;
    vy?: number;
    fx?: number | null;
    fy?: number | null;

    id: string;
    name: string;
    type: string;
    linkCount = 0;

    constructor(id, name, type) {
      this.id = id;
      this.name = name;
      this.type = type;
    }

    normal = () => {
      return Math.sqrt(this.linkCount / GRAPH_CONFIG.N);
    }

    get r() {
      return 50 * this.normal() + 14;
    }

    get fontSize() {
      return (30 * this.normal() + 6) + 'px';
    }

    get color() {
      const index = Math.floor(GRAPH_CONFIG.SPECTRUM.length * this.normal());
      return GRAPH_CONFIG.SPECTRUM[index];
    }
  }
