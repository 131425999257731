<div class="polaroid workflow-item">
  <div>
    <i [class]="this.interactionWorkflow.activeVersion ? 'fas fa-exchange-alt'  : 'far fa-file'"></i> &nbsp; <span
      class="font-weight-bold">{{ this.interactionWorkflow.interactionName }}</span>
    <br />
    <span class="verColor">{{ this.interactionWorkflow.interactionVersion | date: 'MMM d, y @ h:mm:ss a z' }}</span>
  </div>
  <div class="app-loading" *ngIf="isWaiting">
    <mat-spinner [diameter]=20></mat-spinner>
    <ng-content></ng-content>
    <span class="verColor">
      <h6>{{ onWaitMessage }}</h6>
    </span>
  </div>
  <div>
    <button class="customPadding controlsBtn" mat-button (click)="onSettingInteractionCondition()"
      matTooltip="Setting the interaction condition(s)" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon class="controlsIcon">settings</mat-icon>
    </button>
    <button class="customPadding controlsBtn" mat-button (click)="onShowInteraction()"
      matTooltip="Show the Interaction" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon class="controlsIcon">remove_red_eye</mat-icon>
    </button>
    <button class="customPadding controlsBtn" mat-button (click)="onMoveDownInteraction()"
      *ngIf="interactionIndex < (interactionCnt - 1)" matTooltip="Moves the Interaction down"
      [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon class="controlsIcon">arrow_downward</mat-icon>
    </button>
    <button class="customPadding controlsBtn" mat-button (click)="onMoveUpInteraction()"
      *ngIf="interactionIndex > 0" matTooltip="Moves the Interaction up" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon class="controlsIcon">arrow_upward</mat-icon>
    </button>
    <button class="customPadding controlsBtn" mat-button (click)="onRemoveInteraction()"
      matTooltip="Drops the Interaction" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon class="controlsIcon">delete_forever</mat-icon>
    </button>
    <!-- <button (click)="onSettingInteractionCondition()"
        matTooltip="Setting the interaction condition(s)" [matTooltipShowDelay]="tooltipShowDelay">
        <i class="fas fa-cog"></i>
      </button>
      <button (click)="onShowInteraction()"
        matTooltip="Show the Interaction" [matTooltipShowDelay]="tooltipShowDelay">
        <i class="fas fa-eye"></i>
      </button>
      <button (click)="onMoveDownInteraction()"
        *ngIf="interactionIndex < (interactionCnt - 1)"
        matTooltip="Moves the Interaction down" [matTooltipShowDelay]="tooltipShowDelay">
        <i class="fas fa-arrow-down"></i>
      </button>
      <button (click)="onMoveUpInteraction()"
        *ngIf="interactionIndex > 0"
        matTooltip="Moves the Interaction up" [matTooltipShowDelay]="tooltipShowDelay">
        <i class="fas fa-arrow-up"></i>
      </button>
      <button (click)="onRemoveInteraction()"
        matTooltip="Drops the Interaction" [matTooltipShowDelay]="tooltipShowDelay">
        <i class="fas fa-times-circle"></i>
      </button> -->
  </div>
</div>