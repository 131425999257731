import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { Log } from '@eva-model/log';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private _firestoreService: FirestoreService
  ) { }

  error(log: Log): Promise<any> {
    const logClctn = this._firestoreService.col('logs');

    return this._firestoreService.add(logClctn, log);
  }
}
