export class KnowledgeConstants {
  /**
   * allowed buttons on the knowledge editor.
   */
  private static TOOLBAR_BUTTONS = [
    ["paragraphFormat"],
    [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
    ],
    ["formatOL", "formatUL"],
    ["outdent", "indent"],
    ["undo", "redo"],
    [
      "insertLink",
      "linkToKnowledgeDocument",
      "linkToDocumentSection",
    ],
    [
      "insertImage",
      "insertTable"
    ],
    ["print", "findAndReplace", "fullscreen"]
  ];

  /**
   * allowed functions when uploading an image in the knowledge editor.
   */
  private static IMAGE_UPLOAD_BUTTONS = ["imageUpload"];

  /**
   * allowed buttons when interacting with images on the knowledge editor.
   */
  private static IMAGE_EDIT_BUTTONS = [
    "imageReplace",
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "-",
    "imageLink",
    "linkOpen",
    "linkRemove",
    "-",
    "imageDisplay",
    "imageAlt",
    "imageSize",
  ];

  private static LINK_INSERT_BUTTONS = ['linkBack'];
  private static LINK_EDIT_BUTTONS = ["linkOpen", "linkEdit", "linkRemove"];

  /**
   * Constants for setting the knowledge-create page to know if you are creating a new document
   * or updating an existing document. Will change some of the UI, etc.
   */
  public static KNOWLEDGE_MODE = {
    CREATE: "create",
    UPDATE: "update",
  };

  /**
   * public constants
   */
  public static EDITOR = {
    IMAGE_EDIT_BUTTONS: KnowledgeConstants.IMAGE_EDIT_BUTTONS,
    IMAGE_UPLOAD_BUTTONS: KnowledgeConstants.IMAGE_UPLOAD_BUTTONS,
    TOOLBAR_BUTTONS: KnowledgeConstants.TOOLBAR_BUTTONS,
    LINK_EDIT_BUTTONS: KnowledgeConstants.LINK_EDIT_BUTTONS,
    LINK_INSERT_BUTTONS: KnowledgeConstants.LINK_INSERT_BUTTONS
  };
}
