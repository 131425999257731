import { Pipe, PipeTransform } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Pipe({
  name: 'timestampPast'
})
export class TimestampPastPipe implements PipeTransform {
  transform(timestampToCompare: number): Observable<boolean> {
    let timestampPast = false;
    const endStreamNotifier$ = new Subject();

    const timer$ = timer(0, 2000);

    return timer$.pipe(
      // map timer emissions to boolean
      map(() => Date.now() > timestampToCompare),
      // without effecting the stream emission, check the value and do an action
      tap((isPast) => {
        // this allows one more 'next' emission to go through before completing the source (timer)
        if (isPast && timestampPast) {
          endStreamNotifier$.next(isPast);
        }
        if (isPast) {
          timestampPast = true;
        }
      }),
      takeUntil(endStreamNotifier$)
    );
  }
}
