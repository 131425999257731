import { Component, OnInit } from '@angular/core';
import { KnowledgeGlobalFindReplaceService } from '@eva-services/knowledge/knowledge-global-find-replace.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { PropertyMapping, ValueModifier } from '@eva-model/search';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { Observable } from 'rxjs';
import { KnowledgeSearchRecord } from '@eva-model/knowledge/knowledge';
import { debounceTime, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-global-find-and-replace',
  templateUrl: './knowledge-global-find-and-replace.component.html',
  styleUrls: ['./knowledge-global-find-and-replace.component.scss']
})
export class KnowledgeGlobalFindAndReplaceComponent implements OnInit {
  currentlyLoading = true;
  searchExecuted = false;

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    find: new UntypedFormControl('', Validators.required)
  });

  selectedDocs: any = {};
  docsData: Map<string, KnowledgeSearchRecord> = new Map();

  propertyNameMapping: PropertyMapping = {
    properties: [
      { propertyName: 'updatedAt', propertyUserFriendlyName: 'Last Updated', valueModifiers: [ValueModifier.DateShort] }
    ]
  };

  // generic search config
  searchConfig = { indexName: '', searchClient: {} }; // the search configuration
  searchClient: SearchClient; // the algolia search client
  algoliaIndex = 'KnowledgeSearch' + environment.algolia.indexEnding; // the Algolia index to query.
  searchToken: AlgoliaSearchToken; // the algolia search token used for this component.
  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.KNOWLEDGE_READER;

  // 
  findQuery$: Observable<string>;

  // display data
  totalHits = 0;
  totalPages = 0;
  perPage = 0;
  page = 0;
  indexQuery = '';

  currentHits: any;

  selectedHits = 0;


  constructor(public findAndReplaceService: KnowledgeGlobalFindReplaceService,
              private searchAuthService: SearchAuthService) {}

  get findQuery(): string {
    return this.searchForm.get('find').value;
  }

  ngOnInit(): void {
    this.findAndReplaceService.clearQueue();

    this.searchAuthService.getSearchToken(AlgoliaTokenType.KNOWLEDGE_READER).pipe(take(1)).subscribe(
      (token) => {
        this.searchToken = token;
        this.searchClient = algoliasearch(environment.algolia.appId, this.searchToken.securedAPIKey);
        this.searchConfig = {
          indexName: this.algoliaIndex,
          searchClient: this.searchClient
        };

        this.currentlyLoading = false;
      },
      (err) => {
        console.error(err);
        alert('There was an error.');
      });

    const find$ = this.searchForm.get('find').valueChanges;

    this.findQuery$ = find$.pipe(
      debounceTime(500),
      map((query) => {
        if (query && query.trim().length > 0) {
          return `"${query}"`;
        }
      })
    );
  }

  public doSearch(): void {
    if (!this.searchForm.valid) {
      return;
    }
    this.searchExecuted = true;
  }

  public resultSelected(hit: KnowledgeSearchRecord) {
    this.findAndReplaceService.addToQueue(hit, {
      searchTerm: this.searchForm.get('find').value as string,
      caseSensitive: false
    });
  }

  public startEditingDocuments() {
    // populate our queue
    Object.keys(this.selectedDocs).forEach((docId: string) => {
      if (this.selectedDocs[docId] === true) {
        this.findAndReplaceService.addToQueue(this.docsData.get(docId), {
          searchTerm: this.searchForm.get('find').value as string,
          caseSensitive: false
        });
      }
    });

    this.findAndReplaceService.openNextDocument(true);
  }

  resultsChanged(results: any): void {
    if (!results || !results.hits) {
      // reset view stats
      this.totalHits = 0;
      this.totalPages = 0;
      this.perPage = 0;
      this.page = 0;
      this.indexQuery = '';
      this.currentHits = null;
      return;
    }

    this.currentHits = results.hits;

    this.totalHits = results.nbHits;
    this.totalPages = results.nbPages;
    this.perPage = results.hitsPerPage;
    this.page = results.page;
    this.indexQuery = results.query;

    // populate our checked docs
    results.hits.forEach((result) => {
      if (!this.selectedDocs.hasOwnProperty(result.docId)) {
        this.selectedDocs[result.docId] = false;
        this.docsData.set(result.docId, result);
      }
    })
  }

  calcSelected(): void {
    let selected = 0;
    Object.keys(this.selectedDocs).forEach((docId) => {
      if (this.selectedDocs[docId] === true) {
        ++selected;
      }
    })
    this.selectedHits = selected;
  }
  
  selectAll(): void {
    if (!this.currentHits) {
      return;
    }

    this.currentHits.forEach((result) => {
      this.selectedDocs[result.docId] = true;
    })

    // re-calc selected results.
    this.calcSelected();
  }

}
