import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { NodeColors } from '@eva-model/workflow';

// eslint-disable-next-line max-len
import { WorkflowInteractionConditionViewerService } from '../../../providers/workflow-interaction-condition-viewer/workflow-interaction-condition-viewer.service';
import * as shape from 'd3-shape';
// eslint-disable-next-line max-len
import { WorkflowInteractionConditionBuilderService } from '@eva-services/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
import { Tree } from '@eva-ui/shared/Tree';
import { Node } from '@eva-ui/shared/Node';
import { IfThenLogicOptions } from '@eva-model/interactionElementRelationDialogModel';

@Component({
  selector: 'eva-workflow-interaction-condition-viewer',
  templateUrl: './workflow-interaction-condition-viewer.component.html',
  styleUrls: ['./workflow-interaction-condition-viewer.component.scss']
})
export class WorkflowInteractionConditionViewerComponent implements OnInit, OnDestroy {

  NodeColors = NodeColors;                                    // Enum for node colors for ngx-graph
  condition: any;                                             // Current interaction condition being viewed

  interactionConditionChangeSubs: ISubscription;              // Subscription to the interaction condition changes
  interactionConditionBuilderChangeSubs: ISubscription;       // Subscription to the interaction condition builder changes

  center$: Subject<boolean> = new Subject();                  // Observable to center the ngx-graph
  zoomToFit$: Subject<boolean> = new Subject();               // Observable to zoom to fit the ngx-graph
  curve: any = shape.curveMonotoneX;                          // Shape of the links between nodes in ngx-graph
  nodes = [];                                                 // List of all the nodes in ngx-graph
  links = [];                                                 // List of all the links in ngx-graph
  index = 1;                                                  // Number for generating unique id's for nodes
  tree: Tree = null;                                          // Tree object used for managing graph structure
  viewHeight = 150;                                           // Height of the ngx-graph
  flag = false;                                               // Toggle for height of ngx-graph

  name: String;                                               // Name of the condition
  @Input() conditionIndex: number;
  @Input()
  set workflowInteractionConditionDefinition(workflowInteractionConditionDefinition: string) {
    if (!workflowInteractionConditionDefinition) { return; }
    this.condition = JSON.parse(workflowInteractionConditionDefinition).condition;
    this.name = JSON.parse(workflowInteractionConditionDefinition).name;
  }

  constructor(private interactionConditionViewerService: WorkflowInteractionConditionViewerService,
    private interactionConditionBuilderService: WorkflowInteractionConditionBuilderService) { }

  ngOnInit() {
    const that = this;
    this.generateConditionTreeNodes();

    this.interactionConditionChangeSubs =
      this.interactionConditionViewerService.interactionConditionChanged$
        .subscribe(
          conditionChange => {
            if (conditionChange && conditionChange.conditionIndex === that.conditionIndex) {
              that.condition = conditionChange.condition;
              that.generateConditionTreeNodes();
            }
          },
          err => { console.log(err); }
        );

    this.interactionConditionBuilderChangeSubs =
      this.interactionConditionBuilderService.conditionSetChanged$
        .subscribe(
          conditionSetChange => {
            if (conditionSetChange) {
              that.generateConditionTreeNodes();
            }
          }
        );
  }

  ngOnDestroy() {
    if (this.interactionConditionChangeSubs) {
      this.interactionConditionChangeSubs.unsubscribe();
    }
  }

  /**
   * This function generates nodes and links for ngx-graph for selected condition
   */
  generateConditionTreeNodes(): void {
    this.nodes = [];
    this.links = [];
    this.links.length = 0;
    this.nodes.length = 0;

    // if no condition selected, return
    if (!this.condition) {
      return;
    }

    // root node of the tree
    const node = {
      id: this.name.split(' ').join(''),
      label: this.name,
      color: this.NodeColors.condition
    };
    this.tree = new Tree(node);

    if (Array.isArray(this.condition)) {
      // add all the condition sets for the selected condition to the graph
      this.condition.forEach((conditionSet, conditionSetIndex) => {
        const subNode = {
          id: '' + this.index,
          label: conditionSet.name + (conditionSet.connectionOperator && conditionSetIndex !== this.condition.length - 1 ? ` ${conditionSet.connectionOperator}` : ''),
          color: this.NodeColors.conditionSet
        };
        this.index++;

        this.tree.add(subNode, this.tree.root.data);

        if (Array.isArray(conditionSet.conditions)) {
          conditionSet.conditions.forEach((condition: any, index: number) => {
            const subSubNode = {
              id: '' + this.index,
              label: this.conditionElementLogic(condition, conditionSet.conditions.length, index),
              color: this.NodeColors.elementCondition
            };
            this.index++;
            // insert new node to the appropriate location in the tree
            this.tree.contains((treeNode: Node) => {
              if (treeNode.data.id === subNode.id) {
                if (treeNode.children.length === 0) {
                  this.tree.add(subSubNode, treeNode.data);
                } else {
                  (function traverseTree(trNode, that) {
                    trNode.children.forEach(child => {
                      if (child.children.length === 0) {
                        that.tree.add(subSubNode, child.data);
                      } else {
                        traverseTree(child, that);
                      }
                    });
                  })(treeNode, this);
                }
              }
            });
          });
        }

      });
    }
    // traverse the tree to generate nodes and links for the graph
    this.tree.traverseDF((treeNode: Node) => {
      this.nodes.push(treeNode.data);
      if (treeNode.parent) {
        this.links.push({
          source: treeNode.parent.data.id,
          target: treeNode.data.id
        });
      }
    });
    // zoom to fit graph after changes
    this.zoomToFitGraph();
  }

  /**
   * This function creates label for the condition based on the condition selected
   *
   * @param condition Condition being displayed
   * @param totalConditions Total number of conditions in the set
   * @param conditionIndex Index of the condition in the set
   */
  conditionElementLogic(condition: any, totalConditions: number, conditionIndex: number): string {
    let value = [];
    let secondValue;
    if (typeof(condition.value) === 'object' && !Array.isArray(condition.value) && condition.value !== null
      && typeof condition.value !== 'undefined') {
      Object.keys(condition.value).forEach(checkboxLabel => {
        if (condition.value[checkboxLabel]) {
          value.push(checkboxLabel);
        }
      });
    } else {
      value = condition.value ?? '';
    }
    if ((condition.operator === IfThenLogicOptions.IsBetween || condition.operator === IfThenLogicOptions.IsNotBetween)
      && condition.secondValue !== null && typeof condition.secondValue !== 'undefined') {
        secondValue = condition.secondValue;
    }
    if (totalConditions > 1 && conditionIndex < totalConditions - 1) {
      return `${condition.scrnIdx + 1}-${condition.elmntIdx + 1} ${condition.operator} ${value} ` + `${secondValue ? ' and ' + secondValue : ''}` +
        `${(condition.connectionOperator) ? ':: ' + condition.connectionOperator : ''}`;
    } else {
      return `${condition.scrnIdx + 1}-${condition.elmntIdx + 1} ${condition.operator} ${value} ` + `${secondValue ? ' and ' + secondValue : ''}`;
    }
  }

  /**
   * This function adds and removes the nodes from ngx-graph on collapse
   *
   * @param node Node being collapsed
   */
  updateNodesTree(node: any): void {
    if (node.collapse) {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF((n: Node) => {
            if (node.id !== n.data.id) {
              this.nodes = this.nodes.filter(graphNode => graphNode.id !== n.data.id);
              this.links = this.links.filter(link => link.source !== n.parent.data.id && link.target !== n.data.id);
            } else {
              this.nodes.forEach(graphNode => {
                if (graphNode.id === node.id) {
                  graphNode.collapse = true;
                }
              });
            }
          }, node);
        }
      });
    } else {
      this.tree.contains((treeNode: Node) => {
        if (treeNode.data.id === node.id) {
          this.tree.traverseNodeDF((n: Node) => {
            if (node.id !== n.data.id) {
              this.nodes = [...this.nodes, n.data];
              this.links = [...this.links, { source: n.parent.data.id, target: n.data.id }];
              this.nodes.forEach(graphNode => {
                if (graphNode.id === n.data.id) {
                  graphNode.collapse = false;
                }
              });
            } else {
              this.nodes.forEach(graphNode => {
                if (graphNode.id === node.id) {
                  graphNode.collapse = false;
                }
              });
            }
          }, node);
        }
      });
    }
  }

  /**
   * This functions toggles the collapse of nodes in ngx-graph
   *
   * @param node Node being collapsed
   */
  toggleTree(node: any): void {
    node.collapse = !node.collapse;
    this.updateNodesTree(node);
  }

  /**
   * This function centers ngx-graph
   */
  centerGraph(): void {
    this.center$.next(true);
  }

  /**
   * This function zoom to fit the graph and centers it
   */
  zoomToFitGraph(): void {
    this.zoomToFit$.next(true);
    this.centerGraph();
  }

  /**
   * This function toggles the height of ngx-graph
   */
  toggleViewHeight(): void {
    this.flag = !this.flag;
    this.flag ? this.viewHeight = 450 : this.viewHeight = 150;
  }
}
