import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { map, flatMap, filter } from 'rxjs/operators';
import { KnowledgeDocumentSectionFlat } from '@eva-model/knowledge/knowledge';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { TfidfFeedbackProvidedAnswer } from '@eva-model/eva-custom-nlp/tfidf/tfidf-training';
import { ChatService } from '@eva-services/chat/chat.service';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { userLastStateType } from '@eva-model/userLastState';

interface SelectedFeedbackSections {
  [docId: string]: Map<string, KnowledgeDocumentSectionFlat>;
}

@Injectable({
  providedIn: 'root'
})
export class KnowledgeDocumentFeedbackService implements OnDestroy {

  // listening when to clear the provided (viewed) sections
  private nextChatQuerySub: Subscription;
  private lastStateSub: Subscription;

  // keep track of all answers viewed for this knowledge response
  private providedAnswers: TfidfFeedbackProvidedAnswer[] = [];
  // keep track of model version. sometimes we may not have access to this in certain circumstances.
  private modelVersion = '';
  // keeping track of all selected sections on all docs
  private selectedFeedbackSections: SelectedFeedbackSections = {};
  // changed to behaviour because a doc may subscribe and sections already highlighted from before.. maybe.
  private selection$: BehaviorSubject<SelectedFeedbackSections> = new BehaviorSubject(this.selectedFeedbackSections);

  constructor(private perf: AngularFirePerformance,
              private chatService: ChatService) {
    // listen to the chat box. If a new query is typed, clear our saved answers
    this.nextChatQuerySub = this.chatService.nextChatQuery$.subscribe((q) => {
      if (this.providedAnswers.length > 0) {
        this.providedAnswers = [];
      }
    });

    // listen to last state and set model version if necessary
    // this.lastStateSub = this.lastStateService.lastState$.pipe(
    //   filter(state => !!state), // null check,
    //   filter(state => state.type === userLastStateType.TFIDF),
    //   filter(state => state.response.tfidfDocumentMatches.length > 0)
    // ).subscribe(state => {
    //   this.modelVersion = state.response.tfidfDocumentMatches[0].modelVersion;
    // });
    this.modelVersion = '100';
  }

  ngOnDestroy() {
    if (this.nextChatQuerySub) { this.nextChatQuerySub.unsubscribe(); }
    if (this.lastStateSub) { this.lastStateSub.unsubscribe(); }
  }

  /**
   * Keeps this section in memory for feeback submission
   */
  addProvidedAnswer(sectionData: TfidfFeedbackProvidedAnswer) {
    // ensure there are no duplicates if the user leaves the home page and returns
    const sectionIndex = this.providedAnswers.findIndex((s) => {
      return s.docId === sectionData.docId && s.id === sectionData.id;
    });

    if (sectionIndex < 0) {
      // section does not exist yet, add it
      this.providedAnswers.push( Object.assign({}, sectionData) );
    }
  }

  /**
   * Returns all viewed sections for the current feedback response when sending feedback. If there are no viewed answers, returns null.
   */
  getProvidedAnswers(): TfidfFeedbackProvidedAnswer[] | null {
    if (this.providedAnswers.length) {
      return this.providedAnswers;
    }
    return null;
  }

  /**
   * Returns the model version that the knowledge query came from.
   */
  getModelVersion(): string {
    return this.modelVersion;
  }

  /**
   * array of selected section ids, if there are none for this doc id, then emits an empty array.
   * @param docId
   */
  getSectionSelectionByDocId(docId: string): Observable<KnowledgeDocumentSectionFlat[]> {
    return this.selection$.pipe(
      trace('knowledge-document-feedback-getSectionSelectionByDocId'),
      map(data => {
        if (data && data[docId]) {
          return of(Array.from(data[docId].values()));
        }
        return of([]);
      }),
      flatMap(v => v)
    );
  }

  /**
   * emits a boolean and watches if the section id under this document id becomes selected.
   * @param docId knowledge document id
   * @param sectionId knowledge section id
   */
  getSectionSelectionByDocAndSectionId(docId: string, sectionId: string): Observable<boolean> {
    return this.getSectionSelectionByDocId(docId).pipe(
      trace('knowledge-document-feedback-getSectionSelectionByDocAndSectionId'),
      // map section objects into an array of section ids
      map(sections => sections.map((a) => a.id)),
      map(sectionIds => sectionIds.includes(sectionId))
    );
  }

  /**
   * toggles the section id under this document id to be either selected or unselected if selected already.
   * @param docId knowledge document id
   * @param sectionId knowledge section id
   */
  toggleSelectedSection(docId: string, sectionId: string, section: KnowledgeDocumentSectionFlat) {
    const docSet = this.selectedFeedbackSections[docId];
    if (docSet) {
      // doc already has sections
      if (docSet.has(sectionId)) {
        // delete section
        docSet.delete(sectionId);
      } else {
        docSet.set(sectionId, section);
      }
    } else {
      // doc doesn't have sections, create new Set and add section id
      this.selectedFeedbackSections[docId] = new Map<string, KnowledgeDocumentSectionFlat>().set(sectionId, section);
    }
    this.selection$.next(this.selectedFeedbackSections);
  }

  /**
   * clears ALL selected sections on a single doc.
   * @param docId knowledge document id
   */
  clearAllSelectionsByDocument(documentId: string): void {
    if (this.selectedFeedbackSections[documentId]) {
      this.selectedFeedbackSections[documentId] = null;
      this.selection$.next(this.selectedFeedbackSections);
    }
  }

  /**
   * clears ALL selected sections on all docs
   */
  clearAllSelections(): void {
    this.selectedFeedbackSections = {};
    this.selection$.next(this.selectedFeedbackSections);
  }
}
