import { NgModule } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { AuthGuard } from '@eva-core/auth-guard/auth.guard';

import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { EvaEncryptionService } from '@eva-core/encryption/eva-encryption.service';
import { SubtleEncryptionService } from '@eva-core/encryption/subtle-encryption.service';

import { SigningService } from '@eva-core/signing.service';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { KeysService } from '@eva-core/encryption/keys.service';
import { CryptoFunctionsService } from '@eva-core/cryptographic/crypto-functions.service';
import { LoggingService } from '@eva-core/logging.service';
import { EvaHeartService } from '@eva-services/chat/eva-heart.service';
import { EvaHeartTrainingService } from '@eva-services/chat/eva-heart-training.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { DeviceService } from '@eva-services/device/device.service';
import { ProcessEditSummaryComponent } from '@eva-ui/process/process-edit-summary/process-edit-summary.component';

@NgModule({
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  exports: [
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [
    ProcessEditSummaryComponent,
    AuthService,
    AuthGuard,
    DynamicInteractionsService,
    SigningService,
    EvaEncryptionService,
    GroupProviderService,
    KeysService,
    CryptoFunctionsService,
    LoggingService,
    EvaHeartService,
    EvaHeartTrainingService,
    EvaGlobalService,
    SubtleEncryptionService,
    DeviceService
  ]
})
export class CoreModule { }
