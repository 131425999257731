import { Component, OnInit, Inject } from '@angular/core';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AboutPageDocumentData } from '@eva-model/releaseNotes';

@Component({
  selector: 'app-add-edit-documents',
  templateUrl: './add-edit-documents.component.html',
  styleUrls: ['./add-edit-documents.component.scss']
})
export class AddEditDocumentsComponent implements OnInit {

  documentForm: UntypedFormGroup;
  addUpdate = false;
  urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'; // url regex pattern
  id: string; // id

  constructor(
    public dialogRef: MatDialogRef <AddEditDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _fb: UntypedFormBuilder
    ) { }

  ngOnInit() {
    // setup the document form group
    this.documentForm = this._fb.group(
      {
        'category': ['', [
          Validators.required
        ]],
        'documentName': ['', [
          Validators.required
        ]],
        'url': ['', [
          Validators.required,
          Validators.pattern(this.urlReg)
        ]]
      });
    // determine if there is incoming data, if so this is an edit. set the values in the form group.
    if (this.dialogData.extra.data) {
      const inputData = this.dialogData.extra.data;
      this.id = inputData.id;
      this.documentForm.setValue({
        category: inputData.category,
        documentName: inputData.documentName,
        url: inputData.url
      });
    }
  }
  /**
   * Closer the dialog and don't return any values.
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * When the data for an addition or edit is available and valid, create an object and return it to the
   * calling component.
   */
  addEditDocument() {
    if (this.documentForm.valid) { // filled in form to send to user outside system
      this.addUpdate = true;
      const documentData: AboutPageDocumentData = {
        category: this.documentForm.get('category').value,
        dateUploaded: new Date().getTime(),
        documentName: this.documentForm.get('documentName').value,
        url: this.documentForm.get('url').value,
        id: this.id
      };

      this.addUpdate = false;
      this.dialogRef.close(documentData);
    } else {
      this.dialogRef.close(null);
    }
  }
}
