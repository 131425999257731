
import { Directive, HostListener, EventEmitter, Output, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollable]',
})
export class ScrollableDirective {

  @Output() scrollPosition = new EventEmitter();

  public disableScrollDown = false;
  constructor(public el: ElementRef) { }

  @HostListener('scroll', ['$event'])
  public onScroll() {
    const element = this.el.nativeElement;
    const top = element.scrollTop;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (top === 0) {
      // console.log(' hit the top');
      this.scrollPosition.emit('top');
    }
    // console.log('disabledscrolldown atbottom: ' + this.disableScrollDown + ' ' + atBottom);
    if (this.disableScrollDown && atBottom) {
      this.disableScrollDown = false;
      //  console.log('not disabled bottom');
    } else {
      this.disableScrollDown = true;
      //  console.log('disabled bottom');
    }
  }


  public scrollToBottom(): void {
    if (this.disableScrollDown) {
      return;
    }
    try {
      //    console.log('bottom');
      this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
    } catch (err) { }
  }

}
