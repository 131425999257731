import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@eva-core/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SubscriptionLike as ISubscription, Subscription, Observable } from 'rxjs';

import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { mnemonicType } from '@eva-model/mnemonicType';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { take } from 'rxjs/operators';
import { LoggingService } from '@eva-core/logging.service';

@Component({
  selector: 'app-user-mnemonic',
  templateUrl: './user-mnemonic.component.html',
  styleUrls: ['./user-mnemonic.component.scss']
})
export class UserMnemonicComponent implements OnDestroy {

  isWaiting = false;

  encryptionMnemonic = null;
  signingMnemonic = null;
  splitSigningMnemonic: string[] = [];
  splitEncryptionMnemonic: string[] = [];
  mnemonicWordsCount = 12;

  signingMnemonicForm: UntypedFormGroup = new UntypedFormGroup({});
  encryptionMnemonicForm: UntypedFormGroup = new UntypedFormGroup({});

  mntype: any;

  generalDialogChangeSubscription: ISubscription;

  constructor(
    public afAuth: AuthService,
    private _fb: UntypedFormBuilder,
    private _http: HttpClient,
    private logging: LoggingService,
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService
  ) {
    this.mntype = mnemonicType;
  }

  ngOnDestroy(): void {
    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }
  }

  async getMnemonic(mnemonicTypeOfKey: mnemonicType, stepper: MatStepper) {

    if ( Object.values(mnemonicType).indexOf(mnemonicTypeOfKey) === -1 ) return;

    this.isWaiting = true;
    const mnemonic = await this.generateMnemonic();
    mnemonic
    .pipe(
      take(1)
    )
    .subscribe(
      (data) => {

        this.isWaiting = false;
        if ( data && data.mnemonic ) {
          if ( mnemonicTypeOfKey === mnemonicType.encryption ) {
            this.encryptionMnemonic = data.mnemonic;
            this.encryptionMnemonicForm = this.createEncryptionMnemonicFormGroup();
          } else  if ( mnemonicTypeOfKey === mnemonicType.signing ) {
            this.signingMnemonic = data.mnemonic;
            this.signingMnemonicForm = this.createSigningMnemonicFormGroup();
          }

          stepper.next();
        }

      },
      (err) => {
        // TODO :: log the errors in DB error collection
        console.log(err);
        this.isWaiting = false;
      }
    );

  }

  async generateMnemonic(): Promise<Observable<any>> {
    const url = environment.firebaseFunction.endpoint + '/generateMnemonic';

    const bodyData = { 'uid': await this.afAuth.getUserId(), 'userPassword': 'userpassword' };
    return this._http.post<any>(url, bodyData);
  }

  openSnackBarCopy() {
    this.logging.logMessage('Copied mnemonic to clipboard.', false, 'success', null, 2000);
  }

  openSnackBarSave() {
    this.logging.logMessage('Saved mnemonic to desktop.', false, 'success', null, 2000);
  }

  private saveToFile(input, title) {
    const blob = new Blob([input], { type: 'text/plain' });
    saveAs(blob, title);
    this.openSnackBarSave();
  }

  createSigningMnemonicFormGroup() {
    this.splitSigningMnemonic = this.signingMnemonic.split(' ');

    const group = this._fb.group({});
    this.splitSigningMnemonic.forEach((word, index) => {
      let control;
      if (index === 2 || index === 5 || index === 8) {
        control =
          new UntypedFormControl(
            { value: '', disabled: false },
            [Validators.required, this.wordMatchValidator(word, index, this.splitSigningMnemonic)]
          );
      } else {
        control = new UntypedFormControl({value: word, disabled: true});
      }
      group.addControl(word, control);
    });
    return group;
  }

  createEncryptionMnemonicFormGroup() {
    this.splitEncryptionMnemonic = this.encryptionMnemonic.split(' ');

    const group = this._fb.group({});
    this.splitEncryptionMnemonic.forEach((word, index) => {
      let control;
      if (index === 2 || index === 5 || index === 8) {
        control =
          new UntypedFormControl(
            {value: '', disabled: false},
            [Validators.required, this.wordMatchValidator(word, index, this.splitEncryptionMnemonic)]
          );
      } else {
        control = new UntypedFormControl({value: word, disabled: true});
      }
      group.addControl(word, control);
    });
    return group;
  }

  wordMatchValidator(word: string, index: number, array: string[]) {
    return (control): {[key: string]: any} | null => {
      const match = control.value === array[index];
      return match ? null : {'wordmatch': true};
    };
  }

  isEncryptionMnemonicValid() {
    return (this.encryptionMnemonic &&
      this.encryptionMnemonic.length > 0 &&
      this.encryptionMnemonic.split(' ').length === this.mnemonicWordsCount );
  }

  isSigningMnemonicValid() {
    return (this.signingMnemonic &&
      this.signingMnemonic.length > 0 &&
      this.signingMnemonic.split(' ').length === this.mnemonicWordsCount );
  }

  // resetUserSecurityKeys
  async resetUserSecurityKeys(): Promise<Observable<any>> {
    const url = environment.firebaseFunction.endpoint + '/resetUserSecurityKeys';

    const bodyData = {
      'uid': await this.afAuth.getUserId(),
      'userPassword': 'userpassword',
      'signingMnemonic': this.signingMnemonic,
      'encryptionMnemonic': this.encryptionMnemonic
    };

    return this._http.post<any>(url, bodyData);
  }

  confirmResettingUserSecurityKeys() {
    // eslint-disable-next-line max-len
    const notification = 'Note: In case of updating security keys you will loose access to existing data, entities, and objects which you may have already recorded.';

    const dialogData = new GeneralDialogModel(
      'Reset User Security Keys dialog',
      `Are you sure you want to reset the security keys? <br> ${notification}`,
      'Yes', 'No');

    this.openGeneralDialog(dialogData, this.confirmedUpdateUserSecurityKeys, { that: this, msg: "Exited the process" });
  }

  async confirmedUpdateUserSecurityKeys(data) {
    await data.that.updateUserSecurityKeys();
  }

  async updateUserSecurityKeys() {

    this.isWaiting = true;
    const resetUserKeys$ = await this.resetUserSecurityKeys();
    resetUserKeys$
    .pipe(
      take(1)
    )
    .subscribe(
      (data) => {

        this.isWaiting = false;
        // console.log(data);

      },
      (err) => {
        // TODO :: log the errors in DB error collection
        console.log(err);
        this.isWaiting = false;
      }
    );

  }

  //#region Dialog related methods
  private openGeneralDialog(dialogModel: GeneralDialogModel, callback, callbackData: any) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel
    });

    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }
  //#endregion

}
