import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { AngularFireUploadTask, AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject, forkJoin } from 'rxjs';
import { DeleteFileResponse } from '@eva-model/fileStorageResponses';
import { FileUploadComponent } from '@eva-ui/file/file-upload/file-upload.component';
import { MatDialog } from '@angular/material/dialog';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';

export interface UploadQueueItem {
  path: string;
  data: File | Blob;
}

export interface ActiveUploadItem {
  path: string;
  task: AngularFireUploadTask;
}

export interface CompletedUploadItem {
  path: string;
  task: AngularFireUploadTask;
  downloadUrl: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Contains enough data to create a firebase storage upload task.
  private pendingUploads: BehaviorSubject<UploadQueueItem[]> = new BehaviorSubject([]);
  // collection of active upload tasks
  private activeUploads: BehaviorSubject<AngularFireUploadTask[]> = new BehaviorSubject([]);
  // If any tasks are in active uploads
  private currentlyUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // Subject tracks how many uploads have completed
  private uploadCompleted: Subject<any> = new Subject();

  // Publicly exposed subjects to pass and subscribe to
  public activeUploads$ = this.activeUploads.asObservable();
  public currentlyUploading$ = this.currentlyUploading.asObservable();

  constructor(
    private storage: AngularFireStorage,
    private dialog: MatDialog
  ) {
    // Listen for changes in activeUploads and update currentlyUploading
    this.activeUploads.pipe(
      map((uploads: AngularFireUploadTask[]) => {
        // map to boolean
        return uploads.length > 0;
      }),
      distinctUntilChanged()
    ).subscribe((uploading: boolean) => {
      // Show visualization dialog of uploads
      if (uploading) {
        this.triggerUploadVisualizer();
      }

      this.currentlyUploading.next(uploading);
    });
  }

  public async startUploads(toUpload: UploadQueueItem[]): Promise<CompletedUploadItem[]> {
    const uploading$ = [];
    const activeUploadsTasks: AngularFireUploadTask[] = [];
    const results: CompletedUploadItem[] = [];

    toUpload.forEach((item) => {
      this.currentlyUploading.next(true);

      // Create and initialize our uplosfd
      const task: AngularFireUploadTask = this.storage.upload(item.path, item.data);

      // push to this array for our subject
      activeUploadsTasks.push(task);
      // Add task to currently uploading, track when all complete
      uploading$.push(task.snapshotChanges());
      // Add task to result
      results.push({
        path: item.path,
        task,
        downloadUrl: null,
        success: true
      });
    });

    // Push all our active tasks into our subject
    this.activeUploads.next(activeUploadsTasks);

    // All our uploads completed
    try {
      const uploadSnapshots: UploadTaskSnapshot[] = await forkJoin(...uploading$).toPromise();

      // Get our download urls
      for (let i = 0; i < uploadSnapshots.length; i++) {
        const url = await uploadSnapshots[i].ref.getDownloadURL();
        results[i].downloadUrl = url;
      }

      // Uploading has finished
      this.activeUploads.next([]);

      return results;
    } catch (err) {
      throw new Error('Something happened in the uploads...');
    }
  }

  /**
   * Only use with single file
   * Checks if the file is an image file type, uploads the file and returns the url if successful or null if failed.
   * @param dirPath firebase storage directory
   * @param file file object to store
   */
  public async uploadFileAndGetURL(path: string, file: File): Promise<string | null> {
    // Queue our files
    const uploads: CompletedUploadItem[] = await this.startUploads([{path, data: file}]);
    return uploads[0].downloadUrl;
  }

  /**
   * Creates a dialog component that shows the uploads
   */
  private triggerUploadVisualizer() {
    // Create our overlay which contains our upload status component
    // Visualizer will close itself when done
    this.dialog.open(FileUploadComponent, {
      hasBackdrop: false,
      width: '320px',
      data: {
        activeUploads: this.activeUploads$,
        currentlyUploading: this.currentlyUploading$
      },
      position: {
        right: '16px',
        bottom: '16px'
      }
    });
  }

  /**
   * This function deletes the image from firebase storage
   *
   * @param imageUrl Image URL of the image being deleted
   */
  public async deleteImage(imageUrl: string): Promise<DeleteFileResponse> {
    const promiseResponse: DeleteFileResponse = {
      url: '',
      successful: false
    };
    promiseResponse.url = imageUrl;
    try {
      const imageRef = this.storage.storage.refFromURL(imageUrl);
      await imageRef.delete();
      promiseResponse.successful = true;
    } catch (error) {
      console.log(error);
      promiseResponse.successful = false;
    }
    // finally
    return promiseResponse;
  }

}
