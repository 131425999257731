<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="sub-title-container">
    <h5 class="font-weight-bold" [innerHTML]="dialogData.content"></h5>
  </div>

  <ng-template appViewContainer></ng-template>

</div>
<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-raised-button color="primary" [disabled]="isConfirmButtonDisabled" [mat-dialog-close]="true"
      cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
    <button mat-stroked-button (click)="onNoClick()"
      *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
  </div>
</div>