<button 
  [class.hideButton]="buttonType !== 'Download'" 
  [class.showButton]="buttonType === 'Download'" 
  mat-icon-button 
  (click)="downloadJSON()">
  <mat-icon aria-label="Save">save_alt</mat-icon>
</button>
<button
  mat-raised-button
  color="primary"
  [class.hideButton]="buttonType !== 'Upload'"
  [class.showButton]="buttonType === 'Upload'"
  (click)="uploadJSON()">
  Import Workflow
  <input type="file" style="display:none;" #uploadJSONFile (change)="uploadFile($event)" />
</button>