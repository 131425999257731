
<h2 mat-dialog-title>
    <div>Notifications</div>
</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="notifications">
    <p *ngIf="workflowNotificationService.workingOnProcesses.length === 0 && workflowNotificationService.notificationResults.length === 0 && notificationsService.invites.length === 0 && notificationsService.requests.length === 0 && notificationsService.notifications.length === 0">
      No notifications... Yay!
    </p>

      <div class="notifications-header">Working On</div>
      <ng-container *ngIf="!isWaitingForActionProcess">
        <div class="notification" *ngFor="let wnS of workflowNotificationService.workingOnProcesses">
          <div>
            <app-user-info-display matLine [publicKey]="wnS.submitterPublicKey" [processId]="wnS.processId"></app-user-info-display>
          </div>
          <div>Group: {{ this.evaGlobalService.getGroupNameByPublicKey(wnS.destinationPublicKey) }}</div>
          <div class="notification-timestamp">Created at {{ wnS.createdAt | date:'medium' }}</div>
          <div class="notification-timestamp">Updated at {{ wnS.updatedAt | date:'medium' }}</div>
          <div class="notification-actions">
            <button mat-raised-button color="primary" type="button" (click)="actionProcess(wnS)" [disabled]="wnS.sending === true">Action</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isWaitingForActionProcess">
        <div>
          <mat-spinner diameter="100" strokeWidth="10"></mat-spinner>
          <h6>
            <span class="verColor">{{ onWaitForPrcsMsg }}</span>
          </h6>
        </div>
      </ng-container>

      <div class="notifications-header">Group Items To Acknowledge</div>
      <ng-container *ngIf="!isWaitingForAcceptProcess">
        <div class="notification" *ngFor="let wnS of workflowNotificationService.notificationResults">
          <div>
            <app-user-info-display matLine [publicKey]="wnS.submitterPublicKey" [processId]="wnS.processId"></app-user-info-display>
          </div>
          <div>Group: {{ this.evaGlobalService.getGroupNameByPublicKey(wnS.destinationPublicKey) }}</div>
          <div class="notification-timestamp">Created at {{ wnS.createdAt | date:'medium' }}</div>
          <div class="notification-timestamp">Updated at {{ wnS.updatedAt | date:'medium' }}</div>
          <div class="notification-actions">
            <button mat-raised-button color="primary" type="button" (click)="acknowledgeProcess(wnS)" [disabled]="wnS.sending === true">Accept</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isWaitingForAcceptProcess">
        <div>
          <mat-spinner diameter="100" strokeWidth="10"></mat-spinner>
          <h6>
            <span class="verColor">{{ onWaitForPrcsMsg }}</span>
          </h6>
        </div>
      </ng-container>

      <div class="notifications-header">My Ended Processes</div>
      <ng-container *ngIf="!isWaitingForEndedProcess">
        <div class="notification" *ngFor="let wnS of workflowNotificationService.myEndedProcesses">
          <div>
            <app-user-info-display matLine [publicKey]="wnS.submitterPublicKey"></app-user-info-display>
          </div>
          <div>{{ this.evaGlobalService.getGroupNameByPublicKey(wnS.destinationPublicKey) !== 'ATB' ? 'Group:' : 'User:' }}  {{ (this.getNameByPublicKey(wnS.destinationPublicKey) | async)?.name }}</div>
          <div class="notification-timestamp">Created at {{ wnS.createdAt | date:'medium' }}</div>
          <div class="notification-timestamp">Updated at {{ wnS.updatedAt | date:'medium' }}</div>
          <div class="notification-actions button-row-left">
            <button mat-stroked-button (click)="viewProcess(wnS)" [disabled]="wnS.sending === true">View</button>
            <button mat-raised-button color="primary" (click)="doneProcess(wnS)" [disabled]="wnS.sending === true">Done</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isWaitingForEndedProcess">
        <div>
          <mat-spinner diameter="100" strokeWidth="10"></mat-spinner>
          <h6>
            <span class="verColor">{{ onWaitForPrcsMsg }}</span>
          </h6>
        </div>
      </ng-container>

      <ng-container *ngIf="notificationsService.invites.length > 0">
        <div class="notifications-header">Invitations</div>
        <div class="notification" *ngFor="let invite of notificationsService.invites">
          <div><strong>Invited to {{invite.invitationName}}</strong></div>
          <div class="notification-timestamp">Sent by {{invite.inviterName}}</div>
          <div>{{invite.invitationDescription}}</div>
          <div class="notification-actions button-row-left">
            <button mat-raised-button color="primary" type="button" (click)="acceptInvitation(invite)" [disabled]="invite.sending === true">Accept</button>
            <button mat-stroked-button type="button" (click)="declineInvitation(invite)" [disabled]="invite.sending === true">Decline</button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="notificationsService.requests.length > 0">
        <div class="notifications-header">Group Access Requests</div>
        <div class="notification" *ngFor="let request of notificationsService.requests">
          <div><strong>Group {{request.groupName}}</strong></div>
          <div class="notification-timestamp">Requested by {{request.requesterName}} </div>
          <div class="notification-actions button-row-left">
            <button mat-raised-button color="primary" type="button" (click)="acceptRequest(request)" [disabled]="request.sending === true">Accept</button>
            <button mat-stroked-button type="button" (click)="declineRequest(request)" [disabled]="request.sending === true">Decline</button>
          </div>
        </div>
      </ng-container>

      <div class="notifications-header">Notifications <button mat-button color="accent" type="button" class="clear-notifications-button" (click)="deleteAllNotifications()">Clear</button></div>
      <div class="notification" *ngFor="let notification of notificationsService.notifications">
        <div>
          {{notification.message}}
        </div>
        <div class="notification-timestamp">Generated at {{notification.dateUpdated | date :'short'}}</div>
        <div class="notification-actions button-row-left">
          <button *ngIf="notification.url !== undefined" mat-raised-button color="primary" type="button" (click)="gotToView(notification)" [disabled]="notification.sending === true">View</button>
          <button mat-stroked-button type="button" (click)="deleteNotification(notification)" [disabled]="notification.sending === true">Clear</button>
        </div>
      </div>
  </div>
</mat-dialog-content>
