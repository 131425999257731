import { Component, OnInit } from '@angular/core';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';
import { environment } from '@environments/environment';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { AuthService } from '@eva-core/auth.service';
import { HoldableEmitter } from '@eva-model/directives';
import { LoggingService } from '@eva-core/logging.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-administration-overview',
  templateUrl: './administration-overview.component.html',
  styleUrls: ['./administration-overview.component.scss']
})
export class AdministrationOverviewComponent implements OnInit {

  disableUpdateEVA = false;

  progress = 0; // The total progress of the hold to execute.
  versionUpdateDisabled = false;
  updateAllRecordsDisabled = false;

  // endpoints for use in administration
  updateAllRecordsUrl = environment.firebaseFunction.endpoint + '/forceInteractionSync';
  isAdministrator = false; // whether the user is an administrator.
  componentTitle: string; // the component title.

  constructor(
    private menuNavigationService: MenuNavigationService,
    private firestoreService: FirestoreService,
    public authService: AuthService,
    private loggingService: LoggingService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    this.menuNavigationService.updateCurrentMenu('administration');
  }

  async ngOnInit() {
    // announce the change of menus
    try {
      this.isAdministrator = await this.authService.isUserAdmin();
      if (!this.isAdministrator) {
        this.menuNavigationService.updateCurrentMenu('main');
          this.router.navigate(['/']);
      }
    } catch (error) {
      console.log(error);
      this.menuNavigationService.updateCurrentMenu('main');
      this.router.navigate(['/']);
    }
  }

  /**
   * This function is used to update the holdable directive.
   */
  doSomething(e) {
    this.progress = e / 30;
    if (this.progress === 100) {
      console.log('yep');
    }
   }
  /**
   * This takes the interval from the appHoldable directive and provides this to the function. By dividing this
   * by 30, the update will occur after holding the button for 3 seconds. It then updates the version of EVA in the database
   * prompting the users to cause a refresh.
   *
   * @param emitter Emits progress of user action
   */
  async updateVersion(emitter: HoldableEmitter): Promise<void> {
    this.progress = emitter.holdTime / 30;
    // if this is at 100% fire.
    if (this.progress === 100) {
      this.disableUpdateEVA = true;
      this.versionUpdateDisabled = true;
      const versionRef$ = this.firestoreService.doc('EVAVersion/VersionNumber');
      try {
        await versionRef$.set({ version: Date.now() }, { merge: false });
        // this.loggingService.logMessage('Updated Version', false, 'success');
        this.versionUpdateDisabled = false;
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('An error occurred when creating a new version', false, 'error');
        this.versionUpdateDisabled = false;
      }
    }
  }

  /**
   * This forces the items in EVARecords to update all records which triggers an update to algolia.
   *
   * @param emitter Emits progress of user action
   */
  async updateAllRecords(emitter: HoldableEmitter): Promise<void> {
    this.progress = emitter.holdTime / 30;
    // if this is at 100% fire.
    if (this.progress === 100) {
      this.versionUpdateDisabled = true;
      try {
        this.loggingService.logMessage('We are starting to update all records.', false, 'success');
        await this.http.get(this.updateAllRecordsUrl).toPromise();
        this.loggingService.logMessage('All Records are set and will update through the scheduled task', false, 'success');
        this.versionUpdateDisabled = false;
      } catch (err) {
        console.log(err);
        this.loggingService.logMessage('An error occurred when creating a new version', false, 'error');
        this.versionUpdateDisabled = false;
      }
    }
  }


}
