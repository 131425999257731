import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-complete-grid-child',
  templateUrl: './complete-grid-child.component.html',
  styleUrls: ['./complete-grid-child.component.scss']
})
export class CompleteGridChildComponent implements ICellRendererAngularComp {

  public params: any;
  isDisabled: boolean;

  constructor() { }

  /**
   * this fires when the grid is initialized.
   * @param params the row parameters
   */
  agInit(params: any): void {
    this.setupParams(params);
  }

  /**
   * This sets up the grid parameters.
   * @param params grid parameters
   */
  setupParams(params: any): void {
    // if the reply isn't set disable the button.
    if (!params.data.reply) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
      this.params = params;
  }
  /**
   * This function invokes a method on the parent component. To pass this the grid requires a context in the definition
   * on the parent component. The completeFeedbackRecord is the function in the containing grid.
   * This returns the data that exists (the whole row of data that is used in the grid.)
   */
  public invokeParentMethod(): any {
      this.params.context.componentParent.completeFeedbackRecord(this.params.data);
  }

  /**
   * the refresh method is required on a ICellRendererAngularComp type. It fires when data is updated in the
   * control.
   */
  refresh(params: any): boolean {
    // console.log(params);
    this.setupParams(params);
    return true;
  }
}
