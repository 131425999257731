<h2 mat-dialog-title>
  Find and Replace
</h2>
<div class="search-bar">
  <form fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="findAndReplaceForm" autocomplete="off" (ngSubmit)="searchDocument()">
    <mat-form-field class="example-full-width">
      <input matInput formControlName="find" placeholder="Find" cdkFocusInitial>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput formControlName="replace" placeholder="Replace with">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="markJsOpts.caseSensitive" [ngModelOptions]="{standalone: true}">Case Sensitive</mat-checkbox>
    <button type="submit" mat-raised-button color="primary">Search</button>
  </form>
</div>

<mat-dialog-content>
  <mat-card *ngFor="let result of results; let i = index; trackBy: trackById" [class.result-changed]="result.changed">
    <div [innerHTML]="result.displayHTML" class="html-display-chunk"></div>
    <button mat-stroked-button (click)="updateFoundTermWithReplacement(i)" [disabled]="result.changed">{{ !result.changed ? 'Replace' : 'Replaced' }}</button>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="space-between center">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="results">
      <div>
        <div>{{resultsFound}} Results</div>
        <div>{{resultsChanged === 0 ? 'None' : resultsChanged}} Changed</div>
      </div>
      <div>
        <button mat-stroked-button (click)="updateAllFoundTermsWithReplacement()">
          Replace All
        </button>
      </div>
    </div>
  </div>
  <div>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="closeDialogAndEmitNewHTML()" [disabled]="!results">Finish</button>
  </div>
</mat-dialog-actions>