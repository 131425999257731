import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupProviderService } from '../../core/group-provider.service';
import { AuthService } from '../../core/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupMembersDialogComponent } from '@eva-ui/group-admin/group-members-dialog/group-members-dialog.component';
import { ShareGroupDialogComponent } from '@eva-ui/group-admin/share-group-dialog/share-group-dialog.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { KeysService } from '@eva-core/encryption/keys.service';
import { GroupType, Group } from '@eva-model/group';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-admin',
  templateUrl: './group-admin.component.html',
  styleUrls: ['./group-admin.component.css']
})
export class GroupAdminComponent implements OnInit, OnDestroy {

  user: any;
  myGroups: Group[];
  myCollection: any;

  myGroupsLoading = true;

  groupType = new GroupType();
  componentTitle: string;

  private subs: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private keysService: KeysService,
    public evaGlobalService: EvaGlobalService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });

  }

  ngOnInit() {
    if (this.evaGlobalService.userGroupsPublicKeys &&
      Array.isArray(this.evaGlobalService.userGroupsPublicKeys) &&
      this.evaGlobalService.userGroupsPublicKeys.length > 0 )  {
        this.setupGroups();
      } else {
        this.prepareThenSetGroups();
      }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  /**
   * This function loads all the admin user groups, except "change" group type.
   */
  setupGroups(): void {
    this.myGroups = this.evaGlobalService.userGroups.filter(group => (group.groupType !== 'V2hhdCBkbyB5b3UgdGhpbmsgb2YgbWU/'));
    this.myGroupsLoading = false;
  }

  /**
   * This function sets up all the admin user groups once user group observable is ready in the service
   */
  prepareThenSetGroups(): void {
    if ( this.evaGlobalService.userPublicKey ) {
      this.subs.add(
        this.evaGlobalService.userGroups$
        .subscribe(
          () => {
            setTimeout( () => {
              this.setupGroups();
            }, 500);
          }
        )
      );
    } else {
      this.keysService.getUserPublicKey()
      .then(currentUserPublicKey => {
        this.evaGlobalService.userPublicKey = currentUserPublicKey.toString();
        this.prepareThenSetGroups();
      });
    }
  }

  /**
   * This function opens a dialog box to edit the user group
   *
   * @param group Group to edit
   */
  editGroup(group: any): void {
    let dialogData = null;
    let dialogComponent = null;

    dialogData = new GeneralDialogModel(
      group.groupName + ' Membership',
      `Add and remove members of a group`,
      'Done', 'Cancel', null,
      {
        'group': JSON.parse(JSON.stringify(group)),
        'user': this.user
      }
    );
    dialogComponent = GroupMembersDialogComponent;

    this.dialog.open(dialogComponent, {
      data: dialogData,
      minHeight: '400px',
      minWidth: '600px',
      disableClose: true
    });
  }

  /**
   * This function opens a dialog box to share the group
   *
   * @param group Group to share
   */
  shareGroup(group: any): void {
    let dialogData = null;
    let dialogComponent = null;

    dialogData = new GeneralDialogModel(
      'Share group ' + group.groupName,
      null,
      'Done',
      'Cancel',
      null,
      group
    );
    dialogComponent = ShareGroupDialogComponent;

    this.dialog.open(dialogComponent, {
      data: dialogData,
      minHeight: '400px',
      minWidth: '600px',
      disableClose: true
    });
  }

  /**
   * This function checks if the current user is part of the group
   *
   * @param group Group to check whether user is part of it or not
   */
  isMyGroup(group: any): boolean {
    return group.groupSigningMembership.includes(this.evaGlobalService.userPublicKey);
  }
}
