import { TutorialModel } from "../tutorial";

/**
 * This file contains the shapes to find and the steps that will occur when a tutorial is loaded.
 */

/**
 * This contains the welcome tutorial with basic EVA Functionality.
 */
export const welcomeModel: TutorialModel = {
  dialogConfig: {
    disableClose: true,
    data: {
      imageLink: '/assets/images/illy-atb-system.png',
      imageAlt: 'ATB System',
      html: `<h3>Welcome to EVA</h3>
      <p><strong>Ask. Learn. Do.</strong></p>
      <p>Let us give you a quick overview of how to get the most from EVA, and how the interface works.</p>`
    }
  },
  introJsConfig: {
    steps:  [{
      element: '#step1',
      intro: 'Type your question, then click "Send" or press "Enter" to ask EVA a question.',
      position: 'top'
    }, {
      element: '#step2',
      intro: 'EVA will read out her response to your question. Click "Mute EVA" if you would prefer to only see the response.',
      position: 'top'
    }, {
      element: '#ProcessesMenuItem',
      intro: 'Use "Processes" button to see pre-defined work processes like Balance Transfer, Loan Service Request, etc.',
      position: 'right'
    }, {
      element: '#step3',
      // eslint-disable-next-line max-len
      intro: 'Instead of typing your question, click "Talk to EVA" then your words will be transcribed as you speak. Click the button again to stop.',
      position: 'top'
    }, {
      element: '#step4',
      intro: 'Use "Speech Mode" to record a question and "Transcription Mode" (*In development) to record longer passages.',
      position: 'top'
    }, {
      element: '#chat_knowledge_toggle',
      // eslint-disable-next-line max-len
      intro: 'Turn on the toggle to filter EVA responses for Knowledge only. The default state includes all of EVA: Change Management, Processes and Knowledge.',
      position: 'top'
    }],
    exitOnEsc: false,
    exitOnOverlayClick: false
  }
};
