import {
    DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
    DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER,
    DYNAMIC_FORM_CONTROL_TYPE_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_INPUT,
    DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
    DYNAMIC_FORM_CONTROL_TYPE_SELECT,
    DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
    DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
    DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,

    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL,
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK,

    DYNAMIC_FORM_TEXTAREA_WRAP_SOFT,
    DYNAMIC_FORM_TEXTAREA_WRAP_HARD,

    DynamicFormControlModel,
    DynamicInputModel,
    DynamicCheckboxModel,
    DynamicCheckboxGroupModel,
    DynamicDatePickerModel,
    DynamicRadioGroupModel,
    DynamicSelectModel,
    DynamicSliderModel,
    DynamicSwitchModel,
    DynamicTextAreaModel,
    DynamicFormOption,
    DynamicFormControlLayout
} from '@ng-dynamic-forms/core';

/** Commented out as this is not exported in the current ng Dynamic forms.
 * import {
    MAT_LABEL_POSITION_AFTER,
    MAT_LABEL_POSITION_BEFORE
} from '@ng-dynamic-forms/ui-material';**/

const MAT_LABEL_POSITION_AFTER = 'after';
const MAT_LABEL_POSITION_BEFORE = 'before';
const EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS = 'CHIPS' ;

export const DYNAMIC_FORM_CONTROL_TYPE_INPUT_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Auto Focus', id: 'autoFocus', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Max length', id: 'maxLength', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Min length', id: 'minLength', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Max Value', id: 'max', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Min Value', id: 'min', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Step', id: 'step', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Placeholder', id: 'placeholder', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Read only', id: 'readOnly', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'prefix', id: 'prefix', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'suffix', id: 'suffix', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Chips Input', id: 'multiple', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Data list', id: 'list', type:  `${DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA}`, inputType: '', nullable: true },
    // { name: 'Mask', id: 'mask', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Pattern', id: 'pattern', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'step', id: 'step', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Default Value', id: 'additional', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Date can not be in the past', id: 'isPastDate', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`,
    inputType: '', nullable: true },
    { name: 'Make current date default value', id: 'currentDateDefault', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`,
    inputType: '', nullable: true }
  ];

  export const DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Auto Focus', id: 'autoFocus', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Max length', id: 'maxLength', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Min length', id: 'minLength', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Placeholder', id: 'placeholder', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Read only', id: 'readOnly', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'prefix', id: 'prefix', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'suffix', id: 'suffix', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },

    { name: 'Cols', id: 'cols', type: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, inputType: '', nullable: true },
    { name: 'Rows', id: 'rows', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Wrap', id: 'wrap', type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: '', nullable: true ,
      options:
      [
        {
          value: DYNAMIC_FORM_TEXTAREA_WRAP_SOFT,
          label: DYNAMIC_FORM_TEXTAREA_WRAP_SOFT
        },
        {
          value: DYNAMIC_FORM_TEXTAREA_WRAP_HARD,
          label: DYNAMIC_FORM_TEXTAREA_WRAP_HARD
        }
      ]
    },

    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: 'INPUT', nullable: true },
    { name: 'Default Value', id: 'additional', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true }
  ];

  export const DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Indeterminate', id: 'indeterminate', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Label Position', id: 'labelPosition', type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: '', nullable: true,
      options:
      [
        {
          value: MAT_LABEL_POSITION_AFTER,
          label: MAT_LABEL_POSITION_AFTER
        },
        {
          value: MAT_LABEL_POSITION_BEFORE,
          label: MAT_LABEL_POSITION_BEFORE
        }
      ]
    },
    { name: 'Is Checked by Default?', id: 'additional', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: `INPUT`, nullable: true }

  ];

  /// TODO: must be looking at already entered chips values
  export const DYNAMIC_FORM_CONTROL_TYPE_SELECT_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    // { name: 'Filterable', id: 'filterable', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Placeholder', id: 'placeholder', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Options', id: 'options', type: `${EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS}`, inputType: '', nullable: true },
    { name: 'Default Value', id: 'additional', type: `${EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS}`, inputType: '', nullable: true }
  ];

    /// TODO: must be looking at already entered chips values
  export const DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Options', id: 'options', type: `${EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS}`, inputType: '', nullable: true },
    { name: 'Default Value', id: 'additional',  type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Legend', id: 'legend', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
  ];

  export const DYNAMIC_FORM_CONTROL_TYPE_SLIDER_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Max', id: 'max', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Min', id: 'min', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Step', id: 'step', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER}`, nullable: true },
    { name: 'Vertical', id: 'vertical', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Default Value', id: 'additional', type: `${DYNAMIC_FORM_CONTROL_TYPE_SLIDER}`,
    inputType: `${DYNAMIC_FORM_CONTROL_TYPE_SLIDER}`, nullable: true }
  ];

  export const DYNAMIC_FORM_CONTROL_TYPE_SWITCH_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Hint', id: 'hint', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Required', id: 'required', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Label Position', id: 'labelPosition', type: `${DYNAMIC_FORM_CONTROL_TYPE_SELECT}`, inputType: '', nullable: true,
      options:
      [
        {
          value: MAT_LABEL_POSITION_AFTER,
          label: MAT_LABEL_POSITION_AFTER
        },
        {
          value: MAT_LABEL_POSITION_BEFORE,
          label: MAT_LABEL_POSITION_BEFORE
        }
      ]
    },
    { name: 'Off Label', id: 'offLabel', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'On Label', id: 'onLabel', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Default Value', id: 'additional', type: `${DYNAMIC_FORM_CONTROL_TYPE_SWITCH}`,
      inputType: '${DYNAMIC_FORM_CONTROL_TYPE_SWITCH}', nullable: true}
  ];

  export const DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP_ELEMENTS = [
    { name: 'Label', id: 'label', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Label tool tip', id: 'labelTooltip', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    // { name: 'Control tool tip', id:'controlTooltip', type:'INPUT', inputType:`${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Disabled', id: 'disabled', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Hidden', id: 'hidden', type: `${DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX}`, inputType: '', nullable: true },
    { name: 'Group', id: 'group', type: `${EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS}`, inputType: '', nullable: true },
    { name: 'Legend', id: 'legend', type: 'INPUT', inputType: `${DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT}`, nullable: true },
    { name: 'Default Value', id: 'additional', type: `${EVA_DYNAMIC_FORM_CONTROL_TYPE_INPUT_CHIPS}`, inputType: '', nullable: true}
  ];

  export const formControlValidationPattern = {
    email: '^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)[.]([a-zA-Z]{2,})$',
    phone: '^[(]([2-9]{1}[0-9]{2})[)][-. ]([0-9]{3})[-. ]([0-9]{4}).?$',
    url: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$',
    afterNow: ''
  };
