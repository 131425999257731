<div class="notifications-feedback">
  <app-page-header [title]="componentTitle"></app-page-header>

  <ng-container *ngIf="knowledgeGroups; else noKnowledgeGroups">
    <div class="page-title">
      <div class="container">
        <!--- Knowledge Group Selection --->
        <mat-form-field class="knowledge-group-selection">
          <mat-label>Knowledge Group</mat-label>
          <mat-select [(value)]="selectedGroup" (selectionChange)="groupChanged($event)">
            <mat-option *ngFor="let group of knowledgeGroups" [value]="group">
              {{group.groupName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--- Reviewed Selection --->
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(value)]="selectedStatus">
            <mat-option [value]="false">Needs Review</mat-option>
            <mat-option [value]="true">Reviewed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="container">
      <app-notifications-feedback-group [group]="selectedGroup" [status]="selectedStatus">
      </app-notifications-feedback-group>
    </div>
  </ng-container>
  <ng-template #noKnowledgeGroups>
    <div class="container">
      You don't belong to any Knowledge groups.
    </div>
  </ng-template>
</div>