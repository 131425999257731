import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, from, of, interval, concat } from 'rxjs';
import { withLatestFrom, map, distinctUntilChanged, repeat, concatMap, tap, delayWhen, take, filter, pluck } from 'rxjs/operators';
import { NextChatQueryType, NextChatQuery, ChatEntity, ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { LastState, userLastStateType } from '@eva-model/userLastState';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { AuthService } from '@eva-core/auth.service';

// TODO: This will be moved to a document and cached for an expiry of a week or something.
const saltChatHints = [
  `Try asking "how much can I contribute to my RRSP?"`,
  `Try asking "change an address"`,
  `Try asking "Does ATB do community and social development?"`,
  `Try asking "How is a TFSA structured in ATB?"`
];

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private chatEntities: BehaviorSubject<ChatEntity[]> = new BehaviorSubject([]);
  public chatEntities$: Observable<ChatEntity[]> = this.chatEntities.asObservable();

  private currentChatEntity: BehaviorSubject<ChatEntity> = new BehaviorSubject(null);
  public currentChatEntity$: Observable<ChatEntity> = this.currentChatEntity.asObservable();

  private unseenChatEntitiesCount = 0;
  private unseenChatEntitiesSource: Subject<number> = new Subject();
  public unseenChatEntities$ = this.unseenChatEntitiesSource.asObservable();

  // Chat state
  // "chat" is doing an asynchonrous function or http call (triggers loader in chat)
  private inProgress = new BehaviorSubject<boolean>(false);
  // track if the left pane is showing
  private isLeftPaneActive = new BehaviorSubject<boolean>(false);
  // track if chat is minimized.
  private isChatMinimized = new BehaviorSubject<boolean>(false);
  // combined with `nextChatQueryType` to create our next chat message object
  private nextChatQueryMessage = new Subject<string>();
  // combined with `nextChatQueryMessage` to create our next chat message obj
  private nextChatQueryType = new BehaviorSubject<NextChatQueryType>(null);

  private announceFocusChatInput = new Subject<boolean>();
  private announceChatPlaceholderText = new BehaviorSubject<string>(null);

  // retrived through a function call to get the last query.
  private lastChatQueryMessage: string = null;

  // A long living observable that continually emits salt chat hints and repeats upon completion.
  private delayHintEmissions = false; // used after the first emission, start delaying the rest, else first emission will be delayed.
  public saltChatHint$: Observable<string[]> = from(saltChatHints).pipe(
    concatMap(hint => of(hint).pipe(
      map(v => [v]), // map to array for animations... animated using ngFor on a single array item.
      delayWhen(() => {
        if (this.delayHintEmissions) {
          // 8s + 600ms for animation duration so message appears solid for 8s
          return interval(9600); // duration the hint will show (delay on next emission)
        }
        return interval(400); // do not delay first emission
      }),
      tap(() => {
        if (!this.delayHintEmissions) {
          this.delayHintEmissions = true; // upon first emission, trigger delay on all emissions.
        }
      })
    )),
    repeat() // repeats on end, FOREVER AND EVER AND EVER.
  );

  public chatInProgress$: Observable<boolean> = this.inProgress.asObservable();
  // private, sub to nextChatQuery$ for query and type
  private nextChatQueryMessage$: Observable<string> = this.nextChatQueryMessage.asObservable().pipe(
    tap(query => this.lastChatQueryMessage = query)
  );
  private nextChatQueryType$: Observable<NextChatQueryType> = this.nextChatQueryType.asObservable(); // private, sub to nextChatQuery$
  public announceFocusChatInput$: Observable<boolean> = this.announceFocusChatInput.asObservable(); // focuses chat input field on emit
  public isLeftPaneActive$: Observable<boolean> = this.isLeftPaneActive.asObservable(); // if left home pane is active
  public isChatMinimized$: Observable<boolean> = this.isChatMinimized.asObservable(); // if the chat panel is minimized
  // focuses chat input field on emit
  public announceChatPlaceholderText$: Observable<string> = this.announceChatPlaceholderText.asObservable();

  // you can subscribe to this observable to get the proper next chat message object
  public nextChatQuery$: Observable<NextChatQuery> = this.nextChatQueryMessage$.pipe(
    withLatestFrom(this.nextChatQueryType$),
    distinctUntilChanged(),
    map(([query, type]) => {
      return {
        query,
        type
      };
    })
  );

  public minimizedByUser = false;
  private lastState$: Observable<LastState>;

  constructor(private lastStateService: LastStateService,
              private authService: AuthService) {
    // get the lastState ONCE and set the last chat query
    // this is to ensure if the user just launched the app and had an ML answer in the chat,
    // then went to See Document, the lastChatQuery will be set when sending training.

    // create a greeting message since user just loaded app
    const firstName$ = this.authService.user.pipe(
      filter(user => !!user),
      take(1),
      pluck('preferredName'),
      map((name: string) => name.split(' ')[0]),
      tap((firstName) => {
        this.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: `Welcome back, ${firstName}!<br/>`
        }, null, null, null, true);
        this.newChatEntity({
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: `<strong>Thursday, November 25, 2021</strong><br/>A new update for EVA has been released! With this update, we have added a "Close All Tabs" button to Knowledge, a highly requested feature. 🎉`
        }, null, null, null, true);
      })
    );

    // load last state
    this.lastState$ = this.lastStateService.lastState$.pipe(
      filter(state => !!state),
      filter(state => state.sessionId !== this.lastStateService.getSessionId()),
      filter(state => this.chatEntities.value.findIndex(entity =>
        entity.id === state?.chat?.eva?.id || entity.id === state?.chat?.user?.id) === -1),
      // take(1),
      tap((state) => {
        this.createChatEntitiesFromLastState(state);
      })
    );

    // do in order
    concat(firstName$, this.lastState$).subscribe(
      () => {}, () => {}
    );

    // resetting unseen notifications
    this.isChatMinimized$.pipe(
      distinctUntilChanged(),
      filter(state => state === false)
    ).subscribe(() => {
      this.unseenChatEntitiesCount = 0;
      this.unseenChatEntitiesSource.next(this.unseenChatEntitiesCount);
    })

    // track unseen chat entities by counting every entity
    this.currentChatEntity$.pipe(
      withLatestFrom(this.isChatMinimized$),
      filter(([entity, minimized]) => {
        return minimized;
      })
    ).subscribe(() => {
      this.unseenChatEntitiesCount++;
      this.unseenChatEntitiesSource.next(this.unseenChatEntitiesCount);
    });
  }

  /**
   * Creates chat messages based on lastState changes
   */
  public createChatEntitiesFromLastState(state: Partial<LastState>, omitUserMessage: boolean = false) {
    if (!state.chat) {
      return;
    }
    const user = state.chat.user;
    const eva = state.chat.eva;

    if (!omitUserMessage && user) {
      this.newChatEntity(user, null, null, null, true);
    }
    this.newChatEntity(eva, null, null, null, true);
  }

  public createChatEntitiesFromDialogFlow(state: any, omitUserMessage: boolean = false) {
    let user: ChatEntity;
    let eva: ChatEntity;

    switch (state.type) {
      case userLastStateType.dialogflow:
        user = {
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: state.query
        };
        eva = {
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: state.response.fulfillmentText
        };
        break;
      case userLastStateType.TFIDF:
        user = {
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: state.query
        };
        eva = {
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.KnowledgeResponse,
          componentData: {
            data: KnowledgeUtils.getSectionFromLastState(state),
            lastState: state
          }
        };
        break;
      case userLastStateType.process:
        user = {
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: `Start a Process`
        };
        eva = {
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: state.query
        };
        break;
      case userLastStateType.interaction:
        user = {
          author: ChatEntityAuthor.User,
          type: ChatEntityType.Text,
          text: state.query
        };
        eva = {
          author: ChatEntityAuthor.EVA,
          type: ChatEntityType.Text,
          text: state.response.fulfillmentText
        };
        break;
    }

    if (!omitUserMessage) {
      this.newChatEntity(user);
    }
    this.newChatEntity(eva);
  }
  /**
   * Sets the inProgress subject, showing a loader in the chat
   */
  public setChatInProgress(progress: boolean): void {
    // with the new chat, you should not have to tell the chat to stop showing progress.
    // A new entity should replace the progress indicator automatically.
    if (!progress) {
      return;
    }

    // block any new loading requests
    if (!this.inProgress.getValue()) {
      this.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Loading,
        createdAt: Date.now()
      });
      this.inProgress.next(progress);
    }
  }

  /**
   * Updates next chat query observable with user typed input
   */
  public setNextChatQueryMessage(message: string): void {
    message.trim(); // trim the input

    // do not do anything if there are no characters after trim
    if (!message) {
      return;
    }

    // pass message along
    this.nextChatQueryMessage.next(message);
  }

  /**
   * When the next chat query comes through, the object will have a type of what was set
   *
   * @param type - a NextChatQueryType
   */
  public setNextChatQueryType(type: NextChatQueryType): void {
    this.nextChatQueryType.next(type);
  }

  /**
   * Simply resets the chat query type so the action doesn't happen again... unless that's intentional.
   * (example) User types 'break' and the break function fires. Inside the break function, since it happen, we will reset
   * the chat query type so if the user types something different, we don't invoke a break again when the user didn't
   * type that.
   */
  public resetNextChatQueryType(): void {
    this.nextChatQueryType.next(null);
  }

  /**
   * Calling this updates the focus input observable with true. saltChat subscribes and will focus the user input field
   * whenever this is called.
   */
  public focusChatInput(): void {
    this.announceFocusChatInput.next(true);
  }

  /**
   * Sets the chat placeholder text to the arg passed in.
   */
  public setPlaceholderText(placeholderText: string): void {
    this.announceChatPlaceholderText.next(placeholderText);
  }

  public resetPlaceholderText(): void {
    this.announceChatPlaceholderText.next(null);
  }

  /**
   * Sets the value of our left pane observable to let subscribers know if it is being shown or not in the view.
   *
   * @param active if left pane is being shown
   */
  public setLeftPanelActiveState(active: boolean): void {
    this.isLeftPaneActive.next(active);
  }

  /**
   * this sets the value of whether the chat pane is minimized.
   *
   * @param minimized if the chat state is currently minimized.
   */
  public setChatMinimizedState(minimized: boolean): void {
    this.isChatMinimized.next(minimized);
  }

  /**
   * Returns the last query message sent through the chat. If none then it's null.
   */
  public getLastQueryMessage(): string {
    return this.lastChatQueryMessage;
  }

  /**
   * Adds a new chat entity to the stream. Gets the current value of entities and adds another to the end
   * idToReplace - is where you can have more entities in the future, but if you are waiting on data to replace a specific loader, pass
   * it's id and then the loader will be spliced with the data.
   *
   * Outputs a random id assigned to the message or id that was supplied in data.
   */
  public newChatEntity(data: ChatEntity, idToReplace?: string, isProcessOpen?: boolean, forceOpen?: boolean,
    shouldNotUpdateDatabase?: boolean): string {
    if ((this.isChatMinimized.value && !isProcessOpen && !this.minimizedByUser) || forceOpen) {
      this.setChatMinimizedState(false);
    }
    data.createdAt = Date.now();

    const entities = this.chatEntities.getValue();
    const newEntity = data;

    const generatedId = (length: number): string => {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };

    // generate id on chat message if none was passed in
    if (!data.id) {
      data.id = generatedId(20);
    }

    // Is there a loading chat entity present?
    const loadingIndex: number = entities.findIndex((entity) => {
      return entity.type === ChatEntityType.Loading;
    });

    // If there is a loading index, the next entity should replace the loader.
    if (loadingIndex >= 0) {
      entities.splice(loadingIndex, 1);
      this.inProgress.next(false);
    }

    // check if this entity is replacing another entity by id.
    let replaceIndex: number;
    if (idToReplace) {
      replaceIndex = entities.findIndex((e) => e.id === idToReplace);
    }

    // if this entity should replace another entity and the entity was found, then replace it, otherwise push a new entity.
    if (replaceIndex >= 0) {
      entities.splice(replaceIndex, 1, data);
    } else {
      // conditional logic rules for specific types of entities
      if (newEntity.type === ChatEntityType.KnowledgeResponseTraining) {
        // if there is a new training entity, remove any past training entities.
        entities.forEach((entity, index) => {
          if (entity.type === ChatEntityType.KnowledgeResponseTraining) {
            entities.splice(index, 1);
          }
        });
      }

      entities.push(newEntity);
    }

    this.currentChatEntity.next(newEntity);
    this.chatEntities.next(entities);

    if (!shouldNotUpdateDatabase && data.type !== ChatEntityType.Loading) {
      let newChatData = null;
      if (data.author === ChatEntityAuthor.EVA) {
        newChatData = {
          eva: data
        };
      } else if (data.author === ChatEntityAuthor.User) {
        newChatData = {
          user: data
        };
      }
      if (newChatData) {
        const currentLastState = this.lastStateService.getLastState();
        const newLastState = {
          ...currentLastState,
          shouldNotUpdateDatabase: false,
          chat: {
            ...currentLastState?.chat,
            ...newChatData
          }
        };
        this.lastStateService.updateSaveLastState(newLastState);
      }
    }

    return data.id;
  }

  getLatestEntityOfType(type: ChatEntityType): ChatEntity | undefined {
    const entities = this.chatEntities.getValue();

    // custom - search array backwards and compare each item in array until one is found.
    for (let i = entities.length - 1; i > 0; i--) {
      // compare each entity for the type requested.
      if (entities[i].type === type) {
        return entities[i];
      }
    }

    // if loop never exists the function, return undefined.
    return undefined;
  }

  clearChatEntities() {
    if (this.chatEntities) {
      this.chatEntities.next([]);
    }
  }
}
