import { Component, OnInit, Input } from '@angular/core';
import { EmbedVideoService } from '@eva-services/embed-video/embed-video.service';
import { YouTubeDocument, YouTubeLinks } from '@eva-model/releaseNotes';
import { Observable } from 'rxjs';
import { LearnEVAVideosService } from '@eva-services/about/learn-evavideos.service';
import { AddEditLearnEVAVideosComponent } from './add-edit-learn-evavideos/add-edit-learn-evavideos.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoggingService } from '@eva-core/logging.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-learn-evavideos',
  templateUrl: './learn-evavideos.component.html',
  styleUrls: ['./learn-evavideos.component.scss']
})
export class LearnEVAVideosComponent implements OnInit {

  youTubeUrl = 'https://www.youtube.com/watch?v='; // z4JUm0Bq9AM';

  iframe_html: any;

  loadingReleaseNotes = true;
  releaseNotes$: Observable<any>;
  learnEVAVideosDoc: YouTubeDocument;
  featuredVideoReady = false;
  currentPlayingVideo: YouTubeLinks;

  _isAdministrator = false;
  @Input()
  set isAdministrator(isAdministrator: boolean) {
    this._isAdministrator = isAdministrator;
  }
  constructor(private embedService: EmbedVideoService,
    private learnEVAVideosService: LearnEVAVideosService,
    private dialog: MatDialog,
    private ls: LoggingService) {

    }

  ngOnInit() {
    this.getLearnEVAVideos();
  }

  /**
   * This gets the learn eva videos once and setup the component.
   */
  getLearnEVAVideos() {
    this.learnEVAVideosService.getYouTubeDocument()
    .pipe(
      take(1)
    )
    .subscribe(learnEvaDocs => {
      this.learnEVAVideosDoc = learnEvaDocs;
      this.currentPlayingVideo = this.learnEVAVideosDoc.featured;
      if (typeof this.learnEVAVideosService !== 'undefined') {
        this.setupInitialSettings();
      }
    });
  }

  /**
   * This function sets up the youtube videos and accepts the links as safe for the
   * information in the youtube iframe coming from an external link.
   */
  setupInitialSettings() {
    if (this.learnEVAVideosDoc.featured) {
      // setup the featured video
      this.learnEVAVideosDoc.featured.embedCode =
        this.embedService.embed(this.youTubeUrl + this.learnEVAVideosDoc.featured.youTubeId);

      this.featuredVideoReady = true;
    }
    if (this.learnEVAVideosDoc.links.length > 0) {
      for (let i = 0; i < this.learnEVAVideosDoc.links.length; i++) {
        this.learnEVAVideosDoc.links[i].embedCode =
          this.embedService.embed(this.youTubeUrl + this.learnEVAVideosDoc.links[i].youTubeId,
            { attr: { width: '100%', height: 150 } });
      }
    }
  }


  /**
   * this function initializes the add / edit EVA videos dialog control.
   * If also contains the response back from the dialog to determine what to do with the contrrol next.
   */
  addEditLearnEVAVideos() {
    let dialogData = null;
    let dialogComponent = null;

    const title = ' Learn Videos';
    const confirmButtonText = 'Save';
    const content = '';
    const cancelButtonText = 'Cancel';

    dialogData = new GeneralDialogModel(
      title,
      content, confirmButtonText,
      cancelButtonText, null,
      {
        data: this.learnEVAVideosDoc
      }
    );
    dialogComponent = AddEditLearnEVAVideosComponent;
    const dialogRef: MatDialogRef<AddEditLearnEVAVideosComponent, YouTubeDocument> = this.dialog.open(dialogComponent, {
      data: dialogData,
      disableClose: true,
      minHeight: '400px',
      minWidth: '600px'
    });

    /**
     * Fires after this completes.
     */
    dialogRef.afterClosed().subscribe(async result => {
      // check for a result.
      if (result) {
        try {
          // update the youtube document.
          await this.learnEVAVideosService.storeYoutubeDocument(result);
        } catch (err) {
          console.log(err);
        }
        // finally refactor the videos to show the outcome of the updates
        this.getLearnEVAVideos();
      }
    });
  }

  changeCurrentPlaying(link: YouTubeLinks): void {
    this.currentPlayingVideo = link;
  }

  scrollToElement() {
    document.getElementById("video-player").scrollIntoView({ behavior: 'smooth' });
  }
}
