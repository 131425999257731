import { SearchAuthService } from '@eva-core/search-auth-service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { Group, GroupType } from '@eva-model/group';
import { KnowledgeSearchRecord } from '@eva-model/knowledge/knowledge';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { KnowledgeDeleteComponent } from '../knowledge-delete/knowledge-delete.component';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { AnnounceKnowledgeShow } from '@eva-model/chat/knowledge/chatKnowledge';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { KnowledgeCreateComponent } from '../knowledge-create/knowledge-create.component';
import { PropertyMapping, ValueModifier } from '@eva-model/search';
import { GenericSearchFilter } from '@eva-model/generic-search';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { SearchUtilsService } from '@eva-services/utils/search-utils.service';
import { AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { KnowledgeGlobalFindAndReplaceComponent } from '../knowledge-global-find-and-replace/knowledge-global-find-and-replace.component';

const knowledgeDocNameFormatter = (data) => {
  let name = data.data.name;
  // if (data.data.isDeleted) {
  //   name = `${name} (Archived)`;
  // } else if (data.data.isPublished === false) {
  //   name = `${name} (Draft)`;
  // }
  // console.log(name);
  return name;
};

const knowledgeDocStatusFormatter = (data): string => {
  if (data.data.isDeleted) {
    return 'Archived';
  }
  if (!data.data.lastPublishedVersion) {
    return 'Draft';
  }
  return 'Published';
};

@Component({
  selector: 'app-knowledge-search',
  templateUrl: './knowledge-search.component.html',
  styleUrls: ['./knowledge-search.component.scss']
})
export class KnowledgeSearchComponent implements OnInit, OnDestroy {

  // Subscriptions in this component
  componentSubs: Subscription = new Subscription();

  // Algolia Search
  hits: KnowledgeSearchRecord[] = [];

  context: any;

  // Algolia Columns
  displayedColumns: string[] = ['docName', 'groupPublicKey', 'docVersion', 'archiveAction', 'editAction', 'viewAction', 'linkAction'];

  groupValueMap: {[value: string]: string} = null;

  isDeletedValueMap: {[value: string]: string} = {
    'true': 'Include Archived',
    'false': 'Include Unarchived'
  };

  // object of users group keys, to quickly allow the front end to check object key if conditional buttons should
  // show or not depending if they are a member of a group.
  usersGroupKeys: {[groupPublicKey: string]: boolean} = {};
  knowledgeGroupType = environment.blockConfig.types.groups.types.knowledge;

  isLoading = true;
  componentTitle: string;

  public headerActions: any[] = [{
    text: 'Create Knowledge',
    style: 'primary',
    outputEvent: 'create'
  }];

  // for algolia search
  searchConfig = { indexName: '', searchClient: {} }; // the search configuration
  searchClient: SearchClient; // the algolia search client
  algoliaKnowledgeIndex = 'Knowledge' + environment.algolia.indexEnding;
  algoliaKnowledgeSearchIndex = 'KnowledgeSearch' + environment.algolia.indexEnding;
  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.KNOWLEDGE_READER;
  searchAccess = false; // is search access ready.
  currentlyLoading = true; // currently getting a token
  searchToken: AlgoliaSearchToken; // the algolia search token used for this component.
  knowledgeFilters: GenericSearchFilter[] = []; // the facet filters for use in algolia.
  knowledgeSearchFilters: GenericSearchFilter[] = []; // the facet filters for use in algolia.
  knowledgePropertyNameMapping: PropertyMapping = {
    properties: [
      { propertyName: 'groupPublicKey', propertyUserFriendlyName: 'Group Name', valueModifiers: [ValueModifier.GroupName] },
      { propertyName: 'updatedAt', propertyUserFriendlyName: 'Last Updated', valueModifiers: [ValueModifier.DateShort] }
    ]
  };
  public defaultColDef: ColDef = {
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: 'CustomTooltipComponent'
  };
  public rowHeight: number; // the height of individual rows
  public knowledgeSearchColumnDefs: (ColDef | ColGroupDef)[]; // stores definitions for each column
  public knowledgeColumnDefs: (ColDef | ColGroupDef)[]; // stores definitions for each column

  constructor(
    public evaGlobal: EvaGlobalService,
    private searchAuthService: SearchAuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private multiViewService: MultiViewService,
    private chatKnowledgeService: ChatKnowledgeService,
    private valueModifierService: SearchValueModifierService,
    private searchUtils: SearchUtilsService) {
      this.activatedRoute.data.pipe(take(1)).subscribe(data => {
          this.componentTitle = data.componentTitle;
      });
      this.multiViewService.setCreateNewTabFunction(this.createKnowledgeDocument);
      this.setKnowledgeSearchFilters(); // set this so it exists when the groups update.
    }

  ngOnInit(): void {
    this.getUserGroups();
    this.setColumnDefinitions();
    this.context = { componentParent: this };
  }

  ngOnDestroy(): void {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  /**
   * Gets the users groups, get the knowledge group keys and then make a request to get a search token
   */
  getUserGroups(): void {
    const groupTypes = new GroupType();

    // create observable to subscribe to and checks if groups are ready and returns any knowledge groups.
    const userKnowledgeGroups$ = this.evaGlobal.userGroupsChange$.pipe(
      map(() => {
        if (this.evaGlobal.userGroups) {
          this.evaGlobal.userGroups.forEach((group) => {
            this.usersGroupKeys[group.groupPublicKey] = true;
          });

          return this.evaGlobal.userGroups.filter((group) => {
            return group.groupType === groupTypes.knowledge;
          });
        } else {
          return [];
        }
      })
    );

    this.componentSubs.add(
      userKnowledgeGroups$.subscribe((groups: Group[]) => {
        // In this case, we want to use the user groups as a filter also, so we create an object (map)
        // where the key is the group public key and the value is the group name.
        this.groupValueMap = {};
        groups.forEach((group: Group) => {
          this.groupValueMap[group.groupPublicKey] = group.groupName;
        });

        // update some search filters if the user belongs to a knowledge group
        if (groups.length > 0) {
          this.knowledgeSearchFilters[1].hidden = false;
          this.knowledgeSearchFilters[2].hidden = false;
          this.knowledgeFilters[1].hidden = false;
        }

        // create an array of just user group public keys for the search token request.
        const knowledgeGroupKeys: string[] = [...groups.map(group => group.groupPublicKey),
          '0494d22d1569f4541550557ca6ed12a155075502344f1411cc0b3d34a3be3e32e5e2314946af0bc45313c44f11455cf9a4cf005a9054a32daca6ccf08054b258ab'];
        // this.knowledgeFilters[0].defaultValue = knowledgeGroupKeys;

        this.knowledgeSearchFilters[0].filterValues = groups; // set the knowledge set filter values.
        this.knowledgeFilters[0].filterValues = groups; // set the knowledge set filter values.

      })
    );
  }

  /**
   * This checks if a user has the groups in their group list and enables editing if that is the case.
   *
   * @param hit 
   */
  isValidEditor = (groupPublicKey: string): boolean => {
    return this.usersGroupKeys[groupPublicKey];
  }

  /**
   * Fired every time Algolia search results change and binds the new results to the component property hits for display.
   *
   * @param searchResults - results from an Algolia search, from the <hits> component
   */
  onSearchChange(searchResults: { results: any, state: any }): void {
    if (searchResults && searchResults.results) {
      this.hits = searchResults.results.hits;
    }
  }

  /**
   * Called when creating a new document button is pressed.
   */
  createKnowledgeDocument = (): void => {
    this.multiViewService.setCreateNewTab({
      component: KnowledgeCreateComponent,
      tabName: 'New Document',
      removePaddingFromContainer: true
    });
  }

  /**
   * Sets an object to a property in knowledge service and then redirects the user to the edit doc page for the result clicked.
   *
   * @param {KnowledgeSearchResult} result - the search result data clicked from results of Algolia
   */
  editKnowledgeDocument = (docId: string, docName: string, groupPublicKey: string, isPublished: boolean): void => {
    this.chatKnowledgeService.announceKnowledgeEdit({
      docGroup: groupPublicKey,
      docId: docId,
      docName: docName,
      published: isPublished,
      viewKey: 'bGV0IG1lIGluIHBsZWFzZSE'
    });
  }

  logThis(data: any): void {
    console.log(data);
  }

  /**
   * View the knowledge document and get the last edited version.
   */
  // viewKnowledgeDocument = (result: KnowledgeSearchRecord): void => {
  viewKnowledgeDocument = (docId: string, groupPublicKey: string, isPublished: boolean): void => {
    console.log(docId);
    console.log(groupPublicKey);
    console.log(isPublished);
    // const userMemberOfKnowledgeGroup = this.usersGroupKeys[groupPublicKey];
    // ----
    // this is a temporary fix for client care guides. Basically, if the result is being viewed by a user not in the knowledge group
    // of the result, then let's direct them to the knowledge on a single page. We should provide a menu or something to choose
    // how the user wants to view their document. In the split pane view like they are asking a question, or just as a standalone.
    // ----
    // if (!userMemberOfKnowledgeGroup) {
    //   // open knowledge as a standalone page
    // this.router.navigateByUrl(`/knowledge/view/${result.docId}/${result.groupPublicKey}/${result.isPublished ? '0' : result.docVersion}/bGV0IG1lIGluIHBsZWFzZSE`);
    // } else {
      // open knowledge in the split view on the Home screen.
      // this.router.navigateByUrl('/').then(() => {
      // once route has changed, execute announcement.
      const announcement: AnnounceKnowledgeShow = {
        docId: docId,
        docGroup: groupPublicKey,
        viewKey: 'bGV0IG1lIGluIHBsZWFzZSE',
        promptForFeedback: false,
        published: isPublished
      };
      this.chatKnowledgeService.announceKnowledgeShow(announcement);
      // });
    // }
  }

  /**
   * Triggers the opening of a delete/restore knowledge document dialog.
   */
  deleteKnowledgeDocument = (result: KnowledgeSearchRecord): void => {
    const dialogData = {
      groupPublicKey: result.groupPublicKey,
      docId: result.docId,
      docName: result.docName,
      isDeleted: result.isDeleted
    };
    const dialog = this.dialog.open(KnowledgeDeleteComponent, {data: dialogData});
    dialog.afterClosed().subscribe(
      (success: boolean) => {
        if (success) {
          result.isDeleted = !result.isDeleted;
        }
      }
    );
  }

//#region Token Functions

//#region Setup Search Grid Items.

  /**
   * This sets the default communication search settings for the component.
   */
  private setKnowledgeSearchFilters(): void {
    this.knowledgeSearchFilters = [{
      filterName: ['Group'],
      defaultValue: [null],
      attribute: 'groupPublicKey',
      type: 'select',
      filterValueModifier: ValueModifier.GroupName,
      hidden: true
    }, {
      filterName: ['Archived'],
      defaultValue: [false],
      attribute: 'isDeleted',
      filterValues: [true, false],
      type: 'select',
      filterValueModifier: ValueModifier.KnowledgeArchivedStatus,
      hidden: true
    }, {
      filterName: ['Document Type'],
      defaultValue: [true],
      attribute: 'isPublished',
      filterValues: [true, false],
      type: 'select',
      filterValueModifier: ValueModifier.KnowledgePublishedStatus,
      hidden: true
    }];

    this.knowledgeFilters = [{
      filterName: ['Group'],
      defaultValue: [null],
      attribute: 'groupPublicKey',
      type: 'select',
      filterValueModifier: ValueModifier.GroupName,
      hidden: true
    }, {
      filterName: ['Archived'],
      defaultValue: [false],
      attribute: 'isDeleted',
      filterValues: [true, false],
      type: 'select',
      filterValueModifier: ValueModifier.KnowledgeArchivedStatus,
      hidden: true
    }];
  }

  /**
   * This sets the column definitions for search.
   */
  private setColumnDefinitions(): void {
    this.knowledgeSearchColumnDefs = [
      {...(this.searchUtils.getColumnDefinition('Name', 'docName')), valueFormatter: knowledgeDocNameFormatter},
      {...(this.searchUtils.getColumnDefinition('Group Name', 'groupPublicKey')),
      cellRenderer: (data) => {
        return this.valueModifierService.modifyValues(ValueModifier.GroupName, data.data.groupPublicKey) as string;
      }},
      (this.searchUtils.getColumnDefinitionForDateTime('Last Updated', 'docVersion')),
      {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'updatedAt')),
      cellRenderer: 'actionTemplateRenderer'}
    ];

    // this.knowledgeColumnDefs = [
    //   {...(this.searchUtils.getColumnDefinition('Name', 'name')), valueFormatter: knowledgeDocNameFormatter},
    //   {...(this.searchUtils.getColumnDefinition('Group Name', 'groupPublicKey')),
    //   cellRenderer: (data) => {
    //     return this.valueModifierService.modifyValues(ValueModifier.GroupName, data.data.groupPublicKey) as string;
    //   }},
    //   (this.searchUtils.getColumnDefinitionForDateTime('Last Updated', 'docVersion')),
    //   {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'updatedAt')),
    //   cellRenderer: 'actionTemplateRenderer'}
    // ];

    this.knowledgeColumnDefs = [
      {...(this.searchUtils.getColumnDefinition('Name', 'name')), valueFormatter: knowledgeDocNameFormatter},
      {...(this.searchUtils.getColumnDefinition('Status', 'status')), valueFormatter: knowledgeDocStatusFormatter},
      (this.searchUtils.getColumnDefinitionForDateTime('Last Updated', 'lastEditVersion')),
      {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'updatedAt')),
      cellRenderer: 'actionTemplateRenderer'}
    ];
  }

//#endregion Setup Search Grid Items.

//#endregion Token Functions

  /**
   * Generates a link to the document and copies it to the clipboard.
   * Optionally passing in a section id to add it as a query param
   *
   * @param sectionId section id (eg. 14_1)
   * @param version number - only for drafts
   */
  getDocumentLink = (result: KnowledgeSearchRecord): void => {
    this.chatKnowledgeService.copyDocumentLinkToClipboard(result.docId, result.groupPublicKey);
  }

  /**
   * Creates a dialog component that shows the uploads
   */
  viewGlobalFindAndReplace() {
    // Create our overlay which contains our upload status component
    // Visualizer will close itself when done
    this.dialog.open(KnowledgeGlobalFindAndReplaceComponent, {
      maxWidth: '65vw',
      width: '65vw',
      height: '80vh'
    });
  }

}
