<div class="group-page">
  <app-page-header [title]="componentTitle"></app-page-header>

  <p-messages [(value)]="msgs"></p-messages>

  <div>

    <!-- The Top Bar -->
    <form>
          <div style="padding: 5px;">

            <div fxFlex="100%">
              <div *ngIf="!group" style="padding: 5px;">
                <div fxFlex="40%" class="rightMargin10">
                  <button pButton type="button" label="Group not found!" class="ui-button-secondary pCustomButton"
                    icon="fas fa-exclamation" iconPos="left">
                  </button>
                </div>
                <div fxFlex="60%" fxHide.lt-lg="true">
                </div>
              </div>

              <div *ngIf="group" style="padding: 5px;">
                <!-- <div fxFlex="1%" class="rightMargin10"> 
                  </div> -->
                <div fxFlex="48%" class="rightMargin10">
                  <button pButton type="button" label="Group Related Functions"
                    class="ui-button-secondary pCustomButton" [pTooltip]="group.groupName" showDelay=400 hideDelay=200
                    icon="fas fa-cogs" iconPos="left">
                  </button>
                </div>
                <div fxFlex="20%" fxHide.lt-lg="true">
                </div>
                <div fxFlex="1%" class="rightMargin10">
                </div>
                <div fxFlex="15%" fxFlex.lt-lg="25%">

                  <div class="app-loading" *ngIf="isWaitingForDelete">
                    <span style="padding-top: 3px !important;" *ngIf="isWaitingForDelete">
                      <mat-spinner [diameter]=24 [strokeWidth]=6 *ngIf="isWaitingForDelete"></mat-spinner>
                    </span>
                    <span class="verColor" *ngIf="isWaitingForDelete">
                      <h6>&nbsp;Please wait&nbsp;...&nbsp;&nbsp;</h6>
                    </span>
                  </div>

                  <button pButton *ngIf="!isWaitingForDelete" [disabled]="isWaiting" type="button" label="Delete All !"
                    class="ui-button-info pCustomButton fullWidth" icon="fas fa-trash-alt" iconPos="left"
                    (click)="deleteAllConfirm()">
                  </button>

                </div>
                <div fxFlex="1%" class="rightMargin10">
                </div>
                <div fxFlex="15%" fxFlex.lt-lg="25%">

                  <div class="app-loading" *ngIf="isWaitingForAddMembers">
                    <span style="padding-top: 3px !important;" *ngIf="isWaitingForAddMembers">
                      <mat-spinner [diameter]=24 [strokeWidth]=6 *ngIf="isWaitingForAddMembers"></mat-spinner>
                    </span>
                    <span class="verColor" *ngIf="isWaitingForAddMembers">
                      <h6>&nbsp;Please wait&nbsp;...&nbsp;&nbsp;</h6>
                    </span>
                  </div>

                  <button pButton *ngIf="!isWaitingForAddMembers" [disabled]="!isAnyMember() || isWaiting" type="button"
                    label="Add users" class="ui-button-info pCustomButton fullWidth" icon="fas fa-users" iconPos="left"
                    (click)="addMembersConfirm()">
                  </button>

                </div>
              </div>

            </div>
          </div>
    </form>
    <!-- The Top Bar -->

    <div *ngIf="group">
        <div style="padding: 10px;">
          <div fxFlex="100%">
            <form [formGroup]="frmGrp">
              <mat-form-field class="fullWidth">
                <textarea matInput placeholder="Comma separated group member emails"
                  formControlName="memberEmailsCsv"></textarea>
              </mat-form-field>
            </form>
          </div>
        </div>
    </div>

  </div>

  <!-- {{ jsonStringify(frmGrp.value) }} -->

  <!-- {{ jsonStringify(this.group) }} -->
</div>