<div class="tutorial-dialog">
    <button mat-icon-button mat-dialog-close class="closeBtn">
        <mat-icon>close</mat-icon>
    </button>

    <mat-dialog-content>
        <div class="tutorial-dialog-card-content">
            <div class="tutorial-image" fxFlex="40%">
                <img width="200" height="200" mat-card-image [src]="incomingData.imageLink" [alt]="incomingData.imageAlt">
                <!-- [src]="/assets/images/illy-atb-system.png" alt="ATB System" -->
            </div>
            <div fxFlex="2%"></div>
            <div class="tutorial-description" fxFlex="58%">
                <div [innerHTML]="incomingData.html | safeHtml"></div>
                <button mat-raised-button class="text-white" type="button" color="primary" (click)="launchIntro()" cdkFocusInitial>
                    Learn how
                </button>
                <button class="btn-round-white" mat-button type="button" (click)="skipTutorial()" mat-dialog-close>
                    Skip & close
                </button>
            </div>
        </div>
    </mat-dialog-content>
</div>

