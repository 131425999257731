<h1 mat-dialog-title>{{ dialogData.title }}</h1>

<div mat-dialog-content>

  <mat-card>
    <mat-card-title>
      Groups shared with:
    </mat-card-title>
    <mat-card-content>
      <app-spinner name="getting-preferences" show="true" *ngIf="!sharedGroupInfoLoaded">
        <h6>Getting group information...</h6>
      </app-spinner>
      <mat-list *ngIf="sharedGroupInfoLoaded && sharedGroupInfo.length > 0">
        <mat-list-item *ngFor="let element of sharedGroupInfo">
          <app-group-info-display matLine [groupPublicKey]="element.groupPublicKey"></app-group-info-display>
          <button class="move-right" mat-button type="button" color="primary" (click)="unshareGroup(element)" [disabled]="element.isRemoving">
            <mat-spinner diameter="24" *ngIf="element.isRemoving"></mat-spinner>
            <span *ngIf="!element.isRemoving">Remove</span>
          </button>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="sharedGroupInfo.length === 0">
        <mat-list-item>
          <div matLine>None</div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
  
  <mat-card>
    <mat-card-title>
      Share with other groups:
    </mat-card-title>
    <mat-card-content>
      <form>
        <mat-form-field class="full-width-input">
          <input matInput type="text" autocomplete="off" placeholder="Search for Groups..." (keyup)="findGroup()" [(ngModel)]="query" [disabled]="shouldDisableSearch()" name="queryInput" #queryInput>
          <mat-spinner matSuffix diameter="24" *ngIf="queryLoading | async"></mat-spinner>
        </mat-form-field>
      </form>
      <mat-list *ngIf="showGroupSearchResults | async">
        <mat-list-item *ngFor="let group of groupSearchResults; let i = index;">
          <div matLine>
            <span>{{group?.groupName}}</span>
            <button class="move-right" *ngIf="!checkIfGroupIsShared(group.groupPublicKey)" mat-raised-button type="button" color="primary" (click)="addToSelectedList(group, i)">
              <mat-icon>share</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
      <h4>Selected Groups to Share With</h4>
      <mat-list>
        <mat-list-item *ngFor="let group of selectedGroups; let i = index">
          <div matLine>
            <span>{{group?.groupName}}</span>
            <button class="move-right" mat-raised-button type="button" color="primary" (click)="removeFromSelectedList(group, i)">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
      <form [formGroup]="createInvitationForm" (ngSubmit)="createInvitation()">
        <mat-form-field class="full-width-input">
          <input matInput type="text" formControlName="invitationComment" placeholder="Send a comment to the group(s).">
        </mat-form-field>
        <button mat-raised-button type="submit" color="primary" [disabled]="shouldDisableSendButton()">
          <mat-spinner diameter="24" *ngIf="isSending"></mat-spinner>
          <span *ngIf="!isSending">Send invitation to selected group(s)</span>
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div mat-dialog-actions>
  <div class="margin-left-auto">
    <button mat-button color="accent" [mat-dialog-close]="true" *ngIf="dialogData.cancelBtnTxt !== undefined">{{ dialogData.cancelBtnTxt }}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>{{ dialogData.confirmBtnTxt }}</button>
  </div>
</div>
