<ng-container *ngFor="let subFilters of _selectedFilter?.filterName; let i = index;">
    <div class="generic-search-filter" [ngClass]="{'begin': _selectedFilter.filterName.length > 1 && i === 0, 'end': _selectedFilter.filterName.length > 1 && i === _selectedFilter.filterName.length-1}">
        <ng-container *ngIf="_selectedFilter.type === 'date' || _selectedFilter.type === 'date-range'">
            <div class="filter-input-container">
                <button mat-stroked-button [matMenuTriggerFor]="menu" [disabled]="_selectedFilter.disabled"><div class="filter-name">{{ _selectedFilter.filterName[i] }}</div>{{ _selectedFilter.defaultValue | getFilterValue:_selectedFilter:i }}</button>
                <mat-menu #menu="matMenu">
                    <mat-form-field class="example-full-width" (click)="$event.stopPropagation();">
                        <input matInput type="date" [(ngModel)]="_selectedFilter.defaultValue[i]"
                            (ngModelChange)="valueChanged(i)" [value]="_selectedFilter.defaultValue[i]" #dateInput>
                    </mat-form-field>
                    <button (click)="clearFilterValue(dateInput, i)" mat-icon-button aria-label="clear" matTooltip="Clear">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-menu>
            </div>
        </ng-container>
        <ng-container *ngIf="_selectedFilter?.type === 'select'">
            <!-- <h3 class="filter-name">{{_selectedFilter.filterName[i]}}</h3> -->
            <div class="filter-input-container">
                <button mat-stroked-button [matMenuTriggerFor]="menu" (click)="fetchSelectionList()" [disabled]="_selectedFilter.disabled"><div class="filter-name">{{ _selectedFilter.filterName[i] }}</div>{{ _selectedFilter.defaultValue | getFilterValue:_selectedFilter:i }}</button>
                <mat-menu #menu="matMenu">
                    <button *ngFor="let option of selectionList" mat-menu-item (click)="$event.stopPropagation(); option.selected = !option.selected; valueChanged();">
                        <mat-icon *ngIf="option.selected" color="primary">check_box</mat-icon>
                        <mat-icon *ngIf="!option.selected">check_box_outline_blank</mat-icon>
                        <span>{{ option.value | getFilterValue:_selectedFilter:i }}</span>
                    </button>
                </mat-menu>
            </div>
        </ng-container>
    </div>
</ng-container>