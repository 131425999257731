import { Component, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'eva-app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent {

  windowScrolled: boolean; // whether the window has scrolled.

  constructor(@Inject(DOCUMENT) private document: Document) {}
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
        this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
        this.windowScrolled = false;
    }
  }
  /**
   * This function scrolls the page to the top.
   */
  scrollToTop() {
      (function smoothscroll() {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }


  returnToTop() {
    // console.log('return to top');
    // const scrollDuration = 500;
    // const cosParameter = window.scrollY / 2;
    // let scrollCount = 0;
    // let oldTimestamp = performance.now();
    // function step (newTimestamp) {
    //     scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
    //     if (scrollCount >= Math.PI) window.scrollTo(0, 0);
    //     if (window.scrollY === 0) return;
    //     window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
    //     oldTimestamp = newTimestamp;
    //     window.requestAnimationFrame(step);
    // }
    // window.requestAnimationFrame(step);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
