/**
 * Changes to this file require changes to EVA and EVA-HeartFunctions Project.
 */
import { TfidfDocumentMatches } from '../eva-custom-nlp/eva-custom-nlp';
import { KnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { TfidfUserLastStateFeedBack } from './chatML';
import { ScrollPosition } from '@eva-model/util/scrollPosition';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { ComponentRef } from '@angular/core';

/**
 * Who is authoring the chat message. This dictates the appearance of the message.
 */
export enum ChatEntityAuthor {
    User,
    EVA
}

/**
 * Used to determine what component this data should be loaded into and what component should show.
 */
export enum ChatEntityType {
    Loading,
    Text,
    KnowledgeResponse,
    KnowledgeResponseTraining,
    InteractionResponse,
    RouteRedirect
}

/**
 * ChatEntity base object.
 */
export interface ChatEntity {
    author: ChatEntityAuthor;
    type: ChatEntityType;
    createdAt?: number; // timestamp when created
    componentData?: ChatKnowledgeResponse | any; // data to inject into component if chat is of a certain type
    id?: string; // random id for locating this entity in the list, replacement, splicing, etc. does not always exist
    metadata?: any; // extra data for this chat entity
    text?: string | null; // string content to display
    originator?: string; // where the message originated from. can be displayed in the view.
}

/**
 * Data injected into the chat response component
 */
interface ChatKnowledgeResponse {
    query: string;
    response: any;
    type: string;
}


/**
 * This interface is used to query the EVA heart system
 */
export interface EVAHeartQuery {
    query: string; // the query passed to the heart system.
    direction?: EvaChatDirection; // the direction of the query
    event?: EvaChatEventTypes; // the event type of the query.
    filter?: EvaHeartFilterResponseType[];
    incomingGroupRequests?: EvaCustomSignedIncomingChatRequest[]; // the groups that the user is requesting knowledge from.
}

/**
 * What the backend endpoint expects before sending.
 */
export interface EVAHeartQueryRequest extends EVAHeartQuery {
    email: string;
    incomingGroupRequests: EvaCustomSignedIncomingChatRequest[];
}

/**
 * This interface is used to create the basic shape of the incoming eva group requests.
 */
export interface EvaIncomingGroupChatRequest {
    publicKey: string; // the public key of the group that the request is coming in with
    version: string; // the version number that is incoming;
    timestamp: number; // the timestamp of the network in the request.
}

/**
 * This takes the incoming EVA group request, and has a crypto signature for the eva signed incoming group request.
 */
export interface EvaCustomSignedIncomingChatRequest {
    groupData: EvaIncomingGroupChatRequest; // the group details of the incoming chat request
    signature: string; // the hex encoded DER signature relating to the group
}

/**
 * This is the shape of a dialogflow to detect the intent and context.
 */
export interface EVAQueryResponse {
    fulfillmentText?: string; // this is additional information that may be passed to the calling function.
    // the fulfillment text gets presented in the EVA Chat / and read out if voice is on.
    query: string; // the original query provided to the EVA Heart service.
    message?: string; // this is an option messagee to include if there is an error when calling eva heart.
    response?: GenericEVAResponse | CustomIdidfEVAKnowledgeResponse | any; // generic any type of response
    type: EvaHeartResponseTypes; // the type of response from EVA heart
}

/**
 * This interface is used to return document matches with a Customer TFIDF type to the system.
 */
export interface CustomIdidfEVAKnowledgeResponse {
    tfidfDocumentMatches: TfidfDocumentMatches[]; // these are the matches from the tfidf AI
    knowledgeDocument: KnowledgeDocument; // this is the knowledge doc matching the current record.
    count?: number; // the item number that we are on in the response. (will be zero for first match)
    fulfillmentText?: string; // the text that is representing the current match for the AI
    // this is the status of the user feeedback based on the information provided by the user.
    tfidfUserLastStateFeedBack?: TfidfUserLastStateFeedBack;
}

/**
 * This function has a generic response for the EVA application. It is used when matches fail on the backend.
 */
export interface GenericEVAResponse {
    fulfillmentText: string; // this is presented to the User in EVA Chat
}

//#region EVAChatEnums

/**
 * This contains the response types that may come back from the EVA heart API's
 */
export const enum EvaHeartResponseTypes {
    DIALOGFLOW = 'DialogFlowResponse',
    EVAINTERACTION = 'EVAInteraction',
    EVAMLHEART = 'EVAMLHeart',
    DEEPPAVLOV = ' DeepPavlov',
    UNDEFINED = 'undefined',
    ERROR = 'error',
    TFIDF = 'EVATFIDF'
}

/**
 * The chat event types that may be incoming.
 */
export const enum EvaChatEventTypes {
    DATE = 'interaction-date',
    DATETIME = 'interaction-dateandtime',
    MONTH = 'interaction-dateperiod',
    EMAIL = 'interaction-email',
    FILE = 'none',
    NONE = 'none',
    NUMBER = 'interaction-number',
    SLIDER = 'interaction-number',
    TEL = 'interaction-phonenumber',
    TIME = 'interaction-time',
    URL = 'interaction-url',
    WEEK = 'interaction-dateperiod',
    SWITCH = 'no-interaction-true-false',
    CHECKBOX = 'no-interaction-true-false',
    RADIOGROUP = 'interaction-ordinal',
    CHECKBOXGROUP = 'interaction-ordinal',
    SELECT = 'interaction-ordinal',
    DEFAULT = 'any',
    INTERACTIONCHANGE = 'interaction-change',
    INTERACTIONELEMENTCHANGE = 'interaction-element-change'
}

/**
 * Filter the response from heart to only give a specific type of response.
 */
export const enum EvaHeartFilterResponseType {
    KNOWLEDGE = 'knowledge'
}

/**
 * This contains the items for the EVA Chat direction
 */
export const enum EvaChatDirection {
    DYNAMIC_INTERACTION_FOLLOWUP = 'dynamicInteractionFollowup',
    UNKNOWN = 'unknown'
}
//#endregion EVAChatEnums

/**
 * The following region contains the interfaces to hit the Providers that have been built for the EVA Heart interfaces.
 */

//#region ServerSideProviderInterfaces

/**
 * This interface is used to create a request in EVA heart and to send this to dialogflow. It is the minimum structure to
 * be accepted into the dialogflow system. This is for v2 of the API.
 */
export interface DialogFlowQuery {
    session: string; // the session path
    queryInput: {
        text: {
            languageCode: string; // the language code to send. 'en-us'
            text: string; // the query that you will hit dialogflow with.
        };
    };
}

//#endregion ServerSideProviderInterfaces

//#region FrontEnd

/**
 * This interface is used to send the next query to a behavoir subject in the EVA application. It is not for use in the
 * EVA Heart functions or backend code.
 */
export interface NextChatQuery {
    query: string;
    type: NextChatQueryType;
}

/**
 * This interface contains all chat query types, feel free to add more if you are filtering and need more
 * when filter the query observable, simply import this type and define the type you're looking for in a filter.
 * This is used only in the EVA application. It is not for use in the EVA Heart functions project or backend code.
 */
export enum NextChatQueryType {
    // Break
    BREAK,
    // Process Setup
    PROCESS_NAME,
    // ML User Responses
    MLRESPONSE_GETBETTER_FACTSWRONG,
    MLRESPONSE_GETBETTER_NOTRELEVANT,
    MLRESPONSE_GETBETTER_POORUNDERSTANDING,
    MLRESPONSE_GETBETTER_OTHER
}

/**
 * This interface represents the function which is to be invoked on dynamic component instantiation
 */
export interface FunctionCall {
    functionName: string;       // name of the function being invoked
    parameters?: any[];         // parameters for the function
    async?: boolean;            // whether this function is async or not - if async, the invocation will be awaited
}


/*
  This interface represents the announcement object when creating new or editing existing entity
  if entityId is not provided, it will open create new entity view, otherwise edit view

  entities - (Interaction, Workflow, Knowledge Doc, Process, Change, Technical Requirement, Communication Requirement)
**/
export interface AnnounceNewTab {
    component?: any;                        // Component being opened dynamically - e.g. SaltChatComponent
    componentRef?: ComponentRef<any>;       // Reference to the instance of the dynamically opened component
    entityId?: string;                      // id of the entity being opened
    additionalInstanceData?: any;           // additional information being passed to the component on instantiation
    additionalLocalInstanceData?: any;
    functionCalls?: FunctionCall[];         // any functions that are needed to be invoked on instantiation
    tabName: string;                        // name of the tab
    removePaddingFromContainer?: boolean;   // whether to remove padding from container or not when loading the dynamic component
    scrollPosition?: ScrollPosition;        // position of the scroll indicator for this tab
    createdAt?: number;                      // timestamp of when the tab was created
    updatedAt?: number;                      // timestamp of when the tab was updated
}

export interface AnnounceNewTabs extends Partial<Record<Routes, AnnounceNewTab[]>> {}

//#endregion FrontEnd
