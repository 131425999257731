import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map, catchError } from 'rxjs/operators';
import { InteractionResponse } from '@eva-model/interaction/interactionResponse';
import { InteractionMapped } from '@eva-model/interaction/interactionMapped';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';

@Injectable()
export class DynamicInteractionsService {

  private getPrcsByNameUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getProcessesByName';
  private getDynamicFormByIdUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getDynamicFormById';
  private createDynamicFormUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'crud-createDynamicForm';
  private getInteractionsByGroupUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getInteractionsByGroup';
  private getInteractionByIdAndVerUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getInteractionByIdAndVer';
  private getDynamicListUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getDynamicList';

  constructor(private http: HttpClient,
    private formBuilderService: FormBuilderService,
    private perf: AngularFirePerformance) { }

  fetchProcessByTitle(processName: string): Observable<any> {
    processName = processName.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('title', processName);

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getPrcsByNameUrl, options).pipe(
      trace('dynamic-forms-fetchProcessByTitle')
    );
    // .pipe(
    //  catchError(err => Observable.of([]))
    // );
  }

  fetchInteractionsById(interactionId: string): Observable<InteractionResponse> {
    interactionId = interactionId.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('interactionId', interactionId);

    const options = {
      params: paramsOption
    };

    return this.http.get<InteractionResponse>(this.getInteractionByIdAndVerUrl, options).pipe(
      trace('dynamic-forms-fetchInteractionsById')
    );
  }

  fetchInteractionsByIdAndVer(interactionId: string, version: number): Observable<any> {
    interactionId = interactionId.trim();

    // Add safe, URL encoded search parameter if there is a search term
    let paramsOption = new HttpParams().set('interactionId', interactionId);
    paramsOption = paramsOption.set('version', version.toString());

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getInteractionByIdAndVerUrl, options).pipe(
      trace('dynamic-forms-fetchInteractionsByIdAndVer')
    );
  }

  fetchInteractionsByGroup(groupPk: string): Observable<any> {
    groupPk = groupPk.trim();

    // Add safe, URL encoded search parameter if there is a search term
    const paramsOption = new HttpParams().set('groupPublicKey', groupPk);

    const options = {
      params: paramsOption
    };

    return this.http.get(this.getInteractionsByGroupUrl, options).pipe(
      trace('dynamic-forms-fetchInteractionsByGroup')
    );
  }

  createDynamicForm(dynamicForm: object): Observable<any> {
    return this.http.post(this.createDynamicFormUrl, dynamicForm).pipe(
      trace('dynamic-forms-createDynamicForm')
    );
  }

  //#region Interaction object mapper
  interactionObjectMapper(interactionData: InteractionResponse): InteractionMapped {
    let dynInteraction: any = {};
    //#region input validation
    if ( ! (interactionData.FormScreens &&
        Array.isArray(interactionData.FormScreens) &&
        interactionData.name &&
        interactionData.id &&
        interactionData.groupPublicKey &&
        interactionData.timestamp ) ) return dynInteraction;
    //#endregion

    dynInteraction.name = interactionData.name;
    dynInteraction.id = interactionData.id;
    dynInteraction.version = interactionData.version.version;
    dynInteraction.groupPublicKey = interactionData.groupPublicKey;
    dynInteraction.timestamp = interactionData.timestamp;
    dynInteraction.encryptedByDefault = interactionData.encryptedByDefault;
    dynInteraction.FormScreens = [];

    if ( !interactionData.FormScreens || !Array.isArray(interactionData.FormScreens) ) return;
    interactionData.FormScreens.forEach( scrnArray => {

      if ( scrnArray && Array.isArray(scrnArray) ) {
        scrnArray.forEach( scrn => {
          const frmElmnts = [];
          if ( scrn.FormElements && Array.isArray(scrn.FormElements) ) {
            scrn.FormElements.forEach(scrnElement => {
              frmElmnts.push(scrnElement.element);
            });
          }
          dynInteraction.FormScreens.push({ name: scrn.name, order: scrn.order, "FormElements": frmElmnts});
        });

        dynInteraction.FormScreens = dynInteraction.FormScreens.sort( function(a, b) {
          return ( (a.order || a.order === 0) && (b.order || b.order === 0) )
            ? (( a.order > b.order ) ? 1 : ( ( b.order > a.order ) ? -1 : 0 ))
            : 0;
        } );
      }
    });

    dynInteraction.specialControls = [];
    if ( interactionData.specialControls && Array.isArray(interactionData.specialControls) ) {
      dynInteraction.specialControls = JSON.parse(JSON.stringify(interactionData.specialControls));
    }
    // fix for ng-dynamic-form library to support older shape for new version for Angular 9
    dynInteraction = this.formBuilderService.fixOldShapeForRelations(dynInteraction);

    return dynInteraction;
  }
  //#endregion

}
